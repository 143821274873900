import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import useQueryParams from 'hooks/useQueryParams';
import useTaskManagement from 'features/taskManagement/useTaskManagement';

import FormModal from './components/FormModal';
import TemplateList from './components/TemplateList';

import classNames from 'utils/classNames';
import { linkStyles, tabStyles } from 'utils/tabStyles';

const Templates = () => {
  const { PARTNER_TYPES, TYPES } = useTaskManagement();

  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [templates, setTemplates] = useState({ rows: [] });

  const { params, updateParams } = useQueryParams({
    page: 1,
    pageSize: 10,
    sorts: 'createdAt:desc',
    partnerType: 'new',
    type: 'onboarding',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/tasks/lists/templates/list', {
        params: { ...params, include: ['lastUpdater'] },
      });

      if (isSubscribed) {
        setTemplates(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params]);

  const fetchData = async () => {
    setLoading(true);

    const response = await axios.get('/v2/tasks/lists/templates/list', {
      params: { ...params, include: ['lastUpdater'] },
    });

    setTemplates(response.data.data);
    setLoading(false);
  };

  return (
    <div>
      <FormModal
        open={openForm}
        setOpen={setOpenForm}
        onSuccess={() => fetchData()}
      />

      <div className="flex space-x-4 items-center">
        <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
          Task Management
        </h2>

        <button
          onClick={() => setOpenForm(true)}
          className="border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
        >
          <PlusIcon className="w-3 h-3" />
          <span>Add</span>
        </button>
      </div>

      <div className="border-b border-grayscale-500 mt-4">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {PARTNER_TYPES.map((partnerType) => (
            <div
              key={partnerType.value}
              onClick={() =>
                updateParams({ ...params, partnerType: partnerType.value })
              }
              className={classNames(
                params.partnerType === partnerType.value
                  ? 'border-secondary-light font-bold text-secondary-light'
                  : '',
                'flex cursor-pointer items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent'
              )}
            >
              {partnerType.name}
            </div>
          ))}
        </nav>
      </div>

      <div className="tabnav tablinks flex mt-5">
        {TYPES.map((type, idx) => (
          <a
            key={type.value}
            onClick={() => updateParams({ ...params, type: type.value })}
            className={classNames(
              'cursor-pointer',
              linkStyles,
              tabStyles(
                idx,
                TYPES.findIndex((type) => type.value === params.type),
                TYPES.length - 1
              )
            )}
          >
            {type.name}
          </a>
        ))}
      </div>

      <TemplateList
        params={params}
        data={templates}
        loading={loading}
        setParams={updateParams}
      />
    </div>
  );
};

export default Templates;
