import classNames from 'utils/classNames';

const UrgencyLevel = ({ urgencyLevel }) => {
  return (
    <span
      className={classNames(
        'px-4 py-1.5 rounded-full text-13',
        [
          'bg-warning text-warning-dark',
          'bg-warning text-warning-dark',
          'bg-[FFD2B9] text-[D48236]',
          'bg-[FFD2B9] text-[D48236]',
          'bg-error-light text-error-dark',
        ][urgencyLevel - 1]
      )}
    >
      {urgencyLevel}
    </span>
  );
};

export default UrgencyLevel;
