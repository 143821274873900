import { keys, isEmpty } from 'lodash';

import { AcitivityLogItem } from 'features/activityLogs';

const AcitivityLogList = ({ logs, changeCustomizers = {} }) => {
  return (
    <div className="overflow-y-scroll h-60vh mt-2">
      {!isEmpty(logs) ? (
        keys(logs).map((date) => (
          <div key={date}>
            <p className="font-inter tracking-3/4 font-bold text-grayscale-800 mt-4 mb-2">
              {date}
            </p>

            <div className="space-y-8 mt-2 pr-4">
              {logs[date].map((log) => (
                <AcitivityLogItem
                  log={log}
                  key={log.id}
                  changeCustomizers={changeCustomizers}
                />
              ))}
            </div>
          </div>
        ))
      ) : (
        <p className="text-center mt-5 text-grayscale-700 tracking-3/4 text-sm font-inter">
          No Activity Logs record
        </p>
      )}
    </div>
  );
};

export default AcitivityLogList;
