import { useUsers } from 'features/users';

const UserStatus = ({ value, className = 'py-1 px-3 rounded-xl' }) => {
  const { getStatusByValue } = useUsers();

  const status = getStatusByValue(value);

  return (
    <span
      className={className}
      style={{ color: status?.color, backgroundColor: status?.bgColor }}
    >
      {value}
    </span>
  );
};

export default UserStatus;
