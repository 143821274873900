import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';

import PerformanceBreakdownModal from './PerformanceBreakdownModal';

import classNames from 'utils/classNames';
import { currencyFormatter, numberFormatter } from 'utils/formatters';

const StorePerformanceCard = ({
  accountId,
  marketplace,
  display,
  startDate,
  endDate,
}) => {
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [performance, setPerformance] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/account/profits', {
        params: {
          accountId,
          marketplace,
          startDate,
          endDate,
          attributes: [
            'orderCount',
            'orderItemCount',
            'unitCount',
            'unitsRefund',
            'totalRevenue',
            'principalRevenue',
            'taxRevenue',
            'shippingRevenue',
            'tax',
            'promotions',
            'totalFee',
            'giftwrapChargeback',
            'commission',
            'salesTaxCollectionFee',
            'fbaPerUnitFulfillmentFee',
            'variableClosingFee',
            'fbaWeightBasedFee',
            'fbaPerOrderFulfillmentFee',
            'shippingChargeback',
            'fixedClosingFee',
            'refunds',
            'advertisingCost',
            'sponsoredProductsCost',
            'sponsoredBrandsCost',
            'sponsoredDisplayCost',
            'cost',
            'profit',
            'margin',
            'previousTotalRevenue',
            'previousProfit',
            'sessions',
            'conversionRate',
            'pageViews',
            'promotionsRedeemed',
            'reimbursement',
            'productCogs',
            'shippingCogs',
            'miscellaneousCogs',
            'totalCogs',
          ],
        },
      });

      if (isSubscribed) {
        setPerformance(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate]);

  const RevenueDifference = ({ current = 0, previous = 0 }) => {
    const diff = current - previous;

    return (
      <div
        className={classNames(
          'text-13 leading-1.5 flex',
          diff >= 0 ? 'text-success-dark' : 'text-error-dark'
        )}
      >
        {diff >= 0 ? (
          <ChevronUpIcon className="w-5 h-5 mr-1" />
        ) : (
          <ChevronDownIcon className="w-5 h-5 mr-1" />
        )}
        {currencyFormatter(diff)}
      </div>
    );
  };

  return (
    <div className="border border-grayscale-500 rounded-xl">
      <PerformanceBreakdownModal
        open={openBreakdown}
        setOpen={setOpenBreakdown}
        data={performance}
      />

      <div className="flex justify-between border-b border-grayscale-500 px-4 py-6">
        <p className="text-grayscale-800 leading-1.5 font-bold">{display}</p>
        <p className="text-grayscale-700 leading-1.5 text-13">
          {startDate === endDate
            ? moment(startDate).format('MMMM DD, YYYY')
            : `${moment(startDate).format('MMMM DD, YYYY')}  -  ${moment(
                endDate
              ).format('MMMM DD, YYYY')}`}
        </p>
      </div>

      <div className="p-4">
        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-3">
            <p className="text-11 leading-1.5 text-grayscale-700">
              Gross Revenue
            </p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-600 text-lg">$</p>
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.totalRevenue)}
              </p>
            </div>
            <RevenueDifference
              current={performance.totalRevenue}
              previous={performance.previousTotalRevenue}
            />
          </div>

          <div className="col-span-2">
            <p className="text-11 leading-1.5 text-grayscale-700">Cost</p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-600 text-lg">$</p>
              <p className="text-grayscale-800 text-25">
                {numberFormatter(-1 * performance.cost)}
              </p>
            </div>
          </div>

          <div className="col-span-3">
            <p className="text-11 leading-1.5 text-grayscale-700 flex">
              Net Profit
              <span
                className="ml-1 cursor-pointer"
                onClick={() => setOpenBreakdown(true)}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.50008 0.616714C3.59008 0.616714 0.416748 3.79005 0.416748 7.70005C0.416748 11.61 3.59008 14.7834 7.50008 14.7834C11.4101 14.7834 14.5834 11.61 14.5834 7.70005C14.5834 3.79005 11.4101 0.616714 7.50008 0.616714ZM8.20842 12.6584H6.79175V11.2417H8.20842V12.6584ZM9.67467 7.1688L9.03717 7.82047C8.52717 8.33755 8.20842 8.76255 8.20842 9.82505H6.79175V9.47088C6.79175 8.69172 7.1105 7.98338 7.6205 7.4663L8.49883 6.5738C8.76091 6.3188 8.91675 5.96463 8.91675 5.57505C8.91675 4.79588 8.27925 4.15838 7.50008 4.15838C6.72091 4.15838 6.08342 4.79588 6.08342 5.57505H4.66675C4.66675 4.00963 5.93467 2.74171 7.50008 2.74171C9.0655 2.74171 10.3334 4.00963 10.3334 5.57505C10.3334 6.19838 10.0784 6.76505 9.67467 7.1688Z"
                    fill="#002F5D"
                  />
                </svg>
              </span>
            </p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-600 text-lg">$</p>
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.profit)}
              </p>
            </div>
            <RevenueDifference
              current={performance.profit}
              previous={performance.previousProfit}
            />
          </div>

          <div className="col-span-2">
            <p className="text-11 leading-1.5 text-grayscale-700">Margin</p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.margin * 100)}
              </p>
              <p className="text-grayscale-600 text-lg">%</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-8">
          <div className="col-span-1">
            <p className="text-11 leading-1.5 text-grayscale-700">Orders</p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.orderCount)}
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <p className="text-11 leading-1.5 text-grayscale-700">Units</p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.unitCount)}
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <p className="text-11 leading-1.5 text-grayscale-700">Refunds</p>
            <div className="font-inter leading-1.2 tracking-3/4 flex items-center">
              <p className="text-grayscale-800 text-25">
                {numberFormatter(performance.unitsRefund)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorePerformanceCard;
