import React, { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, array, boolean } from 'yup';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import Button from 'components/Button';
import {
  ExclamationIcon,
  PlusIcon,
  AnnotationIcon,
} from '@heroicons/react/solid';
import { useEffect, useMemo, useState } from 'react';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import useSubscription from 'hooks/useSubscription';
import { CANCELLED, EXPIRED } from 'utils/subscriptions';
import { fetchAddons } from 'features/zoho/zohoSlice';
import { currencyFormatter } from 'utils/formatters';
import { TrashIcon } from '@heroicons/react/outline';
import { isEmpty } from 'lodash';
import ButtonLink from 'components/ButtonLink';
import FormikErrorNotification from 'components/FormikErrorNotification';
import useAlert from 'hooks/useAlert';
import { fetchScheduledChanges, fetchSubscription } from '../subscriptionSlice';

const ChangeSubscriptionModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { addons } = useSelector((state) => state.zoho);
  const {
    subscription: { z: subscription },
    scheduledChanges,
  } = useSelector((state) => state.subscription);
  const status = useSubscription(subscription);
  const { alertSuccess, alertError } = useAlert();
  const [saving, setSaving] = useState(false);
  const forReactivation = useMemo(() => {
    return status.hasAny([EXPIRED, CANCELLED]);
  }, [status]);

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons());
    }
  }, [addons, dispatch]);

  const [initialValues, setInitialValues] = useState({
    plan_code: '',
    plan_description: '',
    price: 0,
    addons: [],
    apply_changes: forReactivation ? 'immediately' : 'end_of_this_term',
    auto_collect: true,
    card_id: '',
  });

  useEffect(() => {
    setInitialValues({
      plan_code: subscription.plan.plan_code,
      plan_description: subscription.plan.description,
      price: subscription.plan.price,
      addons: subscription.addons,
      apply_changes: forReactivation ? 'immediately' : 'end_of_this_term',
      auto_collect: subscription.auto_collect,
      card_id: subscription.card?.card_id,
    });
  }, [subscription]);

  const addonObj = {
    name: '',
    addon_description: '',
    addon_code: '',
    price: 0,
    quantity: 1,
    type: 'one_time',
  };

  const validationSchema = object().shape({
    plan_code: string().required(),
    plan_description: string().nullable(),
    price: number().min(0).required(),
    addons: array()
      .of(
        object().shape({
          addon_code: string().required('addon code is required'),
          name: string(),
          addon_description: string(),
          type: string().required(),
          quantity: number()
            .min(1, 'qty must be greater than or equal to 1')
            .required('required'),
          price: number().min(0).required('required'),
        })
      )
      .nullable(),
    apply_changes: string().required(),
    auto_collect: boolean(),
    card_id: string(),
  });

  const onAddonChange = (index, value, setFieldValue) => {
    const addon = addons.find((a) => a.addon_code === value);
    setFieldValue(`addons.${index}.addon_code`, value);
    setFieldValue(`addons.${index}.type`, addon.type);
    setFieldValue(`addons.${index}.name`, addon.name);
    setFieldValue(`addons.${index}.addon_description`, addon.description);
    setFieldValue(`addons.${index}.price`, addon.price_brackets[0].price);
  };

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      const response = await axios.put(
        `/subscriptions/${subscription.subscription_id}`,
        values
      );

      setOpen(false);
      alertSuccess('Changes saved', response.data.data.message);
      if (forReactivation) {
        dispatch(fetchSubscription(subscription.subscription_id));
      } else {
        dispatch(fetchScheduledChanges(subscription.subscription_id));
      }
    } catch (error) {
      alertError('Changes were not saved', error.response.data.message);
      console.log(error.response);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            forReactivation ? 'Reactivate Subscription' : 'Change Subscription'
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <div className="py-4 px-6 ">
          {forReactivation ? (
            <p className="col-span-3 bg-red-50 text-red-900 text-sm p-3">
              <AnnotationIcon className="w-6 h-6 inline text-red-600 mr-2" />
              Client will be charged and changes will be applied immediately.
            </p>
          ) : (
            <p className="col-span-3 bg-gray-50 text-gray-900 text-sm p-3">
              <AnnotationIcon className="w-6 h-6 inline text-blue-600 mr-2" />
              Changes will be applied starting on the next billing
            </p>
          )}

          {scheduledChanges?.code === 0 && (
            <p className="col-span-3 bg-yellow-50 text-gray-900 text-sm p-3">
              <ExclamationIcon className="w-6 h-6 inline text-yellow-600 mr-2" />
              This subscription has some scheduled changes. Those changes will
              be discarded if you update the subscription
            </p>
          )}

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              errors,
            }) => (
              <Form>
                <FormikErrorNotification />
                <div className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
                  <div className="col-span-2">
                    <Label htmlFor="price">
                      Price <RequiredAsterisk />
                    </Label>
                    <Field
                      name="price"
                      placeholder=""
                      className="form-input disabled-white"
                      onChange={(e) => handleChange(e)}
                      type="number"
                      required
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div className="col-span-2">
                    <Label htmlFor="plan_description">Description</Label>
                    <Field
                      name="plan_description"
                      as="textarea"
                      rows={3}
                      className="form-input"
                      maxLength={2000}
                    />
                  </div>
                  <div className="col-span-2">
                    <Label htmlFor="addons">Addons</Label>
                    <FieldArray name="addons">
                      {({ insert, remove, push }) => (
                        <>
                          {values.addons.length > 0 &&
                            values.addons.map((addon, index) => (
                              <div
                                key={`addon-${index}`}
                                className="grid grid-cols-12 gap-2 mb-3"
                              >
                                <div className="col-span-12 text-sm flex justify-between">
                                  <span className="font-bold text-gray-600">
                                    Addon {index + 1}
                                  </span>
                                  <button
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    className="text-gray-500 hover:text-red-700"
                                  >
                                    <TrashIcon className="w-4 h-4" />
                                  </button>
                                </div>
                                <div className="col-span-12 lg:col-span-5 text-sm">
                                  <Label>Addon</Label>
                                  <Field
                                    name={`addons.${index}.addon_code`}
                                    as="select"
                                    className="form-select disabled-white"
                                    onChange={(e) =>
                                      onAddonChange(
                                        index,
                                        e.target.value,
                                        setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">Select One</option>
                                    {addons?.map((addon) => (
                                      <option
                                        key={addon.addon_code}
                                        value={addon.addon_code}
                                      >
                                        {addon.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`addons.${index}.addon_code`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-6 lg:col-span-2 text-sm">
                                  <Label htmlFor="type">Type</Label>
                                  <div className="pl-3 py-2 capitalize">
                                    {values.addons[index].type}
                                  </div>
                                </div>
                                <div className="col-span-6 lg:col-span-2 text-sm">
                                  <Label>Qty</Label>
                                  <Field
                                    name={`addons.${index}.quantity`}
                                    className="form-input disabled-white"
                                    type="number"
                                    step="1"
                                  />
                                  <ErrorMessage
                                    name={`addons.${index}.quantity`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-6 lg:col-span-2 text-sm">
                                  <Label>Price</Label>
                                  <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                      <span className="text-gray-500 sm:text-sm">
                                        $
                                      </span>
                                    </div>
                                    <Field
                                      name={`addons.${index}.price`}
                                      className="form-input  pl-7 r-12"
                                      type="number"
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center">
                                      <Label
                                        htmlFor="currency_code"
                                        classes="sr-only"
                                      >
                                        Currency
                                      </Label>
                                      <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                                        {values.currency_code}
                                      </span>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name={`addons.${index}.price`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-6 lg:col-span-1 text-sm text-right flex flex-col justify-end">
                                  <Label>Total</Label>
                                  <span className="py-2 text-green-500 font-bold ">
                                    {currencyFormatter(
                                      values.addons[index].price *
                                        values.addons[index].quantity,
                                      values.currency_code
                                    )}
                                  </span>
                                </div>
                              </div>
                            ))}

                          <div className="col-span-2">
                            <Button
                              classes="font-bold tracking-wider w-full justify-center"
                              bgColor="gray-50"
                              hoverColor="gray-200"
                              roundedSize="2xl"
                              textColor="gray-600"
                              border="border-2 border-gray-600"
                              py={1}
                              shadow=""
                              onClick={(e) => {
                                push(addonObj);
                              }}
                            >
                              <PlusIcon className="w-4 h-4 inline" /> Addon
                            </Button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      {forReactivation ? 'Reactivate' : 'Apply Changes'}
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeSubscriptionModal;
