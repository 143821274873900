import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './Ribbon.css';
import classnames from 'classnames';
import Spinner from 'components/Spinner';
import {
  PrinterIcon,
  DownloadIcon,
  ChevronLeftIcon,
  SearchIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import {
  currencyFormatter,
  dateFormatter,
  decimalFormatter,
  strUnderscoreToSpace,
} from 'utils/formatters';
import { useDispatch } from 'react-redux';
import classNames from 'utils/classNames';
import useBodyClass from 'hooks/useBodyClass';
import Badge from 'components/Badge';
import useUpsell from 'hooks/useUpsell';
import useLoggedInUser from 'hooks/useLoggedInUser';
import PaymentForm from 'components/PaymentForm';
import useBraintreeUtils from 'hooks/useBraintreeUtils';
import amex from 'assets/icons/amex.svg';
import mastercard from 'assets/icons/mastercard.svg';
import visa from 'assets/icons/visa.svg';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import { Modal } from 'components';
import ModalHeader from 'components/ModalHeader';
import ConfirmOkOnly from 'components/ConfirmOkOnly';
import usePdf from 'hooks/usePdf';
import CreditsApplied from './components/CreditsApplied';
import PaymentsMade from './components/PaymentsMade';

const paymentModes = {
  existing: 'existing',
  new: 'new',
};

const InvoiceDetails = ({ upsellId }) => {
  useBodyClass(['client-profile', 'billing']);
  const { STATUS, isItemWalmartListingOptimization, isItemPaidReviews } =
    useUpsell();
  const { invoiceId } = useParams();
  const { user, agency } = useLoggedInUser();
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [usePaymentMode, setUsePaymentMode] = useState(null);
  const paymentFormRef = useRef();
  const [paymentFormError, setPaymentFormError] = useState(false);
  const [paying, setPaying] = useState(false);
  const { showTokenizeError } = useBraintreeUtils();
  const [upsell, setUpsell] = useState(null);
  const [openScope, setOpenScope] = useState(false);
  const [openSucessNotification, setOpenSucessNotification] = useState(false);
  const { onDownloadPdf, onPreviewPdf } = usePdf();

  useEffect(() => {
    setLoading(true);
    loadInvoice();
  }, [invoiceId]);

  const loadInvoice = async (force = false) => {
    setLoading(true);
    await axios
      .get(`/agency/invoices/${invoiceId}${force ? '?force=true' : ''}`)
      .then((res) => {
        setInvoice(res.data.data.invoice);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (upsellId) {
      axios.get(`/agency/upsell/${upsellId}?see=n`).then((res) => {
        setUpsell(res.data.data);
      });
    }
  }, [upsellId]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `/agency/clients/${user.defaultAgency.agencyClientId}/payment-methods/default/${user.defaultAgency.btCustomerId}`
        )
        .then((res) => {
          setDefaultPaymentMethod(res.data.data);
          setUsePaymentMode(
            res.data.data ? paymentModes.existing : paymentModes.new
          );
        });
    }
  }, [user]);

  const Th = ({ children, className }) => {
    return (
      <th
        scope="col"
        className={classNames(
          'px-4 py-2.5 text-sm font-bold capitalize text-grayscale-900',
          className ?? ''
        )}
      >
        {children}
      </th>
    );
  };

  const Td = ({ children, className }) => {
    return (
      <td
        className={classNames(
          'p-2 sm:p-4 text-sm text-gray-900',
          className ?? ''
        )}
      >
        {children}
      </td>
    );
  };

  const onPayment = async () => {
    setPaying(true);

    if (usePaymentMode === 'new') {
      let nonce = null;

      await paymentFormRef.current
        .tokenize()
        .then((response) => {
          nonce = response.nonce;
        })
        .catch((error) => {
          showTokenizeError(error);
          setPaying(false);
        });

      if (nonce && paymentFormRef.current.deviceData) {
        await axios
          .post(`/agency/invoices/${invoiceId}/process-payment`, {
            paymentMethodNonce: nonce,
            deviceData: paymentFormRef.current.deviceData,
            invoiceId,
            invoiceNumber: invoice.number,
            invoiceDate: invoice.invoice_date,
            client: user.defaultAgency,
            paymentMode: usePaymentMode,
            amount: invoice.balance,
            currencyCode: invoice.currency_code,
            ...(upsellId ? { upsellId } : {}),
          })
          .then((response) => {
            setOpenSucessNotification(true);
          })
          .catch((error) => {
            alertError('Something went wrong', error.response.data.message);
          })
          .finally(() => setPaying(false));
      }
    } else {
      await axios
        .post(`/agency/invoices/${invoiceId}/process-payment`, {
          invoiceId,
          invoiceNumber: invoice.number,
          invoiceDate: invoice.invoice_date,
          client: user.defaultAgency,
          paymentMode: usePaymentMode,
          amount: invoice.balance,
          currencyCode: invoice.currency_code,
          ...(upsellId ? { upsellId } : {}),
        })
        .then((response) => {
          setOpenSucessNotification(true);
        })
        .catch((error) => {
          alertError('Something went wrong', error.response.data.message);
        })
        .finally(() => setPaying(false));
    }
  };

  return !loading && invoice ? (
    <div className="lg:grid grid-cols-6 lg:space-x-6 max-w-6xl mx-auto">
      <div className="col-span-4 bg-white">
        <div className="px-5 pt-5 pb-16">
          <div className="flex items-center justify-between border-b pb-6 mb-4 w-full">
            <div className="flex items-center space-x-4 ">
              <Link to={upsellId ? '/additional-services' : '/invoice-history'}>
                <ChevronLeftIcon className="w-8 h-8 inline text-grayscale-700" />
              </Link>

              <h3 className="text-2xl font-bold text-grayscale-900">
                {invoice.number}
              </h3>
              <Badge
                color={classnames({
                  gray: invoice.status === 'void',
                  yellow:
                    invoice.status === STATUS.DRAFT ||
                    invoice.status === STATUS.PENDING ||
                    invoice.status === STATUS.SENT,
                  green: invoice.status === STATUS.PAID,
                  red: invoice.status === STATUS.OVERDUE,
                  purple: invoice.status === 'partially_paid',
                })}
                classes="capitalize text-center px-6 justify-center"
                rounded="full"
                padding="py-2"
                textSize="sm"
                fontFamily=""
              >
                {invoice.status === STATUS.SENT ||
                invoice.status === STATUS.DRAFT
                  ? 'Pending'
                  : strUnderscoreToSpace(invoice.status)}
              </Badge>
            </div>
            <div className="flex space-x-2">
              <button
                className="text-secondary hover:text-secondary-light"
                title="Print"
                onClick={() => onPreviewPdf(`agency/invoice/${invoiceId}/pdf`)}
              >
                <PrinterIcon className="w-5 h-5" />
              </button>
              <button
                className="text-secondary hover:text-secondary-light"
                title="Download PDF"
                onClick={() =>
                  onDownloadPdf(
                    `agency/invoice/${invoiceId}/pdf`,
                    invoice.number
                  )
                }
              >
                <DownloadIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="py-4">
            <CreditsApplied invoice={invoice} />
            <PaymentsMade invoice={invoice} />
          </div>
          <div className="sm:grid sm:grid-cols-4 sm:gap-3">
            <div className="self-end text-sm col-span-2 text-right sm:text-left mb-3 sm:mb-0">
              <span className="text-grayscale-700">Bill To</span>
              <p className="mt-4 mb-0">{invoice.customer_name}</p>
              {invoice.billing_address && (
                <>
                  <p className="mb-0 text-gray-900">
                    {invoice.billing_address.street}
                  </p>
                  <p className="mb-0 text-gray-900">
                    {invoice.billing_address.street2}
                  </p>
                  <p className="mb-0 text-gray-900">
                    {[
                      invoice.billing_address.city,
                      invoice.billing_address.state,
                      invoice.billing_address.zip,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                  <p className="mb-0 text-gray-900">
                    {invoice.billing_address.country}
                  </p>
                </>
              )}
            </div>
            <div className=" col-span-2 grid grid-cols-2 gap-3 text-sm text-right">
              <span className="col-span-1 text-grayscale-700">
                Invoice Date:
              </span>
              <span className="col-span-1">
                {dateFormatter(invoice.invoice_date)}
              </span>
              <span className="col-span-1 text-grayscale-700">Terms:</span>
              <span className="col-span-1">{invoice.payment_terms_label}</span>
              <span className="col-span-1 text-grayscale-700">Due Date:</span>
              <span className="col-span-1">
                {dateFormatter(invoice.due_date)}
              </span>
              <span className="col-span-1 text-grayscale-700">
                Reference #:
              </span>
              <span className="col-span-1 text-xs">{invoice.reference_id}</span>
            </div>
          </div>

          <div className="overflow-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed w-1024px mt-8">
              <thead className="bg-grayscale-500">
                <tr>
                  <Th className="text-left w-6/12">ITEM & DESCRIPTION</Th>
                  <Th className="text-right w-2/12">QTY</Th>
                  <Th className="text-right w-2/12">RATE</Th>
                  <Th className="text-right w-1/12">AMOUNT</Th>
                </tr>
              </thead>
              <tbody>
                {invoice.invoice_items &&
                  invoice.invoice_items.map((invoiceItem, index) => {
                    return (
                      <tr
                        className="bg-white border-b"
                        key={invoiceItem.item_id}
                      >
                        <Td>
                          {invoiceItem.name}
                          <br />
                          <span className="text-grayscale-700 text-xs whitespace-pre-wrap">
                            {invoiceItem.description}
                          </span>
                        </Td>
                        <Td className="text-right">{invoiceItem.quantity}</Td>
                        <Td className="text-right">
                          {decimalFormatter(invoiceItem.price)}
                        </Td>
                        <Td className="text-right">
                          {decimalFormatter(invoiceItem.item_total)}
                        </Td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="mt-2 sm:grid sm:grid-cols-4 sm:gap-3">
            <div className="col-span-2">&nbsp;</div>
            <div className="col-span-2">
              <div className="grid grid-cols-4 gap-y-5 text-sm text-right">
                <div className="col-span-2 sm:col-span-3 text-grayscale-700">
                  Sub Total
                </div>
                <div className="col-span-2 sm:col-span-1 pr-4">
                  {decimalFormatter(invoice.sub_total)}
                </div>

                {invoice.taxes &&
                  invoice.taxes.map((tax, i) => {
                    return (
                      <React.Fragment key={tax.tax_id}>
                        <div className="col-span-2 sm:col-span-3 text-grayscale-700">
                          {tax.tax_name}
                        </div>
                        <div className="col-span-2 sm:col-span-1 pr-4">
                          {currencyFormatter(
                            tax.tax_amount,
                            invoice.currency_code
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}

                <div className="col-span-2 sm:col-span-3 text-grayscale-700 font-bold">
                  Total
                </div>
                <div className="col-span-2 sm:col-span-1 font-medium pr-4">
                  {currencyFormatter(invoice.total, invoice.currency_code)}
                </div>
                {invoice.payment_made > 0 && (
                  <>
                    <div className="col-span-2 sm:col-span-3 text-grayscale-700">
                      Payment Made
                    </div>
                    <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                      (-) {decimalFormatter(invoice.payment_made)}
                    </div>
                  </>
                )}
                <div className="col-span-4 bg-grayscale-500 grid grid-cols-4 py-2">
                  <div className="col-span-2 sm:col-span-3 text-grayscale-900 font-bold uppercase">
                    Amount Due
                  </div>
                  <div className="col-span-2 sm:col-span-1 pr-4 text-grayscale-900 font-bold uppercase">
                    {currencyFormatter(invoice.balance, invoice.currency_code)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-2 ">
        <div className="py-5 bg-white">
          <div className="flex justify-between px-5 ">
            <h4 className="font-bold text-grayscale-900">Amount Due</h4>
            <div className="flex flex-col items-end">
              <div className="font-normal flex items-center">
                <span className="text-grayscale-600">$</span>&nbsp;
                <span className="text-grayscale-900 text-2xl font-bold">
                  {invoice.balance}
                </span>
              </div>
              <span className="text-xs">
                {upsellId ? 'Total Product Cost' : <>&nbsp;</>}
              </span>
            </div>
          </div>
          {upsellId && (
            <button
              onClick={() => setOpenScope(true)}
              className="w-full text-secondary-light font-bold tracking-widest text-sm py-6 flex items-center justify-center"
            >
              <SearchIcon className="w-3 h-3 inline mr-2" />
              View Scope of Service
            </button>
          )}

          <div className="border-t border-grayscale-500">&nbsp;</div>
          {invoice.status === 'paid' || invoice.status === 'void' ? (
            <h4 className="text-base font-bold text-green-600 tracking-widest text-center">
              {invoice.status === 'paid'
                ? 'Thanks for your payment'
                : 'Void Invoice'}
            </h4>
          ) : (
            <div className="px-5">
              {user.defaultAgency.paymentMode === 'braintree' &&
              user.defaultAgency.btCustomerId ? (
                <>
                  {defaultPaymentMethod && (
                    <div className="flex items-center justify-between border rounded-xl py-2 px-5 mb-5">
                      <label className="flex items-center space-x-3">
                        <input
                          value="existing"
                          name="paymentMode"
                          type="radio"
                          className="focus:outline-none focus:ring-0 h-6 w-6 text-green-500 border-gray-300"
                          checked={usePaymentMode === paymentModes.existing}
                          onChange={() =>
                            setUsePaymentMode(paymentModes.existing)
                          }
                        />
                        <span> Ending in {defaultPaymentMethod.last4}</span>
                      </label>
                      <img src={defaultPaymentMethod.imageUrl} />
                    </div>
                  )}
                  <div className="flex flex-col border rounded-xl pt-2 pb-4">
                    <div className="flex items-center justify-between px-5">
                      <label className="flex items-center space-x-3">
                        {defaultPaymentMethod && (
                          <input
                            value="new"
                            name="paymentMode"
                            type="radio"
                            className="focus:outline-none focus:ring-0 h-6 w-6 text-green-500 border-gray-300"
                            checked={usePaymentMode === paymentModes.new}
                            onChange={() => setUsePaymentMode(paymentModes.new)}
                          />
                        )}
                        <span>Use A Different Card</span>
                      </label>
                      <div className="flex items-center space-x-1.5 flex-wrap">
                        <img
                          src={visa}
                          alt="visa"
                          className="object-fill w-7 h-6"
                        />
                        <img
                          src={mastercard}
                          alt="mastercard"
                          className="object-fill w-7 h-6"
                        />
                        <img
                          src={amex}
                          alt="amex"
                          className="object-fill w-6 h-5"
                        />
                      </div>
                    </div>
                    <div className="border-t border-grayscale-500 mt-4">
                      &nbsp;
                    </div>
                    <div
                      className={classNames(
                        'px-5',
                        usePaymentMode === paymentModes.new
                          ? ''
                          : 'pointer-events-none opacity-20'
                      )}
                    >
                      <PaymentForm
                        ref={paymentFormRef}
                        setError={setPaymentFormError}
                      />
                    </div>
                  </div>
                  <div className="my-8 flex space-x-1 justify-around">
                    <div className="flex items-center justify-center space-x-1">
                      <svg
                        width="17"
                        height="20"
                        viewBox="0 0 19 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.46986 0.166016L0.25 4.10541V10.0145C0.25 15.4804 4.18381 20.5918 9.46986 21.8327C14.7559 20.5918 18.6897 15.4804 18.6897 10.0145V4.10541L9.46986 0.166016ZM16.6409 10.0145C16.6409 14.466 13.5881 18.5728 9.46986 19.794C5.35166 18.5728 2.29886 14.466 2.29886 10.0145V5.38571L9.46986 2.32283L16.6409 5.38571V10.0145ZM4.76773 10.5956L3.32329 11.9842L7.421 15.9236L15.6164 8.0448L14.172 6.64632L7.421 13.1365L4.76773 10.5956Z"
                          fill="#949494"
                        />
                      </svg>
                      <span className="text-grayscale-700 leading-1.5 text-sm">
                        Secured checkout
                      </span>
                    </div>
                    <div className="flex justify-center space-x-3">
                      <a
                        href={`https://www.braintreegateway.com/merchants/${process.env.REACT_APP_BT_MERCHANT_ID}/verified`}
                        target="_blank"
                      >
                        <img
                          src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                          width="150px"
                          border="0"
                        />
                      </a>
                    </div>
                  </div>
                  <Button
                    onClick={onPayment}
                    classes="w-full tracking-widest items-flex justify-center"
                    showLoading={true}
                    loading={paying}
                    px={5}
                    py={2.5}
                    bgColor="custom-green"
                    hoverColor="green-700"
                    fontWeight="bold"
                    roundedSize="full"
                    textSize="lg"
                  >
                    Pay {invoice.currency_code} {invoice.balance}
                  </Button>
                </>
              ) : (
                <div className="flex flex-col text-center space-y-2">
                  <h4 className="font-bold text-primary">
                    Invalid payment method
                  </h4>
                  <span className="text-xs text-grayscale-800">
                    Please update your payment method in the&nbsp;
                    <Link to="/plan" className="text-secondary-light underline">
                      Plan
                    </Link>
                    &nbsp;page
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {upsell && (
        <Modal
          open={openScope}
          setOpen={setOpenScope}
          as={'div'}
          align="middle"
          zIndex="z-50"
        >
          <div className="max-w-3xl inline-block w-full my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl">
            <ModalHeader
              title="Scope of Services"
              setOpen={setOpenScope}
              border=""
              titleClasses="tracking-wider"
              fontSize="text-3xl"
              fontStyle="font-bold"
              px="px-4 md:px-8"
              py="py-4 md:py-8"
            />
            <div className="mx-8 mb-10 mt-4 border border-grayscale-500 rounded-xl">
              <div className="p-5 flex justify-between">
                <h3 className="font-bold text-grayscale-900 text-2xl w-1/2">
                  {upsell.details.name}
                  <p className="font-normal text-grayscale-700 text-xs">
                    {upsell.details.description}
                  </p>
                </h3>
                <div className="flex flex-col items-end">
                  <div className="font-normal flex items-center">
                    <span className="text-grayscale-600">$</span>&nbsp;
                    <span className="text-grayscale-900 text-2xl font-bold">
                      {invoice.total}
                    </span>
                  </div>
                  <span className="text-xs text-grayscale-800">
                    {upsellId ? 'Total Product Cost' : <>&nbsp;</>}
                  </span>
                </div>
              </div>
              <div className="p-5">
                <h4 className="font-bold text-grayscale-900 text-xl">
                  {isItemPaidReviews(upsell.details.name)
                    ? 'ASINs to review'
                    : isItemWalmartListingOptimization(upsell.details.name)
                    ? 'Service Items'
                    : 'Service ASINs'}
                  &nbsp;Included:
                </h4>

                <div className="grid gap-4 grid-cols-4">
                  {upsell.serviceAsins.map((item) => (
                    <>
                      <div className="col-span-1 flex flex-col">
                        <h5 className="text-grayscale-900 font-inter tracking-3/4 whitespace-nowrap text-ellipsis overflow-hidden">
                          {item.asin}
                        </h5>
                        <span className="text-xs text-grayscale-700">
                          {isItemWalmartListingOptimization(upsell.details.name)
                            ? 'Item'
                            : 'ASIN'}
                        </span>
                      </div>
                      {isItemPaidReviews(upsell.details.name) && (
                        <>
                          <div className="col-span-1 flex flex-col">
                            <h5 className="text-grayscale-900 font-inter tracking-3/4">
                              {item.qty}
                            </h5>
                            <span className="text-xs text-grayscale-700">
                              No. of Reviews
                            </span>
                          </div>
                          <div className="col-span-1 flex flex-col text-right">
                            <h5 className="text-grayscale-900 font-inter tracking-3/4 text-xl">
                              <sup className="text-xs">USD</sup>{' '}
                              {item.price.toFixed(2)}
                            </h5>
                            <span className="text-xs text-grayscale-700">
                              ASIN Price
                            </span>
                          </div>
                          <div className="col-span-1 flex flex-col text-right">
                            <h5 className="text-grayscale-900 font-inter tracking-3/4 text-xl">
                              <sup className="text-xs">USD</sup>{' '}
                              {item.productCost.toFixed(2)}
                            </h5>
                            <span className="text-xs text-grayscale-700">
                              USD {item.price}&nbsp;x&nbsp;
                              {item.qty}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <ConfirmOkOnly
        open={openSucessNotification}
        setOpen={setOpenSucessNotification}
        titleText={
          <div className="flex flex-col text-center">
            <div className=" text-custom-green">
              <span className="border-2 border-custom-green rounded-full py-3.5 px-0.5">
                <CheckIcon className="w-12 h-12 inline" />
              </span>
            </div>
            <h3 className="text-2xl font-bold mt-4">
              Your payment has been processed
            </h3>
          </div>
        }
        okText="Done"
        maxWidth="max-w-xl"
        onOkClick={() => loadInvoice(true)}
      >
        <p className="text-lg text-grayscale-900 py-6 font-inter leading-tight">
          You can view your final invoice in your Better Seller Account, on the
          Invoice History&nbsp;
          <b>PAID</b> tab, we will also send your copy of the invoice to your
          email
        </p>
      </ConfirmOkOnly>
    </div>
  ) : (
    <Spinner />
  );
};
export default InvoiceDetails;
