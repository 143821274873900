import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { startCase } from 'lodash';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

const PerformanceBreakdownModal = ({ open, setOpen, data = {} }) => {
  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-sm my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title="Breakdown"
            fontSize="text-lg"
            fontStyle="font-bold"
            titleClasses="font-inter tracking-3/4 text-grayscale-900 leading-1.2"
            setOpen={setOpen}
            showCloseButton={true}
            border=""
          />

          <div className="py-4 px-4">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4">
                    <div className="flex items-center">
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-grayscale-700`}
                      />
                      <p className="leading-1.5 text-grayscale-800">
                        Gross Revenue
                      </p>
                    </div>
                    <p className="leading-1.5 text-success-dark">
                      {currencyFormatter(data.totalRevenue)}
                    </p>
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                    {[
                      { key: 'principalRevenue', display: 'Baseline' },
                      { key: 'taxRevenue', display: 'Tax' },
                      { key: 'shippingRevenue', display: 'Shipping' },
                    ].map((breakdown) => (
                      <div
                        key={`performance-breakdown-${breakdown.key}-revenue`}
                        className="flex justify-between"
                      >
                        <p className="text-grayscale-800 leading-1.5">
                          {breakdown.display}
                        </p>
                        <p className="text-success-dark leading-1.5">
                          {currencyFormatter(data[breakdown.key])}
                        </p>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <div className="w-full border-b border-t h-1 mt-2 border-grayscale-600"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-800">Reimbursements</p>
              <p className="leading-1.5 text-success-dark">
                {currencyFormatter(data.reimbursement)}
              </p>
            </div>

            <div className="w-full border-b border-grayscale-500"></div>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4 mt-2">
                    <div className="flex items-center">
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-grayscale-700`}
                      />
                      <p className="leading-1.5 text-grayscale-800">
                        Amazon Fees
                      </p>
                    </div>
                    <p className="leading-1.5 text-error-dark">
                      {currencyFormatter(data.totalFee)}
                    </p>
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                    {[
                      'fbaPerOrderFulfillmentFee',
                      'fbaPerUnitFulfillmentFee',
                      'fbaWeightBasedFee',
                      'variableClosingFee',
                      'commission',
                      'fixedClosingFee',
                      'salesTaxCollectionFee',
                      'shippingChargeback',
                      'giftwrapChargeback',
                    ].map((breakdown) => (
                      <div
                        key={`performance-breakdown-${breakdown}-fees`}
                        className="flex justify-between"
                      >
                        <p className="text-grayscale-800 leading-1.5">
                          {startCase(breakdown)}
                        </p>
                        <p className="text-error-dark leading-1.5">
                          {currencyFormatter(data[breakdown])}
                        </p>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <div className="w-full border-b mt-2 border-grayscale-500"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-800">Refunds</p>
              <p className="leading-1.5 text-success-dark">
                {currencyFormatter(data.refunds)}
              </p>
            </div>

            <div className="w-full border-b border-grayscale-500"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-800">Promo Value</p>
              <p className="leading-1.5 text-success-dark">
                {currencyFormatter(data.promotions)}
              </p>
            </div>

            <div className="w-full border-b border-grayscale-500"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-900 font-bold ml-10">
                Est. Value
              </p>
              <p className="leading-1.5 text-success-dark">
                {currencyFormatter(
                  data.promotions +
                    data.refunds +
                    data.reimbursement +
                    data.totalFee +
                    data.totalRevenue
                )}
              </p>
            </div>

            <div className="w-full border-b border-t h-1 mt-2 border-grayscale-600"></div>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4 mt-2">
                    <div className="flex items-center">
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-grayscale-700`}
                      />
                      <p className="leading-1.5 text-grayscale-800">
                        Total COGS
                      </p>
                    </div>
                    <p className="leading-1.5 text-error-dark">
                      {currencyFormatter(data.totalCogs)}
                    </p>
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                    {[
                      { key: 'productCogs', display: 'Product Cost' },
                      { key: 'shippingCogs', display: 'Shipping Cost' },
                      {
                        key: 'miscellaneousCogs',
                        display: 'Miscellaneous Cost',
                      },
                    ].map((breakdown) => (
                      <div
                        key={`performance-breakdown-${breakdown.key}-cogs`}
                        className="flex justify-between"
                      >
                        <p className="text-grayscale-800 leading-1.5">
                          {breakdown.display}
                        </p>
                        <p className="text-error-dark leading-1.5">
                          {currencyFormatter(data[breakdown.key])}
                        </p>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <div className="w-full border-b mt-2 border-grayscale-500"></div>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4 mt-2">
                    <div className="flex items-center">
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-grayscale-700`}
                      />
                      <p className="leading-1.5 text-grayscale-800">PPC Cost</p>
                    </div>
                    <p className="leading-1.5 text-error-dark">
                      {currencyFormatter(data.advertisingCost)}
                    </p>
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                    {[
                      {
                        key: 'sponsoredProductsCost',
                        display: 'Sponsored Products',
                      },
                      {
                        key: 'sponsoredBrandsCost',
                        display: 'Sponsored Brands',
                      },
                      {
                        key: 'sponsoredDisplayCost',
                        display: 'Sponsored Display',
                      },
                    ].map((breakdown) => (
                      <div
                        key={`performance-breakdown-${breakdown.key}-advertising-cost`}
                        className="flex justify-between"
                      >
                        <p className="text-grayscale-800 leading-1.5">
                          {breakdown.display}
                        </p>
                        <p className="text-error-dark leading-1.5">
                          {currencyFormatter(data[breakdown.key])}
                        </p>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <div className="w-full border-b mt-2 border-grayscale-500"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-800">Tax</p>
              <p className="leading-1.5 text-error-dark">
                {currencyFormatter(data.tax)}
              </p>
            </div>

            <div className="w-full border-b border-t h-1 border-grayscale-600"></div>

            <div className="flex justify-between pl-5 py-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-900 font-bold ml-10">
                Net Profit
              </p>
              <p className="leading-1.5 text-success-dark">
                {currencyFormatter(data.profit)}
              </p>
            </div>

            <div className="flex justify-between pl-5 pb-2 text-13 tracking-3/4">
              <p className="leading-1.5 text-grayscale-900 font-bold ml-10">
                Margin
              </p>
              <p className="leading-1.5 text-success-dark">
                {percentageFormatter(data.margin)}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PerformanceBreakdownModal;
