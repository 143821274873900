import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { InformationCircleIcon } from '@heroicons/react/solid';

import Button from 'components/Button';

import { setAlert } from 'features/alerts/alertsSlice';
import { getMyselfAsync } from 'features/auth/authSlice';

const HubstaffSyncInfo = ({ history, hubstaffProfile, className = '' }) => {
  const dispatch = useDispatch();
  const [disconnecting, setDisconnecting] = useState(false);

  const onDisconnect = () => {
    setDisconnecting(true);
    axios
      .post('/hubstaff/revoke')
      .then(() => {
        dispatch(getMyselfAsync());
        dispatch(setAlert('success', 'Hubstaff Integration Revoked'));
        history.push('/user-settings/hubstaff');
      })
      .catch(() => {
        dispatch(setAlert('error', 'Failed to revoke Hubstaff'));
      })
      .finally(() => setDisconnecting(false));
  };

  return (
    <div
      className={`bg-blue-50 rounded-xl flex justify-between px-4 text-blue-800 ${className}`}
    >
      <div className="flex items-center">
        <InformationCircleIcon className="w-5 h-5 mr-1" />
        <p className="text-13 font-inter">
          {hubstaffProfile.activitiesLastSynced
            ? `Last time sync: ${moment(
                hubstaffProfile.activitiesLastSynced
              ).fromNow()}`
            : 'Please wait while we sync your hubstaff account.'}
        </p>
      </div>

      <Button
        classes="my-2 font-inter tracking-3/4 text-11"
        showLoading={true}
        loading={disconnecting}
        onClick={onDisconnect}
      >
        Disconnect
      </Button>
    </div>
  );
};

export default withRouter(HubstaffSyncInfo);
