import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';

import {
  ClientSummary,
  MonthlyRetainers,
  UpsellValue,
  TeamBreakdown,
} from './components';

import useChurn from 'features/churn/useChurn';
import { useUsers } from 'features/users';
import { useRoles } from 'features/roles';

const Clients = () => {
  const { calculateChurn } = useChurn();
  const { PROJECT_MANAGER } = useRoles();

  const { fetchAllUsers, users, selectedUser, setSelectedUser } = useUsers();

  const [year, setYear] = useState(moment().format('YYYY'));
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});

  useEffect(() => {
    fetchAllUsers({ 'role.code': PROJECT_MANAGER.value });
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(`/v2/agency/clients/analytics/summary`, {
        params: { startDate, endDate },
      });

      if (isSubscribed) {
        setSummary(calculateChurn(response.data.data));
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  return (
    <div className="space-y-4">
      <div className="grid items-center lg:grid-cols-2">
        <h4 className="text-lg font-inter text-grayscale-900 leading-1.2 tracking-3/4 font-bold">
          Partner Count
        </h4>

        <div className="flex justify-end space-x-4">
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
          >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </select>

          <select
            value={selectedUser.data?.userId ?? ''}
            onChange={(e) =>
              setSelectedUser({
                data: users.data.find((user) => user.userId === e.target.value),
              })
            }
            className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
          >
            <option value="">All Teams</option>
            {users.data.map((user) => (
              <option key={user.userId} value={user.userId}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 gap-8">
        <ClientSummary
          total={(summary?.totalClients ?? 0) + (summary?.inactiveClients ?? 0)}
          loading={loading}
          records={[
            {
              name: 'Active Partners',
              value: summary?.totalClients ?? 0,
              color: '#00BA88',
            },
            {
              name: 'Inactive Partners',
              value: summary?.inactiveClients ?? 0,
              color: '#FF6868',
            },
          ]}
        />

        <TeamBreakdown
          year={year}
          supervisorId={selectedUser.data?.userId ?? ''}
        />
      </div>

      <MonthlyRetainers
        year={year}
        assigneeId={selectedUser.data?.userId ?? ''}
      />

      <UpsellValue year={year} assigneeId={selectedUser.data?.userId ?? ''} />
    </div>
  );
};

export default Clients;
