import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import classNames from 'utils/classNames';

const Navigation = () => {
  const { url } = useRouteMatch();

  const tabs = [
    {
      name: 'New Partners',
      href: `/partners/new`,
      visible: true,
      exact: true,
    },
    {
      name: 'Existing Partners',
      href: `/partners/existing`,
      visible: true,
      exact: true,
    },
    {
      name: 'Team Level',
      href: `/partners/team-level`,
      visible: true,
      exact: true,
    },
  ];
  return (
    <div className="border-b border-grayscale-500 mt-4">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs
          .filter((tab) => tab.visible)
          .map((tab) => (
            <NavLink
              to={tab.href}
              key={tab.name}
              exact={tab.exact}
              className={classNames(
                'flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent',
                url === tab.href ? 'text-secondary-light' : 'text-grayscale-800'
              )}
              activeClassName="border-secondary-light font-bold text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
      </nav>
    </div>
  );
};

export default Navigation;
