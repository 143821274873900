import axios from 'axios';
import { startCase, take, chunk } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MonthlyReportHeader from '../analytics/components/MonthlyReportHeader';
import MonthlyKPIs from './components/MonthlyKPIs';
import ActiveCampaignsOverview from './components/ActiveCampaignsOverview';
import MostImprovedProductAds from './components/MostImprovedProductAds';
import MostImprovedTargetings from './components/MostImprovedTargetings';
import CampaignTypesOverview from './components/CampaignTypesOverview';
import TableSummary from 'features/clients/Client/Account/Advertising/Trends/pages/Breakdown/components/TableSummary';

const DetailedAnalytics = () => {
  const { reportId } = useParams();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  useEffect(() => {
    setLoading(true);

    axios.get(`/account/advertising/reports/${reportId}`).then((response) => {
      setReport(response.data.data);
      setLoading(false);
    });
  }, [reportId]);
  return loading ? (
    'Loading...'
  ) : (
    <div className="bg-grayscale-400 h-full">
      <div style={{ width: '210mm', height: '297mm' }} className="mx-auto">
        <MonthlyReportHeader
          startDate={report.startDate}
          endDate={report.endDate}
          client={report.client.client}
        />

        <MonthlyKPIs data={report.data.overallPerformance} />

        <ActiveCampaignsOverview
          className="mt-8"
          data={report.data.activeCampaigns}
        />

        <CampaignTypesOverview
          campaignTypes={report.data.campaignTypesOverview}
        />

        <div className="pt-5">
          <div className="flex items-center justify-between px-8 pt-6 pb-2">
            <p className="text-lg font-bold text-grayscale-900 tracking-3/4">
              Weekly Breakdown
            </p>
          </div>

          <TableSummary
            textSize={9}
            loading={false}
            records={take(report.data.breakdown, 5)}
            tableClassName="w-full"
            className="overflow-x-auto w-full px-5"
            fullTitle={false}
            dataPadding="py-4"
            dataBgColor="none"
            attributes={[
              'impressions',
              'clicks',
              'ctr',
              'cpc',
              'cr',
              'sales',
              'cost',
              'acos',
              'tacos',
              'unitsSold',
              'advertisingSalesPercentage',
              'organicUnitsSold',
              'organicSalesPercentage',
              'totalSales',
            ]}
          />
        </div>
      </div>

      {report.data?.breakdown?.length > 5 &&
        chunk(
          report.data?.breakdown.filter((item, idx) => idx > 4),
          16
        ).map((item, idx) => (
          <div
            key={`weekly-breakdown-table-chunk-${idx}`}
            style={{ width: '210mm', height: '297mm' }}
            className="mx-auto pagebreak"
          >
            <MonthlyReportHeader
              startDate={report.startDate}
              endDate={report.endDate}
              client={report.client.client}
            />

            <div className="pt-5">
              <div className="flex items-center justify-between px-8 pt-6 pb-2">
                <p className="text-lg font-bold text-grayscale-900 tracking-3/4">
                  Weekly Breakdown
                </p>
              </div>

              <TableSummary
                textSize={9}
                loading={false}
                records={item}
                tableClassName="w-full"
                className="overflow-x-auto w-full px-5"
                fullTitle={false}
                dataPadding="py-4"
                dataBgColor="none"
                attributes={[
                  'impressions',
                  'clicks',
                  'ctr',
                  'cpc',
                  'cr',
                  'sales',
                  'cost',
                  'acos',
                  'tacos',
                  'unitsSold',
                  'advertisingSalesPercentage',
                  'organicUnitsSold',
                  'organicSalesPercentage',
                  'totalSales',
                ]}
              />
            </div>
          </div>
        ))}

      <div
        style={{ width: '210mm', height: '297mm' }}
        className="mx-auto pagebreak"
      >
        <MonthlyReportHeader
          startDate={report.startDate}
          endDate={report.endDate}
          client={report.client.client}
        />

        <MostImprovedProductAds productAds={report.data.topProductAds} />
        <MostImprovedTargetings productAds={report.data.topTargetings} />
      </div>

      <div
        style={{ width: '210mm', height: '297mm' }}
        className="pagebreak mx-auto px-8 pt-12"
      >
        {['challenge', 'solution', 'results', 'futurePlanOfAction'].map(
          (attr) => {
            return (
              <div key={attr} className="mb-5 text-grayscale-900">
                <p className="mb-2 font-bold tracking-3/4 text-base font-inter">
                  {startCase(attr)}
                </p>
                <div className="border text-8 p-3 rounded pb-8 whitespace-pre-line">
                  {report.data[attr]}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default DetailedAnalytics;
