import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOutAsync } from './authSlice';
import AuthLayout from 'layouts/auth/AuthLayout';
import logo from 'assets/logos/logo-orange.png';
import Loading from 'components/Loading';

const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOutAsync());
  }, [dispatch]);

  return (
    <AuthLayout>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto rounded-md"
          src={logo}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Agency Dashboard
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex justify-between">
          Signing out...
          <Loading />
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignOut;
