import axios from 'axios';
import moment from 'moment';
import { groupBy } from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchNotifications = createAsyncThunk(
  'notifications/fetch',
  async (params = {}) => {
    const response = await axios.get('/notifications', { params });
    return response.data;
  }
);

export const fetchUnreadNotificationsCount = createAsyncThunk(
  'notifications/fetchUnreadCount',
  async () => {
    const response = await axios.get('/notifications', {
      params: { pageSize: 1, scopes: ['unread'] },
    });
    return response.data.data.count;
  }
);

export const markAllAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async () => {
    const response = await axios.post('/notifications/read-all');
    return response.data;
  }
);

export const markAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async (notificationId) => {
    const response = await axios.post(`/notifications/${notificationId}/read`);
    return response.data;
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    unreadCount: 0,
    list: { rows: [], count: 0 },
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, { payload }) => {
      state.list = payload.data;
    },
    [markAllAsRead.fulfilled]: (state) => {
      state.unreadCount = 0;
      state.list = {
        ...state.list,
        rows: state.list.rows.map((row) => {
          return { ...row, readAt: moment().format() };
        }),
      };
    },
    [fetchUnreadNotificationsCount.fulfilled]: (state, { payload }) => {
      state.unreadCount = payload;
    },
  },
});

export const selectGroupedNotifications = (state) =>
  groupBy(
    state.notifications.list.rows.map((row) => {
      return {
        ...row,
        date: moment(row.createdAt).tz(moment.tz.guess()).calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: 'MMMM D, YYYY',
          sameElse: 'MMMM D, YYYY',
          sameElse: 'MMMM D, YYYY',
        }),
      };
    }),
    'date'
  );

export default notificationsSlice.reducer;
