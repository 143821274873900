import axios from 'axios';
import {
  PARTNER_TYPES,
  TYPES,
  ROADMAP_TYPES,
  ONBOARDING_TYPE,
  RECURRING_TYPE,
  ROADMAP_TYPE,
  ONE_TIME_TYPE,
} from './constants';
import { useRoles } from 'features/roles';

const useTaskManagement = () => {
  const { departments: roleDepartments, subDepartments } = useRoles();

  const DEPARTMENTS = [
    subDepartments.SI_OPERATIONS_SUB_DEPARTMENT,
    subDepartments.SI_PPC_SUB_DEPARTMENT,
    subDepartments.SI_WRITING_SUB_DEPARTMENT,
    subDepartments.SI_DESIGN_SUB_DEPARTMENT,
    subDepartments.SI_ADMIN_SUB_DEPARTMENT,
    roleDepartments.BILLING_DEPARTMENT,
    roleDepartments.SALES_DEPARTMENT,
    roleDepartments.LEAD_GENERATION_DEPARTMENT,
  ];

  const getTemplate = async (templateId) => {
    return await axios.get(`/v2/tasks/lists/templates/${templateId}`);
  };

  const updateTemplate = async (templateId, data) => {
    return await axios.put(`/v2/tasks/lists/templates/${templateId}`, data);
  };

  return {
    getTemplate,
    updateTemplate,

    ROADMAP_TYPE,
    ONE_TIME_TYPE,
    RECURRING_TYPE,
    ONBOARDING_TYPE,
    TYPES,
    PARTNER_TYPES,
    ROADMAP_TYPES,
    DEPARTMENTS,
  };
};

export default useTaskManagement;
