import { currencyFormatter, numberFormatter } from 'utils/formatters';

const TeamSummary = ({ summary }) => {
  const CARDS = [
    {
      title: 'Total Accounts',
      key: 'totalAssignedActiveClients',
      formatter: numberFormatter,
    },
    {
      title: 'Monthly Retainer',
      key: 'totalAssignedActiveRecurringClientsMonthlyRetainer',
      formatter: currencyFormatter,
    },
    {
      title: 'Term Clients',
      key: 'totalAssignedTerminatedClients',
      formatter: numberFormatter,
    },
    {
      title: 'ASINs',
      key: 'totalAssignedActiveClientsAsins',
      formatter: numberFormatter,
    },
  ];

  return (
    <div className="grid grid-cols-4 gap-4">
      {CARDS.map((card) => (
        <div className="bg-white p-6" key={card.key}>
          <p className="text-grayscale-800 leading-1.5">{card.title}</p>
          <p className="font-inter font-bold tracking-1 text-grayscale-900 leading-1.2 text-32 mt-4">
            {card.formatter(summary[card.key])}
          </p>
        </div>
      ))}
    </div>
  );
};

export default TeamSummary;
