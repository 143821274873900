import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';
import { Table } from 'components';
import PerformanceBreakdownModal from './PerformanceBreakdownModal';

const StorePerformanceMatrix = ({ accountId, marketplace }) => {
  const [loading, setLoading] = useState(false);
  const [matrix, setMatrix] = useState([]);
  const [breakdownData, setBreakdownData] = useState({});
  const [openBreakdown, setOpenBreakdown] = useState(false);

  const dates = [
    {
      display: 'Yesterday',
      startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    {
      display: '7 Days',
      startDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    {
      display: '30 Days',
      startDate: moment().subtract(31, 'day').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const records = await Promise.all(
        dates.map(async ({ startDate, endDate, display }) => {
          const response = await axios.get('/account/profits', {
            params: {
              accountId,
              marketplace,
              startDate,
              endDate,
              attributes: [
                'orderCount',
                'orderItemCount',
                'unitCount',
                'unitsRefund',
                'totalRevenue',
                'principalRevenue',
                'taxRevenue',
                'shippingRevenue',
                'tax',
                'roi',
                'promotions',
                'totalFee',
                'giftwrapChargeback',
                'commission',
                'salesTaxCollectionFee',
                'fbaPerUnitFulfillmentFee',
                'variableClosingFee',
                'fbaWeightBasedFee',
                'fbaPerOrderFulfillmentFee',
                'shippingChargeback',
                'fixedClosingFee',
                'refunds',
                'advertisingCost',
                'sponsoredProductsCost',
                'sponsoredBrandsCost',
                'sponsoredDisplayCost',
                'cost',
                'profit',
                'margin',
                'sessions',
                'conversionRate',
                'pageViews',
                'promotionsRedeemed',
                'reimbursement',
                'productCogs',
                'shippingCogs',
                'miscellaneousCogs',
                'totalCogs',
                'sessions',
                'previousTotalRevenue',
                'previousProfit',
                'previousOrderCount',
                'previousUnitCount',
                'previousRoi',
                'previousMargin',
                'previousSessions',
              ],
            },
          });

          return { ...response.data.data, startDate, endDate, display };
        })
      );

      if (isSubscribed) {
        setMatrix(records);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace]);

  const columns = [
    {
      sort: false,
      dataField: 'display',
      text: 'Period',
      headerStyle: { minWidth: '275px' },
      classes: 'sticky left-0 z-10 bg-white py-3 px-2',
      headerClasses:
        'sticky left-0 z-10 bg-grayscale-200 border-b border-grayscale-500 text-13 text-grayscale-600 leading-1.5 text-left py-4 px-2',
      formatter: (cell, row) => {
        return (
          <div>
            <p className="text-grayscale-800 leading-1.5">{cell}</p>
            <p className="text-grayscale-700 leading-1.5 text-11">
              {row.startDate} - {row.endDate}
            </p>
          </div>
        );
      },
    },
    ...[
      {
        key: 'totalRevenue',
        formatter: currencyFormatter,
        display: 'Gross Revenue',
      },
      {
        key: 'profit',
        formatter: currencyFormatter,
        display: 'Net Profit',
      },
      {
        key: 'orderCount',
        formatter: numberFormatter,
        display: 'Orders',
      },
      {
        key: 'unitCount',
        formatter: numberFormatter,
        display: 'Units',
      },
      {
        key: 'roi',
        formatter: percentageFormatter,
        display: 'ROI',
      },
      {
        key: 'margin',
        formatter: percentageFormatter,
        display: 'Margin',
      },
      {
        key: 'unitsRefund',
        formatter: numberFormatter,
        display: 'Refund',
      },
      {
        key: 'pageViews',
        formatter: numberFormatter,
        display: 'Page Views',
      },
      {
        key: 'sessions',
        formatter: numberFormatter,
        display: 'Sessions',
      },
    ].map((metric) => {
      return {
        dataField: metric.key,
        text: metric.display,
        sort: false,
        headerStyle: { minWidth: '120px' },
        headerClasses:
          'bg-grayscale-200 border-b border-grayscale-500 text-13 text-grayscale-600 leading-1.5 text-right py-4 px-2',
        formatter: (cell) => {
          return (
            <div className="text-right">
              <p className="leading-1.5 text-grayscale-800">
                {metric.formatter(cell)}
              </p>
            </div>
          );
        },
      };
    }),
    {
      dataField: 'startDate',
      text: 'Action',
      sort: false,
      headerStyle: { minWidth: '75px' },
      headerClasses:
        'bg-grayscale-200 border-b border-grayscale-500 text-13 text-grayscale-600 leading-1.5 text-right py-4 px-2',
      classes: 'flex justify-center items-center px-4 py-3',
      formatter: (cell, row) => {
        return (
          <span
            id={cell}
            className="ml-1 cursor-pointer"
            onClick={() => {
              setBreakdownData(row);
              setOpenBreakdown(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50008 0.616714C3.59008 0.616714 0.416748 3.79005 0.416748 7.70005C0.416748 11.61 3.59008 14.7834 7.50008 14.7834C11.4101 14.7834 14.5834 11.61 14.5834 7.70005C14.5834 3.79005 11.4101 0.616714 7.50008 0.616714ZM8.20842 12.6584H6.79175V11.2417H8.20842V12.6584ZM9.67467 7.1688L9.03717 7.82047C8.52717 8.33755 8.20842 8.76255 8.20842 9.82505H6.79175V9.47088C6.79175 8.69172 7.1105 7.98338 7.6205 7.4663L8.49883 6.5738C8.76091 6.3188 8.91675 5.96463 8.91675 5.57505C8.91675 4.79588 8.27925 4.15838 7.50008 4.15838C6.72091 4.15838 6.08342 4.79588 6.08342 5.57505H4.66675C4.66675 4.00963 5.93467 2.74171 7.50008 2.74171C9.0655 2.74171 10.3334 4.00963 10.3334 5.57505C10.3334 6.19838 10.0784 6.76505 9.67467 7.1688Z"
                fill="#002F5D"
              />
            </svg>
          </span>
        );
      },
    },
  ];

  return (
    <div className="mt-10">
      <p className="text-grayscale-900 tracking-3/4 font-inter font-bold leading-1.2 mb-5">
        Store Performance Matrix
      </p>

      <PerformanceBreakdownModal
        open={openBreakdown}
        setOpen={setOpenBreakdown}
        data={breakdownData}
      />

      <Table
        columns={columns}
        data={{ rows: matrix }}
        params={{}}
        keyField="display"
        loading={loading}
        showPagination={false}
      />
    </div>
  );
};

export default StorePerformanceMatrix;
