import { Card } from 'components';
import { DocumentTextIcon } from '@heroicons/react/outline';
import {
  currencyFormatter,
  dateFormatter,
  strUnderscoreToSpace,
} from 'utils/formatters';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Spinner from 'components/Spinner';
import classnames from 'classnames';
import Badge from 'components/Badge';
import ZohoPlanAddonsTable from './billing/components/ZohoPlanAddonsTable';
import ScheduledChangesModal from './ScheduledChangesModal';
import useSubscription from 'hooks/useSubscription';
import { CANCELLED, EXPIRED, NON_RENEWING } from 'utils/subscriptions';
import PaymentMethodManager from 'components/Billing/PaymentMethod/PaymentMethodManager';
import useLoggedInUser from 'hooks/useLoggedInUser';
import SwitchPaymentModal from './SwitchPaymentModal';

const Plan = () => {
  const { user, currentAccount } = useLoggedInUser();
  const [subscription, setSubscription] = useState({});
  const status = useSubscription(subscription);
  const [scheduledChanges, setScheduledChanges] = useState({});
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSwitchModal, setIsOpenSwitchModal] = useState(false);

  useEffect(() => {
    if (currentAccount) {
      setLoading(true);

      axios
        .get(`/subscriptions/${currentAccount.subscription.subscriptionId}`)
        .then((res) => {
          setSubscription(res.data.data.z);
          setScheduledChanges(res.data.data.sc);
          setLoading(false);
        });
    }
  }, [currentAccount]);

  const Heading = ({ children, className }) => {
    return (
      <div
        className={`mb-2 border-b text-gray-700 uppercase ${className ?? ''}`}
      >
        {children}
      </div>
    );
  };

  const Row = ({ children, label }) => {
    return (
      <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {children}
        </dd>
      </div>
    );
  };

  return loading || !currentAccount ? (
    <Spinner />
  ) : (
    <>
      <h1 className="text-3xl tracking-wide font-bold mb-6">Plan</h1>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4">
        <div className="sm:col-span-1 space-y-5">
          <Card>
            <Heading>Details</Heading>
            <Row label="Subscription Number">
              #{subscription.subscription_number}
            </Row>
            <Row label="Status">
              <Badge
                color={classnames({
                  green: status.live(),
                  gray: status.nonRenewing(),
                  yellow: status.cancelled(),
                  red: status.expired(),
                  blue: status.paused(),
                })}
                classes="uppercase"
                rounded="md"
              >
                {strUnderscoreToSpace(subscription.status)}
              </Badge>
            </Row>
            <Row label="Activation Date">
              {dateFormatter(subscription.activated_at)}
            </Row>

            <Heading className="mt-4">Billing Address</Heading>

            {subscription.customer.billing_address && (
              <>
                <span className=" block text-md text-gray-500">
                  {subscription.customer.billing_address.street}
                </span>
                {subscription.customer.billing_address.street2 && (
                  <span className=" block text-md text-gray-500">
                    {subscription.customer.billing_address.street2}
                  </span>
                )}
                <span className=" block text-md text-gray-500">
                  {[
                    subscription.customer.billing_address.city,
                    subscription.customer.billing_address.state,
                    subscription.customer.billing_address.zip,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </span>
                <span className=" block text-md text-gray-500">
                  {subscription.customer.billing_address.country}
                </span>
              </>
            )}

            {(user.defaultAgency.paymentMode === 'zoho' ||
              user.defaultAgency.paymentMode === 'offline') && (
              <>
                <Heading className="mt-4">Payment Method</Heading>
                {user.defaultAgency.paymentMode === 'zoho' &&
                  (subscription.card ? (
                    <>
                      <Row label="Card Number">
                        {subscription.card.last_four_digits}&nbsp;
                        <em className="text-gray-400 text-xs">
                          (last four digits)
                        </em>
                      </Row>
                      <Row label="Expires On">
                        {subscription.card.expiry_month}&nbsp;/&nbsp;
                        {subscription.card.expiry_year}
                      </Row>
                      <Row label="Gateway">
                        {subscription.card.payment_gateway}
                      </Row>
                      <Row label="Funding">{subscription.card.funding}</Row>
                    </>
                  ) : (
                    <span className="text-red-500 text-sm">
                      No Payment Method
                    </span>
                  ))}

                {user.defaultAgency.paymentMode === 'offline' && (
                  <span className="text-red-500 text-sm">OFFLINE MODE</span>
                )}

                <div className="mb-8 text-center">
                  <button
                    onClick={() => setIsOpenSwitchModal(true)}
                    className="inline-flex justify-center items-center mt-4 px-12 py-2 border-2 border-secondary tracking-widest font-inter border-transparent shadow-sm text-sm font-medium rounded-full text-secondary bg-white hover:bg-grayscale-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light"
                  >
                    Update Payment Method
                  </button>
                </div>

                <SwitchPaymentModal
                  open={isOpenSwitchModal}
                  setOpen={setIsOpenSwitchModal}
                  subscription={subscription}
                />
              </>
            )}

            <Heading className="mt-4">Subscription Options</Heading>
            <Row label="Subscription ID">{subscription.subscription_id}</Row>
          </Card>

          {!!user.defaultAgency.btCustomerId &&
            currentAccount.AgencyClient?.paymentMode === 'braintree' && (
              <Card overflowHidden={false}>
                <PaymentMethodManager
                  agencyClientId={user.defaultAgency.agencyClientId}
                  canCreate={true}
                  canEdit={true}
                />
              </Card>
            )}
        </div>

        <div className="sm:col-span-2">
          <Card>
            <div className="sm:grid sm:grid-cols-5 sm:gap-4 sm:px-5">
              <div className="flex flex-col sm:px-5 pb-2">
                <span className="text-sm text-gray-500">
                  Subscription Amount
                </span>
                <span>{currencyFormatter(subscription.amount)}</span>
              </div>
              <div className="flex flex-col sm:px-5 pb-2">
                <span className="text-sm text-gray-500">Next Billing Date</span>
                {status.live() && (
                  <span>{dateFormatter(subscription.next_billing_at)}</span>
                )}
                {status.hasAny([NON_RENEWING, EXPIRED, CANCELLED]) ? (
                  <span>-</span>
                ) : (
                  ''
                )}
              </div>
              <div className="flex flex-col sm:px-5 pb-2">
                <span className="text-sm text-gray-500">Last Billing Date</span>
                <span>{dateFormatter(subscription.last_billing_at)}</span>
              </div>
              <div className="flex flex-col sm:px-5 pb-2">
                {status.live() && (
                  <>
                    {subscription.remaining_billing_cycles < 0 ? (
                      <span className="text-sm text-green-500">&nbsp;</span>
                    ) : (
                      <>
                        <span className="text-sm text-gray-500">
                          Renewals remaining
                        </span>
                        <span className="text-sm text-gray-500">
                          Expires on {dateFormatter(subscription.expires_at)}
                        </span>
                      </>
                    )}
                  </>
                )}
                {status.nonRenewing() && (
                  <>
                    <span className="text-sm text-yellow-500">
                      Cancellation Date
                    </span>
                    {dateFormatter(subscription.scheduled_cancellation_date)}
                  </>
                )}
                {status.cancelled() && (
                  <>
                    <span className="text-sm text-yellow-500">
                      Cancelled Date
                    </span>
                    {dateFormatter(subscription.cancelled_at)}
                  </>
                )}
              </div>

              <div className="flex flex-col sm:px-5 pb-2">
                {currentAccount.acknowledgement && (
                  <div className="bg-green-100 text-green-600 flex rounded-3xl w-40 text-sm items-center py-1.5 px-2 space-x-2 justify-start">
                    <Link
                      className="text-red-500 hover:text-red-700"
                      to={`/proposal/view/${currentAccount.AgencyClient.sales.salesClientId}`}
                      title="Profile"
                    >
                      <DocumentTextIcon className="m-1 h-5 w-5" color="green" />
                    </Link>
                    <span>View Contract</span>
                  </div>
                )}
              </div>
            </div>

            {scheduledChanges && scheduledChanges.code === 0 && (
              <ScheduledChangesModal
                open={isOpen}
                setOpen={setIsOpen}
                scheduledChanges={scheduledChanges}
              />
            )}
            <div className="sm:p-5">
              <ZohoPlanAddonsTable subscription={subscription} />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Plan;
