import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import { fetchTaxes, setTaxes } from './billingSlice';
import DataTable from 'components/DataTable';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import usePermissions from 'hooks/usePermissions';

const Taxes = () => {
  const { userCan } = usePermissions();
  const { taxes } = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { alertSuccess, alertError } = useAlert();

  useEffect(() => {
    if (!taxes) {
      dispatch(fetchTaxes());
    }
  }, [taxes]);

  const columns = [
    { accessorKey: 'name' },
    { accessorKey: 'authority' },
    { header: 'Rate(%)', accessorKey: 'percentage' },
  ];

  const onSync = async () => {
    setLoading(true);
    await axios
      .post(`/billing/taxes/import`)
      .then((response) => {
        alertSuccess('sync successful', response.data.message);
        dispatch(setTaxes(response.data.data));
      })
      .catch((error) => {
        alertError('sync failed', error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-4">
        <h1 className="text-3xl tracking-wide font-bold">Taxes</h1>
        {userCan('billing.taxes.sync') && (
          <button
            type="button"
            className="flex items-center px-4 tracking-widest rounded-3xl py-1.5 border-2 font-bold border-gray-600 text-sm text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            onClick={onSync}
            disabled={loading}
          >
            <CloudDownloadIcon className="h-4 w-4 mr-2" /> Sync with Zoho{' '}
            {loading && <SpinnerGrow />}
          </button>
        )}
      </div>
      <div className="mt-6 flex flex-col max-w-xl">
        {taxes && <DataTable data={taxes} columns={columns} />}
      </div>
    </div>
  );
};
export default Taxes;
