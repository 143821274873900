import React from 'react';
import Navigation from '../../components/Navigation';

import {
  MonthlyCount,
  MonthToMonthGrowth,
  ClientCountPerService,
} from './components';

const NewPartners = () => {
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex space-x-4 items-center">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Partners
          </h2>
        </div>
      </div>

      <Navigation />

      <div className="space-y-8 mt-8">
        <div className="grid xl:grid-cols-2 gap-8">
          <MonthlyCount />

          <MonthToMonthGrowth />
        </div>

        <ClientCountPerService />
      </div>
    </div>
  );
};

export default NewPartners;
