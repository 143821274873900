import PercentageChange from '../PercentageChange';
import MetricCardFooter from './MetricCardFooter';
import MetricLoading from './MetricLoading';

const MetricCard = ({
  title,
  value,
  formatter,
  previousValue = 0,
  loading = true,
  prefixValue = '',
  suffixValue = '',
  dividendTitle = '',
  dividendValue = '',
  divisorTitle = '',
  divisorValue = '',
  reversePercentage = false,
  showPercentage = true,
  showCalculation = true,
  className = 'bg-white py-5 rounded-3xl bs-shadow h-44',
}) => {
  return loading ? (
    <MetricLoading />
  ) : (
    <div className={className}>
      <div className="border-grayscale-500 border-b pb-2">
        <div>
          <p className="text-center text-tiny text-grayscale-700 leading-1.5">
            {title}
          </p>
          <div className="font-inter leading-1.2 tracking-3/4 flex items-center justify-center">
            {prefixValue && (
              <p className="text-xl text-grayscale-600">{prefixValue}</p>
            )}

            <p className="text-25 text-grayscale-900 ">{formatter(value)}</p>

            {suffixValue && (
              <p className="text-xl text-grayscale-600">{suffixValue}</p>
            )}
          </div>
        </div>

        {showPercentage && (
          <PercentageChange
            currentValue={value}
            previousValue={previousValue}
            reverse={reversePercentage}
          />
        )}
      </div>

      {showCalculation && (
        <MetricCardFooter
          dividendTitle={dividendTitle}
          dividendValue={dividendValue}
          divisorTitle={divisorTitle}
          divisorValue={divisorValue}
        />
      )}
    </div>
  );
};

export default MetricCard;
