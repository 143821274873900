import {
  useFloating,
  arrow,
  FloatingArrow,
  offset,
  shift,
  flip,
  useTransitionStyles,
  autoUpdate,
} from '@floating-ui/react';
import { useRef, useState } from 'react';
import classNames from 'utils/classNames';

const ToolTip = ({
  title,
  children,
  arrowOptions = { width: 15, height: 7.5 },
  arrowFill = 'white',
  bg = 'bg-white',
  placement = 'right',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(arrowOptions.height),
      flip({ padding: 5 }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + arrowOptions.width / 2;
  const transformY = arrowY + arrowOptions.height;

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: 'scale(0)',
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${arrowOptions.height}px)`,
        bottom: `${transformX}px ${-arrowOptions.height}px`,
        left: `calc(100% + ${arrowOptions.height}px) ${transformY}px`,
        right: `${-arrowOptions.height}px ${transformY}px`,
      }[side],
    }),
  });

  return (
    <>
      <button
        ref={refs.setReference}
        className="reference"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </button>
      {isMounted && (
        <div ref={refs.setFloating} style={floatingStyles} className="z-100">
          <div
            style={styles}
            className={classNames(
              bg,
              'floating max-h-80 shadow-lg rounded-lg p-4 overflow-y-auto'
            )}
          >
            {children}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={arrowOptions.width}
              height={arrowOptions.height}
              fill={arrowFill}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ToolTip;
