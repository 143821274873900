import { useEffect } from 'react';
import classNames from 'utils/classNames';

const TabNav = ({
  tabs,
  setTabs,
  onSelectChange,
  onClick,
  theme = 'default',
  displayName = false,
  tabClasses,
}) => {
  useEffect(() => {
    let filteredTabs = tabs.filter((tab) => tab.visible !== false);
    filteredTabs[0].current = true;
    if (theme === 'tabbed') {
      let myTabs = [...filteredTabs];
      let currentTab = myTabs.find((t) => t.current === true);
      let i = myTabs.findIndex((t) => t.current === true);

      currentTab.className = 'current';

      if (myTabs[i - 1]) {
        myTabs[i - 1].className = 'tail-right';
      } else {
        currentTab.className = 'current left';
      }

      if (myTabs[i + 1]) {
        myTabs[i + 1].className = 'tail-left';
      } else {
        currentTab.className = 'current right';
      }
      if (myTabs[i - 1] && myTabs[i + 1]) {
        currentTab.className = 'current middle';
      }
      setTabs(myTabs);
    }
  }, [theme]);

  const onClickFilter = (e, tab, i) => {
    e.preventDefault();

    let myTabs = [...tabs];

    if (theme === 'tabbed') {
      myTabs = [...tabs].map((element) => ({
        ...element,
        className: '',
      }));
    }

    let currentTab = myTabs.find((t) => t.current === true);
    currentTab.current = false;
    let selectedTab = myTabs.find((t) =>
      t.value ? t.value === tab.value : t.name === tab.name
    );
    selectedTab.current = true;

    if (theme === 'tabbed') {
      selectedTab.className = 'current';

      if (myTabs[i - 1]) {
        myTabs[i - 1].className = 'tail-right';
      } else {
        selectedTab.className = 'current left';
      }

      if (myTabs[i + 1]) {
        myTabs[i + 1].className = 'tail-left';
      } else {
        selectedTab.className = 'current right';
      }

      if (myTabs[i - 1] && myTabs[i + 1]) {
        selectedTab.className = 'current middle';
      }
    }
    setTabs(myTabs);
    onClick(selectedTab);
  };

  return (
    <div className={classNames('tabnav', theme)}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={onSelectChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>
              {displayName ? tab.displayName : tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="tab-container">
          <nav aria-label="Tabs">
            {tabs.map((tab, i) => (
              <button
                key={tab.name}
                type="button"
                className={classNames('tab-btn', tabClasses, tab.className)}
                aria-current={tab.current ? 'page' : undefined}
                onClick={(e) => onClickFilter(e, tab, i)}
                disabled={!tab.visible}
              >
                {tab.displayName ?? tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current || tab.countRed
                        ? 'bg-red-100 text-red-600'
                        : 'bg-gray-100 text-gray-900',
                      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TabNav;
