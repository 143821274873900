const useTermination = () => {
  const STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending',
    DENIED: 'denied',
    CANCELLED: 'cancelled',
  };

  const REASONS = [
    'PPC Issues',
    'Communication Issues',
    'Design Issues',
    'Writing Issues',
    'Poor Sales Growth',
    'Amazon Listing Restriction',
    'Lack of Reporting/Strategies',
    'Not profitable - No ROI with SI',
    'SI Terminated',
    'Change of Agency',
    'Ceasing Amazon Channel',
    'SI Delays',
    'Created In-house Team',
    'Contract Fulfilled',
  ];

  const RETENTION_EFFORT_CHECKLIST = [
    'Provided a seamless onboarding process.',
    'Established a regular communication schdule.',
    'Addressed and resolve issues promptly.',
    'Reached out to clients with solutions before they encounter problems.',
    'Schedule regular check-in calls or meetings to discuss their progress, challenges, and goals.',
  ];

  return { REASONS, STATUS, RETENTION_EFFORT_CHECKLIST };
};

export default useTermination;
