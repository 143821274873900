import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Bar,
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import { currencyFormatter, numberFormatter } from 'utils/formatters';

const StoreTrends = ({ accountId, marketplace }) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const dates = [
    {
      display: 'This Month',
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    {
      display: 'Previous Month',
      startDate: moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const data = await Promise.all(
        dates.map(async ({ startDate, endDate, display }) => {
          const response = await axios.get('/account/profits/records', {
            params: {
              accountId,
              marketplace,
              startDate,
              endDate,
              attributes: ['unitCount', 'profit'],
            },
          });

          return response.data.data;
        })
      );

      if (isSubscribed) {
        const [thisMonth, previousMonth] = data;

        const records = thisMonth.map((item, idx) => {
          return {
            profit: item.profit,
            unitCount: item.unitCount,
            startDate: moment(item.startDate).format('MMM D'),
            previousProfit: previousMonth[idx].profit,
            previousUnitCount: previousMonth[idx].unitCount,
          };
        });

        setRecords(records);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace]);

  return (
    <div className="mt-10">
      <p className="text-grayscale-700 text-13 leading-1.5">This Month</p>
      <p className="text-grayscale-900 tracking-3/4 font-inter font-bold leading-1.2 mb-5">
        Store Trends
      </p>

      <div className="grid grid-cols-2 mt-5 gap-8">
        {[
          {
            display: 'Unit Sold',
            current: { key: 'unitCount', color: '#00BA88' },
            previous: { key: 'previousUnitCount', color: '#00966D' },
            formatter: numberFormatter,
          },
          {
            display: 'Net Profit',
            current: { key: 'profit', color: '#1879D8' },
            previous: { key: 'previousProfit', color: '#002F5D' },
            formatter: currencyFormatter,
          },
        ].map((metric) => {
          return (
            <div
              key={`store-trends-${metric.display}`}
              className="border border-grayscale-500 rounded-xl"
            >
              <div className="flex justify-between border-b border-grayscale-500 px-4 py-6">
                <p className="text-grayscale-800 leading-1.5 font-bold">
                  {metric.display}
                </p>
                <div className="flex text-11 text-grayscale-800">
                  <div className="flex items-center mr-4">
                    <div
                      style={{ backgroundColor: metric.current.color }}
                      className="w-5 h-5 mr-1"
                    ></div>
                    <p className="">This Month</p>
                  </div>

                  <div className="flex items-center">
                    <div
                      style={{ backgroundColor: metric.previous.color }}
                      className="w-5 h-5 mr-1"
                    ></div>
                    <p className="">Previous Month</p>
                  </div>
                </div>
              </div>

              <div className="p-4 h-64">
                <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
                  <BarChart data={records}>
                    <CartesianGrid vertical={false} horizontal={false} />
                    <XAxis
                      dataKey="startDate"
                      style={{ fontSize: '11px', fontWeight: 400 }}
                    />

                    <YAxis
                      style={{ fontSize: '11px' }}
                      tickFormatter={(value) => metric.formatter(value)}
                    />

                    <Bar
                      dataKey={metric.current.key}
                      fill={metric.current.color}
                    />
                    <Bar
                      dataKey={metric.previous.key}
                      fill={metric.previous.color}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreTrends;
