import { Avatar } from 'components';
import {
  currencyFormatter,
  getNameInitials,
  numberFormatter,
} from 'utils/formatters';

const TeamOverview = ({ brandStrategist = {} }) => {
  return (
    <div className="bg-white mt-8 rounded-xl grid grid-cols-2 lg:grid-cols-3 p-3 lg:p-8">
      <div className="flex space-x-3 items-center pb-5">
        <Avatar
          size="52px"
          imageSrc={brandStrategist?.avatar?.thumbnailUrl}
          initials={getNameInitials(
            brandStrategist?.firstName,
            brandStrategist?.lastName
          )}
        />

        <p className="font-inter text-13 lg:text-base font-bold leading-1.2 tracking-3/4 text-grayscale-900">
          {brandStrategist?.firstName}'s Team Level
        </p>
      </div>

      <div className="p-5 border-l lg:border-b border-grayscale-500">
        <p className="text-13 leading-1.5 text-grayscale-700">Total Account</p>
        <p className="text-grayscale-800 font-inter tracking-1 leading-1.2 text-4xl">
          {numberFormatter(brandStrategist?.totalClients)}
        </p>
      </div>

      <div className="p-5 border-b border-t lg:border-t-0 lg:border-l border-grayscale-500">
        <p className="text-13 leading-1.5 text-grayscale-700">
          Total Terminated Clients
        </p>
        <p className="text-grayscale-800 font-inter tracking-1 leading-1.2 text-4xl">
          {numberFormatter(brandStrategist?.totalTerminatedClients)}
        </p>
      </div>

      <div className="p-5 border-b border-t border-l lg:border-l-0 lg:border-r lg:border-b-0 border-grayscale-500">
        <p className="text-13 leading-1.5 text-grayscale-700">
          Total Clients MR
        </p>
        <p className="text-grayscale-800 font-inter tracking-1 leading-1.2 text-4xl">
          {currencyFormatter(brandStrategist?.assignedClientsMonthlyRevenue)}
        </p>
      </div>

      <div className="p-5">
        <p className="text-13 leading-1.5 text-grayscale-700">Total ASINs</p>
        <p className="text-grayscale-800 font-inter tracking-1 leading-1.2 text-4xl">
          {numberFormatter(brandStrategist?.totalServiceAsins)}
        </p>
      </div>

      <div className="p-5 border-l border-grayscale-500">
        <p className="text-13 leading-1.5 text-grayscale-700">Workload</p>
        <p className="text-grayscale-800 font-inter tracking-1 leading-1.2 text-4xl">
          67
        </p>
      </div>
    </div>
  );
};

export default TeamOverview;
