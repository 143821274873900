import axios from 'axios';
import { getNameInitials } from 'utils/formatters';
import { useRef, useState } from 'react';
import CommentEditor from 'components/Editor/CommentEditor';
import { useParams } from 'react-router-dom';
import { getAttachments } from '../leadsSlice';
import { useSelector } from 'react-redux';
import CommentOptions from 'components/Editor/CommentOptions';

const Comment = ({ data, onChangeData, onDelete, getAttachmentLink }) => {
  const { all: employees } = useSelector((state) => state.employees);
  const [edit, setEdit] = useState(false);
  const cRef = useRef();

  // const { leadId } = useParams();
  //   const salesClientId = '4a2bd93f-10b5-4d9f-8dd5-38cc1af6de0e';

  const togglePinComment = (action) => {
    axios
      .post(
        `/agency/leads/clients/${data.leadId}/comments/${data.leadsCommentId}/${action}`
      )
      .then(() => onChangeData(false));
  };

  const onSaveComment = async (payload) => {
    return axios
      .put(
        `/agency/leads/clients/${data.leadId}/comments/${data.leadsCommentId}`,
        payload
      )
      .then(() => {
        setEdit(false);
        onChangeData(false);
      });
  };

  return (
    <div className="comment flex justify-between">
      <div className="bg-red-500 flex-shrink-0 text-white w-10 h-10 rounded-3xl text-base uppercase text-center leading-10 font-bold">
        {getNameInitials(data?.creator?.firstName, data?.creator?.lastName)}
      </div>
      <div className="ml-3 bg-white rounded-lg flex-1 shadow-sm px-4 py-2 group max-w-lg w-3/4">
        <CommentOptions
          data={data}
          onEdit={setEdit}
          togglePinComment={togglePinComment}
          onDelete={onDelete}
        />
        {edit ? (
          <div>
            <CommentEditor
              qRef={cRef}
              setOpen={setEdit}
              isEditMode={true}
              editCommentValue={data?.structure}
              employees={employees}
              uploaderOptions={{
                attachmentKey: 'leadsAttachmentId',
                getAttachmentLink: getAttachmentLink,
                primaryKey: { key: 'leadId', value: data.leadId },
                postUrl: '/agency/leads/attachments',
                onRefreshList: getAttachments({
                  params: {
                    page: 1,
                    pageSize: 10,
                    sort: 'dateUploaded:desc',
                    leadId: data?.leadId,
                  },
                }),
              }}
              onSave={onSaveComment}
            />
          </div>
        ) : (
          <div
            className="text-sm pb-2 text-gray-800 overflow-x-auto"
            dangerouslySetInnerHTML={{
              __html: data?.content,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Comment;
