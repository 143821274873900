import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import metrics from '../../../../utils/metrics';

import { upperFirst } from 'lodash';
import {
  Area,
  XAxis,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

const chartStyle = {
  fontSize: '11px',
  color: '#666666',
};

const attributes = ['clicks', 'sales', 'cost', 'orders', 'impressions', 'acos'];

const ChartDisplay = ({
  accountId,
  marketplace,
  date,
  additionalParams = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState({ current: [], previous: [] });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const data = await Promise.all(
        [
          {
            key: 'current',
            startDate: moment(date).format('YYYY-MM-DD'),
            endDate: moment(date).endOf('month').format('YYYY-MM-DD'),
          },
          {
            key: 'previous',
            startDate: moment(date).subtract(1, 'month').format('YYYY-MM-DD'),
            endDate: moment(date)
              .subtract(1, 'month')
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
        ].map(async (record) => {
          const response = await axios.get(
            `/account/advertising/analytics/week`,
            {
              params: {
                accountId,
                marketplace,
                startDate: record.startDate,
                endDate: record.endDate,
                attributes,
                ...additionalParams,
              },
            }
          );

          return response.data.data;
        })
      );

      if (isSubscribed) {
        const [current, previous] = data;
        let formattedRecords = [];

        for (let index = 0; index < 4; index++) {
          let obj = { date: `Week ${index + 1}` };

          attributes.forEach((attr) => {
            obj[attr] = current[index][attr];
            obj[`previous${upperFirst(attr)}`] = previous[index][attr];
          });

          formattedRecords.push(obj);
        }

        setRecords(formattedRecords);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, date, additionalParams]);

  return (
    <div className="grid grid-cols-2 gap-8 mt-20">
      {attributes
        .map((attr) => metrics.find((m) => m.key === attr))
        .map((metric) => (
          <div key={`trends-snapshot-chart-display-${metric.key}`}>
            <div className="flex justify-between">
              <p className="font-inter font-bold tracking-3/4 text-grayscale-900 leading-1.2">
                {metric.title}
              </p>
              <div className="flex text-grayscale-900 text-tiny leading-1.5">
                <div className="flex items-center">
                  <div className="w-5 mr-1 h-1 bg-success"></div>
                  <p>Current</p>
                </div>

                <div className="flex items-center ml-3">
                  <div className="w-5 mr-1 h-1 bg-secondary-light"></div>
                  <p>Previous</p>
                </div>
              </div>
            </div>

            <div className="w-full h-48 mt-2">
              {loading ? (
                <div className="bg-grayscale-200 h-full rounded-xl text-grayscale-800 animate-pulse font-inter text-lg tracking-2 font-bold flex w-full items-center justify-center">
                  <p className="text-center">Loading Chart Data</p>
                </div>
              ) : (
                <ResponsiveContainer className="w-full" height="100%">
                  <ComposedChart className="w-full" data={records}>
                    <defs>
                      <linearGradient
                        id={`${metric.key}Color`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="10%"
                          stopColor="#00BA88"
                          stopOpacity={0.25}
                        />
                        <stop
                          offset="50%"
                          stopColor="#00BA88"
                          stopOpacity={0.05}
                        />
                      </linearGradient>
                    </defs>

                    <defs>
                      <linearGradient
                        id={`previous${upperFirst(metric.key)}Color`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="10%"
                          stopColor="#1879D8"
                          stopOpacity={0.25}
                        />
                        <stop
                          offset="50%"
                          stopColor="#1879D8"
                          stopOpacity={0.05}
                        />
                      </linearGradient>
                    </defs>

                    <XAxis dataKey="date" style={chartStyle} axisLine={false} />

                    <Tooltip
                      content={
                        <CustomTooltip
                          containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl w-48"
                          colorId="color"
                        />
                      }
                    />

                    <Area
                      yAxisId={'right'}
                      type="linear"
                      dataKey={metric.key}
                      strokeWidth={2}
                      stroke="#00BA88"
                      fillOpacity={1}
                      fill={`url(#${metric.key}Color)`}
                      dot={{ fill: '#00BA88' }}
                      isAnimationActive={false}
                      formatter={metric.rawFormatter}
                      name="Current Month"
                    />

                    <Area
                      yAxisId={'right'}
                      type="linear"
                      dataKey={`previous${upperFirst(metric.key)}`}
                      strokeWidth={2}
                      stroke="#1879D8"
                      fillOpacity={1}
                      fill={`url(#previous${upperFirst(metric.key)}Color)`}
                      dot={{ fill: '#1879D8' }}
                      isAnimationActive={false}
                      name="Previous Month"
                      formatter={metric.rawFormatter}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChartDisplay;
