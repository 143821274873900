import dot from 'dot-object';
import { isNull } from 'lodash';

const ActivityLogChangeItem = ({
  values,
  attribute,
  previousValues,
  nullDisplay = 'None',
  changeCustomizer = '',
}) => {
  let prevValue = isNull(previousValues[attribute])
    ? nullDisplay
    : previousValues[attribute];

  let value = isNull(values[attribute]) ? nullDisplay : values[attribute];

  prevValue = changeCustomizer
    ? dot.dot(previousValues)[changeCustomizer.key]
    : prevValue;

  value = changeCustomizer ? dot.dot(values)[changeCustomizer.key] : value;

  value = changeCustomizer
    ? dot.dot(values)[changeCustomizer.key]
    : values[attribute];

  const changedAttribute = changeCustomizer
    ? changeCustomizer.display
    : attribute;

  return (
    <li className="text-11 text-grayscale-600 leading-none ml-8">
      {`Change ${changedAttribute} from "${prevValue}" to "${value}"`}
    </li>
  );
};

export default ActivityLogChangeItem;
