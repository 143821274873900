import axios from 'axios';
import moment from 'moment';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import './funnel.scss';
import './print.scss';

import MonthlyKPIs from './components/MonthlyKPIs';
import TopProducts from './components/TopProducts';
import ExternalLink from './components/ExternalLink';
import TopCampaigns from './components/TopCampaigns';
import BrandedKeywords from './components/BrandedKeywords';
import TotalMonthlyOrders from './components/TotalMonthlyOrders';
import MonthlyReportHeader from './components/MonthlyReportHeader';
import MonthlyReportCharts from './components/MonthlyReportCharts';

const MonthlyReport = () => {
  const { reportId } = useParams();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});
  const [monthlyRecords, setMonthlyRecords] = useState([]);

  useEffect(() => {
    setLoading(true);
    let monthlyScope = [];

    for (let index = 12; index > 0; index--) {
      const startDate = moment()
        .subtract(index, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');

      const endDate = moment()
        .subtract(index, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');

      monthlyScope.push({ startDate, endDate });
    }

    axios.get(`/account/advertising/reports/${reportId}`).then((response) => {
      setReport(response.data.data);

      let { monthlyRecords } = response.data.data.data;
      let defaultRecord = {};
      [
        'acos',
        'cost',
        'cr',
        'orders',
        'roas',
        'saes',
        'tacos',
        'totalOrderCount',
        'organicConversionRate',
      ].forEach((attr) => (defaultRecord[attr] = 0));

      monthlyRecords = monthlyScope.map(({ startDate, endDate }) => {
        const monthlyRecord = monthlyRecords.find(
          (m) => m.startDate === startDate && m.endDate === endDate
        );

        return monthlyRecord
          ? monthlyRecord
          : { ...defaultRecord, startDate, endDate };
      });

      setMonthlyRecords(monthlyRecords);
      setLoading(false);
    });
  }, [reportId]);

  return loading ? (
    'Loading...'
  ) : (
    <div className="bg-grayscale-400 h-full">
      <div style={{ width: '210mm', height: '297mm' }} className="mx-auto">
        <MonthlyReportHeader
          startDate={report.startDate}
          endDate={report.endDate}
          client={report.client.client}
        />

        <MonthlyKPIs data={report.data.overallPerformance} />

        <MonthlyReportCharts data={monthlyRecords} />

        <TotalMonthlyOrders
          data={monthlyRecords}
          performance={report.data.overallPerformance}
        />
      </div>

      {!!report.data.brandedKeywords.length && (
        <div
          style={{ width: '210mm', height: '297mm' }}
          className="mx-auto pagebreak"
        >
          <MonthlyReportHeader
            startDate={report.startDate}
            endDate={report.endDate}
            client={report.client.client}
          />

          <BrandedKeywords brandedKeywords={report.data.brandedKeywords} />
        </div>
      )}

      {!!report.data.topCampaigns.length && (
        <div
          style={{ width: '210mm', height: '297mm' }}
          className="mx-auto pagebreak"
        >
          <MonthlyReportHeader
            startDate={report.startDate}
            endDate={report.endDate}
            client={report.client.client}
          />

          <TopCampaigns campaigns={report.data.topCampaigns} />
        </div>
      )}

      {!!report.data.topProducts.length && (
        <div
          style={{ width: '210mm', height: '297mm' }}
          className="mx-auto pagebreak"
        >
          <MonthlyReportHeader
            startDate={report.startDate}
            endDate={report.endDate}
            client={report.client.client}
          />

          <TopProducts products={report.data.topProducts} />

          <ExternalLink
            detailedReportLink={report.data.detailedReportLink}
            inventoryReportLink={report.data.inventoryReportLink}
          />
        </div>
      )}

      <div
        style={{ width: '210mm', height: '297mm' }}
        className="pagebreak mx-auto px-8 pt-12"
      >
        {['pastAction', 'analysis', 'futurePlanOfAction'].map((attr) => {
          return (
            <div
              key={attr}
              className="mb-5 text-grayscale-900 bg-white rounded p-8"
            >
              <p className="mb-2 font-bold tracking-3/4 text-base font-inter">
                {startCase(attr)}
              </p>
              <div className="border text-8 p-3 rounded pb-8 whitespace-pre-line">
                {report.data[attr]}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(MonthlyReport);
