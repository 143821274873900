import { Menu, Transition } from '@headlessui/react';
import { Fragment, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

const StatusDropdown = ({
  value,
  options = [],
  onChange,
  compact = false,
  disabled = false,
  className = 'relative inline-block text-left w-full',
  itemsClassName = 'absolute right-0 mt-2 w-full z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
}) => {
  const status = useMemo(
    () => options.find(({ name }) => name === value),
    [value, options]
  );

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  return (
    <Menu as="div" className={className}>
      <Menu.Button
        ref={setReferenceElement}
        as="button"
        disabled={disabled}
        style={{ color: status?.color, background: status?.bgColor }}
        className={
          compact
            ? 'w-5 h-5 rounded-md'
            : 'text-13 leading-1.5 w-full py-2 rounded-40'
        }
      >
        {!compact && value}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={styles.popper}
          {...attributes.popper}
          ref={setPopperElement}
          className={itemsClassName}
        >
          <div className="space-y-4 p-4">
            {options.map((option) => (
              <Menu.Item as="div" key={option.name}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => onChange(option.name)}
                    style={{ color: option.color, background: option.bgColor }}
                    className="text-13 leading-1.5 w-full py-2 rounded-40"
                  >
                    {option.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default StatusDropdown;
