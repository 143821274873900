import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SmartFilter from '../../../components/SmartFilter';
import ChartDisplay from './components/ChartsDisplay';
import TableSummary from './components/TableSummary';

const Snapshot = () => {
  const { account, marketplace } = useSelector((state) => state.account);

  const [loading, setLoading] = useState(false);
  const [snapshot, setSnapshot] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(
    moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  );

  let options = [];

  const ref = moment().subtract(1, 'month').startOf('month');

  while (ref.isSameOrAfter(moment().subtract(12, 'months').startOf('month'))) {
    options.push(ref.format('YYYY-MM-DD'));
    ref.subtract(1, 'month');
  }

  const [filters, setFilters] = useState({
    advCampaignIds: [],
    advPortfolioIds: [],
    campaignTypes: [],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(`/account/advertising/analytics`, {
        params: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          startDate: selectedMonth,
          endDate: moment(selectedMonth).endOf('month').format('YYYY-MM-DD'),
          include: ['previousData', 'totalSales'],
          attributes: [
            'impressions',
            'clicks',
            'ctr',
            'cpc',
            'cr',
            'sales',
            'cost',
            'acos',
            'unitsSold',
          ],
          ...filters,
        },
      });

      if (isSubscribed) {
        setSnapshot(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, selectedMonth, filters]);

  return (
    <div className="mt-10">
      <div className="grid grid-cols-8 gap-4">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="col-span-2 text-grayscale-900 text-mini py-2 w-full border-0 bg-grayscale-400 rounded-xl focus:outline-none focus:ring-0"
        >
          {options.map((option) => (
            <option className="px-5" value={option} key={option}>
              {moment(option).format('MMM YYYY')}
            </option>
          ))}
        </select>

        <div className="col-span-2 col-start-7">
          <SmartFilter
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            campaignIds={filters.advCampaignIds}
            campaignTypes={filters.campaignTypes}
            portfolioIds={filters.advPortfolioIds}
            onChange={setFilters}
            buttonBg="grayscale-400"
            productIds={[]}
            only={['campaignTypes', 'advPortfolioIds', 'advCampaignIds']}
          />
        </div>
      </div>

      <TableSummary loading={loading} date={selectedMonth} data={snapshot} />

      <ChartDisplay
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        date={selectedMonth}
        additionalParams={filters}
      />
    </div>
  );
};

export default Snapshot;
