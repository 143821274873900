import { useLayoutEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  NavLink,
  useParams,
  withRouter,
  useRouteMatch,
} from 'react-router-dom';
import useBodyClass from 'hooks/useBodyClass';
import usePermissions from 'hooks/usePermissions';
import { fetchMarketplaces } from '../clientsSlice';
import {
  fetchAssignees,
  fetchClient,
  getLatestUpdates,
} from './agencyClientSlice';
import Loading from 'components/Loading';
import Account from './Account/Account';
import Billing from './Billing/Billing';
import Dashboard from './Dashboard/Dashboard';

import { InitiateTermination } from 'features/termination';
import Tasks from 'pages/clients/[clientId]/Tasks';

const Client = ({ location }) => {
  useBodyClass(['profile-layout', 'agency-client']);

  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { agencyClientId } = useParams();
  const { path, url } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const { pathname } = location;

  useLayoutEffect(() => {
    setLoading(true);
    dispatch(fetchClient(agencyClientId));
    dispatch(fetchAssignees(agencyClientId));
    setLoading(false);
  }, [dispatch, agencyClientId]);

  useLayoutEffect(() => {
    if (marketplaces.length <= 0) {
      dispatch(fetchMarketplaces());
    }
  }, [dispatch]);

  const tabs = [
    {
      name: 'Client Dashboard',
      href: `${url}/dashboard`,
      visible: true,
    },
    {
      name: 'Billing',
      href: `${url}/billing`,
      visible:
        userCan('clients.subscription.view') &&
        agencyClient?.account?.subscription,
    },
    {
      name: 'Tasks',
      href: `${url}/tasks`,
      visible: true,
    },
    {
      name: 'Advertising',
      href: `${url}/account/advertising`,
      visible:
        !!agencyClient?.account.marketplaces.length &&
        !!agencyClient?.account.credentials.length &&
        userCan('ppc.view'),
    },
    {
      name: 'Products',
      href: `${url}/account/products`,
      visible:
        !!agencyClient?.account.marketplaces.length && userCan('products.view'),
    },
    {
      name: 'Profits',
      href: `${url}/account/profits`,
      visible:
        !!agencyClient?.account.marketplaces.length && userCan('profits.view'),
    },
    {
      name: 'Data Sync',
      href: `${url}/account/data-sync`,
      visible:
        !!agencyClient?.account.marketplaces.length &&
        userCan('data-sync.view'),
    },
  ];

  return !loading && agencyClient ? (
    <>
      <div className="flex items-center justify-between pr-5">
        <h1 className="text-3xl tracking-wide font-bold pt-2">
          {agencyClient?.client}
        </h1>

        <InitiateTermination
          agencyClient={agencyClient}
          onInitiateTermination={() => {
            dispatch(fetchClient(agencyClientId));
            dispatch(getLatestUpdates(agencyClientId));
          }}
        />
      </div>

      <nav className="flex space-x-8 mt-4 mb-10 border-b" aria-label="Tabs">
        {tabs
          .filter((tab) => tab.visible)
          .map((tab) => (
            <NavLink
              key={tab.name}
              to={`${tab.href}`}
              exact={tab.exact}
              className="flex items-center whitespace-nowrap py-1 px-1 font-normal font-inter border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 tracking-wide text-sm"
              activeClassName="border-b-2 border-red-500 text-red-600 font-bold"
              aria-current={pathname.includes(tab.href) ? 'page' : undefined}
            >
              {tab.name}
            </NavLink>
          ))}
      </nav>
      <Switch>
        <Route path={`${path}/dashboard`} component={Dashboard} />
        <Route path={`${path}/billing`} component={Billing} />
        <Route path={`${path}/tasks`} component={Tasks} />
        <Route path={`${path}/account`} component={Account} />
      </Switch>
    </>
  ) : (
    <Loading />
  );
};

export default withRouter(Client);
