import React from 'react';
import classNames from 'utils/classNames';
import SpinnerGrow from './SpinnerGrow';

const ButtonLink = ({
  children,
  classes,
  onClick,
  loading = false,
  color = 'red',
  spinnerColor = 'white',
  showLoading = false,
  textSize = 'sm',
  type = 'button',
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        loading ? 'pointer-events-none opacity-50' : '',
        `text-${textSize}`,
        color === 'white' ? `text-${color}` : `text-${color}-500`,
        `hover:text-${color}-900`,
        classes ?? '',
        'inline-flex items-center'
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
      {showLoading && loading ? (
        <>
          &nbsp;
          <SpinnerGrow color={spinnerColor} />
        </>
      ) : (
        ''
      )}
    </button>
  );
};

export default ButtonLink;
