import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';

import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';

import { Table, Avatar } from 'components';
import Occurence from './components/Occurence';
import FormModal from './components/FormModal';
import UrgencyLevel from './components/UrgencyLevel';
import DetailsModal from './components/DetailsModal';
import ResolveModal from './components/ResolveModal';
import DatePicker from 'features/datePicker/DatePicker';
import ComplaintStatus from './components/ComplaintStatus';
import usePermissions from 'hooks/usePermissions';

import { fetchComplaintList, setListParams } from './complaintSlice';

import { getNameInitials } from 'utils/formatters';

const Complaints = () => {
  const query = useQuery();
  const history = useHistory();
  const { isApplicationUser, isAgencyUser } = useAuth();
  const { userCan } = usePermissions();

  const [openForm, setOpenForm] = useState(false);
  const [openResolveForm, setOpenResolveForm] = useState(false);
  const [openDetails, setOpenDetails] = useState(query.has('selected'));
  const [selectedId, setSelectedId] = useState(query.get('selected') ?? '');

  const { currentAccount } = useSelector((state) => state.accounts);
  const { startDate, endDate } = useSelector((state) => state.datePicker.range);

  const { params, data, loading } = useSelector(
    (state) => state.complaint.list
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      ...params,
      createdOnOrAfter: moment(startDate)
        .tz(moment.tz.guess())
        .startOf('d')
        .format(),
      createdOnOrBefore: moment(endDate)
        .tz(moment.tz.guess())
        .endOf('d')
        .format(),
    };

    if (isApplicationUser) {
      payload.clientId = currentAccount?.AgencyClient?.agencyClientId;

      if (payload.clientId) {
        dispatch(fetchComplaintList(payload));
      }
      return;
    }

    dispatch(fetchComplaintList(payload));
  }, [dispatch, params, startDate, endDate, currentAccount, isApplicationUser]);

  const columns = [
    {
      sort: true,
      dataField: 'client.client',
      text: "Partner's Account",
      classes: 'text-secondary-light text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell, row) =>
        isAgencyUser && userCan('clients.complaints.client_profile.view') ? (
          <Link to={`/clients/redirect/${row.client.agencyClientId}`}>
            {cell}
          </Link>
        ) : (
          cell
        ),
    },
    {
      sort: true,
      dataField: 'client.defaultContact.firstName',
      text: "Partner's Name",
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
      formatter: (cell, row) =>
        cell ? `${cell} ${row.client.defaultContact.lastName}` : '',
    },
    {
      sort: true,
      dataField: 'createdAt',
      text: 'Submitted Date',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => moment(cell).format('MMM DD, YYYY'),
    },
    {
      sort: true,
      dataField: 'addedBy.firstName',
      text: 'Submitted By',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
      formatter: (cell, row) => (
        <div className="flex items-center space-x-1">
          <Avatar
            imageSrc={row.addedBy.avatar?.thumbnailUrl}
            initials={getNameInitials(
              row.addedBy.firstName,
              row.addedBy.lastName
            )}
          />
          <span>
            {cell} {row.addedBy.lastName}
          </span>
        </div>
      ),
    },
    {
      dataField: 'client.salesRepresentative',
      text: 'Sales Representative',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
      formatter: (cell) => {
        return (
          cell && (
            <div className="flex items-center space-x-1">
              <Avatar
                imageSrc={cell.user.avatar?.thumbnailUrl}
                initials={getNameInitials(
                  cell.user.firstName,
                  cell.user.lastName
                )}
              />
              <span>
                {cell.user.firstName} {cell.user.lastName}
              </span>
            </div>
          )
        );
      },
    },
    {
      dataField: 'client.projectManager',
      text: 'Project Manager',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
      formatter: (cell) => {
        return (
          cell && (
            <div className="flex items-center space-x-1">
              <Avatar
                imageSrc={cell.user.avatar?.thumbnailUrl}
                initials={getNameInitials(
                  cell.user.firstName,
                  cell.user.lastName
                )}
              />
              <span>
                {cell.user.firstName} {cell.user.lastName}
              </span>
            </div>
          )
        );
      },
    },
    {
      sort: true,
      dataField: 'type',
      text: 'Type',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
    },
    {
      sort: true,
      dataField: 'occurence',
      text: 'Occurence',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => <Occurence occurence={parseInt(cell)} />,
    },
    {
      sort: true,
      dataField: 'urgencyLevel',
      text: 'Urgency Level',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => <UrgencyLevel urgencyLevel={parseInt(cell)} />,
    },
    {
      sort: true,
      dataField: 'status',
      text: 'Status',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => <ComplaintStatus status={cell} />,
    },
    {
      dataField: 'id',
      text: 'Action',
      headerStyle: { minWidth: '120px' },
      formatter: (cell) => (
        <div className="flex items-center justify-center">
          <SearchIcon
            className="w-6 h-6 text-success-dark cursor-pointer"
            onClick={() => {
              setSelectedId(cell);
              setOpenDetails(true);
            }}
          />
        </div>
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    dispatch(setListParams(newParams));
  };

  const refreshList = () => {
    let payload = {
      ...params,
      createdOnOrAfter: moment(startDate)
        .tz(moment.tz.guess())
        .startOf('d')
        .format(),
      createdOnOrBefore: moment(endDate)
        .tz(moment.tz.guess())
        .endOf('d')
        .format(),
    };

    if (currentAccount && isApplicationUser) {
      payload.clientId = currentAccount.agencyClientId;
    }

    dispatch(fetchComplaintList(payload));
  };

  return (
    <div>
      <FormModal
        open={openForm}
        setOpen={setOpenForm}
        onSuccess={refreshList}
        clientId={
          currentAccount && isApplicationUser
            ? currentAccount.agencyClientId
            : ''
        }
      />

      {!!selectedId && (
        <>
          <DetailsModal
            id={selectedId}
            open={openDetails}
            setOpen={(value) => {
              if (!value) {
                setSelectedId('');
                history.replace(history.location.pathname);
              }
              setOpenDetails(value);
            }}
            onInitResolve={() => setOpenResolveForm(true)}
          />

          <ResolveModal
            id={selectedId}
            open={openResolveForm}
            setOpen={setOpenResolveForm}
            onResolved={refreshList}
          />
        </>
      )}

      <div className="mt-4 mb-8 flex justify-between">
        <div className="flex items-center space-x-4">
          <h3 className="font-inter font-bold text-25 tracking-3/4 text-grayscale-900 leading-1.2">
            Complaints
          </h3>

          <button
            onClick={() => setOpenForm(true)}
            className="border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5"
            disabled={isAgencyUser && !userCan('clients.complaints.add')}
          >
            <PlusIcon className="w-3 h-3" />
            <span>Add</span>
          </button>
        </div>

        <div className="flex space-x-4">
          {isAgencyUser && (
            <div className="relative rounded-2xl bg-white">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>

              <input
                name="search"
                id="search"
                className="block w-full rounded-md border-0 py-4 pl-10 text-grayscale-800 text-13 focus:ring-0 appearance-none focus:appearance-none focus:border-0 focus:outline-none"
                placeholder="Search client"
                value={params.search}
                onChange={(e) =>
                  dispatch(setListParams({ ...params, search: e.target.value }))
                }
              />
            </div>
          )}

          <DatePicker showLabel={false} />
        </div>
      </div>

      <Table
        loading={loading}
        keyField="id"
        columns={columns}
        data={data}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default Complaints;
