import axios from 'axios';
import { useEffect, useState } from 'react';

import {
  XAxis,
  Tooltip,
  Legend,
  Bar,
  Pie,
  PieChart,
  BarChart,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { numberFormatter } from 'utils/formatters';

const ShipmentStatus = ({ accountId, marketplace, startDate, endDate }) => {
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        '/account/shipments/inbound/items/summary',
        {
          params: {
            accountId,
            marketplace,
            startDate,
            endDate,
          },
        }
      );

      if (isSubscribed) {
        const { data } = response.data;
        setSummary([
          {
            name: 'Received',
            value: data.quantityReceived,
            color: '#00BA88',
          },
          {
            name: 'Shipped',
            value: data.quantityShipped,
            color: '#1879D8',
          },
          {
            name: 'Missing',
            value: data.quantityMissing > 0 ? data.quantityMissing : 0,
            color: '#ED2E2E',
          },
        ]);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate]);

  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return percent ? (
      <g>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
          fontWeight="700"
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    ) : null;
  };

  return (
    <div className="rounded-md bg-white">
      <p className="font-inter font-bold tracking-3/4 leading-1.2 text-grayscale-900 border-b border-grayscale-500 px-5 py-6">
        Shipment Status
      </p>

      <div className="h-72 w-full mt-6 bg-grayscale-300 pb-4">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={summary}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={80}
              fill="#8884d8"
              labelLine={false}
              isAnimationActive={false}
              label={renderCustomizedLabel}
            >
              {summary.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              verticalAlign="bottom"
              iconType="circle"
              wrapperStyle={{
                fontSize: '11px',
                margin: 'auto',
              }}
              iconSize={10}
              margin={{ top: 15, left: 0, right: 0, bottom: 0 }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ShipmentStatus;
