import React, { useContext, useEffect, useState } from 'react';
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import logo from 'assets/logos/logo-white.png';
import SidebarSubmenuLink from './SidebarSubmenuLink';
import SidebarSubmenu from './SidebarSubmenu';
import MenuSpriteIcons from 'components/icons/MenuSpriteIcons';
import { Context } from 'layouts/NavProvider';
import { isEmpty } from 'lodash';
import classNames from 'utils/classNames';

const Sidebar = () => {
  const { items, sidebarSubmenuOpen, setSidebarSubmenuOpen } =
    useContext(Context);
  const location = useLocation();
  const { pathname } = location;
  const [currentItem, setCurrentItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      onOpen(items[0]);
    }
  }, [location]);

  useEffect(() => {
    const parent = items.find((item) => {
      const pathParent = pathname.split('/');
      return item.children
        ? item.children.some(
            (c) => c.href.includes(pathname) || c.href.includes(pathParent[1])
          )
        : null;
    });

    setCurrentItem(!isEmpty(parent) ? parent : null);
  }, [pathname, items]);

  const onOpen = (item) => {
    if (currentItem === item) {
      setSidebarSubmenuOpen(!sidebarSubmenuOpen);
    } else {
      setCurrentItem(item);
      //setSidebarSubmenuOpen(currentItem === item ? !sidebarSubmenuOpen : true);
      history.push('children' in item ? item.children[0].href : item.href);
    }
  };

  return (
    <>
      <div className="hidden w-40 bg-red-500 overflow-visible md:block relative">
        <div className="w-full py-6 flex flex-col items-center relative">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/dashboard">
              <img className="h-10 w-auto" src={logo} alt="Workflow" />
            </Link>
          </div>
          <div className="flex-1 mt-6 w-full px-4 space-y-1 relative">
            {items.map((item) => {
              return item.children && item.children.length > 0 ? (
                <SidebarSubmenuLink
                  key={item.name}
                  onOpen={onOpen}
                  currentItem={currentItem}
                  item={item}
                />
              ) : (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="group w-full p-3 rounded-md flex flex-row space-x-1 font-inter items-center text-xs text-white hover:bg-white hover:text-red-500"
                  activeClassName="bg-white text-red-500"
                  aria-current={
                    pathname.includes(item.href) ? 'page' : undefined
                  }
                >
                  {item.iconType && item.iconType === 'svg' ? (
                    <svg
                      className={classNames(
                        'fill-current group-hover:text-red-500'
                      )}
                      style={item.iconSize ?? { width: '14px', height: '14px' }}
                    >
                      <use xlinkHref={item.icon} />
                    </svg>
                  ) : (
                    <item.icon className="h-4 w-4 inline" aria-hidden="true" />
                  )}

                  <span>{item.name}</span>
                </NavLink>
              );
            })}
          </div>

          <MenuSpriteIcons />
        </div>
        <div className="text-white text-2xs font-inter text-center bottom-0 w-full p-2 uppercase absolute">
          {process.env.REACT_APP_NODE_ENV} v{process.env.REACT_APP_VERSION}
        </div>
      </div>

      {currentItem && <SidebarSubmenu currentItem={currentItem} />}
    </>
  );
};

export default withRouter(Sidebar);
