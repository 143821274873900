import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useAlert from 'hooks/useAlert';
import DataTable from 'components/DataTable';
import MarketplaceFlag from 'components/MarketplaceFlag';
import Loading from 'components/Loading';
import { DateTime } from 'luxon';

const ComputedErrors = () => {
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const [computedValues, setComputedValues] = useState(null);

  const [loading, setLoading] = useState(false);
  const { alertError } = useAlert();

  const getComputedValues = async () => {
    setLoading(true);
    await axios
      .get(
        `/agency/commissions/account/${agencyClient.accountId}/computed-error`
      )
      .then((response) => {
        setComputedValues(response.data.data.rows);
      })
      .catch((error) => {
        alertError(
          'Failed to load computed values',
          error.response.data.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!computedValues) {
      getComputedValues();
    }
  }, [computedValues]);

  const columns = [
    { header: 'Computed Month', accessorKey: 'computedMonth' },
    { header: 'Type', accessorKey: 'commission.type' },
    { header: 'Rate (%)', accessorKey: 'commission.rate' },
    {
      header: 'Marketplace',
      accessorKey: 'commission.marketplace',
      cell: (cell) => <MarketplaceFlag marketplace={cell.getValue()} />,
    },
    { accessorKey: 'message' },
    {
      header: 'Created',
      accessorKey: 'createdAt',
      cell: (cell) => (
        <>{DateTime.fromISO(cell.getValue()).toFormat('yyyy-LL-dd')}</>
      ),
    },
  ];

  return (
    <div className="col-span-5 flex flex-col bg-white px-8 py-8 pb-16">
      <div className="flex items-center space-x-4">
        <h4 className="text-2xl font-bold">Computed Commissions Errors</h4>
      </div>
      <div className="mt-6 flex flex-col max-w-full">
        {loading && <Loading />}
        {computedValues && (
          <DataTable
            data={computedValues}
            columns={columns}
            width="max-w-full"
          />
        )}
      </div>
    </div>
  );
};

export default ComputedErrors;
