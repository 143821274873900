const {
  numberFormatter,
  currencyFormatter,
  percentageFormatter,
} = require('utils/formatters');

const metrics = [
  {
    key: 'totalSales',
    title: 'Total Sales',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#fdcc08',
  },
  {
    key: 'advertisingSales',
    title: 'Ad Sales',
    shortTitle: 'Sales',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#FB3767',
  },
  {
    key: 'sales',
    title: 'Ad Sales',
    shortTitle: 'Sales',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#FB3767',
  },
  {
    key: 'profit',
    title: 'Revenue',
    shortTitle: 'Revenue',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#FB3767',
  },
  {
    key: 'cost',
    title: 'Ad Spend',
    shortTitle: 'Spend',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#FF89A6',
  },
  {
    key: 'advertisingCost',
    title: 'Ad Spend',
    shortTitle: 'Spend',
    prefix: '$',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#FF89A6',
  },
  {
    key: 'tacos',
    title: 'TACoS',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'cost',
    divisor: 'totalSales',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#830165',
  },
  {
    key: 'acos',
    title: 'ACoS',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'cost',
    divisor: 'sales',
    rawFormatter: percentageFormatter,
    reverse: true,
    chartType: 'line',
    color: '#202020',
  },
  {
    key: 'advertisingAcos',
    title: 'ACoS',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'cost',
    divisor: 'sales',
    rawFormatter: percentageFormatter,
    reverse: true,
    chartType: 'line',
    color: '#202020',
  },
  {
    key: 'cpcon',
    title: 'Cost per conversion',
    prefix: '$',
    formatter: numberFormatter,
    dividend: 'cost',
    divisor: 'orders',
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#00966D',
  },
  {
    key: 'orders',
    shortTitle: 'Orders',
    title: 'PPC Orders',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#00BA88',
  },
  {
    key: 'totalOrders',
    shortTitle: 'Orders',
    title: 'Orders',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#00BA88',
  },
  {
    key: 'impressions',
    title: 'Impressions',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#3BD7AD',
  },
  {
    key: 'advertisingImpressions',
    title: 'Impressions',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#3BD7AD',
  },
  {
    key: 'advertisingClicks',
    title: 'Clicks',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#C30000',
  },
  {
    key: 'clicks',
    title: 'Clicks',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#C30000',
  },
  {
    key: 'cr',
    shortTitle: 'CVR',
    title: 'Conversion rate',
    longTitle: 'Paid Conversion',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'orders',
    divisor: 'clicks',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#949494',
  },
  {
    key: 'advertisingConversionRate',
    shortTitle: 'CVR',
    title: 'Conversion rate',
    longTitle: 'Paid Conversion',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'orders',
    divisor: 'clicks',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#949494',
  },
  {
    key: 'organicConversionRate',
    shortTitle: 'CVR',
    title: 'Conversion rate',
    longTitle: 'Organic Conversion',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'orders',
    divisor: 'clicks',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#949494',
  },
  {
    key: 'ctr',
    title: 'Click Through rate',
    shortTitle: 'CTR',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'clicks',
    divisor: 'impressions',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#f97d7f',
  },
  {
    key: 'advertisingSalesPercentage',
    title: 'PPC %',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'clicks',
    divisor: 'impressions',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#f97d7f',
  },
  {
    key: 'organicSalesPercentage',
    title: 'Organic %',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'clicks',
    divisor: 'impressions',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#f97d7f',
  },
  {
    key: 'ctr',
    title: 'Click Through rate',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'clicks',
    divisor: 'impressions',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#f97d7f',
  },
  {
    key: 'profitMargin',
    title: 'Profit Margin',
    suffix: '%',
    formatter: (value) => ((value * 10000) / 100).toFixed(2),
    dividend: 'clicks',
    divisor: 'impressions',
    rawFormatter: percentageFormatter,
    chartType: 'line',
    color: '#f97d7f',
  },
  {
    key: 'aov',
    shortTitle: 'AOV',
    title: 'Average order value',
    prefix: '$',
    formatter: numberFormatter,
    dividend: 'cost',
    divisor: 'orders',
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#BFA654',
  },
  {
    key: 'averageSalesPrice',
    title: 'Average sales price',
    prefix: '$',
    formatter: numberFormatter,
    dividend: 'cost',
    divisor: 'orders',
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#BFA654',
  },
  {
    key: 'cpm',
    title: 'CPM',
    prefix: '$',
    formatter: numberFormatter,
    dividend: 'cost',
    divisor: 'impressions',
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#F9DC7D',
  },
  {
    key: 'ordersPerUnit',
    title: 'Conversions per Unit',
    suffix: 'x',
    formatter: numberFormatter,
    dividend: 'orders',
    divisor: 'units',
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#817df9',
  },
  {
    key: 'impressionsPerClick',
    title: 'Impressions per Click',
    suffix: 'x',
    formatter: numberFormatter,
    dividend: 'impressions',
    divisor: 'clicks',
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#002F5D',
  },
  {
    key: 'clicksPerOrder',
    title: 'Clicks per Conversion',
    suffix: 'x',
    formatter: numberFormatter,
    dividend: 'clicks',
    divisor: 'orders',
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#7db3f9',
  },
  {
    key: 'cpc',
    title: 'Cost per Click',
    shortTitle: 'CPC',
    prefix: '$',
    formatter: numberFormatter,
    dividend: 'cost',
    divisor: 'clicks',
    rawFormatter: currencyFormatter,
    chartType: 'bar',
    color: '#ED2E2E',
  },
  {
    key: 'roas',
    title: 'RoAS',
    suffix: 'x',
    formatter: numberFormatter,
    dividend: 'sales',
    divisor: 'cost',
    rawFormatter: (value) => numberFormatter(value) + 'x',
    chartType: 'bar',
    color: '#FF6868',
  },
  {
    key: 'unitsSold',
    title: 'PPC Units',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#4f8301',
  },
  {
    key: 'organicUnitsSold',
    title: 'Organic Unit',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
    color: '#018372',
  },
  {
    key: 'impressionsPerSpend',
    title: 'Impressions per $1 ad spend',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
  },
  {
    key: 'unitsPerOrder',
    title: 'Units per order',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
    chartType: 'bar',
  },
  {
    key: 'costPerConvertedUnit',
    title: 'Total cost per converted unit',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
    chartType: 'bar',
  },
  {
    key: 'bid',
    title: 'Bid',
    formatter: numberFormatter,
    rawFormatter: currencyFormatter,
  },
  {
    key: 'bidUpdatedAtInDays',
    title: 'Bid last updated at in days',
    formatter: numberFormatter,
    rawFormatter: numberFormatter,
  },
];

export default metrics;
