import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { currencyFormatter, dateFormatter } from 'utils/formatters';
import { TrashIcon } from '@heroicons/react/outline';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import ButtonLink from 'components/ButtonLink';
import usePermissions from 'hooks/usePermissions';
import { ConfirmationModal } from 'components';
import useAlert from 'hooks/useAlert';
import { fetchInvoice } from '../invoiceSlice';

const PaymentsMade = () => {
  const dispatch = useDispatch();
  const { invoice } = useSelector((state) => state.invoice);
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const [paymentsExpanded, setPaymentsExpanded] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletePaymentItem, setDeletePaymentItem] = useState(null);

  const openConfirmDelete = (payment) => {
    setDeletePaymentItem(payment);
    setIsOpenDelete(true);
  };

  const onDeletePayment = async () => {
    setDeleting(true);

    try {
      const res = await axios.delete(
        `agency/invoices/${invoice.invoice_id}/payments/${deletePaymentItem.payment_id}?amount=${deletePaymentItem.amount}`
      );

      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('Payment Deleted', res.data.data.message);
    } catch (error) {
      alertError('Delete payment failed', error.response.data.message);
    } finally {
      setDeleting(false);
    }
  };

  return (
    invoice.payment_made > 0 && (
      <>
        <ConfirmationModal
          title="Delete Payment"
          content={`Are you sure you want to delete this payment ${currencyFormatter(
            deletePaymentItem?.amount,
            invoice.currency_code
          )}?`}
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          onOkClick={onDeletePayment}
          onCancelClick={() => setIsOpenDelete(false)}
          okLoading={deleting}
          showOkLoading={true}
        />
        <div className="border border-gray-200 py-2 px-4 bg-white">
          <div
            className="text-sm cursor-pointer flex justify-between"
            onClick={() => setPaymentsExpanded(!paymentsExpanded)}
          >
            <div>
              <span className=" font-bold">Payments Received</span>
              <span className="ml-2 bg-gray-100 rounded-lg px-1 text-blue-500 text-xs font-normal">
                {invoice.payments.length}
              </span>
            </div>
            <span>
              {paymentsExpanded ? (
                <ChevronDownIcon className="w-5 h-5 inline text-gray-500" />
              ) : (
                <ChevronRightIcon className="w-5 h-5 inline text-gray-500" />
              )}
            </span>
          </div>
          <div
            className={
              paymentsExpanded ? 'border-t mt-2' : 'h-0 overflow-hidden'
            }
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="py-1 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                  >
                    Payment #
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                  >
                    Reference #
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                  >
                    Payment Mode
                  </th>
                  <th
                    scope="col"
                    className="relative py-1 pl-3 pr-4 text-left text-sm font-medium text-gray-500 sm:pr-6"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="relative py-1 pl-3 pr-4 text-left text-sm font-medium text-gray-500 sm:pr-6"
                  >
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {invoice.payments.map((payment) => (
                  <tr key={payment.payment_id}>
                    <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                      {dateFormatter(payment.date)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                      {payment.invoice_payment_id}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                      {payment.reference_number}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-sm capitalize text-gray-500">
                      {payment.payment_mode}
                    </td>
                    <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-sm text-gray-700 sm:pr-6">
                      {currencyFormatter(payment.amount, invoice.currency_code)}
                    </td>
                    <td>
                      {userCan('invoices.payment.add') && (
                        <ButtonLink onClick={() => openConfirmDelete(payment)}>
                          <TrashIcon className="w-4 h-4" />
                        </ButtonLink>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  );
};
export default PaymentsMade;
