import * as yup from 'yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { isEmpty, keys, startCase, lowerCase } from 'lodash';

import { useUsers } from 'features/users';
import { useRoles } from 'features/roles';
import { useAlerts } from 'features/alerts';

import { Modal, Button } from 'components';
import { Label, RequiredAsterisk } from 'components/Forms';

const FormModal = ({ open, setOpen, user = {}, onSuccess }) => {
  const { popupAlert } = useAlerts();
  const { fetchAllUsers, users } = useUsers();

  const {
    roles,
    levels,
    departments,
    selectedRole,
    defaultSorts,
    fetchAllRoles,
    subDepartments,
    fetchRoleDetails,
    CEO,
  } = useRoles();

  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedSubDepartment, setSelectedSubDepartment] = useState('');

  useEffect(() => {
    setSelectedRoleId(user?.roleId ?? '');
    setSelectedDepartment(user?.role?.department ?? '');
    setSelectedSubDepartment(user?.role?.subDepartment ?? '');
  }, [user]);

  useEffect(() => {
    if (selectedRoleId) {
      fetchRoleDetails(selectedRoleId);
    }
  }, [selectedRoleId]);

  useEffect(() => {
    if (open) {
      fetchAllRoles({
        sorts: defaultSorts,
        filterGroups: JSON.stringify([
          {
            filters: [
              {
                attribute: 'level',
                operator: 'EQ',
                value: levels.AGENCY_LEVEL,
              },
              {
                attribute: 'status',
                operator: 'EQ',
                value: 'ACTIVE',
              },
              user?.role?.code === CEO.value
                ? {}
                : {
                    attribute: 'code',
                    operator: 'NEQ',
                    value: CEO.value,
                  },
              selectedDepartment
                ? {
                    attribute: 'department',
                    operator: 'EQ',
                    value: selectedDepartment,
                  }
                : {
                    attribute: 'department',
                    operator: 'NOT_HAS_PROPERTY',
                  },
              selectedSubDepartment
                ? {
                    attribute: 'subDepartment',
                    operator: 'EQ',
                    value: selectedSubDepartment,
                  }
                : {
                    attribute: 'subDepartment',
                    operator: 'NOT_HAS_PROPERTY',
                  },
            ].filter((item) => !isEmpty(item)),
          },
        ]),
      });
    }
  }, [user, open, selectedDepartment, selectedSubDepartment]);

  useEffect(() => {
    const { supervisorRole } = selectedRole.data;

    if (supervisorRole) {
      fetchAllUsers({
        filterGroups: JSON.stringify([
          {
            filters: [
              {
                attribute: 'role.code',
                operator: 'EQ',
                value: supervisorRole,
              },
              {
                attribute: 'status',
                operator: 'NEQ',
                value: 'INACTIVE',
              },
            ],
          },
        ]),
      });
    }
  }, [selectedRole]);

  const onSubmit = (values, actions) => {
    let data = { ...values, supervisorId: values.supervisorId ?? null };

    if (!data.hiringDate) {
      delete data.hiringDate;
    }

    if (!data.address) {
      delete data.address;
    }

    const method = user?.userId ? 'put' : 'post';
    const url = user?.userId ? `/v2/users/${user.userId}` : '/v2/users';

    axios[method](url, data)
      .then((response) => {
        setOpen(false);
        onSuccess();

        setTimeout(() => {
          popupAlert({ type: 'success', title: response.data.message });
        }, 250);
      })
      .catch((err) => {
        actions.setErrors(err.response.data.errors);
      })
      .finally(() => actions.setSubmitting(false));
  };

  const close = () => {
    setOpen(false);

    fetchAllRoles({
      sorts: defaultSorts,
      level: levels.AGENCY_LEVEL,
      status: 'ACTIVE',
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-8">
          <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-25 tracking-3/4">
            {isEmpty(user)
              ? 'Add Employee'
              : `Update ${user.firstName} ${user.lastName}`}
          </h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => close()}
          />
        </div>

        <div className="overflow-y-auto mt-2">
          <Formik
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
              firstName: yup.string().required('Required'),
              lastName: yup.string().required('Required'),
              address: yup.string().nullable(),
              email: yup.string().email().required('Required'),
              roleId: yup.number().required('Required'),
              hiringDate: yup.date().nullable(),
              supervisorId: !!selectedRole.data.supervisorRole
                ? yup.string().required('Required')
                : yup.string(),
            })}
            initialValues={{
              email: user.email ?? '',
              roleId: user.roleId ?? 0,
              lastName: user?.lastName ?? '',
              hiringDate: user.hiringDate ?? '',
              address: user.address ?? '',
              firstName: user?.firstName ?? '',
              supervisorId: user.supervisorId ?? '',
            }}
          >
            {({ isSubmitting, isValid, dirty, setFieldValue }) => (
              <Form className="p-8">
                <div className="space-y-5">
                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Email Address
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      name="email"
                      type="email"
                      disabled={!isEmpty(user)}
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    />

                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-5">
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        First Name
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="firstName"
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Last Name
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="lastName"
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-5">
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Start Date
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="hiringDate"
                        type="date"
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="hiringDate"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Location
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="address"
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="address"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Department
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="select"
                      name="department"
                      value={selectedDepartment}
                      disabled={user?.role?.code === CEO.value}
                      onChange={(e) => {
                        setSelectedDepartment(e.target.value);
                      }}
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">No Department</option>
                      {keys(departments).map((key) => (
                        <option
                          key={departments[key].value}
                          value={departments[key].value}
                        >
                          {startCase(lowerCase(departments[key].value))}
                        </option>
                      ))}
                    </Field>
                  </div>

                  {selectedDepartment ===
                    departments.OPERATIONS_DEPARTMENT.value && (
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Sub Department
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        as="select"
                        name="department"
                        value={selectedSubDepartment}
                        onChange={(e) => {
                          setSelectedSubDepartment(e.target.value);
                        }}
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      >
                        <option value="">Select Sub Department</option>
                        {keys(subDepartments).map((key) => (
                          <option
                            key={subDepartments[key].value}
                            value={subDepartments[key].value}
                          >
                            {startCase(subDepartments[key].value)}
                          </option>
                        ))}
                      </Field>
                    </div>
                  )}

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Role
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="select"
                      name="roleId"
                      disabled={user?.role?.code === CEO.value}
                      onChange={(e) => {
                        setFieldValue('supervisorId', '');
                        setSelectedRoleId(e.target.value);
                        setFieldValue('roleId', e.target.value);
                      }}
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">Select Role</option>
                      {roles.data.map((role) => (
                        <option
                          name={role.name}
                          key={role.roleId}
                          value={role.roleId}
                          data-code={role.code}
                          data-supervisor-id={role.supervisorId}
                        >
                          {role.name}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="roleId"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  {!!selectedRole.data.supervisorRole && (
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Team Leader
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        as="select"
                        name="supervisorId"
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      >
                        <option value="">Select Team Leader</option>
                        {users.data.map((user) => (
                          <option key={user.userId} value={user.userId}>
                            {user.firstName} {user.lastName}
                          </option>
                        ))}
                      </Field>

                      <ErrorMessage
                        name="department"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  )}

                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => close()}
                      className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                    >
                      Cancel
                    </button>

                    <Button
                      type="submit"
                      roundedSize={40}
                      showLoading={true}
                      loading={isSubmitting}
                      disabled={!(isValid && dirty)}
                      classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
