import React, { useState, useEffect } from 'react';
import useBodyClass from 'hooks/useBodyClass';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currencyFormatter, dateFormatter } from 'utils/formatters';
import { columnClasses, headerClasses } from 'utils/table';
import { MailIcon, EyeIcon, DownloadIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import axios from 'axios';
import EmailInvoiceModal from 'features/invoices/Invoice/components/EmailInvoiceModal';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import { Table } from 'components';
import InvoiceStatusBadge from 'features/invoices/components/InvoiceStatusBadge';
import usePdf from 'hooks/usePdf';
import PriceLabel from 'components/PriceLabel';

const InvoiceHistory = ({ fromSales = false, zohoId = null }) => {
  useBodyClass(['profile-layout', 'no-sidebar']);
  const { userCan } = usePermissions();
  const [invoice, setInvoice] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [amountDue, setAmountDue] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const { agencyClient, billing } = useSelector((state) => state.agencyClient);
  const { onDownloadPdf, onPreviewPdf } = usePdf();
  const { params, updateParams } = useQueryParams({
    ...billing.invoices.defaultPaginationParams,
    zohoId: fromSales ? zohoId : agencyClient.zohoId,
    status: fromSales ? 'Paid' : 'All',
  });

  useEffect(() => {
    !loading && loadInvoice();
  }, [params]);

  const loadInvoice = async () => {
    setLoading(true);

    await axios
      .get(`/agency/invoices`, { params })
      .then((response) => {
        const { data } = response.data;

        setInvoices(data);
        setAmountTotal(
          data.totalAmount % 1 != 0
            ? data.totalAmount.toFixed(2)
            : data.totalAmount
        );
        setAmountDue(
          data.dueCurrentMonthAmount % 1 != 0
            ? data.dueCurrentMonthAmount.toFixed(2)
            : data.dueCurrentMonthAmount
        );
        setAmountPaid(
          data.paidAmount % 1 != 0
            ? data.paidAmount.toFixed(2)
            : data.paidAmount
        );
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const dispatch = useDispatch();

  const tableColumns = [
    {
      dataField: 'number',
      text: 'Invoice #',
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/invoices/${row.invoice_id}`}
            className="text-secondary-light hover:text-secondary underline"
          >
            {cell}
          </Link>
        );
      },
    },
    {
      dataField: 'total',
      text: 'Amount',
      sort: false,
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      headerStyle: {
        minwidth: '150px',
        textAlign: 'right',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'balance',
      text: 'Balance',
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerClasses: `${headerClasses} text-center`,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <InvoiceStatusBadge status={cell} />,
    },
    {
      dataField: 'due_date',
      text: 'Due Date',
      sort: false,
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <span>{dateFormatter(cell)}</span>,
    },
    agencyClient &&
      !agencyClient.deletedAt && {
        dataField: 'action',
        text: 'Action',
        sort: false,
        headerClasses: `${headerClasses} text-center`,
        classes: `${columnClasses} text-center`,
        headerStyle: {
          minWidth: '150px',
        },
        formatter: (cell, row) => {
          return (
            <span className="flex justify-center items-center space-x-3">
              {userCan('invoices.pdf.download') && (
                <button
                  onClick={() =>
                    onDownloadPdf(
                      `agency/invoices/${row.invoice_id}/pdf`,
                      row.number
                    )
                  }
                >
                  <DownloadIcon className="h-7 w-7 text-secondary hover:text-secondary-light" />
                </button>
              )}
              {userCan('invoices.email') && params.status !== 'Paid' && (
                <button
                  onClick={async () => {
                    await axios
                      .get(`/agency/invoices/${row.invoice_id}`)
                      .then((res) => {
                        setInvoice(res.data.data.invoice);
                      });

                    setIsOpenEmailModal(true);
                  }}
                >
                  <MailIcon className="h-7 w-7 text-secondary hover:text-secondary-light" />
                </button>
              )}
              {userCan('invoices.pdf.download') && (
                <button
                  onClick={() =>
                    onPreviewPdf(`agency/invoices/${row.invoice_id}/pdf`)
                  }
                >
                  <EyeIcon className="h-7 w-7 text-secondary hover:text-secondary-light" />
                </button>
              )}
            </span>
          );
        },
      },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({ page, sizePerPage });
  };

  return (
    <>
      <div
        className={
          fromSales ? `bg-white` : `client-content container-fixed bg-white`
        }
      >
        <div className="">
          {!fromSales && (
            <div className="grid grid-cols-3 divide-x">
              <div className="flex flex-col justify-center items-center py-8  ">
                <div className="font-bold text-grayscale-600 text-1sm">
                  Total Invoice Amount
                </div>
                <div className="font-light text-5xl flex items-center">
                  <span className=" text-grayscale-600 text-4xl mr-1">$</span>
                  {amountTotal}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center py-8 ">
                <div className="font-bold text-grayscale-600 text-1sm">
                  Due This Month
                </div>
                <div className="font-light text-5xl flex items-center">
                  <span className=" text-grayscale-600 text-4xl mr-1">$</span>
                  {amountDue}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center py-8 ">
                <div className="font-bold text-grayscale-600 text-1sm">
                  Payment Received
                </div>
                <div className="font-light text-5xl flex items-center">
                  <span className=" text-grayscale-600 text-4xl mr-1">$</span>
                  {amountPaid}
                </div>
              </div>
            </div>
          )}

          <EmailInvoiceModal
            invoice={invoice}
            open={isOpenEmailModal}
            setOpen={setIsOpenEmailModal}
            type="custom"
          />
          <Table
            columns={tableColumns}
            data={invoices}
            onTableChange={onTableChange}
            params={params}
            keyField="invoice_id"
            loading={loading}
            paginationType="prevnext"
            noDataDisplay={
              <div className="bg-gray-200  px-4 py-2 max-w-xs text-center mx-auto my-0 rounded-md">
                No invoices found!
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceHistory;
