import axios from 'axios';
import { upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChartBarIcon } from '@heroicons/react/solid';

import TrendsModal from '../../../components/TrendsModal/TrendsModal';
import AllocationEditor from '../../components/AllocationEditor';
import StatusSwitch from '../../components/StatusSwitch';

import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import { metricColumns } from 'features/advertising/utils/columns';

import {
  EQUAL_TO,
  KEYWORDS,
  LESS_THAN,
  STATUS_OPTIONS,
} from 'features/advertising/utils/constants';

import { userCan } from 'utils/permission';
import RelatedSearchTerms from '../../components/RelatedSearchTerms';

const Keywords = ({ accountId, marketplace, campaignType }) => {
  const queryParams = new URLSearchParams(window.location.search);

  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { user } = useSelector((state) => state.auth);

  const [openTrends, setOpenTrends] = useState(false);
  const [selectedKeyword, setSelectedKeyWord] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('keywords-column') ??
      'advKeywordId,advCampaignId,keywordText,bid,state,cost,sales,orders'
  );

  const [showSearchTerms, setShowSearchTerms] = useState(false);
  const [keywords, setKeywords] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    campaignType,
    search: '',
    attributes: [
      'advKeywordId',
      'advAdGroupId',
      'advCampaignId',
      'state',
      'keywordText',
      'matchType',
      'bid',
      'impressions',
      'clicks',
      'ctr',
      'cost',
      'cpc',
      'orders',
      'sales',
      'acos',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'roas',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
    include: ['previousData', 'campaign', 'adGroup'],
    sort: 'cost:desc',
    advAdGroupId: queryParams.has('adGroupId')
      ? queryParams.get('adGroupId')
      : null,
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/account/advertising/keywords`, {
        params: {
          ...params,
          accountId,
          marketplace,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
      });

      if (isSubscribed) {
        setKeywords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, campaignType, dateRange, params]);

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('keywords-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const columns = [
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'keywordText',
      text: 'Keyword',
      sort: true,
      headerStyle: { minWidth: '275px' },
      formatter: (cell, row) => {
        const showSearchTerms = () => {
          setSelectedKeyWord(row);
          setShowSearchTerms(true);
        };

        return (
          <>
            <p>{cell}</p>
            {row.adGroup && (
              <p className="text-grayscale-600 text-tiny">
                Ad Group: {row.adGroup.name}
              </p>
            )}
            <span
              className="text-tiny text-secondary-light hover:text-secondary cursor-pointer font-bold tracking-2"
              onClick={showSearchTerms}
            >
              See related search terms
            </span>
          </>
        );
      },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
    },
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'advCampaign',
      exportable: false,
      text: 'Campaign',
      sort: false,
      headerStyle: { minWidth: '200px' },
      formatter: (cell, row) => (row.campaign ? row.campaign.name : ''),
    },
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'matchType',
      text: 'Match Type',
      sort: true,
      headerStyle: { minWidth: '200px' },
      classes: 'text-center',
      headerClasses: 'text-center',
    },
    {
      sort: false,
      default: true,
      hideable: true,
      text: 'Status',
      dataField: 'state',
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity="keywords"
          entityId={row.advKeywordId}
          accountId={accountId}
          marketplace={marketplace}
          disabled={
            !userCan(
              user,
              'ppc.keyword.update.status.noApproval|ppc.keyword.update.status.requireApproval'
            )
          }
        />
      ),
      category: 'settings',
    },
    {
      dataField: 'bid',
      hideable: true,
      category: 'settings',
      text: 'Bid',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: { minWidth: '175px' },
      formatter: (cell, row) => (
        <AllocationEditor
          accountId={accountId}
          marketplace={marketplace}
          entity="keywords"
          entityId={row.advKeywordId}
          currentValue={cell}
          editable={userCan(
            user,
            'ppc.keyword.update.bid.noApproval|ppc.keyword.update.bid.requireApproval'
          )}
          attribute="bid"
        />
      ),
    },
    ...metricColumns().filter((col) => {
      return col.campaignTypes
        ? col.campaignTypes.includes(campaignType)
        : true;
    }),
    {
      auto: true,
      default: true,
      dataField: 'advKeywordId',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const onClick = () => {
          setSelectedKeyWord(row);
          setOpenTrends(true);
        };

        return (
          <div className="flex">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={onClick}
            />
          </div>
        );
      },
    },
  ];

  const statusOptions = STATUS_OPTIONS.map((value) => {
    const display = upperFirst(value);
    return { display, value };
  });

  return (
    <div id={`advertising-${campaignType}-ad-groups`}>
      <TrendsModal
        title={selectedKeyword.keywordText}
        open={openTrends}
        setOpen={(value) => {
          setSelectedKeyWord({});
          setOpenTrends(value);
        }}
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selectedKeyword}
        url={
          selectedKeyword.advKeywordId
            ? '/account/advertising/analytics/records'
            : ''
        }
        additionalParams={{ advKeywordId: selectedKeyword.advKeywordId }}
      />

      <RelatedSearchTerms
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        open={showSearchTerms}
        setOpen={setShowSearchTerms}
        entity={selectedKeyword}
        campaignType={campaignType}
        title={selectedKeyword ? selectedKeyword.keywordText : ''}
        attribute="advKeywordId"
      />

      <AdvertisingTable
        params={params}
        list={keywords}
        columns={columns}
        accountId={accountId}
        recordType={KEYWORDS}
        keyField="advKeywordId"
        marketplace={marketplace}
        campaignType={campaignType}
        onChangeParams={setParams}
        searchClassName="lg:col-span-3"
        filtersClassName="lg:col-span-3"
        exportClassName="lg:col-span-3"
        columnPickerClassName="lg:col-span-3"
        searchPlaceholder="Search ad group"
        attributesKey={`${accountId}-${campaignType}-${KEYWORDS}`}
        visibleColumns={visibleColumns}
        onChangeVisibleColumns={onChangeVisibleColumns}
        endpoint="ad-groups"
        exportInclude={['campaign']}
        additionalFilters={[
          {
            attribute: 'state',
            display: 'Status',
            comparison: EQUAL_TO,
            value: '',
            options: statusOptions,
            placeholder: 'Status',
          },
          {
            attribute: 'defaultBid',
            display: 'Bid',
            comparison: LESS_THAN,
            value: '',
          },
        ]}
      />
    </div>
  );
};

export default Keywords;
