import axios from 'axios';
import * as yup from 'yup';
import { useRef } from 'react';
import { isFunction, isString } from 'lodash';
import Modal from 'components/Modal';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import StarRating from './StarRating';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import ClientSelector from './ClientSelector';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

import classNames from 'utils/classNames';

const types = [
  'Finished Tasks',
  'Missed Meetings',
  'More action needed',
  'Missed deadlines/Delays',
  'Delayed Reporting',
  'Lack of sales growth',
  'Increase in sales',
  'PPC Issues',
];

const FormModal = ({ open, setOpen, onSuccess }) => {
  const inputFile = useRef();

  const onSubmit = (values, actions) => {
    const formData = new FormData();
    formData.append('type', values.type);
    formData.append('rating', values.rating);
    formData.append('clientId', values.clientId);
    formData.append('information', values.information);

    values.images.forEach((image) => formData.append('images', image));

    axios
      .post('/agency/clients/performance-ratings', formData)
      .then(() => {
        setOpen(false);
        if (isFunction(onSuccess)) {
          onSuccess();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-8">
          <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
            Rate Account Performance
          </h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="h-80vh overflow-y-auto mt-2">
          <Formik
            initialValues={{
              clientId: '',
              type: '',
              rating: 0,
              images: [],
              information: '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
              clientId: yup.string().required('Required'),
              type: yup.string().required('Required'),
              information: yup.string().required('Required'),
              rating: yup.number().required('Required'),
              images: yup
                .array()
                .required('Required')
                .min(1, 'Must have at least one item.'),
            })}
          >
            {({
              errors,
              isSubmitting,
              isValid,
              dirty,
              values,
              setFieldValue,
            }) => (
              <Form className="p-8">
                <div className="space-y-8">
                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Partner's Account
                      <RequiredAsterisk />
                    </Label>

                    <ClientSelector
                      value={values.clientId}
                      onChange={(clientId) =>
                        setFieldValue('clientId', clientId)
                      }
                    />

                    <ErrorMessage
                      name="clientId"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Type of Review
                      <RequiredAsterisk />
                    </Label>

                    <div
                      className="grid grid-cols-2 gap-5 mt-3"
                      role="group"
                      aria-labelledby="my-radio-group"
                    >
                      {types.map((type) => (
                        <label
                          className="flex items-center space-x-1 text-13 text-grayscale-900 leading-1.5"
                          key={type}
                        >
                          <Field type="radio" name="type" value={type} />
                          <span>{type}</span>
                        </label>
                      ))}
                    </div>

                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Start Rating
                      <RequiredAsterisk />
                    </Label>

                    <div className="flex justify-center mt-3">
                      <StarRating
                        size={16}
                        gap={2}
                        value={values.rating}
                        onChange={(value) => setFieldValue('rating', value)}
                      />
                    </div>

                    <ErrorMessage
                      name="rating"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <div
                      className={classNames(
                        values.images.length
                          ? 'flex items-center justify-between'
                          : ''
                      )}
                    >
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Additional Information Screenshots
                        <RequiredAsterisk />
                      </Label>

                      <div>
                        <input
                          type="file"
                          className="hidden"
                          ref={inputFile}
                          accept="image/*"
                          multiple
                          onChange={(e) =>
                            setFieldValue('images', [
                              ...values.images,
                              ...e.target.files,
                            ])
                          }
                        />

                        <button
                          onClick={() => inputFile.current.click()}
                          className="mt-3 text-grayscale-700 tracking-2 leading-1.5 text-sm font-bold border-2 border-grayscale-700 rounded-40 py-1.5 px-5 w-full"
                        >
                          {values.images.length
                            ? 'Upload more'
                            : 'Additional Information Screenshots'}
                        </button>
                      </div>
                    </div>

                    <FieldArray name="images">
                      {({ remove }) => (
                        <>
                          <div className="grid grid-cols-3 gap-4 mt-4">
                            {values.images.map((image, idx) => (
                              <div className="relative" key={`images-${idx}`}>
                                <div
                                  onClick={() => remove(idx)}
                                  className="bg-secondary cursor-pointer w-6 h-6 z-20 absolute -right-1.5 -top-1.5 rounded-full flex items-center justify-center"
                                >
                                  <XIcon className="w-3 h-3 text-white" />
                                </div>
                                <div className="aspect-w-1 aspect-h-1 border w-full">
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt="Image"
                                    className="h-full w-full object-cover"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </FieldArray>

                    {!!errors?.images && isString(errors.images) && (
                      <ErrorMessage
                        name="images"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    )}
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Any additional notes related to this rating?*
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="textarea"
                      name="information"
                      rows={5}
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    />

                    <ErrorMessage
                      name="information"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="flex">
                    <button
                      onClick={() => setOpen(false)}
                      className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                    >
                      Cancel
                    </button>

                    <Button
                      type="submit"
                      roundedSize={40}
                      showLoading={true}
                      loading={isSubmitting}
                      disabled={!(isValid && dirty)}
                      classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
