import axios from 'axios';
import { useState, useEffect } from 'react';
import { numberFormatter, percentageFormatter } from 'utils/formatters';

const Funnel = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  className = '',
  additionalParams = {},
}) => {
  const [overview, setOverview] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: ['impressions', 'ctr', 'clicks', 'cr', 'orders'],
        ...additionalParams,
      };

      const response = await axios.get('/account/advertising/analytics', {
        params,
      });

      if (isSubscribed) {
        setOverview(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    !!overview.impressions && (
      <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
        <div className="flex items-center justify-between px-8 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
            PPC Funnel
          </p>
        </div>

        <div className="px-8">
          <div className="grid grid-cols-5 justify-center my-8">
            <div className="col-span-2">
              <div className="mt-2 xl:mt-3 2xl:mt-10 ml-8">
                {[
                  { label: 'Impressions', color: '#fb3767' },
                  { label: 'Clicked Through Rate', color: '#ff89a6' },
                  { label: 'Clicks', color: '#002f5d' },
                  { label: 'Conversion Rate', color: '#1879d8' },
                  { label: 'PPC Orders', color: '#00ba88' },
                ].map((x) => (
                  <p
                    key={x.label}
                    style={{ color: x.color }}
                    className="text-xs md:text-sm lg:text-base xl:text-md 2xl:text-lg mb-12"
                  >
                    {x.label}
                  </p>
                ))}
              </div>
            </div>
            <div className="max-w-xl w-full col-span-3">
              <div className="advertising-funnel text-white">
                <div className="trapezoid">
                  <span className="text-xl py-2 font-medium">
                    {numberFormatter(overview.impressions)}
                  </span>
                </div>
                <div className="trapezoid">
                  <span className="text-xl py-2 font-medium">
                    {percentageFormatter(overview.ctr)}
                  </span>
                </div>
                <div className="trapezoid">
                  <span className="text-xl py-2 font-medium">
                    {numberFormatter(overview.clicks)}
                  </span>
                </div>
                <div className="trapezoid">
                  <span className="text-xl py-2 font-medium">
                    {percentageFormatter(overview.cr)}
                  </span>
                </div>
                <div className="trapezoid">
                  <span className="text-xl py-2 font-medium">
                    {numberFormatter(overview.orders)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Funnel;
