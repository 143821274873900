export const STATUSES = [
  'For Approval',
  'Old-Leads',
  'Shopify',
  'Instagram',
  'Unprocessed New Leads',
  'Ready to Pitch',
  'No LinkedIn Available',
  'Unqualified',
  'Out of Stock',
  'SF under $1000',
  'Duplicate',
  'Reseller',
  'Chinese Seller',
  'Linkedin Messaging Disabled',
  'System-duplicate',
  'Potential-duplicate',
  'Revision',
  'Direct-Booking',
  'Lemlist Direct-Booking',
  'Accepted Positive',
  'Accepted Neutral',
  'Accepted Negative',
  'Call-Booked',
];

export const STATUSES_ARCHIVED = [
  'No LinkedIn Available',
  'Unqualified',
  'Out of Stock',
  'SF under $1000',
  'Inmail Declined',
  'Drip',
  'Disengaged Old Lead',
  'Not Interested',
  'Duplicate',
  'Reseller',
  'Chinese Seller',
  'Linkedin Messaging Disabled',
  'No Competitor',
  'AMZ',
  'Other Marketplace',
  'Do Not Contact',
  'Working with Another Agency',
  'Incorrect Contact',
  'Previous / Existing Client',
  'No Seller Info',
];

export const STATUSES_FOLLOW_UP = [
  'Follow Up 1',
  'Follow Up 2',
  'Follow Up 3',
  'Follow Up 4',
];

export const PRE_PITCH_STATUSES = [
  'Old-Leads',
  'Unprocessed New Leads',
  'Shopify',
  'Instagram',
];

export const FOLLOW_UP_DUE = [
  {
    key: 'Follow Up 1',
    val: 3,
  },
  {
    key: 'Follow Up 2',
    val: 3,
  },
  {
    key: 'Follow Up 3',
    val: 3,
  },
  {
    key: 'Follow Up 4',
    val: 14,
  },
];

export const STATUSES_SYSTEM_ARCHIVED = [
  'System-duplicate',
  'Potential-duplicate',
];

export const STATUS_REQUIRED = [
  'For Approval',
  'Ready to Pitch',
  'Connection Request Sent',
  'Pitched',
  'Lemlist',
  'Call-Booked',
  'Direct-Booking',
  'Lemlist Direct-Booking',
  'Accepted Positive',
  'Accepted Neutral',
  'Accepted Negative',
  'Accepted Disqualified',
];

export const STATUSES_BOOKED = ['Call-Booked', 'Direct-Booking'];

export const LABEL_VALUE = [
  { label: 'First Name', value: 'lead', isNumber: false },
  { label: 'Last Name', value: 'leadLastName', isNumber: false },
  { label: 'ASIN Full Title', value: 'asinFullTitle', isNumber: false },
  {
    label: 'Competitor Brand Name',
    value: 'competitorBrandName',
    isNumber: false,
  },
  { label: 'Competitor ASIN Revenue', value: 'revenue', isNumber: true },
  { label: 'ASIN Revenue', value: 'currentEarnings', isNumber: true },
  { label: 'ASIN', value: 'asin', isNumber: false },
  { label: 'Storefront Revenue', value: 'storeFrontEarnings' },
  { label: 'ASIN Reviews', value: 'asinReviews' },
  { label: 'ASIN Price', value: 'asinPrice' },
  { label: 'ASIN Major Keyword', value: 'asinMajorKeyword' },
  { label: 'Amazon Store Front URL', value: 'amzStoreFBAstoreFront' },
  { label: 'Brand Name', value: 'brandName' },
  { label: 'Product Category', value: 'productCategory' },
  { label: 'Company Name', value: 'companyName' },
];

export const PITCHER_POD_TAP = ['Pitcher Team'];

export const LEADS_POD_TAP = [
  'New Leads Team I',
  'New Leads Team II',
  'Old Leads Team',
  'Manual Search Team',
];

export const LEMLIST_RESPONSE_TYPE = [
  'Lemlist Accepted Positive',
  'Lemlist Accepted Neutral',
  'Lemlist Accepted Negative',
  'Do Not Contact',
];

export const BOLD_MAP = {
  A: '\u{1D5D4}',
  B: '\u{1D5D5}',
  C: '\u{1D5D6}',
  D: '\u{1D5D7}',
  E: '\u{1D5D8}',
  F: '\u{1D5D9}',
  G: '\u{1D5DA}',
  H: '\u{1D5DB}',
  I: '\u{1D5DC}',
  J: '\u{1D5DD}',
  K: '\u{1D5DE}',
  L: '\u{1D5DF}',
  M: '\u{1D5E0}',
  N: '\u{1D5E1}',
  O: '\u{1D5E2}',
  P: '\u{1D5E3}',
  Q: '\u{1D5E4}',
  R: '\u{1D5E5}',
  S: '\u{1D5E6}',
  T: '\u{1D5E7}',
  U: '\u{1D5E8}',
  V: '\u{1D5E9}',
  W: '\u{1D5EA}',
  X: '\u{1D5EB}',
  Y: '\u{1D5EC}',
  Z: '\u{1D5ED}',
  a: '\u{1D5EE}',
  b: '\u{1D5EF}',
  c: '\u{1D5F0}',
  d: '\u{1D5F1}',
  e: '\u{1D5F2}',
  f: '\u{1D5F3}',
  g: '\u{1D5F4}',
  h: '\u{1D5F5}',
  i: '\u{1D5F6}',
  j: '\u{1D5F7}',
  k: '\u{1D5F8}',
  l: '\u{1D5F9}',
  m: '\u{1D5FA}',
  n: '\u{1D5FB}',
  o: '\u{1D5FC}',
  p: '\u{1D5FD}',
  q: '\u{1D5FE}',
  r: '\u{1D5FF}',
  s: '\u{1D600}',
  t: '\u{1D601}',
  u: '\u{1D602}',
  v: '\u{1D603}',
  w: '\u{1D604}',
  x: '\u{1D605}',
  y: '\u{1D606}',
  z: '\u{1D607}',
  0: '\u{1D7EC}',
  1: '\u{1D7ED}',
  2: '\u{1D7EE}',
  3: '\u{1D7EF}',
  4: '\u{1D7F0}',
  5: '\u{1D7F1}',
  6: '\u{1D7F2}',
  7: '\u{1D7F3}',
  8: '\u{1D7F4}',
  9: '\u{1D7F5}',
};
