import axios from 'axios';
import moment from 'moment';
import { range, sortBy } from 'lodash';
import { useEffect, useState } from 'react';

import classNames from 'utils/classNames';

const History = ({ account, marketplace }) => {
  const [records, setRecords] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    moment().startOf('month').format('YYYY-MM-DD')
  );

  let options = [];

  const ref = moment().startOf('month');

  while (ref.isSameOrAfter(moment('2022-01-01'))) {
    options.push(ref.format('YYYY-MM-DD'));
    ref.subtract(1, 'month');
  }

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/data-sync/categories', {
        params: { isHistory: true },
      });

      if (isSubscribed) {
        setCategories(sortBy(response.data.data, 'id'));
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/data-sync/history', {
        params: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          startDate: selectedMonth,
          endDate: moment(selectedMonth).endOf('month').format('YYYY-MM-DD'),
        },
      });

      if (isSubscribed) {
        setRecords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, selectedMonth]);

  return (
    <div className="bg-white p-5 rounded-md">
      <div className="grid grid-cols-8 gap-4">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="col-span-2 text-grayscale-900 text-mini py-2 w-full border-0 bg-grayscale-400 rounded-xl focus:outline-none focus:ring-0"
        >
          {options.map((option) => (
            <option className="px-5" value={option} key={option}>
              {moment(option).format('MMM YYYY')}
            </option>
          ))}
        </select>
      </div>

      <p className="text-13 text-grayscale-700 mt-2">
        Note: Old history synced before Jan. 9, 2023 is not recorded.
      </p>

      <div className="p-4 mt-5">
        <div className="overflow-x-auto">
          <table>
            <tbody>
              {categories.map((category) => {
                return (
                  <tr key={`daily-sync-status-${category.id}`}>
                    <td
                      style={{ minWidth: '150px' }}
                      className="text-13 text-grayscale-700 sticky left-0 z-10 whitespace-nowrap bg-white pr-5"
                    >
                      {category.id}
                    </td>

                    {range(0, moment(selectedMonth).daysInMonth()).map((i) => {
                      const date = moment(selectedMonth)
                        .add(i, 'days')
                        .format('YYYY-MM-DD');

                      const record = records.find(
                        (i) => i.date === date && i.categoryId === category.id
                      );

                      return (
                        <td
                          key={`daily-sync-status-${category.id}-${date}`}
                          className={classNames(
                            'border px-3 py-5',
                            record ? 'bg-success-dark' : 'bg-grayscale-500'
                          )}
                          title={
                            record
                              ? `${date} last sync at: ${moment(
                                  record.syncAt
                                ).format(' MMMM Do, h:mm:ss a')}`
                              : `${date}: Not yet sync`
                          }
                        ></td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                <td
                  style={{ minWidth: '150px' }}
                  className="text-13 text-grayscale-700 sticky left-0 z-10 whitespace-nowrap bg-white"
                ></td>
                {range(0, moment(selectedMonth).daysInMonth()).map((i) => {
                  const date = moment(selectedMonth)
                    .add(i, 'days')
                    .format('YYYY-MM-DD');

                  return (
                    <td className="h-20 transform rotate-90 text-11 whitespace-nowrap">
                      {date}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default History;
