import { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useRoles } from 'features/roles';
import useClient from 'features/clients/useClient';
import { sumBy, isEmpty, upperFirst, camelCase } from 'lodash';
import MemberServiceTypeBreakdown from '../components/MemberServiceTypeBreakdown';
import TeamSummary from '../components/TeamSummary';
import TeamMembersTable from '../components/TeamMembersTable';

const TeamMembers = ({ year }) => {
  const { PROJECT_MANAGER } = useRoles();
  const { SERVICES } = useClient();

  const [loading, setLoading] = useState(true);
  const [projectManagers, setProjectManagers] = useState([]);
  const [selectedProjectManager, setSelectedProjectManager] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

      const { data } = await axios.get('/v2/users', {
        params: {
          endDate,
          startDate,
          'role.code': PROJECT_MANAGER.value,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'totalAssignedNewClients',
            'totalAssignedActiveClients',
            'totalAssignedTerminatedClients',
            'totalAssignedActiveRecurringClientsMonthlyRetainer',
            'totalAssignedActiveClientsAsins',
            ...SERVICES.map((serviceType) => {
              let serviceKey = upperFirst(camelCase(serviceType.value));

              return `totalAssignedActiveClientsWith${serviceKey}Service`;
            }),
          ],
        },
      });

      if (isSubscribed) {
        setProjectManagers(data.data);
        if (data.data.length) {
          setSelectedProjectManager(data.data[0]);
        }
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  return (
    <div>
      <h4 className="font-inter text-xl font-bold leading-1.2 tracking-3/4 text-grayscale-900 mb-8">
        Total Client Count per Team
      </h4>

      <select
        value={selectedProjectManager?.userId ?? ''}
        onChange={(e) => {
          setSelectedProjectManager(
            projectManagers.find((i) => i.userId === e.target.value)
          );
        }}
        className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-56 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
      >
        {projectManagers.map((projectManager) => (
          <option key={projectManager.userId} value={projectManager.userId}>
            {`Team ${projectManager.firstName} ${projectManager.lastName}`}
          </option>
        ))}
      </select>

      <div className="mt-8">
        {!isEmpty(selectedProjectManager) && (
          <div className="space-y-8">
            <TeamSummary summary={selectedProjectManager} />

            <MemberServiceTypeBreakdown
              year={year}
              supervisor={selectedProjectManager}
            />

            <TeamMembersTable
              year={year}
              supervisorId={selectedProjectManager?.userId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMembers;
