import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import BrandedKeyword from './pages/BrandedKeyword';
import NonBrandedKeyword from './pages/NonBrandedKeyword';

const KeywordTracking = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={url}
        render={() => <Redirect to={`${url}/branded`} />}
      />

      <Route exact={true} path={`${path}/branded`} component={BrandedKeyword} />

      <Route
        exact={true}
        path={`${path}/non-branded`}
        component={NonBrandedKeyword}
      />
    </Switch>
  );
};

export default KeywordTracking;
