import { useState } from 'react';
import { Menu } from '@headlessui/react';
import Modal from 'components/Modal';
import Checkbox from 'components/Forms/Checkbox';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import ButtonLink from 'components/ButtonLink';

const ColumnPicker = ({ options, setTableColumns, showTitle = true }) => {
  const [search, setSearch] = useState('');
  const [viewOptions, setViewOptions] = useState(false);
  const onChangeCheckBox = (e) => {
    const i = options.findIndex((el) => el.dataField === e.target.id);
    const optionsCopy = [...options];
    optionsCopy[i].show = !optionsCopy[i].show;
    setTableColumns(optionsCopy);
  };

  const applyAll = () => {
    const optionsCopy = options.map((option) => {
      return {
        ...option,
        show: true,
      };
    });
    setTableColumns(optionsCopy);
  };
  return (
    <>
      <Modal open={viewOptions} persistent={true} as="div" align="top">
        <div className="inline-block w-full max-w-sm my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="p-6">
            <div className="grid grid-cols-3 border-b gap-4 mb-6">
              <div className="col-span-3">
                <div className="">
                  <div className="flex text-gray-700 justify-between items-center">
                    <p className="text-lg font-bold">Available Metrics</p>
                    <p
                      className="text-red-500 cursor-pointer text-xs hover:text-red-800 tracking-wider"
                      onClick={applyAll}
                    >
                      Show All
                    </p>
                  </div>
                  <input
                    type="search"
                    className="px-3 py-2 w-full text-xs my-2 border-gray-300 border rounded"
                    placeholder="Quick Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="h-64 overflow-y-scroll">
                    {options
                      .filter((option) => option.hideable)
                      .filter((option) =>
                        search
                          ? option.text
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          : true
                      )
                      .map((option, i) => {
                        return (
                          <div key={option.dataField}>
                            <label className="text-sm font-medium text-gray-700 w-full cursor-pointer flex items-center">
                              <Checkbox
                                id={option.dataField}
                                classes="my-1.5 ml-1 mr-3 rounded-sm"
                                checked={option.show}
                                onChange={onChangeCheckBox}
                              />
                              {option.text}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div></div>
              </div>
            </div>

            <button
              type="button"
              onClick={() => setViewOptions(false)}
              className="flex items-center px-3 tracking-wider rounded-3xl py-0.5 border-2 font-bold border-gray-600 text-xs text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <button
        className="flex items-center space-x-2 text-grayscale-700 hover:text-grayscale-800"
        onClick={() => setViewOptions(true)}
        title="Show/Hide Columns"
      >
        <AdjustmentsIcon className="h-5 w-5 inline" aria-hidden="true" />
        {showTitle && (
          <span className="tracking-2 font-bold leading-1.5">Customize</span>
        )}
      </button>
    </>
  );
};

export default ColumnPicker;
