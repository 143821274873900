import proposalImage from './assets/proposal-side-image-two.png';

export const About = () => {
  return (
    <div
      className="relative flex flex-col p-6 lg:pb-28 lg:pl-10 md:pr-0 section"
      id="about"
    >
      <div className="flex justify-between">
        <div className="z-10 md:w-9/12 space-y-6">
          <h2 className="text-4xl font-bold text-si-pink">About Us</h2>
          <p className="text-md whitespace-pre-line">
            {`${`
            Seller Interactive’s humble beginning started in Toronto as entry-level sellers in 2010. Our selling experience is broad in several different categories while stumbling upon thousands of pitfalls and hardships on Amazon. Since then, we have transformed our success into the best fundamental selling strategies to become Canada’s #1 leader in the Digital Marketing industry.        `}`}
          </p>
        </div>{' '}
        <img src={proposalImage} className="max-w-xs hidden md:inline" />
      </div>
    </div>
  );
};
