import React, { useEffect } from 'react';
import { fetchListingMockup } from 'features/clients/Client/Dashboard/Mockup/mockupSlice';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import PageHeader from 'components/PageHeader';
import ListingMockup from './components/ListingMockup';
import useLoggedInUser from 'hooks/useLoggedInUser';

const Mockup = () => {
  const dispatch = useDispatch();
  const { user } = useLoggedInUser();
  const { listingMockup } = useSelector((state) => state.mockup);

  useEffect(() => {
    dispatch(
      fetchListingMockup(
        user.memberships[0].account.AgencyClient.agencyClientId
      )
    );
  }, [user]);

  return (
    <DashboardLayout>
      <PageHeader title="Mockups" />
      {listingMockup &&
        listingMockup.map((item, i) => (
          <ListingMockup key={item.listingMockupId} item={item} />
        ))}
    </DashboardLayout>
  );
};

export default Mockup;
