import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter, dateFormatter } from 'utils/formatters';
import { TrashIcon } from '@heroicons/react/outline';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import axios from 'axios';
import ButtonLink from 'components/ButtonLink';
import usePermissions from 'hooks/usePermissions';
import { ConfirmationModal } from 'components';
import useAlert from 'hooks/useAlert';
import { fetchInvoice } from '../invoiceSlice';

const CreditsApplied = () => {
  const dispatch = useDispatch();
  const { invoice } = useSelector((state) => state.invoice);
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const [creditsExpanded, setCreditsExpanded] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [credit, setCredit] = useState(null);

  const openConfirmDelete = (credit) => {
    setCredit(credit);
    setIsOpenDelete(true);
  };

  const onRemoveAppliedCredit = async () => {
    setDeleting(true);

    try {
      const res = await axios.delete(
        `agency/invoices/${invoice.invoice_id}/credits/${credit.creditnote_id}/${credit.creditnotes_invoice_id}`
      );

      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('Credit Note removed', res.data.data.message);
    } catch (error) {
      alertError('Remove credit note failed', error.response.data.message);
    } finally {
      setDeleting(false);
    }
  };

  return (
    invoice.credits_applied > 0 && (
      <>
        <ConfirmationModal
          title="Remove Applied Credit"
          content={`Are you sure you want to remove this applied credit ${currencyFormatter(
            credit?.credited_amount
          )}?`}
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          onOkClick={onRemoveAppliedCredit}
          onCancelClick={() => setIsOpenDelete(false)}
          okLoading={deleting}
          showOkLoading={true}
        />
        <div className="border border-gray-200 py-2 px-4">
          <div
            className="text-sm cursor-pointer flex justify-between"
            onClick={() => setCreditsExpanded(!creditsExpanded)}
          >
            <div>
              <span className="font-bold text-gray-700">Credits Applied</span>
              <span className="ml-2 bg-gray-100 rounded-lg px-1 text-blue-500 text-xs font-normal">
                {invoice.credits.length}
              </span>
            </div>
            <span>
              {creditsExpanded ? (
                <ChevronDownIcon className="w-5 h-5 inline text-gray-500" />
              ) : (
                <ChevronRightIcon className="w-5 h-5 inline text-gray-500" />
              )}
            </span>
          </div>
          <div
            className={
              creditsExpanded ? 'border-t mt-2' : 'h-0 overflow-hidden'
            }
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="py-1 pl-4 pr-3 text-left text-xs font-medium text-gray-400 sm:pl-6"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1 text-left text-xs font-medium text-gray-400 "
                  >
                    Credit Note #
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-1 text-left text-xs font-medium text-gray-400 "
                  >
                    Credits Applied
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {invoice.credits.map((credit) => (
                  <tr key={credit.creditnote_id}>
                    <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm text-gray-800 sm:pl-6">
                      {dateFormatter(credit.credited_date)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                      {credit.creditnotes_number}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                      {currencyFormatter(
                        credit.credited_amount,
                        invoice.currency_code
                      )}
                    </td>
                    <td>
                      {userCan('creditNotes.approve') && (
                        <ButtonLink onClick={() => openConfirmDelete(credit)}>
                          <TrashIcon className="w-4 h-4" />
                        </ButtonLink>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  );
};
export default CreditsApplied;
