import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useTasks } from 'Version2/features/tasks';

import {
  TaskGroup,
  TaskFilters,
  FloatingTaskForm,
  TaskGroupSkeleton,
} from 'Version2/features/tasks/components';

import TaskDetailsPage from 'features/tasks/TaskDetailsPage';

const TeamWorkspace = () => {
  const history = useHistory();
  const { STATUS_LIST, board, fetchTaskBoardItems, fetchTaskGroupItems } =
    useTasks();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const onDebouncedSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 1000);

  useEffect(() => {
    refreshList().then(() => setInitialLoad(false));
  }, [search, filters]);

  const refreshList = async () => {
    fetchTaskBoardItems(
      board.key,
      STATUS_LIST.map((status) => {
        return {
          title: status.name,
          params: {
            search,
            filterGroups: JSON.stringify([
              {
                filters: [
                  { attribute: 'isSubtask', operator: 'EQ', value: false },
                  {
                    attribute: 'isAssignedToMyTeam',
                    operator: 'EQ',
                    value: true,
                  },
                  {
                    value: false,
                    operator: 'EQ',
                    attribute: 'isSubtask',
                  },
                  {
                    attribute: 'status',
                    operator: 'EQ',
                    value: status.name,
                  },
                  ...filters.filter((filter) => filter.attribute !== board.key),
                ],
              },
            ]),
            attributes: [
              'due',
              'name',
              'taskItemId',
              'taskListId',
              'dueDate',
              'status',
              'priority',
              'hasSubtasks',
              'parentTaskItemId',
              'hierarchyLevelId',
            ],
            include: [
              'assignees[avatar]',
              'subtasks[assignees[avatar]]',
              'list[client[brandStrategist[user[avatar]],accountSpecialist[user[avatar]]]]',
            ],
            sorts: 'updatedAt',
          },
        };
      })
    );
  };

  return (
    <div className="py-4">
      <div className="flex items-center justify-between">
        <h3 className="font-inter leading-1.2 tracking-3/4 text-grayscale-900 font-bold text-xl mt-2">
          Tasks
        </h3>

        <div className="flex space-x-5 items-center">
          <div className="relative rounded-16">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              defaultValue={search}
              onChange={onDebouncedSearch}
              className="block w-full rounded-16 border-0 py-3 pl-10 text-grayscale-700 ring-1 ring-inset ring-white placeholder:text-grayscale-600 focus:ring-2 focus:ring-inset focus:ring-white text-13 leading-1.5"
              placeholder="Search Tasks"
            />
          </div>

          <TaskFilters
            statusesOptions={STATUS_LIST.map((status) => ({
              label: status.name,
              value: status.name,
            }))}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>

      <div className="space-y-8 mt-8">
        {initialLoad ? (
          <TaskGroupSkeleton />
        ) : (
          board.groups.map((group, idx) => (
            <TaskGroup
              key={idx}
              title={group.title}
              taskItemList={group.data}
              isLoading={group.isLoading}
              itemsConfig={{
                onClick: (task) =>
                  history.push(`/home/team-workspace/${task.taskItemId}`),
                onUpdate: (attribute) => {
                  if (attribute === board.key) {
                    refreshList();
                  }
                },
              }}
              onLoadMore={() =>
                fetchTaskGroupItems(idx, {
                  ...group.params,
                  pageSize: group.data.pageSize + 10,
                })
              }
            />
          ))
        )}
      </div>

      <FloatingTaskForm onSuccess={refreshList} />

      <Switch>
        <Route
          path="/home/team-workspace/:taskItemId"
          exact={true}
          render={() => <TaskDetailsPage onChange={refreshList} />}
        />
      </Switch>
    </div>
  );
};

export default TeamWorkspace;
