import axios from 'axios';
import { useEffect, useState } from 'react';

import classNames from 'utils/classNames';
import metrics from '../../utils/metrics';

const attributes = [
  'cost',
  'sales',
  'impressions',
  'clicks',
  'orders',
  'unitsSold',
  'ctr',
  'cr',
  'acos',
  'cpm',
  'cpc',
  'cpcon',
];

const BestVsWorstTargeting = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  className = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [targetings, setTargetings] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    const fetchData = async () => {
      let data = [
        { key: 'Most Sales', metric: 'sales', order: 'desc' },
        { key: 'Most Spend', metric: 'cost', order: 'desc' },
        { key: 'Lowest ACoS', metric: 'acos', order: 'asc' },
        { key: 'Highest ACoS', metric: 'acos', order: 'desc' },
      ];

      data = await Promise.all(
        data.map(async (data) => {
          const response = await axios.get('/account/advertising/targetings', {
            params: {
              page: 1,
              pageSize: 1,
              accountId,
              marketplace,
              startDate,
              endDate,
              attributes: ['advTargetingId', 'value', ...attributes],
              ...additionalParams,
              sort: `${data.metric}:${data.order}`,
            },
          });

          return response.data.data.count
            ? { ...response.data.data.rows[0], key: data.key }
            : { key: data.key };
        })
      );

      if (isSubscribed) {
        setTargetings(data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    targetings.some((t) => t.advTargetingId) && (
      <div
        className={`${className} p-6 border border-gray-300 bg-white bs-shadow`}
      >
        <div className="flex items-center justify-between px-4 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
            Best vs. Worst Keywords
          </p>
        </div>

        <div className="px-4 w-full">
          <table className="w-full">
            <thead>
              <tr className="text-mini font-bold">
                <th className="border border-grayscale-500"></th>
                <th className="bg-success-dark text-white py-2 border border-grayscale-500">
                  Most sales
                </th>
                <th className="bg-error-dark text-white py-2 border border-grayscale-500">
                  Most Spend
                </th>
                <th className="bg-success-dark text-white py-2 border border-grayscale-500">
                  Lowest ACoS
                </th>
                <th className="bg-error-dark text-white py-2 border border-grayscale-500">
                  Highest ACoS
                </th>
              </tr>
            </thead>
            <tbody>
              {attributes
                .map((attr) => metrics.find((m) => m.key === attr))
                .map((metric, idx) => (
                  <tr
                    key={`best-vs-worst-targetings-${metric.key}`}
                    className={idx % 2 !== 1 ? 'bg-grayscale-400' : 'bg-white'}
                  >
                    <td className="text-mini text-grayscale-800 leading-1.5 p-2 border border-grayscale-500">
                      {metric.shortTitle ?? metric.title}
                    </td>
                    {targetings.map((targeting, idx) => (
                      <td
                        key={`best-vs-worst-targetings-${metric.key}-${targeting.advTargetingId}-${idx}`}
                        className={classNames(
                          loading ? 'animate-pulse' : '',
                          'text-mini text-grayscale-800 leading-1.5 p-2 text-right border border-grayscale-500'
                        )}
                      >
                        {loading ? (
                          <div className="h-5 w-full mt-1 mx-auto bg-gray-200 rounded"></div>
                        ) : metric.key in targeting ? (
                          metric.rawFormatter(targeting[metric.key])
                        ) : (
                          ''
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

export default BestVsWorstTargeting;
