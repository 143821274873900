import { useState } from 'react';
import DealGroup from './DealGroup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

const statuses = [
  {
    order: 1,
    name: 'Booked',
    value: 'booked',
  },
  { order: 2, name: 'Proposal', value: 'Proposal' },
  {
    order: 3,
    name: 'Transfer to Ops',
    value: 'Transfer to Operations',
  },
  {
    order: 4,
    name: 'Prospect',
    value: 'prospect',
  },
  {
    order: 5,
    name: 'Lost',
    value: 'Lost',
  },
  {
    order: 5,
    name: 'No Show',
    value: 'No Show',
  },
  {
    order: 6,
    name: 'Pending',
    value: 'Pending',
  },
];

const BoardView = ({ searchParams }) => {
  const [isRefreshStatus, setIsRefreshStatus] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="flex flex-row overflow-x-auto overflow-y-hidden space-x-4 "
        style={{ height: '90%' }}
      >
        {statuses.map((category) => (
          <DealGroup
            title={category.name}
            params={{
              pageSize: 5,
              sort: 'createdAt:asc',
              status: category.value,
              search: searchParams.search,
              fields: 'all',
              include: ['salesAdmin', 'leadsRep', 'boardViewStatus'],
              creationDate: searchParams.creationDate
                ? searchParams.creationDate
                : null,
              closeDate: searchParams.closeDate ? searchParams.closeDate : null,
              lastActivityDate: searchParams.lastActivityDate
                ? searchParams.lastActivityDate
                : null,
              dealType: searchParams.dealType,
              dealOwner: searchParams.dealOwner,
            }}
            isRefreshStatus={isRefreshStatus}
            setIsRefreshStatus={setIsRefreshStatus}
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default BoardView;
