import moment from 'moment';
import { upperFirst } from 'lodash';

import PercentageChange from 'components/PercentageChange';

const ChartToolTip = ({
  active,
  payload,
  CustomItemTitle,
  Title,
  width = '52',
  showComparison = true,
  comparisonText = 'vs. previous month',
}) => {
  const LineLabel = ({ item }) => (
    <div className="w-8 h-1" style={{ background: item.stroke }}></div>
  );

  const AreaLabel = ({ item }) => (
    <div>
      <div className="w-8 h-1" style={{ background: item.stroke }} />
      <div className="w-8 h-2 opacity-20" style={{ background: item.stroke }} />
    </div>
  );

  const BarLabel = ({ item }) => (
    <div
      className="w-2 h-2"
      style={{ background: item.fill, color: item.fill }}
    />
  );

  if (active && payload && payload.length) {
    return (
      <div
        className={`bg-white p-4 w-${width}`}
        style={{
          boxShadow: '0px 8px 16px 0px rgba(17, 17, 17, 0.06)',
        }}
      >
        <div>
          <Title payload={payload} />

          <div className="mt-2 space-y-4">
            {payload.map((item, id) => (
              <div key={id}>
                <div className="flex justify-between w-full">
                  <div className="flex items-center space-x-2 w-full">
                    {item.chartType === 'line' ? (
                      <LineLabel item={item} />
                    ) : item.chartType === 'area' ? (
                      <AreaLabel item={item} />
                    ) : (
                      <BarLabel item={item} />
                    )}

                    {CustomItemTitle ? (
                      <CustomItemTitle item={item} />
                    ) : (
                      <p className="text-13 leading-1.5 text-grayscale-900">
                        {item.name}
                      </p>
                    )}
                  </div>

                  <p className="text-13 leading-1.5 text-grayscale-900">
                    {item.formatter
                      ? item.formatter(item.payload[item.dataKey])
                      : item.payload[item.dataKey]}
                  </p>
                </div>

                {showComparison && (
                  <div className="flex items-center space-x-1 mt-2">
                    <PercentageChange
                      textSize="11"
                      currentValue={item.payload[item.dataKey]}
                      previousValue={
                        item.payload[`previous${upperFirst(item.dataKey)}`]
                      }
                    />
                    <span className="text-11 leading-1.5 text-grayscale-800">
                      {comparisonText}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default ChartToolTip;
