import axios from 'axios';
import { useState, useEffect } from 'react';

import SectionComment from '../components/SectionComment';

import { numberFormatter, percentageFormatter } from 'utils/formatters';

import metrics from '../../utils/metrics';

const attributes = [
  'cost',
  'sales',
  'impressions',
  'clicks',
  'orders',
  'unitsSold',
  'ctr',
  'cr',
  'acos',
  'cpm',
  'cpc',
  'cpcon',
];

const ConvertersOverview = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  showAllGraph = false,
  additionalParams = {},
  className = '',
  tableClassName = 'rounded-3xl bg-white bs-shadow py-6',
  tableHeaderClassName = 'flex items-center justify-between border-b px-8 pb-6',
  graphClassName = 'rounded-3xl bg-white bs-shadow py-6 mt-10',
  graphTitle = 'Compare Converters vs. Non Converters',
  comment = '',
  onChangeComment,
  editableComment = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState([
    { key: 'converters' },
    { key: 'nonConverters' },
    { key: 'all' },
  ]);

  const [selected, setSelected] = useState(['count', 'sales']);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes,
        ...additionalParams,
      };

      const response = await axios.get(
        '/account/advertising/targetings/conversions-summary',
        {
          params,
        }
      );

      if (isSubscribed) {
        setOverview(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  const comparisonOption = [
    {
      key: 'count',
      title: 'Keywords',
    },
    ...['cost', 'sales', 'impressions', 'clicks', 'orders', 'unitsSold'].map(
      (attribute) => metrics.find((metric) => metric.key === attribute)
    ),
  ];

  const onChangeSelected = (e) => {
    const { id, value } = e.target;
    let newSelected = [...selected];
    newSelected[id] = value;
    setSelected(newSelected);
  };

  return (
    overview.some((i) => i.count) && (
      <div className="relative">
        <SectionComment
          value={comment}
          onChangeValue={onChangeComment}
          editable={editableComment}
        />

        <div className={className}>
          <div className={tableClassName}>
            <div className={tableHeaderClassName}>
              <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
                Converters vs. Non Converters
              </p>
            </div>

            <div className="px-8 w-full mt-8">
              <table className="w-full">
                <thead>
                  <tr className="text-xs">
                    <th width="25%"></th>
                    <th className="bg-success-dark text-white py-2 font-bold text-mini leading-1.5">
                      Converters
                    </th>
                    <th
                      width="25%"
                      className="bg-error-dark text-white py-2 font-bold text-mini leading-1.5"
                    >
                      Non-Converters
                    </th>
                    <th
                      width="25%"
                      className="bg-grayscale-800 text-white py-2 font-bold text-mini leading-1.5"
                    >
                      All
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="py-2 px-4 text-mini leading-1.5 text-grayscale-800 border border-grayscale-500">
                      Keyword
                    </td>
                    {overview.map((element) => (
                      <td
                        key={`converters-overview-table-header-${element.key}`}
                        className="py-2 px-4 text-mini leading-1.5 text-grayscale-800 border border-grayscale-500 text-center"
                      >
                        {loading ? (
                          <div className="h-3 bg-gray-200 rounded animate-pulse"></div>
                        ) : (
                          numberFormatter(element.count)
                        )}
                      </td>
                    ))}
                  </tr>

                  {attributes
                    .map((attr) => metrics.find((m) => m.key === attr))
                    .map((metric, idx) => (
                      <tr
                        key={`converters-overview-table-row-${metric.key}`}
                        className={`${
                          idx % 2 !== 1 ? 'bg-grayscale-400' : 'bg-white'
                        }`}
                      >
                        <td className="py-2 px-4 text-mini leading-1.5 text-grayscale-800 border border-grayscale-500">
                          {metric.title}
                        </td>

                        {overview.map((element) => (
                          <td
                            key={`converters-overview-table-row-${metric.key}-${element.key}`}
                            className="py-2 px-4 text-mini leading-1.5 text-grayscale-800 border border-grayscale-500 text-center"
                          >
                            {loading ? (
                              <div className="h-3 bg-gray-200 rounded animate-pulse"></div>
                            ) : (
                              metric.rawFormatter(element[metric.key])
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className={graphClassName}>
            <div className="flex items-center justify-between px-8 pb-6">
              <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
                {graphTitle}
              </p>

              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="w-4 h-4 bg-success-dark"></div>
                  <span className="ml-1 mr-4 font-medium text-mini text-grayscale-800">
                    CONVERTERS
                  </span>
                </div>
                <div className="flex items-center mt-2">
                  <div className="w-4 h-4 bg-error-dark"></div>
                  <span className="ml-1 mr-4 font-medium text-mini text-grayscale-800">
                    NON-CONVERTERS
                  </span>
                </div>
              </div>
            </div>

            <div className="px-8 w-full mt-8">
              {loading ? (
                <div className="">
                  <div className="h-10 w-1/3 mx-auto bg-gray-200 rounded animate-pulse mb-5"></div>
                  <div className="h-16 w-full bg-gray-200 rounded animate-pulse mb-8"></div>
                  <div className="h-10 w-1/3 mx-auto bg-gray-200 rounded animate-pulse mb-5"></div>
                  <div className="h-16 w-full bg-gray-200 rounded animate-pulse"></div>
                </div>
              ) : showAllGraph ? (
                comparisonOption
                  .filter((option) =>
                    ['count', 'cost', 'impressions', 'clicks'].includes(
                      option.key
                    )
                  )
                  .map((metric) => {
                    const [converters, nonConverters, all] = overview;

                    const converterPercentage = all[metric.key]
                      ? converters[metric.key] / all[metric.key]
                      : 0;

                    const nonConverterPercentage = all[metric.key]
                      ? nonConverters[metric.key] / all[metric.key]
                      : 0;

                    return (
                      <div
                        key={`converters-overview-compare-${metric.key}-all-graph`}
                        className="grid grid-cols-4 mb-5 flex items-center"
                      >
                        <p>{metric.title}</p>
                        <div className="flex col-span-3 text-white font-bold leading-1.5">
                          <div
                            style={{ width: `${converterPercentage * 100}%` }}
                            className="bg-success-dark py-6 text-center"
                          >
                            {percentageFormatter(converterPercentage)}
                          </div>
                          <div
                            className="bg-error-dark py-6 text-center"
                            style={{
                              width: `${nonConverterPercentage * 100}%`,
                            }}
                          >
                            {percentageFormatter(nonConverterPercentage)}
                          </div>
                        </div>
                      </div>
                    );
                  })
              ) : (
                selected
                  .map((attribute) =>
                    comparisonOption.find((m) => m.key === attribute)
                  )
                  .map((metric, index) => {
                    const [converters, nonConverters, all] = overview;

                    const converterPercentage = all[metric.key]
                      ? converters[metric.key] / all[metric.key]
                      : 0;

                    const nonConverterPercentage = all[metric.key]
                      ? nonConverters[metric.key] / all[metric.key]
                      : 0;

                    return (
                      <div
                        key={`converters-overview-compare-${metric.key}`}
                        className="mb-8"
                      >
                        <div className="flex justify-center">
                          <select
                            className="bg-grayscale-400 w-48 py-2 px-5 rounded-2xl border-0 mb-5 text-mini focus:outline-none focus:ring-0"
                            id={index}
                            value={selected[index]}
                            onChange={onChangeSelected}
                          >
                            {comparisonOption
                              .filter((m) =>
                                index
                                  ? m.key !== selected[0]
                                  : m.key !== selected[1]
                              )
                              .map((metricOption) => (
                                <option
                                  key={`converters-overview-compare-${metric.key}-option-${metricOption.key}`}
                                  value={metricOption.key}
                                >
                                  {metricOption.title}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="flex text-white font-bold leading-1.5">
                          <div
                            style={{ width: `${converterPercentage * 100}%` }}
                            className="bg-success-dark py-6 text-center"
                          >
                            {percentageFormatter(converterPercentage)}
                          </div>
                          <div
                            className="bg-error-dark py-6 text-center"
                            style={{
                              width: `${nonConverterPercentage * 100}%`,
                            }}
                          >
                            {percentageFormatter(nonConverterPercentage)}
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ConvertersOverview;
