import FilterConditionItem from './FilterConditionItem';

const FilterConditionPicker = ({
  filters = [],
  updateFilterItem,
  errors = {},
}) => {
  const onSelectItem = (item) => {
    updateFilterItem({ ...item, selected: true });
  };

  return (
    <div className="w-full mt-8">
      <div className="w-full py-3.5 px-4 rounded-t-2xl text-grayscale-800 font-inter text-sm tracking-3/4 font-bold focus:outline-none bg-grayscale-400">
        <p>Filter Conditions</p>
        {errors.filters && (
          <span className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
            {errors.filters}
          </span>
        )}
      </div>

      <div className="px-4 pb-4 text-sm text-gray-500 bg-grayscale-400 rounded-b-2xl">
        {filters.filter((item) => item.selected).length ? (
          <div className="grid gap-5 grid-cols-3 2xl:grid-cols-4">
            {filters
              .filter((item) => item.selected)
              .map((item, idx) => (
                <FilterConditionItem
                  key={`rules-filter-conditions-picker-selected${
                    item.key ?? item.attribute
                  }-item`}
                  item={item}
                  updateItem={updateFilterItem}
                  hasError={[
                    `filters.${idx}.value.0`,
                    `filters.${idx}.value.0`,
                    `filters.${idx}.value`,
                  ].some((errorKey) => !!errors[errorKey])}
                />
              ))}
          </div>
        ) : (
          <p className="text-center tracking-2 text-grayscale-700 font-bold py-4">
            You haven’t added any conditions to your rule yet.
          </p>
        )}

        {!!filters.filter((item) => !item.selected).length && (
          <div className="mt-4 border-grayscale-500 border-t pt-4">
            <p className="text-tiny font-bold text-grayscale-700 font-medium tracking-3/4">
              Available filters:
            </p>
            {filters
              .filter((item) => !item.selected)
              .map((item) => (
                <button
                  key={`rules-filter-conditions-picker-options-${
                    item.key ?? item.attribute
                  }-item`}
                  className="bs-shadow px-3 py-2 text-tiny tracking-3/4 font-inter rounded-3xl mr-2 mb-2 text-grayscale-800 bg-white border-grayscale-500 border"
                  onClick={() => onSelectItem(item)}
                >
                  {item.title}
                </button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterConditionPicker;
