import { CheckIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { Label } from 'components/Forms';
import PriceLabel from 'components/PriceLabel';
import {
  dateFormatter,
  getNameInitials,
  nameFormatter,
} from 'utils/formatters';
import UserProfileAvatar from 'components/UserProfileAvatar';
import { UserDisplay } from 'components';

const AppliedCreditsTable = ({ creditNote }) => {
  return (
    <>
      {creditNote.appliedToInvoices &&
        creditNote.appliedToInvoices.length > 0 && (
          <div className="flex flex-col">
            <Label>Credits applied to invoices</Label>
            <div className="border rounded-lg overflow-hidden mt-2">
              <div className="grid grid-cols-4 bg-grayscale-400 text-xs text-grayscale-700 py-2">
                <div className="col-span-1 pl-6">Invoice Number</div>
                <div className="col-span-1 text-right">Applied Credits</div>
                <div className="col-span-1 text-center">Applier</div>
                <div className="col-span-1 text-center pr-0">Date Applied</div>
              </div>

              {creditNote.appliedToInvoices.map((row, index) => (
                <div
                  className="grid grid-cols-4 disabled:opacity-30 items-center py-1 justify-between w-full text-left hover:bg-grayscale-300 text-grayscale-800  py-0 text-sm rounded-md focus:ring-gray-300 focus:border-gray-200"
                  key={`${row.creditNoteInvoiceId}-${index}`}
                  value={row.invoiceId}
                >
                  <p className="col-span-1 pl-4 flex items-center">
                    <CheckIcon className="w-6 h-6 inline text-success mr-1" />
                    <Link
                      to={`/invoices/${row.invoiceId}`}
                      title={row.creditNoteInvoiceId}
                      className="text-secondary-light underline"
                    >
                      {row.invoice?.invoiceNumber ?? row.invoiceNumber}
                    </Link>
                  </p>
                  <div className="col-span-1 text-right">
                    <PriceLabel
                      price={parseFloat(row.amountApplied)}
                      color="text-success"
                    />
                  </div>
                  <div className="col-span-1 text-center">
                    {nameFormatter(row.applier)}
                  </div>
                  <p className="col-span-1 text-center pr-0">
                    {dateFormatter(row.createdAt)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default AppliedCreditsTable;
