import { Fragment, useMemo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'utils/classNames';
import useTasks from '../../useTasks';

const PriorityDropdown = ({ value, onChange }) => {
  const { PRIORITIES } = useTasks();

  const priority = useMemo(
    () => PRIORITIES.find((item) => item.value === value),
    [value]
  );

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button
          className={classNames(
            'text-13 leading-1.5 w-full py-2 rounded-40',
            priority?.className
          )}
        >
          {priority?.text ?? 'None'}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-full z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="space-y-4 p-4">
            {PRIORITIES.map((option) => (
              <Menu.Item as="div" key={option.value}>
                {({ active }) => (
                  <button
                    onClick={() => onChange(option.value)}
                    className={classNames(
                      'text-13 leading-1.5 w-full py-2 rounded-40',
                      option.className
                    )}
                  >
                    {option.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PriorityDropdown;
