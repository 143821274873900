import TaskForm from './TaskForm';
import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import useTaskManagement from 'features/taskManagement/useTaskManagement';
import TaskGroup from './TaskGroup';

const TemplateTasks = ({ template, layout }) => {
  const [addTask, setAddTask] = useState(false);
  const { DEPARTMENTS } = useTaskManagement();

  return (
    <div className="space-y-8 mt-8">
      <div className="flex space-x-4 items-center">
        <h4 className="font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900 font-bold">
          Tasks
        </h4>

        <button
          onClick={() => setAddTask(true)}
          className="border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
        >
          <PlusIcon className="w-3 h-3" />
          <span>Add</span>
        </button>
      </div>

      {addTask && (
        <TaskForm
          className="bg-white p-4"
          taskListTemplate={template}
          onCancel={() => setAddTask(false)}
          onSuccess={() => setAddTask(false)}
          layoutClass={{ columns: layout }}
        />
      )}

      <div className="space-y-8">
        {template.type === 'onboarding' ? (
          <TaskGroup
            template={template}
            label="Onboarding tasks"
            params={{
              taskListTemplateId: template.taskListTemplateId,
            }}
            layoutClass={{ columns: layout }}
          />
        ) : (
          DEPARTMENTS.map((department) => (
            <TaskGroup
              template={template}
              key={department.value}
              label={department.label}
              layoutClass={{ columns: layout }}
              params={{
                department: department.value,
                taskListTemplateId: template.taskListTemplateId,
              }}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TemplateTasks;
