import axios from 'axios';
import { sortBy, startCase } from 'lodash';
import { useEffect, useState } from 'react';

import classNames from 'utils/classNames';

const Overview = ({ account, marketplace }) => {
  const [overview, setOverview] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/data-sync/categories');

      if (isSubscribed) {
        console.log(sortBy(response.data.data, 'module'));
        setCategories(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/data-sync/initial', {
        params: {
          sellerId: account.sellingPartnerId,
          marketplaceId: marketplace.marketplaceId,
          pageSize: 50,
        },
      });

      if (isSubscribed) {
        setOverview(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace]);

  const Status = ({ categoryId }) => {
    const colors = {
      PENDING: 'bg-grayscale-800',
      COMPLETED: 'bg-success',
      'IN-PROGRESS': 'bg-blue-500',
    };

    const status =
      overview.find((i) => i.categoryId === categoryId)?.status || 'PENDING';

    return (
      <p
        className={classNames(
          'text-white rounded-2xl px-2 whitespace-nowrap py-1 font-inter',
          colors[status]
        )}
      >
        {status}
      </p>
    );
  };

  return (
    <div className="bg-white p-5 rounded-md">
      <table>
        <thead>
          <tr>
            <td className="text-mini font-bold text-grayscale-800 leading-1.5 py-5 px-4">
              Module
            </td>
            <td className="text-mini font-bold text-grayscale-800 leading-1.5 py-5 px-4">
              Data Type
            </td>
            <td className="text-mini font-bold text-grayscale-800 leading-1.5 py-5 px-4">
              Description
            </td>
            <td className="text-mini font-bold text-grayscale-800 leading-1.5 py-5 px-4">
              Status
            </td>
          </tr>
        </thead>

        <tbody className="">
          {sortBy(categories, 'module').map((category) => (
            <tr key={category.id}>
              <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4">
                {startCase(category.module)}
              </td>
              <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4">
                {startCase(category.name)}
              </td>
              <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4">
                {category.description}
              </td>
              <td className="text-8 tracking-3/4  text-grayscale-800 leading-1.5 py-5 px-4 text-center">
                <Status categoryId={category.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Overview;
