import Avatar from 'components/Avatar';
import { getNameInitials } from 'utils/formatters';

const NewClientAssignee = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { sender, data, message, title } = notification;

  const onClick = () => {
    markAsRead();
    router.push(`/clients/redirect/${data.agencyClientId}`);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <Avatar
        size="48px"
        imageSrc={sender?.avatar?.thumbnailUrl}
        initials={getNameInitials(sender.firstName, sender.lastName)}
      />

      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>
      </div>

      {children}
    </div>
  );
};

export default NewClientAssignee;
