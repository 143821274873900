import { Tab } from '@headlessui/react';
import { startCase } from 'lodash';

import CustomTargetCampaign from './CustomTargetCampaign';
import CustomTargetListing from './CustomTargetListing';
import CustomTargetPortfolio from './CustomTargetPortfolio';

import classNames from 'utils/classNames';

const CustomTarget = ({
  accountId,
  marketplace,
  campaignType,
  campaignIds,
  portfolioIds,
  listingIds,
  onChange,
}) => {
  const tabs = ['portfolios', 'campaigns', 'products'];

  return (
    <div className="w-full mt-8">
      <div className="flex w-full py-3.5 px-4 rounded-t-2xl text-grayscale-800 font-inter text-sm tracking-3/4 font-bold focus:outline-none bg-grayscale-400">
        Custom Target
        {!!(campaignIds.length + portfolioIds.length + listingIds.length) && (
          <span className="ml-2">
            ({campaignIds.length + portfolioIds.length + listingIds.length}
            {!!campaignIds.length && ' Campaigns'}
            {!!portfolioIds.length && ' Portfolios'}
            {!!listingIds.length && ' Products'})
          </span>
        )}
      </div>

      <div className="px-4 pb-4 text-sm text-gray-500 bg-grayscale-400 rounded-b-2xl">
        <div className="w-full">
          <Tab.Group>
            <Tab.List className="grid grid-cols-6 rounded-xl bg-blue-900/20">
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-t-xl py-2.5 text-mini font-bold text-grayscale-700 font-medium tracking-3/4',
                      selected ? 'bg-white' : ''
                    )
                  }
                >
                  {startCase(tab)}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="">
              <Tab.Panel className="rounded-b-xl bg-white p-3">
                <CustomTargetPortfolio
                  accountId={accountId}
                  marketplace={marketplace}
                  campaignType={campaignType}
                  value={portfolioIds}
                  onChangeValue={(values) =>
                    onChange('advPortfolioIds', values)
                  }
                  disabled={listingIds.length || campaignIds.length}
                />
              </Tab.Panel>

              <Tab.Panel className="rounded-b-xl bg-white p-3">
                <CustomTargetCampaign
                  accountId={accountId}
                  marketplace={marketplace}
                  campaignType={campaignType}
                  value={campaignIds}
                  onChangeValue={(values) => onChange('advCampaignIds', values)}
                  disabled={listingIds.length || portfolioIds.length}
                />
              </Tab.Panel>

              <Tab.Panel className="rounded-b-xl bg-white p-3">
                <CustomTargetListing
                  accountId={accountId}
                  marketplace={marketplace}
                  campaignType={campaignType}
                  value={listingIds}
                  onChangeValue={(values) => onChange('products', values)}
                  disabled={campaignIds.length || portfolioIds.length}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default CustomTarget;
