import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Label from 'components/Forms/Label';
import Button from 'components/Button';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import { getLatestUpdates } from '../salesSlice';

const FilesForm = ({ data, setOpen, getAttachments, params, dispatch }) => {
  const [initialValues, setInitialValues] = useState({ title: '' });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const title = data.title.replace(`.${data.extension}`, '');
    setInitialValues({ title });
  }, [data]);

  const onUpdateFile = async (values) => {
    let modulePath = data.agencyClientAttachmentId ? 'clients' : 'sales';
    let attachmentId = data.agencyClientAttachmentId ?? data.salesAttachmentId;
    setSaving(true);
    await axios
      .put(`/agency/${modulePath}/attachments/${attachmentId}`, values)
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          dispatch(
            setAlert('success', 'File name is updated', res.data.message)
          );
          dispatch(getAttachments({ params }));
          let clientId = data.salesClientId ?? data.agencyClientId;
          dispatch(getLatestUpdates(clientId));
        } else {
          dispatch(setAlert('error', 'Input error', res.data.message));
        }
      });
    setSaving(false);
  };

  const onSubmit = async (values) => {
    onUpdateFile(values);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const validationSchema = object().shape({
    title: string().required('Required'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <Label>File name</Label>
                <div className="flex items-center	">
                  <div className="w-3/4">
                    <Field
                      name="title"
                      placeholder="name"
                      className="form-select text-sm"
                      onChange={(e) => handleChange(e)}
                      type="text"
                    />
                  </div>
                  <div>
                    <p>.{data.extension}</p>
                  </div>
                </div>

                <ErrorMessage
                  name="key"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={(e) => {
                  onCancel(e);
                }}
                className="mt-2"
                color="green"
              >
                Cancel
              </button>
              <Button
                onClick={() => console.log('')}
                type="submit"
                classes="mt-2"
                showLoading={true}
                loading={saving}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FilesForm;
