import React, { useState } from 'react';
import moment from 'moment';
import cx from 'classnames';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/calendar.css';
import axios from 'axios';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { Field } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

moment.tz.setDefault('America/Toronto');

const CustomCalendar = ({
  salesReps,
  handleChange,
  setFieldValue,
  handleSubmit,
  open,
  setOpen,
  values,
  errors,
}) => {
  const dispatch = useDispatch();
  const minDate = new Date(
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      4,
      0,
      0
    )
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        4,
        0,
        0
      )
    )
  );
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [data, setData] = useState();

  const customShortWeekday = (locale, date) => {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()];
  };

  const handleDateClick = (date) => {
    if (!values.salesRep) {
      dispatch(
        setAlert(
          'error',
          'Missing Sales Representative',
          'Please select a sales representative before selecting a date.'
        )
      );
      return null;
    }
    const [userId, email] = values.salesRep.split(',');

    setSelectedDate(null);
    axios
      .get(`/agency/leads/call-booking`, {
        params: {
          email: email,
          date: new Date(
            Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              4,
              0,
              0
            )
          ),
        },
      })
      .then((res) => {
        setData(res.data.data);
        setSelectedDate(
          new Date(
            Date.UTC(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              4,
              0,
              0
            )
          )
        );
      })
      .catch((err) => {
        setSelectedDate(
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              4,
              0,
              0
            )
          )
        );
        dispatch(
          setAlert(
            'error',
            'Invalid Email',
            'Please select a sales representative with a valid email.'
          )
        );
        console.log(err);
      });
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    setFieldValue('date', selectedDate.toISOString().substring(0, 10));
    setFieldValue('time', slot);
  };
  const isDisabled = (date, slot, data) => {
    if (!data) {
      return false;
    }
    const dateObj = moment(date)
      .hour(slot.split(':')[0])
      .minute(slot.split(':')[1]);
    const start = moment(date).hour(9).minute(0);
    const end = moment(date).hour(17).minute(0);

    let isOverlappingEvent = false;
    for (const event of data) {
      if (!isEmpty(event)) {
        const eventStart = moment.tz(
          event.start.dateTime,
          event.start.timeZone
        );
        const eventEnd = moment.tz(event.end.dateTime, event.end.timeZone);

        if (
          dateObj.isBetween(
            eventStart,
            eventEnd.subtract(1, 'seconds'),
            'minute',
            '[]'
          )
        ) {
          isOverlappingEvent = true;
          break;
        }
      }
    }

    return !dateObj.isBetween(start, end, 'minute', '[]') || isOverlappingEvent;
  };

  const renderSlots = (date) => {
    if (!selectedDate) {
      return null;
    }

    const slots = [];
    const start = moment(date).hour(5).minute(0);
    const end = moment(date).hour(17).minute(0);

    while (start.isBefore(end)) {
      const slot = start.format('HH:mm');

      slots.push(
        <div
          key={slot}
          className={cx(
            'py-3 px-4  border-2 border-blue-900 cursor-pointer text-sm my-2 rounded-full',
            {
              'text-white bg-blue-500 hover:bg-blue-600': selectedSlot === slot,
              'text-blue-800 font-bold hover:bg-blue-100':
                selectedSlot !== slot,
            }
          )}
          onClick={() => handleSlotClick(slot)}
        >
          <div className="flex justify-center items-center">
            <span>{slot}</span>
          </div>
        </div>
      );
      start.add(30, 'minutes');
    }

    return (
      <div
        style={{ minWidth: '200px' }}
        className="overflow-y-scroll scroll-bar"
      >
        <div></div>{' '}
        <div className="flex flex-col justify-center items-center "></div>
        <div>{slots}</div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        zIndex="z-10"
        persistent={true}
      >
        <div className="inline-block   my-8 p-8  text-left transition-all transform bg-white shadow-xl rounded-xl space-y-8">
          <ModalHeader
            title={
              <div className="flex items-center space-x-4">
                <span>Direct Booking</span>
              </div>
            }
            setOpen={setOpen}
            titleClasses="capitalize"
            border=""
            fontSize="text-xl"
            fontStyle="font-bold"
            px="px-0"
            py="py-0"
          />{' '}
          <div className="space-y-2 w-2/5">
            {' '}
            <Label>
              Sales Representative
              <RequiredAsterisk />
            </Label>
            <Field
              name="salesRep"
              as="select"
              className="form-select text-sm "
              onChange={(e) => handleChange(e)}
            >
              <option value=""></option>
              {salesReps.map((rec) => {
                return (
                  <option value={[rec.userId, rec.email]}>
                    {rec.firstName}
                  </option>
                );
              })}
            </Field>
          </div>
          <div
            style={{ minWidth: '650px' }}
            className=" rounded-lg  bg-white p-4"
          >
            <div className="flex justify-between">
              <div className="space-y-4">
                {' '}
                <Label>
                  {' '}
                  Select Date
                  <RequiredAsterisk />
                </Label>
                <Calendar
                  onChange={handleDateClick}
                  formatShortWeekday={customShortWeekday}
                  minDate={minDate}
                />
              </div>
              {selectedDate ? (
                <div className="flex flex-col items-start">
                  {' '}
                  <Label>
                    {' '}
                    Select Available Time
                    <RequiredAsterisk />
                  </Label>{' '}
                  <div className="mt-4">
                    {selectedDate &&
                      selectedDate.toLocaleString('en-US', {
                        weekday: 'long',
                        month: 'long',
                        day: '2-digit',
                      })}{' '}
                    (EST)
                  </div>
                  <Label>Duration: 30 Minutes</Label>{' '}
                  <div className="flex flex-col h-80 overflow-y-hidden mt-2">
                    {selectedDate && renderSlots(selectedDate)}
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-center items-center bg-white-300 rounded-lg">
                  <Spinner color="#002F5D" width={100} height={100} />
                </div>
              )}
            </div>
            <div className="flex space-x-4 items-center">
              <Button
                bgColor="white"
                shadow=""
                textColor="blue-500"
                fontWeight="bold"
                color="white"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                bgColor="blue-500"
                border="rounded-full"
                px={8}
                color="blue-700"
                onClick={() => {
                  values.date && values.time
                    ? handleSubmit()
                    : dispatch(
                        setAlert(
                          'error',
                          'Date/Time Missing',
                          'Both date and time should be selected.'
                        )
                      );
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CustomCalendar;
