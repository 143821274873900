import axios from 'axios';
import * as yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { ExclamationIcon } from '@heroicons/react/solid';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Label from 'components/Forms/Label';

import usePermissions from 'hooks/usePermissions';

import { setAlert } from 'features/alerts/alertsSlice';
import { setUserOnly } from 'features/auth/authSlice';

import { getNameInitials } from 'utils/formatters';

const General = () => {
  const dispatch = useDispatch();
  const { isAgencyLevel } = usePermissions();
  const { user } = useSelector((state) => state.auth);

  const [updatingProfilePic, setUpdatingProfilePic] = useState(false);

  const onSubmit = (values, actions) => {
    axios
      .put('/auth/me', values)
      .then((response) => {
        dispatch(setUserOnly(response.data.data));
        dispatch(setAlert('success', response.data.message));
      })
      .catch((err) => actions.setErrors(err.response.data.errors || {}))
      .finally(() => actions.setSubmitting(false));
  };

  const onRemoveAvatar = () => {
    setUpdatingProfilePic(true);

    axios
      .delete(`/users/${user.userId}/profile-picture`)
      .then(() => dispatch(setUserOnly({ ...user, avatar: null })))
      .catch(() =>
        dispatch(setAlert('error', 'Failed to remove profile picture.'))
      )
      .finally(() => setUpdatingProfilePic(false));
  };

  const onChangeAvatar = (e) => {
    if (!e.target.files.length) return;

    const form = new FormData();
    form.append('file', e.target.files[0]);

    setUpdatingProfilePic(true);

    axios
      .post(`/users/${user.userId}/profile-picture`, form)
      .then((response) =>
        dispatch(setUserOnly({ ...user, avatar: response.data.data }))
      )
      .catch(() =>
        dispatch(setAlert('error', 'Failed to upload profile picture.'))
      )
      .finally(() => setUpdatingProfilePic(false));
  };

  return (
    <div className=" px-5 py-5 bg-white">
      <p className="text-base font-inter font-bold tracking-3/4 text-grayscale-900 mb-5">
        Profile
      </p>

      <Avatar
        size="8rem"
        textSize="3.75rem"
        editable={true}
        onRemove={onRemoveAvatar}
        onChange={onChangeAvatar}
        imageSrc={updatingProfilePic ? '' : user.avatar?.thumbnailUrl}
        isLoading={updatingProfilePic}
        initials={getNameInitials(user.firstName, user.lastName)}
      />

      {isAgencyLevel() && (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                As an employee, you're not allowed to update your profile.
              </p>
            </div>
          </div>
        </div>
      )}

      <Formik
        initialValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
        }}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          firstName: yup.string().required('First name is required'),
          lastName: yup.string().required('Last name is required'),
          email: yup.string().required('Email Address is required'),
        })}
      >
        {({ handleChange, isSubmitting }) => (
          <Form>
            <div className="grid md:grid-cols-12 gap-5 mt-5">
              <div className="md:col-span-6">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  First Name
                </Label>

                <Field
                  name="firstName"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  disabled={isAgencyLevel()}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>

              <div className="md:col-span-6">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  Last Name
                </Label>
                <Field
                  name="lastName"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  disabled={isAgencyLevel()}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>

              <div className="md:col-span-6">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  Email Address
                </Label>
                <Field
                  name="email"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  disabled={isAgencyLevel()}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>
            </div>

            {!isAgencyLevel() && (
              <div className="flex justify-end mt-5">
                <Button
                  disabled={isAgencyLevel()}
                  type="submit"
                  loading={isSubmitting}
                  showLoading={true}
                >
                  Submit
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default General;
