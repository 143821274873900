import axios from 'axios';
import moment from 'moment';

import { useRoles } from 'features/roles';
import { useEffect, useState, useMemo } from 'react';
import { upperFirst, camelCase } from 'lodash';
import useClient from 'features/clients/useClient';
import { Table, UserDisplay } from 'components';
import { currencyFormatter } from 'utils/formatters';
import ClientsModal from './ClientsModal';

const TeamMembersTable = ({ year, supervisorId }) => {
  const { SERVICES } = useClient();
  const { BRAND_STRATEGIST } = useRoles();

  const endDate = useMemo(
    () => moment(year).endOf('year').format('YYYY-MM-DD'),
    [year]
  );

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openClients, setOpenClients] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [clientListFilterGroups, setClientListFilterGroups] = useState([]);
  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

      const response = await axios.get('/v2/users/list', {
        params: {
          ...params,
          endDate,
          startDate,
          supervisorId,
          'role.code': BRAND_STRATEGIST.value,
          include: ['avatar'],
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'totalAssignedActiveClients',
            'totalAssignedInactiveClients',
            'totalAssignedActiveRecurringClientsMonthlyRetainer',
            ...SERVICES.map((serviceType) => {
              let serviceKey = upperFirst(camelCase(serviceType.value));

              return `totalAssignedActiveClientsWith${serviceKey}Service`;
            }),
          ],
        },
      });

      if (isSubscribed) {
        setMembers(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year, params, supervisorId]);

  const columns = [
    {
      dataField: 'firstName',
      text: 'Name',
      sort: true,
      headerStyle: {
        minWidth: '250px',
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => (
        <UserDisplay data-first-name={cell} user={row} />
      ),
    },
    {
      dataField: `totalAssignedActiveClients`,
      text: 'Total Accounts',
      sort: true,
      classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap text-center`,
      formatter: (cell, user) =>
        cell ? (
          <span
            className="cursor-pointer text-secondary-light underline"
            onClick={() => {
              setSelectedUser(user);
              setClientListFilterGroups([
                {
                  filters: [
                    { operator: 'EQ', attribute: 'status', value: 'ACTIVE' },
                    {
                      operator: 'EQ',
                      attribute: 'brandStrategist.userId',
                      value: user.userId,
                    },
                    {
                      operator: 'LTE',
                      attribute: 'contractSigned',
                      value: endDate,
                    },
                  ],
                },
                {
                  filters: [
                    {
                      operator: 'EQ',
                      attribute: 'brandStrategist.userId',
                      value: user.userId,
                    },
                    { operator: 'EQ', attribute: 'status', value: 'INACTIVE' },
                    {
                      operator: 'LTE',
                      attribute: 'contractSigned',
                      value: endDate,
                    },
                    {
                      operator: 'GT',
                      attribute: 'terminatedAt',
                      value: endDate,
                    },
                  ],
                },
              ]);

              setOpenClients(true);
            }}
          >
            {cell}
          </span>
        ) : (
          0
        ),
    },
    {
      dataField: `totalAssignedInactiveClients`,
      text: 'Term Clients',
      sort: true,
      classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap text-center`,
      formatter: (cell, user) =>
        cell ? (
          <span
            className="cursor-pointer text-secondary-light underline"
            onClick={() => {
              setSelectedUser(user);
              setClientListFilterGroups([
                {
                  filters: [
                    {
                      operator: 'EQ',
                      attribute: 'brandStrategist.userId',
                      value: user.userId,
                    },
                    { operator: 'EQ', attribute: 'status', value: 'INACTIVE' },
                    {
                      operator: 'LTE',
                      attribute: 'terminatedAt',
                      value: endDate,
                    },
                  ],
                },
              ]);

              setOpenClients(true);
            }}
          >
            {cell}
          </span>
        ) : (
          0
        ),
    },
    {
      dataField: `totalAssignedActiveRecurringClientsMonthlyRetainer`,
      text: 'Monthly Retainer',
      sort: true,
      formatter: (cell) => currencyFormatter(cell ?? 0),
      classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap text-center`,
    },
    ...SERVICES.map((serviceType) => {
      let serviceKey = upperFirst(camelCase(serviceType.value));

      return {
        dataField: `totalAssignedActiveClientsWith${serviceKey}Service`,
        text: serviceType.label,
        sort: true,
        classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap text-center`,
        formatter: (cell, user) =>
          cell ? (
            <span
              className="cursor-pointer text-secondary-light underline"
              onClick={() => {
                setSelectedUser(user);
                setClientListFilterGroups([
                  {
                    filters: [
                      { operator: 'EQ', attribute: 'status', value: 'ACTIVE' },
                      {
                        operator: 'EQ',
                        attribute: 'service',
                        value: serviceType.value,
                      },
                      {
                        operator: 'EQ',
                        attribute: 'brandStrategist.userId',
                        value: user.userId,
                      },
                      {
                        operator: 'LTE',
                        attribute: 'contractSigned',
                        value: endDate,
                      },
                    ],
                  },
                  {
                    filters: [
                      {
                        operator: 'EQ',
                        attribute: 'brandStrategist.userId',
                        value: user.userId,
                      },
                      {
                        operator: 'EQ',
                        attribute: 'service',
                        value: serviceType.value,
                      },
                      {
                        operator: 'EQ',
                        attribute: 'status',
                        value: 'INACTIVE',
                      },
                      {
                        operator: 'LTE',
                        attribute: 'contractSigned',
                        value: endDate,
                      },
                      {
                        operator: 'GT',
                        attribute: 'terminatedAt',
                        value: endDate,
                      },
                    ],
                  },
                ]);

                setOpenClients(true);
              }}
            >
              {cell}
            </span>
          ) : (
            0
          ),
      };
    }),
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div>
      {openClients && (
        <ClientsModal
          open={openClients}
          setOpen={setOpenClients}
          filterGroups={clientListFilterGroups}
        />
      )}

      <div className="flex items-center justify-between mb-8">
        <h3 className="leading-1.2 font-bold tracking-3/4 font-inter text-grayscale-900">
          Team Members Client Count
        </h3>

        <p className="leading-1.5 text-grayscale-900">{year}</p>
      </div>

      <Table
        loading={loading}
        keyField="userId"
        columns={columns.map((column) => {
          return {
            ...column,
            classes: column.classes
              ? column.classes
              : `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: column.headerClasses
              ? column.headerClasses
              : `text-left py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={members}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default TeamMembersTable;
