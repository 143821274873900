import {
  Link,
  Route,
  Switch,
  Redirect,
  withRouter,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import classNames from 'utils/classNames';
import { linkStyles, tabStyles } from 'utils/tabStyles';

import Onboarding from './tasks/Onboarding';
import TaskList from './tasks/TaskList';

const Tasks = ({ location }) => {
  const { agencyClientId } = useParams();
  const { url } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [taskLists, setTaskLists] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get('/v2/tasks/lists', { params: { clientId: agencyClientId } })
      .then((response) => {
        setTaskLists(response.data.data);
        setLoading(false);
      });
  }, [agencyClientId]);

  const tabs = [
    {
      text: 'Onboarding',
      value: 'client_onboarding_task_list',
      component: Onboarding,
    },
    {
      text: 'Operations',
      value: 'client_operation_task_list',
    },
    {
      text: 'Writing',
      value: 'client_copy_writing_task_list',
    },
    {
      text: 'Design',
      value: 'client_design_task_list',
    },
    {
      text: 'PPC',
      value: 'client_ppc_task_list',
    },
    {
      text: 'Billing',
      value: 'client_billing_task_list',
    },
    {
      text: 'Sales',
      value: 'client_sales_task_list',
    },
    {
      text: 'Leads',
      value: 'client_lead_generation_task_list',
    },
    {
      text: 'Admin',
      value: 'client_admin_task_list',
    },
  ].map((tab) => ({ ...tab, href: `${url}/${tab.text.toLowerCase()}` }));

  return (
    <div>
      {loading ? (
        <div className="my-5">
          <p className="text-center font-inter text-3xl tracking-2 font-bold text-grayscale-700 animate-pulse">
            Fetching Client Tasks. Please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="tabnav tablinks flex mt-5">
            {tabs.map((tab, idx) => (
              <Link
                to={tab.href}
                key={tab.value}
                className={classNames(
                  'cursor-pointer',
                  linkStyles,
                  tabStyles(
                    idx,
                    tabs.findIndex((tab) => tab.href === location.pathname),
                    tabs.length - 1
                  )
                )}
              >
                {tab.text}
              </Link>
            ))}
          </div>
          <div className="">
            <Switch>
              <Route
                exact
                path={url}
                render={() => <Redirect to={`${url}/onboarding`} />}
              />

              {tabs
                .map((tab) => ({
                  ...tab,
                  component: tab.component ?? TaskList,
                }))
                .map((tab) => (
                  <Route
                    key={`client-tasks-${tab.value}`}
                    path={tab.href}
                    render={() => (
                      <tab.component
                        taskList={taskLists.find(
                          (taskList) => taskList.code === tab.value
                        )}
                      />
                    )}
                  />
                ))}
            </Switch>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Tasks);
