import axios from 'axios';
import { usePopper } from 'react-popper';
import ReactTooltip from 'react-tooltip';
import { Popover } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { debounce, isFunction } from 'lodash';
import { UserAddIcon, SearchIcon, XIcon } from '@heroicons/react/outline';

import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';
import InputPrepend from './Forms/InputPrepend';
import Avatar from './Avatar';

const UserSelector = ({
  size = '50px',
  iconSize = '24px',
  value = null,
  disabled,
  position = 'left-1/2 -translate-x-1/2 transform',
  width = 'w-64',
  onChange,
  onDelete,
  params = {},
  className = 'w-full max-w-sm px-4',
}) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setIsLoading(true);

      const response = await axios.get('/v2/users', {
        params: { ...params, search, include: ['avatar'] },
      });

      if (isSubscribed) {
        setUsers(response.data.data);
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchData().catch(console.error);
    }

    return () => (isSubscribed = false);
  }, [params, isOpen, search]);

  const onDebouncedSearch = debounce((e) => {
    setSearch(e.target.value.toLowerCase());
  }, 500);

  return (
    <div className={className}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              ref={setReferenceElement}
              disabled={disabled}
              className="w-full flex justify-center"
              onClick={() => setIsOpen(!open)}
            >
              {value ? (
                <>
                  <div
                    data-tip
                    className="relative"
                    data-for={`user-${value?.userId}`}
                  >
                    <Avatar
                      bgColor="#1879D8"
                      size={size}
                      imageSrc={value.avatar?.thumbnailUrl}
                      initials={getNameInitials(
                        value.firstName,
                        value.lastName
                      )}
                    />

                    {!disabled && (
                      <span
                        className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-secondary-dark ring-2 ring-white cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          isFunction(onDelete) && onDelete(value);
                        }}
                      >
                        <XIcon className="w-3 h-3 text-white" />
                      </span>
                    )}
                  </div>

                  <ReactTooltip
                    id={`user-${value?.userId}`}
                    place="bottom"
                    effect="solid"
                    backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                    textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                    padding="0 10px"
                  >
                    {value.firstName} {value.lastName}
                  </ReactTooltip>
                </>
              ) : (
                <div
                  className="w-full flex items-center justify-center border rounded-full border-grayscale-700 text-grayscale-700"
                  style={{ height: size, width: size }}
                >
                  <UserAddIcon
                    className="inline text-gray-400"
                    style={{ height: iconSize, width: iconSize }}
                  />
                </div>
              )}
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={classNames(
                position,
                width,
                'absolute z-20 min-h-full max-w-sm'
              )}
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white">
                    <div className="border-b">
                      <InputPrepend
                        name="search"
                        onChange={(e) => onDebouncedSearch(e)}
                        type="text"
                        placeholder="Search..."
                        prependText={<SearchIcon className="w-4 h-4" />}
                        border="border-none"
                        rounded="rounded-none"
                        classes="text-base"
                        autoFocus
                      />
                    </div>

                    <div className="overflow-y-auto h-72 py-4 px-2 text-left">
                      {isLoading ? (
                        <div className="animate-pulse w-full text-13 text-gray-700 py-2 px-3">
                          Loading users.
                        </div>
                      ) : users.length > 0 ? (
                        users.map((user) => {
                          return (
                            <button
                              className="items-center w-full py-2 px-1.5 hover:bg-gray-100 rounded-md focus:ring-gray-300 focus:border-gray-200 flex space-x-2"
                              key={user.userId}
                              value={user.userId}
                              onClick={() => {
                                onChange(user);
                                close();
                              }}
                            >
                              <Avatar
                                bgColor="#1879D8"
                                imageSrc={user.avatar?.thumbnailUrl}
                                initials={getNameInitials(
                                  user.firstName,
                                  user.lastName
                                )}
                              />

                              <span className="text-grayscale-900 text-13 leading-1.5">
                                {user.firstName} {user.lastName}
                              </span>
                            </button>
                          );
                        })
                      ) : (
                        <div className="w-full text-xs text-gray-700 py-2 px-3">
                          No users found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default UserSelector;
