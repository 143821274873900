import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchRestrictedKeywords = createAsyncThunk(
  'mockup/fetchRestrictedKeywords',
  async () => {
    const response = await axios.get(
      `/agency/clients/listing-mockup/keywords`,
      { params: { page: 1, pageSize: 9999, sort: 'createdAt' } }
    );
    return response.data.data;
  }
);

export const fetchListingMockup = createAsyncThunk(
  'mockup/fetchListingMockup',
  async (agencyClientId) => {
    const response = await axios.get(
      `/agency/clients/${agencyClientId}/listing-mockup`
    );
    return response.data.data;
  }
);

export const fetchListingMockupItem = createAsyncThunk(
  'mockup/fetchListingMockupItem',
  async (listingMockupId) => {
    const response = await axios.get(
      `/agency/clients/listing-mockup/${listingMockupId}`
    );
    return response.data.data;
  }
);

export const fetchListingMockupSlide = createAsyncThunk(
  'mockup/fetchListingMockupSlide',
  async (listingMockupSlideId) => {
    const response = await axios.get(
      `/agency/clients/listing-mockup/slides/${listingMockupSlideId}`
    );
    return response.data.data;
  }
);

export const mockupSlice = createSlice({
  name: 'mockup',
  initialState: {
    restrictedKeywords: null,
    listingMockup: undefined,
  },
  reducers: {
    setListingMockup: (state, action) => {
      state.listingMockup = action.payload;
    },
    setAplusContent: (state, action) => {
      //console.log(action.payload, 'act');
      const { data } = action.payload;
      const mockup = [...state.listingMockup];

      const idx = mockup.findIndex(
        (m) => m.listingMockupId == data.listingMockupId
      );

      mockup[idx]['aplus'] = data;

      state.listingMockup = mockup;
    },
  },
  extraReducers: {
    [fetchRestrictedKeywords.pending]: (state, { payload }) => {
      state.restrictedKeywords = null;
    },
    [fetchRestrictedKeywords.fulfilled]: (state, { payload }) => {
      state.restrictedKeywords = payload;
    },
    [fetchListingMockup.pending]: (state, { payload }) => {
      state.listingMockup = undefined;
    },
    [fetchListingMockup.fulfilled]: (state, { payload }) => {
      state.listingMockup = payload;
    },
    [fetchListingMockupItem.fulfilled]: (state, { payload }) => {
      const mockup = [...state.listingMockup];
      const idx = mockup.findIndex(
        (m) => m.listingMockupId == payload.listingMockupId
      );
      mockup[idx] = payload;

      state.listingMockup = mockup;
    },
    [fetchListingMockupSlide.fulfilled]: (state, { payload }) => {
      const mockup = [...state.listingMockup];
      const itemIdx = mockup.findIndex(
        (m) => m.listingMockupId == payload.listingMockupId
      );
      const slides = [...mockup[itemIdx].listingMockupSlide];
      const slideIdx = slides.findIndex(
        (s) => s.listingMockupSlideId === payload.listingMockupSlideId
      );

      slides[slideIdx] = payload;
      mockup[itemIdx] = { ...mockup[itemIdx], listingMockupSlide: slides };

      state.listingMockup = mockup;
    },
  },
});

export const { setListingMockup, setAplusContent } = mockupSlice.actions;

export default mockupSlice.reducer;
