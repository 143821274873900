import { useParams } from 'react-router-dom';
import InvoiceDetails from './InvoiceDetails';

const UpsellInvoiceDetails = () => {
  const { upsellId } = useParams();

  return <InvoiceDetails upsellId={upsellId} />;
};

export default UpsellInvoiceDetails;
