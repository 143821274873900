import axios from 'axios';
import React, { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const StopWatch = ({
  isStop,
  stopwatchOffset,
  taskItemId,
  onChange,
  onChangeTimeTracker,
  detailPage,
  setIsStop,
  enable = true,
}) => {
  const { seconds, minutes, hours, days, start, pause } = useStopwatch({
    autoStart: !isStop,
    offsetTimestamp: stopwatchOffset,
  });

  useEffect(() => {
    if (isStop) {
      pause();
    } else {
      start();
    }
  }, [isStop]);

  const startTracker = async () => {
    await axios
      .post(`/agency/tasks/items/${taskItemId}/time-logs/start`)
      .then((res) => {
        if (res.data.success) {
          if (detailPage) {
            onChangeTimeTracker(false);
            onChange();
          } else {
            onChange();
          }
        }
      });
  };

  const stopTracker = async () => {
    await axios
      .patch(`/agency/tasks/items/${taskItemId}/time-logs/stop`)
      .then((res) => {
        if (res.data.success) {
          if (detailPage) {
            onChangeTimeTracker(true);
            onChange();
          } else {
            onChange();
          }
        }
      });
  };

  const formatTime = (time) => {
    return String(time).padStart(2, '0');
  };

  return (
    <div className="flex flex-row text-13 text-grayscale-800">
      <div className="pr-2">
        {isStop ? (
          <button
            className={enable ? 'cursor-pointer' : 'cursor-not-allowed'}
            onClick={(e) => {
              e.stopPropagation();
              if (!enable) return;
              startTracker();
              setIsStop(false);
              start();
            }}
          >
            <PlayArrowIcon style={{ color: '#949494' }} />
          </button>
        ) : (
          <button
            className={enable ? 'cursor-pointer' : 'cursor-not-allowed'}
            onClick={(e) => {
              e.stopPropagation();
              if (!enable) return;
              stopTracker();
              setIsStop(true);
              pause();
            }}
          >
            <StopIcon style={{ color: '#949494' }} />
          </button>
        )}
      </div>
      <div>
        {days > 0 && <span>{formatTime(days)}:</span>}
        <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
        <span>{formatTime(seconds)}</span>
      </div>
    </div>
  );
};

export default StopWatch;
