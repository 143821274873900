import usePermissions from 'hooks/usePermissions';
import { Link } from 'react-router-dom';

const ClientLink = ({ client, children }) => {
  const { userCan } = usePermissions();

  let hasPermission = client.subscription
    ? userCan('clients.profile.view')
    : userCan('clients.profile.edit');

  return hasPermission ? (
    <Link
      className="cursor-pointer"
      to={
        client.subscription
          ? `/clients/${client.agencyClientId}/dashboard`
          : client.salesClientId
          ? `/sales/client/${client.salesClientId}`
          : `/clients/edit/${client.agencyClientId}`
      }
    >
      {children}
    </Link>
  ) : (
    children
  );
};

export default ClientLink;
