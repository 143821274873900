import { useState } from 'react';
import { Popover } from '@headlessui/react';
import axios from 'axios';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useRouteMatch } from 'react-router-dom';
import useAlert from 'hooks/useAlert';
import Toggle from 'components/Toggle';
import { capitalize } from 'lodash';
import { usePopper } from 'react-popper';
import useMail from './useMail';

const MailBlockMenu = ({ item, type, fetchItems, isEdit = false }) => {
  const { url } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const { alertError, alertSuccess } = useAlert();
  const { openTemplatesModal, updateDefaultSignature } = useMail();
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  const itemId = `email${capitalize(type)}Id`;

  const onDeleteItem = async (e, item, close) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .delete(`/agency/email-${type}/${item[itemId]}`)
      .then((response) => {
        alertSuccess('Successfully deleted', response.data.message);
        fetchItems();
        close();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const onItemDefault = async (item, close) => {
    setLoading(true);

    await axios
      .patch(`/agency/email-${type}/${item[itemId]}`, {
        isDefault: !item.isDefault,
      })
      .then((response) => {
        alertSuccess('Set to default', response.data.message);
        fetchItems();
        updateDefaultSignature(response.data.data);
        close();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const onEditTemplate = () => {
    openTemplatesModal('edit', item[itemId], type, url);
  };

  return (
    <Popover className="relative">
      <Popover.Button ref={setReferenceElement}>
        <DotsVerticalIcon className="w-5 h-5 text-gray-500 opacity-0 group-hover:opacity-100" />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="absolute left-5 translate-x-full top-0 z-10 w-44 bg-white shadow-xl rounded-xl"
      >
        {({ close }) => (
          <div className="flex flex-col text-left">
            {!isEdit && (
              <button
                className="text-left hover:bg-grayscale-400 px-4 py-3"
                onClick={onEditTemplate}
              >
                Edit
              </button>
            )}
            <button
              type="button"
              className="text-left hover:bg-grayscale-400 px-4 py-3"
              onClick={(e) => onDeleteItem(e, item, close)}
            >
              Delete
            </button>
            {type === 'signature' && (
              <label className="flex items-center justify-between px-4 py-3">
                Set as default&nbsp;&nbsp;
                <Toggle
                  onChange={() => onItemDefault(item, close)}
                  checked={item.isDefault}
                />
              </label>
            )}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};
export default MailBlockMenu;
