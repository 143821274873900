import { NavLink, useLocation, withRouter } from 'react-router-dom';
import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import { useContext } from 'react';
import { Context } from 'layouts/NavProvider';

const SidebarSubmenu = ({ currentItem }) => {
  const { sidebarSubmenuOpen, setSidebarSubmenuOpen } = useContext(Context);
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      id="afterclass"
      className={classNames(
        'hidden w-40 shadow-sm bg-white overflow-visible font-inter relative',
        sidebarSubmenuOpen && 'md:block'
      )}
    >
      {currentItem && sidebarSubmenuOpen && (
        <>
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow"></div>
          <div>
            <div className="w-full px-4 pt-4 pb-2 mb-5 font-inter font-bold text-base text-grayscale-800 relative">
              {currentItem.name}
              <button
                className="absolute -right-2.5 z-1 rounded-full p-1 border border-grayscale-600 bg-white shadow-md"
                onClick={() => setSidebarSubmenuOpen(!sidebarSubmenuOpen)}
              >
                <ChevronDoubleLeftIcon className="w-3.5 justify-self-start text-grayscale-600" />
              </button>
            </div>
            {currentItem.children.map((c) => {
              return (
                <NavLink
                  key={c.name}
                  to={`${c.href}`}
                  className="group w-full pr-3 pl-6 py-2 flex items-center text-xs font-inter font-medium text-gray-400 hover:bg-gray-50 hover:text-red-500 relative"
                  activeClassName="bg-red-50 text-red-500 select-arrow"
                  aria-current={pathname.includes(c.href) ? 'page' : undefined}
                >
                  <c.icon className="h-5 w-5 inline mr-2" aria-hidden="true" />
                  <span className="mt-1">{c.name}</span>
                  <div className="arrow-left"></div>
                </NavLink>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default withRouter(SidebarSubmenu);
