import { Popover } from '@headlessui/react';
import { FilterIcon } from '@heroicons/react/solid';
import { XIcon, ChevronDownIcon, SearchIcon } from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import classNames from 'utils/classNames';
import { Formik, Form } from 'formik';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import {
  getNameInitials,
  parseArray,
  strUnderscoreToSpace,
} from 'utils/formatters';
import InputPrepend from 'components/Forms/InputPrepend';
import { debounce, isArray, pick } from 'lodash';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import RangePicker from 'components/RangePicker';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import Button from 'components/Button';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import { getMyselfAsync } from 'features/auth/authSlice';
import useLoggedInUser from 'hooks/useLoggedInUser';

const Criteria = ({ title, fieldName, data, open, values }) => {
  return (
    <div>
      <Disclosure.Button
        className={classNames(
          open ? 'pt-5' : 'py-5',
          'flex w-full justify-between  text-left text-sm tracking-tight text-grayscale-800  font-bold'
        )}
      >
        <h6>
          {title}{' '}
          {values && parseInt(values?.length) > 0 ? (
            <span className="ml-2 text-xs rounded-full bg-grayscale-500 text-gray-500 px-1.5">
              {values?.length}
            </span>
          ) : (
            <>&nbsp;</>
          )}
        </h6>
        <div className="flex items-center space-x-2">
          <ChevronDownIcon
            className={`${
              open ? 'rotate-180 transform' : ''
            } h-6 w-6 text-grayscale-700`}
          />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm text-gray-500 py-5">
        <div
          role="group"
          aria-labelledby="checkbox-group"
          className="flex flex-col space-y-2.5"
        >
          {data.map((status, i) => {
            return (
              <label key={i} className="flex items-center capitalize">
                <Field
                  type="checkbox"
                  name={fieldName}
                  value={status}
                  className="text-custom-blue focus:border-custom-blue ring-0 focus:ring-0 focus:ring-custom-blue rounded mr-1.5"
                />
                {strUnderscoreToSpace(status)}
              </label>
            );
          })}
        </div>
      </Disclosure.Panel>
    </div>
  );
};

const UserCriteria = ({ title, fieldName, data, open, values }) => {
  const [filteredUsers, setFilteredUsers] = useState(data);

  const onSearch = (e) => {
    const search = e.target.value.toLowerCase();

    let f = data.filter((user) => {
      const fullName = user.firstName + ' ' + user.lastName;
      return (
        user.firstName.toLowerCase().includes(search) ||
        user.lastName.toLowerCase().includes(search) ||
        fullName.toLowerCase().includes(search)
      );
    });
    setFilteredUsers(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div>
      <Disclosure.Button
        className={classNames(
          open ? 'pt-5' : 'py-5',
          'flex w-full justify-between  text-left text-sm tracking-tight text-grayscale-800  font-bold'
        )}
      >
        <h6>
          {title}{' '}
          {values && parseInt(values?.length) > 0 ? (
            <span className="ml-2 text-xs rounded-xl bg-grayscale-500 text-gray-500 px-1.5">
              {values?.length}
            </span>
          ) : (
            <>&nbsp;</>
          )}
        </h6>
        <div className="flex items-center space-x-2">
          <ChevronDownIcon
            className={`${
              open ? 'rotate-180 transform' : ''
            } h-6 w-6 text-grayscale-700`}
          />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm text-gray-500 py-5">
        <div
          role="group"
          aria-labelledby="checkbox-group"
          className="flex flex-col space-y-2.5"
        >
          {filteredUsers && (
            <>
              <InputPrepend
                name="search"
                onChange={(e) => onDebouncedSearch(e)}
                type="text"
                placeholder="Search..."
                prependText={<SearchIcon className="w-3 h-3" />}
                border="border-none"
                rounded="rounded-xl"
                classes="text-xs bg-gray-50"
                autoFocus
              />
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => {
                  return (
                    <label
                      key={user.userId}
                      className="flex items-center capitalize space-x-2.5"
                    >
                      <Field
                        type="checkbox"
                        name={fieldName}
                        value={user.userId}
                        className="text-custom-blue focus:border-custom-blue ring-0 focus:ring-0 focus:ring-custom-blue rounded"
                      />
                      <span className="col-span-1 block bg-red-500 text-white rounded-2xl w-7 h-7 leading-7 text-sm uppercase text-center font-normal">
                        {getNameInitials(user.firstName, user.lastName)}
                      </span>
                      <span className="text-1xs leading-none">
                        {user.firstName} {user.lastName}
                      </span>
                    </label>
                  );
                })
              ) : (
                <div className="w-full text-xs text-gray-700 py-2 px-3">
                  No users found.
                </div>
              )}
            </>
          )}
        </div>
      </Disclosure.Panel>
    </div>
  );
};

const DateCriteria = ({ title, fieldName, open, values }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const { setFieldValue } = useFormikContext();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (values && values.length === 2) {
      setSelectedDates([new Date(values[0]), new Date(values[1])]);
    } else {
      setSelectedDates([]);
    }
  }, []);

  //check if values were reset
  useEffect(() => {
    if (values && values.length <= 0) {
      setSelectedDates([]);
      setCounter(0);
    }
  }, [values]);

  useEffect(() => {
    if (selectedDates && selectedDates.length === 2) {
      setFieldValue(fieldName, [
        selectedDates[0].toLocaleDateString(),
        selectedDates[1].toLocaleDateString(),
      ]);

      const start = moment(selectedDates[0]),
        end = moment(selectedDates[1]);

      setCounter(end.diff(start, 'days') + 1);
    }
  }, [selectedDates]);

  return (
    <div>
      <Disclosure.Button
        className={classNames(
          open ? 'pt-5' : 'py-5',
          'flex w-full justify-between  text-left text-sm tracking-tight text-grayscale-800  font-bold'
        )}
      >
        <h6>
          {title}{' '}
          {counter > 0 ? (
            <span className="ml-2 text-xs rounded-xl bg-grayscale-500 text-gray-500 px-1.5">
              {counter}
            </span>
          ) : (
            <>&nbsp;</>
          )}
        </h6>
        <div className="flex items-center space-x-2">
          <ChevronDownIcon
            className={`${
              open ? 'rotate-180 transform' : ''
            } h-6 w-6 text-grayscale-700`}
          />
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm text-gray-500 py-5">
        <div
          role="group"
          aria-labelledby="checkbox-group"
          className="flex flex-col space-y-2.5"
        >
          <RangePicker
            monthsToDisplay={1}
            orientation="vertical"
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            ignoreMinDate={true}
          />
        </div>
      </Disclosure.Panel>
    </div>
  );
};

const FiltersForm = ({ open, onSetFilters, params }) => {
  const { statuses, taskTypes, agencyUsers } = useSelector(
    (state) => state.sales
  );

  const emptyInitialValues = {
    statuses: [],
    types: [],
    assigneesUserIds: [],
    dueDate: [],
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { user } = useLoggedInUser();

  const onTotalCounter = (values) => {
    const countables = pick(values, ['statuses', 'types', 'assigneesUserIds']);
    const counter = Object.keys(countables)
      .map((k) => {
        return values && values[k].length;
      })
      .reduce((a, b) => a + b, 0);

    const rangeCountables = pick(values, ['dueDate', 'lastEngagement']);
    const rangeCounter = Object.keys(rangeCountables)
      .map((k) => {
        if (values && values[k].length === 2) {
          const start = moment(values[k][0]),
            end = moment(values[k][1]);

          return end.diff(start, 'days') + 1;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    setTotal(counter + rangeCounter);
  };

  useEffect(() => {
    const initial = {
      statuses: parseArray(params.statuses),
      types: parseArray(params.types),
      assigneesUserIds: parseArray(params.assigneesUserIds),
      dueDate: parseArray(params.dueDate),
    };
    setInitialValues(initial);
    onTotalCounter(initial);
  }, [params]);

  const TotalListener = ({ values }) => {
    useEffect(() => {
      onTotalCounter(values);
    }, [values]);

    return null;
  };

  const onSubmit = (values) => {
    onSetFilters(values);
  };

  const onClear = (resetForm) => {
    resetForm({ values: emptyInitialValues });
    onSetFilters(emptyInitialValues);
  };

  const onSavingUserSetting = async (values) => {
    axios
      .patch(`/users/${user.userId}/setting/sales-workspace-filter`, values)
      .then((res) => {
        dispatch(setAlert('success', res.data.message));
        dispatch(getMyselfAsync());
      })
      .catch((err) => dispatch(setAlert('error', err)))
      .finally(() => {
        onSetFilters(values);
      });
  };

  return (
    <>
      <Popover.Button
        className={classNames(
          total > 0 ? 'text-gray-500' : 'text-grayscale-700',
          'flex items-center'
        )}
      >
        <FilterIcon className="w-4 h-4 inline mr-1" />
        <span className="tracking-wider font-bold text-sm">
          Filter
          {total > 0 && (
            <span className="ml-2 text-sm rounded-full bg-grayscale-500 text-gray-500 py-1 px-1.5">
              {total}
            </span>
          )}
        </span>
      </Popover.Button>

      <Popover.Panel className="right-0 absolute z-20 min-h-full max-w-sm">
        {({ close }) => (
          <div className="overflow-hidden rounded-2xl shadow-xl ring-1 ring-gray-500 ring-opacity-5 bg-white w-80">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ submitForm, resetForm, values }) => (
                <Form>
                  <TotalListener values={values} />
                  <div
                    className="overflow-y-auto text-left px-6 flex flex-col"
                    style={{ height: '450px' }}
                  >
                    <div className="flex justify-between sticky top-0 bg-white pt-6 z-10">
                      <h5 className="text-lg font-bold text-grayscale-900">
                        Filter
                      </h5>
                      <div className="flex items-center space-x-4">
                        <button
                          type="button"
                          onClick={() => {
                            close();
                          }}
                        >
                          <XIcon className="w-6 h-6 text-gray-400" />
                        </button>
                      </div>
                    </div>
                    <div className="divide-y mt-4 flex-grow">
                      <Disclosure>
                        {({ open }) => (
                          <Criteria
                            title="Status"
                            fieldName="statuses"
                            data={statuses.map((s) => s.name)}
                            open={open}
                            values={values.statuses}
                          />
                        )}
                      </Disclosure>
                      <Disclosure>
                        {({ open }) => (
                          <UserCriteria
                            title="Assignee"
                            fieldName="assigneesUserIds"
                            data={agencyUsers}
                            open={open}
                            values={values.assigneesUserIds}
                          />
                        )}
                      </Disclosure>
                      <Disclosure>
                        {({ open }) => (
                          <DateCriteria
                            title="Due Date"
                            fieldName="dueDate"
                            open={open}
                            values={values.dueDate}
                          />
                        )}
                      </Disclosure>
                      <Disclosure>
                        {({ open }) => (
                          <Criteria
                            title="Task Type"
                            fieldName="types"
                            data={taskTypes}
                            open={open}
                            values={values.types}
                          />
                        )}
                      </Disclosure>
                    </div>
                    <div className="flex justify-between sticky bottom-0 bg-white py-4 z-50">
                      <button
                        className="text-sm text-custom-sky font-bold tracking-widest"
                        onClick={() => onClear(resetForm)}
                        type="button"
                      >
                        Clear
                      </button>

                      <button
                        type="button"
                        onClick={() => {
                          submitForm();
                          close();
                        }}
                        className="flex items-center px-4 tracking-widest rounded-full py-1 font-bold text-sm text-white bg-custom-blue hover:bg-custom-sky focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
                      >
                        Apply
                      </button>
                      <Button
                        classes="border-0 font-bold tracking-widest"
                        bgColor="green-500"
                        hoverColor="green-400"
                        roundedSize="full"
                        textColor="white"
                        px={6}
                        py={1}
                        shadow=""
                        textSize=""
                        onClick={() => {
                          onSavingUserSetting(values);
                          close();
                        }}
                      >
                        Save Filter
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Popover.Panel>
    </>
  );
};

const FiltersDropdown = ({ params, onSetFilters }) => {
  return (
    <div className="w-full max-w-sm px-4">
      <Popover className="relative">
        {({ open }) => (
          <FiltersForm
            open={open}
            onSetFilters={onSetFilters}
            params={params}
          />
        )}
      </Popover>
    </div>
  );
};

export default FiltersDropdown;
