import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string, number } from 'yup';
import axios from 'axios';
import FormikErrorNotification from 'components/FormikErrorNotification';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { isEmpty } from 'lodash';
import ButtonLink from 'components/ButtonLink';
import useAlert from 'hooks/useAlert';

const OneTimeChargeModal = ({ subscription, open, setOpen }) => {
  const [saving, setSaving] = useState(false);
  const { alertError, alertSuccess } = useAlert();

  const initialValues = { description: '', amount: 0 };

  const descriptionCheck = (string) => /[<>]/.test(string);
  const validationSchema = object().shape({
    amount: number().min(0).required('required'),
    description: string()
      .test(
        'Invalid characters: < >',
        'Invalid characters: < >',
        (value) => !descriptionCheck(value)
      )
      .required(),
  });

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      const res = await axios.post(
        `subscriptions/${subscription.subscription_id}/onetimecharge`,
        values
      );
      alertSuccess('Charge successful', res.data.data.message);
      setOpen(false);
    } catch (error) {
      //console.log(error.response.data);
      alertError('Cannot charge add-on', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Add One Time Charge"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ errors, setFieldValue, submitForm, values }) => (
            <div className="px-4 md:px-8">
              <Form>
                <FormikErrorNotification />
                <div className="col-span-12">
                  <Label>
                    Description
                    <RequiredAsterisk />
                  </Label>
                  <Field
                    name="description"
                    as="textarea"
                    rows={2}
                    className="form-input"
                    maxLength={2000}
                  />
                  <div className="flex justify-between">
                    <div className="flex">
                      &nbsp;
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div
                      className={`text-right italic text-xs mt-1  ${
                        values.description?.length === 2000
                          ? 'text-red-700'
                          : ''
                      }`}
                    >
                      {values.description?.length}/
                      <span className="font-medium">2000</span> characters
                    </div>
                  </div>
                </div>
                <div className="col-span-6 lg:col-span-2 text-sm">
                  <Label>
                    Amount
                    <RequiredAsterisk />
                  </Label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <Field
                      name="amount"
                      className="form-input  pl-7 r-12"
                      type="number"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <Label htmlFor="currency_code" classes="sr-only">
                        Currency
                      </Label>
                      <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                        USD
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    name="price"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      Apply
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <div className="py-4 px-6"></div>
      </div>
    </Modal>
  );
};
export default OneTimeChargeModal;
