import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Popover } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import ButtonLink from 'components/ButtonLink';
import Textarea from 'components/Forms/Textarea';
import { PlusIcon } from '@heroicons/react/outline';
import useAlert from 'hooks/useAlert';
import { fetchSubscription } from '../subscriptionSlice';
import { joiAlertErrorsStringify } from 'utils/formatters';

const AddNotesPopover = ({ subscription, setSubscription }) => {
  const { subscription_id } = subscription;

  const [saving, setSaving] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const closeRef = useRef();
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();

  const onSaveChanges = async () => {
    setSaving(true);
    try {
      const res = await axios.post(`subscriptions/${subscription_id}/notes`, {
        description: newDescription,
      });

      if (res.data.data.code === 0) {
        alertSuccess('Note added', res.data.data.message);
        dispatch(fetchSubscription(subscription_id));
        closeRef.current?.click();
      } else {
        alertError('Failed to add note', res.data.data.message);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError('Failed to add note', errorMessages);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Popover className="relative leading-none w-1/2">
      <Popover.Button
        ref={closeRef}
        className="text-red-500 hover:text-red-600 text-xs flex items-center tracking-widest font-bold"
      >
        <PlusIcon className="h-4 w-4 inline" /> Add
      </Popover.Button>

      <Popover.Panel className="tail absolute z-10 shadow-md border bg-white text-sm rounded-md w-full">
        <div className="py-2 bg-gray-50 mb-2 px-4 text-gray-700 border-b text-sm">
          Add Note
        </div>
        <div className="px-2 text-xs">
          <Textarea
            name="newDescription"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            textSize="xs"
            required
            rows={6}
          />
        </div>
        <div className="text-xs border-t p-2 mt-2 flex justify-end">
          <ButtonLink
            onClick={onSaveChanges}
            loading={saving}
            showLoading={true}
            color="white"
            classes="bg-red-500 hover:bg-red-700 py-2 px-3 rounded-md"
            textSize="xs"
          >
            Save
          </ButtonLink>
          <Popover.Button
            as="button"
            className="rounded-md bg-gray-100 hover:bg-gray-400 py-2 px-3 ml-2"
          >
            Cancel
          </Popover.Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
export default AddNotesPopover;
