import axios from 'axios';
import EditableContent from 'components/EditableContent';
import usePermissions from 'hooks/usePermissions';
import React from 'react';
import { currencyFormatter, joiAlertErrorsStringify } from 'utils/formatters';
import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import useAlert from 'hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscription } from '../subscriptionSlice';

const PlanAddonsTable = ({ subscription, isScheduledChanges }) => {
  const { userCan } = usePermissions();
  const { alertError, alertSuccess } = useAlert();
  const dispatch = useDispatch();
  const { agencyClient } = useSelector((state) => state.agencyClient);

  const onEditTitle = async (e) => {
    try {
      const res = await axios.post(
        `subscriptions/${subscription.subscription_id}/lineitems/${subscription.plan.plan_code}`,
        { description: e.target.value }
      );

      if (res.data.data.code === 0) {
        alertSuccess('description updated', res.data.data.message);
        dispatch(fetchSubscription(subscription.subscription_id));
      } else {
        alertError('Failed to update description', res.data.data.message);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    }
  };

  const Th = (label, classes) => {
    return (
      <th
        scope="col"
        className={`px-3 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider ${
          classes ?? ''
        }`}
      >
        {label}
      </th>
    );
  };

  const Td = (value, classes, colspan = '') => {
    return (
      <td
        colSpan={colspan ?? ''}
        className={`p-3 text-sm text-gray-900 ${classes ?? ''}`}
      >
        {value}
      </td>
    );
  };

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200 table-fixed w-1024px">
        <thead className="bg-gray-100">
          <tr>
            {Th(`Plan & Addon Details`, 'text-left w-5/12')}
            {Th('Qty', 'text-right w-1/12')}
            {Th('Rate', 'text-right w-2/12')}
            {Th('Tax', 'text-right w-2/12')}
            {Th('Amount', 'text-right w-3/12')}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            {Td(
              <>
                {subscription.plan.name}
                <br />

                {!isScheduledChanges &&
                userCan('clients.subscription.description.update') &&
                !agencyClient.deletedAt ? (
                  <EditableContent
                    value={subscription.plan.description}
                    onUpdate={onEditTitle}
                    tag="textarea"
                    textClasses="w-full"
                    textSize="text-xs"
                    borderIndicator="border border-white hover:border-gray-200"
                    placeholderText="Add a description"
                    editButton={
                      <div className="w-full text-xs flex items-center font-bold tracking-widest text-red-500 hover:text-red-700 mt-1">
                        {subscription.plan.description !== '' ? (
                          <>
                            <PencilIcon className="h-4 w-4 inline mr-1" />
                            Edit Description
                          </>
                        ) : (
                          <>
                            <PlusIcon className="h-4 w-4 inline mr-1" />
                            Add Description
                          </>
                        )}
                      </div>
                    }
                  />
                ) : (
                  <span className="text-xs whitespace-pre-wrap">
                    {subscription.plan.description}
                  </span>
                )}
              </>
            )}
            {Td(subscription.plan.quantity, 'text-right')}
            {Td(
              currencyFormatter(
                subscription.plan.price,
                subscription.currency_code
              ),
              'text-right'
            )}
            {Td(
              subscription.plan.tax_percentage &&
                subscription.plan.tax_percentage > 0
                ? `${subscription.plan.tax_name} (${subscription.plan.tax_percentage}%)`
                : '-',
              'text-right'
            )}
            {Td(
              currencyFormatter(
                subscription.plan.total,
                subscription.currency_code
              ),
              'text-right'
            )}
          </tr>
          {subscription.addons &&
            subscription.addons.map((addon, index) => {
              return (
                <tr className="bg-white" key={index}>
                  {Td(
                    <>
                      {addon.name}
                      <br />
                      <span className="text-xs">{addon.description}</span>
                    </>
                  )}
                  {Td(addon.quantity, 'text-right')}
                  {Td(
                    currencyFormatter(addon.price, subscription.currency_code),
                    'text-right'
                  )}
                  {Td(
                    addon.tax_percentage && addon.tax_percentage > 0
                      ? addon.tax_percentage
                      : '-',
                    'text-right'
                  )}
                  {Td(
                    currencyFormatter(addon.total, subscription.currency_code),
                    'text-right'
                  )}
                </tr>
              );
            })}
          <tr className="bg-white border-t pt-4">
            {Td('Sub Total', 'text-right', 4)}
            {Td(
              currencyFormatter(
                subscription.sub_total,
                subscription.currency_code
              ),
              'text-right',
              1
            )}
          </tr>

          {subscription.taxes &&
            subscription.taxes.map((tax, i) => {
              return (
                <tr className="bg-white" key={i}>
                  {Td(tax.tax_name, 'text-right', 4)}
                  {Td(
                    currencyFormatter(
                      tax.tax_amount,
                      subscription.currency_code
                    ),
                    'text-right',
                    1
                  )}
                </tr>
              );
            })}
          <tr className="border-t bg-gray-100 border-b">
            <td
              colSpan={4}
              className="px-4 py-1 text-right text-xs font-bold uppercase text-gray-800"
            >
              Total ({subscription.currency_code})
            </td>
            <td
              colSpan={1}
              className="px-4 py-1 text-right text-sm font-bold uppercase"
            >
              {currencyFormatter(
                subscription.amount,
                subscription.currency_code
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlanAddonsTable;
