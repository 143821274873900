import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import Label from 'components/Forms/Label';
import { Field, useFormikContext } from 'formik';
import Modal from 'components/ModalPlain';
import ModalHeader from 'components/ModalHeader';
import Button from 'components/Button';
import TabNav from 'components/TabNav';
import moment from 'moment';
import { FileDrop } from 'react-file-drop';
import FontPicker from 'font-picker-react';
import daniSignature from './assets/daniSignature.png';
moment.tz.setDefault('America/Toronto');

export const Acknowledgement = ({
  activeFontFamily,
  setActiveFontFamily,
  acknowledgement,
}) => {
  const { setFieldValue, handleChange, values } = useFormikContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const [tabs, setNavTabs] = useState([
    { name: 'Draw', type: 'draw', current: true },
    { name: 'Type', type: 'type', current: false },
    { name: 'Upload', type: 'upload', current: false },
  ]);

  const [signatureType, setSignatureType] = useState('draw');
  const [signaturePadDrawing, setSignaturePadDrawing] = useState(null);
  const [signaturePadText, setSignaturePadText] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [fullname, setFullname] = useState('');
  const [openSignModal, setOpenSignModal] = useState(false);
  const fileInputRef = useRef(null);
  const [fileUp, setFileUp] = useState(null);
  const [typeNameValue, setTypeNameValue] = useState('');

  const clearSignatureHandler = () => {
    setFullname('');
    if (signaturePadDrawing && !signaturePadDrawing.isEmpty())
      signaturePadDrawing.clear();
    if (signaturePadText && !signaturePadText.isEmpty())
      signaturePadText.clear();
  };

  // const saveSignatureHandler = async () => {
  //   // e.preventDefault();

  //   const queryParams = new URLSearchParams(location.search);
  //   const publicProposalId = queryParams.get('proposalId');

  // };

  const getSignatureByDrawing = () => {
    if (signaturePadDrawing && signaturePadDrawing.isEmpty()) return;
    return signaturePadDrawing.getTrimmedCanvas().toDataURL('image/png');
  };

  const onTabChange = (tab) => {
    setSignatureType(tab.type);
  };

  const onCloseModal = () => {
    setOpenSignModal(false);
  };

  const filterSignitureValue = (type, values) => {
    switch (type) {
      case 'draw':
        return getSignatureByDrawing();
      case 'type':
        return typeNameValue;
      case 'upload':
        return fileUp;
      default:
        throw Error('Unknown type: ' + type);
    }
  };

  const onApply = (setFieldValue, values) => {
    let signature = filterSignitureValue(signatureType, values);

    setSignatureImage(signature);

    if (!signature) return;

    setFieldValue('signature', {
      signature,
      value: signature,
      type: signatureType,
      date: moment().format(),
    });
    setOpenSignModal(false);
    setFileUp(null);
  };

  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    uploadFiles(event.target.files[0]);
  };

  // * Upload files update the details
  const uploadFiles = (file) => {
    fileInputRef.current.file = file;
    if (file) {
      getBase64(file)
        .then((result) => {
          setFileUp(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('No image found');
    }
  };

  // * Triggered when the button for adding files is clicked
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  return (
    <div
      className="relative flex flex-col p-6 lg:px-10 lg:pb-14 section"
      id="acknowledgement"
    >
      <div className="space-y-6">
        <h2 className="text-4xl font-bold text-si-pink">E-Signature</h2>

        <div className="text-base space-y-4 md:text-lg text-gray-500">
          <p>
            We don't have complicated contracts or fussy paperwork; just enter
            your full name and sign the 'E-Sign Agreement' below and continue
            with your payment.
          </p>

          <div className="sm:grid sm:grid-cols-12 space-y-3 mx-2 border-bmy-10 px-10 gap-4">
            <div className="col-span-6">
              <div className="flex">
                <Label classes="p-3">Name</Label>
                <Field
                  name="name"
                  placeholder="Enter your name here"
                  className="form-input disabled-white placeholder:text-slate-400"
                  type="text"
                  disabled={acknowledgement && acknowledgement.signedDate}
                />
              </div>
            </div>
            <div className="col-span-6">
              {Object.keys(values.signature).length > 0 && (
                <div className="flex">
                  <Label classes="p-1 pr-4">Managing Director</Label>
                  Danielle Bernabe
                </div>
              )}
            </div>
            {Object.keys(values.signature).length > 0 ? (
              <>
                <div
                  onClick={() =>
                    setOpenSignModal(
                      acknowledgement && acknowledgement.signedDate
                        ? false
                        : true
                    )
                  }
                  className="col-span-6 bg-gray-50 rounded-2xl h-48 flex items-center justify-center text-gray-400 cursor-pointer"
                >
                  {values.signature.type === 'type' ? (
                    <p className="apply-font text-4xl">
                      {signatureImage ?? values.signature.value}
                    </p>
                  ) : (
                    <img
                      className="h-full"
                      alt="signature"
                      src={signatureImage ?? values.signature.value}
                    />
                  )}
                </div>

                <div className="col-span-6 bg-white h-48 flex items-center justify-center text-gray-400 cursor-pointer">
                  <img src={daniSignature} className="w-auto" />
                </div>

                <div className="col-span-12">
                  <div className="flex justify-between">
                    <div>
                      {values && values.signature && values.signature.date && (
                        <>
                          <CheckIcon className="w-5 h-5 text-green-400 inline absolute mt-2 ml-1" />
                          <CheckIcon className="w-5 h-5 text-green-400 inline" />
                          <span className="ml-3 text-gray-400">
                            Signed on&nbsp;
                            {moment(
                              values.signature.date,
                              'YYYY-MM-DD mm:ss'
                            ).format('lll')}
                          </span>
                        </>
                      )}
                    </div>

                    <div>
                      {acknowledgement && acknowledgement.signedDate ? (
                        <>&nbsp;</>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenSignModal(true);
                          }}
                          disabled={
                            acknowledgement && acknowledgement.signedDate
                          }
                          className=" text-gray-500 font-bold py-2 px-8 rounded-full border-2 border-gray-500"
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                onClick={() => setOpenSignModal(true)}
                className="col-span-12 bg-gray-50 h-48 flex items-center justify-center text-gray-400 cursor-pointer"
              >
                Click me to sign here
              </div>
            )}
          </div>
        </div>

        <Modal
          open={openSignModal}
          setOpen={setOpenSignModal}
          align="middle"
          persistent={true}
          as="div"
        >
          <div className="inline-block w-full my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl max-w-min">
            <ModalHeader
              title={
                <div className="flex items-center space-x-4">
                  <span>Signature</span>
                </div>
              }
              setOpen={setOpenSignModal}
              titleClasses="capitalize"
              border=""
              fontSize="text-xl"
              fontStyle="font-bold"
              px="px-4 md:px-8"
              py="py-4 md:py-8"
            />

            <div className="mt-8 bg-white">
              <div className="relative max-w-7xl mx-auto ">
                <div className="max-w-lg overflow-hidden lg:max-w-none lg:flex">
                  <div className="flex-1 space-y-4">
                    <div>
                      <TabNav
                        tabs={tabs}
                        setTabs={setNavTabs}
                        onClick={(tab) => onTabChange(tab)}
                        theme="tabbed"
                      />

                      <div className="flex justify-center items-center h-40 border border-1 rounded bg-gray-100 overflow-auto">
                        {/* Sign by draw */}
                        {signatureType === 'draw' && (
                          <SignatureCanvas
                            ref={(ref) => setSignaturePadDrawing(ref)}
                            dotSize={3}
                            maxWidth={2.5}
                            canvasProps={{
                              className: 'w-full h-full',
                            }}
                          />
                        )}
                        {/* Sign by type */}
                        {signatureType === 'type' && (
                          <div className="font-Tangerine signature-text h-auto">
                            <div className="flex items-center border-b border-teal-500 py-2">
                              <p className="apply-font">
                                <input
                                  type="text"
                                  value={typeNameValue}
                                  placeholder="Enter your name here"
                                  className="form-input disabled-white appearance-none bg-gray-100 border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                  onChange={(e) => {
                                    setTypeNameValue(e.target.value);
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        )}
                        {/**Sign by uploading file */}
                        {signatureType === 'upload' && (
                          <div className="overflow-auto">
                            {fileUp ? (
                              <div className="overflow-auto">
                                <img
                                  src={fileUp}
                                  alt="Signature"
                                  className="object-fill"
                                />
                              </div>
                            ) : (
                              <>
                                <input
                                  onChange={onFileInputChange}
                                  accept="image/*"
                                  ref={fileInputRef}
                                  type="file"
                                  className="hidden"
                                />
                                <FileDrop
                                  onTargetClick={onTargetClick}
                                  onDrop={(files, event) =>
                                    uploadFiles(files[0])
                                  }
                                >
                                  <div className=" p-5 text-center cursor-pointer bg-gray-100">
                                    <div className="flex items-center justify-center">
                                      <PlusIcon
                                        className="text-gray-500 mr-1 h-5 w-5 inline"
                                        aria-hidden="true"
                                      />
                                      <span className="text-gray-500 text-xl">
                                        Drop file or click to select
                                      </span>
                                    </div>
                                  </div>
                                </FileDrop>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-col">
              {signatureType === 'type' && (
                <div className="flex mt-3">
                  <div className="flex flex-col justify-center px-3">
                    <p>Font:</p>
                  </div>
                  <div className="inline-block relative">
                    <FontPicker
                      apiKey="AIzaSyC0EGkVukJuqjHDDZl_aBbxlUMi2xyU1t4"
                      activeFontFamily={activeFontFamily}
                      onChange={(nextFont) =>
                        setActiveFontFamily(nextFont.family)
                      }
                      // limit={20}
                    />
                  </div>
                </div>
              )}

              <div className="col-span-2  m-8 flex justify-between items-center">
                <div className="space-x-4">
                  <button
                    className="border-white font-bold text-blue-700"
                    onClick={() => {
                      onCloseModal();
                    }}
                  >
                    Cancel
                  </button>

                  <Button
                    classes="border-0 font-bold tracking-wider "
                    bgColor="blue-700"
                    hoverColor="blue-400"
                    roundedSize="2xl"
                    textColor="white"
                    px={12}
                    py={1.5}
                    shadow=""
                    // type="submit"
                    // showLoading={true}
                    // loading={saving}
                    onClick={() => onApply(setFieldValue, values)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
