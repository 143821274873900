import { TerminationBreakdown, TerminationSummary } from './components';

const TerminationReason = () => {
  return (
    <div>
      <div className="space-y-10">
        <TerminationSummary />

        <TerminationBreakdown />
      </div>
    </div>
  );
};

export default TerminationReason;
