import { createSlice } from '@reduxjs/toolkit';
import { Country } from '@bmulingbayan22/country-state';

export const countrySlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
  },
  reducers: {
    setCountries: (state, action) => {
      state.countries = Country.getAllCountries();
    },
  },
});

export const { setCountries } = countrySlice.actions;

export default countrySlice.reducer;
