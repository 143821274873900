import axios from 'axios';
import * as yup from 'yup';
import { isFunction } from 'lodash';
import { useDispatch } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { resolveComplaint } from '../complaintSlice';

const ResolveModal = ({ id, open, setOpen, onResolved }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, actions) => {
    dispatch(resolveComplaint({ id, data: values }))
      .then(() => {
        setOpen(false);
        if (isFunction(onResolved)) {
          onResolved();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal open={open} setOpen={setOpen} as={'div'} align="middle">
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-5">
          <h4 className="text-grayscale-700 leading-1.5 text-base tracking-3/4">
            Mark as Resolved
          </h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <Formik
          initialValues={{
            resolution: '',
          }}
          onSubmit={onSubmit}
          validationSchema={yup.object().shape({
            resolution: yup.string().required('Required'),
          })}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form className="p-8">
              <div className="space-y-8">
                <div className="">
                  <Label
                    classes="text-left leading-1.5"
                    textColor="text-grayscale-800"
                    textSize="13"
                  >
                    Tell us how you resolve the problem
                    <RequiredAsterisk />
                  </Label>

                  <Field
                    as="textarea"
                    name="resolution"
                    rows={5}
                    className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0 placeholder:text-grayscale-600"
                    placeholder="Enter additional notes here"
                  />

                  <ErrorMessage
                    name="resolution"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <div className="flex">
                  <button
                    onClick={() => setOpen(false)}
                    className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                  >
                    Cancel
                  </button>

                  <Button
                    type="submit"
                    roundedSize={40}
                    showLoading={true}
                    loading={isSubmitting}
                    disabled={!(isValid && dirty)}
                    classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ResolveModal;
