import { useSelector } from 'react-redux';
import {
  ChartBarIcon,
  ArchiveIcon,
  CogIcon,
  TableIcon,
  BriefcaseIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

const useLeadsMenu = () => {
  const { userCan } = usePermissions();

  const { leadsPaginationParams } = useSelector((state) => state.leads);

  return {
    name: 'Leads',
    href: '/leads',
    icon: '#leads',
    iconType: 'svg',
    visible: () => userCan('leads.list'),
    query: () => {
      return {
        page: leadsPaginationParams.page ?? 1,
        status: leadsPaginationParams.status ?? 'Unprocessed New Leads',
        pageSize: leadsPaginationParams.pageSize ?? 30,
        sort: leadsPaginationParams.sort ?? 'createdAt:asc',
      };
    },
    children: [
      {
        name: 'My Workplace',
        href: '/leads',
        icon: BriefcaseIcon,
        visible: () => userCan('leads.list'),
      },
      // {
      //   name: 'Marketing Module',
      //   href: '/leads-marketing-module',
      //   icon: PhoneIcon,
      //   visible: () => userCan('leads.list'),
      // },
      {
        name: 'Records',
        href: '/leads-data',
        icon: TableIcon,
        visible: () => userCan('leads.list'),
      },
      {
        name: 'Metrics',
        href: '/leads-metrics',
        icon: ChartBarIcon,
        visible: () => userCan('leads.metrics'),
      },
      {
        name: 'Settings',
        href: '/leads-settings',
        icon: CogIcon,
        visible: () => userCan('leads.list'),
      },
      {
        name: 'Archived',
        href: '/leads-archived',
        icon: ArchiveIcon,
        visible: () => userCan('leads.list'),
      },
      {
        name: 'No Seller Info',
        href: '/leads-no-seller',
        icon: QuestionMarkCircleIcon,
        visible: () => userCan('leads.list'),
      },
      {
        name: 'Deleted Leads',
        href: '/deleted-leads',
        icon: TrashIcon,
        visible: () => userCan('leads.admin.team.lead'),
      },
    ],
  };
};

export default useLeadsMenu;
