import axios from 'axios';
import moment from 'moment';

import { useRoles } from 'features/roles';
import { useEffect, useState } from 'react';
import { upperFirst, camelCase } from 'lodash';
import useClient from 'features/clients/useClient';
import ServiceTypeBreakdown from './ServiceTypeBreakdown';

const MemberServiceTypeBreakdown = ({ year, supervisor }) => {
  const { SERVICES } = useClient();
  const { BRAND_STRATEGIST } = useRoles();
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

      const usersResponse = await axios.get('/v2/users', {
        params: {
          endDate,
          startDate,
          'role.code': BRAND_STRATEGIST.value,
          supervisorId: supervisor?.userId,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            ...SERVICES.map((serviceType) => {
              let serviceKey = upperFirst(camelCase(serviceType.value));

              return `totalAssignedActiveClientsWith${serviceKey}Service`;
            }),
          ],
        },
      });

      const members = SERVICES.map((service) => {
        let serviceKey = upperFirst(camelCase(service.value));
        const attribute = `totalAssignedActiveClientsWith${serviceKey}Service`;

        let obj = { service: service.value, count: supervisor[attribute] };

        let assigned = 0;

        usersResponse.data.data.forEach((user) => {
          obj[`${user.firstName} ${user.lastName}`] = user[attribute];
          assigned = assigned + user[attribute];
        });

        obj['Unassigned'] = obj.count - assigned;

        return obj;
      });

      if (isSubscribed) {
        setMembers(members);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year, supervisor]);

  return (
    <ServiceTypeBreakdown
      loading={loading}
      teams={members}
      colors={[
        '#202020',
        '#949494',
        '#BEBEBE',
        '#6082B6',
        '#7393B3',
        '#808080',
        '#818589',
        '#D3D3D3',
        '#899499',
        '#C0C0C0',
        '#708090',
        '#848884',
        '#71797E',
      ]}
    />
  );
};

export default MemberServiceTypeBreakdown;
