const MenuSpriteIcons = () => (
  <svg display="none" className="hidden">
    <symbol id="clients" width="14" height="14" viewBox="0 0 14 14">
      <path d="M7.00004 0.333374C3.32004 0.333374 0.333374 3.32004 0.333374 7.00004C0.333374 10.68 3.32004 13.6667 7.00004 13.6667C10.68 13.6667 13.6667 10.68 13.6667 7.00004C13.6667 3.32004 10.68 0.333374 7.00004 0.333374ZM3.71337 11.1867C4.00004 10.5867 5.74671 10 7.00004 10C8.25337 10 10.0067 10.5867 10.2867 11.1867C9.38004 11.9067 8.24004 12.3334 7.00004 12.3334C5.76004 12.3334 4.62004 11.9067 3.71337 11.1867ZM11.24 10.22C10.2867 9.06004 7.97337 8.66671 7.00004 8.66671C6.02671 8.66671 3.71337 9.06004 2.76004 10.22C2.08004 9.32671 1.66671 8.21337 1.66671 7.00004C1.66671 4.06004 4.06004 1.66671 7.00004 1.66671C9.94004 1.66671 12.3334 4.06004 12.3334 7.00004C12.3334 8.21337 11.92 9.32671 11.24 10.22ZM7.00004 3.00004C5.70671 3.00004 4.66671 4.04004 4.66671 5.33337C4.66671 6.62671 5.70671 7.66671 7.00004 7.66671C8.29337 7.66671 9.33337 6.62671 9.33337 5.33337C9.33337 4.04004 8.29337 3.00004 7.00004 3.00004ZM7.00004 6.33337C6.44671 6.33337 6.00004 5.88671 6.00004 5.33337C6.00004 4.78004 6.44671 4.33337 7.00004 4.33337C7.55337 4.33337 8.00004 4.78004 8.00004 5.33337C8.00004 5.88671 7.55337 6.33337 7.00004 6.33337Z" />
    </symbol>
    <symbol id="billing" width="16" height="12" viewBox="0 0 16 12">
      <path d="M12.6666 7.33329V1.99996C12.6666 1.26663 12.0666 0.666626 11.3333 0.666626H1.99996C1.26663 0.666626 0.666626 1.26663 0.666626 1.99996V7.33329C0.666626 8.06663 1.26663 8.66663 1.99996 8.66663H11.3333C12.0666 8.66663 12.6666 8.06663 12.6666 7.33329ZM11.3333 7.33329H1.99996V1.99996H11.3333V7.33329ZM6.66663 2.66663C5.55996 2.66663 4.66663 3.55996 4.66663 4.66663C4.66663 5.77329 5.55996 6.66663 6.66663 6.66663C7.77329 6.66663 8.66663 5.77329 8.66663 4.66663C8.66663 3.55996 7.77329 2.66663 6.66663 2.66663ZM15.3333 2.66663V9.99996C15.3333 10.7333 14.7333 11.3333 14 11.3333H2.66663C2.66663 10.6666 2.66663 10.7333 2.66663 9.99996H14V2.66663C14.7333 2.66663 14.6666 2.66663 15.3333 2.66663Z" />
    </symbol>
    <symbol id="leads" width="14" height="14" viewBox="0 0 14 14">
      <path d="M2.9556 1.81487C3.00004 2.47413 3.11115 3.11857 3.28893 3.73339L2.40004 4.62227C2.09634 3.73339 1.90374 2.79264 1.83708 1.81487H2.9556ZM10.2593 10.7186C10.8889 10.8963 11.5334 11.0075 12.1852 11.0519V12.1556C11.2074 12.0889 10.2667 11.8963 9.37041 11.6001L10.2593 10.7186ZM3.66671 0.333385H1.07411C0.666707 0.333385 0.333374 0.666719 0.333374 1.07413C0.333374 8.02968 5.97041 13.6667 12.926 13.6667C13.3334 13.6667 13.6667 13.3334 13.6667 12.926V10.3408C13.6667 9.93339 13.3334 9.60005 12.926 9.60005C12.0074 9.60005 11.1112 9.4519 10.2815 9.17783C10.2074 9.1482 10.126 9.14079 10.0519 9.14079C9.8593 9.14079 9.67412 9.21487 9.52597 9.35561L7.89634 10.9852C5.80004 9.91116 4.08152 8.20005 3.01486 6.10376L4.64449 4.47413C4.85189 4.26672 4.91115 3.97783 4.82967 3.71857C4.5556 2.88894 4.40745 2.00005 4.40745 1.07413C4.40745 0.666719 4.07411 0.333385 3.66671 0.333385Z" />
      <path d="M8.42301 1.10591L9.57027 1.16054V3.29116L12.8618 -0.000366211L13.6813 0.8191L10.3897 4.11062L12.493 4.11062L12.4794 5.27153L8.40936 5.27153L8.42301 1.10591Z" />
    </symbol>
    <symbol id="sales" width="14" height="14" viewBox="0 0 14 14">
      <path d="M2.9556 1.81486C3.00004 2.47411 3.11115 3.11856 3.28893 3.73337L2.40004 4.62226C2.09634 3.73337 1.90374 2.79263 1.83708 1.81486H2.9556ZM10.2593 10.7186C10.8889 10.8963 11.5334 11.0074 12.1852 11.0519V12.1556C11.2074 12.0889 10.2667 11.8963 9.37041 11.6L10.2593 10.7186ZM3.66671 0.333374H1.07411C0.666707 0.333374 0.333374 0.666707 0.333374 1.07411C0.333374 8.02967 5.97041 13.6667 12.926 13.6667C13.3334 13.6667 13.6667 13.3334 13.6667 12.926V10.3408C13.6667 9.93337 13.3334 9.60004 12.926 9.60004C12.0074 9.60004 11.1112 9.45189 10.2815 9.17782C10.2074 9.14819 10.126 9.14078 10.0519 9.14078C9.8593 9.14078 9.67412 9.21486 9.52597 9.3556L7.89634 10.9852C5.80004 9.91115 4.08152 8.20004 3.01486 6.10374L4.64449 4.47412C4.85189 4.26671 4.91115 3.97782 4.82967 3.71856C4.5556 2.88893 4.40745 2.00004 4.40745 1.07411C4.40745 0.666707 4.07411 0.333374 3.66671 0.333374Z" />
      <path d="M13.2169 4.534L12.0696 4.47937V2.34876L8.77812 5.64028L7.95865 4.82082L11.2502 1.52929L9.14688 1.52929L9.16053 0.368382L13.2306 0.368382L13.2169 4.534Z" />
    </symbol>
    <symbol id="writing" width="14" height="14" viewBox="0 0 14 14">
      <path d="M8.52597 4.79263L9.20745 5.47411L2.49634 12.1852H1.81486V11.5037L8.52597 4.79263ZM11.1926 0.333374C11.0074 0.333374 10.8149 0.407448 10.6741 0.548189L9.31856 1.90374L12.0963 4.68152L13.4519 3.32597C13.7408 3.03708 13.7408 2.57041 13.4519 2.28152L11.7186 0.548189C11.5704 0.400041 11.3852 0.333374 11.1926 0.333374ZM8.52597 2.69634L0.333374 10.8889V13.6667H3.11115L11.3037 5.47411L8.52597 2.69634Z" />
    </symbol>
    <symbol id="design" width="14" height="12" viewBox="0 0 14 12">
      <path d="M13.6666 11.5518V0.666626H0.333292V11.5518H13.6666ZM3.37651 3.94934H1.7444V2.07774H3.37651V3.94934ZM3.37651 7.045H1.7444V5.17342H3.37651V7.045ZM12.2555 10.1407H4.60059V2.07774H12.2555V10.1407ZM3.37651 10.1407H1.7444V8.26909H3.37651V10.1407Z" />
    </symbol>
    <symbol id="admin" width="14" height="12" viewBox="0 0 14 12">
      <path d="M12.3334 2.66667H9.66671V1.33333L8.33337 0H5.66671L4.33337 1.33333V2.66667H1.66671C0.933374 2.66667 0.333374 3.26667 0.333374 4V7.33333C0.333374 7.83333 0.600041 8.25333 1.00004 8.48667V10.6667C1.00004 11.4067 1.59337 12 2.33337 12H11.6667C12.4067 12 13 11.4067 13 10.6667V8.48C13.3934 8.24667 13.6667 7.82 13.6667 7.33333V4C13.6667 3.26667 13.0667 2.66667 12.3334 2.66667ZM5.66671 1.33333H8.33337V2.66667H5.66671V1.33333ZM1.66671 4H12.3334V7.33333H9.00004V5.33333H5.00004V7.33333H1.66671V4ZM7.66671 8H6.33337V6.66667H7.66671V8ZM11.6667 10.6667H2.33337V8.66667H5.00004V9.33333H9.00004V8.66667H11.6667V10.6667Z" />
    </symbol>
  </svg>
);

export default MenuSpriteIcons;
