import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  BarChart,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { currencyFormatter } from 'utils/formatters';

const SalesSummary = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  className = '',
}) => {
  const [overview, setOverview] = useState({ total: {}, perUnit: {} });

  const bars = [
    { key: 'sales', display: 'Sales', color: '#00966D' },
    { key: 'cost', display: 'Spend', color: '#C30000' },
    { key: 'profit', display: 'Net Sales', color: '#949494' },
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: ['sales', 'unitsSold', 'cost', 'profit'],
        ...additionalParams,
      };

      const response = await axios.get('/account/advertising/analytics', {
        params,
      });

      if (isSubscribed) {
        let { data } = response.data;

        let summary = {
          total: data,
          perUnit: {
            sales: 0,
            unitsSold: 0,
            cost: 0,
            profit: 0,
          },
        };

        if (summary.total.unitsSold) {
          summary.perUnit.unitsSold = 1;
          summary.perUnit.sales =
            Math.round(
              (summary.total.sales / summary.total.unitsSold + Number.EPSILON) *
                100
            ) / 100;

          summary.perUnit.cost =
            Math.round(
              (summary.total.cost / summary.total.unitsSold + Number.EPSILON) *
                100
            ) / 100;

          summary.perUnit.profit =
            Math.round(
              (summary.total.profit / summary.total.unitsSold +
                Number.EPSILON) *
                100
            ) / 100;
        }

        setOverview(summary);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return overview.total.cost ||
    overview.total.sales ||
    overview.total.orders ? (
    <div
      className={`${className} p-6 border border-gray-300 bg-white shadow hover:shadow-xl rounded-xl`}
    >
      <div className="flex justify-between items-center mb-4">
        <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
          Sales Summary
        </p>
        <div className="flex flex-col">
          {bars.map((bar) => (
            <div className="flex items-center mb-2" key={`${bar.key}-label`}>
              <div
                className="w-5 h-5"
                style={{ backgroundColor: bar.color }}
              ></div>
              <span className="ml-1 mr-4 text-mini text-grayscale-800 leading-1.5">
                {bar.display}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="gap-8 grid grid-cols-2">
        {['total', 'perUnit'].map((key) => {
          return (
            <div className="h-96" key={key}>
              <div className="mb-4 text-grayscale-800 tracking-3/4 font-bold font-inter">
                {key === 'total' ? 'Total' : 'Per Unit'}
              </div>
              <ResponsiveContainer height="100%">
                <BarChart
                  data={[{ ...overview[key], cost: overview[key].cost * -1 }]}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} />

                  <XAxis dataKey="name" />
                  <YAxis
                    tickFormatter={(value) => currencyFormatter(value)}
                    style={{ fontSize: '0.6rem' }}
                  />

                  {bars.map((bar) => (
                    <Bar
                      key={`${bar.key}-bar`}
                      dataKey={bar.key}
                      fill={bar.color}
                      isAnimationActive={false}
                      barSize={120}
                    >
                      <LabelList
                        dataKey={bar.key}
                        position="insideTop"
                        formatter={(value) => currencyFormatter(value)}
                        fill="#ffffff"
                        style={{ fontSize: '0.55rem' }}
                      />
                    </Bar>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SalesSummary;
