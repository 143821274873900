import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import axios from 'axios';
import moment from 'moment';

const Export = ({ params }) => {
  const [loading, setLoading] = useState(false);
  const getCsv = async () => {
    setLoading(true);
    await axios
      .get(`/agency/clients/survey/export`, {
        params: {
          ...params,
        },
      })
      .then((res) => {
        if (res.data !== 'no data') {
          const blob = new Blob([res.data], {
            type: res.headers['content-type'],
            encoding: 'UTF-8',
          });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `survey-${moment().format('YYYY-MM-DD')}.csv`;
          link.click();
        }
      });
    setLoading(false);
  };

  const onExport = () => {
    async function getData() {
      await getCsv(true);
    }
    getData();
  };

  return (
    <div>
      <div className="flex justify-end">
        <Button
          type="submit"
          onClick={onExport}
          showLoading={true}
          loading={loading}
        >
          Export
        </Button>
      </div>
    </div>
  );
};

export default Export;
