import { useSelector } from 'react-redux';
import { BriefcaseIcon } from '@heroicons/react/outline';

import usePermissions from 'hooks/usePermissions';

const useDesignMenu = () => {
  const { userCan } = usePermissions();
  const { pods } = useSelector((state) => state.organization);

  return {
    name: 'Design',
    href: '/design',
    icon: '#design',
    iconType: 'svg',
    visible: () => userCan('design.view'),
    children: [
      ...pods
        .filter((pod) => pod.type === 'design')
        .map((pod) => {
          return {
            name: pod.name,
            href: `/design/teams/${pod.podId}/summary`,
            icon: BriefcaseIcon,
            visible: () => userCan('design.team_workspace_summary.view'),
          };
        }),
      ...pods
        .filter((pod) => pod.type === 'design')
        .map((pod) => {
          return {
            name: pod.name,
            href: `/design/teams/${pod.podId}/workspace`,
            icon: BriefcaseIcon,
            visible: () => userCan('design.team_workspace.view'),
          };
        }),
      {
        name: 'Metrics',
        href: `/design/analytics`,
        icon: BriefcaseIcon,
        visible: () => true,
      },
      {
        name: 'Pending',
        href: `/design/open`,
        icon: BriefcaseIcon,
        visible: () => userCan('design.pending.view'),
      },
    ],
  };
};
export default useDesignMenu;
