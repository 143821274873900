import axios from 'axios';
import { useState } from 'react';

import { statuses } from './constants';

export default function useUsers() {
  const [users, setUsers] = useState({ loading: true, data: [] });
  const [selectedUser, setSelectedUser] = useState({ loading: true, data: {} });

  const [userList, setUserList] = useState({
    loading: true,
    data: { rows: [], count: 0 },
  });

  /**
   * Fetch user list.
   *
   * @param {object} params
   */
  const fetchUserList = async (params = {}) => {
    setUserList({ loading: true, data: { rows: [], count: 0 } });

    const { data: response } = await axios.get('/v2/users/list', { params });

    setUserList({ loading: false, data: response.data });

    return response.data;
  };

  /**
   * Fetch all users.
   *
   * @param {object} params
   */
  const fetchAllUsers = async (params = {}) => {
    setUsers({ loading: true, data: [] });

    const { data: response } = await axios.get('/v2/users', { params });

    setUsers({ loading: false, data: response.data });

    return response.data;
  };

  /**
   * Fetch user details.
   *
   * @param {uuid} userId
   * @param {object} params
   * @returns
   */
  const fetchUserDetails = async (userId, params = {}) => {
    setSelectedUser({ loading: true, data: {} });

    const { data: response } = await axios.get(`/v2/users/${userId}`, {
      params,
    });

    setSelectedUser({ loading: false, data: response.data });

    return response.data;
  };

  const getStatusByValue = (value) =>
    value
      ? Object.keys(statuses)
          .map((key) => statuses[key])
          .find((status) => status.value === value)
      : null;

  return {
    users,
    userList,
    statuses,
    selectedUser,

    fetchUserList,
    fetchAllUsers,
    fetchUserDetails,

    setUsers,
    setUserList,
    setSelectedUser,

    getStatusByValue,
  };
}
