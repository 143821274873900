import axios from 'axios';
import { Route, Switch, useHistory } from 'react-router-dom';
import InvoiceHistory from './InvoiceHistory';
import InvoiceDetails from './InvoiceDetails';

const Invoices = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}`} component={InvoiceHistory} exact />
      <Route path={`${path}/:invoiceId`} component={InvoiceDetails} />
    </Switch>
  );
};
export default Invoices;
