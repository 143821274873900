import Rating from 'react-rating';
import { StarIcon } from '@heroicons/react/solid';
import classNames from 'utils/classNames';
import { isFunction } from 'lodash';

const StarRating = ({
  value,
  readOnly = false,
  size = 5,
  gap = 0,
  onChange,
}) => {
  return (
    <Rating
      fractions={2}
      readOnly={readOnly}
      initialRating={value}
      emptySymbol={
        <StarIcon className={classNames(`w-${size} h-${size}`, `mx-${gap}`)} />
      }
      fullSymbol={
        <StarIcon
          className={classNames(
            `w-${size} h-${size}`,
            `mx-${gap}`,
            'text-yellow-500'
          )}
        />
      }
      onChange={(value) => isFunction(onChange) && onChange(value)}
    />
  );
};

export default StarRating;
