import Checkbox from 'components/Forms/Checkbox';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectReport } from '../../optimizationSlice';

const UpdateStatus = ({
  accountId,
  marketplace,
  option,
  checked,
  onUpdate,
}) => {
  const report = useSelector(selectReport);

  const onChangeChecked = async (e) => {
    const reportId = report.advOptimizationReportId;
    const optionId = option.advOptimizationReportItemOptionId;
    const itemId = option.advOptimizationReportItemId;

    const { checked } = e.target;

    await axios.put(
      `/account/advertising/optimizations/reports/${reportId}/items/${itemId}/options/${optionId}`,
      {
        accountId,
        marketplace,
        selected: checked,
        data: {},
      }
    );

    onUpdate(optionId, checked);
  };

  return (
    <div className="flex items-center mb-2">
      <Checkbox checked={checked} onChange={onChangeChecked} />

      <div className="ml-3">
        <p className="font-medium">{option.rule.name}</p>
        <p>{`Update status to ${option.rule.actionData.state}`}</p>
      </div>
    </div>
  );
};

export default UpdateStatus;
