import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import usePermissions from 'hooks/usePermissions';

import General from './userSettings/General';
import Password from './userSettings/Password';
import Hubstaff from './userSettings/Hubstaff';
import NotificationPreferences from './userSettings/NotificationPreferences';

const UserSettings = () => {
  const { url } = useRouteMatch();
  const { isAgencyLevel } = usePermissions();

  const tabs = [
    {
      name: 'General',
      exact: true,
      href: url,
      component: General,
      visible: true,
    },
    {
      name: 'Password',
      exact: true,
      href: `${url}/password`,
      component: Password,
      visible: true,
    },
    {
      name: 'Hubstaff',
      exact: true,
      href: `${url}/hubstaff`,
      component: Hubstaff,
      visible: isAgencyLevel(),
    },
    {
      name: 'Notification Preferences',
      exact: true,
      href: `${url}/notif-preferences`,
      component: NotificationPreferences,
      visible: isAgencyLevel(),
    },
  ];

  return (
    <div>
      <h3 className="text-25 font-inter text-grayscale-900 font-bold tracking-3/4">
        Settings
      </h3>

      <div className="">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs
              .filter((tab) => tab.visible)
              .map((tab) => (
                <NavLink
                  key={`user-settings-tab-links-${tab.href}`}
                  className="whitespace-nowrap border-b-2 py-4 px-1 tracking-wider leading-1.5 text-13"
                  activeClassName="border-red-500 text-red-600"
                  to={tab.href}
                  exact={tab.exact}
                >
                  {tab.name}
                </NavLink>
              ))}
          </nav>
        </div>
      </div>

      <div className="mt-5">
        <Switch>
          {tabs
            .filter((tab) => tab.visible)
            .map((tab) => (
              <Route
                key={`user-settings-subroutes-${tab.href}`}
                path={tab.href}
                exact={tab.exact}
                component={tab.component}
              />
            ))}
        </Switch>
      </div>
    </div>
  );
};

export default UserSettings;
