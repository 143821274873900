import { Avatar } from 'components';
import { getNameInitials } from 'utils/formatters';

const ClientAssignee = ({ user }) => {
  return (
    <Avatar
      bgColor={user ? '#1879D8' : '#666666'}
      imageSrc={user?.avatar?.thumbnailUrl}
      initials={user ? getNameInitials(user?.firstName, user?.lastName) : 'U'}
    />
  );
};

export default ClientAssignee;
