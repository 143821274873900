import axios from 'axios';
import moment from 'moment';
import { range } from 'lodash';
import { useEffect, useState } from 'react';

import classNames from 'utils/classNames';

const DailySyncStatus = ({ account, marketplace }) => {
  const [records, setRecords] = useState([]);

  const startDate = moment().subtract(101, 'days').format('YYYY-MM-DD');
  const endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

  const types = [
    {
      display: 'SP Targetings',
      recordType: 'targetings',
      campaignType: 'sponsoredProducts',
    },
    {
      display: 'SP Searchterms',
      recordType: 'searchTerms',
      campaignType: 'sponsoredProducts',
    },
    {
      display: 'SP Product Ads',
      recordType: 'productAds',
      campaignType: 'sponsoredProducts',
    },
    {
      display: 'SB Keywords',
      recordType: 'keywords',
      campaignType: 'sponsoredBrands',
    },
    {
      display: 'SB Searchterms',
      recordType: 'searchTerms',
      campaignType: 'sponsoredBrands',
    },
    {
      display: 'SD Targets',
      recordType: 'targets',
      campaignType: 'sponsoredDisplay',
    },
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/advertising/sync/history', {
        params: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          startDate,
          endDate,
        },
      });

      if (isSubscribed) {
        setRecords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace]);

  return (
    <div className="md:col-span-7 col-span-10 bg-white rounded-xl bs-shadow my-2">
      <p className="font-bold text-13 font-inter tracking-3/4 leading-1.5 text-grayscale-900 border-b border-grayscale-500 p-4">
        Report Sync Status By Day
      </p>

      <div className="p-4">
        <div className="overflow-x-auto">
          <table>
            <tbody>
              {types.map((type) => {
                return (
                  <tr
                    key={`daily-sync-status-${type.campaignType}-${type.recordType}`}
                  >
                    <td
                      style={{ minWidth: '150px' }}
                      className="text-13 text-grayscale-700 sticky left-0 z-10 whitespace-nowrap bg-white"
                    >
                      {type.display}
                    </td>

                    {range(2, 102).map((i) => {
                      const date = moment()
                        .subtract(i, 'days')
                        .format('YYYY-MM-DD');

                      const record = records.find(
                        (i) =>
                          i.date === date &&
                          i.campaignType === type.campaignType &&
                          i.recordType === type.recordType
                      );

                      return (
                        <td
                          key={`daily-sync-status-${type.campaignType}-${type.recordType}-${date}`}
                          className={classNames(
                            'border px-3 py-5',
                            record ? 'bg-success-dark' : 'bg-grayscale-500'
                          )}
                          title={
                            record
                              ? `${date} last sync at: ${moment(
                                  record.syncAt
                                ).format(' MMMM Do, h:mm:ss a')}`
                              : `${date}: Not yet sync`
                          }
                        ></td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                <td
                  style={{ minWidth: '150px' }}
                  className="text-13 text-grayscale-700 sticky left-0 z-10 whitespace-nowrap bg-white"
                ></td>
                {range(2, 102).map((i) => {
                  const date = moment()
                    .subtract(i, 'days')
                    .format('YYYY-MM-DD');

                  return (
                    <td className="h-20 transform rotate-90 text-11 whitespace-nowrap">
                      {date}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DailySyncStatus;
