export const PERMISSION_FEATURES = {
  CLIENTS: {
    value: 'clients',
    label: 'Clients',
  },
  SALES: {
    value: 'sales',
    label: 'Sales',
  },
  BILLING: {
    value: 'billing',
    label: 'Billing',
  },
  DESIGN: {
    value: 'design',
    label: 'Design',
  },
  WRITING: {
    value: 'writing',
    label: 'Writing',
  },
  LEADS: {
    value: 'leads',
    label: 'Leads',
  },
  ACCOUNT: {
    value: 'account',
    label: 'Account',
  },
  ADMIN: {
    value: 'admin',
    label: 'Admin',
  },
};
