import InvoicePaid from './InvoicePaid';
import OverdueTask from './OverdueTask';
import InvoiceOverdue from './InvoiceOverdue';
import ProposalSigned from './ProposalSigned';
import SalesBookedCall from './SalesBookedCall';
import NewTaskAssignee from './NewTaskAssignee';
import TerminatedClient from './TerminatedClient';
import ClientSubscribed from './ClientSubscribed';
import MentionInComment from './MentionInComment';
import NewClientAssignee from './NewClientAssignee';
import NewSalesClientAssignee from './NewSalesClientAssignee';
import SalesClientStatusChanged from './SalesClientStatusChanged';
import LeadProfileStatusChanged from './LeadProfileStatusChanged';
import CreditNoteStatusChanged from './CreditNoteStatusChanged';
import PaymentConfirmed from './PaymentConfirmed';
import UpsellStatusUpdated from './UpsellStatusUpdated';

export default {
  InvoicePaid,
  OverdueTask,
  InvoiceOverdue,
  ProposalSigned,
  SalesBookedCall,
  NewTaskAssignee,
  TerminatedClient,
  ClientSubscribed,
  MentionInComment,
  NewClientAssignee,
  NewSalesClientAssignee,
  SalesClientStatusChanged,
  LeadProfileStatusChanged,
  CreditNoteStatusChanged,
  PaymentConfirmed,
  UpsellStatusUpdated,
};
