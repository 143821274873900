import { Field, ErrorMessage } from 'formik';

import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const AccountAudit = ({ handleChange }) => {
  return (
    <div className="flex flex-col w-full gap-y-6 p-8 mt-6 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.auditLink"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Audit Link <RequiredAsterisk />
        </Label>
        <Field
          name="data.auditLink"
          placeholder="Add audit link"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => handleChange(e)}
          type="text"
        />
        <ErrorMessage
          name="data.auditLink"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label
          htmlFor="data.otherInformation"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Other Information <RequiredAsterisk />
        </Label>
        <Field
          name="data.otherInformation"
          placeholder="Add information related to action taken"
          as="textarea"
          rows={3}
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.otherInformation"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
    </div>
  );
};

export default AccountAudit;
