import { startCase } from 'lodash';
const FilterItemOption = ({
  option,
  field,
  values,
  onChange,
  isParent = true,
}) => {
  return (
    <div>
      <label className="text-13 leading-1.5 text-grayscale-800">
        <input
          type="checkbox"
          name={field}
          value={option.value}
          checked={values.includes(option.value)}
          className="text-secondary focus:border-secondary-dark ring-0 focus:ring-0 focus:ring-secondary-dark rounded mr-1.5"
          onChange={(e) => {
            const { checked, name, value } = e.target;

            let itemValues = values ?? [];

            const newValues = checked
              ? [...itemValues, value]
              : [...itemValues].filter((i) => i !== value);

            let obj = { [name]: newValues };

            if (option.children && isParent) {
              obj[option.children.field] = checked
                ? option.children.options.map((opt) => opt.value)
                : [];
            }

            onChange(obj);
          }}
        />
        {startCase(option.label)}
      </label>

      {option.children && isParent && (
        <div className="flex flex-col ml-5 space-y-2 mt-2">
          {option.children.options.map((childOption) => (
            <FilterItemOption
              key={childOption.value}
              option={childOption}
              field={option.children.field}
              values={option.children.values}
              onChange={option.children.onChange}
              isParent={false}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterItemOption;
