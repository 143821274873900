import axios from 'axios';
import filesize from 'filesize';
import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.bubble.css';
import { MailIcon, ChatAltIcon, XIcon } from '@heroicons/react/solid';
import { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLink from 'components/ButtonLink';
import classNames from 'utils/classNames';
import React from 'react';
import { setAlert } from 'features/alerts/alertsSlice';
import Input from 'components/Forms/Input';
import { useFormik } from 'formik';
import { alertErrorsStringify } from 'utils/formatters';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from 'lodash';
import useMail from './components/useMail';

const ATTRIBUTES = ['src', 'title', 'class'];
const BlockEmbed = Quill.import('blots/block/embed');
class InlineImageBlot extends BlockEmbed {
  static create(val) {
    const { value, props } = val;

    const node = super.create(value);
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    }
    node.setAttribute('title', props);
    node.setAttribute('class', 'inline-img');
    return node;
  }
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  static value(domNode) {
    return {
      src: domNode.getAttribute('src'),
      title: domNode.getAttribute('title'),
    };
  }
  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
InlineImageBlot.blotName = 'inlineImage';
InlineImageBlot.tagName = 'IMG';
InlineImageBlot.className = 'inline-img';

Quill.register(InlineImageBlot);

const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ color: [] }],
  ['link', 'image'],
  ['clean'],
];

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'mention',
  'inlineImage',
];

const LinkedInEditor = ({
  qRef,
  onChangeData,
  setOpen,
  editCommentValue = '',
  threadData,
  afterSend,
  setOpenEditor,
  employees,
  isReplying = false,
  primaryKey,
  postUrl,
  isHasLinkedIn = false,
  linkedInEmail,
}) => {
  const [value, setValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const [mailAttachments, setMailAttachments] = useState([]);
  const { mailSubject } = useSelector((state) => state.mail);
  const [mailInitialValues, setMailInitialValues] = useState({
    mailTo: '',
    mailSubject: mailSubject ? mailSubject : '',
    mailMessage: '',
    mailFrom: linkedInEmail,
  });

  const { saveMailSubject } = useMail(qRef);

  useEffect(() => {
    if (isReplying) {
      setMailInitialValues({
        mailTo: threadData.mailTo,
        mailSubject: threadData.mailSubject,
        mailMessage: threadData.mailMessage,
        mailFrom: threadData.mailFrom,
      });
    }
  }, [threadData]);

  useEffect(() => {
    if (editCommentValue !== '') {
      setValue(editCommentValue);
    }
  }, [editCommentValue]);

  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getContents());
    setContentValue(content);
  };

  const pasteImageMatcher = useCallback((node, delta) => {
    const d = new Date().getTime();
    let src = node.getAttribute('src');

    return new Delta().insert({
      inlineImage: {
        value: src,
        props: d,
      },
    });
  }, []);

  const imageHandler = useCallback(() => {
    //console.log('image handler called', qRef);
    let fileInput = qRef.current.editor.container.querySelector(
      'input.ql-image[type=file]'
    );
    if (fileInput == null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
      );
      fileInput.classList.add('ql-image');
      fileInput.classList.add('invisible');
      fileInput.addEventListener('change', () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          const fileSize = fileInput.files[0].size / 1024 / 1024;
          console.log(fileSize);
          if (fileSize > 2.5) {
            dispatch(
              setAlert(
                'error',
                'Cannot add file',
                'File is too large! Size limit is 2.5MB'
              )
            );
          } else {
            let reader = new FileReader();
            reader.onload = (e) => {
              let range = qRef.current.editor.getSelection(true);
              qRef.current.editor.insertEmbed(range.index, 'inlineImage', {
                value: e.target.result,
                props: fileInput.files[0].name,
              });
              qRef.current.editor.updateContents(
                new Delta()
                  .retain(range.index)
                  .delete(range.length + 1)
                  .insert({
                    inlineImage: {
                      value: e.target.result,
                      props: fileInput.files[0].name,
                    },
                  }),
                'user'
              );
              qRef.current.editor.setSelection(range.index + 1, 'silent');
              fileInput.value = '';
            };
            reader.readAsDataURL(fileInput.files[0]);
          }
        }
      });
      qRef.current.editor.container.appendChild(fileInput);
    }
    fileInput.click();
  }, []);

  const formik = useFormik({
    initialValues: mailInitialValues,
    enableReinitialize: true,
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    validate: (values) => {
      const errors = {};

      if (!values.mailFrom.trim()) {
        errors.mailFrom = 'From is required';
      } else if (!isEmail(values.mailFrom.trim())) {
        errors.mailFrom = 'From is invalid';
      }

      if (!values.mailTo.trim()) {
        errors.mailTo = 'To is required';
      }

      if (!values.mailSubject.trim()) {
        errors.mailSubject = 'Subject is required';
      }

      if (isReplying) {
        if (!contentValue) {
          errors.mailMessage = 'Message body is required';
        }
      } else {
        if (!contentValue) {
          errors.mailMessage = 'Message body is required';
        }
      }

      if (!isEmpty(errors)) {
        dispatch(
          setAlert(
            'error',
            'Invalid email paramenters',
            alertErrorsStringify(errors)
          )
        );
      }

      return errors;
    },
    onSubmit: async (values) => {
      setSaving(true);
      try {
        const { mailTo, mailSubject, mailFrom } = values;

        if (mailAttachments.length > 0) {
          const formData = new FormData();

          mailAttachments.forEach((file) => {
            formData.append('attachment', file);
          });

          formData.append('mailTo', mailTo);
          formData.append('mailSubject', mailSubject);
          formData.append('mailFrom', mailFrom);

          formData.append(
            'structure',
            JSON.stringify(qRef.current.editor.getContents())
          );
          formData.append('text', qRef.current.editor.getText());

          //let message = contentValue;
          if (isReplying) {
            const { inReplyTo, references, threadId, mailMessage } = threadData;
            //message = `${contentValue}${mailMessage}`;
            formData.append('inReplyTo', inReplyTo);
            formData.append('references', references);
            formData.append('threadId', threadId);
            formData.append('quotedMessage', mailMessage);
          }
          formData.append(primaryKey.key, primaryKey.value);
          formData.append('mailMessage', contentValue);

          await axios.post(
            `${postUrl}${isReplying ? '/reply' : ''}/attachments`,
            formData
          );
        } else {
          let payload = {
            mailTo,
            mailSubject,
            mailFrom,
            structure: qRef.current.editor.getContents(),
            text: qRef.current.editor.getText(),
          };

          if (isReplying) {
            const { inReplyTo, references, threadId, mailMessage } = threadData;
            payload.inReplyTo = inReplyTo;
            payload.references = references;
            payload.threadId = threadId;
            payload.quotedMessage = mailMessage;
          }
          payload[primaryKey.key] = primaryKey.value;
          payload.mailMessage = contentValue;

          await axios.post(`${postUrl}${isReplying ? '/reply' : ''}`, payload);
        }

        dispatch(setAlert('success', 'Save!', `LinkedIn Thread Save`));
        setSaving(false);
        onChangeData(false);
        qRef.current.editor.setContents('');
        setMailAttachments([]);
        if (isReplying) {
          afterSend();
        } else {
          setTimeout(() => {
            setOpen(false);
          }, 500);
        }
      } catch (error) {
        dispatch(setAlert('error', 'Sending email failed!', error.message));
      }
      setSaving(false);
    },
  });

  const onAttached = (files) => {
    setMailAttachments(Array.from(files));
  };

  const onRemoveAttachment = (index) => {
    let ma = [...mailAttachments];
    ma.splice(index, 1);
    setMailAttachments(ma);
  };

  return (
    <div>
      <div>
        <div className="flex items-center px-4">
          <label className="text-sm text-gray-400">From:</label>
          <Input
            name="mailFrom"
            type="text"
            id="mailFrom"
            placeholder=""
            value={formik.values.mailFrom}
            onChange={formik.handleChange}
            rounded="rounded-none"
            border="border-0"
            classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
            readOnly={isReplying ? true : false}
          />
        </div>

        <div className="flex items-center border-b border-gray-200 px-4 flex-col">
          <div className="flex items-center w-full">
            <label className="text-sm text-gray-400 whitespace-nowrap">
              {isReplying ? 'Reply ' : ''}To:
            </label>
            <Input
              name="mailTo"
              type="text"
              id="mailTo"
              placeholder=""
              value={formik.values.mailTo}
              onChange={formik.handleChange}
              rounded="rounded-none"
              border="border-0 "
              classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
              readOnly={isReplying ? true : false}
            />
          </div>
        </div>

        <Input
          name="mailSubject"
          type="text"
          id="mailSubject"
          placeholder="Subject"
          value={formik.values.mailSubject}
          onChange={(e) => {
            saveMailSubject(e.target.value);
            formik.handleChange(e);
          }}
          rounded="rounded-none"
          border="border-0 border-b border-gray-200"
          classes={classNames(
            isReplying ? 'hidden' : '',
            'appearance-none px-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0'
          )}
          readOnly={isReplying ? true : false}
        />
      </div>

      <div
        className={classNames(
          isReplying && 'edit-comment',
          'quill-text-editor py-2'
        )}
      >
        <ReactQuill
          ref={qRef}
          theme="bubble"
          placeholder=""
          value={value}
          onChange={handleChange}
          bounds={`.quill-text-editor`}
          modules={{
            toolbar: {
              container: toolbarOptions,
              handlers: {
                image: imageHandler,
              },
            },
            clipboard: {
              matchVisual: false,
              matchers: [['img', pasteImageMatcher]],
            },
          }}
          formats={formats}
        />
      </div>
      {mailAttachments.length > 0 && (
        <div className="flex flex-col items-start px-4 py-2 space-y-1">
          {mailAttachments.map((attachment, i) => (
            <div
              key={i}
              className="bg-gray-200 py-1 px-2 flex items-center text-sm"
            >
              <span className="">{attachment.name}</span>
              <span className="ml-1 mr-2">({filesize(attachment.size)})</span>
              <button
                className="hover:text-red-400 flex items-center font-inter"
                onClick={() => onRemoveAttachment(i)}
              >
                <XIcon className="w-3 h-3 inline" />
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-between py-3 px-4 border-t">
        <div className="flex space-x-2">
          {/* <MailAttachment onAttached={onAttached} title="Upload" /> */}
        </div>
        <div className="space-x-4 flex items-center">
          {!isReplying && (
            <div className="flex mx-1">
              {isHasLinkedIn ? (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('email');
                  }}
                  title="LinkedIn Thread"
                  classes="p-0.5 bg-blue-400 font-bold rounded"
                  color="white"
                >
                  in
                </ButtonLink>
              ) : (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('email');
                  }}
                  title="Send Email"
                  classes="p-0.5"
                >
                  <MailIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
                </ButtonLink>
              )}
              <ButtonLink
                onClick={() => {
                  setOpenEditor('comment');
                }}
                title="Add Comment"
                classes="p-0.5"
              >
                <ChatAltIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
              </ButtonLink>
            </div>
          )}
          {isReplying && (
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </ButtonLink>
          )}

          <Button
            roundedSize="2xl"
            classes="tracking-wider font-bold"
            px={6}
            py={2}
            textSize="xs"
            color="green"
            onClick={() => {
              formik.handleSubmit();
            }}
            showLoading={true}
            loading={saving}
            type="submit"
            form="formEmail"
          >
            {isReplying ? 'Reply' : 'Send'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LinkedInEditor;
