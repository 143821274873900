import { useMemo, useState } from 'react';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import useTaskManagement from 'features/taskManagement/useTaskManagement';
import classNames from 'utils/classNames';
import axios from 'axios';
import { useAlerts } from 'features/alerts';
import TaskForm from './TaskForm';

const ItemTemplate = ({ layoutClass, itemTemplate }) => {
  const { DEPARTMENTS } = useTaskManagement();
  const { popupAlert } = useAlerts();
  const [isEditing, setIsEditing] = useState(false);

  const department = useMemo(
    () => DEPARTMENTS.find((d) => d.value === itemTemplate.department),
    [itemTemplate.department]
  );

  const onDelete = async () => {
    try {
      const response = await axios.delete(
        `/v2/tasks/items/templates/${itemTemplate.taskItemTemplateId}`
      );

      popupAlert({ type: 'success', title: response.data.message });
    } catch (error) {
      popupAlert({
        type: 'error',
        title: error?.response?.data?.message ?? 'Something went wrong',
      });
    }
  };

  return (
    <div>
      {isEditing ? (
        <TaskForm
          className="py-4"
          layoutClass={layoutClass}
          taskItemTemplate={itemTemplate}
          taskListTemplate={itemTemplate.listTemplate}
          onCancel={() => setIsEditing(false)}
          onSuccess={() => setIsEditing(false)}
        />
      ) : (
        <div className="grid grid-cols-7 gap-3 py-4">
          <p className={classNames('pl-4', layoutClass?.columns?.title)}>
            {itemTemplate.title}
          </p>

          <p className={layoutClass?.columns?.description}>
            {itemTemplate.description}
          </p>

          {layoutClass?.columns?.recurringConfig && <p>Recurring</p>}

          {layoutClass?.columns?.department && (
            <div className="flex items-start">
              <p
                className="text-center rounded-40 py-1.5 px-4"
                style={{
                  color: department?.color,
                  backgroundColor: department?.bgColor,
                }}
              >
                {itemTemplate.department}
              </p>
            </div>
          )}

          <div className="flex items-center justify-center space-x-2">
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="w-8 h-8 flex items-center justify-center border-2 border-secondary bg-secondary rounded-10"
            >
              <PencilAltIcon className="w-4 h-4 text-white" />
            </button>

            <button
              type="button"
              onClick={() => onDelete()}
              className="border-2 border-grayscale-700 w-8 h-8 flex items-center justify-center bg-white rounded-10"
            >
              <TrashIcon className="w-4 h-4 text-grayscale-700" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemTemplate;
