import React from 'react';
import classNames from 'utils/classNames';

const Select = ({
  children,
  id,
  name = '',
  value,
  onChange,
  className,
  disabled,
  padding = 'pl-3 pr-10 py-2',
  rounded = 'rounded-md',
  ...rest
}) => {
  return (
    <select
      {...(id && { id })}
      name={id ?? name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        'block w-full focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm disabled:bg-gray-100',
        padding,
        rounded,
        className ?? ''
      )}
      {...rest}
    >
      {children}
    </select>
  );
};

export default Select;
