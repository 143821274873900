import axios from 'axios';
import ButtonLink from 'components/ButtonLink';
import useBodyClass from 'hooks/useBodyClass';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnnotationIcon,
  CheckIcon,
  StatusOnlineIcon,
  StatusOfflineIcon,
  XIcon,
} from '@heroicons/react/outline';
import {
  PauseIcon,
  PlayIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/solid';
import usePermissions from 'hooks/usePermissions';
import Actions from './Actions';
import RecentActivitiesSlideOver from './components/RecentActivitiesSlideOver';
import Badge from 'components/Badge';
import { dateFormatter, strUnderscoreToSpace } from 'utils/formatters';
import classNames from 'utils/classNames';
import classnames from 'classnames';
import useSubscription from 'hooks/useSubscription';
import {
  CANCELLED,
  DUNNING,
  EXPIRED,
  FUTURE,
  LIVE,
  NON_RENEWING,
  PAUSED,
  UNPAID,
} from 'utils/subscriptions';
import PlanAddonsTable from './components/PlanAddonsTable';
import Notes from './components/Notes';
import ScheduledChanges from './components/ScheduledChanges';
import Summary from './components/Summary';
import Button from 'components/Button';
import { ConfirmationModal } from 'components';
import useAlert from 'hooks/useAlert';
import { fetchSubscription } from './subscriptionSlice';
import Commissions from './Commissions';
import PaymentMethodManager from 'components/Billing/PaymentMethod/PaymentMethodManager';
import useAgencyClient from 'hooks/useAgencyClient';
import Select from 'components/Forms/Select';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import SpinnerGrow from 'components/SpinnerGrow';

const Subscriptions = () => {
  useBodyClass(['profile-layout']);
  const dispatch = useDispatch();
  const { agencyClient, paymentModeBraintree, reloadClient } =
    useAgencyClient();
  const { subscription, scheduledChanges } = useSelector(
    (state) => state.subscription
  );
  const [saving, setSaving] = useState(false);
  const [savingCf, setSavingCf] = useState(false);
  const [savingAc, setSavingAc] = useState(false);
  const [savingUca, setSavingUca] = useState(false);
  const [isOpenScheduledChanges, setIsOpenScheduledChanges] = useState(false);
  const [isOpenRecentActivities, setIsOpenRecentActivities] = useState(false);
  const [isOpenUpdateCardDetails, setIsOpenUpdateCardDetails] = useState(false);
  const [isOpenRemoveCard, setIsOpenRemoveCard] = useState(false);
  const [isOpenSwitch, setIsOpenSwitch] = useState(false);
  const { userCan, isAgencySuperUser } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const status = useSubscription(subscription.z);
  const [chargeAdminFee, setChargeAdminFee] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [paymentMode, setPaymentMode] = useState(agencyClient.paymentMode);
  const [savingMode, setSavingMode] = useState(false);

  useEffect(() => {
    if (subscription.z.custom_field_hash) {
      setChargeAdminFee(
        subscription.z.custom_field_hash.cf_charge_admin_fee
          ? subscription.z.custom_field_hash.cf_charge_admin_fee === 'yes'
            ? true
            : false
          : false
      );
    }
  }, [subscription]);

  const onChargeAdminFee = async () => {
    let state = !chargeAdminFee;
    setChargeAdminFee(state);
    let charge_admin_fee = state === true ? 'yes' : 'no';
    try {
      const res = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/customfields`,
        { label: 'charge_admin_fee', value: charge_admin_fee }
      );

      if (res.data.data.code === 0) {
        alertSuccess('Charge admin fee updated', res.data.data.message);
      } else {
        alertError('Operation failed', res.data.data.message);
      }
    } catch (error) {
      alertError('Error', error.response.data.message);
    }
  };

  const onPauseCollect = async () => {
    setSavingCf(true);
    try {
      const res = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/customfields`,
        {
          label: 'pause collect',
          value: !subscription.z.custom_field_hash.cf_pause_collect_unformatted,
        }
      );

      if (res.data.data.code === 0) {
        alertSuccess('Pause Collect updated', res.data.data.message);
        dispatch(fetchSubscription(subscription.z.subscription_id));
      } else {
        alertError('Operation failed', res.data.data.message);
      }
    } catch (error) {
      alertError('Error', error.response.data.message);
    } finally {
      setSavingCf(false);
    }
  };

  const updateCardDetails = async () => {
    setSaving(true);

    try {
      const response = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/updatecard`
      );

      alertSuccess(
        'Email will be sent shortly',
        `A link will be sent to ${agencyClient.defaultContact.email} with the steps to update their payment details`
      );
    } catch (error) {
      alertError('Email not sent', error.response.data.message);
    } finally {
      setIsOpenUpdateCardDetails(false);
      setSaving(false);
    }
  };

  const onRemoveCard = async () => {
    setSaving(true);

    try {
      const response = await axios.delete(
        `subscriptions/${subscription.z.subscription_id}/card`
      );

      alertSuccess('Card Removed', response.data.data.message);
      dispatch(fetchSubscription(subscription.z.subscription_id));
    } catch (error) {
      alertError('Cannot remove card', error.response.data.message);
    } finally {
      setIsOpenRemoveCard(false);
      setSaving(false);
    }
  };

  const changeAutoCollect = async () => {
    setSavingAc(true);
    try {
      const response = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/autocollect`,
        { auto_collect: !subscription.z.auto_collect }
      );
      alertSuccess(
        'Payment mode updated',
        `Subscription set to ${
          !subscription.z.auto_collect ? 'Online' : 'Offline'
        }`
      );
      dispatch(fetchSubscription(subscription.z.subscription_id));
    } catch (error) {
      alertError('Cannot update payment mode', error.response.data.message);
    } finally {
      setSavingAc(false);
    }
  };

  const onUpdateCardAdmin = async () => {
    setSavingUca(true);

    try {
      const response = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/updatecard/admin`
      );

      alertSuccess(
        'Hosted page generated',
        `Opening a new tab for the generated hosted page`
      );

      setTimeout(() => {
        window.open(response.data.data.hostedpage.url, '_blank');
      }, 500);
    } catch (error) {
      alertError('Email not sent', error.response.data.message);
    } finally {
      setIsOpenUpdateCardDetails(false);
      setSavingUca(false);
    }
  };

  const row = (
    label,
    value,
    dtColspan = 'col-span-2',
    dlColspan = 'col-span-7'
  ) => {
    return (
      <>
        <dt className={classNames(dtColspan, 'text-sm text-gray-400')}>
          {label}
        </dt>
        <dd className={classNames(dlColspan, 'text-sm text-gray-800 pl-2')}>
          {value}
        </dd>
      </>
    );
  };

  const onChangePaymentMode = async () => {
    //console.log(paymentMode);
    setSavingMode(true);
    await axios
      .patch(`/agency/clients/${agencyClient.agencyClientId}/paymentmode`, {
        paymentMode,
      })
      .then((response) => {
        alertSuccess('Payment mode updated', response.data.message);
        setOpenChange(false);
        reloadClient();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      })
      .finally(() => setSavingMode(false));
  };

  const onSwitch = async () => {
    setSavingMode(true);
    await axios
      .post(
        `/agency/clients/${agencyClient.agencyClientId}/payment-methods/switch-notify`
      )
      .then((response) => {
        alertSuccess('Mail notification sent to queue', response.data.message);
        setIsOpenSwitch(false);
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      })
      .finally(() => setSavingMode(false));
  };

  return (
    <>
      <div className="client-content container-fixed bg-white">
        <div className="px-8 pt-8 pb-16">
          <h4 className="text-2xl font-bold">Overview</h4>
          <dl className="col-span-8 sm:grid sm:grid-cols-9 space-y-1 items-center">
            {row('Client', subscription.z?.customer?.display_name)}
            {row('Subscription #', `#${subscription.z.subscription_number}`)}
            {row(
              'Status',
              <div className="flex items-center space-x-2">
                <Badge
                  classes={classNames(
                    classnames({
                      'bg-green-100 text-green-600': status.live(),
                      'bg-gray-100 text-gray-600': status.nonRenewing(),
                      'bg-yellow-100 text-yellow-600': status.cancelled(),
                      'bg-red-100 text-red-600': status.expired(),
                      'bg-blue-100 text-blue-600': status.paused(),
                      'bg-indigo-100 text-indigo-600': status.hasAny([
                        UNPAID,
                        DUNNING,
                      ]),
                      'bg-pink-100 text-pink-600': status.future(),
                    }),
                    'capitalize rounded-2xl  text-xs'
                  )}
                  rounded=""
                  textSize=""
                  color=""
                  padding="px-3 py-1"
                >
                  {status.live() && (
                    <CheckIcon className="w-4 h-4 inline mr-1" />
                  )}
                  {status.hasAny([NON_RENEWING, EXPIRED, CANCELLED]) && (
                    <XIcon className="w-4 h-4 inline mr-1" />
                  )}
                  {status.paused() && (
                    <PauseIcon className="w-4 h-4 inline mr-1" />
                  )}
                  {status.hasAny([UNPAID, DUNNING]) && (
                    <ExclamationCircleIcon className="w-4 h-4 inline mr-1" />
                  )}

                  {strUnderscoreToSpace(subscription.z.status)}
                </Badge>
                {subscription.d.isOffline && (
                  <Badge
                    classes="bg-blue-100 text-blue-600 capitalize rounded-2xl  text-xs"
                    rounded=""
                    textSize=""
                    color=""
                    padding="px-3 py-1"
                  >
                    Offline
                  </Badge>
                )}
              </div>
            )}
            {row(
              'Billing Address',
              Object.values(subscription.z.customer.billing_address).some(
                (prop) => prop !== ''
              ) ? (
                <>
                  <span className=" block text-md text-gray-500">
                    {subscription.z.customer.billing_address.street}
                  </span>
                  {subscription.z.customer.billing_address.street2 && (
                    <span className=" block text-md text-gray-500">
                      {subscription.z.customer.billing_address.street2}
                    </span>
                  )}
                  <span className="text-md text-gray-500">
                    {[
                      subscription.z.customer.billing_address.city,
                      subscription.z.customer.billing_address.state,
                      subscription.z.customer.billing_address.zip,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </span>
                  <span className=" text-md text-gray-500">
                    , {subscription.z.customer.billing_address.country}
                  </span>
                </>
              ) : (
                <span className="text-gray-500 text-sm">
                  No address specified
                </span>
              )
            )}

            {row(
              'Activation Date',
              `${dateFormatter(subscription.z.activated_at)}`
            )}
            {row(
              'Start Date',
              `${dateFormatter(subscription.z.current_term_starts_at)}`
            )}
            {row(
              'Valid Until Date',
              `${dateFormatter(subscription.z.current_term_ends_at)}`
            )}
          </dl>
          <Commissions />

          {paymentModeBraintree() ? (
            <dl className="col-span-8 mt-5">
              <PaymentMethodManager
                agencyClientId={agencyClient.agencyClientId}
                canEdit={userCan('clients.subscription.card.add')}
                canCreate={userCan('clients.subscription.card.add')}
              />
            </dl>
          ) : (
            <>
              <div className="flex justify-between items-center mt-8">
                <h4 className="text-xl font-bold">
                  Payment Method
                  {subscription.z.auto_collect ? (
                    <span title="online. auto collect true">
                      <StatusOnlineIcon className="ml-2 w-6 h-6 inline text-green-600" />
                    </span>
                  ) : (
                    <span title="offline. auto collect false">
                      <StatusOfflineIcon className="ml-2 w-6 h-6 inline text-red-600" />
                    </span>
                  )}
                </h4>
                <div className="flex items-center space-x-4">
                  {status.hasAny([LIVE, FUTURE, UNPAID, DUNNING, PAUSED]) &&
                    userCan('clients.subscription.card.add') && (
                      <Button
                        classes="border-0 font-bold tracking-wider "
                        bgColor="green-600"
                        hoverColor="green-700"
                        roundedSize="2xl"
                        textColor="white"
                        px={6}
                        py={1.5}
                        shadow=""
                        type="button"
                        onClick={() => {
                          setIsOpenUpdateCardDetails(true);
                        }}
                      >
                        {!subscription.z.card ? 'Add' : 'Update'} Card
                      </Button>
                    )}
                </div>
              </div>
              <dl className="col-span-8 sm:grid sm:grid-cols-9 space-y-1 items-center">
                {subscription.z.card ? (
                  <>
                    {row(
                      'Card Number',
                      <>
                        {subscription.z.card.last_four_digits}&nbsp;
                        <em className="text-gray-400 text-xs">
                          (last four digits)
                        </em>
                      </>,
                      'col-span-2',
                      'col-span-3'
                    )}
                    {subscription.z.card &&
                    status.hasAny([LIVE, FUTURE, UNPAID, DUNNING, PAUSED]) &&
                    userCan('clients.subscription.card.update') ? (
                      <div className="col-span-4 text-right">
                        <div className="py-1 text-sm flex justify-end">
                          <ButtonLink
                            className="text-red-500 hover:text-red-700 tracking-widest text-xs flex items-center"
                            onClick={changeAutoCollect}
                            loading={savingAc}
                            showLoading={true}
                            spinnerColor="red-500"
                          >
                            Change to&nbsp;
                            <span className="font-bold">
                              {subscription.z.auto_collect
                                ? 'Offline'
                                : 'Online'}
                            </span>
                            &nbsp;Mode
                          </ButtonLink>
                        </div>
                      </div>
                    ) : (
                      <div className="col-span-4">&nbsp;</div>
                    )}
                    {row(
                      'Expires On',
                      `${subscription.z.card.expiry_month} / ${subscription.z.card.expiry_year}`
                    )}
                    {row('Gateway', subscription.z.card.payment_gateway)}
                    {row('Funding', subscription.z.card.funding)}
                  </>
                ) : (
                  <>
                    <span className="text-gray-400 text-sm col-span-full">
                      No payment method specified
                    </span>
                    {status.hasAny([LIVE, FUTURE, UNPAID, DUNNING, PAUSED]) &&
                      userCan('clients.subscription.card.add') && (
                        <div className="col-span-full">
                          <ButtonLink
                            className="text-red-600 tracking-wider flex items-center text-xs"
                            onClick={onUpdateCardAdmin}
                            loading={savingUca}
                            showLoading={true}
                            spinnerColor="red-500"
                          >
                            Add Card (Admin)
                          </ButtonLink>
                        </div>
                      )}
                  </>
                )}
              </dl>
              {subscription.z.card &&
                status.hasAny([LIVE, FUTURE, UNPAID, DUNNING, PAUSED]) &&
                userCan('clients.subscription.card.update') && (
                  <div>
                    <div className="py-1 text-sm flex items-center">
                      <button
                        className="text-red-600 tracking-wider"
                        onClick={() => setIsOpenRemoveCard(true)}
                      >
                        Remove Card
                      </button>
                      <span className="px-2 text-gray-400 inline-block">|</span>
                      <ButtonLink
                        className="text-red-600 tracking-wider flex items-center"
                        onClick={onUpdateCardAdmin}
                        loading={savingUca}
                        showLoading={true}
                        spinnerColor="red-500"
                      >
                        Update Card (Admin)
                      </ButtonLink>
                    </div>
                  </div>
                )}
            </>
          )}

          {userCan('clients.subscription.card.add') && (
            <div className="mt-5 flex items-center justify-between">
              <div className="flex">
                <div className="flex items-center">
                  <span className="flex-1 whitespace-nowrap">
                    Payment Mode:
                  </span>
                  {openChange ? (
                    <>
                      <Select
                        padding="pl-3 pr-10 py-1 mx-2"
                        className="border bg-white block w-auto rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lg:col-span-2"
                        value={paymentMode}
                        onChange={(e) => setPaymentMode(e.target.value)}
                      >
                        <option value="zoho">Zoho</option>
                        {agencyClient.btCustomerId && (
                          <option value="braintree">Braintree</option>
                        )}
                        <option value="offline">Offline</option>
                      </Select>
                      {savingMode ? (
                        <span>
                          <SpinnerGrow />
                        </span>
                      ) : (
                        <>
                          <span>
                            <CheckCircleIcon
                              className="w-5 h-5 text-success-dark cursor-pointer"
                              onClick={onChangePaymentMode}
                            />
                          </span>
                          <span>
                            <XCircleIcon
                              className="w-5 h-5 text-primary cursor-pointer"
                              onClick={() => {
                                setPaymentMode(agencyClient.paymentMode);
                                setOpenChange(false);
                              }}
                            />
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span className="ml-2 uppercase text-secondary">
                      {paymentMode}
                    </span>
                  )}
                </div>
                {!openChange && (
                  <ButtonLink
                    classes="tracking-widest ml-2 pl-2 leading-none border-l"
                    onClick={() => setOpenChange(true)}
                    textSize="xs"
                  >
                    Change
                  </ButtonLink>
                )}
              </div>

              {agencyClient.paymentMode && !agencyClient.btCustomerId && (
                <ButtonLink
                  classes="border-2 border-secondary-dark text-secondary-dark px-4 py-1 rounded-full hover:bg-grayscale-500"
                  onClick={() => setIsOpenSwitch(true)}
                  textSize="sm"
                  color=""
                >
                  Notify Client to Switch
                </ButtonLink>
              )}
            </div>
          )}

          <h4 className="text-xl font-bold mt-8">Subscription Options</h4>
          <dl className="col-span-8 sm:grid sm:grid-cols-9 space-y-1 items-center">
            {row(
              'Subscription ID',
              subscription.z.subscription_id,
              'col-span-3',
              'col-span-6'
            )}
            {row(
              'Auto Collect',
              subscription.z.auto_collect.toString(),
              'col-span-3',
              'col-span-6'
            )}
            {row(
              'Pause Collect',
              <div className="flex items-center">
                {subscription.z.custom_field_hash.cf_pause_collect}{' '}
                <span className="px-2 text-gray-400 inline-block">|</span>
                {!agencyClient.deletedAt && (
                  <ButtonLink
                    onClick={onPauseCollect}
                    loading={savingCf}
                    showLoading={true}
                    className="text-red-500 hover:text-red-700 tracking-widest text-xs flex items-center"
                    spinnerColor="red-500"
                  >
                    {subscription.z.custom_field_hash
                      .cf_pause_collect_unformatted === true
                      ? 'Resume Collect'
                      : 'Stop Collect'}
                  </ButtonLink>
                )}
              </div>,
              'col-span-3',
              'col-span-6'
            )}
            {!subscription.z.auto_collect &&
              userCan('clients.subscription.adminfee.update') &&
              row(
                'Charge 3% Admin Fee',
                <div className="flex">
                  <span className="">{chargeAdminFee ? 'yes' : 'no'}</span>
                  <button
                    type="button"
                    onClick={onChargeAdminFee}
                    className="ml-2"
                  >
                    {chargeAdminFee ? (
                      <PauseIcon className="w-5 h-5 text-red-500" />
                    ) : (
                      <PlayIcon className="w-5 h-5 text-green-500" />
                    )}
                  </button>
                </div>,
                'col-span-3',
                'col-span-6'
              )}
            {isAgencySuperUser() && (
              <>
                {row(
                  'Reference ID',
                  subscription.z.reference_id,
                  'col-span-3',
                  'col-span-6'
                )}
                {row(
                  'Customer ID',
                  subscription.d.zohoId,
                  'col-span-3',
                  'col-span-6'
                )}
                {row(
                  'BT Customer ID',
                  agencyClient.btCustomerId,
                  'col-span-3',
                  'col-span-6'
                )}
              </>
            )}
          </dl>
        </div>
      </div>
      <div className="client-updates container-fixed bg-white border-l border-gray-100">
        <div className="flex justify-between p-2 sm:p-5">
          {userCan('clients.subscription.activities.view') && (
            <ButtonLink onClick={() => setIsOpenRecentActivities(true)}>
              <AnnotationIcon className="w-5 h-5 inline mr-1" />{' '}
              <span className="tracking-widest font-bold">
                Recent Activities
              </span>
            </ButtonLink>
          )}
          <Actions />
        </div>
        <Summary />
        {scheduledChanges && scheduledChanges.code === 0 && (
          <ScheduledChanges
            open={isOpenScheduledChanges}
            setOpen={setIsOpenScheduledChanges}
            subscription={subscription.z}
            scheduledChanges={scheduledChanges}
          />
        )}
        <div className="p-2 sm:p-5 overflow-auto">
          <PlanAddonsTable subscription={subscription.z} />
        </div>
        <Notes subscription={subscription.z} />
      </div>
      <RecentActivitiesSlideOver
        open={isOpenRecentActivities}
        setOpen={setIsOpenRecentActivities}
        subscription={subscription.z}
      />
      <ConfirmationModal
        title="Remove payment details"
        content="This will change the subscription as offline mode. Invoices cannot be automatically collected"
        open={isOpenRemoveCard}
        setOpen={setIsOpenRemoveCard}
        onOkClick={onRemoveCard}
        onCancelClick={() => setIsOpenRemoveCard(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Update payment details"
        content={`This will send an email to ${agencyClient.defaultContact.email} with instructions to update their payment details`}
        open={isOpenUpdateCardDetails}
        setOpen={setIsOpenUpdateCardDetails}
        onOkClick={updateCardDetails}
        onCancelClick={() => setIsOpenUpdateCardDetails(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Notify client to switch to Braintree"
        content={`This will send an email to ${agencyClient.defaultContact.email} with instructions to update their payment details to Braintree`}
        open={isOpenSwitch}
        setOpen={setIsOpenSwitch}
        onOkClick={onSwitch}
        onCancelClick={() => setIsOpenSwitch(false)}
        okLoading={saving}
        showOkLoading={true}
      />
    </>
  );
};
export default Subscriptions;
