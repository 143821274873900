import proposalImage from './assets/proposal-side-image.png';
export const Welcome = () => {
  return (
    <div
      className="relative flex flex-col p-6 lg:py-28 lg:pl-10 md:pr-0 section"
      id="intro"
    >
      <div className="flex justify-between">
        <div className="z-10 md:w-9/12 space-y-6">
          <h2 className="text-4xl font-bold text-si-pink">Introduction</h2>

          <p className="text-md whitespace-pre-line ">{`${`
            Hello, \n
            Welcome to Seller Interactive! \n
            Our team is delighted to have you on board, and congratulations on taking this vital step in your business. \n        
            Please read through our service agreement and add your signature and payment details at the end of the document.\n        
            Let us know if anything needs to be changed or clarified. We are committed to building a healthy and long-lasting business relationship with you. If you have any questions, please let us know.`}`}</p>
        </div>
        <img src={proposalImage} className="max-w-xs hidden md:inline" />
      </div>
    </div>
  );
};
