import { useState, useEffect } from 'react';
import axios from 'axios';

import Navigation from './components/Navigation';
import ClientList from 'features/clients/List/components/ClientList';

import {
  Pie,
  Cell,
  PieChart,
  ResponsiveContainer,
  Label,
  Legend,
} from 'recharts';

const ProgressReport = () => {
  const [pieData, setPieData] = useState([]);
  const [clientsData, setClientsData] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 1000,
  });
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterGroups, setFilterGroups] = useState([
    {
      filters: [],
    },
  ]);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await axios.get('/v2/agency/clients/list', {
        params: {
          ...params,
          attributes: [
            'agencyClientId',
            'client',
            'onboardingTaskCompletionPercentage',
            'onboardingTaskCompletionSummary',
            'service',
          ],
          filterGroups: JSON.stringify(filterGroups),
        },
      });

      setClientsData(response.data.data);
      setLoading(false);
    }
    getData();
  }, [params, filterGroups]);

  useEffect(() => {
    const pieDataTemplate = [
      {
        status: 'Completed',
        clients: 0,
        color: '#00BA88',
      },
      {
        status: 'Pending',
        clients: 0,
        color: '#F9DC7D',
      },
    ];

    clientsData?.rows.length > 0 &&
      clientsData.rows.map((data) => {
        if (data.onboardingTaskCompletionPercentage === 1) {
          pieDataTemplate[0].clients++;
        } else {
          pieDataTemplate[1].clients++;
        }
      });

    setTotalClients(clientsData.rows.length);
    setPieData(pieDataTemplate);
  }, [clientsData]);

  function CustomLabel(props) {
    const { cx, cy } = props.viewBox;
    return (
      <>
        <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central">
          <tspan
            alignmentBaseline="middle"
            fontSize="32px"
            fontFamily="Inter"
            fontWeight="bold"
          >
            {props.value}
          </tspan>
        </text>
        <text x={cx} y={cy + 25} textAnchor="middle" dominantBaseline="central">
          <tspan fontSize="13px" fontFamily="Source Sans Pro">
            Total
          </tspan>
        </text>
      </>
    );
  }

  return (
    <div>
      <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Progress Reports
      </h2>

      <Navigation />

      <div className="grid grid-cols-4 gap-2 mt-6">
        <div className="col-span-1 bg-white p-6 h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieData}
                dataKey="clients"
                nameKey="status"
                innerRadius={60}
                outerRadius={80}
                labelLine={false}
                isAnimationActive={false}
              >
                <Label
                  width={30}
                  position="center"
                  content={<CustomLabel value={totalClients} />}
                ></Label>
                {pieData.map((entry, index) => {
                  return (
                    <Cell key={`${entry.status}-${index}`} fill={entry.color} />
                  );
                })}
              </Pie>

              <Legend
                layout="vertical"
                verticalAlign="bottom"
                iconType="square"
                iconSize={11}
                wrapperStyle={{
                  marginTop: '30px',
                  fontSize: '11px',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="col-span-3 p-2">
          <div className="">
            <ClientList
              title="Breakdown"
              customizableColumns={false}
              initialFilters={[
                {
                  attribute: 'status',
                  operator: 'EQ',
                  value: 'ACTIVE',
                },
              ]}
              defaultColumns={[
                'agencyClientId',
                'client',
                'onboardingTaskCompletionPercentage',
                'service',
              ]}
              initialParams={{
                page: 1,
                pageSize: 100,
                filters: '[]',
                include: ['subscription'],
                search: '',
                attributes: [
                  'agencyClientId',
                  'client',
                  'onboardingTaskCompletionPercentage',
                  'onboardingTaskCompletionSummary',
                  'service',
                ],
              }}
              allowedFilters={['onboardingTaskCompletionSummary', 'service']}
              setFilterGroups={setFilterGroups}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProgressReport;
