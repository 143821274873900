import useTaskSpace from 'hooks/useTaskSpace';
import useTaskTray from 'hooks/useTaskTray';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'utils/classNames';

const TaskTray = () => {
  const { taskTray } = useTaskTray();

  return taskTray.length > 0 ? (
    <div className="flex items-center">
      <div className="flex items-center space-x-0.5">
        {taskTray.map((task) => (
          <div
            key={task.taskItemId}
            className="w-20 overflow-hidden flex items-center space-x-1 justify-start border border-gray-300 px-1.5 py-0.5 rounded-sm hover:shadow-md"
            title={task.name}
          >
            <div
              className={classNames('w-3 h-3 rounded-sm')}
              style={{ background: task.status.color }}
            />
            <Link
              to={task.url}
              className="w-16 truncate text-sm text-left text-gray-500"
            >
              {task.name}
            </Link>
          </div>
        ))}
      </div>
    </div>
  ) : (
    ''
  );
};

export default TaskTray;
