import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';

const TaskManagerAvatar = ({ user }) => {
  return (
    <div
      className={classNames(
        'bg-red-500 text-white flex text-center font-normal uppercase rounded-3xl border border-gray-100 relative h-8 w-8 leading-8 text-xs'
      )}
    >
      <span
        className={classNames(
          'h-8 w-8 leading-8 text-xs',
          'inline-block font-bold'
        )}
        title={`${user.firstName} ${user.lastName}`}
      >
        {getNameInitials(user.firstName, user.lastName)}
      </span>
    </div>
  );
};

export default TaskManagerAvatar;
