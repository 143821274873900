import axios from 'axios';
import moment from 'moment';

import { useState, useEffect } from 'react';
import ChartToolTip from 'components/Charts/ChartTooltip';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const TerminationSummary = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment()
      .year(selectedYear)
      .month(selectedMonth - 1)
      .date(1)
      .format('YYYY-MM-DD');

    const endDate = moment()
      .year(selectedYear)
      .month(selectedMonth - 1)
      .endOf('month')
      .format('YYYY-MM-DD');

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/terminations/summary', {
        params: { startDate, endDate },
      });

      if (isSubscribed) {
        setSummary(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [selectedMonth, selectedYear]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-inter font-bold leading-1.2 tracking-3/4">
          Termination Reason
        </h4>

        <div className="space-x-2">
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-32 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
          >
            {moment.months().map((month, idx) => (
              <option key={month} value={idx + 1}>
                {month}
              </option>
            ))}
          </select>

          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-32 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
          >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </select>
        </div>
      </div>

      <div className="h-72 bg-white p-4 mt-8 rounded">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <BarChart
              data={summary}
              layout="vertical"
              margin={{ top: 0, right: 0, bottom: 0, left: 15 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                horizontal={false}
              />

              <XAxis
                type="number"
                style={{ fontSize: '11px' }}
                axisLine={{ stroke: '#ECECEC' }}
                tickLine={false}
              />

              <YAxis
                dataKey="reason"
                type="category"
                style={{ fontSize: '11px', color: 'black', textAlign: 'left' }}
                axisLine={{ stroke: '#ECECEC' }}
                tickLine={{ stroke: '#ECECEC' }}
                interval={0}
                dx={-5}
                width={160}
              />

              <Bar dataKey="count" strokeWidth={3} fill="#FF6868" dot={false} />

              <Tooltip
                content={
                  <ChartToolTip
                    showComparison={false}
                    Title={() => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {moment()
                          .year(selectedYear)
                          .month(selectedMonth - 1)
                          .format('MMMM, YYYY')}
                      </p>
                    )}
                    CustomItemTitle={({ item }) => (
                      <p className="text-13 w-full flex justify-between leading-1.5 text-grayscale-900">
                        {item.payload.reason}
                      </p>
                    )}
                  />
                }
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default TerminationSummary;
