import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setAlert } from 'features/alerts/alertsSlice';

const ApplyRecommendedBudget = ({
  accountId,
  marketplace,
  campaignType,
  campaigns,
  onSuccess,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [applyingRecoBudget, setApplyingRecoBudget] = useState(false);

  const onClick = () => {
    setApplyingRecoBudget(true);
    const payload = {
      accountId,
      marketplace,
      campaignType,
      campaignIds: campaigns.map((campaign) => campaign.advCampaignId),
    };

    axios
      .post('/account/advertising/campaigns/apply-recommended-budget', payload)
      .then((response) => {
        const { status, message } = response.data;
        dispatch(setAlert(status ? 'success' : 'errror', message));
      })
      .catch((err) => {
        dispatch(setAlert('errror', err.response.data.message));
      })
      .finally(() => {
        onSuccess();
        setApplyingRecoBudget(false);
      });
  };

  return (
    <button
      type="submit"
      disabled={!campaigns.length || applyingRecoBudget || disabled}
      onClick={onClick}
      className="col-span-6 lg:col-span-3 xl:col-span-3 justify-center w-full rounded-2xl border border-gray-300 shadow-sm px-1 lg:px-4 py-2 bg-secondary hover:bg-secondary-light text-sm font-medium text-grayscale-300 tracking-2 font-bold hover:bg-gray-50 focus:outline-none "
    >
      {applyingRecoBudget
        ? 'Applying Recommendations'
        : 'Apply Recommendations'}
    </button>
  );
};

export default ApplyRecommendedBudget;
