import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import { fetchTaxExemptions, setTaxExemptions } from './billingSlice';
import DataTable from 'components/DataTable';
import SpinnerGrow from 'components/SpinnerGrow';
import useAlert from 'hooks/useAlert';
import axios from 'axios';
import usePermissions from 'hooks/usePermissions';

const TaxExemptions = () => {
  const { userCan } = usePermissions();
  const { taxExemptions } = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { alertSuccess, alertError } = useAlert();

  useEffect(() => {
    if (!taxExemptions) {
      dispatch(fetchTaxExemptions());
    }
  }, [taxExemptions]);

  const columns = [
    { header: 'Code', accessorKey: 'taxExemptionCode' },
    { header: 'Description', accessorKey: 'description' },
  ];

  const onSync = async () => {
    setLoading(true);
    await axios
      .post(`/billing/tax-exemptions/import`)
      .then((response) => {
        alertSuccess('sync successful', response.data.message);
        dispatch(setTaxExemptions(response.data.data));
      })
      .catch((error) => {
        alertError('sync failed', error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-4">
        <h1 className="text-3xl tracking-wide font-bold">Tax Exemptions</h1>
        {userCan('billing.taxexemptions.sync') && (
          <button
            type="button"
            className="flex items-center px-4 tracking-widest rounded-3xl py-1.5 border-2 font-bold border-gray-600 text-sm text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            onClick={onSync}
          >
            <CloudDownloadIcon className="h-4 w-4 mr-2" /> Sync with Zoho{' '}
            {loading && <SpinnerGrow />}
          </button>
        )}
      </div>
      <div className="mt-6 flex flex-col max-w-xl">
        {taxExemptions && <DataTable data={taxExemptions} columns={columns} />}
      </div>
    </div>
  );
};
export default TaxExemptions;
