import { useSelector } from 'react-redux';
import {
  CurrencyDollarIcon,
  ShieldCheckIcon,
  ClipboardListIcon,
  SpeakerphoneIcon,
  CashIcon,
  ViewBoardsIcon,
} from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';
import {
  selectCurrentAccount,
  selectCurrentMarketplace,
} from 'features/accounts/accountsSlice';

const usePartnerMenu = () => {
  const marketplace = useSelector(selectCurrentMarketplace);
  const account = useSelector(selectCurrentAccount);
  const { isApplicationLevel } = usePermissions();

  const routes = [
    {
      name: 'Plan',
      href: '/plan',
      icon: CurrencyDollarIcon,
      visible: () => isApplicationLevel(),
      query: () => {
        let q = {};
        if (account) {
          q.account = account.accountId;
        }

        if (marketplace) {
          q.marketplace = marketplace.details.countryCode;
        }

        return q;
      },
    },
    {
      name: 'Invoices',
      href: '/additional-services',
      icon: '#billing',
      iconType: 'svg',
      iconSize: { height: '12px', width: '16px' },
      visible: () => isApplicationLevel(),
      children: [
        {
          name: 'Additional Services',
          href: '/additional-services',
          icon: ViewBoardsIcon,
          visible: () => isApplicationLevel(),
        },
        {
          name: 'Invoice History',
          href: '/invoice-history',
          icon: CashIcon,
          visible: () => isApplicationLevel(),
        },
      ],
    },
    {
      name: 'Authorization',
      href: '/callback/amazon',
      icon: ShieldCheckIcon,
      visible: () => {
        return (
          isApplicationLevel() &&
          account &&
          !!account.credentials.find(
            (c) => c.service === 'spApi' && !c.hasFullAccess
          )
        );
      },
      requiresAccount: true,
      requiresMarketplace: true,
      query: () => {
        let q = {};
        if (account) q.account = account.accountId;
        if (marketplace) q.marketplace = marketplace.details.countryCode;

        return q;
      },
    },
    {
      name: 'Mockup',
      href: '/mockup',
      icon: ClipboardListIcon,
      visible: () => isApplicationLevel(),
      query: () => {
        let q = {};
        if (account) {
          q.account = account.accountId;
        }

        if (marketplace) {
          q.marketplace = marketplace.details.countryCode;
        }

        return q;
      },
    },
    {
      name: 'Complaints',
      href: '/complaints',
      icon: SpeakerphoneIcon,
      visible: () => isApplicationLevel(),
      query: () => {
        let q = {};

        if (account) {
          q.account = account.accountId;
        }

        return q;
      },
    },
  ];

  return routes;
};

export default usePartnerMenu;
