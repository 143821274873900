import axios from 'axios';
import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import PercentageChange from '../../components/PercentageChange';
import metrics from '../../utils/metrics';

const CampaignTypesOverview = ({
  className = 'grid grid-cols-1 2xl:grid-cols-3 gap-6',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [campaignTypes, setCampaignTypes] = useState([
    { title: 'Sponsored Products' },
    { title: 'Sponsored Brands' },
    { title: 'Sponsored Display' },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: ['sales', 'cost', 'acos'],
        ...additionalParams,
        include: ['previousData'],
      };

      const response = await axios.get(
        '/account/advertising/analytics/campaign-types',
        {
          params,
        }
      );

      if (isSubscribed) {
        setCampaignTypes(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    <div className={`${className}`}>
      {campaignTypes.map((campaignType) => {
        return (
          <div
            key={`camapaign-types-overview-${campaignType.title}`}
            className="rounded-3xl bg-white py-5 bs-shadow"
          >
            <div>
              <p className="text-grayscale-800 leading-1.5 text-center">
                {campaignType.title}
              </p>
            </div>

            <div className="grid grid-cols-3 mt-5">
              {['sales', 'cost', 'acos']
                .map((attribute) => metrics.find((m) => m.key === attribute))
                .map((metric, index) => {
                  return (
                    <div
                      key={`camapaign-types-overview-${campaignType.title}-${metric.key}`}
                      className={classNames(
                        index === 1
                          ? 'border-r border-l border-grayscale-500'
                          : ''
                      )}
                    >
                      <p className="text-grayscale-800 text-tiny leading-1.5 text-center">
                        {metric.title}
                      </p>
                      {loading ? (
                        <div className="animate-pulse">
                          <div className="h-5 w-1/4 mt-1 mx-auto bg-gray-200 rounded"></div>
                          <div className="h-3 w-1/2 mt-3 mx-auto bg-gray-200 rounded"></div>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-center items-center mt-1 mb-2">
                            {metric.prefix && (
                              <p className="leading-1.2 text-grayscale-600 tracking-3/4 mr-0.5">
                                {metric.prefix}
                              </p>
                            )}
                            <p className="font-bold font-inter leading-1.2 text-grayscale-900 tracking-3/4">
                              {metric.formatter(campaignType[metric.key])}
                            </p>
                            {metric.suffix && (
                              <p className="leading-1.2 text-grayscale-600 tracking-3/4 ml-0.5">
                                {metric.suffix}
                              </p>
                            )}
                          </div>
                          <PercentageChange
                            currentValue={campaignType[metric.key]}
                            previousValue={
                              campaignType[`previous${upperFirst(metric.key)}`]
                            }
                            reverse={!!metric.reverse}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignTypesOverview;
