import Clients from 'features/clients/Clients';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import React from 'react';
import PrivateRoute from './PrivateRoute';
import ClientMigration from 'features/clientMigration/ClientMigration';
import { Route } from 'react-router-dom';

const ClientRoutes = [
  <PrivateRoute
    path="/clients"
    component={Clients}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <Route path="/client-migration/:token" component={ClientMigration} exact />,
];
export default ClientRoutes;
