import axios from 'axios';
import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';

import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  XAxis,
  YAxis,
  Cell,
  Bar,
} from 'recharts';

import metrics from '../../utils/metrics';

const BiggestChangesByKeyword = ({
  title = 'Biggest Changes By Keywords',
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  campaignType,
  additionalParams = {},
}) => {
  const [attribute, setAttribute] = useState('profit');
  const [keywords, setKeywords] = useState({ rows: [] });
  const [params, setParams] = useState({ page: 1 });

  const attributes = [
    'profit',
    'aov',
    'impressions',
    'clicks',
    'cost',
    'sales',
    'orders',
    'cpc',
    'ctr',
    'cr',
    'acos',
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/advertising/keywords', {
        params: {
          accountId,
          marketplace,
          startDate,
          endDate,
          campaignType,
          attributes: [attribute, 'advKeywordId', 'keywordText'],
          ...params,
          ...additionalParams,
          include: [
            'previousData',
            'changesFromPreviousData',
            'absoluteChangesFromPreviousData',
          ],
          sort: `absoluteChanges${upperFirst(attribute)}`,
        },
      });

      if (isSubscribed) {
        setKeywords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    accountId,
    marketplace,
    startDate,
    endDate,
    attribute,
    params,
    campaignType,
    additionalParams,
  ]);

  const chartStyle = {
    fontSize: '0.75rem',
  };

  const onPrevPage = () => {
    const newParams = { ...params, page: params.page - 1 };
    setParams(newParams);
  };

  const onNextPage = () => {
    const newParams = { ...params, page: params.page + 1 };
    setParams(newParams);
  };

  return (
    !!keywords.rows.length && (
      <div
        className={`${className} bs-shadow rounded-3xl bg-white bs-shadow py-6 flex flex-col`}
      >
        <div className="flex items-center justify-between border-b px-8 pb-6">
          <p className="font-bold leading-1.5 text-grayscale-900">{title}</p>

          <select
            value={attribute}
            onChange={(e) => {
              setParams({ page: 1 });
              setAttribute(e.target.value);
            }}
            className="bg-grayscale-400 text-mini text-grayscale-900 border-0 rounded-2xl pl-3 pr-9 py-3 focus:outline-none focus:ring-0"
          >
            {attributes
              .map((attribute) => metrics.find((m) => m.key === attribute))
              .map((metric) => (
                <option
                  key={`biggest-changes-products-options-${metric.key}`}
                  value={metric.key}
                >
                  {metric.title}
                </option>
              ))}
          </select>
        </div>

        <div className="m-4 flex-grow">
          <ResponsiveContainer
            width={'100%'}
            height={50 * keywords.rows.length}
            debounce={50}
          >
            <BarChart
              data={keywords.rows}
              layout="vertical"
              margin={{ right: 40, left: -50 }}
            >
              <CartesianGrid strokeDasharray="4" />
              <XAxis
                axisLine={false}
                type="number"
                style={chartStyle}
                tickFormatter={(value) => {
                  const metric = metrics.find((m) => m.key === attribute);
                  return metric.rawFormatter(value);
                }}
              />
              <YAxis
                yAxisId={0}
                dataKey={'keywordText'}
                type="category"
                axisLine={false}
                tickLine={false}
                style={chartStyle}
                width={180}
                tickFormatter={(value) =>
                  value.length > 30 ? value.substr(0, 30) + '...' : value
                }
              />

              <YAxis
                orientation="right"
                yAxisId={1}
                dataKey={`changes${upperFirst(attribute)}`}
                type="category"
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => {
                  const metric = metrics.find((m) => m.key === attribute);
                  return metric.rawFormatter(value);
                }}
                style={chartStyle}
                tick={{
                  transform: `translate(10, 0)`,
                }}
              />

              <Bar
                dataKey={`changes${upperFirst(attribute)}`}
                minPointSize={2}
                barSize={15}
              >
                {keywords.rows.map((d) => {
                  const color =
                    d[`changes${upperFirst(attribute)}`] > 0
                      ? '#FF89A6'
                      : '#ffd6e0';
                  return <Cell key={d['advKeywordId']} fill={color} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div
          className="px-8 pt-5 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="">
            <p className="text-tiny text-grayscale-900">
              Showing <span className="font-medium">{keywords.from}</span> to{' '}
              <span className="font-medium">{keywords.to}</span> of{' '}
              <span className="font-medium">{keywords.count}</span> results
            </p>
          </div>
          <div className="flex-1 flex justify-between sm:justify-end">
            <button
              disabled={!!!keywords.prevPage}
              onClick={onPrevPage}
              className="relative inline-flex items-center px-4 py-2 border-2 border-grayscale-800 text-sm font-bold rounded-md text-grayscale-800 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              disabled={!!!keywords.nextPage}
              onClick={onNextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border-2 border-grayscale-800 text-sm font-bold rounded-md text-grayscale-800 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default BiggestChangesByKeyword;
