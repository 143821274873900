import { Label } from 'components/Forms';
import useCreditNotes from '../../useCreditNotes';
import PriceLabel from 'components/PriceLabel';
import usePermissions from 'hooks/usePermissions';
import { SearchIcon, DownloadIcon } from '@heroicons/react/outline';
import usePdf from 'hooks/usePdf';

const Details = ({ creditNote }) => {
  const { STATUS } = useCreditNotes();
  const { isMine } = usePermissions();
  const { onDownloadPdf, onPreviewPdf } = usePdf();

  return (
    <>
      {creditNote && creditNote.status === STATUS.denied && (
        <div className="mb-4 bg-red-50 rounded-lg py-4 px-4">
          <p className="text-left text-xl text-red-500 font-bold pb-4">
            {isMine(creditNote.requestedBy) ? 'Your credit' : 'Credit'}
            &nbsp;note request was rejected
          </p>
          {creditNote.rejecter && (
            <>
              <Label>Rejected By</Label>
              <p className="text-left text-sm font-normal pb-2">
                {creditNote.rejecter?.firstName}&nbsp;
                {creditNote.rejecter?.lastName}
              </p>
            </>
          )}
          <Label>Reason for rejection</Label>
          <p className="text-left text-sm font-normal">
            {creditNote.reason ?? 'n/a'}
          </p>
        </div>
      )}

      {!creditNote.upsell && (
        <>
          <div>
            <Label>Client</Label>
            <p>{creditNote?.agencyClient?.client}</p>
          </div>
          <div>
            <Label>Name</Label>
            <p>{creditNote.name}</p>
          </div>
          <div>
            <Label>Description</Label>
            <p className="whitespace-pre-wrap break-words">
              {creditNote.description}
            </p>
          </div>
          <div>
            <Label>Requester Note</Label>
            <p className="whitespace-pre-wrap break-words">
              {creditNote.notes}
            </p>
          </div>
        </>
      )}

      {creditNote.upsell && (
        <p className="font-bold text-lg pb-2 text-grayscale-900">
          Credit note details
        </p>
      )}

      <div className="border-gray-200 pb-2 mb-2 flex flex-col">
        <Label>Amount</Label>
        <div className="flex items-center">
          <PriceLabel
            price={parseFloat(
              creditNote.upsell ? creditNote.creditsApplied : creditNote.amount
            )}
          />
          {creditNote.upsell && (
            <span className="text-grayscale-700 text-1xs ml-1 italic">
              (incl. invoice tax, if applicable)
            </span>
          )}
        </div>
      </div>

      {creditNote.zohoCreditNoteNumber && (
        <div className="border-gray-200 pb-2 mb-2 flex flex-col">
          <Label>Zoho Credit Note</Label>
          <div className="text-xl flex items-center">
            <span title={creditNote.zohoCreditNoteId}>
              {creditNote.zohoCreditNoteNumber}
            </span>
            <button
              type="button"
              className="text-secondary hover:text-secondary-light ml-5"
              title="View PDF"
              onClick={() =>
                onPreviewPdf(
                  `agency/credit-notes/${creditNote.zohoCreditNoteId}/pdf`
                )
              }
            >
              <SearchIcon className="w-5 h-5 transform rotate-90" />
            </button>
            <button
              type="button"
              className="text-secondary hover:text-secondary-light ml-0.5"
              title="Download PDF"
              onClick={() =>
                onDownloadPdf(
                  `agency/credit-notes/${creditNote.zohoCreditNoteId}/pdf`,
                  creditNote.zohoCreditNoteNumber
                )
              }
            >
              <DownloadIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Details;
