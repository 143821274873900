import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { percentageFormatter } from 'utils/formatters';

const PercentageChange = ({
  currentValue,
  previousValue,
  reverse = false,
  textSize = 11,
  iconSize = 4,
}) => {
  let Icon = ChevronUpIcon;
  let percentage = 0;
  let infoColor = 'success';

  if (currentValue !== previousValue && previousValue) {
    Icon = currentValue > previousValue ? ChevronUpIcon : ChevronDownIcon;

    const lessRef = reverse ? currentValue : previousValue;
    const greaterRef = reverse ? previousValue : currentValue;

    infoColor = lessRef < greaterRef ? 'success' : 'error';
    percentage = (currentValue - previousValue) / Math.abs(previousValue);
  }

  return (
    <div className="flex justify-center items-center">
      <Icon className={`text-${infoColor} h-${iconSize} w-${iconSize} mr-1`} />
      <div className={`text-${textSize} text-${infoColor}`}>
        {percentageFormatter(percentage)}
      </div>
    </div>
  );
};

export default PercentageChange;
