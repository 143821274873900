import { useTranslation } from 'react-i18next';
import {
  AD_GROUPS,
  CAMPAIGNS,
  NEGATIVE_EXACT,
  NEGATIVE_PHRASE,
} from 'features/advertising/utils/constants';

const ConvertAsNegativeKeyword = ({ data, onChangeData, errors = {} }) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    const { name, value } = e.target;
    let newData = { ...data, [name]: value };
    onChangeData(newData);
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="mt-4">
        <label
          htmlFor="matchType"
          className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
        >
          {t('Advertising.Rule.Action.ConvertAsNegKeyword.MatchType')}
        </label>
        <div className="mt-1">
          <select
            id="matchType"
            name="matchType"
            className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
            value={data.matchType}
            onChange={onChange}
          >
            <option value="">
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.SelectMatchType')}
            </option>
            <option value={NEGATIVE_PHRASE}>
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.NegativePhrase')}
            </option>
            <option value={NEGATIVE_EXACT}>
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.NegativeExact')}
            </option>
          </select>
        </div>
        {errors['actionData.matchType'] && (
          <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
            {errors['actionData.matchType']}
          </p>
        )}
      </div>

      <div className="mt-4">
        <label
          htmlFor="level"
          className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
        >
          {t('Advertising.Rule.Action.ConvertAsNegKeyword.Level')}
        </label>
        <div className="mt-1">
          <select
            id="level"
            name="level"
            className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
            value={data.level}
            onChange={onChange}
          >
            <option value="">
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.SelectLevel')}
            </option>
            <option value={AD_GROUPS}>
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.AdGroup')}
            </option>
            <option value={CAMPAIGNS}>
              {t('Advertising.Rule.Action.ConvertAsNegKeyword.Campaign')}
            </option>
          </select>
        </div>
        {errors['actionData.level'] && (
          <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
            {errors['actionData.level']}
          </p>
        )}
      </div>
    </div>
  );
};

export default ConvertAsNegativeKeyword;
