import { useState } from 'react';
import classNames from 'utils/classNames';
import DueDateDropdown from './DueDateDropdown';
import { CheckIcon } from '@heroicons/react/outline';

import useTasks from '../../useTasks';
import Assignees from './Assignees';
import { isFunction } from 'lodash';

const OnboardingTaskItem = ({ taskItem, onClick }) => {
  const {
    updateTask,
    addAssignee,
    getAssignees,
    removeAssignee,
    updateAssignee,
    DONE_STATUS,
    PENDING_STATUS,
  } = useTasks();

  const [task, setTask] = useState(taskItem);
  const [isHovered, setIsHovered] = useState(false);

  const onChange = async (data) => {
    const response = await updateTask(task.taskItemId, data);

    setTask({ ...task, ...response.data });
  };

  const onAddAssignee = async (userId) => {
    await addAssignee(task.taskItemId, userId);

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  const onRemoveAssignee = async (userId) => {
    await removeAssignee(task.taskItemId, userId);

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  const onUpdateAssignee = async (userId, assigneeId) => {
    await updateAssignee(task.taskItemId, userId, { assigneeId });

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  return (
    <div
      className="p-4 grid grid-cols-12 gap-4 hover:bg-grayscale-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="col-span-3 lg:col-span-4 xl:col-span-4 flex items-center space-x-2">
        <button
          onClick={() =>
            onChange({
              status:
                task.status === DONE_STATUS.name
                  ? PENDING_STATUS.name
                  : DONE_STATUS.name,
            })
          }
          disabled={task.isSkipped}
          className={classNames(
            task.status === DONE_STATUS.name
              ? 'bg-success'
              : isHovered
              ? 'border-grayscale-700 bg-grayscale-500 border-2'
              : 'border-grayscale-600 bg-white border-2',
            'w-6 h-6  rounded-full disabled:opacity-20 flex items-center justify-center'
          )}
        >
          {task.status === DONE_STATUS.name && (
            <CheckIcon className="text-white w-4 h-4" />
          )}
        </button>

        <p
          className={classNames(
            'text-13 leading-1.5 cursor-pointer',
            isHovered ? 'text-secondary-light' : 'text-grayscale-800',
            task?.isSkipped ? 'line-through' : 'no-underline'
          )}
          onClick={() => {
            if (isFunction(onClick)) {
              onClick(task);
            }
          }}
        >
          {task?.name}
        </p>
      </div>

      <div className="col-span-3 lg:col-span-3 xl:col-span-4 flex items-center">
        <p
          className={classNames(
            'text-13 leading-1.5',
            task?.isSkipped ? 'line-through' : 'no-underline',
            isHovered ? 'text-secondary-light' : 'text-grayscale-800'
          )}
          dangerouslySetInnerHTML={{
            __html: task?.description,
          }}
        ></p>
      </div>

      <div className="col-span-2 xl:col-span-1">
        <DueDateDropdown value={task.dueDate} />
      </div>

      <div className="col-span-2 flex justify-center">
        <Assignees
          onAdd={onAddAssignee}
          onRemove={onRemoveAssignee}
          onUpdate={onUpdateAssignee}
          value={task.assignees ?? []}
        />
      </div>

      <div className="col-span-2 lg:col-span-1">
        <button
          onClick={() => onChange({ isSkipped: !task.isSkipped })}
          className="w-full py-2 rounded-xl border-2 border-grayscale-700 text-grayscale-700 text-11 leading-1.5 tracking-2 font-bold hover:bg-grayscale-500 hover:border-grayscale-800 hover:text-grayscale-800"
        >
          {task.isSkipped ? 'Unskip' : 'Skip'}
        </button>
      </div>
    </div>
  );
};

export default OnboardingTaskItem;
