import axios from 'axios';
import { startCase, upperFirst } from 'lodash';
import { useEffect, useState } from 'react';

import SectionComment from '../components/SectionComment';
import RedirectToCampaign from '../../CampaignType/components/RedirectToCampaign';
import RedirectToAdGroup from '../../CampaignType/components/RedirectToAdGroup';

import classNames from 'utils/classNames';

import metrics from '../../utils/metrics';

const MostImprovedTargeting = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  redirectToEntity = true,
  comment = '',
  onChangeComment,
  editableComment = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [attribute, setAttribute] = useState('sales');
  const [targetings, setTargetings] = useState({ rows: [] });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        pageSize: 5,
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: [
          'advTargetingId',
          'value',
          'matchType',
          'type',
          'sales',
          'acos',
          'clicks',
        ],
        ...additionalParams,
        include: [
          'changesFromPreviousData',
          'previousData',
          'campaign',
          'adGroup',
        ],
        sort: `changes${upperFirst(attribute)}`,
      };

      const response = await axios.get('/account/advertising/targetings', {
        params,
      });

      if (isSubscribed) {
        setTargetings(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, attribute, additionalParams]);

  return (
    !loading &&
    !!targetings.rows.length && (
      <div className="relative">
        <SectionComment
          value={comment}
          onChangeValue={onChangeComment}
          editable={editableComment}
        />

        <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
          <div className="flex items-center justify-between border-b px-8 pb-6">
            <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
              Top 5 most improved Keywords
            </p>

            <div className="flex items-center font-sourceSansPro">
              <p className="text-mini mr-3 text-gray-900">Sort by</p>
              <div className="relative z-0 inline-flex rounded-md">
                {['acos', 'sales', 'clicks']
                  .map((attr) => metrics.find((m) => m.key === attr))
                  .map((metric, index) => {
                    return (
                      <button
                        key={`most-improved-targetings-metrics-${metric.key}`}
                        type="button"
                        onClick={() => setAttribute(metric.key)}
                        className={classNames(
                          index === 0 ? 'rounded-l-md' : '',
                          index === 2 ? 'rounded-r-md' : '',
                          index === 1 ? 'border-r-0 border-l-0' : '',
                          attribute === metric.key
                            ? 'bg-grayscale-800 text-white'
                            : 'bg-white text-grayscale-800',
                          'tracking-2 font-bold relative inline-flex items-center px-5 py-2 border border-grayscale-800 text-tiny'
                        )}
                      >
                        {metric.title}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="px-8 pb-6 mt-5">
            <table className="divide-y divide-grayscale-300 w-full table-fixed font-sourceSansPro">
              <thead className="bg-success-dark">
                <tr className="">
                  {[
                    'Keywords',
                    'Campaign',
                    'Ad Group',
                    'Previous',
                    'Current',
                    'Change',
                  ].map((col) => (
                    <th
                      key={`most-improved-targetings-headers-${col}`}
                      scope="col"
                      className="p-3 text-mini font-bold text-white border border-grayscale-500"
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center tracking-2 p-3 text-mini text-grayscale-800 border border-grayscale-500"
                    >
                      Loading Records
                    </td>
                  </tr>
                ) : (
                  targetings.rows.map((row, rowIdx) => {
                    return (
                      <tr
                        key={row.advTargetingId}
                        className={rowIdx % 2 === 1 ? 'bg-grayscale-400' : ''}
                      >
                        <td className="px-3 py-2 text-mini text-grayscale-800 border border-grayscale-500">
                          <div>
                            <p className="text-secondary-light">{row.value}</p>
                            {row.matchType && (
                              <p className="text-grayscale-600 text-tiny capitalize">
                                {row.matchType}
                              </p>
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-2 text-mini text-grayscale-800 border border-grayscale-500">
                          <div>
                            <RedirectToCampaign
                              className="text-secondary-light"
                              campaignName={row.campaign.name}
                              campaignId={row.campaign.advCampaignId}
                              campaignType={row.campaign.campaignType}
                              enable={redirectToEntity}
                            />
                            <p className="text-grayscale-600 text-tiny capitalize">
                              {startCase(row.campaign.campaignType)}
                            </p>
                            <p className="text-grayscale-600 text-tiny capitalize">
                              {row.campaign.targetingType}
                            </p>
                          </div>
                        </td>
                        <td className="px-3 py-2 text-mini text-grayscale-800 border border-grayscale-500">
                          <div>
                            <RedirectToAdGroup
                              className="text-secondary-light text-wrap"
                              adGroupId={row.adGroup.advAdGroupId}
                              adGroupName={row.adGroup.name}
                              campaignType={row.campaign.campaignType}
                              enable={redirectToEntity}
                            />

                            <p className="text-grayscale-600 text-tiny capitalize">
                              {startCase(row.type)} Targeting
                            </p>
                          </div>
                        </td>
                        <td className="p-3 text-mini text-grayscale-800 border border-grayscale-500 text-center">
                          {metrics
                            .find((m) => m.key === attribute)
                            .rawFormatter(
                              row[`previous${upperFirst(attribute)}`]
                            )}
                        </td>
                        <td className="p-3 text-mini text-grayscale-800 border border-grayscale-500 text-center">
                          {metrics
                            .find((m) => m.key === attribute)
                            .rawFormatter(row[attribute])}
                        </td>

                        <td className="p-3 text-mini text-grayscale-800 border border-grayscale-500 text-center">
                          {metrics
                            .find((m) => m.key === attribute)
                            .rawFormatter(
                              row[`changes${upperFirst(attribute)}`]
                            )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
};

export default MostImprovedTargeting;
