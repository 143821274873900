import { Avatar } from 'components';
import { useRoles } from 'features/roles';

import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';

const NodeItem = ({ user }) => {
  const { getDepartmentByValue, getSubDepartmentByValue } = useRoles();

  const department = getDepartmentByValue(user?.role?.department);
  const subDepartment = getSubDepartmentByValue(user?.role?.subDepartment);

  const team = subDepartment ?? department;

  return (
    <div
      className={classNames(
        'rounded-xl border w-full h-full bg-white relative',
        user?.status === 'INVITED' ? 'opacity-30' : 'opacity-100'
      )}
    >
      {team && (
        <div
          className="text-center text-13 leading-1.5 py-1.5"
          style={{ backgroundColor: team?.bgColor, color: team?.color }}
        >
          <span>{team.value}</span>
        </div>
      )}

      <div className="flex space-x-4 p-4">
        <div className="col-span-1">
          <Avatar
            size="40px"
            imageSrc={user.avatar?.thumbnailUrl}
            initials={getNameInitials(user.firstName, user.lastName)}
          />
        </div>

        <div className="col-span-3">
          <p className="font-bold text-base leading-1.5">
            {user.firstName} {user.lastName}
          </p>

          <p className="space-x-1.5 mt-2 text-grayscale-800 text-13 leading01.5">
            {user.role?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NodeItem;
