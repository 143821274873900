import moment from 'moment';
import { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import { withRouter } from 'react-router-dom';
import { camelCase, groupBy, keys, upperFirst } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BellIcon } from '@heroicons/react/outline';
import { Switch } from '@headlessui/react';
import { usePopper } from 'react-popper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  markAsRead,
  fetchNotifications,
  selectGroupedNotifications,
  fetchUnreadNotificationsCount,
} from './notificationSlice';

import types from './types';
import classNames from 'utils/classNames';
import NotificationItem from './NotificationItem';
import { simpleFromNow } from 'utils/formatters';

const Notifications = ({ history }) => {
  const dispatch = useDispatch();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const { user } = useSelector((state) => state.auth);
  const { list, unreadCount } = useSelector((state) => state.notifications);
  const groupNotifications = useSelector(selectGroupedNotifications);

  const [showUnreadOnly, setShowUnreadOnly] = useState(false);

  useEffect(() => {
    const events = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/agency/liveupdates/notifications?id=${user.userId}`
    );

    events.addEventListener(`upd-${user.userId}`, () => {
      dispatch(fetchUnreadNotificationsCount());
      dispatch(
        fetchNotifications({
          pageSize: 1000,
          include: ['sender'],
          sort: 'createdAt:desc',
          scopes: showUnreadOnly ? ['unread'] : [],
        })
      );
    });

    events.onerror = (e) => {
      console.log('An error occurred while attempting to connect.');
    };

    return () => events.close();
  }, [user, showUnreadOnly]);

  useEffect(() => {
    dispatch(fetchUnreadNotificationsCount());

    dispatch(
      fetchNotifications({
        pageSize: 1000,
        include: ['sender'],
        sort: 'createdAt:desc',
        scopes: showUnreadOnly ? ['unread'] : [],
      })
    );
  }, [showUnreadOnly]);

  const onMarkAsRead = async (notificationId) => {
    await dispatch(markAsRead(notificationId));
    dispatch(fetchUnreadNotificationsCount());
    await dispatch(
      fetchNotifications({
        pageSize: 1000,
        include: ['sender'],
        sort: 'createdAt:desc',
        scopes: showUnreadOnly ? ['unread'] : [],
      })
    );
  };

  return (
    <Popover>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        className="border-transparent focus:border-transparent focus:ring-0 border-0"
      >
        <div className="relative inline-flex items-center bg-white rounded-full text-gray-400 hover:text-gray-500 border-transparent focus:border-transparent focus:ring-0">
          <span className="sr-only">View notifications</span>
          <span className="relative">
            <BellIcon className="h-7 w-7" aria-hidden="true" />
          </span>

          {!!unreadCount && (
            <span className="absolute right-0 top-0 block h-2 w-2 rounded-full bg-secondary-light ring-2 ring-white" />
          )}
        </div>
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <div
          className="absolute z-20 bg-white right-0 top-0 w-128"
          style={{
            borderRadius: '10px',
            boxShadow: '0px 8px 16px rgba(17, 17, 17, 0.06)',
          }}
        >
          <div className="bg-white p-4 text-gray-700 flex justify-between">
            <span className="flex items-center font-bold text-grayscale-900 font-inter leading-1.2 tracking-3/4">
              Notifications&nbsp;&nbsp;
            </span>

            <div className="space-x-2 flex items-center">
              <Switch.Group as="div" className="flex items-center">
                <Switch.Label
                  as="span"
                  className="mr-2 text-13 text-grayscale-800 leading-1.5"
                >
                  Only show unread
                </Switch.Label>

                <Switch
                  checked={showUnreadOnly}
                  onChange={setShowUnreadOnly}
                  className={classNames(
                    showUnreadOnly ? 'bg-success' : 'bg-grayscale-500',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showUnreadOnly ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          <div className="overflow-y-auto h-75vh">
            {!!list.count ? (
              <PerfectScrollbar>
                <ul className="">
                  {keys(groupNotifications).map((key) => (
                    <li
                      key={key}
                      className={classNames(
                        'px-5 py-2 cursor-pointer bg-grayscale-300'
                      )}
                    >
                      <p className="my-4 font-bold text-grayscale-800 text-base leading-1.5">
                        {key}
                      </p>

                      <ul>
                        {groupNotifications[key].map((row) => {
                          const ItemComponent =
                            types[upperFirst(camelCase(row.type))] ??
                            NotificationItem;

                          return (
                            <li key={row.id}>
                              <ItemComponent
                                notification={row}
                                router={history}
                                markAsRead={() => onMarkAsRead(row.id)}
                                className="bg-white px-4 py-3 rounded-md"
                              >
                                <div className="flex flex-col items-end space-y-2">
                                  <p className="text-13 text-grayscale-800">
                                    {simpleFromNow(row.createdAt)}
                                  </p>
                                  {!!!row.readAt && (
                                    <span className="h-2.5 w-2.5 rounded-full bg-secondary-light ring-2 ring-white" />
                                  )}
                                </div>
                              </ItemComponent>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ))}
                </ul>
              </PerfectScrollbar>
            ) : (
              <div>
                <p className="text-center text-grayscale-800 my-3 tracking-3/4">
                  No new notifications
                </p>
              </div>
            )}
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default withRouter(Notifications);