import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSurveyRequests = createAsyncThunk(
  'surveys/fetchSurveys',
  async (params) => {
    const response = await axios.get(`/agency/clients/survey`, { params });
    return response.data.data;
  }
);

export const surveysSlice = createSlice({
  name: 'surveys',
  initialState: {
    surveys: [],
    paginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      sort: 'createdAt:desc',
      status: '',
      include: ['client'],
      filters: '[]',
    },
  },
  extraReducers: {
    [getSurveyRequests.fulfilled]: (state, { payload }) => {
      state.surveys = payload;
    },
  },
});

export default surveysSlice.reducer;
