import axios from 'axios';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { PencilIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import ButtonLink from 'components/ButtonLink';
import usePermissions from 'hooks/usePermissions';
import useBodyClass from 'hooks/useBodyClass';
import ContentBlock from './Proposal/ContentBlock';
import EmptyBlock from './Proposal/components/EmptyBlock';
import ProposalModal from './Proposal/ProposalModal';
import {
  addonValidationSchema,
  commissionValidationSchema,
  initialState,
  proposalReducer,
  proposalValidationSchema,
} from './Proposal/ProposalReducer';
import CommissionModal from './Proposal/CommissionModal';
import CommissionBlock from './Proposal/CommissionBlock';
import ProposalBlock from './Proposal/ProposalBlock';
import AddonModal from './Proposal/AddonModal';
import AddonBlock from './Proposal/AddonBlock';
import useAlert from 'hooks/useAlert';
import { fetchClient, getLatestUpdates } from './salesSlice';
import useBilling from 'hooks/useBilling';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import { setAlert } from 'features/alerts/alertsSlice';
import { ConfirmationModal } from 'components';

const Proposal = ({ page = 'deals', agencySalesClientId = '' }) => {
  useBodyClass('client-profile');
  const { salesClientId } = useParams();
  const { userCan, isAgencySuperUser } = usePermissions();
  const { client, clientStatus } = useSelector((state) => state.sales);
  const dispatched = useDispatch();
  const [proposal, dispatch] = useReducer(proposalReducer, initialState);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [openAddonModal, setOpenAddonModal] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const [isOpenOfflineConfirmation, setIsOpenOfflineConfirmation] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [proposalValues, setProposalValues] = useState({});
  const { alertSuccess, alertError } = useAlert();
  const { getPlans, getAddons, getMarketplaces, plans, addons, marketplaces } =
    useBilling();

  useEffect(() => {
    if (!plans) {
      getPlans();
    }
  }, [plans]);

  useEffect(() => {
    if (!addons) {
      getAddons();
    }
  }, [addons]);

  useEffect(() => {
    if (marketplaces.length <= 0) {
      getMarketplaces();
    }
  }, [marketplaces]);

  const initialize = () => {
    if (client && client.AgencyClient) {
      const { AgencyClient } = client;

      if (AgencyClient) {
        dispatch({ type: 'initialSetup', client });
      }
    }
  };

  useEffect(() => {
    if (page === 'client') {
      dispatched(fetchClient(agencySalesClientId));
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [client]);

  const validationSchema = object().shape({
    ...proposalValidationSchema,
    ...addonValidationSchema,
    ...commissionValidationSchema,
    reference_id: string().nullable(),
  });

  const onSubmit = async (values) => {
    let data = { ...values };
    if (client && client.agencyClientId) {
      data.agencyClientId = client.agencyClientId;
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `/agency/sales/clients/${salesClientId}`,
        { ...data, status: clientStatus }
      );
      if (response.data.success) {
        alertSuccess('Proposal saved', 'Proposal Successfully Updated');
        dispatched(getLatestUpdates(salesClientId));

        dispatched(fetchClient(salesClientId));
        if (response.data.data.invite) {
          alertSuccess('Saved', 'Invite link sent!');
        }
        if (response.data.data.isProposalSent) {
          alertSuccess('Saved', 'Proposal link sent!');
        }
        initialize();
      }
    } catch (error) {
      alertError(
        'Failed to save proposal',
        error.message === 'Request failed with status code 409'
          ? 'Client is already registered - update in client profile'
          : error.message == 'Request failed with status code 404'
          ? 'Email Missing! Please check client profile'
          : error.message
      );
    } finally {
      setLoading(false);
      if (values.isOffline) {
        setIsOpenOfflineConfirmation(false);
      }
    }
  };

  const onUpdateForm = (data, values, setValues) => {
    setValues({ ...values, ...data });
    setProposalValues({ ...values, ...data });
    onSubmit({ ...values, ...data });
  };

  const onSendInvite = (values) => {
    onSubmit({ ...values, isSendInvite: true });
  };

  const onCreateProposal = (values) => {
    if ((values.noCommission || values.commissionType) && values.plan_code) {
      if (proposal.proposalInvite && proposal.proposalInvite.proposalId) {
        onSubmit({
          ...proposalValues,
          isResendProposal: true,
          acknowledgement: proposal.acknowledgement,
        });
      } else {
        onSubmit({ ...proposalValues, isCreateProposal: true });
      }
    } else {
      dispatched(
        setAlert(
          'error',
          'Incomplete Details',
          'Please check Proposal and Commission Information.'
        )
      );
    }
  };

  const onUpdateProposalContent = (values, proposalContent, e) => {
    e.preventDefault();

    if ((values.noCommission || values.commissionType) && values.plan_code) {
      onSubmit({ ...values, proposalContent });
    } else {
      dispatched(
        setAlert(
          'error',
          'Incomplete Details',
          'Please check Proposal and Commission Information.'
        )
      );
    }
  };
  const handleCopy = async () => {
    try {
      const link = `${process.env.REACT_APP_CLIENT_URL}/proposal/${proposal.proposalInvite.proposalToken}`;
      await navigator.clipboard.writeText(link);
      alertSuccess('Proposal link copied to clipboard', link);
    } catch (error) {
      alertError(
        'Failed to copy link',
        'Please check if the proposal has been sent or not'
      );
    }
  };

  const onConfirmPayment = async (status) => {
    try {
      await axios.patch(
        `/agency/sales/clients/${salesClientId}/proposal/confirm-payment`,
        { status }
      );
      alertSuccess(
        `Proposal payment ${status}ed`,
        'Sales Rep and Sales admin will be notified'
      );
    } catch (error) {
      alertError('Failed to confirm payment', error.response.data.message);
    }
  };
  const onResendLogin = async () => {
    const userId = client?.AgencyClient.defaultContactId;
    try {
      await axios.post(
        `/agency/sales/clients/${salesClientId}/proposal/resend-login`,
        { userId }
      );
      alertSuccess(
        `Login email will be sent shortly`,
        'A login email will be resent to the client'
      );
      setConfirmResend(false);
    } catch (error) {
      alertError('Failed to resend client login', error.response.data.message);
    }
  };

  const onCreateOffline = async (values) => {
    onSubmit({ ...values, isOffline: true });
  };

  return (
    <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
      <Formik
        initialValues={proposal.initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors, setValues }) => (
          <Form>
            <div className="border rounded-2xl p-8 mb-10 space-y-3">
              <div className="flex justify-between">
                <h4 className="col-span-1 text-xl font-bold flex flex-row items-center">
                  Proposal&nbsp;
                  {values.name &&
                    page === 'deals' &&
                    !proposal.onboardingHistory?.paymentMade && (
                      <PencilIcon
                        className="h-6 w-6 text-grayscale-800 cursor-pointer inline ml-3"
                        onClick={() => setOpenQuoteModal(true)}
                      />
                    )}
                </h4>
                <div className="flex flex-col items-end space-y-2">
                  {userCan('sales.profiles.quotes.manage') &&
                    (values.name ? (
                      <div className="flex space-x-4 items-center">
                        {client?.AgencyClient && (
                          <>
                            {page === 'deals' && (
                              <DropdownMenu
                                title={
                                  <DotsVerticalIcon className="w-6 h-6 text-gray-500 hover:text-gray-900" />
                                }
                                titleClasses="flex items-center mr-1"
                                button
                                buttonBg="bg-transparent"
                                buttonFontWeight="font-normal"
                                hoverClasses="bg-none"
                                textColor="text-gray-600"
                                classes="text-sm"
                                buttonRounded=""
                                hoverText="hover:text-red-500"
                                dropdownWidth="w-48"
                                padding=""
                                position=""
                                hideArrow
                              >
                                <div className="px-1 py-1 flex flex-col">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={handleCopy}
                                        className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                                      >
                                        Copy Proposal Link
                                      </button>
                                    )}
                                  </Menu.Item>
                                  {userCan(
                                    'sales.proposal.generate.offline'
                                  ) && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            setIsOpenOfflineConfirmation(true)
                                          }
                                          className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                                        >
                                          Generate Offline
                                        </Button>
                                      )}
                                    </Menu.Item>
                                  )}
                                  {isAgencySuperUser() &&
                                    !client?.AgencyClient.btCustomerId && (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Button
                                            type="button"
                                            onClick={() => onSendInvite(values)}
                                            className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                                          >
                                            Send Invite
                                          </Button>
                                        )}
                                      </Menu.Item>
                                    )}

                                  {userCan('sales.proposal.resend.login') &&
                                    client?.AgencyClient.defaultContact && (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              setConfirmResend(true)
                                            }
                                            className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                                          >
                                            Resend Login
                                          </Button>
                                        )}
                                      </Menu.Item>
                                    )}
                                </div>
                              </DropdownMenu>
                            )}

                            <Link
                              className="border-2 border-secondary rounded-full px-10 py-1.5 font-bold tracking-widest"
                              to={`/proposal/view/${client.salesClientId}`}
                              title="Profile"
                              target="_blank"
                            >
                              View
                            </Link>
                          </>
                        )}
                        {proposal.proposalInvite &&
                        proposal.proposalInvite.proposalId ? (
                          ''
                        ) : userCan('sales.profiles.quotes.manage') &&
                          page === 'deals' ? (
                          <Button
                            roundedSize="3xl"
                            classes="tracking-wider font-bold"
                            px={7}
                            py={2}
                            textSize="sm"
                            color="blue"
                            type="button"
                            bgColor="blue-900"
                            onClick={() => onCreateProposal(values)}
                          >
                            Send
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      page === 'deals' && (
                        <Button
                          roundedSize="full"
                          classes="tracking-widest font-bold capitalize bg-secondary hover:bg-secondary-light"
                          px={8}
                          py={2}
                          textSize="sm"
                          color=""
                          onClick={() => setOpenQuoteModal(true)}
                        >
                          create
                        </Button>
                      )
                    ))}

                  {userCan('sales.proposal.payment.confirm') &&
                    values.name &&
                    page === 'deals' && (
                      <div className="flex space-x-2">
                        <Button
                          type="button"
                          classes="tracking-widest py-1.5 rounded-full text-sm font-bold text-white bg-green-500 hover:bg-green-400 focus:ring-red-500 border border-transparent border-0 font-bold tracking-wider  inline-flex items-center focus:outline-none"
                          px={8}
                          onClick={() => onConfirmPayment('confirm')}
                        >
                          Confirm Payment
                        </Button>
                        <Button
                          type="button"
                          classes="tracking-widest py-1.5 rounded-full text-sm font-bold text-white bg-red-500 hover:bg-red-400 focus:ring-red-500 border border-transparent border-0 font-bold tracking-wider  inline-flex items-center focus:outline-none"
                          px={6}
                          onClick={() => onConfirmPayment('fail')}
                        >
                          Notify Payment Failure
                        </Button>
                      </div>
                    )}
                </div>
              </div>
              {values.name !== '' ? (
                <ProposalBlock
                  setOpen={setOpenQuoteModal}
                  proposal={proposal}
                  values={values}
                />
              ) : (
                <EmptyBlock title="proposal" />
              )}
            </div>
            <ProposalModal
              open={openQuoteModal}
              setOpen={setOpenQuoteModal}
              proposal={proposal}
              data={values}
              onSubmit={(data) => onUpdateForm(data, values, setValues)}
            />

            <div className="mb-10 space-y-6">
              <div className="flex justify-between">
                <h4 className="col-span-1 text-xl font-bold">
                  Commission Rate
                </h4>
                {page === 'deals' &&
                  (values.commissionType === '' || values.noCommission) && (
                    <ButtonLink
                      classes="tracking-widest font-bold capitalize text-grayscale-700 border-2 border-grayscale-700 rounded-full px-8 py-2 hover:bg-secondary hover:text-white hover:border-white"
                      color=""
                      onClick={() => setOpenCommissionModal(true)}
                    >
                      create
                    </ButtonLink>
                  )}
              </div>

              {values.commissionType === '' ? (
                <EmptyBlock title="commission rate" />
              ) : (
                <CommissionBlock
                  values={values}
                  setOpen={setOpenCommissionModal}
                  page={page}
                />
              )}
            </div>
            <CommissionModal
              setOpen={setOpenCommissionModal}
              open={openCommissionModal}
              values={values}
              onSubmit={(data) => onUpdateForm(data, values, setValues)}
            />

            <div className="mb-10 space-y-6">
              <div className="flex justify-between">
                <h4 className="col-span-1 text-xl font-bold">Add Ons</h4>
                {page === 'deals' && client?.AgencyClient && (
                  <ButtonLink
                    classes="tracking-widest font-bold capitalize text-grayscale-700 border-2 border-grayscale-700 rounded-full px-8 py-2 hover:bg-secondary hover:text-white hover:border-white"
                    color=""
                    onClick={() => setOpenAddonModal(true)}
                  >
                    create
                  </ButtonLink>
                )}
              </div>

              {values.addons && values.addons.length ? (
                <AddonBlock setOpen={setOpenAddonModal} page={page} />
              ) : (
                <EmptyBlock title="add ons" />
              )}
            </div>
            <AddonModal
              open={openAddonModal}
              setOpen={setOpenAddonModal}
              values={values}
              onSubmit={(data) => onUpdateForm(data, values, setValues)}
            />
            {page === 'deals' && (
              <ContentBlock
                onSubmit={(e, data) => onUpdateProposalContent(values, data, e)}
              />
            )}
            <ConfirmationModal
              title="Create Offline Subscription"
              content="This will let the client skip credit card billing, but you will have to manually record payments for invoices"
              open={isOpenOfflineConfirmation}
              setOpen={setIsOpenOfflineConfirmation}
              onOkClick={() => onCreateOffline(values)}
              onCancelClick={() => setIsOpenOfflineConfirmation(false)}
              okLoading={loading}
              showOkLoading={true}
            />
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        title="Resend login credentials to client"
        content={`Please note that this will generate a new password. Proceed?`}
        open={confirmResend}
        setOpen={setConfirmResend}
        onOkClick={onResendLogin}
        onCancelClick={() => setConfirmResend(false)}
      />
    </div>
  );
};

export default Proposal;
