import useLoggedInUser from 'hooks/useLoggedInUser';
import { Avatar } from '@material-ui/core';

const UserAvatar = ({ size = '32px' }) => {
  const { user, name, initials } = useLoggedInUser();

  return user.profileLink ? (
    <Avatar
      alt={name}
      src={user?.profileLink}
      style={{
        width: size,
        height: size,
        color: '#fff',
        backgroundColor: '#002F5D',
      }}
    />
  ) : (
    <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400 ml-3">
      <span className="font-medium leading-none text-white">{initials}</span>
    </span>
  );
};
export default UserAvatar;
