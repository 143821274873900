import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { Checkbox } from 'components/Forms';

import { setUser } from 'features/auth/authSlice';

import useAuth from 'hooks/useAuth';
import usePermissions from '../usePermissions';
import usePermissionsHook from 'hooks/usePermissions';

const PermissionItem = ({ roleId, data }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [permission, setPermission] = useState(data);

  const { userCan, isAgencySuperUser } = usePermissionsHook();
  const { addPermission, removePermission } = usePermissions();

  useEffect(() => {
    setPermission(data);
  }, [data]);

  const onChange = async (e) => {
    const { id, checked } = e.target;

    checked
      ? await addPermission(roleId, id)
      : await removePermission(roleId, id);

    setPermission({ ...permission, enabled: checked });

    if (parseInt(user.role.roleId) === parseInt(roleId)) {
      const userCopy = cloneDeep({ ...user });

      userCopy.role.permissions = checked
        ? [
            ...userCopy.role.permissions,
            { access: permission.access, feature: permission.feature },
          ]
        : [...userCopy.role.permissions].filter(
            (p) => p.access !== permission.access
          );

      dispatch(setUser(userCopy));
    }
  };

  return (
    <li className="text-sm text-grayscale-800 flex justify-between items-center space-x-4">
      {permission.description ?? permission.access}
      <Checkbox
        disabled={
          isAgencySuperUser() ? false : userCan('admin.roles.permisions.update')
        }
        id={permission.permissionId}
        onChange={onChange}
        checked={permission.enabled}
      />
    </li>
  );
};

export default PermissionItem;
