import axios from 'axios';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import SectionComment from '../components/SectionComment';

import classNames from 'utils/classNames';
import metrics from '../../utils/metrics';

const attributes = ['sales', 'roas', 'cost', 'orders', 'impressions', 'cpcon'];

const KeywordPerformance = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  comment = '',
  onChangeComment,
  editableComment = true,
}) => {
  const [sortBy, setSortBy] = useState('desc');
  const [sortAttribute, setSortAttribute] = useState('sales');
  const [targetings, setTargetings] = useState({ rows: [] });
  const [localSortAttribute, setLocalSortAttribute] = useState('sales');
  const [localSortBy, setLocalSortBy] = useState('desc');

  useEffect(() => {
    let params = {
      page: 1,
      pageSize: 10,
      attributes: ['advTargetingId', 'value', ...attributes],

      startDate,
      endDate,
      accountId,
      marketplace,
      sort: `${sortAttribute}:${sortBy}`,
      ...additionalParams,
    };

    axios
      .get('/account/advertising/targetings', { params })
      .then((response) => setTargetings(response.data.data));
  }, [
    accountId,
    marketplace,
    startDate,
    endDate,
    additionalParams,
    sortAttribute,
    sortBy,
  ]);

  return (
    !!targetings.rows.length && (
      <div className="relative">
        <SectionComment
          value={comment}
          onChangeValue={onChangeComment}
          editable={editableComment}
        />

        <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
          <div className="flex items-center justify-between border-b px-8 pb-6">
            <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
              Keywords Performance
            </p>
          </div>

          <div className="px-8">
            <div className="flex py-4 justify-between items-center">
              <div className="relative z-0 inline-flex rounded-md">
                {attributes
                  .map((attribute) => metrics.find((m) => m.key === attribute))
                  .map((metric, index) => (
                    <button
                      type="button"
                      key={`keyword-performance-metrics-option-${metric.key}`}
                      onClick={() => {
                        setSortAttribute(metric.key);
                        setLocalSortAttribute(metric.key);
                      }}
                      className={classNames(
                        index === 0 ? 'rounded-l-md' : '',
                        index === 5 ? 'rounded-r-md' : '',
                        index < 5 ? 'border-r-0' : '',
                        sortAttribute === metric.key
                          ? 'bg-grayscale-800 text-white'
                          : 'bg-white text-grayscale-800',
                        'tracking-2 font-bold relative inline-flex items-center px-5 py-2 border border-grayscale-800 text-tiny'
                      )}
                    >
                      {metric.shortTitle ?? metric.title}
                    </button>
                  ))}
              </div>

              <div className="flex items-center font-sourceSansPro">
                <p className="text-xs mr-3 text-grayscale-900">Sort by</p>
                <div className="relative z-0 inline-flex rounded-md">
                  <button
                    type="button"
                    onClick={() => {
                      setSortBy('desc');
                      setLocalSortBy('desc');
                    }}
                    className={classNames(
                      sortBy === 'desc'
                        ? 'bg-grayscale-800 text-white'
                        : 'bg-white text-grayscale-800',
                      'tracking-2 font-bold relative inline-flex items-center px-5 py-2 rounded-l-md border border-grayscale-800 text-tiny'
                    )}
                  >
                    Most
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSortBy('asc');
                      setLocalSortBy('asc');
                    }}
                    className={classNames(
                      sortBy === 'asc'
                        ? 'bg-grayscale-800 text-white'
                        : 'bg-white text-grayscale-800',
                      'tracking-2 font-bold  -ml-px relative inline-flex items-center px-5 py-2 rounded-r-md border border-grayscale-800 text-tiny'
                    )}
                  >
                    Least
                  </button>
                </div>
              </div>
            </div>

            <p className="text-grayscale-800 tracking-3/4 font-bold font-inter leading-1.2 text-xl my-5">
              Top 10 keywords with {sortBy === 'asc' ? 'least' : 'most'}{' '}
              {metrics.find((m) => m.key === sortAttribute).title}
            </p>

            <table className="divide-y divide-gray-300 w-full table-fixed font-sourceSansPro">
              <thead
                className={`bg-${sortBy === 'asc' ? 'error' : 'success'}-dark`}
              >
                <tr className="">
                  <th className="p-3 text-mini font-bold text-white border border-grayscale-500 text-left">
                    Keyword
                  </th>
                  {attributes
                    .map((attr) => metrics.find((m) => m.key === attr))
                    .map((metric, index) => (
                      <th
                        key={`keyword-performance-header-${metric.key}`}
                        scope="col"
                        className={classNames(
                          'p-3 text-mini font-bold text-white border border-grayscale-500',
                          index ? 'text-right' : 'text-left'
                        )}
                        width={index == 5 ? '18%' : '13%'}
                      >
                        <div className="flex justify-between items-center whitespace-nowrap">
                          {metric.title}
                          <div
                            className="ml-2 cursor-pointer"
                            style={{ fontSize: '8px' }}
                            onClick={() => {
                              setLocalSortAttribute(metric.key);
                              setLocalSortBy(
                                localSortBy === 'asc' ? 'desc' : 'asc'
                              );
                            }}
                          >
                            <div
                              className={
                                localSortBy === 'asc' &&
                                localSortAttribute === metric.key
                                  ? 'text-grayscale-600'
                                  : ''
                              }
                            >
                              ▲
                            </div>
                            <div
                              className={
                                localSortBy === 'desc' &&
                                localSortAttribute === metric.key
                                  ? 'text-grayscale-600'
                                  : ''
                              }
                            >
                              ▼
                            </div>
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody>
                {orderBy(
                  targetings.rows,
                  [localSortAttribute],
                  [localSortBy]
                ).map((targeting) => (
                  <tr
                    key={`keyword-performance-row-${targeting.advTargetingId}`}
                  >
                    <td className="px-3 py-2 text-mini text-grayscale-800 border border-grayscale-500">
                      {targeting.value}
                    </td>
                    {attributes
                      .map((attr) => metrics.find((m) => m.key === attr))
                      .map((metric) => (
                        <td
                          key={`keyword-performance-row-${targeting.advTargetingId}-${metric.key}`}
                          className="px-3 py-2 text-mini text-grayscale-800 border border-grayscale-500 text-center"
                        >
                          {metric.rawFormatter(targeting[metric.key])}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
};

export default KeywordPerformance;
