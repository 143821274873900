import axios from 'axios';
import moment from 'moment-timezone';

import { useState, useEffect } from 'react';

import { FilterGroup } from 'components/Filters';
import RangePicker from 'components/RangePicker';
import useTasks from '../useTasks';
import useAuth from 'hooks/useAuth';
import { useRoles } from 'features/roles';

const TaskFilters = ({ filters = [], statusesOptions = [], setFilters }) => {
  const { user } = useAuth();
  const { AGENCY_SUPER_USER } = useRoles();
  const { PRIORITIES } = useTasks();

  const [priorities, setPriorities] = useState(
    filters.find((f) => f.attribute === 'priority')?.value ?? []
  );

  const [statuses, setStatuses] = useState(
    filters.find((f) => f.attribute === 'status')?.value ?? []
  );

  const [dueDate, setDueDate] = useState(
    filters.find((f) => f.attribute === 'dueDate')?.value ?? []
  );

  const [assignees, setAssignees] = useState(
    filters.find((f) => f.attribute === 'assignees.userId')?.value ?? []
  );

  const [assigneesSearch, setAssigneesSearch] = useState('');
  const [assigneeOptions, setAssigneeOptions] = useState([]);

  useEffect(() => {
    let params = {
      search: assigneesSearch,
      'role.level': 'agency',
    };

    if (user?.role?.code !== AGENCY_SUPER_USER.value) {
      params.isMyTeamMate = true;
    }

    axios
      .get('/v2/users', { params })
      .then((response) => setAssigneeOptions(response.data.data));
  }, [assigneesSearch, user?.role?.code]);

  const onApplyFilter = () => {
    let newFilters = [];

    if (priorities.length) {
      newFilters.push({
        operator: 'IN',
        value: priorities,
        attribute: 'priority',
      });
    }

    if (statuses.length) {
      newFilters.push({
        operator: 'IN',
        value: statuses,
        attribute: 'status',
      });
    }

    if (assignees.length) {
      newFilters.push({
        operator: 'IN',
        value: assignees,
        attribute: 'assignees.userId',
      });
    }

    if (dueDate.length) {
      newFilters.push({
        operator: 'BETWEEN',
        value: dueDate,
        attribute: 'dueDate',
      });
    }

    setFilters(newFilters);
  };

  const DateFilterComponent = ({ values = [], onChange }) => {
    const [selectedDates, setSelectedDates] = useState(values);

    return (
      <RangePicker
        monthsToDisplay={1}
        orientation="vertical"
        selectedDates={selectedDates.map((value) => new Date(value))}
        setSelectedDates={(dates) => {
          const formattedDates = dates.map((date) =>
            moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD')
          );

          setSelectedDates(formattedDates);

          if (dates.length === 2) {
            onChange(formattedDates);
          }
        }}
        ignoreMinDate={true}
      />
    );
  };

  return (
    <FilterGroup
      onApply={onApplyFilter}
      onClear={() => {
        setStatuses([]);
        setPriorities([]);
        setAssignees([]);
        setDueDate([]);
      }}
      filters={[
        {
          field: 'status',
          title: 'Status',
          values: statuses,
          options: statusesOptions,
          onChange: (values) => {
            setStatuses(values.status);
          },
        },
        {
          field: 'priority',
          title: 'Priority',
          values: priorities,
          options: PRIORITIES.map((priority) => ({
            label: priority.text,
            value: priority.value,
          })),
          onChange: (values) => {
            setPriorities(values.priority);
          },
        },
        {
          searchable: true,
          field: 'assignees.userId',
          title: 'Assignees',
          values: assignees,
          onSearch: (search) => setAssigneesSearch(search),
          options: assigneeOptions.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            setAssignees(values['assignees.userId']);
          },
        },
        {
          field: 'dueDate',
          title: 'Due Date',
          values: dueDate,
          FilterComponent: DateFilterComponent,
          onChange: (values) => {
            setDueDate(values);
          },
        },
      ]}
    />
  );
};

export default TaskFilters;
