import { Modal } from 'components';
import ModalHeader from 'components/ModalHeader';
import React, { useState, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import classNames from 'utils/classNames';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useCountry from 'hooks/useCountry';
import useAlert from 'hooks/useAlert';
import Button from 'components/Button';
import {
  setupReducer,
  initialSetupState,
} from '../onboarding/accountSubscriptionReducer';
import amex from 'assets/icons/amex.svg';
import mastercard from 'assets/icons/mastercard.svg';
import visa from 'assets/icons/visa.svg';

import PaymentForm from 'components/PaymentForm';
import useBraintreeUtils from 'hooks/useBraintreeUtils';
import { omit, pick } from 'lodash';
import useBilling from 'hooks/useBilling';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { getAccountsAsync } from 'features/accounts/accountsSlice';
import { useDispatch } from 'react-redux';

const paymentModes = {
  existing: 'existing',
  new: 'new',
};

const SwitchPaymentModal = ({ open, setOpen, subscription }) => {
  const dispatched = useDispatch();
  const { taxes, getTaxes } = useBilling();
  const { user, currentAccount, reloadMe } = useLoggedInUser();
  const { countries } = useCountry();
  const { alertSuccess, alertError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const paymentFormRef = useRef();
  const [paymentFormError, setPaymentFormError] = useState(false);
  const { showTokenizeError } = useBraintreeUtils();
  const [setup, dispatch] = useReducer(setupReducer, initialSetupState);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    address: '',
    city: '',
    country: '',
    province: '',
    postalCode: '',
  });
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [usePaymentMode, setUsePaymentMode] = useState(null);

  const validationSchema = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().email().required('Email is required'),
    company: string().required('Company is required'),
    address: string().required('Address isrequired'),
    city: string().required('City is required'),
    country: string().required('Country is required'),
    province: string().required('State is required'),
    postalCode: string().required('Postal code is required'),
  });

  useEffect(() => {
    if (!taxes) {
      getTaxes();
    }
  }, [taxes]);

  useEffect(() => {
    if (subscription && taxes) {
      const setAddressValue = (key) => {
        if (
          subscription.customer.billing_address &&
          subscription.customer.billing_address[key]
        ) {
          return subscription.customer.billing_address[key];
        }

        return '';
      };

      setInitialValues({
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        email: user ? user.email : '',
        company: user.defaultAgency?.client ?? '',
        address: setAddressValue('street'),
        city: setAddressValue('city'),
        country: setAddressValue('country'),
        province: setAddressValue('state'),
        postalCode: setAddressValue('zip'),
      });

      if (setAddressValue('country') !== '') {
        dispatch({
          type: 'updateInitialAddress',
          country: countries.find(
            ({ name }) => name === setAddressValue('country')
          ),
          province: setAddressValue('state'),
          taxRates: taxes,
        });
      }
    }
  }, [subscription, taxes]);

  useEffect(() => {
    if (user && user.defaultAgency?.btCustomerId) {
      axios
        .get(
          `/agency/clients/${user.defaultAgency.agencyClientId}/payment-methods/default/${user.defaultAgency.btCustomerId}`
        )
        .then((res) => {
          setDefaultPaymentMethod(res.data.data);
          setUsePaymentMode(
            res.data.data ? paymentModes.existing : paymentModes.new
          );
        });
    } else {
      setUsePaymentMode(paymentModes.new);
    }
  }, [user]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    if (usePaymentMode === 'existing') {
      await axios
        .patch(
          `/agency/clients/${user.defaultAgency.agencyClientId}/paymentmode`,
          {
            paymentMode: 'braintree',
          }
        )
        .then((response) => {
          alertSuccess(
            'Payment method Updated',
            'Page will reload automatically...'
          );
          setOpen(false);
          reloadMe();
        })
        .catch((error) => {
          alertError('Something went wrong', error.response.data.message);
        })
        .finally(() => setIsLoading(false));
    } else {
      let paymentMethodNonce = null;

      await paymentFormRef.current
        .tokenize()
        .then((response) => {
          paymentMethodNonce = response.nonce;
        })
        .catch((error) => {
          showTokenizeError(error);
        });

      if (paymentMethodNonce && paymentFormRef.current.deviceData) {
        try {
          await axios.post(
            `/agency/clients/${user.defaultAgency.agencyClientId}/payment-methods/switch`,
            {
              setup: omit(setup, [
                'hostedPageDetails',
                'provinces',
                'selectedCountry',
                'billingAddress',
                'plan',
                'onboardingHistory',
                'startsAt',
              ]),
              selectedCountry: pick(setup.selectedCountry, [
                'isoCode',
                'name',
                'currency',
              ]),
              ...values,
              paymentMethodNonce,
              deviceData: paymentFormRef.current.deviceData,
              accountId: currentAccount.accountId,
            }
          );

          alertSuccess(
            'Payment method Updated',
            'Page will reload automatically...'
          );

          setOpen(false);
          reloadMe();
        } catch (error) {
          alertError('Something went wrong', error.response.data.message);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <Modal open={open} setOpen={setOpen} as={'div'} align="top" persistent>
      <div className="inline-block w-full max-w-lg my-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Update Payment Method"
          setOpen={setOpen}
          showCloseButton={setOpen}
          border=""
          fontSize="text-xl"
          py="py-8"
          px="px-8"
          titleClasses="font-bold"
        />
        <div className="px-8 pb-8 text-base">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, setFieldValue }) => (
              <Form>
                <FormikErrorNotification />
                <h3 className="text-lg leading-6 font-bold text-grayscale-900 mb-6">
                  Payment Information
                </h3>

                {defaultPaymentMethod && (
                  <div className="flex items-center justify-between border rounded-xl py-2 px-5 mb-5">
                    <label className="flex items-center space-x-3">
                      <input
                        value="existing"
                        name="paymentMode"
                        type="radio"
                        className="focus:outline-none focus:ring-0 h-6 w-6 text-green-500 border-gray-300"
                        checked={usePaymentMode === paymentModes.existing}
                        onChange={() =>
                          setUsePaymentMode(paymentModes.existing)
                        }
                      />
                      <span> Ending in {defaultPaymentMethod.last4}</span>
                    </label>
                    <img src={defaultPaymentMethod.imageUrl} />
                  </div>
                )}

                <div className="flex flex-col border rounded-xl pt-2 pb-4">
                  <div className="flex items-center justify-between px-5">
                    <label className="flex items-center space-x-3">
                      {defaultPaymentMethod && (
                        <input
                          value="new"
                          name="paymentMode"
                          type="radio"
                          className="focus:outline-none focus:ring-0 h-6 w-6 text-green-500 border-gray-300"
                          checked={usePaymentMode === paymentModes.new}
                          onChange={() => setUsePaymentMode(paymentModes.new)}
                        />
                      )}
                      <span>
                        Add&nbsp;
                        {defaultPaymentMethod ? 'A New Card' : 'Payment Method'}
                      </span>
                    </label>
                    <div className="flex items-center space-x-1.5 flex-wrap">
                      <img
                        src={visa}
                        alt="visa"
                        className="object-fill w-7 h-6"
                      />
                      <img
                        src={mastercard}
                        alt="mastercard"
                        className="object-fill w-7 h-6"
                      />
                      <img
                        src={amex}
                        alt="amex"
                        className="object-fill w-6 h-5"
                      />
                    </div>
                  </div>
                  <div className="border-t border-grayscale-500 mt-4">
                    &nbsp;
                  </div>
                  <div
                    className={classNames(
                      'px-5',
                      usePaymentMode === paymentModes.new
                        ? ''
                        : 'pointer-events-none opacity-20'
                    )}
                  >
                    <PaymentForm
                      ref={paymentFormRef}
                      setError={setPaymentFormError}
                    />
                  </div>
                </div>

                <div className="my-8 flex space-x-1 justify-around">
                  <div className="flex items-center justify-center space-x-1">
                    <svg
                      width="17"
                      height="20"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.46986 0.166016L0.25 4.10541V10.0145C0.25 15.4804 4.18381 20.5918 9.46986 21.8327C14.7559 20.5918 18.6897 15.4804 18.6897 10.0145V4.10541L9.46986 0.166016ZM16.6409 10.0145C16.6409 14.466 13.5881 18.5728 9.46986 19.794C5.35166 18.5728 2.29886 14.466 2.29886 10.0145V5.38571L9.46986 2.32283L16.6409 5.38571V10.0145ZM4.76773 10.5956L3.32329 11.9842L7.421 15.9236L15.6164 8.0448L14.172 6.64632L7.421 13.1365L4.76773 10.5956Z"
                        fill="#949494"
                      />
                    </svg>
                    <span className="text-grayscale-700 leading-1.5 text-sm">
                      Secured checkout
                    </span>
                  </div>
                  <div className="flex justify-center space-x-3">
                    <a
                      href={`https://www.braintreegateway.com/merchants/${process.env.REACT_APP_BT_MERCHANT_ID}/verified`}
                      target="_blank"
                    >
                      <img
                        src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                        width="150px"
                        border="0"
                      />
                    </a>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button
                    classes="border-0 mt-8 tracking-widest"
                    bgColor="green-500"
                    hoverColor="green-700"
                    roundedSize="full"
                    textColor="white"
                    px={16}
                    py={2}
                    shadow=""
                    textSize="sm"
                    fontWeight="bold"
                    showLoading={true}
                    loading={isLoading}
                    type="submit"
                    disabled={paymentFormError}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchPaymentModal;
