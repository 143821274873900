import React, { useEffect, useState } from 'react';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import moment from 'moment';
import { AnnotationIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/solid';
import UserAvatar from 'components/UserAvatar';
import classNames from 'utils/classNames';
import { usePopper } from 'react-popper';
moment.tz.setDefault('America/Toronto');

const CommentForm = ({
  id,
  revision,
  update,
  listingMockupTextId = null,
  listingMockupSlideId = null,
  mockupTextRevision,
  type,
  setReadyRevision,
  checkReadyRevision,
  setCheckReadyRevision,
  label = '',
  ...rest
}) => {
  const { name } = useLoggedInUser();
  const [comment, setComment] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [action, setAction] = useState('add');
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: { offset: [revision?.type === 'slide' ? 150 : -150, 0] },
      },
    ],
  });
  const enumAction = {
    ADD: 'add',
    UPDATE: 'update',
  };

  useEffect(() => {
    if (revision) {
      if (revision.revisions.length > 0) {
        setAction(enumAction.UPDATE);
        setComment(revision.revisions[0].comment);
      } else {
        setAction(enumAction.ADD);
        setComment('');
      }
      setIsEdit(false);
    }
  }, [revision]);

  const addComment = (close) => {
    update({ revision, comment, action, label });
    close();
  };

  const onDeleteComment = (close) => {
    update({ revision, comment, action: 'delete', label });
    close();
  };

  return (
    <>
      <div className="relative" {...rest}>
        <Popover
          className={classNames(
            type === 'text' ? 'justify-end' : 'justify-start',
            'flex relative'
          )}
        >
          {({ open, close }) => {
            return (
              <>
                <Popover.Button
                  ref={setReferenceElement}
                  className={classNames(
                    open ? '' : 'text-opacity-90',
                    'text-white group bg-orange-700 py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                  )}
                >
                  {action === enumAction.UPDATE ? (
                    <UserAvatar />
                  ) : (
                    <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-custom-blue">
                      <AnnotationIcon className="w-5 h-5" />
                    </span>
                  )}
                </Popover.Button>
                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="absolute z-20 min-h-full max-w-sm w-80 left-1/2 -translate-x-1/2 transform"
                >
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="flex-col bg-white p-4">
                      <div className="flex justify-end">
                        <button
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          onClick={close}
                          type="button"
                        >
                          <XIcon className="h-5 w-5 inline" />
                        </button>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex pb-3 space-x-3">
                          <UserAvatar />
                          <div className="flex flex-col text-xs">
                            <p className="text-grayscale-900 capitalize">
                              {name}
                            </p>
                            <p className="text-grayscale-700">
                              {action === enumAction.ADD
                                ? moment().format('LLL')
                                : moment(
                                    revision?.revisions[0]?.updatedAt
                                  ).calendar()}
                            </p>
                          </div>
                        </div>

                        {action === enumAction.UPDATE && (
                          <>
                            <div className="flex justify-end pr-5">
                              <button
                                type="button"
                                onClick={() => setIsEdit(true)}
                              >
                                <PencilAltIcon className="text-custom-blue w-5 h-5" />
                              </button>
                              <button
                                type="button"
                                onClick={() => onDeleteComment(close)}
                              >
                                <TrashIcon className="text-custom-blue w-5 h-5 ml-1" />
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      {action === enumAction.UPDATE && !isEdit && (
                        <p
                          className="ml-4 pt-1 pb-3 px-5 whitespace-pre-wrap"
                          dangerouslySetInnerHTML={{ __html: comment }}
                        ></p>
                      )}

                      {(action === enumAction.ADD ||
                        (action === enumAction.UPDATE && isEdit)) && (
                        <>
                          <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                            rows={4}
                            cols={40}
                            className="form-input mt-2"
                            placeholder="Type here..."
                          />
                          <div className="flex justify-end mt-2">
                            <button
                              type="button"
                              className="text-custom-sky font-bold text-xs mr-6"
                              onClick={close}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={() => addComment(close)}
                              className="bg-custom-blue py-2 px-10 text-white font-bold text-xs rounded-full disabled:opacity-10 capitalize"
                              disabled={!comment}
                            >
                              {action}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </>
            );
          }}
        </Popover>
      </div>
    </>
  );
};

export default CommentForm;
