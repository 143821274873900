import axios from 'axios';
import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';

import {
  XIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';

import Modal from 'components/Modal';

import classNames from 'utils/classNames';

const ProductFilter = ({
  accountId,
  marketplace,
  value = [],
  onChange,
  buttonBg = 'white',
  className = '',
}) => {
  const [open, setOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState(value);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/products', {
        params: {
          accountId,
          marketplace,
          attributes: ['productId', 'asin'],
          pageSize: 1000,
        },
      });

      if (isSubscribed) {
        setProducts(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace]);

  const onChangeItem = (e) => {
    const { checked, value } = e.target;

    const newSelected = checked
      ? [...selectedProductIds, value]
      : [...selectedProductIds].filter((i) => i !== value);

    setSelectedProductIds(newSelected);
  };

  return (
    <div className={className}>
      <Modal open={open} setOpen={setOpen} as={'div'} align="top">
        <div
          className={classNames(
            'inline-block w-96 min-h-fit my-8 text-left align-middle transition-all transform shadow-xl rounded-2xl py-6 px-7 bg-white'
          )}
        >
          <div className="flex justify-between items-center">
            <p className="font-bold text-grayscale-900 font-inter tracking-3/4">
              Product Filters
            </p>
            <button
              type="button"
              className="rounded-md text-grayscale-700 hover:text-grayscale-800 focus:outline-none"
              onClick={() => setOpen(false)}
            >
              <XIcon className="h-6 w-6 " aria-hidden="true" />
            </button>
          </div>

          <div>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="mt-8 flex w-full justify-between font-bold">
                    <span className="text-grayscale-800">
                      Products ({selectedProductIds.length} / {products.length})
                    </span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-grayscale-700`}
                    />
                  </Disclosure.Button>

                  <Disclosure.Panel className="flex justify-end text-mini py-2 text-grayscale-800">
                    <p
                      className="uppercase text-mini text-grayscale-800 cursor-pointer"
                      onClick={() => {
                        selectedProductIds.length
                          ? setSelectedProductIds([])
                          : setSelectedProductIds(
                              products.map((option) => option.productId)
                            );
                      }}
                    >
                      {selectedProductIds.length
                        ? 'Unselect All'
                        : 'Select All'}
                    </p>
                  </Disclosure.Panel>

                  <div className="max-h-60 overflow-y-scroll pr-1">
                    {products.map((option) => {
                      return (
                        <Disclosure.Panel
                          key={`product-filters-${option.productId}`}
                          className="flex justify-between text-mini pt-4 text-grayscale-800"
                        >
                          {option.asin}
                          <input
                            value={option.productId}
                            type="checkbox"
                            className="text-secondary rounded-full w-6 h-6 bg-grayscale-500 focus:border-0 focus:ring-0"
                            checked={selectedProductIds.includes(
                              option.productId
                            )}
                            onChange={onChangeItem}
                          />
                        </Disclosure.Panel>
                      );
                    })}
                  </div>
                </>
              )}
            </Disclosure>
          </div>

          <div className="mt-8 flex justify-between">
            <button
              className="text-sm font-bold tracking-2 text-secondary-light"
              onClick={() => {
                setSelectedProductIds(value);
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-sm font-bold tracking-2 bg-secondary text-grayscale-300 rounded-3xl px-8 py-2"
              onClick={() => {
                onChange(selectedProductIds);
                setOpen(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>

      <button
        onClick={() => setOpen(true)}
        className={classNames(
          'w-full whitespace-nowrap relative flex justify-between items-center rounded-2xl border-0 shadow-xs px-6 py-4 text-mini text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none',
          `bg-${buttonBg}`
        )}
      >
        All Products
        <ChevronDownIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

export default ProductFilter;
