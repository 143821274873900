import axios from 'axios';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { object, string } from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Label from 'components/Forms/Label';
import { omit, pick } from 'lodash';
import {
  setupReducer,
  initialSetupState,
} from '../onboarding/accountSubscriptionReducer';

import { LUMPSUM, ONE_TIME } from 'utils/constants';
import Button from 'components/Button';
import useBraintreeUtils from 'hooks/useBraintreeUtils';
import useAlert from 'hooks/useAlert';
import { useHistory, useLocation } from 'react-router-dom';
import useBilling from 'hooks/useBilling';
import useCountry from 'hooks/useCountry';
import PaymentForm from 'components/PaymentForm';
import { currencyFormatter } from 'utils/formatters';
import classNames from 'utils/classNames';
import { ConfirmationModal, Modal } from 'components';
import ModalHeader from 'components/ModalHeader';
import Loading from 'components/Loading';

const Payment = ({
  email,
  hostedpageDetails,
  agencyClient,
  salesClientId,
  addons,
  service,
  paid,
  disabled,
}) => {
  const { alertSuccess, alertError } = useAlert();
  const { taxes, getTaxes } = useBilling();
  const { countries } = useCountry();
  const user = agencyClient?.defaultContact ?? null;

  const { showTokenizeError } = useBraintreeUtils();
  let addonsValue = hostedpageDetails?.addons ? hostedpageDetails.addons : [];
  const [isLoading, setIsLoading] = useState(false);
  const paymentFormRef = useRef();
  const [paymentFormError, setPaymentFormError] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [proceedConfirm, setProceedConfirm] = useState(false);
  const [paymentMethodNonce, setPaymentMethodNonce] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [processingMessages, setProcessingMessages] = useState('');
  const [setup, dispatch] = useReducer(setupReducer, initialSetupState);
  const history = useHistory();
  const location = useLocation();

  const isDisabled = useMemo(() => {
    return agencyClient
      ? !!!agencyClient.account?.acknowledgement?.signedDate ||
          agencyClient.status === 'subscribed' ||
          agencyClient.status === 'ACTIVE'
      : true;
  }, [agencyClient]);

  useEffect(() => {
    if (!location.pathname.includes('view') && paid) setIsOpenLogin(true);
  }, [paid]);

  useEffect(() => {
    if (!agencyClient) return;
    const {
      hostedpageDetails,
      agencyClientId,
      address2,
      city,
      state,
      zip,
      country,
      contractSigned,
    } = agencyClient;

    dispatch({
      type: 'updateData',
      data: {
        data: hostedpageDetails,
        zohoCustomer: null,
        isMigrate: agencyClient.ClientMigration
          ? agencyClient.ClientMigration.id
          : 0,
        agencyClientId,
        billingAddress: {
          address: address2,
          city,
          state,
          zip,
          country,
        },
        account: agencyClient.account,
        contractSigned,
      },
    });
  }, [agencyClient]);

  const [initialValues, setInitialValues] = useState({
    email,
    firstName: '',
    lastName: '',
    company: '',
    address: '',
    city: '',
    country: '',
    province: '',
    postalCode: '',
  });

  const validationSchema = object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email().required('Required'),
    address: string().required('Required'),
    city: string().required('Required'),
    country: string().required('Required'),
    province: string().required('Required'),
    postalCode: string().required('Required'),
  });

  useEffect(() => {
    if (!taxes) {
      getTaxes();
    }
  }, [taxes]);

  useEffect(() => {
    if (user && setup && taxes) {
      const setAddressValue = (key) => {
        if (setup.zohoCustomer && setup.zohoCustomer.billing_address) {
          return setup.zohoCustomer.billing_address[key];
        }

        if (setup.billingAddress && setup.billingAddress[key]) {
          return setup.billingAddress[key];
        }

        return '';
      };

      setInitialValues({
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        email: user ? user.email : '',
        company: setup.hostedPageDetails
          ? setup.hostedPageDetails.customer_name
          : '',
        address: setAddressValue('address'),
        city: setAddressValue('city'),
        country: setAddressValue('country'),
        province: setAddressValue('state'),
        postalCode: setAddressValue('zip'),
      });

      if (setAddressValue('country') !== '') {
        dispatch({
          type: 'updateInitialAddress',
          country: countries.find(
            ({ name }) => name === setAddressValue('country')
          ),
          province: setAddressValue('state'),
          taxRates: taxes,
        });
      }
    }
  }, [user, setup.hostedPageDetails, setup.billingAddress, taxes]);

  useEffect(() => {
    if (agencyClient?.accountId && !location.pathname.includes('view')) {
      const events = new EventSource(
        `${process.env.REACT_APP_API_BASE_URL}/agency/liveupdates/proposal?id=${agencyClient.accountId}`
      );

      events.addEventListener(`ppy-${agencyClient.accountId}`, ({ data }) => {
        const status = JSON.parse(data);
        //console.log(status, status === 'payment-processing');
        //let msgs = [...processingMessages];

        switch (status) {
          case 'payment-processing':
            setProcessingMessages('Connecting to payment gateway');
            break;
          case 'payment-processed':
            setProcessingMessages('Confirming payment');
            break;
          case 'payment-confirmed':
            setProcessingMessages(
              'Payment confirmed. The page will automatically reload'
            );
            break;
        }
      });

      events.onerror = (e) => {
        console.log('An error occurred while attempting to connect.');
      };

      return () => events.close();
    }
  }, [agencyClient?.accountId]);

  const pricingData = [
    {
      title: service,
      subTitle:
        hostedpageDetails.paymentOption === LUMPSUM ||
        hostedpageDetails.paymentOption === ONE_TIME
          ? 'Service Fee'
          : 'Monthly Retainer',
      currencyLabel: 'USD',
      currency: '$',
      price: hostedpageDetails?.price,
      dateType:
        hostedpageDetails.paymentOption === LUMPSUM
          ? ` /${hostedpageDetails.interval}`
          : hostedpageDetails.paymentOption === ONE_TIME
          ? ''
          : ' / month',
    },
    ...addonsValue.map((el, i) => {
      return {
        title: addons
          ? addons.find((addon) => addon.addon_code === el.addon_code).name
          : '',
        subTitle: 'Add-on Service',
        currencyLabel: 'USD',
        currency: '$',
        price: el.price * el.quantity,
        dateType: el.type === 'one_time' ? 'one time' : 'month',
      };
    }),
  ].filter((el) => el.title);

  const onSubmit = async (values) => {
    setIsLoading(true);

    let nonce = null;

    await paymentFormRef.current
      .tokenize()
      .then((response) => {
        setPaymentMethodNonce(response.nonce);
        nonce = response.nonce;
      })
      .catch((error) => {
        showTokenizeError(error);
      });

    if (nonce && paymentFormRef.current.deviceData) {
      setProceedConfirm(true);
      setProcessingMessages('');
    }

    setIsLoading(false);
  };

  const pay = async (values) => {
    setPaymentProcessing(true);
    setProcessingMessages('Initializing');

    try {
      await axios.post(
        `/agency/sales/clients/${salesClientId}/proposal/payment`,
        {
          setup: omit(setup, [
            'hostedPageDetails',
            'provinces',
            'selectedCountry',
            'billingAddress',
          ]),
          selectedCountry: pick(setup.selectedCountry, [
            'isoCode',
            'name',
            'currency',
          ]),
          ...values,
          paymentMethodNonce,
          company: agencyClient.client,
          deviceData: paymentFormRef.current.deviceData,
          accountId: agencyClient.accountId,
        }
      );
      alertSuccess('Payment confirmed');
      window.location.reload();
    } catch (error) {
      setProcessingMessages(
        `${error.response.data.message}\n Something went wrong. Please reload the page and try again or contact the administrator for assistance`
      );
      alertError('Something went wrong', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="payment"
      className={classNames(
        'p-6 lg:px-10 lg:pt-14 lg:pb-40 section',
        disabled ? 'pointer-events-none opacity-30' : ''
      )}
    >
      <h2 className="text-32 tracking-1 font-bold text-primary leading-1.2 font-inter mb-5">
        Payment
      </h2>

      {paid ? (
        <>
          Thanks for your payment.
          <ConfirmationModal
            title="Payment Confirmed"
            content={`An email will be sent containing your initial login credentials`}
            open={isOpenLogin}
            setOpen={setIsOpenLogin}
            onOkClick={() => history.replace('/sign-in')}
            onCancelClick={() => setIsOpenLogin(false)}
            okText="Sign In"
            cancelText="Stay"
          />
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleChange, values, setFieldValue, handleSubmit }) => (
            <Form>
              <div className="grid grid-cols-2 gap-8">
                <div className="space-y-10">
                  <div id="contact-details">
                    <h3 className="font-inter font-bold tracking-3/4 text-grayscale-900 leading-1.2 text-xl mb-2">
                      Contact Details
                    </h3>

                    <div>
                      <Label htmlFor="email">Email</Label>
                      <Field
                        name="email"
                        placeholder=""
                        className="form-input text-sm disabled-white"
                        type="email"
                        disabled={true}
                      />

                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>

                  <div id="payment-information">
                    <h3 className="font-inter font-bold tracking-3/4 text-grayscale-900 leading-1.2 text-xl mb-2">
                      Payment Information
                    </h3>

                    <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-2">
                      <div>
                        <Label htmlFor="firstName">First Name</Label>
                        <Field
                          name="firstName"
                          placeholder=""
                          className="form-input text-sm bg-grayscale-400"
                          type="text"
                          disabled={isDisabled}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>

                      <div>
                        <Label htmlFor="lastName">Last Name</Label>
                        <Field
                          name="lastName"
                          placeholder=""
                          className="form-input text-sm bg-grayscale-400"
                          type="text"
                          disabled={isDisabled}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </div>

                    <PaymentForm
                      ref={paymentFormRef}
                      setError={setPaymentFormError}
                    />
                  </div>

                  <div id="billing-address">
                    <h3 className="font-inter font-bold tracking-3/4 text-grayscale-900 leading-1.2 text-xl">
                      Billing Address
                    </h3>

                    <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
                      <div className="sm:col-span-2">
                        <Label htmlFor="address">Address</Label>
                        <Field
                          name="address"
                          placeholder=""
                          className="form-input text-sm disabled-white"
                          type="text"
                          disabled={isDisabled}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div>
                        <Label htmlFor="city">City</Label>
                        <Field
                          name="city"
                          placeholder=""
                          className="form-input text-sm disabled-white"
                          type="text"
                          disabled={isDisabled}
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div>
                        <Label htmlFor="city">Country</Label>
                        <Field
                          name="country"
                          as="select"
                          className="form-select disabled-white"
                          disabled={isDisabled}
                          onChange={(e) => {
                            dispatch({
                              type: 'updateCountry',
                              country: countries.find(
                                ({ name }) => name === e.target.value
                              ),
                            });
                            setFieldValue('province', '');
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Country</option>
                          {countries &&
                            countries.map(({ name }, i) => (
                              <option value={name} key={i}>
                                {name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div>
                        <Label htmlFor="province">State/Province/Region</Label>
                        <Field
                          name="province"
                          as="select"
                          className="form-select disabled-white"
                          disabled={isDisabled}
                          onChange={(e) => {
                            dispatch({
                              type: 'updateProvince',
                              province: setup.provinces.find(
                                ({ name }) => name === e.target.value
                              ),
                              taxRates: taxes,
                            });
                            handleChange(e);
                          }}
                        >
                          <option value="">Select State/Provice/Region</option>
                          {setup.provinces &&
                            setup.provinces.map(({ name }, i) => (
                              <option value={name} key={i}>
                                {name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="province"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div>
                        <Label htmlFor="postalCode">ZIP/Postal Code</Label>
                        <Field
                          name="postalCode"
                          placeholder=""
                          className="form-input text-sm disabled-white"
                          type="text"
                          disabled={isDisabled}
                        />
                        <ErrorMessage
                          name="postalCode"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="border-b border-gryscale-500 mb-5">
                    {pricingData.map((el, i) => {
                      return (
                        <div key={i} className="py-2 mb-5">
                          <div className="flex justify-between">
                            <div>
                              <div className="font-inter font-bold text-base text-grayscale-800 leading-1.2 tracking-3/4">
                                {el.title}
                              </div>
                              <div>
                                <p className="text-13 text-grayscale-700 font-normal leading-1.5">
                                  {el.subTitle}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="flex justify-start items-center text-13 font-normal leading-1.5 text-grayscale-800">
                                {el.currencyLabel}
                                <span className="pr-1 pl-2 font-bold text-base tracking-3/4 leading-1.2">
                                  {el.price}
                                </span>
                                {el.dateType && <> {el.dateType}</>}
                              </div>
                            </div>
                          </div>

                          {el.label && (
                            <div className="flex justify-between px-4">
                              {el.label}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex justify-between my-5">
                    <p className="text-lg font-bold leading-1.2 tracking-3/4 text-grayscale-800">
                      Sub Total
                    </p>

                    <p className="flex items-start text-lg font-bold leading-1.2 tracking-3/4 text-grayscale-800 space-x-1">
                      <span className="text-13 leading-1.5 text-grayscale-800">
                        USD
                      </span>
                      <span>{setup.subTotal}</span>
                    </p>
                  </div>

                  <div className="flex justify-between my-5">
                    <p className="text-lg font-bold leading-1.2 tracking-3/4 text-grayscale-800">
                      Tax ({setup.selectedTaxRate * 100} %)
                    </p>

                    <p className="flex items-start text-lg font-bold leading-1.2 tracking-3/4 text-grayscale-800 space-x-1">
                      <span className="text-13 leading-1.5 text-grayscale-800">
                        USD
                      </span>
                      <span>
                        {' '}
                        {parseInt((setup.taxAmount * 100).toFixed(2)) / 100}
                      </span>
                    </p>
                  </div>

                  <div className="flex justify-between mb-12 mt-8 pt-8 border-t">
                    <p className="text-xl font-bold leading-1.2 tracking-3/4 text-grayscale-900">
                      Amount Due
                    </p>

                    <p className="flex items-start text-xl font-bold leading-1.2 tracking-3/4 text-grayscale-900 space-x-1">
                      <span className="text-13 leading-1.5 text-grayscale-800">
                        USD
                      </span>
                      <span>{setup.total.toFixed(2)}</span>
                    </p>
                  </div>

                  <div className="my-16">
                    <p className="leading-1.5 text-grayscale-800">
                      All prices are listed in US dollars.
                    </p>

                    <p className="leading-1.5 text-grayscale-800 mt-5">
                      {hostedpageDetails.paymentOption !== ONE_TIME && (
                        <>
                          Your card will be charged every month and billing will
                          begin on your start date
                        </>
                      )}
                    </p>
                  </div>

                  <div className="grid grid-cols-2 divide-x my-8">
                    <div className="flex items-center justify-center space-x-3">
                      <svg
                        width="19"
                        height="22"
                        viewBox="0 0 19 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.46986 0.166016L0.25 4.10541V10.0145C0.25 15.4804 4.18381 20.5918 9.46986 21.8327C14.7559 20.5918 18.6897 15.4804 18.6897 10.0145V4.10541L9.46986 0.166016ZM16.6409 10.0145C16.6409 14.466 13.5881 18.5728 9.46986 19.794C5.35166 18.5728 2.29886 14.466 2.29886 10.0145V5.38571L9.46986 2.32283L16.6409 5.38571V10.0145ZM4.76773 10.5956L3.32329 11.9842L7.421 15.9236L15.6164 8.0448L14.172 6.64632L7.421 13.1365L4.76773 10.5956Z"
                          fill="#949494"
                        />
                      </svg>
                      <span className="text-grayscale-700 leading-1.5">
                        Secured checkout
                      </span>
                    </div>
                    <div className="flex justify-center space-x-3">
                      <a
                        href={`https://www.braintreegateway.com/merchants/${process.env.REACT_APP_BT_MERCHANT_ID}/verified`}
                        target="_blank"
                      >
                        <img
                          src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
                          width="280px"
                          height="44px"
                          border="0"
                        />
                      </a>
                    </div>
                  </div>

                  <Button
                    px={3}
                    py={3}
                    bgColor="success"
                    hoverColor="success-dark"
                    defaultClass="w-full text-center tracking-2 disabled:opacity-50 inline-flex items-center justify-center"
                    roundedSize="full"
                    fontWeight="bold"
                    textColor="grayscale-300"
                    showLoading={true}
                    loading={isLoading}
                    type="submit"
                    disabled={paymentFormError || isDisabled}
                    //onClick={() => setProceedConfirm(true)}
                  >
                    Pay {currencyFormatter(setup.total)}
                  </Button>
                </div>
              </div>
              <Modal
                open={proceedConfirm}
                setOpen={setProceedConfirm}
                as={'div'}
                align="top"
                persistent
              >
                <div className="inline-block w-full max-w-sm my-64 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                  <ModalHeader
                    title="Authorize Payment"
                    setOpen={setProceedConfirm}
                    showCloseButton={false}
                    border=""
                    fontSize="text-lg"
                    py="py-6"
                  />
                  <div className="px-5 pb-5 text-base">
                    {processingMessages !== '' ? (
                      <>
                        <div className="font-inter uppercase text-sm mb-4 bg-yellow-100 p-4 rounded-md text-yellow-700">
                          Please do not close this window while payment is
                          processing..
                        </div>
                        <div className="flex flex-col items-center text-center space-y-4">
                          <span className="whitespace-pre-wrap">
                            {processingMessages}
                          </span>
                          {paymentProcessing && <Loading />}
                        </div>
                      </>
                    ) : (
                      <p className="leading-1.5 text-grayscale-800 ">
                        {hostedpageDetails.paymentOption === ONE_TIME ? (
                          <>One-Time payment</>
                        ) : (
                          <>
                            Your card will be charged every month and billing
                            will begin on your start date
                          </>
                        )}
                        <br />
                        <br />
                        Total amount of {currencyFormatter(setup.total)} will be
                        charged. Proceed?
                      </p>
                    )}
                  </div>

                  {!paymentProcessing && (
                    <div className="p-5 space-x-4 text-center">
                      <>
                        <Button
                          classes="border-0 font-bold tracking-widest"
                          bgColor="green-500"
                          hoverColor="green-400"
                          roundedSize="full"
                          textColor="white"
                          px={12}
                          py={1.5}
                          shadow=""
                          type="button"
                          onClick={() => pay(values)}
                        >
                          YES
                        </Button>
                        <Button
                          classes="border-0 font-bold tracking-widest"
                          bgColor="gray-200"
                          hoverColor="gray-50"
                          roundedSize="full"
                          textColor="black"
                          px={12}
                          py={1.5}
                          shadow=""
                          type="gray-700"
                          onClick={() => setProceedConfirm(false)}
                        >
                          NO
                        </Button>
                      </>
                    </div>
                  )}
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Payment;
