import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTaskBoardItemsAsync = createAsyncThunk(
  'tasks.v2/fetchTaskBoardItems',
  async ({ key, options = [] }) => {
    const groups = await Promise.all(
      options.map(async ({ params, title }) => {
        const response = await axios.get('/v2/tasks/items/list', { params });

        return { data: response.data.data, params, title, isLoading: false };
      })
    );

    return { key, groups };
  }
);

export const fetchTaskGroupItemsAsync = createAsyncThunk(
  'tasks.v2/fetchTasGroupItems',
  async ({ id, params }) => {
    const response = await axios.get('/v2/tasks/items/list', { params });

    return { data: response.data.data, params, id, isLoading: false };
  }
);

export const tasksSlice = createSlice({
  name: 'tasks.v2',
  initialState: {
    board: { key: 'status', groups: [] },
  },
  reducers: {
    setTaskBoard: (state, action) => {
      state.board = action.payload;
    },
  },
  extraReducers: {
    [fetchTaskBoardItemsAsync.pending]: (state) => {
      state.board = {
        ...state.board,
        groups: state.board.groups.map((group) => ({
          ...group,
          isLoading: true,
        })),
      };
    },
    [fetchTaskBoardItemsAsync.fulfilled]: (state, { payload }) => {
      state.board = payload;
    },
    [fetchTaskGroupItemsAsync.pending]: (state, { meta }) => {
      state.board = {
        ...state.board,
        groups: state.board.groups.map((group, idx) => ({
          ...group,
          isLoading: idx === meta.arg.id,
        })),
      };
    },
    [fetchTaskGroupItemsAsync.fulfilled]: (state, { meta, payload }) => {
      state.board = {
        ...state.board,
        groups: state.board.groups.map((group, idx) => {
          return idx === meta.arg.id ? { ...group, ...payload } : { ...group };
        }),
      };
    },
  },
});

export const { setTaskBoard } = tasksSlice.actions;

export default tasksSlice.reducer;
