import React from 'react';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import PrivateRoute from './PrivateRoute';
import Onboarding from 'features/onboarding/Onboarding';
import Plan from 'features/client/Plan';
import Authorization from 'features/client/authorization/Authorization';
import Mockup from 'features/client/mockup/Mockup';
import UpdateCardSuccess from 'features/clients/UpdateCardSucess';
import UpdateCard from 'features/clients/UpdateCard';
import { Route } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import Proposal from 'features/proposal/Proposal';
import ClientSurvey from 'features/clients/Survey/Form';
import ReportsGenerator from 'features/reportsGenerator/ReportsGenerator';
import AccountSubscription from 'features/onboarding/AccountSubscription';
import Checkout from 'features/onboarding/Checkout';
import TaskRedirect from 'features/tasks/TaskRedirect';
import AccountConfirmation from 'features/onboarding/AccountConfirmation';
import AccountSubscriptionBraintree from 'features/onboarding/AccountSubscriptionBraintree';
import Complaints from 'features/complaint/Complaints';
import ProposalZoho from 'features/proposal/ProposalZoho';
import Invoices from 'features/client/billing/Invoices';
import AdditionalServices from 'features/client/billing/AdditionalServices';
import PaymentOnlyProposal from 'features/proposal/skipproposal/PaymentOnlyProposal';
import Workspace from 'pages/Workspace';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import UserSettings from 'pages/UserSettings';
import TaskDetails from 'pages/task/[taskItemId]';
import PrivacyPolicy from 'features/static/PrivacyPolicy';
import Eula from 'features/static/Eula';

const AppRoutes = [
  <PrivateRoute
    path="/user-settings"
    component={UserSettings}
    layout={DashboardLayout}
    access={['agency', 'application']}
  />,

  <PrivateRoute
    path="/home"
    component={Workspace}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/task/:taskItemId"
    component={TaskDetails}
    layout={AgencyLayout}
  />,

  <PrivateRoute
    path="/tasks/:id"
    component={TaskRedirect}
    layout={DashboardLayout}
    access={['agency', 'application']}
  />,

  <PrivateRoute
    path="/subscription"
    component={AccountSubscription}
    access={['application']}
    exact
  />,
  <PrivateRoute
    path="/subscription/bt"
    component={AccountSubscriptionBraintree}
    access={['application']}
    exact
  />,
  <PrivateRoute
    path="/checkout/:accountId"
    component={Checkout}
    access={['application']}
    exact
  />,
  <PrivateRoute
    path="/subscription/confirmation"
    component={AccountConfirmation}
    access={['application']}
    exact
  />,
  <PrivateRoute
    path="/onboarding"
    component={Onboarding}
    access={['application']}
    exact
  />,

  <PrivateRoute
    path="/plan"
    restricted={true}
    component={Plan}
    module="plan"
    access={['application']}
    layout={DashboardLayout}
  />,

  <PrivateRoute
    path="/invoice-history"
    restricted={true}
    component={Invoices}
    module="plan"
    access={['application']}
    layout={DashboardLayout}
  />,

  <PrivateRoute
    path="/additional-services"
    restricted={true}
    component={AdditionalServices}
    module="plan"
    access={['application']}
    layout={DashboardLayout}
  />,

  <PrivateRoute
    path="/complaints"
    restricted={true}
    component={Complaints}
    access={['application']}
    layout={DashboardLayout}
  />,

  <PrivateRoute
    path="/callback/amazon"
    component={Authorization}
    module="authorization"
    access={['application']}
  />,

  <PrivateRoute
    path="/mockup"
    component={Mockup}
    module="mockup"
    access={['application']}
  />,
  <Route path="/update-card/permit/:token" component={UpdateCard} exact />,
  <Route path="/update-card-success" component={UpdateCardSuccess} exact />,
  <PublicRoute path="/proposal/:token" component={Proposal} exact />,
  <PublicRoute path="/proposal/z/:token" component={ProposalZoho} exact />,
  <Route path="/survey/:token" component={ClientSurvey} exact />,
  <PrivateRoute
    path="/proposal/view/:token"
    component={Proposal}
    exact
    access={['agency', 'application']}
  />,
  <PublicRoute
    path="/proposal/skip-content/:token"
    component={PaymentOnlyProposal}
    exact
  />,

  <Route path="/reports-generator" component={ReportsGenerator} />,
  <Route path="/privacy-policy" component={PrivacyPolicy} exact />,
  <Route path="/eula" component={Eula} exact />,
];
export default AppRoutes;
