import axios from 'axios';
import { useRoles } from 'features/roles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
} from 'recharts';
import { omit } from 'lodash';

const colors = [
  '#002F5D',
  '#1879D8',
  '#82B0DE',
  '#00966D',
  '#00BA88',
  '#3BD7AD',
  '#BFA654',
  '#F9DC7D',
  '#FFEDB2',
];

const options = [
  {
    title: 'Active Partners',
    userKey: 'totalAssignedActiveClients',
    summary: {
      key: 'totalClients',
      params: {
        attributes: ['totalClients'],
      },
    },
  },
  {
    title: 'One-Time Partners',
    userKey: 'totalAssignedActiveOneTimeClients',
    summary: {
      key: 'totalClients',
      params: {
        attributes: ['totalClients'],
        subscriptionPaymentOption: 'One Time',
      },
    },
  },
  {
    title: 'New Partners',
    userKey: 'totalAssignedNewClients',
    summary: {
      key: 'newClients',
      params: {
        attributes: ['newClients'],
      },
    },
  },
  {
    title: 'Inactive Partners',
    userKey: 'totalAssignedInactiveClients',
    summary: {
      key: 'inactiveClients',
      params: {
        attributes: ['inactiveClients'],
      },
    },
  },
];

const renderCustomizedLabel = (props) => {
  return props.value ? (
    <text
      {...omit(props, ['content', 'textBreakAll', 'parentViewBox'])}
      textAnchor="middle"
      className="recharts-text recharts-label"
    >
      <tspan
        {...omit(props, ['content', 'textBreakAll', 'parentViewBox'])}
        textAnchor="middle"
        x={props.x + props.width / 2}
      >
        {props.value}
      </tspan>
    </text>
  ) : null;
};

const TeamBreakdown = ({ year, supervisorId = '' }) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState({ summary: [], records: [] });
  const [selectedOption, setSelectedOption] = useState('Active Partners');

  const { BRAND_STRATEGIST, PROJECT_MANAGER } = useRoles();

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

    const { summary } = options.find((opt) => opt.title === selectedOption);

    const fetchData = async () => {
      setLoading(true);

      let params = { startDate, endDate, ...summary.params };

      if (supervisorId) {
        params['assignees.userId'] = supervisorId;
      }

      const response = await axios.get(`/v2/agency/clients/analytics/summary`, {
        params,
      });

      const overall = response.data.data[summary.key];

      if (isSubscribed) {
        setTotal(overall);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year, selectedOption, supervisorId]);

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

    const { summary } = options.find((opt) => opt.title === selectedOption);

    const fetchData = async () => {
      setLoading(true);

      let recordsParams = { startDate, endDate, ...summary.params };

      if (supervisorId) {
        recordsParams['assignees.userId'] = supervisorId;
      }

      const recordsResponse = await axios.get(
        `/v2/agency/clients/analytics/records`,
        { params: recordsParams }
      );

      let records = recordsResponse.data.data.map((record) => ({
        ...record,
        total: record[summary.key],
      }));

      let userParams = {
        endDate,
        startDate,
        'role.code': PROJECT_MANAGER.value,
        attributes: ['userId', 'firstName', 'lastName'],
      };

      if (supervisorId) {
        userParams['supervisorId'] = supervisorId;
        userParams['role.code'] = BRAND_STRATEGIST.value;
      }

      const response = await axios.get('/v2/users', {
        params: userParams,
      });

      const users = await Promise.all(
        response.data.data.map(async (user, idx) => {
          const userRecords = await axios.get(
            `/v2/agency/clients/analytics/records`,
            {
              params: {
                startDate,
                endDate,
                'assignees.userId': user.userId,
                ...summary.params,
              },
            }
          );

          userRecords.data.data.forEach((record) => {
            const idx = records.findIndex(
              (r) => r.dateRange === record.dateRange
            );

            records[idx][`${user.firstName} ${user.lastName}`] =
              record[summary.key];
          });

          return {
            ...user,
            color: colors[idx],
            name: `${user.firstName} ${user.lastName}`,
          };
        })
      );

      if (isSubscribed) {
        setLoading(false);
        console.log(records);
        setTeams({ records, summary: users });
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year, selectedOption, supervisorId]);

  return (
    <div className="bg-white p-6 lg:col-span-2">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-base font-inter tracking-3/4 leading-1.2 font-bold text-grayscale-900">
            Total Partner Count
          </p>
          <p className="mt-2 text-25 font-inter leading-1.2 tracking-3/4 text-grayscale-900">
            {total}
          </p>
        </div>

        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          className="text-13 text-grayscale-800 bg-grayscale-400 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          {options.map((option) => (
            <option key={option.title} value={option.title}>
              {option.title}
            </option>
          ))}
        </select>
      </div>

      <div className="h-72 mt-8">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-5 h-full space-x-8">
            <div className="col-span-4 w-full">
              <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
                <BarChart data={teams.records} margin={{ top: 20 }}>
                  <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
                  <XAxis
                    dataKey="dateRange"
                    style={{ fontSize: '11px', color: '#202020' }}
                    tickFormatter={(dateRange) =>
                      dateRange
                        ? moment(dateRange.split('--')[0]).format('MMM')
                        : dateRange
                    }
                  />

                  <YAxis
                    style={{ fontSize: '11px' }}
                    axisLine={{ stroke: '#ECECEC' }}
                  />

                  {teams.summary.map((team, id) => (
                    <Bar
                      key={team.userId}
                      dataKey={team.name}
                      stackId="main"
                      name={team.name}
                      barSize={50}
                      fill={team.color}
                    >
                      <LabelList
                        id={`${team.name}=${id}`}
                        stackId="main"
                        dataKey={team.name}
                        position="middle"
                        fill="#202020"
                        fontSize="13px"
                        content={(props) =>
                          renderCustomizedLabel({
                            ...props,
                            y: props.y + props.height / 2,
                          })
                        }
                      />

                      {id === teams.summary.length - 1 && (
                        <LabelList
                          dataKey="total"
                          position="top"
                          fill="#202020"
                          fontSize="16px"
                          fontWeight="700"
                          content={(props) =>
                            renderCustomizedLabel({
                              ...props,
                              dy: -10,
                            })
                          }
                        />
                      )}
                    </Bar>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>

            <ul className="flex flex-col justify-center">
              {teams.summary.map((team) => (
                <li
                  key={team.userId}
                  className="flex items-center space-x-2 mb-2"
                >
                  <div
                    className="w-2 h-2"
                    style={{ backgroundColor: team.color }}
                  />
                  <p className="w-full text-grayscale-900 text-11 leading-1.5 flex items-center justify-between">
                    {team.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamBreakdown;
