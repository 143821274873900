import Badge from 'components/Badge';
import classnames from 'classnames';
import useCreditNotes from '../useCreditNotes';
import { strDashToSpace } from 'utils/formatters';

const CreditNoteStatus = ({ status }) => {
  const { STATUS } = useCreditNotes();

  return (
    <Badge
      color={classnames({
        gray: status === STATUS.draft,
        orange: status === STATUS.awaitingApproval,
        blue: status === STATUS.approved,
        green: status === STATUS.applied,
        purple: status === STATUS.appliedPartial,
        red: status === STATUS.cancelled || status === STATUS.denied,
      })}
      classes="capitalize text-center w-full justify-center"
      rounded="full"
      padding="py-2"
      textSize="sm"
      fontFamily=""
    >
      {strDashToSpace(status)}
    </Badge>
  );
};
export default CreditNoteStatus;
