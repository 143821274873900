import Button from 'components/Button';
import Label from 'components/Forms/Label';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { currencyFormatter } from 'utils/formatters';
import { TrashIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import AddonsListSelect from 'components/AddonsListSelect';

const AddonModal = ({ open, setOpen, values, onSubmit }) => {
  const { addons } = useSelector((state) => state.zoho);
  const { setFieldValue } = useFormikContext();

  const addonObj = {
    name: '',
    addon_description: '',
    addon_code: '',
    price: 0,
    quantity: 1,
    type: 'one_time',
    action: 'add',
  };

  const onAddAddon = (setFieldValue, values) => {
    let newAddonsValue = [...values.addons];
    if (values.addonTemp.addon_code) {
      if (values.addonTemp.action === 'update') {
        let valuesTemp = { ...values.addonTemp };
        const { index } = valuesTemp;
        delete valuesTemp.index;
        delete valuesTemp.action;
        newAddonsValue[index] = valuesTemp;
      } else {
        let valuesTemp = { ...values.addonTemp };
        delete valuesTemp.index;
        delete valuesTemp.action;
        newAddonsValue.push(valuesTemp);
      }
      setFieldValue('addons', newAddonsValue);
      setFieldValue('addonTemp', addonObj);
      onSubmit({ ...values, addons: newAddonsValue });
      setOpen(false);
    }
  };
  const onRemoveAddon = (setFieldValue, values) => {
    let newAddonsValue = [...values.addons];
    let valuesTemp = { ...values.addonTemp };
    const { index } = valuesTemp;

    if (index > -1) {
      newAddonsValue.splice(index, 1);
    }

    setFieldValue('addons', newAddonsValue);
    setFieldValue('addonTemp', addonObj);
    onSubmit({ ...values, addons: newAddonsValue });
    setOpen(false);
  };

  const onSelectAddon = (c) => {
    setFieldValue('addonTemp.addon_code', c.addon_code);
    setFieldValue('addonTemp.price', c.price_brackets[0].price);
    setFieldValue('addonTemp.type', c.type);
    setFieldValue('addonTemp.name', c.name);
    setFieldValue('addonTemp.addon_description', c.addon_description);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4 text-2xl">
              <span>Create Add-ons</span>
            </div>
          }
          rightContent={
            values?.addonTemp?.action === 'add' ? (
              ''
            ) : (
              <button
                className="relative group mr-4"
                onClick={() => onRemoveAddon(setFieldValue, values)}
              >
                <TrashIcon className="w-5 h-5 transform  text-gray-400 hover:text-red-500" />
              </button>
            )
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <div className="grid grid-cols-2 gap-4 py-4 px-10">
          <div className="col-span-2 text-sm">
            <Label>Service</Label>
            {/* <Field
              name={`addonTemp.addon_code`}
              as="select"
              className="form-select disabled-white"
            >
              <option value="">Select One</option>
              {addons?.map((addon, i) => (
                <option key={i} value={addon.addon_code}>
                  {addon.name}
                </option>
              ))}
            </Field> */}
            <AddonsListSelect
              addons={addons}
              keyField="addon_code"
              onChange={onSelectAddon}
              selectedId={values.addonTemp.addon_code}
              width="w-full"
              containerWidth="w-full"
            />
            <ErrorMessage
              name={`addonTemp.addon_code`}
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          {/* <div className="col-span-12 text-sm">
            <Label htmlFor="type">Type</Label>
            <div className="pl-3 py-2 capitalize">
              {values?.addonTemp?.type}
            </div>
          </div> */}
          <div className="col-span-1 text-sm">
            <Label>Quantity</Label>
            <Field
              name={`addonTemp.quantity`}
              className="form-input disabled-white"
              type="number"
              step="1"
            />
            <ErrorMessage
              name={`addonTemp.quantity`}
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          <div className="col-span-1 text-sm">
            <Label>Price</Label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">$</span>
              </div>
              <Field
                name={`addonTemp.price`}
                className="form-input  pl-7 r-12"
                type="number"
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <Label htmlFor="currency_code" classes="sr-only">
                  Currency
                </Label>
                <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                  {values.currency_code}
                </span>
              </div>
            </div>
            <ErrorMessage
              name={`addonTemp.price`}
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          <div className="col-span-2 text-sm items-center flex flex-row justify-between">
            <Label>Total</Label>
            <span className="py-2 text-green-500 font-bold ">
              {currencyFormatter(
                values.addonTemp.price * values.addonTemp.quantity,
                values.currency_code
              )}
            </span>
          </div>
          <div className="col-span-2">
            <Field
              name={`addonTemp.addon_description`}
              as="textarea"
              rows={2}
              className="form-input"
              maxLength={2000}
            />
            <div className="flex justify-between">
              <div className="flex">
                &nbsp;
                <ErrorMessage
                  name={`addonTemp.addon_description`}
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div
                className={`text-right italic text-xs mt-1  ${
                  values.addonTemp.addon_description?.length === 2000
                    ? 'text-red-700'
                    : ''
                }`}
              >
                {values.addonTemp.addon_description?.length}/
                <span className="font-medium">2000</span> characters
              </div>
            </div>
          </div>
        </div>

        <div className="m-8 flex justify-between items-center">
          <div className="space-x-4">
            <button
              className="border-white font-bold text-secondary-light tracking-widest text-sm mr-2"
              onClick={() => {
                // setAddonModalPayload(values.addons[index]);
                setFieldValue('addonTemp', addonObj);
                setOpen(false);
              }}
            >
              Cancel
            </button>

            <Button
              classes="border-0 font-bold tracking-widest text-sm"
              bgColor="blue-900"
              hoverColor="blue-400"
              roundedSize="2xl"
              textColor="white"
              px={12}
              py={1.5}
              shadow=""
              onClick={() => onAddAddon(setFieldValue, values)}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AddonModal;
