import {
  Bar,
  XAxis,
  YAxis,
  Brush,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import Moment from 'react-moment';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'features/datePicker/DatePicker';
import ChartToolTip from 'components/Charts/ChartTooltip';

const ClientCountPerService = () => {
  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { startDate, endDate } = dateRange;
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(
        '/v2/agency/clients/analytics/service/summary',
        {
          params: { startDate, endDate },
        }
      );

      setRecords(response.data.data);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [dateRange]);

  return (
    <div className="bg-white p-4 mt-8">
      <div className="py-3 flex items-center justify-between">
        <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
          Client Count per service
        </p>

        <DatePicker
          position="right"
          showLabel={false}
          buttonBg="grayscale-400"
        />
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="service"
                style={{ fontSize: '11px', color: '#202020' }}
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
              />
              <Tooltip
                content={
                  <ChartToolTip
                    Title={() => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        <Moment format="ll" className="mr-2">
                          {startDate}
                        </Moment>
                        -
                        <Moment format="ll" className="mr-2 ml-2">
                          {endDate}
                        </Moment>
                      </p>
                    )}
                    CustomItemTitle={({ item }) => {
                      return (
                        <p className="text-13 leading-1.5 text-grayscale-900">
                          {item.payload.service}
                        </p>
                      );
                    }}
                  />
                }
              />

              <Bar
                yAxisId="0"
                stroke="#1879d8"
                fill="#1879d8"
                dataKey={'count'}
                dot={false}
                strokeWidth={3}
              />

              <Brush
                dataKey="service"
                data={records}
                height={15}
                endIndex={5}
                className="text-8"
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ClientCountPerService;
