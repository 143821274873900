import axios from 'axios';
import { useEffect, useState } from 'react';

import { Table } from 'components';
import moment from 'moment';

const History = ({ accountId, marketplace, campaignType }) => {
  const [history, setHistory] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    const payload = {
      ...params,
      accountId,
      marketplace,
      include: ['campaign', 'adGroup', 'user'],
      sort: 'timestamp',
    };

    axios
      .get('/account/advertising/history', { params: payload })
      .then((response) =>
        setHistory({
          ...response.data.data,
          rows: response.data.data.rows.map((r) => {
            return { ...r, id: `${r.entityType}-${r.entityId}-${r.timestamp}` };
          }),
        })
      );
  }, [accountId, marketplace, params]);

  const columns = [
    {
      dataField: 'advCampaignId',
      text: 'Change Level',
      formatter: (cell, row) => {
        const { campaign, adGroup, advAdGroupId, advCampaignId } = row;

        return (
          <div>
            {advAdGroupId && <p>Ad Group: {adGroup?.name || advAdGroupId}</p>}
            {advCampaignId && (
              <p>Campaign: {campaign?.name || advCampaignId}</p>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'user',
      text: 'User',
      formatter: (cell, row) =>
        cell ? `${cell.firstName} ${cell.lastName}` : 'Amazon Console',
    },
    {
      dataField: 'changeType',
      text: 'Change Type',
      formatter: (cell, row) => {
        return (
          <div>
            <p>
              {row.entityType} {cell}
            </p>
            {row.metadata.keyword && <p>{row.metadata.keyword}</p>}
          </div>
        );
      },
    },
    {
      dataField: 'previousValue',
      text: 'From',
    },
    {
      dataField: 'newValue',
      text: 'To',
    },
    {
      dataField: 'timestamp',
      text: 'Date',
      formatter: (cell) =>
        moment.unix(cell / 1000).format('DD MMM YYYY hh:mm a'),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div className="my-4">
      <Table
        keyField={'id'}
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={history}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default History;
