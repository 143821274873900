import { Field, ErrorMessage } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const Reporting = ({ handleChange }) => {
  return (
    <div className="flex flex-col w-full gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <div>
          <Label
            htmlFor="data.type"
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
          >
            Report Type <RequiredAsterisk />
          </Label>
          <Field
            name="data.type"
            as="select"
            className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
            onChange={(e) => handleChange(e)}
          >
            <option value="EOM Report">EOM Report</option>
            <option value="Weekly Report">Weekly Report</option>
            <option value="Escalation Report">Escalation Report</option>
          </Field>
        </div>

        <div>
          <Label
            htmlFor="data.reportLink"
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
            onChange={(e) => handleChange(e)}
          >
            Report Link <RequiredAsterisk />
          </Label>
          <Field
            name="data.reportLink"
            placeholder="Add report link"
            className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
            type="text"
          />
          <ErrorMessage
            name="data.reportLink"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>

        <div>
          <Label
            htmlFor="data.otherInformation"
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
            onChange={(e) => handleChange(e)}
          >
            Other Information <RequiredAsterisk />
          </Label>
          <Field
            name="data.otherInformation"
            placeholder="Add information related to action taken"
            as="textarea"
            rows={3}
            className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          />
          <ErrorMessage
            name="data.otherInformation"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      </div>
    </div>
  );
};

export default Reporting;
