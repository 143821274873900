import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import {
  TrashIcon,
  PlusCircleIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import axios from 'axios';
import Loading from 'components/Loading';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, date, array, boolean } from 'yup';
import FormikErrorNotification from 'components/FormikErrorNotification';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import { joiAlertErrorsStringify } from 'utils/formatters';
import Label from 'components/Forms/Label';
import Button from 'components/Button';
import {
  getOnboardingDetails,
  setOnboardingDetails,
} from '../agencyClientSlice';
import usePermissions from 'hooks/usePermissions';

const Onboarding = () => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const { agencyClientId } = useParams();
  const history = useHistory();

  const servinceAsinObj = {
    asin: '',
    productName: '',
    isListingImages: false,
    isAPlusContent: false,
    isStorefront: false,
    isListingVideo: false,
    isListingCopy: false,
    isAPlusCopy: false,
    isParentASIN: 'false',
    parentAsinUrl: '',
    amazonProductUrl: '',
    productSKU: '',
    competitorAmazonProductUrl1: '',
    competitorAmazonProductUrl2: '',
    topFiveKeywords: '',
    topFiveRestrictedKeywords: '',
    topFiveFeatures: '',
    topFiveBenefits: '',
    targetAudience: '',
    instructionalUse: '',
  };
  const emptyInitialValues = {
    brandName: '',
    amazonStorefront: '',
    website: '',
    facebookUrl: '',
    instagramUrl: '',
    bestSellingAsin: '',
    pastThreeOrganicSales: 0,
    pastThreeMonthsAcos: 0,
    pastThreeMonthsPPCSales: 0,
    targetAcos: 0,
    ppcGoal: '',
    brandColorPalette: '',
    fontGuidelines: '',
    targetAudience: '',
    brandVoiceAndTone: '',
    imageGuidelines: '',
    otherBrandVitalInformation: '',
    serviceAsins: [servinceAsinObj],
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const onboarding = useSelector((state) => state.agencyClient.onboarding);
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const validationSchema = object().shape({
    brandName: string().required('Required - Brand Name'),
    amazonStorefront: string().required('Required - Amazon Storefront'),
    website: string().required('Required - Website'),
    facebookUrl: string().url().required('Required - Facebook URL'),
    instagramUrl: string().url().required('Required - Instagram URL'),
    bestSellingAsin: string().required('Required - Best Selling ASIN'),
    pastThreeOrganicSales: number()
      .min(0)
      .required('Required - Past Three Months Organic Sales'),
    pastThreeMonthsPPCSales: number()
      .min(0)
      .required('Required - Past Three Months PPC Sales'),
    pastThreeMonthsAcos: number()
      .min(0)
      .required('Required - Past Three Months ACOS'),
    targetAcos: number().min(0).required('Required - Target ACOS'),
    ppcGoal: string().required('Required - PPC Goal'),
    brandColorPalette: string().required('Required - Brand Color Palette'),
    fontGuidelines: string().required('Required - Font Guidelines'),
    targetAudience: string().required('Required - Target Audience'),
    brandVoiceAndTone: string().required('Required - Brand Voice and Tone'),
    imageGuidelines: string().required('Required - Image Guidelines'),
    otherBrandVitalInformation: string().nullable(),
    serviceAsins: array()
      .of(
        object().shape({
          asin: string(), //.required('Required - ASIN'),
          productName: string(), //.required('Required - Product Name'),
          isListingImages: boolean(), //.required('Required - Listing Images'),
          isAPlusContent: boolean(), //.required('Required - isAPlusContent'),
          isStorefront: boolean(), //.required('Required - isStorefront'),
          isListingVideo: boolean(), //.required('Required - isListingVideo'),
          isListingCopy: boolean(), //.required('Required - isListingCopy'),
          isAPlusCopy: boolean(), //.required('Required - isAPlusCopy'),
          // isParentASIN: boolean().required('Required - isParentAsin'),
          // childASINs: string().when('isParentASIN', {
          //   is: 'true',
          //   then: string().required('Required - Child ASINs'),
          // }),
          // parentAsinUrl: string().url().required('Required - parentAsinUrl'),
          // parentAsinUrl: string()
          //   .url('must be a valid URL')
          //   .when('isParentASIN', {
          //     is: 'true',
          //     then: string().required('Required - Parent ASIN URL'),
          //   }),
          // amazonProductUrl: string()
          //   .url('must be a valid URL')
          //   .required('Required - amazonProductUrl'),
          // productSKU: string().required('Required - productSKU'),
          // competitorAmazonProductUrl1: string()
          //   .url('must be a valid URL')
          //   .required('Required - competitorAmazonProductUrl1'),
          // competitorAmazonProductUrl2: string()
          //   .url('must be a valid URL')
          //   .required('Required - competitorAmazonProductUrl2'),
          topFiveKeywords: string(), //.required('Required - topFiveKeywords'),
          topFiveRestrictedKeywords: string(), //.required('Required - productSKU'),
          topFiveFeatures: string(), //.required('Required - topFiveFeatures'),
          topFiveBenefits: string(), //.required('Required - topFiveBenefits'),
          targetAudience: string(), //.required('Required - targetAudience'),
          instructionalUse: string(), //.required('Required - instructionalUse'),
        })
      )
      .nullable(),
  });

  useEffect(() => {
    setSaving(true);

    dispatch(getOnboardingDetails(agencyClientId));
    setSaving(false);
  }, []);

  useEffect(() => {
    if (onboarding) {
      setInitialValues({
        brandName: onboarding.brandName ?? '',
        amazonStorefront: onboarding.amazonStorefront ?? '',
        website: onboarding.website ?? '',
        facebookUrl: onboarding.facebookUrl ?? '',
        instagramUrl: onboarding.instagramUrl ?? '',
        bestSellingAsin: onboarding.bestSellingAsin ?? '',
        pastThreeOrganicSales: onboarding.pastThreeOrganicSales ?? 0,
        pastThreeMonthsPPCSales: onboarding.pastThreeMonthsPPCSales ?? 0,
        pastThreeMonthsAcos: onboarding.pastThreeMonthsAcos ?? 0,
        targetAcos: onboarding.targetAcos ?? '',
        ppcGoal: onboarding.ppcGoal ?? '',
        brandColorPalette: onboarding.brandColorPalette ?? '',
        fontGuidelines: onboarding.fontGuidelines ?? '',
        targetAudience: onboarding.targetAudience ?? '',
        brandVoiceAndTone: onboarding.brandVoiceAndTone ?? '',
        imageGuidelines: onboarding.imageGuidelines ?? '',
        otherBrandVitalInformation: onboarding.otherBrandVitalInformation ?? '',
        serviceAsins: onboarding.serviceAsins?.map((asin) => {
          return {
            ...asin,
            isParentASIN: asin.isParentASIN ? 'true' : 'false',
          };
        }) ?? [servinceAsinObj],
      });
    }
  }, [onboarding]);

  const onSubmit = async (values) => {
    //convert isParentASIN from string to boolean base on Proposal saved format start
    const newServiceAsins = values.serviceAsins.map((asin) => {
      return {
        ...asin,
        isParentASIN: asin.isParentASIN === 'true' ? true : false,
      };
    });
    //convert isParentASIN from string to boolean base on Proposal saved format end
    try {
      setSaving(true);
      const response = await axios.patch(
        `agency/clients/onboarding/${agencyClientId}`,
        (values = {
          ...values,
          serviceAsins: newServiceAsins,
        })
      );
      if (response.data.success) {
        dispatch(
          setAlert(
            'success',
            'Save successfully',
            `Agency Client onboarding details were successfully updated`
          )
        );
        dispatch(setOnboardingDetails(response.data.client));
        //get updated service ASINs
        dispatch(getOnboardingDetails(agencyClientId));
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
        <Form>
          <FormikErrorNotification />

          <div className="col-span-8">
            <div className="bg-white">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                      <span>Store Information</span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                          <Label>
                            Brand Name <RequiredAsterisk />
                          </Label>
                          <Field
                            name="brandName"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="brandName"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-12">
                          <Label>
                            Amazon Storefront <RequiredAsterisk />
                          </Label>
                          <Field
                            name="amazonStorefront"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="amazonStorefront"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-12">
                          <Label>
                            Website <RequiredAsterisk />
                          </Label>
                          <Field
                            name="website"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="website"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Facebook URL <RequiredAsterisk />
                          </Label>
                          <Field
                            name="facebookUrl"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="facebookUrl"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Instagram URL <RequiredAsterisk />
                          </Label>
                          <Field
                            name="instagramUrl"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="instagramUrl"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                      <span>Sales Insight and Targets</span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6">
                          <Label>
                            Best Selling ASIN <RequiredAsterisk />
                          </Label>
                          <Field
                            name="bestSellingAsin"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="bestSellingAsin"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Past Three Months Organic Sales <RequiredAsterisk />
                          </Label>
                          <Field
                            name="pastThreeOrganicSales"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="number"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="pastThreeOrganicSales"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Past Three Months PPC Sales <RequiredAsterisk />
                          </Label>
                          <Field
                            name="pastThreeMonthsPPCSales"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="number"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="pastThreeMonthsPPCSales"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Past Three Months ACOS <RequiredAsterisk />
                          </Label>
                          <Field
                            name="pastThreeMonthsAcos"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="number"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="pastThreeMonthsAcos"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            Target ACOS <RequiredAsterisk />
                          </Label>
                          <Field
                            name="targetAcos"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="number"
                            readOnly={agencyClient.deletedAt}
                          />
                          <ErrorMessage
                            name="targetAcos"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        <div className="col-span-6">
                          <Label>
                            PPC Goal <RequiredAsterisk />
                          </Label>
                          <Field
                            name="ppcGoal"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            as="select"
                            readOnly={agencyClient.deletedAt}
                          >
                            <option value="">Select PPC Goal</option>
                            {[
                              'Growth Strategy (High ACOS, High Spend)',
                              'Balance Strategy (Dynamic ACOS)',
                              'Profit Strategy (Low ACOS, Low Budget)',
                            ].map((el) => {
                              return (
                                <option value={el} key={el}>
                                  {el}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            name="ppcGoal"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                      <span>Branding Guide</span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-6 w-6 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                      <div className="col-span-12">
                        <Label>
                          Brand Color Palette <RequiredAsterisk />
                        </Label>
                        <Field
                          name="brandColorPalette"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          rows={5}
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="brandColorPalette"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div className="col-span-12">
                        <Label>
                          Font Guidelines <RequiredAsterisk />
                        </Label>
                        <Field
                          name="fontGuidelines"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          rows={5}
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="fontGuidelines"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div className="col-span-12">
                        <Label>
                          Target Audience <RequiredAsterisk />
                        </Label>
                        <Field
                          name="targetAudience"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          rows={5}
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="targetAudience"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div className="col-span-12">
                        <Label>
                          Brand Voice and Tone <RequiredAsterisk />
                        </Label>
                        <Field
                          name="brandVoiceAndTone"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          rows={5}
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="brandVoiceAndTone"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div className="col-span-12">
                        <Label>
                          Image Guidelines <RequiredAsterisk />
                        </Label>
                        <Field
                          name="imageGuidelines"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          type="text"
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="imageGuidelines"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                      <div className="col-span-12">
                        <Label>
                          Other Brand Vital Information <RequiredAsterisk />
                        </Label>
                        <Field
                          name="otherBrandVitalInformation"
                          className="form-input disabled-white"
                          onChange={(e) => handleChange(e)}
                          type="text"
                          readOnly={agencyClient.deletedAt}
                        />
                        <ErrorMessage
                          name="otherBrandVitalInformation"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <FieldArray name="serviceAsins">
                      {({ insert, remove, push }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                            <span>
                              Service ASINs&nbsp;
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  push(servinceAsinObj);
                                }}
                                className="ml-2"
                              >
                                {!agencyClient.deletedAt && (
                                  <PlusCircleIcon className="w-6 h-6 inline text-gray-700 hover:text-green-500 relative -mt-2" />
                                )}
                              </span>
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } h-6 w-6 text-gray-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="py-4 space-y-4 text-sm text-gray-500">
                            {values.serviceAsins?.length > 0 &&
                              values.serviceAsins.map((asin, index) => (
                                <div
                                  key={index}
                                  className="sm:grid grid-cols-2 gap-4"
                                >
                                  <div className="col-span-2 flex justify-between">
                                    <span className="font-bold text-base text-gray-700">
                                      {values.serviceAsins[index].asin}
                                    </span>
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      ASIN <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.asin`}
                                      placeholder="ASIN"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.asin`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      Product Name <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.productName`}
                                      placeholder="Product Name"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.productName`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-1 row-span-5">
                                    <div className="sm:grid grid-cols-2 grid-rows-5 gap-4">
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          Design Work Needed
                                          <RequiredAsterisk />
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isListingImages`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          Listing Images
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isAPlusContent`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          A + Content
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isStorefront`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          Storefront
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isListingVideo`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          Listing Video
                                        </Label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-1 row-span-5">
                                    <div className="sm:grid grid-cols-2 grid-rows-5 gap-4">
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          Content Work Needed
                                          <RequiredAsterisk />
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isListingCopy`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          Listing Copy
                                        </Label>
                                      </div>
                                      <div className="col-span-2 row-span 1">
                                        <Label>
                                          <Field
                                            type="checkbox"
                                            name={`serviceAsins.${index}.isAPlusCopy`}
                                            className="cursor-pointer mr-4"
                                            readOnly={agencyClient.deletedAt}
                                          />
                                          A + Copy
                                        </Label>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-span-1">
                                    <Label>Is this a Parent ASIN?</Label>
                                    <p className="whitespace-pre-wrap mt-2">
                                      {asin.isParentASIN}
                                    </p>
                                  </div> */}
                                  <div className="col-span-1">
                                    <Label>
                                      Is this a Parent ASIN?
                                      <RequiredAsterisk />
                                    </Label>
                                    <div
                                      role="group"
                                      aria-labelledby="my-radio-group"
                                      className="my-2"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name={`serviceAsins.${index}.isParentASIN`}
                                          value="true"
                                          className="cursor-pointer mr-4"
                                          disabled={agencyClient.deletedAt}
                                        />
                                        Yes
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name={`serviceAsins.${index}.isParentASIN`}
                                          value="false"
                                          className="cursor-pointer mx-4"
                                          disabled={agencyClient.deletedAt}
                                        />
                                        No
                                      </label>
                                      <ErrorMessage
                                        name={`serviceAsins.${index}.isParentASIN`}
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                  </div>

                                  {asin.isParentASIN === 'true' && (
                                    <div className="col-span-1">
                                      <Label>Child ASINs</Label>
                                      <Field
                                        name={`serviceAsins.${index}.childASINs`}
                                        placeholder="Child ASINs"
                                        as="textarea"
                                        rows={3}
                                        className="form-input disabled-white"
                                        readOnly={agencyClient.deletedAt}
                                      />
                                      <ErrorMessage
                                        name={`serviceAsins.${index}.childASINs`}
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                  )}

                                  <div className="col-span-1">
                                    <Label>
                                      Parent ASIN URL{' '}
                                      {values.serviceAsins[index]
                                        .isParentASIN !== 'false' && (
                                        <RequiredAsterisk />
                                      )}
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.parentAsinUrl`}
                                      placeholder="ASIN URL"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.parentAsinUrl`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-1">
                                    <Label>
                                      Amazon Product URL <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.amazonProductUrl`}
                                      placeholder="ASIN URL"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.amazonProductUrl`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      Product SKU <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.productSKU`}
                                      placeholder="ASIN URL"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.productSKU`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      Competitor Product URL 1{' '}
                                      <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.competitorAmazonProductUrl1`}
                                      placeholder="ASIN URL"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.competitorAmazonProductUrl1`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      Competitor Product URL 2{' '}
                                      <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.competitorAmazonProductUrl2`}
                                      placeholder="ASIN URL"
                                      className="form-input disabled-white"
                                      type="text"
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.competitorAmazonProductUrl2`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-2">
                                    <Label>
                                      Top 5 Keywords <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.topFiveKeywords`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.topFiveKeywords`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-2">
                                    <Label>
                                      Top 5 Restricted Keywords{' '}
                                      <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.topFiveRestrictedKeywords`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.topFiveRestrictedKeywords`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-2">
                                    <Label>
                                      Top 5 Features <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.topFiveFeatures`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.topFiveFeatures`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-2">
                                    <Label>
                                      Top 5 Benefits <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.topFiveBenefits`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.topFiveBenefits`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-2">
                                    <Label>
                                      Who are the target audience?{' '}
                                      <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.targetAudience`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.targetAudience`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-2">
                                    <Label>
                                      Instructional use of the product{' '}
                                      <RequiredAsterisk />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.instructionalUse`}
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      rows={5}
                                      readOnly={agencyClient.deletedAt}
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.instructionalUse`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                </div>
                              ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </FieldArray>
                  </>
                )}
              </Disclosure>
            </div>
            {!agencyClient.deletedAt && (
              <div className="col-span-8 pt-3">
                {saving ? (
                  <Loading />
                ) : (
                  <Button
                    // onClick={() => checkErrors(errors)}
                    type="submit"
                    classes="bg-blue-900 rounded-2xl px-12 font-bold"
                    color="sky"
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default Onboarding;
