import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import { useHistory } from 'react-router-dom';

const TaskDetails = () => {
  const history = useHistory();

  const onClose = () => {
    const seachParams = new URLSearchParams(history.location.search);
    history.push(seachParams.get('from') ?? '/home/my-workspace');
  };

  return <TaskDetailsPage onCloseModal={onClose} />;
};

export default TaskDetails;
