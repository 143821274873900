import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch, useSelector } from 'react-redux';

const useAuth = () => {
  const { user } = useSelector((state) => state.auth);

  const isAgencyUser = user?.role?.level === 'agency';
  const isApplicationUser = user?.role?.level === 'application';

  return {
    user,
    isAgencyUser,
    isApplicationUser,
  };
};

export default useAuth;
