import {
  getAccountsAsync,
  selectCurrentAccount,
} from 'features/accounts/accountsSlice';
import {
  getMyselfAsync,
  selectAuthenticatedUser,
} from 'features/auth/authSlice';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNameInitials } from 'utils/formatters';

const useLoggedInUser = () => {
  const user = useSelector(selectAuthenticatedUser);
  const currentAccount = useSelector(selectCurrentAccount);
  const dispatch = useDispatch();

  const name = useMemo(() => {
    return `${user.firstName} ${user.lastName}`;
  }, [user]);

  const initials = useMemo(() => {
    return getNameInitials(user.firstName, user.lastName);
  }, [user]);

  const isHubstaffConnected = user.credentials.some(
    (credential) => credential.service === 'HUBSTAFF'
  );

  const isAccountPlanAgency = () => {
    return currentAccount && currentAccount.plan.name === 'agency';
  };

  const isAlreadySubscribed = () => {
    return (
      currentAccount &&
      // currentAccount.onboardingHistory &&
      // currentAccount.onboardingHistory.subscriptionCreated &&
      // currentAccount.onboardingHistory.paymentMade &&
      currentAccount.isOnboarding &&
      currentAccount.subscription
    );
  };

  const reloadMe = () => {
    dispatch(getMyselfAsync());
    dispatch(getAccountsAsync({}, true));
  };

  return {
    user,
    name,
    initials,
    isHubstaffConnected,
    currentAccount,
    isAccountPlanAgency,
    isAlreadySubscribed,
    reloadMe,
  };
};

export default useLoggedInUser;
