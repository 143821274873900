import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTasks } from 'Version2/features/tasks';
import {
  TaskGroup,
  FloatingTaskForm,
  TaskGroupSkeleton,
} from 'Version2/features/tasks/components';

const TaskList = ({ taskList }) => {
  const history = useHistory();

  const { board, fetchTaskBoardItems, fetchTaskGroupItems } = useTasks();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    refreshList().then(() => setInitialLoad(false));
  }, [taskList]);

  const refreshList = async () => {
    if (!taskList?.taskListId) return;

    fetchTaskBoardItems(
      board.key,
      taskList.statuses.map((status) => {
        return {
          title: status.name,
          params: {
            isSubtask: false,
            status: status.name,
            taskListId: taskList?.taskListId,
            attributes: [
              'due',
              'name',
              'taskItemId',
              'taskListId',
              'dueDate',
              'status',
              'priority',
              'hasSubtasks',
              'parentTaskItemId',
              'hierarchyLevelId',
            ],
            include: [
              'list',
              'assignees[avatar]',
              'subtasks[assignees[avatar]]',
            ],
            sorts: 'updatedAt',
          },
        };
      })
    );
  };

  return taskList ? (
    <div>
      <div className="space-y-8 mt-8">
        {initialLoad ? (
          <TaskGroupSkeleton />
        ) : (
          board.groups.map((group, idx) => (
            <TaskGroup
              key={idx}
              title={group.title}
              taskItemList={group.data}
              isLoading={group.isLoading}
              itemsConfig={{
                onUpdate: (attribute) => {
                  if (attribute === board.key) {
                    refreshList();
                  }
                },
                onClick: (task) =>
                  history.push(
                    `/task/${task.taskItemId}?from=${history.location.pathname}`
                  ),
              }}
              onLoadMore={() =>
                fetchTaskGroupItems(idx, {
                  ...group.params,
                  pageSize: group.data.pageSize + 10,
                })
              }
            />
          ))
        )}
      </div>

      <FloatingTaskForm
        selectedTaskList={taskList}
        onSuccess={() => refreshList()}
      />
    </div>
  ) : (
    <div className="flex justify-center my-16">
      <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2">
        Something went wrong. Please contact administrator
      </p>
    </div>
  );
};

export default TaskList;
