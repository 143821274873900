import Avatar from './Avatar';
import { getNameInitials } from 'utils/formatters';

const UserDisplay = ({ bgColor = '#002F5D', user = {} }) => {
  return (
    <div className="flex space-x-2 items-center">
      <Avatar
        bgColor={bgColor}
        initials={getNameInitials(user?.firstName, user?.lastName)}
        imageSrc={user?.avatar?.thumbnailUrl}
      />

      <span>
        {user?.firstName} {user?.lastName}
      </span>
    </div>
  );
};

export default UserDisplay;
