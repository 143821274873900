import { Route, Switch } from 'react-router-dom';
import UpsellInvoices from './UpsellInvoices';
import InvoiceDetails from './InvoiceDetails';
import UpsellInvoiceDetails from './UpsellInvoiceDetails';

const AdditionalServices = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}`} component={UpsellInvoices} exact />
      <Route
        path={`${path}/:upsellId/:invoiceId`}
        component={UpsellInvoiceDetails}
      />
    </Switch>
  );
};
export default AdditionalServices;
