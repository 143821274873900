import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BanIcon,
  CashIcon,
  ReceiptRefundIcon,
  TrendingUpIcon,
  ExclamationCircleIcon,
  CreditCardIcon,
  TicketIcon,
  ReceiptTaxIcon,
  ClipboardListIcon,
  PuzzleIcon,
} from '@heroicons/react/outline';
import {
  ReceiptTaxIcon as ReceiptTaxSolidIcon,
  CreditCardIcon as CcIcon,
} from '@heroicons/react/solid';
import usePermissions from 'hooks/usePermissions';

const useBillingMenu = () => {
  const { t } = useTranslation();
  const { userCan, isAgencyLevel } = usePermissions();

  const { paginationParams: ipp } = useSelector((state) => state.invoices);
  const { upsellsDefaultPaginationParams } = useSelector(
    (state) => state.upsells
  );

  return {
    name: 'Billing',
    href: '/invoices',
    icon: '#billing',
    iconType: 'svg',
    iconSize: { height: '12px', width: '16px' },
    visible: () => {
      return (
        isAgencyLevel() &&
        (userCan('invoices.view') ||
          userCan('creditNotes.list') ||
          userCan('upsells.list'))
      );
    },
    children: [
      {
        name: t('Dashboard.Invoices'),
        href: '/invoices',
        icon: CashIcon,
        visible: () => {
          return isAgencyLevel() && userCan('invoices.view');
        },
        query: () => {
          return {
            page: 1,
            status: 'Pending',
            sizePerPage: ipp.sizePerPage ?? 25,
          };
        },
      },
      {
        name: 'Commission Errors',
        href: '/commission-errors',
        icon: ExclamationCircleIcon,
        visible: () => userCan('invoices.commissionerror.view'),
      },
      {
        name: 'Payments',
        href: `/payments`,
        icon: CreditCardIcon,
        exact: true,
        visible: () => userCan('invoices.payments.view'),
      },
      {
        name: 'Transactions',
        href: `/transactions`,
        icon: CcIcon,
        exact: true,
        visible: () => userCan('invoices.payments.view'),
      },
      {
        name: 'Events',
        href: `/events`,
        icon: TicketIcon,
        exact: true,
        visible: () => userCan('invoices.events.view'),
      },
      {
        name: 'Credit Notes',
        href: '/credit-notes',
        icon: ReceiptRefundIcon,
        visible: () => {
          return  userCan('creditNotes.view');
        },
      },
      {
        name: 'Upsells',
        href: '/upsells',
        icon: TrendingUpIcon,
        visible: () => userCan('upsells.list'),
        query: () => upsellsDefaultPaginationParams,
      },
      {
        name: 'Upsell Items',
        href: `/addons`,
        icon: PuzzleIcon,
        visible: () => userCan('upsells.items.list'),
      },
      {
        name: 'Taxes',
        href: `/taxes`,
        icon: ReceiptTaxIcon,
        visible: () => userCan('billing.taxes.view'),
      },
      {
        name: 'Tax Exemptions',
        href: `/tax-exemptions`,
        icon: ReceiptTaxSolidIcon,
        visible: () => userCan('billing.taxexemptions.view'),
      },
      {
        name: 'Plans',
        href: `/plans`,
        icon: ClipboardListIcon,
        visible: () => userCan('billing.plans.view'),
      },
      {
        name: 'Terminations',
        href: `/billing/terminations`,
        icon: BanIcon,
        visible: () => true,
      },
    ],
  };
};
export default useBillingMenu;
