import { currencyFormatter, dateFormatter } from 'utils/formatters';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';

const PaymentsMade = ({ invoice }) => {
  const [paymentsExpanded, setPaymentsExpanded] = useState(false);

  return (
    invoice.payment_made > 0 && (
      <div className="border border-gray-200 py-2 px-4 bg-white">
        <div
          className="text-sm cursor-pointer flex justify-between"
          onClick={() => setPaymentsExpanded(!paymentsExpanded)}
        >
          <div>
            <span className=" font-bold">Payments Received</span>
            <span className="ml-2 bg-gray-100 rounded-lg px-1 text-blue-500 text-xs font-normal">
              {invoice.payments.length}
            </span>
          </div>
          <span>
            {paymentsExpanded ? (
              <ChevronDownIcon className="w-5 h-5 inline text-gray-500" />
            ) : (
              <ChevronRightIcon className="w-5 h-5 inline text-gray-500" />
            )}
          </span>
        </div>
        <div
          className={paymentsExpanded ? 'border-t mt-2' : 'h-0 overflow-hidden'}
        >
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="py-1 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                >
                  Payment #
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                >
                  Reference #
                </th>
                <th
                  scope="col"
                  className="px-3 py-1 text-left text-sm font-medium text-gray-500 "
                >
                  Payment Mode
                </th>
                <th
                  scope="col"
                  className="relative py-1 pl-3 pr-4 text-left text-sm font-medium text-gray-500 sm:pr-6"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="relative py-1 pl-3 pr-4 text-left text-sm font-medium text-gray-500 sm:pr-6"
                >
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {invoice.payments.map((payment) => (
                <tr key={payment.payment_id}>
                  <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                    {dateFormatter(payment.date)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                    {payment.invoice_payment_id}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500">
                    {payment.reference_number}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm capitalize text-gray-500">
                    {payment.payment_mode}
                  </td>
                  <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-sm text-gray-700 sm:pr-6">
                    {currencyFormatter(payment.amount, invoice.currency_code)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};
export default PaymentsMade;
