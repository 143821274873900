import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchComplaintList = createAsyncThunk(
  'complaints/fetchList',
  async (params) => {
    const response = await axios.get('/agency/clients/complaints', {
      params,
    });

    return response.data.data;
  }
);

export const fetchComplaintDetails = createAsyncThunk(
  'complaints/fetchDetails',
  async (id) => {
    const response = await axios.get(`/agency/clients/complaints/${id}`, {
      params: {
        include: [
          'files',
          'addedBy',
          'client',
          'client.defaultContact',
          'client.projectManager',
          'client.salesRepresentative',
        ],
      },
    });

    return response.data.data;
  }
);

export const resolveComplaint = createAsyncThunk(
  'complaints/resolve',
  async ({ id, data }) => {
    const response = await axios.post(
      `/agency/clients/complaints/${id}/resolve`,
      data
    );

    return response.data.data;
  }
);

export const complaintSlice = createSlice({
  name: 'complaint',
  initialState: {
    list: {
      loading: true,
      data: { rows: [] },
      params: {
        page: 1,
        search: '',
        pageSize: 10,
        sort: 'createdAt:desc',
        include: [
          'addedBy',
          'client',
          'client.defaultContact',
          'client.projectManager',
          'client.salesRepresentative',
        ],
      },
    },
    selected: { loading: true, data: {} },
  },
  reducers: {
    setListParams: (state, action) => {
      state.list.params = action.payload;
    },
  },
  extraReducers: {
    [fetchComplaintList.pending]: (state) => {
      state.list.loading = true;
    },
    [fetchComplaintList.fulfilled]: (state, { payload }) => {
      state.list.data = payload;
      state.list.loading = false;
    },
    [fetchComplaintDetails.pending]: (state) => {
      state.selected.loading = true;
    },
    [fetchComplaintDetails.fulfilled]: (state, { payload }) => {
      state.selected.data = payload;
      state.selected.loading = false;
    },
    [resolveComplaint.pending]: (state) => {
      state.selected.loading = true;
    },
    [resolveComplaint.fulfilled]: (state, { payload }) => {
      state.selected.data = {
        ...state.selected.data,
        status: payload.status,
        updatedAt: payload.updatedAt,
        resolvedAt: payload.resolvedAt,
        resolution: payload.resolution,
      };
      state.selected.loading = false;
    },
  },
});

export const { setListParams } = complaintSlice.actions;

export default complaintSlice.reducer;
