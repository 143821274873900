import { useFormikContext } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { Field, ErrorMessage, FieldArray } from 'formik';
import InputAppend from 'components/Forms/InputAppend';
import Button from 'components/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { COMMISSION_TYPES, TYPE_DESCRIPTIONS } from 'utils/constants';
import Toggle from 'components/Forms/Toggle';
import classNames from 'utils/classNames';

const CommissionForm = () => {
  const { setFieldValue, handleChange, values, errors } = useFormikContext();

  const tieredRuleObj = {
    min: '',
    max: '',
    rate: '',
  };

  return (
    <div className="sm:grid sm:grid-cols-2 space-y-3 mt-2 border-b pb-5">
      <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
        <span className="">
          Add Commission
          <span
            className={classNames(
              values.noCommission ? 'bg-yellow-100' : 'bg-green-100',
              'text-xs uppercase ml-1 p-1 leading-none rounded-md text-gray-700'
            )}
          >
            {values.noCommission ? 'No' : 'Yes'}
          </span>
        </span>
        <Toggle
          onChange={(e) => {
            setFieldValue('noCommission', !e);
            if (values.noCommission) {
              setFieldValue('commissionType', '');
            }
          }}
          checked={!values.noCommission}
        />
      </div>
      {!values.noCommission ? (
        <>
          <div className="col-span-2 ">
            <Label>Type</Label>
            <Field
              name="commissionType"
              as="select"
              className="form-select disabled-white"
              onChange={(e) => {
                setFieldValue('noCommission', false);
                handleChange(e);
              }}
            >
              <option value="" disabled>
                Select Commission Type
              </option>
              {COMMISSION_TYPES.map((commission_type, i) => (
                <option key={i} value={commission_type.value}>
                  {commission_type.label}
                </option>
              ))}
            </Field>
            <sub className="text-xs indent-2 text-yellow-500 italic relative -top-1">
              {TYPE_DESCRIPTIONS[values.commissionType]}
            </sub>
            <ErrorMessage
              name="commissionType"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          {values.commissionType !== 'tiered' && (
            <div className="col-span-2">
              <Label>Rate</Label>
              <Field name="rate">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <InputAppend
                      type="number"
                      placeholder="0"
                      appendText="%"
                      border="border-white"
                      classes="bg-gray-50 disabled-white"
                      {...field}
                    />
                  </>
                )}
              </Field>
              <ErrorMessage
                name="rate"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
          )}
          {(values.commissionType === 'benchmark' ||
            values.commissionType === 'rolling') && (
            <div className="col-span-2">
              <Label>Month Threshold</Label>
              <Field
                name="monthThreshhold"
                placeholder="No. of months"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="number"
              />
              <ErrorMessage
                name="monthThreshhold"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
          )}
          {values.commissionType === 'benchmark' &&
            values.managedAsins.length < 1 && (
              <div className="col-span-2">
                <Label>Baseline</Label>
                <Field
                  name="baseline"
                  placeholder="Enter Baseline Avg. Sales"
                  className="form-input disabled-white"
                  onChange={(e) => handleChange(e)}
                  type="number"
                />
                <ErrorMessage
                  name="baseline"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            )}
          <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
            <span className="">Automatically add to pending invoice</span>
            <Toggle
              onChange={(e) => setFieldValue('autoAdd', e)}
              checked={values.autoAdd}
            />
          </div>
          {values.commissionType === 'tiered' && (
            <FieldArray name="rules">
              {({ insert, remove, push }) => (
                <>
                  <div className="col-span-2">
                    <hr className="my-2" />
                  </div>

                  {values.rules.length > 0 && (
                    <div className="col-span-2 space-y-3 mb-4">
                      <span className="font-inter font-bold text-base">
                        Rules
                      </span>
                      {values.rules.map((rule, index) => (
                        <div key={index} className="sm:grid grid-cols-3 gap-2">
                          <div className="col-span-3 flex justify-between">
                            <Label
                              fontWeight="font-bold"
                              textColor="text-gray-500"
                            >
                              Rule {index + 1}
                            </Label>
                            <span
                              onClick={() => remove(index)}
                              className="cursor-pointer text-gray-500 hover:text-red-500"
                            >
                              <TrashIcon className="w-4 h-4 " />
                            </span>
                          </div>
                          <div className="col-span-1">
                            <Label>Min. Gross</Label>
                            <Field
                              name={`rules.${index}.min`}
                              placeholder="Enter Min. Gross"
                              className="form-input disabled-white"
                              onChange={(e) => handleChange(e)}
                              type="number"
                            />
                            <ErrorMessage
                              name={`rules.${index}.min`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-1">
                            <Label>Max. Gross</Label>
                            <Field
                              name={`rules.${index}.max`}
                              placeholder="Enter Max. Gross"
                              className="form-input disabled-white"
                              onChange={(e) => handleChange(e)}
                              type="number"
                            />
                            <ErrorMessage
                              name={`rules.${index}.max`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-1">
                            <Label>Rate</Label>
                            <Field name={`rules.${index}.rate`}>
                              {({ field, form: { touched, errors }, meta }) => (
                                <>
                                  <InputAppend
                                    type="number"
                                    placeholder="0"
                                    appendText="%"
                                    border="border-white"
                                    classes="bg-gray-50 disabled-white"
                                    {...field}
                                  />
                                </>
                              )}
                            </Field>
                            <ErrorMessage
                              name={`rules.${index}.rate`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="col-span-2">
                    <Button
                      classes="font-bold tracking-wider w-full justify-center"
                      bgColor="gray-50"
                      hoverColor="gray-200"
                      roundedSize="2xl"
                      textColor="gray-600"
                      border="border-2 border-gray-600"
                      py={1}
                      shadow=""
                      onClick={(e) => {
                        push(tieredRuleObj);
                      }}
                    >
                      <PlusIcon className="w-4 h-4 inline" /> Add Rule
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          )}
          {values.commissionType === 'tiered' && values.rules.length < 1 && (
            <ErrorMessage
              name={`rules`}
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          )}
        </>
      ) : (
        <div className="col-span-2">
          <Label htmlFor="noCommissionReason">
            Reason <RequiredAsterisk />
          </Label>
          <Field
            name="noCommissionReason"
            as="textarea"
            rows={3}
            className="form-input disabled-white"
          />
          <ErrorMessage
            name="noCommissionReason"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      )}
    </div>
  );
};
export default CommissionForm;
