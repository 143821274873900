import { UserSelector } from 'components';

const Assignees = ({ value, onAdd, onRemove, onUpdate }) => {
  return (
    <div className="flex space-x-1">
      {value.map((assignee) => (
        <UserSelector
          key={assignee.userId}
          size="32px"
          value={assignee}
          params={{ 'role.level': 'agency' }}
          className="px-0"
          onDelete={(user) => onRemove(user.userId)}
          onChange={(user) => onUpdate(assignee.userId, user.userId)}
        />
      ))}

      <UserSelector
        size="32px"
        iconSize="16px"
        params={{ 'role.level': 'agency' }}
        className="w-full max-w-sm px-0"
        onChange={(user) => onAdd(user.userId)}
      />
    </div>
  );
};

export default Assignees;
