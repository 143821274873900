import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';

export const fetchPlans = createAsyncThunk(
  'zoho/getPlans',
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get('/z/list?operation=plans');

      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        setAlert('error', 'Fetching plans failed', err.response.data.message)
      );
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAddons = createAsyncThunk(
  'zoho/getAddons',
  async (filter = '', thunkAPI) => {
    try {
      const response = await axios.get(`/z/addons`);
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        setAlert('error', 'Fetching addons failed', err.response.data.message)
      );
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const zohoSlice = createSlice({
  name: 'zoho',
  initialState: {
    plans: null,
    addons: null,
    activeOneTimeAddons: null,
  },
  extraReducers: {
    [fetchPlans.fulfilled]: (state, { payload }) => {
      state.plans = payload;
    },
    [fetchAddons.fulfilled]: (state, { payload }) => {
      state.addons = payload;
      state.activeOneTimeAddons = payload.filter((a) => a.type === 'one_time');
    },
  },
});

export default zohoSlice.reducer;
