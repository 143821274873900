import axios from 'axios';
import moment from 'moment';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

const ClientsSummary = (props) => {
  const getPercentage = (key) => {
    const { totalClients } = props;

    if (!totalClients || !props[key]) {
      return 0;
    }

    return (props[key] / totalClients) * 100;
  };

  const statuses = [
    {
      display: 'Active',
      key: 'totalActiveClients',
      class: 'success-dark',
      color: '#00966D',
    },
    {
      display: 'On Hold',
      key: 'totalOnHoldClients',
      class: 'grayscale-700',
      color: '#949494',
    },
    {
      display: 'Pending',
      key: 'totalPendingClients',
      class: 'warning',
      color: '#F9DC7D',
    },
    {
      display: 'Terminated',
      key: 'totalTerminatedClients',
      class: 'error-dark',
      color: '#C30000',
    },
    {
      display: 'Onboarding',
      key: 'totalOnboardingClients',
      class: 'secondary-light',
      color: '#1879D8',
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-8 my-5">
      <div className="bg-white rounded-xl">
        <div className="grid grid-cols-4 gap-2 text-13 leading-1.5 text-grayscale-600 bg-grayscale-200 py-6 px-5 border-b border-grayscale-500">
          <p className="col-span-2">Account Status</p>
          <p className="text-center">Count</p>
          <p>Chart</p>
        </div>

        <div className="px-5">
          {statuses.map((a) => (
            <div
              key={`client-summary-${a.key}`}
              className="grid grid-cols-4 gap-2 text-13 leading-1.5 text-grayscale-800 my-5"
            >
              <p className="col-span-2">{a.display}</p>
              <p
                className={`text-center font-inter font-bold text-base text-${a.class}`}
              >
                {props[a.key]}
              </p>
              <div
                className={`bg-${a.class}`}
                style={{ height: '16px', width: `${getPercentage(a.key)}%` }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl pb-6 px-5">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={statuses.map((s) => {
                return {
                  name: s.display,
                  value: props[s.key],
                };
              })}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={85}
              fill="#8884d8"
            >
              {statuses.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              verticalAlign="bottom"
              align="center"
              iconType="circle"
              wrapperStyle={{ fontSize: '11px' }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ClientsSummary;
