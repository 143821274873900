import React, { useState, useEffect } from 'react';
import { dateFormatter, strUnderscoreToSpace } from 'utils/formatters';
import EventDetailsSlideOver from './EventDetailsSlideOver';
import axios from 'axios';
import { SearchIcon } from '@heroicons/react/solid';
import useQueryParams from 'hooks/useQueryParams';
import { cloneDeep } from 'lodash';
import { Table } from 'components';
import Exporter from './Exporter';
const Events = () => {
  const { params, updateParams } = useQueryParams({
    page: 1,
    sizePerPage: 25,
    status: 'All',
  });
  const [events, setEvents] = useState([]);
  const [zohoEvent, setZohoEvent] = useState(null);
  const [isOpenEventDetails, setIsOpenEventDetails] = useState(false);
  const [filteredData, setFilteredData] = useState('');
  const [loading, setLoading] = useState(false);
  const statuses = [
    { label: 'All', value: 'All' },
    { label: 'SUBSCRIPTION', value: '', disabled: true },
    { label: 'New Subscription', value: 'subscription_created' },
    { label: 'Subscription Activation', value: 'subscription_activation' },
    { label: 'Upgrade Subscription', value: 'subscription_upgraded' },
    { label: 'Downgrade Subscription', value: 'subscription_downgraded' },
    { label: 'Subscription Renewal', value: 'subscription_renewed' },
    { label: 'Cancel Subscription', value: 'subscription_cancelled' },
    { label: 'Reactivate Subscription', value: 'subscription_reactivated' },
    { label: 'Subscription Expired', value: 'subscription_expired' },
    { label: 'Subscription Renewal Ahead', value: 'subscription_ahead' },
    {
      label: 'Subscription Cancellation Scheduled',
      value: 'subscription_cancellation_scheduled',
    },
    {
      label: 'Subscription Scheduled Cancellation Removed',
      value: 'subscription_scheduled_cancellation_removed',
    },
    { label: 'Subscription Marked as Unpaid', value: 'subscription_unpaid' },
    { label: 'Subscription Deleted', value: 'subscription_deleted' },
    { label: 'Billing Date Changed', value: 'billing_date_changed' },
    { label: 'Subscription Paused', value: 'subscription_paused' },
    { label: 'Subscription Resumed', value: 'subscription_resumed' },
    { label: 'PAYMENT', value: '', disabled: true },
    { label: 'Payment Thank-You', value: 'payment_thankyou' },
    { label: 'Payment Failure', value: 'payment_declined' },
    { label: 'Payment Refund', value: 'payment_refunded' },
    { label: 'INVOICE', value: '', disabled: true },
    { label: 'Invoice Notification', value: 'invoice_notification' },
    { label: 'Invoice Updated', value: 'invoice_updated' },
    { label: 'Invoice Voided', value: 'invoice_voided' },
    { label: 'EXPIRY & CANCELLATION', value: '', disabled: true },
    { label: 'Trial About to Expire', value: 'trial_expiring' },
    {
      label: 'Subscription about to be Cancelled',
      value: 'subscription_cancelling',
    },
    { label: 'Subscription About to Expire', value: 'subscription_expiring' },
    { label: 'Card about to Expire', value: 'card_expiring' },
    { label: 'EXPIRED', value: '', disabled: true },
    { label: 'Card Expired', value: 'card_expired' },
    { label: 'CARD', value: '', disabled: true },
    { label: 'Card Deleted', value: 'card_deleted' },
    { label: 'CREDIT NOTE', value: '', disabled: true },
    { label: 'Credit Note Added', value: 'creditnote_added' },
    { label: 'Credit Note Refunded', value: 'creditnote_refunded' },
    { label: 'Credit Note Deleted', value: 'creditnote_deleted' },
    { label: 'PAYMENT METHOD', value: '', disabled: true },
    { label: 'Payment Method Added', value: 'payment_method_added' },
    { label: 'Payment Method Deleted', value: 'payment_method_deleted' },
    { label: 'Payment Method Updated', value: 'payment_method_updated' },
    { label: 'UNBILLED CHARGES', value: '', disabled: true },
    { label: 'Unbilled charges added', value: 'unbilled_charges_added' },
    { label: 'Unbilled charges updated', value: 'unbilled_charges_updated' },
    { label: 'Unbilled charges invoiced', value: 'unbilled_charges_invoiced' },
    { label: 'Unbilled charges deleted', value: 'unbilled_charges_deleted' },
  ];

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await axios
        .get(`/z/events`, { params })
        .then((res) => {
          setEvents(res.data.data);

          if (filteredData) {
            const results = res.data.data.rows.filter((row) => {
              if (filteredData === '') return row;
              return row.event_type
                .replace(/\s+/g, '')
                .toLowerCase()
                .includes(filteredData.replace(/\s+/g, '_').toLowerCase());
            });
            const newData = cloneDeep(res.data.data);
            newData.rows = results;
            setEvents(newData);
          }
        })
        .catch((error) => {})
        .finally(() => setLoading(false));
    };

    !loading && load();
  }, [params, filteredData]);

  const tableColumns = [
    {
      dataField: 'event_time',
      text: 'Event Date',
      sort: false,
      headerStyle: {
        width: '250px',
      },
      formatter: (cell) => <span>{dateFormatter(cell)}</span>,
    },
    {
      dataField: 'event_id',
      text: 'Event Id',
      sort: false,
      headerStyle: {
        width: '250px',
      },
      formatter: (cell, row) => {
        return (
          <button
            className="capitalize text-custom-sky underline font-normal"
            onClick={() => onClickEventDetails(row)}
          >
            {cell}
          </button>
        );
      },
    },
    {
      dataField: 'event_type',
      text: 'Event Type',
      sort: false,
      headerStyle: {
        minWidth: '150px',
        minHeight: '56px',
      },
      formatter: (cell) => strUnderscoreToSpace(cell),
    },

    {
      dataField: 'event_source',
      text: 'Source',
      sort: false,
      headerStyle: {
        minWidth: '150px',
        minHeight: '56px',
      },
      formatter: (cell) =>
        cell === 'api' ? <span>API</span> : strUnderscoreToSpace(cell),
    },
  ];

  const onClickEventDetails = (row) => {
    setIsOpenEventDetails(true);
    setZohoEvent(row);
  };

  const updateStatus = (status) => {
    updateParams({ status, page: 1 });
  };

  const onChangeInput = (input) => {
    setFilteredData(input);
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, sizePerPage };
    updateParams(newParams);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Events
          </h2>

          <div className="flex flex-row sm:flex-col lg:flex-row h-12 gap-1">
            <select
              id="status"
              label="status"
              value={params.status}
              className="h-full rounded-l-xl w-40 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
              onChange={(e) => updateStatus(e.target.value)}
            >
              {statuses.map((status, i) => (
                <option
                  key={i}
                  value={status.value}
                  disabled={status.disabled ?? ''}
                  className={status.disabled ? 'bg-red-50' : ''}
                >
                  {status.label}
                </option>
              ))}
            </select>
            <div className="h-full min-h-12 relative">
              <div className="h-full absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">
                  <SearchIcon className="w-4 h-4" />
                </span>
              </div>
              <input
                type="text"
                className="h-full rounded-r-xl w-60 pl-12 border-0 sm:text-sm appearance-none focus:ring-0 focus:border-0 focus:appearance-none  disabled:bg-gray-100"
                placeholder={'Search'}
                onChange={(e) => onChangeInput(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Exporter />
      </div>

      <Table
        columns={tableColumns}
        data={events}
        onTableChange={onTableChange}
        params={params}
        keyField="event_id"
        loading={loading}
        paginationType="prevnext"
      />

      <EventDetailsSlideOver
        open={isOpenEventDetails}
        setOpen={setIsOpenEventDetails}
        zohoEvent={zohoEvent}
      />
    </>
  );
};
export default Events;
