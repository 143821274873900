import axios from 'axios';
import { keys } from 'lodash';
import { useState } from 'react';

import {
  levels,
  departments,
  subDepartments,
  roles as rolesConstant,
} from './constants';

export default function useRoles() {
  const [selectedRole, setSelectedRole] = useState({ loading: true, data: {} });
  const [roles, setRoles] = useState({ loading: true, data: [] });

  const [roleList, setRoleList] = useState({
    loading: true,
    data: { rows: [], count: 0 },
  });

  const defaultSorts = 'department:ASC,subDepartment:ASC,seniorityLevel:ASC';

  const defaultFilters = [
    { operator: 'EQ', attribute: 'level', value: levels.AGENCY_LEVEL },
    { operator: 'EQ', attribute: 'status', value: 'ACTIVE' },
  ];

  const departmentList = keys(departments).map((key) => departments[key]);

  /**
   * Fetch role list.
   *
   * @param {object} params
   */
  const fetchRoleList = async (params = {}) => {
    setRoleList({ loading: true, data: { rows: [], count: 0 } });

    const { data: response } = await axios.get('/v2/roles/list', { params });

    setRoleList({ loading: false, data: response.data });

    return response.data;
  };

  /**
   * Fetch all roles.
   *
   * @param {object} params
   */
  const fetchAllRoles = async (params = {}) => {
    setRoles({ loading: true, data: [] });

    const { data: response } = await axios.get('/v2/roles', { params });

    setRoles({ loading: false, data: response.data });

    return response.data;
  };

  /**
   * Fetch specific roles.
   *
   * @param {object} params
   */
  const fetchRoleDetails = async (roleId, params = {}) => {
    setSelectedRole({ loading: true, data: {} });

    const { data: response } = await axios.get(`/v2/roles/${roleId}`, {
      params,
    });

    setSelectedRole({ loading: false, data: response.data });

    return response.data;
  };

  const getDepartmentByValue = (value) =>
    value
      ? Object.keys(departments)
          .map((key) => departments[key])
          .find((dept) => dept.value === value)
      : null;

  const getSubDepartmentByValue = (value) =>
    value
      ? Object.keys(subDepartments)
          .map((key) => subDepartments[key])
          .find((dept) => dept.value === value)
      : null;

  return {
    roles,
    levels,
    roleList,
    departments,
    departmentList,
    selectedRole,
    rolesConstant,
    subDepartments,
    ...rolesConstant,

    defaultSorts,
    defaultFilters,

    getDepartmentByValue,
    getSubDepartmentByValue,

    fetchRoleList,
    fetchAllRoles,
    fetchRoleDetails,

    setRoles,
    setRoleList,
    setSelectedRole,
  };
}
