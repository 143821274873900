import React, { useState, useEffect } from 'react';
import usePermissions from 'hooks/usePermissions';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser, setUser } from 'features/auth/authSlice';
import { Link, useParams } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/outline';
import Loading from 'components/Loading';
import axios from 'axios';
import usFlag from 'assets/icons/usaFlagButton.png';
import caFlag from 'assets/icons/canadaFlagButton.png';
import facebookLogo from 'assets/icons/facebookLogo.png';
import googleLogo from 'assets/icons/googleLogo.png';
import webLogo from 'assets/icons/webLogo.png';
import linkedinLogo from 'assets/icons/linkedinLogo.png';
import { getNameInitials, percentageFormatter } from 'utils/formatters';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import SalesOfRepTable from './SalesOfRepTable';

const MetricsTeamGroup = ({
  teamSalesData,
  selectedStartDate,
  selectedEndDate,
}) => {
  const { userCan, isAgencySuperUser } = usePermissions();
  const [open, setOpen] = useState(false);
  const [salesRep, setSalesRep] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const flag = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return usFlag;
      case 'CA':
        return caFlag;

      default:
        break;
    }
  };

  const sourceIcon = (name) => {
    switch (name) {
      case 'Facebook':
        return facebookLogo;
      case 'LinkedIn':
        return linkedinLogo;
      case 'Website':
        return webLogo;
      case 'Google Ads':
        return googleLogo;
      default:
        break;
    }
  };

  const setupModal = (userId, position, statuses) => {
    setSalesRep(userId);
    setSelectedPosition(position);
    setSelectedStatus(statuses);
    setOpen(true);
  };

  const expandRow = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              >
                First Call Presentations
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              >
                Closed Deals
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              >
                Conversion Rate
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {sales.map((r, rIdx) =>
              r.group.map((g, gIdx) => {
                return (
                  <tr key={g.userId}>
                    <td className="flex felx-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                      <div>
                        <span className="flex flex-col justify-center  bg-blue-500 text-white w-7 h-7 text-base rounded-3xl uppercase text-center ">
                          {getNameInitials(g.firstName, g.lastName)}
                        </span>
                      </div>
                      <div className="flex flex-col justify-center pl-2">
                        {g.firstName}
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      {/* {g.current.numOfFirstCall > 0 ? (
                        <button
                          type="button"
                          className="underline text-lg font-semibold"
                          onClick={() =>
                            setupModal(g.userId, r.teamMetrics.name, 'FirstCall')
                          }
                          style={{ color: '#eb34ab' }}
                        >
                          {g.current.numOfFirstCall}
                        </button>
                      ) : (
                        <span>{g.current.numOfFirstCall}</span>
                      )} */}
                      <span>{g.current.numOfFirstCall}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                      {/* {g.current.totalClosedDeals > 0 ? (
                        <button
                          type="button"
                          className="underline text-lg font-semibold text-green-600"
                          onClick={() =>
                            setupModal(g.userId, r.teamMetrics.name, 'ClosedDeals')
                          }
                        >
                          {g.current.totalClosedDeals}
                        </button>
                      ) : (
                        <span>{g.current.totalClosedDeals}</span>
                      )} */}
                      <span>{g.current.totalClosedDeals}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      <span>
                        {percentageFormatter(g.current.conversionRate)}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      <Link
                        to={`/sales-metrics/${g.userId}/${g.firstName}`}
                        target="_blank"
                      >
                        <button data-tip="Profile">
                          <UserCircleIcon
                            className="m-1 h-5 w-5"
                            color="green"
                          />
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  const sourceTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Source
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Booked Calls
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                No Show
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.teamMetrics.current.source.map((g, gIdx) => {
                return (
                  <tr>
                    <td className=" flex flex-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                      <div>
                        <img
                          className="h-7 w-7 object-contain"
                          src={sourceIcon(g.name)}
                          alt={g.name}
                        />
                      </div>
                      <div className="flex flex-col justify-center pl-1">
                        {g.name}
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      <span>{g.bookedCall}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                      <span>{g.noShow}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  const bookedCallTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Booked Calls
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Presentation Completed
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                No Show
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.teamMetrics.current.bookedCalls.map((g, gIdx) => {
                return (
                  <tr>
                    <td className=" break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                      {g.name}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      <span>{g.callCompleted}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                      <span>{g.callNoShow}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  const marketplaceTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Marketplace
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Closed Deals
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Booked
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.teamMetrics.current.marketplace.map((g, gIdx) => {
                return (
                  <tr>
                    <td className=" flex flex-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                      <div>
                        <img
                          className="h-9 w-9 object-contain"
                          src={flag(g.name)}
                          alt={g.name}
                        />
                      </div>
                      <div className="flex flex-col justify-center">
                        {g.name}
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                      <span>{g.closedDeals}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                      <span>{g.booked}</span>
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                    <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  return (
    <>
      {(userCan('sales.metrics.director') || isAgencySuperUser()) && (
        <div className="mt-8 flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:px-8">
            <div className="py-2 align-middle sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {expandRow(teamSalesData)}
              </div>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {sourceTable(teamSalesData)}
              </div>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {bookedCallTable(teamSalesData)}
              </div>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {marketplaceTable(teamSalesData)}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="h-4/5 overscroll-auto">
        <Modal open={open} setOpen={setOpen} as={'div'} align="top">
          <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
            <ModalHeader title="Sales list" setOpen={setOpen} />
            <SalesOfRepTable
              selectedDates={{ selectedStartDate, selectedEndDate }}
              salesRep={salesRep}
              selectedPosition={selectedPosition}
              selectedStatus={selectedStatus}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MetricsTeamGroup;
