import axios from 'axios';
import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, TrashIcon } from '@heroicons/react/outline';

import Checkbox from 'components/Forms/Checkbox';
import classNames from 'utils/classNames';
import { PencilIcon, CheckIcon, XIcon } from '@heroicons/react/solid';

const ServiceAsin = ({ field, onChange, disabled = false }) => {
  const [data, setData] = useState(field);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const onChangeAsinValue = (e) => {
    setData({ ...data, value: e.target.value });
  };

  const exitEditingAsinValue = () => {
    if (saving) return;
    setData(field);
    setEditing(false);
  };

  const updateAsinValue = () => {
    if (saving) return;

    setSaving(true);

    axios
      .put(
        `/agency/tasks/items/${field.taskItemId}/custom-fields/${field.id}`,
        { value: data.value }
      )
      .finally(() => {
        setSaving(false);
        setEditing(false);
      });
  };

  const deleteField = () => {
    axios
      .delete(
        `/agency/tasks/items/${field.taskItemId}/custom-fields/${field.id}`
      )
      .then(() => onChange());
  };

  const onChangeChecklistItem = (checklistId, checklistItemId, checked) => {
    let newData = { ...data };

    axios.put(
      `/agency/tasks/items/${field.taskItemId}/custom-fields/${field.id}/checklists/${checklistId}/items/${checklistItemId}`,
      { checked }
    );

    const checklistIdx = field.checklists.findIndex(
      (c) => c.taskChecklistId === checklistId
    );

    const checklistItemIdx = field.checklists[checklistIdx].items.findIndex(
      (i) => i.taskChecklistItemId === checklistItemId
    );

    newData['checklists'][checklistIdx]['items'][checklistItemIdx]['checked'] =
      checked;

    setData(newData);
  };

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700 font-bold">
            <span className="font-inter tracking-wide">Service ASIN</span>
            <ChevronUpIcon
              className={`${
                open ? '' : 'rotate-180 transform'
              } h-7 w-7 text-gray-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
            <div className="rounded-md">
              <div className="flex justify-between">
                <div className="flex mb-4">
                  {editing ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        className="font-bold px-1.25 py-1.25 border text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 block w-full text-lg border-gray-300 outline-none appearance-none focus:appearance-none focus:outline-none focus:ring-0"
                        value={data.value}
                        onChange={onChangeAsinValue}
                        disabled={saving || disabled}
                      />
                      <div className="flex ml-2">
                        <CheckIcon
                          className={classNames(
                            'h-5 w-5 text-secondary',
                            saving ? 'cursor-wait' : 'cursor-pointer'
                          )}
                          onClick={updateAsinValue}
                        />
                        <XIcon
                          className={classNames(
                            'h-5 w-5 text-secondary',
                            saving ? 'cursor-wait' : 'cursor-pointer'
                          )}
                          onClick={exitEditingAsinValue}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <p className="font-bold text-lg p-1.25">{data.value}</p>
                      {!disabled && (
                        <PencilIcon
                          className="ml-3 h-5 w-5 cursor-pointer text-secondary"
                          onClick={() => setEditing(true)}
                        />
                      )}
                    </div>
                  )}
                </div>
                {!disabled && (
                  <TrashIcon
                    className="ml-3 h-5 w-5 cursor-pointer text-secondary"
                    onClick={deleteField}
                  />
                )}
              </div>

              {field.checklists.map((checklist) => (
                <div key={checklist.taskChecklistId}>
                  <p>{checklist.name}</p>
                  <ul>
                    {checklist.items.map((item) => (
                      <li
                        className="flex items-center mt-2"
                        key={item.taskChecklistItemId}
                      >
                        <Checkbox
                          checked={item.checked}
                          disabled={disabled}
                          onChange={(e) =>
                            onChangeChecklistItem(
                              checklist.taskChecklistId,
                              item.taskChecklistItemId,
                              e.target.checked
                            )
                          }
                        />
                        <label className="ml-1 text-12">{item.value}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ServiceAsin;
