import React, { useState } from 'react';
import axios from 'axios';
import { DocumentTextIcon, TrashIcon } from '@heroicons/react/outline';
import { dateFormatter } from 'utils/formatters';
import AddNotesPopover from './AddNotesPopover';
import { ConfirmationModal } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import usePermissions from 'hooks/usePermissions';
import useAlert from 'hooks/useAlert';
import { fetchSubscription } from '../subscriptionSlice';

const Notes = ({ subscription }) => {
  const { userCan } = usePermissions();
  const { notes, subscription_id } = subscription;
  const [isOpen, setIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState(null);
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();
  const { agencyClient } = useSelector((state) => state.agencyClient);

  const deleteThisNote = (note) => {
    setNote(note);
    setIsOpen(true);
  };

  const onDeleteNote = async () => {
    setSaving(true);
    try {
      const res = await axios.delete(
        `subscriptions/${subscription_id}/notes/${note.note_id}`
      );

      if (res.data.data.code === 0) {
        dispatch(fetchSubscription(subscription_id));
        alertSuccess('Note deleted', res.data.data.message);
      } else {
        alertError('Note not deleted', res.data.data.message);
      }
    } catch (error) {
      //console.log(error.response.data);
      alertError('Note not deleted', error.response.data.message);
    } finally {
      setIsOpen(false);
      setSaving(false);
    }
  };

  return (
    <>
      <div className="sm:p-5">
        <div className="text-sm text-gray-500  mb-1 flex items-center">
          <span className="text-gray-400">NOTES</span>
          <span className="px-2.5 text-gray-400 inline-block">|</span>
          {userCan('clients.subscription.note.add') &&
          !agencyClient.deletedAt ? (
            <AddNotesPopover subscription={subscription} />
          ) : (
            <></>
          )}
        </div>
        {notes && notes.length > 0 ? (
          notes.map((note) => {
            return (
              <div className="flex sm:py-1 items-start" key={note.note_id}>
                <div className="flex-none w-6">
                  <DocumentTextIcon className="w-4 h-4 text-gray-600" />
                </div>
                <div className="w-4/6 text-sm pl-1 pr-5">
                  <span className="whitespace-pre-wrap">
                    {note.description}
                  </span>
                  <br />
                  <span className="italic text-gray-500 font-inter">
                    - {note.commented_by}
                  </span>
                </div>
                <div className="sm:pl-4 w-2/6 text-sm text-gray-400">
                  <div className="flex items-center">
                    <span className="font-inter text-xs">
                      {dateFormatter(note.commented_time)}
                    </span>
                    {userCan('clients.subscription.note.delete') &&
                    !agencyClient.deletedAt ? (
                      <button
                        type="button"
                        className="hover:text-red-700"
                        onClick={() => deleteThisNote(note)}
                      >
                        <TrashIcon className="ml-2 w-5 h-5 inline" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <span className="sm:py-1 text-sm text-gray-600">
            There are no notes added for this subscription.
          </span>
        )}
      </div>
      <ConfirmationModal
        title="Delete Note"
        content={`Are you sure you want to delete this note?`}
        open={isOpen}
        setOpen={setIsOpen}
        onOkClick={onDeleteNote}
        onCancelClick={() => setIsOpen(false)}
        okLoading={saving}
        showOkLoading={true}
      />
    </>
  );
};
export default Notes;
