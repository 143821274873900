import React, { useRef } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, array } from 'yup';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoice } from '../invoiceSlice';
import {
  currencyFormatter,
  dateFormatter,
  floatFormatter,
} from 'utils/formatters';
import useInvoice from 'hooks/useInvoice';
import usePermissions from 'hooks/usePermissions';
import { TrashIcon, PlusIcon } from '@heroicons/react/solid';
import useAlert from 'hooks/useAlert';
import ReasonForUpdateModal from './ReasonForUpdateModal';
import FormikErrorNotification from 'components/FormikErrorNotification';

const EditItemsModal = ({ invoice, open, setOpen }) => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const status = useInvoice(invoice);
  const { activeOneTimeAddons } = useSelector((state) => state.zoho);

  const itemObj = {
    name: '',
    description: '',
    price: 0,
    quantity: 1,
  };

  const productObj = {
    code: 'new',
    ...itemObj,
  };

  const [initialValues, setInitialValues] = useState({
    invoiceId: '',
    invoice_items: [],
    old_items: [],
  });
  const [subscription, setSubscription] = useState(null);
  const [commissions, setCommissions] = useState(null);
  const [computing, setComputing] = useState(false);
  const [isOpenReasonModal, setIsOpenReasonModal] = useState(false);
  const [reason, setReason] = useState('');
  const formRef = useRef();

  const descriptionCheck = (string) => /[<>]/.test(string);
  const validationSchema = object().shape({
    invoiceId: string().required('Required'),
    invoice_items: array()
      .of(
        object().shape({
          code: string().nullable(),
          name: string().required('Name is required'),
          description: string()
            .test(
              'Description must not have any (<) less than or (>) greater than symbol',
              'Description must not have any (<) less than or (>) greater than symbol',
              (value) => !descriptionCheck(value)
            )
            .nullable(),
          price: number().min(0).required('Required'),
          quantity: number()
            .min(1, 'qty must be greater than or equal to 1')
            .required('Required'),
        })
      )
      .required()
      .min(1),
  });

  useEffect(() => {
    const getAssociatedSubscription = async () => {
      try {
        const response = await axios.get(
          `/subscriptions/${invoice.subscriptions[0].subscription_id}`
        );
        setSubscription(response.data.data);
      } catch (error) {
        alertError('Error fetching subscription', error.response.data.message);
      }
    };
    const getAssociatedSubscriptionCommissions = async () => {
      const response = await axios.get(
        `/agency/commissions/account/${invoice.reference_id}`
      );

      setCommissions(response.data.data);
    };

    if (invoice && open) {
      setInitialValues({
        ...initialValues,
        invoiceId: invoice.invoice_id,
        invoice_items: invoice.invoice_items,
        old_items: invoice.invoice_items,
      });
      getAssociatedSubscription();
      getAssociatedSubscriptionCommissions();
    }
  }, [invoice, open]);

  const itemReadOnly = (code) => {
    if (subscription?.z.plan.plan_code === code) {
      return true;
    }
    if (subscription?.z.addons.find((a) => a.addon_code === code)) {
      return true;
    }
    return false;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/lineitems`,
        reason === '' ? { values } : { values, reason }
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('Invoice items added', res.data.data.message);
    } catch (error) {
      if (
        error &&
        error.response.data.message ===
          'Please enter the reason for updating a sent invoice.'
      ) {
        setIsOpenReasonModal(true);
      } else {
        alertError('Failed to add invoice items', error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onAddReason = async (reason) => {
    setIsOpenReasonModal(false);
    setReason(reason);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onAddonChange = (index, value, setFieldValue) => {
    const addon = activeOneTimeAddons.find((a) => a.addon_code === value);

    //console.log(addon, 'aa');
    if (addon) {
      setFieldValue(`invoice_items.${index}.code`, value);
      //setFieldValue(`invoice_items.${index}.product_id`, addon.product_id);
      setFieldValue(`invoice_items.${index}.name`, addon.name);
      setFieldValue(`invoice_items.${index}.description`, addon.description);
      setFieldValue(
        `invoice_items.${index}.price`,
        addon.price_brackets[0].price
      );
    } else {
      setFieldValue(`invoice_items.${index}.code`, '');
      //setFieldValue(`invoice_items.${index}.product_id`, '');
      setFieldValue(`invoice_items.${index}.name`, '');
      setFieldValue(`invoice_items.${index}.description`, '');
      setFieldValue(`invoice_items.${index}.price`, 0);
    }
  };

  const addCommission = async (push) => {
    setComputing(true);
    const response = await axios.post(
      `agency/invoices/${invoice.invoice_id}/commissions`,
      {
        accountId: invoice.reference_id,
        invoiceDate: invoice.invoice_date,
        invoiceId: invoice.invoice_id,
        invoiceNumber: invoice.invoice_number,
        isAuto: false,
      }
    );

    const { data } = response.data;

    data.forEach((item) => {
      let lineitem = { ...item };
      lineitem.tag = 'commission';
      push(lineitem);
    });

    setComputing(false);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-4xl my-24 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Edit Invoice"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <ReasonForUpdateModal
          open={isOpenReasonModal}
          setOpen={setIsOpenReasonModal}
          onAddReason={onAddReason}
        />

        <div className="overflow-y-auto" style={{ height: '65vh' }}>
          <div className="px-8 pt-4 pb-16 space-y-4">
            <h5 className="font-bold text-xl border-b pb-4 flex justify-between">
              {invoice.invoice_number}
            </h5>
            <div className="sm:grid sm:grid-cols-2 sm:gap-3">
              <div className="self-start text-sm col-span-1 text-right sm:text-left mb-3 sm:mb-0">
                <span className="text-gray-400">Bill To</span>

                <p className="mt-3 mb-0">{invoice.customer_name}</p>
                {invoice.billing_address && (
                  <>
                    <p className="mb-0 text-gray-900">
                      {invoice.billing_address.street}
                    </p>
                    <p className="mb-0 text-gray-900">
                      {invoice.billing_address.street2}
                    </p>
                    <p className="mb-0 text-gray-900">
                      {[
                        invoice.billing_address.city,
                        invoice.billing_address.state,
                        invoice.billing_address.zip,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </p>
                    <p className="mb-0 text-gray-900">
                      {invoice.billing_address.country}
                    </p>
                  </>
                )}
              </div>
              <div className="col-span-1 grid grid-cols-3 gap-2 text-sm text-left">
                <div className="col-span-1 text-gray-400 flex justify-end">
                  <span className="mr-2">Invoice Date</span>
                </div>
                <span className="col-span-2">
                  {dateFormatter(invoice.invoice_date)}
                </span>
                <div className="col-span-1 text-gray-400 flex justify-end">
                  <span className="mr-2">Terms</span>
                </div>
                <span className="col-span-2">
                  {invoice.payment_terms_label}
                </span>
                <div className="col-span-1 text-gray-400 flex justify-end">
                  <span className="mr-2">Due Date</span>
                </div>
                <span className="col-span-2">
                  {dateFormatter(invoice.due_date)}
                </span>
                <div className="col-span-1 text-gray-400 flex justify-end">
                  <span className="mr-2">Reference #</span>
                </div>
                <span className="col-span-2">{invoice.reference_id}</span>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              innerRef={formRef}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <Form>
                  <FormikErrorNotification />
                  <div className="overflow-auto">
                    <table className="min-w-full divide-y divide-gray-200 table-fixed w-1024px mt-3">
                      <thead className="bg-gray-200">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider text-left w-7/12"
                          >
                            Item & Description
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider text-right w-2/12"
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider text-right w-2/12"
                          >
                            Rate
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider text-right w-1/12"
                          >
                            Amount
                          </th>
                          {subscription &&
                            status.isEditable(invoice.status) && (
                              <th
                                scope="col"
                                className="px-4 py-2 text-xs font-bold uppercase text-gray-800 tracking-wider text-right w-2/12"
                              >
                                &nbsp;
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="invoice_items">
                          {({ insert, remove, push }) => (
                            <>
                              {values.invoice_items.length > 0 &&
                                values.invoice_items.map((item, index) => (
                                  <>
                                    <tr className="bg-white" key={index}>
                                      {itemReadOnly(item.code) ? (
                                        <>
                                          <td className="p-4 text-sm text-gray-900">
                                            {item.name}
                                            <br />
                                            <span className="text-gray-500 text-xs whitespace-pre-wrap">
                                              {item.description}
                                            </span>
                                          </td>
                                          <td className="p-4 text-sm text-gray-900 text-right">
                                            {item.quantity}
                                          </td>
                                          <td className="p-4 text-sm text-gray-900 text-right">
                                            {floatFormatter(item.price)}
                                          </td>
                                          <td className="p-4 text-sm text-gray-900  text-right">
                                            {floatFormatter(item.item_total)}
                                          </td>
                                          <td>&nbsp;</td>
                                        </>
                                      ) : (
                                        <>
                                          <td className="p-4 text-sm text-gray-900 relative">
                                            {'code' in item &&
                                            item.code !== '' ? (
                                              <Field
                                                name={`invoice_items.${index}.code`}
                                                as="select"
                                                className="form-select disabled-white"
                                                onChange={(e) =>
                                                  onAddonChange(
                                                    index,
                                                    e.target.value,
                                                    setFieldValue
                                                  )
                                                }
                                                disabled={
                                                  item.tag ? true : false
                                                }
                                              >
                                                <option value="">
                                                  Select One
                                                </option>
                                                {activeOneTimeAddons?.map(
                                                  (addon) => (
                                                    <option
                                                      key={addon.addon_code}
                                                      value={addon.addon_code}
                                                    >
                                                      {addon.name}
                                                    </option>
                                                  )
                                                )}
                                              </Field>
                                            ) : (
                                              <Field
                                                name={`invoice_items.${index}.name`}
                                                className="form-input disabled-white"
                                                type="text"
                                              />
                                            )}
                                            <ErrorMessage
                                              name={`invoice_items.${index}.name`}
                                              component="div"
                                              className="text-red-700 font-normal text-1xs absolute -mt-1"
                                            />
                                          </td>
                                          <td className="p-4 text-sm text-gray-900 text-right relative">
                                            <Field
                                              name={`invoice_items.${index}.quantity`}
                                              className="form-input disabled-white text-right"
                                              type="number"
                                            />
                                            <ErrorMessage
                                              name={`invoice_items.${index}.quantity`}
                                              component="div"
                                              className="text-red-700 font-normal  text-1xs absolute -mt-1"
                                            />
                                          </td>
                                          <td className="p-4 text-sm text-gray-900 text-right relative">
                                            <Field
                                              name={`invoice_items.${index}.price`}
                                              className="form-input disabled-white text-right"
                                              type="number"
                                            />
                                            <ErrorMessage
                                              name={`invoice_items.${index}.price`}
                                              component="div"
                                              className="text-red-700 font-normal  text-1xs absolute -mt-1"
                                            />
                                          </td>
                                          <td className="p-4 text-sm text-gray-900 text-right">
                                            {currencyFormatter(
                                              values.invoice_items[index]
                                                .price *
                                                values.invoice_items[index]
                                                  .quantity,
                                              invoice.currency_code
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {subscription &&
                                              status.isEditable(
                                                invoice.status
                                              ) &&
                                              userCan(
                                                'invoices.lineitem.delete'
                                              ) && (
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                  className="text-gray-500 hover:text-red-700"
                                                >
                                                  <TrashIcon className="w-5 h-5" />
                                                </button>
                                              )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                    {!itemReadOnly(item.code) && (
                                      <tr
                                        className="bg-white"
                                        key={`desc-${index}`}
                                      >
                                        <td
                                          colSpan={4}
                                          className="px-4 pb-4 text-sm text-gray-900 "
                                        >
                                          <Field
                                            name={`invoice_items.${index}.description`}
                                            as="textarea"
                                            rows={item.tag ? 4 : 2}
                                            className="form-input"
                                            maxLength={2000}
                                          />
                                          {/* <p
                                            onClick={() =>
                                              setIsOpenReasonModal(true)
                                            }
                                            className="cursor-pointer underline text-red-600 text-sm font-normal"
                                          >
                                            Add reason
                                          </p> */}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              <tr>
                                <td colSpan={5}>
                                  <div className="px-4 space-x-3 divide-x border-b py-4">
                                    {userCan('invoices.lineitem.add') && (
                                      <>
                                        <ButtonLink
                                          classes="tracking-wider font-bold py-1"
                                          color="red"
                                          onClick={() => push(itemObj)}
                                        >
                                          <PlusIcon className="w-5 h-5 inline mr-2" />{' '}
                                          Add Line Item
                                        </ButtonLink>
                                        <ButtonLink
                                          classes="tracking-wider font-bold pl-3 py-1"
                                          color="red"
                                          onClick={() => push(productObj)}
                                        >
                                          <PlusIcon className="w-5 h-5 inline mr-2" />{' '}
                                          Add Product Item
                                        </ButtonLink>
                                      </>
                                    )}
                                    {commissions &&
                                      commissions.computeEnabled &&
                                      userCan(
                                        'invoices.lineitem.commission.add'
                                      ) && (
                                        <ButtonLink
                                          classes="tracking-wider font-bold pl-3 py-1"
                                          color="red"
                                          onClick={() => addCommission(push)}
                                          showLoading={true}
                                          loading={computing}
                                        >
                                          <PlusIcon className="w-5 h-5 inline mr-2" />{' '}
                                          Add Commission
                                        </ButtonLink>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-2 sm:grid sm:grid-cols-4 sm:gap-3">
                    <div className="col-span-2 flex justify-start items-end">
                      <div className="flex items-center space-x-4 px-4">
                        <ButtonLink
                          classes="tracking-wider font-bold"
                          color="red"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </ButtonLink>
                        <Button
                          classes="border-0 font-bold tracking-wider "
                          bgColor="red-700"
                          hoverColor="red-400"
                          roundedSize="2xl"
                          textColor="white"
                          px={12}
                          py={1.5}
                          shadow=""
                          type="submit"
                          showLoading={true}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="grid grid-cols-4 gap-y-5 text-sm text-right">
                        <div className="col-span-2 sm:col-span-3 text-gray-500">
                          Sub Total
                        </div>
                        <div className="col-span-2 sm:col-span-1 pr-4">
                          {floatFormatter(invoice.sub_total)}
                        </div>

                        {invoice.taxes &&
                          invoice.taxes.map((tax, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div className="col-span-2 sm:col-span-3 text-gray-500">
                                  {tax.tax_name}
                                </div>
                                <div className="col-span-2 sm:col-span-1 pr-4">
                                  {currencyFormatter(
                                    tax.tax_amount,
                                    invoice.currency_code
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          })}

                        <div className="col-span-2 sm:col-span-3 text-gray-500 font-bold">
                          Total
                        </div>
                        <div className="col-span-2 sm:col-span-1 font-medium pr-4">
                          {currencyFormatter(
                            invoice.total,
                            invoice.currency_code
                          )}
                        </div>
                        {invoice.payment_made > 0 && (
                          <>
                            <div className="col-span-2 sm:col-span-3 text-gray-500">
                              Payment Made
                            </div>
                            <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                              (-) {floatFormatter(invoice.payment_made)}
                            </div>
                          </>
                        )}
                        {invoice.credits_applied > 0 && (
                          <>
                            <div className="col-span-2 sm:col-span-3 text-gray-500">
                              Credits Applied
                            </div>
                            <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                              (-) {floatFormatter(invoice.credits_applied)}
                            </div>
                          </>
                        )}
                        {invoice.write_off_amount > 0 && (
                          <>
                            <div className="col-span-2 sm:col-span-3 text-gray-500">
                              Write Off Amount
                            </div>
                            <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                              (-) {floatFormatter(invoice.write_off_amount)}
                            </div>
                          </>
                        )}
                        <div className="col-span-4 bg-gray-100 grid grid-cols-4 py-2">
                          <div className="col-span-2 sm:col-span-3 text-gray-500">
                            Balance Due
                          </div>
                          <div className="col-span-2 sm:col-span-1  pr-4">
                            {currencyFormatter(
                              invoice.balance,
                              invoice.currency_code
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditItemsModal;
