import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { getNameInitials, percentageFormatter } from 'utils/formatters';
import Card from 'components/Card';

const MetricsCard = ({ label, data, footerData, icon = false }) => {
  return (
    <Card>
      <div className="w-full flex flex-col pb-5 text-center ">
        <p className="text-gray-500 text-base mb-1">{label}</p>
        <p className="my-1 text-gray-600 font-normal text-3xl font-body">
          {data}
        </p>
        {footerData && (
          <div className="flex justify-center items-center mt-1">
            {footerData.color === 'green' ? (
              <ChevronUpIcon className={`text-green-800 h-4 w-4`} />
            ) : (
              <ChevronDownIcon className={`text-red-800 h-4 w-4`} />
            )}
            <p className={`text-base text-${footerData.color}-800 ml-1`}>
              {footerData.data}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default MetricsCard;
