import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';

import DatePicker from 'features/datePicker/DatePicker';
import MetricCard from 'features/clients/Client/Account/Advertising/components/MetricCard/MetricCard';

import { currencyFormatter, numberFormatter } from 'utils/formatters';

const Forecast = () => {
  const [overview, setOverview] = useState({});
  const [loading, setLoading] = useState(true);

  const { account, marketplace } = useSelector((state) => state.account);
  const { range: dateRange } = useSelector((state) => state.datePicker);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/account/advertising/analytics', {
        params: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          ...dateRange,
          attributes: ['cost', 'sales'],
        },
      });

      if (isSubscribed) {
        setOverview(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, dateRange]);

  const numberOfDays = useMemo(() => {
    const { startDate, endDate } = dateRange;

    return moment(endDate).diff(moment(startDate), 'days') + 1;
  }, [dateRange]);

  const remainingDaysOfMonth = useMemo(() => {
    const { endDate } = dateRange;

    return moment(endDate).endOf('month').diff(moment(endDate), 'days');
  }, [dateRange]);

  const avgCost = useMemo(() => {
    return overview.cost ? overview.cost / numberOfDays : 0;
  }, [numberOfDays, overview.cost]);

  const avgSales = useMemo(() => {
    return overview.sales ? overview.sales / numberOfDays : 0;
  }, [numberOfDays, overview.sales]);

  return (
    <div>
      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-3">
          <DatePicker
            position="left"
            showLabel={false}
            buttonBg="grayscale-400"
          />
        </div>
      </div>

      <div className="my-10">
        {/* High level Overview */}
        <div className="grid grid-cols-4 gap-5">
          {[
            { title: 'Ad Sales', value: overview.sales },
            { title: 'Average Ad Sales', value: avgSales },
            { title: 'Ad Spend', value: overview.cost },
            { title: 'Average Ad Spend', value: avgCost },
          ].map(({ title, value }) => (
            <MetricCard
              key={title}
              value={value}
              title={title}
              prefixValue="$"
              loading={loading}
              showPercentage={false}
              showCalculation={false}
              formatter={numberFormatter}
              className="bs-shadow py-5 rounded-3xl bg-grayscale-300"
            />
          ))}
        </div>

        <div className="my-8">
          <table className="divide-y divide-grayscale-300 w-full table-fixed font-sourceSansPro">
            <thead className="bg-success-dark">
              <tr className="">
                <th></th>
                <th className="p-3 text-13 font-bold text-white border border-grayscale-500">
                  Estimated Sales
                </th>
                <th className="p-3 text-13 font-bold text-white border border-grayscale-500">
                  Estimated Spend
                </th>
              </tr>
            </thead>

            <tbody>
              {[
                { title: 'Next 7 Days', multiplier: 7 },
                { title: 'Next 15 Days', multiplier: 15 },
                {
                  title: 'Until End of month',
                  multiplier: remainingDaysOfMonth,
                },
              ].map(({ multiplier, title }) => (
                <tr key={title}>
                  <td className="px-3 py-2 text-13 text-grayscale-800 border border-grayscale-500">
                    {title}
                  </td>
                  <td className="px-3 py-2 text-13 text-center text-grayscale-800 border border-grayscale-500">
                    {currencyFormatter(avgSales * multiplier)}
                  </td>
                  <td className="px-3 py-2 text-13 text-center text-grayscale-800 border border-grayscale-500">
                    {currencyFormatter(avgCost * multiplier)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Forecast;
