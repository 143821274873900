import useAlert from './useAlert';

const useLink = () => {
  const { alertSuccess } = useAlert();

  /**
   *
   * @param {string} link
   * @param {string} type relative | absolute
   */
  const copyToClipboard = (link, type = 'relative') => {
    const url =
      type === 'relative' ? `${process.env.REACT_APP_CLIENT_URL}${link}` : link;

    navigator.clipboard.writeText(url);
    alertSuccess('Link copied to clipboard', url);
  };

  return {
    copyToClipboard,
  };
};

export default useLink;
