import axios from 'axios';
import Button from 'components/Button';
import cardWireframe from 'assets/images/card-wireframe.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PencilIcon } from '@heroicons/react/solid';
import { LinkIcon, MailIcon, EyeIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';
import QuoteModal from './QuoteModal';
import { fetchSalesReps } from '../salesSlice';
import { ConfirmationModal, Table } from 'components';
import useQueryParams from 'hooks/useQueryParams';
import { Link } from 'react-router-dom';
import useBodyClass from 'hooks/useBodyClass';
import { DateTime } from 'luxon';
import Badge from 'components/Badge';
import classnames from 'classnames';
import { columnClasses, headerClasses } from 'utils/table';
import ReactTooltip from 'react-tooltip';
import useLink from 'hooks/useLink';
import classNames from 'utils/classNames';
import useAlert from 'hooks/useAlert';
import UserProfileAvatar from 'components/UserProfileAvatar';
import { useUsers } from 'features/users';

const Quotes = () => {
  useBodyClass('client-profile');
  const { userCan } = usePermissions();
  const { copyToClipboard } = useLink();
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { client, salesReps } = useSelector((state) => state.sales);
  const [quotes, setQuotes] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const { params, updateParams } = useQueryParams({
    page: 1,
    pageSize: 10,
    sort: 'createdAt:desc',
    salesClientId: client?.salesClientId,
  });
  const [openSend, setOpenSend] = useState(false);
  const [sending, setSending] = useState(false);

  const { fetchAllUsers } = useUsers();

  useEffect(async () => {
    if (!salesReps) dispatch(fetchSalesReps(fetchAllUsers));
  }, [salesReps]);

  const getQuotes = async () => {
    setLoading(true);
    await axios
      .get('/agency/sales/quotes', { params })
      .then((res) => setQuotes(res.data.data))
      .catch()
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (client && !loading) getQuotes();
  }, [client, params]);

  const tableColumns = [
    {
      dataField: 'quoteNumber',
      text: 'Quote Number',
      sort: true,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell) => cell.padStart(5, '0'),
    },
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerStyle: {
        minWidth: '250px',
      },
      formatter: (cell, row) => (
        <button
          type="button"
          onClick={() => {
            setSelectedQuote(row);
            setOpenQuoteModal(true);
          }}
          className="text-secondary-light"
        >
          {cell}
        </button>
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        minWidth: '150px',
      },
      headerClasses: `${headerClasses} text-center`,
      formatter: (cell, row) => {
        return (
          <div className="flex flex-col items-center text-center">
            <Badge
              color={classnames({
                gray: cell === 'draft',
                yellow: cell === 'sent',
                blue: cell === 'seen',
                green: cell === 'accepted',
              })}
              classes="capitalize text-center w-40 justify-center"
              rounded="full"
              padding="py-2"
              textSize="sm"
              fontFamily=""
            >
              {cell}
            </Badge>
            {cell === 'sent' && (
              <sub className="text-warning-dark pt-1">
                {DateTime.fromISO(row.sentAt).toFormat('yyyy-LL-dd hh:mm a')}
              </sub>
            )}
            {cell === 'seen' && (
              <sub className="text-secondary-light pt-1">
                {DateTime.fromISO(row.seenAt).toFormat('yyyy-LL-dd')}
              </sub>
            )}
            {cell === 'accepted' && (
              <sub className="text-success-light pt-1">
                {DateTime.fromISO(row.acceptedAt).toFormat('yyyy-LL-dd')}
              </sub>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      sort: false,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => <UserProfileAvatar user={row.creator} />,
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      sort: true,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => (
        <span className="text-sm">
          {DateTime.fromISO(cell).toFormat('yyyy-LL-dd hh:mm a')}
        </span>
      ),
    },
    {
      dataField: 'expiresAt',
      text: 'Expires',
      sort: true,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => (
        <span className={classNames(row.isExpired && 'text-error', 'text-sm')}>
          {row.isExpired ? 'Expired' : DateTime.fromISO(cell).toRelative()}
        </span>
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      headerClasses: `${headerClasses} text-center`,
      classes: columnClasses,
      formatter: (cell, row) => {
        return (
          <div className="flex items-center justify-center space-x-2">
            <button
              type="button"
              onClick={() => {
                setSelectedQuote(row);
                setOpenQuoteModal(true);
              }}
              disabled={row.status === 'accepted'}
              className="disabled:opacity-20"
            >
              <PencilIcon
                className="w-5 h-5 inline text-secondary"
                data-tip="Edit Quote"
              />
              <ReactTooltip
                place="bottom"
                className="max-w-xs text-black"
                backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              />
            </button>

            <Link
              to={`/quote/preview/${row.quoteId}`}
              target="_blank"
              className=""
            >
              <EyeIcon
                className="w-5 h-5 inline text-secondary"
                data-tip="Open preview in new tab"
              />
              <ReactTooltip
                place="bottom"
                className="max-w-xs text-black"
                backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              />
            </Link>
            <button
              type="button"
              onClick={() => copyToClipboard(`/quote/show/${row.token}`)}
              disabled={row.isExpired}
              className="disabled:opacity-20"
            >
              <LinkIcon
                className="w-5 h-5 inline text-secondary"
                data-tip="Copy Share URL"
              />
              <ReactTooltip
                place="bottom"
                className="max-w-xs text-black"
                backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              />
            </button>
            <button
              type="button"
              onClick={() => {
                setSelectedQuote(row);
                setOpenSend(true);
              }}
              disabled={row.isExpired}
              className="disabled:opacity-20"
            >
              <MailIcon
                className="w-5 h-5 inline text-secondary"
                data-tip="Email quote"
              />
              <ReactTooltip
                place="bottom"
                className="max-w-xs text-black"
                backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              />
            </button>
          </div>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      ...params,
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };

  const onSend = async () => {
    setSending(true);
    await axios
      .post(`/agency/sales/quotes/${selectedQuote.quoteId}/send`)
      .then((response) => {
        alertSuccess('Success', response.data.message);
        getQuotes();
      })
      .catch((error) =>
        alertError('Failed to send quote', error.response.data.message)
      )
      .finally(() => {
        setSending(false);
        setOpenSend(false);
      });
  };

  return (
    <>
      <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
        <div className="flex justify-between">
          <h4 className="col-span-1 text-2xl font-bold">Quotes</h4>
          {userCan('sales.profiles.quotes.manage') &&
            client?.status === 'quote' && (
              <Button
                roundedSize="3xl"
                classes="tracking-wider font-bold"
                px={7}
                py={2}
                textSize="sm"
                color="green"
                onClick={() => {
                  setSelectedQuote(null);
                  setOpenQuoteModal(true);
                }}
              >
                Create Quote
              </Button>
            )}
        </div>
        <div className="col-span-1 mt-4">
          <Table
            columns={tableColumns}
            data={quotes}
            onTableChange={onTableChange}
            params={params}
            keyField="quoteId"
            defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
            loading={loading}
            noDataDisplay={
              <div className=" flex flex-col items-center space-y-4">
                <div className="flex space-x-8 justify-center">
                  <img src={cardWireframe} className="w-1/4 sm:w-auto" />
                  <img src={cardWireframe} className="w-1/4 sm:w-auto" />
                  <img src={cardWireframe} className="w-1/4 sm:w-auto" />
                </div>
                <h4 className="font-bold text-2xl text-gray-700 text-center leading-tight mb-2">
                  No quotes available <br />
                  <span className="font-normal text-base text-gray-500 ">
                    You haven't created a quote yet.
                  </span>
                </h4>
              </div>
            }
          />
        </div>
      </div>

      <QuoteModal
        open={openQuoteModal}
        setOpen={setOpenQuoteModal}
        quote={selectedQuote}
        onRefresh={getQuotes}
      />

      <ConfirmationModal
        title={`Send Quote`}
        content={
          <div className="flex flex-col">
            <span className="text-base pb-4">{selectedQuote?.title}</span>
            <span>
              Send to <b>{client?.email}</b>?
            </span>
          </div>
        }
        open={openSend}
        setOpen={setOpenSend}
        onOkClick={onSend}
        onCancelClick={() => setOpenSend(false)}
        showOkLoading={true}
        okLoading={sending}
      />
    </>
  );
};

export default Quotes;
