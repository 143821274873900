import React, { useEffect, useState } from 'react';
import CommentForm from './CommentForm';

const MockupDescription = ({ listingMockup, revisions, onUpdateRevision }) => {
  const attr = {
    existingCopy: '',
    newCopy: '',
    listingMockupTextId: '',
    mockupTextRevision: [],
  };
  const data = {
    title: { ...attr, order: 1, type: 'TITLE' },
    bpOne: { ...attr, order: 2, type: 'BULLET' },
    bpTwo: { ...attr, order: 3, type: 'BULLET' },
    bpThree: { ...attr, order: 4, type: 'BULLET' },
    bpFour: { ...attr, order: 5, type: 'BULLET' },
    bpFive: { ...attr, order: 6, type: 'BULLET' },
    keywords: { ...attr, order: 7, type: 'KEYWORDS' },
    description: { ...attr, order: 8, type: 'DESCRIPTION' },
  };
  const [initialValues, setInitialValues] = useState(data);

  useEffect(() => {
    if (listingMockup.listingMockupText.length > 0) {
      const getItem = (order) => {
        return listingMockup.listingMockupText.find((i) => i.order === order);
      };

      setInitialValues({
        title: getItem(1),
        bpOne: getItem(2),
        bpTwo: getItem(3),
        bpThree: getItem(4),
        bpFour: getItem(5),
        bpFive: getItem(6),
        keywords: getItem(7),
        description: getItem(8),
      });
    }
  }, [listingMockup]);

  const getRevision = (listingMockupTextId) => {
    if (revisions) {
      const rev = revisions.data.find(
        (r) => r.id === listingMockupTextId && r.type === 'text'
      );
      return rev;
    }
    return null;
  };

  return (
    <>
      <div className="flex justify-between">
        <p
          className="font-normal text-base leading-6 text-grayscale-900"
          dangerouslySetInnerHTML={{ __html: initialValues.title.newCopy }}
        />
        {revisions.open && (
          <CommentForm
            id={initialValues.title.listingMockupTextId}
            revision={getRevision(initialValues.title.listingMockupTextId)}
            type="text"
            update={onUpdateRevision}
            label='title'
          />
        )}
      </div>
      <div>
        <p className="font-bold mb-4 text-grayscale-900 text-sm">
          About this Item
        </p>
        <ul className="font-normal text-grayscale-900 text-sm list-disc pl-4 space-y-3">
          {['bpOne', 'bpTwo', 'bpThree', 'bpFour', 'bpFive'].map((r) => {
            return (
              <div key={r} className="flex justify-between">
                <li
                  dangerouslySetInnerHTML={{
                    __html: initialValues[r].newCopy,
                  }}
                />
                {revisions.open && (
                  <CommentForm
                    id={initialValues[r].listingMockupTextId}
                    revision={getRevision(initialValues[r].listingMockupTextId)}
                    type="text"
                    update={onUpdateRevision}
                    label={r}
                  />
                )}
              </div>
            );
          })}
        </ul>
      </div>
      <div>
        <p className="font-bold mb-4 text-grayscale-900 text-sm">Keywords</p>
        <div className="flex justify-between">
          <p className="font-normal text-grayscale-900 text-sm">
            {initialValues.keywords.newCopy}
          </p>
          {revisions.open && (
            <CommentForm
              id={initialValues.keywords.listingMockupTextId}
              revision={getRevision(initialValues.keywords.listingMockupTextId)}
              type="text"
              update={onUpdateRevision}
              label='keywords'
            />
          )}
        </div>
      </div>
      <div>
        <p className="font-bold mb-4 text-grayscale-900 text-sm">Description</p>
        <div className="flex justify-between">
          <p className="font-normal text-grayscale-900 text-sm">
            {initialValues.description.newCopy}
          </p>
          {revisions.open && (
            <CommentForm
              id={initialValues.description.listingMockupTextId}
              revision={getRevision(
                initialValues.description.listingMockupTextId
              )}
              type="text"
              update={onUpdateRevision}
              label='description'
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MockupDescription;
