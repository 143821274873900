import React from 'react';
import { Route, Switch } from 'react-router';

import PageHeader from 'components/PageHeader';
import Initial from './pages/Initial';

const DataSyncManager = () => {
  const tabs = [
    {
      name: 'Initial',
      href: '/data-sync',
      path: '/data-sync',
      component: Initial,
      exact: true,
    },
  ];

  return (
    <div>
      <PageHeader title="Data Sync" tabs={tabs} />

      <Switch>
        {tabs.map((tab) => {
          const { component: Component } = tab;
          return (
            <Route
              exact={tab.exact}
              key={tab.name}
              path={tab.path}
              render={() => <Component key={tab.href} />}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default DataSyncManager;
