import axios from 'axios';

import { useState, useEffect } from 'react';
import { currencyFormatter } from 'utils/formatters';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';

import ChartToolTip from 'components/Charts/ChartTooltip';

const options = [
  {
    title: 'Active One-Time Partners',
    summary: {
      key: 'upsoldAmountToActiveClients',
      params: {
        subscriptionPaymentOption: 'One Time',
        attributes: ['upsoldAmountToActiveClients'],
      },
    },
  },
  {
    title: 'Completed One-Time Partners',
    summary: {
      key: 'upsoldAmountToInactiveClients',
      params: {
        subscriptionPaymentOption: 'One Time',
        attributes: ['upsoldAmountToInactiveClients'],
      },
    },
  },
];

const UpsellValue = ({ year, assigneeId = '' }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [records, setRecords] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    'Active One-Time Partners'
  );

  console.log(
    options.find((opt) => opt.title === selectedOption)?.summary?.key
  );

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

    const { summary } = options.find((opt) => opt.title === selectedOption);

    const fetchData = async () => {
      setLoading(true);

      const [summaryData, recordsData] = await Promise.all(
        ['summary', 'records'].map(async (resource) => {
          let endpoint = `/v2/agency/clients/analytics/${resource}`;

          let params = {
            startDate,
            endDate,
            withPrevious: true,
            ...summary.params,
          };

          if (assigneeId) {
            params['assignees.userId'] = assigneeId;
          }

          const response = await axios.get(endpoint, { params });

          return response.data.data;
        })
      );

      if (isSubscribed) {
        setSummary({ ...summaryData, value: summaryData[summary.key] });
        setRecords(
          recordsData.map((record) => ({
            ...record,
            value: record[summary.key],
          }))
        );
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year, selectedOption, assigneeId]);

  return (
    <div className="bg-white p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-base font-inter tracking-3/4 leading-1.2 font-bold text-grayscale-900">
            Total Upsell Value
          </p>
          <p className="mt-2 text-25 font-inter leading-1.2 tracking-3/4 text-grayscale-900">
            {loading ? '-' : currencyFormatter(summary['value'])}
          </p>
        </div>

        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          className="text-13 text-grayscale-800 bg-grayscale-400 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          {options.map((option) => (
            <option key={option.title} value={option.title}>
              {option.title}
            </option>
          ))}
        </select>
      </div>

      <div className="h-72 mt-5">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
              <XAxis
                dataKey="dateRange"
                style={{
                  fontSize: '11px',
                  color: '#202020',
                  cursor: 'pointer',
                }}
                tickFormatter={(dateRange) =>
                  moment(dateRange.split('--')[0]).format('MMM')
                }
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(data) => currencyFormatter(data)}
              />

              <Tooltip
                content={
                  <ChartToolTip
                    Title={() => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {year}
                      </p>
                    )}
                    CustomItemTitle={({ item }) => (
                      <p className="text-13 leading-1.5 text-grayscale-900">
                        {moment(item.payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />

              <Line
                chartType="line"
                yAxisId="0"
                stroke="#FF6868"
                type="linear"
                dataKey={'value'}
                dot={false}
                strokeWidth={3}
                formatter={(value) => currencyFormatter(value)}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default UpsellValue;
