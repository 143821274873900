import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import SearchBar from './filters/SearchBar';
import StatusFilter from './filters/StatusFilter';
import StatusSwitch from './common/StatusSwitch';
import ExportButton from './ExportButton';

const NegativeTargets = ({ accountId, marketplace, campaignType }) => {
  const { t } = useTranslation();
  const [negativeTargets, setNegativeTargets] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    include: ['adGroup'],
  });

  useEffect(() => {
    axios
      .get('/advertising/negative-targets', {
        params: { accountId, marketplace, campaignType, ...params },
      })
      .then((response) => setNegativeTargets(response.data.data));
  }, [accountId, marketplace, campaignType, params]);

  const columns = [
    {
      dataField: 'targetingText',
      text: t('Advertising.Target.Expression'),
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <p>{cell}</p>
            {row.AdvAdGroup && (
              <p className="text-grayscale-600 text-tiny">
                Ad Group: {row.AdvAdGroup.name}
              </p>
            )}
          </>
        );
      },
    },
    {
      dataField: 'AdvAdGroup.AdvCampaign.name',
      text: 'Campaign',
      sort: false,
    },
    {
      dataField: 'state',
      text: t('Advertising.Common.Status'),
      sort: true,
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity="negative-targets"
          entityId="advNegativeTargetId"
          accountId={accountId}
          marketplace={marketplace}
          disabled={true}
        />
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div className="my-4">
      <div className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-12">
        <SearchBar
          params={params}
          className="lg:col-span-5"
          onApplyFilter={setParams}
        />

        <StatusFilter
          params={params}
          setParams={setParams}
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lg:col-span-4"
        />

        <ExportButton
          className="lg-col-span-3"
          params={{
            accountId,
            marketplace,
            campaignType,
            include: ['adGroup'],
          }}
          endpoint="negative-targets"
        />
      </div>
      <Table
        keyField="advNegativeTargetId"
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={negativeTargets}
        onTableChange={onTableChange}
        params={params}
        bordered={true}
      />
    </div>
  );
};

export default NegativeTargets;
