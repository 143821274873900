import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { reverse } from 'lodash';

export const fetchOlderUpdates = createAsyncThunk(
  'leads/olderUpdates',
  async ({ params, leadId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/leads/clients/${leadId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const fetchUpdates = createAsyncThunk(
  'leads/updates',
  async ({ params, leadId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/leads/clients/${leadId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const getAttachments = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/agency/leads/attachments`,
      params: params.params,
    });

    dispatch(setAttachments(response.data.data));
  } catch (error) {
    console.log(error.message);
  }
};

export const leadsSlice = createSlice({
  name: 'leads',
  initialState: {
    currentPage: null,
    updates: { rows: [] },
    olderUpdates: { nextPage: 1, rows: [] },
    showUpdatesSection: false,
    attachments: {},
    leadsPaginationParams: {
      page: 1,
      pageSize: 30,
      sort: 'companyName:asc',
      statuses: 'Unprocessed New Leads',
      search: '',
      fields: 'companyName',
      minPrice: 0,
      maxPrice: 0,
      pitcher: true, // show regardless who process the leads
    },
    allLeadsPaginationParams: {
      page: 1,
      pageSize: 30,
      sort: 'companyName:asc',
      statuses: '',
      status: '',
      lead: '',
      fields: 'companyName',
      companyName: '',
    },
    leadsVariablesPaginationParams: {
      page: 1,
      pageSize: 30,
      sort: 'createdAt:desc',
    },
    liAccountsPaginationParams: {
      page: 1,
      pageSize: 30,
      sort: 'name:asc',
    },
  },

  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setLeadsPaginationParams: (state, action) => {
      state.leadsPaginationParams = action.payload;
    },
    setAllLeadsPaginationParams: (state, action) => {
      state.allLeadsPaginationParams = action.payload;
    },
    setLeadsVariablesPaginationParams: (state, action) => {
      state.leadsVariablesPaginationParams = action.payload;
    },
    setLiAccountsPaginationParams: (state, action) => {
      state.liAccountsPaginationParams = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload;
    },
    setOlderUpdates: (state, action) => {
      state.olderUpdates = action.payload;
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload;
    },
    setShowUpdatesSection: (state, action) => {
      state.showUpdatesSection = action.payload;
    },
  },
  extraReducers: {
    [fetchOlderUpdates.fulfilled]: (state, { payload }) => {
      state.olderUpdates = {
        ...payload,
        rows: [...reverse(payload.rows), ...state.olderUpdates.rows],
      };
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.updates = { ...payload, rows: reverse(payload.rows) };
    },
  },
});

export const {
  setCurrentPage,
  setLeadsPaginationParams,
  setAllLeadsPaginationParams,
  setLeadsVariablesPaginationParams,
  setLiAccountsPaginationParams,
  setOlderUpdates,
  setUpdates,
  setAttachments,
  setShowUpdatesSection,
} = leadsSlice.actions;

export default leadsSlice.reducer;
