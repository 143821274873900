import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { Helmet } from 'react-helmet';
import classNames from 'utils/classNames';
import OnboardingLayout from 'layouts/onboarding/OnboardingLayout';
import Label from 'components/Forms/Label';
import { percentageFormatter } from 'utils/formatters';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useCountry from 'hooks/useCountry';
import useEnv from 'hooks/useEnv';
import useAlert from 'hooks/useAlert';
import Button from 'components/Button';
import { setupReducer, initialSetupState } from './accountSubscriptionReducer';
import PaymentForm from 'components/PaymentForm';
import useBraintreeUtils from 'hooks/useBraintreeUtils';
import { omit, pick } from 'lodash';
import useBilling from 'hooks/useBilling';

const AccountSubscriptionBraintree = () => {
  const history = useHistory();
  const { taxes, getTaxes } = useBilling();
  const { user, currentAccount, isAccountPlanAgency, isAlreadySubscribed } =
    useLoggedInUser();
  const { countries } = useCountry();
  const { alertSuccess, alertError } = useAlert();
  const { appInProduction } = useEnv();
  const [isLoading, setIsLoading] = useState(false);
  const paymentFormRef = useRef();
  const [paymentFormError, setPaymentFormError] = useState(false);
  const { showTokenizeError } = useBraintreeUtils();
  const [setup, dispatch] = useReducer(setupReducer, initialSetupState);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    address: '',
    city: '',
    country: '',
    province: '',
    postalCode: '',
  });

  const validationSchema = object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email().required('Required'),
    company: string().required('Required'),
    address: string().required('Required'),
    city: string().required('Required'),
    country: string().required('Required'),
    province: string().required('Required'),
    postalCode: string().required('Required'),
  });

  useEffect(() => {
    if (!taxes) {
      getTaxes();
    }
  }, [taxes]);

  const getHostedPageDetails = async () => {
    await axios
      .get(
        `/account/onboarding/hostedpage?accountId=${currentAccount.accountId}`
      )
      .then((response) => {
        if (response.data.isMigrate > 0) {
          history.push(`/client-migration/${currentAccount.accountId}`);
        }
        dispatch({
          type: 'updateData',
          data: response.data.data,
        });
      })
      .catch((error) => {
        alertError('Failed to load details', error.response.data.message);
      });
  };

  useEffect(() => {
    if (isAlreadySubscribed()) {
      history.push('/onboarding');
    } else {
      if (isAccountPlanAgency()) {
        getHostedPageDetails();
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    if (user && setup && taxes) {
      const setAddressValue = (key) => {
        if (setup.zohoCustomer && setup.zohoCustomer.billing_address) {
          return setup.zohoCustomer.billing_address[key];
        }

        if (setup.billingAddress && setup.billingAddress[key]) {
          return setup.billingAddress[key];
        }

        return '';
      };

      setInitialValues({
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        email: user ? user.email : '',
        company: setup.hostedPageDetails
          ? setup.hostedPageDetails.customer_name
          : '',
        address: setAddressValue('address'),
        city: setAddressValue('city'),
        country: setAddressValue('country'),
        province: setAddressValue('state'),
        postalCode: setAddressValue('zip'),
      });

      if (setAddressValue('country') !== '') {
        dispatch({
          type: 'updateInitialAddress',
          country: countries.find(
            ({ name }) => name === setAddressValue('country')
          ),
          province: setAddressValue('state'),
          taxRates: taxes,
        });
      }
    }
  }, [
    user,
    setup.hostedPageDetails,
    setup.zohoCustomer,
    setup.billingAddress,
    taxes,
  ]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    let paymentMethodNonce = null;

    await paymentFormRef.current
      .tokenize()
      .then((response) => {
        paymentMethodNonce = response.nonce;
      })
      .catch((error) => {
        showTokenizeError(error);
      });

    if (paymentMethodNonce && paymentFormRef.current.deviceData) {
      try {
        const response = await axios.post(`/account/onboarding/subscribe`, {
          setup: omit(setup, [
            'hostedPageDetails',
            'provinces',
            'selectedCountry',
            'billingAddress',
          ]),
          selectedCountry: pick(setup.selectedCountry, [
            'isoCode',
            'name',
            'currency',
          ]),
          ...values,
          paymentMethodNonce,
          deviceData: paymentFormRef.current.deviceData,
          accountId: currentAccount.accountId,
        });

        alertSuccess(
          'Payment confirmed and subscription created',
          'Proceeding to next step...'
        );
        history.push('/onboarding');
      } catch (error) {
        alertError('Something went wrong', error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  const isZohoCustomer = () => {
    return setup.zohoCustomer ? true : false;
  };

  const disableAddress = () => {
    if (setup.zohoCustomer) return true;
    if (setup.onboardingHistory && setup.onboardingHistory.subscriptionCreated)
      return true;
    return false;
  };

  return (
    <OnboardingLayout>
      {appInProduction() && (
        <Helmet>
          <script>
            var head = document.head; var script =
            document.createElement('script'); script.type = 'text/javascript';
            script.src =
            "https://181966.t.hyros.com/v1/lst/universal-script?ph=63864d682fd26eae3db3c4f2a45700ddbd4cba98eec333ac52aaa6df3a8242ae&tag=!clicked";
            head.appendChild(script);
          </script>
        </Helmet>
      )}

      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Account Subscription
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Let's set up your subscription
        </p>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue }) => (
          <Form className="mt-8 px-4 py-8 sm:px-10 sm:mx-auto sm:w-full sm:max-w-xl bg-white shadow sm:rounded-2xl">
            <h3 className="text-lg leading-6 font-medium text-gray-900 tracking-widest">
              Account Information
            </h3>
            <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <Label htmlFor="firstName">First Name</Label>
                <Field
                  name="firstName"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={true}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div>
                <Label htmlFor="lastName">Last Name</Label>
                <Field
                  name="lastName"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={true}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div className="sm:col-span-2">
                <Label htmlFor="company">Company</Label>
                <Field
                  name="company"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={true}
                />
                <ErrorMessage
                  name="company"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>

              <div className="sm:col-span-2">
                <Label htmlFor="email">Email</Label>
                <Field
                  name="email"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="email"
                  disabled={true}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            </div>
            <h3 className="mt-12 mb-4 text-lg leading-6 font-medium text-gray-900 tracking-widest">
              Billing Address
            </h3>
            <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
              <div className="sm:col-span-2">
                <Label htmlFor="address">Address</Label>
                <Field
                  name="address"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={disableAddress()}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div>
                <Label htmlFor="city">City</Label>
                <Field
                  name="city"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={disableAddress()}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div>
                <Label htmlFor="city">Country</Label>
                <Field
                  name="country"
                  as="select"
                  className="form-select disabled-white"
                  disabled={disableAddress()}
                  onChange={(e) => {
                    dispatch({
                      type: 'updateCountry',
                      country: countries.find(
                        ({ name }) => name === e.target.value
                      ),
                    });
                    setFieldValue('province', '');
                    handleChange(e);
                  }}
                >
                  <option value="">Select Country</option>
                  {countries &&
                    countries.map(({ name }, i) => (
                      <option value={name} key={i}>
                        {name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="country"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div>
                <Label htmlFor="province">State/Province/Region</Label>
                <Field
                  name="province"
                  as="select"
                  className="form-select disabled-white"
                  disabled={disableAddress()}
                  onChange={(e) => {
                    dispatch({
                      type: 'updateProvince',
                      province: setup.provinces.find(
                        ({ name }) => name === e.target.value
                      ),
                      taxRates: taxes,
                    });
                    handleChange(e);
                  }}
                >
                  <option value="">Select State/Provice/Region</option>
                  {setup.provinces &&
                    setup.provinces.map(({ name }, i) => (
                      <option value={name} key={i}>
                        {name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="province"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              <div>
                <Label htmlFor="postalCode">ZIP/Postal Code</Label>
                <Field
                  name="postalCode"
                  placeholder=""
                  className="form-input text-sm disabled-white"
                  type="text"
                  disabled={disableAddress()}
                />
                <ErrorMessage
                  name="postalCode"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            </div>

            {setup.hostedPageDetails && (
              <>
                <h3 className="mt-12 text-lg leading-6 font-medium text-gray-900 tracking-widest">
                  Subscription Summary
                </h3>
                <ul
                  // role="list"
                  className="text-sm font-medium text-gray-900 divide-y divide-gray-200"
                >
                  <li className="flex justify-between py-6 space-x-4">
                    <div className="flex-col space-y-2">
                      <div className="flex items-center">
                        <h3>{setup.hostedPageDetails.name}</h3>
                        <span className="ml-2 items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                          Monthly
                        </span>
                      </div>

                      <p className="text-gray-500 whitespace-pre-wrap">
                        {setup.hostedPageDetails.plan_description}
                      </p>
                    </div>
                    <p className="flex-none text-base font-medium">
                      ${parseFloat(setup.hostedPageDetails.price).toFixed(2)}
                    </p>
                  </li>

                  {setup.hostedPageDetails.addons.map((addon) => (
                    <li
                      key={addon.code}
                      className="flex justify-between py-6 space-x-4"
                    >
                      <div className="flex-col space-y-2">
                        <div className="flex items-center">
                          <h3>{addon.name}</h3>
                          <span
                            className={classNames(
                              addon.type === 'one_time'
                                ? 'bg-yellow-100 text-yellow-800'
                                : 'bg-green-100 text-green-800',
                              'ml-2 items-center px-2 py-0.5 rounded text-xs font-medium'
                            )}
                          >
                            {addon.type === 'one_time'
                              ? 'One-Time Fee'
                              : 'Monthly Fee'}
                          </span>
                        </div>
                        <p className="text-gray-500">
                          Unit Price:&nbsp;
                          <span className="text-gray-700 mr-5">
                            ${addon.price}
                          </span>
                          Qty:&nbsp;
                          <span className="text-gray-700">
                            {addon.quantity}
                          </span>
                        </p>
                        <p className="text-gray-500 whitespace-pre-wrap">
                          {addon.addon_description}
                        </p>
                      </div>
                      <p className="flex-none text-base font-medium">
                        $
                        {(
                          parseFloat(addon.price) * parseFloat(addon.quantity)
                        ).toFixed(2)}
                      </p>
                    </li>
                  ))}
                </ul>

                <dl className="text-sm font-medium text-gray-500 space-y-6 mt-5">
                  <div className="flex justify-between">
                    <dt>Subtotal</dt>
                    <dd className="text-gray-900">${setup.subTotal}</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt>
                      Taxes ({percentageFormatter(setup.selectedTaxRate)})
                    </dt>
                    <dd className="text-gray-900">
                      ${setup.taxAmount.toFixed(2)}
                    </dd>
                  </div>
                  <div className="flex justify-between border-t border-gray-200 text-gray-900 pt-6">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">${setup.total.toFixed(2)}</dd>
                  </div>
                </dl>
              </>
            )}

            <div>
              <h3 className="mt-12 mb-4 text-lg leading-6 font-medium text-gray-900 tracking-widest">
                Payment Information
              </h3>

              <PaymentForm
                ref={paymentFormRef}
                setError={setPaymentFormError}
              />
            </div>

            <div className="mt-8 px-4 sm:px-0 sm:mx-auto sm:w-full sm:max-w-xl">
              <div className="flex justify-center">
                <Button
                  classes="border-0 mt-8 font-inter"
                  bgColor="custom-yellow"
                  hoverColor="yellow-400"
                  roundedSize="full"
                  textColor="black"
                  px={16}
                  py={2}
                  shadow=""
                  textSize=""
                  showLoading={true}
                  loading={isLoading}
                  type="submit"
                  disabled={paymentFormError}
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingLayout>
  );
};

export default withRouter(AccountSubscriptionBraintree);
