import { useState } from 'react';

const useImageLoad = () => {
  const [loading, setLoading] = useState(false);

  const onLoadSrc = () => {
    setLoading(false);
  };

  const onReloadSrc = (e, src) => {
    e.target.src = src;
  };

  const css = {
    opacity: loading ? 0 : 1,
    transition: 'opacity 500ms ease-in 0ms',
  };

  return {
    onLoadSrc,
    onReloadSrc,
    css,
  };
};

export default useImageLoad;
