import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PencilIcon, CheckIcon, XIcon } from '@heroicons/react/solid';

import { setAlert } from 'features/alerts/alertsSlice';

import { currencyFormatter } from 'utils/formatters';
import classNames from 'utils/classNames';

const AllocationEditor = ({
  accountId,
  marketplace,
  entity,
  entityId,
  currentValue,
  editable = false,
  attribute,
  recommended = null,
}) => {
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(currentValue);
  const [saving, setSaving] = useState(false);

  const updateValue = () => {
    if (saving) return;

    setSaving(true);
    const payload = { accountId, marketplace };

    axios
      .put(`/account/advertising/${entity}/${entityId}`, {
        ...payload,
        [attribute]: value,
      })
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
        setValue(response.data.data[attribute]);
      })
      .catch((error) => {
        setValue(currentValue);
        dispatch(setAlert('error', error.response.data.message));
      })
      .finally(() => {
        setSaving(false);
        setEditing(false);
      });
  };

  const exitEditing = () => {
    if (saving) return;
    setValue(currentValue);
    setEditing(false);
  };

  return (
    <div className="flex justify-center">
      {editing ? (
        <div className="flex items-center">
          <div className="relative rounded-2xl">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5 text-mini text-grayscale-600">
              $
            </div>
            <input
              type="number"
              className="pr-3 pl-8 py-1.25 border text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 block w-full text-mini border-gray-300 rounded-3xl outline-none appearance-none focus:appearance-none focus:outline-none focus:ring-0"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={saving}
            />
          </div>
          <div className="flex ml-2">
            <CheckIcon
              className={classNames(
                'h-5 w-5 text-secondary',
                saving ? 'cursor-wait' : 'cursor-pointer'
              )}
              onClick={updateValue}
            />
            <XIcon
              className={classNames(
                'h-5 w-5 text-secondary',
                saving ? 'cursor-wait' : 'cursor-pointer'
              )}
              onClick={exitEditing}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div className="flex flex-col">
            <span>{currencyFormatter(value)}</span>
            {recommended && (
              <span className="text-tiny text-error-dark">
                ({currencyFormatter(recommended.suggestedBudget)})
              </span>
            )}
          </div>
          {editable && (
            <PencilIcon
              className="ml-3 h-5 w-5 cursor-pointer text-secondary"
              onClick={() => setEditing(true)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AllocationEditor;
