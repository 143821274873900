import { useDispatch, useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/outline';

import {
  Link,
  Route,
  Switch,
  withRouter,
  useRouteMatch,
} from 'react-router-dom';

import useBodyClass from 'hooks/useBodyClass';
import usePermissions from 'hooks/usePermissions';

import View from './View';
import Edit from './Edit';
import Admin from './Admin';
import Files from './Files';
import Mockup from './Mockup';
import Updates from './Updates';
import Onboarding from './Onboarding';
import UpdatesToggle from 'components/Updates/UpdatesToggle';

import { setShowUpdatesSection } from '../agencyClientSlice';

import classNames from 'utils/classNames';
import { linkStyles, setTabStyle } from 'utils/tabStyles';
import Proposal from 'features/sales/Proposal';

const Profile = ({ location }) => {
  useBodyClass('client-profile');

  const dispatch = useDispatch();

  const { pathname } = location;
  const { path, url } = useRouteMatch();
  const { userCan, isAgencySuperUser } = usePermissions();

  const { showUpdatesSection, agencyClient } = useSelector(
    (state) => state.agencyClient
  );

  const profileTabs = [
    { tab: 'profile', url: url, visible: true },
    {
      tab: 'Onboarding',
      url: `${url}/onboarding`,
      visible: userCan('clients.subscription.onboarding'),
    },
    {
      tab: 'Files',
      url: `${url}/files`,
      visible: userCan('clients.profile.files.list'),
    },
    {
      tab: 'Mockup',
      url: `${url}/mockup`,
      visible: userCan('mockup.view'),
    },
    {
      tab: 'Proposal',
      url: `${url}/proposal`,
      visible: agencyClient.sales?.salesClientId
        ? true && userCan('clients.profile.proposal.view')
        : false,
    },
    {
      tab: 'Admin',
      url: `${url}/admin`,
      visible: userCan('clients.profile.admin.view'),
      icon: LockClosedIcon,
    },
  ].filter((tab) => tab.visible);

  return (
    <>
      <div className="lg:grid grid-cols-5">
        <div className="col-span-5 curve-fix">
          <div className="tablinks flex">
            {profileTabs.map((tab, i) => (
              <Link
                key={i}
                to={tab.url}
                className={classNames(
                  linkStyles,
                  setTabStyle(tab, profileTabs, pathname),
                  tab.icon ? 'flex items-center justify-center' : ''
                )}
              >
                {tab.icon && (
                  <tab.icon className="w-4 h-4 inline mr-2 text-gray-700" />
                )}{' '}
                {tab.tab}
              </Link>
            ))}
          </div>
        </div>
        <div
          className={`${
            showUpdatesSection ? 'client-content' : 'col-span-5'
          } container-fixed bg-white`}
        >
          <div className="px-8 pt-8 pb-16">
            <Switch>
              <Route exact path={`${path}`} component={View} />
              <Route exact path={`${path}/edit`} component={Edit} />
              <Route path={`${path}/onboarding`} component={Onboarding} />
              <Route path={`${path}/files`} component={Files} />
              <Route path={`${path}/admin`} component={Admin} />
              <Route path={`${path}/mockup`} component={Mockup} />
              <Route
                path={`${path}/proposal`}
                render={(props) => (
                  <Proposal
                    page={'client'}
                    agencySalesClientId={agencyClient.sales.salesClientId}
                    {...props}
                  />
                )}
              />
            </Switch>
          </div>
        </div>

        {showUpdatesSection && userCan('clients.profile.updates.view') ? (
          <div className="client-updates container-fixed bg-gray-50 border-l border-gray-100">
            <Updates
              setVisible={(value) => dispatch(setShowUpdatesSection(value))}
            />
          </div>
        ) : (
          <UpdatesToggle
            onToggle={(value) => dispatch(setShowUpdatesSection(value))}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(Profile);
