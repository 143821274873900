import axios from 'axios';
import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';

import MetricCard from '../../components/MetricCard/MetricCard';
import metrics from '../../utils/metrics';

const AnalyticsOverview = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  campaignType,
}) => {
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: [
          'cr',
          'ctr',
          'cpc',
          'acos',
          'roas',
          'cost',
          'sales',
          'clicks',
          'impressions',
          'orders',
        ],
        campaignTypes: [campaignType],
        include: ['totalSales', 'previousData'],
      };

      const response = await axios.get('/account/advertising/analytics', {
        params,
      });

      if (isSubscribed) {
        setOverview(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, campaignType]);

  return (
    <div className={className}>
      {[
        'sales',
        'cost',
        'totalSales',
        'tacos',
        'acos',
        'ctr',
        'cr',
        'cpc',
        'roas',
        'clicks',
      ]
        .map((attribute) => metrics.find((m) => m.key === attribute))
        .map((metric) => (
          <MetricCard
            loading={loading}
            key={`analytics-overview-${metric.key}`}
            title={metric.title}
            reversePercentage={!!metric.reverse}
            formatter={metric.formatter}
            value={overview[metric.key]}
            previousValue={overview[`previous${upperFirst(metric.key)}`]}
            prefixValue={metric.prefix}
            suffixValue={metric.suffix}
            dividendTitle={
              metric.dividend && metrics.find((m) => m.key === metric.dividend)
                ? metrics.find((m) => m.key === metric.dividend).title
                : ''
            }
            divisorTitle={
              metric.divisor && metrics.find((m) => m.key === metric.divisor)
                ? metrics.find((m) => m.key === metric.divisor).title
                : ''
            }
            dividendValue={
              metric.dividend && metrics.find((m) => m.key === metric.dividend)
                ? metrics
                    .find((m) => m.key === metric.dividend)
                    .rawFormatter(overview[metric.dividend])
                : ''
            }
            divisorValue={
              metric.divisor && metrics.find((m) => m.key === metric.divisor)
                ? metrics
                    .find((m) => m.key === metric.divisor)
                    .rawFormatter(overview[metric.divisor])
                : ''
            }
          />
        ))}
    </div>
  );
};

export default AnalyticsOverview;
