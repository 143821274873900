import { Field, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import Loading from 'components/Loading';
import PriceLabel from 'components/PriceLabel';
import { Label } from 'components/Forms';
import { Button } from 'components';
import useAlert from 'hooks/useAlert';
import useCreditNotes from 'features/creditNotes/useCreditNotes';

const ApplyCredits = ({
  loading,
  creditNote,
  checkAppliedCredits,
  remainingBalance,
  creditsApplied,
  setIsOpenConfirm,
  setIsOpenDeny,
}) => {
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const { alertError } = useAlert();
  const { STATUS } = useCreditNotes();

  const hasSelectedInvoices = (values) => {
    return (
      values.invoices &&
      values.invoices.filter((i) => i.customer_id !== 'autoapp').length > 0
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div>
        <FieldArray name="invoices">
          {({ remove, push }) => (
            <>
              <div className="flex border-t border-grayscale-500 mt-4 py-6 justify-between">
                <label className="text-sm flex flex-col text-left text-gray-500 w-1/2 cursor-pointer">
                  <span className="flex items-center">
                    <Field
                      type="checkbox"
                      name="autoApply"
                      className="cursor-pointer mr-4 rounded-sm p-2 border-grayscale-600"
                      onChange={(e) => {
                        if (e.target.checked) {
                          push({
                            balance: creditNote.amount,
                            customer_id: 'autoapp',
                            invoice_id: 'new',
                            number: 'new',
                            status: 'pending',
                            creditsToApply: 0,
                            hasChanged: false,
                            apply: true,
                          });
                        } else {
                          const index = values.invoices.length - 1;
                          checkAppliedCredits(
                            0,
                            `invoices.${index}.creditsToApply`,
                            index,
                            values.invoices[index],
                            setFieldValue,
                            values
                          );
                          remove(index);
                        }

                        handleChange(e);
                      }}
                    />
                    Apply to next month's invoice
                  </span>
                </label>
                {values.autoApply && (
                  <div className="w-1/4 text-center">
                    <div className="relative">
                      <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                        $
                      </span>
                      <Field
                        name={`invoices.${
                          values.invoices.length - 1
                        }.creditsToApply`}
                        placeholder="0.0"
                        className="form-input-g disabled:border-white disabled:opacity-30 text-left pl-10"
                        type="number"
                        disabled={!values.autoApply}
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                        onChange={(e) => {
                          setFieldValue(
                            `invoices.${values.invoices.length - 1}.hasChanged`,
                            true
                          );
                          handleChange(e);
                        }}
                        onBlur={(e) => {
                          const index = values.invoices.length - 1;
                          if (values.invoices[index].hasChanged) {
                            checkAppliedCredits(
                              e.target.value,
                              e.target.name,
                              index,
                              values.invoices[index],
                              setFieldValue,
                              values
                            );
                          }

                          handleBlur(e);
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="text-xs text-grayscale-700 disabled:opacity-30 leading-none"
                      disabled={!values.autoApply}
                      onClick={() => {
                        const index = values.invoices.length - 1;
                        checkAppliedCredits(
                          values.invoices[index].balance,
                          `invoices.${index}.creditsToApply`,
                          index,
                          values.invoices[index],
                          setFieldValue,
                          values
                        );
                      }}
                    >
                      Apply max amount
                    </button>

                    <ErrorMessage
                      name="autoApplyAmount"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
              </div>

              {hasSelectedInvoices(values) ? (
                <div className="flex flex-col border-t border-grayscale-500 pt-8">
                  <h4 className="font-bold">Client's Open Invoice</h4>
                  <label className="text-gray-500 text-sm pt-1 mb-6">
                    Please select the open invoices where you would like to
                    apply the credit amount.
                  </label>
                  <div className="border rounded-lg overflow-hidden">
                    <div className="grid grid-cols-7 bg-grayscale-400 text-sm text-grayscale-700 py-5">
                      <div className="col-span-1">&nbsp;</div>
                      <div className="col-span-2">Invoice Number</div>
                      <div className="col-span-2 text-right px-6">
                        Invoice Balance
                      </div>
                      <div className="col-span-2 text-center">Actions</div>
                    </div>

                    {values.invoices
                      .filter((i) => i.customer_id !== 'autoapp')
                      .map((invoice, index) => (
                        <div
                          className="grid grid-cols-7 disabled:opacity-30 items-center justify-between w-full text-left hover:bg-grayscale-300 text-grayscale-800  px-2 py-3 text-base rounded-md focus:ring-gray-300 focus:border-gray-200"
                          key={invoice.invoice_id}
                          value={invoice.invoice_id}
                        >
                          <div className="col-span-1 text-center">
                            <Field
                              type="checkbox"
                              name={`invoices.${index}.apply`}
                              className="rounded-sm w-5 h-5 border-grayscale-600 disabled:opacity-30"
                              onChange={(e) => {
                                checkAppliedCredits(
                                  0,
                                  `invoices.${index}.creditsToApply`,
                                  index,
                                  invoice,
                                  setFieldValue,
                                  values
                                );
                                handleChange(e);
                              }}
                            />
                          </div>
                          <p className="col-span-2">{invoice.number}</p>
                          <p className="col-span-2 text-right px-6">
                            <PriceLabel price={parseFloat(invoice.balance)} />
                          </p>
                          <div className="col-span-2 text-center">
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                $
                              </span>
                              <Field
                                name={`invoices.${index}.creditsToApply`}
                                placeholder="0.0"
                                className="form-input-g disabled:border-white disabled:opacity-30 text-left pl-10"
                                type="number"
                                disabled={!values.invoices[index].apply}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                                onChange={(e) => {
                                  setFieldValue(
                                    `invoices.${index}.hasChanged`,
                                    true
                                  );
                                  handleChange(e);
                                }}
                                onBlur={(e) => {
                                  if (values.invoices[index].hasChanged) {
                                    checkAppliedCredits(
                                      e.target.value,
                                      e.target.name,
                                      index,
                                      invoice,
                                      setFieldValue,
                                      values
                                    );
                                  }

                                  handleBlur(e);
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="text-xs text-grayscale-700 disabled:opacity-30 leading-none"
                              disabled={!values.invoices[index].apply}
                              onClick={() => {
                                checkAppliedCredits(
                                  invoice.balance,
                                  `invoices.${index}.creditsToApply`,
                                  index,
                                  invoice,
                                  setFieldValue,
                                  values
                                );
                              }}
                            >
                              Apply max amount
                            </button>

                            <ErrorMessage
                              name={`invoices.${index}.creditsToApply`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col border-t border-grayscale-500 pt-4">
                  <label className="text-grayscale-800 text-sm pt-1">
                    No open invoices available
                  </label>
                  <div className="grid grid-cols-2 pt-8">
                    <div className="col-span-1">
                      <Label classes="col-span-2 text-grayscale-700">
                        Credits Available:
                      </Label>
                      <p className="text-4xl flex items-start mt-2">
                        <span className="text-grayscale-600 text-3xl">$</span>
                        &nbsp;
                        <span className="text-grayscale-800">
                          {parseFloat(creditNote.creditsAvailable).toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="col-span-1">
                      <Label classes="col-span-2 text-grayscale-700">
                        Credits To Apply:
                      </Label>
                      <p className="text-4xl flex items-start mt-2">
                        <span className="text-grayscale-600 text-3xl">$</span>
                        &nbsp;
                        <span className="text-grayscale-800">
                          {parseFloat(creditsApplied).toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </FieldArray>

        {hasSelectedInvoices(values) && (
          <div className="grid grid-cols-2 pt-8">
            <div className="col-span-1">
              <Label classes="col-span-2 text-grayscale-700">
                Remaining Credits:
              </Label>
              <p className="text-4xl flex items-start mt-2">
                <span className="text-grayscale-600 text-3xl">$</span>
                &nbsp;
                <span className="text-grayscale-800">
                  {parseFloat(remainingBalance).toFixed(2)}
                </span>
              </p>
            </div>
            <div className="col-span-1">
              <Label classes="col-span-2 text-grayscale-700">
                Credits Applied:
              </Label>
              <p className="text-4xl flex items-start mt-2">
                <span className="text-grayscale-600 text-3xl">$</span>
                &nbsp;
                <span className="text-grayscale-800">
                  {parseFloat(creditsApplied).toFixed(2)}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="py-8 flex space-x-6">
        {creditNote.status === STATUS.awaitingApproval && (
          <Button
            classes="border-0 font-bold tracking-2 w-40 justify-center"
            bgColor="error-dark"
            hoverColor="error"
            roundedSize="full"
            textColor="white"
            px={0}
            py={2}
            showLoading={true}
            onClick={() => setIsOpenDeny(true)}
            type="button"
          >
            Deny
          </Button>
        )}
        <Button
          classes="border-0 font-bold tracking-2 w-40 justify-center"
          bgColor="success-dark"
          hoverColor="success"
          roundedSize="full"
          textColor="white"
          px={0}
          py={2}
          type="button"
          onClick={() => {
            if (!values.autoApply && creditsApplied === 0) {
              alertError(
                'Cannot proceed',
                "Please apply credits to open invoices or select 'Apply to next month's invoice'"
              );
            } else setIsOpenConfirm(true);
          }}
        >
          {creditNote.status === STATUS.appliedPartial ? 'Apply' : 'Approve'}
        </Button>
      </div>
    </>
  );
};

export default ApplyCredits;
