import axios from 'axios';
import { useEffect, useState } from 'react';

import Checkbox from 'components/Forms/Checkbox';

const CustomTargetCampaign = ({
  accountId,
  marketplace,
  campaignType,
  value,
  onChangeValue,
  disabled = false,
}) => {
  const [search, setSearch] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get('/account/advertising/campaigns', {
        params: {
          search,
          accountId,
          marketplace,
          campaignType,
          pageSize: 100000,
          sort: 'name:asc',
        },
      });

      if (isSubscribed) {
        setCampaigns(response.data.data.rows);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, campaignType, search]);

  return (
    <div id="rules-custom-target-campaigns">
      <input
        placeholder="Search Campaign"
        className="px-4 py-2 focus:outline-none focus:ring-0 block w-full text-mini font-inter border border-grayscale-500 rounded-md text-grayscale-800"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="h-48 px-2 mt-2 overflow-y-scroll">
        {loading ? (
          <p className="text-center my-2 tracking-2 font-bold animate-pulse">
            Loading Records
          </p>
        ) : campaigns.length ? (
          campaigns.map((campaign) => {
            return (
              <div
                className="flex items-center py-1.5"
                key={`rules-custom-target-campaigns-${campaign.advCampaignId}`}
              >
                <Checkbox
                  disabled={disabled}
                  id={campaign.advCampaignId}
                  classes="mr-2"
                  checked={value.includes(campaign.advCampaignId)}
                  onChange={() => {
                    onChangeValue(
                      value.includes(campaign.advCampaignId)
                        ? value.filter((v) => v !== campaign.advCampaignId)
                        : [...value, campaign.advCampaignId]
                    );
                  }}
                />

                <p className="text-grayscale-700 tracking-3/4">
                  {campaign.name}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-center my-2 tracking-2 font-bold">
            No records found.
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomTargetCampaign;
