import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, number, date, array, ref, min, boolean } from 'yup';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import axios from 'axios';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { setAlert } from 'features/alerts/alertsSlice';
import Label from 'components/Forms/Label';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import { isEmpty } from 'lodash';
import { getLatestUpdates } from '../../agencyClientSlice';

const UpdatePasswordModal = ({ open, setOpen, userId }) => {
  const dispatch = useDispatch();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const [saving, setSaving] = useState(false);
  const initialValues = {
    userId,
    newPassword: '',
    confirmPassword: '',
    agencyClientId: agencyClient.agencyClientId,
  };

  const validationSchema = object().shape({
    userId: string().required('Required'),
    newPassword: string()
      .min(8, 'Password must be at least 8 characters')
      .required('Required'),
    confirmPassword: string()
      .oneOf([ref('newPassword')], 'Passwords do not match')
      .required('Required'),
  });

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const out = await axios.patch('/auth/you/password', values);
      dispatch(setAlert('success', 'Successfully updated password'));
      setSaving(false);
      setOpen(false);
      dispatch(getLatestUpdates(agencyClient.agencyClientId));
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-10"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl ">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4">
              <span>Update Default Contact Password</span>
            </div>
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto">
                <div className="col-span-2 ">
                  <Label>*New* Password</Label>
                  <Field
                    name="newPassword"
                    placeholder="Enter minimum of 8 characters"
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2 ">
                  <Label>Confirm *New* Password</Label>
                  <Field
                    name="confirmPassword"
                    placeholder="re-type new password"
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      Reset
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default UpdatePasswordModal;
