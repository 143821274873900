import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createReportAsync, getReportItemsAsync } from '../optimizationSlice';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';
import {
  getAdGroupsAsync,
  getCampaignsAsync,
  getPortfoliosAsync,
} from 'features/advertising/advertisingSlice';

import SpinnerGrow from 'components/SpinnerGrow';
import { SEARCH_TERMS } from 'features/advertising/utils/constants';
import classNames from 'utils/classNames';

const GenerateReportButton = ({
  accountId,
  marketplace,
  campaignType,
  recordType,
  ruleIds,
}) => {
  const dispatch = useDispatch();
  const selectedDates = useSelector(selectCurrentDateRange);

  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);

    try {
      const report = await dispatch(
        createReportAsync({
          accountId,
          marketplace,
          campaignType,
          recordType,
          ruleIds,
          ...selectedDates,
        })
      );

      await dispatch(
        getReportItemsAsync(report.advOptimizationReportId, {
          accountId,
          marketplace,
        })
      );

      setLoading(false);

      if (recordType === SEARCH_TERMS) {
        await dispatch(
          getPortfoliosAsync({
            pageSize: 1000,
            accountId,
            marketplace,
          })
        );

        const campaigns = await dispatch(
          getCampaignsAsync({
            pageSize: 1000,
            accountId,
            marketplace,
            campaignType,
            targetingType: 'manual',
            state: 'enabled',
          })
        );

        if (campaigns.rows.length) {
          await dispatch(
            getAdGroupsAsync({
              pageSize: 1000,
              accountId,
              marketplace,
              campaignType,
              advCampaignIds: campaigns.rows.map((c) => c.advCampaignId),
            })
          );
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={loading || ruleIds.length === 0}
      className={classNames(
        'flex items-center disabled:opacity-95 justify-center w-full rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-secondary text-sm font-medium text-grayscale-300 tracking-2 font-bold focus:outline-none',
        loading || ruleIds.length === 0
          ? 'opacity-95 cursor-not-allowed'
          : 'hover:bg-secondary-light'
      )}
    >
      {loading ? (
        <>
          Generating Report &nbsp;
          <SpinnerGrow color="white" />
        </>
      ) : (
        'Generate Report'
      )}
    </button>
  );
};

export default GenerateReportButton;
