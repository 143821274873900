import axios from 'axios';
import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid';

import classNames from 'utils/classNames';

const ClientSelector = ({ onChange }) => {
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const [display, setDisplay] = useState(`Select Partner's Account`);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios
        .get('/agency/clients/list', { params: { search } })
        .then((response) => {
          setClients(response.data.data.rows);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const onSelect = ({ agencyClientId, client }) => {
    setDisplay(client);
    if (isFunction(onChange)) {
      onChange(agencyClientId);
    }
  };

  return (
    <div className="w-64 mt-3">
      <Popover as="div" className="relative inline-block w-full">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                'inline-flex w-full justify-between bg-grayscale-400 px-5 py-3.5 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
                open ? 'rounded-t-2xl' : 'rounded-2xl'
              )}
            >
              <span className="text-grayscale-900 text-13 leading-1.5">
                {display}
              </span>
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-grayscale-800"
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={'div'}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="border border-grayscale-400 absolute right-0 w-64 origin-top-right rounded-b-2xl bg-white focus:outline-none z-50 shadow-2xl">
                <div className="p-3">
                  <div className="relative rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SearchIcon
                        className="h-5 w-5 text-grayscale-700"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full text-13 bg-grayscale-400 rounded-2xl border-0 py-2.5 pl-10 text-grayscale-800 placeholder:text-grayscale-600 border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <div className="h-48 overflow-y-auto mt-2">
                    <PerfectScrollbar>
                      <ul>
                        {clients.map((client) => (
                          <li
                            key={client.agencyClientId}
                            className="text-13 text-grayscale-900 leading-1.5 hover:bg-grayscale-400"
                          >
                            <Popover.Button
                              className="w-full text-left py-2"
                              onClick={() => onSelect(client)}
                            >
                              <span>{client.client}</span>
                            </Popover.Button>
                          </li>
                        ))}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ClientSelector;
