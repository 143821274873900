import { Route, Switch, NavLink, Redirect } from 'react-router-dom';

import classNames from 'utils/classNames';
import MyWorkspace from './workspace/MyWorkspace';
import TeamWorkspace from './workspace/TeamWorkspace';

const Workspace = () => {
  const tabs = [
    {
      name: 'My Workspace',
      href: '/home/my-workspace',
      exact: false,
      visible: true,
      component: MyWorkspace,
    },
    {
      name: 'My Team Workspace',
      href: '/home/team-workspace',
      exact: false,
      visible: true,
      component: TeamWorkspace,
    },
  ];

  return (
    <div>
      <h3 className="font-inter text-25 font-bold leading-1.2 tracking-3/4 text-grayscale-900">
        Workspace
      </h3>

      <div className="border-b border-grayscale-500 mt-4">
        <nav
          className="-mb-px flex space-x-8 text-grayscale-800"
          aria-label="Tabs"
        >
          {tabs
            .filter((tab) => tab.visible)
            .map((tab) => (
              <NavLink
                to={tab.href}
                key={tab.name}
                exact={tab.exact}
                className={classNames(
                  'text-13 flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent leading-1.5'
                )}
                activeClassName="border-secondary-light font-bold text-secondary-light"
              >
                {tab.name}
              </NavLink>
            ))}
        </nav>
      </div>

      <Switch>
        <Route
          path="/home"
          exact={true}
          render={() => <Redirect to="/home/my-workspace" />}
        />

        {tabs.map((tab) => (
          <Route
            key={tab.href}
            path={tab.href}
            component={tab.component}
            exact={tab.exact}
          />
        ))}
      </Switch>
    </div>
  );
};

export default Workspace;
