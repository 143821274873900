import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import { Tab } from '@headlessui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MailBlockTab from './components/MailBlockTab';
import { useSelector } from 'react-redux';
import useMail from './components/useMail';

const MailTemplatesModal = () => {
  const { url } = useRouteMatch();
  const tabs = ['Templates', 'Signatures'];
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();
  const { type } = useSelector((state) => state.mail);
  const { setMailData } = useMail();

  const onClose = () => {
    setOpen(false);
    history.push(url.replace('/template', ''));
  };
  useEffect(() => {
    setSelectedIndex(type === 'signature' ? 1 : 0);
  }, [type]);

  const onChangeTab = (index) => {
    setSelectedIndex(index);
    setMailData('', '', type);
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-9/12 xl:w-3/5 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={tabs[selectedIndex]}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            iconClassname="h-8 w-8"
            rightContent={
              <button className="relative group mr-4">
                <XIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 absolute bottom-0 right-0 bg-red-500 text-white rounded-xl leading-none" />
              </button>
            }
            onClose={onClose}
          />
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(index) => onChangeTab(index)}
          >
            <Tab.List>
              {tabs.map((category) => {
                return (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        'w-1/2 pb-5 text-sm font-medium leading-5',
                        selected
                          ? ' text-blue-600 hover:text-blue-700 border-b-2 border-blue-700 font-bold'
                          : 'border-b border-gray-200'
                      )
                    }
                  >
                    {category}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <MailBlockTab type="template" isCurrent={selectedIndex === 0} />
              </Tab.Panel>
              <Tab.Panel>
                <MailBlockTab
                  type="signature"
                  isCurrent={selectedIndex === 1}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
    </>
  );
};

export default MailTemplatesModal;
