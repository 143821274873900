import { useSelector } from 'react-redux';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { linkStyles } from 'utils/tabStyles';
import useBodyClass from 'hooks/useBodyClass';

import MarketplaceContainer from '../components/MarketplaceContainer';
import MarketplacePicker from '../components/MarketplacePicker';

import Trends from './Trends/Trends';
import Reports from './Reports/Reports';
import Analytics from './Analytics/Analytics';
import KeywordTracking from './KeywordTracking/KeywordTracking';
import CampaignType from './CampaignType/CampaignType';
import Sync from './Sync/Sync';

import classNames from 'utils/classNames';

const Advertising = () => {
  useBodyClass(['client-profile', 'no-sidebar']);

  const { url, path } = useRouteMatch();
  const { account, marketplace } = useSelector((state) => state.account);

  const tabs = [
    { name: 'Analytics', exact: true, url: url },
    { name: 'Reports', exact: false, url: `${url}/reports` },
    { name: 'Trends', exact: false, url: `${url}/trends` },
    { name: 'Tracking', exact: false, url: `${url}/keyword-tracking` },
    { name: 'Sync', exact: false, url: `${url}/sync` },
    { name: 'Sponsored Products', exact: false, url: `${url}/products` },
    { name: 'Sponsored Brands', exact: false, url: `${url}/brands` },
    { name: 'Sponsored Display', exact: false, url: `${url}/display` },
  ];

  return (
    <MarketplaceContainer>
      <div className="flex justify-between items-center">
        <div className="tablinks">
          {tabs.map((tab, i) => (
            <NavLink
              key={tab.name}
              to={tab.url}
              exact={tab.exact}
              className={classNames(linkStyles, 'px-3')}
              activeClassName="current border-secondary-light text-secondary-light font-bold hover:border-secondary-light hover:text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </div>

        <MarketplacePicker />
      </div>

      {account.advProfiles.find(
        (profileId) => profileId.marketplaceId === marketplace.marketplaceId
      ) ? (
        <div className="mt-5">
          <Switch>
            <Route exact path={path} component={Analytics} />

            <Route exact path={`${path}/sync`} component={Sync} />

            <Route exact path={`${path}/reports`} component={Reports} />

            <Route path={`${path}/trends`} component={Trends} />

            <Route
              path={`${path}/keyword-tracking`}
              component={KeywordTracking}
            />

            {[
              { value: 'sponsoredProducts', url: 'products' },
              { value: 'sponsoredBrands', url: 'brands' },
              { value: 'sponsoredDisplay', url: 'display' },
            ].map((campaignType) => {
              return (
                <Route
                  key={`advertising-pages-campaign-type-${campaignType.value}`}
                  path={`${path}/${campaignType.url}`}
                  render={() => (
                    <CampaignType campaignType={campaignType.value} />
                  )}
                />
              );
            })}
          </Switch>
        </div>
      ) : (
        <div className="h-96 flex items-center justify-center">
          <p className="font-inter text-3xl tracking-2 text-grayscale-800 font-bold text-center">
            This account does'nt have advertising profile at this marketplace.
          </p>
        </div>
      )}
    </MarketplaceContainer>
  );
};

export default Advertising;
