import { useRef, useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';
import { FileDrop } from 'react-file-drop';
import { useDispatch } from 'react-redux';
//import { S3_LIMIT_SIZE } from 'utils/constants';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import SpinnerGrow from 'components/SpinnerGrow';

const UploadAttachment = ({ onUpload, primaryKey, postUrl, onRefreshList }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    uploadFiles(event.target.files);
  };

  // * Upload files to bulk update the details
  const uploadFiles = (files) => {
    if (fileInputRef.current.files.length <= 0) {
      fileInputRef.current.files = files;
    }
    onStartUpload();
  };

  // * Triggered when the button for adding files is clicked
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onStartUpload = async () => {
    setUploading(true);
    const files = fileInputRef.current.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append(primaryKey.key, primaryKey.value);
      await axios.post(postUrl, formData).then((res) => {
        if (res.data.success) {
          onUpload(res.data.data);
          dispatch(
            setAlert('success', 'Successfully Uploaded', res.data.message)
          );
          dispatch(onRefreshList);
        } else {
          dispatch(
            setAlert('error', 'Unsuccessfully Uploaded', res.data.message)
          );
        }
      });
    }
    setUploading(false);
  };

  return (
    <div
      className="flex flex-col justify-center relative"
      title="Upload Attachment"
    >
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="hidden"
        multiple
      />
      <FileDrop onTargetClick={onTargetClick}>
        <PaperClipIcon
          className="w-6 h-6 inline text-gray-500 hover:text-green-600"
          title="Upload Attachment"
        />
      </FileDrop>

      {uploading && (
        <div className="absolute -right-5 bottom-2">
          <SpinnerGrow size={3} />
        </div>
      )}
    </div>
  );
};

export default UploadAttachment;
