import { useEffect } from 'react';
import { useTasks } from 'Version2/features/tasks';
import OnboardingTaskItem from 'Version2/features/tasks/components/TaskItem/OnboardingTaskItem';
import { useHistory } from 'react-router-dom';

const Onboarding = ({ taskList }) => {
  const history = useHistory();

  const { taskItemList, getTaskItemList, taskCount, getTaskCount } = useTasks();

  useEffect(() => {
    getTaskCount({ taskListId: taskList?.taskListId });
  }, [taskList]);

  useEffect(() => {
    if (taskList) {
      getTaskItemList({
        isSubtask: false,
        taskListId: taskList.taskListId,
        include: ['assignees[avatar]', 'subtasks[assignees[avatar]]'],
        attributes: [
          'due',
          'name',
          'taskItemId',
          'taskListId',
          'dueDate',
          'status',
          'priority',
          'hasSubtasks',
          'description',
          'parentTaskItemId',
          'hierarchyLevelId',
          'isSkipped',
        ],
      });
    }
  }, [taskList]);

  return taskList ? (
    <div>
      {taskCount.loading ? (
        <div className="flex justify-center my-16">
          <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2 animate-pulse">
            Loading. Please Wait.
          </p>
        </div>
      ) : taskCount.data ? (
        <div className="bg-white">
          <div className="grid px-4 grid-cols-12 gap-4 text-11 text-grayscale-700 leading-1.5 pt-5">
            <p className="col-span-3 lg:col-span-4 xl:col-span-4">Task name</p>
            <p className="col-span-3 lg:col-span-3 xl:col-span-4">
              Task Description
            </p>
            <p className="col-span-2 xl:col-span-1 text-center">Due Date</p>
            <p className="col-span-2 text-center">Assignees</p>
            <p className="col-span-2 lg:col-span-1 text-center">Action</p>
          </div>

          {taskItemList.data.rows.map((taskItem) => (
            <OnboardingTaskItem
              key={taskItem.taskItemId}
              taskItem={taskItem}
              onClick={(task) =>
                history.push(
                  `/task/${task.taskItemId}?from=${history.location.pathname}`
                )
              }
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center my-16">
          <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2">
            No tasks found.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-center my-16">
      <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2">
        Something went wrong. Please contact administrator
      </p>
    </div>
  );
};

export default Onboarding;
