import { NavLink, useRouteMatch } from 'react-router-dom';

const RedirectToAdGroup = ({
  adGroupName,
  campaignType,
  adGroupId,
  className = '',
  enable = true,
}) => {
  const { url } = useRouteMatch();

  const links = {
    sponsoredProducts: 'products',
    sponsoredBrands: 'brands',
    sponsoredDisplay: 'display',
  };

  return enable ? (
    <NavLink
      className={className}
      to={`${url}/${links[campaignType]}/ad-groups?adGroupId=${adGroupId}`}
    >
      {adGroupName}
    </NavLink>
  ) : (
    <p className={className}>{adGroupName}</p>
  );
};

export default RedirectToAdGroup;
