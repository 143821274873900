import { useSelector } from 'react-redux';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { linkStyles } from 'utils/tabStyles';
import useBodyClass from 'hooks/useBodyClass';

import MarketplaceContainer from '../components/MarketplaceContainer';
import MarketplacePicker from '../components/MarketplacePicker';

import classNames from 'utils/classNames';
import Overview from './Overview/Overview';
import Inventory from './Inventory/Inventory';
import Planning from './Planning/Planning';

const Products = () => {
  useBodyClass(['client-profile', 'no-sidebar']);

  const { url, path } = useRouteMatch();
  const { account, marketplace } = useSelector((state) => state.account);

  const tabs = [
    { name: 'Overview', exact: true, url: url },
    { name: 'Inventory', exact: false, url: `${url}/inventory` },
    { name: 'Planning', exact: false, url: `${url}/planning` },
  ];

  return (
    <MarketplaceContainer>
      <div className="flex justify-between items-center">
        <div className="tablinks">
          {tabs.map((tab, i) => (
            <NavLink
              key={tab.name}
              to={tab.url}
              exact={tab.exact}
              className={classNames(linkStyles, 'px-3')}
              activeClassName="current border-secondary-light text-secondary-light font-bold hover:border-secondary-light hover:text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </div>

        <MarketplacePicker />
      </div>

      <div className="mt-5">
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Overview account={account} marketplace={marketplace} />
            )}
          />

          <Route
            exact
            path={`${path}/inventory`}
            render={() => (
              <Inventory account={account} marketplace={marketplace} />
            )}
          />

          <Route
            path={`${path}/planning`}
            render={() => (
              <Planning account={account} marketplace={marketplace} />
            )}
          />
        </Switch>
      </div>
    </MarketplaceContainer>
  );
};

export default Products;
