import * as yup from 'yup';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { XIcon, ArrowSmLeftIcon } from '@heroicons/react/outline';

import { Button, Modal } from 'components';
import { Label } from 'components/Forms';

import { useAlerts } from 'features/alerts';
import useTermination from '../useTermination';
import usePermissions from 'hooks/usePermissions';
import { CheckCircleIcon } from '@heroicons/react/solid';

const TerminationDetailsModal = ({
  open,
  setOpen,
  onEvaluate,
  termination,
  client = '',
}) => {
  const { userCan } = usePermissions();
  const { popupAlert } = useAlerts();
  const { REASONS, STATUS } = useTermination();

  const onSubmit = (values, actions) => {
    const { reason, denyReason, action } = values;

    let data = action === 'deny' ? { reason, denyReason } : { reason };

    axios
      .post(
        `/v2/agency/terminations/${termination?.terminationId}/${action}`,
        data
      )
      .then((response) => {
        popupAlert({
          type: action === 'deny' ? 'info' : 'success',
          title: response.data.message,
        });

        setOpen(false);
        onEvaluate();
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-20"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <div className="p-5 border-b border-grayscale-500 flex items-center justify-between">
          <div>
            <h4 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
              Termination Request
            </h4>

            <h5 className="font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900 mt-2">
              For {client}
            </h5>
          </div>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="p-5">
          {termination?.status === STATUS.DENIED && (
            <div>
              <div className=" bg-red-50 rounded-lg py-4 px-4 mb-4">
                <p className="text-left text-xl text-red-500 font-bold pb-4">
                  Your termination request was denied
                </p>

                <Label>Reason for deny</Label>
                <p className="text-left text-sm font-normal">
                  {termination?.denyReason}
                </p>
              </div>
            </div>
          )}

          <Formik
            initialValues={{
              denyReason: '',
              action: 'approve',
              reason: termination?.reason ?? '',
              moreInformation: termination?.moreInformation ?? '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
              denyReason: yup.string().when('action', {
                is: 'deny',
                then: yup.string().required('Reason for denying is required.'),
              }),
            })}
          >
            {({ setFieldValue, values, isSubmitting }) => (
              <>
                <Form>
                  {values.action === 'deny' ? (
                    <div className="space-y-6">
                      <h5 className="font-inter font-bold tracking-3/4 leading-1.2">
                        Let the requestor know why are you denying the request
                      </h5>

                      <div>
                        <Label>Reason for denying:</Label>
                        <div>
                          <Field
                            rows={5}
                            as="textarea"
                            name="denyReason"
                            className="rounded-15 border-0 mt-2 bg-grayscale-400 text-13 text-grayscale-900 leading-1.5 p-2 w-full"
                          />

                          <ErrorMessage
                            name="denyReason"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <button
                          type="button"
                          onClick={(e) => {
                            setFieldValue('action', 'approve');
                            e.preventDefault();
                          }}
                          className="flex items-center space-x-1 text-sm leading-1.5 tracking-2 font-bold text-grayscale-700 hover:text-grayscale-900"
                        >
                          <ArrowSmLeftIcon className="w-5 h-5" />
                          <span>Go Back</span>
                        </button>

                        <Button
                          px={8}
                          py={2}
                          type="submit"
                          roundedSize="40"
                          bgColor="secondary"
                          hoverColor="secondary-light"
                          fontWeight="bold"
                          classes="leading-1.5 tracking-2"
                          textColor="grayscale-300"
                          showLoading={true}
                          loading={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      <div>
                        <Label>Reason for termination</Label>
                        <div>
                          <Field
                            as="select"
                            name="reason"
                            disabled={termination?.status !== STATUS.PENDING}
                            className="rounded-15 bg-grayscale-400 text-13 text-grayscale-900 leading-1.5 py-2 px-5 w-full"
                          >
                            {REASONS.map((reason) => (
                              <option value={reason} key={reason}>
                                {reason}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div>
                        <Label classes="mt-2">Retention Effort Checklist</Label>
                        <ul className="space-y-2 mt-4">
                          {!!(termination?.retentionEffortCheckList ?? [])
                            .length ? (
                            (termination?.retentionEffortCheckList ?? []).map(
                              (checklist) => (
                                <li
                                  key={checklist}
                                  className="text-sm text-gray-500 flex space-x-2"
                                >
                                  <CheckCircleIcon className="w-5 h-5 text-success" />
                                  <span>{checklist}</span>
                                </li>
                              )
                            )
                          ) : (
                            <li className="text-sm text-gray-500 flex space-x-2">
                              No rentention Checklists
                            </li>
                          )}
                        </ul>
                      </div>

                      <div>
                        <Label>Additional Notes</Label>
                        <div>
                          <Field
                            disabled={true}
                            rows={5}
                            as="textarea"
                            name="moreInformation"
                            className="rounded-15 border-0 text-13 text-grayscale-900 px-0 leading-1.5 py-1 w-full"
                          />
                        </div>
                      </div>

                      {termination?.status === STATUS.PENDING &&
                        userCan('termination.evaluate') && (
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              onClick={(e) => {
                                setFieldValue('action', 'deny');
                                e.preventDefault();
                              }}
                              className="py-2 px-8 rounded-40 bg-error-dark hover:bg-error text-sm leading-1.5 tracking-2 font-bold text-grayscale-300"
                            >
                              Deny
                            </button>

                            <Button
                              px={8}
                              py={2}
                              type="submit"
                              roundedSize="40"
                              bgColor="success"
                              hoverColor="success-light"
                              fontWeight="bold"
                              classes="leading-1.5 tracking-2"
                              textColor="grayscale-300"
                              showLoading={true}
                              loading={isSubmitting}
                            >
                              Approve
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default TerminationDetailsModal;
