import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { Parser } from 'json2csv';
import { PlusIcon } from '@heroicons/react/outline';

const ExportButton = ({ podId }) => {
  const { range: dateRange } = useSelector((state) => state.datePicker);
  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  const exportData = async () => {
    let payload = {
      ...params,
      ...dateRange,
      include: ['previousData'],
      roleDepartment: 'ppc',
      roleGroupLevel: 'cell',
      attributes: [
        'userId',
        'firstName',
        'lastName',
        'totalClients',
        'totalTerminatedClients',
        'advertisingSales',
        'advertisingClicks',
        'advertisingImpressions',
        'advertisingCTR',
        'advertisingCPC',
        'advertisingCVR',
        'advertisingCost',
        'advertisingACOS',
        'totalSales',
        'tacos',
        'organicSalesPercentage',
        'advertisingSalesPercentage',
      ],
    };
    if (isNumber(podId)) {
      payload.groupPodId = podId;
    }
    const response = await axios.get('/users/export', {
      params: payload,
    });

    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
      encoding: 'UTF-8',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'ppcSpecialists.csv';
    link.click();
  };

  return (
    <button
      onClick={() => {
        exportData();
      }}
      className="flex items-center border-2 border-secondary rounded-40 px-5 py-1.5 text-secondary tracking-2 font-bold text-sm leading-1.5"
    >
      <span className="mr-2">
        <PlusIcon className="w-4 h-4" />
      </span>
      Export
    </button>
  );
};

export default ExportButton;
