import axios from 'axios';
import { isArray, lowerCase, startCase } from 'lodash';
import { XIcon } from '@heroicons/react/outline';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import Modal from 'components/ModalPlain';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

import AccountAudit from './AccountAudit';
import ClientCall from './ClientCall';
import Proposal from './Proposal';
import Reporting from './Reporting';
import Research from './Research';
import CampaignCreation from './CampaignCreation';
import Optimization from './Optimization';

yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    return isArray(list) && list.length === new Set(list.map(mapper)).size;
  });
});

const FormModal = ({ open, setOpen, account, refreshList }) => {
  const ActionTypes = {
    ACCOUNT_AUDIT: {
      component: AccountAudit,
      initialValues: {
        auditLink: '',
        otherInformation: '',
      },
    },
    CLIENT_CALL: {
      component: ClientCall,
      initialValues: {
        callAgenda: '',
        callRecordingLink: '',
        meetingNotes: '',
      },
    },
    PROPOSAL: {
      component: Proposal,
      initialValues: {
        proposalLink: '',
        otherInformation: '',
      },
    },
    REPORTING: {
      component: Reporting,
      initialValues: {
        type: 'EOM Report',
        reportLink: '',
        otherInformation: '',
      },
    },
    KEYWORD_RESEARCH: {
      component: Research,
      initialValues: {
        products: [{ asin: '', link: '' }],
        otherInformation: '',
      },
    },
    PRODUCT_RESEARCH: {
      component: Research,
      initialValues: {
        products: [{ asin: '', link: '' }],
        otherInformation: '',
      },
    },
    CAMPAIGN_CREATION: {
      component: CampaignCreation,
      initialValues: {
        campaignName: '',
        campaignType: 'sponsoredProducts',
        startDate: '',
        endDate: '',
        budget: '',
        targetingType: 'auto',
        strategy: 'legacyForSales',
        adGroupName: '',
        negativeKeywords: '',
        products: [
          {
            asin: '',
          },
        ],
      },
    },
    OPTIMIZATION: {
      component: Optimization,
      initialValues: {
        optimizationType: 'Cutting Bleeders',
        recordType: 'keywords',
        otherInformation: '',
        rules: [
          {
            advRuleId: '',
            name: '',
          },
        ],
      },
    },
  };

  const validationSchema = yup.object().shape({
    accountId: yup.string().required('Required'),
    action: yup.string().required('Required'),
    data: yup
      .object()
      .when('action', (action, dataSchema) => {
        switch (action) {
          case 'OPTIMIZATION':
            return yup.object().shape({
              optimizationType: yup.string().required('Required'),
              recordType: yup.string().required('Required'),
              rules: yup
                .array()
                .of(
                  yup.object().shape({
                    advRuleId: yup.number().required('Required'),
                    name: yup.string().required('Required'),
                  })
                )
                .unique('duplicate rule', (a) => a.advRuleId)
                .required('Required')
                .min(1, 'Must have at least one item.'),
              otherInformation: yup.string().required('Required'),
            });
          case 'ACCOUNT_AUDIT':
            return yup.object().shape({
              auditLink: yup
                .string()
                .url('Must be a valid URL')
                .required('Required'),
              otherInformation: yup.string().required('Required'),
            });
          case 'REPORTING':
            return yup.object().shape({
              type: yup.string().required('Required'),
              reportLink: yup
                .string()
                .url('Must be a valid URL')
                .required('Required'),
              otherInformation: yup.string().required('Required'),
            });
          case 'CAMPAIGN_CREATION':
            return yup.object().shape({
              // Campaign
              startDate: yup.date().required('Required'),
              endDate: yup
                .date()
                .min(
                  yup.ref('StartDate'),
                  "End date can't be before Start date"
                ),
              budget: yup.number().required('Required'),
              campaignName: yup.string().required('Required'),
              campaignType: yup.string().required('Required'),
              targetingType: yup
                .string()
                .when('campaignType', (campaignType, targetingTypeSchema) => {
                  switch (campaignType) {
                    case 'sponsoredProducts':
                    case 'sponsoredDisplay':
                      return yup.string().required('Required');

                    default:
                      return targetingTypeSchema;
                  }
                }),
              strategy: yup
                .string()
                .when('campaignType', (campaignType, strategySchema) => {
                  switch (campaignType) {
                    case 'sponsoredProducts':
                    case 'sponsoredDisplay':
                      return yup.string().required('Required');

                    default:
                      return strategySchema;
                  }
                }),
              adFormat: yup
                .string()
                .when('campaignType', (campaignType, adFormatSchema) => {
                  switch (campaignType) {
                    case 'sponsoredBrands':
                      return yup.string().required('Required');

                    default:
                      return adFormatSchema;
                  }
                }),
              // Ad Group
              adGroupName: yup.string().required('Required'),
              adGroupTargeting: yup
                .string()
                .when(
                  ['campaignType', 'targetingType'],
                  (campaignType, targetingType, adGroupTargetingSchema) => {
                    switch (campaignType) {
                      case 'sponsoredProducts':
                        return targetingType === 'manual'
                          ? yup.string().required('Required')
                          : adGroupTargetingSchema;

                      default:
                        return adGroupTargetingSchema;
                    }
                  }
                ),
              adGroupCreativeType: yup
                .string()
                .when(
                  'campaignType',
                  (campaignType, adGroupCreativeTypeSchema) => {
                    switch (campaignType) {
                      case 'sponsoredDisplay':
                        return yup.string().required('Required');

                      default:
                        return adGroupCreativeTypeSchema;
                    }
                  }
                ),
              // negative keywords
              negativeKeywords: yup
                .string()
                .when(
                  'campaignType',
                  (campaignType, negativeKeywordsSchema) => {
                    switch (campaignType) {
                      case 'sponsoredProducts':
                      case 'sponsoredBrands':
                        return yup.string().required('Required');

                      default:
                        return negativeKeywordsSchema;
                    }
                  }
                ),
              // products
              products: yup
                .string()
                .when('campaignType', (campaignType, productsSchema) => {
                  switch (campaignType) {
                    case 'sponsoredProducts':
                      return yup
                        .array()
                        .of(
                          yup.object().shape({
                            asin: yup.string().required('Required'),
                          })
                        )
                        .unique('Duplicate Asin', (a) => a.asin)
                        .required('Required')
                        .min(1, 'Must have at least one item.');

                    default:
                      return productsSchema;
                  }
                }),
            });
          case 'CLIENT_CALL':
            return yup.object().shape({
              callRecordingLink: yup
                .string()
                .url('Must be a valid URL')
                .required('Required'),
              callAgenda: yup.string().required('Required'),
              meetingNotes: yup.string().required('Required'),
            });
          case 'KEYWORD_RESEARCH':
            return yup.object().shape({
              products: yup
                .array()
                .of(
                  yup.object().shape({
                    asin: yup.string().required('Required'),
                    link: yup
                      .string()
                      .url('Must be a valid URL')
                      .required('Required'),
                  })
                )
                .unique('Duplicate Asin', (a) => a.asin)
                .required('Required')
                .min(1, 'Must have at least one item.'),
              otherInformation: yup.string().required('Required'),
            });
          case 'PRODUCT_RESEARCH':
            return yup.object().shape({
              products: yup
                .array()
                .of(
                  yup.object().shape({
                    asin: yup.string().required('Required'),
                    link: yup
                      .string()
                      .url('Must be a valid URL')
                      .required('Required'),
                  })
                )
                .unique('Duplicate Asin', (a) => a.asin)
                .required('Required')
                .min(1, 'Must have at least one item.'),
              otherInformation: yup.string().required('Required'),
            });
          case 'PROPOSAL':
            return yup.object().shape({
              proposalLink: yup
                .string()
                .url('Must be a valid URL')
                .required('Required'),
              otherInformation: yup.string().required('Required'),
            });
          default:
            return dataSchema;
        }
      })
      .required('Required'),
  });

  const onSubmit = (values, actions) => {
    let payload = { ...values };

    if ('endDate' in payload.data && !!!payload.data.endDate) {
      delete payload.data.endDate;
    }

    axios.post('/account/advertising/logs', payload).finally(() => {
      actions.setSubmitting(false);
      setOpen(false);
      refreshList();
    });
  };

  const SubForm = ({ handleChange, values, setValues, errors, Component }) => {
    return (
      <Component
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        errors={errors}
      />
    );
  };

  return (
    <Modal
      zIndex="z-20"
      open={open}
      setOpen={setOpen}
      persistent={true}
      align="top"
    >
      <div className="inline-block max-w-lg w-full my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl">
        <div className="px-5 py-6 flex justify-between items-center">
          <h3 className="text-grayscale-900 leading-1.2 text-lg font-bold font-inter">
            Account Update
          </h3>
          <XIcon
            className="w-6 h-6 text-grayscale-700"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="px-5 py-6">
          <Formik
            initialValues={{
              action: 'ACCOUNT_AUDIT',
              accountId: account.accountId,
              data: ActionTypes['ACCOUNT_AUDIT'].initialValues,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleChange, isSubmitting, values, setValues, errors }) => (
              <Form>
                <div className="flex w-full items-center font-sourceSansPro justify-center gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
                  <div className="w-11/12">
                    <Label
                      classes="text-left leading-1.2"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Action type
                      <RequiredAsterisk />
                    </Label>
                    <Field
                      name="action"
                      as="select"
                      className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
                      onChange={(e) => {
                        setValues({
                          action: e.target.value,
                          accountId: account.accountId,
                          data: ActionTypes[e.target.value].initialValues,
                        });
                      }}
                    >
                      {Object.keys(ActionTypes).map((action) => (
                        <option key={action} value={action}>
                          {startCase(lowerCase(action))}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="action"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />

                    <SubForm
                      Component={ActionTypes[values.action]['component']}
                      handleChange={handleChange}
                      values={values}
                      setValues={setValues}
                      errors={errors}
                    />

                    <div className="flex justify-start items-center gap-8 justify-self-start">
                      <button
                        className="text-sm font-bold tracking-2 text-secondary-light"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="text-sm font-bold tracking-2 bg-secondary text-grayscale-300 rounded-3xl px-8 py-2"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
