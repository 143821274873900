import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import Card from 'components/Card';
import { DocumentTextIcon } from '@heroicons/react/outline';

const generateDistinctColors = (count, hue) => {
  const colors = [];
  const goldenRatio = 0.618033988749895;
  for (let i = 0; i < count; i++) {
    hue += goldenRatio;
    hue %= 1;
    const color = `hsl(${Math.floor(hue * 360)}, 70%, 50%)`;
    colors.push(color);
  }
  return colors;
};

const ResponseChart = ({ title, data }) => {
  // Convert object to array
  const chartSourceData = Object.values(data);

  const distinctColorsSource = generateDistinctColors(
    chartSourceData.length,
    Math.random()
  );

  const total = chartSourceData.reduce((acc, entry) => acc + entry.value, 0);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataItem = payload[0].payload;
      const percentage = ((dataItem.value / total) * 100).toFixed(2);
      return (
        <div className="bg-white shadow-md p-2 border border-gray-300 rounded">
          <p>{`${dataItem.name} (${dataItem.value}): ${percentage}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <div className="flex justify-center">
        <div className="w-1/2 flex justify-center">
          <div>
            <h2 className="text-center text-xl font-bold">
              <DocumentTextIcon className="inline w-5 h-5 mb-1" /> {title}
            </h2>
            <PieChart width={300} height={300}>
              <Pie
                data={chartSourceData}
                cx={150}
                cy={150}
                innerRadius={30}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label
              >
                {chartSourceData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={distinctColorsSource[index]}
                  />
                ))}
              </Pie>

              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="top"
                height={60}
                wrapperStyle={{ lineHeight: '16px', color: '#fff' }}
              />
            </PieChart>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ResponseChart;
