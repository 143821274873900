import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

import CustomTooltip from 'components/Charts/CustomTooltip';
import ProductFilter from '../../../Products/components/ProductFilter';

const SalesAndTrafficConversion = ({ accountId, marketplace }) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [records, setRecords] = useState([]);
  const [productIds, setProductIds] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        accountId,
        marketplace,
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
        attributes: ['pageViews', 'sessions', 'unitCount', 'conversionRate'],
      };

      if (productIds.length) {
        params.productIds = productIds;
      }

      const summaryResponse = await axios.get('/account/profits', {
        params,
      });

      const recordsResponse = await axios.get('/account/profits/records', {
        params,
      });

      if (isSubscribed) {
        setSummary(summaryResponse.data.data);
        setRecords(recordsResponse.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, productIds]);

  const metrics = [
    {
      key: 'pageViews',
      display: 'Page Views',
      formatter: numberFormatter,
      color: '#1879D8',
      yAxisId: '0',
    },
    {
      key: 'sessions',
      display: 'Sessions',
      formatter: numberFormatter,
      color: '#FB3767',
      yAxisId: '0',
    },
    {
      key: 'unitCount',
      display: 'Units Sold',
      formatter: numberFormatter,
      color: '#00BA88',
      yAxisId: '0',
    },
    {
      key: 'conversionRate',
      display: 'Conversion Rate',
      formatter: percentageFormatter,
      color: '#F9DC7D',
      yAxisId: '1',
    },
  ];

  return (
    <div className="mt-10">
      <p className="text-grayscale-700 text-13 leading-1.5">This Month</p>
      <p className="text-grayscale-900 tracking-3/4 font-inter font-bold leading-1.2 mb-5">
        Sales and Traffic Conversion
      </p>

      <div className="border border-grayscale-500 px-4 py-6 rounded-xl">
        <div className="grid grid-cols-6 gap-8">
          <div className="col-span-4 flex justify-between">
            {metrics.map((metric) => (
              <div key={`sales-and-traffic-conversion-${metric.key}`}>
                <p className="text-grayscale-700 leading-1.5 text-11">
                  {metric.display}
                </p>
                <p className="text-grayscale-800 font-inter tracking-3/4 leading-1.2 text-25">
                  {metric.formatter(summary[metric.key])}
                </p>
              </div>
            ))}
          </div>

          <ProductFilter
            accountId={accountId}
            marketplace={marketplace}
            value={productIds}
            onChange={setProductIds}
            buttonBg="grayscale-400"
            className="col-span-2"
          />
        </div>

        <div className="mt-10">
          <div className="h-60">
            <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
              <ComposedChart data={records}>
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="startDate"
                  style={{ fontSize: '11px' }}
                  tickFormatter={(value) => moment(value).format('MMM D')}
                />

                <YAxis
                  yAxisId="0"
                  orientation="left"
                  stroke="#666666"
                  style={{ fontSize: '11px' }}
                  tickFormatter={(tick) => currencyFormatter(tick)}
                  axisLine={false}
                />

                <YAxis
                  yAxisId="1"
                  orientation="right"
                  stroke="#666666"
                  style={{ fontSize: '11px' }}
                  tickFormatter={(tick) => percentageFormatter(tick)}
                  axisLine={false}
                />

                <Tooltip
                  content={
                    <CustomTooltip
                      containerStyle={{ width: '28rem' }}
                      containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl"
                      itemsClassName="grid grid-cols-2"
                    />
                  }
                />

                {metrics.map((metric) => (
                  <Line
                    yAxisId={metric.yAxisId}
                    key={`sales-and-traffic-conversion-line-charts-${metric.key}`}
                    type="linear"
                    dataKey={metric.key}
                    fill={metric.color}
                    stroke={metric.color}
                    name={metric.display}
                    dot={{ fill: metric.color }}
                    formatter={metric.formatter}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <div className="flex justify-center">
            {metrics.map((metric) => (
              <div
                key={`sales-and-traffic-conversion-chart-legends-${metric.key}`}
                className="flex items-center mx-2"
              >
                <div
                  style={{ backgroundColor: metric.color }}
                  className="w-6 h-1 mr-1"
                ></div>
                <p className="text-11 text-grayscale-900 leading-1.5">
                  {metric.display}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAndTrafficConversion;
