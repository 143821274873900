import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'utils/classNames';
import { XIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import usePermissions from 'hooks/usePermissions';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import useLoggedInUser from 'hooks/useLoggedInUser';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const ProfileMenu = ({ history, isOnboarding = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, name, initials } = useLoggedInUser();
  const { isAgencyLevel } = usePermissions();

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(open && 'opened', 'bell-notif')}
          >
            <div className="bell-div relative inline-flex items-center bg-white ml-3 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              <span className="sr-only">Open User Settings</span>
              {user.avatar ? (
                <Avatar
                  alt={name}
                  src={user.avatar?.thumbnailUrl}
                  className={classes.small}
                />
              ) : (
                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400">
                  <span className="font-medium leading-none text-white">
                    {initials}
                  </span>
                </span>
              )}
            </div>
          </Popover.Button>
          <Popover.Panel>
            <div className="menu-popup-div absolute bg-white shadow-lg border w-96 z-10 right-0">
              <div className="bg-green-50 py-8 text-gray-700">
                <span className="absolute right-5 top-3">
                  <Popover.Button>
                    <XIcon className="h-5 w-5 inline text-gray-400" />
                  </Popover.Button>
                </span>
                <div className="text-center flex flex-col items-center space-y-2">
                  {user.avatar ? (
                    <Avatar
                      alt={name}
                      src={user.avatar?.thumbnailUrl}
                      className={classes.small}
                    />
                  ) : (
                    <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-400">
                      <span className="leading-none text-white text-2xl">
                        {initials}
                      </span>
                    </span>
                  )}
                  <span className="text-sm uppercase text-gray-800">
                    {name}
                  </span>
                  {isAgencyLevel() && (
                    <span className="text-1xs text-gray-400 uppercase">
                      {user.role?.name}
                    </span>
                  )}
                  <span className="text-xs text-gray-400">{user.email}</span>
                  <div className="flex items-center divide-x-2 leading-none pt-2">
                    {!isOnboarding && (
                      <Link
                        to="/user-settings"
                        className="text-sm text-red-400 hover:text-red-600 pr-3"
                      >
                        {t('Dashboard.Settings')}
                      </Link>
                    )}
                    <Link
                      to="/sign-out"
                      className="text-sm text-red-700 hover:text-red-900 pl-3"
                    >
                      {t('Dashboard.SignOut')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="px-4 py-2">&nbsp;</div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default withRouter(ProfileMenu);
