import React, { useState, useEffect } from 'react';
import { useDayzed } from 'dayzed';
import classNames from 'utils/classNames';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';

const monthNamesFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdayNamesShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const Calendar = (props) => {
  return <div className="w-full">{props.children}</div>;
};

const Month = (props) => {
  return (
    <div className="grid grid-cols-7 gap-0.5 w-full">{props.children}</div>
  );
};

const DayOfMonth = (props) => {
  const { selected, unavailable, today, isInRange } = props;

  let cellStyle = '';
  if (today) cellStyle = 'bg-custom-light-green rounded-full';
  if (isInRange) cellStyle = 'bg-custom-light-blue add-range-bg';
  if (selected) cellStyle = 'bg-custom-blue text-white z-20 rounded-full';
  if (unavailable) cellStyle = 'opacity-25 cursor-not-allowed';

  return (
    <button
      type="button"
      className={`${cellStyle} col-span-1 text-center py-1.5 relative`}
      {...props}
    >
      <span className="relative">{props.children}</span>
    </button>
  );
};

const DayOfMonthEmpty = (props) => {
  return <div className="col-span-1 text-center">{props.children}</div>;
};

const RangeDatepicker = (props) => {
  const [hoveredDate, setHoveredDate] = useState(null);

  let { calendars, getBackProps, getForwardProps, getDateProps } =
    useDayzed(props);

  useEffect(() => {
    function navigate(e) {
      switch (e.keyCode) {
        case 37: // leftArrow
          getKeyOffset(-1);
          break;
        case 38: // leftArrow
          getKeyOffset(-7);
          break;
        case 39: // leftArrow
          getKeyOffset(1);
          break;
        case 40: // leftArrow
          getKeyOffset(7);
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', navigate);

    return () => window.removeEventListener('keydown', navigate);
  });

  function getKeyOffset(number) {
    const e = document.activeElement;
    let buttons = document.querySelectorAll('button');

    buttons.forEach((el, i) => {
      const newNodeKey = i + number;
      if (el == e) {
        if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
          buttons[newNodeKey].focus();
        } else {
          buttons[0].focus();
        }
      }
    });
  }

  function onMouseLeave() {
    setHoveredDate(null);
  }

  function onMouseEnter(date) {
    if (!props.selected.length) return;

    setHoveredDate(date);
  }

  function isInRange(date) {
    let { selected } = props;

    if (selected.length) {
      let firstSelected = selected[0].getTime();
      if (selected.length === 2) {
        let secondSelected = selected[1].getTime();
        return firstSelected < date && secondSelected > date;
      } else {
        return (
          hoveredDate &&
          ((firstSelected < date && hoveredDate >= date) ||
            (date < firstSelected && date >= hoveredDate))
        );
      }
    }

    return false;
  }

  if (calendars.length) {
    return (
      <Calendar onMouseLeave={onMouseLeave}>
        {calendars.length > 1 && (
          <div className="flex justify-between">
            <button
              className="disabled:opacity-20"
              {...getBackProps({ calendars })}
            >
              Back
            </button>
            <button
              className="disabled:opacity-20"
              {...getForwardProps({ calendars })}
            >
              Next
            </button>
          </div>
        )}

        <div
          className={classNames(
            props.orientation === 'vertical' ? 'flex-row' : 'flex-col',
            'flex sm:justify-between'
          )}
        >
          {calendars.map((calendar) => (
            <Month key={`${calendar.month}${calendar.year}`}>
              <div className="col-span-7 flex justify-between py-2">
                {monthNamesFull[calendar.month]} {calendar.year}
                {calendars.length === 1 && (
                  <div className="flex justify-between">
                    <button
                      className="disabled:opacity-20"
                      {...getBackProps({ calendars })}
                    >
                      <ChevronLeftIcon className="w-6 h-6" />
                    </button>
                    <button
                      className="disabled:opacity-20"
                      {...getForwardProps({ calendars })}
                    >
                      <ChevronRightIcon className="w-6 h-6" />
                    </button>
                  </div>
                )}
              </div>

              {weekdayNamesShort.map((weekday, i) => (
                <DayOfMonthEmpty
                  key={`${calendar.month}${calendar.year}${weekday}${i}`}
                >
                  {weekday}
                </DayOfMonthEmpty>
              ))}

              {calendar.weeks.map((week, windex) =>
                week.map((dateObj, index) => {
                  let key = `${calendar.month}${calendar.year}${windex}${index}`;
                  if (!dateObj) {
                    return <DayOfMonthEmpty key={key} />;
                  }

                  let { date, selected, selectable, today } = dateObj;

                  return (
                    <DayOfMonth
                      key={key}
                      {...getDateProps({
                        dateObj,
                        onMouseEnter: () => onMouseEnter(date),
                      })}
                      selected={selected}
                      unavailable={!selectable}
                      today={today}
                      isInRange={isInRange(date)}
                    >
                      {date.getDate()}
                    </DayOfMonth>
                  );
                })
              )}
            </Month>
          ))}
        </div>
      </Calendar>
    );
  }

  return null;
};

const RangePicker = ({
  monthsToDisplay = 2,
  orientation = 'horizontal',
  selectedDates,
  setSelectedDates,
  ignoreMinDate = false,
}) => {
  const [date] = useState(new Date());

  function _handleOnDateSelected({ selected, selectable, date }) {
    if (!selectable) return;

    let dateTime = date.getTime();
    let newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        let firstTime = selectedDates[0].getTime();

        if (firstTime < dateTime) newDates.push(date);
        else newDates.unshift(date);

        setSelectedDates(newDates);
      } else if (newDates.length === 2) {
        setSelectedDates([date]);
      }
    } else {
      newDates.push(date);
      setSelectedDates(newDates);
    }
  }

  let minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  return (
    <>
      <RangeDatepicker
        minDate={ignoreMinDate ? null : minDate}
        date={date}
        selected={selectedDates}
        onDateSelected={_handleOnDateSelected}
        monthsToDisplay={monthsToDisplay}
        orientation={orientation}
      />
      {/* {selectedDates.length === 2 && (
<div style={{ paddingTop: 20, textAlign: 'center' }}>
<p>Selected:</p>
<p>{`${selectedDates[0].toLocaleDateString()} - ${selectedDates[1].toLocaleDateString()}`}</p>
</div>
)} */}
      <div className="flex items-center space-x-2 text-xs">
        {' '}
        <InformationCircleIcon className="h-4 w-4" />
        <p>Click twice to select a single date.</p>
      </div>
    </>
  );
};

export default RangePicker;
