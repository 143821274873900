import { useEffect, useState } from 'react';

import { useUsers } from 'features/users';
import { useRoles } from 'features/roles';

import { FilterGroup } from 'components/Filters';

const EmployeeFilters = ({ filters, setFilters }) => {
  const { fetchAllUsers, users } = useUsers();
  const { departmentList, fetchAllRoles, levels, roles } = useRoles();

  const [roleCodes, setRoleCodes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [supervisorIds, setSupervisorIds] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);

  const [roleSearch, setRoleSearch] = useState('');
  const [supervisorSearch, setSupervisorSearch] = useState('');

  useEffect(() => {
    setRoleCodes([]);
  }, [departments, subDepartments]);

  useEffect(() => {
    setSupervisorIds([]);
  }, [departments, subDepartments, roleCodes]);

  useEffect(() => {
    const fetchData = async () => {
      let filters = [
        { attribute: 'status', operator: 'EQ', value: 'ACTIVE' },
        { attribute: 'level', operator: 'EQ', value: levels.AGENCY_LEVEL },
      ];

      if (departments.length) {
        filters.push({
          operator: 'IN',
          value: departments,
          attribute: 'department',
        });
      }

      if (subDepartments.length) {
        filters.push({
          operator: 'IN',
          value: subDepartments,
          attribute: 'subDepartment',
        });
      }

      await fetchAllRoles({
        search: roleSearch,
        sorts: 'seniorityLevel:ASC',
        filterGroups: JSON.stringify([{ filters }]),
        include: ['supervisingRoles'],
      });
    };

    fetchData().catch(console.error);
  }, [roleSearch, departments, subDepartments]);

  useEffect(() => {
    const fetchData = async () => {
      let filters = [
        { attribute: 'directSubordinatesCount', operator: 'GT', value: 0 },
        { attribute: 'role.level', operator: 'EQ', value: levels.AGENCY_LEVEL },
      ];

      if (departments.length) {
        filters.push({
          operator: 'IN',
          value: departments,
          attribute: 'role.supervisingRoles.department',
        });
      }

      if (roleCodes.length) {
        filters.push({
          operator: 'IN',
          value: roleCodes,
          attribute: 'role.supervisingRoles.code',
        });
      }

      await fetchAllUsers({
        include: ['avatar', 'role'],
        search: supervisorSearch,
        sorts: 'role.seniorityLevel:ASC',
        filterGroups: JSON.stringify([{ filters }]),
        attributes: ['userId', 'roleId', 'firstName', 'lastName', 'email'],
      });
    };

    fetchData().catch(console.error);
  }, [supervisorSearch, departments, subDepartments, roleCodes]);

  const onApplyFilter = () => {
    let newFilters = [...filters].filter(
      ({ attribute }) =>
        ![
          'role.department',
          'role.subDepartment',
          'role.code',
          'supervisorId',
        ].includes(attribute)
    );

    if (departments.length) {
      newFilters.push({
        operator: 'IN',
        value: departments,
        attribute: 'role.department',
      });
    }

    if (subDepartments.length) {
      newFilters.push({
        operator: 'IN',
        value: subDepartments,
        attribute: 'role.subDepartment',
      });
    }

    if (roleCodes.length) {
      newFilters.push({
        operator: 'IN',
        value: roleCodes,
        attribute: 'role.code',
      });
    }

    if (supervisorIds.length) {
      newFilters.push({
        operator: 'IN',
        value: supervisorIds,
        attribute: 'supervisorId',
      });
    }

    setFilters(newFilters);
  };

  return (
    <FilterGroup
      onApply={onApplyFilter}
      onClear={() => {
        setSupervisorIds([]);
        setRoleCodes([]);
        setSubDepartments([]);
        setDepartments([]);
      }}
      filters={[
        {
          field: 'department',
          title: 'Department',
          values: departments,
          options: departmentList.map((department) => {
            return {
              ...department,
              children: department.subDepartments
                ? {
                    field: 'subDepartment',
                    options: department.subDepartments,
                    values: subDepartments,
                    onChange: (values) => {
                      setSubDepartments(values.subDepartment);
                    },
                  }
                : null,
            };
          }),
          onChange: (values) => {
            setDepartments(values.department);

            if (values.subDepartment) {
              setSubDepartments(values.subDepartment);
            }
          },
        },
        {
          title: 'Role',
          field: 'code',
          searchable: true,
          onSearch: (search) => setRoleSearch(search),
          values: roleCodes,
          onChange: (values) => {
            setRoleCodes(values.code);
          },
          options: roles.data.map((role) => {
            return { ...role, value: role.code, label: role.name };
          }),
        },
        {
          title: 'Team Lead',
          field: 'supervisorId',
          searchable: true,
          onSearch: (search) => setSupervisorSearch(search),
          values: supervisorIds,
          options: users.data.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            setSupervisorIds(values.supervisorId);
          },
        },
      ]}
    />
  );
};

export default EmployeeFilters;
