import { Avatar } from 'components';
import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';

const UserProfileAvatar = ({
  user,
  showName = true,
  size = '36px',
  textSize = '16px',
  className,
  textClassname,
  breakNameFormat = true,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center',
        className,
        showName && 'space-x-2'
      )}
    >
      <Avatar
        size={size}
        initials={getNameInitials(user.firstName, user.lastName)}
        textSize={textSize}
        imageSrc={user?.avatar?.thumbnailUrl}
      />
      {showName && (
        <span className={classNames('text-sm', textClassname)}>
          {user.firstName}
          {breakNameFormat ? <br /> : ' '}
          {user.lastName}
        </span>
      )}
    </div>
  );
};

export default UserProfileAvatar;
