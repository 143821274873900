import axios from 'axios';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import Button from 'components/Button';
import Label from 'components/Forms/Label';

import { setAlert } from 'features/alerts/alertsSlice';

const Password = () => {
  const dispatch = useDispatch();

  const onSubmit = (values, actions) => {
    axios
      .put('/auth/me/change-password', values)
      .then((response) => {
        actions.resetForm();
        dispatch(setAlert('success', response.data.message));
      })
      .catch((err) => actions.setErrors(err.response.data.errors || {}))
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <div className="px-5 py-5 bg-white">
      <p className="text-base font-inter font-bold tracking-3/4 text-grayscale-900">
        Change Password
      </p>

      <Formik
        initialValues={{
          password: '',
          newPassword: '',
          confirmPassword: '',
        }}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          password: yup.string().required('Current password is required'),
          newPassword: yup
            .string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters.')
            .matches(
              /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?\\/~`|\-=])[^\s]*$/,
              'Password must contain at least 1 letter, number and special character.'
            ),
          confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
            .required('Confirm Password is required'),
        })}
      >
        {({ handleChange, isSubmitting }) => (
          <Form>
            <div className="grid md:grid-cols-12 gap-5 mt-5">
              <div className="md:col-span-6">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  Current Password
                </Label>

                <Field
                  name="password"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  type="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>

              <div className="md:col-span-6 md:col-start-1">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  New Password
                </Label>
                <Field
                  name="newPassword"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  type="password"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>

              <div className="md:col-span-6">
                <Label
                  textColor="text-grayscale-800"
                  textSize="sm"
                  fontWeight="font-bold"
                  classes="tracking-3/4"
                >
                  Confirm New Password
                </Label>
                <Field
                  name="confirmPassword"
                  className="form-select text-mini"
                  onChange={(e) => handleChange(e)}
                  as="input"
                  type="password"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-error text-tiny"
                />
              </div>
            </div>

            <div className="flex justify-end mt-5">
              <Button type="submit" loading={isSubmitting} showLoading={true}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Password;
