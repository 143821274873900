import axios from 'axios';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/outline';

import useQuery from 'hooks/useQuery';

import { Table } from 'components';
import InputPrepend from 'components/Forms/InputPrepend';

import { currencyFormatter, numberFormatter } from 'utils/formatters';

const Inventory = ({ account, marketplace }) => {
  let query = useQuery();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: parseInt(query.get('page') ?? 1),
    pageSize: parseInt(query.get('pageSize') ?? 10),
    sort: query.get('sort') ?? 'totalQuantity',
    search: query.get('search') ?? '',
    include: ['listing'],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(`/account/products/inventory/items`, {
        params: {
          ...params,
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          attributes: [
            'inventoryItemId',
            'yourPrice',
            'estimatedFeeTotal',
            'defaultCog',
            'totalQuantity',
            'salesShippedLast7Days',
            'fulfillableQuantity',
            'inboundShippedQuantity',
            'totalUnfulfillableQuantity',
            'reservedFutureSupplyQuantity',
          ],
        },
      });

      if (isSubscribed) {
        setInventory(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, params]);

  const updateParams = (newParams, search = false) => {
    setParams(newParams);
    query.set('page', newParams.page);
    query.set('pageSize', newParams.pageSize);
    query.set('sort', newParams.sort);

    if (search) {
      if (newParams.search === '') {
        query.delete('search');
      } else {
        query.set('search', newParams.search);
      }
    }

    history.push(window.location.pathname + '?' + query.toString());
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = {
      ...params,
      page,
      pageSize: sizePerPage,
    };
    updateParams(newParams);
  };

  const tableColumns = [
    {
      dataField: 'listing.asin',
      text: 'Products',
      sort: false,
      headerStyle: {
        minWidth: '350px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return (
          <div className="flex">
            <img
              src={row.listing.thumbnail}
              className="h-20 w-20 mr-2"
              alt={row.listing.asin}
            />

            <div className="relative flex flex-col justify-center px-3.5">
              <div className="mb-3.5">
                <p className="font-normal text-tiny text-grayscale-800 leading-4">
                  {row.listing.title}
                </p>
              </div>
              <div className="mb-3.5">
                <p className="text-tiny">
                  <span className="text-grayscale-600">ASIN: </span>
                  <span className="text-secondary-light underline">
                    {row.listing.asin}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-tiny">
                  <span className="text-grayscale-600">SKU: </span>
                  <span className="text-grayscale-800 font-normal">
                    {row.listing.sku}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'yourPrice',
      text: 'Price',
      sort: false,
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'estimatedFeeTotal',
      text: 'Estimated Fees',
      sort: false,
      headerStyle: {
        minWidth: '150px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'defaultCog',
      text: 'COGS',
      sort: false,
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'salesShippedLast7Days',
      text: 'Sales (7 days)',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'totalQuantity',
      text: 'Sellable Inventory',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'fulfillableQuantity',
      text: 'Available',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'inboundShippedQuantity',
      text: 'Inbound',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'totalUnfulfillableQuantity',
      text: 'Unfullfillable',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'reservedFutureSupplyQuantity',
      text: 'Reserved',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(cell)}
          </p>
        );
      },
    },
  ];

  const onSearch = (e) => {
    updateParams({
      ...params,
      search: e.target.value,
    });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="bg-white p-5">
      <div className="sm:grid grid-cols-7 mb-8">
        <div className="col-span-3 flex items-center space-x-4">
          <h1 className="text-grayscale-900 text-xl tracking-3/4 leading-1.2 font-inter font-bold">
            Inventory
          </h1>
        </div>
        <div className="col-span-4 flex justify-end items-center space-x-4">
          <div className="sm:col-span-2 bg-grayscale-400">
            <InputPrepend
              name="search"
              defaultValue={params.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Name, ASINs or SKUs'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
              classes="py-3.5 pr-6 pl-9"
            />
          </div>
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={inventory}
        onTableChange={onTableChange}
        params={params}
        keyField="inventoryItemId"
        defaultSorted={[{ dataField: 'totalQuantity', order: 'desc' }]}
        loading={loading}
      />
    </div>
  );
};

export default Inventory;
