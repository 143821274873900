import axios from 'axios';
import { XIcon } from '@heroicons/react/outline';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import PriorityDropdown from './PriorityDropdown';
import { useSelector } from 'react-redux';
import classNames from 'utils/classNames';
import TaskDueDateDropdown from './TaskDueDateDropdown';
import { useEffect, useState } from 'react';
import { isFunction, pick } from 'lodash';
import ClientsListSelect from 'components/ClientsListSelect';
import TaskType from './TaskType';

const NewTaskKanban = ({
  taskListId,
  isSubtask = false,
  parentTaskItemId = null,
  cancel,
  created,
  status = 'To Do',
  assigneeOptions = [],
  taskListOptions = [],
  withTaskType = false,
}) => {
  const [row, setRow] = useState({
    id: 0,
    status,
    name: '',
    assignees: [],
    priority: 'none',
    dueDate: null,
    parentTaskItemId,
    taskListId,
    type: null,
  });

  const onUpdateAssignee = async (user, action) => {
    let assignees = [...row.assignees];

    assignees =
      action === 'add'
        ? [...assignees, user]
        : assignees.filter((a) => a.id !== user.userId);

    setRow({ ...row, assignees });
  };

  const onEditTitle = (e) => {
    if (e.key === 'Escape') {
      cancel();
    } else {
      setRow({ ...row, name: e.target.value });
    }
  };

  const onChangePriority = (priority) => {
    setRow({ ...row, priority: priority ? priority.status : null });
  };

  const onChangeDueDate = (dueDate) => {
    setRow({ ...row, dueDate });
  };

  const onSelectClient = (client) => {
    setRow({ ...row, taskListId: client.taskListId });
  };

  const onSave = () => {
    let payload = pick(row, [
      'name',
      'priority',
      'dueDate',
      'parentTaskItemId',
      'taskListId',
      'status',
      'checklists',
      'type',
    ]);

    payload.assigneeIds = row.assignees.map((user) => user.userId);

    if (row.status.taskSpaceStatusId) {
      payload.statusId = row.status.taskSpaceStatusId;
    }

    axios.post('/agency/tasks/items', payload).then((response) => {
      isFunction(created) && created(response.data.data);
    });
  };

  const onChangeType = (type) => {
    setRow({ ...row, type });
  };

  return (
    <>
      <div
        className={classNames(
          isSubtask
            ? 'list-group-col-sub py-2 bg-gray-50'
            : 'list-group-col bg-white',
          'text-gray-500 group border border-red-200 p-4'
        )}
      >
        <div className="col-span-8">
          {!taskListId && (
            <div className="text-sm flex">
              <ClientsListSelect
                clients={taskListOptions}
                keyField="taskListId"
                onChange={onSelectClient}
                selectedId={row.taskListId}
              />
            </div>
          )}
          <input
            type="text"
            placeholder={isSubtask ? 'New Subtask' : 'New Task'}
            defaultValue={row.title}
            className={classNames(
              isSubtask && 'ml-2',
              'border-0 w-auto 2xl:w-full focus:ring-white focus:border-white p-0 text-red-500 placeholder-gray-300 text-sm'
            )}
            onKeyUp={onEditTitle}
            autoFocus
          />
        </div>
        <div className="col-span-4  flex justify-end">
          <UsersPopoverMultiple
            users={assigneeOptions}
            position="left-1/2 transform -translate-x-1/2"
            onChange={onUpdateAssignee}
            selectedIds={row.assignees.map((user) => user.userId)}
          />
        </div>

        <div className="col-span-8 mt-8">
          <TaskDueDateDropdown
            value={row.dueDate}
            setValue={onChangeDueDate}
            iconClassname="w-5 h-5 relative top-0.5"
            titleClasses={!row.dueDate ? '' : 'text-red-500'}
            position="left-0"
          />
        </div>

        <div
          className={classNames(
            'col-span-4 mt-8 flex items-center',
            withTaskType ? 'justify-between' : 'justify-end'
          )}
        >
          {withTaskType && (
            <TaskType value={row.type} setValue={onChangeType} />
          )}
          <PriorityDropdown value={row.priority} setValue={onChangePriority} />
        </div>

        <div className="col-span-12 flex items-center justify-end space-x-2 mt-8">
          <button
            className="bg-green-500 text-xs text-white rounded-full py-1 px-4 z-1"
            onClick={onSave}
            disabled={!!!(row.name && row.taskListId)}
          >
            Save
          </button>
          <button onClick={() => cancel()} className="z-1">
            <XIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NewTaskKanban;
