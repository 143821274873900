import Button from 'components/Button';
import { useEffect, useState, React } from 'react';
import UploadImagesModal from './UploadImagesModal';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import Loading from 'components/Loading';
import { debounce } from 'lodash';
import classNames from 'utils/classNames';
import useImageLoad from 'hooks/useImageLoad';
import zoomin from 'assets/icons/zoomin.svg';
import zoomout from 'assets/icons/zoomout.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ModalCentered from 'components/ModalCentered';
import usePermissions from 'hooks/usePermissions';
import useWindowSize from 'hooks/useWindowSize';
import { AnnotationIcon } from '@heroicons/react/outline';

const MainImage = ({ thumb, src, alt, type, metadata }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState('26%');
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    if (src) {
      setLoading(true);
      if (metadata) {
        if (windowWidth && windowHeight) {
          if (
            windowWidth >= metadata.width &&
            windowHeight >= metadata.height
          ) {
            setWidth(metadata.width);
          } else {
            const maxH =
              metadata.height < windowHeight
                ? metadata.height
                : windowHeight * 0.9;

            //const maxH = windowHeight * 0.9;
            const w = (metadata.width / metadata.height) * maxH;
            setWidth(w);
          }
        } else {
          setWidth(metadata.height > metadata.width ? '26%' : '50%');
        }
      }
    }
  }, [src]);

  const onLoad = () => {
    setLoading(false);
  };
  return (
    <>
      {type === 'video' ? (
        <video
          controls
          src={src}
          key={src}
          autoPlay={false}
          className="w-full h-full"
        />
      ) : (
        <>
          <img
            key={thumb}
            src={thumb}
            className={classNames(
              loading ? 'visible' : 'invisible',
              'absolute left-0 top-0 object-contain w-full h-full'
            )}
          />
          <img
            key={src}
            src={src}
            alt={alt}
            onLoad={onLoad}
            className="object-contain w-full h-full cursor-pointer"
            onClick={() => setOpen(true)}
          />
          <ModalCentered
            open={open}
            setOpen={setOpen}
            closeClasses="absolute right-5 top-5 z-10"
            width=""
            style={{ maxWidth: width }}
          >
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
              className="cursor-pointer"
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <TransformComponent>
                    <img
                      key={src}
                      src={src}
                      alt={alt}
                      className="object-contain w-full h-full"
                    />
                  </TransformComponent>

                  <div className="flex justify-center absolute bottom-0 w-full pb-8 space-x-8">
                    <button
                      type="button"
                      onClick={() => zoomIn()}
                      className="hover:opacity-50"
                    >
                      <img src={zoomin} width="18px" />
                    </button>
                    <button
                      type="button"
                      onClick={() => zoomOut()}
                      className="hover:opacity-50"
                    >
                      <img src={zoomout} width="18px" />
                    </button>
                  </div>
                </>
              )}
            </TransformWrapper>
          </ModalCentered>
        </>
      )}
    </>
  );
};

const MockupImages = ({ listingMockup, setSlideReadyExport }) => {
  const { userCan } = usePermissions();
  const { alertError } = useAlert();
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const items = [...Array(8).keys()];
  const [slides, setSlides] = useState(items);
  const [mainImage, setMainImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { onReloadSrc } = useImageLoad();

  useEffect(() => {
    if (listingMockup.listingMockupSlide) {
      fetchMedia();
    }
  }, []);

  const fetchMedia = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `/agency/clients/listing-mockup/${listingMockup.listingMockupId}/slides`
      );
      const media = response.data.data;

      const images = items.map((item, idx) => {
        let slide = listingMockup.listingMockupSlide.find(
          (s) => s.order === idx
        );

        if (slide) {
          const mediaUrls = media.find(
            (m) => m.listingMockupSlideId === slide.listingMockupSlideId
          );

          if (mediaUrls) {
            return {
              ...slide,
              thumbUrl: mediaUrls.media[0],
              url: mediaUrls.media[1],
            };
          }
          return slide;
        } else return null;
      });

      setSlides(images);
      const main = images.find((s) => s && s.url !== '');
      setMainImage(main);
    } catch (error) {
      alertError('failed to load images', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onReloadSlides = (payload) => {
    const uploaded = payload.filter((d) => d?.success === true);
    let sCopy = [...slides];
    uploaded.map((u, i) => {
      const { data } = u.data;
      sCopy[data.order] = data;
      return;
    });
    setSlides(sCopy);
    setSlideReadyExport(true);
  };

  const onReorderSlides = (payload) => {
    let sCopy = [...slides];
    sCopy.map((s, i) => {
      const row = payload.find((p) => p.order === i);
      sCopy[i] = row ? row : null;
    });
    setSlides(sCopy);
  };

  const updateMainImage = (slide) => {
    setMainImage(slide);
  };

  const onDebounceUpdateMainImage = debounce((slide) => {
    updateMainImage(slide);
  }, 1000);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="grid grid-cols-9 space-x-2">
        <div className="col-span-1 grid grid-rows-7 gap-y-2">
          {slides.map((slide, i) => {
            return slide ? (
              <button
                key={`${i}-${slide.listingMockupSlideId}-${slide.filename}`}
                className="bg-gray-200 h-16 overflow-hidden mockup-img-button relative"
                type="button"
                onClick={() => {
                  updateMainImage(slide);
                }}
                onMouseEnter={() => {
                  onDebounceUpdateMainImage(slide);
                }}
                onMouseLeave={() => {
                  onDebounceUpdateMainImage.cancel();
                }}
              >
                {slide.type === 'video' && (
                  <span className="mockup-vid-play-btn absolute-center z-10"></span>
                )}
                <img
                  src={slide.thumbUrl}
                  alt={slide.alt}
                  key={slide.thumbUrl}
                  onError={(e) => onReloadSrc(e, slide.thumbUrl)}
                  className={classNames(
                    slide.type === 'video' ? 'opacity-70' : '',
                    'w-full h-full object-cover'
                  )}
                />
                {slide.revisions && slide.revisions.length > 0 && (
                  <AnnotationIcon className="absolute opacity-50 font-sourceSansPro w-4 h-4 p-0.5 bg-custom-blue rounded-full bottom-1 right-1 text-white drop-shadow-lg" />
                )}
              </button>
            ) : (
              <div key={i} className="bg-gray-200 py-4">
                &nbsp;
              </div>
            );
          })}
        </div>
        <div
          className="col-span-8 border border-gray-100 relative"
          style={{ height: '568px' }}
        >
          {mainImage && (
            <MainImage
              thumb={mainImage.thumbUrl}
              src={mainImage.url}
              alt={mainImage.alt}
              type={mainImage.type}
              metadata={mainImage.metadata}
            />
          )}
        </div>
      </div>
      {(listingMockup.status === 'draft' ||
        listingMockup.status === 'for_revision') &&
        userCan('mockup.manage.media') && (
          <>
            <Button
              classes="border-0 font-bold tracking-widest mt-4 mr-2 text-base"
              bgColor="blue-900"
              hoverColor="blue-800"
              roundedSize="full"
              textColor="white"
              px={6}
              py={2}
              shadow=""
              type="button"
              showLoading={false}
              textSize=""
              onClick={() => setIsOpenUpload(true)}
            >
              {listingMockup.status === 'draft'
                ? 'Upload Images'
                : 'Edit Images'}
            </Button>
            <UploadImagesModal
              open={isOpenUpload}
              setOpen={setIsOpenUpload}
              listingMockup={listingMockup}
              slides={slides}
              onReloadSlides={onReloadSlides}
              onReorderSlides={onReorderSlides}
            />
          </>
        )}
      {mainImage && mainImage.revisions && mainImage.revisions.length > 0 && (
        <div className="mt-5">
          <p className="font-normal text-13 text-custom-error whitespace-pre-wrap">
            Client Revision Note
          </p>
          <p className="font-normal text-grayscale-900 text-13 pt-1.5 whitespace-pre-wrap">
            {mainImage.revisions[0].comment}
          </p>
        </div>
      )}
    </>
  );
};

export default MockupImages;
