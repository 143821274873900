import Avatar from 'components/Avatar';
import { getNameInitials } from 'utils/formatters';

const LeadProfileStatusChanged = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { data, sender, message, title } = notification;

  const onClick = () => {
    markAsRead();
    router.push(`/leads/profile/${data.leadId}`);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <Avatar
        size="48px"
        imageSrc={sender?.avatar?.thumbnailUrl}
        initials={getNameInitials(sender?.firstName, sender?.lastName)}
      />
      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>

        <div className="text-13 text-grayscale-800 flex items-center">
          <span>From</span>
          <span className="mx-1 bg-warning-light text-warning-dark py-0.5 px-2 rounded-xl">
            {data.oldStatus}
          </span>
          <span>to </span>
          <span className="mx-1 bg-success-light text-success-dark py-0.5 px-2 rounded-xl">
            {data.status}
          </span>
        </div>
      </div>

      {children}
    </div>
  );
};

export default LeadProfileStatusChanged;
