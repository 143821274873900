import classNames from 'utils/classNames';

const Badge = ({
  children,
  rounded = 'full',
  textSize = 'xs',
  color,
  classes,
  padding = 'px-2 py-1',
  fontFamily = 'font-inter',
}) => {
  return (
    <span
      className={classNames(
        classes ?? '',
        rounded !== '' ? `rounded-${rounded}` : '',
        textSize !== '' ? `text-${textSize}` : '',
        color !== '' ? `bg-${color}-100` : '',
        color !== '' ? `text-${color}-500` : '',
        fontFamily,
        padding,
        'inline-flex items-center font-medium'
      )}
    >
      {children}
    </span>
  );
};
export default Badge;
