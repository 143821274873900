import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import inventoriesIcon from 'assets/icons/inventoriesIcon.png';
import onHandIcon from 'assets/icons/onHandIcon.png';
import receivedIcon from 'assets/icons/receivedIcon.png';
import shippedIcon from 'assets/icons/shippedIcon.png';

import InventoryStatus from './components/InventoryStatus';
import ShipmentStatus from './components/ShipmentStatus';

import { numberFormatter } from 'utils/formatters';

const Overview = ({ account, marketplace }) => {
  const [summary, setSummary] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(
    moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  );

  let options = [];

  const ref = moment().subtract(1, 'month').startOf('month');

  while (ref.isSameOrAfter(moment().startOf('year'))) {
    options.push(ref.format('YYYY-MM-DD'));
    ref.subtract(1, 'month');
  }

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        '/account/products/inventory/ledger/summary',
        {
          params: {
            startDate: selectedMonth,
            endDate: moment(selectedMonth).endOf('month').format('YYYY-MM-DD'),
            accountId: account.accountId,
            marketplace: marketplace.details.countryCode,
          },
        }
      );

      if (isSubscribed) {
        setSummary(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, selectedMonth]);

  return (
    <div>
      <div className="bg-white p-5 rounded-md">
        <div className="flex justify-between">
          <h1 className="text-grayscale-900 text-xl tracking-3/4 leading-1.2 font-inter font-bold">
            Inventory Level Overview
          </h1>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="col-span-2 text-grayscale-900 text-mini py-2 w-48 border-0 bg-grayscale-400 rounded-xl focus:outline-none focus:ring-0"
          >
            {options.map((option) => (
              <option className="px-5" value={option} key={option}>
                {moment(option).format('MMM YYYY')}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-4 mt-8">
          {[
            {
              key: 'total',
              display: 'Total Inventories',
              icon: inventoriesIcon,
            },
            {
              key: 'receipts',
              display: 'Received',
              icon: receivedIcon,
            },
            {
              key: 'shipped',
              display: 'Shipped',
              icon: shippedIcon,
            },
            {
              key: 'endingWarehouseBalance',
              display: 'On Hand',
              icon: onHandIcon,
            },
          ].map((stat) => (
            <div key={stat.key} className="flex flex-col items-center">
              <img
                className="h-12 w-12 object-contain"
                src={stat.icon}
                alt={`${stat.key}-icon`}
              />

              <p className="text-grayscale-700 text-mini leading-1.5 mt-2">
                {stat.display}
              </p>

              <p className="text-grayscale-800 font-inter text-2xl tracking-3/4 leading-1.2 mt-2">
                {numberFormatter(summary[stat.key])}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-2 mt-8 gap-8">
        <InventoryStatus
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
        />

        <ShipmentStatus
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
          startDate={selectedMonth}
          endDate={moment(selectedMonth).endOf('month').format('YYYY-MM-DD')}
        />
      </div>
    </div>
  );
};

export default Overview;
