import { Clients, Overview, Organization, TerminationReason } from './pages';
import { NavLink, Route, Switch } from 'react-router-dom';

const Churn = () => {
  const tabs = [
    {
      name: 'Overview',
      href: `/churn`,
      exact: true,
      component: Overview,
    },
    {
      name: 'Clients',
      href: `/churn/clients`,
      exact: true,
      component: Clients,
    },
    {
      name: 'Termination Breakdown',
      href: `/churn/termination-reason`,
      exact: true,
      component: TerminationReason,
    },
    // {
    //   name: 'Organization',
    //   href: `/churn/organization`,
    //   exact: true,
    //   component: Organization,
    // },
  ];

  return (
    <div>
      <h3 className="text-25 font-inter font-bold leading-1.2 tracking-3/4 text-grayscale-900">
        Churn
      </h3>

      <div className="border-b border-grayscale-500 mt-4">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <NavLink
              to={tab.href}
              key={tab.name}
              exact={tab.exact}
              className="flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent"
              activeClassName="border-secondary-light font-bold text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>

      <div className="my-4">
        <Switch>
          {tabs.map((tab) => (
            <Route
              key={tab.href}
              path={tab.href}
              component={tab.component}
              exact={tab.exact}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};
export default Churn;
