import { Formik, Form } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { object, string, number, array, boolean } from 'yup';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import axios from 'axios';
import { joiAlertErrorsStringify } from 'utils/formatters';
import FormikErrorNotification from 'components/FormikErrorNotification';
import useAlert from 'hooks/useAlert';
import usePermissions from 'hooks/usePermissions';
import DenyModal from './components/review/DenyModal';
import useCreditNotes from './useCreditNotes';
import ViewAppliedCredits from './components/review/ViewAppliedCredits';
import ConfirmApplyModal from './components/review/ConfirmApplyModal';
import ApplyCredits from './components/review/ApplyCredits';
import Details from './components/review/Details';
import AppliedCreditsTable from './components/review/AppliedCreditsTable';
import UpsellDetails from './components/UpsellDetails';

const CreditNoteReviewModal = ({
  open,
  setOpen,
  creditNote,
  onReload,
  type = 'creator',
}) => {
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const emptyInitialValues = {
    invoices: null,
    autoApply: false,
    status: 'awaiting approval',
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const { STATUS, isReviewer, canApplyToInvoice, fetchOpenInvoices } =
    useCreditNotes();
  const [balance, setBalance] = useState(0);
  const [creditsApplied, setCreditsApplied] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const formRef = useRef();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenDeny, setIsOpenDeny] = useState(false);
  const validationSchema = object().shape({
    invoices: array()
      .of(
        object().shape({
          balance: number(),
          customer_id: string(),
          invoice_id: string(),
          number: string(),
          status: string(),
          creditsToApply: number(),
          hasChanged: boolean(),
          apply: boolean(),
        })
      )
      .nullable(),
    autoApply: boolean().required(),
    status: string(),
  });

  useEffect(() => {
    if (open && creditNote) {
      if (isReviewer(type) && canApplyToInvoice(creditNote.status))
        initializeValues();
      setBalance(creditNote.creditsAvailable);
      setRemainingBalance(parseFloat(creditNote.creditsAvailable));
      setCreditsApplied(0);
    }
  }, [creditNote, open]);

  const initializeValues = async () => {
    setLoading(true);
    try {
      const response = await fetchOpenInvoices(creditNote.customerId);

      const data = response.data.data;

      const autoApplyData = creditNote.autoApply
        ? {
            balance: creditNote.amount,
            customer_id: 'autoapp',
            invoice_id: 'new',
            number: 'new',
            status: 'pending',
            creditsToApply: creditNote.autoApplyAmount,
            hasChanged: true,
            apply: true,
          }
        : null;

      if (data && data.rows.length <= 0) {
        setInitialValues({
          ...initialValues,
          invoices: autoApplyData ? [autoApplyData] : null,
          autoApply: creditNote.autoApply ?? false,
        });
      } else {
        const invoices = data.rows.map((row) => ({
          ...row,
          creditsToApply: 0,
          hasChanged: false,
        }));

        if (autoApplyData) invoices.push(autoApplyData);

        setInitialValues({
          ...initialValues,
          invoices,
          autoApply: creditNote.autoApply ?? false,
        });
      }

      if (autoApplyData) {
        setCreditsApplied(autoApplyData.creditsToApply);
        setRemainingBalance(
          creditNote.creditsAvailable - autoApplyData.creditsToApply
        );
      }
    } catch (error) {
      alertError('Fetch open invoices failed', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const checkAppliedCredits = async (
    value,
    name,
    index,
    row,
    setFieldValue,
    values
  ) => {
    let creditsToApply = value !== '' ? parseFloat(value) : 0;
    const totalAppliedCredits = values.invoices
      ?.filter((i, x) => x !== index)
      .reduce(
        (a, b) =>
          a + parseFloat(b.creditsToApply === '' ? 0 : b.creditsToApply),
        0
      );

    let currentRemainingBalance = balance - totalAppliedCredits;

    if (creditsToApply > 0) {
      if (creditsToApply >= currentRemainingBalance) {
        if (creditsToApply >= row.balance) {
          //credits to apply is greater than or equal to row balance and greater than or equal remaining balance
          //row.balance is larger than remaining balance, apply remaining balance
          creditsToApply =
            row.balance > currentRemainingBalance
              ? currentRemainingBalance
              : row.balance;
        } else {
          creditsToApply = currentRemainingBalance;
        }
      } else {
        //credits to apply is less than remaining balance
        if (creditsToApply >= row.balance) creditsToApply = row.balance;
      }
    }

    const newTotalAppliedCredits =
      totalAppliedCredits + parseFloat(creditsToApply);

    setFieldValue(name, creditsToApply);
    setFieldValue(`invoices.${index}.hasChanged`, false);
    setCreditsApplied(newTotalAppliedCredits);
    setRemainingBalance(balance - newTotalAppliedCredits);
  };

  const onSubmit = async (values) => {
    if (!values.autoApply && creditsApplied === 0) {
      alertError(
        'Cannot proceed',
        "Please apply credits to open invoices or select 'Apply to next month's invoice'"
      );
      return;
    }

    setSaving(true);
    await axios
      .post(
        `/agency/credit-notes/${creditNote.creditNoteId}/${
          creditNote.status === STATUS.appliedPartial ? 'apply' : 'approve'
        }`,
        {
          ...values,
          remainingBalance,
          creditsApplied,
        }
      )
      .then((response) => {
        alertSuccess('Success', response.data.message);
        fetchOpenInvoices(creditNote.customerId, true);
        onReload();
        setOpen(false);
        setIsOpenConfirm(false);
      })
      .catch((error) => {
        const errorMessages = joiAlertErrorsStringify(error);
        alertError(error.response.data.message, errorMessages);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-20"
      >
        <div className="inline-block w-full max-w-xl my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title={
              <div className="flex items-center space-x-8 pb-4">
                <span>
                  {creditNote?.upsell && 'Upsell '} Credit Note Request
                </span>
              </div>
            }
            setOpen={setOpen}
            fontSize="text-xl"
            fontStyle="font-bold"
            px="px-4 md:px-8"
            py="pt-4 md:pt-8 pb-0"
            xAlign="items-start"
            border=""
          />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form>
                <FormikErrorNotification />
                <div
                  className="flex flex-col space-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto"
                  style={{ height: '55vh' }}
                >
                  {creditNote?.upsell && (
                    <>
                      <UpsellDetails creditNote={creditNote} />
                      <hr />
                    </>
                  )}
                  <Details creditNote={creditNote} />

                  {userCan('creditNotes.approve') &&
                  isReviewer(type) &&
                  creditNote &&
                  canApplyToInvoice(creditNote.status) ? (
                    <>
                      <AppliedCreditsTable creditNote={creditNote} />

                      <ApplyCredits
                        loading={loading}
                        creditNote={creditNote}
                        checkAppliedCredits={checkAppliedCredits}
                        remainingBalance={remainingBalance}
                        creditsApplied={creditsApplied}
                        setIsOpenConfirm={setIsOpenConfirm}
                        setIsOpenDeny={setIsOpenDeny}
                      />
                    </>
                  ) : (
                    <ViewAppliedCredits
                      creditNote={creditNote}
                      loading={loading}
                    />
                  )}
                </div>
                <ConfirmApplyModal
                  open={isOpenConfirm}
                  setOpen={setIsOpenConfirm}
                  saving={saving}
                  creditsApplied={creditsApplied}
                  okText={
                    creditNote.status === STATUS.appliedPartial
                      ? 'Apply'
                      : 'Approve'
                  }
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <DenyModal
        open={isOpenDeny}
        setOpen={setIsOpenDeny}
        creditNote={creditNote}
        getCreditNotes={onReload}
        setReviewModalOpen={setOpen}
      />
    </>
  );
};
export default CreditNoteReviewModal;
