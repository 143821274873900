import React from 'react';
import { useSelector } from 'react-redux';
import { currencyFormatter, floatFormatter } from 'utils/formatters';

const Body = () => {
  const { invoice } = useSelector((state) => state.invoice);

  const Th = (label, classes) => {
    return (
      <th
        scope="col"
        className={`px-4 py-2 text-xs font-bold uppercase text-gray-800  tracking-wider ${
          classes ?? ''
        }`}
      >
        {label}
      </th>
    );
  };

  const Td = (value, classes) => {
    return (
      <td className={`p-2 sm:p-4 text-sm text-gray-900 ${classes ?? ''}`}>
        {value}
      </td>
    );
  };

  return (
    <>
      <div className="overflow-auto">
        <table className="min-w-full divide-y divide-gray-200 table-fixed w-1024px mt-3">
          <thead className="bg-gray-200">
            <tr>
              {Th(`Item & Description`, 'text-left w-7/12')}
              {Th('Qty', 'text-right w-2/12')}
              {Th('Rate', 'text-right w-2/12')}
              {Th('Amount', 'text-right w-1/12')}
            </tr>
          </thead>
          <tbody>
            {invoice.invoice_items &&
              invoice.invoice_items.map((invoiceItem, index) => {
                return (
                  <tr className="bg-white border-b" key={index}>
                    {Td(
                      <>
                        {invoiceItem.name}
                        <br />
                        <span className="text-gray-500 text-xs whitespace-pre-wrap">
                          {invoiceItem.description}
                        </span>
                      </>
                    )}
                    {Td(invoiceItem.quantity, 'text-right')}
                    {Td(floatFormatter(invoiceItem.price), 'text-right')}
                    {Td(floatFormatter(invoiceItem.item_total), 'text-right')}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="mt-2 sm:grid sm:grid-cols-4 sm:gap-3">
        <div className="col-span-2">&nbsp;</div>
        <div className="col-span-2">
          <div className="grid grid-cols-4 gap-y-5 text-sm text-right">
            <div className="col-span-2 sm:col-span-3 text-gray-500">
              Sub Total
            </div>
            <div className="col-span-2 sm:col-span-1 pr-4">
              {floatFormatter(invoice.sub_total)}
            </div>

            {invoice.taxes &&
              invoice.taxes.map((tax, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="col-span-2 sm:col-span-3 text-gray-500">
                      {tax.tax_name}
                    </div>
                    <div className="col-span-2 sm:col-span-1 pr-4">
                      {currencyFormatter(tax.tax_amount, invoice.currency_code)}
                    </div>
                  </React.Fragment>
                );
              })}

            <div className="col-span-2 sm:col-span-3 text-gray-500 font-bold">
              Total
            </div>
            <div className="col-span-2 sm:col-span-1 font-medium pr-4">
              {currencyFormatter(invoice.total, invoice.currency_code)}
            </div>
            {invoice.payment_made > 0 && (
              <>
                <div className="col-span-2 sm:col-span-3 text-gray-500">
                  Payment Made
                </div>
                <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                  (-) {floatFormatter(invoice.payment_made)}
                </div>
              </>
            )}
            {invoice.credits_applied > 0 && (
              <>
                <div className="col-span-2 sm:col-span-3 text-gray-500">
                  Credits Applied
                </div>
                <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                  (-) {floatFormatter(invoice.credits_applied)}
                </div>
              </>
            )}
            {invoice.write_off_amount > 0 && (
              <>
                <div className="col-span-2 sm:col-span-3 text-gray-500">
                  Write Off Amount
                </div>
                <div className="col-span-2 sm:col-span-1 text-red-500 pr-4">
                  (-) {floatFormatter(invoice.write_off_amount)}
                </div>
              </>
            )}
            <div className="col-span-4 bg-gray-100 grid grid-cols-4 py-2">
              <div className="col-span-2 sm:col-span-3 text-gray-500">
                Balance Due
              </div>
              <div className="col-span-2 sm:col-span-1  pr-4">
                {currencyFormatter(invoice.balance, invoice.currency_code)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
