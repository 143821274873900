import React from 'react';
import PrivateRoute from './PrivateRoute';
import AccountLayout from 'layouts/account/AccountLayout';
import OrderManager from 'features/orders/OrderManager';
import ReviewManager from 'features/reviews/ReviewManager';
import ProductAlertsManager from 'features/productAlerts/ProductAlertsManager';

const AccountRoutes = [
  <PrivateRoute
    path="/accounts/:accountId/orders"
    layout={AccountLayout}
    component={OrderManager}
    access={['agency']}
    permissions="orders.view"
  />,

  <PrivateRoute
    path="/accounts/:accountId/reviews"
    layout={AccountLayout}
    component={ReviewManager}
    access={['agency']}
    permissions="reviews.view"
  />,

  <PrivateRoute
    path="/accounts/:accountId/alerts"
    layout={AccountLayout}
    component={ProductAlertsManager}
    access={['agency']}
    permissions="alerts.view"
  />,
];
export default AccountRoutes;
