import { percentageFormatter } from 'utils/formatters';

const PercentageChange = ({
  currentValue,
  previousValue,
  reverse = false,
  className = '',
  textSize = '13',
}) => {
  let percentage = 0;
  let infoColor = 'success';
  let increased = true;

  if (currentValue !== previousValue && previousValue) {
    const lessRef = reverse ? currentValue : previousValue;
    const greaterRef = reverse ? previousValue : currentValue;

    infoColor = lessRef < greaterRef ? 'success' : 'error';
    increased = lessRef < greaterRef;
    percentage = (currentValue - previousValue) / Math.abs(previousValue);
  }

  return (
    <div className={`${className} flex items-center`}>
      {increased ? (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.13623 10.7455L13.4696 10.7455L8.8029 3.41221L4.13623 10.7455Z"
            fill="#00BA88"
          />
        </svg>
      ) : (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4692 5.41217L4.1359 5.41217L8.80257 12.7455L13.4692 5.41217Z"
            fill="#ED2E2E"
          />
        </svg>
      )}
      <div className={`ml-0.5 text-${textSize} leading-1.5 text-${infoColor}`}>
        {percentageFormatter(percentage)}
      </div>
    </div>
  );
};

export default PercentageChange;
