import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Disclosure, Menu } from '@headlessui/react';
import { ChevronUpIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import axios from 'axios';

import usePermissions from 'hooks/usePermissions';

import {
  getLatestUpdates,
  setAgencyClient,
  fetchClient,
} from '../agencyClientSlice';
import { setAlert } from 'features/alerts/alertsSlice';

import DropdownMenu from 'components/DropdownMenu';
import ProfileLogo from './components/ProfileLogo';
import SlackChannelButton from './components/SlackChannelButton';
import UpdatePasswordModal from './components/UpdatePasswordModal';
import UpdateDefaultContactModal from './components/UpdateDefaultContactModal';

import { currencyFormatter, nameFormatter } from 'utils/formatters';

import ClientAssignees from './components/ClientAssignees/ClientAssignees';
import CredentialsOverview from 'Version2/features/clients/components/CredentialsOverview';
import SendMailButton from 'Version2/features/clients/components/SendMailButton';
import { useRoles } from 'features/roles';

moment.tz.setDefault('America/Toronto');

const View = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { userCan } = usePermissions();
  const { ACCOUNT_SPECIALIST, PPC_SPECIALIST } = useRoles();

  const { agencyClient, assignees } = useSelector(
    (state) => state.agencyClient
  );

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);

  const isAPIAuthorized = (service) => {
    if (agencyClient.account.credentials.length) {
      return agencyClient.account.credentials.some(
        (credential) => credential.service === service
      );
    }
    return false;
  };

  const spApi = useMemo(() => {
    return isAPIAuthorized('spApi');
  });

  const advApi = useMemo(() => {
    return isAPIAuthorized('advApi');
  });

  const onReloadContact = async (data) => {
    dispatch(
      setAgencyClient({
        ...agencyClient,
        defaultContact: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      })
    );
    dispatch(getLatestUpdates(agencyClient.agencyClientId));
  };

  const handleFilePath = (path) => {
    const newPath =
      process.env.REACT_APP_FILE_URL +
      '/' +
      path.substring(path.indexOf('/files') + 1);
    return newPath;
  };

  const enableEmailButton = useMemo(() => {
    const isPPCClient = ['PPC Management', 'PPC Audit and Proposal'].includes(
      agencyClient?.service
    );

    return (assignees ?? []).some(
      (a) =>
        a.role ===
        (isPPCClient ? PPC_SPECIALIST.value : ACCOUNT_SPECIALIST.value)
    );
  }, [assignees, agencyClient, PPC_SPECIALIST, ACCOUNT_SPECIALIST]);

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex justify-between items-center">
        <h4 className="text-2xl font-bold">Overview</h4>
        <div className="flex items-center space-x-4">
          {!agencyClient.deletedAt && (
            <DropdownMenu
              title={
                <DotsVerticalIcon className="w-6 h-6 text-gray-500 hover:text-gray-900" />
              }
              titleClasses="flex items-center mr-1"
              button
              buttonBg="bg-transparent"
              buttonFontWeight="font-normal"
              hoverClasses="bg-none"
              textColor="text-gray-600"
              classes="text-sm"
              buttonRounded=""
              hoverText="hover:text-red-500"
              dropdownWidth="w-48"
              padding=""
              position=""
              hideArrow
            >
              <div className="px-1 py-1 flex flex-col">
                {userCan('clients.profile.edit') && (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`${url}/edit`}
                        className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                      >
                        Edit Profile
                      </Link>
                    )}
                  </Menu.Item>
                )}
                {userCan('clients.profile.resetpassword') && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setOpenPasswordModal(true)}
                        className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                      >
                        Update Password
                      </button>
                    )}
                  </Menu.Item>
                )}
                {userCan('clients.profile.edit') && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setOpenContactModal(true)}
                        className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                      >
                        Update Default Contact
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </DropdownMenu>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-4">
        <div className="">
          <ProfileLogo
            agencyClientId={agencyClient.agencyClientId}
            deletedAt={agencyClient.deletedAt}
          />
        </div>

        <div className="flex flex-col space-y-5 justify-center items-center">
          <CredentialsOverview
            isConnected={spApi}
            title={'Selling Partner API'}
          />

          <CredentialsOverview isConnected={advApi} title={'Advertising API'} />

          <SendMailButton
            type="welcome"
            clientId={agencyClient.agencyClientId}
            isSent={agencyClient.isWelcomeEmailSent}
            isDisabled={!enableEmailButton}
          />

          <SendMailButton
            type="onboarding"
            clientId={agencyClient.agencyClientId}
            isSent={agencyClient.isOnboardingEmailSent}
            isDisabled={!enableEmailButton}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-5">
        {[
          {
            label: 'Contact Name',
            value: nameFormatter(agencyClient.defaultContact),
          },
          {
            label: 'Contact Number',
            value: agencyClient.phone || '-',
          },
          {
            label: 'Address',
            value: agencyClient.address || '-',
          },
          {
            label: "Client's Email Address",
            value: agencyClient.defaultContact
              ? agencyClient.defaultContact.email
              : '-',
          },
          {
            label: 'Seller Interactive Email Address',
            value: agencyClient.siEmail || '-',
          },
        ].map((item) => (
          <div key={item.label} className="space-y-3">
            <p className="text-sm leading-1.5 text-grayscale-700">
              {item.label}
            </p>

            <p className="text-grayscale-900 leading-1.5 text-base">
              {item.value}
            </p>
          </div>
        ))}
      </div>

      <ClientAssignees client={agencyClient} assignees={assignees ?? []} />

      <div>
        {!agencyClient.slackChannelId &&
          userCan('clients.slackChannel.create') && <SlackChannelButton />}
      </div>
      <div>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                <h5 className="text-lg font-bold">Contact Details</h5>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2">
                <div className="sm:grid grid-cols-2">
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Lead First Name
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.lead?.lead || ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Lead Last Name
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.lead.leadLastName || ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Email</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.lead.email || ''}
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Website</label>
                    {agencyClient?.sales?.lead.website ? (
                      <a
                        href={agencyClient?.sales?.lead.website}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.website}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Source</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.source
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Facebook URL
                    </label>
                    {agencyClient.sales !== null ? (
                      <a
                        href={agencyClient?.sales?.lead.facebook}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.facebook}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Instagram URL
                    </label>
                    {agencyClient.sales !== null ? (
                      <a
                        href={agencyClient?.sales?.lead.instagram}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.instagram}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      LinkedIn Account
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.liAccountUsed?.name
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      LinkedIn Profile URL
                    </label>
                    {agencyClient.sales !== null ? (
                      <a
                        href={agencyClient?.sales?.lead.linkedInProfileURL}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.linkedInProfileURL}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                <h5 className="text-lg font-bold">Business Information</h5>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2">
                <div className="sm:grid grid-cols-2 mt-4">
                  <div className="col-span-1 flex flex-col space-y-1.5">
                    <label className="text-gray-400 text-sm">
                      Decision Maker
                    </label>
                    {agencyClient?.sales !== null
                      ? agencyClient?.sales?.decisionMaker
                      : ''}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5">
                    <label className="text-gray-400 text-sm">
                      Display Name
                    </label>
                    {agencyClient.displayName || '--'}
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">About Us</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.aboutUs}
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Goals</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.goals}
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Pain Points</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.painPoints}
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col justify-start space-y-1.5  mt-4">
                    <label className="text-gray-400 text-sm">Other Notes</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.otherNotes}
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Main Issue with Amazon
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.mainIssue}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Average Monthly Sales on Amazon
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? currencyFormatter(
                            agencyClient?.sales?.avgMonthlySalesOnAmazon
                          )
                        : 0}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Average Monthly Sales Outside Amazon
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? currencyFormatter(
                            agencyClient?.sales?.avgMonthlySalesOutsideAmazon
                          )
                        : 0}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">PPC Spend</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? currencyFormatter(agencyClient?.sales?.ppcSpend ?? 0)
                        : 0}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Avg. ACOS</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient?.sales?.avgACOS || 0} %
                    </p>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                <h5 className="text-lg font-bold">
                  Amazon Store And Product Details
                </h5>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2">
                <div className="sm:grid grid-cols-2 mt-4">
                  <div className="col-span-1 flex flex-col space-y-1.5">
                    <label className="text-gray-400 text-sm">Brand Name</label>
                    <p>
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.brandName
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5">
                    <label className="text-gray-400 text-sm">
                      Amazon Store Front URL
                    </label>
                    {agencyClient.sales !== null ? (
                      <a
                        href={agencyClient?.sales?.lead.amzStoreFBAstoreFront}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.amzStoreFBAstoreFront}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Marketplace</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.marketplace
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Product Category
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.productCategory
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Sub Category 1
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.subCategory1
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Sub Category 2
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.subCategory2
                        : ''}
                    </p>
                  </div>

                  <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      ASIN Full Title
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.asinFullTitle
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">ASIN URL</label>
                    {agencyClient.sales !== null ? (
                      <a
                        href={agencyClient?.sales?.lead.leadScreenShotURL}
                        target="_blank"
                        className="text-red-500 truncate"
                      >
                        {agencyClient?.sales?.lead.leadScreenShotURL}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">ASIN</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.asin
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      ASIN Major Keyword
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.asinMajorKeyword
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Fullfilment</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.leadType
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">ASIN Price</label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.asinPrice
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      ASIN Reviews
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? agencyClient?.sales?.lead.asinReviews
                        : ''}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      ASIN Revenue
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? currencyFormatter(
                            agencyClient?.sales?.lead.currentEarnings
                          )
                        : 0}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">
                      Storefront Revenue
                    </label>
                    <p className="whitespace-pre-wrap">
                      {agencyClient.sales !== null
                        ? currencyFormatter(
                            agencyClient?.sales?.lead.storeFrontEarnings
                          )
                        : 0}
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                    <label className="text-gray-400 text-sm">Screenshot</label>
                    {agencyClient.sales !== null &&
                    agencyClient?.sales?.lead.profileAsinRevenueScreenshot ? (
                      <img
                        id="target"
                        src={
                          agencyClient?.sales?.lead.profileAsinRevenueScreenshot
                            .url ?? ''
                        }
                        data-tip={
                          agencyClient?.sales?.lead.profileAsinRevenueScreenshot
                            .createdAt ?? ''
                        }
                        className="border-2 border-indigo-600"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                <h5 className="text-lg font-bold">Competitor Comparable</h5>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2">
                <div>
                  {agencyClient.sales !== null ? (
                    agencyClient?.sales?.lead.competitor?.length > 0 &&
                    agencyClient?.sales?.lead.competitor.map(
                      (competitor, index) => (
                        <div
                          key={index}
                          className="sm:grid grid-cols-2 gap-4 mb-5"
                        >
                          <div className="col-span-2 flex justify-between">
                            <span className="font-bold text-base text-gray-700">
                              Competitor Comparable {index + 1}
                            </span>
                          </div>
                          <div className="col-span-1 flex flex-col space-y-1.5">
                            <label className="text-gray-400 text-sm">
                              Competitor Brand Name
                            </label>
                            <p className="whitespace-pre-wrap">
                              {competitor.competitorBrandName}
                            </p>
                          </div>
                          <div className="col-span-1 flex flex-col space-y-1.5">
                            <label className="text-gray-400 text-sm">
                              Competitor ASIN URL
                            </label>
                            {competitor.competitorScreenShotURL !== null ? (
                              <a
                                href={competitor.competitorScreenShotURL}
                                target="_blank"
                                className="text-red-500 truncate"
                              >
                                {competitor.competitorScreenShotURL}
                              </a>
                            ) : (
                              '-'
                            )}
                          </div>
                          <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                            <label className="text-gray-400 text-sm">
                              Competitor ASIN Revenue
                            </label>
                            <p className="whitespace-pre-wrap">
                              {competitor.revenue}
                            </p>
                          </div>
                          <div className="col-span-1 flex flex-col space-y-1.5 mt-6">
                            <label className="text-gray-400 text-sm">
                              Competitor ASIN Revenue Screenshot
                            </label>
                            {competitor.competitorAsinRevenueScreenshot ? (
                              <img
                                id="target"
                                src={
                                  handleFilePath(
                                    competitor.competitorAsinRevenueScreenshot
                                  ) ?? ''
                                }
                                data-tip={
                                  competitor.competitorAsinRevenueScreenshotDateStamp ??
                                  ''
                                }
                                className="border-2 border-indigo-600"
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <p>No Competitor Comparable</p>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                <h5 className="text-lg font-bold">Call Bookings</h5>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2">
                <div>
                  {agencyClient.sales !== null &&
                    agencyClient?.sales?.callBookings?.length > 0 &&
                    agencyClient?.sales?.callBookings.map(
                      (callBooking, index) => (
                        <div
                          key={index}
                          className="sm:grid grid-cols-2 gap-4 mb-5"
                        >
                          <div className="col-span-2 flex justify-between">
                            <span className="font-bold text-base text-gray-700">
                              Booked Call {index + 1}
                            </span>
                          </div>
                          <div className="col-span-1 flex flex-col space-y-1.5">
                            <label className="text-gray-400 text-sm">
                              Call Booking Date
                            </label>
                            <p className="whitespace-pre-wrap">
                              {moment(
                                callBooking.bookingDate,
                                'YYYY-MM-DD'
                              ).format('YYYY-MM-DD')}
                            </p>
                          </div>
                          <div className="col-span-1 flex flex-col space-y-1.5">
                            <label className="text-gray-400 text-sm">
                              Call Recording Link
                            </label>
                            {callBooking.recordingLink !== null ? (
                              <a
                                href={callBooking.recordingLink}
                                target="_blank"
                                className="text-red-500 truncate"
                              >
                                {callBooking.recordingLink}
                              </a>
                            ) : (
                              '-'
                            )}
                          </div>
                          <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                            <label className="text-gray-400 text-sm">
                              Video Summary Link
                            </label>
                            {callBooking.videoSummaryLink !== null ? (
                              <a
                                href={callBooking.videoSummaryLink}
                                target="_blank"
                                className="text-red-500 truncate"
                              >
                                {callBooking.videoSummaryLink}
                              </a>
                            ) : (
                              '-'
                            )}
                          </div>
                          <div className="col-span-2 flex flex-col space-y-1.5 mt-6">
                            <label className="text-gray-400 text-sm">
                              Call Booking Notes
                            </label>
                            <p className="whitespace-pre-wrap">
                              {callBooking.notes}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <UpdatePasswordModal
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
        userId={agencyClient.defaultContactId}
      />
      <UpdateDefaultContactModal
        open={openContactModal}
        setOpen={setOpenContactModal}
        agencyClient={agencyClient}
        onReload={onReloadContact}
      />
    </div>
  );
};
export default View;
