import { XIcon, PencilIcon, CheckIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import classNames from 'utils/classNames';

const InlineEditor = ({ onExit, onSubmit, children, display }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  return isEditing ? (
    <div className="flex items-center">
      {children}

      <div className="flex ml-2">
        <CheckIcon
          className={classNames(
            'h-5 w-5 text-success-dark',
            isSaving ? 'cursor-wait' : 'cursor-pointer'
          )}
          onClick={() => {
            setIsSaving(true);
            onSubmit().then(() => {
              setIsSaving(false);
              setIsEditing(false);
            });
          }}
        />
        <XIcon
          className={classNames(
            'h-5 w-5 text-error-dark',
            isSaving ? 'cursor-wait' : 'cursor-pointer'
          )}
          onClick={() => {
            setIsEditing(false);
            onExit();
          }}
        />
      </div>
    </div>
  ) : (
    <div className="flex space-x-1">
      <span className="font-normal">{display}</span>

      <PencilIcon
        className="h-5 w-5 cursor-pointer"
        onClick={() => setIsEditing(true)}
      />
    </div>
  );
};

export default InlineEditor;
