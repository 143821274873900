import { useFormikContext, Field, ErrorMessage } from 'formik';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import Label from 'components/Forms/Label';

const SponsoredDisplayCampaign = () => {
  const { handleChange } = useFormikContext();

  const biddingStrategies = [
    { name: 'Optimize For Each', value: 'optimizeForEach' },
    { name: 'Optimize For Page Visit', value: 'optimizeForPageVisit' },
    { name: 'Optimize For Conversion ', value: 'optimizeForConversion' },
  ];

  const targeting = [
    { name: 'Contextual Targeting', value: 'T00020' },
    { name: 'Audiences Targeting', value: 'T00030' },
  ];

  const adTypes = [
    { name: 'Image', value: 'image' },
    { name: 'Video', value: 'video' },
  ];

  return (
    <div className="flex flex-col w-full gap-y-6 p-8 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.campaignName"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Campaign Name <RequiredAsterisk />
        </Label>
        <Field
          name="data.campaignName"
          placeholder="Add campaign name"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <ErrorMessage
          name="data.campaignName"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <Label
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
          >
            Start Date <RequiredAsterisk />
          </Label>
          <input
            name="data.startDate"
            type="date"
            placeholder="Select Date"
            className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <ErrorMessage
            name="data.startDate"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div>
          <Label
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
          >
            End Date <RequiredAsterisk />
          </Label>
          <input
            name="data.endDate"
            type="date"
            placeholder="Select Date"
            className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <ErrorMessage
            name="data.endDate"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>

        <div>
          <Label
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
          >
            Budget <RequiredAsterisk />
          </Label>
          <div className="flex justify-center gap-0 items-center">
            <span className="text-black sm:text-sm">$</span>
            <Field
              name="data.budget"
              className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400 disabled-white"
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <ErrorMessage
            name="data.budget"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      </div>

      <div>
        <Label
          htmlFor="data.strategy"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Bidding Optimization <RequiredAsterisk />
        </Label>
        <Field
          name="data.strategy"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {biddingStrategies.map((t, i) => (
            <option key={i} value={t.value}>
              {t.name}
            </option>
          ))}
        </Field>
      </div>

      <div>
        <Label
          htmlFor="data.adGroupCreativeType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Ad Format <RequiredAsterisk />
        </Label>
        <Field
          name="data.adGroupCreativeType"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {adTypes.map((t, i) => (
            <option key={i} value={t.value}>
              {t.name}
            </option>
          ))}
        </Field>
      </div>

      <div>
        <Label
          htmlFor="data.targetingType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Targeting Type
          <RequiredAsterisk />
        </Label>
        <Field
          name="data.targetingType"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {targeting.map((b, i) => (
            <option key={i} value={b.value}>
              {b.name}
            </option>
          ))}
        </Field>
      </div>

      <div>
        <Label
          htmlFor="data.adGroupName"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Ad Name <RequiredAsterisk />
        </Label>
        <Field
          name="data.adGroupName"
          placeholder="Add ad name"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <ErrorMessage
          name="data.adGroupName"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
    </div>
  );
};

export default SponsoredDisplayCampaign;
