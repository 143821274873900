export const LIVE = 'live';
export const FUTURE = 'future';
export const UNPAID = 'unpaid';
export const DUNNING = 'dunning';
export const NON_RENEWING = 'non_renewing';
export const EXPIRED = 'expired';
export const CANCELLED = 'cancelled';
export const PAUSED = 'paused';
export const OFFLINE = 'offline';
export const NOT_SUBSCRIBED = 'not subscribed';

export const SUBSCRIPTION_STATUSES = [
  LIVE,
  FUTURE,
  UNPAID,
  DUNNING,
  NON_RENEWING,
  EXPIRED,
  CANCELLED,
  PAUSED,
  OFFLINE,
  NOT_SUBSCRIBED,
];
