import usePermissions from 'hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import {
  InboxInIcon,
  XCircleIcon,
  ClipboardCheckIcon,
  BriefcaseIcon,
  StarIcon,
  PresentationChartLineIcon,
  SpeakerphoneIcon,
  AnnotationIcon,
  BanIcon,
} from '@heroicons/react/outline';

const useClientsMenu = () => {
  const { t } = useTranslation();
  const { userCan } = usePermissions();

  return {
    name: t('Dashboard.Clients'),
    href: '/clients/active',
    icon: '#clients',
    iconType: 'svg',
    visible: () =>
      userCan('clients.list.active') ||
      userCan('clients.list.onboarding') ||
      userCan('clients.list.termination') ||
      userCan('clients.list.inactive') ||
      userCan('clients.list.all'),
    children: [
      {
        name: 'Active Clients',
        href: '/clients/active',
        icon: ClipboardCheckIcon,
        visible: () => userCan('clients.list.active'),
      },
      {
        name: 'For Onboarding',
        href: '/clients/foronboarding',
        icon: InboxInIcon,
        visible: () => userCan('clients.list.onboarding'),
      },
      {
        name: 'For Termination',
        href: '/clients/fortermination',
        icon: BanIcon,
        visible: () => userCan('clients.list.termination'),
      },
      {
        name: 'Inactive Clients',
        href: '/clients/inactive',
        icon: XCircleIcon,
        visible: () => userCan('clients.list.inactive'),
      },
      {
        name: 'All Clients',
        href: '/clients/all',
        icon: ClipboardCheckIcon,
        visible: () => userCan('clients.list.all'),
      },
      {
        name: 'Records',
        href: '/clients/records',
        icon: ClipboardCheckIcon,
        visible: () => true,
      },
      {
        name: 'Rating Dashboard',
        href: '/clients/performance-ratings',
        icon: StarIcon,
        visible: () => userCan('clients.performance_rating.list'),
      },
      {
        name: 'Account Performance',
        href: '/clients/accounts-performance',
        icon: PresentationChartLineIcon,
        visible: () => userCan('clients.account_performance.list'),
      },
      {
        name: 'PPC Dashboard',
        href: '/clients/ppc',
        icon: BriefcaseIcon,
        visible: () => userCan('clients.view.ppc_dashboard'),
      },
      {
        name: 'Complaints',
        href: '/clients/complaints',
        icon: SpeakerphoneIcon,
        visible: () => userCan('clients.complaints.view'),
      },
      {
        name: 'Client Survey',
        href: '/clients/surveys',
        icon: AnnotationIcon,
        visible: () => userCan('clients.survey.list'),
      },
    ],
  };
};

export default useClientsMenu;
