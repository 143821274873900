import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import Client from './Client/Client';
import NewClient from './Client/NewClient';
import NewClientEdit from './Client/NewClientEdit';
import ClientMigration from './Migration/ClientMigration';
import Active from './List/Active';
import ForOnboarding from './List/ForOnboarding';
import Inactive from './List/Inactive';
import Admin from './Admin/Admin';
import PPC from './PPC/PPC';
import ClientRedirect from './Client/ClientRedirect';
import RatingDashboard from './RatingDashboard/RatingDashboard';
import AccountPerformance from './AccountPerformance/AccountPerformance';
import Complaints from 'features/complaint/Complaints';
import Surveys from './Survey/Surveys';
import ForTermination from './List/ForTermination';
import ClientList from './List/components/ClientList';
import Terminated from './List/Terminated';
import AllClients from './List/AllClients';
import Records from './List/Records';

function AccountRedirectRoute({ component: Component, ...rest }) {
  const history = useHistory();
  useEffect(() => {
    async function getClient() {
      try {
        const response = await axios.get(
          `/agency/clients/accounts/${rest.computedMatch.params.id}`
        );
        history.replace(
          `/clients/${response.data.data.agencyClientId}/dashboard`
        );
      } catch (error) {
        history.replace('/not-found');
      }
    }

    getClient();
  }, [rest]);

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}

function ZohoIdRedirectRoute({ component: Component, ...rest }) {
  const history = useHistory();
  useEffect(() => {
    async function getClient() {
      try {
        const response = await axios.get(
          `/agency/clients/zid/${rest.computedMatch.params.id}`
        );
        history.replace(
          `/clients/${response.data.data.agencyClientId}/dashboard`
        );
      } catch (error) {
        history.replace('/not-found');
      }
    }

    getClient();
  }, [rest]);

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}

const Clients = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Redirect to={`${path}/active`} />
      </Route>
      <Route path={`${path}/active`} component={Active} exact />
      <Route path={`${path}/all`} component={AllClients} exact />
      <Route path={`${path}/records`} component={Records} exact />
      <Route path={`${path}/foronboarding`} component={ForOnboarding} exact />

      <Route
        path={`${path}/fortermination`}
        render={() => (
          <ForTermination attribute="hasPendingTerminationRequest" />
        )}
        exact
      />

      <Route
        path={`${path}/fortermination/denied`}
        render={() => (
          <ForTermination attribute="hasDeniedTerminationRequest" />
        )}
        exact
      />

      <Route path={`${path}/inactive`} component={Inactive} exact />

      <Route path={`${path}/admin`} component={Admin} />
      <Route path={`${path}/ppc`} component={PPC} />
      <Route path={`${path}/add`} component={NewClient} />
      <Route path={`${path}/complaints`} component={Complaints} />
      <Route path={`${path}/surveys`} component={Surveys} />
      <Route path={`${path}/performance-ratings`} component={RatingDashboard} />
      <Route
        path={`${path}/accounts-performance`}
        component={AccountPerformance}
      />
      <Route path={`${path}/edit/:agencyClientId`} component={NewClientEdit} />
      <Route path={`${path}/migration/:id`} component={ClientMigration} />
      <AccountRedirectRoute path={`${path}/account/:id`} component={Client} />
      <ZohoIdRedirectRoute path={`${path}/zid/:id`} component={Client} />
      <Route
        path={`${path}/redirect/:agencyClientId`}
        component={ClientRedirect}
      />
      <Route path={`${path}/:agencyClientId`} component={Client} />
    </Switch>
  );
};

export default Clients;
