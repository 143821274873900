import axios from 'axios';
import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';

import MetricCard from '../../components/MetricCard/MetricCard';
import SectionComment from '../components/SectionComment';
import metrics from '../../utils/metrics';

const AnalyticsOverview = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  comment = '',
  onChangeComment,
  editableComment = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [overview, setOverview] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: [
          'cr',
          'ctr',
          'cpm',
          'cpc',
          'acos',
          'aov',
          'roas',
          'cost',
          'cpcon',
          'sales',
          'clicks',
          'orders',
          'impressions',
          'ordersPerUnit',
          'clicksPerOrder',
          'impressionsPerClick',
          'unitsSold',
        ],
        ...additionalParams,
        include: ['totalSales', 'previousData'],
      };

      const response = await axios.get('/account/advertising/analytics', {
        params,
      });

      if (isSubscribed) {
        setOverview(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    <div className="relative">
      <SectionComment
        value={comment}
        onChangeValue={onChangeComment}
        editable={editableComment}
      />

      <div className={className}>
        {[
          'totalSales',
          'sales',
          'cost',
          'tacos',
          'acos',
          'cpcon',
          'orders',
          'impressions',
          'clicks',
          'cr',
          'ctr',
          'aov',
          'cpm',
          'ordersPerUnit',
          'impressionsPerClick',
          'clicksPerOrder',
          'cpc',
          'roas',
        ]
          .map((attribute) => metrics.find((m) => m.key === attribute))
          .map((metric) => (
            <MetricCard
              loading={loading}
              key={`analytics-overview-${metric.key}`}
              title={metric.title}
              reversePercentage={!!metric.reverse}
              formatter={metric.formatter}
              value={overview[metric.key]}
              previousValue={overview[`previous${upperFirst(metric.key)}`]}
              prefixValue={metric.prefix}
              suffixValue={metric.suffix}
              dividendTitle={
                metric.dividend &&
                metrics.find((m) => m.key === metric.dividend)
                  ? metrics.find((m) => m.key === metric.dividend).title
                  : ''
              }
              divisorTitle={
                metric.divisor && metrics.find((m) => m.key === metric.divisor)
                  ? metrics.find((m) => m.key === metric.divisor).title
                  : ''
              }
              dividendValue={
                metric.dividend &&
                metrics.find((m) => m.key === metric.dividend)
                  ? metrics
                      .find((m) => m.key === metric.dividend)
                      .rawFormatter(overview[metric.dividend])
                  : ''
              }
              divisorValue={
                metric.divisor && metrics.find((m) => m.key === metric.divisor)
                  ? metrics
                      .find((m) => m.key === metric.divisor)
                      .rawFormatter(overview[metric.divisor])
                  : ''
              }
            />
          ))}
      </div>
    </div>
  );
};

export default AnalyticsOverview;
