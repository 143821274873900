import axios from 'axios';
import { useEffect, useState } from 'react';

const useFetch = (url, params) => {
  const [data, setData] = useState(null);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setIsLoading(true);

      const response = await axios.get(url, {
        params,
      });

      if (isSubscribed) {
        setData(response.data.data);
        setIsLoading(false);
      }
    };

    fetchData().catch((error) => {
      setErrorMessage(error.message);
    });

    return () => (isSubscribed = false);
  }, [url, params, reload]);

  return { data, isLoading, errorMessage, reload, setReload };
};

export default useFetch;
