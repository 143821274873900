import axios from 'axios';

const useCreditNotes = () => {
  const STATUS = {
    all: 'all',
    draft: 'draft',
    awaitingApproval: 'awaiting approval',
    //pending: 'pending',
    approved: 'approved',
    applied: 'applied',
    denied: 'denied',
    cancelled: 'cancelled',
    appliedPartial: 'applied-partial',
    //manuallyApproved: 'manually-approved',
  };

  const USERTYPE = {
    creator: 'creator',
    reviewer: 'reviewer',
    applier: 'applier',
  };

  const isAppliedPartial = (status) => {
    return status === STATUS.appliedPartial;
  };

  const isAnyApplied = (status) => {
    return status === STATUS.applied || status === STATUS.appliedPartial;
  };

  const isApprovedOrApplied = (status) => {
    return (
      status === STATUS.approved ||
      status === STATUS.applied ||
      status === STATUS.appliedPartial
    );
  };
  const isApprovedOrAnyApplied = (status) => {
    return status === STATUS.approved || isAnyApplied(status);
  };
  const isApprovedOrAppliedPartial = (status) => {
    return status === STATUS.approved || status === STATUS.appliedPartial;
  };

  const isRejected = (status) => {
    return status === STATUS.denied || STATUS.cancelled;
  };

  const canApplyToInvoice = (status) => {
    return (
      status === STATUS.awaitingApproval ||
      status === STATUS.approved ||
      status === STATUS.appliedPartial
    );
  };

  const isReviewer = (type) => {
    return type === USERTYPE.reviewer;
  };

  const isApplier = (type) => {
    return type === USERTYPE.applier;
  };

  const isCreator = (type) => {
    return type === USERTYPE.creator;
  };

  const canReviewOrApply = (type) => {
    return !isCreator(type);
  };

  const fetchOpenInvoices = async (customerId, force = false) => {
    return await axios.get(
      `/agency/invoices/open/${customerId}${force ? `?force=true` : ''}`
    );
  };

  return {
    STATUS,
    USERTYPE,
    isAnyApplied,
    isApprovedOrAnyApplied,
    isApprovedOrAppliedPartial,
    canApplyToInvoice,
    isApprovedOrApplied,
    isAppliedPartial,
    isReviewer,
    isApplier,
    isCreator,
    canReviewOrApply,
    isRejected,
    fetchOpenInvoices,
  };
};

export default useCreditNotes;
