import axios from 'axios';

import { upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ChartBarIcon, BookmarkIcon } from '@heroicons/react/solid';

import { userCan } from 'utils/permission';

import ApplyRecommendedBudget from './components/ApplyRecommendedBudget';
import TrendsModal from '../../../components/TrendsModal/TrendsModal';
import AllocationEditor from '../../components/AllocationEditor';
import StatusSwitch from '../../components/StatusSwitch';

import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import { metricColumns } from 'features/advertising/utils/columns';

import {
  EQUAL_TO,
  LESS_THAN,
  SPONSORED_PRODUCTS,
  STATUS_OPTIONS,
} from 'features/advertising/utils/constants';

const Campaigns = ({ accountId, marketplace, campaignType }) => {
  const { url } = useRouteMatch();
  const queryParams = new URLSearchParams(window.location.search);

  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { user } = useSelector((state) => state.auth);

  const [changed, setChanged] = useState(false);
  const [selected, setSelected] = useState([]);
  const [campaigns, setCampaigns] = useState({ rows: [] });
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [openTrends, setOpenTrends] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('campaigns-column') ??
      'advCampaignId,name,state,campaignType,startDate,endDate,cost,sales,orders'
  );

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    campaignType,
    search: '',
    attributes: [
      'advCampaignId',
      'state',
      'name',
      'campaignType',
      'budget',
      'advPortfolioId',
      'bidding',
      'startDate',
      'endDate',
      'budget',
      'dailyBudget',
      'impressions',
      'clicks',
      'ctr',
      'cost',
      'cpc',
      'orders',
      'sales',
      'acos',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'roas',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
    include: ['previousData', 'budgetRecommendation', 'portfolio'],
    sort: 'cost:desc',
    listingId: queryParams.has('listingId')
      ? queryParams.get('listingId')
      : null,
    advCampaignId: queryParams.has('campaignId')
      ? queryParams.get('campaignId')
      : null,
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/account/advertising/campaigns`, {
        params: {
          ...params,
          accountId,
          marketplace,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          scope: params.scope ? [params.scope] : [],
        },
      });

      if (isSubscribed) {
        setCampaigns(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, campaignType, dateRange, params, changed]);

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('campaigns-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const columns = [
    {
      sort: true,
      auto: true,
      default: true,
      dataField: 'name',
      category: 'settings',
      text: 'Campaign',
      headerStyle: { minWidth: '275px' },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
    },
    {
      sort: false,
      default: true,
      hideable: true,
      text: 'Status',
      dataField: 'state',
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity="campaigns"
          entityId={row.advCampaignId}
          accountId={accountId}
          marketplace={marketplace}
          disabled={
            !userCan(
              user,
              'ppc.campaign.status.noApproval|ppc.campaign.status.requireApproval'
            )
          }
        />
      ),
      category: 'settings',
    },
    {
      sort: false,
      hideable: true,
      text: 'Bidding',
      category: 'settings',
      dataField: 'bidding',
      headerStyle: { minWidth: '150px' },
      formatter: (bidding) => {
        if (!bidding) return '';
        switch (bidding.strategy) {
          case 'legacyForSales':
            return 'Dynamic bids - down only';
          case 'autoForSales':
            return 'Dynamic bids - up and down';
          case 'manual':
            return 'Fixed bid';
          default:
            return '-';
        }
      },
    },
    {
      dataField: 'budget',
      text: 'Budget',
      sort: true,
      headerStyle: { minWidth: '175px' },
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <AllocationEditor
          accountId={accountId}
          marketplace={marketplace}
          entity="campaigns"
          entityId={row.advCampaignId}
          currentValue={cell}
          recommended={row.budgetRecommendation}
          editable={userCan(
            user,
            'ppc.campaign.updateDailyBudget.noApproval|ppc.campaign.updateDailyBudget.requireApproval'
          )}
          attribute={
            campaignType === 'sponsoredProducts' ? 'dailyBudget' : 'budget'
          }
        />
      ),
      hideable: true,
      category: 'settings',
    },
    {
      sort: true,
      default: true,
      hideable: true,
      dataField: 'startDate',
      category: 'settings',
      text: 'Start Date',
      headerStyle: { minWidth: '150px' },
    },
    {
      sort: true,
      default: true,
      hideable: true,
      dataField: 'endDate',
      category: 'settings',
      text: 'End Date',
      headerStyle: { minWidth: '150px' },
      formatter: (cell) => cell ?? 'No end date',
    },
    ...metricColumns().filter((col) => {
      return col.campaignTypes
        ? col.campaignTypes.includes(campaignType)
        : true;
    }),
    {
      auto: true,
      default: true,
      dataField: 'advCampaignId',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const onClick = () => {
          setSelectedCampaign(row);
          setOpenTrends(true);
        };

        return (
          <div className="flex">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={onClick}
            />
            <NavLink
              to={url.replace('campaigns', `ad-groups?campaignId=${cell}`)}
            >
              <BookmarkIcon className="w-6 h-6 text-secondary" />
            </NavLink>
          </div>
        );
      },
    },
  ];

  const statusOptions = STATUS_OPTIONS.map((value) => {
    const display = upperFirst(value);
    return { display, value };
  });

  let additionalFilters = [
    {
      attribute: 'state',
      display: 'Status',
      comparison: EQUAL_TO,
      value: '',
      options: statusOptions,
      placeholder: 'Status',
    },
    {
      attribute: 'budget',
      display: 'Budget',
      comparison: LESS_THAN,
      value: '',
    },
  ];

  if (campaignType === SPONSORED_PRODUCTS) {
    additionalFilters.unshift({
      attribute: 'targetingType',
      display: 'Targeting Type',
      comparison: EQUAL_TO,
      value: '',
      placeholder: 'Targeting Type',
      options: [
        {
          value: 'auto',
          display: 'Automatic Targeting',
        },
        {
          value: 'manual',
          display: 'Manual Targeting',
        },
      ],
    });

    additionalFilters.unshift({
      attribute: 'scope',
      display: 'Scopes',
      comparison: EQUAL_TO,
      order: 0,
      value: '',
      options: [
        {
          display: 'With Budget Recommendation',
          value: 'withBudgetRecommendation',
        },
      ],
    });
  }

  const selectRow = {
    mode: 'checkbox',
    headerColumnStyle: () => {
      return {
        padding: '0.5rem',
        backgroundColor: 'rgba(252, 252, 252, var(--tw-bg-opacity))',
        borderBottomColor: 'rgb(229,231,235)',
        borderBottomWidth: '1px',
      };
    },
    nonSelectable: campaigns.rows
      .filter((campaign) => !campaign.budgetRecommendation)
      .map((campaign) => campaign.advCampaignId),
    selected: selected.map((campaign) => campaign.advCampaignId),
    onSelect: (row, isSelect) => {
      isSelect
        ? setSelected([...selected, row])
        : setSelected(
            selected.filter(
              (campaign) => campaign.advCampaignId !== row.advCampaignId
            )
          );
    },
    onSelectAll: (isSelect, rows) => {
      isSelect
        ? setSelected([
            ...selected,
            ...rows.filter(
              (row) =>
                !selected.find((s) => s.advCampaignId === row.advCampaignId)
            ),
          ])
        : setSelected(
            selected.filter(
              (campaign) =>
                !rows.find(
                  (row) => row.advCampaignId === campaign.advCampaignId
                )
            )
          );
    },
  };

  let tableProps = {
    params: params,
    list: campaigns,
    columns: columns,
    accountId: accountId,
    recordType: 'campaigns',
    keyField: 'advCampaignId',
    marketplace: marketplace,
    campaignType: campaignType,
    onChangeParams: setParams,
    additionalFilters: additionalFilters,
    searchClassName: 'col-span-12 lg:col-span-3 xl:col-span-3',
    filtersClassName: 'col-span-6 lg:col-span-2 xl:col-span-2',
    exportClassName: 'col-span-6 lg:col-span-2 xl:col-span-2',
    columnPickerClassName: 'col-span-6 lg:col-span-2 xl:col-span-2',
    searchPlaceholder: 'Search campaign',
    attributesKey: `${accountId}-${campaignType}-campaigns`,
    visibleColumns,
    onChangeVisibleColumns,
    selectRow,
    endpoint: 'campaigns',
  };

  return (
    <div id={`advertising-${campaignType}-campaigns`}>
      <TrendsModal
        title={selectedCampaign.name}
        open={openTrends}
        setOpen={(value) => {
          setSelectedCampaign({});
          setOpenTrends(value);
        }}
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selectedCampaign}
        url={
          selectedCampaign.advCampaignId
            ? '/account/advertising/analytics/records'
            : ''
        }
        additionalParams={{ advCampaignId: selectedCampaign.advCampaignId }}
      />

      <AdvertisingTable {...tableProps}>
        <ApplyRecommendedBudget
          accountId={accountId}
          marketplace={marketplace}
          campaignType={campaignType}
          campaigns={selected}
          disabled={
            !userCan(
              user,
              'ppc.campaign.applyRecommendedBudget.noApproval|ppc.campaign.applyRecommendedBudget.requireApproval'
            )
          }
          onSuccess={() => setChanged(!changed)}
        />
      </AdvertisingTable>
    </div>
  );
};

export default Campaigns;
