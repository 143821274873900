import { useState, useEffect } from 'react';

import { useUsers } from 'features/users';
import { useRoles } from 'features/roles';
import { Navigation, OrgChartComponent } from 'features/employees/components';

const OrganizationChart = () => {
  const { CEO, departments, levels } = useRoles();
  const { fetchAllUsers, users } = useUsers();

  const [selectedDepartment, setSelectedDepartment] = useState(
    departments.OPERATIONS_DEPARTMENT.value
  );

  useEffect(() => {
    let filterGroups = [
      {
        filters: [
          {
            operator: 'EQ',
            attribute: 'role.level',
            value: levels.AGENCY_LEVEL,
          },
          {
            operator: 'EQ',
            attribute: 'role.department',
            value: selectedDepartment,
          },
          {
            operator: 'HAS_PROPERTY',
            attribute: 'supervisorId',
          },
          {
            operator: 'IN',
            attribute: 'status',
            value: ['ACTIVE', 'INVITED'],
          },
        ],
      },
    ];

    if (selectedDepartment === departments.OUTGIVE_DEPARTMENT.value) {
      filterGroups.push({
        filters: [
          {
            operator: 'EQ',
            attribute: 'role.code',
            value: CEO.value,
          },
        ],
      });
    }

    fetchAllUsers({
      include: ['role', 'avatar'],
      attributes: [
        'email',
        'status',
        'userId',
        'lastName',
        'firstName',
        'supervisorId',
      ],
      filterGroups: JSON.stringify(filterGroups),
    });
  }, [selectedDepartment]);

  return (
    <div>
      <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Organizational Chart
      </h2>

      <Navigation />

      <div className="mt-8">
        {!users.loading && (
          <OrgChartComponent
            department={selectedDepartment}
            onChangeDepartment={setSelectedDepartment}
            data={users.data.map((user) => {
              return {
                ...user,
                id: user.userId,
                parentId: users.data.find((i) => i.userId === user.supervisorId)
                  ? user.supervisorId
                  : null,
              };
            })}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationChart;
