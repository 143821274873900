import { useEffect } from 'react';
import { lowerCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';

import { setMarketplace } from '../accountSlice';

const MarketplaceContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { marketplace: marketplaceParams } = useParams();

  const { account } = useSelector((state) => state.account);

  useEffect(() => {
    const selectedMarketplace = account.marketplaces.find(
      (m) => lowerCase(m.details.countryCode) === lowerCase(marketplaceParams)
    );

    dispatch(setMarketplace(selectedMarketplace ?? account.marketplaces[0]));
  }, [dispatch, marketplaceParams, account]);

  return <div className="mr-8">{children}</div>;
};

export default withRouter(MarketplaceContainer);
