import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Textarea from 'components/Forms/Textarea';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { useState } from 'react';

const ReasonForUpdateModal = ({ open, setOpen, onAddReason, loading }) => {
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');

  const onInputChange = (e) => {
    setReasonError(e.target.value === '' ? 'Reason is required' : '');
    setReason(e.target.value);
  };

  const onSubmit = () => {
    if (reason !== '') {
      onAddReason(reason);
    } else {
      setReasonError('Reason is required');
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Add Reason"
          setOpen={setOpen}
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="pt-4 md:pt-8"
        />

        <form method="POST" onSubmit={onSubmit}>
          <div className="px-8 text-xs tracking-wide pt-3">
            Please enter the reason editing a sent invoice
          </div>
          <div className="py-4 px-8">
            <Textarea
              id="description"
              onChange={onInputChange}
              value={reason}
              classes="form-input"
              rows={3}
              required
            />
            <div className="text-red-500 text-xs">{reasonError}</div>
          </div>
          <div className="py-4 px-8 flex justify-start items-center space-x-4">
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonLink>

            <Button
              onClick={onSubmit}
              loading={loading}
              showLoading={true}
              classes="border-0 font-bold tracking-wider "
              bgColor="red-700"
              hoverColor="red-400"
              roundedSize="2xl"
              textColor="white"
              px={12}
              py={1.5}
              shadow=""
              type="button"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReasonForUpdateModal;
