import { Popover } from '@headlessui/react';
import { FilterIcon, XIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useState, useMemo } from 'react';
import classNames from 'utils/classNames';
import { cloneDeep } from 'lodash';

const FilterPanel = ({ options, onApply }) => {
  const [items, setItems] = useState(cloneDeep(options));

  const count = useMemo(
    () => items.filter(({ visible }) => visible).length,
    [items]
  );

  return (
    <div className="rounded-2xl shadow-xl ring-1 ring-gray-500 ring-opacity-5 bg-white w-72">
      <div className="flex justify-between sticky top-0 bg-white py-6 z-10 border-b border-grayscale-500 px-6">
        <h5 className="flex items-center space-x-2 font-inter tracking-3/4 text-base font-bold text-grayscale-900">
          <span>Filter</span>

          <span
            className="text-13 font-bold leading-1.5 flex items-center justify-center rounded-full bg-grayscale-500"
            style={{ width: '30px', height: '30px' }}
          >
            {count}
          </span>
        </h5>

        <Popover.Button>
          <XIcon className="h-5 w-5 text-grayscale-700" />
        </Popover.Button>
      </div>

      <div className="">
        <ul className="px-6 my-4 space-y-2.5 h-64 overflow-y-auto">
          <div className="flex justify-between items-center">
            <p className="text-grayscale-800 font-bold leading-1.5">Show</p>

            <button
              onClick={() => {
                setItems(
                  [...items].map((item) => ({
                    ...item,
                    visible: !items.every(({ visible }) => visible),
                  }))
                );
              }}
              className="tracking-2 text-11 font-bold leading-1.5 text-secondary-light uppercase"
            >
              {items.every(({ visible }) => visible)
                ? 'Unselect All'
                : 'Select All'}
            </button>
          </div>

          {items.map((option, idx) => {
            return (
              <button
                key={option.key}
                onClick={() => {
                  let newItems = [...items];
                  newItems[idx]['visible'] = !!!option.visible;
                  setItems(newItems);
                }}
                className="flex justify-between items-center p-2.5 bg-grayscale-400 w-full rounded-xl text-13 text-grayscale-800 leading-1.5"
              >
                <span>{option.key}</span>

                <CheckCircleIcon
                  className={classNames(
                    'w-5 h-5',
                    option.visible ? 'text-secondary' : 'text-grayscale-600'
                  )}
                />
              </button>
            );
          })}
        </ul>

        <div className="sticky bottom-0 left-0 w-full border-t border-grayscale-500 px-6 py-4 bg-white">
          <button
            onClick={() => onApply(items)}
            className="bg-secondary w-full py-2.5 rounded-40 text-sm font-bold tracking-2 leading-1.5 text-grayscale-300"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const ChartsFilter = ({ options, setVisibleCharts }) => {
  const count = useMemo(
    () => options.filter((i) => i.visible).length,
    [options]
  );

  return (
    <Popover className="relative">
      <Popover.Button className="flex space-x-1 items-center text-grayscale-700 outline-none">
        <FilterIcon className="w-4 h-4 inline mr-1" />
        <span className="tracking-2 font-bold text-base leading-1.5">
          Filter
        </span>

        <span
          className="text-13 font-bold leading-1.5 flex items-center justify-center rounded-full bg-grayscale-500"
          style={{ width: '30px', height: '30px' }}
        >
          {count}
        </span>
      </Popover.Button>

      <Popover.Panel className="right-0 absolute z-20 min-h-full">
        <FilterPanel
          options={cloneDeep([...options])}
          onApply={setVisibleCharts}
        />
      </Popover.Panel>
    </Popover>
  );
};

export default ChartsFilter;
