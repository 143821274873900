import React, { useCallback, useEffect, useState } from 'react';
import ModalHeader from 'components/ModalHeader';
import { Card } from './Card';
import update from 'immutability-helper';
import ColumnPicker from './ColumnPicker';
import { numberFormatter } from 'utils/formatters';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { getMyselfAsync } from 'features/auth/authSlice';

const CustomizeColumnModal = ({ setOpen, tableColumns, setTableColumns }) => {
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const { user } = useLoggedInUser();
  const [columnsPickerData, setColumnsPickerData] = useState([]);

  useEffect(() => {
    setCards(tableColumns);
    setColumnsPickerData(tableColumns);
  }, [tableColumns]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const deleteCard = useCallback((selectedIndex, id) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [[selectedIndex, 1]],
      })
    );
    const i = tableColumns.findIndex((el) => el.dataField === id);
    const optionsCopy = [...tableColumns];
    optionsCopy[i].show = !optionsCopy[i].show;
    setColumnsPickerData(optionsCopy);
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.dataField}
        index={index}
        id={card.dataField}
        text={card.text}
        moveCard={moveCard}
        deleteCard={deleteCard}
      />
    );
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const getNumOfSelected = (columns) => {
    let selected = 0;
    columns.forEach((element) => {
      if (element.show) {
        selected++;
      }
    });
    return selected;
  };

  const handleRemoveAllSelected = (columns) => {
    const emptyArray = columns.map((element) => {
      let newElement = null;
      if (element.show && element.hideable) {
        newElement = {
          ...element,
          show: false,
        };
        return newElement;
      } else {
        return element;
      }
    });

    setColumnsPickerData(emptyArray);
    setCards(emptyArray);
  };

  const onSavingUserSetting = async (salesColumn) => {
    axios
      .patch(`/users/${user.userId}/setting/sales-column`, { salesColumn })
      .then((res) => {
        dispatch(setAlert('success', res.data.message));
        dispatch(getMyselfAsync());
      })
      .catch((err) => dispatch(setAlert('error', err)))
      .finally(() => {
        setTableColumns(salesColumn);
        setOpen(false);
      });
  };

  return (
    <>
      <div className="inline-block w-9/12 xl:w-3/5 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
        <ModalHeader
          title={'Customize Column'}
          setOpen={setOpen}
          titleClasses="capitalize"
          border="border-0"
          px="px-3"
          py="py-4"
          fontSize="text-2xl"
          fontStyle="font-bold"
          iconClassname="h-8 w-8"
          onClose={onClose}
        />
        <div className="grid grid-cols-3 h-70vh border-t">
          <div className="flex flex-col col-span-1 items-center content-start border-r border-gray-200">
            <ColumnPicker
              tableColumns={columnsPickerData}
              setCards={setCards}
            />
          </div>
          <div className="col-span-2 flex-grow h-70vh overflow-y-auto px-10 bg-grayscale-400 py-2">
            <p className="text-xl font-bold text-grayscale-900 flex pb-3">
              Selected Columns
              <span className="border p-1.5 ml-3 leading-none rounded text-11 font-bold bg-grayscale-500 text-grayscale-800">
                {numberFormatter(getNumOfSelected(columnsPickerData))}
              </span>
            </p>
            <div className="rounded bg-grayscale-200 py-2 px-4 border mb-2">
              <p className="font-normal text-base text-grayscale-800 pl-6">
                Client Name
              </p>
            </div>
            {cards?.map((card, i) => {
              return card.show && card.hideable && renderCard(card, i);
            })}
          </div>
        </div>
        <div className="border-t py-4 px-10 flex flex-row justify-end">
          <button
            className="font-bold text-sm text-custom-sky"
            onClick={() => {
              handleRemoveAllSelected(cards);
            }}
          >
            Remove All Columns
          </button>
          <Button
            classes="border-2 font-bold tracking-widest text-sm border-blue-900 mx-3"
            bgColor="transparent"
            hoverColor="bule-200"
            roundedSize="full"
            textColor="blue-900"
            px={6}
            py={2}
            shadow=""
            textSize=""
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            classes="border-0 font-bold tracking-widest"
            bgColor="blue-900"
            hoverColor="blue-500"
            roundedSize="full"
            textColor="white"
            px={6}
            py={2}
            shadow=""
            textSize=""
            onClick={() => onSavingUserSetting(cards)}
          >
            apply
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomizeColumnModal;
