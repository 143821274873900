import axios from 'axios';
import moment from 'moment-timezone';
import { keys, upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import DateRangePicker from 'components/DateRangePicker';

import {
  Timesheet,
  HoursWorked,
  HubstaffCard,
  TaskTimeLogs,
  RecentActivity,
  HubstaffConnect,
  HubstaffSyncInfo,
} from 'features/hubstaff/components';

import { currencyFormatter } from 'utils/formatters';
import { getPreviousDateRangeAdvance } from 'utils/dateRange';

const Hubstaff = () => {
  const { user } = useSelector((state) => state.auth);

  const [records, setRecords] = useState([]);
  const [dateRange, setDateRange] = useState({
    endDate: moment().endOf('week').add(1, 'day').format('YYYY-MM-DD'),
    startDate: moment().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const data = await Promise.all(
        [
          { ...dateRange },
          {
            ...getPreviousDateRangeAdvance(
              dateRange.startDate,
              dateRange.endDate
            ),
          },
        ].map(async ({ startDate, endDate }) => {
          const response = await axios.get('/hubstaff/activities/summary', {
            params: {
              hubstaffProfileId: user.hubstaffProfile?.id,
              timeSlotStartDate: moment
                .tz(startDate, 'America/New_York')
                .startOf('D')
                .format(),
              timeSlotEndDate: moment
                .tz(endDate, 'America/New_York')
                .endOf('D')
                .format(),
            },
          });

          const records = await axios.get('/hubstaff/activities/records', {
            params: {
              timeSlotEndDate: endDate,
              timeSlotStartDate: startDate,
              hubstaffProfileId: user.hubstaffProfile?.id,
            },
          });

          return { ...response.data.data, records: records.data.data };
        })
      );

      if (isSubscribed) {
        setRecords(data);
      }
    };

    if (user.hubstaffProfile) {
      fetchData().catch(console.error);
    }

    return () => (isSubscribed = false);
  }, [dateRange, user]);

  return user.hubstaffProfile ? (
    <div className="">
      <div className="grid grid-cols-3 gap-5">
        <HubstaffSyncInfo
          hubstaffProfile={user.hubstaffProfile}
          className="col-span-2"
        />

        <div className="col-start-3">
          <DateRangePicker
            showLabel={false}
            startDate={moment(dateRange.startDate).toDate()}
            endDate={moment(dateRange.endDate).toDate()}
            onChange={setDateRange}
          />
        </div>
      </div>

      <div className="grid sm:grid-cols-2 divide-y sm:divide-y-0 sm:divide-x lg:grid-cols-4 mt-5 bg-white gap-5 py-5">
        {[
          {
            title: 'Activity',
            key: 'overallPercentage',
            formatter: (value) => `${parseInt(value * 100)}%`,
          },
          {
            title: 'Earned',
            key: 'earnedSummation',
            formatter: () => currencyFormatter(0),
          },
          {
            title: 'Worked',
            key: 'trackedSummation',
            formatter: (value) =>
              moment.duration(value, 'seconds').format('hh:mm:ss'),
          },
          {
            title: 'Projects Worked',
            key: 'projectsSummation',
            formatter: (value) => value,
          },
        ].map((card) => (
          <HubstaffCard
            id={card.key}
            key={card.key}
            title={card.title}
            formatter={card.formatter}
            currentValue={records.length ? records[0][card.key] : 0}
            previousValue={records.length ? records[1][card.key] : 0}
            records={
              !records.length
                ? []
                : records[0].records.map((record, index) => {
                    let obj = {
                      ...record,
                      earnedSummation: 0,
                      previousEarnedSummation: 0,
                    };

                    keys(record).map((key) => {
                      let prev = 0;

                      if (records[1].records[index]) {
                        prev = records[1].records[index][key];
                      }

                      obj[`previous${upperFirst(key)}`] = prev;
                    });

                    return obj;
                  })
            }
          />
        ))}
      </div>

      <div className="grid grid-cols-2 gap-5 my-5">
        <RecentActivity
          endDate={dateRange.endDate}
          startDate={dateRange.startDate}
        />

        <Timesheet
          endDate={dateRange.endDate}
          startDate={dateRange.startDate}
        />
      </div>

      <HoursWorked records={records.length ? records[0].records : []} />

      <TaskTimeLogs
        endDate={dateRange.endDate}
        startDate={dateRange.startDate}
      />
    </div>
  ) : (
    <HubstaffConnect />
  );
};

export default Hubstaff;
