import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/solid';
import DropdownMenu from 'components/DropdownMenu';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import DatepickerSingle from 'components/DatepickerSingle';
import { setAlert } from 'features/alerts/alertsSlice';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'features/datePicker/DatePicker';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';
import { Menu } from '@headlessui/react';

const Exporter = ({ params }) => {
  const dispatch = useDispatch();
  const { isAgencySuperUser } = usePermissions();
  const [loading, setLoading] = useState(false);
  const selectedDates = useSelector(selectCurrentDateRange);
  const [showCalHideBtn, setShowCalHideBtn] = useState(false);

  const convertCsv = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/agency/invoices/errors`, {
        params: {
          ...params,
          startDate: selectedDates.startDate,
          endDate: selectedDates.endDate,
          isExport: true,
        },
      });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'commission error.csv';
      link.click();
    } catch (error) {
      dispatch(
        setAlert('error', 'No Invoice found for the selected invoice date')
      );
    }

    setLoading(false);
  };

  //   const calenderHandler = async () => {
  //     if (selectedDate) {
  //       setLoading(true);
  //       setShowCalHideBtn(!showCalHideBtn);

  //       await convertCsv();
  //       setLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     calenderHandler();
  //   }, [selectedDate]);

  return (
    <div className="sm:col-span-2">
      <DropdownMenu
        title={
          isAgencySuperUser() && (
            <Button
              classes={'rounded-full'}
              border=" border-2 border-blue-900 tracking-widest font-bold py-2.5"
              hoverColor="blue-100"
              textColor="blue-900"
              bgColor="bg-white"
              onClick={() => {
                setShowCalHideBtn(!showCalHideBtn);
              }}
              showLoading={loading}
            >
              {loading ? (
                <Spinner color="blue" />
              ) : (
                <>
                  <PlusIcon className="h-4 w-4 mr-2" /> Export by Date
                </>
              )}
            </Button>
          )
        }
        titleClasses="flex items-center mr-1"
        buttonBg="bg-transparent"
        buttonFontWeight="font-normal"
        hoverClasses="bg-none"
        textColor="text-cyan-600"
        classes="text-sm  rounded-2xl"
        buttonRounded=""
        hoverText="hover:text-cyan-500"
        dropdownWidth="w-88"
        padding=""
        position="absolute"
        hideArrow
        zIndex=""
      >
        <div className="px-1 py-1 flex flex-col">
          <Menu.Item>
            {({ active }) => (
              <>
                <DatePicker showLabel={false} />
                <div className="text-right px-5 pb-2">
                  <Button
                    classes="border-0 font-bold tracking-widest"
                    bgColor="red-600"
                    hoverColor="red-700"
                    roundedSize="full"
                    textColor="white"
                    px={4}
                    py={1}
                    shadow=""
                    type="button"
                    loading={loading}
                    onClick={convertCsv}
                    showLoading={true}
                  >
                    Export
                  </Button>
                </div>
              </>
            )}
          </Menu.Item>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default Exporter;
