import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';

const useAlert = () => {
  const dispatch = useDispatch();

  const alert = (status, title, message, duration, link) => {
    dispatch(setAlert(status, title, message, duration, link));
  };

  const alertSuccess = (title, message, duration) => {
    alert('success', title, message, duration);
  };

  const alertError = (title, message, duration) => {
    alert('error', title, message, duration);
  };

  return {
    alertSuccess,
    alertError,
    alert,
  };
};

export default useAlert;
