import { Avatar } from 'components';
import { getNameInitials } from 'utils/formatters';

const UserAvatar = ({ user }) => {
  return (
    <div className="flex items-center space-x-2">
      <Avatar
        size="36px"
        initials={getNameInitials(user.firstName, user.lastName)}
      />
      <span className="text-sm">
        {user.firstName}
        <br />
        {user.lastName}
      </span>
    </div>
  );
};

export default UserAvatar;
