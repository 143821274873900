import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'utils/classNames';
import { fetchRestrictedKeywords } from './mockupSlice';
import { useFormikContext } from 'formik';

const BodyCopy = forwardRef(({ name, prop, maxLength = 250 }, ref) => {
  const { values, setFieldValue } = useFormikContext();

  useImperativeHandle(ref, () => ({
    validate() {
      return searchFor();
    },
  }));

  const dispatch = useDispatch();
  const [errorKeywords, setErrorKeywords] = useState([]);
  const [contentLength, setContentLength] = useState(0);
  const qRef = useRef();
  const { restrictedKeywords } = useSelector((state) => state.mockup);

  useEffect(() => {
    if (!restrictedKeywords) {
      dispatch(fetchRestrictedKeywords());
    }
  }, [restrictedKeywords]);

  useEffect(() => {
    const length = qRef.current.editor.getLength();
    setContentLength(length);
  }, [values[prop][name]]);

  const handleChange = (content, delta, source, editor) => {
    setFieldValue(`${prop}.${name}`, content);
  };

  const modules = {
    //toolbar: [[{ color: [] }, { background: [] }], ['clean']],
    toolbar: false,
  };

  const formats = ['color', 'background'];

  const textContents = () => {
    return qRef.current.editor.getContents().ops.reduce((text, op) => {
      if (typeof op.insert === 'string') {
        return text + op.insert;
      } else {
        return text + '\n';
      }
    }, '');
  };

  const clear = () => {
    const contents = textContents().toLowerCase();
    qRef.current.editor.removeFormat(0, contents.length - 1);
  };

  const searchFor = async () => {
    qRef.current.editor.deleteText(maxLength - 1, contentLength - maxLength);
    let matches = [];
    const contents = textContents().toLowerCase();
    qRef.current.editor.removeFormat(0, contents.length - 1);
    restrictedKeywords.rows?.forEach((restrictedKeyword) => {
      const term = restrictedKeyword.keyword
        .trim()
        .toLowerCase()
        .replace(/\$/gi, '\\$');

      const match = [...contents.matchAll(new RegExp(term, 'gi'))].map((a) => {
        return {
          index: a.index,
          keyword: restrictedKeyword.keyword.trim(),
        };
      });

      if (match.length > 0) {
        match.forEach((m) => {
          matches.push(m);
        });
      }

      matches.forEach((text) => {
        qRef.current.editor.formatText(text.index, text.keyword.length, {
          background: 'rgba(255,175,175,1)',
        });
      });
    });

    if (contents.length - 1 > 250) {
      qRef.current.editor.formatText(250, contents.length - 1, {
        background: 'rgba(240,128,128)',
      });
    }

    //function to remove duplicate keyword
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    const errors = getUniqueListBy(matches, 'keyword');
    setErrorKeywords(errors);

    return errors;
  };

  return (
    <div className="w-full">
      <div className="quill-body-copy">
        <ReactQuill
          ref={qRef}
          theme="bubble"
          modules={modules}
          formats={formats}
          placeholder=""
          value={values[prop][name]}
          onChange={handleChange}
          onBlur={searchFor}
          bounds={`.quill-body-copy`}
        />
      </div>

      <div>
        <p
          className={classNames(
            contentLength >= maxLength ? ' text-red-600' : 'text-gray-400',
            'font-normal text-xs tracking-tighter'
          )}
        >
          {contentLength} / {maxLength}
        </p>
      </div>
      <div className="flex space-x-2">
        {errorKeywords.length > 0 ? (
          <div className="text-red-600 font-normal text-sm">
            You used a restricted keyword:&nbsp;
            {errorKeywords.map((k, index) => (
              <Fragment key={index}>
                {errorKeywords.length > 1 &&
                  errorKeywords.length === index + 1 && (
                    <span>&nbsp;&amp;&nbsp;</span>
                  )}
                <span className="font-bold">{k.keyword}</span>
                {index < errorKeywords.length - 1 && <span>,&nbsp;</span>}
              </Fragment>
            ))}
            . Please change it.
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <button onClick={clear}>Clear</button> */}
    </div>
  );
});

export default BodyCopy;
