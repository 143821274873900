import { useSelector } from 'react-redux';

const SuperLogs = () => {
  const { invoice } = useSelector((state) => state.invoice);

  return (
    <div className="text-sm mt-20 pt-4 border-t border-gray-100">
      <div className="flex flex-col">
        <h4 className="mb-1 font-inter uppercase">Custom Fields</h4>
        <span className="text-xs">
          Pause Email:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_pause_email}</b>
        </span>
        <span className="text-xs">
          Pause Collect:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_pause_collect}</b>
        </span>
        <span className="text-xs">
          Charge Admin Fee:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_charge_admin_fee ?? 'no'}</b>
        </span>
        <span className="text-xs">
          Commission Added:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_commission_added}</b>
        </span>
        <span className="text-xs">
          Account Id:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_bs_account_id}</b>
        </span>
        <span className="text-xs">
          Customer Id:&nbsp;&nbsp;
          <b>{invoice.customer_id}</b>
        </span>
        <span className="text-xs">
          Upsell:&nbsp;&nbsp;
          <b>{invoice.custom_field_hash.cf_upsell}</b>
        </span>
      </div>
    </div>
  );
};

export default SuperLogs;
