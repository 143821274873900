import axios from 'axios';
import { XIcon } from '@heroicons/react/outline';
import TaskStatusDropdown from './TaskStatusDropdown';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import PriorityDropdown from './PriorityDropdown';
import { useSelector } from 'react-redux';
import classNames from 'utils/classNames';
import TaskDueDateDropdown from './TaskDueDateDropdown';
import { useEffect, useState } from 'react';
import { isFunction, pick } from 'lodash';
import ClientsListSelect from 'components/ClientsListSelect';
import TaskType from './TaskType';

const NewTask = ({
  taskListId,
  isSubtask = false,
  parentTaskItemId = null,
  cancel,
  created,
  status = 'To Do',
  subtaskSpacer = 'px-6',
  additionalData = {},
  statusOptions = [],
  assigneeOptions = [],
  taskListOptions = [],
  withTaskType = false,
}) => {
  const [row, setRow] = useState({
    id: 0,
    status,
    name: '',
    assignees: [],
    priority: 'none',
    dueDate: null,
    parentTaskItemId,
    taskListId,
    type: null,
    ...additionalData,
  });

  const onUpdateAssignee = async (user, action) => {
    let assignees = [...row.assignees];

    assignees =
      action === 'add'
        ? [...assignees, user]
        : assignees.filter((a) => a.id !== user.userId);
    setRow({ ...row, assignees });
  };

  const onEditTitle = (e) => {
    if (e.key === 'Escape') {
      cancel();
    } else {
      setRow({ ...row, name: e.target.value });
    }
  };

  const onChangeStatus = ({ name }) => {
    setRow({ ...row, status: name });
  };

  const onChangePriority = (priority) => {
    setRow({ ...row, priority: priority ? priority.status : null });
  };

  const onChangeType = (type) => {
    setRow({ ...row, type });
  };

  const onChangeDueDate = (dueDate) => {
    setRow({ ...row, dueDate });
  };

  const onSelectClient = (client) => {
    setRow({ ...row, taskListId: client.taskListId });
  };

  const onSave = () => {
    let payload = pick(row, [
      'name',
      'priority',
      'dueDate',
      'parentTaskItemId',
      'taskListId',
      'status',
      'checklists',
      'type',
    ]);

    payload.assigneeIds = row.assignees.map((user) => user.userId);

    axios.post('/agency/tasks/items', payload).then((response) => {
      isFunction(created) && created(response.data.data);
    });
  };

  return (
    <>
      <div
        className={classNames(
          isSubtask ? 'list-group-col-sub py-2' : 'list-group-col',
          'text-gray-500 group border border-red-200'
        )}
      >
        {!taskListId && (
          <div className="px-11 col-span-12 text-sm flex">
            <span className={classNames(isSubtask ? subtaskSpacer : 'px-3')}>
              &nbsp;
            </span>
            <ClientsListSelect
              clients={taskListOptions}
              keyField="taskListId"
              onChange={onSelectClient}
              selectedId={row.taskListId}
            />
          </div>
        )}
        <div className="col-span-8 flex items-center justify-start space-x-1 pl-4 sticky left-0">
          <span className={classNames(isSubtask ? subtaskSpacer : 'px-3')}>
            &nbsp;
          </span>
          <TaskStatusDropdown
            id="new-task"
            value={row.status}
            setValue={onChangeStatus}
            options={statusOptions}
          />
          <input
            type="text"
            placeholder={isSubtask ? 'New Subtask' : 'New Task'}
            defaultValue={row.title}
            className={classNames(
              isSubtask && 'ml-2',
              'border-0 w-auto 2xl:w-full focus:ring-white focus:border-white p-0 text-red-500 placeholder-gray-300 text-sm'
            )}
            onKeyUp={onEditTitle}
            autoFocus
          />
        </div>
        <div className="col-span-4 flex items-center justify-end space-x-2 pr-4">
          <UsersPopoverMultiple
            users={assigneeOptions}
            position="left-1/2 transform -translate-x-1/2"
            onChange={onUpdateAssignee}
            selectedIds={row.assignees.map((user) => user.userId)}
          />
          <div
            className={classNames(
              !row.dueDate &&
                'border border-dashed border-gray-400 rounded-3xl flex items-center justify-center w-8 h-8',
              'px-0.5'
            )}
          >
            <TaskDueDateDropdown
              value={row.dueDate}
              setValue={onChangeDueDate}
              iconClassname="w-5 h-5 relative top-0.5"
              titleClasses={!row.dueDate ? '' : 'text-red-500'}
            />
          </div>
          <div
            className={classNames(
              !row.priority &&
                'border border-dashed border-gray-400 rounded-3xl flex items-center justify-center w-8 h-8',
              'px-0.5'
            )}
          >
            <PriorityDropdown
              value={row.priority}
              setValue={onChangePriority}
            />
          </div>

          {withTaskType && (
            <TaskType value={row.type} setValue={onChangeType} />
          )}

          <button
            className="bg-green-500 text-xs text-white uppercase rounded-md py-1 px-2 z-1"
            onClick={onSave}
            disabled={!!!(row.name && row.taskListId)}
          >
            save
          </button>
          <button onClick={() => cancel()} className="z-1">
            <XIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NewTask;
