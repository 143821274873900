import { useMemo } from 'react';
import usFlag from 'assets/flags/us.png';
import mxFlag from 'assets/flags/mx.png';
import caFlag from 'assets/flags/ca.png';
import brFlag from 'assets/flags/br.png';

const MarketplaceFlag = ({ marketplace }) => {
  const flag = useMemo(() => {
    switch (marketplace.countryCode) {
      case 'US':
        return usFlag;
      case 'CA':
        return caFlag;
      case 'MX':
        return mxFlag;
      case 'BR':
        return brFlag;
      default:
        return null;
    }
  }, [marketplace]);

  return (
    <div className="flex items-center space-x-2">
      <span
        className="rounded-full flex overflow-hidden ring-1 ring-gray-100"
        style={{ width: '24.5px', height: '20px' }}
      >
        {flag && (
          <img
            className="w-full object-cover"
            src={flag}
            alt={marketplace.name}
          />
        )}
      </span>
      <label>{marketplace.countryCode}</label>
    </div>
  );
};
export default MarketplaceFlag;
