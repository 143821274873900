import axios from 'axios';
import { useState } from 'react';

import {
  STATUSES,
  AGENCY_CLIENT_ACCOUNT_STATUSES,
  AGENCY_CLIENT_STATUSES,
  AGENCY_CLIENT_SERVICES,
} from 'utils/constants';

import { SUBSCRIPTION_STATUSES } from 'utils/subscriptions';

const useClient = () => {
  const [clients, setClients] = useState({ loading: true, data: [] });

  const getClients = async (params) => {
    setClients({ loading: true, data: [] });

    const { data: response } = await axios.get('/v2/agency/clients', {
      params,
    });

    setClients({ loading: false, data: response.data });

    return response;
  };

  const getClientList = async (params) => {
    const { data: response } = await axios.get('/v2/agency/clients/list', {
      params,
    });

    return response;
  };

  return {
    clients,
    getClients,
    getClientList,
    STATUSES,
    STATUS_LIST: AGENCY_CLIENT_STATUSES.map((status) => ({
      label: status,
      value: status,
    })),
    SERVICES: AGENCY_CLIENT_SERVICES.map((service) => ({
      label: service,
      value: service,
    })),
    ACCOUNT_STATUSES: AGENCY_CLIENT_ACCOUNT_STATUSES.map((status) => ({
      label: status,
      value: status,
    })),
    SUBSCRIPTION_STATUSES: SUBSCRIPTION_STATUSES.map((status) => ({
      label: status,
      value: status,
    })),
  };
};

export default useClient;
