import usePermissions from 'hooks/usePermissions';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
  Cell,
} from 'recharts';
import { numberFormatter } from 'utils/formatters';

const MetricsGraph = ({ graphData }) => {
  const { userCan, isAgencySuperUser } = usePermissions();

  return (
    <>
      {(userCan('sales.metrics.director') || isAgencySuperUser()) && (
        <>
          {/* <div className="flex flex-col mt-12 border border-gray-300 bg-white shadow hover:shadow-xl rounded-xl"> */}
          <div className="grid grid-cols-2 gap-4 px-4 sm:px-6 py-6">
            <div className="flex flex-col mt-12 border border-gray-300 bg-white shadow hover:shadow-xl rounded-xl h-fit">
              <div className="flex flex-row py-6 px-5 border-b border-slate-300">
                <div>
                  <div>
                    <p className="font-bold text-black tracking-3/4 text-2xl leading-6 pb-2">
                      Monthly Conversion Rate
                    </p>
                  </div>
                </div>
              </div>

              <div className="h-80 pr-4 flex flex-col justify-center">
                <ResponsiveContainer width={'100%'} height="75%" debounce={50}>
                  <LineChart data={graphData}>
                    <CartesianGrid />
                    <XAxis dataKey="month" style={{ fontSize: '0.75rem' }} />
                    <YAxis
                      tickFormatter={(tick) => `${tick * 100}%`}
                      style={{ fontSize: '0.75rem' }}
                    />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Line
                      type="linear"
                      dataKey="conversionRate"
                      stroke="#FFB6C1"
                      activeDot={{ r: 8 }}
                      name="Conversion Rate"
                      dot={{ stroke: '#FFB6C1', strokeWidth: 2 }}
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="flex flex-col mt-12 border border-gray-300 bg-white shadow hover:shadow-xl rounded-xl h-fit">
              <div className="flex flex-row justify-between py-6 px-5 border-b border-slate-300">
                <div>
                  <div>
                    <p className="font-bold text-black tracking-3/4 text-2xl leading-6 pb-2">
                      Month Closed Deals
                    </p>
                  </div>
                </div>
              </div>

              <div className="h-80 pr-6 flex flex-col justify-center">
                <ResponsiveContainer width={'100%'} height="75%" debounce={50}>
                  <BarChart margin={{ top: 15 }} data={graphData}>
                    <CartesianGrid vertical={false} />

                    <XAxis dataKey="month" style={{ fontSize: '0.75rem' }} />

                    <YAxis
                      tickFormatter={(value) => numberFormatter(value)}
                      style={{ fontSize: '0.75' }}
                    />

                    <Bar
                      dataKey="totalClosedDeals"
                      fill="#FF89A6"
                      isAnimationActive={false}
                    >
                      <LabelList
                        dataKey="totalClosedDeals"
                        position="top"
                        style={{
                          fontWeight: '500',
                          color: '#acacac',
                          fontSize: '1rem',
                        }}
                        formatter={(label) => {
                          return label > 0 ? label : null;
                        }}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      )}
    </>
  );
};

export default MetricsGraph;
