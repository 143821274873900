import { useState } from 'react';
import useAlert from './useAlert';
import axios from 'axios';

const usePdf = () => {
  const { alert, alertError } = useAlert();
  const [downloading, setDownloading] = useState(false);
  const [previewing, setPreviewing] = useState(false);

  const onDownloadPdf = async (url, filename) => {
    alert('info', 'Processing', 'Saving PDF...please wait', 3000);
    setDownloading(true);
    await axios
      .get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.pdf`;
        link.click();
      })
      .catch((error) =>
        alertError('Failed to download pdf', error.response.data.message)
      )
      .finally(() => setDownloading(false));
  };

  const onPreviewPdf = async (url) => {
    alert('info', 'Processing', 'Opening in new tab...please wait', 3000);
    setPreviewing(true);
    await axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      })
      .catch((error) =>
        alertError('Failed to preview', error.response.data.message)
      )
      .finally(() => setPreviewing(false));
  };

  return {
    onDownloadPdf,
    onPreviewPdf,
    downloading,
    previewing,
  };
};

export default usePdf;
