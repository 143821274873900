const Overview = ({
  title,
  totalClients,
  totalTerminatedClients,
  totalServiceAsins,
}) => {
  return (
    <div className="bg-white my-5 grid grid-cols-4 py-8 px-5">
      <div>
        <p className="text-grayscale-900 font-inter font-bold tracking-3/4 leading-1.2">
          {title}
        </p>
      </div>
      <div>
        <p className="text-13 leading-1.5 text-grayscale-700">Total Accounts</p>
        <p className="tracking-1 font-intex text-32 mt-2 leading-1.2 text-grayscale-800">
          {totalClients}
        </p>
      </div>
      <div>
        <p className="text-13 leading-1.5 text-grayscale-700">
          Terminated Accounts
        </p>
        <p className="tracking-1 font-intex text-32 mt-2 leading-1.2 text-grayscale-800">
          {totalTerminatedClients}
        </p>
      </div>
      <div>
        <p className="text-13 leading-1.5 text-grayscale-700">
          Number of ASINs
        </p>
        <p className="tracking-1 font-intex text-32 mt-2 leading-1.2 text-grayscale-800">
          {totalServiceAsins}
        </p>
      </div>
    </div>
  );
};

export default Overview;
