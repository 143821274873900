import axios from 'axios';
import { metricColumns } from 'features/advertising/utils/columns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrashIcon } from '@heroicons/react/outline';
import { ChartBarIcon } from '@heroicons/react/solid';

import NavigationTab from '../components/NavigationTab';

import AnalyticsOverview from '../sections/AnalyticsOverview';
import MetricsChart from '../sections/MetricsChart';
import { setAlert } from 'features/alerts/alertsSlice';
import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import TrendsModal from '../../components/TrendsModal/TrendsModal';
import FormModal from '../components/FormModal';
import DatePicker from 'features/datePicker/DatePicker';
import ExportButton from '../components/ExportButton';

const NonBrandedKeyword = () => {
  const dispatch = useDispatch();
  const { account, marketplace } = useSelector((state) => state.account);
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [changed, setChanged] = useState(false);
  const [selected, setSelected] = useState({});
  const [openTrends, setOpenTrends] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState({ keywordText: '', matchType: 'exact' });

  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('non-branded-keywords-column') ??
      'id,keywordText,matchType,cost,sales,orders,'
  );

  const [keywords, setKeywords] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    sort: 'cost',
    include: ['previousData'],
    attributes: [
      'id',
      'keywordText',
      'matchType',
      'sales',
      'cost',
      'cpc',
      'roas',
      'acos',
      'impressions',
      'clicks',
      'ctr',
      'orders',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        `/account/advertising/non-branded-keywords`,
        {
          params: {
            accountId: account.accountId,
            marketplace: marketplace.details.countryCode,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            ...params,
          },
        }
      );

      if (isSubscribed) {
        setKeywords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, dateRange, params, changed]);

  const deleteItem = (id) => {
    axios
      .delete(`/account/advertising/non-branded-keywords/${id}`, {
        data: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
        },
      })
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
        setChanged(!changed);
      });
  };

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('non-branded-keywords-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const toggleForm = (value) => {
    if (!value) {
      setForm({ keywordText: '', matchType: 'exact' });
    }
    setOpenForm(value);
  };

  const onSubmit = () => {
    setSubmitting(true);

    const data = {
      accountId: account.accountId,
      marketplace: marketplace.details.countryCode,
      ...form,
    };

    axios
      .post('/account/advertising/non-branded-keywords', data)
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
        setOpenForm(false);
        setSelected({});
        setForm({ keywordText: '', matchType: 'exact' });
        setChanged(!changed);
      })
      .catch((error) =>
        dispatch(setAlert('error', error.response.data.message))
      )
      .finally(() => setSubmitting(false));
  };

  const columns = [
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'keywordText',
      text: 'Keyword',
      sort: true,
      headerStyle: { minWidth: '275px' },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
    },
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'matchType',
      text: 'Match Type',
      sort: true,
      headerStyle: { minWidth: '200px' },
      classes: 'text-center',
      headerClasses: 'text-center',
    },
    ...metricColumns(),
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'id',
      text: 'Action',
      sort: true,
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <div className="flex justify-center">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={() => {
                setSelected(row);
                setOpenTrends(true);
              }}
            />

            <TrashIcon
              className="w-5 h-5 text-secondary cursor-pointer"
              onClick={() => deleteItem(cell)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="mb-8">
      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-3">
          <DatePicker position="left" showLabel={false} />
        </div>

        <ExportButton
          className="col-start-8"
          type="non-branded"
          params={{
            ...params,
            accountId: account.accountId,
            marketplace: marketplace.details.countryCode,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }}
        />
      </div>

      <TrendsModal
        title={selected.keywordText}
        open={openTrends}
        setOpen={(value) => {
          setSelected({});
          setOpenTrends(value);
        }}
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selected}
        url={
          selected.id
            ? `/account/advertising/non-branded-keywords/${selected.id}/records`
            : null
        }
      />

      <FormModal
        form={form}
        open={openForm}
        updateForm={setForm}
        setOpen={toggleForm}
        loading={submitting}
        title="Create Non-Branded Keyword"
        onSubmit={onSubmit}
      />

      <AnalyticsOverview
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        type="non-branded"
        changed={changed}
      />

      <MetricsChart
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        type="non-branded"
        changed={changed}
      />

      <NavigationTab />

      <AdvertisingTable
        params={params}
        list={keywords}
        columns={columns}
        accountId={account.accountId}
        recordType={'non-branded-keywords'}
        keyField="id"
        marketplace={marketplace.details.countryCode}
        onChangeVisibleColumns={onChangeVisibleColumns}
        visibleColumns={visibleColumns}
        showSearch={false}
        showSmartFilters={false}
        showExport={false}
        customClass="mb-4 grid grid-cols-1 gap-4 grid-cols-5"
        columnPickerClassName="col-start-4"
        onChangeParams={setParams}
      >
        <button
          onClick={() => setOpenForm(true)}
          className={`col-start-5 inline-flex justify-center w-full rounded-2xl border-0 shadow-xs px-4 py-4 bg-white text-mini text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none`}
        >
          Add Keyword
        </button>
      </AdvertisingTable>
    </div>
  );
};

export default NonBrandedKeyword;
