import usePermissions from 'hooks/usePermissions';
import { NavLink, useRouteMatch } from 'react-router-dom';
import classNames from 'utils/classNames';

const Navigation = () => {
  const { url } = useRouteMatch();
  const { userCan } = usePermissions();

  const tabs = [
    {
      name: 'Overview',
      href: '/employees',
      visible: userCan('employees.list'),
      exact: true,
    },
    {
      name: 'Organizational Chart',
      href: '/employees/organization-chart',
      exact: true,
      visible: userCan('employees.orgChart.view'),
    },
    {
      name: 'Roles',
      href: '/employees/roles',
      exact: true,
      visible: userCan('roles.list'),
    },
  ];

  return (
    <div className="border-b border-grayscale-500 mt-4">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs
          .filter((tab) => tab.visible)
          .map((tab) => (
            <NavLink
              to={tab.href}
              key={tab.name}
              exact={tab.exact}
              className={classNames(
                'flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent',
                url === tab.href ? 'text-secondary-light' : 'text-grayscale-800'
              )}
              activeClassName="border-secondary-light font-bold text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
      </nav>
    </div>
  );
};

export default Navigation;
