import { useState } from 'react';
import { pick, cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'components/Forms';
import { setUser } from 'features/auth/authSlice';

import useAuth from 'hooks/useAuth';
import usePermissions from '../usePermissions';
import usePermissionsHook from 'hooks/usePermissions';

import PermissionItem from './PermissionItem';

const PermissionCategory = ({ roleId, category, permissions = [] }) => {
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { updatePermissions } = usePermissions();
  const { userCan, isAgencySuperUser } = usePermissionsHook();

  const [records, setRecords] = useState(permissions);

  const onChange = async (e) => {
    const { checked } = e.target;

    const newRecords = records.map((permission) => ({
      ...permission,
      enabled: checked,
    }));

    await updatePermissions(
      roleId,
      newRecords.map((record) => pick(record, ['permissionId', 'enabled']))
    );

    setRecords(newRecords);

    if (parseInt(user.role.roleId) === parseInt(roleId)) {
      const userCopy = cloneDeep({ ...user });

      let newPermissions = [...userCopy.role.permissions];

      if (checked) {
        permissions.forEach((permission) => {
          if (!newPermissions.find((p) => p.access === permission.access)) {
            newPermissions.push({
              access: permission.access,
              feature: permission.feature,
            });
          }
        });
      } else {
        newPermissions = newPermissions.filter(
          (permission) =>
            !permissions.find((p) => p.access === permission.access)
        );
      }

      userCopy.role.permissions = newPermissions;

      dispatch(setUser(userCopy));
    }
  };

  return (
    <div className="bg-white rounded-md drop-shadow-lg">
      <div className="flex border-b border-grayscale-500 p-4 items-center justify-between">
        <h4 className="font-inter tracking-3/4 text-grayscale-900 font-bold">
          {category}
        </h4>

        <Checkbox
          disabled={
            isAgencySuperUser()
              ? false
              : userCan('admin.roles.permisions.update')
          }
          id={category}
          onChange={onChange}
          checked={records.every((permission) => permission.enabled)}
        />
      </div>

      <ul className="space-y-4 p-4">
        {records.map((permission) => (
          <PermissionItem
            key={permission.permissionId}
            roleId={roleId}
            data={permission}
          />
        ))}
      </ul>
    </div>
  );
};

export default PermissionCategory;
