import React, { Fragment } from 'react';
import classNames from 'utils/classNames';
import { currencyFormatter } from 'utils/formatters';

const ZohoPlanAddonsTable = ({ subscription }) => {
  const Th = ({ children, className }) => {
    return (
      <th
        scope="col"
        className={classNames(
          'px-4 py-2 text-xs font-normal uppercase text-gray-500 tracking-wider',
          className ?? ''
        )}
      >
        {children}
      </th>
    );
  };

  const Td = ({ children, className, colspan = '' }) => {
    return (
      <td
        colSpan={colspan ?? ''}
        className={classNames('p-4 text-sm text-gray-900', className ?? '')}
      >
        {children}
      </td>
    );
  };

  return (
    <div className="overflow-auto">
      <table className="min-w-full divide-y divide-gray-200 table-fixed w-1024px">
        <thead className="bg-gray-100">
          <tr>
            <Th className="text-left w-5/12">Plan & Addon Details</Th>
            <Th className="text-right w-1/12">Qty</Th>
            <Th className="text-right w-2/12">Rate</Th>
            <Th className="text-right w-2/12">Tax</Th>
            <Th className="text-right w-3/12">Amount</Th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <Td>
              {subscription.plan.name}
              <br />
              <span className="text-xs">{subscription.plan.description}</span>
            </Td>
            <Td className="text-right">{subscription.plan.quantity}</Td>
            <Td className="text-right">
              {currencyFormatter(
                subscription.plan.price,
                subscription.currency_code
              )}
            </Td>
            <Td className="text-right">
              {subscription.plan.tax_percentage &&
              subscription.plan.tax_percentage > 0
                ? subscription.plan.tax_percentage
                : '-'}
            </Td>
            <Td className="text-right">
              {currencyFormatter(
                subscription.plan.total,
                subscription.currency_code
              )}
            </Td>
          </tr>
          {subscription.addons &&
            subscription.addons.map((addon, index) => {
              return (
                <tr className="bg-white" key={index}>
                  <Td>
                    {addon.name}
                    <br />
                    <span className="text-xs">{addon.description}</span>
                  </Td>
                  <Td className="text-right">{addon.quantity}</Td>
                  <Td className="text-right">
                    {currencyFormatter(addon.price, subscription.currency_code)}
                  </Td>
                  <Td className="text-right">
                    {addon.tax_percentage && addon.tax_percentage > 0
                      ? addon.tax_percentage
                      : '-'}
                  </Td>
                  <Td className="text-right">
                    {currencyFormatter(addon.total, subscription.currency_code)}
                  </Td>
                </tr>
              );
            })}
          <tr className="bg-white border-t pt-4">
            <Td className="text-right" colspan={4}>
              Sub Total
            </Td>
            <Td className="text-right" colspan={1}>
              {currencyFormatter(
                subscription.sub_total,
                subscription.currency_code
              )}
            </Td>
          </tr>

          {subscription.taxes &&
            subscription.taxes.map((tax, i) => {
              return (
                <tr className="bg-white" key={i}>
                  <Td className="text-right" colspan={4}>
                    {tax.tax_name}
                  </Td>
                  <Td className="text-right" colspan={1}>
                    {currencyFormatter(
                      tax.tax_amount,
                      subscription.currency_code
                    )}
                  </Td>
                </tr>
              );
            })}
          <tr className="border-t bg-gray-100 border-b">
            <td
              colSpan={4}
              className="px-4 py-1 text-right text-xs font-normal uppercase text-gray-500"
            >
              Total ({subscription.currency_code})
            </td>
            <td
              colSpan={1}
              className="px-4 py-1 text-right text-sm font-normal uppercase"
            >
              {currencyFormatter(
                subscription.amount,
                subscription.currency_code
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ZohoPlanAddonsTable;
