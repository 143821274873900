import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, array, boolean } from 'yup';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Label from 'components/Forms/Label';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PriceLabel from 'components/PriceLabel';
import Confirm from 'components/Confirm';
import { CheckIcon } from '@heroicons/react/outline';
import useAlert from 'hooks/useAlert';
import { fetchInvoice } from '../invoiceSlice';

const ApplyCreditsModal = ({ invoice, openCreditNotes, open, setOpen }) => {
  const dispatch = useDispatch();
  const { alertError, alertSuccess } = useAlert();
  const [saving, setSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({
    invoiceId: '',
    invoiceNumber: '',
    creditsToApply: null,
  });
  const [balance, setBalance] = useState(0);
  const [creditsApplied, setCreditsApplied] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const validationSchema = object().shape({
    invoiceId: string().required('Required'),
    invoiceNumber: string().required('Required'),
    creditsToApply: array()
      .of(
        object().shape({
          creditNoteId: number(),
          number: string(),
          creditnote_id: string(),
          creditsAvailable: number(),
          balance: number(),
          amount_applied: string(),
          updated: boolean(),
        })
      )
      .nullable(),
  });

  useEffect(() => {
    if (openCreditNotes && open) {
      setInitialValues({
        invoiceId: invoice.invoice_id,
        invoiceNumber: invoice.number,
        creditsToApply: openCreditNotes.map((c) => {
          return {
            creditNoteId: c.creditNoteId,
            number: c.zohoCreditNoteNumber,
            creditnote_id: c.zohoCreditNoteId,
            creditsAvailable: c.creditsAvailable,
            balance: c.creditsAvailable,
            amount_applied: 0,
            updated: false,
          };
        }),
      });
      setBalance(parseFloat(invoice.balance));
      setRemainingBalance(parseFloat(invoice.balance));
      setCreditsApplied(0);
    }
  }, [openCreditNotes, open]);

  const checkAppliedCredits = async (
    value,
    name,
    index,
    row,
    setFieldValue,
    values
  ) => {
    console.log('yeahh');
    let creditsToApply = value !== '' ? parseFloat(value) : 0;
    const totalAppliedCredits = values.creditsToApply
      ?.filter((i, x) => x !== index)
      .reduce(
        (a, b) =>
          a + parseFloat(b.amount_applied === '' ? 0 : b.amount_applied),
        0
      );

    let currentRemainingBalance = balance - totalAppliedCredits;

    if (creditsToApply > 0) {
      if (creditsToApply >= currentRemainingBalance) {
        if (creditsToApply >= row.balance) {
          //credits to apply is greater than or equal to row balance and greater than or equal remaining balance
          //row.balance is larger than remaining balance, apply remaining balance
          creditsToApply =
            row.balance > currentRemainingBalance
              ? currentRemainingBalance
              : row.balance;
        } else {
          creditsToApply = currentRemainingBalance;
        }
      } else {
        //credits to apply is less than remaining balance
        if (creditsToApply >= row.balance) creditsToApply = row.balance;
      }
    }

    const newTotalAppliedCredits =
      totalAppliedCredits + parseFloat(creditsToApply);

    setFieldValue(name, creditsToApply);
    setFieldValue(`creditsToApply.${index}.updated`, false);
    setCreditsApplied(newTotalAppliedCredits);
    setRemainingBalance(balance - newTotalAppliedCredits);
  };

  const onSubmit = async (values) => {
    setSaving(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/credits`,
        values
      );

      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('Credits applied successfully', res.data.data.message);
    } catch (error) {
      alertError('Failed to apply credits', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-24 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={`Apply credits to ${invoice.invoice_number}`}
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            handleBlur,
          }) => (
            <Form>
              <div
                className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto"
                style={{ height: '65vh' }}
              >
                <div className="col-span-2">
                  <FieldArray name="invoices">
                    {({ remove, push }) => (
                      <>
                        {openCreditNotes && (
                          <div className="flex flex-col border-t border-grayscale-500 pt-8">
                            <h4 className="font-bold">
                              Client Open Credit Notes
                            </h4>
                            <label className="text-gray-500 text-sm pt-1 mb-6">
                              Please select the open credit notes where you
                              would like to apply the credit amount.
                            </label>
                            <div className="border rounded-lg overflow-hidden">
                              <div className="grid grid-cols-6 bg-grayscale-400 text-sm text-grayscale-700 py-5">
                                <div className="col-span-2 px-6">
                                  Credit Note Number
                                </div>
                                <div className="col-span-2 text-right px-6">
                                  Balance
                                </div>
                                <div className="col-span-2 text-center">
                                  Actions
                                </div>
                              </div>

                              {values.creditsToApply.map(
                                (creditNote, index) => (
                                  <div
                                    className="grid grid-cols-6 disabled:opacity-30 items-center justify-between w-full text-left hover:bg-grayscale-300 text-grayscale-800  px-2 py-3 text-base rounded-md focus:ring-gray-300 focus:border-gray-200"
                                    key={creditNote.creditNoteId}
                                    value={creditNote.creditNoteId}
                                  >
                                    <p className="col-span-2 px-4">
                                      {creditNote.number}
                                    </p>
                                    <p className="col-span-2 text-right px-6">
                                      <PriceLabel
                                        price={parseFloat(
                                          creditNote.creditsAvailable
                                        )}
                                      />
                                    </p>
                                    <div className="col-span-2 text-center">
                                      <div className="relative">
                                        <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                          $
                                        </span>
                                        <Field
                                          name={`creditsToApply.${index}.amount_applied`}
                                          placeholder="0.0"
                                          className="form-input-g disabled:border-white disabled:opacity-30 text-left pl-10"
                                          type="number"
                                          onKeyPress={(e) => {
                                            e.which === 13 &&
                                              e.preventDefault();
                                          }}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `creditsToApply.${index}.updated`,
                                              true
                                            );
                                            handleChange(e);
                                          }}
                                          onBlur={(e) => {
                                            if (
                                              values.creditsToApply[index]
                                                .updated
                                            ) {
                                              checkAppliedCredits(
                                                e.target.value,
                                                e.target.name,
                                                index,
                                                creditNote,
                                                setFieldValue,
                                                values
                                              );
                                            }

                                            handleBlur(e);
                                          }}
                                        />
                                      </div>

                                      <ErrorMessage
                                        name={`creditsToApply.${index}.amount_applied`}
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </FieldArray>

                  <div className="grid grid-cols-2 pt-8">
                    <div className="col-span-1">
                      <Label classes="col-span-2 text-grayscale-700">
                        Invoice Balance:
                      </Label>
                      <p className="text-4xl flex items-start mt-2">
                        <span className="text-grayscale-600 text-3xl">$</span>
                        &nbsp;
                        <span className="text-grayscale-800">
                          {balance.toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="col-span-1">
                      <Label classes="col-span-2 text-grayscale-700">
                        Credits Applied:
                      </Label>
                      <p className="text-4xl flex items-start mt-2">
                        <span className="text-grayscale-600 text-3xl">$</span>
                        &nbsp;
                        <span className="text-grayscale-800">
                          {creditsApplied.toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 my-8 flex justify-between items-center">
                  <div className="space-x-8">
                    <ButtonLink
                      classes="tracking-2 font-bold"
                      color="grayscale-700"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-2"
                      bgColor="secondary-dark"
                      hoverColor="secondary"
                      roundedSize="full"
                      textColor="white"
                      px={6}
                      py={2}
                      shadow=""
                      type="button"
                      showLoading={true}
                      onClick={() => {
                        if (creditsApplied === 0) {
                          alertError('Cannot proceed', 'Please apply credits');
                        } else setIsOpenConfirm(true);
                      }}
                    >
                      Apply Credits
                    </Button>
                  </div>
                </div>
              </div>
              <Confirm
                title={`Are you sure you want to apply this credit amount of $${creditsApplied}?`}
                content={
                  <>
                    <div className="sm:grid grid-cols-2 text-center px-4 mb-8">
                      {values.creditsToApply
                        ?.filter((i) => i.amount_applied > 0)
                        .map((i) => (
                          <div className="col-span-1 flex items-center justify-center">
                            <CheckIcon className="w-4 h-4 text-success inline mr-1" />
                            <span className="text-grayscale-800">
                              {i.number}
                            </span>{' '}
                            <span className="text-xs mx-1">
                              &nbsp;&nbsp;&nbsp;
                            </span>
                            <span className="text-xs">$</span>
                            <span className="text-grayscale-900 font-bold">
                              {i.amount_applied}
                            </span>
                          </div>
                        ))}
                    </div>
                    <p className="text-sm leading-tight">
                      If you click&nbsp;
                      <span className="text-success-dark">apply</span>, the
                      credits will be automatically applied to the invoice
                    </p>
                  </>
                }
                open={isOpenConfirm}
                setOpen={setIsOpenConfirm}
                onOkClick={handleSubmit}
                onCancelClick={() => setIsOpenConfirm(false)}
                okText="Apply"
                showOkLoading={true}
                okLoading={saving}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ApplyCreditsModal;
