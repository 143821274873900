import axios from 'axios';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

const ClientRedirect = ({ history }) => {
  const { agencyClientId } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/agency/clients/${agencyClientId}`);

      const { data } = response.data.data;

      if (isSubscribed) {
        let redirectUrl =
          data.account && data.account.subscription
            ? `/clients/${agencyClientId}/dashboard`
            : `/clients/edit/${agencyClientId}`;

        if (data.status === 'registered' && data.ClientMigration) {
          redirectUrl = `/clients/migration/${agencyClientId}`;
        }

        history.push(redirectUrl);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [agencyClientId]);

  return !loading ? <p>Redirecting...</p> : <Loading />;
};

export default withRouter(ClientRedirect);
