import { Disclosure } from '@headlessui/react';

import { ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import ItemTemplate from './ItemTemplate';

const TaskGroup = ({ template, label, params = {}, layoutClass }) => {
  const [loading, setLoading] = useState(true);
  const [itemTemplates, setItemTemplates] = useState([]);

  const taskCountDisplay = useMemo(() => {
    if (!itemTemplates.length) return '';

    const count = itemTemplates.length;

    return `${count} task${count > 1 ? 's' : ''}`;
  }, [itemTemplates]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/tasks/items/templates', { params });

      if (isSubscribed) {
        setItemTemplates(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params]);

  return (
    <div className="">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              as="div"
              className={classNames(
                open ? '' : 'border-b border-grayscale-500',
                'font-inter py-1.5 tracking-3/4 font-bold leading-1.2 text-grayscale-900  grid grid-cols-7 gap-3 items-end'
              )}
            >
              <div
                className={classNames(
                  'flex items-center space-x-2',
                  layoutClass?.columns?.title
                )}
              >
                <div
                  className={classNames(
                    open ? 'rotate-90 transform' : '',
                    'cursor-pointer w-6 h-6 flex justify-center items-center border-2 border-grayscale-700 rounded-full'
                  )}
                >
                  <ChevronRightIcon className="w-3 h-3 text-grayscale-700" />
                </div>

                <p className="flex items-center space-x-2">
                  <span>{label}</span>
                  {taskCountDisplay && (
                    <span
                      className={classNames(
                        'text-11 leading-1.5 text-grayscale-700 font-normal'
                      )}
                    >
                      {taskCountDisplay}
                    </span>
                  )}
                </p>
              </div>

              {open && (
                <>
                  {layoutClass?.columns?.description && (
                    <p
                      className={classNames(
                        layoutClass?.columns?.description,
                        'text-11 leading-1.5 text-grayscale-700 font-normal'
                      )}
                    >
                      Description
                    </p>
                  )}

                  {layoutClass?.columns?.recurringConfig && (
                    <p
                      className={classNames(
                        layoutClass?.columns?.recurringConfig,
                        'text-11 leading-1.5 text-grayscale-700 font-normal'
                      )}
                    >
                      Recurring
                    </p>
                  )}

                  {layoutClass?.columns?.department && (
                    <p
                      className={classNames(
                        layoutClass?.columns?.department,
                        'text-11 leading-1.5 text-grayscale-700 font-normal'
                      )}
                    >
                      Department
                    </p>
                  )}

                  <p
                    className={classNames(
                      'text-11 text-center leading-1.5 text-grayscale-700 font-normal'
                    )}
                  >
                    Actions
                  </p>
                </>
              )}
            </Disclosure.Button>

            <Disclosure.Panel className="bg-white">
              {itemTemplates.length ? (
                <div className="text-13 text-grayscale-800">
                  {itemTemplates.map((itemTemplate) => (
                    <ItemTemplate
                      layoutClass={layoutClass}
                      key={itemTemplate.taskItemTemplateId}
                      itemTemplate={{ ...itemTemplate, listTemplate: template }}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-center text-13 text-grayscale-800 py-5">
                  No Items
                </p>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default TaskGroup;
