import React, { useState, useEffect } from 'react';
import usePermissions from 'hooks/usePermissions';
import Loading from 'components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { useParams } from 'react-router-dom';
import usFlag from 'assets/icons/usaFlagButton.png';
import caFlag from 'assets/icons/canadaFlagButton.png';
import facebookLogo from 'assets/icons/facebookLogo.png';
import googleLogo from 'assets/icons/googleLogo.png';
import webLogo from 'assets/icons/webLogo.png';
import linkedinLogo from 'assets/icons/linkedinLogo.png';

const MetricsGroup = ({ teamSalesData }) => {
  const { salesId } = useParams();
  const { userCan, isAgencySuperUser } = usePermissions();
  const user = useSelector(selectAuthenticatedUser);
  const [userId, setUserId] = useState(null);

  const flag = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return usFlag;
      case 'CA':
        return caFlag;

      default:
        break;
    }
  };

  const sourceIcon = (name) => {
    switch (name) {
      case 'Facebook':
        return facebookLogo;
      case 'LinkedIn':
        return linkedinLogo;
      case 'Website':
        return webLogo;
      case 'Google Ads':
        return googleLogo;
      default:
        break;
    }
  };

  useEffect(() => {
    if (salesId) {
      setUserId(salesId);
    } else {
      setUserId(user.userId);
    }
  }, [salesId, user]);

  const sourceTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Source
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Booked Calls
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                No Show
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.group.map((r, gIdx) => {
                return userId === r.userId ? (
                  r.current.source.map((g, gIdx) => {
                    return (
                      <tr>
                        <td className=" flex flex-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                          <div>
                            <img
                              className="h-7 w-7 object-contain"
                              src={sourceIcon(g.name)}
                              alt={g.name}
                            />
                          </div>
                          <div className="flex flex-col justify-center pl-1">
                            {g.name}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                          <span>{g.bookedCall}</span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                          <span>
                            <span>{g.noShow}</span>
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  const bookedCallTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Booked Calls
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Presentation Completed
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                No Show
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.group.map((r, gIdx) => {
                return userId === r.userId ? (
                  r.current.bookedCalls.map((g, gIdx) => {
                    return (
                      <tr>
                        <td className=" break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                          {g.name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                          <span>{g.callCompleted}</span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                          <span>{g.callNoShow}</span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  const marketplaceTable = (sales) => {
    return sales.length > 0 ? (
      <>
        <table className="min-w-full divide-y divide-gray-300 col-span-12 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                style={{
                  width: '300px',
                }}
              >
                Marketplace
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Closed Deals
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
                style={{
                  width: '300px',
                }}
              >
                Booked
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
              <th
                scope="col"
                className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-left"
                data-sortable="true"
              ></th>
            </tr>
          </thead>
          <tbody>
            {sales.map((s, rIdx) => {
              return s.group.map((r, gIdx) => {
                return userId === r.userId ? (
                  r.current.marketplace.map((g, gIdx) => {
                    return (
                      <tr>
                        <td className=" flex flex-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                          <div>
                            <img
                              className="h-9 w-9 object-contain"
                              src={flag(g.name)}
                              alt={g.name}
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            {g.name}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left">
                          <span>{g.closedDeals}</span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-500 text-left">
                          <span>{g.booked}</span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-left"></td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                );
              });
            })}
          </tbody>
        </table>
      </>
    ) : (
      <p className="p-10">Nothing to display here!</p>
    );
  };

  return (
    <>
      {((userCan('sales.metrics') && userCan('sales.metrics.consultant')) ||
        userCan('sales.metrics.director') ||
        isAgencySuperUser()) && (
        <div className="mt-8 flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:px-8">
            <div className="py-2 align-middle sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {sourceTable(teamSalesData)}
              </div>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {bookedCallTable(teamSalesData)}
              </div>
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white mb-8">
                {marketplaceTable(teamSalesData)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MetricsGroup;
