import axios from 'axios';
import * as Yup from 'yup';
import { isFunction } from 'lodash';
import { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Form, ErrorMessage, Field } from 'formik';

import { Modal, ModalHeader, Button } from 'components';
import { Label, RequiredAsterisk } from 'components/Forms';

import { useAlerts } from 'features/alerts';
import useClient from 'features/clients/useClient';
import useTaskManagement from 'features/taskManagement/useTaskManagement';
import classNames from 'utils/classNames';

const FormModal = ({ open, setOpen, onSuccess }) => {
  const { SERVICES } = useClient();
  const { PARTNER_TYPES, ROADMAP_TYPES, TYPES } = useTaskManagement();
  const { popupAlert } = useAlerts();

  const onClose = () => {
    setStep(0);
    setOpen(false);
  };

  const [step, setStep] = useState(0);

  const handleSubmit = async (values, actions) => {
    let payload = { ...values };

    if (values.type !== 'roadmap') {
      delete payload.roadMapType;
    }

    try {
      const response = await axios.post('/v2/tasks/lists/templates', payload);
      onClose();
      actions.resetForm();

      setTimeout(() => {
        popupAlert({ type: 'success', title: response.data.message });
      }, 250);

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      popupAlert({
        type: 'error',
        title: error?.response?.data?.message || 'Something went wrong',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <Formik
        initialValues={{
          type: 'onboarding',
          name: '',
          status: 'published',
          partnerType: '',
          partnerService: '',
          roadMapType: '',
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required('Template type is required'),
          name: Yup.string().required('Template name is required'),
          partnerType: Yup.string().required('Partner type is required'),
          partnerService: Yup.string().required('Service category is required'),
          roadMapType: Yup.string(),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <div className="inline-block w-full lg:w-2/3 my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl ">
            <ModalHeader
              title={'Task List Creation'}
              setOpen={setOpen}
              titleClasses="capitalize"
              border="border"
              px="px-4"
              py="py-4"
              iconClassname="h-8 w-8"
              rightContent={
                <button className="relative group mr-4">
                  <XIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 absolute bottom-0 right-0 bg-red-500 text-white rounded-xl leading-none" />
                </button>
              }
              onClose={onClose}
            />
            <div className="p-10 flex flex-col items-center space-y-8">
              <h1 className="font-bold text-xl leading-1.2 text-grayscale-900 tracking-3/4">
                {step == 0
                  ? 'Select a Template to start with'
                  : `Customize your ${values.type} template`}
              </h1>

              <Form className="w-full">
                {step === 0 && (
                  <div>
                    <div className="grid grid-cols-2 gap-8">
                      {TYPES.map((type) => (
                        <div
                          key={type.value}
                          disabled={type.disabled}
                          onClick={() => {
                            if (!type.disabled) {
                              setFieldValue('type', type.value);
                            }
                          }}
                          className={classNames(
                            ' bg-white rounded-xl p-5 space-y-5',
                            values.type === type.value
                              ? 'border-secondary-light border-2'
                              : 'border-grayscale-600 border',
                            type.disabled ? 'opacity-25' : 'opacity-100'
                          )}
                        >
                          <div className="flex justify-center">
                            <div
                              className={classNames(
                                'w-5 h-5 rounded-full border flex',
                                values.type === type.value
                                  ? 'bg-secondary-light'
                                  : 'bg-transparent'
                              )}
                            />
                          </div>
                          <p className="text-center font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900">
                            {type.name}
                          </p>

                          <img
                            src={`/assets/tasks-templates/${type.value}.png`}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-center mt-8">
                      <Button
                        classes={
                          'border-0 font-bold tracking-widest ml-2 h-10 hover:text-blue-900'
                        }
                        bgColor="blue-900"
                        hoverColor="white"
                        roundedSize="full"
                        textColor="white "
                        px={12}
                        py={2}
                        shadow=""
                        textSize="sm"
                        border="border border-blue-900"
                        onClick={() => {
                          setStep((prev) => prev + 1);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                )}
                {step === 1 && (
                  <div className="flex flex-col items-center space-y-8 w-full px-40">
                    <div className="w-full">
                      <Label htmlFor="name">
                        Template Name
                        <RequiredAsterisk />
                      </Label>
                      <Field
                        name="name"
                        className="form-input disabled-white"
                        type="text"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    {values.type == 'roadmap' && (
                      <div className="w-full">
                        <Label htmlFor="roadMapType" classes="text-left">
                          Roadmap Type
                        </Label>
                        <Field
                          name="roadMapType"
                          as="select"
                          className="form-select disabled-white"
                        >
                          <option value="">Choose One</option>
                          {ROADMAP_TYPES.map((t, i) => (
                            <option key={i} value={t.value}>
                              {t.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="roadMapType"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    )}

                    <div className="w-full">
                      <Label htmlFor="partnerType" classes="text-left">
                        Partner Type <RequiredAsterisk />
                      </Label>

                      <div>
                        <Field
                          name="partnerType"
                          as="select"
                          className="form-select disabled-white"
                        >
                          <option value="">Choose One</option>
                          {PARTNER_TYPES.map((partnerType) => (
                            <option
                              key={partnerType.value}
                              value={partnerType.value}
                            >
                              {partnerType.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="partnerType"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <Label htmlFor="partnerService" classes="text-left">
                        Which service category will this be used?{' '}
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="partnerService"
                        as="select"
                        className="form-select disabled-white"
                      >
                        <option value="">Choose One</option>
                        {SERVICES.map((service) => (
                          <option key={service.value} value={service.value}>
                            {service.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="partnerService"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    <div className="flex space-x-4 pt-20">
                      <Button
                        type="button"
                        classes={
                          'border-0 font-bold tracking-widest ml-2 h-10 '
                        }
                        bgColor="white"
                        hoverColor="gray-100"
                        roundedSize="full"
                        textColor="gray-500 "
                        px={12}
                        py={2}
                        shadow=""
                        textSize="sm"
                        border="border border-gray-500"
                        onClick={() => {
                          setStep((prev) => prev - 1);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        classes={'border-0 font-bold tracking-widest ml-2 h-10'}
                        bgColor="secondary"
                        hoverColor="secondary-light"
                        roundedSize="full"
                        textColor="white "
                        px={12}
                        py={2}
                        shadow=""
                        textSize="sm"
                        border="border border-blue-900"
                        showLoading={true}
                        loading={isSubmitting}
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default FormModal;
