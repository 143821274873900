import { useEffect, useState } from 'react';
import { SearchIcon, PlusIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { capitalize, debounce } from 'lodash';
import InputPrepend from 'components/Forms/InputPrepend';
import MailBlockMenu from './MailBlockMenu';
import MailBlockTemplateEditor from './MailBlockTemplateEditor';
import classNames from 'utils/classNames';

const MailBlockTab = ({ type, isCurrent }) => {
  const [templates, setTemplates] = useState([]);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [loading, setLoading] = useState(false);
  const { action, id } = useSelector((state) => state.mail);
  const itemId = `email${capitalize(type)}Id`;
  //const [noFwd, setNoFwd] = useState(false);

  const fetchItems = async () => {
    setLoading(true);
    await axios.get(`/agency/email-${type}`).then((res) => {
      setTemplates(res.data.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchItems();
    }
  }, []);

  useEffect(() => {
    if (action === 'add') {
      onCreateTemplate();
    }
  }, [action]);

  useEffect(() => {
    setFilterTemplates(templates);
  }, [templates]);

  useEffect(() => {
    if (isCurrent && filterTemplates.length > 0) {
      const idx = id ? filterTemplates.findIndex((t) => t[itemId] === id) : 0;
      setSelectedTemplate(filterTemplates[idx < 0 ? 0 : idx]);
    }
    // return () => {
    //   setNoFwd(true);
    // };
  }, [isCurrent, filterTemplates]);

  const onSearch = (e) => {
    const search = e.target.value.toLowerCase();

    let f = templates.filter((template) =>
      template.name.toLowerCase().includes(search)
    );
    setFilterTemplates(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const onCreateTemplate = async () => {
    setLoading(true);
    await axios
      .post(`/agency/email-${type}`)
      .then((res) => {
        setTemplates(res.data.data);
        setSelectedTemplate(res.data.data[res.data.data.length - 1]);
      })
      .finally(() => {
        //setNoFwd(true);
        setLoading(false);
      });
  };

  const isItemSelected = (item) => {
    return selectedTemplate && selectedTemplate[itemId] === item[itemId]
      ? true
      : false;
  };

  const onSaveTemplate = (item) => {
    const temps = [...templates];
    const idx = temps.findIndex((m) => m[itemId] === item[itemId]);
    temps[idx] = item;
    setTemplates(temps);
  };

  return (
    <>
      <div className="grid grid-cols-3 h-70vh">
        <div className="flex flex-col col-span-1 items-center content-start border-r border-gray-200">
          <div className="w-full flex-grow">
            <div className="w-full p-3">
              <InputPrepend
                name="search"
                onChange={(e) => onDebouncedSearch(e)}
                type="text"
                placeholder="Search..."
                prependText={<SearchIcon className="w-4 h-4" />}
                border="border-none"
                rounded="rounded-md"
                classes="text-base form-input"
                autoFocus
              />
            </div>
            <div className="w-full border-t border-gray-200 h-54vh max-h-54vh overflow-y-auto">
              {filterTemplates.length > 0 &&
                filterTemplates.map((item) => {
                  return (
                    <div
                      className={classNames(
                        isItemSelected(item)
                          ? 'bg-gray-200'
                          : 'hover:bg-grayscale-400',
                        'flex justify-between py-2.5 pl-4 pr-2 group'
                      )}
                    >
                      <button
                        className="flex items-center space-x-2 w-11/12"
                        key={item[itemId]}
                        onClick={() => setSelectedTemplate(item)}
                      >
                        <span className="truncate ...">
                          {item.isDefault && `(Default) `}
                          {item.name}
                        </span>
                      </button>
                      <MailBlockMenu
                        item={item}
                        type={type}
                        fetchItems={fetchItems}
                        isEdit={true}
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="border-gray-200 border-t w-full pt-4 pb-6">
            <button
              className={
                'flex items-center space-x-2 w-full py-2 px-5 cursor-pointer text-custom-sky text-base font-bold'
              }
              onClick={() => onCreateTemplate()}
            >
              <PlusIcon className="w-4 h-4 inline" />
              &nbsp;Add {type}
            </button>
          </div>
        </div>
        <div className="col-span-2">
          {Object.keys(selectedTemplate).length > 0 && (
            <MailBlockTemplateEditor
              type={type}
              selectedTemplate={selectedTemplate}
              onSaveTemplate={onSaveTemplate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MailBlockTab;
