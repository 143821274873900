import { pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import { PlusIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import DropdownMenu from 'components/DropdownMenu';
import Input from 'components/Forms/Input';
import { Menu } from '@headlessui/react';
import useQueryParams from 'hooks/useQueryParams';
import { dateFormatterUTC, getNameInitials } from 'utils/formatters';
// import { Table } from 'components';
import Table from 'components/Table';

import { DocumentTextIcon } from '@heroicons/react/outline';
import {
  CheckIcon,
  CloudDownloadIcon,
  LinkIcon,
  TrashIcon,
  PencilIcon,
} from '@heroicons/react/solid';
import {
  PencilIcon as PencilIconOutline,
  CloudDownloadIcon as CloudDownloadIconOutline,
  LinkIcon as LinkIconOutline,
  TrashIcon as TrashIconOutline,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import prependHttp from 'prepend-http';

import {
  getAttachments,
  getLatestUpdates,
  selectAttachments,
} from '../tasksSlice';

import usePermissions from 'hooks/usePermissions';
import classNames from 'utils/classNames';
import doc from 'assets/icons/doc.svg';

const TaskFiles = ({ taskItemId, canDelete = true, canUpload = true }) => {
  const { isMine } = usePermissions();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState({
    taskAttachmentId: '',
    name: '',
    extension: '',
  });
  const [filesUp, setFilesUp] = useState(null);
  const [showGridList, setShowGridList] = useState(true);
  const attachments = useSelector(selectAttachments);
  const { attachmentPaginationParams } = useSelector;
  const { params, updateParams, sortParam } = useQueryParams({
    ...attachmentPaginationParams,
  });

  useEffect(() => {
    updateParams({
      page: 1,
      pageSize: 10,
      sort: 'createdAt:desc',
    });
  }, []);

  useEffect(() => {
    async function getData() {
      dispatch(
        getAttachments({
          params: pick(
            {
              ...params,
              taskItemId,
            },
            ['page', 'pageSize', 'sort', 'taskItemId']
          ),
        })
      );
    }
    getData();
  }, [params, dispatch, taskItemId]);

  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    uploadFiles(event.target.files);
  };

  // * Upload files to bulk update the details
  const uploadFiles = (files) => {
    if (fileInputRef.current.files.length <= 0) {
      fileInputRef.current.files = files;
    }
    setFilesUp([...files]);
    setMessage('');
  };

  // * Triggered when the button for adding files is clicked
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onStartUpload = async (e) => {
    e.stopPropagation();
    const files = fileInputRef.current.files;
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('taskItemId', taskItemId);
      await axios.post(`/agency/tasks/attachments`, formData).then((res) => {
        if (res.data.success) {
          dispatch(setAlert('success', 'File uploaded', res.data.message));
          setMessage(res.data.message);
          dispatch(getAttachments({ params: { ...params, taskItemId } }));
          dispatch(getLatestUpdates(taskItemId));
        } else {
          dispatch(
            setAlert('error', 'Failed to upload file', res.data.message)
          );
          setMessage(res.data.message);
        }
      });
    }

    setLoading(false);
    fileInputRef.current.value = null;
  };

  const onDeleteAttachment = async (file) => {
    if (canDelete) {
      setLoading(true);
      await axios
        .delete(`/agency/tasks/attachments/${file.taskAttachmentId}`)
        .then((res) => {
          if (res.data.success === true) {
            dispatch(
              setAlert('success', 'Successfully Deleted', res.data.message)
            );
            setMessage(res.data.message);
            dispatch(getAttachments({ params: { ...params, taskItemId } }));
            dispatch(getLatestUpdates(taskItemId));
          } else {
            dispatch(setAlert('error', res));
          }
        });
      setLoading(false);
    } else {
      dispatch(setAlert('error', 'Permission denied!'));
    }
  };

  const buildLink = async (file) => {
    navigator.clipboard.writeText(file.url);
    dispatch(
      setAlert(
        'success',
        'Successfully Copied',
        'File download link has been copied.'
      )
    );
  };

  const onViewLink = async (file) => {
    setLoading(true);
    await axios
      .get(`/agency/tasks/attachments/${file.taskAttachmentId}`)
      .then((res) => {
        if (res.data.success) {
          window.open(prependHttp(res.data.data.url, { https: false }));
        } else {
          dispatch(setAlert('error', res.data.message));
        }
      });
    setLoading(false);
  };

  const onSelect = (e, row) => {
    e.preventDefault();
    setSelected({
      taskAttachmentId: row.taskAttachmentId,
      name: row.name.split('.').slice(0, -1).join('.'),
      extension: row.extension,
    });
  };

  const onEditName = (e, row) => {
    e.preventDefault();
    setSelected({
      ...selected,
      name: e.target.value,
    });
  };

  const handleSpace = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      setSelected({
        ...selected,
        name: `${selected.name} `,
      });
    }
    if (e.keyCode === 13) {
      onBlurName();
    }
  };

  const onBlurName = async (e, row) => {
    await axios.put(`/agency/tasks/attachments/${selected.taskAttachmentId}`, {
      name: selected.name,
    });
    dispatch(
      getAttachments({
        params: {
          ...params,
          taskItemId: taskItemId,
        },
      })
    );
    setSelected({
      taskAttachmentId: '',
      name: '',
      extension: '',
    });
    dispatch(setAlert('success', 'File name updated'));
  };

  const handleNextPage = () => {
    updateParams({
      page: attachments.nextPage,
      pageSize: 10,
      sort: 'createdAt:desc',
    });
  };

  const handlePreviousPage = () => {
    updateParams({
      page: attachments.prevPage,
      pageSize: 10,
      sort: 'createdAt:desc',
    });
  };

  const handleSaveCell = async (oldValue, newValue, row, column) => {
    await axios.put(`/agency/tasks/attachments/${row.taskAttachmentId}`, {
      name: newValue.split('.').slice(0, -1).join('.'),
    });
    dispatch(
      getAttachments({
        params: {
          ...params,
          taskItemId: taskItemId,
        },
      })
    );
    dispatch(setAlert('success', 'File name updated'));
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'File Name',
      sort: true,
      headerStyle: {
        minWidth: '300px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell}</span>;
      },
    },
    {
      dataField: 'uploadedByUser',
      text: 'Uploaded By',
      sort: true,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'left',
      },
      editable: false,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? `${cell.firstName} ${cell.lastName}` : ''}
          </span>
        );
      },
    },
    {
      dataField: 'uploadedAt',
      text: 'Uploaded Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      editable: false,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? dateFormatterUTC(cell, 'DD MMM YYYY HH:mm') : ''}
          </span>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerStyle: { textAlign: `center` },
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        return (
          <div className="flex flex-row justify-evenly">
            <button onClick={() => onViewLink(row)}>
              <CloudDownloadIconOutline className="w-5 h-5 cursor-pointer text-blue-400" />
            </button>
            <button onClick={() => buildLink(row)}>
              <LinkIconOutline className="w-5 h-5 cursor-pointer text-green-600" />
            </button>
            {canDelete && isMine(row.uploadedBy) && (
              <button onClick={() => onDeleteAttachment(row)}>
                <TrashIconOutline
                  className="w-5 h-5 cursor-pointer"
                  color="#dc2626"
                />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: 'createdAt:desc',
    });
  };

  return (
    <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
      <div className="flex items-center w-full py-2 text-left text-lg text-gray-700 font-bold font-inter tracking-wide">
        Uploaded Files
      </div>
      {/* <h4 className="col-span-1 text-2xl font-bold"></h4> */}
      {canUpload && (
        <div className="col-span-1">
          <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            className="hidden"
            multiple
          />
          <FileDrop
            onTargetClick={onTargetClick}
            onDrop={(files, event) => uploadFiles(files)}
          >
            <div className="border border-dashed border-gray-300 p-5 text-center cursor-pointer bg-gray-100">
              <div className="flex items-center justify-center">
                <PlusIcon
                  className="text-gray-500 mr-1 h-5 w-5 inline"
                  aria-hidden="true"
                />
                <span className="text-gray-500 text-xl">
                  Drop file or click to select
                </span>
              </div>
              {filesUp && (
                <div className="mt-4">
                  <ul className="text-sm mb-4">
                    {filesUp.map((file, i) => {
                      return (
                        <li key={i}>
                          <DocumentTextIcon className="inline w-4 h-4" />
                          <span className="text-gray-700">{file.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                  {fileInputRef.current.value && (
                    <Button
                      color="green"
                      loading={loading}
                      showLoading={true}
                      onClick={onStartUpload}
                    >
                      Upload Attachments
                    </Button>
                  )}
                </div>
              )}
            </div>
          </FileDrop>
        </div>
      )}

      <div className="col-span-1 px-2 py-3 sm:px-0 flex justify-end space-x-1">
        <div>
          <button onClick={() => setShowGridList(true)}>
            <span
              className={classNames(
                'material-symbols-outlined p-0.5 rounded-sm hover:text-red-500',
                showGridList && 'text-red-500 shadow'
              )}
            >
              grid_view
            </span>
          </button>
        </div>
        <div>
          <button onClick={() => setShowGridList(false)}>
            <span
              className={classNames(
                'material-symbols-outlined p-0.5 rounded-sm hover:text-red-500',
                !showGridList && 'text-red-500 shadow'
              )}
            >
              view_list
            </span>
          </button>
        </div>
      </div>

      {showGridList ? (
        <>
          {attachments?.rows?.length >= 1 ? (
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-x-4 gap-y-6 justify-center">
              {attachments.rows.map((attachment, key) => {
                return (
                  <div
                    className="col-span-1 relative flex flex-col items-center transition-all group"
                    key={attachment.taskAttachmentId}
                  >
                    <div className="relative mb-3">
                      <img src={doc} className="h-20" />
                      <span
                        title={attachment.extension}
                        className={classNames(
                          'text-white truncate font-bold  font-inter opacity-80 px-1 py-0.5 shadow absolute w-3/5 text-xs text-center uppercase top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
                          ['png', 'svg', 'jpg', 'jpeg', 'gif'].includes(
                            attachment.extension
                          )
                            ? 'bg-green-600'
                            : 'bg-blue-800'
                        )}
                      >
                        {attachment.extension}
                      </span>
                      <span className="bg-red-500 text-white w-6 h-6 text-1xs rounded-3xl uppercase text-center leading-6 absolute -bottom-1 left-1/2 transform -translate-x-1/2">
                        {getNameInitials(
                          attachment.uploadedByUser.firstName,
                          attachment.uploadedByUser.lastName
                        )}
                      </span>
                    </div>

                    <p
                      className="relative w-full truncate font-inter text-center text-xs text-gray-800"
                      title={attachment.name}
                    >
                      {attachment.name}
                    </p>
                    <p className="relative text-center text-1xs text-gray-500 font-inter my-1">
                      {dateFormatterUTC(
                        attachment.uploadedAt,
                        'MMM DD YYYY HH:mma'
                      )}
                    </p>
                    <div className="hidden shadow group-hover:block absolute w-full h-full bg-gray-700 bg-opacity-70 rounded-lg p-8">
                      <div className="flex flex-wrap justify-between items-center text-center h-full">
                        <div className="w-1/2">
                          <DropdownMenu
                            title={
                              <div
                                className=""
                                title="click to update file name"
                              >
                                <PencilIcon color="white" className="w-5 h-5" />
                              </div>
                            }
                            titleClasses="flex items-center mr-1"
                            buttonBg="bg-transparent"
                            buttonFontWeight="font-normal"
                            hoverClasses=""
                            textColor="text-gray-600"
                            classes="text-sm"
                            buttonRounded=""
                            hoverText="hover:text-red-500"
                            dropdownWidth="w-28"
                            padding=""
                            zIndex="z-10"
                            hideArrow
                          >
                            <div className="flex flex-col ">
                              <Menu.Item>
                                {({ active }) => (
                                  <>
                                    <Input
                                      type="text"
                                      value={selected.name}
                                      name={selected.name}
                                      onClick={(e) => onSelect(e, attachment)}
                                      onChange={(e) =>
                                        onEditName(e, attachment)
                                      }
                                      onBlur={(e) => onBlurName(e, attachment)}
                                      onKeyDown={handleSpace}
                                    />
                                  </>
                                )}
                              </Menu.Item>
                            </div>
                          </DropdownMenu>
                        </div>
                        <div className="w-1/2">
                          <button onClick={() => onViewLink(attachment)}>
                            <CloudDownloadIcon className="w-6 h-6 cursor-pointer text-blue-400 hover:text-blue-600" />
                          </button>
                        </div>
                        <div className="w-1/2">
                          <button onClick={() => buildLink(attachment)}>
                            <LinkIcon className="w-6 h-6 cursor-pointer text-green-400 hover:text-green-600" />
                          </button>
                        </div>
                        <div className="w-1/2">
                          {canDelete && (
                            <button
                              className=""
                              onClick={() => onDeleteAttachment(attachment)}
                            >
                              <TrashIcon className="w-6 h-6 cursor-pointer text-red-500 hover:text-red-700" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5 flex justify-center">
              <p>No attachments to display</p>
            </div>
          )}
          <div className="flex justify-center mt-4">
            {attachments.prevPage >= 1 ? (
              <div className="pr-5">
                <Button
                  classes="border border-gray-700 font-bold tracking-widest"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="3xl"
                  textColor="gray-700"
                  px={5}
                  py={2}
                  shadow=""
                  textSize="xs"
                  onClick={handlePreviousPage}
                  loading={loading}
                  showLoading={true}
                >
                  <ChevronLeftIcon className="w-4 h4 inline mr-2" /> Previous
                </Button>
              </div>
            ) : (
              <div></div>
            )}
            {attachments.nextPage > 1 ? (
              <div>
                <Button
                  classes="border border-gray-700 font-bold tracking-widest"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="3xl"
                  textColor="gray-700"
                  px={5}
                  py={2}
                  shadow=""
                  textSize="xs"
                  onClick={handleNextPage}
                  loading={loading}
                  showLoading={true}
                >
                  Next <ChevronRightIcon className="w-4 h4 inline ml-2" />
                </Button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </>
      ) : (
        <>
          {attachments?.rows?.length >= 1 ? (
            <Table
              columns={tableColumns}
              data={attachments}
              onTableChange={onTableChange}
              params={params}
              keyField="taskAttachmentId"
              defaultSorted={[{ dataField: 'uploadedAt', order: 'desc' }]}
              loading={loading}
              cellEdit={true}
              cellEditProps={{
                mode: 'dbclick',
                blurToSave: true,
                beforeSaveCell: handleSaveCell,
              }}
              // afterSaveCell={afterSaveCell}
            />
          ) : (
            <div className="mt-5 flex justify-center">
              <p>No attachments to display</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TaskFiles;
