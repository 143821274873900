import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';

const ModalHeader = ({
  title,
  setOpen,
  classes,
  titleClasses,
  showCloseButton = true,
  border = 'border-b',
  fontSize = 'text-md',
  fontStyle = 'font-medium',
  px = 'px-4 md:px-5',
  py = 'py-3',
  iconClassname = 'h-6 w-6',
  rightContent,
  onClose,
  xAlign = 'items-center',
}) => {
  return (
    <div
      className={classNames(
        `${px}`,
        `${border}`,
        `${py}`,
        classes ?? '',
        'flex justify-between'
      )}
    >
      <h3
        className={classNames(
          `${fontSize}`,
          `${fontStyle}`,
          titleClasses ?? '',
          'leading-6 text-gray-800'
        )}
      >
        {title}
      </h3>
      <div className={classNames('flex justify-end', xAlign)}>
        {rightContent && rightContent}
        {showCloseButton && (
          <button
            type="button"
            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose ? onClose : () => setOpen(false)}
          >
            <span className="sr-only">Close</span>
            <XIcon className={iconClassname} aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};
export default ModalHeader;
