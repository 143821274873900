import { setTaskTray } from 'features/tasks/tasksSlice';
import { useDispatch, useSelector } from 'react-redux';

const useTaskTray = () => {
  const { taskTray } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();

  const addTaskToTray = (task) => {
    const exists = inTray(task);

    if (!exists) {
      dispatch(setTaskTray([...taskTray, task]));
    }
  };

  const inTray = (task) => {
    const exists = taskTray.find((t) => t.taskItemId === task.taskItemId);
    return exists;
  };

  const removeTaskFromTray = (task) => {
    const exists = inTray(task);

    if (exists) {
      let instance = [...taskTray];
      const index = instance.findIndex((t) => t.taskItemId === task.taskItemId);
      instance.splice(index, 1);
      dispatch(setTaskTray(instance));
    }
  };

  return {
    taskTray,
    addTaskToTray,
    removeTaskFromTray,
    inTray,
  };
};

export default useTaskTray;
