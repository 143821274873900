import axios from 'axios';
import moment from 'moment';
import { Modal, Table, UserDisplay } from 'components';
import { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { currencyFormatter } from 'utils/formatters';
import ClientLink from 'features/clients/List/components/ClientLink';

const ClientModal = ({ open, setOpen, filterGroups = [] }) => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setParams({
      page,
      pageSize: sizePerPage,
      sorts: `${sortField}:${sortOrder}`,
    });
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/clients/list', {
        params: {
          ...params,
          attributes: [
            'agencyClientId',
            'client',
            'status',
            'contractSigned',
            'terminatedAt',
          ],
          include: [
            'subscription',
            'accountSpecialist[user[avatar]]',
            'brandStrategist[user[avatar]]',
            'projectManager[user[avatar]]',
          ],
          filterGroups,
        },
      });

      if (isSubscribed) {
        setClients(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [params, filterGroups]);

  const tableColumns = [
    {
      dataField: 'client',
      text: 'Client',
      sort: true,
      show: true,
      hideable: false,
      headerStyle: {
        minWidth: '250px',
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => (
        <ClientLink client={row}>
          <span className="text-secondary-light">{cell}</span>
        </ClientLink>
      ),
    },
    {
      dataField: 'projectManager.user.firstName',
      text: 'Project Manager',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) =>
        cell && (
          <UserDisplay bgColor="#1879D8" user={row.projectManager.user} />
        ),
    },
    {
      dataField: 'brandStrategist.user.firstName',
      text: 'Brand Strategist',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) =>
        cell && (
          <UserDisplay bgColor="#1879D8" user={row.brandStrategist.user} />
        ),
    },
    {
      dataField: 'accountSpecialist.user.firstName',
      text: 'Account Specialist',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) =>
        cell && (
          <UserDisplay bgColor="#1879D8" user={row.accountSpecialist.user} />
        ),
    },
    {
      dataField: 'subscription.subTotal',
      text: 'Subscription Amount',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => (cell ? currencyFormatter(cell) : 0),
    },
    {
      dataField: 'contractSigned',
      text: 'Contract Date',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => (cell ? moment(cell).format('DD-MM-YYYY') : null),
    },
    {
      dataField: 'terminatedAt',
      text: 'Termination Date',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => (cell ? moment(cell).format('DD-MM-YYYY') : null),
    },
  ];

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-3xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 py-4 border-b border-grayscale-500">
          <h4 className="text-grayscale-700 leading-1.5">Client List</h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="overflow-y-auto mt-2 px-8">
          {/* <p className="flex space-x-2 text-grayscale-700 text-13 leading-1.5">
            <span>{moment(dateRange.startDate).format('MMM DD, YYYY')}</span>
            <span>-</span>
            <span>{moment(dateRange.endDate).format('MMM DD, YYYY')}</span>
          </p>

          <p className="font-inter text-25 tracking-3/4 leading-1.2 text-grayscale-700">
            Terminated Clients:{' '}
            <span className="text-grayscale-900">{reason}</span>
          </p> */}

          <div className="mt-8">
            <Table
              columns={tableColumns.map((column) => {
                return {
                  ...column,
                  classes:
                    'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
                  headerClasses:
                    'text-grayscale-700 border-grayscale-200 border-b border-grayscale-500 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
                };
              })}
              data={clients}
              params={params}
              keyField="agencyClientId"
              loading={loading}
              onTableChange={onTableChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClientModal;
