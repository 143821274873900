import { useEffect, useState } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Table } from 'components';
import { Link } from 'react-router-dom';
import { dateFormatterUTC } from 'utils/formatters';
import Badge from 'components/Badge';
import TabNav from 'components/TabNav';
import useQueryParams from 'hooks/useQueryParams';
import useUpsell from 'hooks/useUpsell';
import { columnClasses } from 'utils/table';
import useLoggedInUser from 'hooks/useLoggedInUser';

const UpsellInvoices = () => {
  const { user } = useLoggedInUser();
  const { STATUS } = useUpsell();
  const { params, updateParams } = useQueryParams({
    page: 1,
    pageSize: 10,
    sort: 'createdAt:desc',
    status: 'pending',
    filter: '',
    client: user.defaultAgency.agencyClientId,
  });
  const [loading, setLoading] = useState(false);
  const [upsells, setUpsells] = useState(null);

  const [menuTabs, setMenuTabs] = useState([
    {
      displayName: 'Pending',
      name: 'pending',
      current: params.status == STATUS.PENDING,
      visible: true,
    },
    {
      displayName: 'Paid',
      name: 'paid',
      current: params.status == STATUS.PAID,
      visible: true,
    },
  ]);

  const getUpsells = async () => {
    setLoading(true);
    await axios.get(`/agency/upsell`, { params }).then((res) => {
      setUpsells(res.data.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      getUpsells();
    }
  }, [params]);

  const updateStatus = (status) => {
    updateParams({
      filter: status.toLowerCase(),
      page: 1,
    });
  };

  const tableColumns = [
    {
      dataField: 'invoiceNumber',
      text: 'Invoice Number',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/additional-services/${row.upsellId}/${row.invoiceId}`}
            className="text-secondary-light underline text-sm"
          >
            {row.invoiceNumber}
          </Link>
        );
      },
      visible: true,
    },
    {
      dataField: 'service',
      text: 'Service',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="uppercase">{row.details.name}</span>;
      },
      visible: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: false,
      classes: `${columnClasses} text-right`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            <span className="text-grayscale-600">$</span>&nbsp;
            {row.totalAmount.toFixed(2)}
          </span>
        );
      },
      visible: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              color={classnames({
                orange: cell === STATUS.AWAITING_APPROVAL,
                gray: cell === STATUS.DRAFT,
                blue: cell === STATUS.APPROVED,
                yellow:
                  cell === STATUS.SENT ||
                  cell === STATUS.OVERDUE ||
                  cell === STATUS.PENDING,
                green: cell === STATUS.PAID,
                red: cell === STATUS.REJECTED,
              })}
              classes="capitalize text-center w-40 justify-center"
              rounded="full"
              padding="py-2"
              textSize="sm"
              fontFamily=""
            >
              {cell}
            </Badge>
          </>
        );
      },
      visible: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{dateFormatterUTC(row.createdAt)}</span>
        );
      },
      visible: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/additional-services/${row.upsellId}/${row.invoiceId}`}
            className="text-sm text-grayscale-700 hover:text-grayscale-800 font-bold border-2 border-grayscale-700 hover:border-grayscale-800 hover:bg-grayscale-500 px-6 py-1.5 rounded-lg tracking-widest"
          >
            View
          </Link>
        );
      },
      visible: true,
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage && sizePerPage,
      sort: sortField ? `${sortField}:${sortOrder}` : 'createdAt:desc',
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Additional Services
          </h2>
        </div>
      </div>

      <TabNav
        tabs={menuTabs}
        setTabs={setMenuTabs}
        onSelectChange={(e) => updateStatus(e.target.value)}
        onClick={(selectedTab) =>
          updateParams({
            status: selectedTab.name.toLowerCase(),
            page: 1,
            sizePerPage: params.pageSize,
          })
        }
        theme="tabbed"
        displayName={true}
      />

      <Table
        columns={tableColumns.filter((t) => t.visible)}
        data={upsells}
        onTableChange={onTableChange}
        params={params}
        keyField="upsellId"
        loading={loading}
        rowClasses="my-4 text-left"
      />
    </>
  );
};

export default UpsellInvoices;
