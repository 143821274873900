import { Popover } from '@headlessui/react';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import InputPrepend from './Forms/InputPrepend';

const ClientsListSelect = ({
  clients,
  selectedId,
  onChange,
  disabled,
  position = 'left-1/2 -translate-x-1/2 transform',
  width = 'w-64',
  keyField = 'agencyClientId',
  textColor = 'text-grayscale-700',
  textSize = 'text-xs',
  containerWidth = 'w-full max-w-sm',
}) => {
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  useEffect(() => {
    if (selectedId && clients) {
      setSelectedClient(
        clients.find((client) => client[keyField] === selectedId)
      );
    } else {
      setSelectedClient(null);
    }
  }, [selectedId, clients]);

  const onSearch = (e) => {
    const search = e.target.value.toLowerCase();

    let f = clients.filter((client) =>
      client.client.toLowerCase().includes(search)
    );
    setFilteredClients(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className={classNames(containerWidth)}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              disabled={disabled}
              className={classNames(
                open ? '' : 'text-opacity-90',
                textSize,
                'group inline-flex items-center rounded-sm bg-orange-700 py-0 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              )}
            >
              {selectedClient ? (
                <span
                  className={classNames(
                    textColor,
                    'text-left flex items-center'
                  )}
                >
                  {selectedClient.client}
                  <ChevronDownIcon className="ml-1 w-4 h-4 text-gray-400 hidden group-hover:inline" />
                </span>
              ) : (
                <span className="flex items-center">
                  Select Client{' '}
                  <ChevronDownIcon className="ml-1 w-4 h-4 text-gray-400" />
                </span>
              )}
            </Popover.Button>

            <Popover.Panel
              className={classNames(
                position,
                width,
                'absolute z-20 min-h-full max-w-sm'
              )}
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white">
                    <div className="border-b">
                      <InputPrepend
                        name="search"
                        onChange={(e) => onDebouncedSearch(e)}
                        type="text"
                        placeholder="Search..."
                        prependText={<SearchIcon className="w-4 h-4" />}
                        border="border-none"
                        rounded="rounded-none"
                        classes="text-base"
                        autoFocus
                      />
                    </div>
                    <div className="overflow-y-auto h-72 py-4 px-2 text-left">
                      {filteredClients.length > 0 ? (
                        filteredClients.map((client) => {
                          return (
                            <button
                              className="flex items-center w-full text-left px-2 py-1 space-x-3 text-sm hover:bg-gray-100 rounded-md focus:ring-gray-300 focus:border-gray-200"
                              key={client[keyField]}
                              value={client[keyField]}
                              onClick={() => {
                                onChange(client);
                                close();
                              }}
                            >
                              <span>{client.client}</span>
                            </button>
                          );
                        })
                      ) : (
                        <div className="w-full text-xs text-gray-700 py-2 px-3">
                          No clients found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ClientsListSelect;
