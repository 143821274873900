const useChurn = () => {
  const calculateChurn = (record) => {
    return {
      ...record,
      churnRate: record.beginningClients
        ? (record.beginningClients - record.totalClients) /
          record.beginningClients
        : 0,
      previousChurnRate: record.previousBeginningClients
        ? (record.previousBeginningClients - record.previousTotalClients) /
          record.previousBeginningClients
        : 0,
      referralRate:
        record.beginningClients &&
        record.referredClients != record.beginningClients
          ? record.referredClients / record.beginningClients
          : 0,
      previousReferralRate:
        record.previousReferredClients &&
        record.previousReferredClients != record.previousBeginningClients
          ? record.previousReferredClients / record.previousBeginningClients
          : 0,
      upsellRate: record.totalClients
        ? record.upsoldClients / record.totalClients
        : 0,
      previousUpsellRate: record.previousTotalClients
        ? record.previousUpsoldClients / record.previousTotalClients
        : 0,
      revenueChurnRate: record.beginningMonthlyRetainer
        ? (record.beginningMonthlyRetainer - record.monthlyRetainer) /
          record.beginningMonthlyRetainer
        : 0,
      previousRevenueChurnRate:
        record.previousMonthlyRetainer + record.previousLostMonthlyRetainer
          ? record.previousLostMonthlyRetainer /
            (record.previousMonthlyRetainer +
              record.previousLostMonthlyRetainer)
          : 0,
      netRevenueChurnRate: record.monthlyRetainer
        ? (record.lostMonthlyRetainer - record.upsoldAmount) /
          record.monthlyRetainer
        : 0,
      previousNetRevenueChurnRate: record.previousMonthlyRetainer
        ? (record.previousLostMonthlyRetainer - record.previousUpsoldAmount) /
          record.previousMonthlyRetainer
        : 0,
      monthlyRetainerAndUpsoldAmount:
        record.monthlyRetainer + record.upsoldAmount,
      previousMonthlyRetainerAndUpsoldAmount:
        record.previousMonthlyRetainer + record.previousUpsoldAmount,
    };
  };

  return { calculateChurn };
};

export default useChurn;
