import axios from 'axios';
import { useState } from 'react';

import moment from 'moment';
import { useAlerts } from 'features/alerts';

import InlineEditor from 'components/Table/InlineEditor';

const DateEditor = ({ attribute = '', clientId, value, onChange }) => {
  const { popupAlert } = useAlerts();

  const [data, setData] = useState(value);

  const onSubmit = () => {
    return axios
      .put(`/v2/agency/clients/${clientId}`, {
        [attribute]: data,
      })
      .then((response) => {
        popupAlert({ type: 'success', title: response.data.message });
        onChange();
      })
      .catch((err) =>
        popupAlert({
          type: 'error',
          title: err.response?.data?.message ?? 'Something went wrong',
        })
      );
  };

  return (
    <InlineEditor
      display={value ? moment(value).format('DD MMM YYYY') : <>&mdash;</>}
      onExit={() => setData(value)}
      onSubmit={onSubmit}
    >
      <input
        type="date"
        value={data}
        className="text-sm w-32 bg-grayscale-400 border-0 rounded-2xl"
        onChange={(e) => setData(e.target.value)}
      />
    </InlineEditor>
  );
};

export default DateEditor;
