import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { ChartBarIcon, BookmarkIcon, ServerIcon } from '@heroicons/react/solid';

import ProductPreview from 'components/ProductPreview';

import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import { metricColumns } from 'features/advertising/utils/columns';
import {
  SORT_BY_COST,
  PRODUCT_ADS,
} from 'features/advertising/utils/constants';
import TrendsModal from '../../../components/TrendsModal/TrendsModal';

const Products = ({ accountId, marketplace, campaignType }) => {
  const { url } = useRouteMatch();
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [openTrends, setOpenTrends] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [products, setProducts] = useState({ rows: [] });
  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('products-column') ??
      'asin,sku,listingId,cost,sales,orders,impressions,clicks,cpc,roas,acos'
  );

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: '',
    campaignType,
    attributes: [
      'asin',
      'sku',
      'listingId',
      'impressions',
      'clicks',
      'ctr',
      'cost',
      'cpc',
      'orders',
      'sales',
      'acos',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'roas',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
    sort: SORT_BY_COST,
    include: ['previousData', 'listing'],
  });

  useEffect(() => {
    axios
      .get('/account/advertising/product-ads/listings', {
        params: {
          ...params,
          ...dateRange,
          accountId,
          marketplace,
        },
      })
      .then((response) => {
        setProducts(response.data.data);
      });
  }, [accountId, marketplace, dateRange, params]);

  const columns = [
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'sku',
      text: 'Products',
      sort: false,
      headerStyle: { minWidth: '275px' },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
      formatter: (cell, row) => (
        <ProductPreview
          sku={row['listing.sku'] ?? row.sku}
          asin={row['listing.asin'] ?? row.asin}
          productName={row['listing.title']}
          imageUrl={row['listing.thumbnail']}
        />
      ),
    },
    ...metricColumns().filter((col) => {
      return col.campaignTypes
        ? col.campaignTypes.includes(campaignType)
        : true;
    }),
    {
      auto: true,
      default: true,
      dataField: 'listingId',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const onClick = () => {
          setSelectedProduct(row);
          setOpenTrends(true);
        };

        return (
          <div className="flex">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={onClick}
            />

            <NavLink
              to={url.replace('listings', `campaigns?listingId=${cell}`)}
              title="View Campaigns"
            >
              <ServerIcon className="w-6 h-6 text-secondary" />
            </NavLink>

            <NavLink
              to={url.replace('listings', `ad-groups?listingId=${cell}`)}
              title="View Ad Groups"
            >
              <BookmarkIcon className="w-6 h-6 text-secondary" />
            </NavLink>
          </div>
        );
      },
    },
  ];

  const onUpdateParams = (params) => {
    setParams(params);
  };

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('products-column', newColumns);
    setVisibleColumns(newColumns);
  };

  return (
    <div id={PRODUCT_ADS}>
      <TrendsModal
        title={selectedProduct.asin}
        open={openTrends}
        setOpen={(value) => {
          setSelectedProduct({});
          setOpenTrends(value);
        }}
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selectedProduct}
        url={
          selectedProduct.listingId
            ? '/account/advertising/product-ads/records'
            : ''
        }
        additionalParams={{
          listingId: selectedProduct.listingId,
          campaignType,
        }}
      />

      <AdvertisingTable
        params={params}
        list={products}
        columns={columns}
        accountId={accountId}
        recordType={PRODUCT_ADS}
        keyField="listingId"
        marketplace={marketplace}
        campaignType={campaignType}
        onChangeParams={onUpdateParams}
        searchClassName="lg:col-span-3"
        filtersClassName="lg:col-span-3"
        exportClassName="lg:col-span-3"
        columnPickerClassName="lg:col-span-3"
        searchPlaceholder="Search asin or sku"
        visibleColumns={visibleColumns}
        onChangeVisibleColumns={onChangeVisibleColumns}
        endpoint="product-ads/products"
      />
    </div>
  );
};

export default Products;
