import PaymentForm from 'components/PaymentForm';
// import useAlert from 'hooks/useAlert';
// import useBraintreePayment from 'hooks/useBraintreePayment';
import OnboardingLayout from 'layouts/onboarding/OnboardingLayout';

const Checkout = () => {
  // const { alertError } = useAlert();
  // const { cardNumber, cvv, expirationDate, hfInstance } = useBraintreePayment();

  // const onPay = async (e) => {
  //   e.preventDefault();

  //   hfInstance.tokenize(async (err, payload) => {
  //     if (err) {
  //       //const details = err.details.{invalidFieldKeys: [], invalidFields: {cvv, expirationDate, number, postalCode}}
  //       console.log(err);
  //       alertError(err.name, err.message);
  //       return;
  //     }

  //     console.log(payload.nonce);

  //     // try {
  //     //   const output = await axios.post(
  //     //     `/agency/sales/proposals/${proposalId}/checkout`,
  //     //     {
  //     //       paymentMethodNonce: payload.nonce,
  //     //       deviceData,
  //     //       customer: {
  //     //         company: proposal.salesClient.lead.companyName,
  //     //         email: proposal.email,
  //     //         firstName: proposal.salesClient.lead.lead,
  //     //         lastName: proposal.salesClient.lead.leadLastName,
  //     //         phone: proposal.salesClient.lead.phoneNumber,
  //     //       },
  //     //       billing: {
  //     //         firstName: 'Paul',
  //     //         lastName: 'Smith',
  //     //         company: 'Braintree',
  //     //         streetAddress: '1 E Main St',
  //     //         extendedAddress: 'Suite 403',
  //     //         locality: 'Chicago',
  //     //         region: 'IL',
  //     //         postalCode: '60622',
  //     //         countryCodeAlpha2: 'US',
  //     //       },
  //     //       amount: 1265,
  //     //       orderId: proposalId,
  //     //       taxExempt: true,
  //     //       customFields: {
  //     //         salesclientid: proposal.salesClientId,
  //     //       },
  //     //     }
  //     //   );

  //     //   console.log(output);
  //     // } catch (err) {
  //     //   console.log(err);
  //     // }
  //   });
  // };

  const onPaymentConfirm = (payload) => {
    console.log(payload);
  };

  return (
    <OnboardingLayout>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Account Subscription
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Let's set up your subscription
        </p>

        <div className="mt-8 px-4 py-8 sm:px-10 sm:mx-auto sm:w-full sm:max-w-xl bg-white shadow sm:rounded-2xl">
          <PaymentForm callback={onPaymentConfirm} submitText="subscribe" />
          {/* <form id="cardForm">
            <div>
              <header className="py-4 px-8">
                <h1>Card Payment</h1>
              </header>
              <div className="py-4 px-8 grid grid-cols-2 overflow-hidden gap-4">
                <div className="col-span-2">
                  <label
                    className="flex flex-row items-center"
                    htmlFor="card-number"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        className="fill-custom-blue"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
                      </svg>
                    </span>{' '}
                    <span>Card Number</span>
                  </label>
                  <div
                    ref={cardNumber}
                    id="card-number"
                    className="text-base font-sourceSansPro h-12 border-b"
                  ></div>
                </div>
                <div className="col-span-1">
                  <label
                    className="flex flex-row items-center"
                    htmlFor="expiration-date"
                  >
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                      </svg>
                    </span>
                    Expiration Date
                  </label>
                  <div
                    ref={expirationDate}
                    id="expiration-date"
                    className="text-base font-sourceSansPro h-12 border-b outline-none ring-0"
                  ></div>
                </div>
                <div className="col-span-1">
                  <label className="flex flex-row items-center" htmlFor="cvv">
                    <span className="mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                      </svg>
                    </span>
                    CVV
                  </label>
                  <div
                    ref={cvv}
                    id="cvv"
                    className="text-base font-sourceSansPro h-12 border-b"
                  ></div>
                </div>
              </div>
              <footer className="flex justify-center py-4 px-8">
                <button
                  className="bg-custom-yellow hover:bg-yellow-400 text-black rounded-full px-20 py-2 shadow-md"
                  onClick={onPay}
                >
                  Pay
                </button>
              </footer>
            </div>
          </form> */}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default Checkout;
