import { StopIcon, PlayIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Button from 'components/Button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoice } from '../invoiceSlice';
import useAlert from 'hooks/useAlert';
import playSvg from '../icons/play.svg';
import stopSvg from '../icons/stop1.svg';
import classNames from 'utils/classNames';

const AutoOptionEmail = () => {
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlert();
  const { invoice } = useSelector((state) => state.invoice);
  const [saving, setSaving] = useState(false);
  const onChangePauseEmail = async () => {
    const value =
      invoice.custom_field_hash.cf_pause_email == null
        ? true
        : !invoice.custom_field_hash.cf_pause_email_unformatted;

    const payload = {
      label: 'pause email',
      value,
    };

    setSaving(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/customfields`,
        payload
      );

      alertSuccess(
        `Auto send email ${true ? 'OFF' : 'ON'}`,
        res.data.output.message
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
    } catch (error) {
      alertError('Cannot update auto send email', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  const isAutoSend =
    invoice.custom_field_hash.cf_pause_email &&
    invoice.custom_field_hash.cf_pause_email_unformatted
      ? false
      : true;

  return (
    <div className="w-full flex items-center mb-8">
      <div className="text-sm text-gray-600 italic mr-2">
        <span
          className={`text-sm font-bold not-italic tracking-wider ${
            !isAutoSend ? 'text-red-500' : 'text-green-500'
          }`}
        >
          AUTO SEND EMAIL {!isAutoSend ? ' OFF' : ' ON'}
        </span>
        {!isAutoSend
          ? ''
          : '. This invoice will be automatically sent after 24 hours'}
      </div>

      <Button
        onClick={onChangePauseEmail}
        loading={saving}
        showLoading={true}
        bgColor="transparent"
        hoverColor="transparent"
        textColor="gray-500"
        py={0}
        px={0}
        roundedSize="2xl"
        spinnerColor="red-500"
        data-tip
        data-for="autosend"
      >
        <img src={isAutoSend ? stopSvg : playSvg} />

        <ReactTooltip
          id="autosend"
          type="light"
          effect="solid"
          className="shadow-lg rounded-lg opacity-100"
        >
          <span>{!isAutoSend ? 'Start Autosend' : 'Stop Autosend'}</span>
        </ReactTooltip>
      </Button>
    </div>
  );
};

export default AutoOptionEmail;
