import { Switch } from '@headlessui/react';

import classNames from 'utils/classNames';

const ToggleChart = ({ className = '', visible, setVisible }) => {
  return (
    <div className={`${className} flex items-center justify-end`}>
      <p className="mr-4 text-grayscale-900 text-mini font-sourceSansPro">
        Display Chart
      </p>
      <Switch
        checked={visible}
        onChange={setVisible}
        className={classNames(
          visible ? 'bg-success' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 items-center rounded-full'
        )}
      >
        <span
          className={classNames(
            visible ? 'translate-x-6' : 'translate-x-1',
            'inline-block h-4 w-4 transform rounded-full bg-white'
          )}
        />
      </Switch>
    </div>
  );
};

export default ToggleChart;
