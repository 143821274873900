const AddSubtaskIcon = ({ fill = '#6B7280', width = '40', height = '40' }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
    <path
      d="M21.0671 23.6457V25.186H14.5117V15.4545H13.1169V26.5862H21.0671V28.4415H25.8442V23.6457H21.0671ZM24.4494 27.0412H22.4619V25.0459H24.4494V27.0412Z"
      fill={fill}
    />
    <path
      d="M23.0995 11.5583H23.9136C23.986 11.5583 24.0222 11.5968 24.0222 11.6738V21.8325C24.0222 21.9095 23.986 21.948 23.9136 21.948H23.0995C23.0271 21.948 22.9909 21.9095 22.9909 21.8325V11.6738C22.9909 11.5968 23.0271 11.5583 23.0995 11.5583Z"
      fill={fill}
    />
    <path
      d="M18.6863 16.1595H28.3268C28.4034 16.1595 28.4416 16.2011 28.4416 16.2845V17.2219C28.4416 17.3052 28.4034 17.3468 28.3268 17.3468H18.6863C18.6097 17.3468 18.5715 17.3052 18.5715 17.2219V16.2845C18.5715 16.2011 18.6097 16.1595 18.6863 16.1595Z"
      fill={fill}
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke={fill} />
  </svg>
);

export default AddSubtaskIcon;
