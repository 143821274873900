import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Overview from './Overview';
import Client from './Client';
import Workspace from './Workspace';

const Sales = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}/deals`} component={Overview} />
      <Route path={`${path}/workplace`} component={Workspace} exact={false} />
      <Route path={`${path}/client/:salesClientId`} component={Client} />
    </Switch>
  );
};

export default Sales;
