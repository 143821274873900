import axios from 'axios';
import { useState } from 'react';
import { isArray } from 'lodash';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { RefreshIcon } from '@heroicons/react/solid';
import useFetch from 'hooks/useFetch';
import Modal from 'components/Modal';
import PaymentForm from 'components/PaymentForm';
import PaymentMethodItem from './PaymentMethodItem';
import ButtonLink from 'components/ButtonLink';
import ReactTooltip from 'react-tooltip';

const PaymentMethodManager = ({
  agencyClientId,
  canEdit = true,
  canCreate = true,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const { data, reload, setReload } = useFetch(
    `/agency/clients/${agencyClientId}/payment-methods`
  );

  const createPaymentMethod = async ({ nonce }) => {
    await axios.post(`/agency/clients/${agencyClientId}/payment-methods`, {
      token: nonce,
    });

    setReload(!reload);
    setOpenModal(false);
  };

  const onSync = async () => {
    setSyncing(true);

    await axios.post(`/agency/clients/${agencyClientId}/payment-methods/sync`);

    setSyncing(false);
    setReload(true);
  };

  return (
    <div className="w-full">
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center px-8 pt-8">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              Add new Payment Method
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpenModal(false)}
            />
          </div>

          <div className="p-8 mt-5">
            <PaymentForm
              canCheckout={true}
              onAction={createPaymentMethod}
              submitText="Submit"
              buttonClassName="text-grayscale-300 tracking-2 leading-1.5 text-base font-bold bg-secondary rounded-40 py-2 px-20 hover:bg-secondary-light "
            />
          </div>
        </div>
      </Modal>

      <div className="flex items-center justify-between">
        <h3 className="font-inter text-base font-bold tracking-3/4 text-grayscale-900">
          Payment Methods
          {canCreate && (
            <ButtonLink
              classes="tracking-widest ml-2 relative -top-0.5"
              onClick={onSync}
              loading={syncing}
              showLoading={true}
              textSize="1xs"
            >
              <span
                className="text-xs relative top-1"
                data-tip
                data-for="btsync"
              >
                <RefreshIcon className="w-4 h-4" />
              </span>

              <ReactTooltip
                id="btsync"
                type="dark"
                effect="solid"
                place="bottom"
                data-delay-show
              >
                <span className="text-xs tracking-normal">
                  Sync card details from braintree
                </span>
              </ReactTooltip>
            </ButtonLink>
          )}
        </h3>
        {canCreate && (
          <button
            onClick={() => setOpenModal(true)}
            className="text-grayscale-300 tracking-3/4 leading-1.5 text-13 font-bold border-2 bg-secondary rounded-40 py-2 px-5 flex items-center space-x-1 hover:bg-secondary-light"
          >
            <PlusIcon className="w-3 h-3 text-grayscale-300" />
            <span>Add new</span>
          </button>
        )}
      </div>

      <ul className="mt-6 space-y-5">
        {isArray(data) &&
          data.map((paymentMethod) => (
            <PaymentMethodItem
              editable={canEdit}
              key={paymentMethod.globalId}
              paymentMethod={paymentMethod}
              agencyClientId={agencyClientId}
              onChange={() => setReload(!reload)}
            />
          ))}
      </ul>
    </div>
  );
};

export default PaymentMethodManager;
