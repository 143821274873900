import React, { useEffect, useState } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/outline';
import Label from 'components/Forms/Label';
import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';
import axios from 'axios';
import { Table } from 'components';

const SalesOfRepTable = ({
  selectedDates,
  salesRep,
  selectedPosition,
  selectedStatus,
}) => {
  const [sales, setSales] = useState({});
  const { salesPaginationParams } = useSelector;
  const { params, updateParams, sortParam } = useQueryParams({
    ...salesPaginationParams,
  });
  const [loading, setLoading] = useState(false);

  const getSales = async () => {
    setLoading(true);
    await axios
      .get(`/agency/sales/clients/byDate`, {
        params: {
          ...params,
          salesRep,
          statuses: selectedStatus,
          startDateStr: selectedDates.selectedStartDate,
          endDateStr: selectedDates.selectedEndDate,
          selectedPosition: selectedPosition,
        },
      })
      .then((res) => {
        setSales(res.data.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    updateParams({
      ...params,
      page: 1,
      fields: 'all',
      search: '',
    });
  }, []);

  useEffect(() => {
    async function getData() {
      await getSales();
    }
    getData();
  }, [params, selectedDates]);

  const tableColumns = [
    {
      dataField: 'lead.companyName',
      text: 'Company Name',
      sort: true,
      headerStyle: {
        minWidth: '200px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
    },
    {
      dataField: 'lead.lead',
      text: 'Sales First Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'lead.leadLastName',
      text: 'Sales Last Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerStyle: { textAlign: `right` },
      sort: true,
      formatter: (cell, row) => {
        return (
          <Link
            to={`/sales/client/${row.salesClientId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button data-tip="Profile">
              <UserCircleIcon className="m-1 h-5 w-5" color="green" />
            </button>
          </Link>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      ...params,
      page,
      pageSize: sizePerPage,
      sort: `${sortField ?? 'createdAt'}:${sortOrder}`,
    });
  };



  return (
    <>
      <Table
        columns={tableColumns}
        data={sales}
        onTableChange={onTableChange}
        params={params}
        keyField="companyName"
        defaultSorted={[
          {
            dataField: sortParam ? sortParam[0] : 'createdAt',
            order: sortParam ? sortParam[1] : 'desc',
          },
        ]}
        loading={loading}
      />
    </>
  );
};

export default SalesOfRepTable;
