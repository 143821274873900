import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Label from 'components/Forms/Label';

import { LinkIcon } from '@heroicons/react/outline';
import PotentialDuplicate from '../PotentialDuplicate';
import usePermissions from 'hooks/usePermissions';
import { STATUS_REQUIRED, PRE_PITCH_STATUSES } from '../../utils/constants';
import HubspotInfo from 'components/Forms/HubspotInfo';

const ContactDetails = ({
  initialValues,
  handleChange,
  onSearchLead,
  onUpdatePitchTemplate,
  values,
  setFieldValue,
  isDisabled,
  onSearchCompanyName,
  like,
  maxLike,
  setMaxLike,
  onContinueAnyway,
  onViewProfile,
  pitchCondition,
  liAccountsOptions,
  currentStatus,
  // onChangeSource,
}) => {
  const { userCan } = usePermissions();

  const isProfileLocked = [
    'Ready to Pitch',
    'Pitched',
    'Call-Booked',
    'Direct-Booking',
    'Lemlist Direct-Booking',
    'Accepted Positive',
    'Accepted Neutral',
    'Accepted Negative',
    'Accepted Disqualified',
  ].includes(initialValues.status);

  const RequiredField = ({ short }) => {
    return STATUS_REQUIRED.includes(currentStatus) ? (
      <span className="text-red-600 text-xs font-light tracking-tighter">
        {short ? '[*Req]' : '[*Required]'}
      </span>
    ) : (
      ''
    );
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Label>
            Lead First Name <RequiredField />
          </Label>
          <Field
            name="lead"
            placeholder="Lead First Name"
            className="form-select text-sm"
            onChange={(e) => (
              handleChange(e),
              onSearchLead(e),
              onUpdatePitchTemplate(e, 'lead', values, setFieldValue)
            )}
            type="text"
          />
        </div>
        <div className="col-span-6">
          <Label>
            Lead Last Name <RequiredField />
          </Label>
          <Field
            name="leadLastName"
            placeholder="Lead Last Name"
            className="form-select text-sm"
            onChange={(e) => (
              handleChange(e),
              //   onSearchLeadLastName(e),
              onUpdatePitchTemplate(e, 'leadLastName', values, setFieldValue)
            )}
            type="text"
          />
          <ErrorMessage
            name="leadLastName"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div className="col-span-12">
          <Label>
            Company Name <RequiredField />
          </Label>
          <Field
            name="companyName"
            placeholder="Company Name"
            className="form-select text-sm"
            onChange={(e) => (
              handleChange(e),
              onSearchCompanyName(e),
              onUpdatePitchTemplate(e, 'companyName', values, setFieldValue)
            )}
            type="text"
          />
        </div>

        <div className="col-span-12">
          <PotentialDuplicate
            like={like}
            onContinueAnyway={onContinueAnyway}
            maxLike={maxLike}
            setMaxLike={setMaxLike}
            onViewProfile={onViewProfile}
            pitchCondition={pitchCondition}
          />
        </div>

        <div className="col-span-6">
          <Label>Phone Number</Label>
          <Field
            name="phoneNumber"
            placeholder="Number"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="phoneNumber"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div className="col-span-6">
          <Label>Email</Label>
          <Field
            name="email"
            placeholder="Email"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="email"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div className="col-span-12">
          <Label>Address</Label>
          <Field
            name="address"
            placeholder="Address"
            className="form-select text-sm"
            onChange={(e) => (
              handleChange(e),
              onUpdatePitchTemplate(e, 'address', values, setFieldValue)
            )}
            type="text"
          />
        </div>
        <div className="col-span-6">
          <Label>
            Website{' '}
            {values.website && (
              <LinkIcon
                onClick={() => window.open(values.website, '_blank')}
                className="cursor-pointer ml-2 inline h-5 w-5"
                color="gray"
              />
            )}
          </Label>

          <Field
            name="website"
            placeholder="Website"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="website"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>

        <div className="col-span-6">
          <Label>
            Lead Source <RequiredField />{' '}
            {initialValues.hubspotDealId && <HubspotInfo />}
          </Label>
          <Field
            name="source"
            as="select"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            // onChange={(e) => {
            //   handleChange(e);
            //   onChangeSource(e.target.value, setFieldValue);
            // }}
            // disabled={
            //   isDisabled ||
            //   (isProfileLocked && !userCan('leads.upload.unassignedLeads'))
            // }
          >
            <option value="values.source">{values.source}</option>
            <option value="New Leads">New Leads</option>
            <option value="Unprocessed New Leads">Unprocessed New Leads</option>
            <option value="Old-Leads">Old-Leads</option>
          </Field>
        </div>
        <div className="col-span-6">
          <Label>
            Facebook URL
            {values?.facebook && (
              <LinkIcon
                onClick={() => window.open(values.facebook, '_blank')}
                className="cursor-pointer ml-2 inline h-5 w-5"
                color="gray"
              />
            )}
          </Label>
          <Field
            name="facebook"
            placeholder="Facebook URL"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="facebook"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div className="col-span-6">
          <Label>
            Instagram URL{' '}
            {values?.instagram && (
              <LinkIcon
                onClick={() => window.open(values.instagram, '_blank')}
                className="cursor-pointer ml-2 inline h-5 w-5"
                color="gray"
              />
            )}
          </Label>
          <Field
            name="instagram"
            placeholder="Instagram URL"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="instagram"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>

        <div className="col-span-6">
          <Label>
            LinkedIn Account
            <RequiredField />
          </Label>
          <Field
            name="linkedInAccountId"
            as="select"
            className="form-select text-sm"
            disabled={isDisabled || !userCan('leads.reassignlinkedin')}
            onChange={(e) => {
              handleChange(e);
              onUpdatePitchTemplate(
                e,
                'linkedInAccountId',
                values,
                setFieldValue
              );
            }}
          >
            <option value=""></option>
            {liAccountsOptions
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((rec, i) => {
                return (
                  <option key={i} value={rec.linkedInAccountId}>
                    {rec.name}
                  </option>
                );
              })}
          </Field>
        </div>

        <div className="col-span-6">
          <Label>
            LinkedIn Profile URL (none-Sales Navigator)
            <RequiredField />{' '}
            {values?.linkedInProfileURL && (
              <LinkIcon
                onClick={() => window.open(values.linkedInProfileURL, '_blank')}
                className="cursor-pointer ml-2 inline h-5 w-5"
                color="gray"
              />
            )}
          </Label>
          <Field
            name="linkedInProfileURL"
            placeholder="LinkedIn Profile URL"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="linkedInProfileURL"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div className="col-span-6">
          <Label>
            LinkedIn Profile Picture URL
            {/* <RequiredField /> */}
            {values?.linkedInProfilePictureURL && (
              <LinkIcon
                onClick={() =>
                  window.open(values.linkedInProfilePictureURL, '_blank')
                }
                className="cursor-pointer ml-2 inline h-5 w-5"
                color="gray"
              />
            )}
          </Label>
          <Field
            name="linkedInProfilePictureURL"
            placeholder="LinkedIn Profile Picture URL"
            className="form-select text-sm"
            onChange={(e) => handleChange(e)}
            type="text"
            disabled={isDisabled}
          />
          <ErrorMessage
            name="linkedInProfilePictureURL"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
