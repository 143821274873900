import axios from 'axios';
import moment from 'moment';
import { object, string } from 'yup';
import { omit, startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import ModalHeader from 'components/ModalHeader';

import classNames from 'utils/classNames';
import { setAlert } from 'features/alerts/alertsSlice';

const ExportButton = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  filters = {},
  comments = {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [type, setType] = useState('analytics');

  const onCancel = (e) => {
    e.preventDefault();
    setOpenModal(false);
  };

  const onSubmit = (values, actions) => {
    setLoading(true);

    let payload = {
      accountId,
      marketplace,
      startDate: moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD'),
      type,
      data: { ...omit(values, 'type'), comments },
      options: { filters },
    };

    if (type !== 'analytics') {
      payload.startDate = startDate;
      payload.endDate = endDate;
    }

    axios
      .post('/account/advertising/reports', payload)
      .then(() => {
        dispatch(
          setAlert(
            'success',
            'Export reports successful.',
            'Please go to reports page.'
          )
        );
      })
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        as={'div'}
        align="top"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title="Summary"
            titleClasses="text-base font-bold text-grayscale-800 tracking-2"
            setOpen={setOpenModal}
            showCloseButton={false}
          />

          <div className="px-4 pb-10">
            <div className="my-4">
              <Formik
                initialValues={{
                  type,
                  analysis: '',
                  pastAction: '',
                  futurePlanOfAction: '',
                  detailedReportLink: '',
                  challenge: '',
                  solution: '',
                  solution: '',
                }}
                onSubmit={onSubmit}
                validationSchema={object().shape({
                  analysis: string().when('type', (type, schema) => {
                    return ['analytics', 'interactive-analytics'].includes(type)
                      ? string().required('Required')
                      : schema;
                  }),
                  pastAction: string().when('type', (type, schema) => {
                    return ['analytics', 'interactive-analytics'].includes(type)
                      ? string().required('Required')
                      : schema;
                  }),
                  futurePlanOfAction: string().required('Required'),
                  detailedReportLink: string().when('type', (type, schema) => {
                    return ['analytics'].includes(type)
                      ? string().url('Must be a valid url')
                      : schema;
                  }),
                  challenge: string().when('type', (type, schema) => {
                    return ['detailed-analytics'].includes(type)
                      ? string().required('Required')
                      : schema;
                  }),
                  solution: string().when('type', (type, schema) => {
                    return ['detailed-analytics'].includes(type)
                      ? string().required('Required')
                      : schema;
                  }),
                  results: string().when('type', (type, schema) => {
                    return ['detailed-analytics'].includes(type)
                      ? string().required('Required')
                      : schema;
                  }),
                })}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <div className="grid grid-cols-12 gap-4">
                      {(type === 'detailed-analytics'
                        ? [
                            'challenge',
                            'solution',
                            'results',
                            'futurePlanOfAction',
                          ]
                        : ['analysis', 'pastAction', 'futurePlanOfAction']
                      ).map((key) => (
                        <div key={key} className="col-span-12">
                          <Label
                            textColor="text-grayscale-800"
                            textSize="mini"
                            fontWeight="font-bold"
                            classes="tracking-3/4"
                          >
                            {startCase(key)}
                          </Label>
                          <Field
                            name={key}
                            className="form-select text-mini"
                            as="textarea"
                          />
                          <ErrorMessage
                            name={key}
                            component="div"
                            className="text-error text-tiny"
                          />
                        </div>
                      ))}

                      {type === 'analytics' && (
                        <>
                          <div className="col-span-12">
                            <Label
                              textColor="text-grayscale-800"
                              textSize="mini"
                              fontWeight="font-bold"
                              classes="tracking-3/4"
                            >
                              Detailed PPC Reporting
                            </Label>
                            <Field
                              name="detailedReportLink"
                              className="form-select text-mini"
                            />
                            <ErrorMessage
                              name="detailedReportLink"
                              component="div"
                              className="text-error text-tiny"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="flex justify-between mt-5">
                      <button
                        onClick={(e) => {
                          onCancel(e);
                        }}
                        className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                      >
                        Cancel
                      </button>

                      <Button
                        type="submit"
                        roundedSize={40}
                        showLoading={true}
                        loading={isSubmitting}
                        disabled={!(isValid && dirty)}
                        classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                      >
                        Export
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="ml-5 inline-flex justify-center w-full rounded-3xl border-2 border-grayscale-700 shadow-sm px-12 py-4 bg-white text-mini text-grayscale-700 hover:bg-gray-50 font-bold tracking-2">
            Export
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setType('analytics');
                      setOpenModal(true);
                    }}
                    disabled={loading}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'text-left block px-4 py-2 text-sm w-full'
                    )}
                  >
                    Export Monthly Report
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setType('interactive-analytics');
                      setOpenModal(true);
                    }}
                    disabled={loading}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'text-left block px-4 py-2 text-sm w-full'
                    )}
                  >
                    Export Interactive Report
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setType('detailed-analytics');
                      setOpenModal(true);
                    }}
                    disabled={loading}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'text-left block px-4 py-2 text-sm w-full'
                    )}
                  >
                    Detailed PDF
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default ExportButton;
