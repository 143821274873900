import axios from 'axios';
import Checkbox from 'components/Forms/Checkbox';
import SlideOver from 'components/SlideOver';
import usePermissions from 'hooks/usePermissions';
import { startCase } from 'lodash';
import { useState } from 'react';
import { DocumentDownloadIcon } from '@heroicons/react/outline';

const Export = () => {
  const { userCan } = usePermissions();
  const [openExportSlideOver, setOpenExportSlideOver] = useState(false);
  const clientSummaryProperties = {
    client: true,
    contractSigned: false,
    onboardingDate: false,
    onboardedDate: false,
    service: false,
    accountStatus: false,
    status: false,
    address: false,
    website: false,
    amazonPageUrl: false,
    phone: false,
    contactName: false,
    contactName2: false,
    primaryEmail: false,
    secondaryEmail: false,
    thirdEmail: false,
    subscription: false,
    subscriptionType: false,
    activatedAt: false,
    isOffline: false,
    spApi: false,
    advApi: false,
    defaultMarketplace: false,
    firstName: false,
    lastName: false,
    email: false,
    assignees: false,
    firstInvoice: false,
  };
  const [clientSummaryCheckbox, setClientSummaryCheckbox] = useState(
    clientSummaryProperties
  );
  const [exporting, setExporting] = useState(false);

  const onExport = async () => {
    setExporting(true);
    const response = await axios.get('/agency/reports/clients-summary/export', {
      params: { ...clientSummaryCheckbox, isActiveOnly: true },
    });

    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
      encoding: 'UTF-8',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'clients-summary.csv';
    link.click();
    setExporting(false);
  };

  return (
    <>
      {userCan('reports.revenue.view') && userCan('clients.export_csv') && (
        <div className="ml-2 flex-shrink-0">
          <button
            onClick={() => setOpenExportSlideOver(true)}
            type="button"
            title="Export CSV"
          >
            <DocumentDownloadIcon className="w-5 h-5 text-gray-400" />
          </button>
        </div>
      )}
      <SlideOver
        open={openExportSlideOver}
        setOpen={setOpenExportSlideOver}
        title="Select fields to export"
      >
        {Object.keys(clientSummaryCheckbox).map((key, index) => {
          return (
            <div key={index}>
              <label className="flex-1 items-center text-sm text-gray-500">
                <Checkbox
                  id={key}
                  value={clientSummaryCheckbox[key]}
                  classes=" mr-2 rounded"
                  checked={clientSummaryCheckbox[key]}
                  onChange={() => {
                    setClientSummaryCheckbox({
                      ...clientSummaryCheckbox,
                      [key]: !clientSummaryCheckbox[key],
                    });
                  }}
                />
                {startCase(key)}
              </label>
            </div>
          );
        })}
        <button
          onClick={onExport}
          type="button"
          className="relative inline-flex items-center mt-6 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Export csv
        </button>
      </SlideOver>
    </>
  );
};
export default Export;
