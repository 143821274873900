import Badge from 'components/Badge';
import React, { useState, useEffect } from 'react';
import { dateFormatter, decimalFormatter } from 'utils/formatters';
import classnames from 'classnames';
import PaymentDetailsSlideOver from './PaymentDetailsSlideOver';
import { Link } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/solid';
import axios from 'axios';
import useQueryParams from 'hooks/useQueryParams';
import { Table } from 'components';
import { cloneDeep } from 'lodash';
import Exporter from './Exporter';

const Payments = () => {
  const { params, updateParams } = useQueryParams({
    page: 1,
    sizePerPage: 25,
    status: 'PaymentStatus.All',
  });
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState(null);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [filteredData, setFilteredData] = useState('');
  const [loading, setLoading] = useState(false);
  const statuses = [
    { label: 'All Success', value: 'PaymentStatus.All' },
    { label: 'Online - Success', value: 'PaymentStatus.OnlineSuccess' },
    { label: 'Online - Failure', value: 'PaymentStatus.OnlineFailure' },
    { label: 'Offline', value: 'PaymentType.Offline' },
  ];

  const tableColumns = [
    {
      dataField: 'transaction_time',
      text: 'Payment Date',
      sort: false,
      headerStyle: {
        width: '150px',
      },
      formatter: (cell, row) => (
        <span className="whitespace-pre">{dateFormatter(cell)}</span>
      ),
    },
    {
      dataField: 'payment_number',
      text: 'Payment #',
      sort: false,
      headerStyle: {
        width: '100px',
      },
      formatter: (cell, row) => {
        return (
          <button
            className="capitalize font-normal text-secondary-light"
            onClick={() => onClickDetails(row)}
          >
            {cell === '' ? 'view' : cell}
          </button>
        );
      },
    },
    {
      dataField: 'customer_name',
      text: 'Client Name',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => (
        <Link
          className="text-secondary-light"
          to={`/clients/zid/${row.customer_id}`}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'invoice_number',
      text: 'Invoice Number',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => (
        <Link
          className="text-secondary-light"
          to={`/invoices/no/${row.invoice_number}`}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'mode',
      text: 'Payment Method',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell) => {
        return (
          <p>
            <span className="text-grayscale-600 mr-1">$</span>
            {decimalFormatter(cell)}
          </p>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell) => {
        return (
          <Badge
            color={classnames({
              green: cell === 'success',
              red: cell === 'failure',
            })}
            textSize="1sm"
            classes="w-20 h-8 font-normal flex items-center justify-center"
            rounded="3xl"
            padding="py-2 px-4"
          >
            {cell === 'success' ? 'Approved' : 'Failed'}
          </Badge>
        );
      },
    },
  ];

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await axios
        .get(`/z/payments`, { params })
        .then((res) => {
          if (filteredData) {
            const results = res.data.data.rows.filter((row) => {
              if (filteredData === '') return row;
              return row.customer_name
                .replace(/\s+/g, '')
                .toLowerCase()
                .includes(filteredData.toLowerCase().replace(/\s+/g, '_'));
            });
            const newData = cloneDeep(res.data.data);
            newData.rows = results;
            setPayments(newData);
          } else {
            setPayments(res.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {})
        .finally(() => setLoading(false));
    };

    !loading && load();
  }, [params, filteredData]);

  const onClickDetails = (row) => {
    setIsOpenDetails(true);
    setPayment(row);
  };

  const updateStatus = (status) => {
    updateParams({ status, page: 1 });
  };

  const onChangeInput = (input) => {
    setFilteredData(input);
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, sizePerPage };
    updateParams(newParams);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-center gap-7 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate py-5 border-b-2 border-transparent">
            Payments
          </h2>

          <div className="flex flex-row sm:flex-col lg:flex-row h-12 gap-1">
            <select
              id="status"
              label="status"
              value={params.status}
              className="h-full rounded-l-xl w-40 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
              onChange={(e) => updateStatus(e.target.value)}
            >
              {statuses.map((status, i) => (
                <option
                  key={i}
                  value={status.value}
                  disabled={status.disabled ?? ''}
                  className={status.disabled ? 'bg-red-50' : ''}
                >
                  {status.label}
                </option>
              ))}
            </select>
            <div className="h-full relative">
              <div className="h-full absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">
                  <SearchIcon className="w-4 h-4" />
                </span>
              </div>
              <input
                type="text"
                className="h-full rounded-r-xl w-60 pl-12 border-0 sm:text-sm appearance-none focus:ring-0 focus:border-0 focus:appearance-none  disabled:bg-gray-100"
                placeholder={'Search'}
                onChange={(e) => onChangeInput(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Exporter />
      </div>

      <Table
        columns={tableColumns}
        data={payments}
        onTableChange={onTableChange}
        params={params}
        keyField="payment_id"
        loading={loading}
        paginationType="prevnext"
      />

      <PaymentDetailsSlideOver
        open={isOpenDetails}
        setOpen={setIsOpenDetails}
        payment={payment}
      />
    </>
  );
};
export default Payments;
