import { fetchClient } from 'features/clients/Client/agencyClientSlice';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAgencyClient = () => {
  const dispatch = useDispatch();

  const { agencyClient } = useSelector((state) => state.agencyClient);
  const account = agencyClient.account;

  const hasSpApiCredentials = () => {
    return (
      account &&
      account.credentials &&
      account.credentials.find((c) => c.service === 'spApi')
    );
  };

  const paymentModeZoho = () => {
    return agencyClient.paymentMode === 'zoho';
  };

  const paymentModeBraintree = () => {
    return (
      agencyClient.paymentMode === 'braintree' && agencyClient.btCustomerId
    );
  };

  const paymentModeOffline = () => {
    return (
      agencyClient.paymentMode === 'offline' ||
      agencyClient.paymentMode === 'ach' ||
      agencyClient.paymentMode === 'wire transfer'
    );
  };

  const reloadClient = () => {
    dispatch(fetchClient(agencyClient.agencyClientId));
  };

  const defaultMarketplace = useMemo(() => {
    return account.marketplaces.find((m) => m.isDefault === true);
  }, [account]);

  return {
    agencyClient,
    account,
    hasSpApiCredentials,
    paymentModeZoho,
    paymentModeBraintree,
    paymentModeOffline,
    reloadClient,
    defaultMarketplace,
  };
};

export default useAgencyClient;
