import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const commentSlice = createSlice({
  name: 'comment',
  initialState: {
    draftComment: null,
  },
  reducers: {
    setDraftComment: (state, action) => {
      state.draftComment = action.payload;
    },
  },
});

export const { setDraftComment } = commentSlice.actions;

export default commentSlice.reducer;
