import { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'utils/classNames';
import axios from 'axios';
import { capitalize, pick } from 'lodash';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import FormikErrorNotification from 'components/FormikErrorNotification';
import useMail from './useMail';

const MailBlockTemplateEditor = ({
  type,
  selectedTemplate,
  onSaveTemplate,
}) => {
  const qRef = useRef();
  const tabs = ['Use Rich Text', 'Use HTML'];
  const emptyInitialValues = {
    name: '',
    content: '',
    structure: null,
    text: '',
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const { alertError, alertSuccess } = useAlert();
  const {
    value,
    setValue,
    contentValue,
    setContentValue,
    formats,
    toolbarOptions,
    clipboard,
    onChangeContent,
  } = useMail(qRef);

  useEffect(() => {
    if (selectedTemplate) {
      setInitialValues(
        pick(selectedTemplate, ['name', 'content', 'structure', 'text'])
      );
      setValue(selectedTemplate.structure);
      setContentValue(selectedTemplate.content);
    }
  }, [selectedTemplate]);

  const validationSchema = object().shape({
    name: string().required('Name is required'),
  });

  const onChangeTab = (index, values, setFieldValue) => {
    if (index === 0) {
      setValue(values.content);
    } else if (index === 1) {
      setFieldValue('content', contentValue);
    }
    setSelectedIndex(index);
  };

  const onSubmit = async (values) => {
    setSaving(true);
    await axios
      .patch(
        `/agency/email-${type}/${
          selectedTemplate[`email${capitalize(type)}Id`]
        }`,
        {
          name: values.name,
          content: contentValue,
          structure: qRef.current.editor.getContents(),
          text: qRef.current.editor.getText(),
        }
      )
      .then((response) => {
        alertSuccess('Successfully saved', response.data.message);
        onSaveTemplate(response.data.data);
      })
      .catch((error) => {
        alertError('Failed to save', error.response.data.message);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form className="h-full p-5 flex flex-col">
            <FormikErrorNotification />
            <div className="flex flex-col justify-center">
              <Field
                name="name"
                placeholder="Template Name"
                className="form-select text-sm"
                type="text"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div className="flex justify-end pt-6">
              {tabs.map((category, index) => {
                return (
                  <button
                    type="button"
                    onClick={() => onChangeTab(index, values, setFieldValue)}
                    key={category}
                    className={classNames(
                      'px-8 py-2 text-sm font-medium leading-5 border-2 border-gray-600',
                      'ring-white ring-offset-2 ring-offset-white focus:outline-none focus:ring-0 tracking-widest',
                      index === 0 ? ' rounded-l-lg' : ' rounded-r-lg',
                      selectedIndex === index
                        ? 'bg-gray-600 shadow text-white'
                        : 'text-gray-600 hover:bg-white/[0.12] bg-white'
                    )}
                  >
                    {category}
                  </button>
                );
              })}
            </div>
            <div
              className={classNames(
                selectedIndex === 1 ? 'show-raw' : '',
                'flex-grow pt-3 overflow-y-auto'
              )}
            >
              <div className="scrolling-container">
                <div className="quill-mailblock-editor">
                  <ReactQuill
                    ref={qRef}
                    theme="snow"
                    placeholder="Enter template contents here"
                    value={value}
                    onChange={onChangeContent}
                    bounds={`.quill-mailblock-editor`}
                    modules={{
                      toolbar: toolbarOptions,
                      clipboard: clipboard,
                      imageActions: {},
                      imageFormats: {},
                    }}
                    formats={formats}
                    scrollingContainer=".scrolling-container"
                  />
                </div>
              </div>
              <div className={selectedIndex === 1 ? 'block' : 'hidden'}>
                <Field
                  name="content"
                  as="textarea"
                  rows={5}
                  className="form-input disabled-white raw-editor"
                />
              </div>
            </div>
            <div className="flex justify-end sticky bottom-0">
              {selectedIndex === 1 && (
                <button
                  className="pr-6 font-bold tracking-widest text-custom-sky"
                  onClick={() => onChangeTab(0, values, setFieldValue)}
                >
                  Preview
                </button>
              )}

              <Button
                classes={classNames('border-0 font-bold tracking-widest')}
                bgColor="blue-900"
                hoverColor="blue-500"
                roundedSize="full"
                textColor="white"
                px={10}
                py={1.5}
                shadow=""
                textSize=""
                showLoading={true}
                loading={saving}
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MailBlockTemplateEditor;
