import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, number, date, array, ref, min } from 'yup';
import {
  PlusIcon,
  TrashIcon as TrashIconOutline,
} from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import InputAppend from 'components/Forms/InputAppend';
import InputPrepend from 'components/Forms/InputPrepend';
import Label from 'components/Forms/Label';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import ButtonLink from 'components/ButtonLink';
import {
  COMMISSION_TYPES,
  DEFAULT_MARKETPLACE,
  TYPE_DESCRIPTIONS,
} from 'utils/constants';
import Toggle from 'components/Forms/Toggle';
import { isEmpty } from 'lodash';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { setAlert } from 'features/alerts/alertsSlice';
import { getLatestUpdates } from '../salesSlice';
import usePermissions from 'hooks/usePermissions';

const QuoteModal = ({ open, setOpen, payload, reloadQuotes }) => {
  const { userCan } = usePermissions();
  const disabled = !userCan('sales.profiles.quotes.manage');
  const { addons, marketplaces } = useSelector((state) => state.clients);
  const { plans } = useSelector((state) => state.zoho);
  const { client } = useSelector((state) => state.sales);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const emptyInitialValues = {
    salesClientId: client?.salesClientId,
    service: process.env.REACT_APP_PLAN_CODE,
    noOfParentAsins: 0,
    noOfChildAsins: 0,
    marketplace: DEFAULT_MARKETPLACE,
    monthlyRetainer:
      plans && plans.length > 0
        ? plans.filter(
            (i) => i.plan_code === process.env.REACT_APP_PLAN_CODE
          )[0].recurring_price
        : 0,
    commissionType: 'gross',
    rate: 0,
    monthThreshhold: 0,
    baseline: '',
    managedAsins: [],
    tieredRules: [],
    autoAdd: true,
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const getQuote = async (id) => {
    setLoading(true);
    await axios.get(`/agency/sales/callBookings/quotes/${id}`).then((res) => {
      const {
        service,
        noOfParentAsins,
        noOfChildAsins,
        marketplace,
        monthlyRetainer,
        commissions: {
          type: commissionType,
          rate,
          autoAdd,
          monthThreshhold,
          baseline,
          tieredRules,
        },
        managedAsins,
      } = res.data.data;

      setInitialValues({
        salesClientId: client.salesClientId,
        service,
        noOfParentAsins,
        noOfChildAsins,
        marketplace,
        monthlyRetainer,
        commissionType,
        rate,
        monthThreshhold,
        baseline,
        managedAsins,
        tieredRules,
        autoAdd,
      });
    });
    setLoading(false);
  };

  useEffect(() => {
    if (payload.action === 'edit') {
      getQuote(payload.salesQuoteId);
    } else {
      setInitialValues(emptyInitialValues);
    }
  }, [payload]);

  const managedAsinObj = {
    asin: '',
    baseline: '',
    startDate: null,
    endDate: null,
  };

  const tieredRuleObj = {
    minGross: '',
    maxGross: '',
    rate: '',
  };

  const validationSchema = object().shape({
    salesClientId: string().required('Required'),
    service: string().required('Service is required'),
    noOfParentAsins: number().min(0).integer('Invalid value').nullable(),
    noOfChildAsins: number().min(0).integer('Invalid value').nullable(),
    marketplace: string().required('Required'),
    monthlyRetainer: number().min(0).required('Required'),
    commissionType: string().required('Required'),
    rate: number().min(0).required('Required'),
    monthThreshhold: number()
      .min(0)
      .nullable()
      .when('commissionType', {
        is: (commissionType) =>
          ['benchmark', 'rolling'].includes(commissionType),
        then: number().required('Required'),
      }),
    baseline: number().min(0).nullable(),
    managedAsins: array()
      .of(
        object().shape({
          asin: string()
            .trim()
            .matches(/([A-Z0-9]{10})/, 'Incorrect ASIN format')
            .required('Required'),
          baseline: number().nullable(),
          startDate: date().nullable(),
          endDate: date()
            .nullable()
            .when('startDate', {
              is: (startDate) => !!startDate,
              then: date()
                .min(ref('startDate'), 'End Date is earlier')
                .required('Required'),
            }),
        })
      )
      .nullable(),
    tieredRules: array()
      .of(
        object().shape({
          minGross: number().min(0, 'minimum value is 0').required('Required'),
          maxGross: number()
            .min(ref('minGross'), 'must be >= minGross')
            .required('Required'),
          rate: number().min(0).required('Required'),
        })
      )
      .nullable()
      .when('commissionType', {
        is: 'tiered',
        then: array().required().min(1),
      }),
  });

  const onServiceChange = (e, setFieldValue) => {
    const selectedItem = plans.filter((i) => i.plan_code === e.target.value)[0];
    setFieldValue(`monthlyRetainer`, selectedItem.recurring_price);
  };

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const url =
        payload.action === 'add'
          ? '/agency/sales/callBookings/quotes'
          : `/agency/sales/callBookings/quotes/${payload.salesQuoteId}`;

      const response = await axios.post(url, values);
      if (response.data.success) {
        dispatch(setAlert('success', `Sales quote ${payload.action}ed`));
        dispatch(getLatestUpdates(client.salesClientId));
        setSaving(false);
        reloadQuotes();
        setOpen(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  const onDeleteQuote = async () => {
    try {
      setSaving(true);
      const response = await axios.delete(
        `/agency/sales/callBookings/quotes/${payload.salesQuoteId}`
      );
      if (response.data.success) {
        dispatch(setAlert('success', `Sales quote removed`));
        dispatch(getLatestUpdates(client.salesClientId));
        setSaving(false);
        reloadQuotes();
        setOpen(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      zIndex="z-20"
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4">
              <span>Monthly Retainer</span>
              {payload.action === 'edit' && !disabled && (
                <ButtonLink onClick={onDeleteQuote}>
                  <TrashIconOutline className="w-5 h-5 text-gray-500 hover:text-red-700" />
                </ButtonLink>
              )}
            </div>
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div
                className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto"
                style={{ height: '50vh' }}
              >
                <div className="col-span-2">
                  <Label>Service</Label>
                  <Field
                    name="service"
                    as="select"
                    className="form-select disabled-white"
                    onChange={(e) => {
                      onServiceChange(e, setFieldValue);
                      handleChange(e);
                    }}
                    disabled={disabled}
                  >
                    {plans &&
                      plans.map((plan) => (
                        <option key={plan.plan_code} value={plan.plan_code}>
                          {plan.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="service"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-1">
                  <Label># of Parent ASINs</Label>
                  <Field
                    name="noOfParentAsins"
                    placeholder="Enter Number"
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="number"
                    step="1"
                    min="0"
                    disabled={disabled}
                  />
                  <ErrorMessage
                    name="noOfParentAsins"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-1">
                  <Label># of Child ASINs</Label>
                  <Field
                    name="noOfChildAsins"
                    placeholder="Enter Number"
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="number"
                    step="1"
                    min="0"
                    disabled={disabled}
                  />
                  <ErrorMessage
                    name="noOfChildAsins"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label>Marketplace</Label>
                  <Field
                    name="marketplace"
                    as="select"
                    className="form-select disabled-white"
                    disabled={disabled}
                  >
                    {marketplaces &&
                      marketplaces.map((marketplace) => (
                        <option
                          key={marketplace.marketplaceId}
                          value={marketplace.marketplaceId}
                        >
                          {marketplace.countryCode} - {marketplace.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="marketplace"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label>Monthly Retainer</Label>
                  <Field name="monthlyRetainer">
                    {({ field, form: { touched, errors }, meta }) => (
                      <>
                        <InputPrepend
                          type="number"
                          placeholder="0"
                          prependText="$"
                          border="border-white"
                          classes="bg-gray-50 disabled-white"
                          {...field}
                          disabled={disabled}
                        />
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="monthlyRetainer"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <hr className="my-2" />
                </div>
                <div className="col-span-2 ">
                  <h3 className="leading-6 text-gray-800 capitalize text-xl font-bold">
                    Commission
                  </h3>
                </div>
                <div className="col-span-2 ">
                  <Label>Type</Label>
                  <Field
                    name="commissionType"
                    as="select"
                    className="form-select disabled-white"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={disabled}
                  >
                    {COMMISSION_TYPES.map((commission_type, i) => (
                      <option key={i} value={commission_type.value}>
                        {commission_type.label}
                      </option>
                    ))}
                  </Field>
                  <sub className="text-xs indent-2 text-yellow-500 italic">
                    {TYPE_DESCRIPTIONS[values.commissionType]}
                  </sub>
                  <ErrorMessage
                    name="commissionType"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                {values.commissionType !== 'tiered' && (
                  <div className="col-span-2">
                    <Label>Rate</Label>
                    <Field name="rate">
                      {({ field, form: { touched, errors }, meta }) => (
                        <>
                          <InputAppend
                            type="number"
                            placeholder="0"
                            appendText="%"
                            border="border-white"
                            classes="bg-gray-50 disabled-white"
                            {...field}
                            disabled={disabled}
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="rate"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
                {(values.commissionType === 'benchmark' ||
                  values.commissionType === 'rolling') && (
                  <div className="col-span-2">
                    <Label>Month Threshold</Label>
                    <Field
                      name="monthThreshhold"
                      placeholder="No. of months"
                      className="form-input disabled-white"
                      onChange={(e) => handleChange(e)}
                      type="number"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="monthThreshhold"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
                {values.commissionType === 'benchmark' &&
                  values.managedAsins.length < 1 && (
                    <div className="col-span-2">
                      <Label>Baseline</Label>
                      <Field
                        name="baseline"
                        placeholder="Enter Baseline Avg. Sales"
                        className="form-input disabled-white"
                        onChange={(e) => handleChange(e)}
                        type="number"
                        disabled={disabled}
                      />
                      <ErrorMessage
                        name="baseline"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  )}
                <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
                  <span className="">Automatically add to pending invoice</span>
                  <Toggle
                    onChange={(e) => setFieldValue('autoAdd', e)}
                    checked={values.autoAdd}
                    disabled={disabled}
                  />
                </div>
                {values.commissionType === 'tiered' && (
                  <FieldArray name="tieredRules">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="col-span-2">
                          <hr className="my-2" />
                        </div>

                        {values.tieredRules.length > 0 && (
                          <div className="col-span-2 space-y-3 mb-4">
                            <span className="font-inter font-bold text-base">
                              Rules
                            </span>
                            {values.tieredRules.map((tieredRule, index) => (
                              <div
                                key={index}
                                className="sm:grid grid-cols-3 gap-2"
                              >
                                <div className="col-span-3 flex justify-between">
                                  <Label
                                    fontWeight="font-bold"
                                    textColor="text-gray-500"
                                  >
                                    Rule {index + 1}
                                  </Label>
                                  <span
                                    onClick={() => remove(index)}
                                    className="cursor-pointer text-gray-500 hover:text-red-500"
                                  >
                                    <TrashIcon className="w-4 h-4 " />
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <Label>Min. Gross</Label>
                                  <Field
                                    name={`tieredRules.${index}.minGross`}
                                    placeholder="Enter Min. Gross"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="number"
                                    disabled={disabled}
                                  />
                                  <ErrorMessage
                                    name={`tieredRules.${index}.minGross`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <Label>Max. Gross</Label>
                                  <Field
                                    name={`tieredRules.${index}.maxGross`}
                                    placeholder="Enter Max. Gross"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="number"
                                    disabled={disabled}
                                  />
                                  <ErrorMessage
                                    name={`tieredRules.${index}.maxGross`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <Label>Rate</Label>
                                  <Field name={`tieredRules.${index}.rate`}>
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputAppend
                                          type="number"
                                          placeholder="0"
                                          appendText="%"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          {...field}
                                          disabled={disabled}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name={`tieredRules.${index}.rate`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        {!disabled && (
                          <div className="col-span-2">
                            <Button
                              classes="font-bold tracking-wider w-full justify-center"
                              bgColor="gray-50"
                              hoverColor="gray-200"
                              roundedSize="2xl"
                              textColor="gray-600"
                              border="border-2 border-gray-600"
                              py={1}
                              shadow=""
                              onClick={(e) => {
                                push(tieredRuleObj);
                              }}
                            >
                              <PlusIcon className="w-4 h-4 inline" /> Add Rule
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}
                {values.commissionType === 'tiered' &&
                  values.tieredRules.length < 1 && (
                    <ErrorMessage
                      name={`tieredRules`}
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  )}

                <div className="col-span-2">
                  <hr className="my-2" />
                </div>

                <FieldArray name="managedAsins">
                  {({ insert, remove, push }) => (
                    <>
                      {values.managedAsins.length > 0 && (
                        <div className="col-span-2 space-y-3 mb-4">
                          <span className="font-inter font-bold text-base">
                            Managed ASIN
                          </span>
                          {values.managedAsins.map((managedAsin, index) => (
                            <div
                              key={index}
                              className="sm:grid grid-cols-3 gap-x-2 "
                            >
                              <div className="col-span-3 flex justify-between">
                                <Label
                                  fontWeight="font-bold"
                                  textColor="text-gray-500"
                                >
                                  ASIN {index + 1}
                                </Label>
                                {!disabled && (
                                  <span
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    className="cursor-pointer text-gray-500 hover:text-red-500"
                                  >
                                    <TrashIcon className="w-4 h-4 " />
                                  </span>
                                )}
                              </div>
                              <div className="col-span-3">
                                {values.commissionType === 'benchmark' && (
                                  <Label>ASIN</Label>
                                )}
                                <Field
                                  name={`managedAsins.${index}.asin`}
                                  placeholder="Enter ASIN"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                />
                                <ErrorMessage
                                  name={`managedAsins.${index}.asin`}
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              {values.commissionType === 'benchmark' && (
                                <>
                                  <div className="col-span-1">
                                    <Label>Start Date</Label>
                                    <Field
                                      name={`managedAsins.${index}.startDate`}
                                      placeholder="Select Date"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="date"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.startDate`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>End Date</Label>
                                    <Field
                                      name={`managedAsins.${index}.endDate`}
                                      placeholder="Select Date"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="date"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.endDate`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>Baseline</Label>
                                    <Field
                                      name={`managedAsins.${index}.baseline`}
                                      placeholder="Baseline Avg. Sales"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="text"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.baseline`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {!disabled && (
                        <div className="col-span-2">
                          <Button
                            classes="font-bold tracking-wider w-full justify-center"
                            bgColor="gray-50"
                            hoverColor="gray-200"
                            roundedSize="2xl"
                            textColor="gray-600"
                            border="border-2 border-gray-600"
                            py={1}
                            shadow=""
                            onClick={(e) => {
                              push(managedAsinObj);
                            }}
                          >
                            <PlusIcon className="w-4 h-4 inline" /> Add Managed
                            Asin
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      {disabled ? 'Close' : 'Cancel'}
                    </ButtonLink>
                    {!disabled && (
                      <Button
                        classes="border-0 font-bold tracking-wider "
                        bgColor="red-700"
                        hoverColor="red-400"
                        roundedSize="2xl"
                        textColor="white"
                        px={12}
                        py={1.5}
                        shadow=""
                        type="submit"
                        showLoading={true}
                        loading={saving}
                      >
                        {payload.action === 'add' ? 'Add' : 'Update'}
                      </Button>
                    )}
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default QuoteModal;
