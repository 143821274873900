import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'utils/classNames';

const EntitiesTable = ({ account, marketplace }) => {
  const [data, setData] = useState([]);

  const campaignTypesShortHand = {
    sponsoredProducts: 'SP',
    sponsoredBrands: 'SB',
    sponsoredDisplay: 'SD',
  };

  const records = [
    {
      display: 'Campaigns',
      recordType: 'campaigns',
      campaignTypes: [
        'sponsoredProducts',
        'sponsoredBrands',
        'sponsoredDisplay',
      ],
    },
    {
      display: 'Ad Groups',
      recordType: 'adGroups',
      campaignTypes: [
        'sponsoredProducts',
        'sponsoredBrands',
        'sponsoredDisplay',
      ],
    },
    {
      display: 'Keyword Targeting',
      recordType: 'keywords',
      campaignTypes: ['sponsoredProducts', 'sponsoredBrands'],
    },
    {
      display: 'Product Targeting',
      recordType: 'targets',
      campaignTypes: ['sponsoredProducts', 'sponsoredDisplay'],
    },
    {
      display: 'Campaign Negative Keywords',
      recordType: 'campaignNegativeKeywords',
      campaignTypes: ['sponsoredProducts'],
    },
    {
      display: 'Negative Keywords',
      recordType: 'negativeKeywords',
      campaignTypes: ['sponsoredProducts'],
    },
    {
      display: 'Negative Targets',
      recordType: 'negativeTargets',
      campaignTypes: ['sponsoredProducts'],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/account/advertising/sync/entities', {
        params: {
          marketplace: marketplace.details.countryCode,
          accountId: account.accountId,
        },
      });

      setData(response.data.data);
    };

    fetchData();
  }, [account, marketplace]);

  return (
    <div className="col-span-10 md:col-span-3 bg-white rounded-xl bs-shadow my-2">
      <p className="font-bold text-13 font-inter tracking-3/4 leading-1.5 text-grayscale-900 border-b border-grayscale-500 p-4">
        Campaign Entities Synced
      </p>

      <div className="p-4">
        {records.map((record) => (
          <div
            key={`advertising-sync-entities-${record.recordType}`}
            className="grid grid-cols-12 flex items-center py-1.5"
          >
            <p className="col-span-8 text-grayscale-700 text-13">
              {record.display}
            </p>
            <div className="flex col-span-4 justify-end">
              {['sponsoredProducts', 'sponsoredBrands', 'sponsoredDisplay'].map(
                (campaignType) => {
                  const sync = data.find(
                    (i) =>
                      i.recordType === record.recordType &&
                      i.campaignType === campaignType
                  );

                  return (
                    <div
                      key={`advertising-sync-entities-${record.recordType}-${campaignType}`}
                      className={
                        record.campaignTypes.includes(campaignType)
                          ? ''
                          : 'hidden'
                      }
                    >
                      <CheckCircleIcon
                        data-tip={
                          sync
                            ? `Last synced at: ${moment(sync.syncAt).format(
                                ' MMMM Do, h:mm:ss a'
                              )}`
                            : 'Not yet sync'
                        }
                        className={classNames(
                          'w-5 h-5',
                          !!sync ? 'text-success-dark' : 'text-grayscale-600'
                        )}
                      />

                      <ReactTooltip
                        place="top"
                        className="max-w-xs text-black"
                        backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                        textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                      />

                      <p className="text-11 text-grayscale-700">
                        {campaignTypesShortHand[campaignType]}
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntitiesTable;
