import { DateTime } from 'luxon';
import Loading from 'components/Loading';
import { nameFormatter } from 'utils/formatters';
import useAsset from 'hooks/useAsset';

const QuoteBody = ({ quote }) => {
  const { ASSET_URL } = useAsset();
  return (
    <main className="main-content flex-1 relative overflow-y-auto print:overflow-y-visible focus:outline-none preview-page">
      <div className="main-content-inner1 flex justify-center">
        <div className="w-11/12 xl:w-5/12 py-10 flex flex-col">
          {quote ? (
            <>
              <img src={`${ASSET_URL}/quote-header.png`} className="w-full" />
              <table className="table-responsive text-lg my-4">
                <tbody>
                  <tr>
                    <td className="p-5 leading-8">
                      <p>
                        <strong className="underline">RECIPIENT DETAILS</strong>
                      </p>
                      <p>
                        <strong>Company name:</strong>{' '}
                        {quote.salesClient.lead.companyName}
                      </p>
                      <p>
                        <strong>Full name:</strong>{' '}
                        {quote.salesClient.decisionMaker}
                      </p>
                      <p>
                        <strong>Email address:</strong>{' '}
                        {quote.salesClient.email}
                      </p>
                      <p>
                        <strong>Phone number:</strong>{' '}
                        {quote.salesClient.lead.phoneNumber}
                      </p>
                    </td>
                    <td className="p-5 leading-8">
                      <p>
                        <strong className="underline">
                          QUOTATION DETAILS:
                        </strong>
                      </p>
                      <p>
                        <strong>Quote #:&nbsp;</strong>
                        {quote.quoteNumber.padStart(5, '0')}
                      </p>
                      <p>
                        <strong>Created by:&nbsp;</strong>
                        {nameFormatter(quote?.creator)}
                      </p>
                      <p>
                        <strong>Date created: </strong>
                        {DateTime.fromISO(quote.createdAt).toFormat(
                          'LL/dd/yyyy'
                        )}
                      </p>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <p>&nbsp;</p>
              <hr />
              <div
                className="text-lg mt-8"
                dangerouslySetInnerHTML={{ __html: quote?.quoteContent }}
              ></div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </main>
  );
};
export default QuoteBody;
