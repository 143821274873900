import { Menu } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { currencyFormatter } from 'utils/formatters';
import Button from 'components/Button';

const RevenueWeightButton = ({ title, revenueDetailData, setOpen }) => {
  const onOpenEditWeightModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div>
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex">
            <Menu.Button className="flex">
              <ExclamationCircleIcon className="w-4 h-4 inline text-gray-500" />
            </Menu.Button>
          </div>
          <Menu.Items className="absolute bottom-12 -right-9 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              <Menu.Item>
                {({ active }) => (
                  <div className="flex flex-col p-4">
                    <div className="pb-1">
                      <p className="text-xl text-grayscale-800 font-bold pb-4">
                        {title}
                      </p>
                      <p className="text-grayscale-800 font-bold text-base">
                        Company Currency
                      </p>
                    </div>
                    <div className="flex flex-row justify-between pb-1">
                      <p className="text-base font-normal">Total</p>
                      <p className="text-base font-normal">
                        {currencyFormatter(revenueDetailData?.totalAmount)}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between pb-2">
                      <p className="font-normal text-13 text-grayscale-900">
                        Weighted: {` (${revenueDetailData?.percentage}%) `}
                      </p>
                      <p className="text-base font-normal">
                        {currencyFormatter(
                          (revenueDetailData?.totalAmount *
                            revenueDetailData?.percentage) /
                            100
                        )}
                      </p>
                    </div>
                    <div className="flex flex-row justify-center">
                      <Button
                        classes="border-2 font-bold tracking-widest border-blue-900"
                        bgColor="transparent"
                        hoverColor="bule-200"
                        roundedSize="full"
                        textColor="blue-900"
                        px={8}
                        py={1}
                        shadow=""
                        textSize="xs"
                        onClick={() => onOpenEditWeightModal(true)}
                      >
                        Edit Weighted
                      </Button>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
export default RevenueWeightButton;
