import { keys } from 'lodash';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { useParams, withRouter } from 'react-router-dom';

import { ChevronDownIcon } from '@heroicons/react/solid';

import classNames from 'utils/classNames';

const MarketplacePicker = ({ history, match }) => {
  const params = useParams();
  const { account, marketplace } = useSelector((state) => state.account);

  const onSelectMarketplace = (option) => {
    const oldPath = match.url;
    let newPath = match.path;

    let newParams = { ...params, marketplace: option.details.countryCode };

    keys(newParams).forEach((paramsKey) => {
      newPath = newPath.replace(`:${paramsKey}`, newParams[paramsKey]);
    });

    history.push(history.location.pathname.replace(oldPath, newPath));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white px-5 py-3.5 text-mini text-grayscale-900 focus:outline-none rounded-2xl">
          {marketplace.details.name}
          <ChevronDownIcon
            className="ml-4 -mr-1 h-5 w-5 text-grayscale-800"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {account.marketplaces.map((option) => (
              <Menu.Item key={option.marketplaceId}>
                <button
                  onClick={() => onSelectMarketplace(option)}
                  className={classNames(
                    option.details.name === marketplace.details.name
                      ? 'bg-grayscale-400'
                      : 'bg-white',
                    'group flex w-full items-center rounded-md px-2 py-2 text-mini text-grayscale-900'
                  )}
                >
                  {option.details.name}
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default withRouter(MarketplacePicker);
