import React from 'react';
import logo from 'assets/logos/logo-orange.png';
import './privacypolicy.scss';

const Eula = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <main
        id="privacy-policy"
        className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 "
      >
        <article class="c4 doc-content eula py-32">
          <a href="/" className="py-10">
            <img
              className="mx-auto h-12 w-auto rounded-md"
              src={logo}
              alt="Logo"
            />
          </a>
          <p class="c0">
            <span class="c1">END-USER LICENSE AGREEMENT</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              This End-User License Agreement (&quot;EULA&quot;) is a legal
              agreement between you (either an individual or a single entity)
              and Seller Interactive, a Canadian corporation, having its
              principal place of business at 7676 Woodbine Ave #2, Markham, ON
              L3R 2N2, Canada, for the Seller Interactive software product.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">1. GRANT OF LICENSE</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              Subject to the terms and conditions of this EULA, Seller
              Interactive grants you a non-exclusive, non-transferable license
              to use the Seller Interactive software. This license is granted
              for the sole purpose of enabling you to use and enjoy the benefits
              of the software, subject to the restrictions set forth in Section
              2.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">2. RESTRICTIONS</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">You may not:</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">a. Copy, modify, or distribute the software.</span>
          </p>
          <p class="c0">
            <span class="c1">
              b. Reverse engineer, decompile, or disassemble the software.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              c. Remove any copyright or proprietary notices from the software.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">3. DISCLAIMER OF WARRANTY</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              The software is provided &quot;as is&quot; without warranty of any
              kind, either express or implied, including, but not limited to,
              the implied warranties of merchantability, fitness for a
              particular purpose, or non-infringement. The entire risk as to the
              quality and performance of the software is with you.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">4. LIMITATION OF LIABILITY</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              In no event shall Seller Interactive, its directors, officers,
              employees, or agents be liable for any consequential, incidental,
              direct, indirect, special, punitive, or other damages whatsoever,
              arising out of or in connection with the use or performance of the
              software.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">5. TERMINATION</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              This EULA is effective until terminated. Your rights under this
              license will terminate automatically without notice from Seller
              Interactive if you fail to comply with any term of this EULA.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">6. GOVERNING LAW</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              This EULA shall be governed by and construed in accordance with
              the laws of Canada. Any legal action or proceeding arising under
              this EULA will be brought exclusively in the federal or state
              courts located in Canada, and the parties consent to the personal
              jurisdiction and venue there.
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">7. CONTACT INFORMATION</span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">
              If you have any questions regarding this EULA, you may contact
              Seller Interactive at:
            </span>
          </p>
          <p class="c0 c3">
            <span class="c2"></span>
          </p>
          <p class="c0">
            <span class="c1">Seller Interactive</span>
          </p>
          <p class="c0">
            <span class="c1">
              7676 Woodbine Ave #2, Markham, ON L3R 2N2, Canada
            </span>
          </p>
          <p class="c0">
            <span class="c1">hello@sellerinteractive.com</span>
          </p>
          <p class="c0">
            <span class="c5">+1 800-820-3746</span>
          </p>
        </article>
      </main>
    </div>
  );
};

export default Eula;
