const SearchBar = ({ defaultValue = '', onChange }) => {
  return (
    <div className="relative rounded-2xl bg-grayscale-400">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9692 11.4637L16.3954 15.89L16.3955 15.8901C16.4625 15.9572 16.5 16.048 16.5 16.1428C16.5 16.2375 16.4623 16.3284 16.3952 16.3954C16.3282 16.4624 16.2373 16.5 16.1425 16.5C16.0478 16.5 15.9569 16.4623 15.8899 16.3952L15.8898 16.3951L11.317 11.8222L11.0055 11.5107L10.6572 11.7805C9.39078 12.7614 7.7982 13.223 6.20348 13.0714C4.60875 12.9198 3.13168 12.1664 2.07274 10.9643C1.0138 9.76231 0.452544 8.20201 0.503147 6.60085C0.553751 4.99968 1.21241 3.47794 2.34514 2.34518C3.47787 1.21243 4.99957 0.553752 6.60069 0.503147C8.2018 0.452543 9.76205 1.01381 10.964 2.07278C12.166 3.13174 12.9195 4.60885 13.0711 6.20363C13.2227 7.7984 12.7611 9.39103 11.7802 10.6575L11.1558 11.4637H11.9692ZM6.80032 12.3995C8.28547 12.3995 9.70979 11.8095 10.7599 10.7594C11.8101 9.70917 12.4001 8.28481 12.4001 6.79964C12.4001 5.31446 11.8101 3.8901 10.7599 2.83992C9.70979 1.78974 8.28547 1.19975 6.80032 1.19975C5.31516 1.19975 3.89084 1.78974 2.84068 2.83992C1.79052 3.8901 1.20055 5.31446 1.20055 6.79964C1.20055 8.28481 1.79052 9.70917 2.84068 10.7594C3.89084 11.8095 5.31516 12.3995 6.80032 12.3995Z"
            fill="#949494"
            stroke="#666666"
          />
        </svg>
      </div>

      <input
        type="search"
        onChange={onChange}
        defaultValue={defaultValue}
        className="py-3 border-0 block w-full bg-grayscale-400 rounded-2xl border-gray-300 pl-10 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-13 placeholder-grayscale-600 text-grayscale-800 leading-1.5"
      />
    </div>
  );
};

export default SearchBar;
