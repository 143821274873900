import { isFunction } from 'lodash';

const CustomTooltip = ({
  active,
  payload,
  label,
  containerClassName = '',
  itemsClassName = '',
  containerStyle = {},
  colorId = 'fill',
}) => {
  if (active && payload && payload.length) {
    return (
      <div style={containerStyle} className={containerClassName}>
        <p className="text-11 text-grayscale-700 leading-1.5 mb-3">{label}</p>

        <div className={itemsClassName}>
          {payload.map((item, idx) => (
            <div
              className="flex items-center my-2"
              key={`${item.dataKey}-${idx}`}
            >
              <div
                className="w-3 h-3 mr-1"
                style={{ backgroundColor: item[colorId] }}
              ></div>
              <p className="text-13 leading-1.5 text-grayscale-800">
                {item.name}:{' '}
                {isFunction(item.formatter)
                  ? item.formatter(item.value)
                  : item.value}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
