import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { isNumber } from 'lodash';
import usePermissions from 'hooks/usePermissions';
import MetricsCard from './components/MetricsCard';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { numberFormatter } from 'utils/formatters';
import { setRange } from 'features/datePicker/datePickerSlice';
import MetricsTable from './components/MetricsTable';
import axios from 'axios';
import moment from 'moment';
import Loading from 'components/Loading';
import DatePicker from 'features/datePicker/DatePicker';
import Spinner from 'components/Spinner';
import Input from 'components/Forms/Input';
import classNames from 'utils/classNames';
import TabNav from 'components/TabNav';
import PageHeader from 'components/PageHeader';
import LeadSourcesTable from './components/LeadSourcesTable';
import MetricsOverview from './components/MetricsOverview';

const Metrics = () => {
  const { userCan, isAgencySuperUser } = usePermissions();
  const dispatch = useDispatch();
  const onTabChange = (tab) => {};

  const { startDate, endDate } = useSelector((state) => state.datePicker.range);
  const [current, setCurrent] = useState('Overview');

  const [navTabs, setNavTabs] = useState([
    {
      name: 'Overview',
      href: '#',
      current: true,
      visible: userCan('leads.list'),
    },
    {
      name: 'LeadsRep',
      href: '#',
      current: false,
      visible: userCan('leads.list'),
    },
    {
      name: 'Source',
      href: '#',
      current: false,
      visible: userCan('leads.list'),
    },
  ]);
  // Fetch data when the component mounts if it hasn't been loaded before
  useEffect(() => {
    const currentTab = navTabs.find((el) => el.current).name;
    setCurrent(currentTab);
  }, [navTabs]);

  return (
    <>
      <PageHeader
        title="Metrics"
        containerClasses={''}
        right={<DatePicker showLabel={false} />}
      />
      <div className="sm:grid sm:grid-cols-4 gap-4 mb-4 items-center pt-5">
        <TabNav
          tabs={navTabs}
          setTabs={setNavTabs}
          onClick={(tab) => onTabChange(tab)}
          theme="tabbed"
        />
      </div>

      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
        {navTabs[0].current ? (
          <MetricsOverview
            selectedDates={{
              selectedStartDate: startDate,
              selectedEndDate: endDate,
            }}
          />
        ) : navTabs[1].current ? (
          <>Leadsrep</>
        ) : (
          <LeadSourcesTable
            selectedDates={{
              selectedStartDate: startDate,
              selectedEndDate: endDate,
            }}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(Metrics);
