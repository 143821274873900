import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { startCase } from 'lodash';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import { useEffect } from 'react';
import axios from 'axios';
import Loading from 'components/Loading';
import moment from 'moment';

const HistoryModal = ({ open, setOpen, accountId, marketplace, listingId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productCostData, setProductCostData] = useState([]);
  const [shippingCostData, setShippingCostData] = useState([]);
  const [miscellaneousCostData, setMiscellaneousCostData] = useState([]);

  const filterByAttribute = (data) => {
    let shippingCost = [];
    let miscellaneousCost = [];
    let productCost = [];

    data.forEach((d) => {
      if (d.attribute === 'productCost') {
        productCost.push(d);
      } else if (d.attribute === 'shippingCost') {
        shippingCost.push(d);
      } else if (d.attribute === 'miscellaneousCost') {
        miscellaneousCost.push(d);
      }
      setProductCostData(productCost);
      setShippingCostData(shippingCost);
      setMiscellaneousCostData(miscellaneousCost);
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/account/products/listings/history`, {
        params: {
          accountId,
          marketplace: marketplace.details.countryCode,
          listingId,
        },
      });

      filterByAttribute(response.data.data);
      setLoading(false);
    };

    if (listingId) {
      fetchData().catch(console.error);
    }
  }, [accountId, marketplace, listingId]);

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-sm my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title="History"
            fontSize="text-lg"
            fontStyle="font-bold"
            titleClasses="font-inter tracking-3/4 text-grayscale-900 leading-1.2"
            setOpen={setOpen}
            showCloseButton={true}
            border=""
          />
          {loading ? (
            <Loading />
          ) : (
            <div className="py-4 px-4">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4">
                      <div className="flex items-center">
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-grayscale-700`}
                        />
                        <p className="leading-1.5 text-grayscale-800">
                          Product Cost
                        </p>
                      </div>
                      <p className="leading-1.5 text-grayscale-600">
                        {productCostData.length} changes
                      </p>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                      {productCostData.map((p) => (
                        <div
                          key={`profit-history-${p.id}-product`}
                          className="flex justify-between"
                        >
                          <p className="text-grayscale-800 leading-1.5">
                            {currencyFormatter(p.newValue)}
                          </p>
                          <p className="text-grayscale-600 leading-1.5">
                            {moment(p.updatedAt).format('LL')}
                          </p>
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <div className="w-full border-b border-t h-1 mt-2 border-grayscale-600"></div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4 mt-2">
                      <div className="flex items-center">
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-grayscale-700`}
                        />
                        <p className="leading-1.5 text-grayscale-800">
                          Shipping Cost
                        </p>
                      </div>
                      <p className="leading-1.5 text-grayscale-600">
                        {shippingCostData.length} changes
                      </p>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                      {shippingCostData.map((p) => (
                        <div
                          key={`profit-history-${p.id}-shipping`}
                          className="flex justify-between"
                        >
                          <p className="text-grayscale-800 leading-1.5">
                            {currencyFormatter(p.newValue)}
                          </p>
                          <p className="text-grayscale-600 leading-1.5">
                            {moment(p.updatedAt).format('LL')}
                          </p>
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <div className="w-full border-b border-t h-1 mt-2 border-grayscale-600"></div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full items-center justify-between text-13 tracking-3/4 mt-2">
                      <div className="flex items-center">
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-grayscale-700`}
                        />
                        <p className="leading-1.5 text-grayscale-800">
                          Misc. Cost
                        </p>
                      </div>
                      <p className="leading-1.5 text-grayscale-600">
                        {miscellaneousCostData.length} changes
                      </p>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-5 mt-1 text-11 tracking-3/4">
                      {miscellaneousCostData.map((p) => (
                        <div
                          key={`profit-history-${p.id}-miscellaneous`}
                          className="flex justify-between"
                        >
                          <p className="text-grayscale-800 leading-1.5">
                            {currencyFormatter(p.newValue)}
                          </p>
                          <p className="text-grayscale-600 leading-1.5">
                            {moment(p.updatedAt).format('LL')}
                          </p>
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default HistoryModal;
