import { upperFirst } from 'lodash';
import { Area, ComposedChart, ResponsiveContainer } from 'recharts';

import PercentageChange from 'components/PercentageChange';

const HubstaffCard = ({
  id,
  title,
  formatter,
  currentValue = 0,
  previousValue = 0,
  records = [],
}) => {
  return (
    <div className="pl-5">
      <p className="text-11 text-grayscale-700 leading-1.5">{title}</p>
      <p className="mt-2 tracking-3/4 text-xl font-inter font-bold leading-1.2 text-grayscale-900">
        {formatter(currentValue)}
      </p>

      <div className="grid grid-cols-2 -mt-2">
        <div className="flex items-end">
          {currentValue !== previousValue && (
            <PercentageChange
              currentValue={currentValue}
              previousValue={previousValue}
            />
          )}
        </div>

        <div className="h-16">
          {records.length > 1 && (
            <ResponsiveContainer className="w-full" height="100%">
              <ComposedChart className="w-full" data={records}>
                <defs>
                  <linearGradient id={`${id}Color`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor="#00BA88" stopOpacity={0.25} />
                    <stop offset="50%" stopColor="#00BA88" stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <defs>
                  <linearGradient
                    id={`previous${upperFirst(id)}Color`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="10%" stopColor="#1879D8" stopOpacity={0.25} />
                    <stop offset="50%" stopColor="#1879D8" stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <Area
                  yAxisId={'right'}
                  type="monotone"
                  dataKey={id}
                  strokeWidth={2}
                  stroke="#00BA88"
                  fillOpacity={1}
                  fill={`url(#${id}Color)`}
                  dot={false}
                  formatter={formatter}
                />

                <Area
                  yAxisId={'right'}
                  type="monotone"
                  dataKey={`previous${upperFirst(id)}`}
                  strokeWidth={2}
                  stroke="#1879D8"
                  fillOpacity={1}
                  fill={`url(#previous${upperFirst(id)}Color)`}
                  dot={false}
                  formatter={formatter}
                />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default HubstaffCard;
