import { useState } from 'react';
import useAlert from './useAlert';

const useBraintreeUtils = () => {
  const { alertError } = useAlert();

  const showTokenizeError = (error) => {
    switch (error.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        alertError(
          'Missing payment information',
          'Please input your card details'
        );
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        alertError(
          'Invalid payment information',
          `${error.details.invalidFieldKeys.join(', ')} are invalid`
        );
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        alertError(
          'Failed connecting',
          'Network error occurred when tokenizing.'
        );
        break;
      default:
        alertError(error.name, error.message);
        break;
    }
  };

  return {
    showTokenizeError,
  };
};

export default useBraintreeUtils;
