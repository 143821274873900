import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useAuth from 'hooks/useAuth';
import { setUser } from './authSlice';
import logo from 'assets/logos/logo-orange.png';
import AuthLayout from 'layouts/auth/AuthLayout';
import SetPassword from 'features/onboarding/SetPassword';
import { Button } from 'components';

const ChangePassword = ({ history }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  if (!user.passwordGenerated) {
    history.push('/dashboard');
  }

  return (
    <AuthLayout>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          src={logo}
          alt="Workflow"
          className="mx-auto h-12 w-auto rounded-md"
        />

        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Agency Dashboard
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
          {success ? (
            <div className="space-y-6 text-grayscale-900 leading-1.5">
              <h3 className="text-center font-inter text-25 font-bold leading-1.2 tracking-3/4">
                Your account is ready!
              </h3>

              <p className="text-center">
                Welcome aboard! We're thrilled to have you as a new member of
                our team.
              </p>
              <p className="text-center">
                From here on out, you'll have access to a range of powerful
                features and resources that will make managing your tasks and
                goals easier than ever before.
              </p>
              <p className="text-center">
                If you have any questions or need assistance, don't hesitate to
                reach out to our dedicated support team.
              </p>

              <Button
                type="submit"
                textSize="sm"
                textColor="grayscale-300"
                fontWeight="bold"
                classes="w-full tracking-2 justify-center"
                showLoading={true}
                roundedSize="40"
                px={8}
                py={4}
                onClick={() =>
                  dispatch(setUser({ ...user, passwordGenerated: false })).then(
                    () => history.push('/dashboard')
                  )
                }
              >
                Go to Dashboard
              </Button>
            </div>
          ) : (
            <SetPassword onSuccess={() => setSuccess(true)} />
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default ChangePassword;
