const useMailThread = (threadData) => {
  const threadHeader = (payload) => {
    const { value } = threadData.headers.find((h) => h.name === payload);
    return value;
  };

  const threadHeaderContains = (payload) => {
    const yes = threadData.headers.find((h) => h.name === payload);
    if (yes) {
      if (yes.value !== '') {
        return true;
      }
      return null;
    }
    return null;
  };

  return {
    threadHeader,
    threadHeaderContains,
  };
};

export default useMailThread;
