import axios from 'axios';
import { debounce } from 'lodash';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { ClockIcon, SearchIcon } from '@heroicons/react/outline';

import useQuery from 'hooks/useQuery';

import { Table } from 'components';
import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';

import HistoryModal from './components/HistoryModal';
import AllocationEditor from './components/AllocationEditor';

import { currencyFormatter } from 'utils/formatters';

const CogsManager = ({ account, marketplace }) => {
  let query = useQuery();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [getUpdated, setGetUpdated] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [cogsData, setCogsData] = useState({ rows: [] });
  const [listingId, setListingId] = useState('');
  const [params, setParams] = useState({
    page: parseInt(query.get('page') ?? 1),
    pageSize: parseInt(query.get('pageSize') ?? 10),
    sort: query.get('sort') ?? 'price',
    search: query.get('search') ?? '',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/account/products/listings`, {
        params: {
          ...params,
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          attributes: [
            'listingId',
            'asin',
            'sku',
            'price',
            'title',
            'thumbnail',
            'productCost',
            'miscellaneousCost',
            'totalCost',
            'shippingCost',
          ],
        },
      });

      if (isSubscribed) {
        setCogsData(response.data.data);
        setGetUpdated(false);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, params, getUpdated]);

  const updateParams = (newParams, search = false) => {
    setParams(newParams);
    query.set('page', newParams.page);
    query.set('pageSize', newParams.pageSize);
    query.set('sort', newParams.sort);

    if (search) {
      if (newParams.search === '') {
        query.delete('search');
      } else {
        query.set('search', newParams.search);
      }
    }

    history.push(window.location.pathname + '?' + query.toString());
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = {
      ...params,
      page,
      pageSize: sizePerPage,
    };
    updateParams(newParams);
  };

  const tableColumns = [
    {
      dataField: 'asin',
      text: 'Products',
      sort: false,
      headerStyle: {
        minWidth: '350px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return (
          <div className="flex">
            <img
              src={row.thumbnail}
              className="h-20 w-20 mr-2"
              alt={row.asin}
            />

            <div className="relative flex flex-col justify-center px-3.5">
              <div className="mb-3.5">
                <p className="font-normal text-tiny text-grayscale-800 leading-4">
                  {row.title}
                </p>
              </div>
              <div className="mb-3.5">
                <p className="text-tiny">
                  <span className="text-grayscale-600">ASIN: </span>
                  <span className="text-secondary-light underline">
                    {row.asin}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-tiny">
                  <span className="text-grayscale-600">SKU: </span>
                  <span className="text-grayscale-800 font-normal">
                    {row.sku}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'productCost',
      text: 'Product Cost',
      sort: false,
      headerStyle: {
        minWidth: '175px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <AllocationEditor
            accountId={account.accountId}
            marketplace={marketplace}
            entityId={row.listingId}
            currentValue={cell}
            editable={true}
            attribute="productCost"
            setGetUpdated={setGetUpdated}
          />
        );
      },
    },
    {
      dataField: 'shippingCost',
      text: 'Shipping Cost',
      sort: false,
      headerStyle: {
        minWidth: '175px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <AllocationEditor
            accountId={account.accountId}
            marketplace={marketplace}
            entityId={row.listingId}
            currentValue={cell}
            editable={true}
            attribute="shippingCost"
            setGetUpdated={setGetUpdated}
          />
        );
      },
    },
    {
      dataField: 'miscellaneousCost',
      text: 'Misc. Cost',
      sort: false,
      headerStyle: {
        minWidth: '175px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <AllocationEditor
            accountId={account.accountId}
            marketplace={marketplace}
            entityId={row.listingId}
            currentValue={cell}
            editable={true}
            attribute="miscellaneousCost"
            setGetUpdated={setGetUpdated}
          />
        );
      },
    },
    {
      dataField: 'totalCost',
      text: 'Total Cost',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(row.totalCost)}
          </p>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerStyle: { textAlign: `center` },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex flex-row justify-center">
            <ButtonLink
              onClick={() => {
                setListingId(row.listingId);
                setOpenHistoryModal(true);
              }}
              color="gray"
            >
              <ClockIcon className="w-5 h-5" />
            </ButtonLink>
          </div>
        );
      },
    },
  ];

  const onSearch = (e) => {
    updateParams({
      ...params,
      search: e.target.value,
    });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="bg-white p-5">
      <div className="sm:grid grid-cols-7 mb-8">
        <div className="col-span-3 flex items-center space-x-4">
          <h1 className="text-grayscale-900 text-xl tracking-3/4 leading-1.2 font-inter font-bold">
            COGS Manager
          </h1>
        </div>
        <div className="col-span-4 flex justify-end items-center space-x-4">
          <div className="sm:col-span-2 bg-grayscale-400">
            <InputPrepend
              name="search"
              defaultValue={params.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Name, ASINs or SKUs'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
              classes="py-3.5 pr-6 pl-9"
            />
          </div>
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={cogsData}
        onTableChange={onTableChange}
        params={params}
        keyField="listingId"
        defaultSorted={[{ dataField: 'price', order: 'desc' }]}
        loading={loading}
      />
      <HistoryModal
        open={openHistoryModal}
        setOpen={setOpenHistoryModal}
        accountId={account.accountId}
        marketplace={marketplace}
        listingId={listingId}
      />
    </div>
  );
};

export default CogsManager;
