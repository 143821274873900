import { useEffect, useState } from 'react';
import axios from 'axios';
import useQueryParams from 'hooks/useQueryParams';
import Button from 'components/Button';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import FunnelIcon from 'components/icons/FunnelIcon';
import useAlert from 'hooks/useAlert';
import { Table } from 'components';
import PriceLabel from 'components/PriceLabel';
import { columnClasses, headerClasses } from 'utils/table';
import PaidReviewDetailsModal from './PaidReviewDetailsModal';
import PaidReviewsStatus from './components/PaidReviewsStatus';
import useAgencyClient from 'hooks/useAgencyClient';
import UpsellReviewModal from 'features/upsells/UpsellReviewModal';

const PaidReviews = () => {
  const { alertError } = useAlert();
  const { agencyClient } = useAgencyClient();
  const [loading, setLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [paidReviews, setPaidReviews] = useState(null);
  const { params, updateParams } = useQueryParams({
    agencyClientId: agencyClient.agencyClientId,
    status: null,
    pageSize: 10,
    page: 1,
  });
  const [selectedPaidReview, setSelectedPaidReview] = useState(null);
  const [upsells, setUpsells] = useState(null);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [selectedUpsell, setSelectedUpsell] = useState(null);

  useEffect(() => {
    getPaidReviews();
  }, [params]);

  const getPaidReviews = async () => {
    setLoading(true);
    await axios
      .get('/agency/paid-reviews', { params })
      .then((response) => {
        const d = response.data.data;
        setPaidReviews({
          rows: d.data,
          count: d.total,
          page: d.current_page,
          to: d.to,
          from: d.from,
          pageSize: d.per_page,
        });
        setUpsells(response.data.data.upsells);
      })
      .catch((error) => {
        alertError(
          'Failed to fetch data from SMTG',
          error.response.data.message
        );
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      dataField: 'upsell_id',
      text: 'Upsell ID',
      sort: false,
      headerStyle: {
        minWidth: '200px',
      },
      formatter: (cell, row) => {
        const upsell = upsells?.find((u) => u.upsellId === cell);

        return cell && upsell ? (
          <button
            className="font-normal flex flex-row items-center space-x-1 text-left"
            onClick={() => {
              setSelectedUpsell(upsell);
              setIsOpenReviewModal(true);
            }}
          >
            <span className=" underline text-secondary-light text-sm uppercase">
              {cell}
            </span>
          </button>
        ) : (
          <>From SMTG</>
        );
      },
    },
    {
      dataField: 'asin',
      text: 'ASIN',
      sort: false,
      headerStyle: {
        minWidth: '50px',
      },
    },
    {
      dataField: 'reviews_count',
      text: 'No. of Reviews',
      sort: false,
      headerStyle: {
        minWidth: '120px',
      },
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      formatter: (cell, row) =>
        `${row.fulfilled_count ?? 0} / ${Math.round(cell)}`,
    },
    {
      dataField: 'totalCost',
      text: 'Total Cost',
      sort: false,
      headerStyle: {
        minWidth: '120px',
      },
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      formatter: (cell, row) => {
        return <PriceLabel price={row.cost * row.reviews_count} />;
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerStyle: {
        width: '180px',
      },
      headerClasses: `${headerClasses} text-center`,
      formatter: (cell, row) => (
        <PaidReviewsStatus status={cell} width="w-full" padding="px-3 py-2.5" />
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      show: true,
      hideable: true,
      headerStyle: {
        minWidth: '120px',
      },
      headerClasses: `${headerClasses} text-center`,
      classes: `${columnClasses} text-center`,
      formatter: (cell, row) => {
        return (
          <Button
            classes="w-auto px-5 justify-center rounded-xl text-grayscale-700 tracking-widest text-sm font-bold py-2.5 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
            border=" border-2 border-grayscale-700"
            color=""
            hoverColor="grayscale-500"
            px=""
            onClick={() => {
              setSelectedPaidReview(row);
              setOpenDetails(true);
            }}
          >
            View Updates
          </Button>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({ page, pageSize: sizePerPage });
  };

  return (
    <div className="col-span-5 p-7 bg-white">
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Paid Reviews
          </h2>
        </div>

        <div className="flex items-center space-x-4">
          <DropdownMenu
            title={<FunnelIcon />}
            titleClasses="flex items-center mr-1 relative top-1.5"
            button
            buttonBg="bg-transparent"
            buttonFontWeight="font-normal"
            hoverClasses="bg-none"
            textColor="text-cyan-600"
            classes="text-sm z-2 rounded-2xl"
            buttonRounded=""
            hoverText="hover:text-cyan-500"
            dropdownWidth="w-48"
            padding=""
            position=""
            placement="bottom-end"
            hideArrow
          >
            <div className="px-1 py-1 flex flex-col">
              {['all', 'paid', 'pending', 'completed', 'cancelled'].map(
                (status) => {
                  return (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            updateParams({
                              ...params,
                              status: status === 'all' ? null : status,
                              page: 1,
                            });
                          }}
                          className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500 capitalize"
                        >
                          {status}
                        </button>
                      )}
                    </Menu.Item>
                  );
                }
              )}
            </div>
          </DropdownMenu>
        </div>
      </div>
      <div>
        <Table
          columns={tableColumns}
          data={paidReviews}
          onTableChange={onTableChange}
          params={params}
          keyField="id"
          loading={loading}
          rowClasses="hover:bg-gray-50 group"
        />
      </div>
      <PaidReviewDetailsModal
        open={openDetails}
        setOpen={setOpenDetails}
        paidReview={selectedPaidReview}
      />
      <UpsellReviewModal
        open={isOpenReviewModal}
        setOpen={setIsOpenReviewModal}
        upsell={selectedUpsell}
        type="creator"
      />
    </div>
  );
};

export default PaidReviews;
