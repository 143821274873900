import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { MailIcon, ChatAltIcon } from '@heroicons/react/solid';
import { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import QuillMention from 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import ButtonLink from 'components/ButtonLink';
import classNames from 'utils/classNames';
import Radio from 'components/Forms/Radio';
Quill.register('modules/mentions', QuillMention);

const Embed = Quill.import('blots/embed');
class LinkAttachmentBlot extends Embed {
  static create(value) {
    let node = super.create();
    const { title, url, key } = value;

    node.setAttribute('href', url);
    node.setAttribute('className', 'text-red-500');
    node.setAttribute('data-id', key);
    node.setAttribute('title', title);
    node.innerText = title;

    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute('href'),
      id: node.getAttribute('data-id'),
      title: node.getAttribute('title'),
    };
  }

  static formats(node) {
    return node.getAttribute('href');
  }
}
LinkAttachmentBlot.blotName = 'linkAttachment';
LinkAttachmentBlot.tagName = 'A';
LinkAttachmentBlot.className = 'link-attach';

Quill.register(LinkAttachmentBlot);

const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ color: [] }],
  ['link', 'image'],
  ['clean'],
];

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'mention',
  'linkAttachment',
];

const LinkedInReplyEditor = ({
  qRef,
  setOpen,
  isEditMode,
  editCommentValue = '',
  setOpenEditor,
  uploaderOptions,
  employees,
  onSave,
  isHasLinkedIn = false,
  linkedInEmail,
}) => {
  const [value, setValue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [contentValue, setContentValue] = useState('');
  const [contentNewValue, setContentNewValue] = useState('');
  const [mailFrom, setMailFrom] = useState('Me');

  useEffect(() => {
    if (editCommentValue !== '') {
      setValue(editCommentValue);
    }
  }, [editCommentValue]);

  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getContents());
    setContentValue(content);
  };

  const handleNewChange = (content, delta, source, editor) => {
    setNewValue(editor.getContents());
    setContentNewValue(content);
  };

  const mention = {
    mentionDenotationChars: ['@'],
    dataAttributes: [
      'id',
      'value',
      'denotationChar',
      'link',
      'target',
      'disabled',
      'type',
    ],
    source: useCallback(function (searchTerm, renderList, mentionChar) {
      let values;

      values = employees;

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }, []),
  };

  const onSaveComment = async () => {
    setSaving(true);
    const data = {
      content: `${contentValue} 
      <hr> 
      <br>
      <blockquote><p>from: ${mailFrom} </p> ${contentNewValue}</blockquote>`,
      structure: value,
      text: qRef.current.editor.getText(),
    };

    qRef.current.editor.setContents('');
    await onSave(data);
    setOpen(false);
    setSaving(false);
  };

  return (
    <>
      <div
        className={classNames(
          isEditMode && 'edit-comment',
          'quill-text-editor py-2'
        )}
      >
        <ReactQuill
          ref={qRef}
          theme="bubble"
          placeholder="Have any updates or comments? Click here to type!"
          value={value}
          onChange={handleChange}
          bounds={`.quill-text-editor`}
          modules={{
            mention,
            toolbar: toolbarOptions,
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={formats}
          readOnly={true}
        />
      </div>
      <div className="flex items-center px-4">
        <label className="text-sm text-gray-400">From:</label>

        <div className="mx-2">
          <Radio
            value="Me"
            checked={mailFrom === 'Me'}
            name="mailFrom"
            label="Me"
            classes="mx-2"
            onChange={(e) => setMailFrom(e.target.value)}
          />
        </div>

        <div className="mx-2">
          <Radio
            value="Client"
            checked={mailFrom === 'Client'}
            name="mailFrom"
            label="Client"
            classes="mx-2"
            onChange={(e) => setMailFrom(e.target.value)}
          />
        </div>
      </div>

      <div
        className={classNames(
          isEditMode && 'edit-comment',
          'quill-text-editor py-2'
        )}
      >
        <ReactQuill
          ref={qRef}
          theme="bubble"
          placeholder="Enter Reply Here"
          value={newValue}
          onChange={handleNewChange}
          bounds={`.quill-text-editor`}
          modules={{
            mention,
            toolbar: toolbarOptions,
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={formats}
        />
      </div>

      <div className="flex justify-between py-3 px-4 border-t">
        <div className="flex space-x-2"></div>
        <div className="space-x-4 flex items-center">
          {!isEditMode && (
            <div className="flex mx-1">
              {isHasLinkedIn ? (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('linkedIn');
                  }}
                  title="LinkedIn Thread"
                  classes="p-0.5 bg-blue-400 font-bold rounded"
                  color="white"
                >
                  in
                </ButtonLink>
              ) : (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('email');
                  }}
                  title="Send Email"
                  classes="p-0.5"
                >
                  <MailIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
                </ButtonLink>
              )}

              <ButtonLink
                onClick={() => {
                  setOpenEditor('comment');
                }}
                title="Add Comment"
                classes="pointer-events-none p-0.5"
              >
                <ChatAltIcon className="w-5 h-5 inline text-blue-600 filter drop-shadow-sm " />
              </ButtonLink>
            </div>
          )}
          {isEditMode && (
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonLink>
          )}

          <Button
            roundedSize="2xl"
            classes="tracking-wider font-bold"
            px={6}
            py={2}
            textSize="xs"
            color="green"
            onClick={onSaveComment}
            showLoading={true}
            loading={saving}
          >
            {isEditMode ? 'Save' : 'Comment'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default LinkedInReplyEditor;
