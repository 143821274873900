import cardWireframe from 'assets/images/card-wireframe.svg';

const EmptyBlock = ({ title }) => {
  return (
    <div className="col-span-1 mt-4">
      <div className=" top-1/2 relative flex flex-col items-center space-y-4">
        <div className="flex space-x-8 justify-center">
          <img src={cardWireframe} className="w-1/4 sm:w-auto" />
          <img src={cardWireframe} className="w-1/4 sm:w-auto" />
        </div>
        <h4 className="text-sm text-gray-500 text-center leading-tight mb-2">
          No {title} created yet
        </h4>
      </div>
    </div>
  );
};
export default EmptyBlock;
