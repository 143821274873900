import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBodyClass from 'hooks/useBodyClass';
import { fetchPlans, fetchAddons } from 'features/zoho/zohoSlice';
import NewClientForm from './NewClient/NewClientForm';
import { fetchSalesEmployees } from 'features/employees/employeesSlice';
import { fetchMarketplaces } from 'features/marketplaces/marketplacesSlice';

const NewClient = () => {
  useBodyClass(['client-profile', 'agency-client']);
  const dispatch = useDispatch();
  const { sales } = useSelector((state) => state.employees);
  const { plans, addons } = useSelector((state) => state.zoho);
  const { marketplaces } = useSelector((state) => state.marketplaces);

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans());
    }
  }, [plans, dispatch]);

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons());
    }
  }, [addons, dispatch]);

  useEffect(() => {
    if (!sales) {
      dispatch(fetchSalesEmployees());
    }
  }, [sales]);

  useEffect(() => {
    if (marketplaces.length <= 0) {
      dispatch(fetchMarketplaces());
    }
  }, [marketplaces]);

  return (
    <>
      <div className="xl:flex justify-between">
        <div className="">
          <h1 className="text-3xl tracking-wide font-bold pt-2">
            Create Client
          </h1>
        </div>
      </div>

      <div className="flex space-x-8 mt-4 mb-10 border-b">&nbsp;</div>

      <div className="lg:grid grid-cols-5">
        <div className="col-span-5">
          <div className="tablinks flex">
            <a className="capitalize py-2 w-32 border-t border-l border-r rounded-t-md text-center current left">
              Profile
            </a>
          </div>
        </div>
        <div className="client-content container-fixed bg-white">
          <div className="px-8 pt-8 pb-16">
            <NewClientForm action="add" />
          </div>
        </div>
        <div className="client-updates container-fixed bg-gray-50 border-l border-gray-100">
          &nbsp;
        </div>
      </div>
    </>
  );
};

export default NewClient;
