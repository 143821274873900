import { Button, Modal } from 'components';
import { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label } from 'components/Forms';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import { isString } from 'lodash';

const FREQUENCIES = [
  {
    value: 'DAILY',
    label: 'Day',
  },
  {
    value: 'WEEKLY',
    label: 'Week',
  },
  {
    value: 'MONTHLY',
    label: 'Month',
  },
];

const DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

const RecurrenceModal = ({ values, setFieldValue, errors }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center justify-center">
      <button
        type="button"
        className="flex items-center justify-center space-x-2"
        onClick={() => setOpen(true)}
      >
        <SwitchHorizontalIcon className="w-5 h-5" />
        <Label>Recurring</Label>
      </button>

      <Modal
        as={'div'}
        open={open}
        setOpen={setOpen}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-sm my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center px-8 pt-8">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-25 tracking-3/4">
              Recurrence
            </h4>
          </div>

          <div className="overflow-y-auto mt-2 pb-8 px-8">
            <div className="space-y-2">
              <Label>Repeat Every:</Label>

              <div className="grid grid-cols-2 gap-2">
                <div>
                  <Field
                    type="number"
                    name="recurringConfig.interval"
                    id="interval"
                    className="form-input"
                  />

                  <ErrorMessage
                    name="recurringConfig.interval"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <div>
                  <Field
                    as="select"
                    name="recurringConfig.frequency"
                    id="frequency"
                    className="form-input"
                  >
                    {FREQUENCIES.map((frequency) => (
                      <option key={frequency.value} value={frequency.value}>
                        {frequency.label}
                        {values.recurringConfig.interval > 1 ? 's' : ''}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="recurringConfig.frequency"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
              </div>
            </div>

            {values.recurringConfig.frequency === 'WEEKLY' && (
              <div className="space-y-2">
                <Label>Repeat on</Label>
                <div className="flex space-x-1">
                  {DAYS.map((day) => (
                    <div
                      key={day}
                      onClick={() => {
                        setFieldValue(
                          'recurringConfig.daysOfWeek',
                          values.recurringConfig.daysOfWeek.includes(day)
                            ? [
                                ...values.recurringConfig.daysOfWeek.filter(
                                  (i) => i !== day
                                ),
                              ]
                            : [...values.recurringConfig.daysOfWeek, day]
                        );
                      }}
                      className={classNames(
                        'cursor-pointer w-8 h-8 border rounded-full flex items-center justify-center',
                        values.recurringConfig.daysOfWeek.includes(day)
                          ? 'bg-secondary text-grayscale-300'
                          : 'bg-white text-grayscale-800'
                      )}
                    >
                      <span className="text-11">{day.charAt(0)}</span>
                    </div>
                  ))}
                </div>

                {isString(errors?.recurringConfig?.daysOfWeek) && (
                  <ErrorMessage
                    name="recurringConfig.daysOfWeek"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                )}
              </div>
            )}

            {values.recurringConfig.frequency === 'MONTHLY' && (
              <div className="space-y-2">
                <Label>Day of Month</Label>
                <Field
                  type="number"
                  name="recurringConfig.dayOfMonth"
                  id="dayOfMonth"
                  className="form-input"
                />

                <ErrorMessage
                  name="recurringConfig.dayOfMonth"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            )}

            <div className="space-y-2">
              <Label>Ends</Label>

              <div className="space-y-2">
                <div className="flex space-x-2 items-center">
                  <Field
                    type="radio"
                    value="NEVER"
                    name="recurringConfig.endType"
                  />

                  <Label>Never</Label>
                </div>

                <div className="flex space-x-2 items-center">
                  <Field
                    type="radio"
                    value="OCCURENCE"
                    name="recurringConfig.endType"
                  />

                  <Label>After</Label>

                  <div className="w-24">
                    <Field
                      type="number"
                      name="recurringConfig.endAfterOccurrences"
                      id="endAfterOccurrences"
                      className="form-input"
                      disabled={values.recurringConfig.endType !== 'OCCURENCE'}
                    />

                    <ErrorMessage
                      name="recurringConfig.endAfterOccurrences"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <Label>Occurence(s)</Label>
                </div>
              </div>
            </div>

            <Button
              type="button"
              roundedSize={40}
              onClick={() => setOpen(false)}
              classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
            >
              Apply
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RecurrenceModal;
