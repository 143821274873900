import axios from 'axios';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useRouteMatch, NavLink, withRouter } from 'react-router-dom';

import { PencilIcon } from '@heroicons/react/outline';

import { Table } from 'components';

import FilterCondition from '../components/FilterCondition';
import ActionSummary from '../components/ActionSummary';

import { userCan } from 'utils/permission';

const ListRule = ({ history, accountId, marketplace, campaignType }) => {
  const { url } = useRouteMatch();
  const { user } = useSelector((state) => state.auth);

  const [rules, setRules] = useState({ rules: [] });
  const [params, setParams] = useState({
    page: 1,
    search: '',
    pageSize: 10,
    campaignType,
    include: ['action'],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/advertising/rules', {
        params: { ...params, accountId, marketplace },
      });

      if (isSubscribed) {
        setRules(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params, marketplace, accountId]);

  const columns = [
    {
      dataField: 'name',
      sort: true,
      text: 'Rule',
      headerStyle: { minWidth: '275px' },
      classes: `sticky left-0 z-10 bg-white py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
      headerClasses: `sticky left-0 z-10 bg-white py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
    },
    {
      dataField: 'recordType',
      sort: true,
      text: 'Record Type',
      headerStyle: { minWidth: '175px' },
      formatter: (value) => startCase(value),
    },
    {
      dataField: 'filters',
      text: 'Conditions',
      headerStyle: { maxWidth: '275px' },
      formatter: (cell, row) => {
        return (
          <div className="flex flex-wrap">
            {cell.map((filterItem, idx) => (
              <FilterCondition
                key={`${row.advRuleId}-${filterItem.attribute}-${idx}`}
                {...filterItem}
              />
            ))}
          </div>
        );
      },
    },
    {
      dataField: 'action.name',
      text: 'Action',
      headerStyle: { minWidth: '275px' },
      formatter: (cell, row) => {
        return <ActionSummary action={row.action} options={row.actionData} />;
      },
    },
    {
      dataField: 'advRuleId',
      text: '',
      headerClasses: userCan(user, 'ppc.rule.update')
        ? 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
        : 'hidden',
      classes: userCan(user, 'ppc.rule.update')
        ? 'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500'
        : 'hidden',
      formatter: (cell, row) => {
        const { predefined } = row;
        const redirectToEditPage = () => {
          if (predefined) return;
          history.push(`${url}/${cell}`);
        };
        return (
          <div className={predefined ? 'cursor-not-allowed' : 'cursor-pointer'}>
            <PencilIcon onClick={redirectToEditPage} className="h-5 w-5" />
          </div>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) newParams.sort = `${sortField}:${sortOrder}`;

    setParams(newParams);
  };

  return (
    <div className="my-4">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 mb-4">
        <input
          type="search"
          className="col-span-2 py-4 px-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal"
          placeholder="Search rule name"
          value={params.search}
          onChange={(e) => setParams({ ...params, search: e.target.value })}
        />

        <select
          value={params.recordType}
          onChange={(e) => {
            let newParams = { ...params };
            e.target.value
              ? (newParams = { ...params, recordType: e.target.value })
              : delete newParams.recordType;

            setParams(newParams);
          }}
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal"
        >
          <option value="">All record types</option>
          <option value="campaigns">Campaigns</option>
          <option value="keywords">Keywords</option>
          <option value="searchTerms">Search Terms</option>
        </select>

        {userCan(user, 'ppc.rule.create') && (
          <NavLink
            to={`${url}/create`}
            className="col-start-4 disabled:opacity-75 justify-center w-full rounded-2xl border border-gray-300 shadow-sm px-4 text-center py-3 bg-secondary hover:bg-secondary-light text-sm font-medium text-grayscale-300 tracking-2 font-bold hover:bg-gray-50 focus:outline-none "
          >
            Create new rule
          </NavLink>
        )}
      </div>

      <Table
        data={rules}
        params={params}
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `${column.classes} py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `${column.headerClasses} py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        keyField="advRuleId"
        onTableChange={onTableChange}
        bordered={false}
      />
    </div>
  );
};

export default withRouter(ListRule);
