import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { Table } from 'components';
import ClientModal from './ClientModal';

const TerminationBreakdown = () => {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ rows: [] });

  const [openClients, setOpenClients] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({});

  const [tableColumns, setTableColumns] = useState([
    {
      dataField: 'reason',
      text: 'Reason',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/terminations/records', {
        params: {
          startDate: moment(year).startOf('year').format('YYYY-MM-DD'),
          endDate: moment(year).endOf('year').format('YYYY-MM-DD'),
        },
      });

      if (isSubscribed) {
        setRecords({
          page: 1,
          from: 1,
          rows: response.data.data,
          to: response.data.data.length,
          pageSize: response.data.data.length,
        });
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  useEffect(() => {
    if (records.rows.length) {
      const [item] = records.rows;

      setTableColumns(
        Object.keys(item).map((key) => {
          const [startDate, endDate] = key.split('-to-');

          return {
            sort: false,
            dataField: key,
            text:
              key === 'reason' ? 'Reason' : moment(startDate).format('MMMM'),
            formatter: (cell, row) => {
              if (key === 'reason') return cell;

              return cell ? (
                <p
                  className="text-secondary-light underline text-center cursor-pointer"
                  onClick={() => {
                    setSelectedReason(row.reason);
                    setSelectedDateRange({ startDate, endDate });
                    setOpenClients(true);
                  }}
                >
                  {cell}
                </p>
              ) : (
                <p className="text-center cursor-pointer">0</p>
              );
            },
          };
        })
      );
    }
  }, [records.rows]);

  return (
    <div className="space-y-5">
      {selectedReason && !isEmpty(selectedDateRange) && (
        <ClientModal
          open={openClients}
          setOpen={setOpenClients}
          reason={selectedReason}
          dateRange={selectedDateRange}
        />
      )}

      <div className="text-grayscale-900 flex justify-between">
        <p className="font-inter font-bold leading-1.2 tracking-3/4">
          Breakdown
        </p>

        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          <option value="2022">2022</option>
          <option value="2023">2023</option>
        </select>
      </div>

      <Table
        columns={[...tableColumns].map((column) => {
          return {
            ...column,
            classes:
              'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
            headerClasses:
              'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
          };
        })}
        data={records}
        params={{ page: 1, pageSize: 10 }}
        keyField="reason"
        loading={loading}
      />
    </div>
  );
};

export default TerminationBreakdown;
