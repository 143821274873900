import axios from 'axios';
import { useState, useEffect } from 'react';

import UpdateBid from './UpdateBid';
import UpdateStatus from './UpdateStatus';

import {
  CAMPAIGNS,
  SP_CAMPAIGNS_UPDATE_BUDGET,
  SP_CAMPAIGNS_UPDATE_STATUS,
  SP_KEYWORDS_UPDATE_BID,
  SP_KEYWORDS_UPDATE_STATUS,
  SP_SEARCH_TERMS_CONVERT_AS_NEGATIVE_KEYWORD,
} from 'features/advertising/utils/constants';
import ConvertAsNegativeKeyword from './ConvertAsNegativeKeyword';

const RuleAction = ({
  campaignType,
  recordType,
  value,
  data,
  onChangeData,
  onChangeValue,
  errors = {},
}) => {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/account/advertising/rules/actions`, {
        params: { recordType, campaignType },
      });

      if (isSubscribed) {
        setActions(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [campaignType, recordType]);

  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="mt-4">
        <label
          htmlFor="name"
          className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
        >
          Record Type
        </label>
        <div className="mt-1">
          <select
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
          >
            <option value="">Select Action</option>
            {actions.map((action) => (
              <option
                key={`rule-actions-options-${action.advRuleActionId}`}
                value={action.code}
              >
                {action.name}
              </option>
            ))}
          </select>
          {errors.actionCode && (
            <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
              {errors.actionCode}
            </p>
          )}
        </div>
      </div>

      {(value === SP_KEYWORDS_UPDATE_STATUS ||
        value === SP_CAMPAIGNS_UPDATE_STATUS) && (
        <UpdateStatus errors={errors} data={data} onChangeData={onChangeData} />
      )}

      {(value === SP_KEYWORDS_UPDATE_BID ||
        value === SP_CAMPAIGNS_UPDATE_BUDGET) && (
        <UpdateBid
          data={data}
          onChangeData={onChangeData}
          errors={errors}
          label={recordType === CAMPAIGNS ? 'Update Budget' : 'Update Bid'}
        />
      )}

      {value === SP_SEARCH_TERMS_CONVERT_AS_NEGATIVE_KEYWORD && (
        <ConvertAsNegativeKeyword
          errors={errors}
          data={data}
          onChangeData={onChangeData}
        />
      )}
    </div>
  );
};

export default RuleAction;
