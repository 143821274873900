import axios from 'axios';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { Table } from 'components';

const TaskTimeLogs = ({ startDate, endDate }) => {
  const [timeLogs, setTimeLogs] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    scopes: ['owned'],
    include: ['task'],
    attributes: [
      'userId',
      'startedAt',
      'stoppedAt',
      'taskItemId',
      'taskTimeLogId',
      'totalTrackedTime',
    ],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/agency/tasks/time-logs', {
        params: {
          ...params,
          startedAtFrom: moment
            .tz(startDate, 'America/New_York')
            .startOf('D')
            .format(),
          startedAtEnd: moment
            .tz(endDate, 'America/New_York')
            .endOf('D')
            .format(),
        },
      });

      if (isSubscribed) {
        setTimeLogs(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params, startDate, endDate]);

  const columns = [
    {
      dataField: 'task.name',
      text: 'Task',
      sort: true,
    },
    {
      dataField: 'startedAt',
      text: 'Start Time',
      sort: true,
      formatter: (cell) =>
        cell ? moment(cell).format('ddd, MMMM Do YYYY hh:mm A') : null,
    },
    {
      dataField: 'stoppedAt',
      text: 'Stop Time',
      sort: true,
      formatter: (cell) =>
        cell ? moment(cell).format('ddd, MMMM Do YYYY hh:mm A') : null,
    },
    {
      dataField: 'totalTrackedTime',
      text: 'Duration',
      sort: true,
      formatter: (cell, row) => {
        if (!row.stoppedAt) return '';
        let dur = moment.duration(cell, 'seconds').format('hh:mm:ss');
        if (dur.length === 2) dur = `00:${dur}`;
        if (dur.length === 5) dur = `00:${dur}`;
        return dur;
      },
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div className="bg-white rounded-xl p-6 mt-5">
      <p className="font-inter font-bold leading-1.2 tracking-3/4 text-grayscale-900 mb-5">
        Task Time Logs
      </p>

      <Table
        bordered={false}
        params={params}
        data={timeLogs}
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `text-left py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        keyField="taskTimeLogId"
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default TaskTimeLogs;
