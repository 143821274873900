import { usePopper } from 'react-popper';
import { Fragment, useState } from 'react';
import { keys, startCase, lowerCase } from 'lodash';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDoubleUpIcon } from '@heroicons/react/solid';
import { ZoomInIcon, ZoomOutIcon } from '@heroicons/react/outline';

import { useRoles } from 'features/roles';
import classNames from 'utils/classNames';

const DepartmentFilter = ({ value, onChange, onZoomIn, onZoomOut }) => {
  const { departments } = useRoles();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
  });

  return (
    <div
      className="absolute left-5 bottom-5 flex py-1.5 items-center rounded-md bg-white divide-x"
      style={{
        boxShadow: '0px 0px 20px 0px rgba(17, 17, 17, 0.20)',
        zIndex: 99,
      }}
    >
      <div className="flex px-2 items-center space-x-1">
        <p className="text-13 leading-1.5 text-grayscale-900">
          You are viewing:
        </p>

        <Popover className="relative w-48">
          {({ open }) => (
            <>
              <Popover.Button
                ref={setReferenceElement}
                className={classNames(
                  open ? 'bg-grayscale-400' : '',
                  'justify-center space-x-2 flex items-center w-full text-grayscale-700 font-bold leading-1.5 text-11 tracking-2 py-1.5 rounded-40'
                )}
              >
                <ChevronDoubleUpIcon
                  className={`${open ? '' : 'text-opacity-70'}
						 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                  aria-hidden="true"
                />
                <span>{startCase(lowerCase(value))}</span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="z-10 w-48 px-4"
                >
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white px-4 py-2 space-y-1">
                      {keys(departments).map((key) => (
                        <div
                          onClick={() => onChange(departments[key].value)}
                          key={departments[key].value}
                          className="text-grayscale-900 text-13 leading-1.5 cursor-pointer"
                        >
                          {startCase(lowerCase(departments[key].value))}
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>

      <div className="text-grayscale-700 flex items-center space-x-1 px-2">
        <button onClick={() => onZoomIn()} title="Zoom in">
          <ZoomInIcon className="w-5 h-5" />
        </button>

        <button onClick={() => onZoomOut()} title="Zoom Out">
          <ZoomOutIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default DepartmentFilter;
