import axios from 'axios';
import { upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChartBarIcon } from '@heroicons/react/solid';

import TrendsModal from '../../../components/TrendsModal/TrendsModal';
import RelatedSearchTerms from '../../components/RelatedSearchTerms';
import AllocationEditor from '../../components/AllocationEditor';
import StatusSwitch from '../../components/StatusSwitch';

import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import { metricColumns } from 'features/advertising/utils/columns';

import {
  EQUAL_TO,
  LESS_THAN,
  STATUS_OPTIONS,
  TARGETS,
} from 'features/advertising/utils/constants';

import { userCan } from 'utils/permission';

const Targets = ({ accountId, marketplace, campaignType }) => {
  const queryParams = new URLSearchParams(window.location.search);

  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { user } = useSelector((state) => state.auth);

  const [openTrends, setOpenTrends] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('targets-column') ??
      'advTargetId,advCampaignId,targetingText,bid,state,cost,sales,orders'
  );

  const [showSearchTerms, setShowSearchTerms] = useState(false);
  const [targets, setTargets] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    campaignType,
    search: '',
    attributes: [
      'advTargetId',
      'advAdGroupId',
      'advCampaignId',
      'state',
      'targetingText',
      'bid',
      'impressions',
      'clicks',
      'ctr',
      'cost',
      'cpc',
      'orders',
      'sales',
      'acos',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'roas',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
    include: ['previousData', 'campaign', 'adGroup'],
    sort: 'cost:desc',
    advAdGroupId: queryParams.has('adGroupId')
      ? queryParams.get('adGroupId')
      : null,
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/account/advertising/targets`, {
        params: {
          ...params,
          accountId,
          marketplace,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
      });

      if (isSubscribed) {
        setTargets(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, campaignType, dateRange, params]);

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('targets-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const columns = [
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'targetingText',
      text: 'Expression',
      sort: true,
      headerStyle: { minWidth: '275px' },
      formatter: (cell, row) => {
        const showSearchTerms = () => {
          setSelectedTarget(row);
          setShowSearchTerms(true);
        };

        return (
          <>
            <p>{cell}</p>
            {row.adGroup && (
              <p className="text-grayscale-600 text-tiny">
                Ad Group: {row.adGroup.name}
              </p>
            )}
            <span
              className="text-tiny text-secondary-light hover:text-secondary cursor-pointer font-bold tracking-2"
              onClick={showSearchTerms}
            >
              See related search terms
            </span>
          </>
        );
      },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
    },
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'advCampaign',
      exportable: false,
      text: 'Campaign',
      sort: false,
      headerStyle: { minWidth: '200px' },
      formatter: (cell, row) => (row.campaign ? row.campaign.name : ''),
    },
    {
      auto: true,
      default: true,
      category: 'settings',
      dataField: 'matchType',
      text: 'Match Type',
      sort: true,
      headerStyle: { minWidth: '200px' },
      classes: 'text-center',
      headerClasses: 'text-center',
    },
    {
      sort: false,
      default: true,
      hideable: true,
      text: 'Status',
      dataField: 'state',
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity="targets"
          entityId={row.advTargetId}
          accountId={accountId}
          marketplace={marketplace}
          disabled={
            !userCan(user, 'ppc.target.update.status|ppc.target.update.status')
          }
        />
      ),
      category: 'settings',
    },
    {
      dataField: 'bid',
      hideable: true,
      category: 'settings',
      text: 'Bid',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: { minWidth: '175px' },
      formatter: (cell, row) => (
        <AllocationEditor
          accountId={accountId}
          marketplace={marketplace}
          entity="targets"
          entityId={row.advTargetId}
          currentValue={cell}
          editable={userCan(
            user,
            'ppc.target.update.bid|ppc.target.update.bid'
          )}
          attribute="bid"
        />
      ),
    },
    ...metricColumns().filter((col) => {
      return col.campaignTypes
        ? col.campaignTypes.includes(campaignType)
        : true;
    }),
    {
      auto: true,
      default: true,
      dataField: 'advTargetId',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const onClick = () => {
          setSelectedTarget(row);
          setOpenTrends(true);
        };

        return (
          <div className="flex">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={onClick}
            />
          </div>
        );
      },
    },
  ];

  const statusOptions = STATUS_OPTIONS.map((value) => {
    const display = upperFirst(value);
    return { display, value };
  });

  return (
    <div id={`advertising-${campaignType}-targets`}>
      <TrendsModal
        title={selectedTarget.keywordText}
        open={openTrends}
        setOpen={(value) => {
          setSelectedTarget({});
          setOpenTrends(value);
        }}
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selectedTarget}
        url={
          selectedTarget.advTargetId
            ? '/account/advertising/analytics/records'
            : ''
        }
        additionalParams={{ advTargetId: selectedTarget.advTargetId }}
      />

      <RelatedSearchTerms
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        open={showSearchTerms}
        setOpen={setShowSearchTerms}
        entity={selectedTarget}
        campaignType={campaignType}
        title={selectedTarget ? selectedTarget.keywordText : ''}
        attribute="advTargetId"
      />

      <AdvertisingTable
        params={params}
        list={targets}
        columns={columns}
        accountId={accountId}
        recordType={TARGETS}
        keyField="advTargetId"
        marketplace={marketplace}
        campaignType={campaignType}
        onChangeParams={setParams}
        searchClassName="lg:col-span-3"
        filtersClassName="lg:col-span-3"
        exportClassName="lg:col-span-3"
        columnPickerClassName="lg:col-span-3"
        searchPlaceholder="Search ad group"
        attributesKey={`${accountId}-${campaignType}-${TARGETS}`}
        visibleColumns={visibleColumns}
        onChangeVisibleColumns={onChangeVisibleColumns}
        endpoint="ad-groups"
        exportInclude={['campaign']}
        additionalFilters={[
          {
            attribute: 'state',
            display: 'Status',
            comparison: EQUAL_TO,
            value: '',
            options: statusOptions,
            placeholder: 'Status',
          },
          {
            attribute: 'bid',
            display: 'Bid',
            comparison: LESS_THAN,
            value: '',
          },
        ]}
      />
    </div>
  );
};

export default Targets;
