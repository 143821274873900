import Button from 'components/Button';
import useInvoice from 'hooks/useInvoice';
import usePermissions from 'hooks/usePermissions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import { fetchInvoiceErrors } from '../invoiceSlice';

const CommissionErrors = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const [saving, setSaving] = useState(false);
  const { invoice, commissionErrors } = useSelector((state) => state.invoice);
  const status = useInvoice(invoice);
  const { alertSuccess, alertError } = useAlert();

  const onResolveCommissionError = async () => {
    setSaving(true);

    try {
      const res = await axios.put(
        `agency/invoices/errors/${commissionErrors.invoiceErrorId}`,
        { status: 'resolved' }
      );
      alertSuccess('Commission Error Resolved', res.data.message);
      dispatch(fetchInvoiceErrors(invoice.invoice_id));
    } catch (error) {
      alertError('Commission error not resolved', error.response.data.message);
    } finally {
      setSaving(false);
    }

    // if (res.data.success === true) {
    //   setCommissionErrors(null);
    //   //await fetchInvoice();
    //   dispatch(setAlert('success', 'Commission error resolved'));
    // } else {
    //   dispatch(setAlert('error', 'operation encountered an error'));
    // }
  };
  return (
    commissionErrors &&
    status.isEditable(invoice.status) &&
    status.notPaid() &&
    userCan('invoices.commissionerror.resolve') && (
      <div className="px-4 py-2  bg-gray-100 rounded-xl mb-5 shadow-sm sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <p>
            Commissions for this invoice were not auto-added. Reason:&nbsp;
            <span className="text-red-500">{commissionErrors.description}</span>
            <br />
            Click on Edit Invoice to add commissions manually
          </p>
        </div>
        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <Button
            classes="border-0 font-bold tracking-wider "
            bgColor="green-600"
            hoverColor="green-700"
            roundedSize="2xl"
            textColor="white"
            px={8}
            py={1}
            shadow=""
            type="button"
            showLoading={false}
            loading={saving}
            onClick={onResolveCommissionError}
          >
            Mark As Resolved
          </Button>
        </div>
      </div>
    )
  );
};

export default CommissionErrors;
