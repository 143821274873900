import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { object, string, mixed } from 'yup';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import useAlert from 'hooks/useAlert';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { Password } from 'components/Forms';

const SetPassword = ({ onSuccess }) => {
  const { alertSuccess, alertError } = useAlert();

  const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  };

  const rules = [
    {
      label: '8 characters minimum',
      pass: (value) => value.length >= 8,
    },
    {
      label: 'at least one letter',
      pass: (value) => /[A-Za-z]/.test(value),
    },
    {
      label: 'at least one number',
      pass: (value) => /.*\d.*/.test(value),
    },
    {
      label: 'at least special one character',
      pass: (value) => /[\W_]/.test(value),
    },
  ];

  const validationSchema = object().shape({
    password: string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters.'),
    newPassword: string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters.')
      .test(
        'atleast-1-letter',
        `Password must contain ${rules[1].label}`,
        (value) => rules[1].pass(value)
      )
      .test(
        'atleast-1-number',
        `Password must contain ${rules[2].label}`,
        (value) => rules[2].pass(value)
      )
      .test(
        'atleast-1-special',
        `Password must contain ${rules[3].label}`,
        (value) => rules[3].pass(value)
      )
      .test(
        'not-same-as-old',
        'Password must not be same as the current one',
        (value, ctx) => value !== ctx.parent.password
      ),
    confirmPassword: mixed().test(
      'match',
      'Passwords do not match',
      function () {
        return this.parent.newPassword === this.parent.confirmPassword;
      }
    ),
  });

  const onSubmit = async (values) => {
    await axios
      .put('/auth/me/change-password', values)
      .then(async (response) => {
        if (response.data.success) {
          alertSuccess('Password changed', 'Successfully updated password');
          onSuccess();
        }
      })
      .catch((error) => {
        alertError('Error updating password', error.response.data.message);
      });
  };

  return (
    <>
      <h2 className="text-center text-2xl font-extrabold text-gray-900">
        Set your password
      </h2>
      <div className="mt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ isSubmitting, values }) => (
            <Form className="flex flex-col space-y-3">
              <div className="mt-5 space-y-6">
                <div className="">
                  <Label textColor="text-grayscale-800">Current Password</Label>

                  <Field
                    name="password"
                    as="input"
                    render={({ field }) => <Password {...field} />}
                  />

                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-error text-tiny"
                  />
                </div>

                <div className="">
                  <Label textColor="text-grayscale-800">New Password</Label>
                  <Field
                    name="newPassword"
                    as="input"
                    render={({ field }) => <Password {...field} />}
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-error text-tiny"
                  />
                </div>

                <div className="">
                  <Label textColor="text-grayscale-800">
                    Confirm New Password
                  </Label>

                  <Field
                    name="confirmPassword"
                    as="input"
                    render={({ field }) => <Password {...field} />}
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-error text-tiny"
                  />
                </div>

                <div className="grid grid-cols-2 text-13 text-grayscale-900 leading-1.5 mt-5 gap-2">
                  {rules.map((rule) => (
                    <div
                      key={rule.label}
                      className="flex space-x-1 items-center"
                    >
                      {rule.pass(values.newPassword) ? (
                        <CheckCircleIcon className="w-4 h-4 text-success-dark" />
                      ) : (
                        <div className="w-4 h-4 rounded-full border text-grayscale-900"></div>
                      )}

                      <span>{rule.label}</span>
                    </div>
                  ))}
                </div>

                <Button
                  type="submit"
                  textSize="sm"
                  textColor="grayscale-300"
                  fontWeight="bold"
                  classes="w-full tracking-2 justify-center"
                  loading={isSubmitting}
                  showLoading={true}
                  roundedSize="40"
                  px={8}
                  py={4}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SetPassword;
