import axios from 'axios';
import { useEffect, useState } from 'react';

import { useRoles } from 'features/roles';
import DatePicker from 'features/datePicker/DatePicker';

import Overview from '../components/Overview';
import UserList from '../components/UserList';
import ClientsSummary from '../components/ClientsSummary';
import ExportButton from '../components/ExportButton';

const TeamDashboard = () => {
  const { PPC_TEAM_LEAD } = useRoles();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const [overall, ppcTeamLeads] = await Promise.all(
        [
          { url: '/agency/clients/analytics/summary', params: {} },
          {
            url: '/users/all',
            params: {
              roleCode: PPC_TEAM_LEAD.value,
              attributes: [
                'userId',
                'firstName',
                'lastName',
                'totalClients',
                'totalOnHoldClients',
                'totalActiveClients',
                'totalPendingClients',
                'totalTerminatedClients',
                'totalOnboardingClients',
                'totalServiceAsins',
              ],
            },
          },
        ].map(async ({ url, params }) => {
          const response = await axios.get(url, { params });

          return response.data.data;
        })
      );

      let data = [
        {
          userId: '',
          firstName: 'All',
          lastName: 'Teams',
          totalActiveClients: overall.activeCount,
          totalClients: overall.totalCount,
          totalOnHoldClients: overall.onHoldCount,
          totalOnboardingClients: overall.forOnboardingCount,
          totalPendingClients: overall.pendingCount,
          totalServiceAsins: overall.totalAsinCount,
          totalTerminatedClients: overall.terminatedCount,
        },
        ...ppcTeamLeads,
      ];

      if (isSubscribed) {
        setTeams(data);
        setTeam(data[0]);
      }
    };

    fetchData().catch((error) => {
      console.log(error.message);
    });

    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <p className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Team Performance Overview
      </p>

      <div className="flex justify-between items-center mt-4">
        <div className="my-4 flex items-center">
          <p className="text-13 leading-1.5 text-grayscale-900 mr-4">
            PPC Team Lead
          </p>

          <select
            className="text-13 text-grayscale-800 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
            value={team?.userId}
            onChange={(e) =>
              setTeam(
                teams.find(
                  (team) => team.userId.toString() === e.target.value.toString()
                )
              )
            }
          >
            {teams.map((team) => (
              <option key={team.userId} value={team.userId}>
                {`${team.firstName} ${team.lastName}`}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center justify-center gap-2">
          <ExportButton podId={team.podId} />
          <DatePicker showLabel={false} />
        </div>
      </div>

      <Overview
        title={`${team.firstName} ${team.lastName}` || 'All Teams'}
        totalClients={team.totalClients}
        totalTerminatedClients={team.totalTerminatedClients}
        totalServiceAsins={team.totalServiceAsins}
      />

      <UserList supervisorId={team.userId} />

      {!!team.totalClients && (
        <ClientsSummary
          title={team.name || 'All Teams'}
          totalClients={team.totalClients}
          totalOnHoldClients={team.totalOnHoldClients}
          totalActiveClients={team.totalActiveClients}
          totalPendingClients={team.totalPendingClients}
          totalTerminatedClients={team.totalTerminatedClients}
          totalOnboardingClients={team.totalOnboardingClients}
        />
      )}
    </div>
  );
};

export default TeamDashboard;
