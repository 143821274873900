import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from 'components/Button';
import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';
import Label from 'components/Forms/Label';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'utils/classNames';
import { TrashIcon, ChevronLeftIcon } from '@heroicons/react/outline';

const FilterModal = ({ open, setOpen, applyFilters, userFilter }) => {
  const [filters, setFilters] = useState([...userFilter]);
  useEffect(() => {
    setFilters([...userFilter]);
  }, [userFilter]);

  const defaultFilter = {
    id: uuidv4(),
    field: 'companyName',
    operator: 'contains',
    value: '',
  };

  // Define field options
  const fieldOptions = [
    { label: 'Company Name', value: 'companyName' },
    { label: 'ASIN', value: 'asin' },
    { label: 'Email', value: 'email' },
    { label: 'Website', value: 'website' },
    { label: 'Product Category', value: 'productCategory' },
    { label: 'Ready to Pitch Date', value: 'approvedDate' },
    { label: 'Pitched Date', value: 'pitchedDate' },
    { label: 'Booked Date', value: 'dateBooked' },
    { label: 'Creation Date', value: 'createdAt' },
    { label: 'Status Update Date', value: 'updatedAt' },
    { label: 'ASIN Price', value: 'asinPrice' },
    { label: 'ASIN Review', value: 'asinReviews' },
    { label: 'No of ASINs', value: 'noOfAsin' },
    { label: 'Marketplace', value: 'marketplace' },
    { label: 'ASIN Revenue', value: 'currentEarnings' },
    { label: 'Storefront Revenue', value: 'storeFrontEarnings' },
    { label: 'Competitor ASIN Revenue', value: 'revenue' },
  ];

  // Define field types
  const fieldDate = [
    'approvedDate',
    'dateBooked',
    'pitchedDate',
    'createdAt',
    'updatedAt',
  ];

  const fieldNumber = [
    'asinPrice',
    'asinReviews',
    'currentEarnings',
    'storeFrontEarnings',
    'revenue',
    'noOfAsin',
  ];

  const fieldSelect = ['status'];

  // Define operator options based on field type
  const getOperatorsForField = (field) => {
    if (fieldDate.includes(field)) {
      return [
        { label: 'Equals', value: 'equals' },
        { label: 'Between', value: 'between' },
      ];
    } else if (fieldNumber.includes(field)) {
      return [
        { label: 'Equals', value: 'equals' },
        { label: 'Between', value: 'between' },
      ];
    } else if (fieldSelect.includes(field)) {
      return [
        { label: 'Equals', value: 'equals' },
        { label: 'Not Equals', value: 'notEquals' },
        { label: 'In', value: 'in' },
        { label: 'Not In', value: 'notIn' },
      ];
    } else {
      return [
        { label: 'Contains', value: 'contains' },
        { label: 'Equals', value: 'equals' },
        { label: 'Not Contain', value: 'notContains' },
      ];
    }
  };

  // Functions to manage filters
  const addFilter = () => {
    setFilters([
      ...filters,
      {
        id: uuidv4(),
        field: 'companyName',
        operator: 'contains',
        value: '',
      },
    ]);
  };

  const updateFilter = (id, updatedFilter) => {
    setFilters(
      filters.map((filter) =>
        filter.id === id ? { ...filter, ...updatedFilter } : filter
      )
    );
  };

  const removeFilter = (id) => {
    setFilters(filters.filter((filter) => filter.id !== id));
  };

  // Function to clear all filters
  const clearFilters = () => {
    setFilters([defaultFilter]); // Reset filters to the default state
  };

  // Function to render value input based on field type
  const renderValueInput = (filter) => {
    const { field, operator, value } = filter;

    if (fieldDate.includes(field)) {
      if (operator === 'between') {
        return (
          <div className="flex space-x-2">
            <div>
              <Label>Start Date</Label>
              <Input
                type="date"
                value={value?.start || ''}
                onChange={(e) =>
                  updateFilter(filter.id, {
                    value: { ...value, start: e.target.value },
                  })
                }
                placeholder="Start Date"
              />
            </div>
            <div>
              <Label>End Date</Label>
              <Input
                type="date"
                value={value?.end || ''}
                onChange={(e) =>
                  updateFilter(filter.id, {
                    value: { ...value, end: e.target.value },
                  })
                }
                placeholder="End Date"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <Label>Date</Label>
            <Input
              type="date"
              value={value || ''}
              onChange={(e) =>
                updateFilter(filter.id, { value: e.target.value })
              }
              placeholder="Select Date"
            />
          </div>
        );
      }
    } else if (fieldNumber.includes(field)) {
      if (operator === 'between') {
        return (
          <div className="flex space-x-2">
            <div>
              <Label>Min</Label>
              <Input
                type="number"
                value={value?.min || ''}
                onChange={(e) =>
                  updateFilter(filter.id, {
                    value: { ...value, min: e.target.value },
                  })
                }
                placeholder="Min"
              />
            </div>
            <div>
              <Label>Max</Label>
              <Input
                type="number"
                value={value?.max || ''}
                onChange={(e) =>
                  updateFilter(filter.id, {
                    value: { ...value, max: e.target.value },
                  })
                }
                placeholder="Max"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <Label>Value</Label>
            <Input
              type="number"
              value={value || ''}
              onChange={(e) =>
                updateFilter(filter.id, { value: e.target.value })
              }
              placeholder="Enter Value"
            />
          </div>
        );
      }
    } else if (fieldSelect.includes(field)) {
      return (
        <div>
          <Label>Status</Label>
          <Select
            value={value || ''}
            onChange={(e) => updateFilter(filter.id, { value: e.target.value })}
          >
            <option value="">Select Status</option>
            <option value="Open">Open</option>
            <option value="Closed">Closed</option>
          </Select>
        </div>
      );
    } else {
      return (
        <div>
          <Label>Value</Label>
          <Input
            type="text"
            value={value || ''}
            onChange={(e) => updateFilter(filter.id, { value: e.target.value })}
            placeholder="Enter Value"
          />
        </div>
      );
    }
  };

  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        onClose={() => setOpen(false)}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-4 scale-95"
          >
            <div className="relative inline-block w-full max-w-3xl p-6 my-8 overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-bold leading-6 text-gray-900"
              >
                Filter Leads
              </Dialog.Title>
              {/* Modal Body */}
              <div className="mt-4 relative">
                {filters.map((filter) => (
                  <div key={filter.id} className="grid grid-cols-12 gap-4 mb-4">
                    {/* Field Selector */}
                    <div className="col-span-4">
                      <Label>Field</Label>
                      <Select
                        value={filter.field}
                        onChange={(e) =>
                          updateFilter(filter.id, {
                            field: e.target.value,
                            operator: 'contains',
                            value: '',
                          })
                        }
                      >
                        {fieldOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </div>

                    {/* Operator Selector */}
                    <div className="col-span-2">
                      <Label>Operator</Label>
                      <Select
                        value={filter.operator}
                        onChange={(e) =>
                          updateFilter(filter.id, { operator: e.target.value })
                        }
                      >
                        {getOperatorsForField(filter.field).map((op) => (
                          <option key={op.value} value={op.value}>
                            {op.label}
                          </option>
                        ))}
                      </Select>
                    </div>

                    {/* Value Input */}
                    <div className="col-span-4">{renderValueInput(filter)}</div>

                    {/* Remove Button */}
                    <div className="col-span-2 flex justify-end items-center mt-5">
                      <button
                        className="bg-white px-1 disabled:opacity-30 border-t border-b border-grayscale-500 h-9 hover:bg-grayscale-500"
                        onClick={() => removeFilter(filter.id)}
                      >
                        <TrashIcon color="red" className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}

                {/* Add Filter Button */}
                <Button
                  roundedSize="2xl"
                  classes="tracking-wider font-bold"
                  px={6}
                  py={2}
                  textSize="xs"
                  color="green"
                  onClick={addFilter}
                >
                  Add Filter
                </Button>
              </div>
              {/* Modal Actions */}
              <div className="mt-4 flex justify-between space-x-2">
                <Button
                  classes="tracking-wider font-bold"
                  px={6}
                  py={2}
                  textSize="xs"
                  color="red"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  classes="tracking-wider font-bold"
                  px={6}
                  py={2}
                  textSize="xs"
                  color="gray"
                  onClick={clearFilters}
                >
                  Clear Filters
                </Button>

                <Button
                  classes="tracking-wider font-bold"
                  px={6}
                  py={2}
                  textSize="xs"
                  color="green"
                  onClick={() => {
                    applyFilters(filters);
                    setOpen(false);
                  }}
                >
                  Apply Filters
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FilterModal;
