import { useEffect } from 'react';
import ModalHeader from 'components/ModalHeader';
import { Modal } from 'components';
import { useFormikContext } from 'formik';
import { SearchIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { Field } from 'formik';
import InputPrepend from 'components/Forms/InputPrepend';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import { useSelector } from 'react-redux';

export default function SelectProductASINModal({
  open,
  setOpen,
  search,
  setSearch,
  productASINs,
  action = 'create',
  upsell,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { alertError } = useAlert();
  const onSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const onClose = () => {
    setSearch('');
    setOpen(false);
  };

  const onSubmit = (values) => {
    const { serviceAsins, selectedAsins } = values;
    if (selectedAsins.length === 0) {
      alertError('Error', 'Must select at least one item');
      return;
    }

    let asinData = [];

    selectedAsins.forEach((asin) => {
      let isCheck = false;
      productASINs.forEach((p) => {
        if (p.asin === asin) {
          if (serviceAsins.length > 0 && !isCheck) {
            serviceAsins.forEach((prev) => {
              if (prev.asin === asin) {
                asinData.push({
                  asin: prev.asin,
                  price: prev.price,
                  qty: prev.qty,
                  ...(action === 'create'
                    ? {}
                    : {
                        upsellServiceAsinId: prev.upsellServiceAsinId,
                        upsellId: upsell.upsellId,
                      }),
                });
                isCheck = true;
              }
            });
            if (!isCheck) {
              asinData.push({
                asin: asin,
                price: p.price,
                qty: 1,
                ...(action === 'create'
                  ? {}
                  : {
                      upsellServiceAsinId: '',
                      upsellId: upsell.upsellId,
                    }),
              });
            }
          } else {
            asinData.push({
              asin: asin,
              price: p.price,
              qty: 1,
              ...(action === 'create'
                ? {}
                : {
                    upsellServiceAsinId: '',
                    upsellId: upsell.upsellId,
                  }),
            });
          }
        }
      });
    });

    setFieldValue('serviceAsins', asinData);
    delete values.selectedAsins;
    onClose();
  };

  useEffect(() => {
    setSearch('');

    if (agencyClient.account.sellingPartnerId) {
      //initial asin list start
      let ASINs = [];
      if (values.serviceAsins.length > 0) {
        ASINs = values.serviceAsins.map((s) => {
          return s.asin;
        });
      }
      setFieldValue('selectedAsins', ASINs);
      //initial asin list start
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-30"
      >
        <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title={''}
            setOpen={setOpen}
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={onClose}
            showCloseButton={true}
          />
          <div
            role="group"
            aria-labelledby="checkbox-group"
            className="flex flex-col space-y-2.5 px-7 pb-5"
          >
            <>
              <InputPrepend
                name="search"
                onChange={(e) => onDebouncedSearch(e)}
                type="text"
                placeholder="Search..."
                prependText={<SearchIcon className="w-3 h-3" />}
                border="border-none"
                rounded="rounded-xl"
                classes="text-xs bg-gray-50"
                autoFocus
              />
              <div className="h-96 overflow-y-auto py-1">
                {productASINs
                  .filter((asin) =>
                    search
                      ? asin.asin.toLowerCase().includes(search.toLowerCase())
                      : true
                  )
                  .map((asin, i) => {
                    return (
                      <label
                        key={asin.asin}
                        className="flex items-center capitalize space-x-2.5 pb-1.5"
                      >
                        <Field
                          type="checkbox"
                          name="selectedAsins"
                          value={asin.asin}
                          className="text-custom-blue focus:border-custom-blue ring-0 focus:ring-0 focus:ring-custom-blue rounded"
                        />
                        <span className="text-base leading-none">
                          {asin.asin}
                        </span>
                      </label>
                    );
                  })}
              </div>
            </>
          </div>
          <div className="flex justify-between sticky bottom-0 bg-white py-4 px-10 z-50">
            <button
              className="text-sm text-custom-sky font-bold tracking-widest"
              onClick={() => {
                setFieldValue('serviceAsins', []);
                onClose();
              }}
              type="button"
            >
              Clear
            </button>
            <Button
              classes="border-0 font-bold tracking-widest"
              bgColor="green-500"
              hoverColor="green-400"
              roundedSize="full"
              textColor="white"
              px={6}
              py={1}
              shadow=""
              textSize=""
              onClick={() => {
                onSubmit(values);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
