import axios from 'axios';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { useRoles } from 'features/roles';

import TeamOverview from '../components/TeamOverview';
import TeamAccountSpecialist from '../components/TeamAccountSpecialist';

const TeamLevel = () => {
  const { BRAND_STRATEGIST } = useRoles();

  const [brandStrategists, setBrandStrategists] = useState([]);
  const [brandStrategist, setBrandStrategist] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const { data } = await axios.get('/v2/users', {
        params: {
          'role.code': BRAND_STRATEGIST.value,
          include: ['avatar'],
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'roleId',
            'totalClients',
            'totalServiceAsins',
            'totalTerminatedClients',
            'totalFullServiceClients',
            'assignedClientsMonthlyRevenue',
          ],
        },
      });

      if (isSubscribed) {
        setBrandStrategists(data.data);

        if (data.data.length) {
          setBrandStrategist(data.data[0]);
        }
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <div className="flex space-x-3 items-center">
        <p className="text-13 text-grayscale-900 leading-1.5">
          Brand Strategist
        </p>

        <select
          value={brandStrategist?.userId}
          onChange={(e) =>
            setBrandStrategist(
              brandStrategists.find((i) => i.userId === e.target.value)
            )
          }
          className="text-13 text-grayscale-800 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          {brandStrategists.map((brandStrategist) => (
            <option key={brandStrategist.userId} value={brandStrategist.userId}>
              {brandStrategist.firstName} {brandStrategist.lastName}
            </option>
          ))}
        </select>
      </div>

      {!isEmpty(brandStrategist) && (
        <>
          <TeamOverview brandStrategist={brandStrategist} />

          <TeamAccountSpecialist supervisorId={brandStrategist?.userId} />
        </>
      )}
    </div>
  );
};

export default TeamLevel;
