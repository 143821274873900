import axios from 'axios';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import {
  ChevronLeftIcon,
  ArrowLeftIcon,
  MinusIcon,
  ExternalLinkIcon,
  XIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';
import useComponentVisible from 'hooks/useComponentVisible';
import Comment from './components/Comment';
import CommentEditor from 'components/Editor/CommentEditor';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchClientsEmployees,
  fetchOlderUpdates,
  fetchUpdate,
  fetchUpdates,
  getAttachments,
  setOlderUpdates,
} from '../agencyClientSlice';

import classNames from 'utils/classNames';
import Loading from 'components/Loading';
import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';
import usePermissions from 'hooks/usePermissions';
import Email from './components/Email';
import MailEditor from 'components/Editor/MailEditor';
import Log from 'components/Updates/Log';
import MailTemplatesModal from 'components/Editor/MailTemplatesModal';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { isFunction } from 'lodash';
import MailEditorModal from 'components/Editor/MailEditorModal';

const Updates = ({ setVisible }) => {
  const { userCan } = usePermissions();
  const { agencyClientId } = useParams();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [openEditor, setOpenEditor] = useState('comment');
  const { url } = useRouteMatch();
  const [openModal, setOpenModal] = useState(false);
  const [isExpandEmail, setIsExpandEmail] = useState(false);
  const { updates, olderUpdates, agencyClient } = useSelector(
    (state) => state.agencyClient
  );
  const { all: employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const qRef = useRef();
  const messagesEndRef = useRef(null);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
    search: '',
  });
  const [oldParams, setOldParams] = useState({
    page: 2,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
    search: '',
  });
  const [showSearch, setShowSearch] = useState(false);
  const [noScroll, setNoScroll] = useState(false);
  const [loading, setLoading] = useState(false);

  const tabButtonsList = [
    {
      ttl: 'All',
      value: '',
    },
    {
      ttl: 'Pin',
      value: 'pinnedComments',
    },
    {
      ttl: 'Users',
      value: 'userComments',
    },
    {
      ttl: 'System',
      value: 'systemComments',
    },
  ];

  const getTabColor = (label) => {
    if (params.scopes.length === 0 && label === '') {
      return 'bg-gray-500 rounded  text-white';
    } else if (params.scopes.includes(`${label}`)) {
      return 'bg-gray-500 text-white';
    } else {
      return 'bg-white text-gray-700';
    }
  };

  useEffect(() => {
    if (params.scopes.includes('pinnedComments')) {
      dispatch(setOlderUpdates({ nextPage: 1, rows: [] }));
    }
    dispatch(fetchUpdates({ params, agencyClientId }));
  }, [agencyClientId, params]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!noScroll) {
      scrollToBottom();
    }
    setNoScroll(false);
  }, [updates]);

  useEffect(() => {
    if (openEditor === 'email') {
      setIsComponentVisible(true);
    }
    setTimeout(() => {
      if (qRef && qRef.current) {
        qRef.current.editor.focus();
      }
    }, 500);
  }, [openEditor]);

  useEffect(() => {
    const events = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/agency/liveupdates/client?id=${agencyClientId}`
    );

    events.addEventListener(`upd-${agencyClientId}`, ({ data }) => {
      const agencyClientUpdateId = parseInt(JSON.parse(data));
      dispatch(fetchUpdate(agencyClientUpdateId));
    });

    events.onerror = (e) => {
      console.log('An error occurred while attempting to connect.');
    };

    return () => events.close();
  }, [agencyClientId]);

  const refreshList = (autoScroll = true) => {
    setLoading(true);
    if (!autoScroll) {
      setNoScroll(true);
    }
    if (params)
      dispatch(fetchUpdates({ params, agencyClientId })).then(() => {
        setLoading(false);
      });
  };

  const loadOlderMessages = () => {
    setNoScroll(true);
    if (params?.scopes?.length <= 0) {
      dispatch(fetchOlderUpdates({ params: oldParams, agencyClientId })).then(
        (res) => {
          setOldParams({ ...oldParams, page: res.payload.nextPage });
        }
      );
    }
  };

  const getAttachmentLink = (attachment) => {
    return `${process.env.REACT_APP_API_BASE_URL}/agency/clients/attachments/${attachment.agencyClientAttachmentId}/download`;
  };

  const onSaveComment = async (data) => {
    return axios
      .post(`/agency/clients/comments`, { ...data, agencyClientId })
      .then(() => {
        refreshList(true);
        setTimeout(() => {
          setIsComponentVisible(false);
        }, 500);
      });
  };

  const onDeleteComment = async (data) => {
    await axios
      .delete(`/agency/clients/comments/${data.agencyClientCommentId}`)
      .then((res) => {
        refreshList(false);
        if (res.data.data.hasAttachment > 0) {
          dispatch(
            getAttachments({
              params: {
                page: 1,
                pageSize: 10,
                sort: 'dateUploaded:desc',
                agencyClientId,
              },
            })
          );
        }
      });
  };

  const onClose = () => {
    setOpenModal(false);
    setIsComponentVisible(true);
    setIsExpandEmail(false);
  };

  const resetOldMessages = () => {
    dispatch(setOlderUpdates({ nextPage: 1, rows: [] }));
  };

  return (
    <>
      <div className="py-4 px-2 bg-white flex justify-between sticky top-0 z-2">
        {showSearch ? (
          <div className="flex items-center w-full">
            <ButtonLink
              onClick={() => setShowSearch(false)}
              color="gray"
              classes="font-bold tracking-widest"
            >
              <ChevronLeftIcon className="w-5 h-5 inline text-gray-400 mr-1" />
              Back
            </ButtonLink>
            <InputPrepend
              name="search"
              type="text"
              placeholder="Search"
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
              classes="bg-gray-50"
              containerClasses="w-full ml-6"
              rounded="rounded-xl"
              onChange={(e) => setParams({ ...params, search: e.target.value })}
            />
          </div>
        ) : (
          <>
            <h4 className=" text-2xl font-bold flex items-center">
              Updates
              <ButtonLink onClick={() => setShowSearch(true)}>
                <SearchIcon className="ml-5 w-5 h-5 inline text-gray-400" />
              </ButtonLink>
            </h4>
            <span className="relative z-0 inline-flex shadow-sm rounded-md bg-gray-500 p-0.5">
              {tabButtonsList.map((item, index) => (
                <>
                  <button
                    key={index + item.value}
                    type="button"
                    onClick={() => {
                      setParams({
                        ...params,
                        scopes: item.value === '' ? [] : [`${item.value}`],
                      });
                      resetOldMessages();
                    }}
                    className={classNames(
                      '-ml-px  relative inline-flex items-center w-20 tracking-wider py-1.5 rounded-r-sm justify-center text-xs font-bold hover:bg-gray-500 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500',
                      getTabColor(item.value)
                    )}
                  >
                    {item.ttl}
                  </button>
                </>
              ))}
            </span>
            <span className="relative z-0 inline-flex items-center justify-center p-0.5">
              <ButtonLink
                onClick={() => {
                  isFunction(setVisible) && setVisible(false);
                }}
              >
                <ChevronDoubleRightIcon className="w-8 h-10 inline font-bold text-gray-400" />
              </ButtonLink>
            </span>
          </>
        )}
      </div>
      <div className="comment-container px-8 pt-8 space-y-3 min-h-77 relative">
        {!params.scopes.includes('pinnedComments') &&
          !showSearch &&
          olderUpdates.nextPage &&
          updates.rows.length > 0 && (
            <span className="absolute top-0 left-0 w-full text-center pt-1">
              <button
                className=" text-xs underline text-gray-400 hover:text-gray-700 "
                onClick={loadOlderMessages}
              >
                load older messages
              </button>
            </span>
          )}
        {olderUpdates.rows.map((update) =>
          update.log ? (
            <Log key={update.agencyClientUpdateId} data={update.log} />
          ) : update?.comment?.threadId ? (
            <Email
              key={update?.agencyClientUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
            />
          ) : (
            <Comment
              key={update.agencyClientUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
              onDelete={onDeleteComment}
              getAttachmentLink={getAttachmentLink}
            />
          )
        )}
        {olderUpdates.rows.length > 0 && (
          <div className="text-xs text-indigo-700 relative py-6">
            <hr />
            <span className="bg-gray-50 px-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              older messages
            </span>
          </div>
        )}
        {updates.rows.map((update) =>
          update.log ? (
            <Log key={update.agencyClientUpdateId} data={update.log} />
          ) : update?.comment?.threadId ? (
            <Email
              key={update?.agencyClientUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
            />
          ) : (
            <Comment
              key={update?.agencyClientUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
              onDelete={onDeleteComment}
              getAttachmentLink={getAttachmentLink}
            />
          )
        )}

        <div ref={messagesEndRef}>{loading && <Loading />}</div>
      </div>
      {userCan('clients.profile.comments.add') && (
        <div className="add-comment-box sticky bottom-0">
          <div className="gradient-box"></div>
          <div
            ref={ref}
            className="bg-white p-0 text-sm text-gray-500 hover:text-gray-700"
          >
            {isComponentVisible ? (
              openEditor === 'comment' ? (
                <CommentEditor
                  qRef={qRef}
                  open={isComponentVisible}
                  onChangeData={refreshList}
                  setOpenEditor={setOpenEditor}
                  employees={employees}
                  uploaderOptions={{
                    attachmentKey: 'agencyClientAttachmentId',
                    getAttachmentLink: getAttachmentLink,
                    primaryKey: {
                      key: 'agencyClientId',
                      value: agencyClientId,
                    },
                    postUrl: '/agency/clients/attachments',
                    onRefreshList: getAttachments({
                      params: {
                        page: 1,
                        pageSize: 10,
                        sort: 'dateUploaded:desc',
                        agencyClientId,
                      },
                    }),
                  }}
                  onSave={onSaveComment}
                />
              ) : (
                <div>
                  <div className="flex justify-between bg-white border-b border-gray-200 p-4">
                    <div className="flex">
                      <div>
                        <button onClick={() => setOpenEditor('comment')}>
                          <ArrowLeftIcon className="w-5 h-5 inline text-gray-400 mr-3" />
                        </button>
                      </div>
                      <div className="flex-col justify-center">
                        <h6 className="text-base font-normal">New Email</h6>
                      </div>
                    </div>
                    <div className="flex">
                      <div>
                        <button onClick={() => setIsComponentVisible(false)}>
                          <MinusIcon className="w-5 h-5 inline text-gray-400 mr-3" />
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setOpenModal(true);
                            setIsExpandEmail(true);
                          }}
                        >
                          <ExternalLinkIcon className="w-5 h-5 inline text-gray-400 mr-3" />
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setOpenEditor('comment');
                            setIsComponentVisible(false);
                          }}
                        >
                          <XIcon className="w-5 h-5 inline text-gray-400 mr-3" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <MailEditor
                    qRef={qRef}
                    onChangeData={refreshList}
                    setOpenEditor={setOpenEditor}
                    employees={employees}
                    primaryKey={{
                      key: 'agencyClientId',
                      value: agencyClientId,
                    }}
                    postUrl={`/agency/clients/mail`}
                    setOpen={setIsComponentVisible}
                    open={isComponentVisible}
                  />
                </div>
              )
            ) : !agencyClient?.deletedAt ? (
              <>
                {openEditor === 'comment' ? (
                  <p
                    className="p-4 text-xs cursor-pointer"
                    onClick={() => {
                      setIsComponentVisible(true);
                      setTimeout(() => {
                        qRef.current.editor.focus();
                      }, 500);
                    }}
                  >
                    Have any updates or comments? Click here to type!
                  </p>
                ) : (
                  <div className="flex justify-between">
                    <div>
                      <p
                        className="p-4 text-xs cursor-pointer"
                        onClick={() => {
                          setIsComponentVisible(true);
                          setTimeout(() => {
                            qRef.current.editor.focus();
                          }, 500);
                        }}
                      >
                        Click here to continue writing your email
                      </p>
                    </div>
                    <div className="flex-col justify-center">
                      <div className="py-4">
                        <button
                          onClick={() => {
                            setIsComponentVisible(true);
                            setTimeout(() => {
                              qRef.current.editor.focus();
                            }, 500);
                          }}
                        >
                          <ExternalLinkIcon className="w-5 h-5 inline text-gray-400 mr-3" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}{' '}
      <MailEditorModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onClose={onClose}
        refreshList={refreshList}
        setOpenEditor={setOpenEditor}
        employees={employees}
        primaryKey={{
          key: 'agencyClientId',
          value: agencyClientId,
        }}
        postUrl={`/agency/clients/mail`}
        isExpandEmail={isExpandEmail}
      />
      <Switch>
        <Route
          path={`${url}/template`}
          render={() => {
            return <MailTemplatesModal />;
          }}
        />
      </Switch>
    </>
  );
};

export default Updates;
