const cleanify = (data) => {
  let dataCopy = {};

  Object.keys(data)
    .filter((key) => data[key])
    .forEach((key) => (dataCopy[key] = data[key]));

  return dataCopy;
};

export default cleanify;
