import React from 'react';
import { useSelector } from 'react-redux';
import DailySyncStatus from './components/DailySyncStatus';
import EntitiesTable from './components/EntitiesTable';

const Sync = () => {
  const { account, marketplace } = useSelector((state) => state.account);

  return (
    <div className="grid grid-cols-10 gap-4 mb-10">
      <EntitiesTable account={account} marketplace={marketplace} />
      <DailySyncStatus account={account} marketplace={marketplace} />
    </div>
  );
};

export default Sync;
