import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Label, RequiredAsterisk } from 'components/Forms';
import useClient from 'features/clients/useClient';
import { ChevronRightIcon } from '@heroicons/react/outline';
import * as Yup from 'yup';

import useTaskManagement from 'features/taskManagement/useTaskManagement';
import classNames from 'utils/classNames';
import { Button } from 'components';
import { useAlerts } from 'features/alerts';
import TemplateTasks from '../components/TemplateTasks';
import Spinner from 'components/Spinner';

const TemplateDetails = () => {
  const { SERVICES } = useClient();
  const { templateId } = useParams();
  const { popupAlert } = useAlerts();

  const { PARTNER_TYPES, ROADMAP_TYPES, TYPES, getTemplate, updateTemplate } =
    useTaskManagement();

  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await getTemplate(templateId);

      if (isSubscribed) {
        setTemplate(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [templateId]);

  const handleSubmit = async (values, actions) => {
    let payload = { ...values };

    if (values.type !== 'roadmap') {
      delete payload.roadMapType;
    }

    try {
      const response = await updateTemplate(templateId, payload);
      setTemplate(response.data.data);

      setTimeout(() => {
        popupAlert({ type: 'success', title: response.data.message });
      }, 250);
    } catch (error) {
      popupAlert({
        type: 'error',
        title: error?.response?.data?.message || 'Something went wrong',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return loading ? (
    <div className="flex items-center justify-center mt-24">
      <Spinner height={64} width={64} />
    </div>
  ) : (
    <div className="">
      <Formik
        initialValues={{
          type: template.type,
          name: template.name,
          status: template.status,
          partnerType: template.partnerType,
          partnerService: template.partnerService,
          roadMapType: template.roadMapType ?? '',
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required('Template type is required'),
          name: Yup.string().required('Template name is required'),
          partnerType: Yup.string().required('Partner type is required'),
          partnerService: Yup.string().required('Service category is required'),
          roadMapType: Yup.string(),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="flex items-center space-x-2 mb-4">
              <p className="text-13 text-grayscale-700 leading-1.5">
                Task management
              </p>

              <ChevronRightIcon className="w-3 h-3 text-grayscale-700" />

              <p className="text-13 text-grayscale-700 leading-1.5">
                New Partners
              </p>

              <ChevronRightIcon className="w-3 h-3 text-grayscale-700" />

              <p className="text-13 text-grayscale-900 leading-1.5">
                {template.name}
              </p>
            </div>

            <div className="flex justify-between">
              <h3 className="text-25 font-inter tracking-3/4 text-grayscale-900 font-bold mb-6">
                {template?.name}
              </h3>

              <Button
                type="submit"
                classes={'border-0 font-bold tracking-widest ml-2 h-10'}
                bgColor="secondary"
                hoverColor="secondary-light"
                roundedSize="full"
                textColor="grayscale-300"
                px={12}
                py={2}
                shadow=""
                textSize="sm"
                border="border"
                showLoading={true}
                loading={isSubmitting}
              >
                Update
              </Button>
            </div>

            <div className="bg-white">
              <Disclosure>
                <Disclosure.Button
                  as="div"
                  className="w-full p-6 cursor-pointer flex items-center justify-between"
                >
                  <span className="text-left font-bold font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900">
                    Settings
                  </span>

                  <ChevronRightIcon className="w-8 h-8 text-grayscale-700" />
                </Disclosure.Button>

                <Disclosure.Panel className="px-6 pb-6">
                  <div
                    className={classNames(
                      'grid gap-3',
                      values.type == 'roadmap' ? 'grid-cols-5' : 'grid-cols-4'
                    )}
                  >
                    <div className="">
                      <Label htmlFor="name">
                        Template Name
                        <RequiredAsterisk />
                      </Label>
                      <Field
                        name="name"
                        className="form-input disabled-white"
                        type="text"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    <div className="">
                      <Label htmlFor="type">
                        Template Type
                        <RequiredAsterisk />
                      </Label>
                      <Field
                        name="type"
                        className="form-input disabled-white"
                        as="select"
                      >
                        <option value="">Choose One</option>
                        {TYPES.map((type) => (
                          <option
                            key={type.value}
                            value={type.value}
                            disabled={type.disabled}
                          >
                            {type.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>

                    {values.type == 'roadmap' && (
                      <div className="">
                        <Label htmlFor="roadMapType" classes="text-left">
                          Roadmap Type
                        </Label>
                        <Field
                          name="roadMapType"
                          as="select"
                          className="form-select disabled-white"
                        >
                          <option value="">Choose One</option>
                          {ROADMAP_TYPES.map((t) => (
                            <option key={t.value} value={t.value}>
                              {t.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="roadMapType"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    )}

                    <div className="">
                      <Label htmlFor="partnerType" classes="text-left">
                        Partner Type <RequiredAsterisk />
                      </Label>

                      <div>
                        <Field
                          name="partnerType"
                          as="select"
                          className="form-select disabled-white"
                        >
                          <option value="">Choose One</option>
                          {PARTNER_TYPES.map((partnerType) => (
                            <option
                              key={partnerType.value}
                              value={partnerType.value}
                            >
                              {partnerType.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="partnerType"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </div>

                    <div className="">
                      <Label htmlFor="partnerService" classes="text-left">
                        Service Category
                        <RequiredAsterisk />
                      </Label>

                      <Field
                        name="partnerService"
                        as="select"
                        className="form-select disabled-white"
                      >
                        <option value="">Choose One</option>
                        {SERVICES.map((service) => (
                          <option key={service.value} value={service.value}>
                            {service.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="partnerService"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
          </Form>
        )}
      </Formik>

      <TemplateTasks
        template={template}
        layout={TYPES.find((type) => type.value === template.type)?.layout}
      />
    </div>
  );
};

export default TemplateDetails;
