import { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

const Password = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative mt-2 rounded-md shadow-sm">
      <input
        {...props}
        className="form-input text-sm"
        type={show ? 'text' : 'password'}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {show ? (
          <EyeIcon
            className="h-5 w-5 text-grayscale-700 cursor-pointer"
            aria-hidden="true"
            onClick={() => setShow(false)}
          />
        ) : (
          <EyeOffIcon
            className="h-5 w-5 text-grayscale-700 cursor-pointer"
            aria-hidden="true"
            onClick={() => setShow(true)}
          />
        )}
      </div>
    </div>
  );
};

export default Password;
