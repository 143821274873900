import moment from 'moment';

const MonthlyReportHeader = ({
  startDate,
  endDate,
  additionalClass = '',
  client = '',
}) => {
  return (
    <div
      className={`bg-primary h-36 w-full flex flex-col items-center ${additionalClass}`}
    >
      <div className="flex justify-between w-full px-8 mt-6">
        <div className="mt-1">
          <div className="flex items-center justify-between">
            <p className="text-primary-light text-tiny mb-2 leading-normal">
              {moment(startDate).format('MMMM DD, YYYY')} -{' '}
              {moment(endDate).format('MMMM DD, YYYY')}
            </p>
            <p className="text-primary-light text-tiny mb-2 leading-normal">
              {client}
            </p>
          </div>

          <p className="text-xl font-bold text-white tracking-3/4 font-inter">
            Monthly Cost and Revenue
          </p>
        </div>
        <div className="flex justify-between items-center mt-1">
          <svg
            width="108"
            height="33"
            viewBox="0 0 108 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2399_80499)">
              <path
                d="M27.0694 5.18C20.8866 -1.06 10.8198 -1.06 4.63706 5.18C-1.54569 11.42 -1.54569 21.58 4.63706 27.82C10.8198 34.06 20.8866 34.06 27.0694 27.82C33.3314 21.5 33.3314 11.42 27.0694 5.18ZM17.3989 21.02C17.1611 21.58 16.7648 22.14 16.2892 22.54C15.8136 22.94 15.1795 23.34 14.4661 23.58C13.7527 23.82 12.96 23.98 12.0088 23.98C11.0576 23.98 10.1064 23.82 9.07596 23.5C8.12477 23.18 7.17358 22.62 6.38092 21.9L8.60037 19.18C9.15523 19.58 9.71009 19.98 10.3442 20.22C10.9783 20.46 11.5332 20.62 12.0881 20.62C12.6429 20.62 13.1185 20.54 13.3563 20.38C13.5941 20.22 13.7527 19.9 13.7527 19.58C13.7527 19.42 13.6734 19.26 13.5941 19.1C13.5149 18.94 13.3563 18.86 13.1978 18.78C13.0393 18.7 12.8015 18.54 12.5637 18.46C12.3259 18.38 12.0088 18.22 11.6917 18.14L9.86862 17.34C9.47229 17.18 9.07596 17.02 8.7589 16.78C8.36257 16.54 8.04551 16.22 7.80771 15.9C7.56991 15.58 7.33211 15.18 7.17358 14.7C7.01505 14.22 6.93578 13.74 6.93578 13.26C6.93578 12.62 7.09431 11.98 7.33211 11.5C7.56991 10.94 7.96624 10.46 8.44184 10.06C8.91743 9.66 9.47229 9.26 10.1857 9.02C10.8991 8.78 11.6125 8.62 12.4051 8.62C13.2771 8.62 14.149 8.78 15.0209 9.1C15.8928 9.42 16.6855 9.9 17.3989 10.62L15.4172 13.1C14.9417 12.78 14.4661 12.46 13.9905 12.3C13.5149 12.14 13.0393 12.06 12.4844 12.06C12.0088 12.06 11.6125 12.14 11.3747 12.3C11.1369 12.46 10.9783 12.7 10.9783 13.1C10.9783 13.42 11.1369 13.74 11.5332 13.9C11.9295 14.06 12.4844 14.3 13.1185 14.54L14.9417 15.26C15.8928 15.66 16.6062 16.14 17.0818 16.78C17.5574 17.42 17.8745 18.3 17.8745 19.34C17.7952 19.82 17.6367 20.46 17.3989 21.02ZM23.5024 23.74H19.5391V8.78H23.5024V23.74Z"
                fill="white"
              />
              <path
                d="M40.7824 14.7001C40.3069 14.7001 39.8313 14.6201 39.3557 14.4601C38.8801 14.3001 38.4837 14.0601 38.0874 13.7401L39.0386 12.3801C39.3557 12.6201 39.6727 12.7801 39.9898 12.9401C40.3069 13.1001 40.6239 13.1001 40.8617 13.1001C41.1788 13.1001 41.4166 13.0201 41.5751 12.9401C41.7336 12.8601 41.8129 12.7001 41.8129 12.4601C41.8129 12.3801 41.7336 12.2201 41.6544 12.1401C41.5751 12.0601 41.4958 11.9801 41.3373 11.9001C41.1788 11.8201 41.0202 11.7401 40.8617 11.6601C40.7032 11.5801 40.5446 11.5001 40.3068 11.4201C40.0691 11.3401 39.8313 11.2601 39.6727 11.1001C39.4349 11.0201 39.2764 10.8601 39.0386 10.7001C38.8801 10.5401 38.7215 10.3001 38.563 10.1401C38.4837 9.9001 38.4045 9.6601 38.4045 9.3401C38.4045 9.0201 38.4837 8.7001 38.563 8.4601C38.7215 8.2201 38.8801 7.9801 39.1179 7.7401C39.3557 7.5801 39.6727 7.4201 39.9898 7.2601C40.3069 7.1801 40.7032 7.1001 41.0995 7.1001C41.6544 7.1001 42.13 7.1801 42.5263 7.4201C42.9226 7.5801 43.319 7.8201 43.636 8.0601L42.6848 9.3401C42.447 9.1801 42.2092 9.0201 41.8922 8.8601C41.6544 8.7801 41.4166 8.7001 41.1788 8.7001C40.6239 8.7001 40.3861 8.8601 40.3861 9.2601C40.3861 9.3401 40.3861 9.5001 40.4654 9.5801C40.5446 9.6601 40.6239 9.7401 40.7824 9.8201C40.941 9.9001 41.0995 9.9801 41.258 10.0601C41.4166 10.1401 41.5751 10.2201 41.8129 10.2201C42.0507 10.3001 42.2885 10.3801 42.447 10.5401C42.6848 10.6201 42.8434 10.7801 43.0812 10.9401C43.2397 11.1001 43.3982 11.3401 43.5568 11.5801C43.636 11.8201 43.7153 12.0601 43.7153 12.3801C43.7153 12.7001 43.636 13.0201 43.5568 13.2601C43.3982 13.5001 43.2397 13.8201 43.0019 13.9801C42.7641 14.2201 42.447 14.3801 42.0507 14.4601C41.6544 14.7001 41.258 14.7001 40.7824 14.7001Z"
                fill="white"
              />
              <path
                d="M48.0748 14.7C47.5992 14.7 47.1236 14.62 46.648 14.46C46.1724 14.3 45.8553 14.06 45.5383 13.74C45.2212 13.42 44.9834 13.02 44.7456 12.54C44.5871 12.06 44.5078 11.58 44.5078 10.94C44.5078 10.38 44.5871 9.82001 44.8249 9.42001C44.9834 8.94001 45.3005 8.54001 45.5383 8.22001C45.8553 7.90001 46.1724 7.66001 46.5687 7.50001C46.9651 7.34001 47.3614 7.26001 47.837 7.26001C48.3126 7.26001 48.7882 7.34001 49.1845 7.50001C49.5808 7.66001 49.8979 7.90001 50.1357 8.22001C50.3735 8.54001 50.6113 8.86001 50.6906 9.34001C50.8491 9.74001 50.8491 10.22 50.8491 10.7C50.8491 10.86 50.8491 11.1 50.8491 11.26C50.8491 11.42 50.8491 11.58 50.7698 11.66H46.5687C46.648 12.14 46.8858 12.54 47.2029 12.78C47.5199 13.02 47.9163 13.1 48.3918 13.1C48.8674 13.1 49.4223 12.94 49.8979 12.62L50.6113 13.9C50.215 14.14 49.8186 14.38 49.4223 14.46C48.9467 14.7 48.4711 14.7 48.0748 14.7ZM46.4895 10.22H49.026C49.026 9.82001 48.9467 9.50001 48.7882 9.26001C48.6296 9.02001 48.3126 8.86001 47.837 8.86001C47.5199 8.86001 47.2029 8.94001 46.9651 9.18001C46.7273 9.42001 46.5687 9.74001 46.4895 10.22Z"
                fill="white"
              />
              <path
                d="M54.0991 14.7C53.7028 14.7 53.465 14.62 53.1479 14.54C52.9101 14.46 52.6723 14.3 52.5138 14.06C52.3552 13.82 52.276 13.58 52.1967 13.34C52.1174 13.02 52.1174 12.7 52.1174 12.38V4.54004H54.1784V12.54C54.1784 12.78 54.1783 12.94 54.2576 13.02C54.3369 13.1 54.4162 13.18 54.4954 13.18C54.5747 13.18 54.5747 13.18 54.5747 13.18C54.5747 13.18 54.654 13.18 54.7332 13.18L54.971 14.78C54.8917 14.86 54.7332 14.86 54.4954 14.86C54.4954 14.7 54.3369 14.7 54.0991 14.7Z"
                fill="white"
              />
              <path
                d="M57.9831 14.7C57.5868 14.7 57.349 14.62 57.0319 14.54C56.7941 14.46 56.5563 14.3 56.3978 14.06C56.2393 13.82 56.16 13.58 56.0807 13.34C56.0015 13.02 56.0015 12.7 56.0015 12.38V4.54004H58.0624V12.54C58.0624 12.78 58.0624 12.94 58.1416 13.02C58.2209 13.1 58.3002 13.18 58.3794 13.18C58.4587 13.18 58.4587 13.18 58.4587 13.18C58.4587 13.18 58.538 13.18 58.6172 13.18L58.855 14.78C58.7758 14.86 58.6172 14.86 58.3794 14.86C58.4587 14.7 58.2209 14.7 57.9831 14.7Z"
                fill="white"
              />
              <path
                d="M63.2147 14.7C62.7391 14.7 62.2635 14.62 61.7879 14.46C61.3123 14.3 60.9952 14.06 60.6782 13.74C60.3611 13.42 60.1233 13.02 59.8855 12.54C59.6477 12.06 59.6477 11.58 59.6477 10.94C59.6477 10.38 59.727 9.82001 59.9648 9.42001C60.1233 8.94001 60.4404 8.54001 60.6782 8.22001C60.9952 7.90001 61.3123 7.66001 61.7086 7.50001C62.105 7.34001 62.5013 7.26001 62.9769 7.26001C63.4525 7.26001 63.9281 7.34001 64.3244 7.50001C64.7207 7.66001 65.0378 7.90001 65.2756 8.22001C65.5134 8.54001 65.7512 8.86001 65.8305 9.34001C65.989 9.74001 65.989 10.22 65.989 10.7C65.989 10.86 65.989 11.1 65.989 11.26C65.989 11.42 65.989 11.58 65.9097 11.66H61.7086C61.7879 12.14 62.0257 12.54 62.3428 12.78C62.6598 13.02 63.0561 13.1 63.5317 13.1C64.0073 13.1 64.5622 12.94 65.0378 12.62L65.7512 13.9C65.3549 14.14 64.9585 14.38 64.5622 14.46C64.0866 14.7 63.611 14.7 63.2147 14.7ZM61.6294 10.22H64.1659C64.1659 9.82001 64.0866 9.50001 63.9281 9.26001C63.7695 9.02001 63.4525 8.86001 62.9769 8.86001C62.6598 8.86001 62.3428 8.94001 62.105 9.18001C61.8672 9.42001 61.7086 9.74001 61.6294 10.22Z"
                fill="white"
              />
              <path
                d="M67.2573 14.5401V7.42009H69.0012L69.1597 8.70009H69.239C69.4768 8.22009 69.7938 7.82009 70.1902 7.66009C70.5865 7.42009 70.9036 7.34009 71.2999 7.34009C71.5377 7.34009 71.6962 7.34009 71.7755 7.42009C71.934 7.42009 72.0133 7.50009 72.0926 7.50009L71.7755 9.34009C71.617 9.34009 71.5377 9.26009 71.3792 9.26009C71.2999 9.26009 71.1414 9.26009 70.9828 9.26009C70.745 9.26009 70.428 9.34009 70.1109 9.58009C69.7938 9.74009 69.556 10.1401 69.3975 10.6201V14.7801H67.2573V14.5401Z"
                fill="white"
              />
              <path
                d="M38.7214 28.9401V21.8201H40.7824V28.9401H38.7214Z"
                fill="white"
              />
              <path
                d="M42.5264 28.9399V21.8199H44.2702L44.4288 22.6999C44.7458 22.3799 45.0629 22.1399 45.4592 21.9799C45.8555 21.7399 46.2519 21.6599 46.7275 21.6599C47.5201 21.6599 48.075 21.8999 48.3921 22.4599C48.7091 22.9399 48.9469 23.6599 48.9469 24.5399V28.9399H46.8067V24.7799C46.8067 24.2999 46.7275 23.8999 46.5689 23.7399C46.4104 23.5799 46.2519 23.4199 45.9348 23.4199C45.697 23.4199 45.4592 23.4999 45.2214 23.5799C45.0629 23.6599 44.8251 23.8999 44.5873 24.0599V28.8599H42.5264V28.9399Z"
                fill="white"
              />
              <path
                d="M53.227 29.1001C52.7515 29.1001 52.4344 29.0201 52.1173 28.8601C51.8003 28.7001 51.5625 28.5401 51.3247 28.3001C51.1661 28.0601 51.0076 27.7401 50.9283 27.4201C50.8491 27.1001 50.7698 26.7001 50.7698 26.3001V23.4201H49.8186V21.8201L50.9283 21.7401L51.1661 19.8201H52.91V21.7401H54.5746V23.4201H52.91V26.3001C52.91 26.7001 52.9893 27.0201 53.1478 27.1801C53.3063 27.3401 53.5441 27.4201 53.7819 27.4201C53.8612 27.4201 54.0197 27.4201 54.099 27.3401C54.2575 27.3401 54.3368 27.2601 54.416 27.2601L54.7331 28.7801C54.5746 28.8601 54.3368 28.8601 54.099 28.9401C53.9404 29.1001 53.6234 29.1001 53.227 29.1001Z"
                fill="white"
              />
              <path
                d="M58.7757 29.0999C58.3001 29.0999 57.8245 29.0199 57.3489 28.8599C56.8733 28.6999 56.5563 28.4599 56.2392 28.1399C55.9221 27.8199 55.6843 27.4199 55.4465 26.9399C55.288 26.4599 55.2087 25.9799 55.2087 25.3399C55.2087 24.7799 55.288 24.2199 55.5258 23.8199C55.6843 23.3399 56.0014 22.9399 56.2392 22.6199C56.5563 22.2999 56.8733 22.0599 57.2697 21.8999C57.666 21.7399 58.0623 21.6599 58.5379 21.6599C59.0135 21.6599 59.4891 21.7399 59.8854 21.8999C60.2818 22.0599 60.5988 22.2999 60.8366 22.6199C61.0744 22.9399 61.3122 23.2599 61.3915 23.7399C61.55 24.1399 61.55 24.6199 61.55 25.0999C61.55 25.2599 61.55 25.4999 61.55 25.6599C61.55 25.8199 61.55 25.9799 61.4708 26.0599H57.2697C57.3489 26.5399 57.5867 26.9399 57.9038 27.1799C58.2209 27.4199 58.6172 27.4999 59.0928 27.4999C59.5684 27.4999 60.1232 27.3399 60.5988 27.0199L61.3122 28.2999C60.9159 28.5399 60.5196 28.7799 60.1232 28.8599C59.6476 29.0999 59.172 29.0999 58.7757 29.0999ZM57.1904 24.6199H59.7269C59.7269 24.2199 59.6476 23.8999 59.4891 23.6599C59.3306 23.4199 59.0135 23.2599 58.5379 23.2599C58.2208 23.2599 57.9038 23.3399 57.666 23.5799C57.4282 23.8199 57.2697 24.1399 57.1904 24.6199Z"
                fill="white"
              />
              <path
                d="M62.8184 28.94V21.82H64.5622L64.7207 23.1H64.8C65.0378 22.62 65.3549 22.22 65.7512 22.06C66.1475 21.82 66.4646 21.74 66.8609 21.74C67.0987 21.74 67.2572 21.74 67.3365 21.82C67.495 21.82 67.5743 21.9 67.6536 21.9L67.3365 23.74C67.178 23.74 67.0987 23.66 66.9402 23.66C66.8609 23.66 66.7024 23.66 66.5439 23.66C66.3061 23.66 65.989 23.74 65.6719 23.98C65.3549 24.14 65.1171 24.54 64.9585 25.02V29.18H62.8184V28.94Z"
                fill="white"
              />
              <path
                d="M69.7938 29.1001C69.4768 29.1001 69.1597 29.0201 68.9219 28.9401C68.6841 28.8601 68.4463 28.7001 68.2878 28.4601C68.1292 28.2201 67.9707 28.0601 67.8914 27.7401C67.8122 27.5001 67.7329 27.1801 67.7329 26.8601C67.7329 26.1401 68.05 25.5001 68.6841 25.1001C69.3182 24.7001 70.3487 24.3801 71.6962 24.2201C71.6962 23.9001 71.6169 23.6601 71.3791 23.5001C71.2206 23.3401 70.9036 23.1801 70.5865 23.1801C70.2694 23.1801 69.9524 23.2601 69.6353 23.3401C69.3182 23.4201 69.0012 23.5801 68.6841 23.8201L67.8914 22.4601C68.367 22.2201 68.8426 21.9801 69.3182 21.8201C69.7938 21.6601 70.3487 21.5801 70.9035 21.5801C71.8547 21.5801 72.4889 21.8201 72.9645 22.3801C73.4401 22.8601 73.6779 23.7401 73.6779 24.7801V28.8601H71.934L71.7755 28.1401H71.6962C71.3791 28.3801 71.0621 28.6201 70.745 28.7801C70.5072 29.0201 70.1902 29.1001 69.7938 29.1001ZM70.5072 27.5001C70.745 27.5001 70.9828 27.4201 71.1413 27.3401C71.2999 27.2601 71.4584 27.1001 71.6962 26.8601V25.5801C70.9828 25.6601 70.428 25.8201 70.1902 26.0601C69.9524 26.3001 69.7938 26.5401 69.7938 26.7801C69.7938 27.0201 69.8731 27.1801 70.0316 27.2601C70.0316 27.4201 70.2694 27.5001 70.5072 27.5001Z"
                fill="white"
              />
              <path
                d="M78.4338 29.1001C77.9582 29.1001 77.4826 29.0201 77.0863 28.8601C76.69 28.7001 76.2936 28.4601 75.9766 28.1401C75.6595 27.8201 75.4217 27.4201 75.2632 26.9401C75.1047 26.4601 75.0254 25.9001 75.0254 25.3401C75.0254 24.7801 75.1047 24.2201 75.3425 23.7401C75.5803 23.2601 75.8181 22.8601 76.1351 22.5401C76.4522 22.2201 76.8485 21.9801 77.3241 21.8201C77.7997 21.6601 78.2753 21.5801 78.7509 21.5801C79.2265 21.5801 79.5435 21.6601 79.9399 21.8201C80.2569 21.9801 80.574 22.1401 80.8911 22.3801L79.9399 23.7401C79.6228 23.5001 79.3058 23.3401 78.9887 23.3401C78.5131 23.3401 78.0375 23.5001 77.7997 23.9001C77.4826 24.3001 77.3241 24.7801 77.3241 25.4201C77.3241 26.0601 77.4826 26.5401 77.7997 26.9401C78.1168 27.3401 78.5131 27.5001 78.9094 27.5001C79.1472 27.5001 79.385 27.4201 79.5435 27.3401C79.7813 27.2601 79.9399 27.1001 80.1777 26.9401L80.9703 28.3001C80.6533 28.6201 80.2569 28.8601 79.8606 28.9401C79.2265 29.1001 78.8302 29.1001 78.4338 29.1001Z"
                fill="white"
              />
              <path
                d="M84.458 29.1001C83.9824 29.1001 83.6653 29.0201 83.3483 28.8601C83.0312 28.7001 82.7934 28.5401 82.5556 28.3001C82.3971 28.0601 82.2386 27.7401 82.1593 27.4201C82.08 27.1001 82.0008 26.7001 82.0008 26.3001V23.4201H81.0496V21.8201L82.1593 21.7401L82.3971 19.8201H84.1409V21.7401H85.8055V23.4201H84.1409V26.3001C84.1409 26.7001 84.2202 27.0201 84.3787 27.1801C84.5373 27.3401 84.7751 27.4201 85.0129 27.4201C85.0921 27.4201 85.2507 27.4201 85.3299 27.3401C85.4885 27.3401 85.5677 27.2601 85.647 27.2601L85.9641 28.7801C85.8055 28.8601 85.5677 28.8601 85.3299 28.9401C85.0921 29.0201 84.7751 29.1001 84.458 29.1001Z"
                fill="white"
              />
              <path
                d="M87.0737 28.9401V21.8201H89.1347V28.9401H87.0737Z"
                fill="white"
              />
              <path
                d="M92.4639 28.9401L90.0859 21.8201H92.2261L93.1773 25.1801C93.2566 25.5001 93.3358 25.9001 93.4151 26.3001C93.4944 26.7001 93.5736 27.0201 93.7322 27.4201H93.8114C93.8907 27.0201 93.97 26.7001 94.0492 26.3001C94.1285 25.9001 94.2078 25.5801 94.3663 25.1801L95.3175 21.8201H97.2991L95.0004 28.9401H92.4639Z"
                fill="white"
              />
              <path
                d="M101.262 29.0999C100.787 29.0999 100.311 29.0199 99.8355 28.8599C99.3599 28.6999 99.0428 28.4599 98.7258 28.1399C98.4087 27.8199 98.1709 27.4199 97.9331 26.9399C97.7746 26.4599 97.6953 25.9799 97.6953 25.3399C97.6953 24.7799 97.7746 24.2199 98.0124 23.8199C98.1709 23.3399 98.488 22.9399 98.7258 22.6199C99.0428 22.2999 99.3599 22.0599 99.7562 21.8999C100.153 21.7399 100.549 21.6599 101.024 21.6599C101.5 21.6599 101.976 21.7399 102.372 21.8999C102.768 22.0599 103.085 22.2999 103.323 22.6199C103.561 22.9399 103.799 23.2599 103.878 23.7399C104.037 24.1399 104.037 24.6199 104.037 25.0999C104.037 25.2599 104.037 25.4999 104.037 25.6599C104.037 25.8199 104.037 25.9799 103.957 26.0599H99.7562C99.8355 26.5399 100.073 26.9399 100.39 27.1799C100.707 27.4199 101.104 27.4999 101.579 27.4999C102.055 27.4999 102.61 27.3399 103.085 27.0199L103.799 28.2999C103.402 28.5399 103.006 28.7799 102.61 28.8599C102.134 29.0999 101.659 29.0999 101.262 29.0999ZM99.677 24.6199H102.213C102.213 24.2199 102.134 23.8999 101.976 23.6599C101.817 23.4199 101.5 23.2599 101.024 23.2599C100.707 23.2599 100.39 23.3399 100.153 23.5799C99.9148 23.8199 99.7562 24.1399 99.677 24.6199Z"
                fill="white"
              />
              <path
                d="M106.732 29.0999C106.336 29.0999 106.098 28.9399 105.781 28.6999C105.543 28.4599 105.464 28.1399 105.464 27.7399C105.464 27.3399 105.543 27.0199 105.781 26.7799C106.018 26.5399 106.336 26.3799 106.732 26.3799C107.128 26.3799 107.366 26.5399 107.604 26.7799C107.842 27.0199 108 27.3399 108 27.7399C108 28.1399 107.842 28.4599 107.604 28.6999C107.366 29.0199 107.128 29.0999 106.732 29.0999Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2399_80499">
                <rect
                  width="108"
                  height="32"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MonthlyReportHeader;
