import { Popover } from '@headlessui/react';
import { SearchIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import { UserAddIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';
import InputPrepend from './Forms/InputPrepend';

const UsersPopoverMultiple = ({
  users,
  selectedIds,
  onChange,
  position = 'left-1/2 -translate-x-1/2 transform',
  width = 'w-72',
  iconSize = 'md',
  compact = true,
  compactSize = 2,
  allowAdd = true,
  allowRemove = true,
}) => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const iconSizes = {
    sm: 'h-6 w-6 leading-6 text-1xs',
    md: 'h-8 w-8 leading-8 text-xs',
    md1: 'h-9 w-9 leading-8 text-sm',
    lg: 'h-12 w-12 leading-12 text-sm',
    smAdd: 'h-3 w-3',
    mdAdd: 'h-5 w-5',
    md1Add: 'h-6 w-6',
    lgAdd: 'h-9 w-9',
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (selectedIds && users) {
      setSelectedUsers(
        users.filter((user) => selectedIds.includes(user.userId))
      );
    }
  }, [selectedIds, users]);

  const onSearch = (e) => {
    const search = e.target.value.toLowerCase();

    let f = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(search) ||
        user.lastName.toLowerCase().includes(search)
    );
    setFilteredUsers(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const adjust = (i) => {
    if (i > 0) return '-ml-3';
  };

  const onUpdate = (user, action) => {
    onChange(user, action);
  };

  const compactedIcons = (selectedUser, i) => {
    return (
      <div
        key={i}
        className={classNames(
          ' text-white flex text-center font-normal uppercase rounded-3xl border border-gray-100 relative',
          i === compactSize - 1 ? 'bg-red-300' : 'bg-red-500',
          iconSizes[iconSize],
          adjust(i)
        )}
        style={{ zIndex: selectedUsers.length - i }}
      >
        {i === compactSize - 1 ? null : (
          <span
            className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-red-400 ring-1 ring-white cursor-pointer z-50"
            onClick={(e) => {
              e.stopPropagation();
              onUpdate(selectedUser, 'remove');
            }}
          >
            <XIcon className="w-3 h-3 text-white" />
          </span>
        )}
        <span
          className={classNames(iconSizes[iconSize], 'inline-block font-bold')}
          title={`+${
            selectedUsers.length - (compactSize - 1)
          } additional assignee`}
        >
          {i === compactSize - 1
            ? `+${selectedUsers.length - (compactSize - 1)}`
            : getNameInitials(selectedUser.firstName, selectedUser.lastName)}
        </span>
      </div>
    );
  };

  const regularIcons = (selectedUser, i) => {
    return (
      <div
        key={i}
        className={classNames(
          'bg-red-500 flex text-white text-center font-normal uppercase rounded-3xl border border-gray-100 relative',
          iconSizes[iconSize],
          adjust(i)
        )}
        style={{ zIndex: selectedUsers.length - i }}
      >
        <span
          className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-red-400 ring-1 ring-white cursor-pointer z-50"
          onClick={(e) => {
            e.stopPropagation();
            onUpdate(selectedUser, 'remove');
          }}
        >
          <XIcon className="w-3 h-3 text-white" />
        </span>
        <span
          className={classNames(iconSizes[iconSize], 'inline-block font-bold')}
          title={`${selectedUser.firstName} ${selectedUser.lastName}`}
        >
          {getNameInitials(selectedUser.firstName, selectedUser.lastName)}
        </span>
      </div>
    );
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            disabled={!(allowAdd || allowRemove)}
            className={classNames(
              open ? '' : 'text-opacity-90',
              'group relative inline-flex items-center rounded-sm bg-orange-700 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
            )}
          >
            {selectedUsers && (
              <>
                {compact ? (
                  <>
                    {selectedUsers.length > compactSize &&
                      selectedUsers.flatMap((selectedUser, i) =>
                        i < compactSize ? compactedIcons(selectedUser, i) : ''
                      )}
                    {selectedUsers.length <= compactSize &&
                      selectedUsers.map((selectedUser, i) =>
                        regularIcons(selectedUser, i)
                      )}
                  </>
                ) : (
                  selectedUsers.map((selectedUser, i) =>
                    regularIcons(selectedUser, i)
                  )
                )}

                {selectedUsers.length < compactSize && allowAdd && (
                  <div
                    className={classNames(
                      'bg-transparent text-xs border border-dashed border-gray-400 flex items-center justify-center uppercase rounded-3xl relative',
                      selectedUsers.length > 0 ? ' -ml-3' : '',
                      iconSizes[iconSize]
                    )}
                    title="Add assignee"
                  >
                    <UserAddIcon
                      className={classNames(
                        iconSizes[`${iconSize}Add`],
                        'inline text-gray-500 hover:text-red-500'
                      )}
                    />
                  </div>
                )}
              </>
            )}
          </Popover.Button>

          <Popover.Panel
            className={classNames(
              position,
              width,
              'absolute z-20 min-h-full max-w-sm'
            )}
          >
            {({ close }) => (
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative bg-white">
                  <div className="border-b px-4 py-2">
                    <InputPrepend
                      name="search"
                      onChange={(e) => onDebouncedSearch(e)}
                      type="text"
                      placeholder="Search..."
                      prependText={<SearchIcon className="w-4 h-4" />}
                      border="border-none"
                      rounded="rounded-md"
                      classes="text-base"
                      autoFocus
                    />
                  </div>
                  <div className="overflow-y-auto h-80 text-left">
                    {filteredUsers.length > 0 ? (
                      filteredUsers.map((user) => {
                        const selected = selectedIds.includes(user.userId);

                        const editable = selected ? allowRemove : allowAdd;

                        return (
                          <button
                            className={classNames(
                              editable ? '' : 'cursor-not-allowed',
                              'grid grid-cols-6 items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-gray-100 rounded-md focus:ring-gray-300 focus:border-gray-200'
                            )}
                            key={user.userId}
                            value={user.userId}
                            onClick={(e) => {
                              e.stopPropagation();
                              const exist = selected;
                              if (editable) {
                                onUpdate(user, exist ? 'remove' : 'add');
                              }
                            }}
                          >
                            <span className="col-span-1 block bg-red-500 text-white rounded-2xl w-7 h-7 leading-7 text-sm uppercase text-center font-normal">
                              {getNameInitials(user.firstName, user.lastName)}
                            </span>
                            <span className="col-span-4 whitespace-pre-wrap">
                              {user.firstName} {user.lastName}
                            </span>
                            <span className="col-span-1 flex justify-end">
                              <label
                                className={classNames(
                                  selected ? 'bg-black' : 'bg-gray-200',
                                  editable ? '' : 'cursor-not-allowed',
                                  'block rounded-2xl w-6 h-6 leading-6 text-sm uppercase text-center font-normal items-end'
                                )}
                              >
                                <CheckIcon className="w-6 h-6 inline text-white" />
                              </label>
                            </span>
                          </button>
                        );
                      })
                    ) : (
                      <div className="w-full text-xs text-gray-700 py-2 px-3">
                        No users found.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default UsersPopoverMultiple;
