import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { columnClasses, headerClasses } from 'utils/table';
import Pagination from './Table/Pagination';
import SortCaret from './Table/SortCaret';
import Loading from 'components/Loading';
import PrevNextOnlyPagination from './Table/PrevNextOnlyPagination';

const Table = ({
  columns,
  data,
  onTableChange,
  keyField,
  params,
  defaultSorted,
  loading,
  cellEdit,
  cellEditProps,
  select,
  selectType = 'checkbox',
  expandRow,
  selectRow,
  bordered = false,
  noDataDisplay,
  headerWrapperClasses = 'bg-grayscale-200',
  showPagination = true,
  rowClasses,
  wrapperClasses,
  paginationType = 'default',
}) => {
  const getColumns = () => {
    return columns.map((column) => ({
      headerClasses: column.headerClasses ?? headerClasses,
      classes: column.classes ?? columnClasses,
      ...(column.sort && { sortCaret: SortCaret }),
      ...column,
    }));
  };

  const tableProps = {
    remote: true,
    keyField,
    data: data && data?.rows ? data?.rows : [],
    columns: getColumns(),
    defaultSorted: defaultSorted,
    classes: `min-w-full${bordered ? ' divide-y divide-gray-200' : ''}`,
    headerWrapperClasses,
    bodyClasses: `bg-white${bordered ? ' divide-y divide-gray-200' : ''}`,
    rowClasses: rowClasses ?? '',
    wrapperClasses: wrapperClasses
      ? wrapperClasses
      : 'overflow-x-auto min-h-1/2',
    onTableChange,
    loading,
    expandRow,
    selectRow,
  };

  if (cellEdit) {
    tableProps.cellEdit = cellEditFactory(cellEditProps);
  }

  if (select) {
    tableProps.selectRow = {
      mode: selectType,
    };
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2">
        <div className="py-2 align-middle">
          <div className="overflow-hidden bg-white ring-1 ring-white">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                page: params.page,
                sizePerPage: params.pageSize,
                sizePerPageList: [10, 30, 50, 100, 200],
                totalSize: data?.count ?? 0,
                bordered,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <>
                  <BootstrapTable
                    {...tableProps}
                    overlay={overlayFactory({
                      spinner: <Loading />,
                      styles: {
                        overlay: (base) => ({
                          ...base,
                          background: 'rgba(255, 255, 255, 0.5)',
                        }),
                      },
                    })}
                    noDataIndication={() => {
                      return (
                        <div className="my-4 text-sm block text-gray-500 text-center">
                          {noDataDisplay ?? 'No Results found'}
                        </div>
                      );
                    }}
                    {...paginationTableProps}
                  />

                  {showPagination && (
                    <>
                      {paginationType === 'default' && (
                        <Pagination {...paginationProps}></Pagination>
                      )}
                      {paginationType === 'prevnext' && (
                        <PrevNextOnlyPagination
                          paginationProps={paginationProps}
                          handleNextPage={({ page, onPageChange }) =>
                            () => {
                              if (data.has_more_page)
                                onPageChange(parseInt(page) + 1);
                            }}
                          handlePrevPage={({ page, onPageChange }) =>
                            () => {
                              if (page > 1) onPageChange(parseInt(page) - 1);
                            }}
                          handleSizePerPage={(
                            { page, onSizePerPageChange },
                            newSizePerPage
                          ) => {
                            onSizePerPageChange(newSizePerPage, page);
                          }}
                          data={data}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </PaginationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
