import axios from 'axios';
import { Table } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';

const Initial = () => {
  const [data, setData] = useState({ rows: [] });
  const [categories, setCategories] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    marketplaceId: '',
    categoryId: '',
    status: '',
    search: '',
    sort: 'startedAt:desc',
  });

  const columns = [
    {
      dataField: 'account.name',
      text: 'Client',
      classes: 'text-13 text-grayscale-800 py-2 px-4 whitespace-nowrap',
      headerStyle: { minWidth: '180px' },
      sort: true,
    },
    {
      dataField: 'marketplace.country',
      text: 'Marketplace',
      classes: 'text-13 text-grayscale-800 py-2 px-4 whitespace-nowrap',
      sort: true,
    },
    {
      sort: true,
      dataField: 'categoryId',
      text: 'Category',
      classes: 'text-13 text-grayscale-800 py-2 px-4 whitespace-nowrap',
      formatter: (cell, row) => {
        return (
          <div>
            <p>{cell}</p>
            <p className="text-11 text-grayscale-700">
              {row.category.description}
            </p>
          </div>
        );
      },
    },
    {
      sort: true,
      dataField: 'status',
      text: 'Status',
      classes:
        'text-8 text-grayscale-800 py-2 px-4 whitespace-nowrap tracking-3/4',
      formatter: (cell) => {
        return (
          <p
            className={classNames(
              'text-white rounded-2xl px-2 whitespace-nowrap py-1 font-inter',
              cell === 'COMPLETED' ? 'bg-success' : 'bg-primary'
            )}
          >
            {cell}
          </p>
        );
      },
    },
    {
      sort: true,
      dataField: 'startedAt',
      text: 'Started',
      classes: 'text-13 text-grayscale-800 py-2 px-4 whitespace-nowrap',
      formatter: (cell) =>
        cell ? moment(cell).format('MMMM Do YYYY, h:mm:ss a') : '',
    },
    {
      sort: true,
      dataField: 'completedAt',
      text: 'Completed',
      classes: 'text-13 text-grayscale-800 py-2 px-4 whitespace-nowrap',
      formatter: (cell) =>
        cell ? moment(cell).format('MMMM Do YYYY, h:mm:ss a') : '',
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }
    setParams(newParams);
  };

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      let payload = { ...params };

      if (!payload.categoryId) {
        delete payload.categoryId;
      }

      if (!payload.status) {
        delete payload.status;
      }

      if (!payload.marketplaceId) {
        delete payload.marketplaceId;
      }

      const response = await axios.get('/account/data-sync/initial', {
        params: payload,
      });

      if (isSubscribed) {
        let { data } = response.data;
        data.rows = data.rows.map((item, id) => {
          return { ...item, id };
        });
        setData(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const response = await axios.get('/account/data-sync/categories');

      if (isSubscribed) {
        setCategories(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <input
          className="text-13 border-grayscale-500 text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 col-span-4"
          type="search"
          value={params.search}
          onChange={(e) => setParams({ ...params, search: e.target.value })}
        />

        <select
          value={params.marketplaceId}
          onChange={(e) =>
            setParams({ ...params, marketplaceId: e.target.value })
          }
          className="text-13 border-grayscale-500 text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 col-span-2"
        >
          <option value="">All Marketplace</option>
          <option value="ATVPDKIKX0DER">USA</option>
          <option value="A2EUQ1WTGCTBG2">Canada</option>
          <option value="A1AM78C64UM0Y8">Mexico</option>
          <option value="A2Q3Y263D00KWC">Brazil</option>
        </select>

        <select
          value={params.status}
          onChange={(e) => setParams({ ...params, status: e.target.value })}
          className="text-13 border-grayscale-500 text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 col-span-2"
        >
          <option value="">All Status</option>
          <option value="COMPLETED">Completed</option>
          <option value="IN-PROGRESS">In-progress</option>
        </select>

        <select
          value={params.categoryId}
          onChange={(e) => setParams({ ...params, categoryId: e.target.value })}
          className="text-13 border-grayscale-500 text-grayscale-800 focus:outline-none focus:ring-0 focus:border-gray-300 col-span-4"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.id}
            </option>
          ))}
        </select>
      </div>

      <Table
        data={data}
        params={params}
        columns={columns}
        keyField="id"
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default Initial;
