import { Field, ErrorMessage, FieldArray } from 'formik';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { isArray, isString } from 'lodash';

import Button from 'components/Button';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const Research = ({ handleChange, values, errors }) => {
  return (
    <div className="flex flex-col w-full gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div className="">
        <FieldArray name="data.products">
          {({ remove, push }) => (
            <>
              <div className="col-span-2 space-y-3 mb-4">
                {values.data?.products?.length > 0 &&
                  values.data.products.map((asin, index) => (
                    <div key={index} className="sm:grid grid-cols-3 gap-y-3 ">
                      <div className="col-span-3 flex justify-between">
                        <p className="font-bold font-inter text-base text-grayscale-900">
                          ASIN {index + 1}
                        </p>
                        <span
                          onClick={() => {
                            remove(index);
                          }}
                          className="cursor-pointer text-gray-500 hover:text-red-500"
                        >
                          <TrashIcon className="w-6 h-6 " />
                        </span>
                      </div>
                      <div className="col-span-3">
                        <Label
                          classes="text-left leading-1.2"
                          textColor="text-grayscale-800"
                          textSize="13"
                        >
                          {' '}
                          ASIN {index + 1}
                        </Label>

                        <Field
                          name={`data.products.${index}.asin`}
                          placeholder="Enter ASIN"
                          className="form-input disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
                          onChange={(e) => handleChange(e)}
                          type="text"
                        />

                        {isArray(errors?.data?.products) && (
                          <ErrorMessage
                            name={`data.products.${index}.asin`}
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        )}
                      </div>
                      <div className="col-span-3">
                        <Label
                          classes="text-left leading-1.2"
                          textColor="text-grayscale-800"
                          textSize="13"
                        >
                          Link
                        </Label>
                        <Field
                          name={`data.products.${index}.link`}
                          placeholder="Add research link"
                          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
                          type="text"
                          onChange={(e) => handleChange(e)}
                        />

                        {isArray(errors?.data?.products) && (
                          <ErrorMessage
                            name={`data.products.${index}.link`}
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="">
                <Button
                  classes="font-bold tracking-wider w-full justify-center"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="2xl"
                  textColor="gray-600"
                  border="border-2 border-gray-600"
                  py={2}
                  shadow=""
                  onClick={(e) => {
                    push({ asin: '', link: '' });
                  }}
                >
                  <PlusIcon className="w-4 h-4 inline" /> Add Managed Asin
                </Button>
              </div>

              {!!errors?.data?.products && isString(errors.data?.products) && (
                <ErrorMessage
                  name={`data.products`}
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              )}
            </>
          )}
        </FieldArray>
      </div>

      <div>
        <Label
          htmlFor="data.otherInformation"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Other Information <RequiredAsterisk />
        </Label>
        <Field
          name="data.otherInformation"
          placeholder="Add information related to action taken"
          as="textarea"
          rows={3}
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.otherInformation"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
    </div>
  );
};

export default Research;
