import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory, Link } from 'react-router-dom';
import qs from 'qs';

import { ShoppingBagIcon } from '@heroicons/react/outline';

import { authorizeSpApiAsync } from './onboardingSlice';

import {
  selectCurrentAccount,
  updateAccountAsync,
} from '../accounts/accountsSlice';

const SpApiAuthorization = ({
  location,
  userId,
  isAuthorizing,
  accountId,
  setActiveStep,
}) => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectCurrentAccount);

  const onComplete = async () => {
    dispatch(
      updateAccountAsync(currentAccount.accountId, { isOnboarding: false })
    );
  };

  useEffect(() => {
    if (location.search.includes('spapi_oauth_code')) {
      const redirectUrl = window.location.hostname + window.location.pathname;

      dispatch(
        authorizeSpApiAsync(qs.parse(location.search.slice(1)), redirectUrl)
      ).then((response) => {
        if (response) setActiveStep(2);
      });
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [dispatch, location.search]);

  return (
    <>
      <div>
        <h2 className="text-center text-xl font-extrabold text-grayscale-900">
          Connect your NA Region Amazon Account
        </h2>

        <p className="mt-2 text-center text-sm tracking-3/4 text-grayscale-800">
          To get started, we'll fetch the past 18 months of product and sales
          data. We'll also synchronize new data throughout the day from your
          Amazon account (NA Region only).
        </p>
      </div>

      <div className="mt-5 text-center">
        <ShoppingBagIcon className="mx-auto h-12 w-12 text-red-500" />
        <div className="mt-4 text-xs text-grayscale-900 space-y-2 tracking-3/4">
          <p>
            Click the button "Login with Amazon" if your Amazon account is from
            NA Region.
          </p>
          <p>OR</p>
          <p>
            Click the button "Skip" if your account is from any other Region.
          </p>
        </div>

        <div className="mt-6 flex flex-col items-center space-y-4">
          <a
            href={`https://sellercentral.amazon.com/apps/authorize/consent?application_id=${process.env.REACT_APP_SP_API_CLIENT_ID}&state=${accountId}&redirect_uri=${process.env.REACT_APP_CLIENT_SP_API_REDIRECT_URL}&version=beta`}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {isAuthorizing ? 'Authorizing..' : 'Login with Amazon'}
          </a>
          <Link
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={onComplete}
          >
            Skip
          </Link>

          <p className="text-xs font-medium tracking-3/4 text-grayscale-700">
            Please Note: Connecting any other account except NA Region will
            display connection Error. If your account is not from NA Region
            please Skip this page
          </p>
        </div>
      </div>
    </>
  );
};

export default withRouter(SpApiAuthorization);
