import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { reverse } from 'lodash';

export const fetchTaskItems = createAsyncThunk(
  'tasks/taskItems',
  async (params) => {
    let payload = { ...params };
    delete payload.page;
    delete payload.pageSize;

    const response = await axios.get(`/agency/tasks/items`, {
      params: payload,
    });

    return response.data.data;
  }
);

export const fetchEmployees = createAsyncThunk(
  'tasks/employees',
  async (params, thunkAPI) => {
    const response = await axios.get(
      `/agency/employees/department/list?department=${params}`
    );

    return response.data.data;
  }
);

export const fetchUpdates = createAsyncThunk(
  'tasks/updates',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/tasks/updates`, { params });
    return response.data.data;
  }
);

export const fetchOlderUpdates = createAsyncThunk(
  'tasks/olderUpdates',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/tasks/updates`, { params });
    return response.data.data;
  }
);

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    space: { statuses: [] },
    boardView: 'list',
    folder: null,
    list: null,
    items: {},
    taskTray: [],
    employees: [],
    commentEditorDraft: '',
    editCommentEditorDraft: '',
    updates: { rows: [] },
    olderUpdates: { nextPage: 1, rows: [] },
    updatesPaginationParams: {
      page: 1,
      pageSize: 20,
      scopes: [],
      sort: 'createdAt:desc',
    },
    attachments: {},
  },
  reducers: {
    setSpace: (state, action) => {
      state.space = action.payload;
    },
    setFolder: (state, action) => {
      state.folder = action.payload;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setTaskTray: (state, action) => {
      state.taskTray = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setcommentEditorDraft: (state, action) => {
      state.commentEditorDraft = action.payload;
    },
    setEditCommentEditorDraft: (state, action) => {
      state.editCommentEditorDraft = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload;
    },
    setOlderUpdates: (state, action) => {
      state.olderUpdates = action.payload;
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload;
    },
    setBoardView: (state, action) => {
      state.boardView = action.payload;
    },
  },
  extraReducers: {
    [fetchEmployees.fulfilled]: (state, { payload }) => {
      state.employees = payload;
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.updates = { ...payload, rows: reverse(payload.rows) };
    },
    [fetchOlderUpdates.fulfilled]: (state, { payload }) => {
      state.olderUpdates = {
        ...payload,
        rows: [...reverse(payload.rows), ...state.olderUpdates.rows],
      };
    },
    [fetchTaskItems.fulfilled]: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const {
  setSpace,
  setFolder,
  setList,
  setItems,
  setTaskTray,
  setEmployees,
  setAttachments,
  setcommentEditorDraft,
  setEditCommentEditorDraft,
  setUpdates,
  setOlderUpdates,
  setBoardView,
} = tasksSlice.actions;

export const getClientSpace =
  (clientId, params = {}) =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `/agency/tasks/spaces/${clientId}`,
        params,
      });

      dispatch(setSpace(response.data.data));

      return response.data.data;
    } catch (error) {
      console.log(error.message);
    }
  };

export const getItems = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/agency/tasks/items`,
      params,
    });

    dispatch(setItems(response.data.data));

    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAttachments = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/agency/tasks/attachments`,
      params: params.params,
    });
    dispatch(setAttachments(response.data.data));
  } catch (error) {
    console.log(error.message);
  }
};

export const getLatestUpdates = (taskItemId) => async (dispatch) => {
  const params = {
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
  };
  dispatch(fetchUpdates({ ...params, taskItemId }));
};

export const selectClientSpace = (state) => state.tasks.space;
export const selectAttachments = (state) => state.tasks.attachments;
export default tasksSlice.reducer;
