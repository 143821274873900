//import { useFormikContext } from 'formik';
import useBraintreePayment from 'hooks/useBraintreePayment';
import Loading from './Loading';
import Label from './Forms/Label';
import classNames from 'utils/classNames';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { isFunction } from 'lodash';

const PaymentForm = forwardRef(
  (
    {
      submitText = 'Subscribe',
      canCheckout = false,
      onAction = null,
      setError = null,
      buttonClassName = 'bg-custom-yellow hover:bg-yellow-400 tracking-widest text-black rounded-full px-20 py-2 shadow-md disabled:bg-gray-200',
    },
    ref
  ) => {
    const {
      clientError,
      paymentFormError,
      cardNumber,
      cvv,
      expirationDate,
      hostedFieldsInstance,
      deviceData,
      loading,
      showTokenizeError,
    } = useBraintreePayment();

    useImperativeHandle(ref, () => ({
      tokenize() {
        return hostedFieldsInstance.tokenize();
      },
      deviceData,
    }));

    useEffect(() => {
      if (clientError) {
        isFunction(setError) && setError(clientError);
      }

      if (paymentFormError) {
        isFunction(setError) && setError(paymentFormError);
      }
    }, [paymentFormError, clientError]);

    const onSubmit = async () => {
      hostedFieldsInstance
        .tokenize()
        .then((response) => {
          if (onAction) onAction(response);
        })
        .catch((error) => {
          showTokenizeError(error);
          isFunction(setError) && setError(true);
        });
    };

    return (
      <div id="btPaymentForm">
        {loading && (
          <div className="flex items-center text-sm italic mb-4">
            Connecting to Payment Gateway&nbsp;&nbsp;&nbsp;
            <Loading />
          </div>
        )}
        <div
          className={classNames(
            loading ? 'opacity-30' : '',
            'grid grid-cols-2 overflow-hidden gap-4 text-sm'
          )}
        >
          <div className="col-span-2">
            <Label htmlFor="card-number">Card Number</Label>
            <div
              ref={cardNumber}
              id="card-number"
              className="form-input h-12 px-2"
            ></div>
          </div>
          <div className="col-span-1">
            <Label htmlFor="expiration-date">Expiration Date</Label>
            <div
              ref={expirationDate}
              id="expiration-date"
              className="form-input h-12 px-2"
            ></div>
          </div>
          <div className="col-span-1">
            <Label htmlFor="cvv">CVV</Label>
            <div ref={cvv} id="cvv" className="form-input h-12 px-2"></div>
          </div>
        </div>
        {canCheckout && (
          <div className="flex justify-center py-4 px-8 mt-8">
            <button
              className={buttonClassName}
              onClick={onSubmit}
              disabled={!hostedFieldsInstance}
              type="button"
            >
              {submitText}
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default PaymentForm;
