import axios from 'axios';
import { useEffect, useState } from 'react';

import SectionComment from '../components/SectionComment';

import metrics from '../../utils/metrics';

import { Table } from 'components';

const attributes = [
  'totalSales',
  'advertisingSales',
  'advertisingCost',
  'tacos',
  'advertisingAcos',
  'advertisingImpressions',
  'advertisingClicks',
  'organicConversionRate',
  'advertisingConversionRate',
  'totalOrders',
];

const AsinLevelPerformance = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  comment = '',
  onChangeComment,
  editableComment = true,
  showPagination = true,
  exportable = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [products, setProducts] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    sort: 'totalSales:desc',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/account/products', {
        params: {
          accountId,
          marketplace,
          startDate,
          endDate,
          attributes: [...attributes, 'productId', 'asin'],
          ...params,
          ...additionalParams,
        },
      });

      if (isSubscribed) {
        setProducts(response.data.data);
        setLoading(false);
        setInitialLoad(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, params, additionalParams]);

  const onExport = () => {
    setExporting(true);

    axios
      .get('/account/products/export', {
        params: {
          endDate,
          startDate,
          accountId,
          marketplace,
          attributes: ['asin', ...attributes],
          ...params,
          ...additionalParams,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
          encoding: 'UTF-8',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `products.csv`;
        link.click();
      })
      .finally(() => setExporting(false));
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === 'sort' && params.sort === `${sortField}:${sortOrder}`) return;

    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const columns = [
    {
      dataField: 'asin',
      text: 'ASIN',
      sort: true,
      headerClasses:
        'p-3 text-mini font-bold text-white border border-grayscale-600 text-left sticky left-0 z-10 bg-grayscale-800',
      classes:
        'px-4 py-2.5 text-mini text-secondary-light border border-grayscale-500 sticky left-0 z-10',
    },
    ...attributes
      .map((attribute) => metrics.find((m) => m.key === attribute))
      .map((metric) => {
        return {
          dataField: metric.key,
          text: metric.longTitle ?? metric.title,
          sort: true,
          headerClasses:
            'p-3 text-mini font-bold text-white border border-grayscale-600 text-left sticky left-0 z-10 bg-grayscale-800',
          classes:
            'px-4 py-2.5 text-mini text-grayscale-800 border border-grayscale-500 text-right',
          formatter: (value) => metric.rawFormatter(value),
        };
      }),
  ];

  return (
    !initialLoad &&
    !!products.rows.length && (
      <div className="relative">
        <SectionComment
          value={comment}
          onChangeValue={onChangeComment}
          editable={editableComment}
        />

        <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
          <div className="flex items-center justify-between border-b px-8 pb-6">
            <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
              ASIN Level Performance
            </p>
            {exportable && (
              <button
                onClick={onExport}
                disabled={exporting}
                className="border-grayscale-700 border-2 font-bold tracking-2 text-grayscale-700 rounded-3xl text-sm leading-1.5 px-5 py-2"
              >
                Export
              </button>
            )}
          </div>

          <div className="px-8 pb-6 mt-5">
            <Table
              columns={columns}
              data={products}
              onTableChange={onTableChange}
              params={params}
              keyField="productId"
              defaultSorted={[{ dataField: 'totalSales', order: 'desc' }]}
              loading={loading}
              showPagination={showPagination}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default AsinLevelPerformance;
