const useUpsell = () => {
  const STATUS = {
    ALL: 'all',
    DRAFT: 'draft',
    AWAITING_APPROVAL: 'awaiting approval',
    APPROVED: 'approved',
    SENT: 'sent',
    OVERDUE: 'overdue',
    PENDING: 'pending',
    PAID: 'paid',
    REJECTED: 'rejected',
    WAIVED: 'waived',
  };

  const ITEM_CODES = {
    REVIEWS: 'REVIEWS',
  };

  const isItemWalmartListingOptimization = (item) => {
    return item.toLowerCase().trim() === 'walmart listing optimization';
  };

  const isItemPaidReviews = (item) => {
    return item.toLowerCase().trim() === 'paid review program';
  };

  return {
    STATUS,
    ITEM_CODES,
    isItemWalmartListingOptimization,
    isItemPaidReviews,
  };
};

export default useUpsell;
