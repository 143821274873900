import {
  fetchTaxExemptions,
  fetchTaxes,
  fetchPlans,
} from 'features/billing/billingSlice';
import { fetchMarketplaces } from 'features/marketplaces/marketplacesSlice';
import { fetchAddons } from 'features/zoho/zohoSlice';
import { useDispatch, useSelector } from 'react-redux';

const useBilling = () => {
  const { taxes, taxExemptions, plans, plansGrouped } = useSelector(
    (state) => state.billing
  );
  const { addons } = useSelector((state) => state.zoho);
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const dispatch = useDispatch();

  const getPlans = () => {
    dispatch(fetchPlans());
  };

  const getAddons = () => {
    dispatch(fetchAddons());
  };

  const getTaxes = () => {
    dispatch(fetchTaxes());
  };

  const getTaxExemptions = () => {
    dispatch(fetchTaxExemptions());
  };

  const getMarketplaces = () => {
    dispatch(fetchMarketplaces());
  };

  return {
    taxes,
    taxExemptions,
    getTaxes,
    getTaxExemptions,
    getPlans,
    plans,
    plansGrouped,
    getAddons,
    addons,
    getMarketplaces,
    marketplaces,
  };
};

export default useBilling;
