import moment from 'moment-timezone';

const useTaskCategories = () => {
  const priorities = [
    {
      name: 'No Priority',
      bgColor: 'gray',
      params: { scopes: ['noPriority'] },
    },
    {
      name: 'Low',
      bgColor: '#60A5FA',
      params: { filter: { priority: 'low' } },
    },
    {
      name: 'Normal',
      bgColor: '#FBBF24',
      params: { filter: { priority: 'normal' } },
    },
    {
      name: 'High',
      bgColor: '#F87171',
      params: { filter: { priority: 'high' } },
    },
  ];

  const due = [
    {
      name: 'Today',
      bgColor: '#FBBF24',
      params: {
        filter: {
          dueDateAfter: moment()
            .tz(moment.tz.guess())
            .startOf('D')
            .format('YYYY-MM-DDTHH:mm:ssZZ'),
          dueDateBefore: moment()
            .tz(moment.tz.guess())
            .endOf('D')
            .format('YYYY-MM-DDTHH:mm:ssZZ'),
        },
      },
    },
    {
      name: 'Future',
      bgColor: '#60A5FA',
      params: {
        filter: {
          dueDateAfter: moment()
            .tz(moment.tz.guess())
            .endOf('D')
            .format('YYYY-MM-DDTHH:mm:ssZZ'),
        },
      },
    },
    {
      name: 'No Due Date',
      bgColor: 'gray',
      params: { scopes: ['noDueDate'] },
    },
    {
      name: 'Overdue',
      bgColor: '#F87171',
      params: {
        filter: {
          dueDateBefore: moment()
            .tz(moment.tz.guess())
            .startOf('D')
            .format('YYYY-MM-DDTHH:mm:ssZZ'),
        },
      },
    },
  ];

  return { due, priorities };
};

export default useTaskCategories;
