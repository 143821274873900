import React, { useState, useEffect, Fragment } from 'react';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { useParams } from 'react-router-dom';
import { setAlert } from 'features/alerts/alertsSlice';
import { getNameInitials } from 'utils/formatters';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MetricsSalesOverall from './components/MetricsSalesOverall';
import MetricsGroup from './components/MetricsGroup';
import MetricsTeamGroup from './components/MetricsTeamGroup';
import MetricsOverall from './components/MetricsOverall';
import Loading from 'components/Loading';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import axios from 'axios';
import MetricsGraph from './components/MetricsGraph';

const Metrics = () => {
  const { userCan, isAgencySuperUser } = usePermissions();
  const user = useSelector(selectAuthenticatedUser);
  const { salesId, salesName } = useParams();
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const today = new Date();
  const year = today.getFullYear();
  const [selectedStartDate, setSelectedStartDate] = useState(`${year}-01-01`);
  const [selectedEndDate, setSelectedEndDate] = useState(`${year}-01-31`);
  const [perviousStartDate, setPerviousStartDate] = useState(null);
  const [perviousEndDate, setPerviousEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [monthData, setMonthData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [overallData, setOverallData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const getGraphMetrics = async () => {
    setLoading(true);
    await axios.get(`/agency/sales/clients/graph`).then((res) => {
      setGraphData(res.data.data);
      setMonthData(res.data.data);
      setSelected(res.data.data[0]);
      setPerviousStartDate(res.data.data[0].monthDetails.startDate);
      setPerviousEndDate(res.data.data[0].monthDetails.endDate);
      setSelectedStartDate(res.data.data[0].monthDetails.startDate);
      setSelectedEndDate(res.data.data[0].monthDetails.endDate);
    });
    setLoading(false);
  };

  const getOverallMetrics = async () => {
    setOverallLoading(true);
    await axios
      .get(`/agency/sales/clients/overall`, {
        params: {
          startDate: selected.monthDetails?.startDate ?? `${year}-01-01`,
          endDate: selected.monthDetails?.endDate ?? `${year}-01-31`,
          perviousStartDate: selectedStartDate,
          perviousEndDate: selectedEndDate,
        },
      })
      .then((res) => {
        setOverallData(res.data.data);
      });
    setOverallLoading(false);
  };

  const getGroupMetrics = async () => {
    setLoading(true);
    await axios
      .get(`agency/sales/clients/group`, {
        params: {
          startDate: selected.monthDetails?.startDate ?? `${year}-01-01`,
          endDate: selected.monthDetails?.endDate ?? `${year}-01-31`,
          perviousStartDate: selectedStartDate,
          perviousEndDate: selectedEndDate,
        },
      })
      .then((res) => {
        setGroupData(res.data.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (salesId) {
      setUserId(salesId);
    } else {
      setUserId(null);
    }
    async function getData() {
      await getGraphMetrics();
    }

    if (!loading) {
      getData();
    }
  }, [salesId, user]);

  useEffect(() => {
    if (!loading) {
      setPerviousStartDate(selectedStartDate);
      setPerviousEndDate(selectedEndDate);
      setSelectedStartDate(selected.monthDetails?.startDate);
      setSelectedEndDate(selected.monthDetails?.endDate);
    }

    async function getData() {
      await getOverallMetrics();
      await getGroupMetrics();
    }

    if (!groupLoading) {
      getData();
    }
  }, [selected]);

  return (
    <>
      {(userCan('sales.metrics') || isAgencySuperUser()) && (
        <>
          <div className="flex">
            <div className="flex justify-center pr-4 align-middle">
              {userId === null ? (
                <span className="flex flex-col justify-center  bg-blue-500 text-white w-10 h-10 text-xl rounded-3xl uppercase text-center ">
                  {getNameInitials(user.firstName, user.lastName)}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div>
              {userCan('sales.metrics.director') || isAgencySuperUser() ? (
                userId === null ? (
                  <p className="text-4xl font-bold pb-6">
                    Sales Team Performance
                  </p>
                ) : (
                  <p className="text-4xl font-bold pb-6">
                    {salesName}'s Sales Performance
                  </p>
                )
              ) : (
                <p className="text-4xl font-bold pb-6">
                  {user.firstName}'s Sales Performance
                </p>
              )}
            </div>
          </div>
        </>
      )}

      <>
        {loading || overallLoading || groupLoading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <>
            {(userCan('sales.metrics.director') || isAgencySuperUser()) &&
              userId === null && <MetricsGraph graphData={graphData} />}
            <div className="flex flex-row justify-between pt-5 px-6">
              <div className="flex flex-col justify-center">
                <p className="font-bold text-2xl">
                  Sales Representative Overview
                </p>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col justify-center pr-2">
                  <p className="font-normal text-base">Month</p>
                </div>
                <div className=" top-16 w-56">
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">
                          {selected.monthDetails?.name}
                        </span>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {monthData.map((month, monthIdx) => (
                            <Listbox.Option
                              key={monthIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? 'bg-amber-100 text-amber-900'
                                    : 'text-gray-900'
                                }`
                              }
                              value={month}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {month.monthDetails?.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
          </>
        )}
      </>

      {overallLoading ? (
        <div className="flex justify-center">
          <Loading />
        </div>
      ) : (
        <>
          {(userCan('sales.metrics.director') || isAgencySuperUser()) &&
          userId === null ? (
            <MetricsOverall teamSalesData={overallData} />
          ) : (
            <MetricsSalesOverall teamSalesData={groupData} />
          )}
        </>
      )}

      {(userCan('sales.metrics.director') || isAgencySuperUser()) &&
      userId === null ? (
        <MetricsTeamGroup
          teamSalesData={groupData}
          selectedStartDate={selected.monthDetails?.startDate}
          selectedEndDate={selected.monthDetails?.endDate}
        />
      ) : (
        <MetricsGroup teamSalesData={groupData} />
      )}
    </>
  );
};

export { Metrics as SalesMetrics };
