import { isEmpty } from 'lodash';
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';

import useAlerts from 'features/alerts/useAlerts';

export default function AlertPopup() {
  const { popup } = useAlerts();
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setIsOpen(!isEmpty(popup));
  }, [popup]);

  const types = {
    success: {
      icon: CheckCircleIcon,
      color: 'text-success-dark',
    },
    error: {
      icon: ExclamationCircleIcon,
      color: 'text-error-dark',
    },
    info: {
      icon: InformationCircleIcon,
      color: 'text-secondary',
    },
  };

  const Content = ({ Icon, color }) => {
    return (
      <div className={`${color} flex space-x-4 items-center`}>
        <Icon className="h-12 w-12" />

        <p className="text-base font-inter font-bold tracking-3/4 leading-1.2">
          {popup.title}
        </p>
      </div>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  style={{
                    boxShadow: '0px 8px 16px 0px rgba(17, 17, 17, 0.06)',
                  }}
                  className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle transition-all"
                >
                  {!isEmpty(popup) && (
                    <Content
                      Icon={types[popup.type]['icon']}
                      color={types[popup.type]['color']}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
