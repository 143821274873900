import moment from 'moment';

const Header = ({ startDate, endDate, client = '', additionalClass = '' }) => {
  return (
    <div
      className={`bg-primary h-36 w-full flex flex-col items-center ${additionalClass}`}
    >
      <div className="flex justify-between w-full px-8 mt-6">
        <div className="mt-1">
          <div className="flex justify-between items-center">
            <p className="text-primary-light mb-2 leading-normal">
              {moment(startDate).format('MMMM DD, YYYY')} -{' '}
              {moment(endDate).format('MMMM DD, YYYY')}
            </p>
            <p className="text-primary-light mb-2 leading-normal">{client}</p>
          </div>

          <p
            className="font-bold text-white tracking-1 font-inter"
            style={{ fontSize: '49px' }}
          >
            Amazon PPC Performance
          </p>
        </div>
        <div className="flex justify-between items-center mt-1">
          <svg
            width="250"
            height="77"
            viewBox="0 0 250 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2383_77965)">
              <path
                d="M62.6606 11.615C48.3486 -3.205 25.0459 -3.205 10.7339 11.615C-3.57798 26.435 -3.57798 50.565 10.7339 65.385C25.0459 80.205 48.3486 80.205 62.6606 65.385C77.156 50.375 77.156 26.435 62.6606 11.615ZM40.2752 49.235C39.7248 50.565 38.8073 51.895 37.7064 52.845C36.6055 53.795 35.1376 54.745 33.4862 55.315C31.8349 55.885 30 56.265 27.7982 56.265C25.5963 56.265 23.3945 55.885 21.0092 55.125C18.8073 54.365 16.6055 53.035 14.7706 51.325L19.9083 44.865C21.1927 45.815 22.4771 46.765 23.945 47.335C25.4128 47.905 26.6972 48.285 27.9817 48.285C29.2661 48.285 30.367 48.095 30.9174 47.715C31.4679 47.335 31.8349 46.575 31.8349 45.815C31.8349 45.435 31.6514 45.055 31.4679 44.675C31.2844 44.295 30.9174 44.105 30.5505 43.915C30.1835 43.725 29.633 43.345 29.0826 43.155C28.5321 42.965 27.7982 42.585 27.0642 42.395L22.844 40.495C21.9266 40.115 21.0092 39.735 20.2752 39.165C19.3578 38.595 18.6239 37.835 18.0734 37.075C17.5229 36.315 16.9725 35.365 16.6055 34.225C16.2385 33.085 16.055 31.945 16.055 30.805C16.055 29.285 16.422 27.765 16.9725 26.625C17.5229 25.295 18.4404 24.155 19.5413 23.205C20.6422 22.255 21.9266 21.305 23.578 20.735C25.2294 20.165 26.8807 19.785 28.7156 19.785C30.7339 19.785 32.7523 20.165 34.7706 20.925C36.789 21.685 38.6239 22.825 40.2752 24.535L35.6881 30.425C34.5872 29.665 33.4862 28.905 32.3853 28.525C31.2844 28.145 30.1835 27.955 28.8991 27.955C27.7982 27.955 26.8807 28.145 26.3303 28.525C25.7798 28.905 25.4128 29.475 25.4128 30.425C25.4128 31.185 25.7798 31.945 26.6972 32.325C27.6147 32.705 28.8991 33.275 30.367 33.845L34.5872 35.555C36.789 36.505 38.4404 37.645 39.5413 39.165C40.6422 40.685 41.3761 42.775 41.3761 45.245C41.1927 46.385 40.8257 47.905 40.2752 49.235ZM54.4037 55.695H45.2294V20.165H54.4037V55.695Z"
                fill="white"
              />
              <path
                d="M94.4038 34.225C93.3029 34.225 92.202 34.035 91.1011 33.6551C90.0001 33.275 89.0827 32.705 88.1653 31.945L90.3671 28.715C91.1011 29.285 91.835 29.665 92.569 30.045C93.3029 30.425 94.0368 30.425 94.5873 30.425C95.3212 30.425 95.8717 30.235 96.2387 30.045C96.6056 29.855 96.7891 29.475 96.7891 28.905C96.7891 28.715 96.6056 28.335 96.4222 28.145C96.2387 27.955 96.0552 27.765 95.6882 27.575C95.3212 27.385 94.9543 27.195 94.5873 27.005C94.2203 26.815 93.8534 26.625 93.3029 26.435C92.7524 26.245 92.202 26.055 91.835 25.675C91.2845 25.485 90.9176 25.105 90.3671 24.725C90.0001 24.345 89.6332 23.775 89.2662 23.395C89.0827 22.825 88.8992 22.255 88.8992 21.495C88.8992 20.735 89.0827 19.975 89.2662 19.405C89.6332 18.835 90.0001 18.265 90.5506 17.695C91.1011 17.315 91.835 16.935 92.569 16.555C93.3029 16.365 94.2203 16.175 95.1378 16.175C96.4222 16.175 97.5231 16.365 98.4405 16.935C99.3579 17.315 100.275 17.885 101.009 18.455L98.8075 21.495C98.257 21.115 97.7066 20.735 96.9726 20.355C96.4222 20.165 95.8717 19.975 95.3212 19.975C94.0368 19.975 93.4864 20.355 93.4864 21.305C93.4864 21.495 93.4864 21.875 93.6699 22.065C93.8534 22.255 94.0368 22.445 94.4038 22.635C94.7708 22.825 95.1378 23.015 95.5047 23.205C95.8717 23.395 96.2387 23.585 96.7891 23.585C97.3396 23.775 97.89 23.965 98.257 24.345C98.8075 24.535 99.1744 24.915 99.7249 25.295C100.092 25.675 100.459 26.245 100.826 26.815C101.009 27.385 101.193 27.955 101.193 28.715C101.193 29.475 101.009 30.235 100.826 30.805C100.459 31.375 100.092 32.135 99.5414 32.515C98.991 33.085 98.257 33.465 97.3396 33.6551C96.4222 34.225 95.5047 34.225 94.4038 34.225Z"
                fill="white"
              />
              <path
                d="M111.284 34.2251C110.184 34.2251 109.083 34.0351 107.982 33.6551C106.881 33.2751 106.147 32.7051 105.413 31.9451C104.679 31.1851 104.129 30.2351 103.578 29.0951C103.211 27.9551 103.028 26.8151 103.028 25.2951C103.028 23.9651 103.211 22.6351 103.762 21.6851C104.129 20.5451 104.862 19.5951 105.413 18.8351C106.147 18.0751 106.881 17.5051 107.798 17.1251C108.716 16.7451 109.633 16.5551 110.734 16.5551C111.835 16.5551 112.936 16.7451 113.853 17.1251C114.771 17.5051 115.505 18.0751 116.055 18.8351C116.606 19.5951 117.156 20.3551 117.34 21.4951C117.706 22.4451 117.706 23.5851 117.706 24.7251C117.706 25.1051 117.706 25.6751 117.706 26.0551C117.706 26.4351 117.706 26.8151 117.523 27.0051H107.798C107.982 28.1451 108.532 29.0951 109.266 29.6651C110 30.2351 110.917 30.4251 112.018 30.4251C113.119 30.4251 114.404 30.0451 115.505 29.2851L117.156 32.3251C116.239 32.8951 115.321 33.4651 114.404 33.6551C113.303 34.2251 112.202 34.2251 111.284 34.2251ZM107.615 23.5851H113.486C113.486 22.6351 113.303 21.8751 112.936 21.3051C112.569 20.7351 111.835 20.3551 110.734 20.3551C110 20.3551 109.266 20.5451 108.716 21.1151C108.165 21.6851 107.798 22.4451 107.615 23.5851Z"
                fill="white"
              />
              <path
                d="M125.229 34.225C124.312 34.225 123.761 34.035 123.027 33.845C122.477 33.655 121.926 33.275 121.56 32.705C121.193 32.135 121.009 31.565 120.826 30.995C120.642 30.235 120.642 29.475 120.642 28.715V10.095H125.413V29.095C125.413 29.665 125.413 30.045 125.596 30.235C125.78 30.425 125.963 30.615 126.147 30.615C126.33 30.615 126.33 30.615 126.33 30.615C126.33 30.615 126.514 30.615 126.697 30.615L127.248 34.415C127.064 34.605 126.697 34.605 126.147 34.605C126.147 34.225 125.78 34.225 125.229 34.225Z"
                fill="white"
              />
              <path
                d="M134.22 34.225C133.303 34.225 132.752 34.035 132.018 33.845C131.468 33.655 130.917 33.275 130.55 32.705C130.184 32.135 130 31.565 129.817 30.995C129.633 30.235 129.633 29.475 129.633 28.715V10.095H134.404V29.095C134.404 29.665 134.404 30.045 134.587 30.235C134.771 30.425 134.954 30.615 135.138 30.615C135.321 30.615 135.321 30.615 135.321 30.615C135.321 30.615 135.505 30.615 135.688 30.615L136.239 34.415C136.055 34.605 135.688 34.605 135.138 34.605C135.321 34.225 134.771 34.225 134.22 34.225Z"
                fill="white"
              />
              <path
                d="M146.33 34.2251C145.229 34.2251 144.129 34.0351 143.028 33.6551C141.927 33.2751 141.193 32.7051 140.459 31.9451C139.725 31.1851 139.174 30.2351 138.624 29.0951C138.073 27.9551 138.073 26.8151 138.073 25.2951C138.073 23.9651 138.257 22.6351 138.807 21.6851C139.174 20.5451 139.908 19.5951 140.459 18.8351C141.193 18.0751 141.927 17.5051 142.844 17.1251C143.762 16.7451 144.679 16.5551 145.78 16.5551C146.881 16.5551 147.982 16.7451 148.899 17.1251C149.817 17.5051 150.551 18.0751 151.101 18.8351C151.651 19.5951 152.202 20.3551 152.385 21.4951C152.752 22.4451 152.752 23.5851 152.752 24.7251C152.752 25.1051 152.752 25.6751 152.752 26.0551C152.752 26.4351 152.752 26.8151 152.569 27.0051H142.844C143.028 28.1451 143.578 29.0951 144.312 29.6651C145.046 30.2351 145.963 30.4251 147.064 30.4251C148.165 30.4251 149.45 30.0451 150.551 29.2851L152.202 32.3251C151.284 32.8951 150.367 33.4651 149.45 33.6551C148.349 34.2251 147.248 34.2251 146.33 34.2251ZM142.661 23.5851H148.532C148.532 22.6351 148.349 21.8751 147.982 21.3051C147.615 20.7351 146.881 20.3551 145.78 20.3551C145.046 20.3551 144.312 20.5451 143.762 21.1151C143.211 21.6851 142.844 22.4451 142.661 23.5851Z"
                fill="white"
              />
              <path
                d="M155.688 33.845V16.935H159.725L160.092 19.975H160.275C160.826 18.835 161.56 17.885 162.477 17.505C163.394 16.935 164.128 16.745 165.046 16.745C165.596 16.745 165.963 16.745 166.147 16.935C166.514 16.935 166.697 17.125 166.881 17.125L166.147 21.495C165.78 21.495 165.596 21.305 165.229 21.305C165.046 21.305 164.679 21.305 164.312 21.305C163.761 21.305 163.027 21.495 162.293 22.065C161.56 22.445 161.009 23.395 160.642 24.535V34.415H155.688V33.845Z"
                fill="white"
              />
              <path
                d="M89.6331 68.045V51.135H94.4037V68.045H89.6331Z"
                fill="white"
              />
              <path
                d="M98.4404 68.045V51.135H102.477L102.844 53.225C103.578 52.465 104.312 51.895 105.229 51.515C106.147 50.945 107.064 50.755 108.165 50.755C110 50.755 111.284 51.325 112.018 52.655C112.752 53.795 113.303 55.505 113.303 57.595V68.045H108.349V58.165C108.349 57.025 108.165 56.075 107.798 55.695C107.431 55.315 107.064 54.935 106.33 54.935C105.78 54.935 105.229 55.125 104.679 55.315C104.312 55.505 103.762 56.075 103.211 56.455V67.855H98.4404V68.045Z"
                fill="white"
              />
              <path
                d="M123.211 68.425C122.11 68.425 121.376 68.235 120.642 67.855C119.908 67.475 119.358 67.095 118.807 66.525C118.44 65.955 118.073 65.195 117.89 64.435C117.706 63.675 117.523 62.725 117.523 61.775V54.935H115.321V51.135L117.89 50.945L118.44 46.385H122.477V50.945H126.33V54.935H122.477V61.775C122.477 62.725 122.661 63.485 123.027 63.865C123.394 64.245 123.945 64.435 124.495 64.435C124.679 64.435 125.046 64.435 125.229 64.245C125.596 64.245 125.78 64.055 125.963 64.055L126.697 67.665C126.33 67.855 125.78 67.855 125.229 68.045C124.862 68.425 124.128 68.425 123.211 68.425Z"
                fill="white"
              />
              <path
                d="M136.055 68.425C134.954 68.425 133.853 68.235 132.752 67.855C131.651 67.475 130.917 66.905 130.183 66.145C129.449 65.385 128.899 64.435 128.349 63.295C127.982 62.155 127.798 61.015 127.798 59.495C127.798 58.165 127.982 56.835 128.532 55.885C128.899 54.745 129.633 53.795 130.183 53.035C130.917 52.275 131.651 51.705 132.569 51.325C133.486 50.945 134.404 50.755 135.505 50.755C136.605 50.755 137.706 50.945 138.624 51.325C139.541 51.705 140.275 52.275 140.826 53.035C141.376 53.795 141.927 54.555 142.11 55.695C142.477 56.645 142.477 57.785 142.477 58.925C142.477 59.305 142.477 59.875 142.477 60.255C142.477 60.635 142.477 61.015 142.293 61.205H132.569C132.752 62.345 133.303 63.295 134.037 63.865C134.771 64.435 135.688 64.625 136.789 64.625C137.89 64.625 139.174 64.245 140.275 63.485L141.927 66.525C141.009 67.095 140.092 67.665 139.174 67.855C138.073 68.425 136.972 68.425 136.055 68.425ZM132.385 57.785H138.257C138.257 56.835 138.073 56.075 137.706 55.505C137.339 54.935 136.605 54.555 135.505 54.555C134.771 54.555 134.037 54.745 133.486 55.315C132.936 55.885 132.569 56.645 132.385 57.785Z"
                fill="white"
              />
              <path
                d="M145.413 68.0449V51.1349H149.45L149.817 54.1749H150C150.55 53.0349 151.284 52.0849 152.202 51.7049C153.119 51.1349 153.853 50.9449 154.771 50.9449C155.321 50.9449 155.688 50.9449 155.872 51.1349C156.239 51.1349 156.422 51.3249 156.605 51.3249L155.872 55.6949C155.505 55.6949 155.321 55.5049 154.954 55.5049C154.771 55.5049 154.404 55.5049 154.037 55.5049C153.486 55.5049 152.752 55.6949 152.018 56.2649C151.284 56.6449 150.734 57.5949 150.367 58.7349V68.6149H145.413V68.0449Z"
                fill="white"
              />
              <path
                d="M161.56 68.4249C160.826 68.4249 160.092 68.2349 159.541 68.0449C158.991 67.8549 158.44 67.4749 158.073 66.9049C157.707 66.3349 157.34 65.9549 157.156 65.1949C156.973 64.6249 156.789 63.8649 156.789 63.1049C156.789 61.3949 157.523 59.8749 158.991 58.9249C160.459 57.9749 162.844 57.2149 165.963 56.8349C165.963 56.0749 165.78 55.5049 165.229 55.1249C164.862 54.7449 164.129 54.3649 163.395 54.3649C162.661 54.3649 161.927 54.5549 161.193 54.7449C160.459 54.9349 159.725 55.3149 158.991 55.8849L157.156 52.6549C158.257 52.0849 159.358 51.5149 160.459 51.1349C161.56 50.7549 162.844 50.5649 164.129 50.5649C166.33 50.5649 167.798 51.1349 168.899 52.4649C170 53.6049 170.551 55.6949 170.551 58.1649V67.8549H166.514L166.147 66.1449H165.963C165.229 66.7149 164.495 67.2849 163.762 67.6649C163.211 68.2349 162.477 68.4249 161.56 68.4249ZM163.211 64.6249C163.762 64.6249 164.312 64.4349 164.679 64.2449C165.046 64.0549 165.413 63.6749 165.963 63.1049V60.0649C164.312 60.2549 163.028 60.6349 162.477 61.2049C161.927 61.7749 161.56 62.3449 161.56 62.9149C161.56 63.4849 161.743 63.8649 162.11 64.0549C162.11 64.4349 162.661 64.6249 163.211 64.6249Z"
                fill="white"
              />
              <path
                d="M181.56 68.4249C180.459 68.4249 179.358 68.2349 178.44 67.8549C177.523 67.4749 176.605 66.9049 175.872 66.1449C175.138 65.3849 174.587 64.4349 174.22 63.2949C173.853 62.1549 173.67 60.8249 173.67 59.4949C173.67 58.1649 173.853 56.8349 174.404 55.6949C174.954 54.5549 175.505 53.6049 176.238 52.8449C176.972 52.0849 177.89 51.5149 178.991 51.1349C180.092 50.7549 181.193 50.5649 182.294 50.5649C183.394 50.5649 184.128 50.7549 185.046 51.1349C185.78 51.5149 186.514 51.8949 187.248 52.4649L185.046 55.6949C184.312 55.1249 183.578 54.7449 182.844 54.7449C181.743 54.7449 180.642 55.1249 180.092 56.0749C179.358 57.0249 178.991 58.1649 178.991 59.6849C178.991 61.2049 179.358 62.3449 180.092 63.2949C180.826 64.2449 181.743 64.6249 182.66 64.6249C183.211 64.6249 183.761 64.4349 184.128 64.2449C184.679 64.0549 185.046 63.6749 185.596 63.2949L187.431 66.5249C186.697 67.2849 185.78 67.8549 184.862 68.0449C183.394 68.4249 182.477 68.4249 181.56 68.4249Z"
                fill="white"
              />
              <path
                d="M195.505 68.425C194.404 68.425 193.67 68.235 192.936 67.855C192.202 67.475 191.651 67.095 191.101 66.525C190.734 65.955 190.367 65.195 190.184 64.435C190 63.675 189.817 62.725 189.817 61.775V54.935H187.615V51.135L190.184 50.945L190.734 46.385H194.771V50.945H198.624V54.935H194.771V61.775C194.771 62.725 194.954 63.485 195.321 63.865C195.688 64.245 196.239 64.435 196.789 64.435C196.973 64.435 197.34 64.435 197.523 64.245C197.89 64.245 198.073 64.055 198.257 64.055L198.991 67.665C198.624 67.855 198.073 67.855 197.523 68.045C196.973 68.235 196.239 68.425 195.505 68.425Z"
                fill="white"
              />
              <path
                d="M201.56 68.045V51.135H206.33V68.045H201.56Z"
                fill="white"
              />
              <path
                d="M214.037 68.045L208.532 51.135H213.486L215.688 59.115C215.872 59.875 216.055 60.825 216.239 61.775C216.422 62.725 216.606 63.485 216.973 64.435H217.156C217.34 63.485 217.523 62.725 217.707 61.775C217.89 60.825 218.074 60.065 218.44 59.115L220.642 51.135H225.229L219.908 68.045H214.037Z"
                fill="white"
              />
              <path
                d="M234.404 68.425C233.303 68.425 232.202 68.235 231.101 67.855C230 67.475 229.266 66.905 228.532 66.145C227.798 65.385 227.248 64.435 226.697 63.295C226.33 62.155 226.147 61.015 226.147 59.495C226.147 58.165 226.33 56.835 226.881 55.885C227.248 54.745 227.982 53.795 228.532 53.035C229.266 52.275 230 51.705 230.917 51.325C231.835 50.945 232.752 50.755 233.853 50.755C234.954 50.755 236.055 50.945 236.972 51.325C237.89 51.705 238.624 52.275 239.174 53.035C239.725 53.795 240.275 54.555 240.459 55.695C240.826 56.645 240.826 57.785 240.826 58.925C240.826 59.305 240.826 59.875 240.826 60.255C240.826 60.635 240.826 61.015 240.642 61.205H230.917C231.101 62.345 231.651 63.295 232.385 63.865C233.119 64.435 234.037 64.625 235.138 64.625C236.238 64.625 237.523 64.245 238.624 63.485L240.275 66.525C239.358 67.095 238.44 67.665 237.523 67.855C236.422 68.425 235.321 68.425 234.404 68.425ZM230.734 57.785H236.605C236.605 56.835 236.422 56.075 236.055 55.505C235.688 54.935 234.954 54.555 233.853 54.555C233.119 54.555 232.385 54.745 231.835 55.315C231.284 55.885 230.917 56.645 230.734 57.785Z"
                fill="white"
              />
              <path
                d="M247.064 68.425C246.147 68.425 245.596 68.045 244.862 67.475C244.312 66.905 244.128 66.145 244.128 65.195C244.128 64.245 244.312 63.485 244.862 62.915C245.413 62.345 246.147 61.965 247.064 61.965C247.982 61.965 248.532 62.345 249.083 62.915C249.633 63.485 250 64.245 250 65.195C250 66.145 249.633 66.905 249.083 67.475C248.532 68.235 247.982 68.425 247.064 68.425Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2383_77965">
                <rect
                  width="250"
                  height="76"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Header;
