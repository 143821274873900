import { startCase, upperFirst } from 'lodash';

const ExpandableRow = ({ row }) => {
  const biddingStrategies = [
    { name: '	Dynamic bids - down only ', value: 'legacyForSales' },
    { name: 'Dynamic bids - up and down ', value: 'autoForSales' },
    { name: 'Fixed bid ', value: 'manual' },
  ];

  const sdBiddingStrategies = [
    { name: 'Optimize For Each', value: 'optimizeForEach' },
    { name: 'Optimize For Page Visit', value: 'optimizeForPageVisit' },
    { name: 'Optimize For Conversion ', value: 'optimizeForConversion' },
  ];

  const DefaultSubComponent = () => {
    return (
      <div className="bg-grayscale-400 w-full flex">
        <div className="w-1/12"></div>

        <div className="w-11/12 ml-5 px-4 py-8">
          <p className="text-11 text-grayscale-600 leading-1.5">
            Other Information
          </p>
          <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
            {row.data.otherInformation}
          </p>
        </div>
      </div>
    );
  };

  const SubComponent = ({ Component }) => {
    return Component ? <Component /> : <DefaultSubComponent />;
  };

  const ActionInfo = {
    CLIENT_CALL: () => {
      return (
        <div className="bg-grayscale-400 w-full flex">
          <div className="w-1/12"></div>

          <div className="w-2/12 ml-5 px-4 py-8">
            <p className="text-11 text-grayscale-600 leading-1.5">
              Call Agenda
            </p>
            <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
              {row.data.callAgenda}
            </p>
          </div>
          <div className="w-9/12 ml-5 px-4 py-8">
            <p className="text-11 text-grayscale-600 leading-1.5">
              Meeting Notes
            </p>
            <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
              {row.data.meetingNotes}
            </p>
          </div>
        </div>
      );
    },
    OPTIMIZATION: () => {
      return (
        <div className="bg-grayscale-400 w-full flex">
          <div className="w-1/12"></div>

          <div className="w-2/12 ml-5 px-4 py-8">
            <p className="text-11 text-grayscale-600 leading-1.5">Rules</p>
            <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
              {row.data.rules[0].name}
            </p>
          </div>
          <div className="w-9/12 ml-5 px-4 py-8">
            <p className="text-11 text-grayscale-600 leading-1.5">
              Other Information
            </p>
            <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
              {row.data.otherInformation}
            </p>
          </div>
        </div>
      );
    },
    CAMPAIGN_CREATION: () => {
      switch (row.data.campaignType) {
        case 'sponsoredProducts':
          return (
            <div className="bg-grayscale-400 w-full flex">
              <div className="w-1/12"></div>

              {row.data.adGroupTargeting && (
                <div className="w-2/12 ml-5 px-4 py-8">
                  <p className="text-11 text-grayscale-600 leading-1.5">
                    Targeting Type
                  </p>
                  <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                    {upperFirst(row.data.adGroupTargeting)} Targeting
                  </p>
                </div>
              )}

              <div className="w-2/12 ml-5 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Negative Keyword Targeting
                </p>
                {row.data.negativeKeywords &&
                  row.data.negativeKeywords.split(',').map((neg, i) => (
                    <p
                      key={`${row.advLogId}-CAMPAIGN_CREATION-ngkeywords-${i}`}
                      className="leading-1.5 text-13 text-grayscale-800 mt-3"
                    >
                      {neg}
                    </p>
                  ))}
              </div>

              {row.data.strategy && (
                <div className="w-7/12 px-4 py-8">
                  <p className="text-11 text-grayscale-600 leading-1.5">
                    Campaign Bidding Strategy
                  </p>
                  <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                    {
                      biddingStrategies.find(
                        (bs) => bs.value === row.data.strategy
                      )?.name
                    }
                  </p>
                </div>
              )}
            </div>
          );
        case 'sponsoredBrands':
          return (
            <div className="bg-grayscale-400 w-full flex">
              <div className="w-1/12"></div>

              <div className="w-2/12 ml-5 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Ad Group name
                </p>
                <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                  {row.data.adGroupName}
                </p>
              </div>

              <div className="w-2/12 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Ad Format
                </p>
                <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                  {startCase(row.data.adFormat)}
                </p>
              </div>

              <div className="w-2/12 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Negative Keyword Targeting
                </p>
                {row.data.negativeKeywords &&
                  row.data.negativeKeywords.split(',').map((neg, i) => (
                    <p
                      key={`${row.advLogId}-CAMPAIGN_CREATION-ngkeywords-${i}`}
                      className="leading-1.5 text-13 text-grayscale-800 mt-3"
                    >
                      {neg}
                    </p>
                  ))}
              </div>

              {row.data.strategy && (
                <div className="w-5/12 px-4 py-8">
                  <p className="text-11 text-grayscale-600 leading-1.5">
                    Campaign Bidding Strategy
                  </p>
                  <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                    {
                      biddingStrategies.find(
                        (bs) => bs.value === row.data.strategy
                      )?.name
                    }
                  </p>
                </div>
              )}
            </div>
          );
        case 'sponsoredDisplay':
          return (
            <div className="bg-grayscale-400 w-full flex">
              <div className="w-1/12"></div>

              <div className="w-2/12 ml-5 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Campaign Bidding Strategy
                </p>
                <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                  {
                    sdBiddingStrategies.find(
                      (bs) => bs.value === row.data.strategy
                    )?.name
                  }
                </p>
              </div>

              <div className="w-2/12 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Ad Format
                </p>
                <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                  {startCase(row.data.adGroupCreativeType)}
                </p>
              </div>

              <div className="w-7/12 px-4 py-8">
                <p className="text-11 text-grayscale-600 leading-1.5">
                  Ad Group name
                </p>
                <p className="leading-1.5 text-13 text-grayscale-800 mt-3">
                  {row.data.adGroupName}
                </p>
              </div>
            </div>
          );
        default:
          return row.data.campaignType;
      }
    },
  };

  return <SubComponent Component={ActionInfo[row.action]} />;
};

export default ExpandableRow;
