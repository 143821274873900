import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import { fetchPlans, setPlans } from './billingSlice';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import { currencyFormatter } from 'utils/formatters';
import usePermissions from 'hooks/usePermissions';

const Plans = () => {
  const { userCan } = usePermissions();
  const { plans } = useSelector((state) => state.billing);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { alertSuccess, alertError } = useAlert();

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans());
    }
  }, [plans]);

  const onSync = async () => {
    setLoading(true);
    await axios
      .post(`/billing/plans/import`)
      .then((response) => {
        alertSuccess('sync successful', response.data.message);
        dispatch(setPlans(response.data.data));
      })
      .catch((error) => {
        alertError('sync failed', error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-4">
        <h1 className="text-3xl tracking-wide font-bold">Plans</h1>
        {userCan('billing.plans.sync') && (
          <button
            type="button"
            className="flex items-center px-4 tracking-widest rounded-3xl py-1.5 border-2 font-bold border-gray-600 text-sm text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            onClick={onSync}
            disabled={loading}
          >
            <CloudDownloadIcon className="h-4 w-4 mr-2" /> Sync with Zoho{' '}
            {loading && <SpinnerGrow />}
          </button>
        )}
      </div>
      <div className="mt-6 flex flex-col">
        {plans &&
          plans.map((plan) => (
            <div
              className="flex justify-between items-start border-b border-gray-200 pt-4 pb-2 max-w-xl"
              key={plan.planCode}
            >
              <div className="w-3/4">
                <h4 className="font-semibold font-inter">{plan.name}</h4>
                <sup className="text-gray-400">{plan.planCode}</sup>
                <p className="text-sm">{plan.description}</p>
                {/* <div className="text-sm space-x-2 mt-2">
                  <a>Edit</a>
                  &nbsp;&nbsp;&bull;
                  <a>Mark as Inactive</a>
                </div> */}
              </div>
              <div className="flex flex-col text-right">
                <span className="text-lg font-medium">
                  {currencyFormatter(plan.recurringPrice)}
                </span>
                <span className="text-sm text-gray-500">
                  {plan.interval} x {plan.intervalUnit}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Plans;
