import classNames from 'utils/classNames';

const ComplaintStatus = ({ status }) => {
  return (
    <span
      className={classNames(
        'px-4 py-1.5 rounded-40 text-13 leading-1.5',
        {
          None: 'bg-grayscale-500 text-grayscale-700',
          Ongoing: 'bg-warning text-warning-dark',
          Resolved: 'bg-#CFFFDD text-success-darkk',
        }[status]
      )}
    >
      {status}
    </span>
  );
};

export default ComplaintStatus;
