import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { reverse } from 'lodash';

export const fetchClient = createAsyncThunk(
  'clients/getClient',
  async (id, thunkAPI) => {
    const response = await axios.get(`/agency/clients/${id}`);
    return response.data.data.data;
  }
);

export const fetchAssignees = createAsyncThunk(
  'clients/assignees',
  async (agencyClientId, thunkAPI) => {
    const response = await axios.get(
      `/agency/clients/assignees/${agencyClientId}`
    );
    return response.data.data;
  }
);

export const fetchUpdates = createAsyncThunk(
  'clients/updates',
  async ({ params, agencyClientId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/clients/${agencyClientId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const fetchClientsEmployees = createAsyncThunk(
  'clients/employees',
  async (params, thunkAPI) => {
    const response = await axios.get(
      `/agency/employees/department/list?department=${params}`
    );
    return response.data.data;
  }
);

export const fetchUpdate = createAsyncThunk(
  'clients/update',
  async (agencyClientUpdateId, thunkAPI) => {
    const response = await axios.get(
      `/agency/clients/updates/${agencyClientUpdateId}`
    );
    return response.data.data;
  }
);

export const fetchOlderUpdates = createAsyncThunk(
  'clients/olderUpdates',
  async ({ params, agencyClientId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/clients/${agencyClientId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const getAttachments = createAsyncThunk(
  'clients/attachments',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/attachments`, {
      params: params.params,
    });
    return response.data.data;
  }
);

export const getOnboardingDetails = createAsyncThunk(
  'clients/onboarding',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/clients/onboarding/${params}`, {
      params: params.params,
    });
    return response.data.data;
  }
);

export const getLatestUpdates = (agencyClientId) => async (dispatch) => {
  const params = {
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
  };
  dispatch(fetchUpdates({ params, agencyClientId }));
};

export const agencyClientSlice = createSlice({
  name: 'agencyClient',
  initialState: {
    loading: false,
    agencyClient: null,
    showUpdatesSection: true,
    updates: { rows: [] },
    olderUpdates: { nextPage: 1, rows: [] },
    attachments: {},
    employees: [],
    onboarding: null,
    assignees: null,
    paginationParams: {
      page: 1,
      pageSize: 20,
      search: '',
      sort: 'createdAt:asc',
      status: 'booked',
    },
    updatesPaginationParams: {
      page: 1,
      pageSize: 20,
      scopes: [],
      sort: 'createdAt:desc',
    },
    billing: {
      invoices: {
        defaultPaginationParams: {
          page: 1,
          sizePerPage: 10,
          zohoId: null,
          status: 'All',
        },
      },
      upsells: {
        defaultPaginationParams: {
          page: 1,
          pageSize: 10,
          sort: 'createdAt:desc',
          status: 'all',
          client: null,
        },
        paginationParams: {
          page: 1,
          pageSize: 10,
          sort: 'createdAt:desc',
          status: 'all',
          client: null,
        },
      },
      creditNotes: {
        defaultPaginationParams: {
          page: 1,
          pageSize: 10,
          sort: 'createdAt:desc',
          status: 'all',
          fields: 'all',
          agencyClientId: null,
        },
        paginationParams: {
          page: 1,
          pageSize: 10,
          sort: 'createdAt:desc',
          status: 'all',
          fields: 'all',
          agencyClientId: null,
        },
      },
    },
  },
  reducers: {
    setPaginationParams: (state, action) => {
      state.paginationParams = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAgencyClient: (state, action) => {
      state.agencyClient = action.payload;
    },
    setAssignees: (state, action) => {
      state.assignees = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload;
    },
    setOlderUpdates: (state, action) => {
      state.olderUpdates = action.payload;
    },
    setOnboardingDetails: (state, action) => {
      state.onboarding = action.payload;
    },
    setShowUpdatesSection: (state, action) => {
      state.showUpdatesSection = action.payload;
    },
    setUpsellPaginationParams: (state, action) => {
      const diffClient = state.billing.upsells.paginationParams.client
        ? action.payload.client !==
          state.billing.upsells.paginationParams.client
        : false;

      state.billing.upsells.paginationParams = diffClient
        ? state.billing.upsells.defaultPaginationParams
        : action.payload;
    },
    setCreditNotesPaginationParams: (state, action) => {
      const diffClient = state.billing.creditNotes.paginationParams.client
        ? action.payload.client !==
          state.billing.creditNotes.paginationParams.client
        : false;

      state.billing.creditNotes.paginationParams = diffClient
        ? state.billing.creditNotes.defaultPaginationParams
        : action.payload;
    },
    setInvoicesPaginationParams: (state, action) => {
      const diffClient = state.billing.invoices.paginationParams.zohoId
        ? action.payload.zohoId !==
          state.billing.invoices.paginationParams.zohoId
        : false;

      state.billing.invoices.paginationParams = diffClient
        ? state.billing.invoices.defaultPaginationParams
        : action.payload;
    },
  },
  extraReducers: {
    [fetchClient.pending]: (state, { payload }) => {
      state.agencyClient = null;
      state.clientLoaded = false;
    },
    [fetchClient.fulfilled]: (state, { payload }) => {
      state.agencyClient = payload;
      state.clientLoaded = true;
    },
    [fetchAssignees.fulfilled]: (state, { payload }) => {
      state.assignees = payload;
    },
    [fetchClientsEmployees.fulfilled]: (state, { payload }) => {
      state.employees = payload;
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.updates = { ...payload, rows: reverse(payload.rows) };
    },
    [fetchUpdate.fulfilled]: (state, { payload }) => {
      let u = [...state.updates.rows];
      const rowExists = u.find(
        (row) => row.agencyClientUpdateId === payload.agencyClientUpdateId
      );
      if (!rowExists) {
        u.push(payload);
        state.updates.rows = u;
      }
    },
    [fetchOlderUpdates.fulfilled]: (state, { payload }) => {
      state.olderUpdates = {
        ...payload,
        rows: [...reverse(payload.rows), ...state.olderUpdates.rows],
      };
    },
    [getAttachments.fulfilled]: (state, { payload }) => {
      state.attachments = payload;
    },
    [getOnboardingDetails.fulfilled]: (state, { payload }) => {
      state.onboarding = payload;
    },
  },
});

export const {
  setPaginationParams,
  setLoading,
  setAgencyClient,
  setAssignees,
  setUpdates,
  setOlderUpdates,
  setOnboardingDetails,
  setShowUpdatesSection,
  setUpsellPaginationParams,
  setCreditNotesPaginationParams,
} = agencyClientSlice.actions;

export const selectAttachments = (state) => state.agencyClient.attachments;

export default agencyClientSlice.reducer;
