import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import { isEmpty } from 'lodash';
import ChartToolTip from 'components/Charts/ChartTooltip';
import { percentageFormatter } from 'utils/formatters';
import PercentageChange from 'components/PercentageChange';
import ComputationTooltip from 'components/ComputationTooltip';

const ReferralRate = ({ loading, summary, records }) => {
  return (
    <div className="bg-white p-4 mt-8">
      <div className="pb-5 pt-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
            Referral Rate
          </p>

          <ComputationTooltip
            title="Referral Rate"
            computations={[
              '(Referred Clients/ Beginning Clients) * 100',
              `(${summary.referredClients} / ${summary.beginningClients}) * 100`,
              percentageFormatter(summary.referralRate),
            ]}
          />
        </div>

        {!isEmpty(summary) && (
          <div>
            <p className="font-inter leading-1.2 tracking-3/4 text-xl text-grayscale-900">
              {percentageFormatter(summary.referralRate)}
            </p>

            <PercentageChange
              className="justify-end"
              currentValue={summary.referralRate}
              previousValue={summary.previousReferralRate}
            />
          </div>
        )}
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
              <XAxis
                dataKey="dateRange"
                style={{
                  fontSize: '11px',
                  color: '#202020',
                  cursor: 'pointer',
                }}
                tickFormatter={(dateRange) =>
                  moment(dateRange.split('--')[0]).format('MMM')
                }
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(value) => percentageFormatter(value)}
              />

              <Bar
                yAxisId="0"
                stroke="#FF6868"
                fill="#FF6868"
                dataKey={'referralRate'}
                dot={false}
                strokeWidth={3}
                formatter={(value) => percentageFormatter(value)}
                name="Referral Rate"
              />

              <Tooltip
                content={
                  <ChartToolTip
                    width="64"
                    Title={({ payload }) => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {moment(payload[0].payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ReferralRate;
