import moment from 'moment';
import { isFunction } from 'lodash';
import ImageViewer from 'react-simple-image-viewer';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { XIcon, CheckIcon } from '@heroicons/react/outline';

import useAuth from 'hooks/useAuth';

import Occurence from './Occurence';
import Button from 'components/Button';
import UrgencyLevel from './UrgencyLevel';
import { Avatar, Modal } from 'components';
import ComplaintStatus from './ComplaintStatus';

import { getNameInitials } from 'utils/formatters';
import { fetchComplaintDetails } from '../complaintSlice';
import usePermissions from 'hooks/usePermissions';

const DetailsModal = ({ id, open, setOpen, onInitResolve }) => {
  const { isAgencyUser } = useAuth();
  const { loading, data } = useSelector((state) => state.complaint.selected);
  const { userCan } = usePermissions();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchComplaintDetails(id));
  }, [id]);

  return (
    <Modal open={open} setOpen={setOpen} as={'div'} align="middle">
      {isViewerOpen && !loading && !!data.files.length && (
        <ImageViewer
          src={data.files.map((s) => s.url)}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ zIndex: 9999 }}
        />
      )}

      <div className="inline-block w-full max-w-5xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="">
          <div className="flex justify-between items-center border-b px-8 py-5">
            <h4 className="text-grayscale-700 leading-1.5 text-base tracking-3/4">
              Complaint
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>

          {!loading && (
            <div className="">
              <div className="grid grid-cols-4 divide-x">
                <div className="col-span-3 pl-8 pr-2 py-8">
                  <div className="grid grid-cols-3 gap-8 border-b border-grayscale-500 pb-5">
                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Partner's Account
                      </p>
                      <p className="text-grayscale-900 text-13 leading-1.5">
                        {data.client?.client}
                      </p>
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Partner's Name
                      </p>
                      <p className="text-grayscale-900 text-13 leading-1.5">
                        {data.client?.defaultContact?.firstName}{' '}
                        {data.client?.defaultContact?.lastName}
                      </p>
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Submitted Date
                      </p>
                      <p className="text-grayscale-900 text-13 leading-1.5">
                        {moment(data.createdAt).format('MMM DD, YYYY')}
                      </p>
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Submitted By
                      </p>
                      <div className="flex items-center space-x-2">
                        <Avatar
                          imageSrc={data.addedBy?.avatar?.thumbnailUrl}
                          initials={getNameInitials(
                            data.addedBy?.firstName,
                            data.addedBy?.lastName
                          )}
                        />

                        <p className="text-grayscale-900 text-13 leading-1.5">
                          {data.addedBy?.firstName} {data.addedBy?.lastName}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Sales Representative
                      </p>
                      {data.client.salesRepresentative && (
                        <div className="flex items-center space-x-2">
                          <Avatar
                            imageSrc={
                              data.client.salesRepresentative.user?.avatar
                                ?.thumbnailUrl
                            }
                            initials={getNameInitials(
                              data.client.salesRepresentative.user?.firstName,
                              data.client.salesRepresentative.user?.lastName
                            )}
                          />

                          <p className="text-grayscale-900 text-13 leading-1.5">
                            {data.client.salesRepresentative.user?.firstName}{' '}
                            {data.client.salesRepresentative.user?.lastName}
                          </p>
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Project Manager
                      </p>
                      {data.client.projectManager && (
                        <div className="flex items-center space-x-2">
                          <Avatar
                            imageSrc={
                              data.client.projectManager.user?.avatar
                                ?.thumbnailUrl
                            }
                            initials={getNameInitials(
                              data.client.projectManager.user?.firstName,
                              data.client.projectManager.user?.lastName
                            )}
                          />

                          <p className="text-grayscale-900 text-13 leading-1.5">
                            {data.client.projectManager.user?.firstName}{' '}
                            {data.client.projectManager.user?.lastName}
                          </p>
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Type
                      </p>
                      <p className="text-grayscale-900 text-13 leading-1.5">
                        {data.type}
                      </p>
                    </div>

                    <div>
                      <p className="text-grayscale-700 text-13 leading-1.5">
                        Occurent
                      </p>
                      <p className="text-grayscale-900 text-13 leading-1.5">
                        <Occurence occurence={data.occurence} />
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-5 gap-5 mt-4">
                    <div className="col-span-3">
                      <p className="font-inter tracking-3/4 text-grayscale-800 font-bold leading-1.2 text-xl mb-3">
                        Files Uploaded by client
                      </p>

                      <div className="grid grid-cols-3 gap-4">
                        {data.files?.map((image, index) => (
                          <div
                            key={image.id}
                            className="aspect-w-1 aspect-h-1 border rounded-lg w-full"
                          >
                            <img
                              src={image.thumbnailUrl || image.url}
                              alt="Image"
                              className="h-full w-full object-cover"
                              onClick={() => openImageViewer(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-span-2">
                      <p className="font-inter tracking-3/4 text-grayscale-800 font-bold leading-1.2 text-xl mb-3">
                        Additional Notes
                      </p>

                      <p className="text-grayscale-900 text-13 leading-1.5">
                        {data.information}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-8 space-y-5">
                  {data.status === 'Ongoing' && isAgencyUser && (
                    <Button
                      autoFocus={true}
                      textColor="grayscale-300"
                      bgColor="success"
                      hoverColor="success-dark"
                      roundedSize="40"
                      fontWeight="bold"
                      classes="w-full tracking-2 flex justify-center space-x-2 leading-1.5"
                      onClick={() =>
                        isFunction(onInitResolve) && onInitResolve()
                      }
                      disabled={!userCan('clients.complaints.resolved')}
                    >
                      <CheckIcon className="w-4 h-4" />
                      <span>Mark as Resolved</span>
                    </Button>
                  )}

                  <div className="flex justify-between">
                    <UrgencyLevel urgencyLevel={data.urgencyLevel} />
                    <ComplaintStatus status={data.status} />
                  </div>

                  {data.status === 'Resolved' && (
                    <div className="space-y-3">
                      <p className="text-grayscale-800 font-bold text-lg leading-1.2 tracking-3/4">
                        Resolution
                      </p>
                      <p className="text-13 text-grayscale-900 leading-1.5">
                        {data.resolution}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DetailsModal;
