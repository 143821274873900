import ClientAssigneeItem from './ClientAssigneeItem';

const TeamAssignees = ({
  title,
  items,
  clientId,
  assignees = [],
  className = '',
  listClassName = '',
  itemClassName = '',
  interConnected = true,
  isPrimary = false,
}) => {
  return (
    <div className={className}>
      <div className="flex items-center space-x-2">
        <h4 className="text-base leading-1.2 tracking-3/4 font-inter font-bold">
          {title}
        </h4>

        {isPrimary && (
          <p className="rounded-2xl bg-red-500 px-2.5 py-1.5 text-white text-10 tracking-1">
            Primary Assignees
          </p>
        )}
      </div>

      <div className={listClassName}>
        {items.map((item, id) => {
          let show = true;
          let disabled = false;

          const roles =
            'allowedRoles' in item
              ? item.allowedRoles.map((role) => role.value)
              : [item.role.value];

          let filters = [
            {
              attribute: 'status',
              operator: 'EQ',
              value: 'ACTIVE',
            },
            {
              attribute: 'role.code',
              operator: 'IN',
              value: roles,
            },
          ];

          if (interConnected) {
            if (id > 0) {
              const dependency = assignees.find(
                (assignee) => assignee.role === items[id - 1].role.value
              );

              if (dependency) {
                filters.push({
                  operator: 'EQ',
                  value: dependency.userId,
                  attribute: 'supervisorId',
                });
              }

              show = !!dependency;
            }

            if (id < items.length - 1) {
              disabled = !!assignees.find(
                (assignee) => assignee.role === items[id + 1].role.value
              );
            }
          }

          return (
            show && (
              <ClientAssigneeItem
                item={item}
                params={{ filterGroups: JSON.stringify([{ filters }]) }}
                clientId={clientId}
                disabled={disabled}
                key={item.role.value}
                className={itemClassName}
                assignee={assignees.find(
                  (assignee) => assignee.role === item.role.value
                )}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default TeamAssignees;
