import axios from 'axios';
import moment from 'moment';
import { lowerCase, startCase } from 'lodash';
import {
  XIcon,
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { Parser } from 'json2csv';

import Modal from 'components/ModalPlain';
import { Table } from 'components';

import FormModal from './form/FormModal';
import ExpandableRow from './ExpandableRow';

const AccountLogs = ({ open, setOpen, account }) => {
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState({ rows: [] });
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get('/account/advertising/logs', {
        params: {
          ...params,
          startDate: date,
          endDate: date,
          accountId: account.accountId,
        },
      })
      .then((response) => setLogs(response.data.data))
      .finally(() => setLoading(false));
  }, [params, account, date]);

  const refreshList = () => {
    setLoading(true);
    axios
      .get('/account/advertising/logs', {
        params: {
          ...params,
          startDate: date,
          endDate: date,
          accountId: account.accountId,
          sort: 'createdAt',
        },
      })
      .then((response) => setLogs(response.data.data))
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      dataField: 'createdAt',
      text: 'Date',
      headerClass: 'w-1/12',
      formatter: (cell, row) => {
        return <span>{moment(cell).format('DD-MM-YYYY')}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      headerClass: 'w-2/12',
      formatter: (cell) => startCase(lowerCase(cell)),
    },
    {
      dataField: 'data.type',
      text: 'Type',
      headerClass: 'w-2/12',
      formatter: (cell, row) => {
        if (row.action === 'CAMPAIGN_CREATION') {
          return (
            <div>
              <p>{startCase(row.data.campaignType)}</p>
              {row.data.campaignType === 'sponsoredProducts' && (
                <p>{startCase(row.data.targetingType)}</p>
              )}
            </div>
          );
        }

        return cell;
      },
    },
    {
      dataField: 'data.campaignName',
      text: 'Ad Group / Campaign Name',
      headerClass: 'w-3/12',
    },
    {
      dataField: 'data.products',
      text: 'ASIN/s',
      headerClass: 'w-2/12',
      formatter: (cell, row) => {
        const asins = cell?.map((item, i) => {
          return (
            <div
              key={`data.products-${i}`}
              className={`${i !== 0 ? 'mt-2' : null}`}
            >
              {item.asin}
            </div>
          );
        });
        return asins;
      },
    },
    {
      dataField: 'data.startDate',
      text: 'Duration',
      headerClass: 'w-2/12',
      formatter: (cell, row) => {
        if (!cell) return '';

        if (cell && row.data?.endDate) {
          return `${cell} - ${row.data.endDate}`;
        }

        return cell;
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const exportData = async () => {
    const response = await axios.get('/account/advertising/logs/export', {
      params: {
        ...params,
        startDate: date,
        endDate: date,
        accountId: account.accountId,
        sort: 'createdAt',
      },
    });
    
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
      encoding: 'UTF-8',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'client-summary.csv';
    link.click();
  };

  return (
    <>
      <FormModal
        account={account}
        open={openForm}
        setOpen={setOpenForm}
        refreshList={refreshList}
      />

      <Modal
        zIndex="z-30"
        open={open}
        setOpen={setOpen}
        persistent={true}
        align="top"
      >
        <div className="inline-block w-11/12 xl:w-4/5 my-8 overflow-hidden text-left transition-all transform bg-grayscale-400 shadow-xl">
          <div className="bg-grayscale-300 border-b border-grayscale-500 px-5 py-6 flex justify-between items-center">
            <h3 className="text-grayscale-700 leading-1.5">{account.name}</h3>
            <XIcon
              className="w-6 h-6 text-grayscale-700 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>

          <div className="px-12 py-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <h3 className="text-grayscale-900 tracking-3/4 text-xl font-bold font-inter leading-1.2 mr-4">
                  Account History
                </h3>
                <div className="flex flex-col items-center justify-center gap-3">
                  <button
                    onClick={() => setOpenForm(true)}
                    className="flex items-center border-2 border-secondary rounded-40 px-5 py-1.5 text-secondary tracking-2 font-bold text-sm leading-1.5"
                  >
                    <span className="mr-2">
                      <PlusIcon className="w-4 h-4" />
                    </span>
                    Update
                  </button>
                  <button
                    onClick={() => {
                      exportData();
                    }}
                    className="flex items-center border-2 border-secondary px-5 py-1.5 text-secondary tracking-2 font-bold text-sm leading-1.5"
                  >
                    Export
                  </button>
                </div>
              </div>

              <div className="flex items-center">
                <button
                  style={{ borderWidth: '0.5px' }}
                  onClick={() =>
                    setDate(moment(date).subtract(1, 'd').format('YYYY-MM-DD'))
                  }
                  className="border p-2 bg-white text-grayscale-700 border-grayscale-600 rounded-l-md"
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>

                <span className="mx-4 traking-3/4 leading-1.2 text-base text-grayscale-900 font-inter">
                  {moment(date).format('MMMM DD, YYYY')}
                </span>

                <button
                  onClick={() =>
                    setDate(moment(date).add(1, 'd').format('YYYY-MM-DD'))
                  }
                  disabled={moment().format('YYYY-MM-DD') === date}
                  style={{ borderWidth: '0.5px' }}
                  className="border p-2 bg-white text-grayscale-700 border-grayscale-600 rounded-r-md disabled:opacity-25"
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </div>
            </div>

            <div className="mt-8">
              <Table
                loading={loading}
                keyField="advLogId"
                columns={columns.map((column) => {
                  return {
                    ...column,
                    classes: `${
                      column.headerClass ?? ''
                    } bg-white font-sourceSansPro py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
                    headerClasses: `${column.headerClass} bg-white font-sourceSansPro py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
                  };
                })}
                data={logs}
                onTableChange={onTableChange}
                params={params}
                bordered={false}
                expandRow={{
                  renderer: (row) => <ExpandableRow row={row} />,
                  onlyOneExpanding: false,
                  showExpandColumn: true,
                  expandHeaderColumnRenderer: () => {
                    return '';
                  },
                  expandColumnRenderer: ({ expanded }) => {
                    return expanded ? (
                      <ChevronUpIcon className="w-5 h-5 cursor-pointer" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 cursor-pointer" />
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountLogs;
