import moment from 'moment';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/solid';
import { Table, UserDisplay } from 'components';

const TemplateList = ({ params, setParams, data, loading }) => {
  const columns = [
    {
      dataField: 'name',
      text: 'Template Name',
      sort: true,
      editable: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            className="underline text-secondary-light"
            to={`/task-management/templates/${row.taskListTemplateId}`}
          >
            {cell}
          </Link>
        );
      },
    },
    {
      dataField: 'partnerType',
      text: 'Partner Type',
      sort: false,
      editable: false,

      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      dataField: 'partnerService',
      text: 'Service Type',
      sort: true,
      headerStyle: {
        minWidth: '150px',
      },
      editable: false,
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      sort: true,
      headerStyle: {
        minWidth: '150px',
      },
      editable: false,
      formatter: (cell) => {
        return moment(cell).format('MMMM Do YYYY, h:mm:ss a');
      },
    },
    {
      dataField: 'lastUpdater.firstName',
      text: 'Updated By',
      sort: false,
      headerStyle: {
        minWidth: '140px',
      },
      formatter: (cell, row) => {
        return <UserDisplay user={row.lastUpdater} />;
      },
    },
    {
      dataField: 'taskListTemplateId',
      text: 'Action',
      className: 'text-center',
      formatter: (cell) => {
        return (
          <Link
            className="border-2 border-grayscale-700 rounded-lg py-1.5 px-4 text-sm items-center space-x-2 flex justify-center font-bold tracking-2 leading-1.5 text-grayscale-700 hover:bg-grayscale-500"
            to={`/task-management/templates/${cell}`}
          >
            <PencilIcon className="w-3.5 h-3.5" />
            <span>Edit</span>
          </Link>
        );
      },
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <Table
      columns={columns.map((column) => {
        return {
          ...column,
          classes:
            'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
          headerClasses:
            'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
        };
      })}
      data={data}
      onTableChange={onTableChange}
      params={params}
      keyField="taskListTemplateId"
      loading={loading}
    />
  );
};

export default TemplateList;
