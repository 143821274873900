import axios from 'axios';
import * as yup from 'yup';
import { isFunction } from 'lodash';
import { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useRoles } from 'features/roles';
import { useAlerts } from 'features/alerts';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const reasons = [
  'Too much workload',
  'Too many asins handled',
  'Have a problematic account',
  'Performance issue of AM',
];

const FormModal = ({ open, setOpen, onSuccess }) => {
  const { popupAlert } = useAlerts();
  const { BRAND_STRATEGIST } = useRoles();

  const [brandStrategists, setBrandStrategists] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/v2/users', {
        params: {
          'role.code': BRAND_STRATEGIST.value,
          include: ['directSubordinates'],
        },
      });

      if (isSubscribed) {
        setBrandStrategists(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  const onSubmit = (values, actions) => {
    axios
      .post('/v2/agency/pause-requests', values)
      .then((response) => {
        setOpen(false);

        setTimeout(() => {
          popupAlert({ type: 'success', title: response.data.message });
        }, 250);

        if (isFunction(onSuccess)) {
          onSuccess();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-8">
          <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
            Pause Request Form
          </h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="h-80vh overflow-y-auto mt-2">
          <Formik
            initialValues={{
              reason: '',
              startDate: null,
              endDate: null,
              accountSpecialistId: '',
              brandStrategistId: '',
            }}
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
              reason: yup.string().required('Required'),
              startDate: yup.date().required('Required'),
              endDate: yup.date().required('Required'),
              accountSpecialistId: yup.string().required('Required'),
              brandStrategistId: yup.string().required('Required'),
            })}
          >
            {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
              <Form className="p-8">
                <div className="space-y-8">
                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Brand Strategist
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="select"
                      name="brandStrategistId"
                      onChange={(e) => {
                        setFieldValue('brandStrategistId', e.target.value);
                        setFieldValue('accountSpecialistId', '');
                      }}
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">Select Brand Strategist</option>
                      {brandStrategists.map((brandStrategist) => (
                        <option
                          key={brandStrategist.userId}
                          value={brandStrategist.userId}
                        >
                          {brandStrategist.firstName} {brandStrategist.lastName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="brandStrategistId"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Account Specialist
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="select"
                      name="accountSpecialistId"
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">Select Account Specialist</option>
                      {(
                        brandStrategists.find(
                          (brandStrategist) =>
                            brandStrategist.userId === values.brandStrategistId
                        )?.directSubordinates ?? []
                      ).map((accountSpecialist) => (
                        <option
                          key={accountSpecialist.userId}
                          value={accountSpecialist.userId}
                        >
                          {accountSpecialist.firstName}{' '}
                          {accountSpecialist.lastName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="accountSpecialistId"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <Label
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Reason
                      <RequiredAsterisk />
                    </Label>

                    <Field
                      as="select"
                      name="reason"
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">Select Reason</option>
                      {reasons.map((reason) => (
                        <option key={reason} value={reason}>
                          {reason}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="reason"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-8">
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Start Date
                        <RequiredAsterisk />
                      </Label>

                      <input
                        type="date"
                        name="startDate"
                        onChange={(e) =>
                          setFieldValue('startDate', e.target.value)
                        }
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="">
                      <Label
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        End Date
                        <RequiredAsterisk />
                      </Label>

                      <input
                        type="date"
                        name="endDate"
                        onChange={(e) =>
                          setFieldValue('endDate', e.target.value)
                        }
                        className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 p-3.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                      />

                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <button
                      onClick={() => setOpen(false)}
                      className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                    >
                      Cancel
                    </button>

                    <Button
                      type="submit"
                      roundedSize={40}
                      showLoading={true}
                      loading={isSubmitting}
                      disabled={!(isValid && dirty)}
                      classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
