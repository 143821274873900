import classNames from 'utils/classNames';

const PriceLabel = ({
  currency = '$',
  price = 0,
  color = 'text-inherit',
  currencyColor = 'text-grayscale-600',
}) => {
  return (
    <span className={classNames('font-normal', color)}>
      <span className={currencyColor}>{currency}</span>
      &nbsp;
      {price.toFixed(2)}
    </span>
  );
};

export default PriceLabel;
