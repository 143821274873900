import { NavLink, useRouteMatch } from 'react-router-dom';

const RedirectToCampaign = ({
  campaignName,
  campaignType,
  campaignId,
  className = '',
  enable = true,
}) => {
  const { url } = useRouteMatch();

  const links = {
    sponsoredProducts: 'products',
    sponsoredBrands: 'brands',
    sponsoredDisplay: 'display',
  };

  return enable ? (
    <NavLink
      className={className}
      to={`${url}/${links[campaignType]}/campaigns?campaignId=${campaignId}`}
    >
      {campaignName}
    </NavLink>
  ) : (
    <p className={className}>{campaignName}</p>
  );
};

export default RedirectToCampaign;
