import axios from 'axios';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';

import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const Optimization = ({ values, handleChange, setValues, errors }) => {
  const [rules, setRules] = useState([]);

  useEffect(() => {
    axios
      .get('/account/advertising/rules/', {
        params: {
          accountId: values.accountId,
          pageSize: 1000,
          campaignType: 'sponsoredProducts',
          recordType: values.data.recordType,
        },
      })
      .then((response) => setRules(response.data.data.rows));
  }, [values.data.recordType]);

  return (
    <div className="flex flex-col w-full gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.optimizationType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Optimization Type <RequiredAsterisk />
        </Label>
        <Field
          name="data.optimizationType"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => handleChange(e)}
        >
          <option value="Cutting Bleeders">Cutting Bleeders</option>
          <option value="Increase Bids">Increase Bids</option>
          <option value="Decrease Bids">Decrease Bids</option>
          <option value="Pause Ad">Pause Ad</option>
        </Field>
      </div>

      <div>
        <Label
          htmlFor="data.recordType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Record Type <RequiredAsterisk />
        </Label>
        <Field
          name="data.recordType"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => handleChange(e)}
        >
          <option value="campaigns">Campaigns</option>
          <option value="searchTerms">Search Terms</option>
          <option value="keywords">Keywords</option>
        </Field>
      </div>

      <div>
        <FieldArray name="data.rules">
          {() => (
            <div>
              <Label
                htmlFor={`data.rules.0.advRuleId`}
                classes="text-left leading-1.2"
                textColor="text-grayscale-800"
                textSize="13"
              >
                Rules <RequiredAsterisk />
              </Label>
              <Field
                name={`data.rules.0.advRuleId`}
                as="select"
                className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
                onChange={(e) => {
                  const { value } = e.target;

                  const rule = rules.find(
                    (r) => parseInt(r.advRuleId) === parseInt(value)
                  );

                  let dataRules = rule
                    ? [{ advRuleId: rule.advRuleId, name: rule.name }]
                    : [{ advRuleId: '', name: '' }];

                  setValues({
                    ...values,
                    data: { ...values.data, rules: dataRules },
                  });
                }}
              >
                <option value="">Select Used Rules</option>
                {rules.map((rule, i) => (
                  <option
                    key={`logs-optimization-${i}`}
                    value={rule.advRuleId}
                    name={rule.name}
                  >
                    {rule.name}
                  </option>
                ))}
              </Field>

              {isArray(errors?.data?.rules) && (
                <ErrorMessage
                  name={'data.rules.0.advRuleId'}
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              )}
            </div>
          )}
        </FieldArray>
      </div>

      <div>
        <Label
          htmlFor="data.otherInformation"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Other Information <RequiredAsterisk />
        </Label>
        <Field
          name="data.otherInformation"
          placeholder="Add information related to action taken"
          as="textarea"
          rows={3}
          onChange={(e) => handleChange(e)}
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
        />
      </div>
      <ErrorMessage
        name={`data.otherInformation`}
        component="div"
        className="text-red-700 font-normal text-xs"
      />
    </div>
  );
};

export default Optimization;
