import { Field } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

import SponsoredProductCampaign from './SponsoredProductCampaign';
import SponsoredDisplayCampaign from './SponsoredDisplayCampaign';
import SponsoredBrandCampaign from './SponsoredBrandCampaign';

const CampaignCreation = ({ handleChange, values, setValues, errors }) => {
  const campaignTypes = [
    {
      name: 'Sponsored Products ',
      value: 'sponsoredProducts',
      initialValues: {
        campaignName: '',
        campaignType: 'sponsoredProducts',
        startDate: '',
        endDate: '',
        budget: '',
        targetingType: 'auto',
        strategy: 'legacyForSales',
        adGroupName: '',
        negativeKeywords: '',
        products: [{ asin: '' }],
      },
    },
    {
      name: 'Sponsored Displays ',
      value: 'sponsoredDisplay',
      initialValues: {
        campaignName: '',
        campaignType: 'sponsoredDisplay',
        targetingType: 'T00020',
        startDate: '',
        endDate: '',
        strategy: 'optimizeForEach',
        budget: '',
        adGroupName: '',
        adGroupCreativeType: 'image',
      },
    },
    {
      name: 'Sponsored Brands ',
      value: 'sponsoredBrands',
      initialValues: {
        campaignName: '',
        campaignType: 'sponsoredBrands',
        startDate: '',
        endDate: '',
        budget: '',
        adGroupName: '',
        adFormat: 'productCollection',
        negativeKeywords: '',
      },
    },
  ];

  return (
    <div className="flex flex-col w-full gap-y-6 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.campaignType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Campaign Type <RequiredAsterisk />
        </Label>
        <Field
          name="data.campaignType"
          as="select"
          className="form-select disabled-white text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          onChange={(e) => {
            const ref = campaignTypes.find((c) => c.value === e.target.value);
            handleChange(e);
            setValues({ ...values, data: ref.initialValues });
          }}
        >
          {campaignTypes.map((campaign) => (
            <option
              key={`campaign-creation-campaignType-${campaign.value}`}
              value={campaign.value}
            >
              {campaign.name}
            </option>
          ))}
        </Field>
      </div>

      {values.data.campaignType === 'sponsoredProducts' ? (
        <SponsoredProductCampaign
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          errors={errors}
        />
      ) : values.data.campaignType === 'sponsoredBrands' ? (
        <SponsoredBrandCampaign
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          errors={errors}
        />
      ) : values.data.campaignType === 'sponsoredDisplay' ? (
        <SponsoredDisplayCampaign
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          errors={errors}
        />
      ) : null}
    </div>
  );
};

export default CampaignCreation;
