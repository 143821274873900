import classNames from 'utils/classNames';
import { XIcon, CheckIcon } from '@heroicons/react/outline';

const CredentialsOverview = ({ isConnected, title }) => {
  return (
    <div
      className={classNames(
        isConnected ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600',
        'flex rounded-3xl w-40 text-sm items-center py-1.5 px-2 space-x-2 justify-start'
      )}
    >
      {isConnected ? (
        <CheckIcon className="h-5 w-5 text-green-600" />
      ) : (
        <XIcon className="h-5 w-5 text-red-600" />
      )}
      <span>{title}</span>
    </div>
  );
};

export default CredentialsOverview;
