import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import Breakdown from './pages/Breakdown/Breakdown';
import Snapshot from './pages/Snapshot/Snapshot';
import Forecast from './pages/Forecast/Forecast';

const Trends = () => {
  const { url, path } = useRouteMatch();

  return (
    <div className="bg-white px-8 py-4">
      <div className="flex items-center">
        <p className="font-inter text-xl font-bold whitespace-nowrap tracking-3/4 text-grayscale-900 leading-1.2">
          Advertising Trends
        </p>

        <div className="ml-8 pb-2 border-b text-grayscale-800 border-grayscale-500 w-full">
          <NavLink
            exact={true}
            to={`${url}`}
            className="text-mini px-3 leading-1.5 py-2"
            activeClassName="text-red-500 font-bold border-b-2 border-red-500"
          >
            Breakdown
          </NavLink>

          <NavLink
            to={`${url}/snapshot`}
            exact={true}
            className="text-mini px-3 leading-1.5 py-2"
            activeClassName="text-red-500 font-bold border-b-2 border-red-500"
          >
            Snapshot
          </NavLink>

          <NavLink
            to={`${url}/forecast`}
            exact={true}
            className="text-mini px-3 leading-1.5 py-2"
            activeClassName="text-red-500 font-bold border-b-2 border-red-500"
          >
            Forecast
          </NavLink>
        </div>
      </div>

      <div className="mt-6">
        <Switch>
          <Route path={path} exact component={Breakdown} />
          <Route path={`${path}/snapshot`} exact component={Snapshot} />
          <Route path={`${path}/forecast`} exact component={Forecast} />
        </Switch>
      </div>
    </div>
  );
};

export default Trends;
