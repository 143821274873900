import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { reverse } from 'lodash';
import { setAlert } from '../alerts/alertsSlice';

export const fetchSalesClients = createAsyncThunk(
  'sales/getClients',
  async (params, thunkAPI) => {
    const response = await axios.get('/agency/sales/clients', { params });
    thunkAPI.dispatch(setPaginationParams(params));
    return response.data.data;
  }
);

export const fetchAllUsers = createAsyncThunk(
  'sales/all-users',
  async ({}, thunkAPI) => {
    const response = await axios.get('/users', {
      params: { roleLevel: 'agency', pageSize: 1000 },
    });
    return response.data.data;
  }
);

export const fetchClient = createAsyncThunk(
  'sales/getClient',
  async (salesClientId, thunkAPI) => {
    const response = await axios.get(`/agency/sales/clients/${salesClientId}`);
    return response.data.data;
  }
);

export const fetchSalesEmployees = createAsyncThunk(
  'sales/employees',
  async (params, thunkAPI) => {
    const response = await axios.get(
      `/agency/employees/department/list?department=${params}`
    );
    return response.data.data;
  }
);

export const fetchUpdate = createAsyncThunk(
  'sales/update',
  async (salesUpdateId, thunkAPI) => {
    const response = await axios.get(`/agency/sales/updates/${salesUpdateId}`);
    return response.data.data;
  }
);

export const fetchUpdates = createAsyncThunk(
  'sales/updates',
  async ({ params, salesClientId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/sales/clients/${salesClientId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const fetchOlderUpdates = createAsyncThunk(
  'sales/olderUpdates',
  async ({ params, salesClientId }, thunkAPI) => {
    const response = await axios.get(
      `/agency/sales/clients/${salesClientId}/updates`,
      { params }
    );
    return response.data.data;
  }
);

export const fetchSalesReps = createAsyncThunk(
  'sales/reps',
  async (fetchAllUsers) => {
    const response = await fetchAllUsers({ 'role.department': 'SALES' });
    // const response = await axios.get(
    //   `/agency/sales/clients/reps?roles=Sales Administrator,Sales Admin,Sales Admin Team Lead,Sales Administrator Team Lead`
    // );
    return response;
  }
);

export const fetchQuotes = createAsyncThunk(
  'sales/getQuotes',
  async (params) => {
    const response = await axios.get('/agency/sales/quotes', { params });
    return response.data.data;
  }
);

export const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    salesClients: [],
    updates: { rows: [] },
    showUpdatesSection: true,
    olderUpdates: { nextPage: 1, rows: [] },
    client: null,
    clientStatus: '',
    attachments: {},
    boardView: 'list',
    paginationParams: {
      page: 1,
      pageSize: 10,
      search: '',
      sort: 'createdAt:asc',
      status: 'booked',
    },
    updatesPaginationParams: {
      page: 1,
      pageSize: 10,
      scopes: [],
      sort: 'createdAt:desc',
    },
    employees: [],
    commentEditorDraft: '',
    editCommentEditorDraft: '',
    comment: '',
    mail: {
      mailTo: '',
      mailSubject: '',
      mailMessage: '',
      mailFrom: '',
      mailCc: '',
      mailBcc: '',
      attachment: '',
    },
    statuses: [
      {
        order: 1,
        type: 'open',
        name: 'Pending',
        color: '#002F5D',
        bgColor: '#D3E4F5',
        params: { filter: { status: 'Pending' } },
      },
      {
        order: 2,
        type: 'open',
        name: 'In Progress',
        color: '#BFA654',
        bgColor: '#FFEFD0',
        params: { filter: { status: 'In Progress' } },
      },
      {
        order: 3,
        type: 'closed',
        name: 'Done',
        color: '#00966D',
        bgColor: '#CFFFDD',
        params: { filter: { status: 'Done' } },
      },
    ],
    groups: [
      { label: 'Status', value: 'status' },
      { label: 'Priority', value: 'priority' },
      { label: 'Due Date', value: 'due' },
    ],
    taskTypes: ['TODO', 'CALL', 'EMAIL'],
    agencyUsers: null,
    salesReps: null,
    quotes: null,
  },
  reducers: {
    setPaginationParams: (state, action) => {
      state.paginationParams = action.payload;
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload;
    },
    setcommentEditorDraft: (state, action) => {
      state.commentEditorDraft = action.payload;
    },
    setEditCommentEditorDraft: (state, action) => {
      state.editCommentEditorDraft = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload;
    },
    setOlderUpdates: (state, action) => {
      state.olderUpdates = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setClientStatus: (state, action) => {
      state.clientStatus = action.payload;
    },
    setAgencyUsers: (state, action) => {
      state.agencyUsers = action.payload;
    },
    setShowUpdatesSection: (state, action) => {
      state.showUpdatesSection = action.payload;
    },
    setBoardView: (state, action) => {
      state.boardView = action.payload;
    },
  },
  extraReducers: {
    [fetchSalesClients.fulfilled]: (state, { payload }) => {
      state.salesClients = payload;
    },
    [fetchClient.fulfilled]: (state, { payload }) => {
      state.client = payload;
      state.clientStatus = payload.status;
    },
    [fetchSalesEmployees.fulfilled]: (state, { payload }) => {
      state.employees = payload;
    },
    [fetchUpdate.fulfilled]: (state, { payload }) => {
      let u = [...state.updates.rows];
      const rowExists = u.find(
        (row) => row.salesUpdateId === payload.salesUpdateId
      );
      if (!rowExists) {
        u.push(payload);
        state.updates.rows = u;
      }
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.updates = { ...payload, rows: reverse(payload.rows) };
    },
    [fetchOlderUpdates.fulfilled]: (state, { payload }) => {
      state.olderUpdates = {
        ...payload,
        rows: [...reverse(payload.rows), ...state.olderUpdates.rows],
      };
    },
    [fetchSalesReps.fulfilled]: (state, { payload }) => {
      state.salesReps = payload;
    },
    [fetchQuotes.fulfilled]: (state, { payload }) => {
      state.quotes = payload;
    },
  },
});

export const {
  setPaginationParams,
  setAttachments,
  setcommentEditorDraft,
  setEditCommentEditorDraft,
  setUpdates,
  setOlderUpdates,
  setClient,
  setClientStatus,
  setAgencyUsers,
  setShowUpdatesSection,
  setBoardView,
} = salesSlice.actions;

export const getAttachments = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/agency/sales/attachments`,
      params: params.params,
    });

    dispatch(setAttachments(response.data.data));
  } catch (error) {
    console.log(error.message);
  }
};

export const getLatestUpdates = (salesClientId) => async (dispatch) => {
  const params = {
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
  };
  dispatch(fetchUpdates({ params, salesClientId }));
};

export const selectAttachments = (state) => state.sales.attachments;

export default salesSlice.reducer;
