import { Popover, Transition, Combobox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useClient from 'features/clients/useClient';
import { Fragment, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import useTasks from '../useTasks';

const TaskListPicker = ({ onChange }) => {
  const { getClientList, STATUSES } = useClient();
  const { getTaskLists, taskLists } = useTasks();

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (selected?.value) {
      getTaskLists({ clientId: selected?.value });
    }
  }, [selected?.value]);

  const promiseOptions = (search) => {
    return new Promise(async (resolve) => {
      const response = await getClientList({
        search,
        attributes: ['agencyClientId', 'client'],
        status: STATUSES.ACTIVE,
        sorts: 'client',
      });

      resolve(
        response.data.rows.map((client) => ({
          label: client.client,
          value: client.agencyClientId,
        }))
      );
    });
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <AsyncSelect
        onChange={(selecetd) => setSelected(selecetd)}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: 'transparent',
            backgroundColor: '#F9F9F9',
            borderRadius: '1rem',
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            borderColor: 'transparent',
            backgroundColor: '#F9F9F9',
            borderRadius: '1rem',
          }),
        }}
        classNames={{
          container: () =>
            'w-full border-0 bg-grayscale-400 border-transparent focus:border-transparent focus:ring-0',
          control: () =>
            'text-13 bg-grayscale-400 rounded-2xl border-0 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0',
          menu: () => 'text-13 text-grayscale-900',
        }}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
      />

      <select
        onChange={(e) =>
          onChange(
            taskLists.data.find(
              (taskList) => taskList.taskListId === e.target.value
            )
          )
        }
        className="block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
      >
        <option>Select Department</option>
        {taskLists.data
          .filter(
            ({ code }) =>
              ![
                'client_keyword_research_task_list',
                'client_product_research_task_list',
                'client_onboarding_task_list',
              ].includes(code)
          )
          .map((taskList) => (
            <option key={taskList.taskListId} value={taskList.taskListId}>
              {taskList.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default TaskListPicker;
