import { useMemo } from 'react';
import { Popover } from '@headlessui/react';
import { FilterIcon, XIcon } from '@heroicons/react/solid';

import FilterItem from './FilterItem';

const FilterGroup = ({ filters = [], onClear, onApply }) => {
  const total = useMemo(() => {
    let count = 0;

    filters.forEach((filter) => {
      let filterItemCount = filter.values.length;

      if (filter.options) {
        filter.options.forEach(
          (option) =>
            (filterItemCount =
              filterItemCount + (option.children?.values?.length ?? 0))
        );
      }

      count = count + filterItemCount;
    });

    return count;
  }, [filters]);

  return (
    <Popover className="relative">
      <Popover.Button className="flex space-x-1 items-center text-grayscale-700 outline-none">
        <FilterIcon className="w-4 h-4 inline mr-1" />
        <span className="tracking-2 font-bold text-base leading-1.2">
          Filter
        </span>

        {total > 0 && (
          <div
            className="bg-grayscale-500 text-13 font-bold leading-1.5 rounded-full flex items-center justify-center"
            style={{ width: '30px', height: '30px' }}
          >
            {total}
          </div>
        )}
      </Popover.Button>

      <Popover.Panel className="right-0 absolute z-20 min-h-full max-w-sm">
        {() => (
          <div className="overflow-hidden rounded-2xl shadow-xl ring-1 ring-gray-500 ring-opacity-5 bg-white w-60">
            <div className="">
              <div className="flex justify-between sticky top-0 bg-white pt-6 z-10 px-6">
                <h5 className="font-inter tracking-3/4 text-lg font-bold text-grayscale-900">
                  Filter
                </h5>

                <Popover.Button>
                  <XIcon className="h-5 w-5 text-grayscale-700" />
                </Popover.Button>
              </div>

              <div className="mt-4 divide-y divide-grayscale-500 overflow-y-auto h-96 px-6">
                {filters.map((filter) => (
                  <FilterItem
                    filter={filter}
                    key={filter.field}
                    FilterComponent={filter.FilterComponent}
                    OptionComponent={filter.OptionComponent}
                  />
                ))}
              </div>

              <div className="stricky bottom-0 px-6 py-4 flex justify-between">
                <button
                  className="text-secondary-light text-14 tracking-2 leading-1.5 font-bold"
                  onClick={() => onClear()}
                >
                  Clear
                </button>

                <button
                  className="text-grayscale-300 bg-secondary rounded-40 py-1 px-4 text-14 tracking-2 leading-1.5 font-bold"
                  onClick={() => onApply()}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default FilterGroup;
