import { useState } from 'react';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';
import { currencyFormatter } from 'utils/formatters';
import metrics from '../../utils/metrics';

const MetricsChart = ({
  id = '',
  className = '',
  attributes = [],
  records = [],
  hideableItem = true,
}) => {
  const [visibleAttributes, setVisibleAttributes] = useState(attributes);

  const handleClick = ({ dataKey }) => {
    if (!hideableItem) return;

    setVisibleAttributes(
      visibleAttributes.includes(dataKey)
        ? visibleAttributes.filter((m) => m !== dataKey)
        : [...visibleAttributes, dataKey]
    );
  };

  return (
    <div className={className}>
      <div className="h-60">
        <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
          <ComposedChart data={records}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="date" style={{ fontSize: '11px' }} />
            <YAxis
              yAxisId="0"
              orientation="left"
              stroke="#8884d8"
              style={{ fontSize: '11px' }}
              tickFormatter={(tick) => currencyFormatter(tick)}
              axisLine={false}
            />

            <Tooltip
              content={
                <CustomTooltip containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl w-48" />
              }
            />

            <Legend
              onClick={handleClick}
              wrapperStyle={{
                cursor: 'pointer',
                fontSize: '11px',
                marginTop: '10rem',
              }}
            />

            {attributes
              .map((attribute) =>
                metrics.find((metric) => metric.key === attribute)
              )
              .map((metric) => {
                return (
                  <Line
                    yAxisId="0"
                    key={`metrics-chart-${metric.key}-${id}`}
                    type="linear"
                    dataKey={metric.key}
                    stroke={metric.color}
                    fill={metric.color}
                    name={metric.title}
                    dot={{ fill: metric.color }}
                    hide={!visibleAttributes.includes(metric.key)}
                    formatter={metric.rawFormatter}
                  />
                );
              })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MetricsChart;
