import { useState } from 'react';
import axios from 'axios';
import ModalHeader from 'components/ModalHeader';
import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';
import { STATUSES_ARCHIVED } from '../utils/constants';
import Spinner from 'components/Spinner';

export default function ChangestatusModal({
  setOpen,
  records,
  getLeads,
  resetSelected,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [selected, setSelected] = useState(records);
  const onClose = () => {
    setOpen(false);
  };
  const onSave = async () => {
    if (newStatus !== '') {
      for (const [i, leadId] of selected.entries()) {
        try {
          const response = await axios.put(`/agency/leads/${leadId}`, {
            status: newStatus,
          });
          if (response.data.success) {
            if (i + 1 === selected.length) {
              dispatch(
                setAlert('success', `${selected.length} Leads Profile Saved`)
              );
              setLoading(false);
              await getLeads();
              setOpen(false);
              setSelected([]);
              resetSelected([]);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    } else {
      dispatch(setAlert('error', 'Select new status!'));
    }
  };

  return (
    <>
      <div className="inline-flex flex-col justify-center h-screen">
        <div className="inline-block w-180 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={`${selected.length} Records: Change status to Archive `}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={onClose}
          />

          <div className="flex justify-center">
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <Spinner height="50" width="50" />
              </div>
            ) : (
              <select
                className="mr-2 pl-4 pr-11 py-1.5 text-base border-gray-300 focus:outline-none focus:ring-red-300 focus:border-red-300 sm:text-sm rounded-2xl"
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value);
                }}
              >
                <option value=""></option>
                {STATUSES_ARCHIVED.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="mt-4 flex justify-center space-x-6">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-bold tracking-2 text-grayscale-700 border border-transparent rounded-40 leading-1.2 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-bold tracking-2 text-red-700 border border-transparent rounded-40 leading-1.2 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
