import { useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import DenyModal from './components/review/DenyModal';
import useUpsell from 'hooks/useUpsell';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import UpsellDetails from './components/UpsellDetails';
import Confirm from 'components/Confirm';

const UpsellCreditNoteModal = ({
  open,
  setOpen,
  creditNote,
  onReload,
  type = 'reviewer',
}) => {
  const [isOpenDeny, setIsOpenDeny] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const { alertError } = useAlert();
  const [saving, setSaving] = useState(false);

  const onApprove = async () => {
    setSaving(true);
    await axios
      .post(`/agency/credit-notes/${creditNote.creditNoteId}/approveupsell`)
      .then((response) => {
        setIsOpenConfirm(false);
        setOpen(false);
        onReload();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-20"
      >
        <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title="Upsell to Credit Request"
            setOpen={setOpen}
            fontSize="text-xl"
            fontStyle="font-bold"
            px="px-4 md:px-8"
            py="pt-4 md:pt-8 pb-0"
            xAlign="items-start"
            border=""
          />
          <div className="p-8 pt-0 overflow-y-auto" style={{ height: '55vh' }}>
            {creditNote?.upsell && (
              <>
                <UpsellDetails creditNote={creditNote} />

                {creditNote.status === 'awaiting approval' &&
                  type !== 'creator' && (
                    <div className="mt-8 flex space-x-6">
                      <Button
                        classes="border-0 font-bold tracking-2"
                        bgColor="red-500"
                        hoverColor="red-700"
                        roundedSize="full"
                        textColor="white"
                        px={12}
                        py={1.5}
                        showLoading={true}
                        onClick={() => setIsOpenDeny(true)}
                      >
                        Deny
                      </Button>
                      <Button
                        classes="border-0 font-bold tracking-2"
                        bgColor="green-500"
                        hoverColor="green-700"
                        roundedSize="full"
                        textColor="white"
                        px={10}
                        py={1.5}
                        type="button"
                        showLoading={false}
                        onClick={() => setIsOpenConfirm(true)}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </Modal>
      <Confirm
        title="Are you sure you want to approve the request to waive the upsell fee?"
        content={
          <p className="text-sm leading-tight">
            If you click&nbsp;
            <span className="text-success-dark">approve</span>, a paid invoice
            will be automatically generated
          </p>
        }
        open={isOpenConfirm}
        setOpen={setIsOpenConfirm}
        onOkClick={onApprove}
        onCancelClick={() => setIsOpenConfirm(false)}
        okText="Approve"
        showOkLoading={true}
        okLoading={saving}
      />

      <DenyModal
        open={isOpenDeny}
        setOpen={setIsOpenDeny}
        creditNote={creditNote}
        getCreditNotes={onReload}
        setReviewModalOpen={setOpen}
      />
    </>
  );
};

export default UpsellCreditNoteModal;
