import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/solid';
import DropdownMenu from 'components/DropdownMenu';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import DatepickerSingle from 'components/DatepickerSingle';
import { setAlert } from 'features/alerts/alertsSlice';
import usePermissions from 'hooks/usePermissions';
import { useDispatch } from 'react-redux';

const Exporter = ({ paginationParams }) => {
  const dispatch = useDispatch();
  const { isAgencySuperUser, userCan } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalHideBtn, setShowCalHideBtn] = useState(false);

  const convertCsv = async () => {
    setLoading(true);
    const [month, day, year] = selectedDate.toLocaleDateString().split('/');
    let response;
    try {
      response = await axios.get(
        `/agency/invoices/export?status=${paginationParams.status}&csvDate=${
          year + '-' + month + '-' + day
        }`
      );

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'invoices.csv';
      link.click();
    } catch (error) {
      dispatch(
        setAlert('error', 'No Invoice found for the selected invoice date')
      );
    } finally {
      setLoading(false);
    }
  };

  const calenderHandler = async () => {
    if (selectedDate) {
      setLoading(true);
      setShowCalHideBtn(!showCalHideBtn);

      await convertCsv();
      setLoading(false);
    }
  };

  useEffect(() => {
    calenderHandler();
  }, [selectedDate]);
  return (
    <div className="flex items-center justify-end">
      <DropdownMenu
        title={
          (userCan('invoices.export') || isAgencySuperUser()) && (
            <Button
              classes={'rounded-full'}
              border=" border-2 border-blue-900 tracking-widest font-bold py-2.5"
              hoverColor="blue-100"
              textColor="blue-900"
              bgColor="bg-white"
              onClick={() => {
                setShowCalHideBtn(!showCalHideBtn);
              }}
              showLoading={loading}
            >
              {loading ? (
                <Spinner color="blue" />
              ) : (
                <>
                  <PlusIcon className="h-4 w-4 mr-2" />
                  Export by Date
                </>
              )}
            </Button>
          )
        }
        titleClasses="flex items-center mr-1"
        buttonBg="bg-transparent"
        buttonFontWeight="font-normal"
        hoverClasses="bg-none"
        textColor="text-cyan-600"
        classes="text-sm  rounded-2xl"
        buttonRounded=""
        hoverText="hover:text-cyan-500"
        dropdownWidth="w-88"
        padding=""
        position="absolute"
        hideArrow
        zIndex=""
      >
        <DatepickerSingle value={selectedDate} onChange={setSelectedDate} />
      </DropdownMenu>
    </div>
  );
};

export default Exporter;
