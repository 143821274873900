import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useEffect, useState } from 'react';
import { object, string, number } from 'yup';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import axios from 'axios';
import {
  dateFormatterUTC,
  joiAlertErrorsStringify,
  numberFormatter,
} from 'utils/formatters';
import Label from 'components/Forms/Label';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import FormikErrorNotification from 'components/FormikErrorNotification';
import useAlert from 'hooks/useAlert';
import { Link } from 'react-router-dom';

import classNames from 'utils/classNames';
import useAgencyClient from 'hooks/useAgencyClient';
import useCreditNotes from 'features/creditNotes/useCreditNotes';
import { pick } from 'lodash';

const CreateCreditNoteModal = ({
  open,
  setOpen,
  action, //create, update
  creditNote,
  getCreditNotes,
}) => {
  const { agencyClient } = useAgencyClient();
  const { STATUS } = useCreditNotes();
  const { alertSuccess, alertError } = useAlert();
  const [saving, setSaving] = useState(false);
  const emptyInitialValues = {
    agencyClientId: agencyClient.agencyClientId,
    customerId: agencyClient.zohoId,
    name: '',
    description: '',
    amount: 0,
    notes: '',
    status: STATUS.draft,
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  useEffect(() => {
    setInitialValues(
      creditNote
        ? pick(creditNote, [
            'agencyClientId',
            'customerId',
            'name',
            'description',
            'amount',
            'notes',
            'status',
          ])
        : emptyInitialValues
    );
  }, [creditNote]);

  const validationSchema = object().shape({
    agencyClientId: string().required('Client is required'),
    name: string().required('Credit Note Name is required'),
    description: string().required('Description is required'),
    amount: number()
      .min(1, 'Credit Request Amount must be >= 1')
      .required('Credit Request Amount is required'),
    notes: string().required('Requester Note is required'),
    status: string().required('Status is required'),
  });

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      const response = await axios.post(
        `/agency/credit-notes${
          action === 'create' ? '' : `/${creditNote.creditNoteId}`
        }`,
        values
      );
      alertSuccess(
        values.status === STATUS.awaitingApproval
          ? 'Your credit note request has been sent for approval'
          : 'Your credit note request draft has been saved',
        response.data.message
      );
      setOpen(false);
      getCreditNotes();
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-40"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={`${action} Credit Note Request`}
          setOpen={setOpen}
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
          xAlign="items-start"
          classes="capitalize"
        />
        <div className="px-8 overflow-y-auto" style={{ height: '55vh' }}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, errors }) => (
              <Form>
                <FormikErrorNotification />
                <div className="mt-6 flex flex-col gap-4">
                  <div>
                    <Label>
                      Credit Note Name
                      <RequiredAsterisk />
                    </Label>
                    <Field
                      name="name"
                      placeholder="Enter Credit Note Name"
                      type="text"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div>
                    <Label>
                      Description
                      <RequiredAsterisk />
                    </Label>
                    <Field
                      name="description"
                      as="textarea"
                      rows={4}
                      placeholder="Enter Credit Note Description"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div>
                    <Label>
                      Requester Note
                      <RequiredAsterisk />
                    </Label>
                    <Field
                      name="notes"
                      as="textarea"
                      rows={4}
                      placeholder="Enter Requester Note"
                      className="form-input"
                    />

                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div className="w-1/2">
                    <Label>
                      Credit Request Amount
                      <RequiredAsterisk />
                    </Label>
                    <div className="relative">
                      <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                        $
                      </span>
                      <Field
                        name="amount"
                        placeholder="Enter Credit Request Amount"
                        type="number"
                        className="form-input text-sm pl-10"
                      />
                    </div>

                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="flex justify-between mt-8 sticky bottom-0 p-8 bg-white border-t -mx-8">
                    {action === 'create' || values.status === STATUS.draft ? (
                      <>
                        <button
                          className="tracking-2 font-bold text-secondary-light text-sm"
                          type="submit"
                          onClick={() => setFieldValue('status', STATUS.draft)}
                          showLoading={
                            values.status === STATUS.draft ? true : false
                          }
                          loading={saving}
                          spinnerColor="success"
                        >
                          Save
                          {action !== 'create' && values.status === STATUS.draft
                            ? ''
                            : ' as Draft'}
                        </button>
                      </>
                    ) : (
                      <ButtonLink
                        classes="tracking-wider font-bold"
                        color="blue"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </ButtonLink>
                    )}
                    <Button
                      classes="border-0 font-bold tracking-2 text-sm"
                      bgColor="secondary"
                      hoverColor="secondary-light"
                      roundedSize="full"
                      textColor="white"
                      px={12}
                      py={2}
                      type="submit"
                      loading={saving}
                      showLoading={
                        values.status === STATUS.awaitingApproval ? true : false
                      }
                      onClick={() =>
                        setFieldValue('status', STATUS.awaitingApproval)
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
export default CreateCreditNoteModal;
