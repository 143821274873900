import axios from 'axios';
import { useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTaskBoardItemsAsync,
  setTaskBoard,
  fetchTaskGroupItemsAsync,
} from './tasksSlice';

const BASE_URI = '/v2/tasks/items';

const {
  PRIORITIES,
  STATUS_LIST,
  TASK_LIST_STATUSES,
  PENDING_STATUS,
  DONE_STATUS,
} = require('./taskConstants');

const useTasks = () => {
  const dispatch = useDispatch();
  const { board } = useSelector((state) => state['tasks.v2']);

  const [taskCount, setTaskCount] = useState({ loading: true, data: 0 });

  const [taskLists, setTaskLists] = useState({
    loading: false,
    data: [],
  });

  const [taskItemList, setTaskItemList] = useState({
    loading: false,
    data: { rows: [] },
  });

  const setBoard = useCallback(
    (payload) => {
      dispatch(setTaskBoard(payload));
    },
    [dispatch]
  );

  const fetchTaskBoardItems = useCallback(
    async (key, options) => {
      await dispatch(await fetchTaskBoardItemsAsync({ key, options }));
    },
    [dispatch]
  );

  const fetchTaskGroupItems = useCallback(async (id, params) => {
    await dispatch(await fetchTaskGroupItemsAsync({ id, params }));
  });

  const getTaskCount = async (params = {}) => {
    setTaskCount({ loading: true, data: 0 });

    const { data: response } = await axios.get(`${BASE_URI}/count`, {
      params,
    });

    setTaskCount({ loading: false, data: response.data });

    return response;
  };

  const getTaskLists = async (params = {}) => {
    setTaskLists({ loading: true, data: [] });

    const { data: response } = await axios.get('/v2/tasks/lists', {
      params,
    });

    setTaskLists({ loading: true, data: response.data });

    return response;
  };

  const getTaskItemList = async (params = {}) => {
    setTaskItemList({ loading: true, data: { rows: [] } });

    const { data: response } = await axios.get(`${BASE_URI}/list`, {
      params,
    });

    setTaskItemList({ loading: false, data: response.data });

    return response;
  };

  const createTask = async (data) => {
    const { data: response } = await axios.post('/agency/tasks/items', data);

    return response;
  };

  const updateTask = async (taskId, data) => {
    const { data: response } = await axios.put(
      `/agency/tasks/items/${taskId}`,
      data
    );

    return response;
  };

  const getAssignees = async (taskId) => {
    const response = await axios.get(`${BASE_URI}/${taskId}/assignees`);

    return response.data;
  };

  const addAssignee = async (taskId, assigneeId) => {
    const response = await axios.post(
      `${BASE_URI}/${taskId}/assignees/${assigneeId}`
    );

    return response.data;
  };

  const removeAssignee = async (taskId, assigneeId) => {
    const response = await axios.delete(
      `${BASE_URI}/${taskId}/assignees/${assigneeId}`
    );

    return response.data;
  };

  const updateAssignee = async (taskId, assigneeId, data) => {
    console.log(data);
    const response = await axios.put(
      `${BASE_URI}/${taskId}/assignees/${assigneeId}`,
      data
    );

    return response.data;
  };

  const hasSubtasks = (task) => {
    return (
      'subtasks' in task &&
      Array.isArray(task.subtasks) &&
      !!task.subtasks.length
    );
  };

  return {
    taskLists,
    taskItemList,
    taskCount,
    createTask,
    updateTask,
    getTaskItemList,
    addAssignee,
    getAssignees,
    updateAssignee,
    removeAssignee,
    getTaskCount,
    hasSubtasks,
    getTaskLists,
    board,
    setBoard,
    fetchTaskBoardItems,
    fetchTaskGroupItems,

    PRIORITIES,
    STATUS_LIST,
    TASK_LIST_STATUSES,
    PENDING_STATUS,
    DONE_STATUS,
  };
};

export default useTasks;
