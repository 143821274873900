import React from 'react';
import classNames from 'utils/classNames';

const Input = ({
  id,
  name = '',
  type,
  value,
  onChange,
  classes,
  hasError,
  errorClasses = 'border-red-400',
  border = 'border-gray-300',
  rounded = 'rounded-md',
  shadow = '',
  ...rest
}) => {
  return (
    <input
      type={type}
      {...(id && { id })}
      name={id ?? name}
      className={classNames(
        'focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full sm:text-sm disabled:bg-gray-200 placeholder-gray-300',
        shadow,
        border,
        rounded,
        hasError && errorClasses,
        classes ?? ''
      )}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Input;
