import PriceLabel from 'components/PriceLabel';
import useUpsell from 'hooks/useUpsell';
import classNames from 'utils/classNames';
import Badge from 'components/Badge';
import { dateFormatter, numberFormatter } from 'utils/formatters';

const UpsellDetails = ({ creditNote }) => {
  const { isItemWalmartListingOptimization, isItemPaidReviews } = useUpsell();

  return (
    <div className="mt-3">
      <div className="sm:grid grid-cols-2 gap-4">
        <div className="col-span-1 flex flex-col">
          <label className="text-gray-500 text-xs pb-1">Client</label>
          {creditNote.agencyClient?.client}
        </div>
        <div className="col-span-1 flex flex-col">
          <label className="text-gray-500 text-xs pb-1">Date</label>
          {dateFormatter(creditNote.upsell?.createdAt)}
        </div>
        <div className="col-span-2 flex flex-col">
          <label className="text-gray-500 text-xs pb-1">Upsell Items</label>
          {creditNote.upsell?.details?.name}
        </div>
        {isItemPaidReviews(creditNote.upsell?.details?.name) ? (
          <>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">Review Fee</label>
              <PriceLabel price={creditNote.upsell?.details?.reviewFee} />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">
                Processing Fee
              </label>
              <PriceLabel
                currency="%"
                price={creditNote.upsell?.details?.processingFee}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">Tax</label>
              <PriceLabel
                currency="%"
                price={creditNote.upsell?.details?.tax}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">
                Estimate Completion Date
              </label>
              {creditNote.upsell?.details?.completionDate}
            </div>
          </>
        ) : (
          <>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">Quantity</label>
              {numberFormatter(creditNote.upsell?.details?.qty)}
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="text-gray-500 text-xs pb-1">Unit Price</label>
              <PriceLabel price={creditNote.upsell?.details?.price} />
            </div>
          </>
        )}
        <div className="col-span-1 flex flex-col">
          <label className="text-gray-500 text-xs pb-1">Total</label>
          <div className="flex items-center space-x-2">
            <PriceLabel price={creditNote.upsell?.totalAmount} />
            <Badge color="green" rounded="full" padding="px-4 py-2">
              Upsell Fee Waived
            </Badge>
          </div>
        </div>

        <div className="col-span-2 flex flex-col">
          <label className="text-gray-500 text-xs pb-1">
            Upsell Description
          </label>
          <p className="whitespace-pre-wrap break-words">
            {creditNote.upsell?.details?.description}
          </p>
        </div>
        {creditNote.upsell?.details && (
          <div className="col-span-2 border-t border-grayscale-500 mt-4">
            <div className="font-bold text-lg pt-6 pb-2 text-grayscale-900">
              {isItemPaidReviews(creditNote.upsell?.details?.name)
                ? 'ASINs to Review'
                : isItemWalmartListingOptimization(
                    creditNote.upsell?.details?.name
                  )
                ? 'Service Items'
                : 'Service ASINs'}
            </div>
          </div>
        )}

        {creditNote.upsell?.serviceAsins?.map((asin, index) => {
          return (
            <div
              key={asin.upsellServiceAsinId}
              className="col-span-2 grid grid-cols-3 border border-grayscale-500 rounded-xl p-4 gap-x-6 gap-y-2"
            >
              <div
                className={classNames(
                  isItemPaidReviews(creditNote.upsell?.details?.name)
                    ? 'col-span-1'
                    : 'col-span-3',
                  'flex flex-col'
                )}
              >
                <label className="text-grayscale-800 text-base pb-1 font-bold">
                  {isItemWalmartListingOptimization(
                    creditNote.upsell.details.name.toLowerCase().trim()
                  )
                    ? ' Item '
                    : ' ASIN '}
                  {index + 1}
                </label>
                {asin.asin}
              </div>
              {isItemPaidReviews(creditNote.upsell?.details?.name) && (
                <>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Quantity
                    </label>
                    {asin.qty}
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Unit Price
                    </label>
                    <PriceLabel price={asin.price} />
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Product Cost
                    </label>
                    <PriceLabel price={asin.productCost} />
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Processing Fee
                    </label>
                    <PriceLabel price={asin.totalProcessingFee} />
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Review Fee
                    </label>
                    <PriceLabel price={asin.totalReviewFee} />
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Tax
                    </label>
                    <PriceLabel price={asin.totalTax} />
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label className="text-grayscale-700 text-sm pb-1">
                      Total Amount
                    </label>
                    <PriceLabel price={asin.totalAmount} />
                  </div>
                </>
              )}
            </div>
          );
        })}

        <div className="col-span-2 flex flex-col  border-t border-grayscale-500 mt-4 pt-8 pb-4">
          <label className="text-gray-500 text-xs pb-1">Note</label>
          <p
            className="whitespace-pre-wrap break-words"
            key={creditNote.upsell?.note}
          >
            {creditNote.upsell?.note}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpsellDetails;
