import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  PlusIcon,
  XIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Table, Modal } from 'components';
import CommentEditor from 'components/Editor/CommentEditor';
import { getAttachments } from './salesSlice';
import useComment from 'components/Editor/components/useComment';
import moment from 'moment';
import usePermissions from 'hooks/usePermissions';
const Notes = () => {
  const qRef = useRef();
  const { userCan } = usePermissions();
  const { saveDraftComment } = useComment(qRef);
  const { user } = useSelector((state) => state.auth);

  const { salesClientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState({ rows: [] });
  const [openForm, setOpenForm] = useState(false);
  const { all: employees } = useSelector((state) => state.employees);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    type: 'note',
    include: ['creator', 'modifier'],
    sort: 'createdAt:desc',
  });

  const [isEdit, setIsEdit] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `/agency/sales/clients/${salesClientId}/comments`,
        { params }
      );
      if (isSubscribed) {
        setNotes(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [salesClientId, params]);

  const columns = [
    {
      sort: false,
      dataField: 'creator',
      text: 'Added By',
      headerStyle: { minWidth: '150px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border',
      formatter: (cell) =>
        cell ? `${cell.firstName} ${cell.lastName}` : 'Hubspot Sync',
    },
    {
      sort: false,
      dataField: 'content',
      text: 'Note',
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border break-all',
      formatter: (cell, row) =>
        row.deletedAt ? (
          <div className="text-red-200">This note was deleted</div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: cell,
            }}
          ></div>
        ),
    },
    {
      sort: false,
      dataField: 'modifier',
      text: 'Edited By',
      headerStyle: { minWidth: '150px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border',
      formatter: (cell, row) =>
        cell && !row.deletedAt ? `${cell.firstName} ${cell.lastName}` : '',
    },
    {
      sort: false,
      dataField: 'updatedAt',
      text: 'Edited At',
      headerStyle: { minWidth: '150px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border',
      formatter: (cell, row) =>
        cell && !row.deletedAt
          ? moment(cell).format('Do MMMM, YYYY h:mmA')
          : '',
    },
    {
      sort: false,
      dataField: 'modifier',
      text: 'Deleted By',
      headerStyle: { minWidth: '150px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border',
      formatter: (cell, row) =>
        cell && row.deletedAt ? `${cell.firstName} ${cell.lastName}` : '',
    },
    {
      sort: false,
      dataField: 'deletedAt',
      text: 'Deleted At',
      headerStyle: { minWidth: '150px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border',
      formatter: (cell) =>
        cell ? moment(cell).format('Do MMMM, YYYY h:mmA') : '-',
    },
    {
      sort: false,
      dataField: 'salesCommentId',
      text: 'Action',
      headerStyle: { minWidth: '100px' },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 border-grayscale-300 border break-all',
      formatter: (cell, row) =>
        row.deletedAt ? null : (
          <div className="flex space-x-2">
            <button
              disabled={!userCan('sales.profile.notes.edit')}
              onClick={() => {
                setSelectedNote(row);
                setIsEdit(true);
                setOpenForm(true);
              }}
            >
              <PencilIcon className="w-5 h-5" />
            </button>
            <button
              disabled={!userCan('sales.profile.notes.delete')}
              onClick={() => {
                onDeleteComment(cell);
              }}
            >
              {' '}
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;
    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }
    setParams(newParams);
  };

  const getAttachmentLink = (attachment) => {
    return `${process.env.REACT_APP_API_BASE_URL}/agency/sales/attachments/${attachment.salesAttachmentId}/download`;
  };

  const onSaveComment = async (data) => {
    await axios.post(`/agency/sales/clients/${salesClientId}/comments`, {
      ...data,
      type: 'note',
    });

    setOpenForm(false);

    const response = await axios.get(
      `/agency/sales/clients/${salesClientId}/comments`,
      { params }
    );
    setNotes(response.data.data);
  };

  const onEditComment = async (data) => {
    await axios.put(
      `agency/sales/clients/${salesClientId}/comments/${selectedNote.salesCommentId}`,
      { ...data, modifiedBy: user.userId }
    );

    setOpenForm(false);
    refreshList();
  };

  const onDeleteComment = async (commentId) => {
    await axios.delete(
      `agency/sales/clients/${salesClientId}/comments/${commentId}`
    );

    refreshList();
  };

  const refreshList = async () => {
    const response = await axios.get(
      `/agency/sales/clients/${salesClientId}/comments`,
      { params }
    );
    setNotes(response.data.data);
  };

  return (
    <div>
      <Modal
        as={'div'}
        align="middle"
        open={openForm}
        persistent={true}
        noOverlayClick={true}
        setOpen={setOpenForm}
      >
        <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center px-4 pt-8">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              {!isEdit ? 'Create new note' : 'Edit note'}
            </h4>
            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => {
                setIsEdit(false);
                setSelectedNote(null);
                saveDraftComment(null);
                setOpenForm(false);
              }}
            />
          </div>

          <div className="overflow-y-auto mt-2">
            <CommentEditor
              qRef={qRef}
              open={true}
              setOpen={setOpenForm}
              mentionOffset={-200}
              canSwitchToMail={false}
              editCommentValue={isEdit ? selectedNote.structure : ''}
              isEditMode={isEdit}
              employees={employees}
              uploaderOptions={{
                attachmentKey: 'salesAttachmentId',
                getAttachmentLink: getAttachmentLink,
                primaryKey: { key: 'salesClientId', value: salesClientId },
                postUrl: '/agency/sales/attachments',
                onRefreshList: getAttachments({
                  params: {
                    page: 1,
                    pageSize: 10,
                    sort: 'dateUploaded:desc',
                    salesClientId,
                  },
                }),
              }}
              onSave={isEdit ? onEditComment : onSaveComment}
            />
          </div>
        </div>
      </Modal>
      <div className="mb-8 flex items-center space-x-4 justify-between">
        <h3 className="font-inter font-bold text-25 tracking-3/4 text-grayscale-900 leading-1.2">
          Notes
        </h3>
        <button
          onClick={() => {
            setIsEdit(false);
            setOpenForm(true);
          }}
          className="text-grayscale-300 tracking-2 leading-1.5 text-sm font-bold border-2 bg-secondary rounded-40 py-2 px-5 flex items-center space-x-1 hover:bg-secondary-light"
          disabled={!userCan('sales.profile.notes.create')}
        >
          <PlusIcon className="w-3 h-3 text-grayscale-300" />
          <span>Create new note</span>
        </button>
      </div>
      <Table
        loading={loading}
        keyField="salesCommentId"
        columns={columns}
        data={notes}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};
export default Notes;
