import { FlagIcon } from '@heroicons/react/solid';
import { FlagIcon as FlagIconOutline, XIcon } from '@heroicons/react/outline';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import classNames from 'utils/classNames';
import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';

const PriorityDropdown = ({
  value = 'none',
  setValue,
  configurable = true,
  editable = true,
}) => {
  const statuses = [
    {
      status: 'high',
      color: 'text-red-400',
      selected: true,
      icon: FlagIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
    {
      status: 'normal',
      color: 'text-yellow-400',
      selected: false,
      icon: FlagIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
    {
      status: 'low',
      color: 'text-blue-400',
      selected: false,
      icon: FlagIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
    {
      status: 'none',
      color: 'text-grayscale-600',
      selected: false,
      icon: FlagIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
  ];

  const [selected, setSelected] = useState(
    statuses.find((status) => status.status === value)
  );

  useEffect(() => {
    setSelected(statuses.find((status) => status.status === value));
  }, [value]);

  const onChange = (status) => {
    isFunction(setValue) && setValue(status);
    setSelected(status);
  };

  return (!!selected && !editable) || (!selected && !configurable) ? (
    <div className="" title={selected?.status}>
      {selected ? (
        <selected.icon
          className={classNames(selected.color, 'w-6 h-6 inline')}
        />
      ) : (
        <FlagIconOutline className="w-5 h-5 inline" />
      )}
    </div>
  ) : (
    <DropdownMenu
      title={
        <div className="" title="click to update priority">
          {selected ? (
            <selected.icon
              className={classNames(selected.color, 'w-6 h-6 inline')}
            />
          ) : (
            <FlagIconOutline className="w-5 h-5 inline" />
          )}
        </div>
      }
      titleClasses="flex items-center mr-1"
      buttonBg="bg-transparent"
      buttonFontWeight="font-normal"
      hoverClasses=""
      textColor="text-gray-600"
      classes="text-sm"
      buttonRounded=""
      hoverText="hover:text-red-500"
      dropdownWidth="w-28"
      padding=""
      position="right-0"
      zIndex="z-10"
      hideArrow
    >
      <div className="flex flex-col ">
        {statuses.map((status) => (
          <Menu.Item key={status.status}>
            {({ active }) => (
              <button
                className="flex items-center space-x-2 w-full hover:bg-gray-200 py-2 px-5"
                onClick={() => onChange(status)}
              >
                <status.icon
                  className={classNames(status.color, 'w-5 h-5 inline')}
                />
                <span className="capitalize">{status.status}</span>
              </button>
            )}
          </Menu.Item>
        ))}
      </div>
    </DropdownMenu>
  );
};

export default PriorityDropdown;
