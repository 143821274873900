import Badge from 'components/Badge';
import classNames from 'utils/classNames';
import classnames from 'classnames';

const PaidReviewsStatus = ({
  status,
  width = 'w-auto',
  padding = 'px-12 py-2.5',
}) => {
  return (
    <Badge
      classes={classNames(
        classnames({
          'bg-green-100 text-green-600': status === 'paid',
          'bg-yellow-100 text-yellow-600':
            status === 'pending' || status === 'in-progress',
          'bg-red-100 text-red-600': status === 'cancelled',
          'bg-blue-100 text-blue-600': status === 'completed',
        }),
        'capitalize rounded-2xl text-xs text-center justify-center',
        width
      )}
      rounded=""
      textSize=""
      color=""
      padding={padding}
    >
      {status}
    </Badge>
  );
};

export default PaidReviewsStatus;
