import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'components/Button';
import HtmlEditor from 'components/Editor/HtmlEditor';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import useAlert from 'hooks/useAlert';
import { useDispatch } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import ButtonLink from 'components/ButtonLink';
import useLoggedInUser from 'hooks/useLoggedInUser';

const EmailCommissionsErrorModal = ({
  invoice,
  open,
  setOpen,
  descriptions,
}) => {
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    to: '',
    cc: '',
    subject: '',
    body: '',
  });
  const { user } = useLoggedInUser();

  useEffect(() => {
    if (invoice && open) {
      setFormData({
        ...formData,
        to: user.email,
        subject: `Commission Error for Invoice #: ${invoice.number}`,
        body: `Client: <b>${
          invoice.customer_name
        }</b><br />Error: <b>${descriptions}</b><br />
        Invoice Number: <b>${invoice.number}</b><br />
        Invoice Link: <a href="${window.location.origin.toString()}/invoices/${
          invoice.invoice_id
        }">${window.location.origin.toString()}/invoices/${
          invoice.invoice_id
        }</a>`,
      });
    }
  }, [invoice, open]);

  const onInputChange = (e) => {
    const { target } = e;
    setFormData({ ...formData, [target.name]: target.value });
  };

  function isQuillEmpty(value) {
    if (
      value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      !value.includes('<img')
    ) {
      return true;
    }
    return false;
  }

  const handleChange = (content, delta, source, editor) => {
    if (!isQuillEmpty(content)) {
      setFormData({ ...formData, body: content });
    } else {
      alert('Message body can not be empty');
      setFormData({ ...formData, body: formData.body });
    }
  };

  const onSubmit = async () => {
    const to = formData.to.split(',').map((a) => a.trim());
    const cc = formData.cc ? formData.cc.split(',').map((a) => a.trim()) : [];
    const payload = { ...formData, to, cc };

    setLoading(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/email`,
        payload
      );

      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Email Commission Error"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <form method="POST" onSubmit={onSubmit}>
          <div className="pb-4 md:pb-8 px-4 md:px-8">
            <div className="">
              <div className="flex justify-between">
                <Label htmlFor="to" classes="text-left">
                  To <RequiredAsterisk />
                </Label>
                <span className="text-xs text-gray-500">
                  separate emails by comma
                </span>
              </div>

              <Input
                id="to"
                type="text"
                value={formData.to}
                onChange={onInputChange}
                required
                className="form-input"
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="cc" classes="text-left">
                Cc
              </Label>
              <Input
                id="cc"
                type="text"
                value={formData.cc}
                onChange={onInputChange}
                required
                className="form-input"
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="subject" classes="text-left">
                Subject <RequiredAsterisk />
              </Label>
              <Input
                id="subject"
                type="text"
                value={formData.subject}
                onChange={onInputChange}
                required
                className="form-input"
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="body" classes="text-left">
                Body <RequiredAsterisk />
              </Label>
              <HtmlEditor value={formData.body} onChange={handleChange} />
            </div>
          </div>
          <div className="space-x-4 pb-4 md:pb-8 px-4 md:px-8">
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonLink>
            <Button
              classes="border-0 font-bold tracking-wider "
              bgColor="red-700"
              hoverColor="red-400"
              roundedSize="2xl"
              textColor="white"
              px={12}
              py={1.5}
              shadow=""
              onClick={onSubmit}
              showLoading={true}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailCommissionsErrorModal;
