const MetricCardFooter = ({
  dividendTitle,
  dividendValue,
  divisorTitle,
  divisorValue,
}) => {
  return (
    <div className="text-tiny text-grayscale-700 leading-1.5 px-4 mt-3">
      <div className="flex justify-between">
        <p>= {dividendTitle}</p>
        <p>{dividendValue ? dividendValue : '-'}</p>
      </div>
      <div className="flex justify-between">
        <p>/ {divisorTitle}</p>
        <p>{divisorValue ? divisorValue : '-'}</p>
      </div>
    </div>
  );
};

export default MetricCardFooter;
