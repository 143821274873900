export const ROADMAP_TYPES = [
  {
    value: 'daily',
    name: 'Daily',
  },
  {
    value: 'weekly',
    name: 'Weekly',
  },
  {
    value: 'bi-weekly',
    name: 'Bi-Weekly',
  },
  {
    value: 'monthly',
    name: 'Monthly',
  },
];

export const PARTNER_TYPES = [
  {
    value: 'new',
    name: 'New',
  },
  {
    value: 'existing',
    name: 'Existing',
  },
];

export const ONBOARDING_TYPE = {
  value: 'onboarding',
  name: 'Onboarding',
  columns: ['title', 'description'],
  layout: {
    title: 'col-span-3',
    description: 'col-span-3',
  },
};

export const ROADMAP_TYPE = {
  value: 'roadmap',
  name: 'Roadmap',
  columns: ['title', 'description', 'dueDate', 'department'],
  disabled: true,
  layout: {
    title: 'col-span-2',
    description: 'col-span-3',
    department: 'col-span-1',
  },
};

export const RECURRING_TYPE = {
  value: 'recurring',
  name: 'Recurring',
  columns: ['title', 'description', 'dueDate', 'department'],
  layout: {
    title: 'col-span-2',
    description: 'col-span-2',
    recurringConfig: 'col-span-1',
    department: 'col-span-1',
  },
};

export const ONE_TIME_TYPE = {
  value: 'one-time',
  name: 'One Time',
  layout: {
    title: 'col-span-2',
    description: 'col-span-3',
    department: 'col-span-1',
  },
};

export const TYPES = [
  ONBOARDING_TYPE,
  ROADMAP_TYPE,
  RECURRING_TYPE,
  ONE_TIME_TYPE,
];

export const CATEGORY_COLORS = [
  { background: '#D3E4F5', text: '#6C8BAE' },
  { background: '#FFEFD0', text: '#B89962' },
  { background: '#F2E1FF', text: '#8C74A6' },
  { background: '#FFD4F6', text: '#B864C6' },
  { background: '#FFAFAF', text: '#B05F5F' },
  { background: '#FFD2B9', text: '#B0594D' },
  { background: '#CFFFDD', text: '#5FAE87' },
  { background: '#FFE5E5', text: '#B27C7C' },
  { background: '#FFEF70', text: '#7F4D0A' },
  { background: '#D39415', text: '#6A320B' },
  { background: '#ebf4F5', text: '#B0CDD0' },
];
