import axios from 'axios';
import { useState, useEffect } from 'react';
import { startCase, pick } from 'lodash';
import { Formik, Form, Field, FieldArray } from 'formik';

import { Button } from 'components';
import { useAlerts } from 'features/alerts';

const NotificationPreferences = () => {
  const { popupAlert } = useAlerts();

  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    axios.get('/v2/notifications/preferences').then((response) => {
      const data = response.data.data;

      const items = [
        {
          value: 'task.update.name',
          label: 'Name updated',
        },
        {
          value: 'task.update.status',
          label: 'Status updated',
        },
        {
          value: 'task.update.priority',
          label: 'Priority updated',
        },
        {
          value: 'task.update.dueDate',
          label: 'Due Date updated',
        },
        {
          value: 'task.update.assignees',
          label: 'Assignees updated',
        },
        {
          value: 'task.update.description',
          label: 'Description updated',
        },
      ].map((preference) => {
        const saved = data.find((p) => p.event === preference.value);

        return {
          event: preference.value,
          label: preference.label,
          slack: saved?.slack ?? false,
          application: saved?.application ?? false,
        };
      });

      setPreferences(items);
      setLoading(false);
    });
  }, []);

  const onSubmit = async (values, actions) => {
    try {
      await axios.post('/v2/notifications/preferences/bulk', {
        preferences: values.preferences.map((preference) =>
          pick(preference, ['event', 'slack', 'application'])
        ),
      });
      popupAlert({
        type: 'success',
        title: 'Notification preference updated.',
      });
    } catch (error) {
      popupAlert({
        type: 'error',
        title: 'Failed to update notification preference',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className="w-full space-y-8">
      {loading ? (
        <div className="flex justify-center my-16 animate-pulse">
          <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2">
            Loading Notification Preference...
          </p>
        </div>
      ) : (
        <Formik onSubmit={onSubmit} initialValues={{ preferences }}>
          {({ values, isSubmitting }) => (
            <Form>
              <FieldArray name="preferences">
                <div className="bg-white p-5">
                  <div className="flex w-full justify-between rounded-lg text-left text-sm font-medium focus:outline-none focus-visible:none">
                    <span className="text-xl leading-1.2 font-inter tracking-3/4 font-bold text-grayscale-900">
                      Notify when assigned task:
                    </span>
                  </div>

                  <div className="space-y-5 text-grayscale-900 text-base mt-5">
                    {values.preferences.map((item, idx) => (
                      <div key={item.event}>
                        <p className="leading-1.5 mb-2">{item.label}</p>

                        <div>
                          {['slack', 'application'].map((channel) => (
                            <div
                              key={`${item.event}-${channel}`}
                              className="flex space-x-2 items-center"
                            >
                              <Field
                                type="checkbox"
                                name={`preferences.${idx}.${channel}`}
                              />

                              <p className="">{startCase(channel)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </FieldArray>

              <div className="flex justify-end mt-8">
                <Button
                  type="submit"
                  roundedSize={40}
                  showLoading={true}
                  loading={isSubmitting}
                  classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default NotificationPreferences;
