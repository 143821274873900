import { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import axios from 'axios';
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { useRouteMatch } from 'react-router-dom';
import esignature from 'assets/icons/esignature.svg';
import etemplate from 'assets/icons/etemplate.svg';
import menu from 'assets/icons/menu.svg';
import { capitalize } from 'lodash';
import MailBlockMenu from './MailBlockMenu';
import classNames from 'utils/classNames';
import useMail from './useMail';
import { useSelector } from 'react-redux';
import InputPrepend from 'components/Forms/InputPrepend';
import { debounce } from 'lodash';

const MailBlockButton = ({ type, applyBlockToBody }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const { url } = useRouteMatch();
  const { openTemplatesModal, updateDefaultSignature } = useMail();

  const fetchItems = async () => {
    setLoading(true);
    await axios.get(`/agency/email-${type}`).then((res) => {
      setItems(res.data.data);

      const defaultItem = res.data.data.find((r) => r.isDefault);
      if (defaultItem) updateDefaultSignature(defaultItem);
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchItems();
    }
  }, []);

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  const onSearch = (e) => {
    e.preventDefault();
    const search = e.target.value.toLowerCase();

    let f = items.filter((item) => item.name.toLowerCase().includes(search));
    setFilterItems(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const onOpenTemplates = (action) => {
    openTemplatesModal(action, null, type, url);
  };

  return (
    <>
      <div>
        <Menu as="div" className="relative inline-block text-left">
          <div className="flex">
            <Menu.Button className="flex">
              <img
                src={type === 'signature' ? esignature : etemplate}
                alt={`email ${type}`}
                className="w-5 h-5"
              />
            </Menu.Button>
          </div>
          <Menu.Items className="absolute bottom-12 left-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              <div className="p-4">
                <InputPrepend
                  name="search"
                  onChange={(e) => onDebouncedSearch(e)}
                  type="text"
                  placeholder="Search..."
                  prependText={<SearchIcon className="w-4 h-4" />}
                  border="border-none"
                  rounded="rounded-md"
                  classes="text-base form-input"
                  autoFocus
                />
              </div>
              <div className="h-64 max-h-64 overflow-y-auto">
                {filterItems.length > 0 &&
                  filterItems.map((item) => {
                    return (
                      <Menu.Item
                        key={`item-${item[`email${capitalize(type)}Id`]}`}
                      >
                        {({ active }) => (
                          <div className="flex justify-between hover:bg-grayscale-400 py-2 pl-4 pr-2 group">
                            <button
                              type="button"
                              className={'flex items-center space-x-2 w-11/12'}
                              key={item[`email${capitalize(type)}Id`]}
                              onClick={() => applyBlockToBody(item, type)}
                            >
                              <span className="truncate ...">
                                {item.isDefault && `(Default) `}
                                {item.name}
                              </span>
                            </button>
                            <MailBlockMenu
                              item={item}
                              type={type}
                              fetchItems={fetchItems}
                            />
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
              </div>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      items.length > 0 && `border-t border-gray-300`,
                      'flex justify-between items-center py-2 px-4'
                    )}
                  >
                    <div>
                      <button
                        onClick={() => onOpenTemplates('add')}
                        className="flex items-center space-x-2 w-full cursor-pointer text-custom-sky hover:text-custom-blue text-base font-bold"
                      >
                        <PlusIcon className="w-4 h-4 inline" />
                        &nbsp;Add {type}
                      </button>
                    </div>
                    <div>
                      <button onClick={() => onOpenTemplates('open')}>
                        <img src={menu} alt="Mail Templates" />
                      </button>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
export default MailBlockButton;
