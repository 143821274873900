export const STATUSES = [
  { label: 'First Call', value: 'first call' },
  { label: 'Second Call', value: 'second call' },
  { label: 'Third Call', value: 'third call' },
  { label: 'Fourth Call', value: 'fourth call' },
  { label: 'Quote', value: 'quote' },
  { label: 'Proposal', value: 'Proposal' },
  { label: 'No Show', value: 'No Show' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Lost', value: 'Lost' },
  // { label: 'Transfer to Operations', value: 'Transfer to Operations' },
];

export const STATUSES_PROSPECT = [
  { label: 'Email Pending', value: 'Email Pending' },
  { label: 'Email Sent', value: 'Email Sent' },
  { label: 'Email Follow-up 1', value: 'Email Follow-up 1' },
  { label: 'Email Follow-up 2', value: 'Email Follow-up 2' },
  { label: 'Email Follow-up 3', value: 'Email Follow-up 3' },
  { label: 'Email Follow-up 4', value: 'Email Follow-up 4' },
  { label: 'Email Follow-up 5', value: 'Email Follow-up 5' },
  { label: 'New Response', value: 'New Response' },
  { label: 'Lost', value: 'Lost' },
];

export const MARKETPLACE_KEY = [
  { key: '.ca', val: 'productCategoryCanada' },
  { key: '.com', val: 'productCategoryUsa' },
  { key: '.co.uk', val: 'productCategoryUk' },
  { key: '.co.jp', val: 'productCategoryJap' },
  { key: '.de', val: 'productCategoryDe' },
  { key: '.in', val: 'productCategoryIn' },
];
