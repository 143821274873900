import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListingMockupForm from './Mockup/ListingMockupForm';
import {
  fetchListingMockup,
  fetchRestrictedKeywords,
} from './Mockup/mockupSlice';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Mockup = () => {
  const dispatch = useDispatch();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { listingMockup, restrictedKeywords } = useSelector(
    (state) => state.mockup
  );

  useEffect(() => {
    dispatch(fetchListingMockup(agencyClient.agencyClientId));
  }, [agencyClient]);

  useEffect(() => {
    if (!restrictedKeywords) {
      dispatch(fetchRestrictedKeywords());
    }
  }, [restrictedKeywords]);

  return (
    <div className="flex flex-col space-y-8">
      <DndProvider backend={HTML5Backend}>
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-bold">Listing Mockups</h2>
        </div>
        <div>
          {listingMockup &&
            listingMockup.map((item, i) => (
              <ListingMockupForm key={i} item={item} />
            ))}
        </div>
      </DndProvider>
    </div>
  );
};
export default Mockup;
