import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import InputPrepend from 'components/Forms/InputPrepend';
import { debounce } from 'lodash';

const fields = [
  { name: 'Deal', type: 'deal' },
  { name: 'Contact Details', type: 'contactDetails' },
  { name: 'Business Information', type: 'businessInformation' },
];

const ColumnPicker = ({ tableColumns, setCards }) => {
  const [search, setSearch] = useState('');

  const onSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const onChangeCheckBox = (e) => {
    const i = tableColumns.findIndex((el) => el.dataField === e.target.id);
    const optionsCopy = [...tableColumns];
    optionsCopy[i].show = !optionsCopy[i].show;
    setCards(optionsCopy);
  };

  return (
    <div className="w-full flex-grow h-70vh overflow-y-auto">
      <p className="px-3 text-xl font-bold text-grayscale-900 pt-2">
        Available Columns
      </p>
      <div className="w-full px-3 pt-3">
        <InputPrepend
          name="search"
          onChange={(e) => onDebouncedSearch(e)}
          type="text"
          placeholder="Search..."
          prependText={<SearchIcon className="w-4 h-4" />}
          border="border-none"
          rounded="rounded-md"
          classes="text-base form-input"
          autoFocus
        />
      </div>
      <div className="w-full">
        {fields.map((f, i) => {
          return (
            <div className="pl-3 pt-3" key={i}>
              <p className="text-base font-bold text-grayscale-800">{f.name}</p>
              {tableColumns
                .filter((option) => option.hideable && option.type === f.type)
                .filter((option) =>
                  search
                    ? option.text.toLowerCase().includes(search.toLowerCase())
                    : true
                )
                .map((option, i) => {
                  return (
                    <div key={option.dataField}>
                      <input
                        id={option.dataField}
                        type="checkbox"
                        className="w-6 h-6 text-custom-blue bg-gray-100 border-gray-300 rounded focus:border-none shadow-none focus:ring-0"
                        checked={option.show}
                        onChange={onChangeCheckBox}
                      />

                      <label className="text-base font-normal text-grayscale-800 ml-3 w-full mt-2 cursor-pointer">
                        {option.text}
                      </label>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColumnPicker;
