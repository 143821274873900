import React, { useEffect, useState } from 'react';
import { Table } from 'components';
import Badge from 'components/Badge';
import { dateFormatter } from 'utils/formatters';
import { columnClasses, headerClasses } from 'utils/table';
import classnames from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TabNav from 'components/TabNav';
import { debounce, lowerCase } from 'lodash';
import InputPrepend from 'components/Forms/InputPrepend';
import {
  SearchIcon,
  CheckIcon,
  PencilIcon,
  MailIcon,
} from '@heroicons/react/outline';
import Select from 'components/Forms/Select';
import DatePicker from 'features/datePicker/DatePicker';
import { setAlert } from 'features/alerts/alertsSlice';
import ReactTooltip from 'react-tooltip';
import EmailCommissionsErrorModal from './EmailCommissionsErrorModal';
import Exporter from './Exporter';
import useQueryParams from 'hooks/useQueryParams';

const CommissionErrors = () => {
  const { params, updateParams } = useQueryParams({
    page: 1,
    pageSize: 30,
    sort: 'invoiceDate:desc',
    status: 'pending',
    search: '',
    fields: 'invoiceNumber',
  });
  const dispatch = useDispatch();

  const defaultSorted = [{ dataField: 'invoiceDate', order: 'desc' }];
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [errorDescriptions, setErrorDescriptions] = useState('');
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState();

  const load = async () => {
    setLoading(true);
    await axios.get(`/agency/invoices/errors`, { params }).then((res) => {
      setInvoices(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!loading) load();
  }, [params]);

  const [tabs, setTabs] = useState([
    { name: 'Pending', href: '#', count: '', current: true, visible: true },
    { name: 'Resolved', href: '#', count: '', current: false, visible: true },
  ]);

  const fieldOptions = [
    // { label: 'All', value: 'all' },
    // { label: 'Client Name', value: 'name' },
    { label: 'Invoice Number', value: 'invoiceNumber' },
    // { label: 'Invoice Date', value: 'invoiceDate' },
    // { label: 'Creation Date', value: 'createAt' },
    { label: 'Description', value: 'description' },
  ];

  const fieldDate = ['invoiceDate', 'createAt'];

  const tableColumns = [
    {
      dataField: 'account.name',
      text: 'Customer Name',
      sort: true,
      headerClasses,
      classes: `${columnClasses}`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            className="text-red-500 "
            to={`/clients/${row.account?.AgencyClient?.agencyClientId}/dashboard`}
          >
            {row.account?.name}
          </Link>
        );
      },
    },
    {
      dataField: 'account.AgencyClient.defaultContact.email',
      text: 'Client Email',
      headerClasses,
      classes: `${columnClasses}`,
      headerStyle: {
        minWidth: '250px',
      },
    },
    {
      dataField: 'invoiceNumber',
      text: 'Invoice Number',
      sort: true,
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => (
        <Link className="text-red-500 " to={`/invoices/${row.invoiceId}`}>
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'invoiceDate',
      text: 'Invoice Date',
      sort: true,
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => <span>{dateFormatter(cell)}</span>,
    },

    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerClasses,
      classes: `${columnClasses} uppercase`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell) => {
        return (
          <Badge
            color={classnames({
              yellow: cell === 'pending',
              green: cell === 'resolved',
            })}
            classes="uppercase"
            rounded="md"
          >
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      sort: true,
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => (
        <span>{dateFormatter(cell, 'DD MMM YYYY HH:MM A')}</span>
      ),
    },

    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      headerClasses,
      classes: `${columnClasses}`,
      headerStyle: {
        minWidth: '250px',
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <span className="flex justify-start items-center space-x-3">
            {row.status === 'pending' && (
              <button
                onClick={async () => {
                  await axios
                    .put(`/agency/invoices/errors/${row.invoiceErrorId}`, {
                      status: 'resolved',
                    })
                    .then((res) => {
                      dispatch(setAlert('success', res.data.message));
                      load();
                    })
                    .catch((error) => {
                      dispatch(setAlert('error', error.response.data.message));
                    });
                }}
              >
                <ReactTooltip
                  id="Mark As Resolved"
                  effect="solid"
                  className="shadow-sm rounded-lg opacity-100"
                />

                <CheckIcon
                  data-for="Mark As Resolved"
                  data-tip="Mark As Resolved"
                  className="h-6 w-6"
                  style={{ color: 'rgb(21 94 117)' }}
                />
              </button>
            )}

            <Link className="text-red-500 " to={`/invoices/${row.invoiceId}`}>
              <ReactTooltip
                id="Edit Invoice"
                effect="solid"
                className="shadow-sm rounded-lg opacity-100"
              />
              <PencilIcon
                data-for="Edit Invoice"
                data-tip="Edit Invoice"
                className="h-6 w-6"
                style={{ color: 'rgb(21 94 117)' }}
              />
            </Link>

            <button
              onClick={async () => {
                await axios
                  .get(`/agency/invoices/${row.invoiceId}`)
                  .then((res) => {
                    setInvoice(res.data.data.invoice);
                    setInvoice((prev) => ({
                      ...prev,
                      contactpersons: [
                        { ...prev.contactpersons[0], email: '' },
                      ],
                    })); //set To input fields empty so far
                    setErrorDescriptions(row.description);
                    setInvoiceId(row.invoiceId);
                  });

                setIsOpenEmailModal(true);
              }}
            >
              <ReactTooltip
                id="Email Commission Error"
                effect="solid"
                className="shadow-sm rounded-lg opacity-100"
              />
              <MailIcon
                data-for="Email Commission Error"
                data-tip="Email Commission Error"
                className="h-6 w-6 "
                style={{ color: 'rgb(21 94 117)' }}
              />
            </button>
          </span>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      ...params,
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };

  const onDebouncedSearch = debounce((e) => {
    updateParams({ search: e.target.value });
  }, 500);

  return (
    <>
      <div className="sm:grid grid-cols-7 mb-8 mt-2">
        <div className="col-span-4 flex items-center space-x-4">
          <h1 className="text-3xl tracking-wide font-bold">
            Commission Errors
          </h1>
          {fieldDate.includes(params.fields) ? (
            <div className="sm:col-span-3">
              <DatePicker position="left" />
            </div>
          ) : (
            <div className="sm:col-span-2">
              <InputPrepend
                name="search"
                defaultValue={params.search}
                onChange={(e) => onDebouncedSearch(e)}
                type="text"
                placeholder={'Search'}
                prependText={<SearchIcon className="w-4 h-4" />}
                border="border-white"
              />
            </div>
          )}
          <div className="sm:col-span-2 flex items-center justify-end">
            <Select
              name="field"
              className={`appearance-none px-3 py-2 border shadow-sm placeholder-gray-400 focus:ring-red-500 focus:border-red-500 `}
              onChange={(e) => {
                updateParams({ fields: e.target.value });
              }}
              value={params.fields}
            >
              {fieldOptions.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="col-span-3 flex justify-end items-center space-x-4">
          <Exporter params={params} />
        </div>
      </div>

      <TabNav
        tabs={tabs}
        setTabs={setTabs}
        onSelectChange={(e) => {
          updateParams({ status: lowerCase(e.target.value), page: 1 });
        }}
        onClick={(selectedTab) => {
          updateParams({ status: lowerCase(selectedTab.name), page: 1 });
        }}
        theme="tabbed"
      />
      <Table
        columns={tableColumns}
        data={invoices}
        onTableChange={onTableChange}
        params={params}
        keyField="invoiceErrorId"
        defaultSorted={defaultSorted}
        loading={loading}
      />
      <EmailCommissionsErrorModal
        invoice={invoice}
        open={isOpenEmailModal}
        setOpen={setIsOpenEmailModal}
        descriptions={errorDescriptions}
      />
    </>
  );
};
export default CommissionErrors;
