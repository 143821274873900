import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SpinnerGrow from './SpinnerGrow';
import classNames from 'utils/classNames';

const Confirm = ({
  open,
  setOpen,
  onOkClick,
  onCancelClick,
  title,
  content,
  align = 'center',
  okLoading = false,
  showOkLoading = false,
  okText = 'Ok',
  cancelText = 'Cancel',
  maxWidth = 'max-w-xl',
}) => {
  return (
    <Transition.Root appear show={open} as="div">
      <Dialog as="div" open={open} onClose={setOpen}>
        <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 80 }}>
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 pointer-events-none" />
            {align === 'center' && (
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
            )}

            <div
              className={classNames(
                maxWidth,
                `inline-block w-full text-center p-12 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-lg rounded-2xl`
              )}
            >
              <Dialog.Title
                as="h3"
                className="text-2xl font-bold font-inter leading-tight text-grayscale-900"
              >
                {title}
              </Dialog.Title>
              <div className="my-8">
                <div className="text-lg text-grayscale-700">{content}</div>
              </div>
              <div className="mt-4 flex items-center justify-center space-x-12">
                <button
                  type="button"
                  className="inline-flex justify-center text-base font-bold tracking-2 text-grayscale-700 hover:text-grayscale-900"
                  onClick={onCancelClick}
                >
                  {cancelText}
                </button>
                <button
                  type="button"
                  className={classNames(
                    okLoading && 'pointer-events-none',
                    'inline-flex items-center justify-center px-10 py-2 text-base font-bold tracking-2 text-white bg-custom-green border border-transparent rounded-full hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500'
                  )}
                  onClick={onOkClick}
                >
                  {okText}
                  {showOkLoading && okLoading && <SpinnerGrow color="white" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Confirm;
