import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, number, date, array, ref, min, boolean } from 'yup';
import {
  PlusIcon,
  TrashIcon as TrashIconOutline,
} from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import InputAppend from 'components/Forms/InputAppend';
import Label from 'components/Forms/Label';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import ButtonLink from 'components/ButtonLink';
import {
  COMMISSION_TYPES,
  DEFAULT_MARKETPLACE,
  TYPE_DESCRIPTIONS,
} from 'utils/constants';
import Toggle from 'components/Forms/Toggle';
import { isEmpty } from 'lodash';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { setAlert } from 'features/alerts/alertsSlice';
import usePermissions from 'hooks/usePermissions';
import OrderMetricsCalculator from './OrderMetricsCalculator';
import useAgencyClient from 'hooks/useAgencyClient';

const CommissionModal = ({ open, setOpen, payload, onReload }) => {
  const { t } = useTranslation();
  const { userCan } = usePermissions();
  const { agencyClient, account, hasSpApiCredentials } = useAgencyClient();
  const disabled = !userCan('clients.commission.update');
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commission, setCommission] = useState(null);
  const emptyInitialValues = {
    accountId: account.accountId,
    type: 'gross',
    marketplaceId: DEFAULT_MARKETPLACE,
    rate: 0,
    monthThreshold: 0,
    baseline: 0,
    managedAsins: [],
    rules: [],
    commence: false,
  };

  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const getCommission = async (id) => {
    setLoading(true);
    await axios.get(`/agency/commissions/${id}`).then((res) => {
      const data = res.data.output;

      setInitialValues({
        accountId: data.accountId,
        type: data.type,
        marketplaceId: data.marketplaceId,
        rate: data.rate,
        monthThreshold: data.monthThreshold,
        baseline: data.baseline,
        managedAsins: data.managedAsins ?? [],
        rules: data.rules ?? [],
        commence: data.commencedAt ? true : false,
      });

      setCommission(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (payload.action === 'edit') {
      getCommission(payload.commissionId);
    } else {
      setInitialValues(emptyInitialValues);
    }
  }, [payload]);

  const managedAsinObj = {
    asin: '',
    baseline: '',
    startDate: null,
    endDate: null,
  };

  const tieredRuleObj = {
    min: '',
    max: '',
    rate: '',
  };

  const validationSchema = object().shape({
    accountId: string().required('Required'),
    type: string().required('Type is required'),
    marketplaceId: string().required('Required'),
    rate: number().min(0).required('Required'),
    monthThreshold: number()
      .min(0)
      .nullable()
      .when('type', {
        is: (type) => ['benchmark', 'rolling'].includes(type),
        then: number().required('Required'),
      }),
    commence: boolean().required(),
    baseline: number().min(0).nullable(),
    managedAsins: array()
      .of(
        object().shape({
          asin: string()
            .trim()
            .matches(/([A-Z0-9]{10})/, 'Incorrect ASIN format')
            .required('Required'),
          baseline: number().nullable(),
          startDate: date().nullable(),
          endDate: date()
            .nullable()
            .when('startDate', {
              is: (startDate) => !!startDate,
              then: date()
                .min(ref('startDate'), 'End Date is earlier')
                .required('Required'),
            }),
        })
      )
      .nullable(),
    rules: array()
      .of(
        object().shape({
          min: number().min(0, 'minimum value is 0').required('Required'),
          max: number().min(ref('min'), 'must be >= min').nullable(true),
          rate: number().min(0).required('Required'),
        })
      )
      .nullable()
      .when('type', {
        is: 'tiered',
        then: array().required().min(1),
      }),
  });

  const onSubmit = async (values) => {
    try {
      setSaving(true);

      const response = await axios.post(
        `agency/commissions${
          payload.action === 'add' ? '' : `/${payload.commissionId}`
        }`,
        values
      );
      if (response.data.success) {
        dispatch(setAlert('success', `Commission ${payload.action}ed`));
        setSaving(false);
        onReload();
        setOpen(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  const onDelete = async () => {
    try {
      setSaving(true);
      const response = await axios.delete(
        `agency/commissions/${payload.commissionId}`
      );
      if (response.data.success) {
        dispatch(setAlert('success', `Commission Deleted`));
        setSaving(false);
        onReload();
        setOpen(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  const setBaseline = (value) => {
    console.log(value, 'baseline');
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-10"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4">
              <span>
                {payload.action === 'add' ? 'Add More' : 'Edit'} Commission Rate
              </span>
              {payload.action === 'edit' && !disabled && (
                <ButtonLink onClick={onDelete}>
                  <TrashIconOutline className="w-5 h-5 text-gray-500 hover:text-red-700" />
                </ButtonLink>
              )}
            </div>
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div
                className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto"
                style={{ height: '65vh' }}
              >
                <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
                  <span className="">
                    {t('Commission.AddToPendingInvoice')}
                  </span>
                  <Toggle
                    onChange={(e) => {
                      setFieldValue('commence', e);
                    }}
                    checked={values.commence}
                    disabled={disabled}
                  />
                </div>
                <div className="col-span-2 ">
                  <Label>Type</Label>
                  <Field
                    name="type"
                    as="select"
                    className="form-select disabled-white"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={disabled}
                  >
                    {COMMISSION_TYPES.map((commission_type, i) => (
                      <option key={i} value={commission_type.value}>
                        {commission_type.label}
                      </option>
                    ))}
                  </Field>
                  <sub className="text-xs indent-2 text-yellow-500 italic">
                    {TYPE_DESCRIPTIONS[values.type]}
                  </sub>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label>Marketplace</Label>
                  <Field
                    name="marketplaceId"
                    as="select"
                    className="form-select disabled-white"
                    disabled={disabled}
                  >
                    {marketplaces &&
                      marketplaces.map((marketplace) => (
                        <option
                          key={marketplace.marketplaceId}
                          value={marketplace.marketplaceId}
                        >
                          {marketplace.countryCode} - {marketplace.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="marketplaceId"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                {values.type !== 'tiered' && (
                  <div className="col-span-2">
                    <Label>Rate</Label>
                    <Field name="rate">
                      {({ field, form: { touched, errors }, meta }) => (
                        <>
                          <InputAppend
                            type="number"
                            placeholder="0"
                            appendText="%"
                            border="border-white"
                            classes="bg-gray-50 disabled-white"
                            {...field}
                            disabled={disabled}
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="rate"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
                {(values.type === 'benchmark' || values.type === 'rolling') && (
                  <div className="col-span-2">
                    <Label>Month Threshold</Label>
                    <Field
                      name="monthThreshold"
                      placeholder="No. of months"
                      className="form-input disabled-white"
                      onChange={(e) => handleChange(e)}
                      type="number"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="monthThreshold"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
                {values.type === 'benchmark' &&
                  values.managedAsins.length < 1 && (
                    <div className="col-span-2 relative">
                      <Label>Baseline</Label>
                      <Field
                        name="baseline"
                        placeholder="Enter Baseline Avg. Sales"
                        className="form-input disabled-white"
                        onChange={(e) => handleChange(e)}
                        type="number"
                        disabled={disabled}
                      />
                      <ErrorMessage
                        name="baseline"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                      {hasSpApiCredentials() && (
                        <div className="absolute inset-y-0 right-1 top-6 flex py-1.5 pr-1.5 ">
                          <OrderMetricsCalculator
                            commission={commission}
                            account={account}
                            field="baseline"
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      )}
                    </div>
                  )}

                {values.type === 'tiered' && (
                  <FieldArray name="rules">
                    {({ insert, remove, push }) => (
                      <>
                        <div className="col-span-2">
                          <hr className="my-2" />
                        </div>

                        {values.rules.length > 0 && (
                          <div className="col-span-2 space-y-3 mb-4">
                            <span className="font-inter font-bold text-base">
                              Rules
                            </span>
                            {values.rules.map((tieredRule, index) => (
                              <div
                                key={index}
                                className="sm:grid grid-cols-3 gap-2"
                              >
                                <div className="col-span-3 flex justify-between">
                                  <Label
                                    fontWeight="font-bold"
                                    textColor="text-gray-500"
                                  >
                                    Rule {index + 1}
                                  </Label>
                                  <span
                                    onClick={() => remove(index)}
                                    className="cursor-pointer text-gray-500 hover:text-red-500"
                                  >
                                    <TrashIcon className="w-4 h-4 " />
                                  </span>
                                </div>
                                <div className="col-span-1">
                                  <Label>Min. Gross</Label>
                                  <Field
                                    name={`rules.${index}.min`}
                                    placeholder="Enter Min. Gross"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="number"
                                    disabled={disabled}
                                  />
                                  <ErrorMessage
                                    name={`rules.${index}.min`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <Label>Max. Gross</Label>
                                  <Field
                                    name={`rules.${index}.max`}
                                    placeholder="Enter Max. Gross"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="number"
                                    disabled={disabled}
                                  />
                                  <ErrorMessage
                                    name={`rules.${index}.max`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <Label>Rate</Label>
                                  <Field name={`rules.${index}.rate`}>
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputAppend
                                          type="number"
                                          placeholder="0"
                                          appendText="%"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          {...field}
                                          disabled={disabled}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name={`rules.${index}.rate`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        {!disabled && (
                          <div className="col-span-2">
                            <Button
                              classes="font-bold tracking-wider w-full justify-center"
                              bgColor="gray-50"
                              hoverColor="gray-200"
                              roundedSize="2xl"
                              textColor="gray-600"
                              border="border-2 border-gray-600"
                              py={1}
                              shadow=""
                              onClick={(e) => {
                                push(tieredRuleObj);
                              }}
                            >
                              <PlusIcon className="w-4 h-4 inline" /> Add Rule
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </FieldArray>
                )}
                {values.type === 'tiered' && values.rules.length < 1 && (
                  <ErrorMessage
                    name={`rules`}
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                )}

                <div className="col-span-2">
                  <hr className="my-2" />
                </div>

                <FieldArray name="managedAsins">
                  {({ insert, remove, push }) => (
                    <>
                      {values.managedAsins?.length > 0 && (
                        <div className="col-span-2 space-y-3 mb-4">
                          <span className="font-inter font-bold text-base">
                            Managed ASIN
                          </span>
                          {values.managedAsins.map((managedAsin, index) => (
                            <div
                              key={index}
                              className="sm:grid grid-cols-3 gap-x-2 "
                            >
                              <div className="col-span-3 flex justify-between">
                                <Label
                                  fontWeight="font-bold"
                                  textColor="text-gray-500"
                                >
                                  ASIN {index + 1}
                                </Label>
                                {!disabled && (
                                  <span
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    className="cursor-pointer text-gray-500 hover:text-red-500"
                                  >
                                    <TrashIcon className="w-4 h-4 " />
                                  </span>
                                )}
                              </div>
                              <div className="col-span-3">
                                {values.type === 'benchmark' && (
                                  <Label>ASIN</Label>
                                )}
                                <Field
                                  name={`managedAsins.${index}.asin`}
                                  placeholder="Enter ASIN"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                />
                                <ErrorMessage
                                  name={`managedAsins.${index}.asin`}
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              {values.type === 'benchmark' && (
                                <>
                                  <div className="col-span-1">
                                    <Label>Start Date</Label>
                                    <Field
                                      name={`managedAsins.${index}.startDate`}
                                      placeholder="Select Date"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="date"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.startDate`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>End Date</Label>
                                    <Field
                                      name={`managedAsins.${index}.endDate`}
                                      placeholder="Select Date"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="date"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.endDate`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1 relative">
                                    <Label>Baseline</Label>
                                    <Field
                                      name={`managedAsins.${index}.baseline`}
                                      placeholder="Baseline Avg. Sales"
                                      className="form-input disabled-white"
                                      onChange={(e) => handleChange(e)}
                                      type="text"
                                      disabled={disabled}
                                    />
                                    <ErrorMessage
                                      name={`managedAsins.${index}.baseline`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                    {hasSpApiCredentials() && (
                                      <div className="absolute inset-y-0 right-0 top-6 flex py-1.5 pr-1.5 ">
                                        <OrderMetricsCalculator
                                          commission={commission}
                                          account={account}
                                          managedAsin={managedAsin}
                                          field={`managedAsins.${index}.baseline`}
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {!disabled && (
                        <div className="col-span-2">
                          <Button
                            classes="font-bold tracking-wider w-full justify-center"
                            bgColor="gray-50"
                            hoverColor="gray-200"
                            roundedSize="2xl"
                            textColor="gray-600"
                            border="border-2 border-gray-600"
                            py={1}
                            shadow=""
                            onClick={(e) => {
                              push(managedAsinObj);
                            }}
                          >
                            <PlusIcon className="w-4 h-4 inline" /> Add Managed
                            Asin
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      {disabled ? 'Close' : 'Cancel'}
                    </ButtonLink>
                    {!disabled && (
                      <Button
                        classes="border-0 font-bold tracking-wider "
                        bgColor="red-700"
                        hoverColor="red-400"
                        roundedSize="2xl"
                        textColor="white"
                        px={12}
                        py={1.5}
                        shadow=""
                        type="submit"
                        showLoading={true}
                        loading={saving}
                      >
                        {payload.action === 'add' ? 'Add' : 'Update'}
                      </Button>
                    )}
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CommissionModal;
