import axios from 'axios';
import { isObject, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import {
  AdjustmentsIcon,
  XIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';

import Modal from 'components/Modal';
import classNames from 'utils/classNames';

const SmartFilter = ({
  accountId,
  marketplace,
  campaignIds = [],
  portfolioIds = [],
  campaignTypes = [],
  productIds = [],
  onChange,
  buttonBg = 'white',
  only = ['campaignTypes', 'advPortfolioIds', 'advCampaignIds', 'productIds'],
}) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  const [selectedProductIds, setSelectedProductIds] = useState(productIds);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState(campaignIds);
  const [selectedCampaignTypes, setSelectedCampaignTypes] =
    useState(campaignTypes);
  const [selectedPortfolioIds, setSelectedPortfolioIds] =
    useState(portfolioIds);

  const campaignTypeOptions = [
    'sponsoredProducts',
    'sponsoredBrands',
    'sponsoredDisplay',
  ];

  useEffect(() => {
    let isSubscribed = true;

    let params = { accountId, marketplace, sort: 'name:asc', pageSize: 10000 };

    if (selectedCampaignTypes.length) {
      params.campaignTypes = selectedCampaignTypes;
    }

    const fetchData = async () => {
      const portfoliosResponse = await axios.get(
        '/account/advertising/portfolios',
        { params }
      );

      if (isSubscribed) {
        setSelectedPortfolioIds([]);
        setPortfolios(portfoliosResponse.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, selectedCampaignTypes]);

  useEffect(() => {
    let isSubscribed = true;
    let params = { accountId, marketplace, sort: 'name:asc', pageSize: 10000 };

    if (selectedCampaignTypes.length) {
      params.campaignTypes = selectedCampaignTypes;
    }

    if (selectedPortfolioIds.length) {
      params.advPortfolioIds = selectedPortfolioIds;
    }

    const fetchData = async () => {
      const campaignsResponse = await axios.get(
        '/account/advertising/campaigns',
        { params }
      );

      if (isSubscribed) {
        setSelectedCampaignIds([]);
        setCampaigns(campaignsResponse.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, selectedCampaignTypes, selectedPortfolioIds]);

  useEffect(() => {
    let isSubscribed = true;

    let params = {
      accountId,
      marketplace,
      sort: 'asin:asc',
      pageSize: 10000,
      attributes: ['productId', 'asin'],
    };

    if (selectedCampaignTypes.length) {
      params.campaignTypes = selectedCampaignTypes;
    }

    if (selectedPortfolioIds.length) {
      params.advPortfolioIds = selectedPortfolioIds;
    }

    if (selectedCampaignIds.length) {
      params.advCampaignIds = selectedCampaignIds;
    }

    const fetchData = async () => {
      const productsResponse = await axios.get('/account/products', {
        params,
      });

      if (isSubscribed) {
        setSelectedProductIds([]);
        setProducts(productsResponse.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    accountId,
    marketplace,
    selectedCampaignTypes,
    selectedPortfolioIds,
    selectedCampaignIds,
  ]);

  const onChangeFilterItem = (category, e) => {
    const { checked, value } = e.target;
    const newSelected = checked
      ? [...category.selected, value]
      : [...category.selected].filter((i) => i !== value);

    category.onChange(newSelected);
  };

  const onCancel = () => {
    setSelectedCampaignIds(campaignIds);
    setSelectedCampaignTypes(campaignTypes);
    setSelectedPortfolioIds(portfolioIds);
    setSelectedProductIds(productIds);
    setOpen(false);
  };

  const onApplyFilter = () => {
    onChange({
      advCampaignIds: selectedCampaignIds,
      advPortfolioIds: selectedPortfolioIds,
      campaignTypes: selectedCampaignTypes,
      productIds: selectedProductIds,
    });
    setOpen(false);
  };

  return (
    <div className="">
      <Modal open={open} setOpen={setOpen} as={'div'} align="top">
        <div
          className={classNames(
            'inline-block w-96 min-h-fit my-8 text-left align-middle transition-all transform shadow-xl rounded-2xl py-6 px-7 bg-white'
          )}
        >
          <div className="flex justify-between items-center">
            <p className="font-bold text-grayscale-900 font-inter tracking-3/4">
              Smart Filters
            </p>
            <button
              type="button"
              className="rounded-md text-grayscale-700 hover:text-grayscale-800 focus:outline-none"
              onClick={() => setOpen(false)}
            >
              <XIcon className="h-6 w-6 " aria-hidden="true" />
            </button>
          </div>

          <div>
            {[
              {
                key: 'campaignTypes',
                display: 'Campaign Type',
                options: campaignTypeOptions,
                selected: selectedCampaignTypes,
                onChange: (value) => setSelectedCampaignTypes(value),
              },
              {
                key: 'advPortfolioIds',
                display: 'Portfolios',
                options: portfolios,
                id: 'advPortfolioId',
                selected: selectedPortfolioIds,
                onChange: (value) => setSelectedPortfolioIds(value),
                displayKey: 'name',
              },
              {
                key: 'advCampaignIds',
                display: 'Campaigns',
                options: campaigns,
                id: 'advCampaignId',
                selected: selectedCampaignIds,
                onChange: (value) => setSelectedCampaignIds(value),
                displayKey: 'name',
              },
              {
                key: 'productIds',
                display: 'Products',
                options: products,
                id: 'productId',
                selected: selectedProductIds,
                displayKey: 'asin',
                onChange: (value) => setSelectedProductIds(value),
              },
            ]
              .filter((category) => only.includes(category.key))
              .map((category) => (
                <Disclosure key={`analytics-smart-filters-${category.key}`}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="mt-8 flex w-full justify-between font-bold">
                        <span className="text-grayscale-800">
                          {category.display} ({category.selected.length} /{' '}
                          {category.options.length})
                        </span>
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-6 w-6 text-grayscale-700`}
                        />
                      </Disclosure.Button>

                      <Disclosure.Panel
                        key={`analytics-smart-filters-${category.key}-main`}
                        className="flex justify-end text-mini py-2 text-grayscale-800"
                      >
                        <p
                          className="uppercase text-mini text-grayscale-800 cursor-pointer"
                          onClick={() => {
                            category.selected.length
                              ? category.onChange([])
                              : category.onChange(
                                  category.options.map((option) =>
                                    isObject(option)
                                      ? option[category.id]
                                      : option
                                  )
                                );
                          }}
                        >
                          {category.selected.length
                            ? 'Unselect All'
                            : 'Select All'}
                        </p>
                      </Disclosure.Panel>

                      <div className="max-h-60 overflow-y-scroll pr-1">
                        {category.options.map((option) => {
                          const optionValue = isObject(option)
                            ? option[category.id]
                            : option;
                          const optionDisplay = isObject(option)
                            ? option[category.displayKey]
                            : startCase(option);
                          return (
                            <Disclosure.Panel
                              key={`analytics-smart-filters-${category.key}-${optionValue}`}
                              className="flex justify-between text-mini pt-4 text-grayscale-800"
                            >
                              {optionDisplay}
                              <input
                                value={optionValue}
                                type="checkbox"
                                className="text-secondary rounded-full w-6 h-6 bg-grayscale-500 focus:border-0 focus:ring-0"
                                checked={category.selected.includes(
                                  optionValue
                                )}
                                onChange={(e) =>
                                  onChangeFilterItem(category, e)
                                }
                              />
                            </Disclosure.Panel>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Disclosure>
              ))}
          </div>

          <div className="mt-8 flex justify-between">
            <button
              onClick={onCancel}
              className="text-sm font-bold tracking-2 text-secondary-light"
            >
              Cancel
            </button>
            <button
              onClick={onApplyFilter}
              className="text-sm font-bold tracking-2 bg-secondary text-grayscale-300 rounded-3xl px-8 py-2"
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>

      <button
        onClick={() => setOpen(true)}
        className={classNames(
          'w-full whitespace-nowrap relative flex justify-around items-center rounded-2xl border-0 shadow-xs px-6 py-4 text-mini text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none',
          `bg-${buttonBg}`
        )}
      >
        <AdjustmentsIcon className="h-4 w-4 mr-6" />
        Smart Filter
      </button>
    </div>
  );
};

export default SmartFilter;
