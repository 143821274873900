import axios from 'axios';
import crypto from 'crypto';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';

import { setAlert } from 'features/alerts/alertsSlice';
import { getMyselfAsync } from 'features/auth/authSlice';

import useLoggedInUser from 'hooks/useLoggedInUser';

import hubstaffLogo from 'assets/images/hubstaff.svg';

const HUBSTAFF = 'hubstaff';

const HubstaffConnect = ({ location, history }) => {
  const dispatch = useDispatch();
  const { user } = useLoggedInUser();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code && state === HUBSTAFF) {
      setLoading(true);

      axios
        .post('/hubstaff/authorize', { code })
        .then(() => {
          dispatch(getMyselfAsync());
          dispatch(setAlert('success', 'Hubstaff Integration Successful'));
          history.push('/user-settings/hubstaff');
        })
        .catch(() => {
          dispatch(setAlert('error', 'Failed to connect to Hubstaff'));
        })
        .finally(() => setLoading(false));
    }
  }, [code, state]);

  const onClick = () => {
    axios.get('/hubstaff/configuration').then((response) => {
      const { authorization_endpoint, scopes_supported } = response.data.data;

      const query = {
        state: HUBSTAFF,
        response_type: 'code',
        scope: scopes_supported.join(' '),
        nonce: crypto.randomBytes(16).toString('base64'),
        client_id: process.env.REACT_APP_HUBSTAFF_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_HUBSTAFF_REDIRECT_URL,
      };

      const queryString = new URLSearchParams(query).toString();
      window.open(`${authorization_endpoint}?${queryString}`, '_self');
    });
  };

  return (
    <div className="flex justify-center">
      <div>
        <img className="object-cover h-28 w-64" src={hubstaffLogo} />

        <Button
          showLoading={true}
          loading={loading}
          onClick={onClick}
          disabled={!!user.hubstaffProfile}
          classes="w-full text-center font-inter text-center justify-center font-bold tracking-3/4"
          textSize="13"
        >
          Connect my Hubstaff Account
        </Button>
      </div>
    </div>
  );
};

export default withRouter(HubstaffConnect);
