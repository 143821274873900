import { useSelector } from 'react-redux';
import { ChartBarIcon, BriefcaseIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

const useSalesMenu = () => {
  const { userCan } = usePermissions();

  const { paginationParams: spp } = useSelector((state) => state.sales);

  return {
    name: 'Sales',
    href: '/sales',
    icon: '#sales',
    iconType: 'svg',
    visible: () => userCan('sales.profiles.list'),
    query: () => {
      return {
        page: spp.page ?? 1,
        status: spp.status ?? 'booked',
        pageSize: spp.pageSize ?? 30,
        sort: spp.sort ?? 'createdAt:asc',
      };
    },
    children: [
      {
        name: 'My Workplace',
        href: '/sales/workplace',
        icon: BriefcaseIcon,
        visible: () => userCan('sales.profiles.list'),
      },
      {
        name: 'Deals',
        href: '/sales/deals',
        icon: BriefcaseIcon,
        visible: () => userCan('sales.profiles.list'),
      },
      {
        name: 'Metrics',
        href: '/sales-metrics',
        icon: ChartBarIcon,
        visible: () => userCan('sales.metrics'),
      },
    ],
  };
};
export default useSalesMenu;
