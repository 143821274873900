import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useAlert from 'hooks/useAlert';
import useBodyClass from 'hooks/useBodyClass';
import usePermissions from 'hooks/usePermissions';

import Button from 'components/Button';
import UpdatesToggle from 'components/Updates/UpdatesToggle';

import Updates from './Dashboard/Updates';
import NewClientForm from './NewClient/NewClientForm';
import { ConfirmationModal } from 'components';

import { setShowUpdatesSection } from './agencyClientSlice';
import { fetchPlans, fetchAddons } from 'features/zoho/zohoSlice';
import { fetchSalesEmployees } from 'features/employees/employeesSlice';
import { fetchMarketplaces } from 'features/marketplaces/marketplacesSlice';

const NewClientEdit = () => {
  useBodyClass(['client-profile', 'agency-client']);

  const { agencyClientId } = useParams();
  const history = useHistory();
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();
  const { sales } = useSelector((state) => state.employees);
  const { plans, addons } = useSelector((state) => state.zoho);
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const { showUpdatesSection } = useSelector((state) => state.agencyClient);

  const [client, setClient] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [isOpenTerminate, setIsOpenTerminate] = useState(false);

  const getClient = async () => {
    const response = await axios.get(`/agency/clients/${agencyClientId}`);
    if (response.data.success) {
      setClient(response.data.data.data);
    }
  };

  useEffect(() => {
    getClient();
  }, [agencyClientId]);

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans());
    }
  }, [plans, dispatch]);

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons());
    }
  }, [addons, dispatch]);

  useEffect(() => {
    if (!sales) {
      dispatch(fetchSalesEmployees());
    }
  }, [sales]);

  useEffect(() => {
    if (marketplaces.length <= 0) {
      dispatch(fetchMarketplaces());
    }
  }, [marketplaces]);

  const onDelete = async () => {
    try {
      setDeleting(true);
      const response = await axios.delete(`/agency/clients/${agencyClientId}`);
      alertSuccess('Client Destroyed', response.data.message);
      history.push('/clients');
    } catch (error) {
      alertError('Cannot delete client', error.response.data.message);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <div className="xl:flex items-center justify-between">
        <h1 className="text-3xl tracking-wide font-bold">
          Update Client: <span className="text-blue-700">{client?.client}</span>
        </h1>

        <div>
          {userCan('clients.delete') && (
            <Button
              classes="border-0 font-bold tracking-wider mr-12 "
              bgColor="red-500"
              hoverColor="red-600"
              roundedSize="2xl"
              textColor="white"
              px={6}
              py={1}
              shadow=""
              type="button"
              showLoading={true}
              loading={deleting}
              onClick={() => {
                setIsOpenTerminate(true);
              }}
            >
              Delete
            </Button>
          )}
        </div>
        <ConfirmationModal
          title="Confirm Delete Client"
          content={`Are you sure you want to delete this client? `}
          open={isOpenTerminate}
          setOpen={setIsOpenTerminate}
          onOkClick={onDelete}
          onCancelClick={() => setIsOpenTerminate(false)}
          okLoading={deleting}
          showOkLoading={true}
        />
      </div>

      <div className="flex space-x-8 mt-4 mb-10 border-b">&nbsp;</div>

      <div className="lg:grid grid-cols-5">
        <div className="col-span-5">
          <div className="tablinks flex">
            <a className="capitalize py-2 w-32 border-t border-l border-r rounded-t-md text-center current left">
              Profile{' '}
              <span className="ml-1 bg-yellow-50 text-yellow-700 text-xs p-0.5">
                {client?.status}
              </span>
            </a>
          </div>
        </div>
        <div
          className={`${
            showUpdatesSection ? 'client-content' : 'col-span-5'
          } container-fixed bg-white`}
        >
          <div className="px-8 pt-8 pb-16">
            <NewClientForm action="edit" payload={client} />
          </div>
        </div>

        {showUpdatesSection ? (
          <div className="client-updates container-fixed bg-gray-50 border-l border-gray-100">
            <Updates
              setVisible={(value) => dispatch(setShowUpdatesSection(value))}
            />
          </div>
        ) : (
          <UpdatesToggle
            onToggle={(value) => dispatch(setShowUpdatesSection(value))}
          />
        )}
      </div>
    </>
  );
};

export default NewClientEdit;
