import { useSelector } from 'react-redux';
import { BriefcaseIcon, BanIcon } from '@heroicons/react/outline';

import usePermissions from 'hooks/usePermissions';

const useWritingMenu = () => {
  const { userCan } = usePermissions();
  const { pods } = useSelector((state) => state.organization);

  return {
    name: 'Writing',
    href: '/writing',
    icon: '#writing',
    iconType: 'svg',
    visible: () =>
      userCan(
        'writing.workspace.all|writing.workspace.team|writing.workspace.mine|writing.metrics.all|writing.metrics.team|writing.metrics.mine|writing.pending.copy_writing.view|writing.pending.keyword_research.view|writing.pending.product_research.view'
      ),
    children: [
      {
        name: 'My WorkSpace',
        href: `/writing/workspace/my`,
        icon: BriefcaseIcon,
        visible: () => userCan('writing.workspace.mine'),
      },
      {
        name: 'Team WorkSpace',
        href: `/writing/workspace/team`,
        icon: BriefcaseIcon,
        visible: () => userCan('writing.workspace.team'),
      },
      ...pods
        .filter((pod) => pod.type === 'writing')
        .map((pod) => {
          return {
            name: pod.name,
            href: `/writing/teams/${pod.podId}/summary`,
            icon: BriefcaseIcon,
            visible: () => userCan('writing.workspace.all'),
          };
        }),
      {
        name: 'Metrics',
        href: `/writing/analytics`,
        icon: BriefcaseIcon,
        visible: () =>
          userCan(
            'writing.metrics.all|writing.metrics.team|writing.metrics.mine'
          ),
      },
      {
        name: 'Pending',
        href: `/writing/open`,
        icon: BriefcaseIcon,
        visible: () =>
          userCan(
            'writing.pending.copy_writing.view|writing.pending.keyword_research.view|writing.pending.product_research.view'
          ),
      },
      {
        name: 'Restricted Keywords',
        href: `/writing/restricted-keywords`,
        icon: BanIcon,
        visible: () => true,
      },
    ],
  };
};
export default useWritingMenu;
