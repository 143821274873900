import axios from 'axios';
import { isNumber, pick, reduce } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  NavLink,
  useParams,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';

import RuleAction from '../components/forms/RuleAction/RuleAction';
import CustomTarget from '../components/forms/CustomTarget/CustomTarget';
import FilterConditionPicker from '../components/forms/FilterCondition/FilterConditionPicker';

import { userCan } from 'utils/permission';
import metrics from '../../../../utils/metrics';

import {
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL_TO,
  KEYWORDS,
} from 'features/advertising/utils/constants';

const EditRule = ({ history, accountId, marketplace, campaignType }) => {
  const { url } = useRouteMatch();
  const { ruleId } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState({
    accountId,
    name: '',
    campaignType,
    recordType: KEYWORDS,
    default: false,
    advCampaignIds: [],
    advPortfolioIds: [],
    products: [],
    filters: [
      {
        value: '',
        key: 'impressions',
        attribute: 'impressions',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'cost',
        attribute: 'cost',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'clicks',
        attribute: 'clicks',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'profit',
        attribute: 'profit',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        key: 'sales',
        attribute: 'sales',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        value: '',
        selected: false,
      },
      {
        value: '',
        key: 'orders',
        attribute: 'orders',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'cpc',
        attribute: 'cpc',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'cr',
        attribute: 'cr',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        value: '',
        key: 'ctr',
        attribute: 'ctr',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
      },
      {
        key: 'acos',
        attribute: 'acos',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        value: '',
        selected: false,
      },
      {
        value: '',
        key: 'bid',
        attribute: 'bid',
        title: 'Bid',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
        recordTypes: [KEYWORDS],
      },
      {
        value: '',
        key: 'bidUpdatedAtInDays',
        attribute: 'bidUpdatedAtInDays',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        selected: false,
        recordTypes: [KEYWORDS],
        comparisons: [GREATER_THAN_OR_EQUAL_TO, GREATER_THAN],
      },
      {
        attribute: 'bid',
        key: 'bidToCpc',
        title: 'Bid to Cost per click',
        comparison: GREATER_THAN_OR_EQUAL_TO,
        value: 'cpc',
        selected: false,
        recordTypes: [KEYWORDS],
        comparisons: [GREATER_THAN_OR_EQUAL_TO, GREATER_THAN],
      },
    ],
    actionCode: '',
    actionData: {},
  });

  useEffect(() => {
    axios
      .get(`/account/advertising/rules/${ruleId}`, {
        params: {
          accountId,
          marketplace,
          include: ['action', 'campaigns', 'portfolios', 'products'],
        },
      })
      .then((response) => {
        const { data } = response.data;
        setForm({
          ...form,
          name: data.name,
          campaignType: data.campaignType,
          recordType: data.recordType,
          default: data.default,
          advCampaignIds: data.campaigns.map((c) => c.advCampaignId),
          advPortfolioIds: data.portfolios.map((p) => p.advPortfolioId),
          products: data.products.map((p) => p.listingId),
          actionCode: data.action.code,
          actionData: data.actionData,
          filters: form.filters.map((item) => {
            const ref = data.filters.find((f) => {
              let bool = f.attribute === item.attribute;
              if (item.attribute === 'bid' && f.attribute === 'bid') {
                bool = bool && item.value === 'cpc' ? f.value === 'cpc' : true;
              }
              return bool;
            });

            if (ref) {
              if (ref.value === 'cpc') {
                if (item.value === 'cpc') {
                  return {
                    ...item,
                    selected: true,
                    value: ref.value,
                    comparison: ref.comparison,
                  };
                }
              } else {
                return {
                  ...item,
                  selected: true,
                  value: ref.value,
                  comparison: ref.comparison,
                };
              }
            }

            return item;
          }),
        });
      });
  }, [accountId, marketplace, ruleId]);

  const updateFilterItem = (item) => {
    let newFilters = [...form.filters];
    const itemIndex = form.filters.findIndex((i) => i.key === item.key);
    newFilters[itemIndex] = item;
    setForm({ ...form, filters: newFilters });
  };

  const onChangeRecordType = (e) => {
    let newForm = {
      ...form,
      actionCode: '',
      actionData: {},
      recordType: e.target.value,
      filters: form.filters.map((filter) => {
        if (
          filter.recordTypes &&
          filter.recordTypes.includes(form.recordType)
        ) {
          filter.selected = false;
        }
        return filter;
      }),
    };

    setForm(newForm);
  };

  const submit = () => {
    if (!userCan(user, 'ppc.rule.update')) return;
    setSubmitting(true);

    let payload = { ...form };
    payload.marketplace = marketplace;
    payload.filters = payload.filters
      .filter((filter) => filter.selected)
      .filter((filter) => {
        return filter.recordTypes
          ? filter.recordTypes.includes(form.recordType)
          : true;
      })
      .map((filter) => {
        return pick(filter, ['attribute', 'comparison', 'value']);
      });

    axios
      .put(`/account/advertising/rules/${ruleId}`, payload)
      .then(() => {
        setSubmitting(false);
        history.push(url.replace(`/${ruleId}`, ''));
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        setSubmitting(false);
      });
  };

  return (
    <div className="bg-white px-5 pt-5 pb-24">
      <p className="text-grayscale-800 font-inter tracking-2 font-bold">
        Edit Rule
      </p>

      <div className="mt-4">
        <p className="text-grayscale-800 font-inter text-sm tracking-3/4 font-bold">
          Settings
        </p>
        <div className="grid grid-cols-2 gap-8">
          <div className="mt-4">
            <label
              htmlFor="name"
              className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
            >
              Rule Name
            </label>
            <div className="mt-1">
              <input
                id="name"
                type="text"
                name="name"
                className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
              {errors.name && (
                <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
                  {errors.name}
                </p>
              )}
            </div>
          </div>

          <div className="mt-4">
            <label
              htmlFor="name"
              className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
            >
              Record Type
            </label>
            <div className="mt-1">
              <select
                onChange={onChangeRecordType}
                value={form.recordType}
                className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
              >
                <option value="campaigns">Campaigns</option>
                <option value="keywords">Keywords</option>
                <option value="searchTerms">Search Terms</option>
              </select>
            </div>
          </div>
        </div>

        <RuleAction
          campaignType={campaignType}
          recordType={form.recordType}
          value={form.actionCode}
          data={form.actionData}
          onChangeValue={(actionCode) => {
            setForm({ ...form, actionCode });
          }}
          onChangeData={(actionData) => {
            setForm({ ...form, actionData });
          }}
          errors={errors}
        />
      </div>

      <CustomTarget
        accountId={accountId}
        marketplace={marketplace}
        campaignType={campaignType}
        campaignIds={form.advCampaignIds}
        portfolioIds={form.advPortfolioIds}
        listingIds={form.products}
        onChange={(key, values) => setForm({ ...form, [key]: values })}
      />

      <FilterConditionPicker
        errors={errors}
        filters={form.filters
          .map((item) => {
            return {
              ...item,
              title:
                item.title ??
                metrics.find((m) => m.key === item.attribute).title,
            };
          })
          .filter((item) =>
            item.recordTypes ? item.recordTypes.includes(form.recordType) : true
          )}
        updateFilterItem={updateFilterItem}
      />

      <div className="flex justify-between mt-8">
        <NavLink
          to={url.replace(`/${ruleId}`, '')}
          className="tracking-2 font-bold leading-normal text-secondary-light rounded-3xl px-8 py-2"
        >
          Cancel
        </NavLink>

        <button
          type="submit"
          onClick={submit}
          disabled={submitting || !userCan(user, 'ppc.rule.update')}
          className="rounded-3xl border border-gray-300 bs-shadow px-8 py-2 bg-secondary hover:bg-secondary-light text-sm font-medium text-grayscale-300 tracking-2 font-bold hover:bg-gray-50 focus:outline-none "
        >
          {submitting ? 'Updating' : 'Update'}
        </button>
      </div>
    </div>
  );
};

export default withRouter(EditRule);
