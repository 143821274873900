import moment from 'moment';
import {
  XAxis,
  YAxis,
  Bar,
  BarChart,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { numberFormatter, percentageFormatter } from 'utils/formatters';

const TotalMonthlyOrders = ({ data, performance }) => {
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height } = props;

    return props.value ? (
      <g style={{ paddingTop: '10px' }}>
        <text fontSize={'8px'} x={x + width / 2 - 10} y={y - 3}>
          {props.value}
        </text>
      </g>
    ) : null;
  };

  return (
    <div className="my-8 px-8">
      <p className="text-lg font-bold text-grayscale-900 tracking-3/4">
        Total Orders
      </p>

      <div className="grid grid-cols-12 gap-4 h-64">
        <div className="col-span-7">
          <ResponsiveContainer height="100%" className="max-w-xl h-full mr-8">
            <BarChart
              data={data}
              margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
            >
              <CartesianGrid vertical={false} horizontal={false} />
              <XAxis
                dataKey="startDate"
                style={{ fontWeight: '400', fontSize: '8px', color: '#202020' }}
                tickSize={0}
                tickLine={false}
                axisLine={false}
                dy={12}
                tickCount={0}
                tickFormatter={(value) => moment(value).format('MMM')}
              />

              <YAxis tickCount={0} tickLine={false} hide={true} />
              <Bar dataKey="orders" fill="#ff89a6" maxBarSize={100}>
                <LabelList
                  content={renderCustomizedLabel}
                  dataKey="orders"
                  position="top"
                  style={{
                    fontWeight: '600',
                    color: '#acacac',
                    fontSize: '8px',
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="grid grid-cols-3 items-center col-span-5">
          <div className="flex flex-col col-span-1 justify-between items-end text-right w-full h-full py-5 mr-5">
            <p className="text-8 font-sourceSansPro font-bold ml-6 text-primary">
              Impressions
            </p>
            <p
              className="text-8 font-sourceSansPro font-bold whitespace-nowrap ml-6"
              style={{ color: '#ff89a6' }}
            >
              Clicked Through Rate
            </p>
            <p
              className="text-8 font-sourceSansPro font-bold"
              style={{ color: '#002f5d' }}
            >
              Clicks
            </p>
            <p
              className="text-8 font-sourceSansPro font-bold"
              style={{ color: '#1879d8' }}
            >
              Conversion Rate
            </p>
            <p
              className="text-8 font-sourceSansPro font-bold"
              style={{ color: '#00ba88' }}
            >
              PPC Orders
            </p>
          </div>
          <div className="flex flex-col col-span-2 justify-center items-center text-right w-full h-full ">
            <div className="flex h-1/5 justify-center w-full items-center">
              <div className="trapezoid1 h-full">
                <p className="text-white text-sm tracking-3/4 font-normal">
                  {numberFormatter(performance.impressions)}
                </p>
              </div>
            </div>

            <div className="flex h-1/5 justify-center w-full items-center">
              <div className="trapezoid2 h-full">
                <p className="text-white text-sm tracking-3/4 font-normal">
                  {percentageFormatter(performance.ctr)}
                </p>
              </div>
            </div>
            <div className="flex h-1/5 justify-center w-full items-center">
              <div className="trapezoid3 h-full">
                <p className="text-white text-sm tracking-3/4 font-normal">
                  {numberFormatter(performance.clicks)}
                </p>
              </div>
            </div>
            <div className="flex h-1/5 justify-center w-full items-center">
              <div className="trapezoid4 h-full">
                <p className="text-white text-sm tracking-3/4 font-normal">
                  {percentageFormatter(performance.cr)}
                </p>
              </div>
            </div>
            <div className="flex h-1/5 justify-center w-full items-center">
              <div className="trapezoid5 h-full">
                <p className="text-white text-sm tracking-3/4 font-normal">
                  {numberFormatter(performance.orders)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalMonthlyOrders;
