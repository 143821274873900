import { Fragment, useState } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

const ComputationTooltip = ({ title, computations = [] }) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  return (
    <Popover className="relative">
      <>
        <Popover.Button ref={setReferenceElement}>
          <QuestionMarkCircleIcon className="w-4 h-4" />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform w-48 bg-white"
          >
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-2 text-10 text-grayscale-800 font-inter font-normal">
                <p className="text-xs font-semibold text-grayscale-800">
                  {title}
                </p>

                <ul>
                  {computations.map((computation, idx) => (
                    <li
                      key={idx}
                      className="flex space-x-1 space-y-1 items-center"
                    >
                      <p>=</p>
                      <p>{computation}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};

export default ComputationTooltip;
