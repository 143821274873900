import React, { useState } from 'react';
import CustomizeColumnModal from './CustomizeColumnModal';
import Modal from 'components/Modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import doubleAdjustmentHorizontal from 'assets/icons/doubleAdjustmentHorizontal.svg';

const CustomizeColumnBtn = ({ tableColumns, setTableColumns }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="pb-2">
      <button
        className="text-grayscale-700 text-base font-bold hover:text-grayscale-800 flex"
        onClick={() => setOpen(true)}
      >
        <span className="flex flex-col justify-center pr-2 pt-1 hover:text-grayscale-800">
          <img src={doubleAdjustmentHorizontal} alt={``} className="w-4 h-4" />
        </span>
        Customize Column
      </button>

      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <DndProvider backend={HTML5Backend}>
          <CustomizeColumnModal
            setOpen={setOpen}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
          />
        </DndProvider>
      </Modal>
    </div>
  );
};

export default CustomizeColumnBtn;
