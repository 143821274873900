import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ReplyIcon } from '@heroicons/react/solid';
import { DocumentIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { Menu } from '@headlessui/react';
import DropdownMenu from 'components/DropdownMenu';
import usePermissions from 'hooks/usePermissions';
import useInvoice from 'hooks/useInvoice';
import { useEffect, useState } from 'react';
import EmailInvoiceModal from './components/EmailInvoiceModal';
import useAlert from 'hooks/useAlert';
import reminderSvg from './icons/reminder.svg';
import mailSvg from './icons/mail.svg';
import downloadSvg from './icons/download.svg';
import chargeSvg from './icons/charge.svg';
import invoiceurlSvg from './icons/invoiceurl.svg';
import recordpaymentSvg from './icons/recordpayment.svg';
import voidSvg from './icons/void.svg';
import writeoffSvg from './icons/writeoff.svg';
import editSvg from './icons/edit.svg';
import { ConfirmationModal } from 'components';
import RecordPaymentModal from './components/RecordPaymentModal';
import { fetchInvoice } from './invoiceSlice';
import EditItemsModal from './components/EditItemsModal';
import usePdf from 'hooks/usePdf';
import ApplyCreditsModal from './components/ApplyCreditsModal';

const Actions = () => {
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlert();
  const { invoice } = useSelector((state) => state.invoice);
  const { userCan, userCanAll } = usePermissions();
  const status = useInvoice(invoice);
  const [saving, setSaving] = useState(false);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isOpenApplyModal, setIsOpenApplyModal] = useState(false);
  const [isOpenVoid, setIsOpenVoid] = useState(false);
  const [isOpenConvertToOpen, setIsOpenConvertToOpen] = useState(false);
  const [isOpenCancelWriteOff, setIsOpenCancelWriteOff] = useState(false);
  const [isOpenWriteOff, setIsOpenWriteOff] = useState(false);
  const [isOpenCollect, setIsOpenCollect] = useState(false);
  const [emailType, setEmailType] = useState('');
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const { onDownloadPdf } = usePdf();
  const [openCreditNotes, setOpenCreditNotes] = useState(null);

  const menuButtonClasses = `bg-white flex items-center text-sm text-gray-600 hover:text-red-500 relative`;

  useEffect(() => {
    if (
      invoice &&
      status.actionEnabled() &&
      userCan('creditNotes.apply_to_invoice')
    ) {
      getOpenCreditNotes();
    }
  }, [invoice]);

  const getOpenCreditNotes = async () => {
    await axios
      .get(`/agency/credit-notes/applicable/${invoice.customer_id}`)
      .then((res) =>
        res.data.data
          ? setOpenCreditNotes(res.data.data)
          : setOpenCreditNotes(null)
      );
  };

  const onCollectCharge = async () => {
    try {
      setSaving(true);
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/collect`
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenCollect(false);
      setSaving(false);
    }
  };

  const voidInvoice = async () => {
    setSaving(true);
    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/void`
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenVoid(false);
      setSaving(false);
    }
  };

  const convertToOpenInvoice = async () => {
    setSaving(true);
    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/converttoopen`
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenConvertToOpen(false);
      setSaving(false);
    }
  };

  const writeOffInvoice = async () => {
    setSaving(true);
    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/writeoff`
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenWriteOff(false);
      setSaving(false);
    }
  };

  const cancelWriteOffInvoice = async () => {
    setSaving(true);
    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/cancelwriteoff`
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenCancelWriteOff(false);
      setSaving(false);
    }
  };

  return (
    <>
      <EmailInvoiceModal
        invoice={invoice}
        open={isOpenEmail}
        setOpen={setIsOpenEmail}
        type={emailType}
      />
      <ConfirmationModal
        title="Charge customer"
        content="Are you sure you want to charge payment for this invoice?"
        open={isOpenCollect}
        setOpen={setIsOpenCollect}
        onOkClick={onCollectCharge}
        onCancelClick={() => setIsOpenCollect(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <RecordPaymentModal
        invoice={invoice}
        open={isOpenPaymentModal}
        setOpen={setIsOpenPaymentModal}
      />
      <ConfirmationModal
        title="Void Invoice"
        content="Are you sure you want to void this invoice?"
        open={isOpenVoid}
        setOpen={setIsOpenVoid}
        onOkClick={voidInvoice}
        onCancelClick={() => setIsOpenVoid(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Convert To Open Invoice"
        content="Are you sure you want to undo void for this invoice?"
        open={isOpenConvertToOpen}
        setOpen={setIsOpenConvertToOpen}
        onOkClick={convertToOpenInvoice}
        onCancelClick={() => setIsOpenConvertToOpen(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Write Off Invoice"
        content="Are you sure you want to write off this invoice?"
        open={isOpenWriteOff}
        setOpen={setIsOpenWriteOff}
        onOkClick={writeOffInvoice}
        onCancelClick={() => setIsOpenWriteOff(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Cancel Write Off Invoice"
        content="Are you sure you want to cancel write off for this invoice?"
        open={isOpenCancelWriteOff}
        setOpen={setIsOpenCancelWriteOff}
        onOkClick={cancelWriteOffInvoice}
        onCancelClick={() => setIsOpenCancelWriteOff(false)}
        okLoading={saving}
        showOkLoading={true}
      />
      <EditItemsModal
        invoice={invoice}
        open={isOpenEditModal}
        setOpen={setIsOpenEditModal}
      />
      <ApplyCreditsModal
        invoice={invoice}
        openCreditNotes={openCreditNotes}
        open={isOpenApplyModal}
        setOpen={setIsOpenApplyModal}
      />

      <div className="flex space-x-6 py-2 justify-between xl:justify-start">
        {status.actionEnabled() && userCan('invoices.invoice_reminder') && (
          <DropdownMenu
            title={
              <>
                <ReactTooltip
                  id="reminder"
                  effect="solid"
                  type="light"
                  className="shadow-lg rounded-lg opacity-100"
                />
                <img
                  data-for="reminder"
                  data-tip="Reminder"
                  src={reminderSvg}
                />
              </>
            }
            titleClasses="relative"
            buttonBg="bg-transparent"
            buttonFontWeight="font-normal"
            hoverClasses="bg-none"
            textColor="text-cyan-600"
            classes="text-sm top-1"
            buttonRounded=""
            hoverText="hover:text-cyan-500"
            dropdownWidth="w-48"
            padding="0px"
            position="left-0"
            hideArrow
            zIndex=""
          >
            <div className="px-1 py-1 flex flex-col">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setIsOpenEmail(true);
                      setEmailType('overdue');
                    }}
                    className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
                  >
                    Overdue Follow-Up
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setIsOpenEmail(true);
                      setEmailType('pastDue');
                    }}
                    className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
                  >
                    Past Due Date
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setIsOpenEmail(true);
                      setEmailType('invoiceReminder');
                    }}
                    className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
                  >
                    Invoice Reminder
                  </button>
                )}
              </Menu.Item>
            </div>
          </DropdownMenu>
        )}

        {userCan('invoices.email') && (
          <button
            className={menuButtonClasses}
            title="Send Email"
            onClick={() => setIsOpenEmail(true)}
            data-tip="Email"
            data-for="email"
          >
            <img src={mailSvg} />
            <ReactTooltip
              id="email"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
          </button>
        )}
        {status.actionEnabled() && userCan('invoices.collect') && (
          <button
            className={menuButtonClasses}
            onClick={() => setIsOpenCollect(true)}
            data-tip="Charge Customer"
            data-for="charge"
          >
            <img src={chargeSvg} />
            <ReactTooltip
              id="charge"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
          </button>
        )}
        {status.actionEnabled() && userCan('invoices.payment.add') && (
          <button
            className={menuButtonClasses}
            onClick={() => setIsOpenPaymentModal(true)}
            data-tip="Record Payment"
            data-for="record"
          >
            <img src={recordpaymentSvg} />
            <ReactTooltip
              id="record"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
          </button>
        )}

        {status.actionEnabled() &&
          userCan('creditNotes.apply_to_invoice') &&
          openCreditNotes &&
          openCreditNotes.length > 0 && (
            <button
              className={menuButtonClasses}
              onClick={() => setIsOpenApplyModal(true)}
              data-tip="Apply Credit Notes"
              data-for="apply"
            >
              <span className="flex relative">
                <DocumentIcon className="w-6 h-6 inline transform rotate-45" />
                <label className="absolute left-1/2 transform -translate-x-1/2 cursor-pointer text-xs font-bold leading-6">
                  $
                </label>
              </span>
              <ReactTooltip
                id="apply"
                type="light"
                effect="solid"
                className="shadow-lg rounded-lg opacity-100"
              />
            </button>
          )}
        {invoice.can_edit_items &&
          userCan('invoices.lineitem.add') &&
          status.actionEnabled() && (
            <button
              className={menuButtonClasses}
              onClick={() => setIsOpenEditModal(true)}
              data-tip="Edit Invoice"
              data-for="edit"
            >
              <img src={editSvg} />
              <ReactTooltip
                id="edit"
                type="light"
                effect="solid"
                className="shadow-lg rounded-lg opacity-100"
              />
            </button>
          )}

        {userCan('invoices.pdf.download') && (
          <button
            className={menuButtonClasses}
            title="Download PDF"
            onClick={() =>
              onDownloadPdf(
                `agency/invoice/${invoice.invoice_id}/pdf`,
                invoice.number
              )
            }
            data-tip="Download Invoice"
            data-for="download"
          >
            <img src={downloadSvg} />
            <ReactTooltip
              id="download"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
          </button>
        )}
        {status.actionEnabled() &&
          invoice.payment_made === 0 &&
          invoice.credits_applied === 0 &&
          userCan('invoices.void') && (
            <button
              className={menuButtonClasses}
              title="Void Invoice"
              onClick={() => setIsOpenVoid(true)}
              data-tip="Void Invoice"
              data-for="void"
            >
              <img src={voidSvg} />
              <ReactTooltip
                id="void"
                type="light"
                effect="solid"
                className="shadow-lg rounded-lg opacity-100"
              />
            </button>
          )}
        {status.voided() && userCan('invoices.void') && (
          <button
            className={menuButtonClasses}
            title="convert to open"
            onClick={() => setIsOpenConvertToOpen(true)}
            data-tip="Convert To Open"
            data-for="open"
          >
            <img src={voidSvg} />
            <ReactTooltip
              id="open"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
            <ReplyIcon className="w-3 h-3 inline absolute text-red-800 bg-white rounded-2xl right-0 bottom-0" />
          </button>
        )}
        {status.actionEnabled() && userCan('invoices.writeoff') && (
          <button
            className={menuButtonClasses}
            title="Write Off Invoice"
            onClick={() => setIsOpenWriteOff(true)}
            data-tip="Write Off Invoice"
            data-for="writeoff"
          >
            <img src={writeoffSvg} className="w-6" />
            <ReactTooltip
              id="writeoff"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
          </button>
        )}
        {status.writtenOff() && userCan('invoices.writeoff') && (
          <button
            className={menuButtonClasses}
            title="Cancel write off"
            onClick={() => setIsOpenCancelWriteOff(true)}
            data-tip="Cancel Write Off"
            data-for="writeoffcancel"
          >
            <img src={writeoffSvg} className="w-6" />
            <ReactTooltip
              id="writeoffcancel"
              type="light"
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            />
            <ReplyIcon className="w-3 h-3 inline absolute text-red-500 bg-white rounded-2xl right-0 bottom-0" />
          </button>
        )}
      </div>
    </>
  );
};

export default Actions;
