import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SpinnerGrow from './SpinnerGrow';
import classNames from 'utils/classNames';

const ConfirmationModal = ({
  open,
  setOpen,
  onOkClick,
  onCancelClick,
  title,
  content,
  align = 'center',
  size = 'xs',
  okLoading = false,
  showOkLoading = false,
  showButtons = true,
  okText = 'Yes',
  cancelText = 'No',
  titleClass = 'text-lg font-bold font-inter leading-6 text-gray-900',
  okButtonClassName = 'inline-flex justify-center px-6 py-1 text-sm font-bold tracking-2 text-white bg-green-600 border border-transparent rounded-40 leading-1.2 hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500',
}) => {
  return (
    <Transition.Root appear show={open} as="div">
      <Dialog as="div" open={open} onClose={setOpen}>
        <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 80 }}>
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 pointer-events-none" />
            {align === 'center' && (
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
            )}

            <div
              className={`inline-block w-full max-w-${size} text-center p-6 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-lg rounded-lg`}
            >
              <Dialog.Title as="h3" className={titleClass}>
                {title}
              </Dialog.Title>
              <div className="my-8">
                <div className="text-sm text-gray-500">{content}</div>
              </div>
              {showButtons ? (
                <div className="mt-4 flex justify-center space-x-6">
                  <button
                    type="button"
                    className="inline-flex justify-center px-6 py-2 text-sm font-bold tracking-2 text-grayscale-700 border border-transparent rounded-40 leading-1.2 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                    onClick={onCancelClick}
                  >
                    {cancelText}
                  </button>

                  <button
                    type="button"
                    className={classNames(
                      okButtonClassName,
                      okLoading ? 'pointer-events-none' : ''
                    )}
                    onClick={onOkClick}
                  >
                    {okText}
                    {showOkLoading && okLoading ? (
                      <SpinnerGrow color="white" />
                    ) : (
                      ''
                    )}
                  </button>
                </div>
              ) : (
                <div>
                  {' '}
                  <button
                    type="button"
                    className="inline-flex justify-center ml-2 px-4 py-2 mt-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                    onClick={onCancelClick}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
