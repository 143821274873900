import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/outline';

import { useRoles } from 'features/roles';
import StatusBadge from 'features/clients/List/components/StatusBadge';
import SubscriptionStatus from 'features/clients/List/components/SubscriptionStatus';

import { Table } from 'components';
import DataDifference from 'components/DataDifference';

import {
  currencyFormatter,
  dateFormatterUTC,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';
import ClientTasks from './components/ClientTasks';
import DatePicker from 'features/datePicker/DatePicker';
import usePermissions from 'hooks/usePermissions';

const AccountPerformance = () => {
  const { PROJECT_MANAGER } = useRoles();
  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { userCan } = usePermissions();

  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState({ rows: [] });
  const [projectManagers, setProjectManagers] = useState([]);
  const [showClientTasks, setShowClientTasks] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    agencyClientAssigneeUserId: '',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let payload = {
        ...params,
        ...dateRange,
        include: ['previousData', 'client', 'subscription'],
        attributes: [
          'name',
          'tacos',
          'totalSales',
          'accountId',
          'advertisingCTR',
          'advertisingCPC',
          'advertisingCVR',
          'advertisingCost',
          'advertisingACOS',
          'advertisingSales',
          'advertisingClicks',
          'advertisingImpressions',
          'organicSalesPercentage',
          'advertisingSalesPercentage',
        ],
      };

      if (!payload.agencyClientAssigneeUserId) {
        delete payload.agencyClientAssigneeUserId;
      }

      const response = await axios.get('/accounts/list', {
        params: payload,
      });

      if (isSubscribed) {
        setAccounts(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params, dateRange]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/users/all', {
        params: {
          roleCode: PROJECT_MANAGER.value,
        },
      });

      if (isSubscribed) {
        setProjectManagers(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, []);

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Account',
      sort: true,
      formatter: (cell, row) => (
        <Link
          className="text-sm text-red-500 hover:text-red-900 inline-flex items-center"
          to={`/clients/redirect/${row.client.agencyClientId}`}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'client.status',
      text: 'Status',
      sort: true,
      formatter: (cell) => <StatusBadge status={cell} />,
    },
    {
      dataField: 'subscription.status',
      text: 'Subscription',
      sort: true,
      formatter: (cell, row) => (
        <SubscriptionStatus subscription={row.subscription} />
      ),
    },
    {
      dataField: 'client.contractSigned',
      text: 'Contract Signed',
      sort: true,
      formatter: (cell) => {
        return cell ? dateFormatterUTC(cell) : <>&mdash;</>;
      },
    },
    {
      dataField: 'advertisingImpressions',
      text: 'Impressions',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingImpressions}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingClicks',
      text: 'Clicks',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingClicks}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCTR',
      text: 'CTR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCTR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCPC',
      text: 'CPC',
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCPC}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCVR',
      text: 'CVR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCVR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'totalSales',
      text: 'Total Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTotalSales}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCost',
      text: 'PPC Spend',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCost}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSales',
      text: 'PPC Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSales}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingACOS',
      text: 'ACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingACOS}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'tacos',
      text: 'TACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTacos}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'organicSalesPercentage',
      text: 'Organic Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousOrganicSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSalesPercentage',
      text: 'PPC Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'client.agencyClientId',
      text: 'Action',
      formatter: (cell) => (
        <UserCircleIcon
          className="h-5 w-5"
          onClick={() => {
            if (userCan('clients.ppc.account_performance_overview.view')) {
              setSelectedId(cell);
              setShowClientTasks(true);
            }
          }}
        />
      ),
    },
  ];

  return (
    <div className="">
      {!!selectedId && (
        <ClientTasks
          id={selectedId}
          open={showClientTasks}
          setOpen={setShowClientTasks}
        />
      )}

      <p className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Account Performance
      </p>

      <div className="flex justify-between items-center my-4">
        <div className="my-4 flex items-center">
          <p className="text-13 leading-1.5 text-grayscale-900 mr-4">
            Project Managers
          </p>

          <select
            className="text-13 text-grayscale-800 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
            value={params.agencyClientAssigneeUserId || ''}
            onChange={(e) =>
              setParams({
                ...params,
                agencyClientAssigneeUserId: e.target.value,
              })
            }
          >
            <option value={''}>Select Project Manager</option>
            {projectManagers.map((pm) => (
              <option key={pm.userId} value={pm.userId}>
                {pm.firstName} {pm.lastName}
              </option>
            ))}
          </select>
        </div>

        <DatePicker showLabel={false} />
      </div>

      <Table
        loading={loading}
        keyField="accountId"
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={accounts}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default AccountPerformance;
