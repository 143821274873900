import { Field, ErrorMessage } from 'formik';

import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const Proposal = ({ handleChange }) => {
  return (
    <div className="flex flex-col w-full gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.proposalLink"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Proposal Link <RequiredAsterisk />
        </Label>
        <Field
          name="data.proposalLink"
          placeholder="Add proposal link"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.proposalLink"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <Label
        htmlFor="data.otherInformation"
        classes="text-left leading-1.2"
        textColor="text-grayscale-800"
        textSize="13"
      >
        Other Information <RequiredAsterisk />
      </Label>
      <Field
        name="data.otherInformation"
        placeholder="Add information related to action taken"
        as="textarea"
        rows={3}
        className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
        onChange={(e) => handleChange(e)}
      />
      <ErrorMessage
        name="data.otherInformation"
        component="div"
        className="text-red-700 font-normal text-xs"
      />
    </div>
  );
};

export default Proposal;
