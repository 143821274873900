import React from 'react';
import classNames from 'utils/classNames';
import SpinnerGrow from './SpinnerGrow';

const Button = ({
  children,
  classes,
  onClick,
  loading = false,
  color = 'red',
  showLoading = false,
  textColor = 'white',
  hoverColor = '',
  bgColor = '',
  spinnerColor = 'white',
  textSize = 'sm',
  roundedSize = 'md',
  px = 4,
  py = 2,
  fontWeight = 'medium',
  shadow = 'shadow-sm',
  border = 'border border-transparent',
  disabled,
  type = 'button',
  defaultClass = 'inline-flex items-center focus:outline-none',
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        disabled ? 'disabled cursor-not-allowed' : '',
        loading ? 'pointer-events-none' : '',
        `px-${px}`,
        `py-${py}`,
        `rounded-${roundedSize}`,
        shadow,
        `text-${textSize}`,
        `font-${fontWeight}`,
        `text-${textColor}`,
        bgColor === '' ? `bg-${color}-600` : `bg-${bgColor}`,
        hoverColor === '' ? `hover:bg-${color}-700` : `hover:bg-${hoverColor}`,
        `focus:ring-${color}-500`,
        border,
        classes ?? '',
        defaultClass
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
      {showLoading && loading ? (
        <>
          &nbsp;
          <SpinnerGrow color={spinnerColor} />
        </>
      ) : (
        ''
      )}
    </button>
  );
};

export default Button;
