import axios from 'axios';
import { isNumber } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

import { useRoles } from 'features/roles';
import { Table } from 'components';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import usePermissions from 'hooks/usePermissions';

import ExpandRow from './ExpandRow';
import AccountList from './AccountList';
import DataDifference from './DataDifference';

import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const UserList = ({ supervisorId }) => {
  const { PPC_SPECIALIST } = useRoles();
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({ rows: [] });
  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const { userCan } = usePermissions();

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      let payload = {
        ...params,
        ...dateRange,
        include: ['previousData'],
        roleCode: 'ACCOUNT_SPECIALIST',
        attributes: [
          'userId',
          'firstName',
          'lastName',
          'totalClients',
          'totalTerminatedClients',
          'advertisingSales',
          'advertisingClicks',
          'advertisingImpressions',
          'advertisingCTR',
          'advertisingCPC',
          'advertisingCVR',
          'advertisingCost',
          'advertisingACOS',
          'totalSales',
          'tacos',
          'organicSalesPercentage',
          'advertisingSalesPercentage',
          'advertisingSPSales',
          'advertisingSPCost',
          'advertisingSPACOS',
          'advertisingSBSales',
          'advertisingSBCost',
          'advertisingSBACOS',
          'advertisingSDSales',
          'advertisingSDCost',
          'advertisingSDACOS',
        ],
      };

      if (supervisorId) {
        payload.supervisorId = supervisorId;
      }

      const response = await axios.get('/users', {
        params: payload,
      });

      if (isSubscribed) {
        setUsers(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params, supervisorId, dateRange]);

  const columns = [
    {
      dataField: 'firstName',
      text: 'PPC Specialist',
      sort: true,
      formatter: (cell, row) => `${cell} ${row.lastName}`,
    },
    {
      dataField: 'totalClients',
      text: 'Total Clients',
      sort: true,
      formatter: (cell, row) => {
        const onClick = () => {
          if (!cell) return;

          setSelectedUser(row);
          setOpenModal(true);
        };

        return (
          <p className="text-right cursor-pointer" onClick={onClick}>
            {cell}
          </p>
        );
      },
    },
    {
      dataField: 'totalTerminatedClients',
      text: 'Terminated',
      sort: true,
      formatter: (cell) => <p className="text-right">{cell}</p>,
    },
    {
      dataField: 'advertisingImpressions',
      text: 'Impressions',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingImpressions}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingClicks',
      text: 'Clicks',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingClicks}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCTR',
      text: 'CTR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCTR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCPC',
      text: 'CPC',
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCPC}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCVR',
      text: 'CVR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCVR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'totalSales',
      text: 'Total Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTotalSales}
          formatter={currencyFormatter}
        />
      ),
    },

    {
      dataField: 'advertisingCost',
      text: 'PPC Spend',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCost}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSales',
      text: 'PPC Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSales}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingACOS',
      text: 'ACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingACOS}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'tacos',
      text: 'TACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTacos}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'organicSalesPercentage',
      text: 'Organic Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousOrganicSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSalesPercentage',
      text: 'PPC Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'userId',
      text: 'Action',
      formatter: (cell) =>
        userCan('clients.ppc.account_performance_overview.view') ? (
          <Link to={`/clients/ppc/users/${cell}`}>
            <UserCircleIcon className="h-5 w-5" />
          </Link>
        ) : (
          <UserCircleIcon className="h-5 w-5 text-gray-400" />
        ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <>
      <Modal open={openModal} setOpen={setOpenModal} as={'div'} align="top">
        <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader title="Client List" setOpen={setOpenModal} />

          <div className="p-5">
            <p className="text-13 text-grayscale-700 leading-1.5">
              Total Clients
            </p>
            <p className="font-inter text-25 tracking-3/4 text-grayscale-900">
              {selectedUser.firstName} {selectedUser.lastName}
            </p>
            <AccountList agencyClientAssigneeUserId={selectedUser.userId} />
          </div>
        </div>
      </Modal>

      <Table
        loading={loading}
        keyField="userId"
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        expandRow={{
          onlyOneExpanding: false,
          showExpandColumn: true,
          expandHeaderColumnRenderer: () => null,
          renderer: (row) => <ExpandRow row={row} identifier={row.userId} />,
          expandColumnRenderer: ({ expanded }) =>
            expanded ? (
              <ChevronUpIcon className="w-5 h-5 text-grayscale-800" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-grayscale-800" />
            ),
        }}
        data={users}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </>
  );
};

export default UserList;
