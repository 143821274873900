import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { updateAccount } from 'features/accounts/accountsSlice';
import OnboardingLayout from 'layouts/onboarding/OnboardingLayout';
import Spinner from 'components/Spinner';
import { CA_MARKETPLACE, US_MARKETPLACE } from 'utils/constants';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useAlert from 'hooks/useAlert';
import { getAccountAsync } from 'features/accounts/accountsSlice';

const AccountConfirmation = ({ location }) => {
  const dispatch = useDispatch();
  const { currentAccount, isAlreadySubscribed } = useLoggedInUser();
  const { alertError } = useAlert();

  // * Check if hostedpage_id is part of the query parameters
  useEffect(() => {
    const confirmSubscription = async (hostedPageId) => {
      try {
        const response = await axios({
          method: 'POST',
          url: '/account/onboarding/confirmsubscription',
          params: {
            accountId: currentAccount.accountId,
            hostedPageId,
          },
        });

        console.log(response.data.data);

        dispatch(updateAccount(response.data.data));
        //dispatch(getAccountAsync(currentAccount.accountId));
      } catch (error) {
        alertError(
          'Failed to confirm subscription',
          error.response.data.message
        );
      }
    };

    if (
      currentAccount &&
      !currentAccount.subscription &&
      location.search.includes('hostedpage_id')
    ) {
      const hostedPageId = qs.parse(location.search.slice(1)).hostedpage_id;

      confirmSubscription(hostedPageId);
    }
  }, [location.search, currentAccount, dispatch]);

  // * Check if the current account is onboarding and has a subscription
  if (isAlreadySubscribed()) {
    if (
      currentAccount?.AgencyClient?.draftMarketplace === US_MARKETPLACE ||
      currentAccount?.AgencyClient?.draftMarketplace === CA_MARKETPLACE
    ) {
      return <Redirect to="/onboarding" />;
    } else {
      return <Redirect to="/plan" />;
    }
  }

  return (
    <OnboardingLayout>
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl text-center">
          {currentAccount && !currentAccount.subscription ? (
            <>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Confirming your subscription
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Please wait while we confirm your subscription status
              </p>
              <div className="mt-5 flex justify-center">
                <Spinner width={80} height={80} />
              </div>
            </>
          ) : (
            <>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Your subscription is confirmed
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Please click the button below to begin setting up your account{' '}
              </p>
              <div className="mt-6">
                <Link
                  to={`/onboarding`}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Proceed to Onboarding
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default withRouter(AccountConfirmation);
