import { useEffect, useState } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Table } from 'components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatterUTC } from 'utils/formatters';
import Badge from 'components/Badge';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import {
  PlusIcon,
  EyeIcon,
  MailIcon,
  PencilIcon,
  CheckIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import Button from 'components/Button';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import CreateUpsellModal from './components/CreateUpsellModal';
import FunnelIcon from 'components/icons/FunnelIcon';
import useUpsell from 'hooks/useUpsell';
import useAlert from 'hooks/useAlert';
import { columnClasses } from 'utils/table';
import Confirm from 'components/Confirm';
import UpsellReviewModal from 'features/upsells/UpsellReviewModal';
import { setUpsellPaginationParams } from '../agencyClientSlice';
import UserProfileAvatar from 'components/UserProfileAvatar';

const Upsells = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { agencyClient, billing } = useSelector((state) => state.agencyClient);

  const { STATUS, isItemPaidReviews } = useUpsell();

  const { params, updateParams } = useQueryParams({
    ...billing.upsells.paginationParams,
    client: agencyClient.agencyClientId,
  });

  const [loading, setLoading] = useState(false);
  const [upsells, setUpsells] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUpsell, setSelectedUpsell] = useState(null);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const [mailing, setMailing] = useState(false);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);

  const getUpsells = async () => {
    setLoading(true);
    await axios.get(`/agency/upsell`, { params }).then((res) => {
      setUpsells(res.data.data);
      dispatch(setUpsellPaginationParams(params));
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      getUpsells();
    }
  }, [params]);

  const tableColumns = [
    {
      dataField: 'service',
      text: 'Service',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <button
            className="font-normal flex flex-row items-center space-x-1 text-left"
            onClick={() => {
              setSelectedUpsell(row);
              row.status === STATUS.DRAFT
                ? setOpenModal(true)
                : setIsOpenReviewModal(true);
            }}
          >
            <span className=" underline text-secondary-light text-sm uppercase">
              {row.details.name}
            </span>
            <EyeIcon className="w-5 h-5 inline text-secondary" />
          </button>
        );
      },
    },
    {
      dataField: 'invoiceNumber',
      text: 'Invoice',
      sort: true,
      headerStyle: {
        minWidth: '100px',
      },
      formatter: (cell, row) => {
        return row.invoiceId ? (
          <Link
            to={`/invoices/${row.invoiceId}`}
            className="text-secondary-light underline text-sm"
          >
            {row.invoiceNumber}
          </Link>
        ) : (
          <>&mdash;</>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: false,
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      classes: `${columnClasses} text-right`,
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            <span className="text-grayscale-600">$</span>&nbsp;
            {row.totalAmount.toFixed(2)}
          </span>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        width: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              color={classnames({
                orange: cell === STATUS.AWAITING_APPROVAL,
                gray: cell === STATUS.DRAFT,
                blue: cell === STATUS.APPROVED,
                yellow:
                  cell === STATUS.SENT ||
                  cell === STATUS.OVERDUE ||
                  cell === STATUS.PENDING,
                green: cell === STATUS.PAID,
                red: cell === STATUS.REJECTED,
                purple: cell === STATUS.WAIVED,
              })}
              classes="capitalize text-center w-full justify-center"
              rounded="full"
              padding="py-2"
              textSize="sm"
              fontFamily=""
            >
              {cell}
            </Badge>
            {cell === STATUS.PENDING && row.seenAt && (
              <div className="flex justify-center items-center mt-1 relative transform -translate-x-2">
                <CheckIcon className="w-4 h-4 inline text-success relative -right-3" />
                <CheckIcon className="w-4 h-4 inline text-success" />
                <p className="text-xs text-success">seen</p>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{dateFormatterUTC(row.createdAt)}</span>
        );
      },
    },
    {
      dataField: 'requestedBy',
      text: 'Submitted By',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <UserProfileAvatar user={row.requestedByUser} />;
      },
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        switch (row.status) {
          case STATUS.PENDING:
          case STATUS.SENT:
          case STATUS.APPROVED:
          case STATUS.WAIVED:
            return row.approvedByUser ? (
              <UserProfileAvatar user={row.approvedByUser} />
            ) : (
              <>&mdash;</>
            );
          case STATUS.REJECTED:
            return row.rejectedByUser ? (
              <UserProfileAvatar user={row.rejectedByUser} />
            ) : (
              <>&mdash;</>
            );

          default:
            return <>&mdash;</>;
        }
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '140px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        switch (row.status) {
          case STATUS.DRAFT:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setOpenModal(true);
                }}
              >
                <PencilIcon className="w-3.5 h-3.5 inline mr-2" /> Edit
              </Button>
            );
          case STATUS.PENDING:
          case STATUS.SENT:
          case STATUS.OVERDUE:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setIsOpenEmail(true);
                }}
              >
                <MailIcon className="w-3.5 h-3.5 inline mr-2" /> Resend
              </Button>
            );
          case STATUS.APPROVED:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setIsOpenEmail(true);
                }}
              >
                <MailIcon className="w-3.5 h-3.5 inline mr-2" /> Send
              </Button>
            );
          default:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {}}
                disabled={true}
              >
                <SearchIcon className="w-3.5 h-3.5 inline mr-2" /> View
              </Button>
            );
        }
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage && sizePerPage,
      sort: sortField ? `${sortField}:${sortOrder}` : 'createdAt:desc',
    });
  };

  const emailUpsell = async () => {
    setMailing(true);
    try {
      const response = await axios.post(
        `agency/upsell/${selectedUpsell.upsellId}/send`,
        {
          subscriptionId:
            selectedUpsell.agencyClient.account.subscription.subscriptionId,
        }
      );

      alertSuccess('Successfully sent email', response.data.message);
      setIsOpenEmail(false);
    } catch (error) {
      alertError('Failed to send', error.response.data.message);
    } finally {
      setMailing(false);
    }
  };

  return (
    <div className="col-span-5 p-7 bg-white">
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Upsells
          </h2>
        </div>

        <div className="flex items-center space-x-4">
          <DropdownMenu
            title={<FunnelIcon />}
            titleClasses="flex items-center mr-1 relative top-1.5"
            button
            buttonBg="bg-transparent"
            buttonFontWeight="font-normal"
            hoverClasses="bg-none"
            textColor="text-cyan-600"
            classes="text-sm z-2 rounded-2xl"
            buttonRounded=""
            hoverText="hover:text-cyan-500"
            dropdownWidth="w-48"
            padding=""
            position=""
            placement="bottom-end"
            hideArrow
          >
            <div className="px-1 py-1 flex flex-col">
              {[
                STATUS.ALL,
                STATUS.DRAFT,
                STATUS.AWAITING_APPROVAL,
                STATUS.APPROVED,
                STATUS.PENDING,
                STATUS.PAID,
                STATUS.REJECTED,
                STATUS.WAIVED,
              ].map((status) => {
                return (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          updateParams({ ...params, page: 1, status });
                        }}
                        className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500 capitalize"
                      >
                        {status}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </DropdownMenu>
          {agencyClient &&
            userCan('upsells.create') &&
            !agencyClient.deletedAt && (
              <Button
                classes={'rounded-full'}
                border=" border-2 border-blue-900 tracking-widest font-bold py-2"
                hoverColor="blue-100"
                textColor="blue-900"
                bgColor="bg-white"
                onClick={() => {
                  setSelectedUpsell(null);
                  setOpenModal(true);
                }}
                px={6}
              >
                <PlusIcon className="h-4 w-4 mr-2" /> Create Upsell
              </Button>
            )}
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={upsells}
        onTableChange={onTableChange}
        params={params}
        keyField="upsellId"
        loading={loading}
        rowClasses="my-4 text-left"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
      />
      <CreateUpsellModal
        open={openModal}
        setOpen={setOpenModal}
        upsell={selectedUpsell}
        getUpsells={getUpsells}
        action={selectedUpsell ? 'update' : 'create'}
      />

      <Confirm
        title="Are you sure you want to resend the billing summary invoice email to the client?"
        content={selectedUpsell?.agencyClient?.defaultContact?.email}
        open={isOpenEmail}
        setOpen={setIsOpenEmail}
        onOkClick={emailUpsell}
        onCancelClick={() => setIsOpenEmail(false)}
        okText="Resend"
        showOkLoading={true}
        okLoading={mailing}
      />

      <UpsellReviewModal
        open={isOpenReviewModal}
        setOpen={setIsOpenReviewModal}
        upsell={selectedUpsell}
        getUpsells={getUpsells}
        setIsOpenEmail={setIsOpenEmail}
        type="creator"
      />
    </div>
  );
};

export default Upsells;
