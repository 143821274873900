import axios from 'axios';
import { useState, useEffect } from 'react';

import {
  Bar,
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import SectionComment from '../components/SectionComment';
import { currencyFormatter, numberFormatter } from 'utils/formatters';
import metrics from '../../utils/metrics';

const TargetingRanking = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  comment = '',
  onChangeComment,
  editableComment = true,
}) => {
  const [targeting, setTargeting] = useState(null);
  const [targetings, setTargetings] = useState({ rows: [] });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let params = {
        pageSize: 30,
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes: [
          'advTargetingId',
          'value',
          'impressionsPerSpend',
          'ctr',
          'cr',
          'unitsPerOrder',
          'costPerConvertedUnit',
        ],
        ...additionalParams,
        include: ['ranking'],
        sort: 'cr',
      };

      const response = await axios.get('/account/advertising/targetings', {
        params,
      });

      if (isSubscribed) {
        setTargetings(response.data.data);
        if (response.data.data.rows.length) {
          setTargeting(response.data.data.rows[0]);
        }
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    !!targetings.rows.length && (
      <div className="relative">
        <SectionComment
          value={comment}
          onChangeValue={onChangeComment}
          editable={editableComment}
        />

        <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
          <div className="flex items-center justify-between border-b px-8 pb-6">
            <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
              Keywords Ranking
            </p>

            <div className="flex items-center">
              <p className="leading-1.5 text-mini text-grayscale-900 mr-4">
                Choose a keyword to compare
              </p>
              <select
                value={targeting ? targeting.advTargetingId : ''}
                onChange={(e) =>
                  setTargeting(
                    targetings.rows.find(
                      (r) => r.advTargetingId === e.target.value
                    )
                  )
                }
                className="bg-grayscale-400 text-mini text-grayscale-900 border-0 rounded-2xl pl-3 pr-9 py-3 focus:outline-none focus:ring-0"
              >
                {targetings.rows.map((targeting) => (
                  <option
                    key={`targeting-ranking-options-${targeting.advTargetingId}`}
                    value={targeting.advTargetingId}
                  >
                    {targeting.value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="px-8">
            {targeting ? (
              <div className="mt-8">
                <p className="font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-800">
                  How does{' '}
                  <span className="text-primary font-bold">
                    {targeting.value}
                  </span>{' '}
                  rank versus other keywords?
                </p>

                <div className="w-full h-48 mt-8">
                  <ResponsiveContainer height="100%">
                    <BarChart
                      margin={{ top: 15 }}
                      data={[
                        'impressionsPerSpend',
                        'ctr',
                        'cr',
                        'unitsPerOrder',
                      ]
                        .map((attr) => metrics.find((m) => m.key === attr))
                        .map(({ key, title }) => {
                          return {
                            text: title,
                            ranking: targeting[`${key}Rank`],
                          };
                        })}
                    >
                      <CartesianGrid vertical={false} />

                      <XAxis dataKey="text" style={{ fontSize: '0.75rem' }} />

                      <YAxis
                        tickFormatter={(value) => numberFormatter(value)}
                        style={{ fontSize: '0.6rem' }}
                      />

                      <Bar
                        dataKey="ranking"
                        fill="#FF89A6"
                        isAnimationActive={false}
                      >
                        <LabelList
                          dataKey="ranking"
                          position="top"
                          style={{
                            fontWeight: '600',
                            color: '#acacac',
                            fontSize: '0.7rem',
                          }}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className="grid grid-cols-4 bg-grayscale-500 py-4 px-5 mt-8">
                  <p className="col-start-3 text-mini font-bold text-grayscale-800 text-right">
                    Value
                  </p>
                  <p className="text-mini font-bold text-grayscale-800 text-right">
                    Relative Rank
                  </p>
                </div>

                {['impressionsPerSpend', 'ctr', 'cr', 'unitsPerOrder']
                  .map((attr) => metrics.find((m) => m.key === attr))
                  .map((metric) => (
                    <div
                      key={`targeting-ranking-table-${metric.key}`}
                      className="grid grid-cols-4 text-mini text-grayscale-800 leading-1.5 py-3 px-5"
                    >
                      <p className="col-span-2">{metric.title}</p>
                      <p className="text-right">
                        {metric.rawFormatter(targeting[metric.key])}
                      </p>
                      <p className="text-right">
                        {targeting[`${metric.key}Rank`]}
                      </p>
                    </div>
                  ))}

                <div className="grid grid-cols-4 text-mini text-grayscale-800 leading-1.5 py-3 px-5 font-bold border-t border-grayscale-500">
                  <p className="col-span-2">Total cost per converted unit</p>
                  <p className="text-right">
                    {currencyFormatter(targeting.costPerConvertedUnit)}
                  </p>
                  <p className="text-right">
                    {targeting.costPerConvertedUnitRank}
                  </p>
                </div>
              </div>
            ) : (
              <p className="tracking-2 font-semibold mt-8 text-grayscale-800 text-center text-xl">
                No Keyword Selected
              </p>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default TargetingRanking;
