import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { currencyFormatter, numberFormatter } from 'utils/formatters';
import classNames from 'utils/classNames';
import DealGroupCard from './DealGroupCard';
import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import RevenueWeightButton from './RevenueWeightButton';
import RevenueWeightModal from './RevenueWeightModal';
import Modal from 'components/Modal';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ButtonLink from 'components/ButtonLink';
import { useDrop } from 'react-dnd';
import { setAlert } from 'features/alerts/alertsSlice';
import { getLatestUpdates, setClientStatus } from '../salesSlice';

const dropValidationData = [
  {
    fieldName: 'lead.email',
    errorMsg: 'Lead email is required',
  },
  {
    fieldName: 'lead.lead',
    errorMsg: 'Lead first name is required',
  },
  {
    fieldName: 'lead.leadLastName',
    errorMsg: 'Lead last name is required',
  },
  {
    fieldName: 'decisionMaker',
    errorMsg: 'Business decision maker is required',
  },
  {
    fieldName: 'email',
    errorMsg: 'Business email is required',
  },
];

const DealGroup = ({ title, params, isRefreshStatus, setIsRefreshStatus }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [revenueDetailData, setRevenueDetailData] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchData = async (params) => {
    setLoading(true);
    const response = await axios.get('/agency/sales/clients', {
      params,
    });
    setSalesData(response.data.data);
    setLoading(false);
  };

  const fetchRevenueDetailData = async (status) => {
    await axios.get(`/agency/sales/revenue/${status}`).then((res) => {
      setRevenueDetailData(res.data.data);
    });
  };

  useEffect(() => {
    fetchData(params).catch(console.error);
    fetchRevenueDetailData(params.status).catch(console.error);
  }, [
    dispatch,
    isRefreshStatus,
    params.creationDate,
    params.closeDate,
    params.lastActivityDate,
    params.search,
    params.dealOwner,
    params.dealType,
  ]);

  const onLoadMore = async () => {
    setIsLoadingMore(true);

    const response = await axios.get('/agency/sales/clients', {
      params: { ...params, page: salesData.nextPage },
    });

    setSalesData({
      ...response.data.data,
      rows: [...salesData.rows, ...response.data.data.rows],
    });

    setIsLoadingMore(false);
  };

  function getdragTitle(label) {
    switch (label) {
      case 'booked':
      case 'first call':
      case 'second call':
      case 'third call':
      case 'fourth call':
        return 'Booked';
      case 'Transfer to Ops':
        return 'Transfer to Operations';
      case 'Email Pending':
      case 'Email Sent':
      case 'Email Follow-up 1':
      case 'Email Follow-up 2':
      case 'Email Follow-up 3':
      case 'Email Follow-up 4':
      case 'Email Follow-up 5':
      case 'New Response':
        return 'Prospect';
      case 'Lost':
        return 'Lost';
      case 'No Show':
        return 'No Show';
      case 'Pending':
        return 'Pending';
      case 'Proposal':
        return 'Proposal';
      default:
        break;
    }
  }

  function getDefalutStatus(dropCol) {
    switch (dropCol) {
      case 'Booked':
        return 'first call';
      case 'Transfer to Operations':
        return 'Transfer to Operations';
      case 'Prospect':
        return 'Email Pending';
      case 'Lost':
        return 'Lost';
      case 'No Show':
        return 'No Show';
      case 'Pending':
        return 'Pending';
      case 'Proposal':
        return 'Proposal';
      default:
        break;
    }
  }

  const [{ isActive }, drop] = useDrop({
    accept: 'card',
    canDrop: (item) => {
      const dragTitle = getdragTitle(item.data.status);

      //Check dropable between drag and drop status
      if (
        (dragTitle !== 'Lost' && title === 'Prospect') ||
        (dragTitle === 'Proposal' && title !== 'Lost') ||
        dragTitle === title ||
        title === 'Transfer to Ops'
      ) {
        return false;
      } else {
        return true;
      }
    },
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
    drop: async (item) => {
      const status = getDefalutStatus(title);

      if (title === 'Proposal') {
        let checkError = false;
        dropValidationData.forEach((d) => {
          if (
            item.data[d.fieldName] === null ||
            item.data[d.fieldName] === ''
          ) {
            dispatch(setAlert('error', d.errorMsg));
            checkError = true;
          }
        });
        if (checkError) {
          return;
        }
      }

      try {
        const response = await axios.put(
          `/agency/sales/clients/${item.data.salesClientId}`,
          {
            status,
          }
        );
        if (response.data.success) {
          dispatch(getLatestUpdates(item.data.salesClientId));
          dispatch(setClientStatus(status));
          setIsRefreshStatus(!isRefreshStatus);
          dispatch(setAlert('success', 'Updated Successfully'));
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div
      className={classNames(
        isVisible ? 'w-56' : 'w-12 overflow-hidden',
        'bg-white relative flex flex-col flex-shrink-0'
      )}
      ref={drop}
    >
      <div
        className={classNames(
          !isVisible ? 'bottom-0 h-full' : '',
          'flex flex-row absolute w-full bg-white cursor-pointer justify-between items-center py-3.5 px-3 leading-1.5'
        )}
        onClick={() => setIsVisible(!isVisible)}
      >
        <p
          className={classNames(
            !isVisible
              ? 'absolute transform -rotate-90 origin-bottom-left bottom-5 left-3/4 ml-0.5'
              : '',
            'text-13 font-bold flex items-center cursor-pointer bg-white text-grayscale-700 space-x-1.5'
          )}
        >
          <span className="whitespace-nowrap leading-none">{title}</span>
          <span className="border p-1.5 leading-none rounded text-11 font-bold bg-grayscale-500 text-grayscale-800">
            {numberFormatter(salesData?.count)}
          </span>
        </p>
        <ChevronDoubleLeftIcon
          className={classNames(
            !isVisible
              ? 'absolute transform rotate-180 left-1/2 -translate-x-1/2 top-5 -mt-0.5'
              : '',
            'w-5 h-5 inline text-grayscale-700'
          )}
        />
      </div>

      <div
        className={classNames(
          !isVisible ? 'opacity-0 pointer-events-none' : '',
          'overflow-y-auto'
        )}
        style={{
          marginTop: '56px',
          marginBottom: '60px',
          paddingBottom: '26px',
        }}
      >
        <PerfectScrollbar>
          <div className="overflow-x-auto">
            {isActive && (
              <p className="text-center bg-grayscale-500 py-4 text-grayscale-800 text-base tracking-3/4">
                Drop Here
              </p>
            )}
            {salesData?.rows.length > 0 &&
              salesData.rows.map((row) => (
                <DealGroupCard
                  row={row}
                  params={params}
                  updateData={fetchData}
                  setIsRefreshStatus={setIsRefreshStatus}
                  isRefreshStatus={isRefreshStatus}
                  fetchRevenueDetailData={fetchRevenueDetailData}
                  canDrag={title === 'Transfer to Ops' ? false : true}
                />
              ))}
            {!!salesData?.rows.length &&
              salesData?.rows.length !== salesData?.count && (
                <div className="flex flex-row justify-center">
                  <ButtonLink
                    classes="border rounded-full border-grayscale-500 px-6 py-1.5"
                    color="gray"
                    showLoading={true}
                    loading={isLoadingMore}
                    onClick={onLoadMore}
                    textSize="xs"
                  >
                    Load More
                  </ButtonLink>
                </div>
              )}
          </div>
        </PerfectScrollbar>
      </div>

      <div
        className={classNames(
          !isVisible ? 'hidden' : '',
          'flex flex-col border border-grayscale-500 py-2 absolute bottom-0 w-full bg-white'
        )}
      >
        <div className="flex flex-row justify-center">
          <p className="font-bold text-base text-grayscale-900">
            Total:
            <span className="font-normal pl-1">
              {currencyFormatter(revenueDetailData?.totalAmount)}
            </span>
          </p>
        </div>

        <div className="flex flex-row justify-center">
          <p className="font-normal text-13 text-grayscale-900">
            Weighted:
            <span className="pl-1 pr-2">
              {currencyFormatter(
                (revenueDetailData?.totalAmount *
                  revenueDetailData?.percentage) /
                  100
              )}
              {` (${revenueDetailData?.percentage}%) `}
            </span>
          </p>
          <RevenueWeightButton
            title={title}
            revenueDetailData={revenueDetailData}
            setOpen={setOpen}
          />
        </div>
      </div>

      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <RevenueWeightModal
          setOpen={setOpen}
          revenueDetailData={revenueDetailData}
          setRevenueDetailData={setRevenueDetailData}
        />
      </Modal>
    </div>
  );
};

export default DealGroup;
