import { Avatar } from 'components';
import { getNameInitials, simpleFromNow } from 'utils/formatters';
import { ActivityLogChangeItem } from 'features/activityLogs';

const AcitivityLogItem = ({ log, changeCustomizers = {} }) => {
  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex space-x-5">
        <div className="bg-secondary-light w-3 h-3 rounded-full mt-2"></div>

        <div>
          <p className="bg-custom-light-blue text-secondary text-11 px-4 py-1.5 rounded-2xl">
            {simpleFromNow(log.createdAt)}
          </p>
        </div>
      </div>

      <div className="col-span-4">
        <div className="flex items-center space-x-2">
          <Avatar
            size="24px"
            textSize="14px"
            imageSrc={log.triggeredBy?.avatar?.thumbnailUrl}
            initials={getNameInitials(
              log.triggeredBy.firstName,
              log.triggeredBy.lastName
            )}
          />

          <p className="text-grayscale-800 font-medium text-13">
            {log.triggeredBy.firstName} {log.triggeredBy.lastName}{' '}
            <span className="lowercase">{log.description}</span>
          </p>
        </div>

        {log.properties?.changed && (
          <ul className="list-disc mt-2 space-y-3">
            {log.properties.changed.map((attr) => (
              <ActivityLogChangeItem
                key={attr}
                attribute={attr}
                values={log.properties.values}
                changeCustomizer={changeCustomizers[attr]}
                previousValues={log.properties.previousValues}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AcitivityLogItem;
