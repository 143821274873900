import axios from 'axios';
import { Link } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import { useState, useEffect } from 'react';

import {
  PlusIcon,
  MailIcon,
  TrashIcon,
  PencilIcon,
  SearchIcon,
  PauseIcon,
  PlayIcon,
} from '@heroicons/react/outline';

import { useRoles } from 'features/roles';
import { useAlerts } from 'features/alerts';
import usePermissions from 'hooks/usePermissions';
import { useUsers, UserStatus } from 'features/users';

import { Avatar, ConfirmationModal, Table } from 'components';
import InputPrepend from 'components/Forms/InputPrepend';

import {
  FormModal,
  Navigation,
  EmployeeFilters,
} from 'features/employees/components';
import { useHistory } from 'react-router-dom';
import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { signOutAsync } from 'features/auth/authSlice';

const EmployeeList = () => {
  const { userCan } = usePermissions();
  const { user } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const { popupAlert } = useAlerts();
  const { userList, fetchUserList, statuses, selectedUser, setSelectedUser } =
    useUsers();

  const { levels, getDepartmentByValue, getSubDepartmentByValue } = useRoles();

  const [openForm, setOpenForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openPauseConfirmation, setOpenPauseConfirmation] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 30,
    search: '',
    include: ['role', 'supervisor[role,avatar]'],
    sorts: 'firstName:asc',
    attributes: [
      'userId',
      'roleId',
      'firstName',
      'lastName',
      'email',
      'employeeCode',
      'supervisorId',
      'hiringDate',
      'address',
      'status',
      'directSubordinatesCount',
    ],
    filterGroups: JSON.stringify([
      {
        filters: [
          {
            attribute: 'role.level',
            operator: 'EQ',
            value: levels.AGENCY_LEVEL,
          },
        ],
      },
    ]),
  });

  const onDebouncedSearch = debounce((e) => {
    setParams({ ...params, search: e.target.value });
  }, 500);

  useEffect(() => {
    const fetchData = async () => await fetchUserList(params);

    fetchData().catch(console.error);
  }, [params]);

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const resendCredentials = (userId) => {
    axios
      .post(`/v2/users/${userId}/resend-credentials`)
      .then((response) =>
        popupAlert({ type: 'success', title: response.data.message })
      )
      .catch((err) =>
        popupAlert({ type: 'error', title: 'Something went wrong!' })
      );
  };

  const deleteUser = (userId) => {
    setIsDeleting(true);

    axios
      .delete(`/v2/users/${userId}`)
      .then((response) => {
        popupAlert({ type: 'info', title: response.data.message });
        fetchUserList(params);
      })
      .catch((err) =>
        popupAlert({
          type: 'error',
          title: err.response?.data.message ?? 'Something when wrong!.',
        })
      )
      .finally(() => {
        setOpenConfirmation(false);
        setIsDeleting(false);
      });
  };

  const updateUser = (userId) => {
    setIsLoading(true);

    axios
      .put(`/v2/users/${userId}`, {
        status:
          selectedUser.data?.status === statuses.ACTIVE_STATUS.value
            ? statuses.PAUSED_STATUS.value
            : statuses.ACTIVE_STATUS.value,
        email: selectedUser.data?.email,
        firstName: selectedUser.data?.firstName,
        lastName: selectedUser.data?.lastName,
        roleId: selectedUser.data?.roleId,
      })
      .then((response) => {
        popupAlert({ type: 'info', title: response.data.message });

        if (userId === user?.userId) {
          dispatch(signOutAsync());
        } else {
          fetchUserList(params);
        }
      })
      .catch((err) =>
        popupAlert({
          type: 'error',
          title: err.response?.data.message ?? 'Something when wrong!.',
        })
      )
      .finally(() => {
        setOpenPauseConfirmation(false);
        setIsLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'email',
      text: 'Email Address',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'address',
      text: 'Location',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'hiringDate',
      text: 'Start Date',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'role.department',
      text: 'Department',
      sort: true,
      headerStyle: { minWidth: '300px' },
      formatter: (cell, row) => {
        if (!cell) return '';

        const department = getDepartmentByValue(cell);
        const subDepartment = getSubDepartmentByValue(row.role.subDepartment);

        return (
          <div className="flex space-x-1">
            {!!subDepartment && (
              <span
                style={{
                  color: subDepartment?.color,
                  backgroundColor: subDepartment?.bgColor,
                }}
                className="py-1 px-3 rounded-xl"
              >
                {row.role.subDepartment}
              </span>
            )}

            <span
              style={{
                color: department?.color,
                backgroundColor: department?.bgColor,
              }}
              className="py-1 px-3 rounded-xl"
            >
              {cell}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'role.name',
      text: 'Role',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'supervisor.firstName',
      text: 'Team Leader',
      sort: true,
      headerStyle: { minWidth: '250px' },
      formatter: (cell, row) =>
        !!cell ? (
          <div className="flex items-center space-x-2">
            <Avatar
              imageSrc={row.supervisor?.avatar?.thumbnailUrl}
              initials={getNameInitials(cell, row.supervisor.lastName)}
            />
            <div>
              <p className="leading-3">
                {cell} {row.supervisor.lastName}
              </p>
              <p className="text-11 text-grayscale-700">
                {row.supervisor?.role?.name}
              </p>
            </div>
          </div>
        ) : (
          ''
        ),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => <UserStatus value={cell} />,
    },
    {
      dataField: 'userId',
      text: 'Action',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <div className="flex space-x-2" id={cell}>
          {userCan('employees.view') && (
            <Link to={`/employees/${cell}`}>
              <SearchIcon className="w-5 h-5 cursor-pointer text-secondary-dark" />
            </Link>
          )}

          <button
            disabled={!userCan('employees.edit')}
            className={classNames(
              userCan('employees.edit')
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            )}
          >
            <PencilIcon
              onClick={() => {
                setSelectedUser({ loading: false, data: row });
                setOpenForm(true);
              }}
              className="w-5 h-5"
            />
          </button>

          <button
            disabled={!userCan('employees.delete')}
            className={classNames(
              userCan('employees.delete')
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            )}
          >
            <TrashIcon
              onClick={() => {
                setSelectedUser({ loading: false, data: row });
                setOpenConfirmation(true);
              }}
              className="w-5 h-5 text-error-dark"
            />
          </button>

          {row.status === statuses.ACTIVE_STATUS.value && (
            <button
              disabled={!userCan('employees.edit')}
              className={classNames(
                userCan('employees.edit')
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              )}
            >
              <PauseIcon
                onClick={() => {
                  setSelectedUser({ loading: false, data: row });
                  setOpenPauseConfirmation(true);
                }}
                className="w-5 h-5 text-blue-600"
              />
            </button>
          )}

          {row.status === statuses.PAUSED_STATUS.value && (
            <button
              disabled={!userCan('employees.edit')}
              className={classNames(
                userCan('employees.edit')
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              )}
            >
              <PlayIcon
                onClick={() => {
                  setSelectedUser({ loading: false, data: row });
                  setOpenPauseConfirmation(true);
                }}
                className="w-5 h-5 text-blue-600"
              />
            </button>
          )}

          {row.status === statuses.INVITED_STATUS.value && (
            <MailIcon
              onClick={() => resendCredentials(cell)}
              className="w-5 h-5 cursor-pointer"
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between">
        <div className="flex space-x-4 items-center">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Employees
          </h2>

          {userCan('employees.create') && (
            <button
              onClick={() => setOpenForm(true)}
              className="border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
            >
              <PlusIcon className="w-3 h-3" />
              <span>Add</span>
            </button>
          )}
        </div>

        <div className="flex items-center space-x-4">
          <EmployeeFilters
            filters={JSON.parse(params.filterGroups)[0].filters}
            setFilters={(filters) =>
              setParams({
                ...params,
                filterGroups: JSON.stringify([{ filters }]),
              })
            }
          />

          <InputPrepend
            name="search"
            defaultValue={params.search}
            onChange={(e) => onDebouncedSearch(e)}
            type="text"
            placeholder={'Search'}
            prependText={<SearchIcon className="w-4 h-4" />}
            border="border-white"
          />
        </div>
      </div>

      {!isEmpty(selectedUser.data) && (
        <>
          <ConfirmationModal
            size="lg"
            okText="Delete"
            cancelText="Cancel"
            showOkLoading={true}
            okLoading={isDeleting}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            onCancelClick={() => {
              setSelectedUser({ loading: false, data: {} });
              setOpenConfirmation(false);
            }}
            onOkClick={() => deleteUser(selectedUser.data.userId)}
            titleClass="font-inter text-25 font-bold text-grayscale-900 leading-1.2 tracking-3/4"
            title={`Are you sure you want to delete ${selectedUser.data.firstName} ${selectedUser.data.lastName} from Better Seller?`}
            okButtonClassName="rounded-40 bg-error-dark text-grayscale-300 text-sm leading-1.5 tracking-2 font-bold py-2 px-6"
          />

          <ConfirmationModal
            size="lg"
            okText="Ok"
            cancelText="Cancel"
            showOkLoading={true}
            okLoading={isLoading}
            open={openPauseConfirmation}
            setOpen={setOpenPauseConfirmation}
            onCancelClick={() => {
              setSelectedUser({ loading: false, data: {} });
              setOpenConfirmation(false);
            }}
            onOkClick={() => updateUser(selectedUser.data.userId)}
            titleClass="font-inter text-25 font-bold text-grayscale-900 leading-1.2 tracking-3/4"
            title={`Are you sure you want to ${
              selectedUser.data?.status === statuses.ACTIVE_STATUS.value
                ? 'pause'
                : 'resume'
            } ${selectedUser.data.firstName} ${
              selectedUser.data.lastName
            } access to Better Seller?`}
            okButtonClassName="rounded-40 bg-blue-600 text-white text-sm leading-1.5 tracking-2 font-bold py-2 px-6"
          />
        </>
      )}

      <FormModal
        open={openForm}
        setOpen={(state) => {
          setOpenForm(state);
          if (!state) {
            setSelectedUser({ loading: false, data: {} });
          }
        }}
        user={selectedUser.data}
        onSuccess={() => {
          setSelectedUser({ loading: false, data: {} });
          fetchUserList(params);
        }}
      />

      <Navigation />

      <div className="mt-8">
        <Table
          columns={tableColumns.map((column) => {
            return {
              ...column,
              classes:
                'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
              headerClasses:
                'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
            };
          })}
          data={userList.data}
          onTableChange={onTableChange}
          params={params}
          keyField="userId"
          loading={userList.loading}
        />
      </div>
    </div>
  );
};

export default EmployeeList;
