import axios from 'axios';

import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Switch } from '@headlessui/react';

import './funnel.scss';

import Header from './components/Header';
import AnalyticsOverview from 'features/clients/Client/Account/Advertising/Analytics/sections/AnalyticsOverview';
import MetricsChart from 'features/clients/Client/Account/Advertising/Analytics/sections/MetricsChart';
import ActiveCampaignsOverview from 'features/clients/Client/Account/Advertising/Analytics/sections/ActiveCampaignsOverview';
import CampaignTypesOverview from 'features/clients/Client/Account/Advertising/Analytics/sections/CampaignTypesOverview';
import CampaignTypesBreakdown from 'features/clients/Client/Account/Advertising/Analytics/sections/CampaignTypesBreakdown';
import TargetingTypeBreakdown from 'features/clients/Client/Account/Advertising/Analytics/sections/TargetingTypesBreakdown';
import ConvertersOverview from 'features/clients/Client/Account/Advertising/Analytics/sections/ConvertersOverview';
import KeywordPerformance from 'features/clients/Client/Account/Advertising/Analytics/sections/KeywordPerformance';
import MostImprovedTargeting from 'features/clients/Client/Account/Advertising/Analytics/sections/MostImprovedTargeting';
import MostImprovedProductAds from 'features/clients/Client/Account/Advertising/Analytics/sections/MostImprovedProductAds';
import TargetingRanking from 'features/clients/Client/Account/Advertising/Analytics/sections/TargetingRanking';
import SalesSummary from 'features/clients/Client/Account/Advertising/Analytics/sections/SalesSummary';
import BestVsWorstTargeting from 'features/clients/Client/Account/Advertising/Analytics/sections/BestVsWorstTargeting';
import EffectsOfNonConverters from 'features/clients/Client/Account/Advertising/Analytics/sections/EffectsOfNonConverters';
import Funnel from 'features/clients/Client/Account/Advertising/Analytics/sections/Funnel';
import KeywordTracking from 'features/clients/Client/Account/Advertising/Analytics/sections/KeywordTracking';
import TopKeywordTracking from 'features/clients/Client/Account/Advertising/Analytics/sections/TopKeywordTracking';
import KeywordTrackingComparison from 'features/clients/Client/Account/Advertising/Analytics/sections/KeywordTrackingComparison';
import AsinLevelPerformance from 'features/clients/Client/Account/Advertising/Analytics/sections/AsinLevelPerformance';

const InteractiveReport = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [additionalParams, setAdditionalParams] = useState({ reportId });

  useEffect(() => {
    setLoading(true);

    axios.get(`/account/advertising/reports/${reportId}`).then((response) => {
      setReport(response.data.data);
      const { filters = {} } = response.data.data.options;
      let params = { ...additionalParams };

      if (filters.advCampaignIds && filters.advCampaignIds.length) {
        params.advCampaignIds = filters.advCampaignIds;
      }

      if (filters.advPortfolioIds && filters.advPortfolioIds.length) {
        params.advPortfolioIds = filters.advPortfolioIds;
      }

      if (filters.campaignTypes && filters.campaignTypes.length) {
        params.campaignTypes = filters.campaignTypes;
      }

      if (filters.productIds && filters.productIds.length) {
        params.productIds = filters.productIds;
      }
      setAdditionalParams(params);
      setLoading(false);
    });
  }, [reportId]);

  return loading ? (
    <div className="h-screen flex items-center justify-between">
      <p className="animate-pulse w-full text-center text-4xl font-inter font-bold tracking-2 text-grayscale-800">
        Loading Report. Please wait...
      </p>
    </div>
  ) : (
    <div className="pb-8 bg-grayscale-400">
      <Header
        startDate={report.startDate}
        endDate={report.endDate}
        client={report.client.client}
      />

      <div className="px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-28 mt-4 md:mt-6 lg:mt-8 xl:mt-12 2xl:mt-16">
        <div className="flex justify-between items-center">
          <p className="text-grayscale-900 tracking-3/4 leasing-1.2 font-inter font-bold text-25">
            Overview
          </p>

          <div className="flex items-center">
            <p className="mr-4 text-graysclae-900 text-mini font-sourceSansPro">
              Display Chart
            </p>
            <Switch
              checked={showChart}
              onChange={setShowChart}
              className={`${
                showChart ? 'bg-success' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Display Chart</span>
              <span
                className={`${
                  showChart ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white`}
              />
            </Switch>
          </div>
        </div>

        <AnalyticsOverview
          className="mt-10 grid grid-cols-6 gap-5"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
          comment={report.data.comments?.overview}
          editableComment={false}
        />

        {showChart && (
          <MetricsChart
            className="mt-10"
            accountId={report.advProfile.account.accountId}
            marketplace={report.advProfile.marketplace.countryCode}
            startDate={report.startDate}
            endDate={report.endDate}
            additionalParams={additionalParams}
          />
        )}

        <ActiveCampaignsOverview
          className="mt-10"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
          comment={report.data.comments?.breakdown}
          editableComment={false}
        />

        <CampaignTypesOverview
          className="mt-10 grid grid-cols-3 gap-6"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
        />

        <CampaignTypesBreakdown
          className="mt-10"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
        />

        {'productIds' in additionalParams ? null : (
          <TargetingTypeBreakdown
            className="mt-10"
            accountId={report.advProfile.account.accountId}
            marketplace={report.advProfile.marketplace.countryCode}
            startDate={report.startDate}
            endDate={report.endDate}
            additionalParams={additionalParams}
          />
        )}

        {'productIds' in additionalParams ? (
          <div className="grid grid-cols-2 gap-5">
            <SalesSummary
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              className="mt-10"
            />

            <Funnel
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              className="mt-10"
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-5">
            <SalesSummary
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              className="mt-10"
            />

            <BestVsWorstTargeting
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              className="mt-10"
            />
          </div>
        )}

        {'productIds' in additionalParams ? null : (
          <>
            <ConvertersOverview
              className="mt-10 grid grid-cols-2 grid-rows-1 rounded-3xl bg-white bs-shadow py-6"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              tableClassName="col-start-2"
              graphClassName="col-start-1 row-start-1"
              tableHeaderClassName="flex items-center justify-between px-8 pb-6"
              showAllGraph={true}
              graphTitle="Converter Share"
              comment={report.data.comments?.convertersVsNonConverters}
              editableComment={false}
            />
            <div className="grid grid-cols-2 gap-5">
              <EffectsOfNonConverters
                accountId={report.advProfile.account.accountId}
                marketplace={report.advProfile.marketplace.countryCode}
                startDate={report.startDate}
                endDate={report.endDate}
                additionalParams={additionalParams}
                className="mt-10"
              />

              <Funnel
                accountId={report.advProfile.account.accountId}
                marketplace={report.advProfile.marketplace.countryCode}
                startDate={report.startDate}
                endDate={report.endDate}
                additionalParams={additionalParams}
                className="mt-10"
              />
            </div>

            <KeywordPerformance
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              comment={report.data.comments?.keywordsPerformance}
              editableComment={false}
            />
            <TargetingRanking
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              comment={report.data.comments?.targetingRanking}
              editableComment={false}
            />
            <MostImprovedTargeting
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
              redirectToEntity={false}
              comment={report.data.comments?.mostImprovedTargeting}
              editableComment={false}
            />
          </>
        )}

        <MostImprovedProductAds
          className="mt-10"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
          redirectToEntity={false}
          comment={report.data.comments?.mostImprovedProductAds}
          editableComment={false}
        />

        {'productIds' in additionalParams ? null : (
          <>
            <KeywordTrackingComparison
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
            />

            <KeywordTracking
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
            />

            <TopKeywordTracking
              className="mt-10"
              accountId={report.advProfile.account.accountId}
              marketplace={report.advProfile.marketplace.countryCode}
              startDate={report.startDate}
              endDate={report.endDate}
              additionalParams={additionalParams}
            />
          </>
        )}

        <AsinLevelPerformance
          className="mt-10"
          accountId={report.advProfile.account.accountId}
          marketplace={report.advProfile.marketplace.countryCode}
          startDate={report.startDate}
          endDate={report.endDate}
          additionalParams={additionalParams}
          comment={report.data.comments?.asinLevelPerformance}
          editableComment={false}
          showPagination={false}
          exportable={false}
        />

        <div className="mt-12">
          <div className="mb-5 text-grayscale-900 bg-white p-5 rounded-2xl">
            <p className="tex-25 font-inter font-bold text-grayscale-900 tracking-3/4 leading-1.2 mb-2">
              Past Action
            </p>
            <div className="border p-3 rounded pb-8 text-grayscale-800 whitespace-pre-line">
              {report.data.passAction ?? report.data.pastAction}
            </div>
          </div>
          {['analysis', 'futurePlanOfAction'].map((attr) => {
            return (
              <div
                key={attr}
                className="mb-5 text-grayscale-900 bg-white p-5 rounded-2xl"
              >
                <p className="tex-25 font-inter font-bold text-grayscale-900 tracking-3/4 leading-1.2 mb-2">
                  {startCase(attr)}
                </p>
                <div className="border p-3 rounded pb-8 text-grayscale-800 whitespace-pre-line">
                  {report.data[attr]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(InteractiveReport);
