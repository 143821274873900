import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import usePermissions from 'hooks/usePermissions';
import { PlusIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import DatepickerSingle from 'components/DatepickerSingle';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import { Parser } from 'json2csv';

const Exporter = () => {
  const dispatch = useDispatch();
  const { userCan, isAgencySuperUser } = usePermissions();
  const [exporting, setExporting] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalHideBtn, setShowCalHideBtn] = useState(false);

  const calenderHandler = async () => {
    if (showCalHideBtn == true) {
      setLoading(true);
      setShowCalHideBtn(!showCalHideBtn);

      await convertCsv();
      setLoading(false);
    }
  };

  useEffect(() => {
    calenderHandler();
  }, [selectedDate]);

  const convertCsv = async () => {
    setExporting(true);
    const [month, day, year] = selectedDate.toLocaleDateString().split('/');
    let response;
    try {
      response = await axios.get(
        `/agency/credit-notes?page=1&pageSize=10&fields=dateApplied&search=%7B%22startDate%22:%22${
          year + '-' + month + '-' + day
        }%22,%22endDate%22:%22${
          year + '-' + month + '-' + day
        }%22%7D&sort=name:asc`
      );

      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(response.data.data.rows);
      const blob = new Blob([csv], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'creditnotes.csv';
      link.click();
    } catch (error) {
      dispatch(
        setAlert('error', 'No credit note found for the selected request date')
      );
    }

    setExporting(false);
  };

  return (
    <div className="flex items-center justify-end">
      <DropdownMenu
        title={
          <Button
            classes={'rounded-full'}
            border=" border-2 border-blue-900 tracking-widest font-bold py-2.5"
            hoverColor="blue-100"
            textColor="blue-900"
            bgColor="bg-white"
            onClick={() => {
              setShowCalHideBtn(!showCalHideBtn);
            }}
            showLoading={true}
            loading={exporting}
          >
            <PlusIcon className="h-4 w-4 mr-2" /> Export by Date
          </Button>
        }
        titleClasses="flex items-center mr-1"
        buttonBg="bg-transparent"
        buttonFontWeight="font-normal"
        hoverClasses="bg-none"
        textColor="text-cyan-600"
        classes="text-sm  rounded-2xl"
        buttonRounded=""
        hoverText="hover:text-cyan-500"
        dropdownWidth="w-88"
        padding=""
        position="absolute"
        hideArrow
        zIndex=""
        placement="top-start"
      >
        <DatepickerSingle value={selectedDate} onChange={setSelectedDate} />
      </DropdownMenu>
    </div>
  );
};

export default Exporter;
