import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCreditNoteRequests = createAsyncThunk(
  'creditNotes/getCreditNoteRequests',
  async (params, thunkAPI) => {
    const response = await axios.get('/agency/credit-notes', { params });
    return response.data.data;
  }
);

export const getClientsByPodId = createAsyncThunk(
  'creditNotes/getClientsByPodId',
  async (params, thunkAPI) => {
    if (params.podId !== null) {
      const { podId, isPpc } = params;
      const response = await axios.get(
        `/agency/client/pod/${podId}?isPpc=${isPpc}`
      );
      return response.data.data;
    } else {
      return [];
    }
  }
);

export const creditNotesSlice = createSlice({
  name: 'creditNotes',
  initialState: {
    creditNotes: [],
    clients: [],
    paginationParams: {
      page: 1,
      pageSize: 30,
      search: '',
      fields: 'all',
      sort: 'createdAt:desc',
      status: 'awaiting approval',
    },
    fieldOptions: [
      { label: 'All', value: 'all' },
      { label: 'Credit Request', value: 'name' },
      { label: 'Client Name', value: 'clientName' },
      { label: 'Request Date', value: 'dateApplied' },
      { label: 'Submitted By', value: 'requestedBy' },
    ],
  },
  extraReducers: {
    [getCreditNoteRequests.fulfilled]: (state, { payload }) => {
      state.creditNotes = payload;
    },
    [getClientsByPodId.fulfilled]: (state, { payload }) => {
      state.clients = payload;
    },
  },
});

export default creditNotesSlice.reducer;
