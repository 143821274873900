import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import doubleDotsVertical from 'assets/icons/doubleDotsVertical.svg';

export const Card = ({ id, text, index, moveCard, deleteCard }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    canDrop: (item) => {
      //Check dropable between drag and drop status
      if (item.index === 0) {
        return false;
      } else {
        return true;
      }
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex || dragIndex === 0) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={classNames(
        'flex flex-row justify-between rounded bg-grayscale-200 py-2 px-4 border mb-2 cursor-pointer hover:shadow-lg',
        isDragging ? 'opacity-0' : 'opacity-100'
      )}
    >
      <div>
        <p className="font-normal text-base text-grayscale-800 flex flex-row">
          <span className='flex flex-col justify-center pr-2'>
            <img src={doubleDotsVertical} alt={``} className="w-4 h-4" />
          </span>
          {text}
        </p>
      </div>
      <div>
        <button
          onClick={() => {
            deleteCard(index, id);
          }}
        >
          <XIcon className="w-3 h-3 text-grayscale-800" />
        </button>
      </div>
    </div>
  );
};
