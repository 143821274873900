import React, { useEffect, useState } from 'react';
import { useDayzed } from 'dayzed';
import { ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';
import { isFunction, min } from 'lodash';
import moment from 'moment-timezone';
import { calendarTime } from 'utils/formatters';
import TimePicker from 'react-time-picker';
import '../time-picker.css';

const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const weekdayNamesShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const preSelected = [
  {
    value: calendarTime(moment.tz(moment.tz.guess())),
    desc: moment.tz(moment.tz.guess()).format('ddd'),
    date: moment.tz(moment.tz.guess()).toDate(),
  },
  {
    value: calendarTime(moment.tz(moment.tz.guess()).add(1, 'days')),
    desc: moment.tz(moment.tz.guess()).add(1, 'days').format('ddd'),
    date: moment.tz(moment.tz.guess()).add(1, 'days').toDate(),
  },
  {
    value: 'This weekend',
    desc: moment.tz(moment.tz.guess()).endOf('week').format('ddd'),
    date: moment
      .tz(moment.tz.guess())
      .endOf('week')
      .subtract(1, 'days')
      .toDate(),
  },
  {
    value: 'Next week',
    desc: moment
      .utc()
      .add(1, 'week')
      .startOf('week')
      .add(1, 'days')
      .format('ddd'),
    date: moment
      .tz(moment.tz.guess())
      .add(1, 'week')
      .startOf('week')
      .add(1, 'days')
      .toDate(),
  },
  {
    value: 'Next weekend',
    desc: moment
      .tz(moment.tz.guess())
      .add(1, 'week')
      .endOf('week')
      .format('MMM D'),
    date: moment
      .utc()
      .add(1, 'week')
      .endOf('week')
      .subtract(1, 'days')
      .toDate(),
  },
  {
    value: '2 weeks',
    desc: moment
      .utc()
      .add(2, 'week')
      .startOf('week')
      .add(1, 'days')
      .format('MMM D'),
    date: moment
      .tz(moment.tz.guess())
      .add(2, 'week')
      .startOf('week')
      .add(1, 'days')
      .toDate(),
  },
  {
    value: '4 weeks',
    desc: moment
      .utc()
      .add(4, 'week')
      .startOf('week')
      .add(1, 'days')
      .format('MMM D'),
    date: moment
      .tz(moment.tz.guess())
      .add(4, 'week')
      .startOf('week')
      .add(1, 'days')
      .toDate(),
  },
];

function Calendar({ calendars, getBackProps, getForwardProps, getDateProps }) {
  if (calendars.length) {
    return (
      <div className="dayzed-calendar">
        {calendars.length > 1 && (
          <div className="nav">
            <button className="back" {...getBackProps({ calendars })}>
              <ChevronUpIcon className="w-5 h-5" />
            </button>
            <button className="next" {...getForwardProps({ calendars })}>
              <ChevronUpIcon className="w-5 h-5 transform rotate-180" />
            </button>
          </div>
        )}
        {calendars.map((calendar) => (
          <div
            key={`${calendar.month}${calendar.year}`}
            className="calendar space-y-1"
          >
            <div className="flex justify-between py-2">
              <div className="current-month">
                {monthNamesShort[calendar.month]} {calendar.year}
              </div>
              {calendars.length === 1 && (
                <div className="nav space-x-1.5">
                  <button
                    key={`${calendar.month}${calendar.year}back`}
                    className="back"
                    {...getBackProps({ calendars })}
                  >
                    <ChevronUpIcon className="w-5 h-5" />
                  </button>
                  <button
                    key={`${calendar.month}${calendar.year}next`}
                    className="next"
                    {...getForwardProps({ calendars })}
                  >
                    <ChevronUpIcon className="w-5 h-5 transform rotate-180" />
                  </button>
                </div>
              )}
            </div>
            {weekdayNamesShort.map((weekday, i) => (
              <div
                key={`${calendar.month}${calendar.year}${weekday}${i}`}
                className="calendar-cell inline-block border-0 bg-transparent"
              >
                {weekday}
              </div>
            ))}
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                let key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                if (!dateObj) {
                  return (
                    <div
                      className="calendar-cell inline-block border-0 bg-transparent"
                      key={key}
                    />
                  );
                }
                let {
                  date,
                  selected,
                  selectable,
                  today,
                  prevMonth,
                  nextMonth,
                } = dateObj;
                let background = today
                  ? 'bg-transparent border border-red-500 rounded-3xl'
                  : '';

                background = selected
                  ? 'border border-white bg-green-500 text-white'
                  : background;
                background = !selectable ? 'bg-gray-400' : background;
                return (
                  <button
                    className={classNames(
                      prevMonth && 'text-gray-200',
                      nextMonth && 'text-gray-200',
                      background,
                      'calendar-cell inline-block border-0 hover:bg-green-500 rounded-3xl'
                    )}
                    key={key}
                    {...getDateProps({
                      dateObj,
                    })}
                  >
                    {selectable ? date.getDate() : 'X'}
                  </button>
                );
              })
            )}
          </div>
        ))}
      </div>
    );
  }
  return null;
}

function Datepicker(props) {
  let dayzedData = useDayzed(props);
  return <Calendar {...dayzedData} />;
}

const DateTimePicker = ({ value, onChange, minDate = null }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const _handleOnDateSelected = ({ date, changeTime = false }) => {
    if (!changeTime) {
      date.setHours(selectedDate ? selectedDate.getHours() : 15);
    }

    onUpdate(date);
  };

  const onUpdate = (date) => {
    setSelectedDate(date);
    isFunction(onChange) && onChange(date);
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(moment(value).tz(moment.tz.guess()).toDate());
    }
  }, [value]);

  const handleTimeSelected = (time) => {
    const [hour, min] = time.split(':');
    const date = moment(selectedDate).tz(moment.tz.guess())
      ? moment(selectedDate).tz(moment.tz.guess())
      : moment();

    date.hours(hour);
    date.minutes(min);
    const dateTime = date.toDate();
    _handleOnDateSelected({ date: dateTime, changeTime: true });
  };

  return (
    <div className="flex divide-x">
      <div className="w-36 pt-4">
        {preSelected.map((d, i) => (
          <button
            key={i}
            className="flex justify-between w-full px-2 py-1 hover:bg-gray-100 transition-all duration-200 text-xs"
            onClick={() => onUpdate(new Date(d.date))}
          >
            <span>{d.value}</span>
            <span className="text-gray-400">{d.desc}</span>
          </button>
        ))}
        <button
          className="flex justify-between w-full px-2 py-1 hover:bg-gray-100 transition-all duration-200 text-xs text-red-500 uppercase tracking-wide border-t"
          onClick={() => onUpdate(null)}
        >
          <span>Clear</span>
          <span className="text-gray-400">&nbsp;</span>
        </button>
      </div>

      <div className="w-52">
        <Datepicker
          selected={selectedDate}
          onDateSelected={_handleOnDateSelected}
          showOutsideDays={true}
          minDate={minDate}
        />

        <div className="text-sm p-4">
          <p className="text-grayscale-700 mb-1 font-medium">
            {moment.tz.guess()}
          </p>

          <div className="flex items-center space-x-2">
            <p>Time:</p>
            <TimePicker
              format="hh:mm a"
              value={selectedDate}
              onChange={handleTimeSelected}
              disableClock={true}
              clearIcon={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
