import axios from 'axios';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from 'features/alerts/alertsSlice';
import { selectCurrentAccount } from 'features/accounts/accountsSlice';

import PageHeader from 'components/PageHeader';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';

const Authorization = ({ location }) => {
  const dispatch = useDispatch();

  const account = useSelector(selectCurrentAccount);
  const [loading, setLoading] = useState(false);
  const [hasFullAccess, setHasFullAccess] = useState(
    account
      ? !!account.credentials.find(
          (c) => c.service === 'spApi' && c.hasFullAccess
        )
      : false
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (
      query.has('spapi_oauth_code') &&
      query.has('account') &&
      query.has('state') &&
      query.has('selling_partner_id')
    ) {
      setLoading(true);
      const redirectUrl = window.location.hostname + window.location.pathname;

      axios
        .put('/account/credentials/authenticate/sp-api', {
          accountId: query.get('account'),
          oAuthCode: query.get('spapi_oauth_code'),
          state: query.get('state'),
          sellingPartnerId: query.get('selling_partner_id'),
          redirectUrl,
        })
        .then(() => {
          setLoading(false);
          setHasFullAccess(true);
          dispatch(setAlert('sucess', 'Authentication Successful.'));
          window.history.replaceState(null, null, window.location.pathname);
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            setAlert(
              'error',
              'Authentication Failed.',
              err.response.data.message
            )
          );
        });
    }
  }, [location.search]);

  return (
    <DashboardLayout>
      <PageHeader title="Authorization" />

      {hasFullAccess ||
      !!account.credentials.find(
        (c) => c.service === 'spApi' && c.hasFullAccess
      ) ? (
        <p className="mt-8 font-bold text-xl tracking-2 text-grayscale-900 text-center">
          All set. Your BetterSeller connection is up to date and has access to
          Business Report.
        </p>
      ) : (
        <div className="flex flex-col">
          <p className="mt-8 font-bold text-xl tracking-2 text-grayscale-900 text-center">
            Please re-authenticate your BetterSeller to give access to Business
            Report.
          </p>

          <div className="mt-8 justify-center flex">
            <a
              href={`https://sellercentral.amazon.com/apps/authorize/consent?application_id=${process.env.REACT_APP_SP_API_CLIENT_ID}&state=${account.accountId}&redirect_uri=${process.env.REACT_APP_SP_API_REDIRECT_URL}&version=beta`}
              className="px-4 py-2 border border-transparent shadow-sm font-bold tracking-2 rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {loading ? 'Authorizing..' : 'Login with Amazon'}
            </a>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default withRouter(Authorization);
