import React from 'react';

import { Switch } from '@headlessui/react';
import classNames from 'utils/classNames';

const Toggle = ({ checked, onChange, classes, title = '' }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={classNames(
        checked ? 'bg-green-500' : 'bg-gray-200',
        classes ? classes : '',
        'relative inline-flex flex-shrink-0 h-7 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
      )}
    >
      <span className="sr-only">{title}</span>
      <span
        className={classNames(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-6 w-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};

export default Toggle;
