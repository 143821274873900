import axios from 'axios';
import moment from 'moment';
import { startCase } from 'lodash';
import { Avatar, Table } from 'components';
import { useState, useEffect } from 'react';
import { PlusIcon, XIcon, CheckIcon } from '@heroicons/react/outline';

import FormModal from '../components/FormModal';
import { getNameInitials } from 'utils/formatters';

const statuses = {
  pending: {
    color: '#BFA654',
    bgColor: '#FFEFD0',
  },
  approved: {
    color: '#00966D',
    bgColor: '#CFFFDD',
  },
  rejected: {
    color: '#C30000',
    bgColor: '#FFAFAF',
  },
};

const PauseRequests = () => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [pauseRequests, setPauseRequests] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    sorts: 'createdAt',
    include: ['accountSpecialist[avatar]', 'brandStrategist[avatar]'],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/pause-requests/list', {
        params,
      });

      if (isSubscribed) {
        setPauseRequests(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params]);

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const refreshList = async () => {
    setLoading(true);
    const response = await axios.get('/v2/agency/pause-requests/list', {
      params,
    });

    setPauseRequests(response.data.data);
    setLoading(false);
  };

  const updatePauseRequest = (id, data) => {
    axios
      .put(`/v2/agency/pause-requests/${id}`, data)
      .then(() => refreshList());
  };

  const tableColumns = [
    {
      dataField: 'reason',
      text: 'Reason',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => moment(cell).format('YYYY-MM-DD'),
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => moment(cell).format('YYYY-MM-DD'),
    },
    {
      dataField: 'accountSpecialist.firstName',
      text: 'Account Specialist',
      sort: true,
      headerStyle: { minWidth: '250px' },
      formatter: (cell, row) =>
        !!cell ? (
          <div className="flex items-center space-x-2">
            <Avatar
              imageSrc={row.accountSpecialist?.avatar?.thumbnailUrl}
              initials={getNameInitials(cell, row.accountSpecialist.lastName)}
            />
            <div>
              <p className="leading-3">
                {cell} {row.accountSpecialist.lastName}
              </p>
            </div>
          </div>
        ) : (
          ''
        ),
    },
    {
      dataField: 'brandStrategist.firstName',
      text: 'Brand Strategist',
      sort: true,
      headerStyle: { minWidth: '250px' },
      formatter: (cell, row) =>
        !!cell ? (
          <div className="flex items-center space-x-2">
            <Avatar
              imageSrc={row.brandStrategist?.avatar?.thumbnailUrl}
              initials={getNameInitials(cell, row.brandStrategist.lastName)}
            />
            <div>
              <p className="leading-3">
                {cell} {row.brandStrategist.lastName}
              </p>
            </div>
          </div>
        ) : (
          ''
        ),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => (
        <span
          style={{
            color: statuses[cell]?.color,
            backgroundColor: statuses[cell]?.bgColor,
          }}
          className="px-4 py-1.5 rounded-xl"
        >
          {startCase(cell)}
        </span>
      ),
    },
    {
      dataField: 'pauseRequestId',
      text: 'Action',
      sort: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => {
        const disabled = row.status !== 'pending';

        return (
          <div className="flex space-x-2">
            <button
              disabled={disabled}
              className="disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => updatePauseRequest(cell, { status: 'approved' })}
            >
              <CheckIcon className="w-8 h-8" />
            </button>

            <button
              disabled={disabled}
              className="disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => updatePauseRequest(cell, { status: 'rejected' })}
            >
              <XIcon className="w-8 h-8" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white">
      <FormModal
        open={openForm}
        setOpen={setOpenForm}
        onSuccess={refreshList}
      />

      <div className="flex justify-between items-center px-4 py-6">
        <h2 className="font-inter font-bold text-xl tracking-3/4 leading-1.2 text-grayscale-900">
          Pause Request
        </h2>
        <button
          onClick={() => setOpenForm(true)}
          className="border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
        >
          <PlusIcon className="w-3 h-3" />
          <span>Add Request</span>
        </button>
      </div>

      <Table
        columns={tableColumns.map((column) => {
          return {
            ...column,
            classes:
              'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
            headerClasses:
              'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
          };
        })}
        data={pauseRequests}
        onTableChange={onTableChange}
        params={params}
        keyField="pauseRequestId"
        loading={loading}
      />
    </div>
  );
};

export default PauseRequests;
