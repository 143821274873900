import axios from 'axios';
import moment from 'moment';
import { useDrag } from 'react-dnd';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import SlideOver from 'components/SlideOver';
import BoardViewSlideOverForm from './BoardViewSlideOverForm';
import classNames from 'utils/classNames';
import { getFileThumbnailUrl } from 'utils/file';
import { currencyFormatter, getNameInitials } from 'utils/formatters';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const DealGroupCard = ({
  row,
  params,
  updateData,
  setIsRefreshStatus,
  isRefreshStatus,
  fetchRevenueDetailData,
  canDrag = true,
}) => {
  const [openSlide, setOpenSlide] = useState(false);
  const [lastestData, setLastestData] = useState([]);
  const classes = useStyles();

  const [, drag] = useDrag({
    type: 'card',
    canDrag,
    item: { data: row },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `/agency/sales/clients/${row.salesClientId}/latest-email-task`
      );

      setLastestData(response.data.data);
    };

    fetchData().catch(console.error);
  }, []);

  const handleOpenSlide = (e) => {
    e.stopPropagation();
    setOpenSlide(true);
  };

  return (
    <>
      <div
        className={classNames(
          'bg-white m-3 box-border h-auto overflow-x-auto cursor-pointer rounded-md border border-grayscale-500',
          canDrag ? 'hover:shadow-lg' : ''
        )}
        key={row.salesClientId}
        ref={drag}
        onClick={(e) => handleOpenSlide(e)}
      >
        <div className="flex flex-col p-2 border-b">
          <Link
            to={`/sales/client/${row.salesClientId}`}
            rel="noopener noreferrer"
            className="text-custom-sky text-base font-bold no-underline"
          >
            {row['lead.companyName']}
          </Link>

          {row.closedDealDate && (
            <p className="font-bold text-13 text-grayscale-900">
              Close Date:{' '}
              <span className="font-normal">
                {moment.utc(row.closedDealDate).format('L')}
              </span>
            </p>
          )}
          {row.totalAmountPaid > 0 && (
            <p className="font-bold text-13 text-grayscale-900">
              Amount:{' '}
              <span className="font-normal">
                {currencyFormatter(row.totalAmountPaid)}
              </span>
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row p-2">
            {row.leadRepId && (
              <div className="pr-2">
                <Tooltip
                  title={
                    row['leadRep.firstName'] + ' ' + row['leadRep.lastName']
                  }
                  arrow
                >
                  {row['leadRep.avatar.id'] ? (
                    <Avatar
                      src={getFileThumbnailUrl(
                        row['leadRep.avatar.name'],
                        row['leadRep.avatar.extension'],
                        row['leadRep.avatar.conversions']
                      )}
                      className={classes.small}
                    />
                  ) : (
                    <Avatar className={classes.small}>
                      <span className="text-sm">
                        {getNameInitials(
                          row['leadRep.firstName'],
                          row['leadRep.lastName']
                        )}
                      </span>
                    </Avatar>
                  )}
                </Tooltip>
              </div>
            )}

            {row.salesAdminId && (
              <div className="pr-2">
                <Tooltip
                  title={
                    row['salesAdmin.firstName'] +
                    ' ' +
                    row['salesAdmin.lastName']
                  }
                  arrow
                >
                  {row['salesAdmin.avatar.id'] ? (
                    <Avatar
                      src={getFileThumbnailUrl(
                        row['salesAdmin.avatar.name'],
                        row['salesAdmin.avatar.extension'],
                        row['salesAdmin.avatar.conversions']
                      )}
                      className={classes.small}
                    />
                  ) : (
                    <Avatar className={classes.small}>
                      <span className="text-sm">
                        {getNameInitials(
                          row['salesAdmin.firstName'],
                          row['salesAdmin.lastName']
                        )}
                      </span>
                    </Avatar>
                  )}
                </Tooltip>
              </div>
            )}

            {row.salesRepId && (
              <div className="pr-2">
                <Tooltip
                  title={
                    row['salesRep.firstName'] + ' ' + row['salesRep.lastName']
                  }
                  arrow
                >
                  {row['salesRep.avatar.id'] ? (
                    <Avatar
                      src={getFileThumbnailUrl(
                        row['salesRep.avatar.name'],
                        row['salesRep.avatar.extension'],
                        row['salesRep.avatar.conversions']
                      )}
                      className={classes.small}
                    />
                  ) : (
                    <Avatar className={classes.small}>
                      <span className="text-sm">
                        {getNameInitials(
                          row['salesRep.firstName'],
                          row['salesRep.lastName']
                        )}
                      </span>
                    </Avatar>
                  )}
                </Tooltip>
              </div>
            )}
          </div>
          <div className="px-2 pb-2">
            {lastestData.emailCreatedAt && (
              <p className="text-11 text-grayscale-800">
                <span>Emailed </span>
                {moment(lastestData.emailCreatedAt).fromNow()}
              </p>
            )}
            {lastestData.taskDueDate && (
              <p className="text-11 text-grayscale-800">
                <span>Task Due </span>
                {moment(lastestData.taskDueDate).fromNow()}
              </p>
            )}
          </div>
        </div>
      </div>
      <SlideOver
        open={openSlide}
        setOpen={setOpenSlide}
        title={row['lead.companyName']}
        titleClasses="capitalize"
        size="md"
      >
        <BoardViewSlideOverForm
          salesClientId={row.salesClientId}
          params={params}
          updateData={updateData}
          setIsRefreshStatus={setIsRefreshStatus}
          isRefreshStatus={isRefreshStatus}
          setOpenSlide={setOpenSlide}
          fetchRevenueDetailData={fetchRevenueDetailData}
        />
      </SlideOver>
    </>
  );
};

export default DealGroupCard;
