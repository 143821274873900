import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import Invoices from 'features/invoices/Invoices';
import CreditNotes from 'features/creditNotes/CreditNotes';
import Upsells from 'features/upsells/Upsells';
import CommissionErrors from 'features/billing/CommissionErrors/CommissionErrors';
import Payments from 'features/billing/Payments/Payments';
import Events from 'features/billing/Events/Events';
import Taxes from 'features/billing/Taxes';
import TaxExemptions from 'features/billing/TaxExemptions';
import Plans from 'features/billing/Plans';
import Terminated from 'features/clients/List/Terminated';
import Addons from 'features/billing/Addons';
import Transactions from 'features/billing/Transactions';

const BillingRoutes = [
  <PrivateRoute
    path="/invoices"
    component={Invoices}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <PrivateRoute
    path="/credit-notes"
    component={CreditNotes}
    layout={AgencyLayout}
    access={['agency']}
    permissions="creditNotes.list"
  />,
  <PrivateRoute
    path="/upsells"
    component={Upsells}
    layout={AgencyLayout}
    access={['agency']}
    permissions="upsells.list"
  />,
  <PrivateRoute
    path="/commission-errors"
    component={CommissionErrors}
    layout={AgencyLayout}
    access={['agency']}
    permissions="invoices.commissionerror.view"
  />,

  <PrivateRoute
    path="/payments"
    component={Payments}
    layout={AgencyLayout}
    access={['agency']}
    permissions="invoices.payments.view"
  />,

  <PrivateRoute
    path="/events"
    component={Events}
    layout={AgencyLayout}
    access={['agency']}
    permissions="invoices.events.view"
  />,
  <PrivateRoute
    path="/taxes"
    component={Taxes}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <PrivateRoute
    path="/tax-exemptions"
    component={TaxExemptions}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/plans"
    component={Plans}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <PrivateRoute
    path="/addons"
    component={Addons}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    exact={true}
    path="/billing/terminations"
    component={() => <Terminated invoiceSummary="UNPAID" />}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    exact={true}
    path="/billing/terminations/paid"
    component={() => <Terminated invoiceSummary="PAID" />}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <PrivateRoute
    path="/addons"
    component={Addons}
    layout={AgencyLayout}
    access={['agency']}
  />,
  <PrivateRoute
    path="/transactions"
    component={Transactions}
    layout={AgencyLayout}
    access={['agency']}
    permissions="invoices.payments.view"
  />,
];

export default BillingRoutes;
