import moment from 'moment';
import { useState } from 'react';

import Navigation from '../../components/Navigation';
import { MonthlyPartnersPerService, MonthlyRetainer } from './components';

const ExistingPartners = () => {
  const [year, setYear] = useState(moment().year().toString());

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex space-x-4 items-center">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Partners
          </h2>
        </div>
      </div>

      <Navigation />

      <div className="mt-8 space-y-8">
        <MonthlyRetainer year={year} setYear={setYear} />

        <MonthlyPartnersPerService
          dateRange={{
            endDate: moment(year).endOf('year').format('YYYY-MM-DD'),
            startDate: moment(year).startOf('year').format('YYYY-MM-DD'),
          }}
        />
      </div>
    </div>
  );
};

export default ExistingPartners;
