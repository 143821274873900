import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string, date } from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import useAlert from 'hooks/useAlert';
import { ModalHeader } from 'components';
import FormikErrorNotification from 'components/FormikErrorNotification';
import TinyEditor from 'components/Editor/TinyEditor';
import { pick } from 'lodash';
import { Label } from 'components/Forms';
import useAsset from 'hooks/useAsset';
import { DateTime } from 'luxon';
import { nameFormatter } from 'utils/formatters';
import ButtonLink from 'components/ButtonLink';
import { nanoid } from 'nanoid';
import ButtonDropdown from 'components/ButtonDropdown';
import { Menu } from '@headlessui/react';
import { LinkIcon, MailIcon } from '@heroicons/react/outline';
import useLink from 'hooks/useLink';

const QuoteModal = ({ open, setOpen, quote = null, onRefresh }) => {
  const { ASSET_URL } = useAsset();
  const { copyToClipboard } = useLink();
  const { client, salesReps } = useSelector((state) => state.sales);
  const [loading, setLoading] = useState(false);
  const [share, setShare] = useState(false);

  const { alertSuccess, alertError } = useAlert();
  const emptyValues = {
    salesClientId: client?.salesClientId,
    title: `${client?.lead.companyName} - QUOTE - SELLER INTERACTIVE`,
    status: 'draft',
    quoteContent: '',
    expiresAt: DateTime.now().plus({ month: 1 }).toFormat('yyyy-LL-dd'),
    createdBy: '',
    token: '',
  };
  const [initialValues, setInitialValues] = useState(emptyValues);
  const editorRef = useRef(null);
  const isReadOnly = useMemo(() => {
    return quote && quote.status === 'accepted';
  }, [quote]);

  useEffect(() => {
    setInitialValues(
      quote
        ? pick(quote, [
            'salesClientId',
            'title',
            'status',
            'quoteContent',
            'expiresAt',
            'createdBy',
            'token',
          ])
        : emptyValues
    );
  }, [quote]);

  const validationSchema = object().shape({
    salesClientId: string().required('salesClientId is required'),
    title: string().required('Title is required'),
    status: string().required('Status is required'),
    quoteContent: string().required('Quote Content is required'),
    expiresAt: date().nullable(),
    createdBy: string().required('Created By is required'),
    token: string(),
  });

  const onSubmit = async (values) => {
    setLoading(true);

    const token =
      !values.token || values.token === '' ? nanoid() : values.token;

    await axios
      .post(`/agency/sales/quotes${quote ? `/${quote.quoteId}` : ''}`, {
        ...values,
        token,
      })
      .then((response) => {
        alertSuccess('Success', response.data.message);
        if (share) copyToClipboard(`/quote/show/${token}`);
        onRefresh();
        setOpen(false);
      })
      .catch((error) =>
        alertError('Failed to create quote', error.response.data.message)
      )
      .finally(() => {
        setLoading(false);
        setShare(false);
      });
  };

  return (
    <Modal
      setOpen={setOpen}
      open={open}
      as="div"
      zIndex="z-20"
      align="middle"
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-5xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={`${quote ? 'Update' : 'Create'} Quote`}
          setOpen={setOpen}
          border="0"
          fontSize="text-2xl"
          fontStyle="font-bold"
          px="px-10"
          classes="pt-10"
        />
        <div
          className="flex flex-col px-10 pt-4 overflow-y-scroll"
          style={{ maxHeight: '80vh' }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form className="flex flex-col space-y-5">
                <FormikErrorNotification />

                <div className="">
                  <Label>Quote Title</Label>
                  <Field
                    name="title"
                    placeholder="Enter Title"
                    className="form-input disabled-white lg:text-xl"
                    type="text"
                    disabled={isReadOnly}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="lg:w-1/4 pb-5">
                  <Label>Expiry Date</Label>
                  <Field
                    name="expiresAt"
                    className="form-input disabled-white"
                    type="date"
                    disabled={isReadOnly}
                  />
                  <ErrorMessage
                    name="expiresAt"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <img src={`${ASSET_URL}/quote-header.png`} />

                <table className="table-responsive text-lg">
                  <tbody>
                    <tr>
                      <td className="p-5 leading-8">
                        <p>
                          <strong className="underline">
                            RECIPIENT DETAILS
                          </strong>
                        </p>
                        <p>
                          <strong>Company name:</strong>{' '}
                          {client.lead.companyName}
                        </p>
                        <p>
                          <strong>Full name:</strong> {client.decisionMaker}
                        </p>
                        <p>
                          <strong>Email address:</strong> {client.email}
                        </p>
                        <p>
                          <strong>Phone number:</strong>{' '}
                          {client.lead.phoneNumber}
                        </p>
                      </td>
                      <td className="p-5 leading-8">
                        <p>
                          <strong className="underline">
                            QUOTATION DETAILS:
                          </strong>
                        </p>
                        {quote && (
                          <p>
                            <strong>Quote #:&nbsp;</strong>
                            {quote.quoteNumber.padStart(5, '0')}
                          </p>
                        )}
                        <p>
                          <strong>Created by:&nbsp;</strong>
                          <Field
                            name="createdBy"
                            as="select"
                            className="form-select disabled-white"
                            disabled={isReadOnly}
                          >
                            <option value="" disabled>
                              Select Sales Rep
                            </option>
                            {salesReps.map((rep) => (
                              <option key={rep.userId} value={rep.userId}>
                                {nameFormatter(rep)}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="createdBy"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </p>
                        <p>
                          <strong>Date created: </strong>
                          {DateTime.now().toFormat('LL/dd/yyyy')}
                        </p>
                        {!quote && <p>&nbsp;</p>}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr />
                <hr />
                <div className="py-4">
                  {isReadOnly ? (
                    <div
                      className="text-lg"
                      dangerouslySetInnerHTML={{ __html: values.quoteContent }}
                    ></div>
                  ) : (
                    <TinyEditor
                      editorRef={editorRef}
                      initialValue="<p>This is the initial content of the editor.</p>"
                      value={values.quoteContent}
                      setValue={(newValue) =>
                        setFieldValue('quoteContent', newValue)
                      }
                    />
                  )}
                </div>

                <footer className="sticky bottom-0 w-full bg-white flex justify-between items-center space-x-4 py-5 z-40">
                  <ButtonLink
                    classes="tracking-2 font-bold"
                    color="blue"
                    onClick={() => setOpen(false)}
                  >
                    {quote ? 'Close' : 'Cancel'}
                  </ButtonLink>

                  {!isReadOnly && (
                    <div className="flex space-x-4">
                      <ButtonDropdown
                        buttonTitle={quote ? 'Save' : 'Create'}
                        buttonType="submit"
                        loading={loading}
                        showLoading={true}
                      >
                        <div className="flex flex-col text-sm text-grayscale-800">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className="p-2 flex items-center w-full justify-center hover:bg-blue-100 disabled:opacity-20"
                                disabled={quote?.isExpired}
                                onClick={() => {
                                  setFieldValue('status', 'sent');
                                  handleSubmit();
                                }}
                              >
                                <MailIcon className="w-4 h-4 inline" />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {quote ? 'Save' : 'Create'} &amp; Send
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className="p-2 flex items-center w-full justify-center hover:bg-blue-100 disabled:opacity-20"
                                disabled={quote?.isExpired}
                                onClick={() => {
                                  setShare(true);
                                  handleSubmit();
                                }}
                              >
                                <LinkIcon className="w-4 h-4 inline" />
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {quote ? 'Save' : 'Create'} &amp; Share
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </ButtonDropdown>
                    </div>
                  )}
                </footer>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default QuoteModal;
