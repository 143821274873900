import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Notifications from 'features/notification/Notifications';
import ProfileMenu from './ProfileMenu';
import MobileMenuButton from './MobileMenuButton';
import MarketplacePicker from './MarketplacePicker';
import logo from 'assets/logos/logo-orange.png';
import TaskTray from 'features/tasks/components/TaskTray';
import usePermissions from 'hooks/usePermissions';
import GlobalSearch from 'features/global/GlobalSearch';

const Header = ({ history, isOnboarding = false }) => {
  const { isApplicationLevel, isAgencyLevel } = usePermissions();

  return (
    <div
      className="relative flex-shrink-0 flex h-16 bg-white shadow"
      style={{ zIndex: 15 }}
    >
      {isOnboarding && (
        <div className="text-left pl-2 flex items-center">
          <Link to="/">
            <img className="h-10 w-auto inline" src={logo} alt="Workflow" />
          </Link>
        </div>
      )}

      {!isOnboarding && <MobileMenuButton />}

      <div className="flex-1 px-4 flex justify-end">
        {!isOnboarding && <TaskTray />}

        <div className="ml-2 flex items-center">
          {isApplicationLevel() && !isOnboarding && (
            <MarketplacePicker history={history} />
          )}
          {!isOnboarding && isAgencyLevel() && <GlobalSearch />}
          {!isOnboarding && <Notifications />}

          <ProfileMenu history={history} isOnboarding={isOnboarding} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
