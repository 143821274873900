import React, { useState } from 'react';
import { useRoles } from 'features/roles';

import Navigation from 'features/partners/components/Navigation';

import ClientSummary from './components/ClientSummary';
import TeamServiceTypeBreakdown from './components/TeamServiceTypeBreakdown';
import TeamMembers from './sections/TeamMembers';

const TeamLevel = () => {
  const { BRAND_STRATEGIST, ACCOUNT_SPECIALIST } = useRoles();

  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [year, setYear] = useState('2023');

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex space-x-4 items-center">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Partners
          </h2>
        </div>
      </div>

      <Navigation />

      <div className="mt-8 mb-6 flex justify-between items-center">
        <p className="font-inter font-bold text-xl tracking-3/4 leading-1.2 text-grayscale-900">
          Combined Client Count Across All Teams
        </p>

        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          <option>2023</option>
          <option>2022</option>
        </select>
      </div>

      <div className="space-y-8">
        <ClientSummary year={year} />

        <TeamServiceTypeBreakdown year={year} />

        <TeamMembers year={year} />
      </div>
    </div>
  );
};

export default TeamLevel;
