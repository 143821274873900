import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import {
  PlusIcon,
  SearchIcon,
  PencilIcon,
  XIcon,
  TrendingUpIcon,
} from '@heroicons/react/outline';
import FunnelIcon from 'components/icons/FunnelIcon';
import { startCase } from 'lodash';
import axios from 'axios';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';
import usePermissions from 'hooks/usePermissions';
import { dateFormatter, joiAlertErrorsStringify } from 'utils/formatters';
import useQueryParams from 'hooks/useQueryParams';
import useAlert from 'hooks/useAlert';
import CreateCreditNoteModal from './components/CreateCreditNoteModal';
import useCreditNotes from 'features/creditNotes/useCreditNotes';
import CreditNoteStatus from 'features/creditNotes/components/CreditNoteStatus';
import UserProfileAvatar from 'components/UserProfileAvatar';
import { columnClasses } from 'utils/table';
import PriceLabel from 'components/PriceLabel';
import CreditNoteReviewModal from 'features/creditNotes/CreditNoteReviewModal';

const CreditNotes = () => {
  const { agencyClient, billing } = useSelector((state) => state.agencyClient);
  const { userCan, isMine } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const [creditNotes, setCreditNotes] = useState(null);
  const { params, updateParams } = useQueryParams({
    ...billing.creditNotes.paginationParams,
    agencyClientId: agencyClient.agencyClientId,
  });
  const [open, setOpen] = useState(false);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedCreditNote, setSelectedCreditNote] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [selectedTitle, setSelectedTitle] = useState('');
  const { STATUS, isApprovedOrApplied } = useCreditNotes();

  useEffect(() => {
    if (!loading) getCreditNotes();
  }, [params]);

  const getCreditNotes = async () => {
    setLoading(true);
    await axios
      .get('/agency/credit-notes', { params })
      .then((response) => setCreditNotes(response.data.data))
      .catch((error) =>
        alertError('Failed to load credit notes', error.response.message)
      )
      .finally(() => setLoading(false));
  };

  const onConfirm = (row, status, title) => {
    setSelectedCreditNote(row);
    setSelectedStatus(status);
    setSelectedTitle(title);
    setModal(true);
  };

  const doCreditNoteAction = async (status) => {
    setModal(false);
    setSaving(true);
    try {
      const response = await axios.patch(
        `/agency/credit-notes/${selectedCreditNote.creditNoteId}`,
        { status }
      );
      alertSuccess('Success', response.data.message);
      getCreditNotes();
      setSaving(false);
      setOpen(false);
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    } finally {
      setSaving(false);
    }
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: {
        minWidth: '180px',
      },
      sort: true,
      formatter: (cell, row) => (
        <a
          onClick={() => {
            setSelectedCreditNote(row);
            row.status === STATUS.draft
              ? setOpen(true)
              : setIsOpenReviewModal(true);
          }}
          className="font-normal text-secondary-light cursor-pointer underline text-sm"
        >
          {cell}{' '}
          {row.upsell && (
            <TrendingUpIcon className="w-3.5 h-3.5 inline ml-0.5 transform -rotate-6 relative -top-0.5" />
          )}
        </a>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      classes: `${columnClasses} text-center`,
      formatter: (cell, row) => (
        <span className="font-normal">{dateFormatter(cell)}</span>
      ),
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      classes: `${columnClasses} text-right`,
      formatter: (cell, row) => <PriceLabel price={parseFloat(cell)} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        width: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => <CreditNoteStatus status={cell} />,
    },
    {
      dataField: 'requestedBy',
      text: 'Submitted By',
      headerStyle: {
        minWidth: '150px',
      },
      sort: true,
      formatter: (cell, row) => <UserProfileAvatar user={row.requester} />,
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        switch (row.status) {
          case STATUS.approved:
          case STATUS.applied:
            return row.approver ? (
              <UserProfileAvatar user={row.approver} />
            ) : (
              <>&mdash;</>
            );
          case STATUS.denied:
            return row.rejecter ? (
              <UserProfileAvatar user={row.rejecter} />
            ) : (
              <>&mdash;</>
            );
          default:
            return <>&mdash;</>;
        }
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      classes: `${columnClasses} text-center`,
      headerStyle: {
        width: '140px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        switch (row.status) {
          case STATUS.draft:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedCreditNote(row);
                  setOpen(true);
                }}
              >
                <PencilIcon className="w-3.5 h-3.5 inline mr-2" /> Edit
              </Button>
            );
          case STATUS.awaitingApproval:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => onConfirm(row, STATUS.cancelled, 'cancel')}
              >
                <XIcon className="w-3.5 h-3.5 inline mr-2" /> Cancel
              </Button>
            );
          default:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedCreditNote(row);
                  row.status === STATUS.draft
                    ? setOpen(true)
                    : setIsOpenReviewModal(true);
                }}
                disabled={isApprovedOrApplied(row.status) ? false : true}
              >
                <SearchIcon className="w-3.5 h-3.5 inline mr-2" /> View
              </Button>
            );
        }
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    console.log(sortField);
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };

  return (
    <div className="col-span-5 p-7 bg-white w-full">
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Credit Notes
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          <DropdownMenu
            title={<FunnelIcon />}
            titleClasses="flex items-center mr-1"
            button
            buttonBg="bg-transparent"
            buttonFontWeight="font-normal"
            hoverClasses="bg-none"
            textColor="text-cyan-600"
            classes="text-sm z-2 rounded-2xl"
            buttonRounded=""
            hoverText="hover:text-cyan-500"
            dropdownWidth="w-48"
            padding=""
            position=""
            hideArrow
          >
            <div className="px-1 py-1 flex flex-col">
              {Object.keys(STATUS).map((s, i) => (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        updateParams({ status: STATUS[s], page: 1 })
                      }
                      className="py-2 px-4 capitalize text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
                    >
                      {STATUS[s]}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </DropdownMenu>
          {userCan('creditNotes.request') && (
            <Button
              classes="rounded-full"
              border=" border-2 border-blue-900 tracking-widest font-bold py-2"
              hoverColor="blue-100"
              textColor="blue-900"
              bgColor="bg-white"
              onClick={() => {
                setSelectedCreditNote(null);
                setOpen(true);
              }}
              px={6}
              type="button"
            >
              <PlusIcon className="h-4 w-4 mr-2" /> Create Credit
            </Button>
          )}
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={creditNotes}
        onTableChange={onTableChange}
        params={params}
        keyField="creditNoteId"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
        loading={loading}
        rowClasses="hover:bg-gray-50"
      />

      <ConfirmationModal
        title={`${startCase(selectedTitle)} Credit Note?`}
        content={
          <div className="flex flex-col">
            <span>
              Credit Note Name:&nbsp;<b>{selectedCreditNote?.name}</b>
            </span>
            <span>
              Amount:&nbsp;<b>${selectedCreditNote?.amount}</b>
            </span>
          </div>
        }
        open={modal}
        setOpen={setModal}
        onOkClick={() => doCreditNoteAction(selectedStatus)}
        onCancelClick={() => setModal(false)}
      />

      <CreateCreditNoteModal
        open={open}
        setOpen={setOpen}
        action={selectedCreditNote ? 'update' : 'create'}
        creditNote={selectedCreditNote}
        getCreditNotes={getCreditNotes}
      />

      <CreditNoteReviewModal
        open={isOpenReviewModal}
        setOpen={setIsOpenReviewModal}
        creditNote={selectedCreditNote}
        onReload={getCreditNotes}
        type="creator"
      />
    </div>
  );
};

export default CreditNotes;
