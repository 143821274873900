import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, date, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import MultipleSelect from 'components/Forms/MultipleSelect';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { TrashIcon, PlusIcon } from '@heroicons/react/outline';
import { setAlert } from 'features/alerts/alertsSlice';
import {
  AGENCY_CLIENT_ACCOUNT_STATUSES,
  AGENCY_CLIENT_CATEGORIES,
  AGENCY_CLIENT_SERVICES,
} from 'utils/constants';
import { joiAlertErrorsStringify } from 'utils/formatters';
import Button from 'components/Button';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { fetchClient, setAgencyClient } from '../agencyClientSlice';
import moment from 'moment';

const Edit = () => {
  const [saving, setSaving] = useState(false);
  const { agencyClientId } = useParams();
  const [categories, setCategories] = useState({ categories: [] });
  const dispatch = useDispatch();
  const returnUrl = `/clients/${agencyClientId}/dashboard`;
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const history = useHistory();

  const emptyInitialValues = {
    client: '',
    serviceAgreementLink: '',
    address: '',
    phone: '',
    siEmail: '',
    contractSigned: '',
    contactName: '',
    contactName2: '',
    primaryEmail: '',
    secondaryEmail: '',
    thirdEmail: '',
    service: '',
    accountStatus: '',
    website: '',
    aboutUs: '',
    overview: '',
    painPoints: '',
    goals: '',
    categoryList: [],
    amazonPageUrl: '',
    asinList: [],
    otherNotes: '',
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const asinListObj = {
    asin: '',
  };

  useEffect(() => {
    setInitialValues({
      client: agencyClient.client,
      serviceAgreementLink: agencyClient.serviceAgreementLink,
      address: agencyClient.address,
      phone: agencyClient.phone ?? '',
      siEmail: agencyClient.siEmail,
      contractSigned: agencyClient.contractSigned
        ? moment(agencyClient.contractSigned).utc().format('YYYY-MM-DD')
        : null,
      contactName: agencyClient.contactName,
      contactName2: agencyClient.contactName2,
      primaryEmail: agencyClient.primaryEmail,
      secondaryEmail: agencyClient.secondaryEmail,
      thirdEmail: agencyClient.thirdEmail,
      service: agencyClient.service,
      accountStatus: agencyClient.accountStatus,
      website: agencyClient.website,
      aboutUs: agencyClient.aboutUs,
      overview: agencyClient.overview,
      painPoints: agencyClient.painPoints,
      goals: agencyClient.goals,
      categoryList: agencyClient.categoryList,
      amazonPageUrl: agencyClient.amazonPageUrl,
      asinList: agencyClient.asinList,
      otherNotes: agencyClient.otherNotes,
    });

    if (agencyClient.categoryList) {
      setCategories({
        categories: agencyClient.categoryList.map((rec) => rec.category),
      });
    }
  }, [agencyClientId]);

  const onCategoryChange = (value, setFieldValue) => {
    setCategories(value);
    const categoryList = categories.categories.map((category) => {
      return { category };
    });
    setFieldValue('categoryList', categoryList);
  };

  const validationSchema = object().shape({
    client: string().required('Required'),
    serviceAgreementLink: string().url().nullable(),
    address: string().nullable(),
    phone: string().nullable(),
    siEmail: string().email().nullable(),
    contractSigned: date().nullable(),
    contactName: string().nullable(),
    contactName2: string().nullable(),
    primaryEmail: string().nullable(),
    secondaryEmail: string().nullable(),
    thirdEmail: string().nullable(),
    service: string().nullable(),
    accountStatus: string().nullable(),
    website: string().url().nullable(),
    aboutUs: string().nullable(),
    overview: string().nullable(),
    painPoints: string().nullable(),
    goals: string().nullable(),
    categoryList: array()
      .of(
        object().shape({
          category: string().required('Required'),
        })
      )
      .nullable(),
    amazonPageUrl: string().url().nullable(),
    asinList: array()
      .of(
        object().shape({
          asin: string()
            .trim()
            .matches(/([A-Z0-9]{10})/, 'Incorrect ASIN format')
            .required('Required'),
        })
      )
      .nullable(),
    otherNotes: string().nullable(),
  });

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const response = await axios.patch(
        `agency/clients/${agencyClientId}`,
        values
      );

      if (response.data.success) {
        dispatch(
          setAlert(
            'success',
            'Update Success',
            `Agency Client details were successfully updated`
          )
        );
        dispatch(fetchClient(agencyClientId));
        setTimeout(() => {
          history.push(returnUrl);
        }, 500);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
        <Form>
          <FormikErrorNotification />
          <div className="flex flex-col space-y-3">
            <div className="col-span-2">
              <Label>
                Client <RequiredAsterisk />
              </Label>
              <Field
                name="client"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="client"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="serviceAgreementLink">
                Service Agreement Link
              </Label>
              <Field
                name="serviceAgreementLink"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="serviceAgreementLink"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="address">Address</Label>
              <Field
                name="address"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="phone">Phone</Label>
              <Field
                name="phone"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="tel"
              />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="siEmail">SI Email</Label>
              <Field
                name="siEmail"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="email"
              />
              <ErrorMessage
                name="siEmail"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="contractSigned">Contract Signed</Label>
              <Field
                name="contractSigned"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="date"
              />
              <ErrorMessage
                name="contractSigned"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="contactName">Contact Name</Label>
              <Field
                name="contactName"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="contactName"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="contactName2">Contact Name 2</Label>
              <Field
                name="contactName2"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="contactName2"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="primaryEmail">Primary Email</Label>
              <Field
                name="primaryEmail"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="email"
              />
              <ErrorMessage
                name="primaryEmail"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="secondaryEmail">Secondary Email</Label>
              <Field
                name="secondaryEmail"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="email"
              />
              <ErrorMessage
                name="secondaryEmail"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="thirdEmail">Third Email</Label>
              <Field
                name="thirdEmail"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="email"
              />
              <ErrorMessage
                name="thirdEmail"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="service">Service</Label>
              <Field
                name="service"
                as="select"
                className="form-select disabled-white"
              >
                <option value="">Select Service</option>
                {AGENCY_CLIENT_SERVICES.map((s) => {
                  return (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                name="service"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="accountStatus">Account Status</Label>
              <Field
                name="accountStatus"
                as="select"
                className="form-select disabled-white"
              >
                <option value="">Select account status</option>
                {AGENCY_CLIENT_ACCOUNT_STATUSES.map((as) => {
                  return (
                    <option key={as} value={as}>
                      {as}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                name="accountStatus"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="website">Website</Label>
              <Field
                name="website"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="website"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <div>
              <Label htmlFor="aboutUs">About Us</Label>
              <Field
                name="aboutUs"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
            </div>

            <div>
              <Label htmlFor="overview">Overview</Label>
              <Field
                name="overview"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
            </div>

            <div>
              <Label htmlFor="painPoints">Pain Points</Label>
              <Field
                name="painPoints"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
            </div>

            <div>
              <Label htmlFor="goals">Goals</Label>
              <Field
                name="goals"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
            </div>

            <MultipleSelect
              className="text-sm block text-gray-900"
              label="Product Categories"
              options={{ categories: AGENCY_CLIENT_CATEGORIES }}
              noSelectedText="No categories selected"
              selected={categories}
              setSelected={(value) => onCategoryChange(value, setFieldValue)}
              containerClass="p-3 sm:grid lg:grid-cols-2 xl:grid-cols-3 sm:gap-x-2 sm:gap-y-1"
            />

            <div>
              <Label htmlFor="amazonPageUrl">Amazon Page URL</Label>
              <Field
                name="amazonPageUrl"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="text"
              />
              <ErrorMessage
                name="amazonPageUrl"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>

            <FieldArray name="asinList">
              {({ insert, remove, push }) => (
                <>
                  <Label htmlFor="asinsToOptimize">ASINs to Optimize</Label>
                  {values.asinList && values.asinList.length > 0 && (
                    <div className="col-span-2 space-y-3 mb-4">
                      {values.asinList.map((asin, index) => (
                        <div
                          key={index}
                          className="sm:grid grid-cols-3 gap-x-2 "
                        >
                          <div className="col-span-3 flex justify-between">
                            <Label
                              fontWeight="font-bold"
                              textColor="text-gray-500"
                            >
                              ASIN {index + 1}
                            </Label>

                            <span
                              onClick={() => {
                                remove(index);
                              }}
                              className="cursor-pointer text-gray-500 hover:text-red-500"
                            >
                              <TrashIcon className="w-4 h-4 " />
                            </span>
                          </div>
                          <div className="col-span-3">
                            <Field
                              name={`asinList.${index}.asin`}
                              placeholder="Enter ASIN"
                              className="form-input disabled-white"
                              onChange={(e) => handleChange(e)}
                              type="text"
                            />
                            <ErrorMessage
                              name={`asinList.${index}.asin`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="col-span-2">
                    <Button
                      classes="font-bold tracking-wider w-full justify-center"
                      bgColor="gray-50"
                      hoverColor="gray-200"
                      roundedSize="2xl"
                      textColor="gray-600"
                      border="border-2 border-gray-600"
                      py={1}
                      shadow=""
                      onClick={(e) => {
                        push(asinListObj);
                      }}
                    >
                      <PlusIcon className="w-4 h-4 inline" /> Add ASIN to
                      optimize
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>

            <div>
              <Label htmlFor="otherNotes">Other Notes</Label>
              <Field
                name="otherNotes"
                as="textarea"
                rows={3}
                className="form-input disabled-white"
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="space-x-4 my-8">
                <Link
                  to={returnUrl}
                  className="tracking-wider font-bold text-red-500 hover:text-red-900"
                >
                  Cancel
                </Link>

                <Button
                  classes="border-0 font-bold tracking-wider "
                  bgColor="red-700"
                  hoverColor="red-400"
                  roundedSize="2xl"
                  textColor="white"
                  px={12}
                  py={1.5}
                  shadow=""
                  type="submit"
                  showLoading={true}
                  loading={saving}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default Edit;
