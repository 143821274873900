import Avatar from 'components/Avatar';
import { getNameInitials } from 'utils/formatters';
import { ExclamationIcon } from '@heroicons/react/outline';

const OverdueTask = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { sender, data, title, message } = notification;

  const onClick = () => {
    markAsRead();
    router.push(`/tasks/${data.taskItemId}`);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <div className="rounded-full bg-error flex items-center justify-center w-12 h-12">
        <ExclamationIcon className="w-8 h-8 text-white" />
      </div>

      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">
          {data.name}
        </p>
      </div>

      {children}
    </div>
  );
};

export default OverdueTask;
