import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import {
  XIcon,
  ExclamationIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { agoUTC } from 'utils/formatters';

const PotentialDuplicate = ({
  like,
  onContinueAnyway,
  maxLike,
  setMaxLike,
  onViewProfile,
  pitchCondition,
}) => {
  return (
    <div className="col-span-12">
      <Transition
        show={like.length > 0}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="overflow-y-auto max-h-100 bg-red-200 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 ">
          <div className="p-4">
            <div className="flex justify-between">
              <div>
                <button className="rounded-md inline-flex text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <ExclamationIcon
                    className="mt-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
                <span className="pl-2 text-lg">
                  Might duplicate record with:
                </span>
              </div>

              <div>
                <button
                  className="bg-white rounded-md inline-flex text-red-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={(e) => onContinueAnyway(e)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>

            <div>
              <ul className="pl-2 list-inside list-none text-red-400">
                <li key="header" className="text-lg py-1 text-red-600">
                  <div className="grid grid-cols-6 gap-4">
                    <div>Lead First Name</div>
                    <div>Lead Last Name</div>
                    <div>Company Name</div>
                    <div>Status</div>
                    <div>Pitch Date</div>
                    <div>Profile</div>
                  </div>
                </li>
                {like.map((rec, i) => {
                  return i < maxLike ? (
                    <li
                      key={rec.leadId}
                      className={
                        rec.lead
                          ? 'text-gray-900 break-words'
                          : 'text-blue-900 break-words'
                      }
                    >
                      <div className={'grid grid-cols-6 gap-4'}>
                        {'lead' in rec ? (
                          <div>{rec.lead}</div>
                        ) : (
                          <div className="font-medium ">Existing Client</div>
                        )}

                        {rec.lead ? (
                          <div>{rec.leadLastName}</div>
                        ) : (
                          <div className="font-medium ">-</div>
                        )}

                        <div>{rec.companyName}</div>
                        <div>{rec.status}</div>
                        <div>
                          {rec.pitchedDate ? agoUTC(rec.pitchedDate) : ''}
                        </div>
                        <div>
                          <UserCircleIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            onClick={() => onViewProfile(rec)}
                          />{' '}
                        </div>
                      </div>
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>

            <div className="pt-4 flex justify-between">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                <Link to={`/leads`}>Cancel</Link>
              </button>
              {maxLike === 5 && maxLike < like.length && (
                <button
                  onClick={(e) => {
                    setMaxLike(100);
                    e.preventDefault();
                  }}
                  className=" bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Show All
                </button>
              )}

              <button
                onClick={(e) => onContinueAnyway(e)}
                className={
                  pitchCondition
                    ? ' bg-gray-300 text-white font-bold py-2 px-4 rounded'
                    : ' bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                }
                disabled={pitchCondition ? true : false}
              >
                Continue Anyway
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default PotentialDuplicate;
