import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import useAsset from 'hooks/useAsset';
import { DownloadIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';
import Loading from 'components/Loading';
import { Button } from 'components';
import usePdf from 'hooks/usePdf';
import useAlert from 'hooks/useAlert';
import Badge from 'components/Badge';
import classNames from 'utils/classNames';
import ButtonLink from 'components/ButtonLink';

const QuoteHeader = ({ quote, setQuote, type = 'preview' }) => {
  const { url } = useRouteMatch();
  const { ASSET_URL } = useAsset();
  const { onDownloadPdf, downloading } = usePdf();
  const { alertSuccess } = useAlert();

  const canAccept = useMemo(() => {
    return (
      quote &&
      quote.status !== 'accepted' &&
      !url.includes('/quote/preview') &&
      !quote.isExpired
    );
  }, [url, quote]);

  const onAccept = async () => {
    await axios
      .patch(`/agency/sales/quotes/${quote?.quoteId}/accept`)
      .then((response) => {
        alertSuccess('Quote Accepted', 'Thanks for accepting this quote');
        setQuote(response.data.data);
      });
  };

  return (
    <div className="relative flex-shrink-0 flex py-4 bg-white shadow border-b border-grayscale-500 justify-center print:hidden">
      <div className="w-full px-4 space-y-4 sm:space-y-0 xl:w-3/5 sm:flex items-center justify-between">
        <div className="flex space-x-4">
          <img
            src={`${ASSET_URL}/si-logo.jpg`}
            alt="Seller Interactive"
            className="w-20"
          />
          <div className="flex flex-col items-left justify-center">
            <h1 className="text-xl tracking-tight">{quote?.title}</h1>
            {quote ? (
              <div className="flex items-center text-sm text-grayscale-600 leading-none">
                <span>{quote.quoteNumber.padStart(5, '0')}</span>
                <span>&nbsp;|&nbsp;</span>
                <span>{quote.salesClient.decisionMaker}</span>
                <span>&nbsp;|&nbsp;</span>
                <span>{quote.salesClient.lead.companyName}</span>
                <span>&nbsp;|&nbsp;</span>
                <span>
                  Expires:&nbsp;
                  <label
                    className={classNames(
                      'text-base leading-none',
                      quote.isExpired && 'text-error'
                    )}
                  >
                    {quote.isExpired
                      ? 'Expired'
                      : DateTime.fromFormat(
                          quote?.expiresAt,
                          'yyyy-LL-dd'
                        ).toRelative()}
                  </label>
                </span>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className="flex space-x-4 justify-end">
          {(type === 'preview' || (type === 'show' && !quote?.isExpired)) && (
            <ButtonLink
              className="flex items-center text-secondary-light"
              onClick={() =>
                onDownloadPdf(
                  `/agency/sales/quotes/${quote?.quoteId}/download`,
                  `${quote?.title}.pdf`
                )
              }
              showLoading={true}
              loading={downloading}
              spinnerColor="si-pink"
            >
              Download PDF <DownloadIcon className="inline w-4 h-4 ml-1" />
            </ButtonLink>
          )}

          {canAccept && (
            <Button
              classes="tracking-wider uppercase text-success hover:text-white bg-white hover:bg-success"
              border="border-2 border-success"
              color=""
              bgColor=""
              hoverColor="success"
              roundedSize="full"
              textColor=""
              textSize="base"
              px={10}
              py={1.5}
              shadow=""
              onClick={onAccept}
            >
              Accept
            </Button>
          )}
          {quote?.status === 'accepted' && (
            <Badge
              color="green"
              classes="uppercase text-center w-32 justify-center"
              rounded="full"
              padding="py-2"
              textSize="sm"
              fontFamily=""
            >
              Accepted
            </Badge>
          )}
        </div>
      </div>
    </div>
  );
};
export default QuoteHeader;
