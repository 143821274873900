import { PaperClipIcon } from '@heroicons/react/outline';
import ButtonLink from 'components/ButtonLink';
import useMailThread from 'hooks/useMailThread';
import classNames from 'utils/classNames';

const MailBody = ({
  data,
  threadData,
  message,
  attachments,
  onLoadThread,
  fetching,
  onDownloadAttachment,
}) => {
  const { threadHeader, threadHeaderContains } = useMailThread(threadData);
  const isLatestAttachment = (attachment) => {
    let yes = threadData.attachmentIds.find(
      (a) => a.attachmentId === attachment.attachmentId
    );
    return yes ? true : false;
  };

  return (
    <div className="text-sm pb-2 text-gray-800 overflow-x-auto">
      <div>
        {threadData ? (
          <div>
            <h1>{threadHeader('Subject')}</h1>
            <p className="mt-2 leading-tight">
              <span className="text-gray-400 mr-2 w-8 inline-block">Date</span>
              <span>{threadHeader('Date')}</span>
            </p>
            <p className="leading-tight">
              <span className="text-gray-400 mr-2 w-8 inline-block">From</span>
              <span>{threadHeader('From')}</span>
            </p>
            <p className="leading-tight">
              <span className="text-gray-400 mr-2 w-8 inline-block">To</span>
              <span>{threadHeader('To')}</span>
            </p>
            {threadHeaderContains('Cc') && (
              <p className="leading-tight">
                <span className="text-gray-400 mr-2 w-8 inline-block">Cc</span>
                <span>{threadHeader('Cc')}</span>
              </p>
            )}
            {threadHeaderContains('Bcc') && (
              <p className="leading-tight">
                <span className="text-gray-400 mr-2 w-8 inline-block">Bcc</span>
                <span>{threadHeader('Bcc')}</span>
              </p>
            )}

            <div
              className="mt-4 whitespace-pre-wrap border-l-2 pl-4 text-sm"
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></div>
            {threadData.allAttachments.length > 0 && (
              <div className="flex flex-col">
                <div className="text-base font-semibold mt-3 border-t py-3">
                  {threadData.allAttachments.length} attachment
                  {threadData.allAttachments.length > 1 && 's'}
                </div>
                {threadData.allAttachments.map((attachment, i) => (
                  <div key={i} className="flex items-center ">
                    <PaperClipIcon className="w-4 h-4 inline mr-1" />
                    <button
                      onClick={() => onDownloadAttachment(attachment)}
                      className={classNames(
                        isLatestAttachment(attachment)
                          ? 'text-green-500 hover:text-green-700'
                          : 'text-blue-300 hover:text-blue-500',
                        'hover:underline'
                      )}
                    >
                      {attachment.filename}
                    </button>
                    <span className="text-1xs text-gray-400 inline-block ml-2">
                      {!isLatestAttachment(attachment) &&
                        attachment.date?.value}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            ></div>
            <ButtonLink
              onClick={onLoadThread}
              classes="underline mt-4"
              loading={fetching}
              showLoading={fetching}
              color="yellow"
              textSize="xs"
              spinnerColor="red-500"
            >
              View Thread
            </ButtonLink>
          </>
        )}
      </div>
    </div>
  );
};

export default MailBody;
