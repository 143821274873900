import { useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import Button from 'components/Button';
import MediaUpload from './components/MediaUpload';
import ButtonLink from 'components/ButtonLink';
import classNames from 'utils/classNames';
import update from 'immutability-helper';
import { ViewGridIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { ConfirmationModal } from 'components';
import useAlert from 'hooks/useAlert';
import { useSelector } from 'react-redux';

function filesReducer(files, action) {
  switch (action.type) {
    case 'changed': {
      return update(files, {
        [action.file.order]: { $set: action.file },
      });
    }
    case 'uploaded': {
      return update(files, {
        [action.order]: { progress: { $set: action.progress } },
      });
    }
    case 'slideAdded': {
      return update(files, {
        [action.order]: {
          slide: { $set: action.slide },
          title: { $set: action.title },
          order: { $set: action.order },
        },
      });
    }
    case 'done': {
      return update(files, {
        [action.order]: { result: { $set: action.result } },
      });
    }
    case 'sorted': {
      return update(files, {
        $splice: [
          [action.dragIndex, 1],
          [action.hoverIndex, 0, files[action.dragIndex]],
        ],
      });
    }
    case 'reset': {
      return action.files;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const UploadImagesModal = ({
  open,
  setOpen,
  listingMockup,
  slides,
  onReloadSlides,
  onReorderSlides,
}) => {
  const { alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(null);
  const maxImages = 8;
  const items = [
    'Main Image',
    'Image 2',
    'Image 3',
    'Image 4',
    'Image 5',
    'Image 6',
    'Image 7',
    'Listing Video',
  ];
  const [files, dispatch] = useReducer(
    filesReducer,
    items.map((i, idx) => {
      return {
        title: i,
        file: null,
        progress: 0,
        type: i === 'Listing Video' ? 'video' : 'image',
        slide: null,
        result: null,
        order: idx,
      };
    })
  );
  const [reorder, setReorder] = useState(false);
  const [filesReorderCopy, setFilesReorderCopy] = useState(null);
  const [isOkReorder, setIsOkReorder] = useState(false);
  const { agencyClient } = useSelector((state) => state.agencyClient);

  useEffect(() => {
    if (open === true) {
      setReorder(false);
      setFilesReorderCopy(null);
    }
  }, [open]);

  useEffect(() => {
    if (slides) {
      items.map((i, idx) => {
        dispatch({
          type: 'slideAdded',
          title: i,
          slide: slides[idx],
          order: idx,
        });
        return;
      });
    }
  }, [slides]);

  function updateFile(file) {
    dispatch({
      type: 'changed',
      file,
    });
  }

  function uploadProgress(order, progress) {
    dispatch({
      type: 'uploaded',
      order,
      progress,
    });
  }

  function uploadDone(order, result) {
    dispatch({
      type: 'done',
      order,
      result,
    });
  }

  const filesCount = () => {
    const filled = files.map((f) => {
      const items = [
        f.file ? (f.file && f.file.length ? true : false) : false,
        f.slide ? true : false,
      ];
      return items.some((x) => x);
    });

    return filled.filter((x) => x).length;
  };

  const onFieldUpload = async (file, index) => {
    uploadProgress(index, 0);
    try {
      const formData = new FormData();
      formData.append('file', file.file[0]);
      formData.append('listingMockupId', listingMockup.listingMockupId);
      formData.append('type', file.type);
      formData.append(
        'listingMockupSlideId',
        file.slide ? file.slide.listingMockupSlideId : ''
      );
      formData.append('order', index);
      formData.append(
        'listingMockupRevisionId',
        file.slide && file.slide.revisions.length > 0
          ? file.slide.revisions[0].listingMockupRevisionId
          : ''
      );
      const response = await axios.post(
        '/agency/clients/listing-mockup/slides',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 50;
            uploadProgress(index, progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress =
              50 + (progressEvent.loaded / progressEvent.total) * 50;
            uploadProgress(index, progress);
          },
        }
      );
      uploadDone(index, response.data);
      return response.data;
    } catch (err) {
      uploadDone(index, err.response.data);
      return err.response.data.errors;
    }
  };

  const onStartUpload = async () => {
    setLoading(true);
    setHasErrors(null);
    const response = await Promise.all(
      files.map(async (file, i) => {
        if (file.file && file.file.length > 0) {
          return await onFieldUpload(file, i);
        } else {
          return { success: false, message: 'no file' };
        }
      })
    );
    const check = response.filter((d) => d === undefined);
    setHasErrors(check.length > 0 ? true : false);
    onReloadSlides(response);
    setLoading(false);
  };

  const onCheckPendingUploads = () => {
    const filled = files.map((f) => {
      return f.file && f.file.length ? true : false;
    });

    if (filled.some((x) => x)) {
      setIsOkReorder(true);
    } else {
      onReorder();
    }
  };

  const onReorder = () => {
    setIsOkReorder(false);
    setReorder(true);
    setFilesReorderCopy(files);
  };

  const onCancelReorder = () => {
    setReorder(false);
    dispatch({ type: 'reset', files: filesReorderCopy });
  };

  const onSaveOrder = async () => {
    let payload = files
      .map((file, i) => {
        return {
          order: i,
          slideId: file.slide ? file.slide.listingMockupSlideId : null,
        };
      })
      .filter((f) => f.slideId);

    try {
      const response = await axios.post(
        `/agency/clients/listing-mockup/slides/order`,
        { listingMockupId: listingMockup.listingMockupId, data: payload }
      );
      onReorderSlides(response.data.data);
      setReorder(false);
    } catch (error) {
      alertError('Reorder failed', error.response.data.message);
    } finally {
    }
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    dispatch({
      type: 'sorted',
      dragIndex,
      hoverIndex,
    });
  }, []);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-10"
    >
      <div className="inline-block w-full max-w-2xl my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4">
              <span>Upload Images</span>
            </div>
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <div className="pb-4 px-4 md:px-8 md:pb-8 text-lg">
          <p className="font-bold">
            Upload multiple files or drag and drop 1 or more files below.
          </p>
          <p>
            Maximum {maxImages} images are allowed. You can arrange the order
            after uploading.
          </p>
          <div className="flex justify-between items-center mt-4">
            <p
              className={classNames(
                filesCount() >= maxImages ? 'text-green-500' : 'text-red-500',
                'font-bold'
              )}
            >
              Uploaded {filesCount()} of {maxImages} images
            </p>
            {reorder ? (
              <div className="flex space-x-8 ">
                <ButtonLink
                  classes="tracking-wide font-bold text-gray-600"
                  color=""
                  onClick={onCancelReorder}
                >
                  Cancel
                </ButtonLink>
                <ButtonLink
                  classes="tracking-wider font-bold text-green-600 hover:bg-green-600 hover:text-white border border-green-600 rounded-full px-3 py-1"
                  color=""
                  onClick={onSaveOrder}
                >
                  Save Order
                </ButtonLink>
              </div>
            ) : (
              <ButtonLink
                classes="tracking-wider font-bold text-blue-900 hover:bg-blue-900 hover:text-white border border-blue-900 rounded-full px-2 py-1"
                color=""
                onClick={onCheckPendingUploads}
              >
                <ViewGridIcon className="w-4 h-4 inline mr-1" />
                Reorder
              </ButtonLink>
            )}
          </div>

          <div className="grid grid-cols-4 gap-x-10 gap-y-4 pt-8 px-4">
            {files.map((file, i) => (
              <MediaUpload
                key={file.title}
                index={i}
                file={file}
                onUpdate={updateFile}
                moveCard={moveCard}
                reorder={reorder}
              />
            ))}
          </div>
          <div className="flex justify-end">
            <div className="flex space-x-8 items-center mt-8 mb-0">
              <ButtonLink
                classes="tracking-wider font-bold"
                color="blue"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </ButtonLink>
              <Button
                classes="border-0 font-bold tracking-wider mr-2 disabled:opacity-60"
                bgColor="blue-800"
                hoverColor="blue-900"
                roundedSize="full"
                textColor="white"
                px={10}
                py={2}
                shadow=""
                type="button"
                showLoading={true}
                loading={loading}
                onClick={onStartUpload}
                disabled={reorder}
              >
                {hasErrors ? 'Retry' : 'Upload'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Reorder Items"
        content={
          <p>
            <ExclamationCircleIcon className="w-8 h-8 text-yellow-400 inline" />
            <br /> You currently have pending uploads. These will be removed if
            you continue
          </p>
        }
        open={isOkReorder}
        setOpen={setIsOkReorder}
        onOkClick={onReorder}
        onCancelClick={() => setIsOkReorder(false)}
      />
    </Modal>
  );
};
export default UploadImagesModal;
