import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRoles } from 'features/roles';
import { startCase, groupBy } from 'lodash';
import usePermissions from './usePermissions';
import PermissionCategory from './components/PermissionCategory';
import { debounce, omit } from 'lodash';

const RolePermission = () => {
  const { roleId } = useParams();
  const { selectedRole, fetchRoleDetails } = useRoles();
  const { PERMISSION_FEATURES, fetchPermissions } = usePermissions();

  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [rolePermissions, setRolePermissions] = useState([]);

  const [selectedFeature, setSelectedFeature] = useState(
    PERMISSION_FEATURES.CLIENTS
  );

  const [params, setParams] = useState({
    search: '',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      await fetchRoleDetails(roleId);

      const response = await fetchPermissions({
        'roles.roleId': roleId,
        feature: selectedFeature,
      });

      if (isSubscribed) {
        setRolePermissions(response);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [roleId, selectedFeature]);

  useEffect(() => {
    if (selectedFeature) {
      fetchPermissions({ feature: selectedFeature, ...params }).then(
        (permissions) => setPermissions(groupBy(permissions, 'category'))
      );

      return;
    }

    setPermissions({});
  }, [selectedFeature, params]);

  const onDebouncedSearch = debounce((e) => {
    setParams({ ...params, search: e.target.value });
  }, 500);

  return (
    <div className="space-y-8">
      <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900 mb-8">
        {startCase(selectedRole.data?.name)}
      </h2>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-8">
        <input
          type="search"
          placeholder="Search permission"
          defaultValue={params.search}
          onChange={(e) => onDebouncedSearch(e)}
          className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        />

        <select
          value={selectedFeature}
          onChange={(e) => setSelectedFeature(e.target.value)}
          className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          <option value="">Select Feature</option>
          {Object.keys(PERMISSION_FEATURES).map((key) => (
            <option key={key} value={PERMISSION_FEATURES[key].value}>
              {PERMISSION_FEATURES[key].label}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <h4 className="mt-24 text-center text-25 font-inter tracking-2 leading-1.5 text-grayscale-800 animate-pulse">
          Loading role and permissions
        </h4>
      ) : (
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-8">
          {Object.keys(permissions).map((category) => (
            <PermissionCategory
              key={category}
              roleId={roleId}
              category={category}
              permissions={permissions[category].map((permission) => {
                return {
                  ...permission,
                  enabled: rolePermissions.some(
                    (p) => p.access === permission.access
                  ),
                };
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RolePermission;
