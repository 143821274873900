import { useHistory, useLocation } from 'react-router-dom';

const BackButton = ({
  title,
  className = 'tracking-wider font-bold text-sm text-red-500 hover:text-red-900',
  backDefault = '/',
}) => {
  const history = useHistory();

  const back = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(backDefault);
    }
  };

  return (
    <button type="button" onClick={back} className={className}>
      {title}
    </button>
  );
};

export default BackButton;
