import axios from 'axios';
import { startCase } from 'lodash';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, ArrowNarrowDownIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/outline';

import TaskRow from '../TaskRow';
import NewTask from '../NewTask';
import TaskRowKanban from '../TaskRowKanban';
import NewTaskKanban from '../NewTaskKanban';

import classNames from 'utils/classNames';
import { numberFormatter } from 'utils/formatters';
import usePermissions from 'hooks/usePermissions';

const TaskGroup = ({
  title = '',
  groupKey = '',
  params = {},
  boardView = 'list',
  titleColor = 'white',
  titleBgColor = 'red',
  setReload,
  reload = false,
  taskListId = '',
  canDragInListView = false,
  canDragInBoardView = false,
  taskRowColumns = [],
  allowAddTask = false,
  defaultStatus = 'To Do',
  statusOptions = [],
  assigneeOptions = [],
  taskListOptions = [],
  withTaskType = false,
  updateParams,
}) => {
  const dispatch = useDispatch();
  const [addTask, setAddTask] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tasks, setTasks] = useState({ rows: [] });
  const { userCan } = usePermissions();

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const response = await axios.get('/agency/tasks/items/list', {
        params,
      });

      if (isSubscribed) {
        setTasks(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [dispatch, reload, params]);

  const onLoadMore = async () => {
    setIsLoadingMore(true);

    const response = await axios.get('/agency/tasks/items/list', {
      params: { ...params, page: tasks.nextPage },
    });

    setTasks({
      ...response.data.data,
      rows: [...tasks.rows, ...response.data.data.rows],
    });

    setIsLoadingMore(false);
  };

  const onItemChange = (key) => {
    if (key === groupKey) {
      setReload(!reload);
    }
  };

  const onAddNewTask = () => {
    setAddTask(false);
    setReload(!reload);
  };

  const [{ isActive }, drop] = useDrop({
    accept: 'card',
    collect: (monitor) => ({
      isActive:
        monitor.canDrop() &&
        monitor.isOver() &&
        (groupKey == 'status' || groupKey == 'priority'),
    }),
    drop: async (item) => {
      if (groupKey === 'status') {
        await axios.put(`/agency/tasks/items/${item.data.taskItemId}`, {
          status: title,
        });
      } else {
        let newPriority = item.data.priority;
        switch (title) {
          case 'No Priority':
            newPriority = null;
            break;
          case 'Low':
            newPriority = 'low';
            break;
          case 'Normal':
            newPriority = 'normal';
            break;
          case 'High':
            newPriority = 'high';
            break;
        }

        await axios.put(`/agency/tasks/items/${item.data.taskItemId}`, {
          priority: newPriority,
        });
      }

      setReload(!reload);
    },
  });

  const onSort = (column) => {
    const key = column === 'priority' ? 'priorityLevel' : column;

    const order = params.sort.includes(key)
      ? params.sort.includes('desc nulls last')
        ? 'asc'
        : 'desc nulls last'
      : 'desc nulls last';

    updateParams({ ...params, sort: `${key}:${order}` });
  };

  return (
    <div className="list-group">
      <div className={classNames('flex flex-col')} ref={drop}>
        <div className="sm:grid grid-cols-12 border-b border-grayscale-500">
          <div
            className={classNames(
              'flex left-0',
              boardView === 'list' ? 'col-span-6' : 'col-span-12'
            )}
          >
            <p
              className={classNames(
                'text-13 leading-1.5 font-bold py-3.5 px-2 flex items-center cursor-pointer',
                boardView === 'list' ? '' : 'justify-between w-full'
              )}
              style={{ color: titleColor, backgroundColor: titleBgColor }}
            >
              {boardView === 'list' && (
                <ChevronDownIcon
                  className={classNames(
                    'w-3 h-4',
                    isCollapse ? 'transform rotate-180' : ''
                  )}
                  onClick={() => setIsCollapse(!isCollapse)}
                />
              )}

              <span className="mx-2">{title}</span>

              <span
                style={{ borderColor: titleColor }}
                className="border px-2 mr-2 py-1 rounded-full text-11"
              >
                {numberFormatter(tasks.count)}
              </span>
            </p>
          </div>

          {boardView === 'list' && !!taskRowColumns.length && (
            <>
              {taskRowColumns.map((column) => (
                <div
                  key={`tskRowColumn-${column.key}`}
                  className={classNames('list-group-th', column.className)}
                >
                  {['priority', 'dueDate'].includes(column.key) ? (
                    <p
                      className="cursor-pointer flex"
                      onClick={() => onSort(column.key)}
                    >
                      {startCase(column.key)}

                      {params.sort.includes(column.key) && (
                        <span>
                          <ArrowNarrowDownIcon
                            className={classNames(
                              'w-3 h-4',
                              params.sort.includes('desc')
                                ? ''
                                : 'transform rotate-180'
                            )}
                          />
                        </span>
                      )}
                    </p>
                  ) : (
                    startCase(column.key)
                  )}
                </div>
              ))}
            </>
          )}
        </div>

        {(isCollapse || !!!tasks.rows.length) &&
          boardView === 'list' &&
          isActive && (
            <div className="border-2 border-dashed bg-white">
              <p className="text-center py-4 text-grayscale-700 text-base tracking-3/4">
                Drop Here
              </p>
            </div>
          )}

        {(!isCollapse || boardView === 'kanban') && (
          <div
            className={classNames(
              isActive ? 'border-2 border-dashed' : 'border-0'
            )}
            style={{ borderColor: titleBgColor }}
          >
            {isActive && (
              <p className="text-center bg-white py-4 text-grayscale-700 text-base tracking-3/4">
                Drop Here
              </p>
            )}

            <div
              className={classNames(isActive ? 'opacity-50' : 'opacity-100')}
            >
              {tasks.rows.map((row, i) =>
                boardView === 'kanban' ? (
                  <TaskRowKanban
                    data={row}
                    key={`${boardView}-${i}-${row.taskItemId}`}
                    onChange={onItemChange}
                    assigneeOptions={assigneeOptions}
                    canDrag={canDragInBoardView}
                    showClient={true}
                  />
                ) : (
                  <TaskRow
                    data={row}
                    onChange={onItemChange}
                    key={`${boardView}-${i}-${row.taskItemId}`}
                    assigneeOptions={assigneeOptions}
                    canDrag={canDragInListView}
                    showClient={true}
                    showTimer={false}
                    columns={taskRowColumns}
                  />
                )
              )}

              {!!tasks.rows.length &&
                tasks.rows.length !== tasks.count &&
                tasks.nextPage && (
                  <p
                    onClick={onLoadMore}
                    className="text-center py-2 text-grayscale-700 cursor-pointer font-inter text-sm"
                  >
                    {isLoadingMore ? 'Loading' : 'Load More'}
                  </p>
                )}
            </div>
          </div>
        )}

        {allowAddTask && (
          <div>
            {addTask ? (
              boardView === 'list' ? (
                <NewTask
                  isSubtask={false}
                  cancel={() => setAddTask(false)}
                  status={defaultStatus}
                  created={onAddNewTask}
                  withTaskType={withTaskType}
                  taskListId={taskListId}
                  statusOptions={statusOptions}
                  assigneeOptions={assigneeOptions}
                  taskListOptions={taskListOptions}
                />
              ) : (
                <NewTaskKanban
                  taskListId={taskListId}
                  isSubtask={false}
                  withTaskType={withTaskType}
                  cancel={() => setAddTask(false)}
                  status={groupKey === 'status' ? title : defaultStatus}
                  created={onAddNewTask}
                  assigneeOptions={assigneeOptions}
                  taskListOptions={taskListOptions}
                />
              )
            ) : (
              <div className="py-3 flex">
                <div className="sticky left-0 flex items-center px-6">
                  <button
                    onClick={() => setAddTask(true)}
                    className="text-gray-500 hover:text-gray-700 font-bold tracking-widest text-sm flex items-center"
                    disabled={!userCan('sales.workspace.task_items.create')}
                  >
                    <PlusIcon className="w-5 h-5 inline mr-1" /> New Task
                  </button>
                </div>
                <div>&nbsp;</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskGroup;
