import React, { useEffect, useState } from 'react';
import { Table } from 'components';
import { dateFormatter } from 'utils/formatters';
import { columnClasses, headerClasses } from 'utils/table';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import useAlert from 'hooks/useAlert';
import PriceLabel from 'components/PriceLabel';
import UserProfileAvatar from 'components/UserProfileAvatar';
import ReactJson from 'react-json-view';
import ToolTip from 'components/ToolTip';
import { InformationCircleIcon } from '@heroicons/react/solid';

const Transactions = () => {
  const { alertError } = useAlert();
  const { params, updateParams } = useQueryParams({
    page: 1,
    pageSize: 10,
    sort: 'createdAt:desc',
  });

  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTransactions = async () => {
    setLoading(true);
    await axios
      .get(`/billing/transactions`, { params })
      .then((response) => setTransactions(response.data.data))
      .catch((error) =>
        alertError('Failed to load transactions', error.response.data.message)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!loading) {
      getTransactions();
    }
  }, [params]);

  const tableColumns = [
    {
      dataField: 'transactionId',
      text: 'Transaction ID',
      sort: true,
    },
    {
      dataField: 'client.client',
      text: 'Client',
      headerStyle: {
        minWidth: '250px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            className="text-secondary-light"
            to={`/clients/${row.agencyClientId}/dashboard`}
          >
            {row.client?.client}
          </Link>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      dataField: 'transactedBy',
      text: 'Updated By',
      sort: true,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) =>
        row.transactor ? (
          <UserProfileAvatar
            user={row.transactor}
            breakNameFormat={false}
            size="32px"
            textSize="14px"
          />
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      headerStyle: {
        minWidth: '80px',
      },
      formatter: (cell, row) => {
        return <span>{dateFormatter(cell, 'DD MMM YYYY HH:MM A')}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        return row.paymentReceipt ? (
          <ToolTip
            title={
              <InformationCircleIcon className="w-5 h-5 inline text-secondary-light" />
            }
            arrowFill="#ccc"
            bg="bg-white"
          >
            <div className="text-xs">
              <ReactJson
                src={row.paymentReceipt}
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
                theme="grayscale:inverted"
                enableClipboard={false}
                iconStyle="square"
              />
            </div>
          </ToolTip>
        ) : (
          <>&mdash;</>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };

  return (
    <>
      <div className="sm:grid grid-cols-7 mb-8 mt-2">
        <div className="col-span-4 flex items-center space-x-4">
          <h1 className="text-3xl tracking-wide font-bold">Transactions</h1>
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={transactions}
        onTableChange={onTableChange}
        params={params}
        keyField="transactionId"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
        loading={loading}
      />
    </>
  );
};
export default Transactions;
