import axios from 'axios';
import * as yup from 'yup';
import { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { StopIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import { Button, Modal } from 'components';
import { Label } from 'components/Forms';
import moment from 'moment';

import useTermination from '../useTermination';
import { useAlerts } from 'features/alerts';
import usePermissions from 'hooks/usePermissions';

const InitiateTermination = ({ agencyClient, onInitiateTermination }) => {
  const { userCan } = usePermissions();
  const { popupAlert } = useAlerts();
  const [open, setOpen] = useState(false);
  const { REASONS, RETENTION_EFFORT_CHECKLIST } = useTermination();

  const onSubmit = (values, actions) => {
    axios
      .post('/v2/agency/terminations', values)
      .then((response) => {
        setOpen(true);
        onInitiateTermination();
        popupAlert({ type: 'success', title: response.data.message });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div>
      {agencyClient?.terminatedAt || agencyClient?.pendingTermination ? (
        <p className="text-error-dark text-13 leading-1.5 bg-error-light px-4 py-2 rounded-40">
          {agencyClient?.terminatedAt
            ? `Terminated last ${moment(agencyClient?.terminatedAt).format(
                'YYYY-MM-DD'
              )}`
            : 'Termination Ongoing'}
        </p>
      ) : (
        userCan('termination.create') && (
          <button
            onClick={() => setOpen(true)}
            className="flex items-center text-error-dark text-lg leading-1.5 tracking-2 font-bold space-x-1"
          >
            <StopIcon className="w-5 h-5" />
            <span>Terminate</span>
          </button>
        )
      )}

      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-20"
      >
        <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
          <div className="p-5 border-b border-grayscale-500 flex items-center justify-between">
            <div>
              <h4 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
                Termination Request
              </h4>

              <h5 className="font-inter text-xl leading-1.2 tracking-3/4 text-grayscale-900 mt-2">
                For {agencyClient?.client}
              </h5>
            </div>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>

          <div className="p-5">
            <Formik
              initialValues={{
                reason: '',
                moreInformation: '',
                agencyClientId: agencyClient?.agencyClientId,
                retentionEffortCheckList: [],
              }}
              onSubmit={onSubmit}
              validationSchema={yup.object().shape({
                reason: yup
                  .string()
                  .required('Please select a reason for termination'),
                moreInformation: yup
                  .string()
                  .required(
                    'Please provide more information about this termination request'
                  ),
                agencyClientId: yup.string().required(),
                retentionEffortCheckList: yup
                  .array()
                  .min(1, 'Checklist must have at least 1 item.'),
              })}
            >
              {({ isSubmitting }) => (
                <>
                  <Form>
                    <div className="space-y-6">
                      <div>
                        <Label>Reason for termination</Label>
                        <div>
                          <Field
                            as="select"
                            name="reason"
                            className="rounded-15 bg-grayscale-400 text-13 text-grayscale-900 leading-1.5 py-2 px-5 w-full"
                          >
                            <option value="">Select Reason</option>
                            {REASONS.map((reason) => (
                              <option value={reason} key={reason}>
                                {reason}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                      </div>

                      <div className="text-grayscale-900">
                        <Label classes="mt-2">Retention Effort Checklist</Label>
                        <div>
                          <Label textColor="text-grayscale-700">
                            Before we terminate this client, we wanna make sure
                            that all efforts are made. Kindly review the
                            checklist and confirm the following has been done.
                          </Label>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className="space-y-3">
                          {RETENTION_EFFORT_CHECKLIST.map((option) => {
                            return (
                              <div key={option} className="flex items-start">
                                <Field
                                  type="checkbox"
                                  name="retentionEffortCheckList"
                                  className="cursor-pointer mr-2"
                                  value={option}
                                />
                                <span className="text-13 text-grayscale-800">
                                  {option}
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        <ErrorMessage
                          name="retentionEffortCheckList"
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>

                      <div>
                        <Label>Additional Notes</Label>
                        <div>
                          <Field
                            rows={5}
                            as="textarea"
                            name="moreInformation"
                            className="rounded-15 border-0 text-13 text-grayscale-900 leading-1.5 p-2 w-full"
                          />

                          <ErrorMessage
                            name="moreInformation"
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <button
                          type="button"
                          onClick={(e) => {
                            setOpen(false);
                            e.preventDefault();
                          }}
                          className="text-sm leading-1.5 tracking-2 font-bold text-grayscale-700 hover:text-grayscale-900"
                        >
                          Cancel
                        </button>

                        <Button
                          px={8}
                          py={2}
                          type="submit"
                          roundedSize="40"
                          bgColor="error-dark"
                          hoverColor="error"
                          fontWeight="bold"
                          classes="leading-1.5 tracking-2"
                          textColor="grayscale-300"
                          showLoading={true}
                          loading={isSubmitting}
                        >
                          Request for Termination
                        </Button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InitiateTermination;
