import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import ChartToolTip from 'components/Charts/ChartTooltip';
import { percentageFormatter } from 'utils/formatters';

const AnalysisSnapshot = ({
  loading,
  records,
  setOpenClientList,
  setClientListFilterGroups,
}) => {
  return (
    <div className="bg-white p-4 mt-8">
      <div className="py-5">
        <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
          Churn Analysis Snapshot
        </p>
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
              <XAxis
                dataKey="dateRange"
                style={{
                  fontSize: '11px',
                  color: '#202020',
                  cursor: 'pointer',
                }}
                tickFormatter={(dateRange) =>
                  moment(dateRange.split('--')[0]).format('MMM')
                }
                onClick={(e) => {
                  const [startDate, endDate] = e.value.split('--');

                  setClientListFilterGroups(
                    JSON.stringify([
                      {
                        filters: [
                          {
                            attribute: 'contractSigned',
                            operator: 'LTE',
                            value: endDate,
                          },
                          {
                            attribute: 'subscriptionPaymentOption',
                            operator: 'EQ',
                            value: 'Recurring',
                          },
                          {
                            attribute: 'status',
                            operator: 'EQ',
                            value: 'ACTIVE',
                          },
                        ],
                      },
                      {
                        filters: [
                          {
                            attribute: 'contractSigned',
                            operator: 'LTE',
                            value: endDate,
                          },
                          {
                            attribute: 'subscriptionPaymentOption',
                            operator: 'EQ',
                            value: 'Recurring',
                          },
                          {
                            attribute: 'terminatedAt',
                            operator: 'HAS_PROPERTY',
                          },
                        ],
                      },
                    ])
                  );

                  setOpenClientList(true);
                }}
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(value) => percentageFormatter(value)}
              />

              <Bar
                yAxisId="0"
                stroke="#FF6868"
                fill="#FF6868"
                dataKey={'churnRate'}
                dot={false}
                strokeWidth={3}
                formatter={(value) => percentageFormatter(value)}
                name="Churn Rate"
              />

              <Line
                chartType="line"
                yAxisId="0"
                stroke="#C30000"
                type="linear"
                dataKey={'revenueChurnRate'}
                dot={false}
                strokeWidth={3}
                formatter={(value) => percentageFormatter(value)}
                name="Revenue Churn Rate"
              />

              <Tooltip
                content={
                  <ChartToolTip
                    width="64"
                    Title={({ payload }) => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {moment(payload[0].payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default AnalysisSnapshot;
