const useSurvey = () => {
  const STATUS = {
    ALL: '',
    SENT: 'sent',
    SEEN: 'seen',
    COMPLETED: 'completed',
  };

  return {
    STATUS,
  };
};

export default useSurvey;
