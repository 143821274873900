import { useState, Fragment, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CameraIcon } from '@heroicons/react/outline';
import { Avatar as MaterialAvatar } from '@material-ui/core';

import SpinnerGrow from './SpinnerGrow';

import classNames from 'utils/classNames';

const Avatar = ({
  imageSrc = '',
  initials = '',
  size = '32px',
  textSize = '16px',
  editable = false,
  isLoading = false,
  color = '#fff',
  bgColor = '#002F5D',
  onChange,
  onRemove,
}) => {
  const inputRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="rounded-full relative"
      style={{ width: size, height: size }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {editable &&
        (isLoading ? (
          <div className="w-full h-full absolute rounded-full bg-grayscale-500 z-20 flex items-center justify-center">
            <SpinnerGrow color="red-500" />
          </div>
        ) : (
          <div
            className={classNames(
              'w-full h-full absolute rounded-full',
              isHovering ? 'visible z-10' : 'invisible'
            )}
          >
            <input
              ref={inputRef}
              type="file"
              className="hidden"
              onChange={onChange}
              accept="image/*"
            />

            <Menu
              as="div"
              className="inline-block text-left absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <div>
                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <span className="sr-only">Open options</span>
                  <CameraIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-10 mt-2 w-20 origin-center left-auto right-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item
                      as="button"
                      onClick={() => inputRef.current.click()}
                      className="py-0.5 px-2 text-grayscale-800 text-11 tracking-3/4 hover:text-grayscale-900 hover:bg-grayscale-500 text-left w-full"
                    >
                      Change
                    </Menu.Item>
                    {imageSrc && (
                      <Menu.Item
                        as="button"
                        onClick={onRemove}
                        className="py-0.5 px-2 text-grayscale-800 text-11 tracking-3/4 hover:text-grayscale-900 hover:bg-grayscale-500 text-left w-full"
                      >
                        Remove
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        ))}

      <div
        className={classNames(
          'w-full h-full rounded-full',
          isHovering && editable ? 'opacity-50' : 'opacity-100'
        )}
      >
        {imageSrc ? (
          <MaterialAvatar
            alt={initials}
            src={imageSrc}
            style={{
              width: size,
              height: size,
              color: color,
              backgroundColor: bgColor,
            }}
          />
        ) : (
          <span
            className="inline-flex items-center justify-center rounded-full font-medium leading-none"
            style={{
              width: size,
              height: size,
              fontSize: textSize,
              color: color,
              backgroundColor: bgColor,
            }}
          >
            {initials}
          </span>
        )}
      </div>
    </div>
  );
};
export default Avatar;
