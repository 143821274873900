import React from 'react';
import { decimalFormatter } from 'utils/formatters';
import classNames from 'utils/classNames';
import { LUMPSUM, ONE_TIME } from 'utils/constants';
import { COMMISSION_TYPES } from 'utils/constants';

const Pricing = ({
  hostedpageDetails,
  addons,
  commissions,
  isNoCommission = false,
  service,
}) => {
  let addonsValue = hostedpageDetails?.addons ? hostedpageDetails.addons : [];

  const getCommission = () => {
    let commissionData = {
      title: isNoCommission
        ? false
        : commissions?.type === 'tiered'
        ? 'Tiered'
        : 'Commissions',
      subTitle:
        commissions?.type === 'tiered'
          ? 'Commission Rate'
          : COMMISSION_TYPES.some((el) => el.value === commissions?.type)
          ? COMMISSION_TYPES.find((el) => el.value === commissions?.type).label
          : '',
      currencyLabel: 'Rate %',
      currency: '%',
      price: commissions?.rate,
      dateType: null,
      label: commissions?.monthThreshold
        ? `Month Threshold ${commissions?.monthThreshold}`
        : null,
    };
    return commissionData;
  };

  const pricingData = [
    {
      title: service,
      subTitle:
        hostedpageDetails.paymentOption === LUMPSUM ||
        hostedpageDetails.paymentOption === ONE_TIME
          ? 'Service Fee'
          : 'Monthly Retainer',
      currencyLabel: 'USD',
      currency: '$',
      price: hostedpageDetails?.price,
      dateType:
        hostedpageDetails.paymentOption === LUMPSUM
          ? ` /${hostedpageDetails.interval}`
          : hostedpageDetails.paymentOption === ONE_TIME
          ? ''
          : ' / month',
    },
    // Commission
    getCommission(),
    ...addonsValue.map((el, i) => {
      return {
        title: addons
          ? addons.find((addon) => addon.addon_code === el.addon_code).name
          : '',
        subTitle: 'Add-on Service',
        currencyLabel: 'USD',
        currency: '$',
        price: el.price * el.quantity,
        dateType: el.type === 'one_time' ? `one time` : 'month',
        perAsin: el.type === 'one_time' ? `${el.quantity} Parent ASIN` : false,
      };
    }),
  ].filter((el) => el.title);

  return (
    <div
      className="p-6 lg:px-10 lg:py-14 grid grid-cols-2 gap-4 section"
      id="pricing"
    >
      <div className="col-span-2">
        <h2 className="text-4xl font-bold text-si-pink">Pricing</h2>
        <p className="py-6 text-md whitespace-pre-line">
          Honest and upfront charges take the stress out of accounting fees. See
          our pricing below.
        </p>
      </div>
      {pricingData.map((el, i) => {
        return el.title !== 'Tiered' ? (
          <div
            key={i}
            className="rounded-lg border border-solid border-gray-300 p-4 mr-5  mb-5"
          >
            <div className="flex justify-between p-4">
              <div>
                <div className="font-bold text-lg text-grayscale-900 leading-5">
                  {el.title}
                </div>
                <div>
                  <p className="text-sm font-normal leading-5 ">
                    {el.subTitle}
                  </p>
                </div>
              </div>

              <div>
                <div className="flex justify-start items-center text-sm font-normal leading-5">
                  {el.currencyLabel}
                  <span className="pr-1 pl-2 font-bold text-gray-900 text-2xl tracking-widest">
                    {el.price}
                  </span>
                  {el.dateType && <> {el.dateType}</>}
                  {el.perAsin && (
                    <>
                      {' '}
                      <br /> {el.perAsin}
                    </>
                  )}
                </div>
              </div>
            </div>
            {el.label && (
              <div className="flex justify-between px-4">{el.label}</div>
            )}
          </div>
        ) : (
          <div
            key={i}
            className={classNames(
              'rounded-lg border border-solid border-gray-300 p-4 mr-5  mb-5 ',
              `row-span-${commissions?.rules.length}`
            )}
          >
            <div className="flex justify-between p-4">
              <div>
                <div className="font-bold text-lg text-grayscale-900 leading-5">
                  {el.title}
                </div>
                <div>
                  <p className="text-sm font-normal leading-5 ">
                    {el.subTitle}
                  </p>
                </div>
              </div>
            </div>

            {commissions?.rules.length > 0 && (
              <>
                {commissions.rules.map((rule, idx) => (
                  <div className="p-4 grid grid-cols-12 gap-2" key={idx}>
                    <div className="col-span-2 pt-3">Rule {idx + 1}</div>
                    <div className="col-span-3">
                      <p>
                        <span className="text-sm">USD</span>{' '}
                        <span className="text-2xl font-semibold text-black">
                          {rule.min.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span className="text-sm">Min. Gross</span>
                      </p>
                    </div>
                    <div className="text-base mt-1">~</div>
                    <div className="col-span-4">
                      <p>
                        <span className="text-sm">USD</span>{' '}
                        <span className="text-2xl font-semibold text-black">
                          {rule.max.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span className="text-sm">Max Gross</span>
                      </p>
                    </div>
                    <div className="col-span-2">
                      <p>
                        <span className="text-2xl font-semibold text-black">
                          {rule.rate} %
                        </span>
                      </p>
                      <p>
                        <span className="text-sm">Rate</span>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Pricing;
