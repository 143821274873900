import { upperFirst } from 'lodash';
import moment from 'moment';

import classNames from 'utils/classNames';
import metrics from '../../../../utils/metrics';
import { percentageFormatter } from 'utils/formatters';

const attributes = [
  'impressions',
  'clicks',
  'ctr',
  'cpc',
  'cr',
  'sales',
  'cost',
  'acos',
  'tacos',
  'unitsSold',
  'advertisingSalesPercentage',
  'organicUnitsSold',
  'organicSalesPercentage',
  'totalSales',
];

const TableSummary = ({ loading, date, data = {} }) => {
  return (
    <div className="w-full mt-8">
      <table className="w-full">
        <thead>
          <tr>
            <td className="text-mini text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-4 bg-grayscale-200 border-b border-grayscale-500">
              Metrics
            </td>
            <td className="text-mini text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-4 bg-grayscale-200 border-b border-grayscale-500 text-right">
              {moment(date).format('MMMM YYYY')}
            </td>
            <td className="text-mini text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-4 bg-grayscale-200 border-b border-grayscale-500 text-right">
              {moment(date).subtract(1, 'month').format('MMMM YYYY')}
            </td>
            <td className="text-mini text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-4 bg-grayscale-200 border-b border-grayscale-500 text-right">
              Difference
            </td>
            <td className="text-mini text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap py-5 px-4 bg-grayscale-200 border-b border-grayscale-500 text-right">
              Percentage Change
            </td>
          </tr>
        </thead>

        <tbody>
          {attributes
            .map((attr) => metrics.find((m) => m.key === attr))
            .map((metric) => {
              const increase =
                data[metric.key] - data[`previous${upperFirst(metric.key)}`] >
                0;

              return (
                <tr key={`trends-snapshot-table-summary-${metric.key}`}>
                  <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4 whitespace-nowrap">
                    {metric.title}
                  </td>
                  <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4 whitespace-nowrap text-right">
                    {loading ? (
                      <div className="animate-pulse h-5 w-1/2 mt-1 ml-auto bg-grayscale-500 rounded"></div>
                    ) : (
                      metric.rawFormatter(data[metric.key])
                    )}
                  </td>
                  <td className="text-mini text-grayscale-800 leading-1.5 py-5 px-4 whitespace-nowrap text-right">
                    {loading ? (
                      <div className="animate-pulse h-5 w-1/2 mt-1 ml-auto bg-grayscale-500 rounded"></div>
                    ) : (
                      metric.rawFormatter(
                        data[`previous${upperFirst(metric.key)}`]
                      )
                    )}
                  </td>

                  <td
                    className={classNames(
                      increase ? 'text-success-dark' : 'text-error-dark',
                      'text-mini leading-1.5 py-5 px-4 whitespace-nowrap text-right'
                    )}
                  >
                    {loading ? (
                      <div className="animate-pulse h-5 w-1/2 mt-1 ml-auto bg-grayscale-500 rounded"></div>
                    ) : (
                      metric.rawFormatter(
                        data[metric.key] -
                          data[`previous${upperFirst(metric.key)}`]
                      )
                    )}
                  </td>

                  <td
                    className={classNames(
                      increase ? 'text-success-dark' : 'text-error-dark',
                      'text-mini leading-1.5 py-5 px-4 whitespace-nowrap text-right'
                    )}
                  >
                    {loading ? (
                      <div className="animate-pulse h-5 w-1/2 mt-1 ml-auto bg-grayscale-500 rounded"></div>
                    ) : data[`previous${upperFirst(metric.key)}`] ? (
                      percentageFormatter(
                        (data[metric.key] -
                          data[`previous${upperFirst(metric.key)}`]) /
                          Math.abs(data[`previous${upperFirst(metric.key)}`])
                      )
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default TableSummary;
