import { StopIcon, PlayIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { dateFormatter } from 'utils/formatters';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { useState } from 'react';
import { fetchInvoice } from '../invoiceSlice';
import useAlert from 'hooks/useAlert';
import playSvg from '../icons/play.svg';
import stopSvg from '../icons/stop1.svg';
import classNames from 'utils/classNames';

const AutoOptionCollect = () => {
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlert();
  const { invoice } = useSelector((state) => state.invoice);
  const [saving, setSaving] = useState(false);
  const onChangePauseCollect = async () => {
    const value =
      invoice.custom_field_hash.cf_pause_collect == null
        ? true
        : !invoice.custom_field_hash.cf_pause_collect_unformatted;

    const payload = {
      label: 'pause collect',
      value,
    };

    setSaving(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/customfields`,
        payload
      );

      alertSuccess(
        `Auto collect ${true ? 'OFF' : 'ON'}`,
        res.data.output.message
      );
      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
    } catch (error) {
      alertError('Cannot update auto collect', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  const isAutoCollect =
    invoice.custom_field_hash.cf_pause_collect &&
    invoice.custom_field_hash.cf_pause_collect_unformatted
      ? false
      : true;

  return (
    <div className="w-full flex items-center mb-8">
      <div className="text-sm text-gray-600 italic mr-2">
        <span
          className={`text-sm font-bold not-italic tracking-wider ${
            !isAutoCollect ? 'text-red-500' : 'text-green-500'
          }`}
        >
          AUTO COLLECT {!isAutoCollect ? ' OFF' : ' ON'}
        </span>
        {!isAutoCollect
          ? '. This invoice must be manually collected'
          : `. This invoice will be automatically collected on`}
        {isAutoCollect && (
          <b className="ml-1 text-green-500">
            {dateFormatter(invoice.due_date)}
          </b>
        )}
      </div>

      <Button
        onClick={onChangePauseCollect}
        loading={saving}
        showLoading={true}
        bgColor="transparent"
        hoverColor="transparent"
        textColor="gray-500"
        py={0}
        px={0}
        roundedSize="2xl"
        spinnerColor="red-500"
        data-tip
        data-for="autocollect"
      >
        <img src={isAutoCollect ? stopSvg : playSvg} />

        <ReactTooltip
          id="autocollect"
          type="light"
          effect="solid"
          className="shadow-lg rounded-lg opacity-100"
        >
          <span>
            {!isAutoCollect ? 'Start auto collect' : 'Stop auto collect'}
          </span>
        </ReactTooltip>
      </Button>
    </div>

    // <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    //   <div className="max-w-4xl mx-auto relative bg-white px-4 py-3 border-b">
    //     <div className="flex items-center justify-between">
    //       <span
    //         className={`text-xs ${
    //           invoice.custom_field_hash.cf_pause_collect &&
    //           invoice.custom_field_hash.cf_pause_collect_unformatted
    //             ? 'text-red-500'
    //             : 'text-green-500'
    //         }`}
    //       >
    //         <CreditCardIcon className="w-6 h-6 inline mr-2 text-gray-500" />
    //         {invoice.custom_field_hash.cf_pause_collect &&
    //         invoice.custom_field_hash.cf_pause_collect_unformatted ? (
    //           'Auto Collect OFF. This invoice must be collected manually'
    //         ) : (
    //           <>
    //             Auto Collect ON. This invoice will be collected automatically on
    //             <b className="ml-1 text-gray-500">
    //               {dateFormatter(invoice.due_date)}
    //             </b>
    //           </>
    //         )}
    //       </span>

    //       <Button
    //         onClick={onChangePauseCollect}
    //         loading={saving}
    //         showLoading={true}
    //         color={
    //           invoice.custom_field_hash.cf_pause_collect &&
    //           invoice.custom_field_hash.cf_pause_collect_unformatted
    //             ? 'green'
    //             : 'red'
    //         }
    //       >
    //         {invoice.custom_field_hash.cf_pause_collect &&
    //         invoice.custom_field_hash.cf_pause_collect_unformatted ? (
    //           <>
    //             <PlayIcon className="w-5 h-5 inline mr-2" />
    //             Auto collect
    //           </>
    //         ) : (
    //           <>
    //             <StopIcon className="w-5 h-5 inline mr-2" />
    //             Stop auto collect
    //           </>
    //         )}
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AutoOptionCollect;
