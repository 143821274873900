import axios from 'axios';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useAlert from 'hooks/useAlert';
import DataTable from 'components/DataTable';
import ReactJson from 'react-json-view';
import MarketplaceFlag from 'components/MarketplaceFlag';
import classNames from 'utils/classNames';
import {
  XIcon,
  CheckIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import { COMMISSIONTYPES, TYPE_DESCRIPTIONS } from 'utils/constants';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Label from 'components/Forms/Label';
import { DateTime } from 'luxon';
import ToolTip from 'components/ToolTip';
import SlideOver from 'components/SlideOver';

const Formulas = () => {
  const [open, setOpen] = useState(false);

  const Gross = () => (
    <b className="text-green-600 tracking-tighter">GROSS SALES</b>
  );
  const Rate = () => <b className="text-yellow-600 tracking-tighter">RATE</b>;
  const Months = () => <b className="text-pink-600 tracking-tighter">MONTHS</b>;
  const Baseline = () => (
    <b className="text-blue-600 tracking-tighter">BASELINE</b>
  );
  const Avg = () => (
    <b className=" text-blue-600 tracking-tighter">AVG. SALES</b>
  );
  const Op = ({ type }) => {
    switch (type) {
      case 'equals':
        return <label className="text-red-600 px-1 font-inter">=</label>;
      case 'minus':
        return <label className="text-red-600 px-1 font-inter">&minus;</label>;
      case 'times':
        return <label className="text-red-600 px-1 font-inter">&times;</label>;
      case 'divide':
        return <label className="text-red-600 px-1 font-inter">&divide;</label>;
      case 'sum':
        return <label className="text-red-600">SUM</label>;
      case 'op':
        return <label className="text-gray-800 pr-0.5">(</label>;
      case 'cp':
        return <label className="text-gray-800 pl-0.5">)</label>;
    }
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center"
        onClick={() => setOpen(true)}
      >
        <InformationCircleIcon className="w-4 h-4 inline text-blue-400" />
        &nbsp;
        <span className="text-secondary-light">Formula Guide</span>
      </button>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title="Formula Guide"
        titleClasses="capitalize"
        width="max-w-lg"
      >
        <div className="flow-root">
          <div className="bg-gray-50 p-2 text-xs mb-4">
            TERMS
            <ul>
              <li>
                <Gross />
                <Op type="equals" /> Previous month's sales metrics
              </li>
              <li>
                <Rate />
                <Op type="equals" /> RATE (%)
                <Op type="divide" />
                100
              </li>
              <li>
                <Months />
                <Op type="equals" /> Duration. # of months to retrieve sales
                metrics
              </li>
              <li>
                <Baseline />
                <Op type="equals" />
                Sales Metrics for # <Months /> before Activation Date
                <Op type="divide" />
                <Months />
              </li>
              <li>
                <Avg />
                <Op type="equals" />
                Sales Metrics for # <Months /> before <Gross />
                <Op type="divide" />
                <Months />
              </li>
            </ul>
          </div>

          <ul role="list" className="space-y-4">
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5 flex justify-between ">
                Gross
                <span className="text-2xs text-gray-500">
                  {TYPE_DESCRIPTIONS.gross}
                </span>
              </div>

              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Gross />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
              </div>
            </li>
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5 flex justify-between">
                Benchmark
                <span className="text-2xs text-gray-500">
                  {TYPE_DESCRIPTIONS.benchmark}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Op type="op" />
                    <Gross />
                    <Op type="minus" />
                    <Baseline />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="minus" />
                    ASIN&nbsp;
                    <Baseline />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
              </div>
            </li>
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5 flex justify-between">
                Rolling Average
                <span className="text-2xs text-gray-500">
                  {TYPE_DESCRIPTIONS.rolling}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Op type="op" />
                    <Gross />
                    <Op type="minus" />
                    <Avg />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="minus" />
                    ASIN <Avg />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
              </div>
            </li>
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5 flex justify-between">
                Tiered
                <span className="text-2xs text-gray-500">
                  {TYPE_DESCRIPTIONS.tiered}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Gross />
                    <Op type="times" /> Matched Rule <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="cp" />
                    <Op type="times" />
                    Matched Rule <Rate />
                  </span>
                </p>
              </div>
            </li>
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5 flex justify-between">
                Yearly Sales Improvement
                <span className="text-2xs text-gray-500">
                  {TYPE_DESCRIPTIONS.yearlySalesImprovement}
                </span>
              </div>
              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Op type="op" />
                    <Gross />
                    <Op type="minus" /> Last year's gross sales
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="minus" />
                    Last year's ASIN gross sales
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
              </div>
            </li>
            <li className="relative border rounded-lg overflow-hidden">
              <div className="text-sm bg-yellow-50 text-yellow-500 px-2 py-1.5">
                PPC Revenue
              </div>
              <div className="mt-1 text-sm text-gray-600 flex flex-col divide-y px-2 ">
                <p className="flex justify-between items-center py-0.5">
                  <span>Default</span>
                  <span className="text-xs">
                    <Gross />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
                <p className="flex justify-between items-center py-0.5">
                  <span>Managed Asins</span>
                  <span className="text-xs">
                    <Op type="sum" />
                    <Op type="op" />
                    ASIN <Gross />
                    <Op type="cp" />
                    <Op type="times" />
                    <Rate />
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </SlideOver>
    </>
  );
};

const ComputedCommissions = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const [computedValues, setComputedValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenCompute, setIsOpenCompute] = useState(false);
  const [computing, setComputing] = useState(false);
  const [monthToCompute, setMonthToCompute] = useState(
    DateTime.now().toFormat('yyyy-LL')
  );
  const { alertSuccess, alertError } = useAlert();
  const [isOpenFormula, setIsOpenFormula] = useState(false);

  const getComputedValues = async () => {
    setLoading(true);
    await axios
      .get(`/agency/commissions/account/${agencyClient.accountId}/computed`)
      .then((response) => {
        console.log(response.data.data.rows);
        setComputedValues(response.data.data.rows);
      })
      .catch((error) => {
        alertError(
          'Failed to load computed values',
          error.response.data.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!computedValues) {
      getComputedValues();
    }
  }, [computedValues]);

  const columns = [
    { header: 'Computed Month', accessorKey: 'computedMonth' },
    { header: 'Type', accessorKey: 'commission.type' },
    {
      header: 'Managed ASINs',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();

        const hasAsins =
          row.commission.managedAsins && row.commission.managedAsins.length > 0;
        const ma = hasAsins
          ? row.commission.managedAsins.filter((m) => m.asin !== '')
          : null;

        return ma?.length > 0 ? (
          <ToolTip
            title={
              <div className="flex items-center ">
                <span className="text-secondary-light">{ma.length} ASINS</span>
                &nbsp;&nbsp;
                <InformationCircleIcon className="w-4 h-4 inline text-blue-400" />
              </div>
            }
            arrowFill="#000"
            bg="bg-black"
          >
            <div className="text-xs">
              <ReactJson
                src={ma}
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
                theme="summerfruit"
                enableClipboard={false}
              />
            </div>
          </ToolTip>
        ) : (
          <>&mdash;</>
        );
      },
    },
    {
      header: 'Data',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        if (row.commission.type === COMMISSIONTYPES.BENCHMARK) {
          return (
            <>
              <sub>({row.commission.monthThreshold}-MONTH) BASELINE:</sub>
              <br />{' '}
              {row.commission.managedAsins &&
              row.commission.managedAsins.length > 0
                ? row.commission.managedAsins
                    .filter((m) =>
                      m.asin.match(/[0-9]{9}[0-9X]|[A-Z][A-Z0-9]{9}/)
                    )
                    .reduce((a, b) => a + b.baseline, 0)
                : row.commission.baseline}
            </>
          );
        }

        if (row.commission.type === COMMISSIONTYPES.ROLLING) {
          return (
            <>
              <sub>({row.commission.monthThreshold}-MONTH) AVG. SALES:</sub>
              <br />
              <ToolTip
                title={
                  <div className="flex items-center ">
                    <span className="text-secondary-light">
                      {row.historicalSalesData.totalAverageSales.toFixed(2)}
                    </span>
                    &nbsp;&nbsp;
                    <QuestionMarkCircleIcon className="w-4 h-4 inline text-blue-400" />
                  </div>
                }
                arrowFill="#000"
                bg="bg-black"
              >
                <div className="text-xs">
                  <ReactJson
                    src={row.historicalSalesData}
                    name={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    theme="summerfruit"
                    enableClipboard={false}
                  />
                </div>
              </ToolTip>
            </>
          );
        }

        if (row.commission.type === COMMISSIONTYPES.TIERED) {
          return (
            <ToolTip
              title={
                <div className="flex items-center ">
                  <span className="text-secondary-light">RULES</span>
                  &nbsp;&nbsp;
                  <InformationCircleIcon className="w-4 h-4 inline text-blue-400" />
                </div>
              }
            >
              <div className="text-xs">
                {row.commission.rules.length > 0 && (
                  <div className="col-span-2 space-y-3 mb-4">
                    <div className="sm:grid grid-cols-4 gap-2">
                      <div className="col-span-1">&nbsp;</div>
                      <div className="col-span-1">Min. Gross</div>
                      <div className="col-span-1">Max. Gross</div>
                      <div className="col-span-1">Rate</div>
                    </div>
                    {row.commission.rules.map((tieredRule, index) => (
                      <div key={index} className="sm:grid grid-cols-4 gap-2">
                        <div className="col-span-1">Rule {index + 1}</div>
                        <div className="col-span-1">{tieredRule.min}</div>
                        <div className="col-span-1">{tieredRule.max}</div>
                        <div className="col-span-1">{tieredRule.rate}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ToolTip>
          );
        }

        if (row.commission.type === COMMISSIONTYPES.YEARLYSALESIMPROVEMENT) {
          return (
            <>
              <sub>PREV. YEAR SALES:</sub>
              <br />
              {row.historicalSalesData.totalPreviousYearSales}
            </>
          );
        }

        return <>&mdash;</>;
      },
    },
    {
      header: 'Rate (%)',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return row.commission.type === COMMISSIONTYPES.TIERED
          ? row.data.computed.rate
          : row.commission.rate;
      },
    },
    {
      header: 'Gross Sales ($)',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return row.monthGrossSalesTotal ? (
          <ToolTip
            title={
              <div className="flex items-center ">
                <span className="text-secondary-light">
                  {row.monthGrossSalesTotal?.toFixed(2)}
                </span>
                &nbsp;&nbsp;
                <QuestionMarkCircleIcon className="w-4 h-4 inline text-blue-400" />
              </div>
            }
            arrowFill="#000"
            bg="bg-black"
          >
            <div className="text-xs">
              <ReactJson
                src={row.orderMetrics}
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
                theme="summerfruit"
                enableClipboard={false}
              />
            </div>
          </ToolTip>
        ) : (
          <span>&mdash;</span>
        );
      },
    },
    {
      header: 'Avg. Sales ($)',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return row.monthAverageSalesTotal ? (
          row.monthAverageSalesTotal?.toFixed(2)
        ) : (
          <span>&mdash;</span>
        );
      },
    },
    {
      header: 'Amount ($)',
      accessorKey: 'total',
      cell: (cell) => (
        <span
          className={classNames(
            cell.getValue() > 0 ? 'text-green-500' : 'text-red-500',
            'font-bold tracking-wider'
          )}
        >
          {cell.getValue()}
        </span>
      ),
    },
    {
      header: 'Add To Invoice',
      accessorKey: 'canAdd',
      cell: (cell) =>
        cell.getValue() ? (
          <CheckIcon className="w-4 h-4 text-green-500" />
        ) : (
          <XIcon className="w-4 h-4 text-red-500" />
        ),
    },
    {
      header: 'Marketplace',
      accessorKey: 'commission.marketplace',
      cell: (cell) => <MarketplaceFlag marketplace={cell.getValue()} />,
    },
    {
      header: 'Inv #',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return (
          <Link
            className="hover:text-secondary text-secondary-light"
            to={`/invoices/${row.appliedToInvoiceId}`}
          >
            {row.appliedToInvoiceNumber}
          </Link>
        );
      },
    },
    {
      header: 'Updated',
      accessorKey: 'updatedAt',
      cell: (cell) => (
        <span>{DateTime.fromISO(cell.getValue()).toFormat('yyyy-LL-dd')}</span>
      ),
    },
  ];

  const onCompute = async () => {
    setComputing(true);
    await axios
      .post('/agency/commissions/compute-month', {
        accountId: agencyClient.accountId,
        invoiceDate: monthToCompute,
        force: true,
      })
      .then(async (response) => {
        await getComputedValues();
        setIsOpenCompute(false);
      })
      .catch((error) => {
        alertError(
          'Failed to compute commissions',
          error.response.data.message
        );
      })
      .finally(() => setComputing(false));
  };

  return (
    <div className="col-span-5 flex flex-col bg-white px-8 py-8 pb-16">
      <div className="flex items-center space-x-4">
        <h4 className="text-2xl font-bold">Computed Commissions (Monthly)</h4>
        <Button
          classes="rounded-full  border-blue-900"
          hoverColor="blue-100"
          textColor="blue-900"
          bgColor="bg-white"
          onClick={() => setIsOpenCompute(true)}
          type="button"
        >
          Compute
        </Button>
      </div>
      <div>
        <Formulas />
      </div>
      <div className="mt-6 flex flex-col max-w-full">
        {loading && <Loading />}
        {computedValues && (
          <DataTable
            data={computedValues}
            columns={columns}
            width="max-w-full"
          />
        )}
      </div>
      <Modal
        open={isOpenCompute}
        setOpen={setIsOpenCompute}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-sm my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center px-8 pt-8">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              Monthly Commission
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setIsOpenCompute(false)}
            />
          </div>

          <div className="pt-4 px-8 pb-8 flex flex-col">
            <Label>Calculate commissions for billing period * (YYYY-MM):</Label>
            <p className="bg-yellow-50 text-red-500 p-2 mb-3 text-xs">
              * Note: the billing period indicated will fetch the previous
              month's commissions <br />
              <span className="italic">
                e.g. 2023-06 will fetch commissions for 2023-05
              </span>
            </p>

            <div className="flex flex-row space-x-4 pt-2">
              <span className="w-28">
                <input
                  type="text"
                  className="form-input"
                  placeholder={DateTime.now().toFormat('yyyy-LL')}
                  value={monthToCompute}
                  onChange={(e) => {
                    setMonthToCompute(e.target.value);
                  }}
                />
              </span>
              <Button
                bgColor="green-500"
                border="rounded-full"
                px={4}
                py={2}
                color="green-700"
                hoverColor="green"
                onClick={onCompute}
                showLoading={true}
                loading={computing}
              >
                Compute
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ComputedCommissions;
