import { Route, Switch } from 'react-router-dom';

import MonthlyReport from './advertising/analytics/MonthlyReport';
import InteractiveReport from './advertising/InteractiveReport/InteractiveReport';
import DetailedAnalytics from './advertising/detailedAnalytics/DetailedAnalytics';

const ReportsGenerator = () => {
  return (
    <Switch>
      <Route
        path="/reports-generator/advertising-analytics/:reportId"
        component={InteractiveReport}
        exact
      />

      <Route
        path="/reports-generator/detailed-analytics/:reportId"
        component={DetailedAnalytics}
        exact
      />

      <Route
        path="/reports-generator/monthly-report/:reportId"
        component={MonthlyReport}
        exact
      />
    </Switch>
  );
};

export default ReportsGenerator;
