import axios from 'axios';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectReport } from '../optimizationSlice';
import { setAlert } from 'features/alerts/alertsSlice';

import SpinnerGrow from 'components/SpinnerGrow';
import OptimizationSummary from './summary/OptimizationSummary';
import classNames from 'utils/classNames';

const ProceedButton = ({ accountId, marketplace }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const report = useSelector(selectReport);

  const [openSummary, setOpenSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({});

  const onClick = async () => {
    setLoading(true);

    const response = await axios.get(
      `account/advertising/optimizations/reports/${report.advOptimizationReportId}`,
      {
        params: {
          accountId,
          marketplace,
          include: ['selectedItems'],
        },
      }
    );

    const reportItems = response.data.data.items;

    if (!reportItems.length) {
      dispatch(
        setAlert(
          'error',
          'No selected items',
          'Please select at least one to proceed'
        )
      );
    }

    if (reportItems.length) {
      const formattedItems = reportItems.map((item) => {
        item.selectedOption = item.options[0];
        delete item.options;
        return item;
      });

      setItems(groupBy(formattedItems, 'selectedOption.rule.action.code'));
      setOpenSummary(true);
    }

    setLoading(false);
  };

  return (
    <>
      {report && (
        <OptimizationSummary
          items={items}
          open={openSummary}
          accountId={accountId}
          setOpen={setOpenSummary}
          marketplace={marketplace}
          recordType={report.recordType}
          reportId={report.advOptimizationReportId}
        />
      )}

      <button
        type="submit"
        onClick={onClick}
        disabled={!report || loading}
        className={classNames(
          'flex items-center disabled:opacity-95 justify-center w-full rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-secondary text-sm font-medium text-grayscale-300 tracking-2 font-bold focus:outline-none',
          !report || loading
            ? 'opacity-95 cursor-not-allowed'
            : 'hover:bg-secondary-light'
        )}
      >
        {loading ? (
          <>
            Optimizing &nbsp;
            <SpinnerGrow color="white" />
          </>
        ) : (
          t('Advertising.Optimization.Proceed')
        )}
      </button>
    </>
  );
};
export default ProceedButton;
