import { Menu } from '@headlessui/react';
import {
  DotsVerticalIcon,
  ReplyIcon,
  RefreshIcon,
} from '@heroicons/react/outline';

import pushpin from 'assets/icons/pushpin.svg';
import DropdownMenu from 'components/DropdownMenu';
import { ago, nameFormatter } from 'utils/formatters';
import useLoggedInUser from 'hooks/useLoggedInUser';
import usePermissions from 'hooks/usePermissions';
import useMailThread from 'hooks/useMailThread';

const MailOptions = ({
  data,
  threadData,
  togglePinComment,
  reply,
  setReply,
  onDelete,
  onLoadThread,
}) => {
  const { isMine, isAgencySuperUser } = usePermissions();
  const { name } = useLoggedInUser();
  const creatorName = nameFormatter(data.creator);
  const { threadHeaderContains } = useMailThread(threadData);

  return (
    <div className="flex justify-between relative mb-3">
      <span className="text-sm text-gray-400 whitespace-pre-wrap">
        {creatorName.replace(name, 'You')} sent an email
      </span>
      <div className="flex items-center space-x-2 absolute right-0">
        <span className="text-xs text-gray-300 w-28 text-right group-hover:hidden pr-2">
          {ago(data.createdAt)}
        </span>
        {data.pinned && (
          <input
            type="image"
            alt="pinned"
            src={pushpin}
            height="20px"
            className="-mt-1"
          />
        )}
        {threadData && (
          <div className="flex items-center -mt-1">
            {threadHeaderContains('Return-Path') &&
              (isMine(data.createdBy) || isAgencySuperUser()) &&
              reply === false && (
                <button
                  onClick={() => setReply(true)}
                  className="pr-2 text-gray-900 hover:text-red-500"
                  title="Reply to Sender"
                >
                  <ReplyIcon className="w-5 h-5" />
                </button>
              )}
            <button
              onClick={onLoadThread}
              className="text-gray-900 hover:text-red-500"
              title="Reload Thread"
            >
              <RefreshIcon className="w-5 h-5" />
            </button>
          </div>
        )}

        <DropdownMenu
          title={
            <DotsVerticalIcon className="w-5 h-5 text-gray-500 hover:text-gray-700" />
          }
          titleClasses="flex items-center mr-1"
          button
          buttonBg="bg-transparent"
          buttonFontWeight="font-normal"
          hoverClasses="bg-gray-100"
          textColor="text-gray-600"
          classes="text-sm z-2 hidden group-hover:block"
          buttonRounded=""
          hoverText="hover:text-red-500"
          dropdownWidth="w-28"
          padding=""
          position=""
          hideArrow
        >
          <div className="px-1 py-1 flex flex-col">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    togglePinComment(data.pinned ? 'unpin' : 'pin')
                  }
                  className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                >
                  {data.pinned ? 'Unpin' : 'Pin'}
                </button>
              )}
            </Menu.Item>
            {threadData &&
              threadHeaderContains('Return-Path') &&
              (isMine(data.createdBy) || isAgencySuperUser()) && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => setReply(true)}
                      className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                    >
                      Reply
                    </button>
                  )}
                </Menu.Item>
              )}
            {(isMine(data.createdBy) || isAgencySuperUser()) && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onDelete}
                    className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-red-500"
                  >
                    Delete
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default MailOptions;
