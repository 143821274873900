import { Field, ErrorMessage } from 'formik';

import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

const ClientCall = ({ handleChange }) => {
  return (
    <div className="flex flex-col w-full gap-y-4 p-8 mt-4 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.callRecordingLink"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Call Recording Link <RequiredAsterisk />
        </Label>
        <Field
          name="data.callRecordingLink"
          placeholder="Add call recording link"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.callRecordingLink"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label
          htmlFor="data.callAgenda"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Call Agenda <RequiredAsterisk />
        </Label>
        <Field
          name="data.callAgenda"
          placeholder="Add call agenda"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.callAgenda"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label
          htmlFor="data.meetingNotes"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Meeting Notes <RequiredAsterisk />
        </Label>
        <Field
          name="data.meetingNotes"
          placeholder="Add meeting notes"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          as="textarea"
          rows={3}
          onChange={(e) => handleChange(e)}
        />
        <ErrorMessage
          name="data.meetingNotes"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>
    </div>
  );
};

export default ClientCall;
