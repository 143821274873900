import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { ChevronLeftIcon } from '@heroicons/react/outline';

import useComponentVisible from 'hooks/useComponentVisible';

import Log from 'components/Updates/Log';
import Comment from './components/Comment';
import CommentEditor from 'components/Editor/CommentEditor';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOlderUpdates,
  fetchUpdates,
  getAttachments,
  setOlderUpdates,
} from './invoiceSlice';

import classNames from 'utils/classNames';
import Loading from 'components/Loading';
import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';
import usePermissions from 'hooks/usePermissions';
import Email from './components/Email';
import MailEditor from 'components/Editor/MailEditor';

const InvoiceUpdates = () => {
  const { userCan } = usePermissions();
  const { invoiceId } = useParams();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [openEditor, setOpenEditor] = useState('comment');
  const { updates, olderUpdates } = useSelector((state) => state.invoice);
  const { all: employees } = useSelector((state) => state.employees);

  const dispatch = useDispatch();
  const qRef = useRef();
  const messagesEndRef = useRef(null);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
    search: '',
  });
  const [oldParams, setOldParams] = useState({
    page: 2,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
    search: '',
  });
  const [showSearch, setShowSearch] = useState(false);
  const [noScroll, setNoScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const tabButtonsList = [
    {
      ttl: 'All',
      value: '',
    },
    {
      ttl: 'Pin',
      value: 'pinnedComments',
    },
    {
      ttl: 'Users',
      value: 'userComments',
    },
    {
      ttl: 'System',
      value: 'systemComments',
    },
  ];

  const getTabColor = (label) => {
    if (params.scopes.length === 0 && label === '') {
      return 'bg-gray-500 rounded  text-white';
    } else if (params.scopes.includes(`${label}`)) {
      return 'bg-gray-500 text-white';
    } else {
      return 'bg-white text-gray-700';
    }
  };

  useEffect(() => {
    if (params.scopes.includes('pinnedComments')) {
      dispatch(setOlderUpdates({ nextPage: 1, rows: [] }));
    }
    dispatch(fetchUpdates({ params, invoiceId }));
  }, [invoiceId, params]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!noScroll) {
      scrollToBottom();
    }
    setNoScroll(false);
  }, [updates]);

  useEffect(() => {
    setTimeout(() => {
      if (qRef && qRef.current) {
        qRef.current.editor.focus();
      }
    }, 500);
  }, [openEditor]);

  const refreshList = (autoScroll = true) => {
    setLoading(true);
    if (!autoScroll) {
      setNoScroll(true);
    }
    if (params)
      dispatch(fetchUpdates({ params, invoiceId })).then(() => {
        setLoading(false);
      });
  };

  const loadOlderMessages = () => {
    setNoScroll(true);
    dispatch(fetchOlderUpdates({ params: oldParams, invoiceId })).then(
      (res) => {
        setOldParams({ ...oldParams, page: res.payload.nextPage });
      }
    );
  };

  const getAttachmentLink = (attachment) => {
    return `${process.env.REACT_APP_API_BASE_URL}/agency/invoices/attachments/${attachment.invoiceAttachmentId}/download`;
  };

  const onSaveComment = async (data) => {
    return axios
      .post(`/agency/invoices/comments`, { ...data, invoiceId })
      .then(() => {
        refreshList(true);
        setTimeout(() => {
          setIsComponentVisible(false);
        }, 500);
      });
  };

  const onDeleteComment = async (data) => {
    await axios
      .delete(`/agency/invoices/comments/${data.invoiceCommentId}`)
      .then((res) => {
        refreshList(false);
        if (res.data.data.hasAttachment > 0) {
          dispatch(
            getAttachments({
              params: {
                page: 1,
                pageSize: 10,
                sort: 'dateUploaded:desc',
                invoiceId,
              },
            })
          );
        }
      });
  };

  return (
    <>
      <div className="py-4 px-8 bg-white flex justify-between sticky top-0 z-2">
        {showSearch ? (
          <div className="flex items-center w-full">
            <ButtonLink
              onClick={() => setShowSearch(false)}
              color="gray"
              classes="font-bold tracking-widest"
            >
              <ChevronLeftIcon className="w-5 h-5 inline text-gray-400 mr-1" />
              Back
            </ButtonLink>
            <InputPrepend
              name="search"
              type="text"
              placeholder="Search"
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
              classes="bg-gray-50"
              containerClasses="w-full ml-6"
              rounded="rounded-xl"
              onChange={(e) => setParams({ ...params, search: e.target.value })}
            />
          </div>
        ) : (
          <>
            <h4 className=" text-2xl font-bold flex items-center">
              Updates
              <ButtonLink onClick={() => setShowSearch(true)}>
                <SearchIcon className="ml-5 w-5 h-5 inline text-gray-400" />
              </ButtonLink>
            </h4>
            <span className="relative z-0 inline-flex shadow-sm rounded-md bg-gray-500 p-0.5">
              {tabButtonsList.map((item, index) => (
                <>
                  <button
                    key={index + item.value}
                    type="button"
                    onClick={() =>
                      setParams({
                        ...params,
                        scopes: item.value === '' ? [] : [`${item.value}`],
                      })
                    }
                    className={classNames(
                      '-ml-px  relative inline-flex items-center w-20 tracking-wider py-1.5 rounded-r-sm justify-center text-xs font-bold hover:bg-gray-500 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500',
                      getTabColor(item.value)
                    )}
                  >
                    {item.ttl}
                  </button>
                </>
              ))}
            </span>
          </>
        )}
      </div>

      <div className="comment-container px-8 pt-8 space-y-3 min-h-77 relative">
        {!params.scopes.includes('pinnedComments') &&
          !showSearch &&
          olderUpdates.nextPage &&
          updates.rows.length > 0 && (
            <span className="absolute top-0 left-0 w-full text-center pt-1">
              <button
                className=" text-xs underline text-gray-400 hover:text-gray-700 "
                onClick={loadOlderMessages}
              >
                load older messages
              </button>
            </span>
          )}
        {olderUpdates.rows.map((update) =>
          update?.log ? (
            <Log key={update?.invoiceUpdateId} data={update.log} />
          ) : update?.comment?.threadId ? (
            <Email
              key={update?.invoiceUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
            />
          ) : (
            <Comment
              key={update.invoiceUpdateId}
              data={update.comment}
              onChangeData={refreshList}
              onDelete={onDeleteComment}
              getAttachmentLink={getAttachmentLink}
            />
          )
        )}
        {olderUpdates.rows.length > 0 && (
          <div className="text-xs text-indigo-700 relative py-6">
            <hr />
            <span className="bg-gray-50 px-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              older messages
            </span>
          </div>
        )}
        {updates.rows.map((update) =>
          update?.log ? (
            <Log key={update?.invoiceUpdateId} data={update.log} />
          ) : update?.comment?.threadId ? (
            <Email
              key={update.invoiceUpdateId}
              data={update.comment}
              onChangeData={refreshList}
            />
          ) : (
            <Comment
              key={update?.invoiceUpdateId}
              data={update?.comment}
              onChangeData={refreshList}
              onDelete={onDeleteComment}
              getAttachmentLink={getAttachmentLink}
            />
          )
        )}

        <div ref={messagesEndRef}>{loading && <Loading />}</div>
      </div>
      {userCan('invoices.updates.create') && (
        <div className="add-comment-box sticky bottom-0">
          <div className="gradient-box"></div>
          <div
            ref={ref}
            className="bg-white p-0 text-sm text-gray-500 hover:text-gray-700"
          >
            {isComponentVisible ? (
              openEditor === 'comment' ? (
                <CommentEditor
                  qRef={qRef}
                  open={isComponentVisible}
                  onChangeData={refreshList}
                  setOpenEditor={setOpenEditor}
                  employees={employees}
                  uploaderOptions={{
                    attachmentKey: 'invoiceAttachmentId',
                    getAttachmentLink: getAttachmentLink,
                    primaryKey: { key: 'invoiceId', value: invoiceId },
                    postUrl: '/agency/invoices/attachments',
                    onRefreshList: getAttachments({
                      params: {
                        page: 1,
                        pageSize: 10,
                        sort: 'uploadedAt:desc',
                        invoiceId,
                      },
                    }),
                  }}
                  onSave={onSaveComment}
                />
              ) : (
                <MailEditor
                  qRef={qRef}
                  onChangeData={refreshList}
                  setOpenEditor={setOpenEditor}
                  employees={employees}
                  primaryKey={{ key: 'invoiceId', value: invoiceId }}
                  postUrl={`/agency/invoices/mail`}
                  setOpen={setIsComponentVisible}
                  open={isComponentVisible}
                />
              )
            ) : (
              <p
                className="p-4 text-xs cursor-pointer"
                onClick={() => {
                  setIsComponentVisible(true);
                  setTimeout(() => {
                    qRef.current.editor.focus();
                  }, 500);
                }}
              >
                Have any updates or comments? Click here to type!
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceUpdates;
