import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { PlusIcon, UserCircleIcon } from '@heroicons/react/outline';

import { Table, Avatar } from 'components';
import FormModal from './components/FormModal';
import StarRating from './components/StarRating';
import { getNameInitials } from 'utils/formatters';
import usePermissions from 'hooks/usePermissions';
import DetailsModal from './components/DetailsModal';

const RatingDashboard = () => {
  const { userCan } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [openDetails, setOpenDetails] = useState(false);

  const [reviews, setReviews] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    include: ['addedBy'],
    sort: 'createdAt:desc',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/agency/clients/performance-ratings', {
        params,
      });

      if (isSubscribed) {
        setReviews(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params]);

  const refreshList = async () => {
    setLoading(true);

    const response = await axios.get('/agency/clients/performance-ratings', {
      params,
    });

    setReviews(response.data.data);
    setLoading(false);
  };

  const columns = [
    {
      sort: true,
      dataField: 'type',
      text: 'Review',
      classes: 'text-secondary-light text-13 leading-1.5 p-4 whitespace-nowrap',
    },
    {
      sort: true,
      dataField: 'information',
      text: 'Details',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
    },
    {
      sort: true,
      dataField: 'createdAt',
      text: 'Date Added',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => moment(cell).format('MMM DD, YYYY'),
    },
    {
      dataField: 'addedBy',
      text: 'Reviewer',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4',
      formatter: (cell) => (
        <Avatar
          imageSrc={cell.avatar?.thumbnailUrl}
          initials={getNameInitials(cell.firstName, cell.lastName)}
        />
      ),
    },
    {
      sort: true,
      dataField: 'rating',
      text: 'Rating',
      headerStyle: { minWidth: '200px' },
      formatter: (cell) => (
        <div className="flex items-center space-x-2">
          <p className="font-bold font-inter text-25">{cell}</p>
          <StarRating value={cell} readOnly={true} size={6} />
        </div>
      ),
    },
    {
      sort: true,
      dataField: 'id',
      text: 'View Details',
      headerStyle: { minWidth: '120px' },
      formatter: (cell) => (
        <div className="flex items-center justify-center">
          <UserCircleIcon
            className="w-6 h-6 text-success-dark cursor-pointer"
            onClick={() => {
              setSelectedId(cell);
              setOpenDetails(true);
            }}
          />
        </div>
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div>
      <FormModal
        open={openForm}
        setOpen={setOpenForm}
        onSuccess={refreshList}
      />

      {!!selectedId && (
        <DetailsModal
          id={selectedId}
          open={openDetails}
          setOpen={setOpenDetails}
        />
      )}

      <div className="mt-4 mb-8 flex items-center space-x-4 justify-between">
        <h3 className="font-inter font-bold text-25 tracking-3/4 text-grayscale-900 leading-1.2">
          Partner Rating Dashboard
        </h3>

        {userCan('clients.performance_rating.add') && (
          <button
            onClick={() => setOpenForm(true)}
            className="text-grayscale-300 tracking-2 leading-1.5 text-sm font-bold border-2 bg-secondary rounded-40 py-2 px-5 flex items-center space-x-1 hover:bg-secondary-light"
          >
            <PlusIcon className="w-3 h-3 text-grayscale-300" />
            <span>Rate Account Performance</span>
          </button>
        )}
      </div>

      <Table
        loading={loading}
        keyField="id"
        columns={columns}
        data={reviews}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default RatingDashboard;
