import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';

import { Table } from 'components';
import Modal from 'components/Modal';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';

const ClientTasks = ({ id, open, setOpen }) => {
  const { all: employees } = useSelector((state) => state.employees);

  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    taskFolderSpaceClientId: id,
    include: ['list', 'assignees'],
    sort: 'isAssignedToMe,updatedAt',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/agency/tasks/items/list', {
        params,
      });

      if (isSubscribed) {
        setLoading(false);
        setTasks(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [id, params]);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (cell, row) => (
        <Link
          className="text-sm text-red-500 hover:text-red-900 inline-flex items-center"
          to={`/tasks/${row.taskItemId}`}
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cell, row) => {
        const status = row.list.statuses.find((s) => s.name === cell);

        return (
          <span
            className="px-2 py-1 rounded-xl text-11"
            style={{ color: status?.color, backgroundColor: status?.bgColor }}
          >
            {cell}
          </span>
        );
      },
    },
    {
      dataField: 'list.folder.name',
      text: 'Type',
      sort: true,
    },
    {
      dataField: 'assignees',
      text: 'Assignees',
      sort: false,
      formatter: (cell) => (
        <UsersPopoverMultiple
          users={employees}
          position="left-1/2 transform -translate-x-1/2"
          selectedIds={cell.map((user) => user.userId)}
          allowAdd={false}
          allowRemove={false}
        />
      ),
    },
    {
      dataField: 'closedAt',
      text: 'Closed At',
      sort: true,
      formatter: (cell) =>
        cell
          ? moment(cell).tz(moment.tz.guess()).format('MMM DD, YYYY HH:mm:ss')
          : '',
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      sort: true,
      formatter: (cell) =>
        moment(cell).tz(moment.tz.guess()).format('MMM DD, YYYY HH:mm:ss'),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <Modal
      zIndex="z-30"
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
    >
      <div className="inline-block w-full max-w-6xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="p-8">
          <div className="flex justify-between items-center">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              Client Tasks
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>

          <div className="mt-5">
            <Table
              loading={loading}
              keyField="taskItemId"
              columns={columns.map((column) => {
                return {
                  ...column,
                  classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
                  headerClasses: `py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
                };
              })}
              data={tasks}
              onTableChange={onTableChange}
              params={params}
              bordered={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClientTasks;
