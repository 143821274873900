import React, { useEffect, useRef, useState, Fragment } from 'react';
import { FileDrop } from 'react-file-drop';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import { PlusIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import useQueryParams from 'hooks/useQueryParams';
import { dateFormatterUTC, getNameInitials } from 'utils/formatters';
import { Table } from 'components';
import { DocumentTextIcon, XIcon } from '@heroicons/react/outline';
import {
  PencilIcon,
  CloudDownloadIcon,
  LinkIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import {
  PencilIcon as PencilIconOutline,
  CloudDownloadIcon as CloudDownloadIconOutline,
  LinkIcon as LinkIconOutline,
  TrashIcon as TrashIconOutline,
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/outline';
import prependHttp from 'prepend-http';
import SlideOver from 'components/SlideOver';
import FilesForm from './components/FilesForm';
import { useParams } from 'react-router-dom';
import { getLatestUpdates, getAttachments } from '../agencyClientSlice';
import usePermissions from 'hooks/usePermissions';
import classNames from 'utils/classNames';
import doc from 'assets/icons/doc.svg';
import useBodyClass from 'hooks/useBodyClass';
import { Dialog, Transition } from '@headlessui/react';
import Loading from 'components/Loading';

const Files = () => {
  //useBodyClass('client-profile');
  const { userCan, isMine, isAgencySuperUser } = usePermissions();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { agencyClientId } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [filesUp, setFilesUp] = useState(null);
  const [showGridList, setShowGridList] = useState(true);
  const { attachments } = useSelector((state) => state.agencyClient);
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { params, updateParams, sortParam } = useQueryParams({
    page: 1,
    pageSize: 10,
    sort: 'createdAt:desc',
    salesPageoffset: null,
  });
  const [selected, setSelected] = useState({
    title: '',
  });
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [previewPath, setPreviewPath] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const folder = `agency/clients/${agencyClientId}/attachments`;

  useEffect(() => {
    // async function getData() {

    // }
    // getData();
    dispatch(
      getAttachments({
        params: {
          ...params,
          agencyClientId,
        },
      })
    );
  }, [params, dispatch, agencyClientId]);

  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    uploadFiles(event.target.files);
  };

  const closeModal = () => {
    setPreviewPath('');
    setIsOpen(false);
  };

  const openModal = async (file) => {
    let modulePath = file.agencyClientAttachmentId ? 'clients' : 'sales';
    let attachmentId = file.agencyClientAttachmentId ?? file.salesAttachmentId;
    setLoading(true);
    setCurrentFile(file);
    await axios
      .get(`/agency/${modulePath}/attachments/${attachmentId}`)
      .then((res) => {
        if (res.data.success) {
          setPreviewPath(res.data.data.url);
        } else {
          dispatch(setAlert('error', res.data.message));
        }
      });
    setLoading(false);
    setIsOpen(true);
  };

  // * Upload files to bulk update the details
  const uploadFiles = (files) => {
    if (fileInputRef.current.files.length <= 0) {
      fileInputRef.current.files = files;
    }
    setFilesUp([...files]);
    setMessage('');
  };

  // * Triggered when the button for adding files is clicked
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onStartUpload = async (e) => {
    e.stopPropagation();
    const files = fileInputRef.current.files;
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('agencyClientId', agencyClientId);
      await axios.post(`/agency/clients/attachments`, formData).then((res) => {
        if (res.data.success) {
          dispatch(setAlert('success', 'File uploaded', res.data.message));
          setMessage(res.data.message);
          resetParams(1, 10, null);
          dispatch(
            getAttachments({
              params: {
                ...params,
                agencyClientId,
              },
            })
          );
          dispatch(getLatestUpdates(agencyClientId));
        } else {
          dispatch(
            setAlert('error', 'Failed to upload file', res.data.message)
          );
          setMessage(res.data.message);
        }
      });
    }

    setLoading(false);
    fileInputRef.current.value = null;
  };

  const onDeleteAttachment = async (file) => {
    let modulePath = file.agencyClientAttachmentId ? 'clients' : 'sales';
    let attachmentId = file.agencyClientAttachmentId ?? file.salesAttachmentId;
    setLoading(true);
    await axios
      .delete(`/agency/${modulePath}/attachments/${attachmentId}`)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            setAlert('success', 'Successfully Deleted', res.data.message)
          );
          setMessage(res.data.message);
          resetParams(1, 10, null);
          dispatch(
            getAttachments({
              params: {
                ...params,
                agencyClientId,
              },
            })
          );
          dispatch(getLatestUpdates(agencyClientId));
        } else {
          dispatch(setAlert('error', res));
        }
      });
    setLoading(false);
  };

  const buildLink = async (file) => {
    let modulePath = file.agencyClientAttachmentId ? 'clients' : 'sales';
    let attachmentId = file.agencyClientAttachmentId ?? file.salesAttachmentId;
    setLoading(true);
    const link = `${process.env.REACT_APP_API_BASE_URL}/agency/${modulePath}/attachments/${attachmentId}/download`;
    navigator.clipboard.writeText(link);
    dispatch(setAlert('success', 'Link copied to clipboard', link));
    setLoading(false);
  };

  const onViewLink = async (file) => {
    let modulePath = file.agencyClientAttachmentId ? 'clients' : 'sales';
    let attachmentId = file.agencyClientAttachmentId ?? file.salesAttachmentId;
    setLoading(true);
    await axios
      .get(`/agency/${modulePath}/attachments/${attachmentId}`)
      .then((res) => {
        if (res.data.success) {
          window.open(prependHttp(res.data.data.url, { https: false }));
        } else {
          dispatch(setAlert('error', res.data.message));
        }
      });
    setLoading(false);
  };

  const onClickEdit = (row) => {
    setSelected(row);
    setOpen(true);
  };

  const handleNextPage = () => {
    updateParams({
      page: attachments.nextPage,
      pageSize: 10,
      sort: 'createdAt:desc',
      salesPageoffset: attachments.salesPageoffset,
    });
  };

  const handlePreviousPage = () => {
    updateParams({
      page: attachments.prevPage,
      pageSize: 10,
      sort: 'createdAt:desc',
      salesPageoffset:
        attachments.prevPage === 1 || attachments.prevPage === null
          ? null
          : attachments.salesPageoffset - attachments.pageSize,
    });
  };

  const resetParams = (page, pageSize, salesPageoffset) => {
    updateParams({
      page,
      pageSize,
      sort: 'createdAt:desc',
      salesPageoffset,
    });
  };

  const tableColumns = [
    {
      dataField: 'title',
      text: 'File Name',
      sort: true,
      headerStyle: {
        minWidth: '300px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return (
          <div className="flex">
            {['png', 'svg', 'jpg', 'jpeg', 'gif'].includes(row.extension) ? (
              <button
                className="flex"
                type="button"
                onClick={() => openModal(row)}
              >
                <EyeIcon className="w-5 h-5 cursor-pointer" color="#242020" />
              </button>
            ) : (
              <EyeOffIcon
                className="w-5 h-5 cursor-not-allowed"
                color="#242020"
              />
            )}
            <span className="pl-2">{row.title}</span>
          </div>
        );
      },
    },
    {
      dataField: 'uploadedByUser',
      text: 'Uploaded By',
      sort: true,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'left',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? `${cell.firstName} ${cell.lastName}` : ''}
          </span>
        );
      },
    },
    {
      dataField: 'dateUploaded',
      text: 'Uploaded Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? dateFormatterUTC(cell, 'DD MMM YYYY HH:mm') : ''}
          </span>
        );
      },
    },

    !agencyClient.deletedAt && {
      dataField: 'action',
      text: 'Action',
      headerStyle: { textAlign: `center` },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="flex flex-row justify-evenly">
            {((userCan('sales.profiles.files.manage') &&
              isMine(row.uploadedBy)) ||
              isAgencySuperUser()) && (
              <button
                onClick={() => {
                  onClickEdit(row);
                }}
              >
                <PencilIconOutline className="w-5 h-5" />
              </button>
            )}

            <button onClick={() => onViewLink(row)}>
              <CloudDownloadIconOutline className="w-5 h-5 cursor-pointer text-blue-400" />
            </button>
            <button onClick={() => buildLink(row)}>
              <LinkIconOutline className="w-5 h-5 cursor-pointer text-green-600" />
            </button>
            {((userCan('sales.profiles.files.manage') &&
              isMine(row.uploadedBy)) ||
              isAgencySuperUser()) && (
              <button onClick={() => onDeleteAttachment(row)}>
                <TrashIconOutline
                  className="w-5 h-5 cursor-pointer"
                  color="#dc2626"
                />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (page > attachments.prevPage && sizePerPage === attachments.pageSize) {
      resetParams(page, sizePerPage, attachments.salesPageoffset);
    } else if (
      page === attachments.prevPage &&
      sizePerPage === attachments.pageSize
    ) {
      resetParams(
        page,
        sizePerPage,
        attachments.prevPage === 1 || attachments.prevPage === null
          ? null
          : attachments.salesPageoffset - attachments.pageSize
      );
    } else if (sizePerPage !== attachments.pageSize) {
      resetParams(1, sizePerPage, null);
    }
  };

  return (
    <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
      {!agencyClient.deletedAt && (
        <>
          <h4 className="col-span-1 text-2xl font-bold">Uploaded Files</h4>
          {userCan('sales.profiles.files.manage') && (
            <div className="col-span-1">
              <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                className="hidden"
                multiple
              />
              <FileDrop
                onTargetClick={onTargetClick}
                onDrop={(files, event) => uploadFiles(files)}
              >
                <div className="border border-dashed border-gray-300 p-5 text-center cursor-pointer bg-gray-100">
                  <div className="flex items-center justify-center">
                    <PlusIcon
                      className="text-gray-500 mr-1 h-5 w-5 inline"
                      aria-hidden="true"
                    />
                    <span className="text-gray-500 text-xl">
                      Drop file or click to select
                    </span>
                  </div>
                  {filesUp && (
                    <div className="mt-4">
                      <ul className="text-sm mb-4">
                        {filesUp.map((file, i) => {
                          return (
                            <li key={i}>
                              <DocumentTextIcon className="inline w-4 h-4" />
                              <span className="text-gray-700">{file.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                      {fileInputRef.current.value && (
                        <Button
                          color="green"
                          loading={loading}
                          showLoading={true}
                          onClick={onStartUpload}
                        >
                          Upload Attachments
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </FileDrop>
            </div>
          )}
        </>
      )}

      <div className="col-span-1 px-2 py-3 sm:px-0 flex justify-end space-x-1">
        <div>
          <button
            onClick={() => {
              setShowGridList(true);
              resetParams(1, 10, null);
            }}
          >
            <span
              className={classNames(
                'material-symbols-outlined p-0.5 rounded-sm hover:text-red-500',
                showGridList && 'text-red-500 shadow'
              )}
            >
              grid_view
            </span>
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              setShowGridList(false);
              resetParams(1, 10, null);
            }}
          >
            <span
              className={classNames(
                'material-symbols-outlined p-0.5 rounded-sm hover:text-red-500',
                !showGridList && 'text-red-500 shadow'
              )}
            >
              view_list
            </span>
          </button>
        </div>
      </div>

      {showGridList ? (
        <>
          {attachments?.rows?.length >= 1 ? (
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-3 gap-y-8 justify-center">
              {attachments.rows.map((attachment, key) => {
                return (
                  <div
                    className="col-span-1 relative flex flex-col items-center transition-all group"
                    key={attachment.salesAttachmentId}
                  >
                    <div className="relative mb-5">
                      {['png', 'svg', 'jpg', 'jpeg', 'gif'].includes(
                        attachment.extension
                      ) ? (
                        <div className="flex items-center h-40 bg-gray-200 overflow-hidden">
                          <img src={attachment.url} className="w-52" />
                        </div>
                      ) : (
                        <>
                          <div className="flex items-center h-40 overflow-hidden">
                            <img src={doc} className="h-28" />
                          </div>
                          <span
                            title={attachment.extension}
                            className={
                              'text-white bg-blue-800 truncate font-bold  font-inter opacity-80 px-1 py-0.5 shadow absolute w-3/5 text-xs text-center uppercase top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'
                            }
                          >
                            {attachment.extension}
                          </span>
                        </>
                      )}
                      <span className="bg-green-text shadow-md text-white w-7 h-7 text-xs rounded-3xl uppercase text-center leading-7 absolute -bottom-3 left-1/2 transform -translate-x-1/2">
                        {getNameInitials(
                          attachment.uploadedByUser.firstName,
                          attachment.uploadedByUser.lastName
                        )}
                      </span>
                    </div>

                    <p
                      className="relative w-full truncate text-sm text-center  text-grayscale-900"
                      title={attachment.title}
                    >
                      {attachment.title}
                    </p>
                    <p className="relative text-center text-sm text-grayscale-600 my-1">
                      {dateFormatterUTC(
                        attachment.dateUploaded,
                        'MMM DD YYYY HH:mma'
                      )}
                    </p>
                    {!agencyClient.deletedAt && (
                      <div className="hidden shadow group-hover:block absolute w-full h-full bg-gray-700 bg-opacity-70 p-8">
                        <div className="flex flex-wrap justify-between items-center text-center h-full">
                          <div className="w-1/2">
                            {((userCan('sales.profiles.files.manage') &&
                              isMine(attachment.uploadedBy)) ||
                              isAgencySuperUser()) && (
                              <button
                                onClick={() => {
                                  onClickEdit(attachment);
                                }}
                              >
                                <PencilIcon className="w-6 h-6 text-gray-100 hover:text-gray-300" />
                              </button>
                            )}
                          </div>
                          <div className="w-1/2">
                            <button onClick={() => onViewLink(attachment)}>
                              <CloudDownloadIcon className="w-6 h-6 cursor-pointer text-blue-400 hover:text-blue-600" />
                            </button>
                          </div>
                          <div className="w-1/2">
                            <button onClick={() => buildLink(attachment)}>
                              <LinkIcon className="w-6 h-6 cursor-pointer text-green-400 hover:text-green-600" />
                            </button>
                          </div>
                          <div className="w-1/2">
                            {((userCan('sales.profiles.files.manage') &&
                              isMine(attachment.uploadedBy)) ||
                              isAgencySuperUser()) && (
                              <button
                                className=""
                                onClick={() => onDeleteAttachment(attachment)}
                              >
                                <TrashIcon className="w-6 h-6 cursor-pointer text-red-500 hover:text-red-700" />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5 flex justify-center">
              <p>No attachments to display</p>
            </div>
          )}
          <div className="flex justify-center mt-4">
            {attachments.prevPage >= 1 ? (
              <div className="pr-5">
                <Button
                  classes="border border-gray-700 font-bold tracking-widest"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="3xl"
                  textColor="gray-700"
                  px={5}
                  py={2}
                  shadow=""
                  textSize="xs"
                  onClick={handlePreviousPage}
                  loading={loading}
                  showLoading={true}
                >
                  <ChevronLeftIcon className="w-4 h4 inline mr-2" /> Previous
                </Button>
              </div>
            ) : (
              <div></div>
            )}
            {attachments.nextPage > 1 ? (
              <div>
                <Button
                  classes="border border-gray-700 font-bold tracking-widest"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="3xl"
                  textColor="gray-700"
                  px={5}
                  py={2}
                  shadow=""
                  textSize="xs"
                  onClick={handleNextPage}
                  loading={loading}
                  showLoading={true}
                >
                  Next <ChevronRightIcon className="w-4 h4 inline ml-2" />
                </Button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </>
      ) : (
        <>
          {attachments?.rows?.length >= 1 ? (
            <Table
              columns={tableColumns}
              data={attachments}
              onTableChange={onTableChange}
              params={params}
              keyField="title"
              defaultSorted={[{ dataField: 'dateUploaded', order: 'desc' }]}
              loading={loading}
            />
          ) : (
            <div className="mt-5 flex justify-center">
              <p>No attachments to display</p>
            </div>
          )}
        </>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto transform overflow-hidden bg-white p-3 text-left align-middle shadow-xl transition-all">
                  <button
                    type="button"
                    className="text-gray-600 hover:text-gray-800 focus:outline-none absolute right-1 top-1"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                  <div className="p-2">
                    {loading ? (
                      <Loading />
                    ) : (
                      <img
                        className="max-h-60vh"
                        src={previewPath}
                        alt={currentFile?.title}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <SlideOver
        open={open}
        setOpen={setOpen}
        title="Update file name"
        titleClasses="capitalize"
        size="2xl"
      >
        <div className="flow-root">
          <FilesForm
            data={selected}
            setOpen={setOpen}
            getAttachments={getAttachments}
            folder={folder}
            params={{
              ...params,
              agencyClientId,
            }}
            dispatch={dispatch}
          />
        </div>
      </SlideOver>
    </div>
  );
};

export default Files;
