import axios from 'axios';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import { setAlert } from 'features/alerts/alertsSlice';

import classNames from 'utils/classNames';

const StatusSwitch = ({
  status,
  entity,
  entityId,
  accountId,
  marketplace,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(status === 'enabled');

  const onChange = () => {
    if (loading) return;

    setLoading(true);
    const state = enabled ? 'paused' : 'enabled';
    const url = `/advertising/${entity}/${entityId}`;
    const payload = { accountId, marketplace };

    axios
      .put(url, { ...payload, data: { state } })
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
        setEnabled(response.data.data.state === 'enabled');
      })
      .catch((error) => {
        dispatch(setAlert('error', error.response.data.message));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Switch
      checked={enabled}
      disabled={status === 'archived' || disabled}
      onChange={onChange}
      className={classNames(
        enabled ? 'bg-success' : 'bg-gray-200',
        loading ? 'cursor-wait' : 'cursor-pointer',
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-success focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};

export default StatusSwitch;
