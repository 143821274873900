import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';

export const fetchSubscription = createAsyncThunk(
  'subscription/fetch',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/subscriptions/${id}`);
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        setAlert(
          'error',
          'Failed fetching subscription',
          err.response.data.message
        )
      );
    }
  }
);

export const fetchPendingInvoices = createAsyncThunk(
  'subscription/pendingInvoices',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/subscriptions/${id}/pendinginvoices`);
      return response.data.data;
    } catch (err) {
      // set to silent error mode
      // thunkAPI.dispatch(
      //   setAlert(
      //     'error',
      //     'Failed to fetch pending invoices',
      //     err.response.data.message
      //   )
      // );
    }
  }
);

export const fetchScheduledChanges = createAsyncThunk(
  'subscription/scheduledChanges',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/subscriptions/${id}/scheduledchanges`);
      return response.data.data;
    } catch (err) {
      // set to silent error mode
      // thunkAPI.dispatch(
      //   setAlert(
      //     'error',
      //     'Failed to fetch scheduled changes',
      //     err.response.data.message
      //   )
      // );
    }
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscription: null,
    scheduledChanges: null,
    pendingInvoices: null,
  },
  reducers: {
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
  extraReducers: {
    [fetchSubscription.fulfilled]: (state, { payload }) => {
      state.subscription = payload;
    },
    [fetchSubscription.rejected]: (state, { payload }) => {
      state.subscription = null;
    },
    [fetchPendingInvoices.fulfilled]: (state, { payload }) => {
      state.pendingInvoices = payload;
    },
    [fetchPendingInvoices.rejected]: (state, { payload }) => {
      state.pendingInvoices = null;
    },
    [fetchScheduledChanges.fulfilled]: (state, { payload }) => {
      state.scheduledChanges = payload;
    },
    [fetchSubscription.rejected]: (state, { payload }) => {
      state.scheduledChanges = null;
    },
  },
});

export const { setSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
