import { Fragment, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from 'assets/logos/bs-si.svg';
import classNames from 'utils/classNames';
import { ChevronDownIcon } from '@heroicons/react/outline';

export const Navigation = ({ proposalContent, z = false }) => {
  const location = useLocation();
  const [isServiceAgreementOpen, setIsServiceAgreementOpen] = useState(false);
  const [activeSubtab, setActiveSubtab] = useState(null);

  const navigation = [
    { name: 'E-Signature', href: '#acknowledgement' },
    !z ? { name: 'Payment', href: '#payment' } : {},
  ];
  return (
    <div className="bg-slate-600">
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-si-dark pt-5">
          <div className="mt-5 flex flex-1 flex-col">
            <nav className="grid ">
              <div className="grid grid-cols-12">
                <div className="col-span-5 mb-4">
                  <a href="#intro">
                    <img
                      className="h-20 w-auto pl-6 pb-2"
                      src={logo}
                      alt="Workflow"
                    />
                  </a>
                </div>
                <div className="col-span-7 pl-1">
                  <p className="leading-none text-2xl font-bold text-white pt-2">
                    seller{' '}
                  </p>
                  <p className="leading-none text-2xl font-bold text-white tracking-tighter">
                    interactive
                    <span className="leading-none text-3xl p-0 m-0 font-bold text-si-pink">
                      .
                    </span>
                  </p>
                </div>
              </div>
              {navigation.map((item) => {
                return (
                  <Fragment key={item.name}>
                    {!item.hidden && !item.isPaid && (
                      <div className="relative py-2">
                        <a
                          href={item.href}
                          className={classNames(
                            location.hash === item.href
                              ? 'pl-5 text-si-pink border-si-pink border-l-4 border-accent-blue font-bold'
                              : 'text-white',
                            'px-6 py-1 text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onClick={() => {
                            //onClickHandler(item.href.replace('#', ''));
                            if (item.href == '#serviceAgreement') {
                              setIsServiceAgreementOpen(
                                !isServiceAgreementOpen
                              );
                            }
                          }}
                          id={item.href}
                        >
                          {item.name}{' '}
                          {item.href === '#serviceAgreement' && (
                            <ChevronDownIcon
                              className={`${
                                isServiceAgreementOpen &&
                                'text-si-pink font-bold'
                              }  ml-12 h-5 w-5 inline relative bottom-1`}
                            />
                          )}
                        </a>
                        {item.subtabs &&
                          (isServiceAgreementOpen ? (
                            <div className=" w-full bg-slate-600">
                              <nav className="flex flex-col items-start pb-2 px-4">
                                {item.subtabs.map((subtab) => (
                                  <Link
                                    key={subtab.name}
                                    to={subtab.href}
                                    className={classNames(
                                      activeSubtab === subtab.href
                                        ? 'text-si-pink font-bold'
                                        : 'text-white hover:text-si-pink',
                                      'px-4 py-1 font-medium'
                                    )}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const targetId = subtab.href.replace(
                                        '#',
                                        ''
                                      );
                                      const targetElement =
                                        document.getElementById(targetId);
                                      targetElement.scrollIntoView({
                                        behavior: 'smooth',
                                      });
                                      setActiveSubtab(subtab.href);
                                    }}
                                  >
                                    {subtab.name}
                                  </Link>
                                ))}
                              </nav>
                            </div>
                          ) : null)}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
