import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { columnClasses, headerClasses } from 'utils/table';
import { dateFormatter } from 'utils/formatters';
import useQueryParams from 'hooks/useQueryParams';
import useLoggedInUser from 'hooks/useLoggedInUser';
import TabNav from 'components/TabNav';
import classNames from 'utils/classNames';
import { Table } from 'components';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import InvoiceStatusBadge from 'features/invoices/components/InvoiceStatusBadge';
import PriceLabel from 'components/PriceLabel';

const InvoiceHistory = () => {
  const { user } = useLoggedInUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const { params, updateParams } = useQueryParams({
    page: 1,
    sizePerPage: 10,
    status: 'Unpaid',
    zohoId: user.defaultAgency.zohoId,
  });
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      name: 'Unpaid',
      current: params.status == 'Unpaid',
      visible: true,
    },
    {
      name: 'Paid',
      current: params.status == 'Paid',
      visible: true,
    },
    {
      name: 'PartiallyPaid',
      displayName: 'Partially Paid',
      current: params.status == 'PartiallyPaid',
      visible: true,
    },
    {
      name: 'Void',
      current: params.status == 'Void',
      visible: true,
    },
  ]);

  useEffect(() => {
    let myTabs = [...tabs];
    let currentTab = myTabs.find((t) => t.current === true);
    currentTab.current = false;
    let selectedTab = myTabs.find((t) => t.name == params.status);
    selectedTab.current = true;
    setTabs(myTabs);
  }, [params.status]);

  useEffect(() => {
    !loading && fetchInvoices();
  }, [params, dispatch]);

  const fetchInvoices = async () => {
    setLoading(true);
    await axios
      .get(`/agency/invoices`, { params })
      .then((response) => setInvoices(response.data.data))
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  };

  const updateStatus = (status) => {
    updateParams({
      status,
      page: 1,
      sizePerPage: params.sizePerPage,
    });
  };

  const tableColumns = [
    {
      dataField: 'number',
      text: 'Invoice Number',
      headerClasses,
      classes: columnClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/invoice-history/${row.invoice_id}`}
            className={classNames(
              row.is_viewed_by_client ? 'text-primary' : 'text-secondary-light',
              'underline'
            )}
          >
            {cell}
          </Link>
        );
      },
    },
    {
      dataField: 'total',
      text: 'Amount',
      sort: false,
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      headerStyle: {
        minwidth: '150px',
        textAlign: 'right',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'balance',
      text: 'Balance',
      headerClasses: `${headerClasses} text-right`,
      classes: `${columnClasses} text-right`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      headerClasses,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '175px',
        textAlign: 'center',
      },
      formatter: (cell, row) => <InvoiceStatusBadge status={cell} />,
    },
    {
      dataField: 'invoice_date',
      text: 'Date',
      sort: false,
      headerClasses,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '150px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return <span>{dateFormatter(cell)}</span>;
      },
    },
    {
      dataField: 'due_date',
      text: 'Due Date',
      sort: false,
      headerClasses,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '150px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return <span>{dateFormatter(cell)}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      headerClasses,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '150px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/invoice-history/${row.invoice_id}`}
            className="text-sm text-grayscale-700 hover:text-grayscale-800 font-bold border-2 border-grayscale-700 hover:border-grayscale-800 hover:bg-grayscale-500 px-6 py-1.5 rounded-lg tracking-widest"
          >
            View
          </Link>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({ page, sizePerPage });
  };

  return (
    <>
      <h1 className="text-3xl tracking-wide font-bold mb-6">Invoice History</h1>
      <TabNav
        tabs={tabs}
        setTabs={setTabs}
        onSelectChange={(e) => updateStatus(e.target.value)}
        onClick={(selectedTab) => updateStatus(selectedTab.name)}
        theme="tabbed"
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <Table
              columns={tableColumns}
              data={invoices}
              onTableChange={onTableChange}
              params={params}
              keyField="invoice_id"
              loading={loading}
              paginationType="prevnext"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceHistory;
