import { CheckIcon } from '@heroicons/react/solid';
import { Label } from 'components/Forms';
import Loading from 'components/Loading';
import PriceLabel from 'components/PriceLabel';
import useCreditNotes from '../../useCreditNotes';
import AppliedCreditsTable from './AppliedCreditsTable';

const ViewAppliedCredits = ({ creditNote, loading }) => {
  const { isAnyApplied } = useCreditNotes();

  return (
    <div>
      {creditNote.autoApply && (
        <div className="flex flex-col border-t border-grayscale-500 mt-4 py-6">
          <label className="flex flex-col text-left text-gray-500 w-3/4">
            <span className="flex items-center">
              <CheckIcon className="w-7 h-7 inline text-success mr-2" />
              Apply to next month's invoice &nbsp;&nbsp;&nbsp;
              <PriceLabel price={parseFloat(creditNote.autoApplyAmount)} />
            </span>
          </label>
        </div>
      )}

      {loading && <Loading />}

      <AppliedCreditsTable creditNote={creditNote} />

      {isAnyApplied(creditNote.status) && (
        <div className="grid grid-cols-2 pt-8">
          <div className="col-span-1">
            <Label classes="col-span-2 text-grayscale-700">
              Remaining Credits:
            </Label>
            <p className="text-4xl flex items-start mt-2">
              <span className="text-grayscale-600 text-3xl">$</span>
              &nbsp;
              <span className="text-grayscale-800">
                {parseFloat(creditNote.creditsAvailable).toFixed(2)}
              </span>
            </p>
          </div>
          <div className="col-span-1">
            <Label classes="col-span-2 text-grayscale-700">
              Credits Applied:
            </Label>
            <p className="text-4xl flex items-start mt-2">
              <span className="text-grayscale-600 text-3xl">$</span>
              &nbsp;
              <span className="text-grayscale-800">
                {parseFloat(creditNote.creditsApplied).toFixed(2)}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAppliedCredits;
