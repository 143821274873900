import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

export const advertisingSlice = createSlice({
  name: 'advertising',
  initialState: {
    exporting: false,
    statistics: {},
    campaigns: { rows: [] },
    adGroups: { rows: [] },
    keywords: {},
    targets: {},
    products: { rows: [] },
    searchTerms: { rows: [] },
    productAds: { rows: [] },
    records: [],
    rules: {},
    list: {},
    optimizations: [],
    ruleActions: { rows: [] },
    portfolios: { rows: [] },
    changeCollections: {},
    prevOptimizations: { rows: [] },
    displayCampaignTypeChart: false,
    displayTargetingTypeChart: false,
  },
  reducers: {
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setAdGroups: (state, action) => {
      state.adGroups = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductAds: (state, action) => {
      state.productAds = action.payload;
    },
    setSearchTerms: (state, action) => {
      state.searchTerms = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setExporting: (state, action) => {
      state.exporting = action.payload;
    },
    setRules: (state, action) => {
      state.rules = action.payload;
    },
    setRuleActions: (state, action) => {
      state.ruleActions = action.payload;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setPortfolios: (state, action) => {
      state.portfolios = action.payload;
    },
    setPrevOptimizations: (state, action) => {
      state.prevOptimizations = action.payload;
    },
    setOptimizations: (state, action) => {
      state.optimizations = action.payload;
    },
    setOptimizationItem: (state, action) => {
      const { index, value } = action.payload;
      if (value.selected) {
        state.optimizations
          .filter((opt) => opt.optimizableId === value.optimizableId)
          .forEach((opt) => {
            opt.selected = false;
          });
      }

      state.optimizations[index] = value;
    },
    setChangeCollections: (state, action) => {
      state.changeCollections = action.payload;
    },
    setDisplayCampaignTypeChart: (state, action) => {
      state.displayCampaignTypeChart = action.payload;
    },
    setDisplayTargetingTypeChart: (state, action) => {
      state.displayTargetingTypeChart = action.payload;
    },
  },
});

export const {
  setStatistics,
  setCampaigns,
  setAdGroups,
  setKeywords,
  setTargets,
  setProducts,
  setProductAds,
  setSearchTerms,
  setRecords,
  setExporting,
  setRules,
  setList,
  setPortfolios,
  setRuleActions,
  setOptimizations,
  setOptimizationItem,
  setNegativeKeywords,
  setPrevOptimizations,
  setChangeCollections,
  setDisplayCampaignTypeChart,
  setDisplayTargetingTypeChart,
} = advertisingSlice.actions;

export const getPrevOptimizationsAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/account/advertising/optimizations',
      params,
    });

    await dispatch(setPrevOptimizations(response.data.data));
  } catch (error) {
    console.log(error);
  }
};

export const getCampaignsAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/account/advertising/campaigns',
      params,
    });

    dispatch(setCampaigns(response.data.data));

    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getAdGroupsAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/account/advertising/ad-groups`,
      params,
    });

    dispatch(setAdGroups(response.data.data));

    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getKeywordsAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/account/advertising/keywords`,
      params,
    });

    dispatch(setKeywords(response.data.data));

    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getProductAdsAsync = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('ppc/product-ads', { params });

      const { data } = response.data;
      dispatch(setProductAds(data));

      resolve(data);
    } catch (error) {
      reject();
    }
  });
};

export const getRulesAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/account/advertising/rules',
      params,
    });

    dispatch(setRules(response.data.data));

    return response.data.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getPortfoliosAsync = (params) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/account/advertising/portfolios',
      params,
    });

    dispatch(setPortfolios(response.data.data));
  } catch (error) {
    console.log(error.message);
  }
};

export const selectList = (state) => state.advertising.list;
export const selectStatistics = (state) => state.advertising.statistics;
export const selectCampaigns = (state) => state.advertising.campaigns;
export const selectAdGroups = (state) => state.advertising.adGroups;
export const selectKeywords = (state) => state.advertising.keywords;
export const selectTargets = (state) => state.advertising.targets;
export const selectProducts = (state) => state.advertising.products;
export const selectProductAds = (state) => state.advertising.productAds;
export const selectSearchTerms = (state) => state.advertising.searchTerms;
export const selectRecords = (state) => state.advertising.records;
export const selectExporting = (state) => state.advertising.exporting;
export const selectCurrentFilter = (state) => state.advertising.currentFilter;
export const selectRules = (state) => state.advertising.rules;
export const selectRuleActions = (state) => state.advertising.ruleActions;
export const selectOptimizations = (state) => state.advertising.optimizations;
export const selectPortfolios = (state) => state.advertising.portfolios;
export const selectChangeCollections = (state) =>
  state.advertising.changeCollections;
export const selectPrevOptimizations = (state) =>
  state.advertising.prevOptimizations;

export const selectDisplayCampaignTypeChart = (state) =>
  state.advertising.displayCampaignTypeChart;

export const selectDisplayTargetingTypeChart = (state) =>
  state.advertising.displayTargetingTypeChart;

export default advertisingSlice.reducer;
