import axios from 'axios';
import Spinner from 'components/Spinner';
import { useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

const TaskRedirect = () => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/agency/tasks/items/${id}`, {
        params: {
          attributes: [
            'name',
            'clientId',
            'taskItemId',
            'taskListCode',
            'salesClientId',
          ],
        },
      });

      if (isSubscribed) {
        const { clientId, taskItemId, taskListCode } = response.data.data;

        let path = '';

        switch (taskListCode) {
          case 'client_operation_task_list':
            path = clientId
              ? `/clients/${clientId}/tasks/operations`
              : '/sales/workplace';
            break;
          case 'client_copy_writing_task_list':
            path = '/writing/workspace/my/copy-writing';
            break;
          case 'client_keyword_research_task_list':
            path = '/writing/workspace/my/keyword-research';
            break;
          case 'client_product_research_task_list':
            path = '/writing/workspace/my/product-research';
          case 'client_design_task_list':
            path = '/design/open';
            break;
          case 'client_ppc_task_list':
            path = '/sales/workplace';
            break;
        }

        history.push(`${path}/${taskItemId}`);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [id]);

  return (
    <div className="w-full flex justify-center py-24">
      <Spinner height={100} width={100} />
    </div>
  );
};

export default TaskRedirect;
