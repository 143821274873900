import axios from 'axios';
import moment from 'moment';
import { Menu } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { cloneDeep, isEmpty, isFunction } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  XIcon,
  PlusIcon,
  CheckIcon,
  ShareIcon,
  TrashIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  DotsHorizontalIcon,
  PlusCircleIcon,
} from '@heroicons/react/outline';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import useTaskTray from 'hooks/useTaskTray';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import DropdownMenu from 'components/DropdownMenu';
import useComment from 'components/Editor/components/useComment';
import TaskUpdates from './TaskUpdates';
import NewTask from './components/NewTask';
import TaskFiles from './components/TaskFiles';
import EditableContent from 'components/EditableContent';
import PriorityDropdown from './components/PriorityDropdown';
import ServiceAsin from './components/customFields/ServiceAsin';
import TaskStatusDropdown from './components/TaskStatusDropdown';
import TaskDueDateDropdown from './components/TaskDueDateDropdown';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import ButtonLink from 'components/ButtonLink';

import { getLatestUpdates } from './tasksSlice';
import { setAlert } from 'features/alerts/alertsSlice';

import classNames from 'utils/classNames';
import TaskTimeTracker from './components/TaskTimeTracker';
import Button from 'components/Button';

const TaskDetailsPage = ({
  onChange,
  assigneeOptions = [],
  allowedStatuses = [],
  serviceAsinData = {},
  notifyAssignee = false,
  onCloseModal,
  permissions = {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { taskItemId } = useParams();
  const { space } = useSelector((state) => state.tasks);
  const { addTaskToTray, removeTaskFromTray, inTray } = useTaskTray();

  const [task, setTask] = useState(null);
  const [open, setOpen] = useState(true);
  const [addSubTask, setAddSubTask] = useState(false);
  const [addingCustomField, setAddingCustomField] = useState(false);
  const { formats, toolbarOptions } = useComment();

  const {
    canUpdateStatus = true,
    canAddAssignee = true,
    canRemoveAssignee = true,
    canAddDueDate = true,
    canUpdateDueDate = true,
    canAddPriority = true,
    canUpdatePriority = true,
    canAddFiles = true,
    canRemoveFiles = true,
    canAddComments = true,
    canAddEmail = true,
    canUpdateName = true,
    canAddDescription = true,
    canUpdateDescription = true,
    canAddSubtasks = true,
    canDeleteTask = true,
  } = permissions;

  const getItem = async () => {
    const response = await axios.get(`/agency/tasks/items/${taskItemId}`, {
      params: {
        include: [
          'assignees',
          'subtasks',
          'parent',
          'customFields',
          'runningTimeLog',
          'list.folder.space.client',
          'list.folder.space.salesClient',
        ],
      },
    });
    setTask(response.data.data);
  };

  useEffect(() => {
    if (taskItemId) {
      getItem();
      setOpen(true);
    }
  }, [taskItemId]);

  const updateTask = (body) => {
    axios
      .put(`/agency/tasks/items/${task.taskItemId}`, body)
      .then((response) => {
        setTask(response.data.data);
        dispatch(getLatestUpdates(task.taskItemId));
      });
  };

  const onEditTitle = (e) => {
    updateTask({ name: e.target.value });
  };

  const onChangePriority = (priority) => {
    updateTask({ priority: priority ? priority.status : null });
  };

  const onChangeTimeTracker = (isStop) => {
    updateTask({ isTimerStop: isStop });
  };

  const onChangeDueDate = (dueDate) => {
    updateTask({ dueDate });
  };

  const onUpdateBrief = (content) => setTask({ ...task, description: content });

  const onUpdateAssignee = async (user, action) => {
    axios({
      method: action === 'add' ? 'POST' : 'DELETE',
      url: `/agency/tasks/items/${task.taskItemId}/assignees/${user.userId}`,
      data: { notifyUser: notifyAssignee },
    }).then((response) => {
      setTask(response.data.data);
      dispatch(getLatestUpdates(task.taskItemId));
    });
  };

  const onChangeStatus = (taskReference, { name }) => {
    axios
      .put(`/agency/tasks/items/${taskReference.taskItemId}`, {
        status: name,
      })
      .then((response) => {
        let newTask = cloneDeep(task);

        const responseData = response.data.data;

        if (newTask.taskItemId === responseData.taskItemId) {
          newTask = responseData;
          dispatch(getLatestUpdates(task.taskItemId));
        } else {
          const subtaskIndex = task.subtasks.findIndex(
            (t) => t.taskItemId === taskReference.taskItemId
          );
          newTask.subtasks[subtaskIndex] = responseData;
        }

        setTask(newTask);
      });
  };

  const onChangeToNextStatus = () => {
    const currentStatus = task.list.statuses.find(
      (i) => i.name === task.status
    );

    if (currentStatus) {
      const nextStatus = task.list.statuses.find(
        (i) => i.order === currentStatus.order + 1
      );

      if (nextStatus) {
        onChangeStatus(task, nextStatus);
      }
    }
  };

  const onMarkAsCompleted = () => {
    axios
      .put(`/agency/tasks/items/${task.taskItemId}/done`)
      .then((response) => {
        setTask(response.data.data);
        dispatch(getLatestUpdates(task.taskItemId));
      });
  };

  const onTrayAction = () => {
    let t = { ...task, url };
    if (inTray(task)) {
      removeTaskFromTray(task);
    } else {
      addTaskToTray(t);
    }
    setOpen(false);
  };

  const onShareLink = () => {
    const link = `${window.location.origin}${url}`;
    navigator.clipboard.writeText(link);
    dispatch(
      setAlert('success', 'Successfully copied link to clipboard', link)
    );
  };

  const onSubtaskAdded = (subtask) => {
    let newTask = cloneDeep(task);
    newTask.subtasks.push(subtask);
    setTask(newTask);
    setAddSubTask(false);
    dispatch(getLatestUpdates(task.taskItemId));
  };

  const onDeleteTask = () => {
    axios.delete(`/agency/tasks/items/${task.taskItemId}`).then((response) => {
      setOpen(false);
      isFunction(onChange) && onChange();
    });
  };

  const onClose = () => {
    setOpen(false);

    if (onCloseModal && isFunction(onCloseModal)) {
      onCloseModal();
      return;
    }

    const parentUrl = url.replace(`/${taskItemId}`, '');

    isFunction(onChange) && onChange();
    history.push(parentUrl);
  };

  const replaceTaskUrl = (subtaskId) => {
    return url.replace(`/${taskItemId}`, `/${subtaskId}`);
  };

  const redirectClientProfile = (id, salesClient = true) => {
    const redirectUrl = salesClient
      ? `/sales/client/${id}`
      : `/clients/${id}/dashboard`;
    window.open(redirectUrl, '_blank');
  };

  const addServiceAsin = () => {
    if (addingCustomField) return;

    setAddingCustomField(true);
    axios
      .post(`/agency/tasks/items/${taskItemId}/custom-fields`, serviceAsinData)
      .then(() => getItem())
      .finally(() => setAddingCustomField(false));
  };
  return (
    task && (
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-11/12 xl:w-4/5 h-90vh my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl">
          <ModalHeader
            title={
              <ButtonLink
                color={'blue'}
                textSize="lg"
                onClick={() => {
                  task.list.folder.space.salesClient
                    ? redirectClientProfile(
                        task.list.folder.space.salesClient.salesClientId
                      )
                    : redirectClientProfile(
                        task.list.folder.space.client.agencyClientId,
                        false
                      );
                }}
              >
                {task.list?.folder?.space?.client?.client ??
                  task.list?.folder?.space?.salesClient?.lead?.companyName}{' '}
                <ChevronRightIcon className="h-5 w-5 ml-2" />
              </ButtonLink>
            }
            setOpen={setOpen}
            titleClasses="capitalize"
            px="px-8"
            py="py-4"
            iconClassname="h-8 w-8"
            rightContent={
              <button
                title={inTray(task) ? 'remove from tray' : 'minimize to tray'}
                onClick={onTrayAction}
                className="relative group mr-4"
              >
                <ExternalLinkIcon className="w-8 h-8 transform rotate-90  text-gray-400 hover:text-green-500" />
                {inTray(task) && (
                  <>
                    <XIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 absolute bottom-0 right-0 bg-red-500 text-white rounded-xl leading-none" />
                    <CheckIcon className="opacity-100 group-hover:opacity-0 w-3 h-3 absolute bottom-0 right-0 bg-green-500 text-white rounded-xl leading-none" />
                  </>
                )}
              </button>
            }
            onClose={onClose}
          />
          <div className="lg:grid grid-cols-5" style={{ height: '85%' }}>
            <div className="bg-gray-50 col-span-3 py-5 px-8 flex items-center justify-between">
              <div className="flex relative">
                <TaskStatusDropdown
                  value={task.status}
                  setValue={(status) => onChangeStatus(task, status)}
                  compact={false}
                  disabled={!canUpdateStatus}
                  space={space}
                  options={task.list.statuses.filter((i) =>
                    allowedStatuses.length
                      ? allowedStatuses.includes(i.name)
                      : true
                  )}
                />

                {canUpdateStatus &&
                  allowedStatuses.length === 0 &&
                  task.list.statuses.find((i) => i.name === task.status)
                    ?.type !== 'closed' &&
                  task.list.statuses.find((i) => i.name === task.status)
                    ?.order !== task.list.statuses.length && (
                    <button
                      onClick={onChangeToNextStatus}
                      className={classNames(
                        'ml-1 rounded-md uppercase px-2 py-0.5 text-base font-bold font-inter'
                      )}
                      style={{
                        background: task.list.statuses.find(
                          (i) => i.name === task.status
                        )?.bgColor,
                      }}
                      title="move to next status"
                    >
                      <ChevronRightIcon className="w-6 h-6 inline" />
                    </button>
                  )}

                {canUpdateStatus &&
                  allowedStatuses.length === 0 &&
                  task.list.statuses.find((s) => s.name === task.status)
                    ?.type !== 'closed' && (
                    <button
                      onClick={onMarkAsCompleted}
                      className="ml-5 rounded-md uppercase px-1.5 py-0.5 text-base font-bold font-inter border-2  text-gray-400 border-gray-400 hover:bg-green-500 hover:border-white hover:text-white"
                      title="mark as completed"
                    >
                      <CheckIcon className="w-6 h-6 inline" />
                    </button>
                  )}

                <div className="ml-10">
                  <UsersPopoverMultiple
                    users={assigneeOptions}
                    position="left-1/2 transform -translate-x-1/2"
                    onChange={onUpdateAssignee}
                    selectedIds={task.assignees.map((user) => user.userId)}
                    iconSize="md1"
                    compact={true}
                    compactSize={4}
                    allowAdd={canAddAssignee}
                    allowRemove={canRemoveAssignee}
                  />
                </div>
                <div className="ml-4 pr-3  border-l border-gray-100">
                  &nbsp;
                </div>
                <div>
                  <PriorityDropdown
                    value={task.priority}
                    setValue={onChangePriority}
                    configurable={canAddPriority}
                    editable={canUpdatePriority}
                  />
                </div>
              </div>
              <div>
                <DropdownMenu
                  title={
                    <DotsHorizontalIcon className="w-8 h-8 text-gray-600 hover:text-green-600" />
                  }
                  titleClasses="flex items-center mr-1"
                  buttonBg="bg-transparent"
                  buttonFontWeight="font-normal"
                  hoverClasses=""
                  textColor="text-gray-600"
                  classes="text-sm"
                  buttonRounded=""
                  hoverText="hover:text-red-500"
                  dropdownWidth="w-28"
                  padding=""
                  position="right-0"
                  zIndex="z-10"
                  hideArrow
                >
                  <div className="flex flex-col ">
                    <Menu.Item>
                      <button
                        onClick={onShareLink}
                        className="flex items-center space-x-2 w-full hover:bg-gray-200 py-2 px-5"
                      >
                        <ShareIcon className="w-4 h-4 inline mr-2" /> Share
                      </button>
                    </Menu.Item>
                    {canDeleteTask && (
                      <Menu.Item>
                        <button
                          onClick={onDeleteTask}
                          className="flex items-center space-x-2 w-full hover:bg-gray-200 py-2 px-5"
                        >
                          <TrashIcon className="w-4 h-4 inline mr-2" /> Delete
                        </button>
                      </Menu.Item>
                    )}
                  </div>
                </DropdownMenu>
              </div>
            </div>
            <div className="col-span-2 bg-gray-50 py-4">
              <div className="grid grid-cols-3">
                <div className="col-span-1 flex flex-col px-8">
                  <label className="uppercase text-gray-400 text-xs">
                    created
                  </label>
                  <span className="text-sm text-gray-500 mt-1">
                    {moment(task.createdAt).format('MMM DD, YYYY')}
                  </span>
                </div>
                <div className="col-span-1 flex flex-col">
                  <label className="uppercase text-gray-400 text-xs">
                    due date
                  </label>
                  <span>
                    <TaskDueDateDropdown
                      value={task.dueDate}
                      setValue={onChangeDueDate}
                      configurable={canAddDueDate}
                      editable={canUpdateDueDate}
                    />
                  </span>
                </div>
                <div className="col-span-1 flex flex-col">
                  <label className="uppercase text-gray-400 text-xs pl-1.5">
                    time tracked
                  </label>
                  <span>
                    <TaskTimeTracker
                      onChange={onChange}
                      taskItemId={task.taskItemId}
                      hasRunningTimeLog={task.hasRunningTimeLog}
                      totalTrackedTime={task.totalTrackedTime}
                      runningTimeLog={task.runningTimeLog}
                      onChangeTimeTracker={onChangeTimeTracker}
                      detailPage={true}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white col-span-3 px-8 pt-8 pb-16 space-y-6 overflow-y-auto">
              {task.parentTaskItemId && task.parent && (
                <Link
                  to={replaceTaskUrl(task.parentTaskItemId)}
                  className="flex items-center text-gray-500 hover:text-gray-800"
                >
                  <ChevronRightIcon className="w-7 h-7 inline transform rotate-180 " />
                  <span className="ml-2 text-gray-400">{task.parent.name}</span>
                </Link>
              )}

              <EditableContent
                disabled={!canUpdateName}
                value={task.name}
                onUpdate={onEditTitle}
                tag="input"
                textSize="text-2xl"
                borderIndicator={classNames(
                  'border border-white',
                  canUpdateName ? 'hover:border-gray-200' : ''
                )}
              />

              <div className="border-b pb-6">
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700 font-bold">
                        <span className="font-inter tracking-wide">Brief</span>
                        <ChevronUpIcon
                          className={`${
                            open ? '' : 'rotate-180 transform'
                          } h-7 w-7 text-gray-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                        <div className="bg-gray-100 p-6 rounded-md">
                          <div
                            className={classNames(
                              !!!task.description && 'edit-comment',
                              'quill-text-editor py-2'
                            )}
                          >
                            <ReactQuill
                              theme="bubble"
                              placeholder="Description"
                              value={task.description}
                              onChange={onUpdateBrief}
                              bounds={`.quill-text-editor`}
                              modules={{
                                toolbar: toolbarOptions,
                                clipboard: {
                                  matchVisual: false,
                                },
                                imageActions: {},
                                imageFormats: {},
                              }}
                              formats={formats}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button
                            onClick={() => {
                              updateTask({ description: task.description });
                              dispatch(
                                setAlert(
                                  'success',
                                  'Task description updated',
                                  'Task description updated successfully'
                                )
                              );
                            }}
                            className="rounded-md shadow-sm px-2 py-1 bg-green-500 hover:bg-green-600 text-mini text-grayscale-300 tracking-2 font-bold focus:outline-none mt-2"
                          >
                            Save
                          </button>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>

              {!!!task.customFields.some(
                (customField) => customField.type === 'service-asin'
              ) &&
                !isEmpty(serviceAsinData) &&
                !task.parentTaskItemId && (
                  <div className="flex w-full justify-between pt-2 pb-5 text-left text-lg tracking-tight font-inter border-b text-gray-700 font-bold">
                    <p className="font-inter tracking-wide flex items-center">
                      Service ASIN
                      <span>
                        <PlusCircleIcon
                          className="w-8 h-8 ml-3 text-secondary cursor-pointer"
                          onClick={() => addServiceAsin()}
                        />
                      </span>
                    </p>
                  </div>
                )}

              {task.customFields.map((customField) => {
                return (
                  <div key={customField.id}>
                    <ServiceAsin field={customField} onChange={getItem} />
                  </div>
                );
              })}

              {!task.parentTaskItemId && (
                <div className="border-b pb-6">
                  <div className="flex items-center w-full py-2 text-left text-lg text-gray-700 font-bold font-inter tracking-wide">
                    Subtasks
                    {canAddSubtasks && (
                      <button
                        onClick={() => setAddSubTask(true)}
                        title="Add Subtask"
                        className="ml-6 border border-gray-500 text-gray-400 hover:bg-gray-50 rounded-3xl px-6 py-2 text-sm tracking-widest font-bold"
                      >
                        <PlusIcon className="w-4 h-4 inline" />
                        Add
                      </button>
                    )}
                  </div>
                  <div className="list-group">
                    {task.subtasks && (
                      <div className="flex flex-col px-8 py-4 space-y-6">
                        {task.subtasks.map((subtask) => (
                          <div
                            key={subtask.taskItemId}
                            className="flex items-center"
                          >
                            <TaskStatusDropdown
                              value={subtask.status}
                              setValue={(status) =>
                                onChangeStatus(subtask, status)
                              }
                              space={space}
                              options={task.list.statuses}
                              disabled={!canUpdateStatus}
                            />
                            <Link to={replaceTaskUrl(subtask.taskItemId)}>
                              <span className="ml-4">{subtask.name}</span>
                            </Link>
                          </div>
                        ))}
                      </div>
                    )}
                    {addSubTask && (
                      <NewTask
                        isSubtask={true}
                        cancel={() => setAddSubTask(false)}
                        taskListId={task.taskListId}
                        parentTaskItemId={task.taskItemId}
                        created={onSubtaskAdded}
                        subtaskSpacer="px-1"
                        status={task.list?.statuses[0]?.name}
                        assigneeOptions={assigneeOptions}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="pb-6">
                <TaskFiles
                  taskItemId={task.taskItemId}
                  canUpload={canAddFiles}
                  canDelete={canRemoveFiles}
                />
              </div>
            </div>
            <div className="col-span-2 bg-gray-50 border-l border-gray-100 overflow-y-auto">
              <TaskUpdates
                taskItemId={task.taskItemId}
                canAddComment={canAddComments}
                canSendEmail={canAddEmail}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default TaskDetailsPage;
