import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import QuoteBody from './components/QuoteBody';
import QuoteHeader from './components/QuoteHeader';

const Preview = () => {
  const { identifier } = useParams();
  const [quote, setQuote] = useState(null);

  const load = async () => {
    await axios
      .get(`/agency/sales/quotes/${identifier}`)
      .then((response) => setQuote(response.data.data));
  };

  useEffect(() => load(), [identifier]);

  return (
    <div className="h-screen print:h-auto bg-white flex overflow-hidden print:overflow-visible agency-layout">
      <div className="content-area flex-1 flex flex-col overflow-hidden">
        <div className="bg-warning-light text-center text-xs text-grayscale-800 py-2 font-inter tracking-widest uppercase">
          This is a preview link. Please do not share this page to the client
        </div>
        <QuoteHeader quote={quote} setQuote={setQuote} type="preview" />
        <QuoteBody quote={quote} />
      </div>
    </div>
  );
};
export default Preview;
