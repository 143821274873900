import axios from 'axios';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/outline';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string, number } from 'yup';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { joiAlertErrorsStringify } from 'utils/formatters';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import ButtonLink from 'components/ButtonLink';
import useAlert from 'hooks/useAlert';
import BodyCopy from './BodyCopy';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { useFormikContext } from 'formik';
import usePermissions from 'hooks/usePermissions';
import MockupImages from './MockupImages';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListingMockup } from './mockupSlice';
import classNames from 'utils/classNames';
import AplusUpload from './components/AplusUpload';
import classnames from 'classnames';
import { AnnotationIcon, EyeIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';

const CopyPartial = forwardRef(({ label, prop, editable }, ref) => {
  const copyRef = useRef();
  const { userCan } = usePermissions();
  const { values } = useFormikContext();

  useImperativeHandle(ref, () => ({
    validate() {
      return copyRef.current.validate();
    },
  }));

  return (
    <div className="border-b pb-4 mb-4">
      <h2 className="text-gray-600 font-bold mb-6">{label}</h2>
      <Label textColor="text-gray-400">Existing Copy</Label>
      {editable && userCan('mockup.manage.copy') ? (
        <>
          <Field
            name={`${prop}.existingCopy`}
            as="textarea"
            rows={2}
            className="form-input-w text-gray-700 px-0 py-1"
            maxLength={prop === 'title' ? '200' : '250'}
          />
          <ErrorMessage
            name={`${prop}.existingCopy`}
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </>
      ) : (
        <p
          className="text-gray-700 px-0 py-1"
          dangerouslySetInnerHTML={{ __html: values[prop].existingCopy }}
        />
      )}

      {values[prop].revisions.length > 0 && (
        <div className="my-5">
          <p className="font-normal text-13 text-custom-error whitespace-pre-wrap">
            Client Revision Note
          </p>
          <p className="font-normal text-grayscale-900 text-13 pt-1.5 whitespace-pre-wrap">
            {values[prop].revisions[0].comment}
          </p>
        </div>
      )}

      <Label textColor="text-gray-400 mt-3 flex">New Copy</Label>
      {editable && userCan('mockup.manage.copy') ? (
        <>
          <BodyCopy
            name="newCopy"
            prop={prop}
            maxLength={prop === 'title' ? 200 : 250}
            ref={copyRef}
          />
          <ErrorMessage
            name={`${prop}.newCopy`}
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </>
      ) : (
        <p
          className="border border-white rounded-xl bg-gray-50 p-4"
          dangerouslySetInnerHTML={{ __html: values[prop].newCopy }}
        />
      )}
    </div>
  );
});

const ListingMockupForm = ({ item }) => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { alertSuccess, alertError } = useAlert();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const [saving, setSaving] = useState(false);
  const [textReadyExport, setTextReadyExport] = useState(false);
  const [slideReadyExport, setSlideReadyExport] = useState(false);

  const attr = {
    existingCopy: '',
    newCopy: '',
    listingMockupTextId: '',
    revisions: [],
  };
  const data = {
    title: { ...attr, order: 1, type: 'TITLE' },
    bpOne: { ...attr, order: 2, type: 'BULLET' },
    bpTwo: { ...attr, order: 3, type: 'BULLET' },
    bpThree: { ...attr, order: 4, type: 'BULLET' },
    bpFour: { ...attr, order: 5, type: 'BULLET' },
    bpFive: { ...attr, order: 6, type: 'BULLET' },
    keywords: { ...attr, order: 7, type: 'KEYWORDS' },
    description: { ...attr, order: 8, type: 'DESCRIPTION' },
  };
  const [initialValues, setInitialValues] = useState(data);
  const titleRef = useRef();
  const bpOneRef = useRef();
  const bpTwoRef = useRef();
  const bpThreeRef = useRef();
  const bpFourRef = useRef();
  const bpFiveRef = useRef();

  useEffect(() => {
    if (item.listingMockupText.length > 0) {
      const getItem = (order) => {
        return item.listingMockupText.find((i) => i.order === order);
      };

      setInitialValues({
        title: getItem(1),
        bpOne: getItem(2),
        bpTwo: getItem(3),
        bpThree: getItem(4),
        bpFour: getItem(5),
        bpFive: getItem(6),
        keywords: getItem(7),
        description: getItem(8),
      });

      setTextReadyExport(true);
    }

    if (item.listingMockupSlide.length > 0) {
      setSlideReadyExport(true);
    }
  }, [item]);

  const copySchema = object().shape({
    existingCopy: string()
      .required('Existing copy is required')
      .max(250, 'Cannot be more than 250 characters'),
    newCopy: string().required('New copy is required'),
    order: number().required(),
  });

  const validationSchema = object().shape({
    title: object().shape({
      existingCopy: string()
        .required('Existing copy is required')
        .max(200, 'Cannot be more than 200 characters'),
      newCopy: string().required('Title is Required'),
      order: number().required(),
    }),
    bpOne: copySchema,
    bpTwo: copySchema,
    bpThree: copySchema,
    bpFour: copySchema,
    bpFive: copySchema,
    keywords: object().shape({
      newCopy: string(),
      order: number().required(),
    }),
    description: object().shape({
      newCopy: string(),
      order: number().required(),
    }),
  });

  const onSubmit = async (values) => {
    const response = await Promise.all([
      titleRef.current.validate(),
      bpOneRef.current.validate(),
      bpTwoRef.current.validate(),
      bpThreeRef.current.validate(),
      bpFourRef.current.validate(),
      bpFiveRef.current.validate(),
    ]);

    const copyValidate = response
      .map((data) => {
        return data.length;
      })
      .every((c) => c === 0);

    if (copyValidate) {
      try {
        setSaving(true);
        const response = await axios.post(
          `/agency/clients/listing-mockup/${item.listingMockupId}`,
          values
        );
        dispatch(fetchListingMockup(agencyClient.agencyClientId));
        //await onReload();
        alertSuccess('Listing Mockup saved', response.data.message);
        setSaving(false);
      } catch (error) {
        const errorMessages = joiAlertErrorsStringify(error);
        alertError(error.response.data.message, errorMessages);
      } finally {
        setSaving(false);
      }
    } else {
      alertError(
        'Copy Errors',
        'Some of your copies contain restricted keywords'
      );
    }
  };

  const onResetForm = () => {};

  const onExport = async (status) => {
    try {
      setSaving(true);
      const response = await axios.patch(
        `/agency/clients/listing-mockup/${item.listingMockupId}/status`,
        { status }
      );
      dispatch(fetchListingMockup(agencyClient.agencyClientId));
      alertSuccess(
        status === 'published'
          ? 'Listing mockup published'
          : 'Listing mockup marked as draft',
        response.data.message
      );
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    } finally {
      setSaving(false);
    }
  };

  const editable = () => {
    return item.status === 'draft' || item.status === 'for_revision';
  };
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
            <h2
              className={classNames(
                classnames({
                  'text-green-500': item.status === 'approved',
                }),
                'font-inter tracking-3/4'
              )}
            >
              <span>{item.asin}</span>
            </h2>
            <div className="flex items-center justify-between">
              {item.status === 'published' && (
                <EyeIcon
                  className="font-sourceSansPro mr-3 w-4 h-4 text-yellow-500"
                  alt="Published"
                />
              )}
              {item.status === 'for_revision' && (
                <AnnotationIcon className="font-sourceSansPro mr-3 w-4 h-4 text-red-500" />
              )}
              {item.status === 'approved' && (
                <CheckCircleIcon className="font-sourceSansPro mr-3 w-4 h-4 text-green-500" />
              )}
              <ChevronDownIcon
                className={`${
                  open ? 'rotate-180 transform' : ''
                } h-6 w-6 text-gray-500`}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
            {({ close }) => (
              <div className="flex flex-col">
                <div className="mb-8">
                  <MockupImages listingMockup={item} setSlideReadyExport={setSlideReadyExport}/>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >
                  {({ handleSubmit, handleReset, setFieldValue, values }) => (
                    <Form>
                      <FormikErrorNotification />
                      <div className="flex flex-col">
                        <CopyPartial
                          label="Title"
                          prop="title"
                          ref={titleRef}
                          editable={editable()}
                        />
                        <CopyPartial
                          label="Bullet Point 1"
                          prop="bpOne"
                          ref={bpOneRef}
                          editable={editable()}
                        />
                        <CopyPartial
                          label="Bullet Point 2"
                          prop="bpTwo"
                          ref={bpTwoRef}
                          editable={editable()}
                        />
                        <CopyPartial
                          label="Bullet Point 3"
                          prop="bpThree"
                          ref={bpThreeRef}
                          editable={editable()}
                        />
                        <CopyPartial
                          label="Bullet Point 4"
                          prop="bpFour"
                          ref={bpFourRef}
                          editable={editable()}
                        />
                        <CopyPartial
                          label="Bullet Point 5"
                          prop="bpFive"
                          ref={bpFiveRef}
                          editable={editable()}
                        />
                        <div className="border-b pb-4 mb-2">
                          <h2 className="text-gray-600 font-bold mb-4">
                            Keywords
                          </h2>
                          {values.keywords.revisions.length > 0 && (
                            <div className="my-5">
                              <p className="font-normal text-13 text-custom-error">
                                Client Revision Note
                              </p>
                              <p className="font-normal text-grayscale-900 text-13 pt-1.5">
                                {values.keywords.revisions[0].comment}
                              </p>
                            </div>
                          )}
                          {editable() && userCan('mockup.manage.copy') ? (
                            <Field
                              name={`keywords.newCopy`}
                              as="textarea"
                              rows={3}
                              className="form-input"
                            />
                          ) : (
                            <p
                              className="border border-white rounded-xl bg-gray-50 p-4"
                              dangerouslySetInnerHTML={{
                                __html: values.keywords.newCopy,
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <h2 className="text-gray-600 font-bold mb-4">
                            Description
                          </h2>
                          {values.description.revisions.length > 0 && (
                            <div className="my-5">
                              <p className="font-normal text-13 text-custom-error">
                                Client Revision Note
                              </p>
                              <p className="font-normal text-grayscale-900 text-13 pt-1.5">
                                {values.description.revisions[0].comment}
                              </p>
                            </div>
                          )}
                          {editable() && userCan('mockup.manage.copy') ? (
                            <Field
                              name={`description.newCopy`}
                              as="textarea"
                              rows={3}
                              className="form-input"
                            />
                          ) : (
                            <p
                              className="border border-white rounded-xl bg-gray-50 p-4"
                              dangerouslySetInnerHTML={{
                                __html: values.description.newCopy,
                              }}
                            />
                          )}
                        </div>

                        <div className="flex justify-between">
                          <div className="flex space-x-4 items-center my-8">
                            <ButtonLink
                              classes="tracking-wider font-bold"
                              color="blue"
                              onClick={() => {
                                handleReset();
                                close();
                              }}
                            >
                              Cancel
                            </ButtonLink>
                            {userCan(
                              'mockup.manage.copy|mockup.manage.media'
                            ) &&
                              editable() && (
                                <Button
                                  classes="border-0 font-bold tracking-wider mr-2"
                                  bgColor="blue-800"
                                  hoverColor="blue-900"
                                  roundedSize="full"
                                  textColor="white"
                                  px={5}
                                  py={2}
                                  shadow=""
                                  type="submit"
                                  showLoading={true}
                                  loading={saving}
                                >
                                  {item.status === 'draft'
                                    ? 'Save as draft'
                                    : 'Update'}
                                </Button>
                              )}
                            {editable() &&
                              userCan('mockup.export') &&
                              textReadyExport > 0 &&
                              slideReadyExport > 0 && (
                                <Button
                                  classes="border-0 font-bold tracking-wider mr-2"
                                  bgColor="blue-800"
                                  hoverColor="blue-900"
                                  roundedSize="full"
                                  textColor="white"
                                  px={5}
                                  py={2}
                                  shadow=""
                                  type="button"
                                  showLoading={true}
                                  loading={saving}
                                  onClick={() => onExport('published')}
                                >
                                  Export
                                </Button>
                              )}
                            {item.status === 'published' &&
                              userCan('mockup.export') && (
                                <Button
                                  classes="border-0 font-bold tracking-wider mr-2"
                                  bgColor="red-500"
                                  hoverColor="red-600"
                                  roundedSize="full"
                                  textColor="white"
                                  px={5}
                                  py={2}
                                  shadow=""
                                  type="button"
                                  showLoading={true}
                                  loading={saving}
                                  onClick={() => onExport('draft')}
                                >
                                  Mark as Draft
                                </Button>
                              )}
                          </div>
                          {userCan('mockup.update') && (
                            <ButtonLink onClick={handleReset}>
                              <TrashIcon className="w-5 h-5 inline" />
                            </ButtonLink>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>

                {userCan('mockup.manage.aplus') && (
                  <div className="flex flex-col items-left mb-6 space-y-2">
                    <h2 className="text-gray-600 font-bold">A+ Content</h2>
                    <AplusUpload item={item} />
                  </div>
                )}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ListingMockupForm;
