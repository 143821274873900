import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from 'layouts/components/Header';
import Navigations from 'layouts/components/Navigations';

import { NavProvider } from 'layouts/NavProvider';
import { fetchAllEmployees } from 'features/employees/employeesSlice';

import useAuth from 'hooks/useAuth';

const AgencyLayout = ({ children, history }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    dispatch(fetchAllEmployees());
  }, []);

  if (user.passwordGenerated) {
    history.push('/change-password?');
  }

  return (
    <NavProvider>
      <div className={`h-screen bg-gray-50 flex overflow-hidden agency-layout`}>
        <Navigations />

        <div className="content-area flex-1 flex flex-col overflow-hidden">
          <Header />
          <main className="main-content flex-1 relative overflow-y-auto focus:outline-none">
            <div className="main-content-inner1">
              <div className="main-content-inner2 max-auto">{children}</div>
            </div>
          </main>
        </div>
      </div>
    </NavProvider>
  );
};

export default withRouter(AgencyLayout);
