import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, date, array, boolean, ref } from 'yup';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import {
  PlusCircleIcon,
  ClipboardIcon,
  XIcon,
  ExternalLinkIcon,
  UploadIcon,
  LinkIcon,
} from '@heroicons/react/outline';
import { ChevronUpIcon, TrashIcon } from '@heroicons/react/solid';
import Textarea from 'components/Forms/Textarea';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import InputPrepend from 'components/Forms/InputPrepend';
import { fetchClient, getLatestUpdates } from './salesSlice';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import usePermissions from 'hooks/usePermissions';
import useBodyClass from 'hooks/useBodyClass';
import SubscriptionFormPartial from '../clients/Client/NewClient/SubscriptionFormPartial';
import CommissionFormPartial from '../clients/Client/NewClient/CommissionFormPartial';
// import CommissionFormPartial from './CommissionFormPartial';
import { fetchPlans, fetchAddons } from 'features/zoho/zohoSlice';
import { DEFAULT_MARKETPLACE } from 'utils/constants';
import clientsSlice from 'features/clients/clientsSlice';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import CallBookingQuotes from './CallBookingQuotes';
import { fetchMarketplaces } from 'features/marketplaces/marketplacesSlice';
import HubspotInfo from 'components/Forms/HubspotInfo';
import { MARKETPLACE_KEY } from './utils/constants';
import { set, cloneDeep, isEmpty } from 'lodash';
moment.tz.setDefault('America/Toronto');

const Profile = () => {
  useBodyClass('client-profile');
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const disabled = !userCan('sales.profiles.manage');
  const { salesClientId } = useParams();
  const { client, clientStatus } = useSelector((state) => state.sales);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [am, setAm] = useState([]);
  const [liAccounts, setLiAccounts] = useState({});
  const me = useSelector(selectAuthenticatedUser);
  const { plans, addons } = useSelector((state) => state.zoho);
  const [variables, setVariables] = useState({});
  const [leadMarketPlaces, setLeadMarketPlaces] = useState([]);
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const [productCategoryOptions, setProductCategoryOptions] = useState(['']);

  const callBookingObj = {
    bookingDate: '',
    recordingLink: '',
    videoSummaryLink: '',
    notes: '',
    addedBy: me.userId,
    source: client?.lead?.source ?? '',
    serviceCondition: '',
  };

  const competitorObj = {
    competitorBrandName: '',
    competitorScreenShotURL: '',
    revenue: '',
    fileId: '',
  };
  let emptyInitialValues = {
    leadId: '??',
    source: 'unknown',
    // status: client.status ?? '',
    decisionMaker: '',
    email: '',
    displayName: '',
    accountManager: '',
    aboutUs: '',
    goals: '',
    painPoints: '',
    mainIssue: '',
    avgMonthlySalesOnAmazon: 0,
    avgMonthlySalesOutsideAmazon: 0,
    ppcSpend: 0,
    avgACOS: 0,
    callBookings: [callBookingObj],
    competitors: [competitorObj],
    serviceAgreementSentDate: null,
    serviceAgreementSignedDate: null,
    // serviceAgreementLink: client.serviceAgreementLink ?? '',
    service: '',
    invoiceSentDate: null,
    totalAmountPaid: 0,
    lead: {
      lead: '',
      leadLastName: '',
      companyName: '',
      email: '',
      facebook: '',
      linkedInProfileURL: '',
      linkedInAccountId: '',
      instagram: '',
      phoneNumber: '',
      address: '',
      source: '',
      bookingSource: '',
      website: '',
      marketplace: '',
      linkedInProfilePictureURL: '',
      productCategory: '',
      subCategory1: '',
      subCategory2: '',
      brandName: '',
      amzStoreFBAstoreFront: '',
      leadScreenShotURL: '',
      asin: '',
      asinFullTitle: '',
      asinMajorKeyword: '',
      asinPrice: 0,
      asinReviews: 0,
      asinRevenueScreenshot: '',
      currentEarnings: 0,
      storeFrontEarnings: 0,
      competitorBrandName: '',
      competitorAsinRevenueScreenshot: '',
      competitorScreenShotURL: '',
      revenue: 0,
      profileAsinRevenueScreenshot: {},
    },

    noCommission: false,
    noCommissionReason: '',
    serviceAgreementLink: '',

    plan_code: '',
    name: '',
    reference_id: '',
    pricebook_id: '',
    price: 0,
    noExpiry: true,
    billing_cycles: '',
    currency_code: 'USD',
    convertDisabled: false,
    convert_retainer_cycle: null,
    retainer_after_convert: '',
    plan_description: '',
    addons: [],

    defaultMarketplace: DEFAULT_MARKETPLACE,
    siEmail: '',
    contractSigned: '',

    commissionType: 'gross',
    rate: 0,
    monthThreshhold: 0,
    baseline: null,
    managedAsins: [],
    rules: [],
    autoAdd: false,
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans());
    }
  }, [plans, dispatch]);
  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons());
    }
  }, [addons, dispatch]);
  useLayoutEffect(() => {
    if (marketplaces.length <= 0) {
      dispatch(fetchMarketplaces());
    }
  }, [dispatch]);

  function productCategorySetter(marketplace, variables) {
    setProductCategoryOptions(
      MARKETPLACE_KEY?.find((el) => el.key === marketplace) &&
        variables.rows.find(
          (el) =>
            el.key === MARKETPLACE_KEY.find((el) => el.key === marketplace).val
        )
        ? variables.rows
            .find(
              (el) =>
                el.key ===
                MARKETPLACE_KEY.find((el) => el.key === marketplace).val
            )
            .value.split('\n')
            .map((el) => el.toLowerCase())
        : variables.rows
            .find((el) => el.key === 'productCategoryUsa')
            .value.split('\n')
            .map((el) => el.toLowerCase())
    );
  }

  const liAccountsOptions =
    liAccounts && liAccounts.rows ? liAccounts.rows : [];

  useEffect(async () => {
    let isMounted = true;
    const fetchAm = async () => {
      const am = await axios.get(
        `/agency/sales/clients/reps?roles=account manager,Account Manager`
      );
      if (isMounted) setAm(am.data.data);
    };

    const fetchLiAccount = async () => {
      const liAccount = await axios.get(
        `/agency/leads/liAccounts?sort=name:asc`,
        {
          params: { pageSize: 1000, status: 'active' },
        }
      );
      if (isMounted) setLiAccounts(liAccount.data.data);
    };

    fetchAm().catch(console.error);
    fetchLiAccount().catch(console.error);
    let variableResponse = await axios.get(`/agency/leads/variables`, {
      params: { pageSize: 1000 },
    });

    setVariables(variableResponse.data.data);

    setLeadMarketPlaces(
      variableResponse.data.data.rows
        .find((el) => el.key === 'leadMarketPlace')
        .value.split('\n')
    );

    let tempValues = {};

    if (client) {
      tempValues = {
        ...initialValues,
        leadId: client.leadId ?? '??',
        source: client.source ?? 'unknown',
        // status: client.status ?? '',
        decisionMaker: client.decisionMaker ?? '',
        displayName: client.displayName ?? '',
        email: client.email ?? '',
        accountManager: client.accountManager ?? '',
        aboutUs: client.aboutUs ?? '',
        goals: client.goals ?? '',
        painPoints: client.painPoints ?? '',
        mainIssue: client.mainIssue ?? '',
        avgMonthlySalesOnAmazon: client.avgMonthlySalesOnAmazon ?? 0,
        ppcSpend: client.ppcSpend ?? 0,
        avgACOS: client.avgACOS ?? 0,
        avgMonthlySalesOutsideAmazon: client.avgMonthlySalesOutsideAmazon ?? 0,
        callBookings: client.callBookings
          ? client.callBookings.map((el) => ({
              ...el,
              bookingDate: moment(el.bookingDate).utc().format('YYYY-MM-DD'),
              // dueDate: moment(el.dueDate).utc().format('YYYY-MM-DD'),
            }))
          : [callBookingObj],
        competitors: client.lead.competitor
          ? client.lead.competitor
          : [competitorObj],
        serviceAgreementSentDate: client.serviceAgreementSentDate
          ? moment(client.serviceAgreementSentDate).utc().format('YYYY-MM-DD')
          : null,
        serviceAgreementSignedDate: client.serviceAgreementSignedDate
          ? moment(client.serviceAgreementSignedDate).utc().format('YYYY-MM-DD')
          : null,
        // serviceAgreementLink: client.serviceAgreementLink ?? '',
        // service: client.serviceType ?? '',
        invoiceSentDate: client.invoiceSentDate
          ? moment(client.invoiceSentDate).utc().format('YYYY-MM-DD')
          : null,
        totalAmountPaid: client.totalAmountPaid ?? 0,
        lead: {
          lead: client.lead?.lead ?? '',
          leadLastName: client.lead?.leadLastName ?? '',
          companyName: client.lead?.companyName ?? '',
          email: client.lead?.email ?? '',
          facebook: client.lead?.facebook ?? '',
          linkedInProfileURL: client.lead?.linkedInProfileURL ?? '',
          linkedInAccountId: client.lead?.linkedInAccountId ?? '',
          instagram: client.lead?.instagram ?? '',
          phoneNumber: client.lead?.phoneNumber ?? '',
          address: client.lead?.address ?? '',
          source: client.lead?.source ?? '',
          bookingSource: client.lead?.bookingSource ?? '',
          website: client.lead?.website ?? '',
          marketplace: client.lead?.marketplace ?? '',
          linkedInProfilePictureURL:
            client.lead?.linkedInProfilePictureURL ?? '',
          productCategory: client.lead?.productCategory ?? '',
          subCategory1: client.lead?.subCategory1 ?? '',
          subCategory2: client.lead?.subCategory2 ?? '',
          brandName: client.lead?.brandName ?? '',
          amzStoreFBAstoreFront: client.lead?.amzStoreFBAstoreFront ?? '',
          leadScreenShotURL: client.lead?.leadScreenShotURL ?? '',
          asin: client.lead?.asin ?? '',
          asinFullTitle: client.lead?.asinFullTitle ?? '',
          asinMajorKeyword: client.lead?.asinMajorKeyword ?? '',
          asinPrice: client.lead?.asinPrice ?? 0,
          asinReviews: client.lead?.asinReviews ?? 0,
          asinRevenueScreenshot: client.lead?.asinRevenueScreenshot ?? '',
          currentEarnings: client.lead?.currentEarnings ?? 0,
          storeFrontEarnings: client.lead?.storeFrontEarnings ?? 0,
          competitorBrandName: client.lead?.competitorBrandName ?? '',
          competitorAsinRevenueScreenshot:
            client.lead?.competitorAsinRevenueScreenshot ?? '',
          competitorScreenShotURL: client.lead?.competitorScreenShotURL ?? '',
          revenue: client.lead?.revenue ?? 0,
          profileAsinRevenueScreenshot:
            client.lead?.profileAsinRevenueScreenshot ?? '',
        },
      };
    }

    if (tempValues?.lead?.marketplace) {
      productCategorySetter(
        tempValues.lead.marketplace,
        variableResponse.data.data
      );
    }

    if (client && client.AgencyClient) {
      const checkCommissionValue = (key) => {
        if (
          client.AgencyClient.account &&
          client.AgencyClient.account.commissions[0]
        ) {
          if (key === 'commence') {
            return client.AgencyClient.account.commissions[0].commencedAt
              ? true
              : false;
          }
          return client.AgencyClient.account.commissions[0][key];
        }

        if (client.AgencyClient.draftCommission) {
          return client.AgencyClient.draftCommission[key];
        }

        return null;
      };

      tempValues = {
        ...tempValues,
        noCommission: client.AgencyClient?.noCommission ?? false,
        noCommissionReason: client.AgencyClient?.noCommissionReason ?? '',
        serviceAgreementLink: client.AgencyClient?.serviceAgreementLink ?? '',
        service: client.AgencyClient?.service ?? '' ?? '',
        displayName: client.AgencyClient?.displayName
          ? client.AgencyClient?.displayName
          : client.displayName ?? '',
        plan_code: client.AgencyClient?.hostedpageDetails?.plan_code ?? '',
        name: client.AgencyClient?.hostedpageDetails?.name ?? '',
        reference_id:
          client.AgencyClient?.hostedpageDetails?.reference_id ?? '',
        pricebook_id:
          client.AgencyClient?.hostedpageDetails?.pricebook_id ?? '',
        price: client.AgencyClient?.hostedpageDetails?.price ?? 0,
        noExpiry: client.AgencyClient?.hostedpageDetails?.noExpiry ?? true,
        billing_cycles:
          client.AgencyClient?.hostedpageDetails?.billing_cycles ?? '',
        currency_code:
          client.AgencyClient?.hostedpageDetails?.currency_code ?? 'USD',
        convertDisabled:
          client.AgencyClient?.hostedpageDetails?.convert_retainer_cycle ===
            '' ||
          client.AgencyClient?.hostedpageDetails?.convert_retainer_cycle === 0
            ? true
            : false,
        convert_retainer_cycle:
          client.AgencyClient?.hostedpageDetails?.convert_retainer_cycle ??
          null,
        retainer_after_convert:
          client.AgencyClient?.hostedpageDetails?.retainer_after_convert ?? '',
        plan_description:
          client.AgencyClient?.hostedpageDetails?.plan_description ?? '',
        addons: client.AgencyClient?.hostedpageDetails?.addons ?? [],

        defaultMarketplace:
          client.AgencyClient?.hostedpageDetails?.draftMarketplace ??
          DEFAULT_MARKETPLACE,
        siEmail: client.AgencyClient?.siEmail
          ? client.AgencyClient?.siEmail
          : client.AgencyClient?.hostedpageDetails?.siEmail ?? '',
        contractSigned: client.AgencyClient?.contractSigned
          ? moment.utc(client.AgencyClient.contractSigned).format('YYYY-MM-DD')
          : '',

        commissionType: checkCommissionValue('type'),
        rate: checkCommissionValue('rate') ?? 0,
        monthThreshhold: checkCommissionValue('monthThreshold') ?? 0,
        baseline: checkCommissionValue('baseline') ?? null,
        managedAsins: checkCommissionValue('managedAsins') ?? [],
        rules: checkCommissionValue('rules') ?? [],
        autoAdd: checkCommissionValue('commence') ?? false,
      };
    }

    setInitialValues({
      ...tempValues,
    });

    ReactTooltip.rebuild();
    return () => {
      isMounted = false;
    };
  }, [client]);

  const descriptionCheck = (string) => /[<>]/.test(string);
  const displayNameCheck = (string) =>
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);
  let validationObj = {
    // decisionMaker: string().required('Decision Maker - Required'),
    // email: string()
    //   .email('Invalid email')
    //   .required('Decision Maker Email - Required'),
    // aboutUs: string().required('About Us - Required'),
    // goals: string().required('Goal - Required'),
    // lead: object()
    //   .shape({
    //     lead: string().required('Required - First Name'),
    //     leadLastName: string().required('Required - Last Name'),
    //     email: string()
    //       .email('Invalid email')
    //       .required('Required - Lead Email'),
    //     // source: string().required('Required - Source'),
    //     // brandName: string().required('Brand Name- Required'),
    //     // amzStoreFBAstoreFront: string()
    //     //   .url()
    //     //   .required('Amazon Store Front URL - Required'),
    //     // productCategory: string().required('Product Category - Required'),
    //     // marketplace: string().required('Marketplace - Required'),
    //     // asin: string().required('ASIN - Required'),
    //     // asinFullTitle: string().required('ASIN Full Title- Required'),
    //     // leadScreenShotURL: string().url().required('ASIN URL - Required'),
    //     // asinPrice: number().min(0).required('ASIN Price - Required'),
    //     // asinReviews: number().min(0).required('ASIN Reviews - Required'),
    //     // currentEarnings: number().min(0).required('ASIN Revenue - Required'),
    //     // storeFrontEarnings: number()
    //     //   .min(0)
    //     //   .required('Storefront Revenue - Required'),
    //     // asinRevenueScreenshot: string().required(
    //     //   'ASIN Revenue Screenshot - Required'
    //     // ),
    //   })
    //   .required(),
    displayName: string()
      .test(
        'Display Name must not have any special characters or symbols',
        (value) => !displayNameCheck(value)
      )
      .max(25, 'Maximum of 25 characers'),
  };

  const validationSchema = object().shape(validationObj);

  const checkErrors = (errors) => {
    Object.keys(errors).map(function (key) {
      if (key !== 'lead') {
        dispatch(setAlert('error', errors[key]));
      } else {
        Object.keys(errors[key]).map(function (el) {
          dispatch(setAlert('error', `${errors[key][el]}`));
        });
      }
    });
  };

  const onPhasteImage = (e, setFieldValue, field, fieldStamp) => {
    const dT = e.clipboardData || window.clipboardData;
    const file = dT.files[0];
    if (file) {
      getBase64(file)
        .then((result) => {
          setFieldValue(field, result);
          setFieldValue(fieldStamp, moment().format('YYYY-MM-DD HH:mm:ss'));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFieldValue(field, '');
      dispatch(setAlert('error', 'No image found'));
    }
  };

  const onUploadImage = (
    e,
    setFieldValue,
    field,
    fieldStamp,
    competitorId = null,
    values
  ) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    const form = new FormData();
    form.append('file', file);
    form.append('field', field);
    form.append('leadId', values.leadId);
    form.append(
      'isCompetitor',
      field === 'competitorAsinRevenueScreenshot' ? true : false
    );

    const hasCompetitor = values.competitors.length > 0;
    form.append('hasCompetitor', hasCompetitor);
    form.append('competitorId', competitorId);
    form.append('competitorBrandName', values.competitorBrandName ?? '');
    form.append(
      'competitorScreenShotURL',
      values.competitorScreenShotURL ?? ''
    );
    form.append('revenue', values.revenue ?? '');

    axios
      .post(`/agency/sales/clients/upload/profile-screenshot`, form)
      .then((response) => {
        // setFieldValue(field, response.data.data.data.url);
        // setFieldValue(
        //   fieldStamp,
        //   moment(response.data.data.data.updatedAt).format(
        //     'YYYY-MM-DD HH:mm:ss'
        //   )
        // );
        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to upload profile picture.'))
      )
      .finally(() => {
        onSubmit(values);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const copyToClipboard = async (link) => {
    navigator.clipboard.writeText(link);
    dispatch(setAlert('success', 'Link copied to clipboard', link));
  };

  const handleBlur = (values, e = false, setFieldValue, field) => {
    let data = cloneDeep(values);
    if (e) {
      // handleChange(e);
      setFieldValue(field, e.currentTarget.value);
      set(data, field, e.currentTarget.value);
    }

    onSubmit(data, false);
  };

  const onSubmit = async (val, reload = true) => {
    let data = val;
    if (client.agencyClientId) {
      data.agencyClientId = client.agencyClientId;
    }
    try {
      if (val.callBookings) {
        val.callBookings.map((item, index) => {
          if (!item.bookingDate) {
            throw new Error(
              `Missing booking date in Call Booking ${index + 1}.`
            );
          }
        });
      }
      if (reload) {
        setLoading(true);
      }
      const response = await axios.put(
        `/agency/sales/clients/${salesClientId}`,
        { ...data, status: clientStatus }
      );
      if (reload) {
        setLoading(false);
      }

      if (response.data.success) {
        if (reload) {
          dispatch(fetchClient(salesClientId));
          dispatch(setAlert('success', 'Successfully Updated'));
          dispatch(getLatestUpdates(salesClientId));

          if (response.data.data.invite) {
            dispatch(setAlert('success', 'Client invited!'));
          }
        }
      }
    } catch (error) {
      dispatch(
        setAlert(
          'error',
          'Saving failed!',
          error.message === 'Request failed with status code 409'
            ? 'Client is already registered - update in client profile'
            : error.message
        )
      );

      setLoading(false);
    }
  };

  const onRemoveScreenshot = async (
    fileId,
    setFieldValue,
    field,
    fieldStamp,
    CompetitorId = null,
    values
  ) => {
    const isCompetitor = field === 'competitorAsinRevenueScreenshot';
    axios
      .delete(`/agency/sales/clients/remove/profile-screenshot`, {
        params: { fileId, field, isCompetitor, CompetitorId },
      })
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to remove profile picture.'))
      )
      .finally(() => {
        onSubmit(values);
      });
  };

  const onDeleteCallBooking = async (callBooking) => {
    try {
      const response = await axios.delete(
        `/agency/sales/callbookings/${callBooking.salesCallBookingId}`
      );
      if (response.data.success) {
        dispatch(setAlert('success', 'Call booking removed'));
        dispatch(getLatestUpdates(salesClientId));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCompetitor = async (values) => {
    axios
      .post(`/agency/sales/competitor/`, {
        leadId: values.leadId,
      })
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to remove profile picture.'))
      )
      .finally(() => {
        onSubmit(values);
      });
  };

  const onDeleteCompetitor = async (obj, values) => {
    axios
      .delete(`/agency/sales/competitor/${obj?.CompetitorId}`)
      .then((response) => {
        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to remove profile picture.'))
      )
      .finally(() => {
        const newCompetitor = values.competitors.filter((c) => {
          if (c.CompetitorId !== obj.CompetitorId) {
            return c;
          }
        });

        let newValues = values;
        newValues.competitors = newCompetitor;
        onSubmit(newValues);
      });
  };

  const handleFilePath = (path) => {
    const newPath =
      process.env.REACT_APP_FILE_URL +
      '/' +
      path.substring(path.indexOf('/files') + 1);
    return newPath;
  };

  return salesClientId === 'add'
    ? ''
    : client && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
                <h4 className="col-span-1 text-2xl font-bold">
                  {salesClientId === 'add'
                    ? 'Create New Profile'
                    : 'Update Profile'}
                </h4>
                <div className="col-span-1">
                  <Disclosure defaultOpen>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                          <span>Contact Details</span>
                          <ChevronUpIcon
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-6 w-6 text-gray-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                          <fieldset disabled={!userCan('sales.deals.edit')}>
                            <div className="sm:grid grid-cols-2 gap-4">
                              <div className="col-span-1">
                                <Label>Lead First Name</Label>
                                <Field
                                  name="lead.lead"
                                  placeholder="First Name"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.lead"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Lead Last Name</Label>
                                <Field
                                  name="lead.leadLastName"
                                  placeholder="Last Name"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.leadLastName"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              <div className="col-span-2">
                                <Label>Company Name</Label>
                                <Field
                                  name="lead.companyName"
                                  placeholder="Last Name"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.companyName"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              <div className="col-span-1">
                                <Label>Phone Number</Label>
                                <Field
                                  name="lead.phoneNumber"
                                  placeholder="Phone Number"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="tel"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Email</Label>
                                <Field
                                  name="lead.email"
                                  placeholder="Email Address"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="email"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.email"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              <div className="col-span-2">
                                <Label>Address</Label>
                                <Field
                                  name="lead.address"
                                  placeholder="Address"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>

                              <div className="col-span-2">
                                <Label>
                                  Website
                                  {values.lead.website && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.website,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.website"
                                  placeholder="Website"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Lead Source</Label>
                                <Field
                                  name="lead.source"
                                  as="select"
                                  className="form-select disabled-white"
                                  disabled={disabled}
                                  onChange={(e) =>
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.source'
                                    )
                                  }
                                >
                                  <option value={values.lead.source}>
                                    {values.lead.source}
                                  </option>
                                  <option value="New Leads">New Leads</option>
                                  <option value="Unprocessed New Leads">
                                    Unprocessed New Leads
                                  </option>
                                  <option value="Old-Leads">Old-Leads</option>
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>Booking Source</Label>
                                <Field
                                  name="lead.bookingSource"
                                  as="select"
                                  className="form-select disabled-white"
                                  disabled={disabled}
                                  onChange={(e) =>
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.bookingSource'
                                    )
                                  }
                                >
                                  <option value={values.lead.bookingSource}>
                                    {values.lead.bookingSource}
                                  </option>
                                  <option value="Ads">Ads</option>
                                  <option value="Amazon Authority">
                                    Amazon Authority
                                  </option>
                                  <option value="DTC Ads">DTC Ads</option>
                                  <option value="Email">Email</option>
                                  <option value="Facebook">Facebook</option>
                                  <option value="Google">Google</option>
                                  <option value="Helpwise">Helpwise</option>
                                  <option value="HeyPixel">HeyPixel</option>
                                  <option value="Inbound Call (Phone Call)">
                                    Inbound Call (Phone Call)
                                  </option>
                                  <option value="Instagram">Instagram</option>
                                  <option value="Lemlist">Lemlist</option>
                                  <option value="LinkedIn">LinkedIn</option>
                                  <option value="Networking Leads (Ecom North)">
                                    Networking Leads (Ecom North)
                                  </option>
                                  <option value="Nurture Campaign">
                                    Nurture Campaign
                                  </option>
                                  <option value="Outbound IG">
                                    Outbound IG
                                  </option>
                                  <option value="Outreachr">Outreachr</option>
                                  <option value="PPC">PPC</option>
                                  <option value="Phone call">Phone call</option>
                                  <option value="Referral">Referral</option>
                                  <option value="SellerBites">
                                    SellerBites
                                  </option>
                                  <option value="Shopify">Shopify</option>
                                  <option value="TikTok">Tiktok</option>
                                  <option value="Typelab">Typelab</option>
                                  <option value="Twitter">Twitter</option>
                                  <option value="Unknown">Unknown</option>
                                  <option value="Walmart">Walmart</option>
                                  <option value="Website">Website</option>
                                  <option value="Winback Campaign">
                                    Winback Campaign
                                  </option>
                                  <option value="Youtube">Youtube</option>
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  Facebook URL
                                  {values.lead.facebook && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.facebook,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.facebook"
                                  placeholder="Facebook URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  Instagram URL
                                  {values.lead.instagram && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.instagram,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.instagram"
                                  placeholder="Instagram URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>

                              <div className="col-span-1">
                                <Label>LinkedIn Account</Label>
                                <Field
                                  name="lead.linkedInAccountId"
                                  as="select"
                                  className="form-select disabled-white"
                                  disabled={
                                    disabled ||
                                    values.lead?.source !== 'LinkedIn'
                                  }
                                  onChange={(e) =>
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.linkedInAccountId'
                                    )
                                  }
                                >
                                  <option value=""></option>
                                  {liAccountsOptions.map((rec) => {
                                    return (
                                      <option
                                        key={rec.linkedInAccountId}
                                        value={rec.linkedInAccountId}
                                      >
                                        {rec.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  LinkedIn Profile URL
                                  {values.lead.linkedInProfileURL && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.linkedInProfileURL,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.linkedInProfileURL"
                                  placeholder="LinkedIn Profile URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={
                                    disabled ||
                                    values.lead?.source !== 'LinkedIn'
                                  }
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  LinkedIn Profile Picture URL
                                  {values.lead.linkedInProfilePictureURL && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.linkedInProfilePictureURL,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.linkedInProfilePictureURL"
                                  placeholder="LinkedIn Profile Picture URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={
                                    disabled ||
                                    values.lead?.source !== 'LinkedIn'
                                  }
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                            </div>
                          </fieldset>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>

                <div className="col-span-1">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700 font-bold border-b">
                          <span>Business Information</span>
                          <ChevronUpIcon
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-6 w-6 text-gray-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                          <fieldset disabled={!userCan('sales.deals.edit')}>
                            <div className="sm:grid grid-cols-2 gap-4">
                              <div className="col-span-1">
                                <Label>Decision Maker</Label>
                                <Field
                                  name="decisionMaker"
                                  placeholder="Decision Maker"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Email Address</Label>
                                <Field
                                  name="email"
                                  placeholder="Email Address"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-2">
                                <Label>Display Name</Label>
                                <Field
                                  name="displayName"
                                  placeholder="Display Name"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="displayName"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              {/* <div className="col-span-2">
                              <Label>Responsible for Account Management</Label>
                              <Field
                                name="accountManager"
                                as="select"
                                className="form-select disabled-white"
                                disabled={disabled}
                              >
                                <option value=""></option>
                                {am.map((el) => {
                                  return (
                                    <option key={el.userId} value={el.userId}>
                                      {el.firstName} {el.lastName}
                                    </option>
                                  );
                                })}
                              </Field>
                            </div> */}

                              <div className="col-span-2">
                                <Label>About Us</Label>
                                <Field
                                  name="aboutUs"
                                  placeholder="About Us"
                                  as="textarea"
                                  rows={3}
                                  className="form-input disabled-white"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-2">
                                <Label>Goals/Pain Points</Label>
                                <Field
                                  name="goals"
                                  placeholder="Goals/Pain Points"
                                  as="textarea"
                                  rows={3}
                                  className="form-input disabled-white"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-2">
                                <Label>First Call Summary</Label>
                                <Field
                                  name="painPoints"
                                  placeholder="First Call Summary"
                                  as="textarea"
                                  rows={3}
                                  className="form-input disabled-white"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-2">
                                <Label>Service Conditions for Ops</Label>
                                <Field
                                  name="mainIssue"
                                  placeholder="Service Conditions for Ops"
                                  as="textarea"
                                  rows={3}
                                  className="form-input disabled-white"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Average Monthly Sales on Amazon</Label>
                                <Field name="avgMonthlySalesOnAmazon">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        prependText="$"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        disabled={disabled}
                                        {...field}
                                        onBlur={() => handleBlur(values)}
                                      />
                                      <sub className="text-gray-400">
                                        Include decimals, no round offs
                                      </sub>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  Average Monthly Sales Outside Amazon
                                </Label>
                                <Field name="avgMonthlySalesOutsideAmazon">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        prependText="$"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        disabled={disabled}
                                        {...field}
                                        onBlur={() => handleBlur(values)}
                                      />
                                      <sub className="text-gray-400">
                                        Include decimals, no round offs
                                      </sub>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>PPC Spend</Label>
                                <Field name="ppcSpend">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        prependText="$"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        disabled={disabled}
                                        {...field}
                                        onBlur={() => handleBlur(values)}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>Avg. ACOS (%)</Label>
                                <Field name="avgACOS">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        disabled={disabled}
                                        {...field}
                                        onBlur={() => handleBlur(values)}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </fieldset>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="col-span-1">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                          <span>Amazon Store And Product Details</span>
                          <ChevronUpIcon
                            className={`${
                              open ? 'rotate-180 transform' : ''
                            } h-6 w-6 text-gray-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                          <fieldset disabled={!userCan('sales.deals.edit')}>
                            <div className="sm:grid grid-cols-2 gap-x-6 gap-y-4">
                              <div className="col-span-1">
                                <Label>Brand Name</Label>
                                <Field
                                  name="lead.brandName"
                                  placeholder="Brand Name"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.brandName"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>
                                  Amazon Store Front URL
                                  {values.lead.amzStoreFBAstoreFront && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.amzStoreFBAstoreFront,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.amzStoreFBAstoreFront"
                                  placeholder="Amazon Store Front URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.amzStoreFBAstoreFront"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Marketplace</Label>
                                <Field
                                  name="lead.marketplace"
                                  as="select"
                                  className="form-select text-sm"
                                  onChange={(e) => {
                                    productCategorySetter(
                                      e.target.value,
                                      variables
                                    );
                                    handleChange(e);
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.marketplace'
                                    );
                                  }}
                                  disabled={disabled}
                                >
                                  <option value=""></option>
                                  {leadMarketPlaces.map((rec, i) => {
                                    return (
                                      <option key={i} value={rec}>
                                        {rec}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="lead.marketplace"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Product Category</Label>
                                <Field
                                  name="lead.productCategory"
                                  as="select"
                                  className="form-select text-sm capitalize"
                                  disabled={disabled}
                                  onChange={(e) =>
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.productCategory'
                                    )
                                  }
                                >
                                  <option
                                    className="capitalize"
                                    value={initialValues.lead.productCategory}
                                  >
                                    {initialValues.lead.productCategory}
                                  </option>
                                  {productCategoryOptions.map((el, i) => {
                                    return (
                                      <option
                                        key={i}
                                        className="capitalize"
                                        value={el}
                                      >
                                        {el}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="lead.productCategory"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Sub category 1</Label>
                                <Field
                                  name="lead.subCategory1"
                                  placeholder="Sub category 1"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Sub category 2</Label>
                                <Field
                                  name="lead.subCategory2"
                                  placeholder="Sub category 2"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>
                              <div className="col-span-2">
                                <Label>ASIN Full Title</Label>
                                <Field
                                  name="lead.asinFullTitle"
                                  placeholder="ASIN Full Title"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.asinFullTitle"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              <div className="col-span-1">
                                <Label>
                                  ASIN URL
                                  {values.lead.leadScreenShotURL && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.leadScreenShotURL,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.leadScreenShotURL"
                                  placeholder="ASIN URL"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.leadScreenShotURL"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>ASIN</Label>
                                <Field
                                  name="lead.asin"
                                  placeholder="ASIN"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.asin"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              <div className="col-span-1">
                                <Label>
                                  ASIN Major Keyword
                                  {values.lead.asinMajorKeyword && (
                                    <LinkIcon
                                      onClick={() =>
                                        window.open(
                                          values.lead.asinMajorKeyword,
                                          '_blank'
                                        )
                                      }
                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                      color="gray"
                                    />
                                  )}
                                </Label>
                                <Field
                                  name="lead.asinMajorKeyword"
                                  placeholder="ASIN Major Keyword"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                              </div>

                              <div className="col-span-1">
                                <Label>Fullfilment</Label>
                                <Field
                                  name="leadType"
                                  as="select"
                                  className="form-select text-sm"
                                  disabled={disabled}
                                  onChange={(e) =>
                                    handleBlur(
                                      values,
                                      e,
                                      setFieldValue,
                                      'lead.leadType'
                                    )
                                  }
                                >
                                  <option value="None">None</option>
                                  <option value="FBA">FBA</option>
                                  <option value="FBM">FBM</option>
                                  <option value="Amazon">Amazon</option>
                                </Field>
                              </div>
                              <div className="col-span-1">
                                <Label>ASIN Price</Label>
                                <Field
                                  name="lead.asinPrice"
                                  placeholder="ASIN Price"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="text"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.asinPrice"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>ASIN Reviews</Label>
                                <Field
                                  name="lead.asinReviews"
                                  placeholder="ASIN Reviews"
                                  className="form-input disabled-white"
                                  onChange={(e) => handleChange(e)}
                                  type="number"
                                  disabled={disabled}
                                  onBlur={() => handleBlur(values)}
                                />
                                <ErrorMessage
                                  name="lead.asinReviews"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>ASIN Revenue</Label>
                                <Field name="lead.currentEarnings">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        prependText="$"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        {...field}
                                        disabled={disabled}
                                        onBlur={() => handleBlur(values)}
                                      />
                                      <sub className="text-gray-400">
                                        Include decimals, no round offs
                                      </sub>
                                    </>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name="lead.currentEarnings"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-1">
                                <Label>Storefront Revenue</Label>
                                <Field name="lead.storeFrontEarnings">
                                  {({
                                    field,
                                    form: { touched, errors },
                                    meta,
                                  }) => (
                                    <>
                                      <InputPrepend
                                        type="number"
                                        placeholder="0"
                                        prependText="$"
                                        border="border-white"
                                        classes="bg-gray-50 disabled-white"
                                        {...field}
                                        disabled={disabled}
                                        onBlur={() => handleBlur(values)}
                                      />
                                      <sub className="text-gray-400">
                                        Include decimals, no round offs
                                      </sub>
                                    </>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name="lead.storeFrontEarnings"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                              <div className="col-span-2 row-span-3">
                                {values.lead?.profileAsinRevenueScreenshot &&
                                !isEmpty(
                                  values.lead?.profileAsinRevenueScreenshot
                                ) ? (
                                  <div className="">
                                    <Label>
                                      ASIN Revenue Screenshot
                                      <XIcon
                                        onClick={() => {
                                          onRemoveScreenshot(
                                            values.lead
                                              .profileAsinRevenueScreenshot.id,
                                            setFieldValue,
                                            'asinRevenueScreenshot',
                                            'asinRevenueScreenshotDateStamp',
                                            null,
                                            values
                                          );
                                        }}
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                        data-tip="Change screenshot"
                                      />
                                      <ExternalLinkIcon
                                        onClick={() => {
                                          let image = new Image();
                                          image.src =
                                            values.lead.profileAsinRevenueScreenshot.url;
                                          window
                                            .open('')
                                            .document.write(image.outerHTML);
                                        }}
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                        data-tip="Open in new tab"
                                      />
                                      <ClipboardIcon
                                        onClick={() =>
                                          copyToClipboard(
                                            values.lead
                                              .profileAsinRevenueScreenshot.url
                                          )
                                        }
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                        data-tip="Copy image"
                                      />
                                      <ReactTooltip
                                        place="bottom"
                                        className="max-w-xs text-black"
                                        backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                        textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                      />
                                    </Label>

                                    <img
                                      id="target"
                                      src={
                                        values.lead
                                          ?.profileAsinRevenueScreenshot.url
                                      }
                                      data-tip={
                                        values.lead
                                          .asinRevenueScreenshotDateStamp
                                      }
                                      className="border-2 border-indigo-600"
                                    />
                                    <ReactTooltip
                                      place="bottom"
                                      className="max-w-xs text-black"
                                      backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                      textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                    />
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="flex flex-row justify-between">
                                      <div className="flex flex-col">
                                        <Label>ASIN Revenue Screenshot</Label>
                                        {userCan('sales.profiles.manage') && (
                                          <input
                                            id="upload"
                                            className="pt-2 focus:outline-none"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) =>
                                              onUploadImage(
                                                e,
                                                setFieldValue,
                                                'asinRevenueScreenshot',
                                                'asinRevenueScreenshotDateStamp',
                                                null,
                                                values
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <ErrorMessage
                                  name="lead.asinRevenueScreenshot"
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>
                            </div>
                          </fieldset>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>

                <div className="col-span-1">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <FieldArray name="competitors">
                          {({ insert, remove, push }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                                <span>
                                  Competitor Comparable &nbsp;
                                  {userCan('sales.profiles.manage') && (
                                    <span
                                      onClick={() => {
                                        createCompetitor(values);
                                      }}
                                      className="ml-2"
                                    >
                                      <PlusCircleIcon className="w-6 h-6 inline text-gray-700 hover:text-green-500 relative -mt-2" />
                                    </span>
                                  )}
                                </span>
                                <ChevronUpIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-6 w-6 text-gray-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="py-4 space-y-4 text-sm text-gray-500">
                                <fieldset
                                  disabled={!userCan('sales.deals.edit')}
                                >
                                  <div>
                                    {values.competitors?.length > 0 &&
                                      values.competitors.map(
                                        (competitors, index) => (
                                          <div
                                            key={index}
                                            className="sm:grid grid-cols-2 gap-4"
                                          >
                                            <div className="col-span-2 flex justify-between">
                                              <span className="font-bold text-base text-gray-700">
                                                Competitor Comparable{' '}
                                                {index + 1}
                                              </span>
                                              {userCan(
                                                'sales.profiles.manage'
                                              ) && (
                                                <span
                                                  onClick={() => {
                                                    remove(index);
                                                    onDeleteCompetitor(
                                                      competitors,
                                                      values
                                                    );
                                                  }}
                                                  className="cursor-pointer hover:text-red-500"
                                                >
                                                  <TrashIcon className="w-5 h-5 inline" />
                                                </span>
                                              )}
                                            </div>
                                            <div className="col-span-2">
                                              <Label>
                                                Competitor Brand Name
                                              </Label>
                                              <Field
                                                name={`competitors.${index}.competitorBrandName`}
                                                placeholder="Competitor Brand Name"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={disabled}
                                              />
                                            </div>
                                            <div className="col-span-2">
                                              <Label>
                                                Competitor ASIN URL
                                                {values.competitors[index]
                                                  ?.competitorScreenShotURL && (
                                                  <LinkIcon
                                                    onClick={() =>
                                                      window.open(
                                                        values.competitors[
                                                          index
                                                        ]
                                                          ?.competitorScreenShotURL,
                                                        '_blank'
                                                      )
                                                    }
                                                    className="cursor-pointer ml-2 inline h-5 w-5"
                                                    color="gray"
                                                  />
                                                )}
                                              </Label>
                                              <Field
                                                name={`competitors.${index}.competitorScreenShotURL`}
                                                placeholder="Competitor ASIN URL"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={disabled}
                                              />
                                            </div>
                                            <div className="col-span-2">
                                              <Label>
                                                Competitor ASIN Revenue
                                              </Label>
                                              <Field
                                                name={`competitors.${index}.revenue`}
                                              >
                                                {({
                                                  field,
                                                  form: { touched, errors },
                                                  meta,
                                                }) => (
                                                  <>
                                                    <InputPrepend
                                                      type="number"
                                                      placeholder="0"
                                                      prependText="$"
                                                      border="border-white"
                                                      classes="bg-gray-50 disabled-white"
                                                      {...field}
                                                      disabled={disabled}
                                                    />
                                                    <sub className="text-gray-400">
                                                      Include decimals, no round
                                                      offs
                                                    </sub>
                                                  </>
                                                )}
                                              </Field>
                                            </div>
                                            <div className="col-span-2 row-span-3">
                                              {values.competitors[index]
                                                ?.competitorAsinRevenueScreenshot ? (
                                                <div className="">
                                                  <Label>
                                                    Competitor ASIN Revenue
                                                    Screenshot
                                                    <XIcon
                                                      onClick={() =>
                                                        onRemoveScreenshot(
                                                          values.competitors[
                                                            index
                                                          ].fileId,
                                                          setFieldValue,
                                                          'competitorAsinRevenueScreenshot',
                                                          'competitorAsinRevenueScreenshotDateStamp',
                                                          values.competitors[
                                                            index
                                                          ].CompetitorId,
                                                          values
                                                        )
                                                      }
                                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                                      color="gray"
                                                      data-tip="Change screenshot"
                                                    />
                                                    <ExternalLinkIcon
                                                      onClick={() => {
                                                        let image = new Image();
                                                        image.src =
                                                          handleFilePath(
                                                            values.competitors[
                                                              index
                                                            ]
                                                              .competitorAsinRevenueScreenshot
                                                          );
                                                        window
                                                          .open('')
                                                          .document.write(
                                                            image.outerHTML
                                                          );
                                                      }}
                                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                                      color="gray"
                                                      data-tip="Open in new tab"
                                                    />
                                                    <ClipboardIcon
                                                      onClick={() =>
                                                        copyToClipboard(
                                                          handleFilePath(
                                                            values.competitors[
                                                              index
                                                            ]
                                                              .competitorAsinRevenueScreenshot
                                                          )
                                                        )
                                                      }
                                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                                      color="gray"
                                                      data-tip="Copy image"
                                                    />
                                                    <ReactTooltip
                                                      place="bottom"
                                                      className="max-w-xs text-black"
                                                      backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                                      textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                                    />
                                                  </Label>

                                                  <img
                                                    id="target"
                                                    src={handleFilePath(
                                                      values.competitors[index]
                                                        .competitorAsinRevenueScreenshot
                                                    )}
                                                    data-tip={
                                                      values.competitors[index]
                                                        .competitorAsinRevenueScreenshotDateStamp
                                                    }
                                                    className="border-2 border-indigo-600"
                                                  />
                                                  <ReactTooltip
                                                    place="bottom"
                                                    className="max-w-xs text-black"
                                                    backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                                    textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                                  />
                                                </div>
                                              ) : (
                                                <div className="">
                                                  <div className="flex flex-row justify-between">
                                                    <div className="flex flex-col">
                                                      <Label>
                                                        Competitor ASIN Revenue
                                                        Screenshot
                                                      </Label>
                                                      {userCan(
                                                        'sales.profiles.manage'
                                                      ) && (
                                                        <input
                                                          id="upload"
                                                          className="pt-2 focus:outline-none"
                                                          type="file"
                                                          accept="image/*"
                                                          onChange={(e) =>
                                                            onUploadImage(
                                                              e,
                                                              setFieldValue,
                                                              `competitorAsinRevenueScreenshot`,
                                                              `competitorAsinRevenueScreenshotDateStamp`,
                                                              values
                                                                .competitors[
                                                                index
                                                              ].CompetitorId ??
                                                                null,
                                                              values
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </fieldset>
                              </Disclosure.Panel>
                            </>
                          )}
                        </FieldArray>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="col-span-1">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <FieldArray name="callBookings">
                          {({ insert, remove, push }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                                <span>
                                  Call Bookings&nbsp;
                                  {userCan('sales.profiles.manage') && (
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (values.callBookings?.length === 4) {
                                          alert(
                                            'You can only add up to 4 booked calls.'
                                          );
                                        } else {
                                          push(callBookingObj);
                                        }
                                      }}
                                      className="ml-2"
                                    >
                                      <PlusCircleIcon className="w-6 h-6 inline text-gray-700 hover:text-green-500 relative -mt-2" />
                                    </span>
                                  )}
                                </span>
                                <ChevronUpIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-6 w-6 text-gray-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="py-4 space-y-4 text-sm text-gray-500">
                                <fieldset
                                  disabled={!userCan('sales.deals.edit')}
                                >
                                  <div>
                                    {values.callBookings?.length > 0 &&
                                      values.callBookings.map(
                                        (callBooking, index) => (
                                          <div
                                            key={index}
                                            className="sm:grid grid-cols-2 gap-4"
                                          >
                                            <div className="col-span-2 flex justify-between">
                                              <span className="font-bold text-base text-gray-700">
                                                Booked Call {index + 1}
                                              </span>
                                              {userCan(
                                                'sales.profiles.manage'
                                              ) && (
                                                <span
                                                  onClick={() => {
                                                    remove(index);
                                                    onDeleteCallBooking(
                                                      callBooking
                                                    );
                                                  }}
                                                  className="cursor-pointer hover:text-red-500"
                                                >
                                                  <TrashIcon className="w-5 h-5 inline" />
                                                </span>
                                              )}
                                            </div>
                                            <div className="col-span-1">
                                              <Label>Call Booking Date</Label>
                                              <Field
                                                name={`callBookings.${index}.bookingDate`}
                                                placeholder="Call Booking Date"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="date"
                                                disabled={disabled}
                                              />
                                            </div>
                                            <div className="col-span-1">
                                              <Label>
                                                Call Recording Link
                                                {values.callBookings[index]
                                                  ?.recordingLink && (
                                                  <LinkIcon
                                                    onClick={() =>
                                                      window.open(
                                                        values.callBookings[
                                                          index
                                                        ]?.recordingLink,
                                                        '_blank'
                                                      )
                                                    }
                                                    className="cursor-pointer ml-2 inline h-5 w-5"
                                                    color="gray"
                                                  />
                                                )}
                                              </Label>
                                              <Field
                                                name={`callBookings.${index}.recordingLink`}
                                                placeholder="Call Recording Link"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={disabled}
                                              />
                                            </div>
                                            <div className="col-span-2">
                                              <div>
                                                <Label>
                                                  Video Summary Link
                                                  {values.callBookings[index]
                                                    ?.videoSummaryLink && (
                                                    <LinkIcon
                                                      onClick={() =>
                                                        window.open(
                                                          values.callBookings[
                                                            index
                                                          ]?.videoSummaryLink,
                                                          '_blank'
                                                        )
                                                      }
                                                      className="cursor-pointer ml-2 inline h-5 w-5"
                                                      color="gray"
                                                    />
                                                  )}
                                                </Label>
                                                <Field
                                                  name={`callBookings.${index}.videoSummaryLink`}
                                                  placeholder="Video Summary Link"
                                                  className="form-input disabled-white"
                                                  onChange={(e) =>
                                                    handleChange(e)
                                                  }
                                                  type="text"
                                                  disabled={disabled}
                                                />
                                              </div>
                                              <div className="col-span-2">
                                                <Label>
                                                  Call Booking Notes
                                                </Label>
                                                <Field
                                                  name={`callBookings.${index}.notes`}
                                                  placeholder="Booking Notes"
                                                  as="textarea"
                                                  rows={2}
                                                  className="form-select text-sm disabled-white"
                                                  disabled={disabled}
                                                />
                                              </div>
                                              <div className="col-span-2">
                                                <Label>Service Condition</Label>
                                                <Field
                                                  name={`callBookings.${index}.serviceCondition`}
                                                  placeholder="Service Condition"
                                                  className="form-select text-sm disabled-white"
                                                  disabled={disabled}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>

                                  {values.callBookings?.length > 0 ? (
                                    <div>
                                      <CallBookingQuotes />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </fieldset>
                              </Disclosure.Panel>
                            </>
                          )}
                        </FieldArray>
                      </>
                    )}
                  </Disclosure>
                </div>

                {userCan('sales.profiles.manage') && (
                  <div className="col-span-1">
                    <Button
                      type="submit"
                      onClick={() => checkErrors(errors)}
                      classes="rounded-3xl text-center"
                      disabled={!userCan('sales.deals.edit')}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      );
};

export default Profile;
