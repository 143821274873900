import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router';
import { mapValues } from 'lodash';
import moment from 'moment';

import usePermissions from 'hooks/usePermissions';
import LeadForm from './components/LeadForm';
import { Card } from 'components';
import Button from 'components/Button';
import { setAlert } from 'features/alerts/alertsSlice';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { PRE_PITCH_STATUSES } from './utils/constants';
import { selectAuthenticatedUser } from 'features/auth/authSlice';

const Overview = ({ tabs }) => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  // const [variables, setVariables] = useState({});
  // const [liAccounts, setLiAccounts] = useState({});
  const additionalContactsObj = {
    lead: '',
    leadLastName: '',
    phoneNumber: '',
    email: '',
    linkedInUrl: '',
  };
  const competitorObj = {
    competitorBrandName: '',
    competitorScreenShotURL: '',
    revenue: '',
  };
  const [selectedLeads, setSelectedLeads] = useState({
    lead: '',
    title: '',
    companyName: '',
    companyLI: '',
    website: '',
    country: '',
    amzStoreFBAstoreFront: '',
    leadScreenShotURL: '',
    // competitorScreenShotURL: '',
    linkedInProfileURL: '',
    linkedInProfilePictureURL: '',
    leadPhotoURL: '',
    remarks: '',
    leadType: 'None',
    leadQuality: 'None',
    status: 'For Approval',
    leadSource: 'LinkedIn',
    dateBooked: '',
    dateOfCall: '',
    dateTimeOfResponse: '',
    dateTimeWeResponded: '',
    pitchDate: '',
    pitchedDate: '',
    lemlistDate: '',
    lemlistResponseType: '',
    lemlistResponseDate: '',
    lemlistReplySeq: '',
    typeOfResponse: 'None',
    messageOverview: '',
    currentEarnings: '',
    // revenue: '',
    competitorSalesUnits: '',
    // competitorBrandName: '',

    source: '',
    bookingSource: '',
    amazonProduct: '',
    majorKeywordSearchPage: '',
    competitorsProduct: '',
    competitorsProduct2: '',
    competitorsWebsite: '',
    spokeTo: '',
    address: '',
    personsResponsible: '',
    mainObjectivePainPoints: '',
    otherSalesChannels: '',
    ppcSpend: '',
    avgACOS: '',
    quote: '',
    firstCallSummary: '',
    serviceConditionsForOP: '',
    email: '',
    mockListing: '',
    ownersFullName: '',
    phoneNumber: '',
    aboutUs: '',
    qualifiedFromLIAccount: '',
    qualifiedBy: '',
    callRecording: '',
    productCategory: '',
    paymentStatus: '',
    paymentType: '',
    plan: '',
    stage: '',
    averageMonthlyAmazonSales: '',
    averageMonthlyOutsideAmazonSales: '',
    mainIssueWithAmazon: '',
    marketplace: '',
    rejectionReasons: '',
    storeFrontEarnings: '',
    processedByUser: {},
    requestedByUser: {},
    liAccountUsed: {},
    salesRep: '',
    linkedInAccountId: '',
    callAppointmentDate1: '',
    callAppointmentDate2: '',
    callAppointmentDate3: '',
    otherEmails: '',
    callRecording2: '',
    companyAverageMonthlyRevenue: '',
    linkedinContact: '',
    decisionMakersEmail: '',
    instagram: '',
    facebook: '',
    subCategory1: '',
    subCategory2: '',
    channelPartnerType: '',
    asinMajorKeyword: '',
    asinFullTitle: '',
    noOfAsin: '',
    asinRevenueScreenshot: '',
    competitorAsinRevenueScreenshot: '',
    profileAsinRevenueScreenshot: {},
    asinRevenueScreenshotDateStamp: '',
    competitorAsinRevenueScreenshotDateStamp: '',
    brandName: '',
    asinPrice: '',
    asinReviews: '',
    revisionText: '',
    leadLastName: '',
    asin: '',
    secondaryLeadFirstName: '',
    secondaryLeadLastName: '',
    secondaryPhoneNumber: '',
    secondaryEmailAddress: '',
    processedBy: '',
    approvedDate: '',
    revisionDate: '',
    rejectedDate: '',
    dateOfCallScreenshot: '',
    responseDateCallScreenshot: '',
    isFromOldLeads: false,
    prevStatus: '',
    isInSales: false,
    pitcher: '',
    salesRep: '',
    leadsRep: '',
    addedBy: null,
    hubspotDealId: null,
    hubspotDealStageId: null,
    additionalContacts: [additionalContactsObj],
    competitors: [competitorObj],
  });

  const [isLoading, setIsLoading] = useState(false);

  const me = useSelector(selectAuthenticatedUser);

  const [status, setStatus] = useState('For Approval');

  const [title, setTitle] = useState('Create Leads');

  const [showAssignedButton, setShowAssignedButton] = useState(false);

  const dispatch = useDispatch();

  const { userCan } = usePermissions();

  const left = (
    <Button
      classes="border font-bold tracking-widest"
      bgColor="blue-100"
      hoverColor="blue-200"
      roundedSize="3xl"
      textColor="gray-700"
      px={5}
      py={2}
      shadow=""
    >
      {status}
    </Button>
  );

  useEffect(async () => {
    if (id !== 'create') {
      await axios.get(`/agency/leads/${id}`).then((res) => {
        let newValues = { ...res.data.data };
        newValues.competitors = [...newValues.competitor];

        Object.keys(newValues).map(function (key, index) {
          if (!(key in selectedLeads)) delete newValues[key];
        });

        const valuesNoNull = mapValues(newValues, (val) =>
          val === null ? '' : val
        );

        if (valuesNoNull.productCategory) {
          valuesNoNull.productCategory =
            valuesNoNull.productCategory.toLowerCase();
        }

        if (valuesNoNull.pitchDate)
          valuesNoNull.pitchDate = moment(valuesNoNull.pitchDate)
            .utc()
            .format('DD MMM YYYY HH:mm');

        if (valuesNoNull.dateBooked)
          valuesNoNull.dateBooked = moment(valuesNoNull.dateBooked)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.dateOfCall)
          valuesNoNull.dateOfCall = moment(valuesNoNull.dateOfCall)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.dateTimeOfResponse)
          valuesNoNull.dateTimeOfResponse = moment(
            valuesNoNull.dateTimeOfResponse
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.dateTimeWeResponded)
          valuesNoNull.dateTimeWeResponded = moment(
            valuesNoNull.dateTimeWeResponded
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.pitchedDate)
          valuesNoNull.pitchedDate = moment(valuesNoNull.pitchedDate)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.callAppointmentDate1)
          valuesNoNull.callAppointmentDate1 = moment(
            valuesNoNull.callAppointmentDate1
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.callAppointmentDate2)
          valuesNoNull.callAppointmentDate2 = moment(
            valuesNoNull.callAppointmentDate2
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.callAppointmentDate3)
          valuesNoNull.callAppointmentDate3 = moment(
            valuesNoNull.callAppointmentDate3
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.asinRevenueScreenshotDateStamp)
          valuesNoNull.asinRevenueScreenshotDateStamp = moment(
            valuesNoNull.asinRevenueScreenshotDateStamp
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.competitorAsinRevenueScreenshotDateStamp)
          valuesNoNull.competitorAsinRevenueScreenshotDateStamp = moment(
            valuesNoNull.competitorAsinRevenueScreenshotDateStamp
          )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.approvedDate)
          valuesNoNull.approvedDate = moment(valuesNoNull.approvedDate)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.revisionDate)
          valuesNoNull.revisionDate = moment(valuesNoNull.revisionDate)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        if (valuesNoNull.rejectedDate)
          valuesNoNull.rejectedDate = moment(valuesNoNull.rejectedDate)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');

        if (valuesNoNull.additionalContacts.length === 0) {
          valuesNoNull.additionalContacts = [additionalContactsObj];
        }

        setTitle(`${valuesNoNull.lead} / ${valuesNoNull.companyName}`);
        setStatus(valuesNoNull.status);

        // delete valuesNoNull.competitorAsinRevenueScreenshot;
        delete valuesNoNull.competitorBrandName;
        delete valuesNoNull.revenue;
        delete valuesNoNull.competitorAsinRevenueScreenshotDateStamp;
        delete valuesNoNull.competitorScreenShotURL;
        let initialValues = {
          competitorScreenShotURL:
            valuesNoNull.competitors[0]?.competitorScreenShotURL,
          competitorBrandName: valuesNoNull.competitors[0]?.competitorBrandName,
          revenue: valuesNoNull.competitors[0]?.revenue,
          competitorAsinRevenueScreenshotDateStamp:
            valuesNoNull.competitors[0]
              ?.competitorAsinRevenueScreenshotDateStamp,
          competitorAsinRevenueScreenshot:
            valuesNoNull.competitors[0]?.competitorAsinRevenueScreenshot,
          ...valuesNoNull,
        };

        setSelectedLeads(initialValues);

        if (
          ((valuesNoNull.companyName !== '' ||
            valuesNoNull.brandName !== '' ||
            valuesNoNull.lead !== '') &&
            !valuesNoNull.leadsRep &&
            PRE_PITCH_STATUSES.includes(valuesNoNull.status)) ||
          (!valuesNoNull.pitcher &&
            ['Ready to Pitch'].includes(valuesNoNull.status) &&
            userCan('leads.pitcher'))
        ) {
          setShowAssignedButton(true);
        } else {
          setShowAssignedButton(false);
        }
      });
    }
  }, [id, refresh]);

  const onTabChange = (tab) => {};

  const onMarkedAsDuplicate = async () => {
    let data = {
      status: 'Duplicate',
    };

    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        // setShowAssignedButton(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onMarkedAsNotDuplicate = async (e) => {
    e.preventDefault();
    let data = {
      status: selectedLeads?.prevStatus,
    };
    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(
          setAlert('success', `Lead Profile Returned to Status: ${data.status}`)
        );
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onAssignToMe = async (e) => {
    e.preventDefault();
    let data =
      status === 'Ready to Pitch'
        ? {
            pitcher: me.userId,
            leadsRep: selectedLeads.leadsRep
              ? selectedLeads.leadsRep
              : me.userId,
          }
        : {
            leadsRep: me.userId,
            pitchDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          };

    if (status !== 'Ready to Pitch') {
      const res = await axios.get(`/agency/leads/${id}`);
      if (res.data.data.leadsRep) {
        dispatch(
          setAlert(
            'info',
            `In-progress`,
            `Leads Rep: ${res.data.data.addedBy?.firstName} ${res.data.data.addedBy?.lastName}`
          )
        );
        return false;
      }
    }

    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        setShowAssignedButton(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onUnAssign = async (e) => {
    e.preventDefault();
    let data =
      status === 'Ready to Pitch' && selectedLeads.pitcher
        ? { pitcher: null }
        : {
            podId: null,
            leadsRep: null,
            pitchDate: null,
          };

    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        setShowAssignedButton(true);
        setRefresh(!refresh);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  return (
    <>
      <div className="xl:flex justify-between">
        <h1 className="text-3xl tracking-wide font-bold pt-2">
          {title} {left}
        </h1>

        <div className="flex justify-between">
          {!isLoading && (
            <Button
              onClick={(e) => onAssignToMe(e)}
              classes="mt-2 text-center bg-transparent"
              color="transparent"
              type="text"
              textColor={
                !showAssignedButton || !userCan('leads.profile.assign')
                  ? 'gray-500'
                  : 'blue-500'
              }
              fontWeight="bold"
              disabled={!showAssignedButton || !userCan('leads.profile.assign')}
            >
              Assign to me
            </Button>
          )}
          {!isLoading &&
            ['Ready to Pitch', ...PRE_PITCH_STATUSES].includes(status) &&
            id !== 'create' && (
              <Button
                onClick={(e) => onUnAssign(e)}
                classes="mt-2 text-center bg-transparent"
                color="transparent"
                type="text"
                textColor={
                  !selectedLeads.leadsRep || !userCan('leads.profile.unassign')
                    ? 'gray-500'
                    : 'blue-500'
                }
                fontWeight="bold"
                disabled={
                  !selectedLeads.leadsRep || !userCan('leads.profile.unassign')
                }
              >
                Unassign
              </Button>
            )}
        </div>

        {selectedLeads?.status === 'Potential-duplicate' &&
          userCan('leads.mark.duplicate') && (
            <Button
              onClick={(e) => onMarkedAsNotDuplicate(e)}
              classes="mt-2 text-center bg-transparent"
              color="transparent"
              type="text"
              textColor="blue-500"
              fontWeight="bold"
            >
              Marked as not duplicate
            </Button>
          )}
      </div>

      <div className="grid grid-cols-4 gap-4 pt-4">
        <div className="col-span-4">
          <Card>
            <LeadForm
              action={id === 'create' ? 'add' : 'update'}
              selectedLeads={selectedLeads}
              initialValues={selectedLeads}
              status={status}
              refresh={refresh}
              setRefresh={setRefresh}
              setShowAssignedButton={setShowAssignedButton}
              showAssignedButton={showAssignedButton}
              additionalContactsObj={additionalContactsObj}
              competitorObj={competitorObj}
            />
          </Card>
        </div>

        {/* <div className="col-span-4">
          <LeadNoteForm
            selectedLeads={selectedLeads}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div> */}
      </div>
    </>
  );
};

export default Overview;
