import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from 'features/alerts/alertsSlice';
import { setAgencyClient } from '../../agencyClientSlice';
import Button from 'components/Button';

const SlackChannelButton = () => {
  const dispatch = useDispatch();
  const { agencyClient } = useSelector((state) => state.agencyClient);

  const [submitting, setSubmitting] = useState(false);

  const onClick = async () => {
    try {
      setSubmitting(true);

      const response = await axios.post(
        `agency/clients/${agencyClient.agencyClientId}/slack-channel`
      );

      dispatch(
        setAgencyClient({
          ...agencyClient,
          slackChannelId: response.data.data.channelId,
        })
      );

      dispatch(setAlert('success', 'Slack Channel Created'));
    } catch (error) {
      dispatch(
        setAlert(
          'error',
          'Failed to create slack channel',
          error.response.data.message
        )
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Button
      onClick={onClick}
      loading={submitting}
      showLoading={true}
      classes="bg-blue-800 hover:bg-blue-900 rounded-2xl font-semibold"
    >
      Create Slack Channel
    </Button>
  );
};

export default SlackChannelButton;
