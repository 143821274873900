import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const ServiceAgreement = ({ proposalContent }) => {
  return (
    <div
      className="relative flex flex-col p-6 lg:pb-28 lg:px-10 section"
      id="serviceAgreement"
    >
      <div className="z-10  space-y-10">
        <h2 className="text-4xl font-bold text-si-pink">
          Service of Agreement
        </h2>
        <br />

        {proposalContent
          .filter((el) => el.isShow)
          .map((el, i) => {
            return (
              <section key={i} id={`section${i + 1}`} className="space-y-8">
                <h3 className="text-xl font-bold text-black">{el.label}</h3>
                <ReactQuill value={el.value} readOnly={true} theme={'bubble'} />
              </section>
            );
          })}

        {/* <section id="section1" className="space-y-8">
          <h3 className="text-xl font-bold text-black">Scope of Work</h3>
          <p>1.1 SERVICES</p>
          <p>
            The Agency will provide the Company with full Account Management for
            the Company's products being sold on the Amazon.com platform (the
            “Services”). This includes:
          </p>
          <div>
            <span>
              <span className="text-lg font-bold">Customer Service: </span>All
              Amazon-related customer service tasks are addressed (respond to
              all concerns) within 1 business day
            </span>
            <ul className="list-disc list-outside pl-5">
              <li>
                Proactive customer service reps dedicated to responding within
                24 hours to any concerns
              </li>
              <li>Following up on customer feedback once every 12 hrs.</li>
              <li>
                Automated solutions to monitor and respond to customer feedback
                at scale (managed by account health specialist-automated email
                campaign strategies to encourage positive product reviews,
                customer support and build community engagement).
              </li>
            </ul>
          </div>
          <div>
            <span className="text-lg font-bold">Review Management: </span>
            <span>
              A post-purchase follow-up sequence will be created, which
              immediately reaches out to recent buyers to maximize the number of
              positive reviews. This also allows us to connect with customers
              that may be unhappy with their products for whatever reason and
              address the issue BEFORE they can leave a negative review.
            </span>
          </div>
          <div>
            <span>
              <span className="text-lg font-bold">Community Management: </span>
              This includes monitoring and encouraging past customers to answer
              prospects' questions with regards to your product, to help provide
              social proof to your listing.
            </span>
            <ul className="list-disc list-outside pl-5">
              <li>Monitor and encourage engagement</li>
              <li>Answer product questions</li>
              <li>
                Encourage past customers to answer prospects questions in regard
                to your products
              </li>
            </ul>
          </div>
          <div>
            <span>
              <span className="text-lg font-bold">PPC Ads: </span>
              Set-up, optimize, and monitor your pay-per-click ad campaigns to
              maximize sales within the Company's PPC budget. Manage and
              optimize PPC ad campaigns on a weekly basis making continuous
              improvements to the campaigns for optimum results. Please note
              that the money used for PPC campaigns is provided by the Company.
            </span>
            <ul className="list-disc list-outside pl-5">
              <li>
                Full audit on any existing campaigns to provide quick
                restructuring and immediate optimizations in month one
              </li>
              <li>
                Unique undiscovered keyword identifiers using Seller
                Interactive’s proprietary software
              </li>
              <li>
                In-depth competitor analysis to determine missing keywords and
                allowing for quick predatory targeting to immediately steal
                sales from competitors.
              </li>
              <li>
                Custom strategies for brand protection & increased brand
                presence
              </li>
              <li>
                Bi-weekly reporting showing the breakdown of activities, target
                ROAS objectives, and future optimization plans.
              </li>
            </ul>
          </div>
          <div>
            <span>
              <span className="text-lg font-bold">
                Over-All Account Health:{' '}
              </span>
              Minimize order defect rate, maintain high customer service
              response time, and overall Amazon policy compliance. The agency
              will make all reasonable efforts to address counterfeiters and
              hijackers on your product listings.{' '}
            </span>
            <ul className="list-disc list-outside pl-5">
              <li>
                Seller central, opening cases, delisted products, maximizing
                amazon support and response, suspended accounts/products.
              </li>
              <li>Assisting in reconciling statements and missing payments.</li>
              <li>Unlocking product categories </li>
              <li>
                Resolving listing suppressions due to pesticide or hazmat
                restrictions
              </li>
              <li>
                Investigating FBA inventory-related issues like lost goods,
                labelling, storage limits, FBA fees (*reimbursement recovery
                fees apply, see section 2.4)
              </li>
              <li>
                Inventory management with prescribed restock thresholds, lead
                time and inventory storage cost considerations
              </li>
            </ul>
          </div>
          <div>
            <span>
              <span className="text-lg font-bold">
                Ongoing Listing Optimization Maintenence:{' '}
              </span>
            </span>
            <ul className="list-disc list-outside pl-5">
              <li>
                Ongoing optimization of keywords based on changes in Amazon's
                algorithm and PPC campaigns.
              </li>
              <li>
                Monitoring conversion rates and making changes to the copy to
                increase the conversion rate.
              </li>
            </ul>
          </div>
          <p>1.2 AMAZON ACCOUNT CONTROL</p>
          <p>
            The Agency will be given sub-user access to the Company's Amazon
            Seller's Account and give full access to complete the work stated
            above. (the Services as listed in 1.1) All sales and account
            activity will be readily transparent to both parties.
          </p>
          <div className="text-lg font-bold my-3">
            ***Note: Amazon UK and EU Requires clients to provide either :
          </div>
          <ol className="list-decimal list-outside pl-5">
            <li className="text-lg font-bold my-3">Main account access OR</li>
            <li className="text-lg font-bold my-3">
              ID/passport from their staffs/friends/family for us to register
              for sub-user access (doesn't have to reside in the UK or
              EU)otherwise we CANNOT obtain sub-user access to manage their
              account.
            </li>
          </ol>
        </section> */}
      </div>
    </div>
  );
};
