import { useSelector } from 'react-redux';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { linkStyles } from 'utils/tabStyles';
import useBodyClass from 'hooks/useBodyClass';

import MarketplaceContainer from '../components/MarketplaceContainer';
import MarketplacePicker from '../components/MarketplacePicker';

import classNames from 'utils/classNames';
import Overview from './Overview/Overview';
import ProductSales from './ProductSales/ProductSales';
import ProductPerformance from './ProductPerformance/ProductPerformance';
import CogsManager from './CogsManager/CogsManager';

const Profits = () => {
  useBodyClass(['client-profile', 'no-sidebar']);

  const { url, path } = useRouteMatch();
  const { account, marketplace } = useSelector((state) => state.account);

  const tabs = [
    { name: 'Overview', exact: true, url: url },
    { name: 'Product Sales', exact: false, url: `${url}/product-sales` },
    {
      name: 'Product Performance',
      exact: false,
      url: `${url}/product-performance`,
    },
    { name: 'COGS Manager', exact: false, url: `${url}/cogs-manager` },
  ];

  return (
    <MarketplaceContainer>
      <div className="flex justify-between items-center">
        <div className="tablinks">
          {tabs.map((tab, i) => (
            <NavLink
              key={tab.name}
              to={tab.url}
              exact={tab.exact}
              className={classNames(linkStyles, 'px-3')}
              activeClassName="current border-secondary-light text-secondary-light font-bold hover:border-secondary-light hover:text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </div>

        <MarketplacePicker />
      </div>

      <div className="mt-5">
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Overview account={account} marketplace={marketplace} />
            )}
          />

          <Route
            exact
            path={`${path}/product-sales`}
            render={() => (
              <ProductSales account={account} marketplace={marketplace} />
            )}
          />

          <Route
            path={`${path}/product-performance`}
            render={() => (
              <ProductPerformance account={account} marketplace={marketplace} />
            )}
          />

          <Route
            path={`${path}/cogs-manager`}
            render={() => (
              <CogsManager account={account} marketplace={marketplace} />
            )}
          />
        </Switch>
      </div>
    </MarketplaceContainer>
  );
};

export default Profits;
