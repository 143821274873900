import { startCase } from 'lodash';
import { XIcon } from '@heroicons/react/outline';

import { COMPARISONS, BETWEEN } from 'features/advertising/utils/constants';

const FilterConditionItem = ({ item, updateItem, hasError = false }) => {
  const onChangeComparison = (e) => {
    const comparison = e.target.value;
    const value = comparison === BETWEEN ? ['', ''] : item.value;
    updateItem({ ...item, comparison, value });
  };

  const onChangeBetweenValue = (e) => {
    const value = [...item.value];
    let newValue = e.target.value === '' ? '' : parseFloat(e.target.value);
    value[e.target.name === 'min' ? 0 : 1] = newValue;
    updateItem({ ...item, value });
  };

  return (
    <div className="bg-white px-2 pt-2 pb-4 bs-shadow">
      <div className="flex items-center justify-between">
        <p className="ml-2 text-center text-tiny font-bold text-grayscale-700 font-medium tracking-3/4">
          {item.title}
        </p>

        <XIcon
          data-tip="Remove filter"
          className="w-3 h-3 cursor-pointer text-grayscale-700 hover:text-grayscale-900"
          onClick={() => {
            updateItem({ ...item, selected: false });
          }}
        />
      </div>
      <div className="flex rounded-3xl">
        <select
          value={item.comparison}
          onChange={onChangeComparison}
          className="rounded-l-3xl border-grayscale-500 border-r-0 focus:border-grayscale-500 pl-3 pr-8 py-2 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-10 font-inter tracking-tighter"
        >
          {COMPARISONS.filter((comparison) =>
            item.comparisons ? item.comparisons.includes(comparison) : true
          ).map((comparison) => {
            return (
              <option
                key={`filter-condition-item${item.key}-${comparison}`}
                value={comparison}
              >
                {startCase(comparison)}
              </option>
            );
          })}
        </select>

        {item.comparison === BETWEEN ? (
          <div className="flex w-full">
            <input
              type="number"
              name="min"
              value={item.value[0]}
              onChange={onChangeBetweenValue}
              className="block w-full rounded-none border-grayscale-500 border-r-0 focus:border-grayscale-500 px-3 py-2 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-tiny font-inter"
            />
            <input
              type="number"
              name="max"
              value={item.value[1]}
              onChange={onChangeBetweenValue}
              className="block w-full rounded-none rounded-r-3xl border-grayscale-500 focus:border-grayscale-500 px-3 py-2 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-tiny font-inter"
            />
          </div>
        ) : (
          <input
            type={item.value === 'cpc' ? 'text' : 'number'}
            disabled={item.value === 'cpc'}
            value={item.value}
            onChange={(e) => updateItem({ ...item, value: e.target.value })}
            className="block w-full rounded-none rounded-r-3xl border-grayscale-500 focus:border-grayscale-500 px-3 py-2 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-tiny font-inter"
          />
        )}
      </div>
      {hasError && (
        <p className="text-right mr-4 text-10 font-medium tracking-3/4 font-inter text-error-dark">
          Invalid value
        </p>
      )}
    </div>
  );
};

export default FilterConditionItem;
