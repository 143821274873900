import { setAlert } from 'features/alerts/alertsSlice';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { alertErrorsStringify } from 'utils/formatters';

const FormikErrorNotification = () => {
  const dispatch = useDispatch();
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      const errorMessages = alertErrorsStringify(errors);
      dispatch(setAlert('error', 'Errors found', errorMessages, 5000));
    }
  }, [isSubmitting, isValid, isValidating]);

  return null;
};

export default FormikErrorNotification;
