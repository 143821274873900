import moment from 'moment-timezone';

import DropdownMenu from 'components/DropdownMenu';
import React, { useEffect, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import DateTimePicker from 'components/DateTimePicker';

const TaskDueDateDropdown = ({
  value,
  setValue,
  iconClassname = 'w-6 h-6',
  titleClasses = '',
  position = '',
  configurable = true,
  editable = true,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const onChangeDueDate = (date) => {
    if (!date || date?.toLocaleDateString() === 'Invalid Date') {
      setSelectedDate(null);
      setValue(null);
    } else {
      setSelectedDate(
        moment(date).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      );
      setValue(
        moment(date).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      );
    }
  };

  return (!!value && !editable) || (!value && !configurable) ? (
    <span className={titleClasses} title="click to update due date">
      {selectedDate ? (
        moment(selectedDate)
          .tz(moment.tz.guess())
          .format('MMMM DD, YYYY hh:mm a')
      ) : (
        <CalendarIcon className={iconClassname} />
      )}
    </span>
  ) : (
    <DropdownMenu
      title={
        <span className={titleClasses} title="click to update due date">
          {selectedDate ? (
            moment(selectedDate)
              .tz(moment.tz.guess())
              .format('MMMM DD, YYYY hh:mm a')
          ) : (
            <CalendarIcon className={iconClassname} />
          )}
        </span>
      }
      titleClasses="flex items-center"
      buttonBg="bg-transparent"
      buttonFontWeight="font-normal"
      buttonFocus=""
      buttonClasses=""
      hoverClasses=""
      textColor="text-gray-600"
      classes="text-sm"
      buttonRounded=""
      hoverText="hover:text-red-500"
      dropdownWidth="w-88"
      padding=""
      position={position}
      zIndex="z-10"
      hideArrow
    >
      <DateTimePicker
        value={value}
        onChange={onChangeDueDate}
        minDate={moment().tz(moment.tz.guess()).startOf('d').toDate()}
      />
    </DropdownMenu>
  );
};

export default TaskDueDateDropdown;
