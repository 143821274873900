import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, array } from 'yup';
import axios from 'axios';
import FormikErrorNotification from 'components/FormikErrorNotification';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import Button from 'components/Button';
import { PlusIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { currencyFormatter } from 'utils/formatters';
import { isEmpty } from 'lodash';
import ButtonLink from 'components/ButtonLink';
import useAlert from 'hooks/useAlert';

const AddonModal = ({ subscription, open, setOpen }) => {
  const [saving, setSaving] = useState(false);
  const { alertError, alertSuccess } = useAlert();
  const { addons } = useSelector((state) => state.zoho);

  const addonObj = {
    name: '',
    addon_description: '',
    addon_code: '',
    price: 0,
    quantity: 1,
    type: 'one_time',
  };

  const initialValues = { addons: [addonObj] };

  const onAddonChange = (index, value, setFieldValue) => {
    const addon = addons.find((a) => a.addon_code === value);
    if (addon) {
      setFieldValue(`addons.${index}.addon_code`, value);
      setFieldValue(`addons.${index}.type`, addon.type);
      setFieldValue(`addons.${index}.name`, addon.name);
      setFieldValue(`addons.${index}.addon_description`, addon.description);
      setFieldValue(`addons.${index}.price`, addon.price_brackets[0].price);
    } else {
      setFieldValue(`addons.${index}.addon_code`, '');
      setFieldValue(`addons.${index}.type`, 'one_time');
      setFieldValue(`addons.${index}.name`, '');
      setFieldValue(`addons.${index}.addon_description`, '');
      setFieldValue(`addons.${index}.price`, 0);
    }
  };

  const descriptionCheck = (string) => /[<>]/.test(string);
  const validationSchema = object().shape({
    addons: array()
      .of(
        object().shape({
          addon_code: string().required('addon code is required'),
          name: string(),
          addon_description: string()
            .test(
              'Addon Description must not have any (<) less than or (>) greater than symbol',
              'Addon Description must not have any (<) less than or (>) greater than symbol',
              (value) => !descriptionCheck(value)
            )
            .nullable(),
          type: string().required(),
          quantity: number()
            .min(1, 'qty must be greater than or equal to 1')
            .required('required'),
          price: number().min(0).required('required'),
        })
      )
      .nullable(),
  });

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      const res = await axios.post(
        `subscriptions/${subscription.subscription_id}/buyonetimeaddon`,
        { addons: values.addons }
      );

      if (res.data.data.code === 0) {
        alertSuccess('Charge successful', res.data.data.message);
        setOpen(false);
      } else {
        alertError('Failed to charge add-on', res.data.data.message);
      }
    } catch (error) {
      //console.log(error.response.data);
      alertError('Cannot charge add-on', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Charge One-Time Addon"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ errors, setFieldValue, submitForm, values }) => (
            <div className="px-4 md:px-8">
              <Form>
                <FormikErrorNotification />
                <FieldArray name="addons">
                  {({ insert, remove, push }) => (
                    <>
                      {values.addons.length > 0 &&
                        values.addons.map((addon, index) => (
                          <div
                            key={`addon-${index}`}
                            className="grid grid-cols-12 gap-2 mb-3"
                          >
                            <div className="col-span-12 text-sm flex justify-between">
                              <span className="font-bold text-gray-600">
                                Addon {index + 1}
                              </span>
                              <button
                                onClick={() => {
                                  remove(index);
                                }}
                                className="text-gray-500 hover:text-red-700"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </button>
                            </div>
                            <div className="col-span-12 lg:col-span-5 text-sm">
                              <Label>
                                Addon <RequiredAsterisk />
                              </Label>
                              <Field
                                name={`addons.${index}.addon_code`}
                                as="select"
                                className="form-select disabled-white"
                                onChange={(e) =>
                                  onAddonChange(
                                    index,
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              >
                                <option value="">Select One</option>
                                {addons?.map((addon) => (
                                  <option
                                    key={addon.addon_code}
                                    value={addon.addon_code}
                                  >
                                    {addon.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`addons.${index}.addon_code`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                            <div className="col-span-6 lg:col-span-2 text-sm">
                              <Label htmlFor="type">Type</Label>
                              <div className="pl-3 py-2 capitalize">
                                {values.addons[index].type}
                              </div>
                            </div>
                            <div className="col-span-6 lg:col-span-2 text-sm">
                              <Label>Qty</Label>
                              <Field
                                name={`addons.${index}.quantity`}
                                className="form-input disabled-white"
                                type="number"
                                step="1"
                              />
                              <ErrorMessage
                                name={`addons.${index}.quantity`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                            <div className="col-span-6 lg:col-span-2 text-sm">
                              <Label>Price</Label>
                              <div className="relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <span className="text-gray-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <Field
                                  name={`addons.${index}.price`}
                                  className="form-input  pl-7 r-12"
                                  type="number"
                                />
                                <div className="absolute inset-y-0 right-0 flex items-center">
                                  <Label
                                    htmlFor="currency_code"
                                    classes="sr-only"
                                  >
                                    Currency
                                  </Label>
                                  <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                                    {values.currency_code}
                                  </span>
                                </div>
                              </div>
                              <ErrorMessage
                                name={`addons.${index}.price`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                            <div className="col-span-6 lg:col-span-1 text-sm text-right flex flex-col justify-end">
                              <Label>Total</Label>
                              <span className="py-2 text-green-500 font-bold ">
                                {currencyFormatter(
                                  values.addons[index].price *
                                    values.addons[index].quantity,
                                  values.currency_code
                                )}
                              </span>
                            </div>
                            <div className="col-span-12">
                              <Field
                                name={`addons.${index}.addon_description`}
                                as="textarea"
                                rows={2}
                                className="form-input"
                                maxLength={2000}
                              />
                              <div className="flex justify-between">
                                <div className="flex">
                                  &nbsp;
                                  <ErrorMessage
                                    name={`addons.${index}.addon_description`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div
                                  className={`text-right italic text-xs mt-1  ${
                                    values.addons[index].addon_description
                                      ?.length === 2000
                                      ? 'text-red-700'
                                      : ''
                                  }`}
                                >
                                  {
                                    values.addons[index].addon_description
                                      ?.length
                                  }
                                  /<span className="font-medium">2000</span>{' '}
                                  characters
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      <div className="col-span-2">
                        <Button
                          classes="font-bold tracking-wider w-full justify-center"
                          bgColor="gray-50"
                          hoverColor="gray-200"
                          roundedSize="2xl"
                          textColor="gray-600"
                          border="border-2 border-gray-600"
                          py={1}
                          shadow=""
                          onClick={(e) => {
                            push(addonObj);
                          }}
                        >
                          <PlusIcon className="w-4 h-4 inline" /> Addon
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      Apply
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <div className="py-4 px-6"></div>
      </div>
    </Modal>
  );
};
export default AddonModal;
