import axios from 'axios';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import metrics from '../../utils/metrics';
import ToggleChart from '../components/ToggleChart';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

const attributes = [
  'cost',
  'clicks',
  'cpc',
  'orders',
  'sales',
  'cr',
  'aov',
  'acos',
];

const COLORS = ['#F84D67', '#FFDB6C', '#82ca9d', '#8884d8'];

const CampaignTypesBreakdown = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [displayChart, setDisplayChart] = useState(false);
  const [breakdown, setBreakdown] = useState([
    { title: 'Sponsored Products' },
    { title: 'Sponsored Brands' },
    { title: 'Sponsored Brands Video' },
    { title: 'Sponsored Display' },
    { title: 'Total' },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(
        '/account/advertising/analytics/campaign-types/breakdown',
        {
          params: {
            accountId,
            marketplace,
            startDate,
            endDate,
            attributes,
            ...additionalParams,
          },
        }
      );

      if (isSubscribed) {
        setBreakdown(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 100) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize="smaller"
        >{`${(percent * 100).toFixed(2)}%`}</text>
      </g>
    );
  };

  return (
    <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
      <div className="flex items-center justify-between border-b px-8 pb-6">
        <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
          Performance Breakdown by Campaign Type
        </p>
        <ToggleChart visible={displayChart} setVisible={setDisplayChart} />
      </div>

      <div className="px-8">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden py-4">
              <table className="min-w-full divide-y divide-grayscale-400">
                <thead className="">
                  <tr className="">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-left text-xs font-bold text-gray-700 sm:pl-6"
                    ></th>
                    {attributes
                      .map((attribute) =>
                        metrics.find((m) => m.key === attribute)
                      )
                      .map((metric) => (
                        <th
                          key={`campaign-types-breakdown-header-${metric.key}`}
                          scope="col"
                          className="px-4 text-center py-3.5 border border-slate-50 text-mini font-bold text-white bg-grayscale-800"
                        >
                          {metric.shortTitle ?? metric.title}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {breakdown.map((breakdown, idx) => (
                    <tr
                      key={breakdown.title}
                      className={classNames(
                        idx % 2 === 0
                          ? 'border-l border-r'
                          : 'bg-gray-100 border-l border-r',
                        'divide-x divide-grayscale-500'
                      )}
                    >
                      <td
                        className={classNames(
                          idx === 0 ? 'border-t border-grayscale-500' : '',
                          idx === 4 ? 'border-b border-grayscale-500' : '',
                          'whitespace-nowrap py-3 pl-4 pr-4 text-mini text-gray-800'
                        )}
                      >
                        {breakdown.title}
                      </td>

                      {attributes
                        .map((attribute) =>
                          metrics.find((m) => m.key === attribute)
                        )
                        .map((metric) => (
                          <td
                            key={`campaign-types-breakdown-${breakdown.title}-data-${metric.key}`}
                            scope="col"
                            className={classNames(
                              loading ? 'animate-pulse' : '',
                              idx === 4 ? 'border-b border-grayscale-500' : '',
                              'font-medium whitespace-nowrap text-right px-4 py-3 text-mini text-grayscale-800'
                            )}
                          >
                            {loading ? (
                              <div className="h-5 w-full mt-1 mx-auto bg-gray-200 rounded"></div>
                            ) : (
                              metric.rawFormatter(breakdown[metric.key])
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {displayChart && (
          <div className="grid grid-cols-2">
            {['sales', 'cost']
              .map((attribute) => metrics.find((m) => m.key === attribute))
              .map((metric) => {
                const record = breakdown
                  .filter((b) => b.title !== 'Total')
                  .filter((b) => b[metric.key])
                  .map((b) => {
                    return { name: b.title, value: b[metric.key] };
                  });

                return (
                  <div
                    key={`campaign-types-breakdown-chart-${metric.key}`}
                    className="w-full h-72 mb-20"
                  >
                    <p className="text-center text-mini tracking-3/4 font-bold text-grayscale-800">
                      {metric.title}
                    </p>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={record}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          fill="#82ca9d"
                          paddingAngle={1}
                          labelLine={false}
                          isAnimationActive={false}
                          label={renderCustomizedLabel}
                        >
                          {record.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Legend
                          verticalAlign="bottom"
                          align="center"
                          wrapperStyle={{ fontSize: '11px', bottom: '-50px' }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignTypesBreakdown;
