import moment from 'moment';
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Bar,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
} from 'recharts';

import { currencyFormatter, percentageFormatter } from 'utils/formatters';

const MonthlyReportCharts = ({ data }) => {
  return (
    <div className="w-full mt-4">
      <div className="h-44 mx-auto pt-8 overflow-hidden">
        <ResponsiveContainer className="bg-gray-50" height="100%">
          <BarChart
            data={data.map((record) => {
              return { ...record, spend: record.cost };
            })}
            margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
          >
            <CartesianGrid vertical={false} fill="white" />
            <XAxis
              dataKey="startDate"
              style={{ fontWeight: '400', fontSize: '8px', color: '#202020' }}
              tickSize={0}
              dy={10}
              tickCount={5}
              axisLine={{ stroke: '#cbd5e1' }}
              tickFormatter={(value) => moment(value).format('MMM')}
            />
            <YAxis
              style={{
                fontSize: '0.7rem',
              }}
              stroke="#94a3b8"
              axisLine={{ stroke: '#cbd5e1' }}
              tick={{ fontWeight: '500' }}
              tickFormatter={(value) => currencyFormatter(value)}
              yAxisId="left"
              orientation="left"
            />

            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="right"
              className="text-black"
              wrapperStyle={{
                marginTop: '-30px',
                textTransform: 'uppercase',
                fontSize: '8px',
              }}
            />

            <Bar
              dataKey="sales"
              fill="#1879D8"
              yAxisId="left"
              isAnimationActive={false}
              barSize={13}
            />

            <Bar
              dataKey="spend"
              fill="#FF89A6"
              yAxisId="left"
              isAnimationActive={false}
              barSize={13}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="h-44 mx-auto pt-8 overflow-hidden">
        <ResponsiveContainer className="bg-gray-50" height="100%">
          <LineChart
            data={data}
            margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
          >
            <CartesianGrid vertical={false} fill="white" />
            <XAxis
              dataKey="startDate"
              style={{ fontWeight: '400', fontSize: '8px', color: '#202020' }}
              tickSize={0}
              dy={10}
              tickCount={5}
              axisLine={{ stroke: '#cbd5e1' }}
              tickFormatter={(value) => moment(value).format('MMM')}
              padding={{ left: 25, right: 25 }}
            />
            <YAxis
              style={{
                fontSize: '0.7rem',
              }}
              stroke="#94a3b8"
              axisLine={{ stroke: '#cbd5e1' }}
              tickFormatter={(value) => percentageFormatter(value)}
              yAxisId="left"
              orientation="left"
            />

            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="right"
              className="text-black"
              wrapperStyle={{
                marginTop: '-30px',
                textTransform: 'uppercase',
                fontSize: '8px',
              }}
            />

            <Line
              dataKey="acos"
              stroke="#1879D8"
              type="linear"
              strokeWidth={1}
              dot={{ stroke: '#1879D8', fill: '#1879D8', strokeWidth: 1 }}
              isAnimationActive={false}
              yAxisId="left"
            ></Line>
            <Line
              dataKey="tacos"
              stroke="#FF89A6"
              type="linear"
              strokeWidth={1}
              dot={{ stroke: '#FF89A6', fill: '#FF89A6', strokeWidth: 1 }}
              isAnimationActive={false}
              yAxisId="left"
            ></Line>
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="h-44 mx-auto pt-8 overflow-hidden">
        <ResponsiveContainer className="bg-gray-50" height="100%">
          <LineChart
            data={data.map((record) => {
              return {
                ...record,
                ['Paid Conversion']: record.cr,
                ['Organic Conversion']: record.organicConversionRate,
              };
            })}
            margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
          >
            <CartesianGrid vertical={false} fill="white" />
            <XAxis
              dataKey="startDate"
              style={{ fontWeight: '400', fontSize: '8px', color: '#202020' }}
              tickSize={0}
              dy={10}
              tickCount={5}
              axisLine={{ stroke: '#cbd5e1' }}
              tickFormatter={(value) => moment(value).format('MMM')}
              padding={{ left: 25, right: 25 }}
            />
            <YAxis
              style={{
                fontSize: '0.7rem',
              }}
              stroke="#94a3b8"
              axisLine={{ stroke: '#cbd5e1' }}
              tickFormatter={(value) => percentageFormatter(value)}
              yAxisId="left"
              orientation="left"
            />

            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="right"
              className="text-black"
              wrapperStyle={{
                marginTop: '-30px',
                textTransform: 'uppercase',
                fontSize: '8px',
              }}
            />

            <Line
              dataKey="Paid Conversion"
              stroke="#1879D8"
              type="linear"
              strokeWidth={1}
              dot={{ stroke: '#1879D8', fill: '#1879D8', strokeWidth: 1 }}
              isAnimationActive={false}
              yAxisId="left"
            />

            <Line
              dataKey="Organic Conversion"
              stroke="#FF89A6"
              type="linear"
              strokeWidth={1}
              dot={{ stroke: '#FF89A6', fill: '#FF89A6', strokeWidth: 1 }}
              isAnimationActive={false}
              yAxisId="left"
            ></Line>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MonthlyReportCharts;
