import { Avatar } from 'components';
import useUpsell from 'hooks/useUpsell';
import { getNameInitials } from 'utils/formatters';

const UpsellStatusUpdated = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { data, message, title, sender } = notification;
  const { STATUS } = useUpsell();

  const path =
    data.status === STATUS.AWAITING_APPROVAL
      ? `/upsells`
      : `/clients/${data.agencyClientId}/dashboard`;

  const onClick = () => {
    markAsRead();
    router.push(path);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <div className="rounded-full bg-success flex items-center justify-center w-12 h-12">
        <Avatar
          size="48px"
          imageSrc={sender?.avatar?.thumbnailUrl}
          initials={
            sender ? getNameInitials(sender.firstName, sender.lastName) : 'S'
          }
        />
      </div>

      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">
          {data?.details?.name}
        </p>
      </div>

      {children}
    </div>
  );
};

export default UpsellStatusUpdated;
