import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HtmlEditor = ({ value = '', onChange }) => {
  const handleChange = (content, delta, source, editor) => {
    onChange(content);
  };

  return (
    <div className="quill-text-editor py-2">
      <ReactQuill
        theme="snow"
        placeholder=""
        value={value}
        onChange={handleChange}
        bounds={`.quill-text-editor`}
      />
    </div>
  );
};

export default HtmlEditor;
