import axios from 'axios';
import { Fragment } from 'react';
import { isFunction } from 'lodash';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

const PaymentMethodItem = ({
  paymentMethod,
  agencyClientId,
  editable = true,
  onChange,
}) => {
  const markAsDefault = async (token) => {
    await axios.post(
      `/agency/clients/${agencyClientId}/payment-methods/${token}/default`
    );
    isFunction(onChange) && onChange();
  };

  const deletePaymentMethod = async (token) => {
    await axios.delete(
      `/agency/clients/${agencyClientId}/payment-methods/${token}`
    );
    isFunction(onChange) && onChange();
  };

  return (
    <li key={paymentMethod.globalId} className="flex justify-between">
      <div className="flex space-x-3">
        <img src={paymentMethod.imageUrl} />

        <div className="text-13">
          <p className="tracking-2 font-bold text-grayscale-900 flex items-center">
            {paymentMethod.maskedNumber}
            {paymentMethod.default && (
              <span className="ml-2 bg-secondary-light text-grayscale-300 px-2 py-1 rounded-full text-8 font-semibold">
                Default
              </span>
            )}
          </p>
          <p className="text-grayscale-800">
            {paymentMethod.expirationMonth}/{paymentMethod.expirationYear}
          </p>
        </div>
      </div>

      {!paymentMethod.default && editable && (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-36 text-xs tracking-3/4 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none e">
              <div className="py-1 text-grayscale-900">
                <Menu.Item
                  as="button"
                  disabled={!editable}
                  onClick={() => markAsDefault(paymentMethod.token)}
                  className="w-full text-left px-4 py-1.5 hover:bg-grayscale-500"
                >
                  Set as Default
                </Menu.Item>

                <Menu.Item
                  as="button"
                  disabled={!editable}
                  onClick={() => deletePaymentMethod(paymentMethod.token)}
                  className="w-full text-left px-4 py-1.5 hover:bg-grayscale-500"
                >
                  Delete
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </li>
  );
};

export default PaymentMethodItem;
