import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { Link } from 'react-router-dom';

const InvoiceSearch = () => {
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (!inputValue) return resolve([]);

      axios
        .get('/v2/agency/billing/invoices/search', {
          params: {
            search: inputValue,
          },
        })
        .then((response) => {
          resolve(
            response.data.data.map((item) => ({
              value: item.invoice_id,
              label: (
                <Link
                  className="text-grayscale-800"
                  to={`/invoices/${item.invoice_id}`}
                >
                  <p className="text-sm font-medium">{item.invoice_number}</p>
                  <p className="text-xs text-grayscale-600">
                    {item.customer_name}
                  </p>
                </Link>
              ),
            }))
          );
        });
    });

  return (
    <AsyncSelect
      placeholder="Search Invoice No."
      className="w-64"
      cacheOptions
      loadOptions={promiseOptions}
      defaultOptions
      classNames={{
        control: (state) =>
          'text-13 bg-grayscale-400 rounded-2xl border-0 px-2 py-1 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0',
      }}
      styles={{
        control: (baseStyles) => {
          return {
            ...baseStyles,
            outline: 'none',
            borderRadius: '0.375rem',
            borderColor: 'rgb(229 231 235)',
            '&:hover': {
              borderColor: 'rgb(229 231 235)',
            },
            '&:focus': {
              outline: 'none',
            },
            '&:active': {
              outline: 'none',
            },
            ':focus': {
              outline: 'none',
            },
            ':active': {
              outline: 'none',
            },
          };
        },
      }}
    />
  );
};

export default InvoiceSearch;
