import { useState } from 'react';
import { ChatIcon } from '@heroicons/react/outline';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';

const SectionComment = ({
  title = 'Analysis',
  value = '',
  onChangeValue,
  editable = true,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState(value);

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        as={'div'}
        align="top"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-lg my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title={title}
            titleClasses="text-base font-bold text-grayscale-800 tracking-2"
            setOpen={() => {
              setOpenModal(false);
              setComment(value);
            }}
            showCloseButton={true}
          />

          <div className="px-4 py-4">
            {editable ? (
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={4}
                className="form-select text-mini"
              ></textarea>
            ) : (
              <div className="rounded pb-8 text-grayscale-800 whitespace-pre-line text-mini">
                {comment}
              </div>
            )}

            {editable && (
              <div className="flex justify-between mt-4 mb-2">
                <button
                  onClick={() => {
                    setOpenModal(false);
                    setComment(value);
                  }}
                  className="mt-2 font-bold tracking-2 text-grayscale-800 text-mini"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    onChangeValue(comment);
                    setOpenModal(false);
                  }}
                  className="rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-secondary text-mini text-grayscale-300 tracking-2 font-bold focus:outline-none"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      {(editable || (!editable && value)) && (
        <div className="absolute cursor-pointer" style={{ right: '-1rem' }}>
          <ChatIcon
            className="w-8 h-8 text-grayscale-800"
            onClick={() => setOpenModal(true)}
          />
        </div>
      )}
    </>
  );
};

export default SectionComment;
