import axios from 'axios';
import { upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ChartBarIcon, KeyIcon } from '@heroicons/react/solid';

import TrendsModal from '../../../components/TrendsModal/TrendsModal';
import AllocationEditor from '../../components/AllocationEditor';
import StatusSwitch from '../../components/StatusSwitch';

import AdvertisingTable from 'features/advertising/components/AdvertisingTable';
import { metricColumns } from 'features/advertising/utils/columns';

import {
  AD_GROUPS,
  EQUAL_TO,
  LESS_THAN,
  STATUS_OPTIONS,
} from 'features/advertising/utils/constants';
import { userCan } from 'utils/permission';

const AdGroups = ({ accountId, marketplace, campaignType }) => {
  const { url } = useRouteMatch();
  const queryParams = new URLSearchParams(window.location.search);

  const { range: dateRange } = useSelector((state) => state.datePicker);
  const { user } = useSelector((state) => state.auth);

  const [openTrends, setOpenTrends] = useState(false);
  const [selectedAdGroup, setSelectedAdGroup] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('adgroups-column') ??
      'advAdGroupId,name,state,cost,sales,orders'
  );

  const [adGroups, setAdGroups] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    campaignType,
    search: '',
    attributes: [
      'advAdGroupId',
      'advCampaignId',
      'state',
      'name',
      'defaultBid',
      'impressions',
      'clicks',
      'ctr',
      'cost',
      'cpc',
      'orders',
      'sales',
      'acos',
      'attributedOrdersNewToBrandPercentage14d',
      'attributedSalesNewToBrand14d',
      'attributedSalesNewToBrandPercentage14d',
      'cpm',
      'cr',
      'profit',
      'roas',
      'profitMargin',
      'unitsSold',
      'cpcon',
      'averageSalesPrice',
    ],
    include: ['previousData', 'campaign'],
    sort: 'cost:desc',
    advCampaignId: queryParams.has('campaignId')
      ? queryParams.get('campaignId')
      : null,
    advAdGroupId: queryParams.has('adGroupId')
      ? queryParams.get('adGroupId')
      : null,
    listingId: queryParams.has('listingId')
      ? queryParams.get('listingId')
      : null,
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(`/account/advertising/ad-groups`, {
        params: {
          ...params,
          accountId,
          marketplace,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
      });

      if (isSubscribed) {
        setAdGroups(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, campaignType, dateRange, params]);

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('adgroups-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const columns = [
    {
      dataField: 'name',
      auto: true,
      default: true,
      category: 'settings',
      text: 'Ad Group',
      sort: true,
      headerStyle: { minWidth: '275px' },
      classes: 'sticky left-0 z-10 bg-white',
      headerClasses: 'sticky left-0 z-10 bg-white',
      formatter: (cell, row) => {
        return (
          <>
            <p>{cell}</p>
            <p className="text-grayscale-600 text-tiny">
              Campaign: {row.campaign?.name}
            </p>
          </>
        );
      },
    },
    {
      sort: false,
      default: true,
      hideable: true,
      text: 'Status',
      dataField: 'state',
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity="ad-groups"
          entityId={row.advAdGroupId}
          accountId={accountId}
          marketplace={marketplace}
          disabled={
            !userCan(
              user,
              'ppc.adGroup.update.status.noApproval|ppc.adGroup.update.status.requireApproval'
            )
          }
        />
      ),
      category: 'settings',
    },
    {
      dataField: 'defaultBid',
      hideable: true,
      category: 'settings',
      text: 'Bid',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: { minWidth: '175px' },
      formatter: (cell, row) => (
        <AllocationEditor
          accountId={accountId}
          marketplace={marketplace}
          entity="ad-groups"
          entityId={row.advAdGroupId}
          currentValue={cell}
          editable={userCan(
            user,
            'ppc.adGroup.update.defaultBid.noApproval|ppc.adGroup.update.defaultBid.requireApproval'
          )}
          attribute="defaultBid"
        />
      ),
    },
    ...metricColumns().filter((col) => {
      return col.campaignTypes
        ? col.campaignTypes.includes(campaignType)
        : true;
    }),
    {
      auto: true,
      default: true,
      dataField: 'advAdGroupId',
      text: 'Action',
      sort: false,
      formatter: (cell, row) => {
        const targeting = row.isProductTargeting ? 'targets' : 'keywords';
        const onClick = () => {
          setSelectedAdGroup(row);
          setOpenTrends(true);
        };
        return (
          <div className="flex">
            <ChartBarIcon
              className="w-6 h-6 text-secondary cursor-pointer"
              onClick={onClick}
            />
            <NavLink
              to={url.replace('ad-groups', `${targeting}?adGroupId=${cell}`)}
            >
              <KeyIcon className="w-6 h-6 text-secondary" />
            </NavLink>
          </div>
        );
      },
    },
  ];

  const statusOptions = STATUS_OPTIONS.map((value) => {
    const display = upperFirst(value);
    return { display, value };
  });

  return (
    <div id={`advertising-${campaignType}-ad-groups`}>
      <TrendsModal
        title={selectedAdGroup.name}
        open={openTrends}
        setOpen={(value) => {
          setSelectedAdGroup({});
          setOpenTrends(value);
        }}
        accountId={accountId}
        marketplace={marketplace}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        entity={selectedAdGroup}
        url={
          selectedAdGroup.advAdGroupId
            ? '/account/advertising/analytics/records'
            : ''
        }
        additionalParams={{ advAdGroupId: selectedAdGroup.advAdGroupId }}
      />

      <AdvertisingTable
        params={params}
        list={adGroups}
        columns={columns}
        accountId={accountId}
        recordType={AD_GROUPS}
        keyField="advAdGroupId"
        marketplace={marketplace}
        campaignType={campaignType}
        onChangeParams={setParams}
        searchClassName="lg:col-span-3"
        filtersClassName="lg:col-span-3"
        exportClassName="lg:col-span-3"
        columnPickerClassName="lg:col-span-3"
        searchPlaceholder="Search ad group"
        attributesKey={`${accountId}-${campaignType}-${AD_GROUPS}`}
        visibleColumns={visibleColumns}
        onChangeVisibleColumns={onChangeVisibleColumns}
        endpoint="ad-groups"
        exportInclude={['campaign']}
        additionalFilters={[
          {
            attribute: 'state',
            display: 'Status',
            comparison: EQUAL_TO,
            value: '',
            options: statusOptions,
            placeholder: 'Status',
          },
          {
            attribute: 'defaultBid',
            display: 'Bid',
            comparison: LESS_THAN,
            value: '',
          },
        ]}
      />
    </div>
  );
};

export default AdGroups;
