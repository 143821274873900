import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import classNames from 'utils/classNames';
import usePermissions from 'hooks/usePermissions';
import { linkStyles, setTabStyle } from 'utils/tabStyles';
import Subscriptions from './Subscriptions';
import CreditNotes from './CreditNotes';
import InvoiceHistory from './InvoiceHistory';
import {
  fetchPendingInvoices,
  fetchScheduledChanges,
  fetchSubscription,
} from './subscriptionSlice';
import useBodyClass from 'hooks/useBodyClass';
import { fetchMarketplaces } from 'features/marketplaces/marketplacesSlice';
import ComputedCommissions from './ComputedCommissions';
import ComputedErrors from './ComputedError';
import Transactions from './Transactions';
//import Upsell from './Upsell';
import Upsells from './Upsells';
import PaidReviews from './PaidReviews';

const Billing = ({ location }) => {
  useBodyClass(['client-profile', 'billing']);
  const { userCan, isAgencySuperUser } = usePermissions();
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { subscription } = useSelector((state) => state.subscription);
  const { marketplaces } = useSelector((state) => state.marketplaces);
  const { pathname } = location;

  useEffect(() => {
    dispatch(
      fetchSubscription(agencyClient.account.subscription.subscriptionId)
    ).then(() => {
      dispatch(
        fetchScheduledChanges(agencyClient.account.subscription.subscriptionId)
      );
      dispatch(
        fetchPendingInvoices(agencyClient.account.subscription.subscriptionId)
      );
    });
  }, [agencyClient.account.subscription.subscriptionId]);

  useEffect(() => {
    if (marketplaces.length <= 0) {
      dispatch(fetchMarketplaces());
    }
  }, [dispatch]);

  const profileTabs = [
    { tab: 'Subscriptions', url: url, visible: true },
    {
      tab: 'Upsells',
      url: `${url}/upsells`,
      visible: userCan('clients.upsells.list'),
    },
    {
      tab: 'Paid Reviews',
      url: `${url}/paidreviews`,
      visible: userCan('clients.paidreviews.view'),
    },
    {
      tab: 'Credit Notes',
      url: `${url}/creditnotes`,
      visible: userCan('clients.profile.credit_notes.view'),
    },
    {
      tab: 'Invoice History',
      url: `${url}/invoicehistory`,
      visible: userCan('clients.subscription.invoices.view'),
    },
    {
      tab: 'Transactions',
      url: `${url}/transactions`,
      visible: userCan('clients.subscription.invoices.view'),
    },
    {
      tab: 'Commissions',
      url: `${url}/computed-commissions`,
      visible: userCan('clients.subscription.invoices.view'),
    },
    {
      tab: 'Comm. Errors',
      url: `${url}/computed-errors`,
      visible: userCan('clients.subscription.invoices.view'),
    },
  ].filter((tab) => tab.visible);

  return (
    <>
      {!loading && subscription ? (
        <>
          <div className="lg:grid grid-cols-5">
            <div className="col-span-5">
              <div className="tablinks flex">
                {profileTabs.map((tab, i) => (
                  <Link
                    key={i}
                    to={tab.url}
                    className={classNames(
                      linkStyles,
                      setTabStyle(tab, profileTabs, pathname),
                      tab.icon ? 'flex items-center justify-center' : ''
                    )}
                  >
                    {tab.icon && (
                      <tab.icon className="w-4 h-4 inline mr-2 text-gray-700" />
                    )}{' '}
                    {tab.tab}
                  </Link>
                ))}
              </div>
            </div>
            <Switch>
              <Route exact path={`${path}`} component={Subscriptions} />
              <Route path={`${path}/creditnotes`} component={CreditNotes} />
              <Route path={`${path}/upsells`} component={Upsells} />
              <Route path={`${path}/paidreviews`} component={PaidReviews} />
              <Route
                path={`${path}/invoicehistory`}
                component={InvoiceHistory}
              />
              <Route path={`${path}/transactions`} component={Transactions} />
              <Route
                path={`${path}/computed-commissions`}
                component={ComputedCommissions}
              />
              <Route
                path={`${path}/computed-errors`}
                component={ComputedErrors}
              />
            </Switch>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default withRouter(Billing);
