import axios from 'axios';
import { useDispatch } from 'react-redux';

import { setAlert } from 'features/alerts/alertsSlice';

const ExportButton = ({ className = '', type, params }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    let payload = { ...params };
    delete payload.include;

    axios
      .get(`/account/advertising/${type}-keywords/export`, {
        params: payload,
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
          encoding: 'UTF-8',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${type}.csv`;
        link.click();
      })
      .catch((error) => {
        dispatch(setAlert('error', error.response.data.message));
      });
  };
  return (
    <button
      onClick={onClick}
      className={`${className} border-grayscale-700 border-2 font-bold tracking-2 text-grayscale-700 rounded-3xl text-sm leading-1.5`}
    >
      Export
    </button>
  );
};

export default ExportButton;
