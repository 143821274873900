import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { ExclamationIcon } from '@heroicons/react/outline';

import { fetchAccount } from './accountSlice';

import Advertising from './Advertising/Advertising';
import Products from './Products/Products';
import Profits from './Profits/Profits';
import DataSync from './DataSync/DataSync';

const Account = () => {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { account, marketplace, accountLoaded } = useSelector(
    (state) => state.account
  );

  const [spApiError, setSpApiError] = useState(false);
  const [advApiError, setAdvApiError] = useState(false);

  useEffect(() => {
    dispatch(fetchAccount(agencyClient.accountId));
  }, [dispatch, agencyClient]);

  useEffect(() => {
    if (
      accountLoaded &&
      account.credentials.some((c) => c.service === 'spApi')
    ) {
      axios
        .post('/account/credentials/sp-api/check', {
          accountId: account.accountId,
        })
        .catch((e) => {
          setSpApiError(e.response.data?.message || e.response.statusText);
        });
    }

    if (
      accountLoaded &&
      account.credentials.some((c) => c.service === 'advApi')
    ) {
      axios
        .post('/account/credentials/adv-api/check', {
          accountId: account.accountId,
        })
        .catch((e) => {
          setAdvApiError(e.response.data?.message || e.response.statusText);
        });
    }
  }, [accountLoaded, account]);

  return accountLoaded && account && marketplace ? (
    <>
      {(spApiError || advApiError) && (
        <div className="-mt-4 bs-shadow bg-red-500 text-white mb-4 mr-8 px-4 py-2 font-bold font-inter text-13 rounded-md">
          {spApiError && (
            <p className="flex">
              <span>
                <ExclamationIcon className="w-5 h-5 mr-1" />
              </span>
              Something went wrong with Account SP API Connection: {spApiError}
            </p>
          )}
          {advApiError && (
            <p className="flex">
              <span>
                <ExclamationIcon className="w-5 h-5 mr-1" />
              </span>
              Something went wrong with Account ADV API Connection:{' '}
              {advApiError}
            </p>
          )}
        </div>
      )}

      <Switch>
        <Route
          exact
          path={`${url}/advertising/`}
          render={() => (
            <Redirect
              to={`${url}/advertising/${marketplace.details.countryCode}`}
            />
          )}
        />

        <Route
          exact
          path={`${url}/products/`}
          render={() => (
            <Redirect
              to={`${url}/products/${marketplace.details.countryCode}`}
            />
          )}
        />

        <Route
          exact
          path={`${url}/profits/`}
          render={() => (
            <Redirect
              to={`${url}/profits/${marketplace.details.countryCode}`}
            />
          )}
        />

        <Route
          exact
          path={`${url}/data-sync/`}
          render={() => (
            <Redirect
              to={`${url}/data-sync/${marketplace.details.countryCode}`}
            />
          )}
        />

        <Route
          path={`${path}/advertising/:marketplace`}
          component={Advertising}
        />

        <Route path={`${path}/products/:marketplace`} component={Products} />
        <Route path={`${path}/profits/:marketplace`} component={Profits} />
        <Route path={`${path}/data-sync/:marketplace`} component={DataSync} />
      </Switch>
    </>
  ) : (
    <div className="h-96 flex items-center justify-center animate-pulse">
      <p className="font-inter text-3xl tracking-2 text-grayscale-800 font-bold text-center">
        Loading Seller Account Information.
      </p>
    </div>
  );
};

export default withRouter(Account);
