import ModalHeader from 'components/ModalHeader';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, number } from 'yup';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import axios from 'axios';

export default function RevenueWeightModal({
  setOpen,
  revenueDetailData,
  setRevenueDetailData,
}) {
  const { alertSuccess, alertError } = useAlert();

  const onClose = () => {
    setOpen(false);
  };

  const validationSchema = object().shape({
    percentage: number('Must be number')
      .required('Is required')
      .positive()
      .integer()
      .min(0, 'Min is 0')
      .max(100, 'max is 100'),
  });

  const onSubmit = async (values) => {
    const response = await axios
      .patch(`/agency/sales/revenue/${values.salesRevenueDetailId}`, {
        percentage: values.percentage,
      })
      .then((res) => {
        if (res.data.success) {
          setRevenueDetailData(res.data.data);
          alertSuccess('Success', res.data.message);
        } else {
          alertError('Fail', res.data.message);
        }
      });
  };

  return (
    <>
      <div className="inline-flex flex-col justify-center h-screen">
        <div className="inline-block w-80 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={'Edit Weighted'}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            rightContent={
              <button className="relative group mr-4">
                <XIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 absolute bottom-0 right-0 bg-red-500 text-white rounded-xl leading-none" />
              </button>
            }
            onClose={onClose}
          />
          <Formik
            initialValues={revenueDetailData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, setFieldValue, values }) => (
              <Form>
                <div className="px-8 pt-3 flex flex-row">
                  <div className="flex flex-col justify-center pr-2">
                    <p className="text-13 font-normal">Weighted</p>
                  </div>
                  <div className="w-23">
                    <Field
                      name="percentage"
                      placeholder="0"
                      className="form-select text-sm w-20"
                      onChange={(e) => handleChange(e)}
                      type="number"
                    />
                  </div>
                  <div className="relative right-6 flex flex-col justify-center">
                    <p className="text-grayscale-700 text-xl ">%</p>
                  </div>
                </div>
                <ErrorMessage
                  name="percentage"
                  component="div"
                  className="text-red-700 font-normal text-xs px-8"
                />
                <div className="flex justify-between px-12 py-8">
                  <button
                    onClick={onClose}
                    className=" text-custom-sky font-bold text-sm"
                    color="green"
                  >
                    Cancel
                  </button>
                  <Button
                    classes="border-0 font-bold tracking-widest"
                    bgColor="blue-900"
                    hoverColor="blue-500"
                    roundedSize="full"
                    textColor="white"
                    px={8}
                    py={2}
                    shadow=""
                    textSize=""
                    showLoading={true}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
