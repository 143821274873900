import axios from 'axios';
import { useEffect, useState } from 'react';

import Checkbox from 'components/Forms/Checkbox';

const CustomTargetPortfolio = ({
  accountId,
  marketplace,
  value = [],
  onChangeValue,
  disabled = false,
}) => {
  const [search, setSearch] = useState('');
  const [portfolios, setPorfolios] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/account/advertising/portfolios`, {
        params: {
          search,
          accountId,
          marketplace,
          pageSize: 100000,
          sort: 'name:asc',
        },
      });

      if (isSubscribed) {
        setPorfolios(response.data.data.rows);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, search]);

  return (
    <div id="rules-custom-target-portfolios">
      <input
        placeholder="Search portfolio"
        className="px-4 py-2 focus:outline-none focus:ring-0 block w-full text-mini font-inter border border-grayscale-500 rounded-md text-grayscale-800"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="h-48 px-2 mt-2 overflow-y-scroll">
        {loading ? (
          <p className="text-center my-2 tracking-2 font-bold animate-pulse">
            Loading Records
          </p>
        ) : portfolios.length ? (
          portfolios.map((portfolio) => {
            return (
              <div
                className="flex items-center py-1.5"
                key={`rules-custom-target-portfolios-${portfolio.advPortfolioId}`}
              >
                <Checkbox
                  disabled={disabled}
                  id={portfolio.advPortfolioId}
                  classes="mr-2"
                  checked={value.includes(portfolio.advPortfolioId)}
                  onChange={() => {
                    onChangeValue(
                      value.includes(portfolio.advPortfolioId)
                        ? value.filter((v) => v !== portfolio.advPortfolioId)
                        : [...value, portfolio.advPortfolioId]
                    );
                  }}
                />

                <p className="text-grayscale-700 tracking-3/4">
                  {portfolio.name}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-center my-2 tracking-2 font-bold">
            No records found.
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomTargetPortfolio;
