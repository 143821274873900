import axios from 'axios';
import { Table } from 'components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

import ExpandRow from './ExpandRow';
import ButtonLink from 'components/ButtonLink';
import DataDifference from 'components/DataDifference';

import StatusBadge from 'features/clients/List/components/StatusBadge';
import SubscriptionStatus from 'features/clients/List/components/SubscriptionStatus';
import AccountLogs from 'features/clients/Client/Account/Advertising/Logs/components/AccountLogsModal';

import {
  currencyFormatter,
  dateFormatterUTC,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const AccountList = ({ agencyClientAssigneeUserId }) => {
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState({ rows: [] });
  const [params, setParams] = useState({ page: 1, pageSize: 10 });
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  let history = useHistory();

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/accounts/list', {
        params: {
          ...params,
          ...dateRange,
          include: ['previousData', 'client', 'subscription'],
          agencyClientAssigneeUserId,
          attributes: [
            'accountId',
            'name',
            'advertisingSales',
            'advertisingClicks',
            'advertisingImpressions',
            'advertisingCTR',
            'advertisingCPC',
            'advertisingCVR',
            'advertisingCost',
            'advertisingACOS',
            'totalSales',
            'tacos',
            'organicSalesPercentage',
            'advertisingSalesPercentage',
            'advertisingSPSales',
            'advertisingSPCost',
            'advertisingSPACOS',
            'advertisingSBSales',
            'advertisingSBCost',
            'advertisingSBACOS',
            'advertisingSDSales',
            'advertisingSDCost',
            'advertisingSDACOS',
          ],
        },
      });

      if (isSubscribed) {
        setAccounts(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [params, agencyClientAssigneeUserId, dateRange]);

  const redirectToClient = async (accountId) => {
    const client = await axios.get(`/agency/clients/accounts/${accountId}`);
    if (client.data.data.status === 'ACTIVE')
      history.push(`/clients/${client.data.data.agencyClientId}/dashboard`);
    else history.push(`/clients/edit/${client.data.data.agencyClientId}/`);
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Account',
      sort: true,
      formatter: (cell, row) => {
        return (
          <ButtonLink
            onClick={() => {
              redirectToClient(row.accountId);
            }}
          >
            {cell}
          </ButtonLink>
        );
      },
    },
    {
      dataField: 'client.status',
      text: 'Status',
      sort: true,
      formatter: (cell) => <StatusBadge status={cell} />,
    },
    {
      dataField: 'subscription.status',
      text: 'Subscription',
      sort: true,
      formatter: (cell, row) => (
        <SubscriptionStatus subscription={row.subscription} />
      ),
    },
    {
      dataField: 'client.contractSigned',
      text: 'Contract Signed',
      sort: true,
      formatter: (cell) => {
        return cell ? dateFormatterUTC(cell) : <>&mdash;</>;
      },
    },
    {
      dataField: 'advertisingImpressions',
      text: 'Impressions',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingImpressions}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingClicks',
      text: 'Clicks',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingClicks}
          formatter={numberFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCTR',
      text: 'CTR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCTR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCPC',
      text: 'CPC',
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCPC}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCVR',
      text: 'CVR',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCVR}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'totalSales',
      text: 'Total Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTotalSales}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingCost',
      text: 'PPC Spend',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingCost}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSales',
      text: 'PPC Sales',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSales}
          formatter={currencyFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingACOS',
      text: 'ACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingACOS}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'tacos',
      text: 'TACOS%',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousTacos}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'organicSalesPercentage',
      text: 'Organic Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousOrganicSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'advertisingSalesPercentage',
      text: 'PPC Sales %',
      sort: true,
      formatter: (cell, row) => (
        <DataDifference
          current={cell}
          previous={row.previousAdvertisingSalesPercentage}
          formatter={percentageFormatter}
        />
      ),
    },
    {
      dataField: 'accountId',
      text: 'Action',
      sort: true,
      formatter: (cell, row) => (
        <button
          onClick={() => {
            setSelectedAccount(row);
            setOpen(true);
          }}
        >
          <UserCircleIcon className="h-5 w-5" />
        </button>
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div>
      {!!selectedAccount.accountId && (
        <AccountLogs open={open} setOpen={setOpen} account={selectedAccount} />
      )}

      <Table
        loading={loading}
        keyField="accountId"
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={accounts}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
        expandRow={{
          onlyOneExpanding: false,
          showExpandColumn: true,
          expandHeaderColumnRenderer: () => null,
          renderer: (row) => <ExpandRow row={row} identifier={row.accountId} />,
          expandColumnRenderer: ({ expanded }) =>
            expanded ? (
              <ChevronUpIcon className="w-5 h-5 text-grayscale-800" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-grayscale-800" />
            ),
        }}
      />
    </div>
  );
};

export default AccountList;
