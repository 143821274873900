import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import SpinnerGrow from './SpinnerGrow';
import { usePopper } from 'react-popper';
import { useState } from 'react';
import classNames from 'utils/classNames';

const ButtonDropdown = ({
  children,
  classes,
  buttonTitle,
  textColor = 'text-white',
  buttonBg = 'bg-secondary',
  hoverBg = 'hover:bg-secondary-light',
  loading,
  showLoading,
  buttonClick,
  itemsClasses = 'right-0 w-40 mt-1',
  buttonType = 'button',
  placement = 'bottom-start',
  buttonDisabled = false,
  dropdownDisabled = false,
}) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  return (
    <Menu
      as="div"
      className={`relative inline-block text-left ${classes ?? ''}`}
      style={{ zIndex: 1 }}
    >
      <div className="flex">
        <button
          type={buttonType}
          className={classNames(
            loading && 'pointer-events-none',
            textColor,
            buttonBg,
            hoverBg,
            `inline-flex items-center pl-8 pr-5 py-2 border border-transparent rounded-full tracking-widest rounded-r-none shadow-sm text-sm font-bold focus:outline-none disabled:opacity-30`
          )}
          disabled={buttonDisabled}
          onClick={buttonClick}
        >
          {buttonTitle}
          &nbsp;{showLoading && loading ? <SpinnerGrow color="white" /> : ''}
        </button>
        <Menu.Button
          ref={setReferenceElement}
          className={classNames(
            textColor,
            buttonBg,
            hoverBg,
            `inline-flex justify-center pl-3 pr-4 py-2 border-l text-sm font-medium  rounded-full rounded-l-none  focus:outline-none`
          )}
          disabled={dropdownDisabled}
        >
          <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Menu.Items
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={`${itemsClasses} absolute origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  disabled:opacity-30`}
      >
        {children}
      </Menu.Items>
    </Menu>
  );
};

export default ButtonDropdown;
