import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClient, getLatestUpdates } from '../salesSlice';
import { PencilIcon, LinkIcon } from '@heroicons/react/outline';
import { object, string, number } from 'yup';
import usePermissions from 'hooks/usePermissions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Label from 'components/Forms/Label';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import InputPrepend from 'components/Forms/InputPrepend';
import Button from 'components/Button';
import { setAlert } from 'features/alerts/alertsSlice';
import classNames from 'utils/classNames';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { STATUSES, STATUSES_PROSPECT } from '../utils/constants';

moment.tz.setDefault('America/Toronto');

const BoardViewSlideOverForm = ({
  salesClientId,
  params,
  updateData,
  setIsRefreshStatus,
  isRefreshStatus,
  setOpenSlide,
  fetchRevenueDetailData,
}) => {
  const { client, clientStatus } = useSelector((state) => state.sales);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  useEffect(async () => {
    dispatch(fetchClient(salesClientId));
  }, [salesClientId]);

  let emptyInitialValues = {
    decisionMaker: '',
    email: '',
    displayName: '',
    aboutUs: '',
    goals: '',
    painPoints: '',
    totalAmountPaid: 0,
    closedDealDate: '',
    mainIssue: '',
    avgMonthlySalesOnAmazon: 0,
    avgMonthlySalesOutsideAmazon: 0,
    ppcSpend: 0,
    avgACOS: 0,
    status: 'booked',
    lead: {
      lead: '',
      leadLastName: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      address: '',
      website: '',
      source: '',
      facebook: '',
      instagram: '',
    },
  };

  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  useEffect(() => {
    let tempValues = {};
    if (client) {
      tempValues = {
        ...initialValues,
        decisionMaker: client.decisionMaker ?? '',
        email: client.email ?? '',
        aboutUs: client.aboutUs ?? '',
        goals: client.goals ?? '',
        painPoints: client.painPoints ?? '',
        totalAmountPaid: client.totalAmountPaid ?? 0,
        closedDealDate: client.closedDealDate
          ? moment.utc(client.closedDealDate).format('YYYY-MM-DD')
          : null,
        mainIssue: client.mainIssue ?? '',
        avgMonthlySalesOnAmazon: client.avgMonthlySalesOnAmazon ?? 0,
        avgMonthlySalesOutsideAmazon: client.avgMonthlySalesOutsideAmazon ?? 0,
        ppcSpend: client.ppcSpend ?? 0,
        avgACOS: client.avgACOS ?? 0,
        status: client.status ?? 'booked',
        lead: {
          lead: client.lead?.lead ?? '',
          leadLastName: client.lead?.leadLastName ?? '',
          companyName: client.lead?.companyName ?? '',
          email: client.lead?.email ?? '',
          phoneNumber: client.lead?.phoneNumber ?? '',
          address: client.lead?.address ?? '',
          website: client.lead?.website ?? '',
          source: client.lead?.source ?? '',
          facebook: client.lead?.facebook ?? '',
          instagram: client.lead?.instagram ?? '',
        },
      };
    }
    if (client && client.AgencyClient) {
      tempValues = {
        ...tempValues,
        displayName: client.AgencyClient?.displayName ?? '' ?? '',
      };
    }
    setInitialValues({
      ...tempValues,
    });
    ReactTooltip.rebuild();
  }, [client]);

  const displayNameCheck = (string) =>
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);

  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  let validationObj = {
    decisionMaker: string().required('Decision Maker - Required'),
    email: string()
      .email('Invalid email')
      .required('Decision Maker Email - Required'),
    lead: object().shape({}).required(),
    displayName: string()
      .test(
        'Display Name must not have any special characters or symbols',
        (value) => !displayNameCheck(value)
      )
      .max(25, 'Maximum of 25 characers'),
    totalAmountPaid: number()
      .positive()
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after comma',
        (val) => {
          if (val != undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .min(0, 'minimum 0')
      .required('Is required'),
  };

  const validationSchema = object().shape(validationObj);

  const checkErrors = (errors) => {
    Object.keys(errors).map(function (key) {
      if (key !== 'lead') {
        dispatch(setAlert('error', errors[key]));
      } else {
        Object.keys(errors[key]).map(function (el) {
          dispatch(setAlert('error', `${errors[key][el]}`));
        });
      }
    });
  };

  const onSubmit = async (val) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `/agency/sales/clients/${salesClientId}`,
        val
      );
      if (response.data.success) {
        await dispatch(setAlert('success', 'Successfully Updated'));
        await dispatch(getLatestUpdates(salesClientId));

        await dispatch(fetchClient(salesClientId));
        if (response.data.data.invite) {
          await dispatch(setAlert('success', 'Client invited!'));
        }
        updateData(params);
        fetchRevenueDetailData(params.status);
        setLoading(false);
      }
    } catch (error) {
      dispatch(
        setAlert(
          'error',
          'Saving failed!',
          error.message === 'Request failed with status code 409'
            ? 'Client is already registered - update in client profile'
            : error.message
        )
      );

      setLoading(false);
    }
  };

  return (
    <div>
      {client && (
        <>
          <div className="flex flex-row justify-between">
            <span className="text-xl font-bold">Deal</span>
            <button onClick={() => setIsEdit(!isEdit)} disabled={isEdit}>
              <PencilIcon
                className={classNames(
                  'w-5 h-5 inline',
                  isEdit ? 'text-custom-sky opacity-60' : 'text-custom-blue'
                )}
              />
            </button>
          </div>
          <div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form>
                  <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
                    <div>
                      <div className="pt-3 flex flex-row">
                        <div className="flex flex-col justify-center w-24">
                          <p className="text-13 font-normal text-grayscale-800">
                            Client Name
                          </p>
                        </div>
                        <div className="w-23">
                          <p className="font-normal text-13 text-grayscale-900">
                            {client?.lead.companyName}
                          </p>
                        </div>
                      </div>
                      <div className="pt-3 flex flex-row">
                        <div className="flex flex-col justify-center w-24">
                          <p className="text-13 font-normal text-grayscale-800">
                            Amount
                          </p>
                        </div>
                        <div className="w-23">
                          <Field
                            name="totalAmountPaid"
                            placeholder="0"
                            className={classNames(
                              !isEdit && 'px-0',
                              'form-input disabled-white w-20'
                            )}
                            onChange={(e) => handleChange(e)}
                            type="number"
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="totalAmountPaid"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                      <div className="pt-3 flex flex-row">
                        <div className="flex flex-col justify-center w-24">
                          <p className="text-13 font-normal text-grayscale-800">
                            Close Date
                          </p>
                        </div>
                        <div className="w-23">
                          {client?.closedDealDate === null && !isEdit ? (
                            <p className="font-normal text-13 text-grayscale-900">
                              yyyy-mm-dd
                            </p>
                          ) : (
                            <Field
                              name="closedDealDate"
                              className={classNames(
                                !isEdit && 'px-0',
                                'form-input disabled-white w-20'
                              )}
                              onChange={(e) => handleChange(e)}
                              type="date"
                              disabled={!isEdit}
                            />
                          )}
                        </div>
                      </div>
                      <div className="pt-3 flex flex-row">
                        <div className="flex flex-col justify-center w-24">
                          <p className="text-13 font-normal text-grayscale-800">
                            Status
                          </p>
                        </div>
                        <div>
                          <Field
                            name="status"
                            as="select"
                            className={classNames(
                              !isEdit && 'pl-0',
                              'form-input disabled-white'
                            )}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            disabled={
                              client.status === 'Client' ||
                              client.status === 'Transfer to Operations' ||
                              !isEdit
                            }
                          >
                            <option value="">Change Status</option>
                            {STATUSES_PROSPECT.some(
                              (el) => el.value === client.status
                            ) &&
                              STATUSES_PROSPECT.map((s) => {
                                if (s.value !== 'Lost') {
                                  return (
                                    <option key={s.value} value={s.value}>
                                      {s.label}
                                    </option>
                                  );
                                }
                              })}
                            {client.status === 'Proposal'
                              ? STATUSES.map((s) => {
                                  if (
                                    s.value === 'Proposal' ||
                                    s.value === 'Lost'
                                  ) {
                                    return (
                                      <option key={s.value} value={s.value}>
                                        {s.label}
                                      </option>
                                    );
                                  }
                                })
                              : STATUSES.map((s) => {
                                  return (
                                    <option key={s.value} value={s.value}>
                                      {s.label}
                                    </option>
                                  );
                                })}
                            {/* show since removed transfer to operations in selection  */}
                            {client.status === 'Transfer to Operations' && (
                              <option
                                key="Transfer to Operations"
                                value="Transfer to Operations"
                              >
                                Transfer to Operations
                              </option>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <Disclosure defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                              <span>Contact Details</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-6 w-6 text-gray-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                              <div className="sm:grid grid-cols-2 gap-4">
                                <div className="col-span-2">
                                  <Label>First Name</Label>
                                  <Field
                                    name="lead.lead"
                                    placeholder="First Name"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                  <ErrorMessage
                                    name="lead.lead"
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Last Name</Label>
                                  <Field
                                    name="lead.leadLastName"
                                    placeholder="Last Name"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                  <ErrorMessage
                                    name="lead.leadLastName"
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>Company Name</Label>
                                  <Field
                                    name="lead.companyName"
                                    placeholder="Last Name"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                  <ErrorMessage
                                    name="lead.companyName"
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>Phone Number</Label>
                                  <Field
                                    name="lead.phoneNumber"
                                    placeholder="Phone Number"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="tel"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Email Address</Label>
                                  <Field
                                    name="lead.email"
                                    placeholder="Email Address"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="email"
                                    disabled={!isEdit}
                                  />
                                  <ErrorMessage
                                    name="lead.email"
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>Address</Label>
                                  <Field
                                    name="lead.address"
                                    placeholder="Address"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>
                                    Website
                                    {values.lead?.website && (
                                      <LinkIcon
                                        onClick={() => {
                                          window.open(
                                            values.lead?.website,
                                            '_blank'
                                          );
                                        }}
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                      />
                                    )}
                                  </Label>
                                  <Field
                                    name="lead.website"
                                    placeholder="Website"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Source</Label>
                                  <Field
                                    name="lead.source"
                                    as="select"
                                    className="form-select disabled-white"
                                    disabled={!isEdit}
                                  >
                                    <option value=""></option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="New Leads">New Leads</option>
                                    <option value="Old-Leads">Old-Leads</option>
                                    <option value="Shopify">Shopify</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Website">Website</option>
                                    <option value="Google">Google</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Phone call">
                                      Phone call
                                    </option>
                                    <option value="Typelab">Typelab</option>
                                    <option value="Tiktok">Tiktok</option>
                                    <option value="Outreachr">Outreachr</option>
                                    <option value="DTC Ads">DTC Ads</option>
                                    <option value="PPC">PPC</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Winback Campaign">
                                      Winback Campaign
                                    </option>
                                    <option value="Walmart">Walmart</option>
                                    <option value="Lemlist">Lemlist</option>
                                    <option value="Helpwise">Helpwise</option>
                                    <option value="Email">Email</option>
                                    <option value="Inbound Call (Phone Call)">
                                      Inbound Call (Phone Call)
                                    </option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="SellerBites">
                                      SellerBites
                                    </option>
                                    <option value="Ads">Ads</option>
                                    <option value="Amazon Authority">
                                      Amazon Authority
                                    </option>
                                    <option value="Unknown">Unknown</option>
                                  </Field>
                                </div>
                                <div className="col-span-2">
                                  <Label>
                                    Facebook URL
                                    {values.lead?.facebook && (
                                      <LinkIcon
                                        onClick={() =>
                                          window.open(
                                            values.lead?.facebook,
                                            '_blank'
                                          )
                                        }
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                      />
                                    )}
                                  </Label>
                                  <Field
                                    name="lead.facebook"
                                    placeholder="Facebook URL"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>
                                    Instagram URL
                                    {values.lead?.instagram && (
                                      <LinkIcon
                                        onClick={() =>
                                          window.open(
                                            values.lead?.instagram,
                                            '_blank'
                                          )
                                        }
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                      />
                                    )}
                                  </Label>
                                  <Field
                                    name="lead.instagram"
                                    placeholder="Instagram URL"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>
                                    LinkedIn Profile URL
                                    {values.lead?.linkedInProfileURL && (
                                      <LinkIcon
                                        onClick={() =>
                                          window.open(
                                            values.lead?.linkedInProfileURL,
                                            '_blank'
                                          )
                                        }
                                        className="cursor-pointer ml-2 inline h-5 w-5"
                                        color="gray"
                                      />
                                    )}
                                  </Label>
                                  <Field
                                    name="lead.linkedInProfileURL"
                                    placeholder="LinkedIn Profile URL"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={
                                      !isEdit ||
                                      values.lead?.source !== 'LinkedIn'
                                    }
                                  />
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="col-span-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700 font-bold border-b">
                              <span>Business Information</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-6 w-6 text-gray-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                              <div className="sm:grid grid-cols-2 gap-4">
                                <div className="col-span-2">
                                  <Label>Decision Maker</Label>
                                  <Field
                                    name="decisionMaker"
                                    placeholder="Decision Maker"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Email Address</Label>
                                  <Field
                                    name="email"
                                    placeholder="Email Address"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Display Name</Label>
                                  <Field
                                    name="displayName"
                                    placeholder="Display Name"
                                    className="form-input disabled-white"
                                    onChange={(e) => handleChange(e)}
                                    type="text"
                                    disabled={!isEdit}
                                  />
                                  <ErrorMessage
                                    name="displayName"
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <Label>About Us</Label>
                                  <Field
                                    name="aboutUs"
                                    placeholder="About Us"
                                    as="textarea"
                                    rows={3}
                                    className="form-input disabled-white"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Goals/Pain Points</Label>
                                  <Field
                                    name="goals"
                                    placeholder="Goals/Pain Points"
                                    as="textarea"
                                    rows={3}
                                    className="form-input disabled-white"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>First Call Summary</Label>
                                  <Field
                                    name="painPoints"
                                    placeholder="First Call Summary"
                                    as="textarea"
                                    rows={3}
                                    className="form-input disabled-white"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Service Conditions for Ops</Label>
                                  <Field
                                    name="mainIssue"
                                    placeholder="Service Conditions for Ops"
                                    as="textarea"
                                    rows={3}
                                    className="form-input disabled-white"
                                    disabled={!isEdit}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <Label>Average Monthly Sales on Amazon</Label>
                                  <Field name="avgMonthlySalesOnAmazon">
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputPrepend
                                          type="number"
                                          placeholder="0"
                                          prependText="$"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          disabled={!isEdit}
                                          {...field}
                                        />
                                        <sub className="text-gray-400">
                                          Include decimals, no round offs
                                        </sub>
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div className="col-span-2">
                                  <Label>
                                    Average Monthly Sales Outside Amazon
                                  </Label>
                                  <Field name="avgMonthlySalesOutsideAmazon">
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputPrepend
                                          type="number"
                                          placeholder="0"
                                          prependText="$"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          disabled={!isEdit}
                                          {...field}
                                        />
                                        <sub className="text-gray-400">
                                          Include decimals, no round offs
                                        </sub>
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div className="col-span-2">
                                  <Label>PPC Spend</Label>
                                  <Field name="ppcSpend">
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputPrepend
                                          type="number"
                                          placeholder="0"
                                          prependText="$"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          disabled={!isEdit}
                                          {...field}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div className="col-span-2">
                                  <Label>Avg. ACOS (%)</Label>
                                  <Field name="avgACOS">
                                    {({
                                      field,
                                      form: { touched, errors },
                                      meta,
                                    }) => (
                                      <>
                                        <InputPrepend
                                          type="number"
                                          placeholder="0"
                                          border="border-white"
                                          classes="bg-gray-50 disabled-white"
                                          disabled={!isEdit}
                                          {...field}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    {userCan('sales.profiles.manage') && (
                      <div className="col-span-2 flex flex-row justify-between">
                        <div>
                          <Button
                            classes={classNames(
                              !isEdit ? 'opacity-30' : '',
                              'border-2 font-bold tracking-widest mt-4text-sm border-blue-900'
                            )}
                            bgColor="transparent"
                            hoverColor="bule-200"
                            roundedSize="full"
                            textColor="blue-900"
                            px={6}
                            py={2}
                            shadow=""
                            textSize=""
                            disabled={!isEdit}
                            onClick={() => setIsEdit(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            classes={classNames(
                              !isEdit ? 'opacity-30' : '',
                              'border-0 font-bold tracking-widest ml-2'
                            )}
                            type="submit"
                            bgColor="blue-900"
                            hoverColor="blue-500"
                            roundedSize="full"
                            textColor="white"
                            px={6}
                            py={2}
                            shadow=""
                            textSize=""
                            onClick={() => checkErrors(errors)}
                            disabled={!isEdit}
                            showLoading={true}
                            loading={loading}
                          >
                            Submit
                          </Button>
                        </div>
                        <div className="flex flex-col justify-center">
                          <Link
                            to={`/sales/client/${salesClientId}`}
                            rel="noopener noreferrer"
                          >
                            <p className="text-custom-sky text-base font-bold">
                              View Profile
                            </p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default BoardViewSlideOverForm;
