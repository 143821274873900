import classnames from 'classnames';
import classNames from 'utils/classNames';

const InvoiceSummary = ({ value }) => {
  return (
    <span
      className={classNames(
        classnames({
          'bg-#CFFFDD text-success-dark': value === 'PAID',
          'bg-error-light text-error-dark': value === 'UNPAID',
        }),
        'capitalize text-13 rounded-40 px-6 py-2'
      )}
    >
      {value}
    </span>
  );
};

export default InvoiceSummary;
