import { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import uimg from 'assets/icons/uimg.svg';
import axios from 'axios';
import ProgressBar from '@ramonak/react-progress-bar';
import classNames from 'utils/classNames';
import useImageLoad from 'hooks/useImageLoad';
import {
  ExclamationCircleIcon,
  CheckIcon,
  XIcon,
} from '@heroicons/react/solid';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import { useDispatch } from 'react-redux';
import { setAplusContent } from '../mockupSlice';
import usePermissions from 'hooks/usePermissions';

const AplusUpload = ({ item }) => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { alertError } = useAlert();
  const fileInputRef = useRef(null);
  const [filesUp, setFilesUp] = useState(null);
  const { onReloadSrc } = useImageLoad();
  const [isUploadOk, setIsUploadOk] = useState(true);
  const [isReloadingImage, setIsReloadingImage] = useState(false);
  const [progress, setProgress] = useState(0);

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onUpload = async () => {
    const file = filesUp[0];
    setProgress(0);
    setIsUploadOk(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('listingMockupId', item.listingMockupId);
      formData.append('agencyClientId', item.agencyClientId);
      const response = await axios.post(
        '/agency/clients/listing-mockup/aplus',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 50;
            setProgress(progress);
          },
          onDownloadProgress: (progressEvent) => {
            const progress =
              50 + (progressEvent.loaded / progressEvent.total) * 50;
            setProgress(progress);
          },
        }
      );

      setIsReloadingImage(true);
      setProgress(0);
      onClearFile();
      dispatch(setAplusContent(response.data.data));
    } catch (err) {
      setIsUploadOk(false);
      alertError('Upload Failed. Please retry', err.response.data?.message);
    }
  };

  const previewFiles = (files) => {
    setIsUploadOk(true);
    fileInputRef.current.files = files;

    setFilesUp(
      [...files].map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      previewFiles(event.target.files);
    }
  };

  const onClearFile = () => {
    fileInputRef.current.files = null;
    fileInputRef.current.value = null;
    setFilesUp(null);
    setIsUploadOk(true);
  };

  const onImgLoad = () => {
    setIsReloadingImage(false);
  };

  return (
    <div className="col-span-1 relative">
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept="image/*"
        disabled={
          !userCan('mockup.manage.media') || item.status === 'published'
        }
      />
      <FileDrop
        onTargetClick={onTargetClick}
        onDrop={(files, event) => previewFiles(files)}
        className={
          !userCan('mockup.manage.media') || item.status === 'published'
            ? 'pointer-events-none'
            : ''
        }
      >
        {filesUp && filesUp.length > 0 && (
          <>
            <button
              className="absolute right-0 -top-2 h-6 w-6 rounded-full text-white bg-red-600 hover:bg-red-400 text-center leading-6 cursor-pointer z-1"
              onClick={(e) => {
                e.stopPropagation();
                onClearFile();
              }}
              type="button"
            >
              <XIcon className="w-5 h-5 inline relative -top-0.5" />
            </button>
            <button
              className="absolute right-8 -top-2 h-6 w-6 shadow-lg rounded-full text-white bg-green-600 hover:bg-green-400 text-center leading-6 cursor-pointer z-1"
              onClick={(e) => {
                e.stopPropagation();
                onUpload();
              }}
              type="button"
            >
              <CheckIcon className="w-5 h-5 inline relative -top-0.5" />
            </button>
          </>
        )}
        <div className="text-center cursor-pointer h-auto bg-gray-100 rounded-sm flex items-center justify-center overflow-hidden relative">
          {filesUp && filesUp.length > 0 ? (
            <img
              key={filesUp[0].name}
              src={filesUp[0].preview}
              className="object-contain"
              onLoad={() => {
                URL.revokeObjectURL(filesUp[0].preview);
              }}
            />
          ) : item.aplus ? (
            <>
              {isReloadingImage && (
                <div className="absolute flex w-auto text-red-500 ">
                  <SpinnerGrow size={2} />
                  <SpinnerGrow size={2} />
                  <SpinnerGrow size={2} />
                </div>
              )}
              <img
                key={item.aplus.thumbUrl}
                src={item.aplus.url}
                alt={item.aplus.filename}
                className="w-full h-full object-cover"
                onLoad={onImgLoad}
                onError={(e) => {
                  onReloadSrc(e, item.aplus.thumbUrl);
                }}
              />
            </>
          ) : (
            <img src={uimg} className="py-12" />
          )}
          {!isUploadOk && (
            <ExclamationCircleIcon className="absolute w-8 h-8 text-red-500 bg-white rounded-full" />
          )}
          <div
            className={classNames(
              progress <= 0 ? 'invisible' : '',
              'absolute w-full bottom-0'
            )}
          >
            <ProgressBar
              completed={progress}
              bgColor="#75e862"
              height="5px"
              borderRadius="0"
              isLabelVisible={false}
              baseBgColor="#e0dede"
              labelColor="#e80909"
              transitionTimingFunction="linear"
              animateOnRender
            />
          </div>
        </div>
      </FileDrop>
    </div>
  );
};
export default AplusUpload;
