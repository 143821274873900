import { useFormikContext } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { Field, ErrorMessage, FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import InputAppend from 'components/Forms/InputAppend';
import Button from 'components/Button';
import { PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/outline';
import { COMMISSION_TYPES, TYPE_DESCRIPTIONS } from 'utils/constants';
import Toggle from 'components/Forms/Toggle';
import classNames from 'utils/classNames';
import { currencyFormatter } from 'utils/formatters';
import { useState } from 'react';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';

const CommissionFormPartial = () => {
  const { setFieldValue, handleChange, values, errors } = useFormikContext();
  const { addons } = useSelector((state) => state.zoho);

  const addonObj = {
    name: '',
    addon_description: '',
    addon_code: '',
    price: 0,
    quantity: 1,
    type: 'one_time',
    action: 'add',
  };

  // const [addonModalPayload, setAddonModalPayload] = useState(addonObj);
  const [openAddonModal, setOpenAddonModal] = useState(false);

  const managedAsinObj = {
    asin: '',
    baseline: '',
    startDate: null,
    endDate: null,
  };

  const tieredRuleObj = {
    min: '',
    max: '',
    rate: '',
  };

  const onAddonChange = (index, value, setFieldValue) => {
    const addon = addons.find((a) => a.addon_code === value);

    if (addon) {
      setFieldValue(`addons.${index}.addon_code`, value);
      setFieldValue(`addons.${index}.type`, addon.type);
      setFieldValue(`addons.${index}.name`, addon.name);
      setFieldValue(`addons.${index}.addon_description`, addon.description);
      setFieldValue(`addons.${index}.price`, addon.price_brackets[0].price);
    } else {
      setFieldValue(`addons.${index}.addon_code`, '');
      setFieldValue(`addons.${index}.type`, 'one_time');
      setFieldValue(`addons.${index}.name`, '');
      setFieldValue(`addons.${index}.addon_description`, '');
      setFieldValue(`addons.${index}.price`, 0);
    }
  };

  const onAddAddon = (setFieldValue, values) => {
    let newAddonsValue = [...values.addons];
    if (values.addonTemp.addon_code) {
      if (values.addonTemp.action === 'update') {
        let valuesTemp = { ...values.addonTemp };
        const { index } = valuesTemp;
        delete valuesTemp.index;
        delete valuesTemp.action;
        newAddonsValue[index] = valuesTemp;
      } else {
        let valuesTemp = { ...values.addonTemp };
        delete valuesTemp.index;
        delete valuesTemp.action;
        newAddonsValue.push(valuesTemp);
      }
      setFieldValue('addons', newAddonsValue);
      setFieldValue('addonTemp', addonObj);
      setOpenAddonModal(false);
    } else {
      alert('Select Addon');
    }
  };

  const onUpdateAddon = (val, index, setFieldValue, e) => {
    e.preventDefault();
    setFieldValue('addonTemp', { ...val, index, action: 'update' });
    setOpenAddonModal(true);
  };

  const onRemoveAddon = (setFieldValue, values) => {
    let newAddonsValue = [...values.addons];
    let valuesTemp = { ...values.addonTemp };
    const { index } = valuesTemp;

    if (index > -1) {
      newAddonsValue.splice(index, 1);
    }

    setFieldValue('addons', newAddonsValue);
    setFieldValue('addonTemp', addonObj);
    setOpenAddonModal(false);
  };

  return (
    <div className="sm:grid sm:grid-cols-2 space-y-3 mt-2 border-b pb-5">
      <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
        <span className="">
          Add Commission
          <span
            className={classNames(
              values.noCommission ? 'bg-yellow-100' : 'bg-green-100',
              'text-xs uppercase ml-1 p-1 leading-none rounded-md text-gray-700'
            )}
          >
            {values.noCommission ? 'No' : 'Yes'}
          </span>
        </span>
        <Toggle
          onChange={(e) => setFieldValue('noCommission', !e)}
          checked={!values.noCommission}
        />
      </div>
      {!values.noCommission ? (
        <>
          <div className="col-span-2 ">
            <Label>Type</Label>
            <Field
              name="commissionType"
              as="select"
              className="form-select disabled-white"
              onChange={(e) => {
                handleChange(e);
              }}
            >
              {COMMISSION_TYPES.map((commission_type, i) => (
                <option key={i} value={commission_type.value}>
                  {commission_type.label}
                </option>
              ))}
            </Field>
            <sub className="text-xs indent-2 text-yellow-500 italic relative -top-1">
              {TYPE_DESCRIPTIONS[values.commissionType]}
            </sub>
            <ErrorMessage
              name="commissionType"
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          </div>
          {values.commissionType !== 'tiered' && (
            <div className="col-span-2">
              <Label>Rate</Label>
              <Field name="rate">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <InputAppend
                      type="number"
                      placeholder="0"
                      appendText="%"
                      border="border-white"
                      classes="bg-gray-50 disabled-white"
                      {...field}
                    />
                  </>
                )}
              </Field>
              <ErrorMessage
                name="rate"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
          )}
          {(values.commissionType === 'benchmark' ||
            values.commissionType === 'rolling') && (
            <div className="col-span-2">
              <Label>Month Threshold</Label>
              <Field
                name="monthThreshhold"
                placeholder="No. of months"
                className="form-input disabled-white"
                onChange={(e) => handleChange(e)}
                type="number"
              />
              <ErrorMessage
                name="monthThreshhold"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
          )}
          {values.commissionType === 'benchmark' &&
            values.managedAsins.length < 1 && (
              <div className="col-span-2">
                <Label>Baseline</Label>
                <Field
                  name="baseline"
                  placeholder="Enter Baseline Avg. Sales"
                  className="form-input disabled-white"
                  onChange={(e) => handleChange(e)}
                  type="number"
                />
                <ErrorMessage
                  name="baseline"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            )}
          <div className="col-span-2 text-sm text-gray-500 flex items-center justify-between">
            <span className="">Automatically add to pending invoice</span>
            <Toggle
              onChange={(e) => setFieldValue('autoAdd', e)}
              checked={values.autoAdd}
            />
          </div>
          {values.commissionType === 'tiered' && (
            <FieldArray name="rules">
              {({ insert, remove, push }) => (
                <>
                  <div className="col-span-2">
                    <hr className="my-2" />
                  </div>

                  {values.rules.length > 0 && (
                    <div className="col-span-2 space-y-3 mb-4">
                      <span className="font-inter font-bold text-base">
                        Rules
                      </span>
                      {values.rules.map((rule, index) => (
                        <div key={index} className="sm:grid grid-cols-3 gap-2">
                          <div className="col-span-3 flex justify-between">
                            <Label
                              fontWeight="font-bold"
                              textColor="text-gray-500"
                            >
                              Rule {index + 1}
                            </Label>
                            <span
                              onClick={() => remove(index)}
                              className="cursor-pointer text-gray-500 hover:text-red-500"
                            >
                              <TrashIcon className="w-4 h-4 " />
                            </span>
                          </div>
                          <div className="col-span-1">
                            <Label>Min. Gross</Label>
                            <Field
                              name={`rules.${index}.min`}
                              placeholder="Enter Min. Gross"
                              className="form-input disabled-white"
                              onChange={(e) => handleChange(e)}
                              type="number"
                            />
                            <ErrorMessage
                              name={`rules.${index}.min`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-1">
                            <Label>Max. Gross</Label>
                            <Field
                              name={`rules.${index}.max`}
                              placeholder="Enter Max. Gross"
                              className="form-input disabled-white"
                              onChange={(e) => handleChange(e)}
                              type="number"
                            />
                            <ErrorMessage
                              name={`rules.${index}.max`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-1">
                            <Label>Rate</Label>
                            <Field name={`rules.${index}.rate`}>
                              {({ field, form: { touched, errors }, meta }) => (
                                <>
                                  <InputAppend
                                    type="number"
                                    placeholder="0"
                                    appendText="%"
                                    border="border-white"
                                    classes="bg-gray-50 disabled-white"
                                    {...field}
                                  />
                                </>
                              )}
                            </Field>
                            <ErrorMessage
                              name={`rules.${index}.rate`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="col-span-2">
                    <Button
                      classes="font-bold tracking-wider w-full justify-center"
                      bgColor="gray-50"
                      hoverColor="gray-200"
                      roundedSize="2xl"
                      textColor="gray-600"
                      border="border-2 border-gray-600"
                      py={1}
                      shadow=""
                      onClick={(e) => {
                        push(tieredRuleObj);
                      }}
                    >
                      <PlusIcon className="w-4 h-4 inline" /> Add Rule
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          )}
          {values.commissionType === 'tiered' && values.rules.length < 1 && (
            <ErrorMessage
              name={`rules`}
              component="div"
              className="text-red-700 font-normal text-xs"
            />
          )}

          <div className="col-span-2">
            <hr className="my-2" />
          </div>

          {/* <FieldArray name="managedAsins">
            {({ insert, remove, push }) => (
              <>
                {values.managedAsins.length > 0 && (
                  <div className="col-span-2 space-y-3 mb-4">
                    <span className="font-inter font-bold text-base">
                      Managed ASIN
                    </span>
                    {values.managedAsins.map((managedAsin, index) => (
                      <div key={index} className="sm:grid grid-cols-3 gap-x-2 ">
                        <div className="col-span-3 flex justify-between">
                          <Label
                            fontWeight="font-bold"
                            textColor="text-gray-500"
                          >
                            ASIN {index + 1}
                          </Label>

                          <span
                            onClick={() => {
                              remove(index);
                            }}
                            className="cursor-pointer text-gray-500 hover:text-red-500"
                          >
                            <TrashIcon className="w-4 h-4 " />
                          </span>
                        </div>
                        <div className="col-span-3">
                          {values.commissionType === 'benchmark' && (
                            <Label>ASIN</Label>
                          )}
                          <Field
                            name={`managedAsins.${index}.asin`}
                            placeholder="Enter ASIN"
                            className="form-input disabled-white"
                            onChange={(e) => handleChange(e)}
                            type="text"
                          />
                          <ErrorMessage
                            name={`managedAsins.${index}.asin`}
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        </div>
                        {values.commissionType === 'benchmark' && (
                          <>
                            <div className="col-span-1">
                              <Label>Start Date</Label>
                              <Field
                                name={`managedAsins.${index}.startDate`}
                                placeholder="Select Date"
                                className="form-input disabled-white"
                                onChange={(e) => handleChange(e)}
                                type="date"
                              />
                              <ErrorMessage
                                name={`managedAsins.${index}.startDate`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                            <div className="col-span-1">
                              <Label>End Date</Label>
                              <Field
                                name={`managedAsins.${index}.endDate`}
                                placeholder="Select Date"
                                className="form-input disabled-white"
                                onChange={(e) => handleChange(e)}
                                type="date"
                              />
                              <ErrorMessage
                                name={`managedAsins.${index}.endDate`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                            <div className="col-span-1">
                              <Label>Baseline</Label>
                              <Field
                                name={`managedAsins.${index}.baseline`}
                                placeholder="Baseline Avg. Sales"
                                className="form-input disabled-white"
                                onChange={(e) => handleChange(e)}
                                type="text"
                              />
                              <ErrorMessage
                                name={`managedAsins.${index}.baseline`}
                                component="div"
                                className="text-red-700 font-normal text-xs"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <div className="col-span-2">
                  <Button
                    classes="font-bold tracking-wider w-full justify-center"
                    bgColor="gray-50"
                    hoverColor="gray-200"
                    roundedSize="2xl"
                    textColor="gray-600"
                    border="border-2 border-gray-600"
                    py={1}
                    shadow=""
                    onClick={(e) => {
                      push(managedAsinObj);
                    }}
                  >
                    <PlusIcon className="w-4 h-4 inline" /> Add Managed Asin
                  </Button>
                </div>
              </>
            )}
          </FieldArray> */}
        </>
      ) : (
        <div className="col-span-2">
          <Label htmlFor="noCommissionReason">
            Reason <RequiredAsterisk />
          </Label>
          <Field
            name="noCommissionReason"
            as="textarea"
            rows={3}
            className="form-input disabled-white"
          />
          <ErrorMessage
            name="noCommissionReason"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      )}

      <div className="col-span-2">
        <FieldArray name="addons">
          {({ insert, remove, push }) => (
            <>
              <div className="col-span-2 mb-4 flex justify-between py-2">
                <Label
                  htmlFor="addons"
                  textSize="lg"
                  fontWeight="font-bold"
                  textColor="text-black"
                >
                  Addons
                </Label>
                <Button
                  classes="font-bold tracking-wider justify-center"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="2xl"
                  textColor="gray-600"
                  border="border-2 border-gray-600"
                  py={1}
                  shadow=""
                  onClick={() => {
                    setOpenAddonModal(true);
                  }}
                >
                  <PlusIcon className="w-4 h-4 inline" /> Create Addons
                </Button>
              </div>
              <div className="grid grid-cols-12 gap-4">
                {values.addons?.length > 0 &&
                  values.addons.map((addon, index) => (
                    <div
                      className="col-span-4 border p-2 hover:shadow-lg"
                      key={`addon-${index}`}
                    >
                      <div className="grid grid-cols-12 gap-2 mb-3">
                        <div className="col-span-12 text-sm flex justify-between">
                          <span className="font-bold text-gray-600">
                            Addon {index + 1}
                          </span>
                          <button
                            onClick={(e) => {
                              // setAddonModalPayload(values.addons[index]);
                              onUpdateAddon(
                                values.addons[index],
                                index,
                                setFieldValue,
                                e
                              );
                            }}
                            className="text-gray-500 hover:text-red-700"
                          >
                            <PencilIcon className="w-4 h-4" />
                          </button>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <label className="text-xs text-gray-400">
                            Amount
                          </label>
                          <span className="font-bold text-green-500">
                            {currencyFormatter(
                              values.addons[index].price *
                                values.addons[index].quantity,
                              values.currency_code
                            )}
                          </span>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <label className="text-xs text-gray-400">Addon</label>
                          <span className="font-bold text-sm">
                            {
                              addons?.find(
                                (addon) =>
                                  values.addons[index].addon_code ===
                                  addon.addon_code
                              ).name
                            }
                          </span>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <label className="text-xs text-gray-400">Type</label>
                          <span className="font-bold text-sm capitalize">
                            {values.addons[index].type}
                          </span>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <label className="text-xs text-gray-400">Qty</label>
                          <span className="font-bold text-sm capitalize">
                            {values.addons[index].quantity}
                          </span>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <label className="text-xs text-gray-400">Price</label>
                          <span className="font-bold text-sm capitalize">
                            {currencyFormatter(
                              values.addons[index].price,
                              values.addons[index].currency_code
                            )}
                          </span>
                        </div>

                        <div className="col-span-12 flex justify-between pt-1">
                          <span className="text-sm">
                            {values.addons[index].addon_description}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </FieldArray>
      </div>

      <Modal
        open={openAddonModal}
        setOpen={setOpenAddonModal}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        zIndex="z-10"
        persistent={true}
      >
        <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
          <ModalHeader
            title={
              <div className="flex items-center space-x-4">
                <span>Addon</span>
              </div>
            }
            rightContent={
              values?.addonTemp?.action === 'add' ? (
                ''
              ) : (
                <button
                  className="relative group mr-4"
                  onClick={() => onRemoveAddon(setFieldValue, values)}
                >
                  <TrashIcon className="w-5 h-5 transform  text-gray-400 hover:text-red-500" />
                </button>
              )
            }
            setOpen={setOpenAddonModal}
            titleClasses="capitalize"
            border=""
            fontSize="text-xl"
            fontStyle="font-bold"
            px="px-4 md:px-8"
            py="py-4 md:py-8"
          />

          <div className="grid grid-cols-12 gap-4 p-4">
            <div className="col-span-12 text-sm">
              <Label>Addon</Label>
              <Field
                name={`addonTemp.addon_code`}
                as="select"
                className="form-select disabled-white"
              >
                <option value="">Select One</option>
                {addons?.map((addon) => (
                  <option key={addon.addon_code} value={addon.addon_code}>
                    {addon.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name={`addonTemp.addon_code`}
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
            <div className="col-span-12 text-sm">
              <Label htmlFor="type">Type</Label>
              <div className="pl-3 py-2 capitalize">
                {values?.addonTemp?.type}
              </div>
            </div>
            <div className="col-span-12 text-sm">
              <Label>Qty</Label>
              <Field
                name={`addonTemp.quantity`}
                className="form-input disabled-white"
                type="number"
                step="1"
              />
              <ErrorMessage
                name={`addonTemp.quantity`}
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
            <div className="col-span-12 text-sm">
              <Label>Price</Label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <Field
                  name={`addonTemp.price`}
                  className="form-input  pl-7 r-12"
                  type="number"
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <Label htmlFor="currency_code" classes="sr-only">
                    Currency
                  </Label>
                  <span className="pr-4 text-gray-500 sm:text-sm rounded-md">
                    {values.currency_code}
                  </span>
                </div>
              </div>
              <ErrorMessage
                name={`addonTemp.price`}
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
            <div className="col-span-6 lg:col-span-1 text-sm text-right flex flex-col justify-start">
              <Label>Total</Label>
              <span className="py-2 text-green-500 font-bold ">
                {currencyFormatter(
                  values.addonTemp.price * values.addonTemp.quantity,
                  values.currency_code
                )}
              </span>
            </div>
            <div className="col-span-12">
              <Field
                name={`addonTemp.addon_description`}
                as="textarea"
                rows={2}
                className="form-input"
                maxLength={2000}
              />
              <div className="flex justify-between">
                <div className="flex">
                  &nbsp;
                  <ErrorMessage
                    name={`addonTemp.addon_description`}
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div
                  className={`text-right italic text-xs mt-1  ${
                    values.addonTemp.addon_description?.length === 2000
                      ? 'text-red-700'
                      : ''
                  }`}
                >
                  {values.addonTemp.addon_description?.length}/
                  <span className="font-medium">2000</span> characters
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2  m-8 flex justify-between items-center">
            <div className="space-x-4">
              <button
                className="border-white font-bold text-blue-700"
                onClick={() => {
                  // setAddonModalPayload(values.addons[index]);
                  setFieldValue('addonTemp', addonObj);
                  setOpenAddonModal(false);
                }}
              >
                Cancel
              </button>

              <Button
                classes="border-0 font-bold tracking-wider "
                bgColor="blue-700"
                hoverColor="blue-400"
                roundedSize="2xl"
                textColor="white"
                px={12}
                py={1.5}
                shadow=""
                // type="submit"
                // showLoading={true}
                // loading={saving}
                onClick={() => onAddAddon(setFieldValue, values)}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CommissionFormPartial;

