import axios from 'axios';
import { range } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

const RecentActivity = ({ startDate, endDate }) => {
  const [loading, setLoading] = useState(false);
  const [screenshots, setScreenshots] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;

    const fetchData = async () => {
      const timeSlotStartDate = moment(endDate).isAfter(
        moment(startDate).add(1, 'week')
      )
        ? moment
            .tz(endDate, 'America/New_York')
            .subtract(1, 'week')
            .startOf('D')
            .format()
        : moment.tz(startDate, 'America/New_York').startOf('D').format();

      console.log(timeSlotStartDate);
      const response = await axios.get('/hubstaff/screenshots', {
        params: {
          pageSize: 6,
          timeSlotStartDate,
          timeSlotEndDate: moment
            .tz(endDate, 'America/New_York')
            .endOf('D')
            .format(),
        },
      });

      if (isSubscribed) {
        setScreenshots(response.data.data.rows);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [startDate, endDate]);

  return (
    <div className="bg-white rounded-xl p-6">
      <p className="font-inter font-bold leading-1.2 tracking-3/4 text-grayscale-900 mb-5">
        Recent Activity
      </p>

      {isViewerOpen && !!screenshots.length && (
        <ImageViewer
          src={screenshots.map((s) => s.full_url)}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ zIndex: 9999 }}
        />
      )}

      <div className="grid grid-cols-3 gap-4 h-72 md:h-60">
        {loading
          ? range(0, 6).map((i) => (
              <div key={i} className="animate-pulse">
                <div className="w-full bg-gray-200 rounded h-full"></div>
              </div>
            ))
          : screenshots.map((screenshot, index) => (
              <div className="w-full" key={screenshot.id}>
                <img
                  src={screenshot.thumb_url}
                  onClick={() => openImageViewer(index)}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default RecentActivity;
