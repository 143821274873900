import axios from 'axios';
import moment from 'moment';
import { startCase } from 'lodash';
import prependHttp from 'prepend-http';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Table } from 'components';

const Reports = () => {
  const { account, marketplace } = useSelector((state) => state.account);
  const [reports, setReports] = useState({ rows: [] });

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    sort: 'createdAt',
    include: ['generatedBy'],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/account/advertising/reports', {
        params: {
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          ...params,
        },
      });

      if (isSubscribed) {
        setReports(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, params]);

  const columns = [
    {
      dataField: 'type',
      text: 'Type',
      formatter: (value) => startCase(value),
      classes:
        'py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap',
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      sort: true,
      classes:
        'py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap',
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      sort: true,
      classes:
        'py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap',
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (value) => {
        let color = 'green';

        switch (value) {
          case 'pending':
          case 'processing':
            color = 'yellow';
            break;
          case 'failed':
            color = 'red';
            break;
          default:
            color = 'green';
            break;
        }

        return (
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color}-100 text-${color}-800`}
          >
            {value}
          </span>
        );
      },
    },
    {
      dataField: 'generatedBy',
      text: 'Generated By',
      formatter: (user) =>
        user ? `${user.firstName} ${user.lastName}` : 'Unknown',
      classes:
        'py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap',
    },
    {
      dataField: 'createdAt',
      text: 'Generated At',
      formatter: (value) => moment(value).format('MMMM Do YYYY, h:mm:ss a'),
      classes:
        'py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap',
    },
    {
      dataField: 'downloadUrl',
      text: 'Action',
      formatter: (value, row) => {
        if (row.status !== 'completed') {
          return null;
        }

        if (row.downloadUrl) {
          const download = () => {
            axios.get(`/s3/files?path=${value}`).then((respons) => {
              const { success, data } = respons.data;
              if (success) {
                window.open(prependHttp(data, { https: false }));
              }
            });
          };

          return (
            <button
              className="text-xs py-1 px-4 rounded font-medium text-white bg-red-600"
              onClick={download}
            >
              Download
            </button>
          );
        }

        return (
          <a
            className="text-xs py-1 px-4 rounded font-medium text-white bg-red-600"
            target="_blank"
            rel="noreferrer"
            href={`/reports-generator/advertising-analytics/${row.advReportId}`}
          >
            View
          </a>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }
    setParams(newParams);
  };

  return (
    <Table
      data={reports}
      params={params}
      columns={columns}
      keyField="advReportId"
      onTableChange={onTableChange}
      defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
    />
  );
};

export default Reports;
