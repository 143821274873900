import { upperFirst } from 'lodash';
import classNames from 'utils/classNames';
import metrics from 'features/clients/Client/Account/Advertising/utils/metrics';
import PercentageChange from 'features/clients/Client/Account/Advertising/components/PercentageChange';

const CampaignTypesOverview = ({ campaignTypes }) => {
  return (
    <div className="mt-10 grid grid-cols-3">
      {campaignTypes.map((campaignType) => {
        return (
          <div key={`camapaign-types-overview-${campaignType.title}`}>
            <div>
              <p className="text-grayscale-800 leading-1.5 text-tiny font-bold text-center">
                {campaignType.title}
              </p>
            </div>

            <div className="grid grid-cols-3 mt-5">
              {['sales', 'cost', 'acos']
                .map((attribute) => metrics.find((m) => m.key === attribute))
                .map((metric, index) => {
                  return (
                    <div
                      key={`camapaign-types-overview-${campaignType.title}-${metric.key}`}
                      className={classNames(
                        index === 1
                          ? 'border-r border-l border-grayscale-500'
                          : ''
                      )}
                    >
                      <p className="text-grayscale-800 text-8 leading-1.5 text-center">
                        {metric.title}
                      </p>

                      <div className="flex justify-center items-center mt-1 mb-2">
                        {metric.prefix && (
                          <p className="leading-1.2 text-grayscale-600 tracking-3/4 mr-0.5 text-8">
                            {metric.prefix}
                          </p>
                        )}
                        <p className="font-bold font-inter text-10 leading-1.2 text-grayscale-900 tracking-3/4">
                          {metric.formatter(campaignType[metric.key])}
                        </p>
                        {metric.suffix && (
                          <p className="leading-1.2 text-grayscale-600 tracking-3/4 ml-0.5 text-8">
                            {metric.suffix}
                          </p>
                        )}
                      </div>

                      <PercentageChange
                        textSize={6}
                        iconSize={2}
                        currentValue={campaignType[metric.key]}
                        previousValue={
                          campaignType[`previous${upperFirst(metric.key)}`]
                        }
                        reverse={!!metric.reverse}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignTypesOverview;
