import { PencilIcon } from '@heroicons/react/outline';
import { COMMISSION_TYPES } from 'utils/constants';

const CommissionBlock = ({ values, setOpen, page }) => {
  return values.noCommission ? (
    <div>
      <span>No Commission</span>
      <div className="flex flex-col text-sm text-grayscale-700">
        <p className="">{values.noCommissionReason}</p>
      </div>
    </div>
  ) : (
    <div className="space-y-6">
      {values.commissionType === 'tiered' ? (
        <div className="space-y-8 ">
          <h3>Tiered Commission</h3>
          <div className="w-full flex justify-around bg-gray-100 py-4 rounded-t-lg">
            <span>Rule</span> <span>Max</span>
            <span>Min</span>
            <span>Rate</span>
            {page === 'deals' && <span>Action</span>}
          </div>
          {values.rules &&
            values.rules.map((item, index) => {
              return (
                <div key={index} className="w-full flex justify-around">
                  <span>{index + 1}</span>
                  <span>{item.max}</span>
                  <span>{item.min}</span>
                  <span>{item.rate}</span>
                  {page === 'deals' && (
                    <span onClick={() => setOpen(true)}>
                      <PencilIcon className="h-6 w-6 text-blue-900" />
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-4 space-x-8 px-4 py-4 items-center bg-gray-50 text-left text-1sm font-bold text-grayscale-600">
            <span className="col-span-1">Type</span>
            <span className="col-span-1">Rate</span>
            <span className="col-span-1">Month Threshold</span>
            {page === 'deals' && (
              <span className="col-span-1 text-right">Action</span>
            )}
          </div>
          <div className="grid grid-cols-4 hover:bg-gray-50 px-4 space-x-8 items-center">
            <span className="col-span-1 py-3 text-gray-500 leading-none">
              {
                COMMISSION_TYPES.find((a) => a.value === values.commissionType)
                  .label
              }
            </span>
            <span className="col-span-1 text-gray-500">{values.rate}</span>
            <span className="col-span-1 text-gray-500">
              {values.monthThreshhold}
            </span>
            {page === 'deals' && (
              <button
                type="button"
                className="col-span-1 text-gray-500 hover:text-red-700 text-right"
                onClick={() => setOpen(true)}
              >
                <PencilIcon className="h-6 w-6 text-grayscale-700 inline" />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CommissionBlock;
