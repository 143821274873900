import Badge from 'components/Badge';
import classnames from 'classnames';
import { strUnderscoreToSpace } from 'utils/formatters';

const InvoiceStatusBadge = ({ status }) => {
  return (
    <Badge
      color={classnames({
        green: status === 'paid',
        yellow: status === 'pending',
        red: status === 'overdue',
        blue: status === 'sent',
        purple: status === 'partially_paid',
        gray: status === 'void',
        orange: status === 'draft',
      })}
      classes="capitalize px-6 w-40 py-2 justify-center"
      rounded="full"
    >
      {strUnderscoreToSpace(status)}
    </Badge>
  );
};
export default InvoiceStatusBadge;
