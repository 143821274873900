import ModalHeader from 'components/ModalHeader';
import { XIcon } from '@heroicons/react/outline';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, number, string } from 'yup';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import axios from 'axios';
import { Modal } from 'components';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';

export default function DenyModal({
  open,
  setOpen,
  upsellData,
  getUpsells,
  setReviewModalOpen,
}) {
  const { alertSuccess, alertError } = useAlert();

  const initialValues = {
    reason: '',
  };

  const onClose = () => {
    setOpen(false);
  };

  const validationSchema = object().shape({
    reason: string().required('Required'),
  });

  const onSubmit = async (values) => {
    await axios
      .patch(`/agency/upsell/${upsellData.details.upsellId}/deny`, {
        reason: values.reason,
      })
      .then((response) => {
        alertSuccess('Upsell denied', response.data.message);
        onClose();
        setReviewModalOpen(false);
        getUpsells();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      });
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-30"
      >
        <div className="inline-block w-full max-w-xl my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <ModalHeader
            title={
              'Let the account manager know why you rejected the upsell request'
            }
            setOpen={setOpen}
            border="border-0"
            px="px-12"
            py="pt-12 pb-6"
            fontSize="text-2xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={onClose}
            showCloseButton={false}
            titleClasses="leading-tight"
          />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-12 gap-4 px-12">
                  <div className="col-span-12">
                    <div>
                      <Label textSize="xs">
                        Reason for rejection <RequiredAsterisk sup={false} />
                      </Label>
                      <Field
                        name={`reason`}
                        placeholder="Share a reply"
                        as="textarea"
                        rows={4}
                        className="form-select text-sm"
                      />
                      <ErrorMessage
                        name={`reason`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between px-12 py-10">
                  <button
                    onClick={() => {
                      setFieldValue('reason', '');
                      onClose();
                    }}
                    className="text-grayscale-800 hover:text-grayscale-900 font-bold text-sm tracking-2"
                  >
                    Cancel
                  </button>
                  <Button
                    classes="border-0 font-bold tracking-2"
                    bgColor="secondary"
                    hoverColor="secondary-light"
                    roundedSize="full"
                    textColor="white"
                    px={10}
                    py={2}
                    shadow=""
                    textSize="sm"
                    showLoading={true}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
