import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrashIcon } from '@heroicons/react/outline';

import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  withRouter,
  useHistory,
} from 'react-router-dom';

import useBodyClass from 'hooks/useBodyClass';
import usePermissions from 'hooks/usePermissions';
import { setAlert } from 'features/alerts/alertsSlice';

import Button from 'components/Button';
import Loading from 'components/Loading';
import Label from 'components/Forms/Label';
import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';
import UsersPopover from 'components/UsersPopover';
import UpdatesToggle from 'components/Updates/UpdatesToggle';

import Tasks from './Tasks';
import Files from './Files';
import Updates from './Updates';
import Profile from './Profile';
import Proposal from './Proposal';
import Notes from './Notes';
import InvoiceHistory from 'features/clients/Client/Billing/InvoiceHistory';

import {
  setUpdates,
  fetchClient,
  setOlderUpdates,
  setClientStatus,
  getLatestUpdates,
  setShowUpdatesSection,
} from './salesSlice';
import { fetchClient as fetchAgencyClient } from 'features/clients/Client/agencyClientSlice';

import classNames from 'utils/classNames';
import { STATUSES, STATUSES_PROSPECT } from './utils/constants';
import useClientAssignees from 'features/clients/Client/useClientAssignees';
import Quotes from './Quote/Quotes';

const Client = ({ location }) => {
  useBodyClass('client-profile');

  const history = useHistory();
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { salesClientId } = useParams();
  const { path, url } = useRouteMatch();
  const { pathname } = location;
  const { SALES_REPRESENTATIVE_ASSIGNEE } = useClientAssignees();

  const { showUpdatesSection } = useSelector((state) => state.sales);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [isreadyToOps, setIsReadyToOps] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [leadReps, setLeadReps] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [salesAdmins, setSalesAdmins] = useState([]);
  const [data, setData] = useState({
    status: 'booked',
    leadRepId: '',
    salesRepId: '',
  });

  const [companyName, setCompanyName] = useState('');
  const { client } = useSelector((state) => state.sales);

  useEffect(async () => {
    if (salesClientId !== 'add') {
      setLoading(true);
      await dispatch(fetchClient(salesClientId)).then(async (res) => {
        if (
          res.payload?.AgencyClient?.account?.acknowledgement?.signedDate ||
          ['registered', 'invited', 'subscribed'].includes(
            res.payload?.AgencyClient?.status
          )
        ) {
          setIsReadyToOps(true);
        }
        await dispatch(
          fetchAgencyClient(res.payload?.AgencyClient?.agencyClientId)
        );
      });
      setLoading(false);
      dispatch(setUpdates({ rows: [] }));
      dispatch(setOlderUpdates({ nextPage: 1, rows: [] }));
    }

    // Get salesAdmin reps
    await axios
      .get(
        `/agency/sales/clients/reps?roles=Sales Administrator,Sales Admin,Sales Admin Team Lead,Sales Administrator Team Lead`
      )
      .then((res) => {
        setSalesAdmins(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get sales reps
    await axios
      .get('/v2/users', {
        params: {
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value: SALES_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                    (role) => role.value
                  ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        setSalesReps(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get Lead reps
    await axios
      .get('/users', {
        params: {
          pageSize: 1000,
          permissionAccess: 'clients.assignees.as.lead_representative',
        },
      })
      .then((res) => {
        setLeadReps(res.data.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [salesClientId, refresh]);

  useEffect(() => {
    if (salesClientId !== 'add') {
      setData({
        status: client ? client.status : 'booked',
        leadRepId: client ? client.leadRepId : '',
        salesRepId: client ? client.salesRepId : '',
        salesAdminId: client ? client.salesAdminId : '',
      });
      setCompanyName(
        client && client.lead && client.lead.companyName
          ? client.lead.companyName
          : ''
      );
    }
  }, [client]);

  const checkClient = () => {
    if (client && client.decisionMaker && client.email) {
      return true;
    }
    return false;
  };

  const onCreate = async () => {
    if (companyName) {
      try {
        const response = await axios.put(
          `/agency/sales/clients/${salesClientId}`,
          {
            ...data,
            companyName,
          }
        );
        if (response.data && response.data.data && response.data.data.newId) {
          dispatch(setAlert('success', 'Created Successfully'));
          history.push(`/sales/client/${response.data.data.newId}`);
        }
      } catch (error) {
        dispatch(setAlert('error', 'Client Name', 'Already Exist!'));
      }
    } else {
      dispatch(setAlert('error', 'Client Name', 'Client Name is Required!'));
    }
  };

  //check required fields when change status to Proposal start
  const checkRequiredFields = (val) => {
    let checkError = false;
    if (val.lead.lead === null || val.lead.lead === '') {
      dispatch(setAlert('error', 'Lead first name is required.'));
      checkError = true;
    }
    if (val.lead.leadLastName === null || val.lead.leadLastName === '') {
      dispatch(setAlert('error', 'Lead last name is required.'));
      checkError = true;
    }
    if (val.lead.email === null || val.lead.email === '') {
      dispatch(setAlert('error', 'Contact Details email is required.'));
      checkError = true;
    }
    if (val.decisionMakerl === null || val.decisionMaker === '') {
      dispatch(setAlert('error', 'Decision maker is required.'));
      checkError = true;
    }
    if (val.email === null || val.email === '') {
      dispatch(setAlert('error', 'Business information email is required.'));
      checkError = true;
    }
    return checkError;
  };
  //check required fields when change status to Proposal end

  const onUpdate = async (val, prop) => {
    setLoading(true);
    if (val === 'Proposal') {
      const isError = checkRequiredFields(client);
      if (isError) {
        return;
      }
    }

    setData({
      ...data,
      [prop]: val,
    });

    try {
      const response = await axios.put(
        `/agency/sales/clients/${salesClientId}`,
        {
          // ...data,
          [prop]: val,
        }
      );
      if (response.data.success) {
        dispatch(getLatestUpdates(salesClientId));
        if (prop === 'status') {
          dispatch(setClientStatus(val));
        }
        dispatch(setAlert('success', 'Updated Successfully'));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onConvert = async () => {
    await axios
      .post(`/agency/sales/clients/${salesClientId}/convert`)
      .then((res) => {
        dispatch(setAlert('success', res.data.message));
        setRefresh(!refresh);
      })
      .catch((error) => {
        dispatch(setAlert('error', error.response.data.message));
      });
  };

  const profileTabs = [
    { tab: 'profile', url: url, visible: true },
    {
      tab: 'files',
      url: `${url}/files`,
      visible: salesClientId !== 'add' && userCan('sales.profiles.files.list'),
    },
    {
      tab: 'tasks',
      url: `${url}/tasks`,
      visible: salesClientId !== 'add',
    },
    {
      tab: 'notes',
      url: `${url}/notes`,
      visible: salesClientId !== 'add' && userCan('sales.profile.notes.view'),
    },
    {
      tab: 'quotes',
      url: checkClient() ? `${url}/quotes` : '#',
      visible: salesClientId !== 'add',
      disabled: checkClient() ? '' : 'disabled',
    },
    {
      tab: 'Proposal',
      url:
        (data.status === 'Proposal' ||
          data.status === 'Transfer to Operations') &&
        checkClient() &&
        userCan('sales.profiles.files.list')
          ? `${url}/proposal`
          : '#',
      visible: salesClientId !== 'add',
      disabled:
        (data.status === 'Proposal' ||
          data.status === 'Transfer to Operations') &&
        checkClient() &&
        userCan('sales.profiles.files.list')
          ? ''
          : 'disabled',
    },
    {
      tab: 'Invoice History',
      url: `${url}/invoice-history`,
      visible: salesClientId !== 'add' && client?.AgencyClient?.zohoId,
    },
  ].filter((tab) => tab.visible);

  const linkStyles =
    'capitalize py-2 w-32 text-sm border-t border-l border-r rounded-t-md text-center';

  const currentStyle = (cTab) => {
    const index = profileTabs.findIndex((tab) => tab.tab === cTab.tab);
    const currentIndex = profileTabs.findIndex((tab) => tab.url === pathname);
    const lastIndex = profileTabs.length - 1;
    let className = [];

    if (currentIndex === index) {
      className.push('current');
      if (index === 0) {
        className.push('left');
      } else if (index === lastIndex) {
        className.push('right');
      } else {
        className.push('middle');
      }
    } else {
      const isBefore = currentIndex - 1 === index;
      const isAfter = currentIndex + 1 === index;
      const isCurrentFirst = currentIndex === 0;
      const isCurrentLast = currentIndex === lastIndex;
      const isCurrentMiddle = currentIndex > 0 && currentIndex < lastIndex;

      if (isCurrentMiddle) {
        if (isBefore) {
          className.push('tail-right');
        }
        if (isAfter) {
          className.push('tail-left');
        }
      } else if (isCurrentFirst && isAfter) {
        className.push('tail-left');
      } else if (isCurrentLast && isBefore) {
        className.push('tail-right');
      }
    }

    return className.join(' ');
  };

  const deleteProfile = () => {
    setDeleting(true);
    axios
      .delete(`/agency/sales/clients/${salesClientId}`)
      .then((response) => {
        history.push('/sales/deals');
        setAlert('success', response.data.data.message);
      })
      .catch(() => {
        setAlert('error', 'Failed to delete profile');
      })
      .finally(() => setDeleting(false));
  };

  const disableUpdatesSection = () => {
    return !(
      pathname.includes('tasks') ||
      pathname.includes('invoice-history') ||
      pathname.includes('notes') ||
      pathname.includes('quotes')
    );
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="xl:flex justify-between">
            <div className="">
              <h1 className="text-3xl tracking-wide font-bold pt-2">
                {salesClientId !== 'add' ? (
                  <div className="flex items-center space-x-4">
                    <p>{companyName}</p>

                    {userCan('sales.profiles.delete') && (
                      <button
                        disabled={deleting || !userCan('sales.deals.delete')}
                        onClick={deleteProfile}
                      >
                        <TrashIcon className="w-5 h-5 text-grayscale-800" />
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="pb-2">
                    <Label>Client Name</Label>
                    <Input
                      type="text"
                      value={companyName}
                      name="companyName"
                      placeholder="Company/Brand"
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                )}
              </h1>
            </div>
            <div className="">
              <div className="sm:flex flex-row justify-end space-x-8">
                <div className="self-center flex items-center">
                  <label className="pr-3 text-gray-400 text-xs">Status</label>
                  <Select
                    className={`appearance-none border-white px-3 py-2 placeholder-gray-400 focus:ring-red-500 focus:border-red-500 `}
                    value={data.status}
                    onChange={(e) => onUpdate(e.target.value, 'status')}
                    disabled={
                      salesClientId === 'add' ||
                      data.status === 'Client' ||
                      data.status === 'Transfer to Operations'
                    }
                  >
                    <option value="">Change Status</option>

                    {STATUSES_PROSPECT.some((el) => el.value === data.status) &&
                      STATUSES_PROSPECT.map((s) => {
                        if (s.value !== 'Lost') {
                          return (
                            <option key={s.value} value={s.value}>
                              {s.label}
                            </option>
                          );
                        }
                      })}
                    {data.status === 'Proposal'
                      ? STATUSES.map((s) => {
                          if (s.value === 'Proposal' || s.value === 'Lost') {
                            return (
                              <option key={s.value} value={s.value}>
                                {s.label}
                              </option>
                            );
                          }
                        })
                      : STATUSES.map((s) => {
                          return (
                            <option key={s.value} value={s.value}>
                              {s.label}
                            </option>
                          );
                        })}
                    {/* show since removed transfer to operations in selection  */}
                    {data.status === 'Transfer to Operations' && (
                      <option
                        key="Transfer to Operations"
                        value="Transfer to Operations"
                      >
                        Transfer to Operations
                      </option>
                    )}
                  </Select>
                </div>
                {salesClientId === 'add' ||
                (data.status === 'Proposal' && client.agencyClientId) ? (
                  <div className="self-center">
                    <Button
                      classes="border border-gray-700 font-bold tracking-widest"
                      bgColor="gray-50"
                      hoverColor="gray-200"
                      roundedSize="3xl"
                      textColor="gray-700"
                      px={5}
                      py={2}
                      shadow=""
                      disabled={salesClientId !== 'add' && !isreadyToOps}
                      onClick={
                        salesClientId === 'add'
                          ? () => onCreate()
                          : () => onConvert()
                      }
                    >
                      {salesClientId === 'add'
                        ? 'Create Sales Record'
                        : 'Transfer to Operations'}
                    </Button>
                  </div>
                ) : (
                  ''
                )}

                <div className="flex items-center pr-8">
                  <div className="flex flex-col px-1 text-center">
                    <div>
                      <UsersPopover
                        users={salesAdmins}
                        selectedId={data.salesAdminId}
                        onChange={(user) =>
                          onUpdate(user.userId, 'salesAdminId')
                        }
                        disabled={
                          salesClientId === 'add'
                            ? true
                            : !userCan('sales.profile.assignee.control')
                        }
                        position="left-0 sm:left-auto sm:right-0"
                      />
                    </div>
                    <span className="text-1xs text-gray-400">
                      Sales
                      <br />
                      Admin
                    </span>
                  </div>
                  <div className="flex flex-col px-1 text-center">
                    <div>
                      <UsersPopover
                        users={salesReps}
                        selectedId={data.salesRepId}
                        onChange={(user) => onUpdate(user.userId, 'salesRepId')}
                        disabled={
                          salesClientId === 'add'
                            ? true
                            : !userCan('sales.profile.assignee.control')
                        }
                        position="left-0 sm:left-auto sm:right-0"
                      />
                    </div>
                    <span className="text-1xs text-gray-400">
                      Sales
                      <br />
                      Representative
                    </span>
                  </div>
                  <div className="flex flex-col px-1 text-center">
                    <div>
                      <UsersPopover
                        users={leadReps}
                        selectedId={data.leadRepId}
                        onChange={(user) => onUpdate(user.userId, 'leadRepId')}
                        disabled={
                          salesClientId === 'add'
                            ? true
                            : !userCan('sales.profile.assignee.control')
                        }
                        position="left-0 sm:left-auto sm:right-0"
                      />
                    </div>
                    <span className="text-1xs text-gray-400 ">
                      Lead
                      <br />
                      Representative
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:grid grid-cols-5">
            <div className="col-span-5">
              <div className="tablinks flex">
                {profileTabs.map((tab, i) => (
                  <Link
                    key={i}
                    to={tab.url}
                    className={classNames(
                      linkStyles,
                      currentStyle(tab),
                      tab.disabled
                    )}
                  >
                    {tab.tab}
                  </Link>
                ))}
              </div>
            </div>
            <div
              className={`${
                showUpdatesSection && disableUpdatesSection()
                  ? 'col-span-3'
                  : 'col-span-5'
              } container-fixed bg-white`}
            >
              <div className="px-8 pt-8 pb-16">
                <Switch>
                  <Route exact path={`${path}`} component={Profile} />
                  <Route path={`${path}/files`} component={Files} />
                  <Route path={`${path}/tasks`} component={Tasks} />
                  <Route path={`${path}/quotes`} component={Quotes} />
                  <Route path={`${path}/proposal`} component={Proposal} />
                  <Route path={`${path}/notes`} component={Notes} />
                  <Route
                    path={`${path}/invoice-history`}
                    render={() => (
                      <InvoiceHistory
                        fromSales={true}
                        zohoId={client?.AgencyClient?.zohoId}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>

            {disableUpdatesSection() && (
              <>
                {showUpdatesSection ? (
                  <div className="col-span-2 container-fixed bg-gray-50 border-l border-gray-100">
                    {salesClientId !== 'add' && (
                      <Updates
                        defaultTo={client?.email}
                        setVisible={(value) =>
                          dispatch(setShowUpdatesSection(value))
                        }
                      />
                    )}
                  </div>
                ) : (
                  <UpdatesToggle
                    onToggle={(value) => dispatch(setShowUpdatesSection(value))}
                  />
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default withRouter(Client);
