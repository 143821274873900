import * as yup from 'yup';
import axios from 'axios';
import { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEmpty } from 'lodash';

import { useRoles } from 'features/roles';
import { useAlerts } from 'features/alerts';

import { Modal, Button } from 'components';

const ReassignRoleModal = ({ open, setOpen, role = {}, onSuccess }) => {
  const { popupAlert } = useAlerts();
  const { fetchAllRoles, roles } = useRoles();

  useEffect(() => {
    if (!isEmpty(role)) {
      let filters = [
        {
          attribute: 'status',
          operator: 'EQ',
          value: 'ACTIVE',
        },
        {
          attribute: 'supervisorRole',
          operator: 'NEQ',
          value: role.code,
        },
        {
          attribute: 'code',
          operator: 'NEQ',
          value: role.code,
        },
      ];

      if (role.department) {
        filters.push({
          attribute: 'department',
          operator: 'EQ',
          value: role.department,
        });
      }

      if (role.subDepartment) {
        filters.push({
          attribute: 'subDepartment',
          operator: 'EQ',
          value: role.subDepartment,
        });
      }

      fetchAllRoles({ filterGroups: JSON.stringify([{ filters }]) });
    }
  }, [role]);

  const onSubmit = (values, actions) => {
    axios
      .delete(`/v2/roles/${role.roleId}`, { data: values })
      .then(() => {
        popupAlert({
          type: 'success',
          title: 'The role has been successfully reassigned',
        });

        onSuccess();
      })
      .catch((error) => {
        error.response?.data?.errors
          ? actions.setErrors(error.response.data.errors)
          : popupAlert({
              type: 'error',
              title: error.response.data.message,
            });
      })
      .finally(() => actions.setSubmitting(false));
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 pt-8">
          <h4 className="text-center text-grayscale-900 leading-1.2 font-inter font-bold text-25 tracking-3/4">
            Reassign the reporting role to another role
          </h4>
        </div>

        <div className="overflow-y-auto mt-2">
          <Formik
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
              targetRole: yup.string().required('Required'),
            })}
            initialValues={{
              targetRole: '',
            }}
          >
            {({ isSubmitting, isValid, dirty, setFieldValue }) => (
              <Form className="p-8">
                <div className="space-y-5">
                  <div className="">
                    <Field
                      as="select"
                      name="targetRole"
                      className="mt-3 block w-full text-13 bg-grayscale-400 rounded-2xl border-0 px-3.5 py-2.5 text-grayscale-900 border-transparent focus:border-transparent focus:ring-0"
                    >
                      <option value="">Select Target Role</option>
                      {roles.data.map((role) => (
                        <option key={role.roleId} value={role.roleId}>
                          {role.name}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => close()}
                      className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                    >
                      Cancel
                    </button>

                    <Button
                      type="submit"
                      roundedSize={40}
                      showLoading={true}
                      loading={isSubmitting}
                      disabled={!(isValid && dirty)}
                      classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ReassignRoleModal;
