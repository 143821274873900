import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string, array } from 'yup';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Navigation } from './Navigation';
import { About } from '../About';
import { ScopeOfService } from '../ScopeOfService';
import { Welcome } from '../Welcome';
import { Acknowledgement } from '../Acknowledgement';
import { fetchClient } from '../../sales/salesSlice';
import Button from 'components/Button';
import { joiAlertErrorsStringify } from 'utils/formatters';
import useAlert from 'hooks/useAlert';
import Pricing from '../Pricing';
import { ServiceAgreement } from '../ServiceAgreement';
import { PROPOSAL_CONTENT } from 'utils/constants';
import { fetchAddons } from 'features/zoho/zohoSlice';
import Payment from '../Payment';
import FormikErrorNotification from 'components/FormikErrorNotification';
moment.tz.setDefault('America/Toronto');

const Proposal = () => {
  const { addons } = useSelector((state) => state.zoho);
  const { client } = useSelector((state) => state.sales);
  const [loading, setLoading] = useState(false);
  const [isAccountUser, setIsAccountUser] = useState(false);
  const [reload, setReload] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [activeFontFamily, setActiveFontFamily] = useState('Open Sans');
  const [proposalContent, setProposalContent] = useState(PROPOSAL_CONTENT);
  const [hostedpageDetails, setHostedPageDetails] = useState([]);
  const [service, setService] = useState('');
  const [acknowledgement, setAcknowledgement] = useState([]);
  const [commissions, setCommissions] = useState(null);
  const [email, setEmail] = useState('');

  let emptyInitialValues = {
    name: '',
    signature: {
      value: '',
      type: '',
      date: '',
    },
    asins: [],
    method: 'braintree',
  };

  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const params = useParams();
  const { token } = params;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [saving, setSaving] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [salesClientId, setSalesClientId] = useState(null);
  const [currentInView, setCurrentInView] = useState('intro');

  useEffect(async () => {
    if (!addons) {
      await dispatch(fetchAddons());
    }
  }, []);

  useEffect(async () => {
    if (!location.pathname.includes('view')) {
      const response = await axios.get(
        `/agency/sales/clients/proposal/invite`,
        {
          params: { token: token },
        }
      );
      if (response.data.data) {
        const { salesClientId, expirationDate } = response.data.data;

        setIsTokenExpired(
          moment().format('YYYY-MM-DD HH:mm:ss') > expirationDate
        );
        setSalesClientId(salesClientId);
        setIsAccountUser(true);
      } else {
        history.push('/');
      }
    }
  }, [token]);

  useEffect(async () => {
    setLoading(true);
    if (location.pathname.includes('view')) {
      setSalesClientId(token);
    }
    if (salesClientId) {
      dispatch(fetchClient(salesClientId)).then((res) => {
        setEmail(res.payload.email);
        if (res.payload?.AgencyClient?.hostedpageDetails?.name) {
          setHostedPageDetails(res.payload.AgencyClient.hostedpageDetails);
          setService(res.payload.AgencyClient.service);
        }
        setProposalContent(
          res.payload.proposalContent
            ? res.payload.proposalContent
            : PROPOSAL_CONTENT
        );
        const acc = res.payload.AgencyClient.account;
        const accId = res.payload.AgencyClient.account.accountId;
        if (acc.commissions.length > 0) {
          setCommissions(acc.commissions[0]);
        }
        const isSeen =
          acc.acknowledgement && acc.acknowledgement.seenDate ? true : false;

        setAcknowledgement(acc.acknowledgement);

        setAccountId(accId);

        // update seen
        if (token !== salesClientId && !isSeen) {
          try {
            axios.post(`/agency/sales/clients/acknowledgement`, {
              accountId: accId,
              seenDate: new Date(Date.now()).toISOString(),
              salesClientId,
            });
          } catch (error) {
            const errorMessages = joiAlertErrorsStringify(error);
            alertError(error.response.data.message, errorMessages);
          }
        }
        setLoading(false);
      });
    }
  }, [salesClientId, reload]);

  const handleScroll = function () {
    var currentScrollPos = window.pageYOffset;
    var sections = document.getElementsByClassName('section');
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      var sectionTop = section.offsetTop;
      var sectionHeight = section.offsetHeight - 100;

      if (
        currentScrollPos >= sectionTop &&
        currentScrollPos < sectionTop + sectionHeight
      ) {
        setCurrentInView(section.id);
        break;
      }
    }
  };

  useEffect(() => {
    const newHash = `#${currentInView}`;
    if (location.hash !== newHash) history.replace(newHash);
  }, [currentInView]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const createNumOfAsins = (client) => {
      let asinArray = [];

      if (client.AgencyClient.account.commissions[0]?.managedAsins) {
        asinArray = client.AgencyClient.account.commissions[0].managedAsins.map(
          (el) => {
            return {
              listingMockupId: el.listingMockupId ?? null,
              agencyClientId: client.AgencyClient.agencyClientId,
              asin: el.asin,
              status: 'draft',
              isParentASIN: (el.isParentASIN ?? false).toString(),
              childASINs: el.childASINs ?? '',
              // createdBy: user.userId,
            };
          }
        );
      }

      if (client.AgencyClient.account.acknowledgement) {
        setInitialValues({
          name: client.AgencyClient.account.acknowledgement.fullName,
          signature: {
            value: client.AgencyClient.account.acknowledgement.signature ?? '',
            type: client.AgencyClient.account.acknowledgement.type ?? '',
            date: client.AgencyClient.account.acknowledgement.signedDate ?? '',
          },
          asins: asinArray,
        });
        setActiveFontFamily(client.AgencyClient.account.acknowledgement.font);
      } else {
        setInitialValues((prev) => ({
          ...prev,
          asins: asinArray,
        }));
      }
    };
    if (client) {
      createNumOfAsins(client);
    }
  }, [client]);
  //update knowledgement
  const onUpdateAcknowledgement = async (payload, accountId, font) => {
    const values = {
      ...payload,
      salesClientId,
      agencyClientId: client.AgencyClient.agencyClientId,
    };

    try {
      const response = await axios.patch(
        `/agency/sales/clients/acknowledgement`,
        values,
        { params: { accountId, font } }
      );
      alertSuccess('Proposal acknowledged', response.data.message);
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    }
  };

  const onSubmit = async (values) => {
    await onUpdateAcknowledgement(values, accountId, activeFontFamily);
    dispatch(fetchClient(salesClientId));
    setReload((prev) => !prev);
  };

  const validationSchema = object().shape({
    name: string().required('Name is required').typeError('Name is required'),
    signature: object().shape({
      value: string().required('Signature is required'),
      type: string(),
      date: string(),
    }),
    // asins: array().of(
    //   object().shape({
    //     asin: string().required('ASIN - Required'),
    //   })
    // ),
  });

  return (
    !loading && (
      <div className="text-gray-500">
        <>
          <Navigation
            isSigned={true}
            isPaid={true}
            proposalContent={proposalContent}
          />

          <div className="lg:ml-64 font-sourceSansPro">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ values }) => (
                <Form>
                  <FormikErrorNotification />
                  {!isTokenExpired && (
                    <>
                      <Acknowledgement
                        activeFontFamily={activeFontFamily}
                        setActiveFontFamily={setActiveFontFamily}
                        acknowledgement={acknowledgement}
                      />

                      <div className="flex justify-between w-full px-10 py-4">
                        <div></div>
                        {isAccountUser &&
                          (!acknowledgement ||
                            !acknowledgement?.signedDate) && (
                            <Button
                              type="submit"
                              roundedSize="3xl"
                              classes="tracking-wider font-bold"
                              px={16}
                              py={2}
                              textSize="lg"
                              color="green"
                              //onClick={() => onSubmit(values)}
                              disabled={
                                !isAccountUser ||
                                (acknowledgement && acknowledgement.signedDate)
                              }
                            >
                              Save Changes
                            </Button>
                          )}
                      </div>
                    </>
                  )}

                  {isTokenExpired && (
                    <div className="container mx-auto px-4 py-10 sm:px-6 lg:px-8">
                      <div className="flex flex-col items-center">
                        <p className="mt-8 text-2xl">
                          Proposal link has expire.
                        </p>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            <Payment
              email={email}
              salesClientId={salesClientId}
              hostedpageDetails={hostedpageDetails}
              addons={addons}
              service={service}
              agencyClient={client?.AgencyClient}
              disabled={
                !acknowledgement ||
                (acknowledgement && !acknowledgement.signedDate)
              }
              paid={
                client?.AgencyClient?.account.onboardingHistory
                  ?.paymentConfirmed
              }
            />
          </div>
        </>
      </div>
    )
  );
};

export default Proposal;
