import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import metrics from '../../utils/metrics';

const attributes = ['cpm', 'ctr', 'cr', 'cpcon'];

const EffectsOfNonConverters = ({
  accountId,
  marketplace,
  startDate,
  endDate,
  className = '',
  additionalParams = {},
}) => {
  const [overview, setOverview] = useState([
    { key: 'converters' },
    { key: 'nonConverters' },
    { key: 'all' },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes,
        ...additionalParams,
      };

      const response = await axios.get(
        '/account/advertising/targetings/conversions-summary',
        {
          params,
        }
      );

      if (isSubscribed) {
        setOverview(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    overview.some((i) => i.count) && (
      <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
        <div className="flex items-center justify-between px-8 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
            Effects of <br /> Non-Converters <br /> on KPIs
          </p>

          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="w-5 h-5 bg-success-dark"></div>
              <span className="ml-1 mr-4 text-tiny leading-1.5 text-grayscale-800">
                CONVERTING KEYWORDS
              </span>
            </div>
            <div className="flex items-center my-2">
              <div className="w-5 h-5 bg-error-dark"></div>
              <span className="ml-1 mr-4 text-tiny leading-1.5 text-grayscale-800">
                NON-CONVERTING KEYWORDS
              </span>
            </div>
            <div className="flex items-center">
              <div className="w-5 h-5 bg-grayscale-700"></div>
              <span className="ml-1 mr-4 text-tiny leading-1.5 text-grayscale-800">
                ALL
              </span>
            </div>
          </div>
        </div>

        <div className="px-8">
          <div className="grid grid-cols-2 gap-5 mt-4">
            {attributes
              .map((attr) => metrics.find((m) => m.key === attr))
              .map((metric) => (
                <div key={`effects-of-non-converters-${metric.key}`}>
                  <p className="text-grayscale-800 text-mini font-bold leading-1.5 mb-2">
                    {metric.title}
                  </p>

                  <div className="h-48">
                    <ResponsiveContainer>
                      <BarChart
                        barGap={20}
                        layout="vertical"
                        data={[
                          {
                            name: 'Keywords',
                            CONVERTERS: overview[0][metric.key],
                            'NON-CONVERTERS': overview[1][metric.key],
                            ALL: overview[2][metric.key],
                          },
                        ]}
                        margin={{
                          left: -40,
                        }}
                      >
                        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />

                        <XAxis type="number" tick={false} />

                        <YAxis
                          dataKey="name"
                          type="category"
                          scale="band"
                          tick={false}
                        />

                        {[
                          { key: 'CONVERTERS', fill: '#00966D' },
                          { key: 'NON-CONVERTERS', fill: '#C30000' },
                          { key: 'ALL', fill: '#949494' },
                        ].map((bar) => (
                          <Bar
                            key={`effects-of-non-converters-${metric.key}-${bar.key}`}
                            dataKey={bar.key}
                            barSize={30}
                            fill={bar.fill}
                            isAnimationActive={false}
                          >
                            <LabelList
                              dataKey={bar.key}
                              position="middle"
                              formatter={(value) => metric.rawFormatter(value)}
                              fill="#ffffff"
                            />
                          </Bar>
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default EffectsOfNonConverters;
