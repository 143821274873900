import { useFormikContext } from 'formik';
import axios from 'axios';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import MultipleSelect from 'components/Forms/MultipleSelect';
import { Field, ErrorMessage, FieldArray } from 'formik';
import Button from 'components/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import {
  AGENCY_CLIENT_ACCOUNT_STATUSES,
  AGENCY_CLIENT_CATEGORIES,
  AGENCY_CLIENT_SERVICES,
} from 'utils/constants';
import { useEffect, useState } from 'react';
import ButtonLink from 'components/ButtonLink';
import ReactTooltip from 'react-tooltip';
import { debounce, trim } from 'lodash';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import Input from 'components/Forms/Input';

const DetailsFormPartial = ({ action, service }) => {
  const { setFieldValue, handleChange, values, errors } = useFormikContext();
  const asinListObj = {
    asin: '',
  };

  const [categories, setCategories] = useState({ categories: [] });
  const [useZohoId, setUseZohoId] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { alertError } = useAlert();

  useEffect(() => {
    if (values.zohoId && action == 'edit') {
      setUseZohoId(true);
    }
  }, [values.zohoId, action]);

  const onCategoryChange = (value, setFieldValue) => {
    setCategories(value);
    const categoryList = categories.categories.map((category) => {
      return { category };
    });
    setFieldValue('categoryList', categoryList);
  };

  const zohoIdFetch = async (e) => {
    const value = trim(e.target.value);
    let client = '';
    let email = '';
    if (value !== '') {
      setFetching(true);
      try {
        const res = await axios.get(`/agency/client/zoho/${trim(value)}`);
        const { output } = res.data;
        if (output.code === 0) {
          client = output.customer.company_name;
          email = output.customer.email;
        }
      } catch (error) {
        alertError('Fetch failed', error.response.data.message);
      } finally {
        setFetching(false);
      }
    }
    setFieldValue('zohoId', value);
    setFieldValue('client', client);
    setFieldValue('email', email);
  };

  const onDebouncedFetch = debounce((e) => {
    zohoIdFetch(e);
  }, 1000);

  const cancelUseZohoId = () => {
    setUseZohoId(false);
    setFieldValue('zohoId', null);
    setFieldValue('client', '');
    setFieldValue('email', '');
  };

  return (
    <div className="flex flex-col space-y-3 mt-2 border-b pb-5">
      {useZohoId && (
        <div className="col-span-2">
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <Label>
                Zoho Customer ID <RequiredAsterisk />
              </Label>
              {fetching && <SpinnerGrow size={2} />}
            </div>
            <ButtonLink
              classes="tracking-wider"
              color="red"
              onClick={cancelUseZohoId}
            >
              Cancel
            </ButtonLink>
          </div>
          <input
            type="text"
            className="form-input"
            onChange={(e) => onDebouncedFetch(e)}
            defaultValue={values.zohoId}
          />
          {/* <Field
            name="zohoId"
            className="form-input"
            type="text"
            onChange={(e) => onDebouncedFetch(e)}
          /> */}
          <ErrorMessage
            name="zohoId"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      )}

      <div className="col-span-2">
        <div className="flex items-center justify-between">
          <Label>
            Client <RequiredAsterisk />
          </Label>
          {!useZohoId && (
            <div>
              <ButtonLink
                classes="tracking-wider"
                color="green"
                onClick={() => setUseZohoId(true)}
              >
                <span className="text-xs" data-tip data-for="zohocustomerid">
                  Use existing zoho customer
                </span>
                <ReactTooltip
                  id="zohocustomerid"
                  type="dark"
                  effect="solid"
                  place="bottom"
                  data-delay-show
                >
                  <span className="text-xs">
                    fetch client name/email from zoho
                  </span>
                </ReactTooltip>
              </ButtonLink>
            </div>
          )}
        </div>
        <Field
          name="client"
          className="form-input disabled-gray"
          type="text"
          disabled={useZohoId}
        />
        <ErrorMessage
          name="client"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div className="col-span-2">
        <Label>
          Email <RequiredAsterisk />
        </Label>
        <Field
          name="email"
          className="form-input disabled-gray"
          type="email"
          disabled={values.status === 'registered' || useZohoId}
        />
        <ErrorMessage
          name="email"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div className="col-span-2">
        <Label>Display Name</Label>
        <Field
          name="displayName"
          className="form-input disabled-gray"
          type="text"
        />
        <ErrorMessage
          name="displayName"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label htmlFor="address">Address</Label>
        <Field
          name="address"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>

      <div>
        <Label htmlFor="phone">Phone</Label>
        <Field name="phone" className="form-input disabled-white" type="tel" />
      </div>

      <div>
        <Label htmlFor="siEmail">SI Email</Label>
        <Field
          name="siEmail"
          className="form-input disabled-white"
          type="email"
        />
        <ErrorMessage
          name="siEmail"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      {/* <div>
        <Label htmlFor="contractSigned">Contract Signed</Label>
        <Field
          name="contractSigned"
          className="form-input disabled-white"
          type="date"
        />
        <ErrorMessage
          name="contractSigned"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div> */}

      <div>
        <Label htmlFor="contactName">Contact Name</Label>
        <Field
          name="contactName"
          className="form-input disabled-white"
          type="text"
        />
      </div>

      <div>
        <Label htmlFor="contactName2">Contact Name 2</Label>
        <Field
          name="contactName2"
          className="form-input disabled-white"
          type="text"
        />
      </div>

      <div>
        <Label htmlFor="primaryEmail">Primary Email</Label>
        <Field
          name="primaryEmail"
          className="form-input disabled-white"
          type="email"
        />
        <ErrorMessage
          name="primaryEmail"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label htmlFor="secondaryEmail">Secondary Email</Label>
        <Field
          name="secondaryEmail"
          className="form-input disabled-white"
          type="email"
        />
        <ErrorMessage
          name="secondaryEmail"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label htmlFor="thirdEmail">Third Email</Label>
        <Field
          name="thirdEmail"
          className="form-input disabled-white"
          type="email"
        />
        <ErrorMessage
          name="thirdEmail"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      {/* <div>
        <Label htmlFor="service">Service</Label>
        <Field
          name="service"
          as="select"
          className="form-select disabled-white"
        >
          <option value="">Select Service</option>
          {AGENCY_CLIENT_SERVICES.map((s) => {
            return (
              <option key={s} value={s}>
                {s}
              </option>
            );
          })}
        </Field>
      </div> */}

      <div>
        <Label htmlFor="accountStatus">Account Status</Label>
        <Field
          name="accountStatus"
          as="select"
          className="form-select disabled-white"
        >
          <option value="">Select account status</option>
          {AGENCY_CLIENT_ACCOUNT_STATUSES.map((as) => {
            return (
              <option key={as} value={as}>
                {as}
              </option>
            );
          })}
        </Field>
      </div>

      <div>
        <Label htmlFor="website">Website</Label>
        <Field
          name="website"
          className="form-input disabled-white"
          type="text"
        />
        <ErrorMessage
          name="website"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div>
        <Label htmlFor="aboutUs">About Us</Label>
        <Field
          name="aboutUs"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>

      <div>
        <Label htmlFor="overview">Overview</Label>
        <Field
          name="overview"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>

      <div>
        <Label htmlFor="painPoints">Pain Points</Label>
        <Field
          name="painPoints"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>

      <div>
        <Label htmlFor="goals">Goals</Label>
        <Field
          name="goals"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>

      <MultipleSelect
        className="text-sm block text-gray-900"
        label="Product Categories"
        options={{ categories: AGENCY_CLIENT_CATEGORIES }}
        noSelectedText="No categories selected"
        selected={categories}
        setSelected={(value) => onCategoryChange(value, setFieldValue)}
        containerClass="p-3 sm:grid lg:grid-cols-2 xl:grid-cols-3 sm:gap-x-2 sm:gap-y-1"
      />

      <div>
        <Label htmlFor="amazonPageUrl">Amazon Page URL</Label>
        <Field
          name="amazonPageUrl"
          className="form-input disabled-white"
          type="text"
        />
        <ErrorMessage
          name="amazonPageUrl"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <FieldArray name="asinList">
        {({ insert, remove, push }) => (
          <>
            <Label htmlFor="asinsToOptimize">ASINs to Optimize</Label>
            {values.asinList.length > 0 && (
              <div className="col-span-2 space-y-3 mb-4">
                {values.asinList.map((asin, index) => (
                  <div key={index} className="sm:grid grid-cols-3 gap-x-2 ">
                    <div className="col-span-3 flex justify-between">
                      <Label fontWeight="font-bold" textColor="text-gray-500">
                        {service === 'Walmart'
                          ? `Item ID ${index + 1}`
                          : `ASIN ${index + 1}`}
                      </Label>

                      <span
                        onClick={() => {
                          remove(index);
                        }}
                        className="cursor-pointer text-gray-500 hover:text-red-500"
                      >
                        <TrashIcon className="w-4 h-4 " />
                      </span>
                    </div>

                    <div className="col-span-3">
                      <Field
                        name={`asinList.${index}.asin`}
                        placeholder={
                          service === 'Walmart' ? 'Enter Item Id' : 'Enter ASIN'
                        }
                        className="form-input disabled-white"
                        type="text"
                      />

                      <ErrorMessage
                        name={`asinList.${index}.asin`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="col-span-2">
              <Button
                classes="font-bold tracking-wider w-full justify-center"
                bgColor="gray-50"
                hoverColor="gray-200"
                roundedSize="2xl"
                textColor="gray-600"
                border="border-2 border-gray-600"
                py={1}
                shadow=""
                onClick={(e) => {
                  push(asinListObj);
                }}
              >
                <PlusIcon className="w-4 h-4 inline" />{' '}
                {service === 'Walmart'
                  ? 'Add Item Id to optimize'
                  : 'Add ASIN to optimize'}
              </Button>
            </div>
          </>
        )}
      </FieldArray>

      <div>
        <Label htmlFor="otherNotes">Other Notes</Label>
        <Field
          name="otherNotes"
          as="textarea"
          rows={3}
          className="form-input disabled-white"
        />
      </div>
    </div>
  );
};
export default DetailsFormPartial;
