import React, { useState } from 'react';
import axios from 'axios';
import { Menu } from '@headlessui/react';
import Button from 'components/Button';
import DropdownMenu from 'components/DropdownMenu';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import { DUNNING, LIVE, NON_RENEWING, UNPAID } from 'utils/subscriptions';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from 'components';
import useAlert from 'hooks/useAlert';
import ChangeSubscriptionModal from './components/ChangeSubscriptionModal';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import AddonModal from './components/AddonModal';
import { fetchSubscription } from './subscriptionSlice';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import OneTimeChargeModal from './components/OneTimeChargeModal';
import useAgencyClient from 'hooks/useAgencyClient';

const Actions = () => {
  const { userCan, isAgencySuperUser } = usePermissions();
  const { alertError, alertSuccess } = useAlert();
  const { agencyClient } = useAgencyClient();
  const { subscription, pendingInvoices } = useSelector(
    (state) => state.subscription
  );
  const status = useSubscription(subscription.z);
  const dispatch = useDispatch();

  //const { subscriptionId } = subscription.subscriptionId;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [isOpenCancelImmediately, setIsOpenCancelImmediately] = useState(false);
  const [isOpenAddOneTimeAddon, setIsOpenAddOneTimeAddon] = useState(false);
  const [isOpenCharge, setIsOpenCharge] = useState(false);
  const [isOpenResume, setIsOpenResume] = useState(false);
  const [isOpenPause, setIsOpenPause] = useState(false);
  const [isOpenChangeSubscription, setIsOpenChangeSubscription] =
    useState(false);
  const [cancelAction, setCancelAction] = useState('end_of_term');

  const onEditSubscription = () => {
    if (pendingInvoices && pendingInvoices.invoices.length > 0) {
      alertError(
        'Cannot Reactivate',
        `There ${
          pendingInvoices.invoices.length <= 1 ? 'is a' : 'are'
        } pending invoice(s) for this subscription. Please settle it before reactivating`
      );
    } else {
      setIsOpenChangeSubscription(true);
    }
  };

  const onReactivateNonRenewing = async () => {
    setSaving(true);
    try {
      const response = await axios.post(
        `/subscriptions/${subscription.z.subscription_id}/reactivate`
      );

      alertSuccess('Subscription reactivated', response.data.data.message);
      dispatch(fetchSubscription(subscription.z.subscription_id));
    } catch (error) {
      alertError(
        'Unable to reactivate subscription',
        error.response.data.message
      );
    } finally {
      setSaving(false);
    }
  };

  const updateSubscriptionStatus = async (status, setOpen) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `subscriptions/${subscription.z.subscription_id}/${status}`
      );

      if (res.data.data.code === 0) {
        alertSuccess(`Subscription ${status}d`, res.data.data.message);
        dispatch(fetchSubscription(subscription.z.subscription_id));
        setOpen(false);
      } else {
        alertError('Faled to update status', res.data.data.message);
      }
    } catch (error) {
      alertError('Faled to update status', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onPauseSubscription = async () => {
    await updateSubscriptionStatus('pause', setIsOpenPause);
  };

  const onResumeSubscription = async () => {
    await updateSubscriptionStatus('resume', setIsOpenResume);
  };

  const onOpenCancelModal = (action) => {
    setCancelAction(action);
    setIsOpenCancel(true);
  };

  return (
    <>
      <ChangeSubscriptionModal
        open={isOpenChangeSubscription}
        setOpen={setIsOpenChangeSubscription}
      />
      <AddonModal
        subscription={subscription.z}
        open={isOpenAddOneTimeAddon}
        setOpen={setIsOpenAddOneTimeAddon}
      />
      <OneTimeChargeModal
        subscription={subscription.z}
        open={isOpenCharge}
        setOpen={setIsOpenCharge}
      />
      <ConfirmationModal
        title="Pause Subscription?"
        content={
          status.hasAny([UNPAID, DUNNING]) ? (
            <>
              This subscription's status is {subscription.z.status}.
              <br /> To pause this subscription,{' '}
              <i>we'll have to set their payment mode to offline</i>. <br />
              Do you want to continue?
            </>
          ) : (
            `You will be able to resume this subscription manually`
          )
        }
        open={isOpenPause}
        setOpen={setIsOpenPause}
        onOkClick={onPauseSubscription}
        onCancelClick={() => setIsOpenPause(false)}
        okLoading={loading}
        showOkLoading={true}
      />
      <ConfirmationModal
        title="Resume Subscription"
        content="Reactivate this subscription?"
        open={isOpenResume}
        setOpen={setIsOpenResume}
        onOkClick={onResumeSubscription}
        onCancelClick={() => setIsOpenResume(false)}
        okLoading={loading}
        showOkLoading={true}
      />
      <CancelSubscriptionModal
        open={isOpenCancel}
        setOpen={setIsOpenCancel}
        subscription={subscription.z}
        action={cancelAction}
      />
      <div className="sm:col-span-3 sm:flex justify-between">
        <div className="flex items-center">
          {userCan('clients.subscription.update') && (
            <Button
              classes="border-0 font-bold tracking-wider mr-2"
              bgColor="red-600"
              hoverColor="red-700"
              roundedSize="2xl"
              textColor="white"
              px={4}
              py={1.5}
              shadow=""
              type="button"
              onClick={onEditSubscription}
            >
              {status.expired() || status.cancelled() ? 'Reactivate' : 'Edit'}
            </Button>
          )}
          {status.isEditable() &&
            status.nonRenewing() &&
            userCan('clients.subscription.update') && (
              <Button
                classes="border-0 font-bold tracking-wider mr-2"
                bgColor="green-600"
                hoverColor="green-700"
                roundedSize="2xl"
                textColor="white"
                px={4}
                py={1.5}
                shadow=""
                type="button"
                onClick={onReactivateNonRenewing}
                showLoading={true}
                loading={saving}
              >
                Reactivate
              </Button>
            )}

          {status.paused() && userCan('clients.subscription.pause') && (
            <>
              <Button
                classes="border-0 font-bold tracking-wider mr-2"
                bgColor="green-600"
                hoverColor="green-700"
                roundedSize="2xl"
                textColor="white"
                px={4}
                py={1.5}
                shadow=""
                type="button"
                onClick={() => setIsOpenResume(true)}
              >
                Resume
              </Button>
              {userCan('clients.subscription.cancel') && (
                <DropdownMenu
                  title={
                    <span className="relative inline-flex items-center rounded-2xl tracking-wider  bg-red-600 px-4 py-1.5 text-sm font-bold text-white hover:bg-red-700 focus:z-10 focus:outline-none ">
                      Cancel
                    </span>
                  }
                  position="right-0"
                  titleClasses="flex items-center mr-1"
                  buttonBg="bg-transparent"
                  buttonFontWeight="font-normal"
                  hoverClasses=""
                  textColor="text-gray-600"
                  classes="text-sm z-2"
                  padding="px-0 py-2"
                  buttonRounded=""
                  hoverText="hover:text-red-500"
                  hideArrow={true}
                >
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active && 'bg-gray-200 '
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => onOpenCancelModal('end_of_term')}
                      >
                        Cancel on Next Renewal
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active && 'bg-gray-200'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => onOpenCancelModal('immediately')}
                      >
                        Cancel Immediately
                      </button>
                    )}
                  </Menu.Item>
                </DropdownMenu>
              )}
            </>
          )}
          {status.hasAny([LIVE, UNPAID, DUNNING, NON_RENEWING]) &&
          userCan(
            'clients.subscription.addon.add|clients.subscription.pause|clients.subscription.update'
          ) ? (
            <DropdownMenu
              title={
                <DotsVerticalIcon className="w-6 h-6 text-gray-500 hover:text-black" />
              }
              position="right-0"
              titleClasses="flex items-center mr-1"
              buttonBg="bg-transparent"
              buttonFontWeight="font-normal"
              hoverClasses=""
              textColor="text-gray-600"
              classes="text-sm z-2"
              padding="px-0 py-2"
              buttonRounded=""
              hoverText="hover:text-red-500"
              hideArrow={true}
            >
              {userCan('clients.subscription.addon.add') && (
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active && 'bg-red-500 text-white'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => setIsOpenAddOneTimeAddon(true)}
                      >
                        Charge One-Time Addon
                      </button>
                    )}
                  </Menu.Item>
                  {isAgencySuperUser() && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && 'bg-red-500 text-white'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => setIsOpenCharge(true)}
                        >
                          One Time charge
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </div>
              )}
              <div className="px-1 py-1">
                {status.hasAny([LIVE, UNPAID, DUNNING]) &&
                  userCan('clients.subscription.pause') && (
                    <>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active && 'bg-red-500 text-white'
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => setIsOpenPause(true)}
                          >
                            Pause Subscription
                          </button>
                        )}
                      </Menu.Item>
                      {userCan('clients.subscription.cancel') && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active && 'bg-red-500 text-white'
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              onClick={() => setIsOpenCancel(true)}
                            >
                              Cancel Subscription
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </>
                  )}

                {status.nonRenewing() &&
                  userCan('clients.subscription.cancel') && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && 'bg-red-500 text-white'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => onOpenCancelModal('immediately')}
                        >
                          Cancel Immediately
                        </button>
                      )}
                    </Menu.Item>
                  )}
              </div>
            </DropdownMenu>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
export default Actions;
