import { PhoneIcon, MailIcon, PencilIcon } from '@heroicons/react/outline';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import classNames from 'utils/classNames';
import { isFunction, startCase } from 'lodash';

const TaskType = ({
  value = null,
  setValue,
  configurable = true,
  editable = true,
}) => {
  const options = [
    {
      status: 'EMAIL',
      color: 'text-red-400',
      icon: MailIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
    {
      status: 'CALL',
      color: 'text-yellow-400',
      icon: PhoneIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
    {
      status: 'TODO',
      color: 'text-blue-400',
      icon: PencilIcon,
      sizeSm: 'w-4 h-4',
      sizeMd: 'w-6 h-6',
    },
  ];

  const onChange = (newValue) => {
    isFunction(setValue) && setValue(newValue);
  };

  return (!!value && !editable) || (!value && !configurable) ? (
    <div className="">{value ? startCase(value.toLowerCase()) : 'N/A'}</div>
  ) : (
    <DropdownMenu
      title={
        <div className="" title="click to update priority">
          {value ? startCase(value.toLowerCase()) : 'N/A'}
        </div>
      }
      titleClasses="flex items-center mr-1"
      buttonBg="bg-transparent"
      buttonFontWeight="font-normal"
      hoverClasses=""
      textColor="text-gray-600"
      classes="text-sm"
      buttonRounded=""
      hoverText="hover:text-red-500"
      dropdownWidth="w-28"
      padding=""
      position="right-0"
      zIndex="z-10"
      hideArrow
    >
      <div className="flex flex-col ">
        {options.map((option) => (
          <Menu.Item key={option.status}>
            <button
              className="flex items-center space-x-2 w-full hover:bg-gray-200 py-2 px-5"
              onClick={() => onChange(option.status)}
            >
              <option.icon
                className={classNames(option.color, 'w-5 h-5 inline')}
              />
              <span className="">{startCase(option.status.toLowerCase())}</span>
            </button>
          </Menu.Item>
        ))}
      </div>
    </DropdownMenu>
  );
};

export default TaskType;
