import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { object, string, number, date, array, ref, min, boolean } from 'yup';
import axios from 'axios';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { DEFAULT_MARKETPLACE } from 'utils/constants';
import SubscriptionFormPartial from './SubscriptionFormPartial';
import CommissionFormPartial from './CommissionFormPartial';
import DetailsFormPartial from './DetailsFormPartial';
import Button from 'components/Button';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import Toggle from 'components/Toggle';
import { ConfirmationModal } from 'components';
import Loading from 'components/Loading';
import BackButton from 'components/BackButton';

const NewClientForm = ({ action, payload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [isOpenOfflineConfirmation, setIsOpenOfflineConfirmation] =
    useState(false);
  const emptyInitialValues = {
    plan_code: '',
    name: '',
    reference_id: '',
    pricebook_id: '',
    price: 0,
    noExpiry: true,
    billing_cycles: null,
    currency_code: 'USD',
    convertDisabled: false,
    convert_retainer_cycle: null,
    retainer_after_convert: null,
    plan_description: '',
    addons: [],
    defaultMarketplace: DEFAULT_MARKETPLACE,
    noCommission: false,
    noCommissionReason: '',
    commissionType: 'gross',
    rate: 0,
    monthThreshhold: 0,
    baseline: null,
    managedAsins: [],
    rules: [],
    autoAdd: true,
    client: '',
    email: '',
    serviceAgreementLink: '',
    address: '',
    phone: '',
    siEmail: '',
    contractSigned: moment.utc().format('YYYY-MM-DD'),
    contactName: '',
    contactName2: '',
    primaryEmail: '',
    secondaryEmail: '',
    thirdEmail: '',
    service: '',
    accountStatus: '',
    website: '',
    aboutUs: '',
    overview: '',
    painPoints: '',
    goals: '',
    categoryList: [],
    amazonPageUrl: '',
    asinList: [],
    otherNotes: '',
    status: 'draft',
    zohoId: null,
    chargeAdminFee: false,
    noOfAsins: 0,
    addonTemp: {
      name: '',
      addon_description: '',
      addon_code: '',
      price: 0,
      quantity: 1,
      type: 'one_time',
      action: 'add',
    },
  };

  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const [buttonActions, setButtonActions] = useState({
    add: [
      {
        status: 'draft',
        text: `Save Draft`,
        color: 'green',
      },
      {
        status: 'created',
        text: `Create & Send Invite`,
        color: 'red',
      },
    ],
    edit: [],
  });

  useEffect(() => {
    if (payload && !payload.deletedAt) {
      const { status } = payload;
      const btns = [...buttonActions.edit];
      btns.push({
        status: payload.status,
        text: 'Update',
        color: 'green',
      });

      if (status === 'invited') {
        btns.push({
          status: 'resend',
          text: `Update & Resend Invite`,
          color: 'blue',
        });
      } else if (status === 'draft') {
        btns.push({
          status: 'created',
          text: `Update & Send Invite`,
          color: 'red',
        });
      }
      setButtonActions({ ...buttonActions, edit: btns });
    }
  }, [payload]);

  useEffect(() => {
    if (payload && action === 'edit') {
      const checkCommissionValue = (key) => {
        if (payload.account && payload.account.commissions[0]) {
          if (key === 'commence') {
            return payload.account.commissions[0].commencedAt ? true : false;
          }
          return payload.account.commissions[0][key];
        }

        if (payload.draftCommission) {
          return payload.draftCommission[key];
        }

        return null;
      };

      const hasHostedPage = payload.hostedpageDetails ? true : false;

      setInitialValues({
        ...initialValues,
        plan_code: payload.hostedpageDetails?.plan_code,
        name: hasHostedPage ? payload.hostedpageDetails.name : '',
        reference_id: payload.hostedpageDetails?.reference_id,
        pricebook_id: payload.hostedpageDetails?.pricebook_id,
        price: hasHostedPage ? payload.hostedpageDetails.price : 0,
        noExpiry: payload.hostedpageDetails?.billing_cycles
          ? payload.hostedpageDetails.billing_cycles === '' ||
            payload.hostedpageDetails.billing_cycles === 0
            ? true
            : false
          : true,
        billing_cycles: payload.hostedpageDetails?.billing_cycles,
        currency_code: payload.hostedpageDetails?.currency_code,
        convertDisabled:
          payload.hostedpageDetails?.convert_retainer_cycle === '' ||
          payload.hostedpageDetails?.convert_retainer_cycle === 0
            ? true
            : false,
        convert_retainer_cycle:
          payload.hostedpageDetails?.convert_retainer_cycle,
        retainer_after_convert:
          payload.hostedpageDetails?.retainer_after_convert,
        plan_description: hasHostedPage
          ? payload.hostedpageDetails.plan_description
          : '',
        addons: hasHostedPage ? payload.hostedpageDetails.addons : [],
        defaultMarketplace: payload.draftMarketplace ?? DEFAULT_MARKETPLACE,
        noCommission: payload.noCommission ?? false,
        noCommissionReason: payload.noCommissionReason ?? '',
        commissionType: checkCommissionValue('type'),
        rate: checkCommissionValue('rate') ?? 0,
        noOfAsins:
          checkCommissionValue('managedAsins') &&
          checkCommissionValue('managedAsins').length
            ? checkCommissionValue('managedAsins').length
            : 0,
        monthThreshhold: checkCommissionValue('monthThreshold') ?? 0,
        baseline: checkCommissionValue('baseline') ?? null,
        managedAsins: checkCommissionValue('managedAsins') ?? [],
        rules: checkCommissionValue('rules') ?? [],
        autoAdd: checkCommissionValue('commence') ?? false,
        client: hasHostedPage
          ? payload.hostedpageDetails.customer_name
          : payload.client,
        displayName: hasHostedPage
          ? payload.hostedpageDetails.displayName
          : payload.displayName,
        email: hasHostedPage
          ? payload.hostedpageDetails.email
          : payload.primaryEmail,
        serviceAgreementLink: payload.serviceAgreementLink,
        address: payload.address,
        phone: payload.phone,
        siEmail: payload.siEmail,
        contractSigned: payload.contractSigned
          ? moment.utc(payload.contractSigned).format('YYYY-MM-DD')
          : '',
        contactName: payload.contactName,
        contactName2: payload.contactName2,
        primaryEmail: payload.primaryEmail,
        secondaryEmail: payload.secondaryEmail,
        thirdEmail: payload.thirdEmail,
        service: payload.service,
        accountStatus: payload.accountStatus,
        website: payload.website,
        aboutUs: payload.aboutUs,
        overview: payload.overview,
        painPoints: payload.painPoints,
        goals: payload.goals,
        categoryList: payload.categoryList ?? [],
        amazonPageUrl: payload.amazonPageUrl,
        asinList: payload.asinList ?? [],
        otherNotes: payload.otherNotes,
        status: payload.status,
        zohoId: payload.zohoId,
      });
    }
  }, [payload, action]);

  const descriptionCheck = (string) => /[<>]/.test(string);
  const displayNameCheck = (string) =>
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);
  const validationSchema = object().shape({
    plan_code: string().required('plan is required'),
    name: string().nullable(),
    pricebook_id: string().nullable(),
    price: number().min(0).required('monthly retainer is required'),
    noExpiry: boolean(),
    billing_cycles: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable()
      .when('noExpiry', {
        is: false,
        then: number()
          .min(1, 'billing cycles must be greater than or equal to 1')
          .required('billing cycles is required')
          .typeError('billing cycles is required'),
      }),
    currency_code: string().required(),
    convertDisabled: boolean(),
    convert_retainer_cycle: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable()
      .when('convertDisabled', {
        is: true,
        then: number()
          .min(1, 'convert after cycle must be greater than or equal to 1')
          .required('convert after cycle is required')
          .typeError('convert after cycle is required'),
      }),
    retainer_after_convert: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable()
      .when('convertDisabled', {
        is: true,
        then: number()
          .min(0)
          .required('retainer after convert is required')
          .typeError('retainer after convert is required'),
      }),
    plan_description: string()
      .test(
        'Plan Description must not have any (<) less than or (>) greater than symbol',
        'Plan Description must not have any (<) less than or (>) greater than symbol',
        (value) => !descriptionCheck(value)
      )
      .nullable(),
    addons: array()
      .of(
        object().shape({
          addon_code: string().required('addon code is required'),
          name: string(),
          addon_description: string()
            .test(
              'Addon Description must not have any (<) less than or (>) greater than symbol',
              'Addon Description must not have any (<) less than or (>) greater than symbol',
              (value) => !descriptionCheck(value)
            )
            .nullable(),
          type: string().required(),
          quantity: number()
            .min(1, 'qty must be greater than or equal to 1')
            .required('required'),
          price: number().min(0).required('required'),
        })
      )
      .nullable(),
    salesPerson: string().nullable(),
    defaultMarketplace: string().required('required'),
    noCommission: boolean(),
    noCommissionReason: string()
      .nullable()
      .when('noCommission', {
        is: true,
        then: string().required('no commission reason is required'),
      }),
    autoAdd: boolean(),
    commissionType: string().nullable().when('noCommission', {
      is: false,
      then: string().required(),
    }),
    rate: number().when(['noCommission', 'commissionType'], {
      is: (noCommission, commissionType) =>
        noCommission || (!noCommission && commissionType === 'tiered'),
      then: number()
        .transform((v, o) => (o === '' ? null : v))
        .nullable(),
      otherwise: number()
        .min(0, 'commission rate must be greater than or equal to 1')
        .required('commission rate is required'),
    }),
    monthThreshhold: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable(true)
      .when('commissionType', {
        is: (commissionType) =>
          ['benchmark', 'rolling'].includes(commissionType),
        then: number().min(1).required(),
      }),
    baseline: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable(),
    // managedAsins: array()
    //   .of(
    //     object().shape({
    //       asin: string()
    //         .trim()
    //         .matches(/([A-Z0-9]{10})/, 'incorrect ASIN format')
    //         .required('managed asin is required'),
    //       baseline: number().nullable(),
    //       startDate: date().nullable(),
    //       endDate: date()
    //         .nullable()
    //         .when('startDate', {
    //           is: (startDate) => !!startDate,
    //           then: date()
    //             .min(
    //               ref('startDate'),
    //               'end date cannot earlier than start date'
    //             )
    //             .required('required')
    //             .typeError('end date is required'),
    //         }),
    //     })
    //   )
    //   .nullable(),
    rules: array()
      .of(
        object().shape({
          min: number().min(0, 'minimum value is 0').required('required'),
          max: number().min(ref('min'), 'must be >= minGross').nullable(true),
          rate: number().min(0).required('required'),
        })
      )
      .nullable()
      .when(['noCommission', 'commissionType'], {
        is: (noCommission, commissionType) =>
          !noCommission && commissionType === 'tiered',
        then: array()
          .required()
          .min(1, 'tiered commission must have at least 1 rule'),
      }),
    client: string().required(),
    displayName: string()
      .test(
        'Display Name must not have any special characters or symbols',
        (value) => !displayNameCheck(value)
      )
      .max(25, 'Maximum of 25 characers'),
    email: string().required(),
    serviceAgreementLink: string().url().nullable(),
    address: string().nullable(),
    phone: string().nullable(),
    siEmail: string().email().nullable(),
    contractSigned: date().nullable(),
    contactName: string().nullable(),
    contactName2: string().nullable(),
    primaryEmail: string().email().nullable(),
    secondaryEmail: string().email().nullable(),
    thirdEmail: string().email().nullable(),
    service: string().nullable(),
    noOfAsins: number()
      .min(0, 'no of asin must be greater than or equal to 1')
      .required('no of asin is required'),
    accountStatus: string().nullable(),
    website: string().url().nullable(),
    aboutUs: string().nullable(),
    overview: string().nullable(),
    painPoints: string().nullable(),
    goals: string().nullable(),
    categoryList: array()
      .of(
        object().shape({
          category: string().required('Required'),
        })
      )
      .nullable(),
    amazonPageUrl: string().url().nullable(),
    asinList: array()
      .of(
        object().shape({
          asin: string()
            .trim()
            .when('service', {
              is: (service) => service !== 'Walmart',
              then: string().matches(/([A-Z0-9]{10})/, 'Incorrect ASIN format'),
              otherwise: string().matches(
                /(^\d{0,9}$)/,
                'Incorrect Item ID format'
              ),
            })
            .required('Required'),
        })
      )
      .nullable(),
    otherNotes: string().nullable(),
    status: string().required(),
    zohoId: string().nullable(),
    chargeAdminFee: boolean(),
    reference_id: string().nullable(),
  });

  const onSubmit = async (val) => {
    let values = { ...val };
    delete values.addonTemp;
    delete values.noOfAsins;

    try {
      setSaving(true);
      if (action == 'add') {
        await axios.post(`/agency/clients`, values);
      } else {
        await axios.put(`/agency/clients/${payload.agencyClientId}`, values);
      }
      dispatch(
        setAlert(
          'success',
          `Client ${action}ed`,
          `Details successfully saved. ${
            values.status === 'created' || values.status === 'resend'
              ? 'An email with an invite link will be sent shortly'
              : ''
          }`
        )
      );

      history.goBack();
    } catch (err) {
      dispatch(setAlert('error', 'Create failed', err.response.data.message));
    } finally {
      setSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ errors, setFieldValue, submitForm, values }) => (
        <Form>
          <FormikErrorNotification />
          {/* ::Hide Subscription and Commission form partial => should update in Sales module */}
          {payload && payload?.sales?.salesClientId ? (
            <></>
          ) : (
            <>
              <h4 className="text-xl font-bold">Subscription</h4>
              <SubscriptionFormPartial action={action} formValues={values} />
              <h4 className="text-xl font-bold mt-10">Commission</h4>
              <CommissionFormPartial />
            </>
          )}

          <h4 className="text-xl font-bold mt-10">Client Details</h4>
          <DetailsFormPartial action={action} service={values.service} />
          <div className="my-10 flex justify-between items-center">
            <div className="space-x-4">
              <BackButton title="Cancel" backDefault="/clients" />

              {buttonActions[action].map((button) => (
                <Button
                  key={`btn-${button.status}`}
                  classes="border-0 font-bold tracking-wider "
                  bgColor={`${button.color}-600`}
                  hoverColor={`${button.color}-700`}
                  roundedSize="2xl"
                  textColor="white"
                  px={12}
                  py={1.5}
                  shadow=""
                  type="button"
                  showLoading={false}
                  loading={saving}
                  onClick={() => {
                    setFieldValue('status', button.status);
                    submitForm();
                  }}
                >
                  {button.text}
                </Button>
              ))}

              {payload &&
                !payload.deletedAt &&
                payload.status === 'registered' && (
                  <Button
                    classes="border-0 font-bold tracking-wider "
                    bgColor="red-600"
                    hoverColor="red-700"
                    roundedSize="2xl"
                    textColor="white"
                    px={12}
                    py={1.5}
                    shadow=""
                    type="button"
                    showLoading={false}
                    loading={saving}
                    onClick={() => {
                      setFieldValue('status', 'offline');
                      setIsOpenOfflineConfirmation(true);
                    }}
                  >
                    Update &amp; Create Offline Subscription
                  </Button>
                )}
            </div>
            {saving && <Loading />}
            {!isEmpty(errors) && (
              <div className="text-red-700">Error(s) found!</div>
            )}
          </div>
          <ConfirmationModal
            title={t('NewSubscription.Offline.Title')}
            content={
              <div>
                <span>{t('NewSubscription.Offline.Description')}</span>
                <label className="text-xs mt-4 mx-5 flex justify-center items-center">
                  <Toggle
                    onChange={(e) => setFieldValue('chargeAdminFee', e)}
                    checked={values.chargeAdminFee}
                  />
                  <span className="ml-2 text-red-700 text-left">
                    {t('NewSubscription.Offline.Charge')}
                  </span>
                </label>
              </div>
            }
            open={isOpenOfflineConfirmation}
            setOpen={setIsOpenOfflineConfirmation}
            onOkClick={submitForm}
            onCancelClick={() => setIsOpenOfflineConfirmation(false)}
            okLoading={saving}
            showOkLoading={true}
          />
        </Form>
      )}
    </Formik>
  );
};

export default NewClientForm;
