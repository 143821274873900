import { percentageFormatter } from 'utils/formatters';

const ActiveCampaignsOverview = ({ className = '', data }) => {
  return (
    <div
      className={`${className} bg-primary text-white py-2 px-10 grid grid-cols-11`}
    >
      <div className="flex items-center col-span-3">
        <p className="text-4xl mr-2 font-bold text-white tracking-3/4 font-inter">
          {data.total.count}
        </p>
        <p className="text-lg font-bold text-white tracking-3/4 font-inter">
          Active Campaigns
        </p>
      </div>

      <div className="col-span-4 text-9 leading-normal text-white w-full py-4 md:py-0 md:px-4 mb-4 md:mb-0 border-r border-l">
        <table className="w-full">
          <thead className="font-bold">
            <tr className="">
              <td width="30%" className="pb-2 text-center">
                Type
              </td>
              <td width="30%" className="pb-2 text-center">
                # of campaigns
              </td>
              <td width="30%" className="pb-2 text-center">
                Sales %
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="30%" className="text-center">
                SP
              </td>
              <td width="30%" className="text-center">
                {data.sp.count}
              </td>
              <td width="30%" className="text-center">
                {percentageFormatter(
                  data.total.sales ? data.sp.sales / data.total.sales : 0
                )}
              </td>
            </tr>
            <tr>
              <td width="30%" className="text-center">
                SB
              </td>
              <td width="30%" className="text-center">
                {data.sb.count}
              </td>
              <td width="30%" className="text-center">
                {percentageFormatter(
                  data.total.sales ? data.sb.sales / data.total.sales : 0
                )}
              </td>
            </tr>
            <tr>
              <td width="30%" className="text-center">
                SD
              </td>
              <td width="30%" className="text-center">
                {data.sd.count}
              </td>
              <td width="30%" className="text-center">
                {percentageFormatter(
                  data.total.sales ? data.sd.sales / data.total.sales : 0
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-span-4 text-9 leading-normal text-white w-full py-4 md:py-0 md:px-4 mb-4 md:mb-0">
        <table className="w-full">
          <thead className="font-bold">
            <tr>
              <td width="30%" className="pb-2 text-center">
                Targeting Type
              </td>
              <td width="30%" className="pb-2 text-center">
                # of campaigns
              </td>
              <td width="30%" className="pb-2 text-center">
                Sales %
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="30%" className="text-center">
                Automatic
              </td>
              <td width="30%" className="text-center">
                {data.auto.count}
              </td>
              <td width="30%" className="text-center">
                {percentageFormatter(
                  data.total.sales ? data.auto.sales / data.total.sales : 0
                )}
              </td>
            </tr>
            <tr>
              <td width="30%" className="text-center">
                Manual
              </td>
              <td width="30%" className="text-center">
                {data.manual.count}
              </td>
              <td width="30%" className="text-center">
                {percentageFormatter(
                  data.total.sales ? data.manual.sales / data.total.sales : 0
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveCampaignsOverview;
