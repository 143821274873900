import axios from 'axios';
import moment from 'moment';
import { Modal, Table, UserDisplay } from 'components';
import { useState, useEffect } from 'react';
import {
  currencyFormatter,
  dateFormatterUTC,
  percentageFormatter,
} from 'utils/formatters';
import ClientLink from 'features/clients/List/components/ClientLink';
import { XIcon, UserCircleIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const ClientsModal = ({ open, setOpen, filterGroups = [] }) => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/clients/list', {
        params: {
          ...params,
          filterGroups: JSON.stringify(filterGroups),
          attributes: [
            'client',
            'totalSales',
            'tacos',
            'service',
            'terminatedAt',
            'contractSigned',
            'agencyClientId',
            'advertisingCTR',
            'advertisingCPC',
            'advertisingCVR',
            'advertisingCost',
            'advertisingACOS',
            'advertisingSales',
            'advertisingClicks',
            'advertisingImpressions',
            'organicSalesPercentage',
            'advertisingSalesPercentage',
          ],
        },
      });

      if (isSubscribed) {
        setClients(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [params, filterGroups]);

  const columns = [
    {
      dataField: 'client',
      text: 'Client',
      sort: true,
      formatter: (cell, row) => (
        <ClientLink
          className="text-sm text-red-500 hover:text-red-900 inline-flex items-center"
          client={row}
        >
          {cell}
        </ClientLink>
      ),
    },
    {
      dataField: 'contractSigned',
      text: 'Contract Date',
      sort: true,
      formatter: (cell) => {
        return cell ? dateFormatterUTC(cell) : <>&mdash;</>;
      },
    },
    {
      dataField: 'terminatedAt',
      text: 'Termination Date',
      sort: true,
      formatter: (cell) => {
        return cell ? dateFormatterUTC(cell) : <>&mdash;</>;
      },
    },
    {
      dataField: 'totalSales',
      text: 'Total Sales',
      sort: true,
      formatter: (cell, row) => currencyFormatter(cell),
    },
    {
      dataField: 'advertisingCost',
      text: 'PPC Spend',
      sort: true,
      formatter: (cell, row) => currencyFormatter(cell),
    },
    {
      dataField: 'advertisingSales',
      text: 'PPC Sales',
      sort: true,
      formatter: (cell, row) => currencyFormatter(cell),
    },
    {
      dataField: 'advertisingACOS',
      text: 'ACOS%',
      sort: true,
      formatter: (cell, row) => percentageFormatter(cell),
    },
    {
      dataField: 'tacos',
      text: 'TACOS%',
      sort: true,
      formatter: (cell, row) => percentageFormatter(cell),
    },
    {
      dataField: 'organicSalesPercentage',
      text: 'Organic Sales %',
      sort: true,
      formatter: (cell, row) => percentageFormatter(cell),
    },
    {
      dataField: 'advertisingSalesPercentage',
      text: 'PPC Sales %',
      sort: true,
      formatter: (cell, row) => percentageFormatter(cell),
    },
    {
      dataField: 'agencyClientId',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <Link
            className="px-3 rounded-xl border border-gray-600 text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 hover:bg-gray-300 transition-all ease-in duration-75 disabled:opacity-30"
            to={`/clients/redirect/${row.agencyClientId}`}
          >
            <UserCircleIcon className="w-3.5 h-3.5 inline mr-1" /> View
          </Link>
        );
      },
    },
  ];
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sorts;

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-full max-w-3xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex justify-between items-center px-8 py-4 border-b border-grayscale-500">
          <h4 className="text-grayscale-700 leading-1.5">Client List</h4>

          <XIcon
            className="w-5 h-5 text-grayscale-700 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="overflow-y-auto mt-2 px-8">
          <div className="mt-8">
            <Table
              loading={loading}
              keyField="agencyClientId"
              columns={columns.map((column) => {
                return {
                  ...column,
                  classes: `py-5 px-4 text-13 text-grayscale-800 leading-normal whitespace-nowrap`,
                  headerClasses: `py-5 px-4 text-13 font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
                };
              })}
              data={clients}
              onTableChange={onTableChange}
              params={params}
              bordered={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClientsModal;
