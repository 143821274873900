import { CogIcon } from '@heroicons/react/outline';

const useUserMenu = () => {
  return [
    {
      name: 'Settings',
      href: '/user-settings',
      icon: CogIcon,
      visible: () => true,
    },
  ];
};

export default useUserMenu;
