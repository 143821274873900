import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { dateFormatter } from 'utils/formatters';
import useAlert from 'hooks/useAlert';
import { fetchSubscription } from '../subscriptionSlice';
import ButtonLink from 'components/ButtonLink';

const CancelSubscriptionModal = ({
  open,
  setOpen,
  subscription,
  action = 'end_of_term',
}) => {
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const { pendingInvoices } = useSelector((state) => state.subscription);
  const [hasPendingInvoices, setHasPendingInvoices] = useState(false);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pendingInvoices && pendingInvoices.invoices.length > 0) {
      setHasPendingInvoices(true);
      let totalAmount = 0;
      if (pendingInvoices.invoices.length >= 1) {
        totalAmount = pendingInvoices.invoices.reduce(
          (a, b) => a + b.balance,
          0
        );
      } else {
        totalAmount = pendingInvoices.invoices[0].balance;
      }
      setTotal(totalAmount);
    } else {
      setHasPendingInvoices(false);
      setTotal(0);
    }
  }, [pendingInvoices]);

  const onCancel = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `subscriptions/${subscription.subscription_id}/cancel`,
        { cancelAtEnd: action === 'end_of_term' ? true : false }
      );
      alertSuccess(
        action === 'end_of_term'
          ? 'Subscription marked for cancellation'
          : 'Subscription cancelled',
        response.data.data.message
      );
      dispatch(fetchSubscription(subscription.subscription_id));
      setOpen(false);
    } catch (error) {
      //console.log(error.response.data);
      alertError('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen} align="top" as={'div'}>
      <div className="inline-block w-full max-w-lg my-16 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={`Cancel subscription ${
            action === 'immediately' ? 'immediately' : 'on next renewal'
          }`}
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <div className="p-4">
          {pendingInvoices && hasPendingInvoices && (
            <p className="bg-yellow-50 text-yellow-700 text-sm p-3 mb-3">
              Upon cancellation, Zoho Subscriptions will not charge the customer
              for any open invoices. Currently there are
              <b>&nbsp;{pendingInvoices.invoices.length}&nbsp;</b> open invoices
              amounting
              <b>&nbsp;${total}&nbsp;</b>
              due for payment. You can collect offline payment and mark the
              invoices as closed or in the event of non-payment, mark it as void
              or write off.
            </p>
          )}

          <div className="p-3 mb-3">
            {action === 'immediately' ? (
              <p className="text-gray-600 text-sm mt-1">
                Subscription will be canceled immediately and the customer will
                not be able to use the service from the moment its canceled.
              </p>
            ) : (
              <p className="text-gray-600 text-sm mt-1">
                Customer can use the service till the end of current term and
                the subscription will be canceled on&nbsp;
                <span className="font-medium text-black">
                  {dateFormatter(subscription.next_billing_at)}
                </span>
              </p>
            )}
            <div className="flex items-center space-x-4">
              <ButtonLink
                classes="tracking-wider font-bold"
                color="red"
                onClick={() => setOpen(false)}
              >
                Close
              </ButtonLink>

              <Button
                classes="border-0 font-bold tracking-wider mr-2 my-8"
                bgColor="red-600"
                hoverColor="red-700"
                roundedSize="2xl"
                textColor="white"
                px={4}
                py={1.5}
                shadow=""
                type="button"
                onClick={onCancel}
                loading={loading}
                showLoading={true}
              >
                Cancel{' '}
                {action === 'immediately' ? 'Immediately' : 'on Next Renewal'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
