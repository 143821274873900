import { Modal, Table } from 'components';
import ModalHeader from 'components/ModalHeader';
import useAgencyClient from 'hooks/useAgencyClient';
import { useEffect, useState } from 'react';
import ProfileLogo from '../Dashboard/components/ProfileLogo';
import Loading from 'components/Loading';
import { DateTime } from 'luxon';
import PriceLabel from 'components/PriceLabel';
import PaidReviewsStatus from './components/PaidReviewsStatus';
import DataTable from 'components/DataTable';
import axios from 'axios';
import paidEmpty from 'assets/images/paid-reviews-empty.svg';
import Badge from 'components/Badge';
import classNames from 'utils/classNames';
import classnames from 'classnames';
import { dateFormatter } from 'utils/formatters';
import useQueryParams from 'hooks/useQueryParams';

const PaidReviewDetailsModal = ({ open, setOpen, paidReview }) => {
  const { agencyClient } = useAgencyClient();
  const { params, updateParams } = useQueryParams({
    pageSize: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    if (paidReview) loadDetails();

    return () => {
      setReviews(null);
    };
  }, [paidReview]);

  const loadDetails = async () => {
    setLoading(true);
    await axios
      .get(`/agency/paid-reviews/${paidReview.asin}/${paidReview.id}`)
      .then((response) => {
        const d = response.data.data;
        setReviews({
          rows: d.data,
          count: d.total,
          page: d.current_page,
          to: d.to,
          from: d.from,
          pageSize: d.per_page,
        });
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    { text: 'Reviewer Name', dataField: 'claimed_by_name' },
    {
      text: 'Reviewer Feedback',
      dataField: 'review_content',
      headerStyle: {
        minWidth: '400px',
      },
    },
    {
      dataField: 'review_verified_at',
      text: 'Completion Date',
      sort: false,
      headerStyle: {
        minWidth: '120px',
      },
      formatter: (cell, row) => dateFormatter(cell),
    },
    {
      dataField: 'status',
      text: 'Review Status',
      sort: false,
      headerStyle: {
        minWidth: '120px',
      },
      formatter: (cell, row) => (
        <Badge
          classes={classNames(
            classnames({
              'bg-green-100 text-green-600': cell === 'fulfilled',
              'bg-yellow-100 text-yellow-600': cell === 'pending',
              'bg-red-100 text-red-600': cell === 'cancelled',
              'bg-blue-100 text-blue-600': cell === 'completed',
            }),
            'capitalize rounded-2xl text-xs text-center justify-center w-full'
          )}
          rounded=""
          textSize=""
          color=""
          padding="px-3 py-2.5"
        >
          {cell}
        </Badge>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-20"
    >
      <div className="inline-block w-full max-w-7xl my-12 overflow-hidden text-left transition-all transform bg-white shadow-xl">
        <ModalHeader
          title="Paid Reviews"
          setOpen={setOpen}
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
          xAlign="items-start"
          classes="capitalize"
        />
        {paidReview ? (
          <div
            className="overflow-y-auto overflow-x-hidden sm:flex justify-around"
            style={{ height: '78vh' }}
          >
            <div className="px-8 py-10 w-1/4">
              <div className="flex flex-row space-x-3">
                <div className="w-12 h-12 rounded-full overflow-hidden">
                  <ProfileLogo
                    agencyClientId={agencyClient.agencyClientId}
                    deletedAt={true}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-grayscale-600 text-sm">Client</span>
                  <h3 className="text-2xl tracking-3/4">
                    {agencyClient.client}
                  </h3>
                </div>
              </div>
              <div className="relative mt-8 text-center">
                <div className="relative overflow-hidden w-56 h-28 -mb-14">
                  <div
                    className="absolute t-0 l-0 w-56 h-56 -mb-4 rounded-full"
                    style={{
                      border: '10px solid #ececec',
                      borderBottomColor: '#00BA88',
                      borderRightColor: '#00BA88',
                      transform: `rotate(${
                        (paidReview.fulfilled_count /
                          paidReview.reviews_count) *
                          180 +
                        45
                      }deg)`,
                    }}
                  ></div>
                </div>
                <div className="relative -left-3 bottom-3">
                  <div className="flex flex-row items-start justify-center text-2xl space-x-2">
                    <span className="text-4xl text-success">
                      {paidReview.fulfilled_count ?? 0}
                    </span>
                    <span>/</span>
                    <span>{Math.round(paidReview.reviews_count)}</span>
                  </div>
                  <span className="text-grayscale-600 text-sm pb-3">
                    Reviews Completed
                  </span>
                </div>
              </div>
              <dl className="mt-8">
                <dt className="text-sm text-grayscale-700">
                  Estimated Completion Date
                </dt>
                <dd className="font-inter tracking-3/4 mt-1">
                  {DateTime.fromFormat(
                    paidReview.est_completion_date,
                    'yyyy-LL-dd'
                  ).toFormat('LLLL dd, yyyy')}
                </dd>
                <dt className="text-sm text-grayscale-700 mt-4">Upsell ID</dt>
                <dd className="font-inter tracking-3/4 mt-1">
                  {paidReview.upsell_id ?? 'created from SMTG'}
                </dd>
                <dt className="text-sm text-grayscale-700 mt-4">ASIN</dt>
                <dd className="font-inter tracking-3/4 mt-1">
                  {paidReview.asin}
                </dd>
                <dt className="text-sm text-grayscale-700 mt-4">Total Cost</dt>
                <dd className="font-inter tracking-3/4 mt-1">
                  <PriceLabel
                    currency="$"
                    price={paidReview.cost * paidReview.reviews_count}
                  />
                </dd>
                <dt className="text-sm text-grayscale-700 mt-4">Status</dt>
                <dd className="text-xl tracking-3/4 mt-1">
                  <PaidReviewsStatus status={paidReview.status} />
                </dd>
              </dl>
            </div>
            <div className="w-3/4 bg-grayscale-300 px-4 py-10">
              <h4 className="text-3xl font-bold">Updates</h4>
              <Table
                columns={tableColumns}
                data={reviews}
                onTableChange={(type, { page, sizePerPage }) => {
                  updateParams({ page, pageSize: sizePerPage });
                }}
                params={params}
                keyField="id"
                loading={loading}
                rowClasses="hover:bg-gray-50 group"
                noDataDisplay={
                  <div className="flex flex-col w-full justify-center items-center space-y-8 pt-12">
                    <span>
                      <img src={paidEmpty} />
                    </span>
                    <span className="text-2xl font-inter text-grayscale-800">
                      There are no paid reviews for this ASIN yet
                    </span>
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Modal>
  );
};

export default PaidReviewDetailsModal;
