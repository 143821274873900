import axios from 'axios';
import { reverse, upperFirst } from 'lodash';
import { useState, useEffect } from 'react';

import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';

import MetricCard from '../MetricCard/MetricCard';
import MetricsChart from './MetricsChart';
import metrics from '../../utils/metrics';

const attributes = [
  'sales',
  'cost',
  'profitMargin',
  'acos',
  'orders',
  'unitsSold',
  'cr',
  'cpc',
  'impressions',
  'clicks',
  'ctr',
  'cpcon',
  'averageSalesPrice',
];

const TrendsModal = ({
  url,
  entity = {},
  accountId,
  marketplace,
  startDate,
  endDate,
  title,
  open,
  setOpen,
  additionalParams = {},
  overviewAttributes = [],
}) => {
  const [trends, setTrends] = useState([]);

  useEffect(() => {
    if (!url) return;
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(url, {
        params: {
          accountId,
          marketplace,
          startDate,
          endDate,
          attributes,
          ...additionalParams,
        },
      });

      if (isSubscribed) {
        setTrends(reverse(response.data.data));
      }
    };

    if (url) fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [url, accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    <Modal open={open} setOpen={setOpen} persistent={true} as="div" align="top">
      <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-grayscale-400 shadow-xl">
        <ModalHeader
          title="Trends"
          titleClasses="text-base font-normal text-grayscale-700 font-sourceSansPro leading-1.5"
          classes="bg-grayscale-300"
          setOpen={setOpen}
          showCloseButton={true}
        />

        <div className="p-4 bg-grayscale-400">
          <p className="tracking-3/4 text-grayscale-900 leading-1.2 text-25 font-inter mb-5">
            {title}
          </p>

          {!!overviewAttributes.length && (
            <div className="grid grid-cols-5 gap-5 mb-8">
              {overviewAttributes
                .map((attr) => metrics.find((m) => m.key === attr))
                .map((metric) => (
                  <MetricCard
                    loading={false}
                    key={`trends-modal-${metric.key}`}
                    title={metric.title}
                    reversePercentage={!!metric.reverse}
                    formatter={metric.formatter}
                    value={entity[metric.key]}
                    previousValue={entity[`previous${upperFirst(metric.key)}`]}
                    prefixValue={metric.prefix}
                    suffixValue={metric.suffix}
                    dividendTitle={
                      metric.dividend &&
                      metrics.find((m) => m.key === metric.dividend)
                        ? metrics.find((m) => m.key === metric.dividend).title
                        : ''
                    }
                    divisorTitle={
                      metric.divisor &&
                      metrics.find((m) => m.key === metric.divisor)
                        ? metrics.find((m) => m.key === metric.divisor).title
                        : ''
                    }
                    dividendValue={
                      metric.dividend &&
                      metrics.find((m) => m.key === metric.dividend)
                        ? metrics
                            .find((m) => m.key === metric.dividend)
                            .rawFormatter(entity[metric.dividend])
                        : ''
                    }
                    divisorValue={
                      metric.divisor &&
                      metrics.find((m) => m.key === metric.divisor)
                        ? metrics
                            .find((m) => m.key === metric.divisor)
                            .rawFormatter(entity[metric.divisor])
                        : ''
                    }
                  />
                ))}
            </div>
          )}

          <MetricsChart
            attributes={['sales', 'cost', 'clicks']}
            records={trends}
            className="w-full py-4 bg-white"
          />

          <div className="bg-white overflow-x-auto overflow-hidden flex flex-col mt-5">
            <table className="min-w-full">
              <tbody>
                <tr className="text-grayscale-600 font-normal text-mini font-sourceSansPro bg-grayscale-200 leading-normal border-b border-grayscale-500">
                  <td className="py-5 px-4 text-left whitespace-nowrap sticky left-0 bg-white">
                    Date Coverage
                  </td>
                  {attributes
                    .map((attr) => metrics.find((m) => m.key === attr))
                    .map((metric) => (
                      <td
                        className="py-5 px-4 text-right whitespace-nowrap"
                        key={`trends-modal-info-${metric.key}`}
                      >
                        {metric.title}
                      </td>
                    ))}
                </tr>
                <tr className="text-grayscale-800 font-normal text-mini font-sourceSansPro leading-normal">
                  <td className="py-5 px-4 text-left whitespace-nowrap sticky left-0 bg-white">
                    Summary
                  </td>
                  {attributes
                    .map((attr) => metrics.find((m) => m.key === attr))
                    .map((metric) => (
                      <td
                        className="py-5 px-4 text-right"
                        key={`trends-modal-info-${metric.key}-summary`}
                      >
                        {metric.rawFormatter(entity[metric.key])}
                      </td>
                    ))}
                </tr>

                {trends.map((record) => (
                  <tr
                    className="text-grayscale-800 font-normal text-mini font-sourceSansPro leading-normal"
                    key={`trends-modal-info-${record.date}-summary`}
                  >
                    <td className="py-5 px-4 text-left whitespace-nowrap sticky left-0 bg-white">
                      {record.startDate === record.endDate
                        ? record.date
                        : `${record.startDate} -- ${record.endDate}`}
                    </td>
                    {attributes
                      .map((attr) => metrics.find((m) => m.key === attr))
                      .map((metric) => (
                        <td
                          className="py-5 px-4 text-right"
                          key={`trends-modal-info-${record.date}-summary-${metric.key}`}
                        >
                          {metric.rawFormatter(record[metric.key])}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrendsModal;
