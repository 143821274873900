import React, { useRef, useState } from 'react';
import axios from 'axios';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Popover } from '@headlessui/react';
import { dateFormatter } from 'utils/formatters';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchSubscription } from '../subscriptionSlice';
import useAlert from 'hooks/useAlert';
import Button from 'components/Button';

const ChangeBillingDatePicker = ({ subscription }) => {
  const { last_billing_at, next_billing_at, subscription_id } = subscription;
  const [saving, setSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState(next_billing_at);
  const closeRef = useRef();
  const dispatch = useDispatch();
  const { alertError, alertSuccess } = useAlert();

  const dateVal = new Date(selectedDate);
  const initial = last_billing_at
    ? moment(last_billing_at).clone().add(1, 'month').toDate()
    : null;

  const past = { before: initial };
  const selectedDays = { highlighted: dateVal };
  const modifiers = {
    highlighted: dateVal,
    initial: initial,
  };

  const onChangeBillingDate = async () => {
    const renewalAt = moment.utc(selectedDate).format('YYYY-MM-DD');
    setSaving(true);
    try {
      const res = await axios.post(
        `subscriptions/${subscription_id}/postpone`,
        { renewal_at: renewalAt }
      );

      if (res.data.data.code === 0) {
        closeRef.current?.click();
        dispatch(fetchSubscription(subscription_id));
        alertSuccess('Billing Date updated', res.data.data.message);
      } else {
        alertError('Billing Date update failed', res.data.data.message);
      }
    } catch (error) {
      console.log(error.response.data);
      alertError('Update failed', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Popover className="relative leading-none">
      <Popover.Button
        ref={closeRef}
        className="text-red-400 text-xs tracking-widest font-bold"
      >
        Change
      </Popover.Button>
      <Popover.Panel className="tail absolute z-10 shadow-md border bg-white text-sm rounded-md">
        <div className="text-xs pt-5 pb-3 px-4 text-gray-600 border-b font-inter">
          This subscription will be renewed on{' '}
          <b>{dateFormatter(next_billing_at)}</b>. If you change it, Zoho
          Subscriptions will not make any pro rated credit or charge for the
          affected period.
        </div>
        <div className="px-2 text-sm">
          <DayPicker
            showOutsideDays
            disabledDays={past}
            modifiers={modifiers}
            selectedDays={selectedDays}
            fromMonth={initial}
            initialMonth={initial}
            onDayClick={(day) => setSelectedDate(day)}
          />
        </div>
        <div className="text-xs border-t pt-3 pb-5 px-5 flex ">
          <Popover.Button
            as="button"
            className="py-1 px-3 mr-2 tracking-widest font-bold text-red-500"
          >
            Cancel
          </Popover.Button>
          <Button
            classes="border-0 font-bold tracking-wider "
            bgColor="red-700"
            hoverColor="red-400"
            roundedSize="2xl"
            textColor="white"
            px={4}
            py={0.5}
            shadow=""
            type="button"
            onClick={onChangeBillingDate}
            showLoading={true}
            loading={saving}
          >
            Save
          </Button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
export default ChangeBillingDatePicker;
