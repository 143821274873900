import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DatePicker from 'features/datePicker/DatePicker';

import SmartFilter from '../components/SmartFilter';
import ToggleChart from './components/ToggleChart';

import AnalyticsOverview from './sections/AnalyticsOverview';
import MetricsChart from './sections/MetricsChart';
import ActiveCampaignsOverview from './sections/ActiveCampaignsOverview';
import CampaignTypesOverview from './sections/CampaignTypesOverview';
import CampaignTypesBreakdown from './sections/CampaignTypesBreakdown';
import TargetingTypeBreakdown from './sections/TargetingTypesBreakdown';
import BiggestChangesByCampaign from './sections/BiggestChangesByCampaign';
import BiggestChangesByProduct from './sections/BiggestChangesByProduct';
import BiggestChangesByKeyword from './sections/BiggestChangesByKeyword';
import ConvertersOverview from './sections/ConvertersOverview';
import KeywordPerformance from './sections/KeywordPerformance';
import MostImprovedTargeting from './sections/MostImprovedTargeting';
import MostImprovedProductAds from './sections/MostImprovedProductAds';
import AsinLevelPerformance from './sections/AsinLevelPerformance';
import TargetingRanking from './sections/TargetingRanking';
import ExportButton from './components/ExportButton';

const Analytics = () => {
  const { account, marketplace } = useSelector((state) => state.account);
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [displayChart, setDisplayChart] = useState(false);
  const [additionalParams, setAdditionalParams] = useState({});
  const [filters, setFilters] = useState({
    advCampaignIds: [],
    advPortfolioIds: [],
    campaignTypes: [],
    productIds: [],
  });

  const [comments, setComments] = useState({
    overview: '',
    breakdown: '',
    convertersVsNonConverters: '',
    keywordsPerformance: '',
    targetingRanking: '',
    mostImprovedTargeting: '',
    mostImprovedProductAds: '',
    asinLevelPerformance: '',
  });

  useEffect(() => {
    let params = {};
    if (filters.advCampaignIds.length) {
      params.advCampaignIds = filters.advCampaignIds;
    }

    if (filters.advPortfolioIds.length) {
      params.advPortfolioIds = filters.advPortfolioIds;
    }

    if (filters.campaignTypes.length) {
      params.campaignTypes = filters.campaignTypes;
    }

    if (filters.productIds.length) {
      params.productIds = filters.productIds;
    }

    setAdditionalParams(params);
  }, [filters]);

  return (
    <div className="pb-8">
      <div className="grid grid-cols-3 gap-4">
        <DatePicker position="left" showLabel={false} />

        <div className="flex w-full items-center">
          <SmartFilter
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            campaignIds={filters.advCampaignIds}
            campaignTypes={filters.campaignTypes}
            portfolioIds={filters.advPortfolioIds}
            productIds={filters.productIds}
            onChange={setFilters}
          />

          <ExportButton
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            comments={comments}
            filters={filters}
          />
        </div>

        <ToggleChart
          className="col-start-3"
          visible={displayChart}
          setVisible={setDisplayChart}
        />
      </div>

      <AnalyticsOverview
        className="mt-10 grid grid-cols-6 gap-5"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
        comment={comments.overview}
        onChangeComment={(overview) => setComments({ ...comments, overview })}
      />

      {displayChart && (
        <MetricsChart
          className="mt-10"
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          additionalParams={additionalParams}
        />
      )}

      <ActiveCampaignsOverview
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
        comment={comments.breakdown}
        onChangeComment={(breakdown) => setComments({ ...comments, breakdown })}
      />

      <CampaignTypesOverview
        className="mt-10 grid grid-cols-1 2xl:grid-cols-3 gap-6"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
      />

      <CampaignTypesBreakdown
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
      />

      {'productIds' in additionalParams ? null : (
        <TargetingTypeBreakdown
          className="mt-10"
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          additionalParams={additionalParams}
        />
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <BiggestChangesByCampaign
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          additionalParams={additionalParams}
          className="mt-10"
        />

        <BiggestChangesByProduct
          accountId={account.accountId}
          marketplace={marketplace.details.countryCode}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          additionalParams={additionalParams}
          className="mt-10"
        />
      </div>

      {'productIds' in additionalParams ? null : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <BiggestChangesByKeyword
              title="Biggest Changes By SP Keywords"
              accountId={account.accountId}
              marketplace={marketplace.details.countryCode}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              campaignType="sponsoredProducts"
              additionalParams={additionalParams}
              className="mt-10"
            />

            <BiggestChangesByKeyword
              title="Biggest Changes By SB Keywords"
              accountId={account.accountId}
              marketplace={marketplace.details.countryCode}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              campaignType="sponsoredBrands"
              additionalParams={additionalParams}
              className="mt-10"
            />
          </div>

          <ConvertersOverview
            className="mt-10 grid grid-cols-2 grid-rows-1 rounded-3xl bg-white bs-shadow py-6"
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            additionalParams={additionalParams}
            comment={comments.convertersVsNonConverters}
            onChangeComment={(convertersVsNonConverters) =>
              setComments({ ...comments, convertersVsNonConverters })
            }
            tableClassName="col-start-2"
            graphClassName="col-start-1 row-start-1"
            tableHeaderClassName="flex items-center justify-between px-8 pb-6"
            showAllGraph={true}
            graphTitle="Converter Share"
          />

          <KeywordPerformance
            className="mt-10"
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            additionalParams={additionalParams}
            comment={comments.keywordsPerformance}
            onChangeComment={(keywordsPerformance) =>
              setComments({ ...comments, keywordsPerformance })
            }
          />

          <TargetingRanking
            className="mt-10"
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            additionalParams={additionalParams}
            comment={comments.targetingRanking}
            onChangeComment={(targetingRanking) =>
              setComments({ ...comments, targetingRanking })
            }
          />

          <MostImprovedTargeting
            className="mt-10"
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            additionalParams={additionalParams}
            comment={comments.mostImprovedTargeting}
            onChangeComment={(mostImprovedTargeting) =>
              setComments({ ...comments, mostImprovedTargeting })
            }
          />
        </>
      )}

      <MostImprovedProductAds
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
        comment={comments.mostImprovedProductAds}
        onChangeComment={(mostImprovedProductAds) =>
          setComments({ ...comments, mostImprovedProductAds })
        }
      />

      <AsinLevelPerformance
        className="mt-10"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={additionalParams}
        comment={comments.asinLevelPerformance}
        onChangeComment={(asinLevelPerformance) =>
          setComments({ ...comments, asinLevelPerformance })
        }
      />
    </div>
  );
};

export default Analytics;
