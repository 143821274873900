import { startCase } from 'lodash';
import classnames from 'classnames';
import classNames from 'utils/classNames';

const StatusBadge = ({ status }) => {
  const colors = {
    ACTIVE: 'bg-#CFFFDD text-success-dark',
    INACTIVE: 'bg-error-light text-error-dark',
    subscribed: 'bg-grayscale-800 text-grayscale-300',
    draft: 'bg-gray-100 text-gray-600',
    registered: 'bg-yellow-100 text-yellow-600',
    cancelled: 'bg-error-light text-error-dark',
    invited: 'bg-blue-100 text-blue-600',
  };

  return (
    <span
      className={classNames(
        colors[status] ?? '',
        'capitalize text-13 rounded-40 px-4 py-2'
      )}
    >
      {startCase(status.toLowerCase())}
    </span>
  );
};

export default StatusBadge;
