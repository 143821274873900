import React, { useEffect, useState } from 'react';

import moment from 'moment';
import StopWatch from './StopWatch';
moment.tz.setDefault('America/Toronto');

const TaskTimeTracker = ({
  onChange,
  taskItemId,
  totalTrackedTime,
  runningTimeLog,
  onChangeTimeTracker,
  detailPage = false,
  enable = true,
}) => {
  const [isStop, setIsStop] = useState(false);
  const [stopwatchOffset, setStopwatchOffset] = useState(null);

  const getDuration = () => {
    let duration = totalTrackedTime;

    if (runningTimeLog) {
      duration += Math.abs(moment().diff(runningTimeLog.startedAt, 'seconds'));
    }

    return duration;
  };

  const getOffset = () => {
    let time = moment().toDate();
    time.setSeconds(time.getSeconds() + getDuration());
    return time;
  };

  useEffect(() => {
    setStopwatchOffset(getOffset());
    setIsStop(!runningTimeLog);
  }, [runningTimeLog]);

  return (
    <>
      {stopwatchOffset && (
        <StopWatch
          isStop={isStop}
          stopwatchOffset={stopwatchOffset}
          taskItemId={taskItemId}
          onChange={onChange}
          onChangeTimeTracker={onChangeTimeTracker}
          detailPage={detailPage}
          setIsStop={setIsStop}
          enable={enable}
        />
      )}
    </>
  );
};

export default TaskTimeTracker;
