import React from 'react';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SignIn from 'features/auth/SignIn';
import Register from 'features/auth/Register';
import ForgotPassword from 'features/auth/ForgotPassword';
import ForgotPasswordSuccess from 'features/auth/ForgotPasswordSuccess';
import ResetPasswordSuccess from 'features/auth/ResetPasswordSuccess';
import ResetPassword from 'features/auth/ResetPassword';
import SignOut from 'features/auth/SignOut';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import Dashboard from 'features/auth/Dashboard';
import ChangePassword from 'features/auth/ChangePassword';

const AuthRoutes = [
  <PublicRoute path="/sign-in" component={SignIn} />,
  <PublicRoute path="/register/:inviteToken" component={Register} exact />,
  <PublicRoute path="/forgot-password" component={ForgotPassword} exact />,
  <PublicRoute
    path="/forgot-password/success"
    component={ForgotPasswordSuccess}
    exact
  />,
  <PublicRoute
    path="/reset-password/success"
    component={ResetPasswordSuccess}
    exact
  />,
  <PublicRoute
    path="/reset-password/:resetToken"
    component={ResetPassword}
    exact
  />,
  <PrivateRoute path="/sign-out" component={SignOut} exact />,
  <PrivateRoute path="/change-password" component={ChangePassword} exact />,
  <PrivateRoute
    path="/dashboard"
    component={Dashboard}
    layout={AgencyLayout}
    access={['agency']}
    exact
  />,
];
export default AuthRoutes;
