const URL = process.env.REACT_APP_FILE_URL;

export const getFileUrl = (path) => (path ? `${URL}/${path}` : '');

export const getFileThumbnailUrl = (path, extension, conversions = {}) => {
  if (!path) return '';

  const thumbnailPath = path.split(extension).join(`_thumb${extension}`);

  return conversions.thumbnail ? `${URL}/${thumbnailPath}` : path;
};
