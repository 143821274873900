import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import ExistingPartners from './pages/ExistingPartners/ExistingPartners';
import NewPartners from './pages/NewPartners/NewPartners';
import TeamLevel from './pages/TeamLevel/TeamLevel';

const Partners = () => {
  const { url } = useRouteMatch();

  const tabs = [
    {
      name: 'New Partners',
      href: `${url}/new`,
      exact: true,
      component: NewPartners,
    },
    {
      name: 'Existing Partners',
      href: `${url}/existing`,
      exact: true,
      component: ExistingPartners,
    },
    {
      name: 'Team Level',
      href: `${url}/team-level`,
      exact: true,
      component: TeamLevel,
    },
  ];
  return (
    <Switch>
      <Route exact path={url} render={() => <Redirect to={`${url}/new`} />} />
      {tabs.map((tab) => (
        <Route
          key={tab.href}
          path={tab.href}
          component={tab.component}
          exact={tab.exact}
        />
      ))}
    </Switch>
  );
};

export default withRouter(Partners);
