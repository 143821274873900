import { useMemo } from 'react';
import { debounce } from 'lodash';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

import FilterItemOption from './FilterItemOption';
import { SearchBar } from 'components';

const FilterItem = ({
  filter,
  FilterComponent,
  OptionComponent = FilterItemOption,
}) => {
  const onDebouncedSearch = debounce((e) => {
    filter.onSearch(e.target.value);
  }, 500);

  const count = useMemo(() => {
    let total = filter.values.length;

    if (filter.options) {
      filter.options.forEach(
        (option) => (total = total + (option.children?.values?.length ?? 0))
      );
    }

    return total;
  }, [filter]);

  return (
    <div>
      <Disclosure className="">
        {({ open }) => (
          <>
            <Disclosure.Button className="py-4 flex justify-between items-center w-full text-grayscale-800 text-base leading-1.5 font-bold">
              <div className="flex items-center space-x-2">
                <h6 className="text-13">{filter.title}</h6>

                {count > 0 && (
                  <div
                    className="bg-grayscale-500 text-13 font-bold leading-1.5 rounded-full flex items-center justify-center"
                    style={{ width: '30px', height: '30px' }}
                  >
                    {count}
                  </div>
                )}
              </div>

              <ChevronDownIcon
                className={`${
                  open ? 'rotate-180 transform' : ''
                } h-6 w-6 text-grayscale-700`}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="flex flex-col space-y-2 pb-5">
              {filter.searchable && (
                <SearchBar value="" onChange={onDebouncedSearch} />
              )}

              {FilterComponent ? (
                <FilterComponent
                  values={filter.values}
                  onChange={filter.onChange}
                />
              ) : (
                filter.options.map((option) => (
                  <OptionComponent
                    key={option.value}
                    option={option}
                    field={filter.field}
                    values={filter.values}
                    onChange={filter.onChange}
                  />
                ))
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default FilterItem;
