import axios from 'axios';
import { useState, useEffect } from 'react';

import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import { Table } from 'components';

import MetricDisplay from 'features/advertising/components/MetricDisplay';
import {
  LIST_DEFAULT_SORT,
  SORT_BY_COST,
} from 'features/advertising/utils/constants';

import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const RelatedSearchTerms = ({
  entity,
  accountId,
  marketplace,
  startDate,
  endDate,
  open,
  setOpen,
  campaignType,
  title,
  attribute,
}) => {
  const [searchTerms, setSearchTerms] = useState({ rows: [] });
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    attributes: [
      'advSearchTermId',
      'advKeywordId',
      'advTargetId',
      'query',
      'target',
      'cost',
      'sales',
      'acos',
      'orders',
      'unitsSold',
      'cpc',
      'cr',
      'clicks',
      'impressions',
      'ctr',
    ],
    include: ['previousData'],
    sort: SORT_BY_COST,
  });

  useEffect(() => {
    if (!entity[attribute]) return;

    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/account/advertising/search-terms', {
        params: {
          ...params,
          campaignType,
          accountId,
          marketplace,
          startDate,
          endDate,
          [attribute]: entity[attribute],
          include: ['previousData'],
        },
      });

      if (isSubscribed) {
        setSearchTerms(response.data.data);
        setLoading(false);
      }
    };

    if (entity) fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    entity,
    attribute,
    params,
    campaignType,
    accountId,
    marketplace,
    startDate,
    endDate,
  ]);

  const columns = [
    {
      sort: true,
      default: true,
      text: 'Spend',
      hideable: true,
      dataField: 'cost',
      category: 'performance',
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <MetricDisplay
          attribute="cost"
          currentData={cell}
          previousData={row.previousCost ?? 0}
          formatter={currencyFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      text: 'Sales',
      default: true,
      hideable: true,
      dataField: 'sales',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'sales'}
          currentData={cell}
          previousData={row.previousSales ?? 0}
          formatter={currencyFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      text: 'ACOS',
      hideable: true,
      dataField: 'acos',
      category: 'performance',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute="acos"
          currentData={cell}
          previousData={row.previousAcos ?? 0}
          formatter={percentageFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      default: true,
      text: 'Orders',
      hideable: true,
      dataField: 'orders',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'orders'}
          currentData={cell}
          previousData={row.previousOrders ?? 0}
          formatter={numberFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      default: true,
      text: 'Units',
      hideable: true,
      dataField: 'unitsSold',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'unitsSold'}
          currentData={cell}
          previousData={row.previousUnitsSold ?? 0}
          formatter={numberFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      text: 'Clicks',
      hideable: true,
      dataField: 'clicks',
      category: 'performance',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute="clicks"
          currentData={cell}
          previousData={row.previousClicks ?? 0}
          formatter={numberFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      text: 'Impressions',
      hideable: true,
      dataField: 'impressions',
      category: 'performance',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute="impressions"
          currentData={cell}
          previousData={row.previousImpressions ?? 0}
          formatter={numberFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      default: true,
      text: 'CPC',
      hideable: true,
      dataField: 'cpc',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'cpc'}
          currentData={cell}
          previousData={row.previousCpc ?? 0}
          formatter={numberFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      default: true,
      text: 'CTR',
      hideable: true,
      dataField: 'ctr',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'ctr'}
          currentData={cell}
          previousData={row.previousCTr ?? 0}
          formatter={percentageFormatter}
          position="center"
        />
      ),
    },
    {
      sort: true,
      default: true,
      text: 'Conversion Rate',
      hideable: true,
      dataField: 'cr',
      category: 'conversions',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <MetricDisplay
          attribute={'cr'}
          currentData={cell}
          previousData={row.previousCr ?? 0}
          formatter={percentageFormatter}
          position="center"
        />
      ),
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === 'sort' && params.sort === `${sortField}:${sortOrder}`) return;

    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <Modal open={open} setOpen={setOpen} persistent={true} as="div" align="top">
      <div className="inline-block w-full max-w-7xl my-8 text-left align-middle transition-all transform bg-grayscale-400 shadow-xl">
        <ModalHeader
          title="Related Search Terms"
          titleClasses="text-base font-normal text-grayscale-700 font-sourceSansPro leading-normal"
          classes="bg-grayscale-300"
          setOpen={setOpen}
          showCloseButton={true}
        />
        <div className="p-4">
          <p className="tracking-3/4 text-grayscale-900 leading-tight text-2xl">
            {title}
          </p>
          <div>
            <div className="mb-2">
              <Table
                keyField={attribute}
                columns={[
                  {
                    auto: true,
                    default: true,
                    category: 'settings',
                    dataField: 'query',
                    text: 'Keyword',
                    sort: true,
                    headerStyle: { minWidth: '200px' },
                    classes: 'sticky left-0 z-10 bg-white',
                    headerClasses: 'sticky left-0 z-10 bg-white',
                  },
                  ...columns,
                ].map((column) => {
                  return {
                    ...column,
                    classes: `${column.classes} py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
                    headerClasses: `${column.headerClasses} py-5 px-4 text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
                  };
                })}
                data={{
                  count: 1,
                  from: 1,
                  nextPage: null,
                  pageSize: 10,
                  prevPage: null,
                  rows: [{ ...entity, query: title }],
                  to: 1,
                }}
                onTableChange={onTableChange}
                params={params}
                defaultSorted={LIST_DEFAULT_SORT}
                bordered={false}
                showPagination={false}
              />
            </div>

            <Table
              keyField="advSearchTermId"
              columns={[
                {
                  auto: true,
                  default: true,
                  category: 'settings',
                  dataField: 'query',
                  text: 'Related Searchterm',
                  sort: true,
                  headerStyle: { minWidth: '200px' },
                  classes: 'sticky left-0 z-10 bg-white',
                  headerClasses: 'sticky left-0 z-10 bg-white',
                },
                ...columns,
              ].map((column) => {
                return {
                  ...column,
                  classes: `${column.classes} py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
                  headerClasses: `${column.headerClasses} py-5 px-4 text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
                };
              })}
              loading={loading}
              data={searchTerms}
              onTableChange={onTableChange}
              params={params}
              defaultSorted={LIST_DEFAULT_SORT}
              bordered={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RelatedSearchTerms;
