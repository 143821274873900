import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRoles } from 'features/roles';
import { currencyFormatter, getNameInitials } from 'utils/formatters';
import { Avatar, Table } from 'components';

const TeamAccountSpecialist = ({ supervisorId }) => {
  const { ACCOUNT_SPECIALIST } = useRoles();

  const [loading, setLoading] = useState(true);
  const [accountSpecialists, setAccountSpecialists] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    sorts: 'createdAt',
    include: ['avatar'],
    'role.code': ACCOUNT_SPECIALIST.value,
    attributes: [
      'userId',
      'firstName',
      'lastName',
      'roleId',
      'totalClients',
      'totalTerminatedClients',
      'totalFullServiceClients',
      'assignedClientsMonthlyRevenue',
    ],
  });

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    const fetchData = async () => {
      const { data } = await axios.get('/v2/users/list', {
        params: { supervisorId, ...params },
      });

      if (isSubscribed) {
        setAccountSpecialists(data.data);
      }

      setLoading(false);
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [supervisorId, params]);

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const tableColumns = [
    {
      dataField: 'firstName',
      text: 'Account Specialist',
      sort: true,
      headerStyle: { minWidth: '250px' },
      formatter: (cell, row) => (
        <div className="flex items-center space-x-2">
          <Avatar
            imageSrc={row?.avatar?.thumbnailUrl}
            initials={getNameInitials(cell, row.lastName)}
          />
          <div>
            <p className="leading-3">
              {cell} {row.lastName}
            </p>
          </div>
        </div>
      ),
    },
    {
      dataField: 'totalClients',
      text: 'Total Clients',
      sort: true,
      headerStyle: { minWidth: '250px' },
    },
    {
      dataField: 'totalTerminatedClients',
      text: 'Terminated Clients',
      sort: true,
      headerStyle: { minWidth: '250px' },
    },
    {
      dataField: 'assignedClientsMonthlyRevenue',
      text: 'Total Clients MR',
      sort: true,
      headerStyle: { minWidth: '250px' },
      formatter: (cell) => currencyFormatter(cell),
    },
    {
      dataField: 'totalFullServiceClients',
      text: 'Full Service',
      sort: true,
      headerStyle: { minWidth: '250px' },
    },
  ];

  return (
    <div className="mt-8 rounded-xl">
      <Table
        columns={tableColumns.map((column) => {
          return {
            ...column,
            classes:
              'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
            headerClasses:
              'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
          };
        })}
        data={accountSpecialists}
        onTableChange={onTableChange}
        params={params}
        keyField="userId"
        loading={loading}
      />
    </div>
  );
};

export default TeamAccountSpecialist;
