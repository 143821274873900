import { useSelector } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import DatePicker from 'features/datePicker/DatePicker';

import AnalyticsOverview from './sections/AnalyticsOverview';
import MetricsChart from './sections/MetricsChart';

import Campaigns from './pages/Campaigns/Campaigns';
import AdGroups from './pages/AdGroups/AdGroups';
import Keywords from './pages/Keywords/Keywords';
import Targets from './pages/Targets/Targets';
import Products from './pages/Products/Products';
import Rules from './pages/Rules/Rules';
import Optimizations from 'features/advertising/optimizations/Optimizations';
import NegativeKeywords from 'features/advertising/components/NegativeKeywords';
import NegativeTargets from 'features/advertising/components/NegativeTargets';
import History from './pages/History/History';

const CampaignType = ({ campaignType }) => {
  const { account, marketplace } = useSelector((state) => state.account);
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const { url, path } = useRouteMatch();

  const pages = [
    {
      name: 'Campaigns',
      link: 'campaigns',
      component: Campaigns,
      visible: true,
      exact: true,
    },
    {
      name: 'Ad Groups',
      link: 'ad-groups',
      component: AdGroups,
      visible: true,
      exact: true,
    },
    {
      name: 'Keyword Targeting',
      link: 'keywords',
      component: Keywords,
      visible: ['sponsoredProducts', 'sponsoredBrands'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Product Targeting',
      link: 'targets',
      component: Targets,
      visible: ['sponsoredProducts', 'sponsoredDisplay'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Products',
      link: 'listings',
      component: Products,
      visible: ['sponsoredProducts', 'sponsoredDisplay'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Neg. Keywords',
      link: 'negative-keywords',
      component: NegativeKeywords,
      visible: ['sponsoredProducts'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Neg. Targets',
      link: 'negative-targets',
      component: NegativeTargets,
      visible: ['sponsoredProducts'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Rules',
      link: 'rules',
      component: Rules,
      visible: ['sponsoredProducts'].includes(campaignType),
      exact: false,
    },
    {
      name: 'Optimizations',
      link: 'optimizations',
      component: Optimizations,
      visible: ['sponsoredProducts'].includes(campaignType),
      exact: true,
    },
    {
      name: 'Change Logs',
      component: History,
      link: 'change-logs',
      visible: ['sponsoredProducts'].includes(campaignType),
      exact: true,
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <DatePicker position="left" showLabel={false} />
      </div>

      <AnalyticsOverview
        className="mt-10 grid grid-cols-5 gap-5"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        campaignType={campaignType}
      />

      <MetricsChart
        className="mt-8 bs-shadow bg-white py-8 rounded-3xl"
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        additionalParams={{ campaignTypes: [campaignType] }}
      />

      <div className="flex justify-between items-center border-b border-gray-200 mt-8">
        <nav className="-mb-px flex space-x-6" aria-label="Tabs">
          {pages
            .filter((page) => page.visible)
            .map((page) => (
              <NavLink
                key={page.name}
                to={`${url}/${page.link}`}
                className="flex items-center whitespace-nowrap py-2 border-b-2 font-medium text-mini border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                activeClassName="border-red-500 text-red-500 font-bold hover:border-red-500 hover:text-red-500"
              >
                {page.name}
              </NavLink>
            ))}
        </nav>
      </div>

      <div className="mt-5">
        <Switch>
          <Route
            exact
            path={url}
            render={() => <Redirect to={`${url}/campaigns`} />}
          />

          {pages.map((page) => {
            const Page = page.component;

            return (
              <Route
                key={`advertising-pages-campaign-type-${campaignType}-sub-page-${page.name}`}
                exact={page.exact}
                path={`${path}/${page.link}`}
                render={() => (
                  <Page
                    accountId={account.accountId}
                    marketplace={marketplace.details.countryCode}
                    campaignType={campaignType}
                  />
                )}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default CampaignType;
