import { useEffect } from 'react';
import { startCase } from 'lodash';
import { useParams } from 'react-router-dom';

import {
  MailIcon,
  CalendarIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';

import { Avatar } from 'components';

import { useUsers, UserStatus } from 'features/users';
import { useActivityLogs, AcitivityLogList } from 'features/activityLogs';

import { getNameInitials } from 'utils/formatters';

const EmployeeDetails = () => {
  const { employeeId } = useParams();
  const { fetchUserDetails, selectedUser } = useUsers();
  const { groupedActivityLogs, fetchActivityLogList } = useActivityLogs();

  const { data = {}, loading } = selectedUser;

  useEffect(() => {
    fetchUserDetails(employeeId, {
      include: ['avatar', 'role', 'supervisor[avatar]'],
    });

    fetchActivityLogList({
      sorts: 'createdAt:desc',
      include: ['triggeredBy[avatar]'],
      filterGroups: JSON.stringify([
        {
          filters: [
            { attribute: 'tags.tagType', operator: 'EQ', value: 'user' },
            { attribute: 'tags.tagId', operator: 'EQ', value: employeeId },
          ],
        },
        {
          filters: [
            { attribute: 'subjectType', operator: 'EQ', value: 'user' },
            { attribute: 'subjectId', operator: 'EQ', value: employeeId },
          ],
        },
        {
          filters: [
            { attribute: 'triggeredById', operator: 'EQ', value: employeeId },
          ],
        },
      ]),
    });
  }, [employeeId]);

  return !loading ? (
    <div>
      <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900 mb-8">
        {data.firstName} {data.lastName}
      </h2>

      <div className="grid grid-cols-3 gap-8">
        <div className="col-span-1 bg-white p-5 space-y-8 divide-y rounded">
          <div className="flex flex-col items-center space-y-5 border-grayscale-500 mt-5">
            <Avatar
              size="64px"
              textSize="32px"
              bgColor="#1879D8"
              imageSrc={data.avatar?.thumbnailUrl}
              initials={getNameInitials(data.firstName, data.lastName)}
            />

            <div>
              <p className="text-center font-inter text-xl font-bold tracking-3/4 leading-1.2 text-grayscale-900">
                {data.firstName} {data.lastName}
              </p>

              <p className="text-center text-sm leading-1.5 text-grayscale-700">
                {startCase(data.role?.name)}
              </p>

              <div className="flex justify-center mt-2">
                <UserStatus
                  className="py-1 px-3 rounded-xl font-inter tracking-3/4 text-13 font-semibold"
                  value={data.status}
                />
              </div>
            </div>
          </div>

          <div className="pt-4">
            <p className="text-grayscale-900 text-lg font-inter leading-1.2 tracking-3/4 font-bold">
              Contact Information
            </p>

            <div className="text-grayscale-900 mt-4 space-y-4 text-13">
              <p className="font-light leading-1.5 flex items-center space-x-2">
                <MailIcon className="w-5 h-5 text-grayscale-700" />
                <span>{data.email}</span>
              </p>

              <p className="font-light leading-1.5 flex items-center space-x-2">
                <LocationMarkerIcon className="w-5 h-5 text-grayscale-700" />
                <span>{data.address}</span>
              </p>

              <p className="font-light leading-1.5 flex items-center space-x-2">
                <CalendarIcon className="w-5 h-5 text-grayscale-700" />
                <span>{data.hiringDate}</span>
              </p>
            </div>
          </div>

          {data.supervisor && (
            <div className="pt-4">
              <p className="text-grayscale-900 text-lg font-inter leading-1.2 tracking-3/4 font-bold">
                Team Lead
              </p>

              <div
                className="mt-5 flex flex-col items-center"
                style={{ width: '75px' }}
              >
                <Avatar
                  size="48px"
                  imageSrc={data.supervisor.avatar?.thumbnailUrl}
                  initials={getNameInitials(
                    data.supervisor.firstName,
                    data.supervisor.lastName
                  )}
                />

                <p className="text-center text-grayscale-700 text-sm">
                  {data.supervisor.firstName} {data.supervisor.lastName}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="col-span-2 bg-white p-5 rounded">
          <p className="mt-5 font-inter text-xl font-bold tracking-3/4 leading-1.2 text-grayscale-900">
            Activity History
          </p>

          <AcitivityLogList logs={groupedActivityLogs} />
        </div>
      </div>
    </div>
  ) : (
    'loading'
  );
};

export default EmployeeDetails;
