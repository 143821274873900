import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import AccountDashboard from './pages/AccountDashboard';
import TeamDashboard from './pages/TeamDashboard';
import { useRoles } from 'features/roles';

const PPC = () => {
  const { user } = useAuth();
  const { PPC_SPECIALIST } = useRoles();
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={url}
        render={() => (
          <Redirect
            to={
              user?.role?.code === PPC_SPECIALIST.value
                ? `${url}/users/${user.userId}`
                : `${url}/team`
            }
          />
        )}
      />

      <Route exact path={`${url}/team`} component={TeamDashboard} />

      <Route exact path={`${url}/users/:userId`} component={AccountDashboard} />
    </Switch>
  );
};

export default PPC;
