import { upperFirst } from 'lodash';

import DataDifference from './DataDifference';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

const ExpandRow = ({ row, identifier }) => {
  return (
    <div className="bg-grayscale-200 py-4">
      <div className="grid grid-cols-3 gap-5 max-w-5xl px-5">
        {['Sponsored Products', 'Sponsored Brands', 'Sponsored Display'].map(
          (campaignType) => (
            <div
              className="border border-grayscale-500 rounded-xl"
              key={`${identifier}-${campaignType}`}
            >
              <p className="text-grayscale-800 text-13 border-b border-grayscale-500 px-2 pt-3 pb-1 text-center">
                {campaignType}
              </p>

              <div className="pt-1 pb-3 px-2 text-13 text-grayscale-700 leading-normal whitespace-nowrap grid grid-cols-3">
                {[
                  { key: 'sales', title: 'Ad Sales' },
                  { key: 'cost', title: 'Ad Spend' },
                  { key: 'ACOS', title: 'ACoS' },
                ].map((metric) => {
                  const campaignTypeKey = campaignType
                    .split(' ')
                    .map((word) => word.charAt(0))
                    .join('');

                  const key = `advertising${campaignTypeKey}${upperFirst(
                    metric.key
                  )}`;

                  return (
                    <div key={`${identifier}-${campaignType}-${metric.key}`}>
                      <p className="text-center">{metric.title}</p>

                      <DataDifference
                        current={row[key]}
                        className="flex flex-col justify-center items-center"
                        previous={row[`previous${upperFirst(key)}`]}
                        formatter={
                          metric.key === 'ACOS'
                            ? percentageFormatter
                            : currencyFormatter
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ExpandRow;
