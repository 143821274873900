import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopup } from 'features/alerts/alertsSlice';

const useAlerts = () => {
  const dispatch = useDispatch();

  const { popup } = useSelector((state) => state.alerts);

  const popupAlert = useCallback(
    async (data) => {
      await dispatch(await setPopup(data));
    },
    [dispatch]
  );

  return {
    popup,
    popupAlert,
  };
};

export default useAlerts;
