import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from '@heroicons/react/solid';

const SortCaret = (order) => {
  if (order === 'asc')
    return (
      <ArrowNarrowUpIcon className="h-4 w-4 text-grayscale-600 inline cursor-pointer ml-1 relative -top-0.5" />
    );
  else if (order === 'desc')
    return (
      <ArrowNarrowDownIcon className="h-4 w-4 text-grayscale-600 inline cursor-pointer ml-1 relative -top-0.5" />
    );

  return '';
};

export default SortCaret;
