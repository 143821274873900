import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { selectCurrentAccount } from 'features/accounts/accountsSlice';
import { selectIsAuthorizing } from './onboardingSlice';
import OnboardingLayout from 'layouts/onboarding/OnboardingLayout';
import SpApiAuthorization from './SpApiAuthorization';
import AdvApiAuthorization from './AdvApiAuthorization';
import DefaultMarketplace from './DefaultMarketplace';
import Steps from './Steps';
import Complete from './Complete';
import { Redirect } from 'react-router';
import PageLoader from 'components/PageLoader';
import SetPassword from './SetPassword';

const Onboarding = () => {
  const user = useSelector(selectAuthenticatedUser);
  const account = useSelector(selectCurrentAccount);
  const isAuthorizing = useSelector(selectIsAuthorizing);

  const [activeStep, setActiveStep] = useState(3);

  useEffect(() => {
    const hasSPAPICredentials =
      account && account.credentials.some(({ service }) => service === 'spApi');
    const hasAdvAPICredentials =
      account &&
      account.credentials.some(({ service }) => service === 'advApi');
    const hasDefaultMarketplace =
      account && account.marketplaces.some(({ isDefault }) => isDefault);

    if (user.passwordGenerated) {
      setActiveStep(0);
    } else if (!hasSPAPICredentials) {
      setActiveStep(1);
    } else if (!hasAdvAPICredentials) {
      setActiveStep(2);
    } else if (!hasDefaultMarketplace) {
      setActiveStep(3);
    } else {
      setActiveStep(4);
    }
  }, [account]);

  if (
    account &&
    account.name !== 'free' &&
    account.isOnboarding &&
    !account.subscription
  ) {
    return <Redirect to="/subscription" />;
  }
  // * Check if the current account is finished with onboarding
  if (account && !account.isOnboarding) {
    return <Redirect to="/plan" />;
  }
  return (
    <OnboardingLayout>
      {!account ? (
        <PageLoader />
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-xl">
          <Steps activeStep={activeStep} setActiveStep={setActiveStep} />
          <div className="mt-8 bg-white py-12 px-12 shadow sm:rounded-lg">
            {activeStep === 0 && (
              <SetPassword onSuccess={() => setActiveStep(1)} />
            )}
            {activeStep === 1 && (
              <SpApiAuthorization
                accountId={account.accountId}
                userId={user.userId}
                isAuthorizing={isAuthorizing}
                setActiveStep={setActiveStep}
              />
            )}
            {activeStep === 2 && (
              <AdvApiAuthorization
                isAuthorizing={isAuthorizing}
                setActiveStep={setActiveStep}
              />
            )}
            {/* {activeStep === 2 && (
              <AccountName account={account} setActiveStep={setActiveStep} />
            )} */}
            {activeStep === 3 && (
              <DefaultMarketplace
                account={account}
                marketplaces={account ? account.marketplaces : []}
              />
            )}
            {activeStep === 4 && <Complete />}
          </div>
        </div>
      )}
    </OnboardingLayout>
  );
};
export default Onboarding;
