import { Route, Switch, NavLink } from 'react-router-dom';

import { TeamLevel, ClientCount, PauseRequests } from './pages';
import classNames from 'utils/classNames';
import usePermissions from 'hooks/usePermissions';

const Admin = () => {
  const { userCan } = usePermissions();
  const tabs = [
    {
      name: 'Client Count',
      href: '/clients/admin',
      exact: true,
      component: ClientCount,
      visible: userCan('clients.admin.client_count.view'),
    },
    {
      name: 'Pause Request',
      href: '/clients/admin/pause-request',
      exact: true,
      component: PauseRequests,
      visible: userCan('clients.admin.pause_request.view'),
    },
    {
      name: 'Team Level',
      href: '/clients/admin/team-level',
      exact: true,
      component: TeamLevel,
      visible: userCan('clients.admin.team_level.view'),
    },
  ];

  return (
    <div className="">
      <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Active Clients
      </h2>

      <div className="border-b border-grayscale-500 mt-4 mb-4">
        <nav
          className="-mb-px flex space-x-8 text-grayscale-800"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <NavLink
              to={tab.href}
              key={tab.name}
              exact={tab.exact}
              className={classNames(
                'flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent'
              )}
              activeClassName="border-secondary-light font-bold text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>

      <Switch>
        {tabs.map(
          (tab) =>
            tab.visible && (
              <Route
                key={tab.href}
                path={tab.href}
                component={tab.component}
                exact={tab.exact}
              />
            )
        )}
      </Switch>
    </div>
  );
};
export default Admin;
