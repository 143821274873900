import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import { XIcon, SearchIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

import usePermissions from 'hooks/usePermissions';
import { InputPrepend } from 'components/Forms';
import ClientFilters from '../List/components/ClientFilters';
import Badge from 'components/Badge';
import TabNav from 'components/TabNav';
import Table from 'components/Table';
import Modal from 'components/Modal';
import { getSurveyRequests } from './surveySlice';
import useSurvey from 'hooks/useSurvey';
import useQueryParams from 'hooks/useQueryParams';
import { columnClasses } from 'utils/table';
import Export from './Export.js';

const Surveys = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { pathname } = useLocation();
  const mod = pathname.includes('employee-surveys') ? 'employees' : 'agency';
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const { paginationParams, surveys } = useSelector((state) => state.surveys);
  const { params, updateParams } = useQueryParams(paginationParams);
  const { STATUS } = useSurvey();
  const [menuTabs, setMenuTabs] = useState([
    {
      displayName: 'All',
      name: '',
      current: params.status == STATUS.ALL,
      visible: true,
    },
    {
      displayName: 'Sent',
      name: 'sent',
      current: params.status == STATUS.SENT,
      visible: true,
    },
    {
      displayName: 'Seen',
      name: 'seen',
      current: params.status == STATUS.SEEN,
      visible: true,
    },
    {
      displayName: 'Completed',
      name: 'completed',
      current: params.status == STATUS.COMPLETED,
      visible: true,
    },
  ]);

  const allowedFilters = [
    'brandStrategist',
    'projectManager',
    'accountSpecialist',
  ];

  const onDebouncedSearch = debounce((e) => {
    updateParams({ ...params, search: e.target.value });
  }, 500);

  const getSurveys = async () => {
    setLoading(true);
    let paramsInclude = {
      ...params,
      mod,
    };
    dispatch(getSurveyRequests(paramsInclude)).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!loading) getSurveys();
  }, [params]);

  const onView = async (row) => {
    setData(row);
    setOpenModal(true);
  };

  const tableColumns = [
    {
      dataField: 'type',
      text: 'Automated Email',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      visible: true,
      formatter: (cell, row) => {
        return <span className="capitalize">{cell}</span>;
      },
    },
    {
      dataField: 'name',
      text: `${mod === 'employees' ? 'Employee' : 'Client'} Name`,
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="capitalize">
            {mod === 'employees'
              ? `${row.user?.firstName} ${row.user?.lastName}`
              : row.agencyClient.client}
          </span>
        );
      },
      visible: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date Sent',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{moment(cell).format('LLL')}</span>
        );
      },
      visible: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              color={classnames({
                red: cell === STATUS.SEEN,
                green: cell === STATUS.COMPLETED,
                blue: cell === STATUS.SENT,
              })}
              classes="capitalize text-center w-40 justify-center"
              rounded="full"
              textSize="sm"
              fontFamily=""
            >
              {cell}
            </Badge>
          </>
        );
      },
      visible: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <button className="text text-red-500" onClick={() => onView(row)}>
            {' '}
            View{' '}
          </button>
        );
      },
      visible: true,
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };
  const ReqAstirisk = () => <span className="text-red-500">*</span>;

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            {mod === 'employees' ? 'Employee' : 'Client'} Survey
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <InputPrepend
            type="text"
            name="search"
            defaultValue={params.search}
            onChange={(e) => onDebouncedSearch(e)}
            placeholder={'Search'}
            prependText={<SearchIcon className="w-4 h-4" />}
            border="border-white"
          />
          {mod !== 'employees' && (
            <ClientFilters
              allowed={allowedFilters}
              filters={JSON.parse(params.filters)}
              setFilters={(filters) =>
                updateParams({ ...params, filters: JSON.stringify(filters) })
              }
            />
          )}

          {userCan(
            `${mod === 'employees' ? 'employees' : 'clients'}.survey.export`
          ) && <Export params={{ ...params, mod }} />}
        </div>
      </div>

      <TabNav
        tabs={menuTabs}
        setTabs={setMenuTabs}
        // onSelectChange={(e) => updateStatus(e.target.value)}
        onClick={(selectedTab) =>
          updateParams({
            status: selectedTab.name.toLowerCase(),
            page: 1,
            sizePerPage: params.pageSize,
          })
        }
        theme="tabbed"
        displayName={true}
      />
      <Table
        columns={tableColumns.filter((t) => t.visible)}
        data={surveys}
        onTableChange={onTableChange}
        params={params}
        keyField="upsellId"
        loading={loading}
        rowClasses="my-4 text-left"
      />

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-center px-8 pt-8 pb-2 border-b-2">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              Client Survey
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpenModal(false)}
            />
          </div>
          <div className="px-8 flex flex-col">
            <p className="mt-8 text-xl text-gray-700 py-4">
              Would you recommend Seller Interactive? <ReqAstirisk />
            </p>
            <input
              disabled={true}
              value={
                data.isRecommend
                  ? 'Yes'
                  : data.isRecommend === false
                  ? 'No'
                  : ''
              }
              className="py-3 border-0 block w-full bg-grayscale-400 rounded-xl border-gray-300 pl-5 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-23 placeholder-grayscale-600 text-grayscale-900 leading-1.5"
            />
            <p className="mt-4 text-xl text-gray-700 py-4">
              Why? Why not? <ReqAstirisk />
            </p>
            <textarea
              disabled={true}
              value={data.description ? data.description : ''}
              rows="5"
              className="py-3 border-0 block w-full bg-grayscale-400 rounded-xl border-gray-300 pl-5 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-23 placeholder-grayscale-600 text-grayscale-900 leading-1.5"
            />
            {!data.seenDate && <br />}

            {data.seenDate && (
              <p className="mt-4 text-xl text-gray-700 py-4">
                {`Seen at: `}{' '}
                <span className="text-green-500 text-lg">
                  {moment(data.seenDate).format('LLL')}
                </span>
              </p>
            )}
            {data.description && data.isRecommend !== null && (
              <p className="mt-4 text-xl text-gray-700 py-4">
                {`Completed at: `}{' '}
                <span className="text-green-500 text-lg">
                  {moment(data.updatedAt).format('LLL')}
                </span>
              </p>
            )}
            <br />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Surveys;
