import Sidebar from './Sidebar';
import MobileMenu from './MobileMenu';

const Navigations = () => {
  return (
    <>
      <Sidebar />
      <MobileMenu />
    </>
  );
};

export default Navigations;
