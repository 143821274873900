import { FieldArray, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { currencyFormatter, strUnderscoreToSpace } from 'utils/formatters';
import { PencilIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';

const AddonBlock = ({ setOpen, page }) => {
  const { addons } = useSelector((state) => state.zoho);
  const { values, setFieldValue } = useFormikContext();

  const onUpdateAddon = (val, index, setFieldValue, e) => {
    e.preventDefault();
    setFieldValue('addonTemp', { ...val, index, action: 'update' });
    setOpen(true);
  };

  const selectedAddonName = (addon_code) => {
    const el = addons?.find((a) => a.addon_code === addon_code);
    return el ? el.name : '';
  };

  const selectedAddonType = (addon_code) => {
    const el = addons?.find((a) => a.addon_code === addon_code);
    return el ? el.type : '';
  };

  return (
    <FieldArray name="addons">
      {({ insert, remove, push }) => (
        <>
          <div className="flex flex-col ">
            <div className="grid grid-cols-5 space-x-8 px-4 py-4 items-center bg-gray-50 text-left text-1sm font-bold text-grayscale-600">
              <span className="col-span-2">Addon</span>
              <span className="col-span-1">Type</span>
              <span className="col-span-1">Amount</span>
              {page === 'deals' && (
                <span className="col-span-1 text-right">Action</span>
              )}
            </div>
            {values.addons.length > 0 &&
              values.addons.map((addon, index) => (
                <div
                  className="grid grid-cols-5 hover:bg-gray-50 px-4 space-x-8 items-center py-2"
                  key={`addon-${index}`}
                >
                  <span className="col-span-2 py-3 text-gray-500 leading-none">
                    {selectedAddonName(addon.addon_code)}
                  </span>
                  <div className="col-span-1 ">
                    <span
                      className={classNames(
                        selectedAddonType(addon.addon_code) === 'recurring'
                          ? 'bg-red-100 text-red-600'
                          : 'bg-green-100 text-green-600',
                        'capitalize text-1xs tracking-wider rounded-sm px-2 py-1'
                      )}
                    >
                      {strUnderscoreToSpace(
                        selectedAddonType(addon.addon_code)
                      )}
                    </span>
                  </div>
                  <span className="col-span-1 text-gray-500">
                    {currencyFormatter(
                      values.addons[index].price,
                      values.addons[index].currency_code
                    )}
                  </span>
                  {page === 'deals' && (
                    <button
                      className="col-span-1 text-gray-500 hover:text-red-700 text-right"
                      onClick={(e) => {
                        onUpdateAddon(
                          values.addons[index],
                          index,
                          setFieldValue,
                          e
                        );
                      }}
                    >
                      <PencilIcon className="h-6 w-6 text-grayscale-700 inline" />
                    </button>
                  )}
                </div>
              ))}
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default AddonBlock;
