const TargetPreview = ({ campaignName, adGroupName, targetingText }) => {
  return (
    <div className="flex flex-col">
      <span>{targetingText}</span>
      <span className="text-xs text-grayscale-400">
        Campaign: {campaignName}
      </span>
      <span className="text-xs text-grayscale-400">
        Ad Group: {adGroupName}
      </span>
    </div>
  );
};

export default TargetPreview;
