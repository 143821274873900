import React, { useEffect } from 'react';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Label from 'components/Forms/Label';
import Button from 'components/Button';
import { useState } from 'react';
import ButtonLink from 'components/ButtonLink';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import useAlert from 'hooks/useAlert';
import { fetchAddons } from './billingSlice';
import { pick } from 'lodash';

const AddonModal = ({ open, setOpen, item = null }) => {
  const { addonsPaginationParams } = useSelector((state) => state.billing);
  const { alertSuccess, alertError } = useAlert();
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const [action, setAction] = useState('add');

  const emptyInitialData = {
    addonCode: '',
    name: '',
    description: '',
    price: '',
    status: 'active',
    type: 'one_time',
  };
  const [initialData, setInitialData] = useState(emptyInitialData);

  useEffect(() => {
    if (item) {
      setAction('edit');
      setInitialData(
        pick(item, [
          'addonCode',
          'name',
          'description',
          'price',
          'status',
          'type',
        ])
      );
    } else {
      setAction('add');
    }
  }, [item]);

  const validationSchema = object().shape({
    addonCode: string().required('Required'),
    name: string().required('Required'),
    description: string(),
    price: string().required('Required'),
    status: string().required('Required'),
    type: string().required('Required'),
  });

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      const response = await axios.post(
        `/billing/addons${action === 'add' ? '' : `/${values.addonCode}`}`,
        values
      );
      alertSuccess(`Invoice item ${action}ed`, response.data.message);
      dispatch(fetchAddons(addonsPaginationParams));
      setOpen(false);
    } catch (error) {
      alertError(
        `Failed to ${action} invoice item`,
        error.response.data.message
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-50"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Create Upsell Item"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <div className="p-8 pt-0">
          <Formik
            initialValues={initialData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, setFieldValue, values }) => (
              <Form>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <Label>
                      Invoice Item Name <RequiredAsterisk />
                    </Label>
                    <Field
                      name="name"
                      placeholder="Add Invoice Item Name"
                      className="form-input text-sm"
                      type="text"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div className="col-span-6">
                    <Label>
                      Invoice Item Code <RequiredAsterisk />
                    </Label>
                    <Field
                      name="addonCode"
                      placeholder="Add Invoice Item Code"
                      className="form-input text-sm disabled-white"
                      type="text"
                      disabled={action === 'edit'}
                    />
                    <ErrorMessage
                      name="addonCode"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div className="col-span-12">
                    <Label>Item Description</Label>
                    <Field
                      name="description"
                      placeholder="Add information related to this upsell"
                      className="form-input text-sm"
                      as="textarea"
                      rows={5}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                  <div className="col-span-9 flex items-center space-x-3">
                    <Label classes="whitespace-nowrap">
                      One Time Fee <RequiredAsterisk />
                    </Label>

                    <Field
                      name="price"
                      placeholder="$ Price"
                      className="form-input text-sm"
                      type="number"
                    />

                    <ErrorMessage
                      name="price"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                </div>
                <div className="flex justify-left pt-8">
                  <div className="space-x-6">
                    <ButtonLink
                      onClick={() => setOpen(false)}
                      classes="tracking-widest font-bold text-secondary-light"
                      color=""
                      loading={saving}
                    >
                      Cancel
                    </ButtonLink>
                    <Button
                      classes="px-12 py-2 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-widest  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                      roundedSize="full"
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddonModal;
