import React from 'react';

const RequiredAsterisk = ({ sup = true }) => {
  return sup ? (
    <sup className="text-error">*</sup>
  ) : (
    <span className="text-error">*</span>
  );
};

export default RequiredAsterisk;
