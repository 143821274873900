import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownloadIcon, PlusIcon } from '@heroicons/react/outline';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import usePermissions from 'hooks/usePermissions';
import { fetchAddons } from './billingSlice';
import AddonModal from './AddonModal';
import DataTable from 'components/DataTable';
import { getNameInitials, numberFormatter } from 'utils/formatters';
import { Avatar, ConfirmationModal } from 'components';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';

const Addons = () => {
  const { userCan, isAgencySuperUser } = usePermissions();
  const { addons, addonsPaginationParams } = useSelector(
    (state) => state.billing
  );
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons(addonsPaginationParams));
    }
  }, [addons]);

  const columns = [
    { header: 'Name', accessorKey: 'name' },
    { header: 'Code', accessorKey: 'addonCode' },
    { header: 'Description', accessorKey: 'description', size: 400 },
    {
      header: 'Price ($)',
      accessorFn: (row) => row,
      size: 100,
      cell: (cell) => {
        const row = cell.getValue();
        return (
          <span className="font-normal">
            <span className="text-gray-400 mr-1">$</span>
            {numberFormatter(row.price)}
          </span>
        );
      },
    },
    {
      header: 'Created By',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return row.creator ? (
          <div className="flex items-center space-x-2">
            <Avatar
              imageSrc={row.creator.profileLink}
              initials={getNameInitials(
                row.creator.firstName,
                row.creator.lastName
              )}
            />
            <span>
              {row.creator.firstName}&nbsp;
              {row.creator.lastName}
            </span>
          </div>
        ) : (
          ''
        );
      },
    },
    {
      header: 'Action',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                setItemToUpdate(row);
                setOpenModal(true);
              }}
              className=" text-secondary hover:text-secondary-light"
            >
              <PencilIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                setItemToDelete(row.addonCode);
                setOpenConfirmDelete(true);
              }}
              className=" text-secondary hover:text-secondary-light"
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        );
      },
    },
  ];

  const onSync = async () => {
    setLoading(true);
    await axios
      .post(`/billing/addons/import`)
      .then((response) => {
        alertSuccess('sync successful', response.data.message);
        dispatch(fetchAddons(addonsPaginationParams));
      })
      .catch((error) => {
        alertError('sync failed', error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteSelectedItem = async () => {
    if (itemToDelete !== '') {
      setDeleting(true);
      try {
        const response = await axios.delete(`/billing/addons/${itemToDelete}`);
        alertSuccess(`Invoice item deleted`, response.data.message);
        setOpenConfirmDelete(false);
        dispatch(fetchAddons(addonsPaginationParams));
      } catch (error) {
        alertError(
          `Failed to delete invoice item`,
          error.response.data.message
        );
      } finally {
        setDeleting(false);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-4 pt-5">
        <h1 className="text-3xl tracking-wide font-bold">
          Addons / Upsell Items
        </h1>
        {isAgencySuperUser && (
          <button
            type="button"
            className="flex items-center px-4 tracking-widest rounded-3xl py-1.5 border-2 font-bold border-gray-600 text-sm text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            onClick={() => {
              setOpenModal(true);
              setItemToUpdate(null);
            }}
          >
            <PlusIcon className="h-4 w-4 mr-2" /> Add
          </button>
        )}
        {isAgencySuperUser && (
          <button
            type="button"
            className="flex items-center px-4 tracking-widest rounded-3xl py-1.5 border-2 font-bold border-gray-600 text-sm text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-red-300"
            onClick={onSync}
            disabled={loading}
          >
            <CloudDownloadIcon className="h-4 w-4 mr-2" /> Sync with Zoho{' '}
            {loading && <SpinnerGrow />}
          </button>
        )}
      </div>
      <div className="mt-6">
        {addons && (
          <DataTable
            data={addons}
            columns={columns}
            classnames={{ header: 'bg-grayscale-200 px-2', cell: 'bg-white' }}
            showGridLines={false}
          />
        )}

        <AddonModal
          open={openModal}
          setOpen={setOpenModal}
          item={itemToUpdate}
        />
        <ConfirmationModal
          title="Confirm Delete Item"
          content={`Are you sure you want to delete this addon/upsell item? `}
          open={openConfirmDelete}
          setOpen={setOpenConfirmDelete}
          onOkClick={onDeleteSelectedItem}
          onCancelClick={() => {
            setItemToDelete('');
            setOpenConfirmDelete(false);
          }}
          okLoading={deleting}
          showOkLoading={true}
        />
      </div>
    </div>
  );
};
export default Addons;
