import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CreateRule from './pages/CreateRule';
import EditRule from './pages/EditRule';
import ListRule from './pages/ListRules';

const Rules = ({ accountId, marketplace, campaignType }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={path}
        exact
        render={() => (
          <ListRule
            accountId={accountId}
            marketplace={marketplace}
            campaignType={campaignType}
          />
        )}
      />

      <Route
        path={`${path}/create`}
        exact
        render={() => (
          <CreateRule
            accountId={accountId}
            marketplace={marketplace}
            campaignType={campaignType}
          />
        )}
      />

      <Route
        path={`${path}/:ruleId`}
        exact
        render={() => (
          <EditRule
            accountId={accountId}
            marketplace={marketplace}
            campaignType={campaignType}
          />
        )}
      />
    </Switch>
  );
};

export default Rules;
