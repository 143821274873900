import axios from 'axios';
import moment from 'moment-timezone';

import { useState, useEffect } from 'react';

import { useRoles } from 'features/roles';
import { FilterGroup } from 'components/Filters';
import useClient from 'features/clients/useClient';
import { useTermination } from 'features/termination';
import RangePicker from 'components/RangePicker';
import { capitalize } from 'lodash';

const ClientFilters = ({ filters = [], allowed = [], setFilters }) => {
  const { REASONS } = useTermination();
  const { PROJECT_MANAGER, BRAND_STRATEGIST, ACCOUNT_SPECIALIST } = useRoles();
  const { STATUS_LIST, ACCOUNT_STATUSES, SUBSCRIPTION_STATUSES, SERVICES } =
    useClient();

  const [statuses, setStatuses] = useState(
    filters.find((f) => f.attribute === 'status')?.value ?? []
  );

  const [accountStatuses, setAccountStatuses] = useState(
    filters.find((f) => f.attribute === 'accountStatus')?.value ?? []
  );

  const [projectManagers, setProjectManagers] = useState(
    filters.find((f) => f.attribute === 'projectManager.userId')?.value ?? []
  );

  const [brandStrategists, setBrandStrategists] = useState(
    filters.find((f) => f.attribute === 'brandStrategist.userId')?.value ?? []
  );

  const [accountSpecialists, setAccountSpecialists] = useState(
    filters.find((f) => f.attribute === 'accountSpecialist.userId')?.value ?? []
  );

  const [subscriptionStatuses, setSubscriptionStatuses] = useState(
    filters.find((f) => f.attribute === 'subscription.status')?.value ?? []
  );

  const [latestComplaintStatuses, setLatestComplaintStatuses] = useState(
    filters.find((f) => f.attribute === 'latestComplaintStatus')?.value ?? []
  );

  const [invoiceSummary, setInvoiceSummary] = useState(
    filters.find((f) => f.attribute === 'invoiceSummary')?.value ?? []
  );

  const [terminationReasons, setTerminationReasons] = useState(
    filters.find((f) => f.attribute === 'terminationReasons')?.value ?? []
  );

  const [subscriptionPaymentOption, setSubscriptionPaymentOption] = useState(
    filters.find((f) => f.attribute === 'subscriptionPaymentOption')?.value ??
      []
  );

  const [contractSigned, setContractSigned] = useState(
    filters.find((f) => f.attribute === 'contractSigned')?.value ?? []
  );

  const [terminationDate, setTerminationDate] = useState(
    filters.find((f) => f.attribute === 'terminatedAt')?.value ?? []
  );

  const [onboardingTaskCompletionSummary, setOnboardingTaskCompletionSummary] =
    useState(
      filters.find((f) => f.attribute === 'onboardingTaskCompletionSummary')
        ?.value ?? []
    );

  const [services, setServices] = useState(
    filters.find((f) => f.attribute === 'service')?.value ?? []
  );

  const [projectManagerSearch, setProjectManagerSearch] = useState('');
  const [brandStrategistSearch, setBrandStrategistSearch] = useState('');
  const [accountSpecialistSearch, setAccountSpecialistSearch] = useState('');

  const [projectManagerOptions, setProjectManagerOptions] = useState([]);
  const [brandStrategistOptions, setBrandStrategistOptions] = useState([]);
  const [accountSpecialistOptions, setAccountSpecialistOptions] = useState([]);

  useEffect(() => {
    axios
      .get('/v2/users', {
        params: {
          search: projectManagerSearch,
          'role.code': PROJECT_MANAGER.value,
        },
      })
      .then((response) => setProjectManagerOptions(response.data.data));
  }, [projectManagerSearch]);

  useEffect(() => {
    let filters = [
      {
        attribute: 'role.code',
        operator: 'EQ',
        value: BRAND_STRATEGIST.value,
      },
    ];

    if (projectManagers.length) {
      filters.push({
        attribute: 'supervisorId',
        operator: 'IN',
        value: projectManagers,
      });
    }

    axios
      .get('/v2/users', {
        params: {
          search: brandStrategistSearch,
          filterGroups: JSON.stringify([{ filters }]),
        },
      })
      .then((response) => setBrandStrategistOptions(response.data.data));
  }, [brandStrategistSearch, projectManagers]);

  useEffect(() => {
    let filters = [
      {
        attribute: 'role.code',
        operator: 'EQ',
        value: ACCOUNT_SPECIALIST.value,
      },
    ];

    if (brandStrategists.length) {
      filters.push({
        attribute: 'supervisorId',
        operator: 'IN',
        value: brandStrategists,
      });
    }

    axios
      .get('/v2/users', {
        params: {
          search: accountSpecialistSearch,
          filterGroups: JSON.stringify([{ filters }]),
        },
      })
      .then((response) => setAccountSpecialistOptions(response.data.data));
  }, [accountSpecialistSearch, brandStrategists]);

  const onApplyFilter = () => {
    let newFilters = [];

    if (statuses.length) {
      newFilters.push({
        operator: 'IN',
        value: statuses,
        attribute: 'status',
      });
    }

    if (accountStatuses.length) {
      newFilters.push({
        operator: 'IN',
        value: accountStatuses,
        attribute: 'accountStatus',
      });
    }

    if (projectManagers.length) {
      newFilters.push({
        operator: 'IN',
        value: projectManagers,
        attribute: 'projectManager.userId',
      });
    }

    if (brandStrategists.length) {
      newFilters.push({
        operator: 'IN',
        value: brandStrategists,
        attribute: 'brandStrategist.userId',
      });
    }

    if (accountSpecialists.length) {
      newFilters.push({
        operator: 'IN',
        value: accountSpecialists,
        attribute: 'accountSpecialist.userId',
      });
    }

    if (subscriptionStatuses.length) {
      newFilters.push({
        operator: 'IN',
        value: subscriptionStatuses,
        attribute: 'subscription.status',
      });
    }

    if (latestComplaintStatuses.length) {
      newFilters.push({
        operator: 'IN',
        value: latestComplaintStatuses,
        attribute: 'latestComplaintStatus',
      });
    }

    if (terminationReasons.length) {
      newFilters.push({
        operator: 'IN',
        value: terminationReasons,
        attribute: 'terminationReason',
      });
    }

    if (contractSigned.length) {
      newFilters.push({
        operator: 'BETWEEN',
        value: contractSigned,
        attribute: 'contractSigned',
      });
    }

    if (terminationDate.length) {
      newFilters.push({
        operator: 'BETWEEN',
        value: terminationDate,
        attribute: 'terminatedAt',
      });
    }

    if (invoiceSummary.length) {
      newFilters.push({
        operator: 'IN',
        value: invoiceSummary,
        attribute: 'invoiceSummary',
      });
    }

    if (subscriptionPaymentOption.length) {
      newFilters.push({
        operator: 'IN',
        value: subscriptionPaymentOption,
        attribute: 'subscriptionPaymentOption',
      });
    }

    if (onboardingTaskCompletionSummary.length) {
      newFilters.push({
        attribute: 'onboardingTaskCompletionSummary',
        operator: 'IN',
        value: onboardingTaskCompletionSummary,
      });
    }

    if (services.length) {
      newFilters.push({
        attribute: 'service',
        operator: 'IN',
        value: services,
      });
    }

    setFilters(newFilters);
  };

  const DateFilterComponent = ({ values = [], onChange }) => {
    const [selectedDates, setSelectedDates] = useState(values);

    return (
      <RangePicker
        monthsToDisplay={1}
        orientation="vertical"
        selectedDates={selectedDates.map((value) => new Date(value))}
        setSelectedDates={(dates) => {
          const formattedDates = dates.map((date) =>
            moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD')
          );

          setSelectedDates(formattedDates);

          if (dates.length === 2) {
            onChange(formattedDates);
          }
        }}
        ignoreMinDate={true}
      />
    );
  };

  return (
    <FilterGroup
      onApply={onApplyFilter}
      onClear={() => {
        setStatuses([]);
        setAccountStatuses([]);
        setProjectManagers([]);
        setBrandStrategists([]);
        setAccountSpecialists([]);
        setSubscriptionStatuses([]);
        setLatestComplaintStatuses([]);
        setTerminationReasons([]);
        setContractSigned([]);
        setTerminationDate([]);
        setInvoiceSummary([]);
        setSubscriptionPaymentOption([]);
        setOnboardingTaskCompletionSummary([]);
        setServices([]);
      }}
      filters={[
        {
          field: 'service',
          title: 'Service',
          values: services,
          options: SERVICES,
          onChange: (values) => {
            setServices(values.service);
          },
        },
        {
          field: 'accountStatus',
          title: 'Account Status',
          values: accountStatuses,
          options: ACCOUNT_STATUSES,
          onChange: (values) => {
            setAccountStatuses(values.accountStatus);
          },
        },
        {
          field: 'status',
          title: 'Status',
          values: statuses,
          options: STATUS_LIST,
          onChange: (values) => {
            setStatuses(values.status);
          },
        },
        {
          field: 'subscriptionStatus',
          title: 'Subscription',
          values: subscriptionStatuses,
          options: SUBSCRIPTION_STATUSES,
          onChange: (values) => {
            setSubscriptionStatuses(values.subscriptionStatus);
          },
        },
        {
          field: 'subscriptionPaymentOption',
          title: 'Subscription Type',
          values: subscriptionPaymentOption,
          options: [
            {
              value: 'Recurring',
              label: 'Recurring',
            },
            {
              value: 'One Time',
              label: 'One Time',
            },
          ],
          onChange: (values) => {
            setSubscriptionPaymentOption(values.subscriptionPaymentOption);
          },
        },
        {
          field: 'latestComplaintStatus',
          title: 'Complaint',
          values: latestComplaintStatuses,
          options: ['None', 'Ongoing', 'Resolved'].map((value) => ({
            label: value,
            value: value,
          })),
          onChange: (values) => {
            setLatestComplaintStatuses(values.latestComplaintStatus);
          },
        },
        {
          field: 'contractSigned',
          title: 'Contract Signed',
          values: contractSigned,
          FilterComponent: DateFilterComponent,
          onChange: (values) => {
            setContractSigned(values);
          },
        },
        {
          field: 'terminatedAt',
          title: 'Termination Date',
          values: terminationDate,
          FilterComponent: DateFilterComponent,
          onChange: (values) => {
            setTerminationDate(values);
          },
        },
        {
          field: 'terminationReason',
          title: 'Termination Reason',
          values: terminationReasons,
          options: REASONS.map((reason) => ({
            label: reason,
            value: reason,
          })),
          onChange: (values) => {
            setTerminationReasons(values.terminationReason);
          },
        },
        {
          field: 'invoiceSummary',
          title: 'Invoice Summary',
          values: invoiceSummary,
          onChange: (values) => setInvoiceSummary(values.invoiceSummary),
          options: [
            {
              value: 'PAID',
              label: 'Paid',
            },
            {
              value: 'UNPAID',
              label: 'Unpaid',
            },
          ],
        },
        {
          searchable: true,
          field: 'projectManager',
          title: 'Project Manager',
          values: projectManagers,
          onSearch: (search) => setProjectManagerSearch(search),
          options: projectManagerOptions.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            console.log(values);
            setProjectManagers(values.projectManager);
          },
        },
        {
          searchable: true,
          field: 'brandStrategist',
          title: 'Brand Stategist',
          values: brandStrategists,
          onSearch: (search) => setBrandStrategistSearch(search),
          options: brandStrategistOptions.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            setBrandStrategists(values.brandStrategist);
          },
        },
        {
          searchable: true,
          field: 'accountSpecialist',
          title: 'Account Specialist',
          values: accountSpecialists,
          onSearch: (search) => setAccountSpecialistSearch(search),
          options: accountSpecialistOptions.map((user) => {
            return {
              ...user,
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
          onChange: (values) => {
            setAccountSpecialists(values.accountSpecialist);
          },
        },
        {
          field: 'onboardingTaskCompletionSummary',
          title: 'Progress',
          values: onboardingTaskCompletionSummary,
          options: ['pending', 'completed'].map((value) => ({
            label: capitalize(value),
            value: value,
          })),
          onChange: (values) => {
            setOnboardingTaskCompletionSummary(
              values.onboardingTaskCompletionSummary
            );
          },
        },
      ].filter((filter) => allowed.includes(filter.field))}
    />
  );
};

export default ClientFilters;
