import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAccount = createAsyncThunk(
  'account/getAccount',
  async (id) => {
    const response = await axios.get(`/accounts/${id}`);
    return response.data.data;
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    account: null,
    marketplace: null,
    accountLoaded: false,
  },
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setMarketplace: (state, action) => {
      state.marketplace = action.payload;
    },
  },
  extraReducers: {
    [fetchAccount.pending]: (state) => {
      state.accountLoaded = false;
      state.account = null;
      state.marketplace = null;
    },
    [fetchAccount.fulfilled]: (state, { payload }) => {
      state.account = payload;
      state.marketplace = payload.marketplaces[0];
      state.accountLoaded = true;
    },
  },
});

export const { setAccount, setMarketplace } = accountSlice.actions;

export const selectAccount = (state) => state.account.account;

export default accountSlice.reducer;
