import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import { debounce } from 'lodash';
import DatePicker from 'features/datePicker/DatePicker';
import { getCreditNoteRequests } from './creditNotesSlice';
import { SearchIcon } from '@heroicons/react/solid';
import { TrendingUpIcon } from '@heroicons/react/outline';
import TabNav from 'components/TabNav';
import {
  dateFormatter,
  getNameInitials,
  nameFormatter,
} from 'utils/formatters';
import useQueryParams from 'hooks/useQueryParams';
import { Link } from 'react-router-dom';
import Exporter from './components/Exporter';
import useCreditNotes from './useCreditNotes';
import CreditNoteStatus from './components/CreditNoteStatus';
import UserProfileAvatar from 'components/UserProfileAvatar';
import { columnClasses } from 'utils/table';
import PriceLabel from 'components/PriceLabel';
import CreditNoteReviewModal from './CreditNoteReviewModal';
import Button from 'components/Button';
import UpsellCreditNoteModal from './UpsellCreditNoteModal';

const CreditNotes = () => {
  const dispatch = useDispatch();
  const { creditNotes, paginationParams } = useSelector(
    (state) => state.creditNotes
  );
  const { params, updateParams } = useQueryParams(paginationParams);
  const [open, setOpen] = useState(false);
  const [isOpenUpsellModal, setIsOpenUpsellModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCreditNote, setSelectedCreditNote] = useState(null);
  const fieldOptions = [
    { label: 'All', value: 'all' },
    { label: 'Credit Request', value: 'name' },
    { label: 'Client Name', value: 'clientName' },
    { label: 'Request Date', value: 'dateApplied' },
    { label: 'Submitted By', value: 'requestedBy' },
  ];
  const { STATUS, isApprovedOrApplied, isRejected } = useCreditNotes();

  const [statusTabs, setStatusTabs] = useState([
    {
      name: STATUS.awaitingApproval,
      current: params.status === STATUS.awaitingApproval,
      visible: true,
    },
    {
      name: STATUS.approved,
      current: params.status === STATUS.approved,
      visible: true,
    },
    {
      name: STATUS.appliedPartial,
      displayName: 'Applied Partial',
      current: params.status === STATUS.appliedPartial,
      visible: true,
    },
    {
      name: STATUS.applied,
      current: params.status === STATUS.applied,
      visible: true,
    },
    {
      name: STATUS.denied,
      current: params.status === STATUS.denied,
      visible: true,
    },
    // {
    //   name: STATUS.cancelled,
    //   current: params.status === STATUS.cancelled,
    //   visible: true,
    // },
  ]);

  useEffect(() => {
    if (!loading) onReload();
  }, [params]);

  const onReload = () => {
    setLoading(true);
    dispatch(getCreditNoteRequests(params)).then(() => {
      setLoading(false);
    });
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: {
        width: '250px',
      },
      sort: true,
      formatter: (cell, row) => (
        <a
          onClick={() => {
            setSelectedCreditNote(row);
            if (params.status === STATUS.awaitingApproval) {
              row.upsell ? setIsOpenUpsellModal(true) : setOpen(true);
            } else setOpen(true);
          }}
          className="font-normal text-secondary-light text-sm cursor-pointer underline"
        >
          {cell}
          {row.upsell && (
            <TrendingUpIcon className="w-3.5 h-3.5 inline ml-0.5 transform -rotate-6 relative -top-0.5" />
          )}
        </a>
      ),
    },
    {
      dataField: 'client',
      text: 'Client Name',
      headerStyle: {
        minWidth: '180px',
      },
      sort: true,
      formatter: (cell, row) => (
        <Link
          className="text-secondary-light text-sm underline"
          to={`/clients/${row.agencyClientId}/dashboard`}
          title={row.agencyClient?.client}
        >
          {row.agencyClient?.client}
        </Link>
      ),
    },
    ...(isApprovedOrApplied(params.status)
      ? [
          {
            dataField: 'zohoCreditNoteNumber',
            text: 'CN #',
            sort: true,
            headerStyle: { textAlign: 'center' },
            classes: `${columnClasses} text-center`,
          },
        ]
      : []),
    ...(params.status === STATUS.awaitingApproval ||
    params.status === STATUS.applied ||
    params.status === STATUS.denied
      ? [
          {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            headerStyle: {
              minWidth: '80px',
              textAlign: 'right',
            },
            classes: `${columnClasses} text-right`,
            formatter: (cell, row) => <PriceLabel price={parseFloat(cell)} />,
          },
        ]
      : []),
    ...(params.status === STATUS.appliedPartial ||
    params.status === STATUS.approved
      ? [
          {
            dataField: 'creditsAvailable',
            text: 'Balance',
            headerStyle: { textAlign: 'right' },
            classes: `${columnClasses} text-right`,
            formatter: (cell, row) => <PriceLabel price={parseFloat(cell)} />,
          },
          {
            dataField: 'autoApplyAmount',
            text: 'Auto Apply',
            headerStyle: { textAlign: 'right' },
            classes: `${columnClasses} text-right`,
            formatter: (cell, row) => (
              <PriceLabel
                price={parseFloat(cell)}
                color={cell > 0 ? 'text-success' : 'text-error'}
              />
            ),
          },
        ]
      : []),
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        width: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => <CreditNoteStatus status={cell} />,
    },
    {
      dataField: 'createdAt',
      text: 'Request Date',
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      classes: `${columnClasses} text-right`,
      sort: true,
      formatter: (cell, row) => (
        <span className="font-normal">{dateFormatter(cell)}</span>
      ),
    },
    {
      dataField: 'requestedBy',
      text: 'Submitted By',
      headerStyle: {
        minWidth: '150px',
      },
      sort: true,
      formatter: (cell, row) => <UserProfileAvatar user={row.requester} />,
    },
    ...(isApprovedOrApplied(params.status) || params.status === STATUS.denied
      ? [
          {
            dataField: 'updatedBy',
            text: 'Updated By',
            formatter: (cell, row) => {
              const user = isApprovedOrApplied(params.status)
                ? row.approver
                : row.rejecter;

              return user && <UserProfileAvatar user={user} />;
            },
          },
        ]
      : []),
    {
      dataField: 'action',
      text: 'Action',
      className: 'text-center',
      formatter: (cell, row) => {
        return (
          <Button
            classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
            border=" border-2 border-grayscale-700"
            color=""
            hoverColor="grayscale-500"
            px=""
            onClick={() => {
              setSelectedCreditNote(row);
              if (params.status === STATUS.awaitingApproval) {
                row.upsell ? setIsOpenUpsellModal(true) : setOpen(true);
              } else setOpen(true);
            }}
          >
            <SearchIcon className="w-3.5 h-3.5 inline mr-2" />
            &nbsp;
            {params.status === STATUS.awaitingApproval ? 'Review' : 'View'}
          </Button>
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField}:${sortOrder}`,
    });
  };

  const onChangeField = (e) => {
    updateParams({ fields: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const onSearch = (e) => {
    updateParams({ search: e.target.value });
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Credit Notes
          </h2>

          <div className="flex flex-row sm:flex-col lg:flex-row h-12 gap-1">
            <select
              id="status"
              label="status"
              value={params.fields}
              className="h-full rounded-l-xl w-40 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
              onChange={onChangeField}
            >
              {fieldOptions.map((status, i) => (
                <option
                  key={i}
                  value={status.value}
                  disabled={status.disabled ?? ''}
                  className={status.disabled ? 'bg-red-50' : ''}
                >
                  {status.label}
                </option>
              ))}
            </select>
            <div className="h-full min-h-12 relative">
              {params.fields == 'dateApplied' ? (
                <div className="sm:col-span-1">
                  <DatePicker position="left" showLabel={false} />
                </div>
              ) : (
                <div className="h-full relative">
                  <div className="h-full absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                      <SearchIcon className="w-4 h-4" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="h-full rounded-r-xl w-60 pl-12 border-0 sm:text-sm appearance-none focus:ring-0 focus:border-0 focus:appearance-none  disabled:bg-gray-100"
                    placeholder="Search"
                    onChange={onDebouncedSearch}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <Exporter />
        </div>
      </div>

      <TabNav
        tabs={statusTabs}
        setTabs={setStatusTabs}
        onSelectChange={(e) =>
          updateParams({ status: e.target.value, page: 1 })
        }
        onClick={(selectedTab) =>
          updateParams({
            status: selectedTab.name,
            page: 1,
          })
        }
        theme="tabbed"
        tabClasses="capitalize"
      />

      <Table
        columns={tableColumns}
        data={creditNotes}
        onTableChange={onTableChange}
        params={params}
        keyField="creditNoteId"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
        loading={loading}
        rowClasses="hover:bg-gray-50"
      />

      <CreditNoteReviewModal
        open={open}
        setOpen={setOpen}
        creditNote={selectedCreditNote}
        onReload={onReload}
        type="reviewer"
      />

      <UpsellCreditNoteModal
        open={isOpenUpsellModal}
        setOpen={setIsOpenUpsellModal}
        creditNote={selectedCreditNote}
        onReload={onReload}
        type="reviewer"
      />
    </>
  );
};

export default CreditNotes;
