import { ago } from 'utils/formatters';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import useLoggedInUser from 'hooks/useLoggedInUser';

const Log = ({ data }) => {
  const { name } = useLoggedInUser();

  return (
    <div>
      <div className="log flex flex-col xl:flex-row justify-between">
        <span
          className="text-sm w-3/4 truncate text-gray-500 whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: data.text.replace(name, 'You'),
          }}
        ></span>
        <span className="text-xs text-gray-400 w-28 text-right">
          {ago(data.createdAt)}
        </span>
      </div>
      {data.moreInformation && (
        <div className="flex">
          <div className="whitespace-pre-wrap text-1xs text-gray-500 pl-5 leading-snug relative">
            <ChevronLeftIcon className="w-3 h-3 inline transform -rotate-45 absolute left-1.5 text-gray-400" />
            <div className="mt-0.5 border-l-2 border-dotted border-gray-300 pl-1.5">
              {data.moreInformation}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Log;
