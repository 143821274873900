import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import classNames from 'utils/classNames';
import useBodyClass from 'hooks/useBodyClass';
import { linkStyles } from 'utils/tabStyles';

import MarketplaceContainer from '../components/MarketplaceContainer';
import MarketplacePicker from '../components/MarketplacePicker';
import Overview from './Overview/Overview';
import History from './History/History';

const DataSync = () => {
  const [notes, setNotes] = useState([]);
  useBodyClass(['client-profile', 'no-sidebar']);

  const { url, path } = useRouteMatch();
  const { account, marketplace } = useSelector((state) => state.account);

  useEffect(() => {
    let newNotes = [];

    if (!account.credentials.find((i) => i.service === 'spApi')) {
      newNotes = [...newNotes, 'SP-API Not Connected'];
    }

    if (
      account.credentials.find(
        (i) => i.service === 'spApi' && i.hasFullAccess === false
      )
    ) {
      newNotes = [...newNotes, 'SP-API needs to re-authenticate'];
    }

    if (!account.credentials.find((i) => i.service === 'advApi')) {
      newNotes = [...newNotes, 'Advertising Not Connected'];
    }

    setNotes(newNotes);
  }, [account]);

  const tabs = [
    { name: 'Initial', exact: true, url },
    { name: 'History', exact: true, url: `${url}/history` },
  ];

  return (
    <MarketplaceContainer>
      <div className="flex justify-between items-center">
        <div className="tablinks">
          {tabs.map((tab, i) => (
            <NavLink
              key={tab.name}
              to={tab.url}
              exact={tab.exact}
              className={classNames(linkStyles, 'px-3')}
              activeClassName="current border-secondary-light text-secondary-light font-bold hover:border-secondary-light hover:text-secondary-light"
            >
              {tab.name}
            </NavLink>
          ))}
        </div>

        <MarketplacePicker />
      </div>

      {!!notes.length && (
        <div className="bs-shadow bg-red-500 text-white mt-4 px-4 py-2 tracking-3/4 rounded-md">
          <ul className="list-disc px-4">
            {notes.map((note) => (
              <li className="text-mini font-inter" key={note}>
                {note}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-5">
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Overview account={account} marketplace={marketplace} />
            )}
          />

          <Route
            exact
            path={`${path}/history`}
            render={() => (
              <History account={account} marketplace={marketplace} />
            )}
          />
        </Switch>
      </div>
    </MarketplaceContainer>
  );
};

export default DataSync;
