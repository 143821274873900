import { OrgChart } from 'd3-org-chart';
import { createPortal } from 'react-dom';
import React, { useEffect, useRef, useReducer, useState } from 'react';

import NodeItem from './NodeItem';
import DepartmentFilter from './DepartmentFilter';

const OrgChartComponent = ({ data = [], department, onChangeDepartment }) => {
  const d3Container = useRef(null);

  const [chart, setChart] = useState(new OrgChart());
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // We need to manipulate DOM
  useEffect(() => {
    if (data && d3Container.current) {
      const newChart = new OrgChart()
        .container(d3Container.current)
        .data(data)
        .nodeWidth(() => 300)
        .nodeHeight(() => 125)
        .buttonContent(({ node }) => {
          return `<div style="color:#002F5D;border-radius:50%;width:20px;height:20px;font-weight:bold;display:flex; justify-content:center;align-items:center; margin:auto auto;background-color:#D3E4F5;"> 
           <span>${node.data._directSubordinates}</span></div>`;
        })
        .nodeContent((node) => {
          return `<div id=${node.id} style="width: 300px; height: 125px;"></div>`;
        })
        .nodeUpdate(() => forceUpdate())
        .render();

      setChart(newChart);
    }
  }, [data, d3Container.current]);

  return (
    <div className="">
      <DepartmentFilter
        value={department}
        onZoomIn={() => chart.zoomIn()}
        onZoomOut={() => chart.zoomOut()}
        onChange={(value) => onChangeDepartment(value)}
      />

      <div ref={d3Container} />

      {d3Container.current &&
        !!data.length &&
        data.map((d) => {
          const element = document.getElementById(d.id);

          if (!element) return null;

          return createPortal(<NodeItem user={d} />, element);
        })}
    </div>
  );
};

export default OrgChartComponent;
