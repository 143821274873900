import ModalHeader from 'components/ModalHeader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import axios from 'axios';
import { Modal } from 'components';

export default function DenyModal({
  open,
  setOpen,
  creditNote,
  getCreditNotes,
  setReviewModalOpen,
}) {
  const { alertSuccess, alertError } = useAlert();

  const initialValues = { reason: '' };

  const validationSchema = object().shape({
    reason: string().required('Required'),
  });

  const onSubmit = async (values) => {
    await axios
      .post(`/agency/credit-notes/${creditNote.creditNoteId}/deny`, {
        reason: values.reason,
      })
      .then((response) => {
        alertSuccess('Credit note request denied', response.data.message);
        setOpen(false);
        setReviewModalOpen(false);
        getCreditNotes();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      });
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="top"
        noOverlayClick={true}
        zIndex="z-30"
      >
        <div className="inline-block w-full max-w-xl my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
          <ModalHeader
            title="What's the reason for denying this credit note request?"
            setOpen={setOpen}
            border="border-0"
            px="px-12"
            py="pt-12 pb-6"
            fontSize="text-2xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={() => setOpen(false)}
            showCloseButton={false}
            titleClasses="leading-tight"
          />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, setFieldValue, values }) => (
              <Form>
                <div className="px-12">
                  <Field
                    name="reason"
                    placeholder="Add a reason for rejecting this credit note request"
                    as="textarea"
                    rows={4}
                    className="form-select text-sm"
                  />
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />

                  <div className="flex justify-center py-10 space-x-12">
                    <button
                      onClick={() => {
                        setFieldValue('reason', '');
                        setOpen(false);
                      }}
                      className="text-grayscale-800 hover:text-grayscale-700 font-bold text-sm tracking-2"
                    >
                      Cancel
                    </button>
                    <Button
                      classes="border-0 font-bold tracking-2"
                      bgColor="error-dark"
                      hoverColor="error"
                      roundedSize="full"
                      textColor="white"
                      px={12}
                      py={2}
                      shadow=""
                      textSize="sm"
                      showLoading={true}
                      type="submit"
                    >
                      Deny
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
