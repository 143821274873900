import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { object, string, number, date, array, ref, min, boolean } from 'yup';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import Select from 'components/Forms/Select';
import Textarea from 'components/Forms/Textarea';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import { setAlert } from 'features/alerts/alertsSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchInvoice } from '../invoiceSlice';
import { isEmpty } from 'lodash';

const RecordPaymentModal = ({ invoice, open, setOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    customer_id: '',
    amount: '',
    date: '',
    payment_mode: '',
    description: '',
    reference_number: '',
  });

  const validationSchema = object().shape({
    customer_id: string().required('Required'),
    amount: number().min(0).required('Required'),
    date: date().required('Required'),
    payment_mode: string().required('Required'),
    description: string().nullable(),
    reference_number: string().nullable(),
  });

  // const [formData, setFormData] = useState({
  //   customer_id: '',
  //   amount: '',
  //   date: '',
  //   payment_mode: '',
  //   description: '',
  //   reference_number: '',
  // });

  useEffect(() => {
    if (invoice && open) {
      setInitialValues({
        ...initialValues,
        amount: invoice.balance,
        customer_id: invoice.customer_id,
      });
    }
  }, [invoice, open]);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/payments`,
        values
      );

      dispatch(fetchInvoice({ invoiceId: invoice.invoice_id, force: true }));
      dispatch(setAlert('success', res.data.data.message));
    } catch (error) {
      dispatch(setAlert('error', error.response.data.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-24 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={`Payment for ${invoice.invoice_number}`}
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div
                className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto"
                style={{ height: '65vh' }}
              >
                <div className="col-span-2">
                  <Label htmlFor="amount" classes="text-left">
                    Amount <RequiredAsterisk />
                  </Label>
                  <Field
                    name="amount"
                    placeholder=""
                    className="form-input disabled-white"
                    type="number"
                  />
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label htmlFor="date" classes="text-left">
                    Payment Date <RequiredAsterisk />
                  </Label>
                  <Field
                    name="date"
                    placeholder="YYYY-MM-DD"
                    className="form-input disabled-white"
                    type="date"
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2 ">
                  <Label>
                    Mode of Payment <RequiredAsterisk />
                  </Label>
                  <Field
                    name="payment_mode"
                    as="select"
                    className="form-select disabled-white"
                  >
                    <option value="">Choose one</option>
                    <option value="cash">Cash</option>
                    <option value="check">Check</option>
                    <option value="creditcard">Credit Card</option>
                    <option value="banktransfer">Bank Transfer</option>
                    <option value="bankremittance">Bank Remittance</option>
                    <option value="other">Others</option>
                  </Field>
                  <ErrorMessage
                    name="payment_mode"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label htmlFor="reference_number" classes="text-left">
                    Reference #
                  </Label>
                  <Field
                    name="reference_number"
                    placeholder=""
                    className="form-input disabled-white"
                    type="text"
                  />
                  <ErrorMessage
                    name="reference_number"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2">
                  <Label htmlFor="description">Notes</Label>
                  <Field
                    name="description"
                    as="textarea"
                    rows={3}
                    className="form-input disabled-white"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {/* <form method="POST" onSubmit={onSubmit}>
          <div className="py-4 px-6">
            <div className="">
              <div className="flex justify-between">
                <Label htmlFor="amount" classes="text-left">
                  Amount <RequiredAsterisk />
                </Label>
              </div>

              <Input
                id="amount"
                type="text"
                value={formData.amount}
                onChange={onInputChange}
                required
              />
            </div>
            <div className="mt-2">
              <div className="flex justify-between">
                <Label htmlFor="date" classes="text-left">
                  Payment Date <RequiredAsterisk />
                </Label>
                <span className="text-gray-400 text-xs">
                  Format: YYYY-MM-DD
                </span>
              </div>
              <Input
                id="date"
                type="text"
                value={formData.date}
                onChange={onInputChange}
                required
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="payment_mode" classes="text-left">
                Mode of Payment <RequiredAsterisk />
              </Label>
              <Select
                name="payment_mode"
                onChange={onInputChange}
                value={formData.payment_mode}
                required
              >
                <option value="cash">Cash</option>
                <option value="check">Check</option>
                <option value="creditcard">Credit Card</option>
                <option value="banktransfer">Bank Transfer</option>
                <option value="bankremittance">Bank Remittance</option>
                <option value="other">Others</option>
              </Select>
            </div>
            <div className="mt-2">
              <Label htmlFor="reference_number" classes="text-left">
                Reference #
              </Label>
              <Input
                id="reference_number"
                type="text"
                value={formData.reference_number}
                onChange={onInputChange}
                required
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="notes" classes="text-left">
                Notes
              </Label>
              <Textarea
                id="description"
                onChange={onInputChange}
                value={formData.description}
                rows={3}
                required
              />
            </div>
          </div>
          <div className="text-right mt-4 p-4 border-t">
            <Button
              color="gray"
              onClick={() => setOpen(false)}
              classes="mr-2"
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              color="red"
              onClick={onSubmit}
              loading={loading}
              showLoading={true}
            >
              Send
            </Button>
          </div>
        </form> */}
      </div>
    </Modal>
  );
};

export default RecordPaymentModal;
