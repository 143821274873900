import React from 'react';
import classNames from 'utils/classNames';

const Label = ({
  htmlFor,
  children,
  classes,
  textSize = 'sm',
  fontWeight = 'font-normal',
  textColor = 'text-gray-500',
}) => {
  return (
    <label
      htmlFor={htmlFor ?? ''}
      className={classNames(
        `text-${textSize}`,
        `${fontWeight}`,
        classes ?? '',
        textColor
      )}
    >
      {children}
    </label>
  );
};

export default Label;
