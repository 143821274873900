import classnames from 'classnames';
import Badge from 'components/Badge';

import classNames from 'utils/classNames';

import { strUnderscoreToSpace } from 'utils/formatters';

import {
  CANCELLED,
  DUNNING,
  EXPIRED,
  LIVE,
  NON_RENEWING,
  PAUSED,
  UNPAID,
} from 'utils/subscriptions';

const SubscriptionStatus = ({ subscription }) => {
  return subscription ? (
    <span className="flex space-x-1">
      <span
        className={classNames(
          classnames({
            'bg-#CFFFDD text-success-dark': subscription.status === LIVE,
            'bg-yellow-100 text-yellow-600':
              subscription.status === NON_RENEWING,
            'bg-error-light text-error-dark':
              subscription.status === CANCELLED ||
              subscription.status === EXPIRED,
            'bg-blue-100 text-blue-600':
              subscription.status === PAUSED ||
              subscription.status === UNPAID ||
              subscription.status === DUNNING,
          }),
          'capitalize text-13 rounded-40 px-4 py-2'
        )}
      >
        {strUnderscoreToSpace(subscription.status)}
      </span>

      {subscription.isOffline && (
        <span className="capitalize text-13 rounded-40 px-4 py-2 bg-indigo-100 text-indigo-600">
          Offline
        </span>
      )}
    </span>
  ) : (
    <span className="text-xs py-1.5 text-gray-600">Not Subscribed</span>
  );
};

export default SubscriptionStatus;
