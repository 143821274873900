import axios from 'axios';
import { PlusIcon, PencilIcon, EyeIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import classNames from 'utils/classNames';
import ButtonLink from 'components/ButtonLink';
import EditableContent from 'components/EditableContent';
import { fetchClient, setAgencyClient } from '../agencyClientSlice';
import CommissionModal from './components/CommissionModal';
import { CANCELLED } from 'utils/subscriptions';

const Commissions = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { marketplaces } = useSelector((state) => state.marketplaces);

  const [openCommissionModal, setOpenCommissionModal] = useState(false);

  const [commissionModalPayload, setCommissionModalPayload] = useState({
    action: 'add',
    commissionId: null,
  });

  const getCurrentMarketplace = (marketplaceId) => {
    const marketplace = marketplaces?.find(
      (marketplace) => marketplace.marketplaceId === marketplaceId
    );
    return marketplace ? marketplace.countryCode : '';
  };

  const onUpdateReason = async (e) => {
    await axios.post(
      `/agency/client/${agencyClient.agencyClientId}/nocommission`,
      {
        noCommission: agencyClient.noCommission,
        noCommissionReason: e.target.value,
      }
    );

    dispatch(
      setAgencyClient({ ...agencyClient, noCommissionReason: e.target.value })
    );
  };

  const onReloadCommissions = async () => {
    const res = await axios.get(
      `/agency/client/${agencyClient.agencyClientId}/commission`
    );

    if (res.data.output.length > 0) {
      dispatch(
        setAgencyClient({
          ...agencyClient,
          account: { ...agencyClient.account, commissions: res.data.output },
        })
      );
    } else {
      dispatch(fetchClient(agencyClient.agencyClientId));
    }
  };
  return (
    <>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h5 className="text-lg font-bold">Commission Rate</h5>
          {userCan('clients.commission.create') &&
            agencyClient.status !== CANCELLED && (
              <button
                onClick={() => {
                  setCommissionModalPayload({
                    action: 'add',
                    commissionId: null,
                  });
                  setOpenCommissionModal(true);
                }}
                className="text-sm font-bold tracking-widest flex items-center text-gray-400"
              >
                <PlusIcon className="h-5 w-5 text-gray-400 inline" /> Add More
              </button>
            )}
        </div>
        {agencyClient.account?.commissions &&
        agencyClient.account?.commissions.length > 0 ? (
          <div className="sm:grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 mt-4">
            {agencyClient.account?.commissions?.map((commission) => (
              <div
                key={commission.commissionId}
                className="col-span-1 border p-2 hover:shadow-lg flex flex-col h-full"
              >
                <div className="flex justify-between border-b pb-2">
                  <span className="font-bold text-sm uppercase truncate w-4/5">
                    {commission.type}
                  </span>
                  {!agencyClient.deletedAt && (
                    <ButtonLink
                      onClick={() => {
                        setCommissionModalPayload({
                          action: 'edit',
                          commissionId: commission.commissionId,
                        });
                        setOpenCommissionModal(true);
                      }}
                      color="gray"
                    >
                      {userCan('clients.commission.update') ? (
                        <PencilIcon className="w-4 h-4" />
                      ) : (
                        <EyeIcon className="w-4 h-4" />
                      )}
                    </ButtonLink>
                  )}
                </div>
                <div className="flex justify-between pt-1">
                  <label className="text-xs text-gray-400">Marketplace</label>
                  <span className="font-bold text-sm">
                    {getCurrentMarketplace(commission.marketplaceId)}
                  </span>
                </div>
                <div className="flex justify-between pt-1">
                  <label className="text-xs text-gray-400">Rate</label>
                  <span className="font-bold text-sm">{commission.rate}%</span>
                </div>

                <div className="flex justify-between pt-1">
                  <label className="text-xs text-gray-400">Baseline</label>
                  {commission.type === 'benchmark' &&
                  (!commission.managedAsins ||
                    commission.managedAsins.length <= 0) ? (
                    <span className="font-bold text-sm">
                      {commission.baseline}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>

                <div className="flex justify-between pt-1">
                  <label className="text-xs text-gray-400">
                    Month Threshold
                  </label>
                  {commission.type === 'rolling' ||
                  commission.type === 'benchmark' ? (
                    <span className="font-bold text-sm">
                      {commission.monthThreshold ?? ''}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </div>

                <div className="flex justify-between pt-2 mt-auto">
                  <label className="text-xs flex flex-col">
                    <b
                      className={classNames(
                        commission.commencedAt
                          ? 'text-green-600'
                          : 'text-gray-800'
                      )}
                    >
                      {commission.commencedAt
                        ? 'Auto-added '
                        : 'Not auto-added '}
                    </b>
                    <span
                      className={classNames(
                        commission.commencedAt
                          ? 'text-green-600'
                          : 'text-gray-400'
                      )}
                    >
                      to a pending invoice
                    </span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : (
          agencyClient.noCommission &&
          agencyClient.account?.commissions.length <= 0 && (
            <div className="col-span-1">
              <div className="p-3">
                <div className="font-bold uppercase text-sm pb-1">
                  No Commission
                </div>
                {userCan('clients.commission.update') &&
                !agencyClient.deletedAt ? (
                  <EditableContent
                    value={agencyClient.noCommissionReason}
                    tag="textarea"
                    classes="focus:ring-gray-100 focus:border-white border-0 w-full p-0 bg-transparent"
                    onUpdate={onUpdateReason}
                    openOnEditMode={!!!agencyClient.noCommissionReason}
                    textClasses="text-gray-500"
                    showEditIcon
                  />
                ) : (
                  <p className="whitespace-pre-wrap italic text-sm text-gray-300">
                    {agencyClient.noCommissionReason}
                  </p>
                )}
              </div>
            </div>
          )
        )}
      </div>
      <CommissionModal
        open={openCommissionModal}
        setOpen={setOpenCommissionModal}
        payload={commissionModalPayload}
        onReload={onReloadCommissions}
      />
    </>
  );
};

export default Commissions;
