import React from 'react';
import { useState, useEffect } from 'react';
import { PencilIcon } from '@heroicons/react/outline';
import { currencyFormatter, dateFormatter } from 'utils/formatters';
import ChangeBillingDatePicker from './ChangeBillingDatePicker';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import {
  CANCELLED,
  EXPIRED,
  LIVE,
  NON_RENEWING,
  UNPAID,
} from 'utils/subscriptions';
import { useSelector } from 'react-redux';
import ExtendBillingCycleModal from './ExtendBillingCycleModal';

const Summary = () => {
  const { userCan } = usePermissions();
  const [isOpenExtendBillingCycle, setIsOpenExtendBillingCycle] =
    useState(false);
  const {
    subscription: { z: subscription },
  } = useSelector((state) => state.subscription);
  const status = useSubscription(subscription);

  return (
    <>
      <div className="flex justify-between sm:gap-4 sm:px-5">
        <div className="flex flex-col sm:px-5 pb-2">
          <span className="text-sm text-gray-400">Subscription Amount</span>
          <span>{currencyFormatter(subscription.amount)}</span>

          <hr className="my-4" />

          <span className="text-xs text-grayscale-800">Sub Total</span>
          <span className="text-xs text-grayscale-600">
            {currencyFormatter(subscription.sub_total)}
          </span>

          <span className="text-xs text-grayscale-800">Taxes</span>
          {subscription.taxes.map((tax) => (
            <span className="text-xs text-grayscale-600" key={tax.tax_id}>
              {currencyFormatter(tax.tax_amount)} - {tax.tax_name}
            </span>
          ))}
        </div>
        <div className="col-span-2 flex flex-col sm:px-5 pb-2">
          <span className="text-sm text-gray-400">Next Billing Date</span>
          {status.hasAny([LIVE, UNPAID]) && (
            <>
              <span>{dateFormatter(subscription.next_billing_at)}</span>
              {userCan('clients.subscription.billing.update') && (
                <ChangeBillingDatePicker subscription={subscription} />
              )}
            </>
          )}
          {status.hasAny([NON_RENEWING, EXPIRED, CANCELLED]) ? (
            <span>-</span>
          ) : (
            ''
          )}
        </div>
        <div className="col-span-2 flex flex-col sm:px-5 pb-2">
          <span className="text-sm text-gray-400">Last Billing Date</span>
          <span>
            {!subscription.last_billing_at
              ? null
              : dateFormatter(subscription.last_billing_at)}
          </span>
        </div>

        {status.live() && subscription.remaining_billing_cycles >= 0 ? (
          <div className="col-span-2 flex flex-col sm:px-5 pb-2  text-right">
            <span className="text-sm text-gray-400">Renewals remaining</span>
            <span className="flex justify-end">
              {subscription.remaining_billing_cycles} cycles
              {userCan('clients.subscription.update') && (
                <button
                  className="ml-2"
                  onClick={() => setIsOpenExtendBillingCycle(true)}
                >
                  <PencilIcon className="h-4 w-4 text-red-500" />
                </button>
              )}
            </span>
            <span className="text-sm text-gray-500">
              Expires on {dateFormatter(subscription.expires_at)}
            </span>
          </div>
        ) : (
          <div className="col-span-2 flex flex-col sm:px-5 pb-2  text-right">
            &nbsp;
          </div>
        )}
        {status.nonRenewing() && (
          <div className="col-span-2 flex flex-col sm:px-5 pb-2">
            <span className="text-sm text-yellow-500">Cancellation Date</span>
            {dateFormatter(subscription.scheduled_cancellation_date)}
          </div>
        )}
        {status.cancelled() && (
          <div className="col-span-2 flex flex-col sm:px-5 pb-2">
            <span className="text-sm text-red-500">Cancelled Date</span>
            {dateFormatter(subscription.cancelled_at)}
          </div>
        )}
        {status.expired() && (
          <div className="col-span-2 flex flex-col sm:px-5 pb-2">
            <span className="text-sm text-red-500">Expiry Date</span>
            {dateFormatter(subscription.expires_at)}
          </div>
        )}
      </div>
      <ExtendBillingCycleModal
        subscription={subscription}
        open={isOpenExtendBillingCycle}
        setOpen={setIsOpenExtendBillingCycle}
      />
    </>
  );
};
export default Summary;
