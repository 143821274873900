// TODO: convert to v2
import { keys } from 'lodash';
import { useSelector } from 'react-redux';

import {
  LIST_BASE_PARAMS,
  LIST_DEFAULT_SORT,
  CUSTOM_ATTRIBUTES,
} from '../utils/constants';

import SmartFilters from './filters/SmartFilters';
import SearchBar from './filters/SearchBar';
import ExportButton from './ExportButton';
import Table from 'components/Table';
import ColumnPicker from './common/ColumnPicker';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';

const AdvertisingTable = ({
  list,
  params,
  columns,
  keyField,
  accountId,
  marketplace,
  recordType,
  campaignType,
  onChangeParams,
  additionalFilters,
  searchPlaceholder,
  searchClassName,
  filterCampaignClassName,
  filterAdGroupClassName,
  filterCampaignPlaceholder,
  filterAdGroupPlaceholder,
  filtersClassName,
  exportClassName,
  columnPickerClassName,
  attributesKey,
  visibleColumns,
  onChangeVisibleColumns,
  children,
  endpoint,
  exportInclude = [],
  showSearch = true,
  showSmartFilters = true,
  showExport = true,
  showColumnPicker = true,
  customClass = 'mb-4 grid grid-cols-1 gap-4 lg:grid-cols-12',
  ...rest
}) => {
  const selectedDates = useSelector(selectCurrentDateRange);
  const customAttributes = CUSTOM_ATTRIBUTES[campaignType];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === 'sort' && params.sort === `${sortField}:${sortOrder}`) return;

    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    onChangeParams(newParams);
  };

  const onApplySmartFilters = (filters) => {
    const newParams = { ...params, page: 1 };

    keys(newParams)
      .filter((key) => !LIST_BASE_PARAMS.includes(key))
      .forEach((key) => delete newParams[key]);

    onChangeParams({ ...newParams, ...filters });
  };

  return (
    <div className="my-4">
      <div className={customClass}>
        {showSearch && (
          <SearchBar
            params={params}
            className={searchClassName}
            onApplyFilter={onChangeParams}
            placeholder={searchPlaceholder}
          />
        )}

        {showColumnPicker && (
          <ColumnPicker
            className={columnPickerClassName}
            options={columns.map((col) => {
              return {
                key: col.dataField,
                hideable: !!col.hideable,
                display: col.display ?? col.text,
                category: col.category,
                default: !!col.default,
              };
            })}
            values={visibleColumns}
            onChange={onChangeVisibleColumns}
          />
        )}

        {showSmartFilters && (
          <SmartFilters
            accountId={accountId}
            recordType={recordType}
            campaignType={campaignType}
            className={filtersClassName}
            customAttributes={customAttributes}
            additionalFilters={additionalFilters}
            onApplyFilters={onApplySmartFilters}
          />
        )}

        {showExport && (
          <div className={exportClassName}>
            <ExportButton
              params={{
                ...params,
                accountId,
                marketplace,
                attributes: visibleColumns
                  .split(',')
                  .filter((a) => a !== 'AdvAdGroup.AdvCampaign.name')
                  .join(','),
                include: exportInclude,
                ...selectedDates,
              }}
              endpoint={endpoint}
            />
          </div>
        )}

        {children}
      </div>

      <Table
        keyField={keyField}
        columns={columns
          .filter((col) => visibleColumns.includes(col.dataField))
          .map((column) => {
            return {
              ...column,
              classes: `${column.classes} py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
              headerClasses: `${column.headerClasses} py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
            };
          })}
        data={list}
        onTableChange={onTableChange}
        params={params}
        defaultSorted={LIST_DEFAULT_SORT}
        bordered={false}
        {...rest}
      />
    </div>
  );
};

export default AdvertisingTable;
