import { isNumber, startCase } from 'lodash';
import metrics from '../../../../utils/metrics';

const FilterCondition = ({ value, attribute, comparison }) => {
  const metric = metrics.find((m) => m.key === attribute);
  const valueFormatter = metric ? metric.rawFormatter : (value) => value;

  const comparisonFormatter = {
    lessThan: () => `< ${isNumber(value) ? valueFormatter(value) : value}`,
    lessThanOrEqualTo: () =>
      `<= ${isNumber(value) ? valueFormatter(value) : value}`,
    notEqualTo: () => `!= ${isNumber(value) ? valueFormatter(value) : value}`,
    equalTo: () => `= ${isNumber(value) ? valueFormatter(value) : value}`,
    greaterThan: () => `> ${isNumber(value) ? valueFormatter(value) : value}`,
    greaterThanOrEqualTo: () =>
      `>= ${isNumber(value) ? valueFormatter(value) : value}`,
    between: () =>
      `between ${valueFormatter(value[0])} and ${valueFormatter(value[1])}`,
  };

  return (
    <span className="inline-flex font-inter items-center rounded-full bg-grayscale-500 tracking-3/4 px-2.5 py-0.5 text-tiny text-grayscale-800 mr-1 mb-1 cursor-pointer">
      {metric ? metric.title : startCase(attribute)}{' '}
      {comparisonFormatter[comparison]()}
    </span>
  );
};

export default FilterCondition;
