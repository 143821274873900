import { startCase, upperFirst } from 'lodash';
import metrics from 'features/clients/Client/Account/Advertising/utils/metrics';

const MostImprovedTargetings = ({ className = '', productAds }) => {
  return (
    <div className={`${className} bg-white`}>
      <div className="flex items-center justify-between px-8 pt-6 pb-2">
        <p className="text-lg font-bold text-grayscale-900 tracking-3/4">
          Top 5 most improved keywords
        </p>
      </div>

      <div className="px-2 pb-2">
        <table className="divide-y divide-grayscale-300 w-full table-fixed font-sourceSansPro">
          <thead className="bg-success-dark">
            <tr className="">
              {[
                'Keywords',
                'Campaign',
                'Ad Group',
                'Previous',
                'Current',
                'Change',
              ].map((col) => (
                <th
                  key={`most-improved-productAds-headers-${col}`}
                  scope="col"
                  className="p-3 text-tiny font-bold text-white border border-grayscale-500"
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>

          <tbody></tbody>

          <tbody>
            {productAds.rows.map((row, rowIdx) => {
              return (
                <tr
                  key={row.advProductAdId}
                  className={rowIdx % 2 === 1 ? 'bg-grayscale-400' : ''}
                >
                  <td className="px-3 py-2 text-9 text-grayscale-800 border border-grayscale-500">
                    <div>
                      <p className="text-secondary-light">{row.value}</p>
                      {row.matchType && (
                        <p className="text-grayscale-600 text-8 capitalize">
                          {row.matchType}
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-2 text-9 text-grayscale-800 border border-grayscale-500">
                    <div>
                      <p className="text-secondary-light">
                        {startCase(row.campaign.name)}
                      </p>
                      <p className="text-grayscale-600 text-8 capitalize">
                        {startCase(row.campaign.campaignType)}
                      </p>
                      <p className="text-grayscale-600 text-8 capitalize">
                        {row.campaign.targetingType}
                      </p>
                    </div>
                  </td>
                  <td className="px-3 py-2 text-9 text-grayscale-800 border border-grayscale-500">
                    <div>
                      <p className="text-secondary-light text-wrap">
                        {row.adGroup.name}
                      </p>
                      <p className="text-grayscale-600 text-8 capitalize">
                        {startCase(row.type)} Targeting
                      </p>
                    </div>
                  </td>
                  <td className="p-3 text-9 text-grayscale-800 border border-grayscale-500 text-center">
                    {metrics
                      .find((m) => m.key === 'sales')
                      .rawFormatter(row[`previous${upperFirst('sales')}`])}
                  </td>
                  <td className="p-3 text-9 text-grayscale-800 border border-grayscale-500 text-center">
                    {metrics
                      .find((m) => m.key === 'sales')
                      .rawFormatter(row['sales'])}
                  </td>

                  <td className="p-3 text-9 text-grayscale-800 border border-grayscale-500 text-center">
                    {metrics
                      .find((m) => m.key === 'sales')
                      .rawFormatter(row[`changes${upperFirst('sales')}`])}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MostImprovedTargetings;
