import axios from 'axios';
import Button from 'components/Button';
import cardWireframe from 'assets/images/card-wireframe.svg';
import QuoteModal from './components/QuoteModal';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PencilIcon, EyeIcon } from '@heroicons/react/solid';
import { currencyFormatter } from 'utils/formatters';
import ButtonLink from 'components/ButtonLink';
import usePermissions from 'hooks/usePermissions';
import { fetchPlans } from 'features/zoho/zohoSlice';
import { fetchMarketplaces } from 'features/clients/clientsSlice';

const CallBookingQuotes = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [quoteModalPayload, setQuoteModalPayload] = useState({
    action: 'add',
    salesQuoteId: null,
  });
  const [loading, setLoading] = useState(false);
  const { client } = useSelector((state) => state.sales);
  const [quotes, setQuotes] = useState(null);

  const { plans } = useSelector((state) => state.zoho);
  const { marketplaces } = useSelector((state) => state.clients);

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlans());
    }
    if (!marketplaces.length > 0) {
      dispatch(fetchMarketplaces());
    }
  }, [plans, dispatch]);

  const getQuotes = async () => {
    setLoading(true);
    await axios
      .get('/agency/sales/callBookings/quotes', {
        params: {
          pageSize: 30,
          salesClientId: client.salesClientId,
        },
      })
      .then((res) => {
        setQuotes(res.data.data);
      });
    setLoading(false);
  };

  useEffect(() => {
    async function getData() {
      await getQuotes();
    }
    if (client) {
      getData();
    }
  }, [client]);

  const getPlanName = (planCode) => {
    const plan = plans ? plans.filter((i) => i.plan_code === planCode) : null;

    if (plan && plan.length > 0) {
      return plan[0].name;
    }

    return planCode;
  };

  return (
    <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
      <div className="flex justify-between">
        <h4 className="col-span-1 text-2xl font-bold">Quotes</h4>
        {userCan('sales.profiles.quotes.manage') && (
          <Button
            roundedSize="3xl"
            classes="tracking-wider font-bold"
            px={7}
            py={2}
            textSize="sm"
            color="green"
            onClick={() => {
              setQuoteModalPayload({ action: 'add', salesQuoteId: null });
              setOpenQuoteModal(true);
            }}
          >
            Create Quote
          </Button>
        )}
      </div>
      <div className="col-span-1 mt-4">
        {quotes && quotes.count > 0 ? (
          <div className="sm:grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {quotes.rows.map((row) => (
              <div
                key={row.salesQuoteId}
                className="col-span-1 border p-2 hover:shadow-lg"
              >
                <div className="flex justify-between border-b pb-2">
                  <span className="font-bold text-sm uppercase truncate w-4/5">
                    {getPlanName(row.service)}
                  </span>
                  <ButtonLink
                    onClick={() => {
                      setQuoteModalPayload({
                        action: 'edit',
                        salesQuoteId: row.salesQuoteId,
                      });
                      setOpenQuoteModal(true);
                    }}
                    color="gray"
                  >
                    {userCan('sales.profiles.quotes.manage') ? (
                      <PencilIcon className="w-4 h-4" />
                    ) : (
                      <EyeIcon className="w-4 h-4" />
                    )}
                  </ButtonLink>
                </div>
                <div className="flex justify-between pt-2">
                  <label className="text-sm text-gray-500">Amount</label>
                  <span className="font-bold text-sm">
                    {currencyFormatter(row.monthlyRetainer)}
                  </span>
                </div>
                {row.managedAsins.length > 0 && (
                  <div className="flex justify-between pt-2">
                    <label className="text-sm text-gray-500">ASINS</label>
                    <div className="flex flex-col overflow-hidden">
                      {row.managedAsins.map((data, i) => (
                        <span
                          key={i}
                          className="font-bold text-sm truncate w-28 text-right"
                        >
                          {data.asin}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className=" flex flex-col items-center space-y-4">
            <div className="flex space-x-8 justify-center">
              <img src={cardWireframe} className="w-1/4 sm:w-auto" />
              <img src={cardWireframe} className="w-1/4 sm:w-auto" />
              <img src={cardWireframe} className="w-1/4 sm:w-auto" />
            </div>
            <h4 className="font-bold text-2xl text-gray-700 text-center leading-tight mb-2">
              No quotes available <br />
              <span className="font-normal text-base text-gray-500 ">
                You haven't created a quote yet.
              </span>
            </h4>
          </div>
        )}
      </div>

      <QuoteModal
        open={openQuoteModal}
        setOpen={setOpenQuoteModal}
        payload={quoteModalPayload}
        reloadQuotes={getQuotes}
      />
    </div>
  );
};

export default CallBookingQuotes;
