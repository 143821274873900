import { keys } from 'lodash';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';

import classNames from 'utils/classNames';

const FormModal = ({
  open,
  setOpen,
  onSubmit,
  title,
  form = {},
  updateForm,
  loading,
}) => {
  const onChange = (e) => {
    let newForm = { ...form };
    const { name, value } = e.target;
    newForm[name] = value;

    updateForm(newForm);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="font-sourceSansPro inline-block w-full max-w-sm my-8 text-left align-middle transition-all transform bg-grayscale-400 shadow-xl rounded-2xl">
        <ModalHeader
          title={title}
          titleClasses="text-grayscale-800 font-bold leading-normal font-sourceSansPro tracking-3/4"
          showCloseButton={false}
        />
        <div className="p-5">
          <input
            name="keywordText"
            className="py-4 mb-8 border-0 bg-white block w-full rounded-2xl border-gray-300 px-6 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lowercase"
            placeholder="Add keyword"
            value={form.keywordText}
            onChange={onChange}
          />

          {'matchType' in form && (
            <select
              value={form.matchType}
              onChange={onChange}
              name="matchType"
              className="py-4 mb-8 border-0 bg-white block w-full rounded-2xl border-gray-300 px-6 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal uppercase"
            >
              {['exact', 'broad', 'phrase'].map((matchType) => (
                <option
                  key={`keyword-tracking-form-modal-${matchType}`}
                  className="uppercase"
                  value={matchType}
                >
                  {matchType}
                </option>
              ))}
            </select>
          )}

          <div className="flex justify-end">
            <button
              onClick={() => setOpen(false)}
              className="tracking-2 font-bold leading-normal text-secondary-light rounded-2xl px-6 py-2"
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              disabled={!keys(form).every((attr) => !!form[attr]) || loading}
              className={classNames(
                !keys(form).every((attr) => !!form[attr]) || loading
                  ? 'cursor-not-allowed'
                  : 'hover:bg-secondary-light',
                'tracking-2 font-bold leading-normal bg-secondary  text-grayscale-300 rounded-2xl px-6 py-2'
              )}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
