import React, { useEffect } from 'react';
import Header from 'layouts/components/Header';
import { NavProvider } from 'layouts/NavProvider';

const OnboardingLayout = ({ children }) => {
  return (
    <NavProvider>
      <div className="h-screen bg-gray-50 flex min-h-screen">
        <div className="flex-1 flex flex-col">
          <Header isOnboarding={true} />

          <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 flex-grow bg-gray-50">
            {children}
          </div>
        </div>
      </div>
    </NavProvider>
  );
};

export default OnboardingLayout;
