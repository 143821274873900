import { State } from '@bmulingbayan22/country-state';
import update from 'immutability-helper';
import { sum } from 'lodash';

export const initialSetupState = {
  hostedPageDetails: null,
  plan: null,
  zohoCustomer: null,
  provinces: '',
  selectedCountry: '',
  selectedProvince: '',
  selectedTax: null,
  selectedTaxRate: 0.0,
  taxAmount: 0,
  subTotal: 0,
  total: 0,
  currencyCode: 'USD',
  addons: [],
  onboardingHistory: {
    subscriptionCreated: false,
    paymentMade: false,
    paymentConfirmed: false,
  },
  billingAddress: null,
  startsAt: '',
};

export function setupReducer(setup, action) {
  switch (action.type) {
    case 'updateInitialAddress': {
      const provinces = State.getStatesOfCountry(action.country.isoCode);
      const province = provinces.find(({ name }) => name === action.province);
      let taxRate = 0;
      let taxAmount = 0;
      let selectedTax = null;
      if (setup.zohoCustomer) {
        selectedTax = action.taxRates.find(
          ({ name }) => name === setup.zohoCustomer.tax_name
        );

        if (selectedTax) {
          taxRate = selectedTax.rate;
          taxAmount = setup.subTotal * taxRate;
        }
      } else if (action.country.name === 'Canada' && action.province) {
        selectedTax = action.taxRates.find(
          ({ name }) => name === action.province
        );

        if (selectedTax) {
          taxRate = selectedTax.rate;
          taxAmount = setup.subTotal * taxRate;
        }
      }

      const total = setup.subTotal + taxAmount;

      return update(setup, {
        selectedCountry: { $set: action.country },
        provinces: { $set: provinces },
        selectedProvince: { $set: province },
        selectedTax: { $set: selectedTax },
        selectedTaxRate: { $set: taxRate },
        taxAmount: { $set: taxAmount },
        total: { $set: total },
      });
    }
    case 'updateCountry': {
      const provinces = State.getStatesOfCountry(action.country.isoCode);

      return update(setup, {
        selectedCountry: { $set: action.country },
        provinces: { $set: provinces },
        selectedProvince: { $set: '' },
        selectedTax: { $set: null },
        selectedTaxRate: { $set: 0 },
        taxAmount: { $set: 0 },
        total: { $set: setup.subTotal },
      });
    }
    case 'updateProvince': {
      let taxRate = 0;
      let taxAmount = 0;
      let selectedTax = null;
      if (setup.zohoCustomer) {
        selectedTax = action.taxRates.find(
          ({ name }) => name === setup.zohoCustomer.tax_name
        );

        if (selectedTax) {
          taxRate = selectedTax.rate;
          taxAmount = setup.subTotal * taxRate;
        }
      } else if (setup.selectedCountry.name === 'Canada' && action.province) {
        selectedTax = action.taxRates.find(
          ({ name }) => name === action.province.name
        );

        if (selectedTax) {
          taxRate = selectedTax.rate;
          taxAmount = setup.subTotal * taxRate;
        }
      }

      const total = setup.subTotal + taxAmount;

      return update(setup, {
        selectedProvince: { $set: action.province },
        selectedTax: { $set: selectedTax },
        selectedTaxRate: { $set: taxRate },
        taxAmount: { $set: taxAmount },
        total: { $set: total },
      });
    }
    case 'updateData': {
      const { data, zohoCustomer, account, billingAddress } = action.data;
      let subTotal = 0;
      let plan = null;
      let startsAt = '';

      if (data) {
        const addonsTotal = sum(
          data.addons.map((addon) => {
            return parseFloat(addon.price) * parseFloat(addon.quantity);
          })
        );
        subTotal = parseFloat(data.price) + addonsTotal;

        plan = {
          plan_code: data.plan_code,
          plan_description: data.plan_description,
          price: data.price,
          quantity: 1,
          billing_cycles: data.billing_cycles,
        };

        startsAt = data.starts_at;
      }

      let onboardingHistory = { ...setup.onboardingHistory };

      if (account) {
        onboardingHistory.subscriptionCreated =
          account.onboardingHistory?.subscriptionCreated ?? false;
        onboardingHistory.paymentMade =
          account.onboardingHistory?.paymentMade ?? false;
        onboardingHistory.paymentConfirmed =
          account.onboardingHistory?.paymentConfirmed ?? false;
      }

      return update(setup, {
        hostedPageDetails: { $set: data },
        plan: { $set: plan },
        addons: { $set: data.addons },
        zohoCustomer: { $set: zohoCustomer },
        subTotal: { $set: subTotal },
        total: { $set: subTotal },
        currencyCode: { $set: data.currency_code },
        onboardingHistory: { $set: onboardingHistory },
        billingAddress: { $set: billingAddress },
        startsAt: { $set: startsAt },
      });
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
