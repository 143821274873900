import { useTranslation } from 'react-i18next';
import dot from 'dot-object';
import {
  CHANGE_TO,
  DECREASE_BY,
  INCREASE_BY,
  PERCENTAGE,
  VALUE,
} from 'features/advertising/utils/constants';

const UpdateBid = ({ data, onChangeData, errors = {}, label }) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    const { name, value } = e.target;
    let newData = { ...data, [name]: value };
    if (name === 'type') {
      newData.value =
        value === INCREASE_BY || value === DECREASE_BY
          ? { type: VALUE, value: '' }
          : '';
    }
    onChangeData(dot.object(newData));
  };

  return (
    <div className="grid grid-cols-2 gap-4 mt-4">
      <div>
        <label
          htmlFor="type"
          className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
        >
          {label}
        </label>
        <div className="mt-1">
          <select
            id="type"
            name="type"
            className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
            value={data.type}
            onChange={onChange}
          >
            <option value="">
              {t('Advertising.Rule.Action.UpdateBid.SelectType')}
            </option>
            <option value={CHANGE_TO}>
              {t('Advertising.Rule.Action.UpdateBid.ChangeTo')}
            </option>
            <option value={INCREASE_BY}>
              {t('Advertising.Rule.Action.UpdateBid.IncreaseBy')}
            </option>
            <option value={DECREASE_BY}>
              {t('Advertising.Rule.Action.UpdateBid.DecreaseBy')}
            </option>
          </select>
          {errors['actionData.type'] && (
            <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
              {errors['actionData.type']}
            </p>
          )}
        </div>
      </div>
      {data.type ? (
        data.type === CHANGE_TO ? (
          <div>
            <label
              htmlFor="value"
              className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
            >
              {t('Advertising.Rule.Action.UpdateBid.Value')}
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="number"
                name="value"
                id="value"
                className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 px-3 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
                value={data.value}
                onChange={onChange}
              />
              {errors['actionData.value'] && (
                <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
                  {errors['actionData.value']}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div>
            <label
              htmlFor="value"
              className="block text-tiny font-bold text-grayscale-700 font-medium tracking-3/4"
            >
              {t('Advertising.Rule.Action.UpdateBid.Value')}
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                type="number"
                name="value.value"
                id="value.value"
                className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 pl-3 pr-16 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
                value={data.value.value}
                onChange={onChange}
              />

              <div className="absolute inset-y-0 right-0 flex items-center">
                <select
                  id="value.type"
                  name="value.type"
                  className="py-3.5 border-0 bg-grayscale-400 block w-full rounded-2xl border-gray-300 pl-3 pr-7 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900"
                  value={data.value.type}
                  onChange={onChange}
                >
                  <option value={PERCENTAGE}>%</option>
                  <option value={VALUE}>$</option>
                </select>
              </div>
            </div>
            {errors['actionData.value.value'] && (
              <p className="text-10 font-medium tracking-3/4 font-inter text-error-dark">
                {errors['actionData.value.value']}
              </p>
            )}
          </div>
        )
      ) : null}
    </div>
  );
};

export default UpdateBid;
