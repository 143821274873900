import { Route, Switch, withRouter } from 'react-router-dom';

import {
  Roles,
  EmployeeList,
  EmployeeDetails,
  OrganizationChart,
} from 'features/employees/pages';
import RolePermission from 'features/permissions/RolePermission';

const Employees = () => {
  const tabs = [
    {
      name: 'Overview',
      href: `/employees`,
      exact: true,
      component: EmployeeList,
    },
    {
      name: 'Organization Chart',
      href: `/employees/organization-chart`,
      exact: true,
      component: OrganizationChart,
    },
    {
      name: 'Roles',
      href: `/employees/roles`,
      exact: true,
      component: Roles,
    },
    {
      name: 'Role Permissionss',
      href: `/employees/roles/:roleId/permissions`,
      exact: true,
      component: RolePermission,
    },
    {
      href: `/employees/:employeeId`,
      exact: true,
      component: EmployeeDetails,
    },
  ];

  return (
    <Switch>
      {tabs.map((tab) => (
        <Route
          key={tab.href}
          path={tab.href}
          component={tab.component}
          exact={tab.exact}
        />
      ))}
    </Switch>
  );
};
export default withRouter(Employees);
