import { Popover } from '@headlessui/react';
import { PlusIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { debounce, isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import { getNameInitials } from 'utils/formatters';
import InputPrepend from './Forms/InputPrepend';
import ReactTooltip from 'react-tooltip';
import { usePopper } from 'react-popper';

const UsersPopover = ({
  users,
  selectedId,
  onChange,
  disabled,
  position = 'left-1/2 -translate-x-1/2 transform',
  width = 'w-64',
  iconSize = 'md',
  showName = false,
  deletable = false,
  onDelete,
}) => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const iconSizes = {
    sm: 'h-6 w-6 leading-6',
    md: 'h-9 w-9 leading-9',
    lg: 'h-12 w-12 leading-12',
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (selectedId && users) {
      setSelectedUser(users.find((user) => user.userId === selectedId));
    } else {
      setSelectedUser(null);
    }
  }, [selectedId, users]);

  const onSearch = (e) => {
    const search = e.target.value.toLowerCase();

    let f = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(search) ||
        user.lastName.toLowerCase().includes(search)
    );
    setFilteredUsers(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="w-full max-w-sm px-4">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              ref={setReferenceElement}
              disabled={disabled}
              className={`${
                open ? '' : 'text-opacity-90'
              } group inline-flex items-center rounded-sm bg-orange-700 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div
                className={classNames(
                  selectedUser
                    ? 'bg-red-500 text-white flex text-base text-center font-normal'
                    : 'bg-transparent text-xs border border-dashed border-gray-600 flex items-center justify-center',
                  iconSizes[iconSize],
                  'uppercase rounded-3xl'
                )}
              >
                {selectedUser ? (
                  <div>
                    <div className="relative">
                      {deletable && !disabled && (
                        <span
                          className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-red-400 ring-2 ring-white cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            isFunction(onDelete) && onDelete(selectedUser);
                          }}
                        >
                          <XIcon className="w-3 h-3 text-white" />
                        </span>
                      )}
                      <span
                        className={classNames(
                          iconSizes[iconSize],
                          'inline-block'
                        )}
                        data-tip
                        data-for={`user-${selectedId}`}
                      >
                        {getNameInitials(
                          selectedUser.firstName,
                          selectedUser.lastName
                        )}
                      </span>
                    </div>
                    <ReactTooltip
                      id={`user-${selectedId}`}
                      place="bottom"
                      effect="solid"
                      backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                      textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                      padding="0 10px"
                    >
                      {selectedUser.firstName} {selectedUser.lastName}
                    </ReactTooltip>
                  </div>
                ) : (
                  <PlusIcon className="w-6 h-6 inline text-gray-400" />
                )}
              </div>
              {showName && selectedUser && (
                <span className="text-grayscale-700 text-xs ml-2 text-left">
                  {selectedUser.firstName} {selectedUser.lastName}
                </span>
              )}
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={classNames(
                position,
                width,
                'absolute z-20 min-h-full max-w-sm'
              )}
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white">
                    <div className="border-b">
                      <InputPrepend
                        name="search"
                        onChange={(e) => onDebouncedSearch(e)}
                        type="text"
                        placeholder="Search..."
                        prependText={<SearchIcon className="w-4 h-4" />}
                        border="border-none"
                        rounded="rounded-none"
                        classes="text-base"
                        autoFocus
                      />
                    </div>
                    <div className="overflow-y-auto h-72 py-4 px-2 text-left">
                      <span className="uppercase text-1xs px-3 text-gray-500">
                        People
                      </span>
                      {filteredUsers.length > 0 ? (
                        filteredUsers.map((user) => {
                          return (
                            <button
                              className="flex items-center w-full text-left px-2 py-1 space-x-3 text-sm hover:bg-gray-100 rounded-md focus:ring-gray-300 focus:border-gray-200"
                              key={user.userId}
                              value={user.userId}
                              onClick={() => {
                                onChange(user);
                                close();
                              }}
                            >
                              <span className="block bg-red-500 text-white rounded-2xl w-8 h-8 leading-8 text-sm uppercase text-center font-normal">
                                {getNameInitials(user.firstName, user.lastName)}
                              </span>
                              <span>
                                {user.firstName} {user.lastName}
                              </span>
                            </button>
                          );
                        })
                      ) : (
                        <div className="w-full text-xs text-gray-700 py-2 px-3">
                          No users found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default UsersPopover;
