import axios from 'axios';
import * as Yup from 'yup';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import classNames from 'utils/classNames';
import { useAlerts } from 'features/alerts';
import useTaskManagement from 'features/taskManagement/useTaskManagement';
import cleanify from 'utils/cleanify';
import RecurrenceModal from './RecurrenceModal';
import { isString } from 'lodash';

const TaskForm = ({
  taskListTemplate,
  taskItemTemplate,
  onCancel,
  onSuccess,
  layoutClass,
  className = '',
}) => {
  const { popupAlert } = useAlerts();
  const { DEPARTMENTS } = useTaskManagement();

  const onSubmit = async (values, actions) => {
    try {
      const payload = cleanify(values);

      const { data: response } = taskItemTemplate
        ? await axios.put(
            `/v2/tasks/items/templates/${taskItemTemplate.taskItemTemplateId}`,
            payload
          )
        : await axios.post('/v2/tasks/items/templates', payload);

      actions.resetForm();
      onSuccess();

      popupAlert({ type: 'success', title: response.message });
    } catch (error) {
      popupAlert({
        type: 'error',
        title: error?.response?.data?.message || 'Something went wrong',
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        title: taskItemTemplate?.title ?? '',
        description: taskItemTemplate?.description ?? '',
        department: taskItemTemplate?.department ?? '',
        taskListTemplateType: taskListTemplate?.type,
        taskListTemplateId: taskListTemplate?.taskListTemplateId,
        recurringConfig:
          taskListTemplate?.type === 'recurring'
            ? taskListTemplate?.recurringConfig ?? {
                frequency: 'DAILY',
                interval: 1,
                daysOfWeek: [],
                dayOfMonth: 1,
                endType: 'NEVER',
                endAfterOccurrences: 1,
              }
            : {},
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        department: Yup.string().when('taskListTemplateType', {
          is: 'onboarding',
          then: Yup.string(),
          otherwise: Yup.string().required(),
        }),
        recurringConfig: Yup.object().when('taskListTemplateType', {
          is: 'recurring',
          then: Yup.object()
            .shape({
              frequency: Yup.string().required(),
              interval: Yup.number().required().min(1, 'Minimum of 1'),
              dayOfMonth: Yup.number().required().min(1),
              daysOfWeek: Yup.array()
                .of(Yup.string())
                .required()
                .when('frequency', {
                  is: 'WEEKLY',
                  then: Yup.array()
                    .of(Yup.string())
                    .required()
                    .min(1, 'Must have 1 at least day'),
                }),
              endType: Yup.string().required(),
              endAfterOccurrences: Yup.number()
                .required()
                .min(1, 'Minimum of 1'),
            })
            .required(),
        }),
      })}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div className={className}>
            <div className={classNames('grid grid-cols-7 gap-3 grow')}>
              <div className={classNames(layoutClass?.columns?.title)}>
                <Field
                  name="title"
                  className="form-input bg-grayscale-400 rounded-xl text-13 leading-1.5 text-grayscale-900"
                  type="text"
                  placeholder="Enter task name"
                />

                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>

              <div className={layoutClass?.columns?.description}>
                <Field
                  name="description"
                  className="form-input bg-grayscale-400 rounded-xl text-13 leading-1.5 text-grayscale-900"
                  type="text"
                  placeholder="Enter task description"
                />

                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
              {layoutClass?.columns?.recurringConfig &&
                taskListTemplate?.type === 'recurring' && (
                  <div
                    className={classNames(
                      'flex items-center justify-center',
                      layoutClass?.columns?.dueDate
                    )}
                  >
                    <RecurrenceModal
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                    />

                    {isString(errors?.recurringConfig) && (
                      <ErrorMessage
                        name="recurringConfig"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    )}
                  </div>
                )}

              {layoutClass?.columns?.department && (
                <div className={layoutClass?.columns?.department}>
                  <Field
                    as="select"
                    name="department"
                    className="form-input bg-grayscale-400 rounded-xl text-13 leading-1.5 text-grayscale-900"
                    type="text"
                    placeholder="Enter task name"
                  >
                    <option> Select Department</option>
                    {DEPARTMENTS.map((department) => (
                      <option key={department.value} value={department.value}>
                        {department.label}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="department"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
              )}

              <div className="flex items-center justify-center space-x-2">
                <button
                  type="submit"
                  className="w-8 h-8 flex items-center justify-center border-2 border-secondary bg-secondary rounded-10"
                >
                  <CheckIcon className="w-5 h-5 text-white" />
                </button>

                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="border-2 border-grayscale-700 w-8 h-8 flex items-center justify-center bg-white rounded-10"
                >
                  <XIcon className="w-5 h-5 text-grayscale-700" />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TaskForm;
