// Levels
const AGENCY_LEVEL = 'agency';
const SYSTEM_LEVEL = 'system';
const ACCOUNT_LEVEL = 'account';
const APPLICATION_LEVEL = 'application';

// Sub Departments
const SI_PPC_SUB_DEPARTMENT = {
  value: 'SI-PPC',
  label: 'SI-PPC',
  color: '#BFA654',
  bgColor: '#FFEFD0',
};

const SI_ADMIN_SUB_DEPARTMENT = {
  value: 'SI-ADMIN',
  label: 'SI-Admin',
  color: '#D85B5B',
  bgColor: '#FFE5E5',
};

const SI_DESIGN_SUB_DEPARTMENT = {
  value: 'SI-DESIGN',
  label: 'SI-Design',
  color: '#E14DCA',
  bgColor: '#FFD4F6',
};

const SI_WRITING_SUB_DEPARTMENT = {
  value: 'SI-WRITING',
  label: 'SI-Writing',
  color: '#AE63D1',
  bgColor: '#F2E1FF',
};

const SI_OPERATIONS_SUB_DEPARTMENT = {
  value: 'SI-OPERATIONS',
  label: 'SI-Operations',
  color: '#002F5D',
  bgColor: '#D3E4F5',
};

// Departments
const OUTGIVE_DEPARTMENT = {
  value: 'OUTGIVE',
  label: 'Outgive',
  color: '#FDA639',
  bgColor: '#10173E',
};

const SALES_DEPARTMENT = {
  value: 'SALES',
  label: 'Sales',
  color: '#C30000',
  bgColor: '#FFAFAF',
};

const BILLING_DEPARTMENT = {
  value: 'BILLING',
  label: 'Billing',
  color: '#00966D',
  bgColor: '#CFFFDD',
};

const OPERATIONS_DEPARTMENT = {
  value: 'OPERATIONS',
  label: 'Operations',
  color: '#666666',
  bgColor: '#ECECEC',
  subDepartments: [
    SI_OPERATIONS_SUB_DEPARTMENT,
    SI_PPC_SUB_DEPARTMENT,
    SI_WRITING_SUB_DEPARTMENT,
    SI_DESIGN_SUB_DEPARTMENT,
  ],
};

const LEAD_GENERATION_DEPARTMENT = {
  value: 'LEAD_GENERATION',
  label: 'Lead Generation',
  color: '#D48236',
  bgColor: '#FFD2B9',
};

// Roles
const APPLICATION_USER = {
  name: 'User',
  value: 'APPLICATION_USER',
  level: APPLICATION_LEVEL,
};

const SYSTEM_ADMINISTRATOR = {
  name: 'Administrator',
  value: 'SYSTEM_ADMINISTRATOR',
  level: SYSTEM_LEVEL,
};

const SYSTEM_SUPER_USER = {
  name: 'super user',
  value: 'SYSTEM_SUPER_USER',
  level: SYSTEM_LEVEL,
};

const ACCOUNT_ADMINISTRATOR = {
  name: 'Administrator',
  value: 'ACCOUNT_ADMINISTRATOR',
  level: ACCOUNT_LEVEL,
};

const ACCOUNT_OWNER = {
  name: 'Owner',
  value: 'ACCOUNT_OWNER',
  level: ACCOUNT_LEVEL,
};
const ACCOUNT_USER = {
  name: 'User',
  value: 'ACCOUNT_USER',
  level: ACCOUNT_LEVEL,
};

const AGENCY_SUPER_USER = {
  name: 'super user',
  value: 'AGENCY_SUPER_USER',
  level: AGENCY_LEVEL,
  seniorityLevel: 0,
};

const CEO = {
  name: 'CEO',
  value: 'CEO',
  level: AGENCY_LEVEL,
  seniorityLevel: 1,
  color: '#739AF0',
};

const AGENCY_UNASSIGNED_EMPLOYEE = {
  name: 'Unassigned Employee',
  value: 'AGENCY_UNASSIGNED_EMPLOYEE',
  level: AGENCY_LEVEL,
  seniorityLevel: 7,
};

const DEMAND_GENERATION_MANAGER = {
  name: 'Demand Generation Manager',
  value: 'DEMAND_GENERATION_MANAGER',
  supervisorRole: CEO.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 2,
  color: '#739AF0',
};

const LEAD_GENERATION_TEAM_CAPTAIN = {
  name: 'Lead Generation Team Captain',
  value: 'LEAD_GENERATION_TEAM_CAPTAIN',
  supervisorRole: DEMAND_GENERATION_MANAGER.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#739AF0',
};

const LEAD_GENERATION_REPRESENTATIVE = {
  name: 'Lead Generation Representative',
  value: 'LEAD_GENERATION_REPRESENTATIVE',
  supervisorRole: LEAD_GENERATION_TEAM_CAPTAIN.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#739AF0',
};

const SENIOR_LEAD_GENERATION_REPRESENTATIVE = {
  name: 'Senior Lead Generation Representative',
  value: 'SENIOR_LEAD_GENERATION_REPRESENTATIVE',
  supervisorRole: LEAD_GENERATION_TEAM_CAPTAIN.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#739AF0',
};

const LEAD_PITCHING_TEAM_CAPTAIN = {
  name: 'Lead Pitching Team Captain',
  value: 'LEAD_PITCHING_TEAM_CAPTAIN',
  supervisorRole: DEMAND_GENERATION_MANAGER.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#739AF0',
};

const LEAD_GENERATION_PITCHER = {
  name: 'Lead Generation Pitcher',
  value: 'LEAD_GENERATION_PITCHER',
  supervisorRole: LEAD_PITCHING_TEAM_CAPTAIN.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#739AF0',
};

const QUALITY_ANALYST_MANAGER = {
  name: 'QA Manager Lead Gen',
  value: 'QUALITY_ANALYST_MANAGER',
  supervisorRole: DEMAND_GENERATION_MANAGER.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#739AF0',
};

const QA_BS_DATA_ANALYST = {
  name: 'QA/BS Data Analyst',
  value: 'QA_BS_DATA_ANALYST',
  supervisorRole: QUALITY_ANALYST_MANAGER.value,
  department: LEAD_GENERATION_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#739AF0',
};

const HEAD_OF_FINANCE = {
  name: 'Head of Finance',
  value: 'HEAD_OF_FINANCE',
  supervisorRole: CEO.value,
  department: BILLING_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 2,
  color: '#4EA660',
};

const FINANCE_ADMINISTRATOR = {
  name: 'Finance Adminstrator',
  value: 'FINANCE_ADMINISTRATOR',
  supervisorRole: HEAD_OF_FINANCE.value,
  department: BILLING_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#4EA660',
};

const BILLING_SPECIALIST = {
  name: 'Billing Specialist',
  value: 'BILLING_SPECIALIST',
  supervisorRole: HEAD_OF_FINANCE.value,
  department: BILLING_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#4EA660',
};

const DIRECTOR_OF_OPERATIONS = {
  name: 'Director of Operations',
  value: 'DIRECTOR_OF_OPERATIONS',
  supervisorRole: CEO.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 2,
  color: '#739AF0',
};

const OPERATIONS_ADMIN_MANAGER = {
  name: 'Operations Admin Manager',
  value: 'OPERATIONS_ADMIN_MANAGER',
  supervisorRole: DIRECTOR_OF_OPERATIONS.value,
  department: OPERATIONS_DEPARTMENT.value,
  subDepartment: SI_ADMIN_SUB_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#C6803F',
};

const OPERATIONS_ADMIN_LEAD = {
  name: 'Operations Admin Lead',
  value: 'OPERATIONS_ADMIN_LEAD',
  supervisorRole: OPERATIONS_ADMIN_MANAGER.value,
  department: OPERATIONS_DEPARTMENT.value,
  subDepartment: SI_ADMIN_SUB_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#C6803F',
};

const OPERATIONS_ADMIN_ASSISTANT = {
  name: 'Operations Admin Assistant',
  value: 'OPERATIONS_ADMIN_ASSISTANT',
  supervisorRole: OPERATIONS_ADMIN_LEAD.value,
  subDepartment: SI_ADMIN_SUB_DEPARTMENT.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 5,
  color: '#C6803F',
};

const OPERATIONS_GENERAL_MANAGER = {
  name: 'Operations General Manager',
  value: 'OPERATIONS_GENERAL_MANAGER',
  supervisorRole: DIRECTOR_OF_OPERATIONS.value,
  department: OPERATIONS_DEPARTMENT.value,
  subDepartment: SI_OPERATIONS_SUB_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#F6C944',
};

const PROJECT_MANAGER = {
  name: 'Project Manager',
  value: 'PROJECT_MANAGER',
  supervisorRole: OPERATIONS_GENERAL_MANAGER.value,
  department: OPERATIONS_DEPARTMENT.value,
  subDepartment: SI_OPERATIONS_SUB_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#F6C944',
};

const BRAND_STRATEGIST = {
  name: 'Brand Strategist',
  value: 'BRAND_STRATEGIST',
  supervisorRole: PROJECT_MANAGER.value,
  department: OPERATIONS_DEPARTMENT.value,
  subDepartment: SI_OPERATIONS_SUB_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 5,
  color: '#F6C944',
};

const ACCOUNT_SPECIALIST = {
  name: 'Account Specialist',
  value: 'ACCOUNT_SPECIALIST',
  supervisorRole: BRAND_STRATEGIST.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_OPERATIONS_SUB_DEPARTMENT.value,
  seniorityLevel: 6,
  color: '#F6C944',
};

const HEAD_OF_PPC = {
  name: 'Head of PPC',
  value: 'HEAD_OF_PPC',
  supervisorRole: DIRECTOR_OF_OPERATIONS.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_PPC_SUB_DEPARTMENT.value,
  seniorityLevel: 3,
  color: '#4EA660',
};

const PPC_PROJECT_MANAGER = {
  name: 'PPC Project Manager',
  value: 'PPC_PROJECT_MANAGER',
  supervisorRole: HEAD_OF_PPC.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_PPC_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#4EA660',
};

const PPC_TEAM_LEAD = {
  name: 'PPC Team Lead',
  value: 'PPC_TEAM_LEAD',
  supervisorRole: PPC_PROJECT_MANAGER.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_PPC_SUB_DEPARTMENT.value,
  seniorityLevel: 5,
  color: '#4EA660',
};

const PPC_SPECIALIST = {
  name: 'PPC Specialist',
  value: 'PPC_SPECIALIST',
  supervisorRole: PPC_TEAM_LEAD.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_PPC_SUB_DEPARTMENT.value,
  seniorityLevel: 6,
  color: '#4EA660',
};

const HEAD_OF_COPYWRITING = {
  name: 'Head of Copywriting',
  value: 'HEAD_OF_COPYWRITING',
  supervisorRole: DIRECTOR_OF_OPERATIONS.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 3,
  color: '#E05A34',
};

const WRITING_TEAM_ADMIN = {
  name: 'Writing Team Admin',
  value: 'WRITING_TEAM_ADMIN',
  supervisorRole: HEAD_OF_COPYWRITING.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const COPYWRITER = {
  name: 'Copywriter',
  value: 'COPYWRITER',
  supervisorRole: HEAD_OF_COPYWRITING.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const CONTENT_SPECIALIST = {
  name: 'Content Specialits',
  value: 'CONTENT_SPECIALIST',
  supervisorRole: HEAD_OF_COPYWRITING.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const LEAD_KEYWORD_RESEARCHER = {
  name: 'Lead Keyword Researcher',
  value: 'LEAD_KEYWORD_RESEARCHER',
  supervisorRole: HEAD_OF_COPYWRITING.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const KEYWORD_RESEARCHER = {
  name: 'Keyword Researcher',
  value: 'KEYWORD_RESEARCHER',
  supervisorRole: LEAD_KEYWORD_RESEARCHER.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_WRITING_SUB_DEPARTMENT.value,
  seniorityLevel: 5,
  color: '#E05A34',
};

const HEAD_OF_DESIGN = {
  name: 'Head of Design',
  value: 'HEAD_OF_DESIGN',
  supervisorRole: DIRECTOR_OF_OPERATIONS.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_DESIGN_SUB_DEPARTMENT.value,
  seniorityLevel: 3,
  color: '#E05A34',
};

const DESIGN_TEAM_ADMIN = {
  name: 'Design Team Admin',
  value: 'DESIGN_TEAM_ADMIN',
  supervisorRole: HEAD_OF_DESIGN.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_DESIGN_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const ART_DIRECTOR = {
  name: 'Art Director',
  value: 'ART_DIRECTOR',
  supervisorRole: HEAD_OF_DESIGN.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_DESIGN_SUB_DEPARTMENT.value,
  seniorityLevel: 4,
  color: '#E05A34',
};

const SENIOR_GRAPHIC_DESIGNER = {
  name: 'Senior Graphic Designer',
  value: 'SENIOR_GRAPHIC_DESIGNER',
  supervisorRole: ART_DIRECTOR.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_DESIGN_SUB_DEPARTMENT.value,
  seniorityLevel: 5,
  color: '#E05A34',
};

const GRAPHIC_DESIGNER = {
  name: 'Graphic Designer',
  value: 'GRAPHIC_DESIGNER',
  supervisorRole: SENIOR_GRAPHIC_DESIGNER.value,
  department: OPERATIONS_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  subDepartment: SI_DESIGN_SUB_DEPARTMENT.value,
  seniorityLevel: 6,
  color: '#E05A34',
};

const DIRECTOR_OF_SALES = {
  name: 'Director of sales',
  value: 'DIRECTOR_OF_SALES',
  supervisorRole: CEO.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 2,
  color: '#545454',
};

const SALES_REPRESENTATIVE = {
  name: 'Sales Representative',
  value: 'SALES_REPRESENTATIVE',
  supervisorRole: DIRECTOR_OF_SALES.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#545454',
};

const SALES_ADMINISTRATOR_TEAM_LEAD = {
  name: 'Sales Administrator Team Lead',
  value: 'SALES_ADMINISTRATOR_TEAM_LEAD',
  supervisorRole: DIRECTOR_OF_SALES.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#545454',
};

const SALES_ADMINISTRATOR = {
  name: 'Sales Administrator',
  value: 'SALES_ADMINISTRATOR',
  supervisorRole: SALES_ADMINISTRATOR_TEAM_LEAD.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#545454',
};

const BUSINESS_DEVELOPMENT_MANAGER = {
  name: 'Business Developement Manager',
  value: 'BUSINESS_DEVELOPMENT_MANAGER',
  supervisorRole: DIRECTOR_OF_SALES.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 3,
  color: '#8C4CF6',
};

const BUSINESS_DEVELOPMENT_REPRESENTATIVE = {
  name: 'Business Developement Representative',
  value: 'BUSINESS_DEVELOPMENT_REPRESENTATIVE',
  supervisorRole: BUSINESS_DEVELOPMENT_MANAGER.value,
  department: SALES_DEPARTMENT.value,
  level: AGENCY_LEVEL,
  seniorityLevel: 4,
  color: '#8C4CF6',
};

export const levels = {
  AGENCY_LEVEL,
  SYSTEM_LEVEL,
  ACCOUNT_LEVEL,
  APPLICATION_LEVEL,
};

export const departments = {
  OUTGIVE_DEPARTMENT,
  SALES_DEPARTMENT,
  BILLING_DEPARTMENT,
  OPERATIONS_DEPARTMENT,
  LEAD_GENERATION_DEPARTMENT,
};

export const subDepartments = {
  SI_PPC_SUB_DEPARTMENT,
  SI_DESIGN_SUB_DEPARTMENT,
  SI_WRITING_SUB_DEPARTMENT,
  SI_OPERATIONS_SUB_DEPARTMENT,
  SI_ADMIN_SUB_DEPARTMENT,
};

export const roles = {
  APPLICATION_USER,
  SYSTEM_ADMINISTRATOR,
  SYSTEM_SUPER_USER,
  ACCOUNT_ADMINISTRATOR,
  ACCOUNT_OWNER,
  ACCOUNT_USER,
  AGENCY_SUPER_USER,
  AGENCY_UNASSIGNED_EMPLOYEE,
  CEO,
  DEMAND_GENERATION_MANAGER,
  LEAD_GENERATION_TEAM_CAPTAIN,
  LEAD_GENERATION_REPRESENTATIVE,
  SENIOR_LEAD_GENERATION_REPRESENTATIVE,
  LEAD_PITCHING_TEAM_CAPTAIN,
  LEAD_GENERATION_PITCHER,
  QUALITY_ANALYST_MANAGER,
  QA_BS_DATA_ANALYST,
  HEAD_OF_FINANCE,
  FINANCE_ADMINISTRATOR,
  BILLING_SPECIALIST,
  OPERATIONS_ADMIN_MANAGER,
  OPERATIONS_ADMIN_LEAD,
  OPERATIONS_ADMIN_ASSISTANT,
  DIRECTOR_OF_OPERATIONS,
  OPERATIONS_GENERAL_MANAGER,
  PROJECT_MANAGER,
  BRAND_STRATEGIST,
  ACCOUNT_SPECIALIST,
  HEAD_OF_PPC,
  PPC_PROJECT_MANAGER,
  PPC_TEAM_LEAD,
  PPC_SPECIALIST,
  DIRECTOR_OF_SALES,
  SALES_REPRESENTATIVE,
  SALES_ADMINISTRATOR_TEAM_LEAD,
  SALES_ADMINISTRATOR,
  BUSINESS_DEVELOPMENT_MANAGER,
  BUSINESS_DEVELOPMENT_REPRESENTATIVE,
  HEAD_OF_COPYWRITING,
  WRITING_TEAM_ADMIN,
  COPYWRITER,
  CONTENT_SPECIALIST,
  LEAD_KEYWORD_RESEARCHER,
  KEYWORD_RESEARCHER,
  HEAD_OF_DESIGN,
  DESIGN_TEAM_ADMIN,
  ART_DIRECTOR,
  SENIOR_GRAPHIC_DESIGNER,
  GRAPHIC_DESIGNER,
};

export const supervisors = {
  CEO,
  DEMAND_GENERATION_MANAGER,
  LEAD_GENERATION_TEAM_CAPTAIN,
  DEMAND_GENERATION_MANAGER,
  LEAD_PITCHING_TEAM_CAPTAIN,
  DEMAND_GENERATION_MANAGER,
  QUALITY_ANALYST_MANAGER,
  HEAD_OF_FINANCE,
  DIRECTOR_OF_OPERATIONS,
  OPERATIONS_ADMIN_MANAGER,
  OPERATIONS_ADMIN_LEAD,
  OPERATIONS_GENERAL_MANAGER,
  PROJECT_MANAGER,
  BRAND_STRATEGIST,
  HEAD_OF_PPC,
  PPC_PROJECT_MANAGER,
  PPC_TEAM_LEAD,
  HEAD_OF_COPYWRITING,
  LEAD_KEYWORD_RESEARCHER,
  HEAD_OF_DESIGN,
  ART_DIRECTOR,
  SENIOR_GRAPHIC_DESIGNER,
  DIRECTOR_OF_SALES,
  SALES_ADMINISTRATOR_TEAM_LEAD,
  BUSINESS_DEVELOPMENT_MANAGER,
};
