import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import Templates from './pages/Templates/Templates';
import TemplateDetails from './pages/TemplateDetails/TemplateDetails';

const TaskManagement = () => {
  const tabs = [
    {
      href: `/task-management/templates`,
      exact: true,
      component: Templates,
    },
    {
      href: `/task-management/templates/:templateId`,
      exact: true,
      component: TemplateDetails,
    },
  ];

  return (
    <Switch>
      <Route
        exact
        path={`/task-management`}
        render={() => <Redirect to={`/task-management/templates`} />}
      />

      {tabs.map((tab) => (
        <Route
          key={tab.href}
          path={tab.href}
          component={tab.component}
          exact={tab.exact}
        />
      ))}
    </Switch>
  );
};
export default withRouter(TaskManagement);
