import React, { useEffect, useState, useMemo } from 'react';
import Toggle from 'components/Forms/Toggle';
import Label from 'components/Forms/Label';
import Select from 'components/Forms/Select';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import AssignedCellsPartial from './components/AssignedCellsPartial';
import { setAgencyClient } from '../agencyClientSlice';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import classNames from 'utils/classNames';
import { XIcon, CheckIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

const Admin = () => {
  const { userCan } = usePermissions();
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlert();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const { accountId, isOnboarding, name, planId, marketplaces } =
    agencyClient.account;
  const defaultMarketplace = marketplaces.filter(
    (m) => m.isDefault === true
  )[0];
  const [onboarding, setOnboarding] = useState(isOnboarding);
  const [marketplace, setMarketplace] = useState(
    defaultMarketplace ? defaultMarketplace.marketplaceId : ''
  );
  const [checking, setChecking] = useState(false);

  const onIsOnboarding = async (value) => {
    try {
      setOnboarding(value);
      const response = await axios.put(`/accounts/${accountId}`, {
        name,
        planId,
        isOnboarding: value,
      });
      alertSuccess('Account Updated', 'Onboarding status updated');
    } catch (error) {
      alertError('Cannot update account', error.response.data);
    }
  };

  const onChangeMarketplace = async (value) => {
    try {
      setMarketplace(value);
      await axios.put(`/accounts/${accountId}/marketplaces/${value}`, {
        isDefault: true,
      });

      alertSuccess(
        'Account Marketplace Updated',
        `Default account marketplace ${
          value === '-' ? 'unassigned' : 'updated'
        }`
      );
    } catch (error) {
      alertError('Cannot update account marketplace', error.response.data);
    }
  };

  const onUpdateCells = (payload) => {
    if (payload.success) {
      const { cells } = payload.output;
      dispatch(setAgencyClient({ ...agencyClient, cells }));
    }
  };

  const onCheckSubscription = async () => {
    try {
      setChecking(true);
      const response = await axios.post(
        `/agency/clients/${agencyClient.agencyClientId}/checksubscription`
      );

      alertSuccess(
        'Subscription verification completed',
        response.data.message
      );
    } catch (error) {
      alertError(
        'Subscription verification failed',
        error.response.data.message
      );
    } finally {
      setChecking(false);
    }
  };

  const onDelete = async () => {
    try {
      setChecking(true);
      const response = await axios.delete(
        `/agency/clients/${agencyClient.agencyClientId}`
      );

      alertSuccess('Client Destroyed', response.data.message);
    } catch (error) {
      alertError('Cannot delete client', error.response.data.message);
    } finally {
      setChecking(false);
    }
  };

  const isAPIAuthorized = (service) => {
    if (agencyClient.account.credentials.length) {
      return agencyClient.account.credentials.some(
        (credential) => credential.service === service
      );
    }
    return false;
  };

  const spApi = useMemo(() => {
    return isAPIAuthorized('spApi');
  });

  const advApi = useMemo(() => {
    return isAPIAuthorized('advApi');
  });

  return (
    <div className="flex flex-col space-y-8">
      <h4 className="text-2xl font-bold">Admin</h4>
      <div>
        <h5 className="text-lg font-bold">Account Onboarding</h5>
        <ul role="list" className="grid grid-cols-1 gap-6">
          <li className="col-span-1 bg-white rounded-lg  divide-y divide-gray-200">
            <div className="w-full text-sm">
              <div className="flex justify-between">
                <Label>Is Onboarding</Label>
                <span className="flex items-center">
                  <Toggle
                    checked={onboarding}
                    onChange={() => onIsOnboarding(!onboarding)}
                    disabled={
                      !userCan('clients.profile.admin.isOnboarding.update')
                    }
                  />
                </span>
              </div>
              {defaultMarketplace && (
                <div className="flex mt-2  items-center justify-between">
                  <Label>Default Marketplace</Label>
                  <span>
                    <Select
                      id="defmarketplace"
                      value={marketplace}
                      onChange={(e) => {
                        onChangeMarketplace(e.target.value);
                      }}
                    >
                      <option value="-">-</option>
                      {marketplaces.map((m) => {
                        return (
                          <option value={m.marketplaceId} key={m.marketplaceId}>
                            {m.details.countryCode}
                          </option>
                        );
                      })}
                    </Select>
                  </span>
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
      {/* <div>
        <h5 className="text-lg font-bold">Assigned Cells</h5>
        <AssignedCellsPartial client={agencyClient} onReload={onUpdateCells} />
      </div> */}
      <div>
        <h5 className="text-lg font-bold">Check Subscription</h5>
        <div className="flex justify-between items-center">
          <Label>
            If client is still registered but subscription is already live
          </Label>
          <div>
            <Button
              classes="border-0 font-bold tracking-wider "
              bgColor="blue-500"
              hoverColor="blue-600"
              roundedSize="2xl"
              textColor="white"
              px={6}
              py={1.5}
              shadow=""
              type="button"
              showLoading={true}
              loading={checking}
              onClick={onCheckSubscription}
            >
              Verify Status
            </Button>
          </div>
        </div>
      </div>
      <div className="text-right">
        <Button
          classes="border-0 font-bold tracking-wider "
          bgColor="red-500"
          hoverColor="red-600"
          roundedSize="2xl"
          textColor="white"
          px={6}
          py={1.5}
          shadow=""
          type="button"
          showLoading={true}
          loading={checking}
          onClick={onDelete}
        >
          Force Delete
        </Button>
      </div>
      <div>
        <h5 className="text-lg font-bold">Account Status</h5>
        <div className="col-span-2 flex sm:items-end space-x-2 pt-2">
          <div
            className={classNames(
              spApi
                ? 'bg-green-100 text-green-600 '
                : 'bg-red-100 text-red-600',
              'flex rounded-3xl w-40 text-sm items-center py-1.5 px-2 space-x-2 justify-start'
            )}
          >
            {spApi ? (
              <CheckIcon className="h-5 w-5 text-green-600" />
            ) : (
              <XIcon className="h-5 w-5 text-red-600" />
            )}
            <span>Selling Partner API</span>
          </div>
          <div
            className={classNames(
              advApi
                ? 'bg-green-100 text-green-600'
                : 'bg-red-100 text-red-600',
              'flex rounded-3xl w-40 text-sm items-center py-1.5 px-2 space-x-2 justify-start'
            )}
          >
            {advApi ? (
              <CheckIcon className="h-5 w-5 text-green-600" />
            ) : (
              <XIcon className="h-5 w-5 text-red-600" />
            )}
            <span>Advertising API</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
