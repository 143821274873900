import { State } from '@bmulingbayan22/country-state';
import { setCountries } from 'features/country/countrySlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useCountry = () => {
  const { countries } = useSelector((state) => state.countries);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length < 1) getCountries();
  }, [countries]);

  const getCountries = () => {
    dispatch(setCountries());
  };

  const filterProvinces = (isoCode) => {
    return State.getStatesOfCountry(isoCode);
  };

  const isCanadian = (country) => {
    return country && country.name === 'Canada';
  };

  return {
    countries,
    filterProvinces,
    isCanadian,
    getCountries,
  };
};

export default useCountry;
