import usePermissions from 'hooks/usePermissions';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import Overview from './Overview';

const LeadsNoSellerInfo = ({ match }) => {
  const { path } = match;
  const { userCan } = usePermissions();
  const { leadsPaginationParams } = useSelector((state) => state.leads);

  const tabs = [
    {
      name: 'No Seller Info',
      href: `/leads-no-seller`,
      exact: true,
      visible: userCan('leads.list'),
      query: () => {
        return {
          page: leadsPaginationParams.page ?? 1,
          statuses: 'No Seller Info',
          status: leadsPaginationParams.status ?? '',
          pageSize: leadsPaginationParams.pageSize ?? 30,
          sort: leadsPaginationParams.sort ?? 'updatedAt:desc ',
        };
      },
    },
  ];

  return (
    <>
      <Switch>
        <Route
          path={`${path}`}
          render={(props) => (
            <Overview tabs={tabs} page="No Seller Info" {...props} />
          )}
          exact
        />
      </Switch>
    </>
  );
};

export default withRouter(LeadsNoSellerInfo);
