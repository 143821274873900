import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { upperFirst } from 'lodash';
import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const TopProducts = ({ products = [] }) => {
  const KpiDifference = ({ product, dataKey }) => {
    const currentValue = product[dataKey];
    const previousValue = product[`previous${upperFirst(dataKey)}`];
    let Icon = ChevronUpIcon;
    let percentage = 0;
    let infoColor = 'green';

    if (currentValue !== previousValue && previousValue) {
      Icon = currentValue > previousValue ? ChevronUpIcon : ChevronDownIcon;

      const lessRef =
        dataKey === 'acos' ||
        dataKey === 'cpc' ||
        dataKey === 'cost' ||
        dataKey === 'tacos'
          ? currentValue
          : previousValue;

      const greaterRef =
        dataKey === 'acos' ||
        dataKey === 'cpc' ||
        dataKey === 'cost' ||
        dataKey === 'tacos'
          ? previousValue
          : currentValue;

      infoColor = lessRef < greaterRef ? 'green' : 'red';
      percentage = (currentValue - previousValue) / Math.abs(previousValue);
    }

    return (
      <div className="flex justify-center items-center mt-1">
        <Icon className={`text-${infoColor}-800 h-2 w-2`} />
        <p className={`text-8 text-${infoColor}-800 ml-1`}>
          {percentageFormatter(percentage)}
        </p>
      </div>
    );
  };

  return (
    <div className="my-6 mx-auto px-8">
      <div className="flex justify-between items-center my-2">
        <p className="tracking-3/4 font-bold text-grayscale-900 font-inter">
          ASIN Level Performance
        </p>
      </div>

      <div className="ring-1 ring-grayscale-900 ring-opacity-5 w-full">
        <table className="h-full divide-y divide-gray-30 w-full table-fixed">
          <thead className="bg-gray-100">
            <tr className="divide-x divide-gray-200 bg-grayscale-900 text-tiny">
              <th
                scope="col"
                colSpan="3"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                ASINs
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                Spend
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                %
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                Sales
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                %
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                ACOS
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                %
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                Orders
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-center font-bold text-white"
              >
                %
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-8 grayscale-800">
            {products.map((product, index) => {
              const bgClass = index % 2 == 0 ? 'bg-grayscale-400' : 'bg-white';

              return (
                <tr
                  key={product.asin}
                  className={`divide-x divide-gray-200 ${bgClass}`}
                >
                  <td
                    colSpan="3"
                    className="whitespace-nowrap py-3 px-2 text-8 grayscale-800"
                  >
                    {product.asin}
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    {currencyFormatter(product.cost)}
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    <KpiDifference product={product} dataKey="cost" />
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    {currencyFormatter(product.sales)}
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    <KpiDifference product={product} dataKey="sales" />
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    {percentageFormatter(product.acos)}
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    <KpiDifference product={product} dataKey="acos" />
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    {numberFormatter(product.orders)}
                  </td>
                  <td className="whitespace-nowrap py-3 px-2 text-8 grayscale-800 text-center">
                    <KpiDifference product={product} dataKey="orders" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TopProducts;
