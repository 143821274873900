import {
  Bar,
  XAxis,
  YAxis,
  BarChart,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import { isEmpty } from 'lodash';

import PercentageChange from 'components/PercentageChange';
import { percentageFormatter } from 'utils/formatters';
import ChartToolTip from 'components/Charts/ChartTooltip';
import ComputationTooltip from 'components/ComputationTooltip';

const UpsellRate = ({ loading, records, summary }) => {
  return (
    <div className="bg-white p-4 mt-8">
      <div className="py-5">
        <div className="pb-5 pt-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
              Upsell Rate
            </p>

            <ComputationTooltip
              title="Upsell Rate"
              computations={[
                '(Upsold Clients / Total Clients) * 100',
                `(${summary.upsoldClients} / ${summary.totalClients}) * 100`,
                percentageFormatter(summary.upsellRate),
              ]}
            />
          </div>

          {!isEmpty(summary) && (
            <div>
              <p className="font-inter leading-1.2 tracking-3/4 text-xl text-grayscale-900">
                {percentageFormatter(summary.upsellRate)}
              </p>

              <PercentageChange
                className="justify-end"
                currentValue={summary.upsellRate}
                previousValue={summary.previousUpsellRate}
              />
            </div>
          )}
        </div>
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <BarChart data={records}>
              <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
              <XAxis
                dataKey="startDate"
                style={{ fontSize: '11px', color: '#202020' }}
                tickFormatter={(date) => moment(date).format('MMM')}
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(value) => percentageFormatter(value)}
              />

              <Tooltip
                content={
                  <ChartToolTip
                    Title={({ payload }) => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {moment(payload[0].payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />

              <Bar
                yAxisId="0"
                stroke="#3BD7AD"
                fill="#3BD7AD"
                dataKey={'upsellRate'}
                dot={false}
                strokeWidth={3}
                name="Upsell Rate"
                formatter={percentageFormatter}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default UpsellRate;
