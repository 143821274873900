import InputOverlappedLabel from 'components/Forms/InputOverlappedLabel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'components/Button';
import classNames from 'utils/classNames';
import { numberFormatter } from 'utils/formatters';
import ButtonLink from 'components/ButtonLink';
import BsPopover from 'components/BsPopover';
import { CalculatorIcon } from '@heroicons/react/solid';
import { Popover } from '@headlessui/react';

const OrderMetricsCalculator = ({
  commission,
  account,
  managedAsin,
  field,
  setFieldValue,
}) => {
  const [computed, setComputed] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [computing, setComputing] = useState(false);
  const [benchmark, setBenchmark] = useState(0);

  useEffect(() => {
    if (commission) {
      const start = moment(account.subscription?.activatedAt)
        .clone()
        .subtract(commission.monthThreshold, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');

      const end = moment(account.subscription?.activatedAt)
        .clone()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');

      if (managedAsin) {
        setStartDate(managedAsin.startDate ?? start);
        setEndDate(managedAsin.endDate ?? end);
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    }
  }, [commission]);

  const getMetrics = async () => {
    setComputing(true);
    let op = {
      accountId: account.accountId,
      marketplaceId: commission.marketplaceId,
      startDate,
      endDate,
    };
    if (managedAsin) op.asin = managedAsin.asin;

    const output = await axios.post(
      `/agency/commissions/${commission.commissionId}/metrics`,
      op
    );
    setComputed(output.data.output);
    setBenchmark(
      parseFloat(output.data.output.totalSales.amount) /
        commission.monthThreshold
    );
    setComputing(false);
  };

  const apply = () => {
    setFieldValue(field, benchmark.toFixed(2));
  };

  return (
    <BsPopover
      title={
        <span className="px-1 inline-flex items-center text-sm font-sans font-normal text-blue-800 hover:text-blue-400">
          <CalculatorIcon className="w-6 h-6" />
        </span>
      }
    >
      <div className="absolute bg-white shadow-lg border p-6 rounded-2xl w-96 divide-y-2 z-10 right-0 bottom-5">
        <div>
          <div className="text-sm mb-6 flex justify-between text-yellow-500">
            {managedAsin && (
              <span className="text-blue-500 font-bold">
                {managedAsin.asin ?? ' '}
              </span>
            )}

            <div className="">
              <b>Baseline</b> = Total{' '}
              {managedAsin && managedAsin.asin ? 'ASIN' : ''} Sales &divide;
              Month
            </div>
          </div>
          <div className="flex space-x-4">
            <div
              className={classNames(
                !computing && computed ? 'w-1/3' : 'w-full',
                'space-y-4'
              )}
            >
              <InputOverlappedLabel
                label="Start Date"
                name="startdate"
                placeholder="YYYY-MM-DD"
                value={startDate}
                required={true}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <InputOverlappedLabel
                label="End Date"
                name="enddate"
                placeholder="YYYY-MM-DD"
                value={endDate}
                required={true}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {!computing && computed && (
              <div className="text-xs flex flex-col w-2/3">
                <dl className="text-gray-500 flex justify-between">
                  <dt>Order Count:</dt>
                  <dd className="text-gray-800">{computed.orderCount}</dd>
                </dl>
                <dl className="text-gray-500 flex justify-between">
                  <dt>Total Sales ({computed.totalSales.currencyCode}):</dt>
                  <dd className="text-gray-800">
                    {numberFormatter(computed.totalSales.amount)}
                  </dd>
                </dl>
                <dl className="text-gray-500 flex justify-between mb-1">
                  <dt>Month Threshold:</dt>
                  <dd className="text-gray-800">{commission.monthThreshold}</dd>
                </dl>
                <hr />
                <dl className="text-gray-500 flex justify-between mt-1 flex-grow">
                  <dt>Benchmark ({computed.totalSales.currencyCode}):</dt>
                  <dd className="text-green-500 font-bold">
                    {numberFormatter(benchmark)}
                  </dd>
                </dl>
              </div>
            )}
          </div>
          <div className="space-x-3 mt-6">
            <ButtonLink classes="tracking-wider font-bold" color="red">
              <Popover.Button className="font-medium">Close</Popover.Button>
            </ButtonLink>
            <Button
              classes="border-0 font-bold tracking-wider "
              bgColor="red-700"
              hoverColor="red-400"
              loading={computing}
              showLoading={true}
              onClick={getMetrics}
              textSize="xs"
              roundedSize="2xl"
              textColor="white"
              px={4}
              py={1}
              shadow=""
            >
              Compute
            </Button>
            {!computing && computed && (
              <ButtonLink color="white" onClick={apply}>
                <Popover.Button className="bg-green-600 hover:bg-green-400 text-xs py-1 px-4 rounded-2xl font-bold tracking-wider">
                  Apply
                </Popover.Button>
              </ButtonLink>
            )}
          </div>
        </div>
      </div>
    </BsPopover>
  );
};
export default OrderMetricsCalculator;
