import classNames from 'utils/classNames';
import { percentageFormatter } from 'utils/formatters';

const DataDifference = ({ current, previous, formatter }) => {
  if (!!!current || !!!previous || previous === current) {
    return <p className="text-right">{formatter(current)}</p>;
  }

  const diff = (current - previous) / previous;

  return (
    <div className="">
      <p className="text-right">{formatter(current)}</p>
      <div className="flex items-center justify-end mt-1">
        <p>
          {diff > 0 ? (
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.53564 3.97292L4.00023 0.4375L0.464811 3.97292L1.05439 4.5625L4.00023 1.61583L6.94648 4.5625L7.53564 3.97292Z"
                fill="#00966D"
              />
            </svg>
          ) : (
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.464355 1.02708L3.99977 4.5625L7.53519 1.02708L6.9456 0.4375L3.99977 3.38417L1.05352 0.4375L0.464355 1.02708Z"
                fill="#C30000"
              />
            </svg>
          )}
        </p>
        <p className={classNames('text-11 ml-1')}>
          {percentageFormatter(diff)}
        </p>
      </div>
    </div>
  );
};

export default DataDifference;
