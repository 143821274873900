import axios from 'axios';
import { useSelector } from 'react-redux';
import { ChevronUpIcon, PencilIcon } from '@heroicons/react/outline';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import ButtonLink from 'components/ButtonLink';
import { isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import useComponentVisible from 'hooks/useComponentVisible';
import AddSubtaskIcon from 'components/icons/AddSubtaskIcon';
import NewTask from './NewTask';
import TaskStatusDropdown from './TaskStatusDropdown';
import TaskDueDateDropdown from './TaskDueDateDropdown';
import PriorityDropdown from './PriorityDropdown';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import TaskManagerAvatar from './TaskManagerAvatar';
import { useDrag } from 'react-dnd';
import TaskTimeTracker from './TaskTimeTracker';
import TaskType from './TaskType';
import usePermissions from 'hooks/usePermissions';

const TaskRow = ({
  data,
  isSubtask = false,
  onChange,
  showClient = false,
  showManagers = false,
  assigneeOptions = [],
  allowedStatuses = [],
  defaultStatus = 'To Do',
  notifyAssignee = false,
  permissions = {},
  canDrag = false,
  titleColumnClass = 'col-span-6',
  columns = [
    { key: 'assignees', className: 'col-span-2' },
    { key: 'dueDate', className: 'col-span-1' },
    { key: 'priority', className: 'col-span-1' },
    { key: 'timeTracker', className: 'col-span-2' },
  ],
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { user } = useSelector((state) => state.auth);
  const [expandSubTasks, setExpandSubTasks] = useState(true);
  const [addSubTask, setAddSubTask] = useState(false);
  const {
    canUpdateName = true,
    canAddDueDate = true,
    canAddAssignee = true,
    canAddPriority = true,
    canAddSubtasks = true,
    canUpdateDueDate = true,
    canUpdatePriority = true,
    canRemoveAssignee = true,
  } = permissions;
  const { userCan } = usePermissions();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [row, setRow] = useState({
    taskItemId: 0,
    status: 'pending',
    name: '',
    priority: 'none',
    dueDate: '',
    subtasks: [],
    assignees: [],
    list: { statuses: [] },
  });

  useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  const onSubtaskAdded = (subtask) => {
    setAddSubTask(false);
    let subtasks = [...row.subtasks, subtask];
    setRow({ ...row, subtasks });
  };

  const onUpdateAssignee = async (user, action) => {
    axios({
      method: action === 'add' ? 'POST' : 'DELETE',
      url: `/agency/tasks/items/${row.taskItemId}/assignees/${user.userId}`,
      data: { notifyUser: notifyAssignee },
    }).then((response) => {
      setRow({ ...response.data.data, list: row.list });
      isFunction(onChange) && onChange('assignee');
    });
  };

  const onEditTitle = (e) => {
    if (e.key === 'Enter') {
      axios
        .put(`/agency/tasks/items/${row.taskItemId}`, {
          name: e.target.value,
        })
        .then((response) => {
          setRow({ ...response.data.data, list: row.list });
          setIsComponentVisible(false);
        });
    } else if (e.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const onChangeStatus = ({ name }) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        status: name,
      })
      .then((response) => {
        setRow({ ...response.data.data, list: row.list });
        if (!response.data.data.parentTaskItemId) {
          isFunction(onChange) && onChange('status');
        }
      });
  };

  const onChangePriority = (priority) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        priority: priority ? priority.status : null,
      })
      .then((response) => {
        setRow({ ...response.data.data, list: row.list });
        if (!response.data.data.parentTaskItemId) {
          isFunction(onChange) && onChange('priority');
        }
      });
  };

  const onChangeType = (type) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        type,
      })
      .then((response) => {
        setRow({ ...response.data.data, list: row.list });
        if (!response.data.data.parentTaskItemId) {
          isFunction(onChange) && onChange('type');
        }
      });
  };

  const onChangeDueDate = (dueDate) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        dueDate,
      })
      .then((response) => {
        setRow({ ...response.data.data, list: row.list });
        if (!response.data.data.parentTaskItemId) {
          isFunction(onChange) && onChange('due');
        }
      });
  };

  const onLoadLink = () => {
    history.push(`${url}/${row.taskItemId}`);
  };

  const [, drag] = useDrag({
    type: 'card',
    canDrag,
    item: { data: row },
  });

  return (
    <>
      <div
        ref={isSubtask ? null : drag}
        className={classNames(
          isSubtask ? 'list-group-col-sub' : 'list-group-col',
          'text-gray-500 group'
        )}
        onClick={onLoadLink}
      >
        {showClient &&
          (row.client ||
            row.list?.folder?.space?.salesClient?.lead?.companyName) && (
            <div className="px-14 ml-0 col-span-12 text-11 text-grayscale-700 flex leading-1.5">
              {row.client ||
                row.list?.folder?.space?.salesClient?.lead?.companyName}
            </div>
          )}

        <div
          ref={ref}
          className={classNames(
            titleColumnClass,
            'flex items-center justify-start space-x-1 pl-4 sticky left-0 xl:static'
          )}
        >
          {!isSubtask ? (
            <ButtonLink
              color="gray"
              classes={row.subtasks.length ? 'visible' : 'invisible'}
              onClick={(e) => {
                e.stopPropagation();
                setExpandSubTasks(!expandSubTasks);
              }}
            >
              <ChevronUpIcon
                className={classNames(
                  !expandSubTasks && 'transform rotate-180',
                  'w-4 h-4 inline'
                )}
              />
            </ButtonLink>
          ) : (
            <span className="px-6">&nbsp;</span>
          )}

          <TaskStatusDropdown
            id={row.taskItemId}
            value={row.status}
            setValue={onChangeStatus}
            disabled={
              permissions.canUpdateStatus ? !permissions.canUpdateStatus : false
            }
            options={row.list.statuses.filter((i) =>
              allowedStatuses.length ? allowedStatuses.includes(i.name) : true
            )}
          />
          {isComponentVisible && (
            <input
              type="text"
              defaultValue={row.name}
              className={classNames(
                isSubtask && 'ml-2',
                'border-0 w-full focus:ring-white focus:border-white p-0 text-red-500 text-sm'
              )}
              onKeyDown={onEditTitle}
              autoFocus
            />
          )}
          {!isComponentVisible && (
            <>
              <p
                className={classNames(
                  isSubtask && 'ml-2',
                  'truncate max-w-fit'
                )}
                title={row.name}
              >
                <Link to={`${url}/${row.taskItemId}`}>{row.name}</Link>
                {/* <ButtonLink
                  color="gray"
                  className="group-hover:text-red-500 text-sm"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  {row.name}
                </ButtonLink> */}
              </p>
              <div className="px-4 hidden group-hover:flex space-x-1">
                {!isSubtask && canAddSubtasks && (
                  <button
                    className="add-subtask-icon hover:opacity-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddSubTask(true);
                    }}
                    title="Add Subtask"
                    disabled={!userCan('sales.workspace.task_items.create')}
                  >
                    <AddSubtaskIcon width="30" height="30" />
                  </button>
                )}

                {canUpdateName && (
                  <button
                    className="text-xs leading-none"
                    title="Edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsComponentVisible(true);
                    }}
                    disabled={!userCan('sales.workspace.task_items.edit')}
                  >
                    <span className="border border-dashed border-gray-400 text-gray-400 hover:text-red-400 hover:border-red-400 rounded-2xl p-1.5 inline-block">
                      <PencilIcon className="w-4 h-4 inline" />
                    </span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        {!!columns.find((col) => col.key === 'assignees') && (
          <div
            className={classNames(
              'flex justify-center',
              columns.find((col) => col.key === 'assignees').className
            )}
          >
            <UsersPopoverMultiple
              users={assigneeOptions}
              onChange={onUpdateAssignee}
              position="left-1/2 transform -translate-x-1/2"
              selectedIds={row.assignees.map((user) => user.userId)}
              allowAdd={canAddAssignee}
              allowRemove={canRemoveAssignee}
            />
          </div>
        )}

        {!!columns.find((col) => col.key === 'dueDate') && (
          <div
            className={classNames(
              'flex text-sm justify-center items-center',
              columns.find((col) => col.key === 'dueDate').className
            )}
          >
            <TaskDueDateDropdown
              value={row.dueDate}
              disabled={!permissions.canUpdateDueDate ?? false}
              configurable={canAddDueDate}
              editable={canUpdateDueDate}
              setValue={onChangeDueDate}
            />
          </div>
        )}

        {!!columns.find((col) => col.key === 'priority') && (
          <div
            className={classNames(
              'items-center flex justify-center',
              columns.find((col) => col.key === 'priority').className
            )}
          >
            <PriorityDropdown
              value={row.priority}
              configurable={canAddPriority}
              editable={canUpdatePriority}
              setValue={onChangePriority}
            />
          </div>
        )}

        {!!columns.find((col) => col.key === 'type') && (
          <TaskType
            value={row.type}
            setValue={onChangeType}
            className={classNames(
              'items-center flex justify-center text-13 text-grayscale-800',
              columns.find((col) => col.key === 'type').className
            )}
          />
        )}

        {showManagers && (
          <>
            <div className="col-span-1 text-center flex justify-center">
              {row.list?.folder?.space?.client?.seniorAccountManager && (
                <TaskManagerAvatar
                  user={row.list.folder.space.client.seniorAccountManager.user}
                />
              )}
            </div>
            <div className="col-span-1 text-center flex justify-center">
              {row.list?.folder?.space?.client?.accountManager && (
                <TaskManagerAvatar
                  user={row.list.folder.space.client.accountManager.user}
                />
              )}
            </div>
          </>
        )}

        {row.taskItemId &&
          !!columns.find((col) => col.key === 'timeTracker') && (
            <div
              className={classNames(
                'items-center flex justify-center',
                columns.find((col) => col.key === 'timeTracker').className
              )}
            >
              <TaskTimeTracker
                onChange={onChange}
                taskItemId={row.taskItemId}
                hasRunningTimeLog={row.hasRunningTimeLog}
                totalTrackedTime={row.totalTrackedTime}
                runningTimeLog={row.runningTimeLog}
                enable={
                  row.runningTimeLog
                    ? row.runningTimeLog.userId === user.userId
                    : true
                }
              />
            </div>
          )}
      </div>

      {!isSubtask && row.subtasks.length > 0 && (
        <div
          className={classNames(
            expandSubTasks ? 'block' : 'hidden',
            'list-group-col-subs'
          )}
        >
          {row.subtasks.map((subtask) => (
            <TaskRow
              key={subtask.taskItemId}
              data={{ ...subtask, list: row.list }}
              isSubtask={true}
              showClient={showClient}
              showManagers={showManagers}
              assigneeOptions={assigneeOptions}
              permissions={permissions}
              onChange={onChange}
              defaultStatus={defaultStatus}
            />
          ))}
        </div>
      )}
      {addSubTask && (
        <NewTask
          isSubtask={true}
          cancel={() => setAddSubTask(false)}
          taskListId={row.taskListId}
          parentTaskItemId={row.taskItemId}
          created={onSubtaskAdded}
          status={defaultStatus}
          assigneeOptions={assigneeOptions}
          statusOptions={row.list.statuses.filter((i) =>
            allowedStatuses.length ? allowedStatuses.includes(i.name) : true
          )}
        />
      )}

      {/* {openModal && (
        <TaskDetailsModal
          key="tdm"
          open={openModal}
          setOpen={setOpenModal}
          task={row}
          isSubtask={isSubtask}
          setTask={setRow}
          onChange={onChange}
        />
      )} */}
    </>
  );
};

export default TaskRow;
