import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { DEFAULT_MARKETPLACE } from 'utils/constants';
import { setAlert } from '../alerts/alertsSlice';

export const fetchMarketplaces = createAsyncThunk(
  'marketplaces/getMarketplaces',
  async (params) => {
    const response = await axios.get(`/agency/marketplaces`, { params });
    return { data: response.data.data };
  }
);

export const marketplacesSlice = createSlice({
  name: 'marketplaces',
  initialState: {
    defaultMarketplaceId: DEFAULT_MARKETPLACE, //US
    marketplaces: [],
  },
  reducers: {
    setDefaultMarketplaceId: (state, action) => {
      state.defaultMarketplaceId = action.payload;
    },
  },
  extraReducers: {
    [fetchMarketplaces.fulfilled]: (state, { payload }) => {
      state.marketplaces = payload.data;
    },
  },
});

export const { setDefaultMarketplaceId } = marketplacesSlice.actions;

export default marketplacesSlice.reducer;
