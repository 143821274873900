import axios from 'axios';
import usePermissions from 'hooks/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useAlert from 'hooks/useAlert';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { nameFormatter } from 'utils/formatters';
import ReactJson from 'react-json-view';
import ToolTip from 'components/ToolTip';
import { InformationCircleIcon } from '@heroicons/react/solid';

const Transactions = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { agencyClient } = useSelector((state) => state.agencyClient);
  const [transactions, setTransactions] = useState(null);

  const [loading, setLoading] = useState(false);
  const { alertSuccess, alertError } = useAlert();

  const getTransactions = async () => {
    setLoading(true);
    await axios
      .get(`/agency/clients/${agencyClient.agencyClientId}/transactions`)
      .then((response) => setTransactions(response.data.data.rows))
      .catch((error) =>
        alertError('Failed to load transactions', error.response.data.message)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!transactions) {
      getTransactions();
    }
  }, [transactions]);

  const columns = [
    { header: 'ID', accessorKey: 'transactionId' },
    { header: 'Amount ($)', accessorKey: 'amount' },
    { header: 'Currency', accessorKey: 'currencyCode' },
    { header: 'Tax ($)', accessorKey: 'taxAmount' },
    {
      header: 'Card',
      accessorFn: (row) => row,
      cell: (cell) => {
        //accessorKey: 'commission.baseline'
        const row = cell.getValue();
        return (
          <>
            *{row.paymentReceipt.cardLast4}{' '}
            <span className="ml-2 uppercase font-bold">
              {row.paymentReceipt.cardType}
            </span>
          </>
        );
      },
    },
    { header: 'Status', accessorKey: 'status' },
    {
      header: 'By',
      accessorFn: (row) => row,
      cell: (cell) => {
        //accessorKey: 'commission.baseline'
        const row = cell.getValue();
        return <>{nameFormatter(row.transactor)}</>;
      },
    },
    { header: 'Created', accessorKey: 'createdAt' },
    {
      header: ' ',
      accessorFn: (row) => row,
      cell: (cell) => {
        const row = cell.getValue();
        return row.paymentReceipt ? (
          <ToolTip
            title={
              <InformationCircleIcon className="w-4 h-4 inline text-secondary-light" />
            }
            arrowFill="#ccc"
            bg="bg-white"
          >
            <div className="text-xs">
              <ReactJson
                src={row.paymentReceipt}
                name={false}
                displayDataTypes={false}
                displayObjectSize={false}
                theme="grayscale:inverted"
                enableClipboard={false}
                iconStyle="square"
              />
            </div>
          </ToolTip>
        ) : (
          <>&mdash;</>
        );
      },
    },
  ];

  return (
    <div className="col-span-5 flex flex-col bg-white px-8 py-8 pb-16">
      <div className="flex items-center space-x-4">
        <h4 className="text-2xl font-bold">Transactions</h4>
      </div>
      <div className="mt-6 flex flex-col max-w-full">
        {loading && <Loading />}
        {transactions && (
          <DataTable data={transactions} columns={columns} width="max-w-full" />
        )}
      </div>
    </div>
  );
};

export default Transactions;
