import axios from 'axios';
import { useEffect, useState } from 'react';

import {
  Bar,
  XAxis,
  Tooltip,
  Legend,
  BarChart,
  ResponsiveContainer,
} from 'recharts';

import { numberFormatter } from 'utils/formatters';

const InventoryStatus = ({ accountId, marketplace }) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        '/account/products/inventory/ledger/records',
        {
          params: {
            accountId,
            marketplace,
            startDate: '2022-01-01',
            endDate: '2022-11-30',
          },
        }
      );

      if (isSubscribed) {
        setRecords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white flex flex-col shadow rounded-lg p-5 font-sourceSansPro">
          <div className="pb-2">
            <p className="text-grayscale-800">{label}</p>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {payload.map((p, idx) => {
              return (
                <div className="flex items-center" key={`tooltip-item-${idx}`}>
                  <div>
                    <p
                      style={{
                        backgroundColor: `${p.color}`,
                        width: '15px',
                        height: '15px',
                      }}
                    ></p>
                  </div>
                  <div>
                    <p className="text-grayscale-800 ml-2">
                      {p.dataKey === 'receipts'
                        ? numberFormatter(p.payload.receipts)
                        : p.dataKey === 'endingWarehouseBalance'
                        ? numberFormatter(p.payload.endingWarehouseBalance)
                        : numberFormatter(p.payload.shipped)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="rounded-md bg-white">
      <p className="font-inter font-bold tracking-3/4 leading-1.2 text-grayscale-900 border-b border-grayscale-500 px-5 py-6">
        Inventory Status
      </p>

      <div className="h-72 w-full mt-6 bg-grayscale-300 pb-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={records}>
            <XAxis
              tick={{ fontSize: 11, fill: 'black' }}
              tickLine={false}
              axisLine={false}
              dataKey="month"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="bottom"
              iconType="square"
              iconSize={10}
              wrapperStyle={{
                fontSize: '11px',
                cursor: 'pointer',
              }}
              margin={{ top: 15, left: 0, right: 0, bottom: 0 }}
            />

            <Bar dataKey="receipts" name="Received" fill="#00966D" />
            <Bar
              dataKey="endingWarehouseBalance"
              name="On Hand"
              fill="#1879D8"
            />
            <Bar dataKey="shipped" fill="#F9DC7D" name="Shipped" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InventoryStatus;
