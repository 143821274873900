import axios from 'axios';
import { useEffect, useState } from 'react';

import Checkbox from 'components/Forms/Checkbox';
import ProductPreview from 'components/ProductPreview';

const CustomTargetListing = ({
  accountId,
  marketplace,
  value,
  onChangeValue,
  disabled = false,
}) => {
  const [search, setSearch] = useState('');
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/account/products/listings`, {
        params: {
          search,
          accountId,
          marketplace,
          pageSize: 100000,
          attributes: ['listingId', 'asin', 'sku', 'title', 'thumbnail'],
        },
      });

      if (isSubscribed) {
        setListings(response.data.data.rows);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, search]);

  return (
    <div id="rules-custom-target-listings">
      <input
        placeholder="Search asin, sku or title"
        className="px-4 py-2 focus:outline-none focus:ring-0 block w-full text-mini font-inter border border-grayscale-500 rounded-md text-grayscale-800"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="h-48 px-2 mt-2 overflow-y-scroll">
        {loading ? (
          <p className="text-center my-2 tracking-2 font-bold animate-pulse">
            Loading Records
          </p>
        ) : listings.length ? (
          listings.map((listing) => {
            return (
              <div
                className="flex items-center py-1.5"
                key={`rules-custom-target-listings-${listing.listingId}`}
              >
                <Checkbox
                  classes="mr-2"
                  disabled={disabled}
                  id={listing.listingId}
                  checked={value.includes(listing.listingId)}
                  onChange={() => {
                    onChangeValue(
                      value.includes(listing.listingId)
                        ? value.filter((v) => v !== listing.listingId)
                        : [...value, listing.listingId]
                    );
                  }}
                />

                <ProductPreview
                  sku={listing.sku}
                  asin={listing.asin}
                  productName={listing.title}
                  imageUrl={listing.thumbnail}
                  displayTooltip={false}
                  truncate={false}
                  nameClass="text-grayscale-700 tracking-3/4 text-xs mb-2"
                  identifierClassName="flex items-center"
                />
              </div>
            );
          })
        ) : (
          <p className="text-center my-2 tracking-2 font-bold">
            No records found.
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomTargetListing;
