import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import { isEmpty } from 'lodash';

import PercentageChange from 'components/PercentageChange';
import { percentageFormatter } from 'utils/formatters';
import ChartToolTip from 'components/Charts/ChartTooltip';
import ComputationTooltip from 'components/ComputationTooltip';

const ChurnRate = ({
  loading,
  year,
  summary = {},
  records = [],
  setOpenClientList,
  setClientListFilterGroups,
}) => {
  return (
    <div className="bg-white p-4">
      <div className="pb-5 pt-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
            Churn Rate
          </p>

          <ComputationTooltip
            title="Churn Rate"
            computations={[
              '((Total Clients at the Beginning - Total Clients at the End)/ Total Clients at the Beginning) * 100',
              `((${summary.beginningClients} - ${summary.totalClients}) / ${summary.beginningClients}) * 100`,
              percentageFormatter(summary.churnRate),
            ]}
          />
        </div>

        {!isEmpty(summary) && (
          <div>
            <p className="font-inter leading-1.2 tracking-3/4 text-xl text-grayscale-900">
              {percentageFormatter(summary.churnRate)}
            </p>

            <PercentageChange
              className="justify-end"
              currentValue={summary.churnRate}
              previousValue={summary.previousChurnRate}
            />
          </div>
        )}
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
              <XAxis
                dataKey="dateRange"
                style={{
                  fontSize: '11px',
                  color: '#202020',
                  cursor: 'pointer',
                }}
                tickFormatter={(dateRange) =>
                  moment(dateRange.split('--')[0]).format('MMM')
                }
                onClick={(e) => {
                  const [startDate, endDate] = e.value.split('--');

                  setClientListFilterGroups(
                    JSON.stringify([
                      {
                        filters: [
                          {
                            attribute: 'contractSigned',
                            operator: 'LTE',
                            value: endDate,
                          },
                          {
                            attribute: 'subscriptionPaymentOption',
                            operator: 'EQ',
                            value: 'Recurring',
                          },
                          {
                            attribute: 'status',
                            operator: 'EQ',
                            value: 'ACTIVE',
                          },
                        ],
                      },
                      {
                        filters: [
                          {
                            attribute: 'contractSigned',
                            operator: 'LTE',
                            value: endDate,
                          },
                          {
                            attribute: 'subscriptionPaymentOption',
                            operator: 'EQ',
                            value: 'Recurring',
                          },
                          {
                            attribute: 'terminatedAt',
                            operator: 'HAS_PROPERTY',
                          },
                        ],
                      },
                    ])
                  );

                  setOpenClientList(true);
                }}
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(data) => percentageFormatter(data)}
              />

              <Tooltip
                content={
                  <ChartToolTip
                    Title={() => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {year}
                      </p>
                    )}
                    CustomItemTitle={({ item }) => (
                      <p className="text-13 leading-1.5 text-grayscale-900">
                        {moment(item.payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />

              <Line
                chartType="line"
                yAxisId="0"
                stroke="#FF6868"
                type="linear"
                dataKey={'churnRate'}
                dot={false}
                strokeWidth={3}
                formatter={(value) => percentageFormatter(value)}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ChurnRate;
