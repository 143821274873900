const KeywordPreview = ({
  adGroupName,
  campaignName,
  keywordText,
  matchType,
  bid,
}) => {
  return (
    <div className="flex flex-col">
      <span>{keywordText}</span>

      <span className="text-xs text-grayscale-600">
        Campaign: {campaignName}
      </span>
      <span className="text-xs text-grayscale-600">
        Ad Group: {adGroupName}
      </span>
      <span className="text-xs text-grayscale-600">
        {matchType && `Match type: ${matchType}`}
      </span>
      {bid && (
        <span className="text-xs text-grayscale-600">{`Bid: ${bid}`}</span>
      )}
    </div>
  );
};

export default KeywordPreview;
