import { useSelector } from 'react-redux';

const useEnv = () => {
  const appEnvIn = (env) => {
    return process.env.REACT_APP_NODE_ENV === env;
  };

  const appInProduction = () => {
    return appEnvIn('production');
  };

  return {
    appInProduction,
  };
};

export default useEnv;
