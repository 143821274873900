import Modal from 'components/Modal';
import FormikErrorNotification from 'components/FormikErrorNotification';
import { Formik, Form } from 'formik';
import { pick } from 'lodash';
import { commissionValidationSchema } from './ProposalReducer';
import { useEffect, useState } from 'react';
import { object } from 'yup';
import usePermissions from 'hooks/usePermissions';
import Button from 'components/Button';
import ModalHeader from 'components/ModalHeader';
import CommissionForm from './components/CommissionForm';

const CommissionModal = ({ open, setOpen, values, onSubmit }) => {
  const { userCan } = usePermissions();
  const [initialValues, setInitialValues] = useState(values);

  useEffect(() => {
    if (values) {
      setInitialValues(
        pick(values, [
          'commissionType',
          'rate',
          'monthThreshhold',
          'baseline',
          'managedAsins',
          'rules',
          'autoAdd',
          'noCommission',
          'noCommissionReason',
        ])
      );
    }
  }, [values]);

  const validationSchema = object().shape(commissionValidationSchema);

  const onClose = () => setOpen(false);

  const onSubmitForm = (values) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Modal setOpen={setOpen} open={open} as="div" zIndex="z-20" align="middle">
      <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Add Commission"
          setOpen={setOpen}
          border="0"
          fontSize="text-2xl"
          fontStyle="font-bold"
          px="px-10"
          classes="pt-10"
        />

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <FormikErrorNotification />
              <div
                className="px-10 overflow-y-auto"
                style={{ maxHeight: '500px' }}
              >
                <CommissionForm />
              </div>
              <div className="flex items-center space-x-4 p-10">
                <button
                  type="button"
                  className="border-white font-bold text-secondary-light tracking-widest text-sm mr-2"
                  onClick={onClose}
                >
                  Cancel
                </button>{' '}
                {userCan('sales.profiles.manage') && (
                  <div className="">
                    <Button
                      type="submit"
                      classes="tracking-widest px-12 py-1.5 rounded-2xl text-sm font-medium text-white bg-blue-900 hover:bg-blue-400 focus:ring-red-500 border border-transparent border-0 font-bold tracking-wider  inline-flex items-center focus:outline-none"
                      px={12}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default CommissionModal;
