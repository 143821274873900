import React, { useContext, useEffect } from 'react';
import { Context } from 'layouts/NavProvider';
import { Redirect, useHistory } from 'react-router-dom';

const Dashboard = () => {
  const { items } = useContext(Context);
  const history = useHistory();

  if (items) {
    const url = items.length
      ? 'children' in items
        ? items[0].children[0].href
        : items[0].href
      : '/user-settings';

    history.push(url);
    return <Redirect to={url} />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      Redirecting to page...
    </div>
  );
};

export default Dashboard;
