import { useSelector } from 'react-redux';
import { BriefcaseIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

const useLeadsMenu = () => {
  const { userCan } = usePermissions();

  const { leadsPaginationParams } = useSelector((state) => state.leads);

  return {
    name: 'Marketing',
    href: '/leads-marketing-module',
    icon: '#leads',
    iconType: 'svg',
    visible: () => userCan('leads.list'),
    query: () => {
      return {
        page: leadsPaginationParams.page ?? 1,
        status: leadsPaginationParams.status ?? 'Accepted',
        pageSize: leadsPaginationParams.pageSize ?? 30,
        sort: leadsPaginationParams.sort ?? 'createdAt:asc',
      };
    },
    children: [
      {
        name: 'My Workplace',
        href: '/leads-marketing-module',
        icon: BriefcaseIcon,
        visible: () => userCan('leads.list'),
      },
    ],
  };
};

export default useLeadsMenu;
