import { useMemo } from 'react';
import TeamAssignees from './TeamAssignees';
import useClientAssignees from 'features/clients/Client/useClientAssignees';

const ClientAssignees = ({ client, assignees = [] }) => {
  const { assigneesConstant } = useClientAssignees();

  const isPPCClient = useMemo(
    () =>
      ['PPC Management', 'PPC Audit and Proposal'].includes(client?.service),
    [client]
  );

  return (
    <div className="space-y-12">
      <TeamAssignees
        assignees={assignees}
        title="Operations Team"
        isPrimary={!isPPCClient}
        clientId={client?.agencyClientId}
        listClassName="mt-3 grid grid-cols-6"
        itemClassName="flex flex-col"
        items={[
          assigneesConstant.BRAND_STRATEGIST_ASSIGNEE,
          assigneesConstant.ACCOUNT_SPECIALIST_ASSIGNEE,
        ]}
      />

      <div className="grid grid-cols-2">
        <TeamAssignees
          title="PPC Team"
          isPrimary={isPPCClient}
          assignees={assignees}
          clientId={client?.agencyClientId}
          listClassName="mt-3 grid grid-cols-3"
          itemClassName="flex flex-col"
          items={[
            assigneesConstant.PPC_TEAM_LEAD_ASSIGNEE,
            assigneesConstant.PPC_SPECIALIST_ASSIGNEE,
          ]}
        />

        <TeamAssignees
          title="Sales Team"
          assignees={assignees}
          interConnected={false}
          clientId={client?.agencyClientId}
          listClassName="mt-3 grid grid-cols-3"
          itemClassName="flex flex-col"
          items={[
            assigneesConstant.SALES_REPRESENTATIVE_ASSIGNEE,
            assigneesConstant.SALES_ADMINISTRATOR_ASSIGNEE,
          ]}
        />
      </div>

      <div className="grid grid-cols-2">
        <TeamAssignees
          title="Design Team"
          assignees={assignees}
          clientId={client?.agencyClientId}
          listClassName="mt-3 grid grid-cols-3"
          itemClassName="flex flex-col"
          items={[assigneesConstant.DESIGNER_ASSIGNEE]}
        />

        <TeamAssignees
          title="Writing Team"
          assignees={assignees}
          clientId={client?.agencyClientId}
          listClassName="mt-3 grid grid-cols-3"
          itemClassName="flex flex-col"
          items={[assigneesConstant.COPYWRITER_ASSIGNEE]}
        />
      </div>
    </div>
  );
};

export default ClientAssignees;
