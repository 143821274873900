import axios from 'axios';
import { Button, Modal } from 'components';
import { upperFirst } from 'lodash';
import { useMemo, useState } from 'react';

const SendMailButton = ({ clientId, isSent, isDisabled, type }) => {
  const [sent, setSent] = useState(isSent);
  const [preview, setPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isFetchingContent, setIsFetchingContent] = useState(false);

  const [emailContent, setEmailContent] = useState({
    message: '',
    source: '',
    cc: [],
    email: '',
    subject: '',
  });

  const text = useMemo(() => {
    let display = upperFirst(type) + ' Email';

    if (sent) {
      return `${display} Sent`;
    } else if (isLoading) {
      return `${display} Sending`;
    } else {
      return `Send ${display}`;
    }
  }, [isLoading, sent]);

  const onPreview = async () => {
    setIsFetchingContent(true);
    const response = await axios.get(
      `/v2/agency/clients/${clientId}/mail/${type}`
    );

    setEmailContent(response.data.data);
    setOpenPreview(true);
    setIsFetchingContent(false);
  };

  const send = async () => {
    setIsLoading(true);
    await axios.post(`/v2/agency/clients/${clientId}/mail/${type}`);
    setSent(true);
    setOpenPreview(false);
    setIsLoading(false);
  };

  return (
    <>
      <Modal open={openPreview} setOpen={setOpenPreview}>
        <div className="inline-block max-w-5xl my-8 p-8 text-left transition-all transform bg-white shadow-xl rounded-xl space-y-8">
          <div className="border-b pb-5">
            <h3 className="tracking-3/4 font-bold text-xl">
              {emailContent.subject}
            </h3>

            <div className="text-grayscale-700 mt-3 text-sm">
              <p>
                <span className="text-grayscale-800 font-semibold">From: </span>{' '}
                <span>{emailContent.source}</span>
              </p>
              <p>
                <span className="text-grayscale-800 font-semibold">To: </span>{' '}
                <span>{emailContent.email}</span>
              </p>

              {emailContent.cc.length > 0 && (
                <p>
                  <span className="text-grayscale-800 font-semibold">CC: </span>{' '}
                  <span>{emailContent.cc.join(',')}</span>
                </p>
              )}
            </div>
          </div>

          <div className="overflow-y-scroll" style={{ height: '50vh' }}>
            {
              <div
                dangerouslySetInnerHTML={{ __html: emailContent.message }}
              ></div>
            }
          </div>

          <div className="border-t pt-5 flex justify-between">
            <button
              onClick={() => setOpenPreview(false)}
              className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
            >
              Cancel
            </button>

            <Button
              type="submit"
              showLoading={true}
              loading={isLoading}
              disabled={isLoading}
              onClick={() => send()}
              classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
            >
              {isLoading ? 'Sending' : 'Send'}
            </Button>
          </div>
        </div>
      </Modal>

      <Button
        px={3}
        textSize="xs"
        classes={`font-bold tracking-widest h-8 w-40 ${
          sent && 'pointer-events-none	'
        }`}
        border={`border-2 ${
          sent ? 'border-green-200' : 'border-blue-900'
        } tracking-widest font-bold`}
        hoverColor="blue-100"
        textColor={`${sent ? 'green-800' : 'blue-900'}`}
        spinnerColor="blue-900"
        bgColor={`${sent ? 'green-200' : 'bg-white'}`}
        roundedSize="full"
        py={5}
        showLoading={true}
        onClick={onPreview}
        disabled={sent || isDisabled || isLoading || isFetchingContent}
        loading={isLoading || isFetchingContent}
      >
        {text}
      </Button>
    </>
  );
};

export default SendMailButton;
