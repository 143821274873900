import { isArray, isString } from 'lodash';
import { Field, ErrorMessage, FieldArray } from 'formik';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import Button from 'components/Button';

const SponsoredProductCampaign = ({
  handleChange,
  values,
  setValues,
  errors,
}) => {
  const targeting = [
    { name: 'Automatic Targeting ', value: 'auto' },
    { name: 'Manual Targeting ', value: 'manual' },
  ];

  const biddingStrategies = [
    { name: '	Dynamic bids - down only ', value: 'legacyForSales' },
    { name: 'Dynamic bids - up and down ', value: 'autoForSales' },
    { name: 'Fixed bid ', value: 'manual' },
  ];

  const adGroupTargeting = [
    { name: 'Keyword Targeting ', value: 'keyword' },
    { name: 'Product Targeting ', value: 'product' },
  ];

  const managedAsinObj = {
    asin: '',
  };

  return (
    <div className="flex flex-col w-full gap-y-6 p-8 md:pb-8 md:px-0 md:pt-0 overflow-y-auto">
      <div>
        <Label
          htmlFor="data.adGroupName"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Ad Group Name <RequiredAsterisk />
        </Label>
        <Field
          name="data.adGroupName"
          placeholder="Add ad group name"
          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400"
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <ErrorMessage
          name="data.adGroupName"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div className="">
        <div className="col-span-2 space-y-3 mb-4">
          <span className="font-inter font-bold text-base">Managed ASIN</span>
        </div>
        <FieldArray name="data.products">
          {({ remove, push }) => (
            <>
              <div className="col-span-2 space-y-3 mb-4">
                {values.data.products &&
                  values.data.products.length > 0 &&
                  values.data.products.map((asin, index) => (
                    <div key={index} className="sm:grid grid-cols-3 gap-x-2 ">
                      <div className="col-span-3 flex justify-between">
                        <Label
                          classes="text-left leading-1.2"
                          textColor="text-grayscale-800"
                          textSize="13"
                        >
                          {' '}
                          ASIN {index + 1}
                        </Label>
                        <span
                          onClick={() => {
                            remove(index);
                          }}
                          className="cursor-pointer text-gray-500 hover:text-red-500"
                        >
                          <TrashIcon className="w-4 h-4 " />
                        </span>
                      </div>
                      <div className="col-span-3">
                        <Field
                          name={`data.products.${index}.asin`}
                          placeholder="Enter ASIN"
                          className="form-input text-13 text-grayscale-900 leading-1.5 bg-grayscale-400 disabled-white"
                          onChange={(e) => handleChange(e)}
                          type="text"
                        />

                        {isArray(errors?.data?.products) && (
                          <ErrorMessage
                            name={`data.products.${index}.asin`}
                            component="div"
                            className="text-red-700 font-normal text-xs"
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="">
                <Button
                  classes="font-bold tracking-wider w-full justify-center"
                  bgColor="gray-50"
                  hoverColor="gray-200"
                  roundedSize="2xl"
                  textColor="gray-600"
                  border="border-2 border-gray-600"
                  py={2}
                  shadow=""
                  onClick={(e) => {
                    push(managedAsinObj);
                  }}
                >
                  <PlusIcon className="w-4 h-4 inline" /> Add Managed Asin
                </Button>
              </div>
            </>
          )}
        </FieldArray>
      </div>

      {!!errors?.data?.products && isString(errors.data?.products) && (
        <ErrorMessage
          name="data.products"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      )}

      <div>
        <Label
          htmlFor="data.targetingType"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Targeting <RequiredAsterisk />
        </Label>
        <Field
          name="data.targetingType"
          as="select"
          className="form-select disabled-white"
          onChange={(e) => {
            let newValues = { ...values };
            newValues.data.targetingType = e.target.value;
            if (e.target.value === 'auto') {
              delete newValues.data.adGroupTargeting;
            } else {
              newValues.data.adGroupTargeting = 'keyword';
            }

            setValues(newValues);
          }}
        >
          {targeting.map((b, i) => (
            <option key={`data.targeting-${i}`} value={b.value}>
              {b.name}
            </option>
          ))}
        </Field>
      </div>

      {values.data?.targetingType === 'manual' ? (
        <div>
          <Label
            htmlFor="data.adGroupTargeting"
            classes="text-left leading-1.2"
            textColor="text-grayscale-800"
            textSize="13"
          >
            Targeting Type <RequiredAsterisk />
          </Label>
          <Field
            name="data.adGroupTargeting"
            as="select"
            className="form-select disabled-white"
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {adGroupTargeting.map((b, i) => (
              <option key={`data.adGroupTargeting-${i}`} value={b.value}>
                {b.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="data.adGroupTargeting"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      ) : null}

      <div>
        <Label
          htmlFor="data.negativeKeywords"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Negative Keyword Targeting
        </Label>
        <Field
          name="data.negativeKeywords"
          placeholder="Add negative keywords"
          className="form-input disabled-white"
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>

      <div>
        <Label
          htmlFor="data.strategy"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Campaign Bidding Strategy <RequiredAsterisk />
        </Label>
        <Field
          name="data.strategy"
          as="select"
          className="form-select disabled-white"
          onChange={(e) => {
            handleChange(e);
          }}
        >
          {biddingStrategies.map((t, i) => (
            <option key={`data.strategy-${i}`} value={t.value}>
              {t.name}
            </option>
          ))}
        </Field>
      </div>

      <div>
        <Label
          htmlFor="data.campaignName"
          classes="text-left leading-1.2"
          textColor="text-grayscale-800"
          textSize="13"
        >
          Campaign Name <RequiredAsterisk />
        </Label>
        <Field
          name="data.campaignName"
          placeholder="Add campaign name"
          className="form-input"
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <ErrorMessage
          name="data.campaignName"
          component="div"
          className="text-red-700 font-normal text-xs"
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <Label>
            Start Date <RequiredAsterisk />
          </Label>
          <input
            name="data.startDate"
            type="date"
            placeholder="Select Date"
            className="form-select disabled-white"
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <ErrorMessage
            name="data.startDate"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div>
          <Label>
            End Date <RequiredAsterisk />
          </Label>
          <input
            name="data.endDate"
            type="date"
            placeholder="Select Date"
            className="form-select disabled-white"
            onChange={(e) => {
              handleChange(e);
            }}
          ></input>
          <ErrorMessage
            name="data.endDate"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
        <div>
          <Label>
            Budget <RequiredAsterisk />
          </Label>
          <div className="flex justify-center gap-0 items-center">
            <span className="text-black sm:text-sm mr-2">$</span>
            <Field
              name="data.budget"
              className="form-input disabled-white"
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <ErrorMessage
            name="data.budget"
            component="div"
            className="text-red-700 font-normal text-xs"
          />
        </div>
      </div>
    </div>
  );
};

export default SponsoredProductCampaign;
