import React from 'react';
import classNames from 'utils/classNames';

const Textarea = ({
  id,
  name = '',
  value,
  onChange,
  rows = 3,
  classes,
  textSize = 'sm',
  border = 'border-gray-300',
  rounded = 'rounded-md',
  shadow = 'shadow-sm',
  ...rest
}) => {
  return (
    <textarea
      {...(id && { id })}
      name={id ?? name}
      onChange={onChange}
      value={value}
      rows={rows}
      className={classNames(
        'focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full',
        `text-${textSize}`,
        shadow,
        border,
        rounded,
        classes ?? ''
      )}
      {...rest}
    ></textarea>
  );
};

export default Textarea;
