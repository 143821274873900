import { Editor } from '@tinymce/tinymce-react';
import Loading from 'components/Loading';
import useAsset from 'hooks/useAsset';
import { useState } from 'react';

const TinyEditor = ({ editorRef, initialValue, value, setValue }) => {
  const { ASSET_URL } = useAsset();
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      {!loaded && (
        <>
          <Loading /> <small>Loading editor...</small>
        </>
      )}
      <Editor
        tinymceScriptSrc={`${ASSET_URL}/tinymce/tinymce.min.js`}
        tagName="div"
        onInit={(evt, editor) => {
          editorRef.current = editor;
          setLoaded(true);
        }}
        initialValue={initialValue}
        value={value}
        onEditorChange={(newValue, editor) => setValue(newValue)}
        init={{
          height: 500,
          noneditable_class: 'noedit',
          menubar: 'edit format insert table view',
          removed_menuitems: 'codeformat styles preview',
          font_size_input_default_unit: 'px',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'media',
            'table',
            'preview',
            //'help',
            //'wordcount',
            'code',
            'autosave',
            'importcss',
          ],
          setup: function (editor) {
            // editor.ui.registry.addButton('divider', {
            //   text: 'Divider',
            //   onAction: () => editor.insertContent('<hr class="noedit" />'),
            // });
            // editor.ui.registry.addButton('details', {
            //   text: 'Details',
            //   onAction: () =>
            //     editor.insertContent(
            //       '<span class="noedit">the quick brown fox jumps over the lazy dog</span>'
            //     ),
            // });
            // editor.ui.registry.addGroupToolbarButton('alignment', {
            //   icon: 'align-left',
            //   tooltip: 'Alignment',
            //   items: 'alignleft aligncenter alignright alignjustify',
            // });
            // editor.ui.registry.addGroupToolbarButton('insert', {
            //   text: 'Insert',
            //   items: 'divider details',
            // });
          },
          toolbar:
            'blocks fontsizeinput bold italic underline forecolor | image link | alignleft aligncenter alignright alignjustify outdent indent ' +
            'bullist numlist',
          color_map: [
            'E82052',
            'Pink',
            '000000',
            'Black',
            'B1B1B1',
            'Gray',
            'FFFFFF',
            'White',
            'FF0000',
            'Red',
            'FFFF00',
            'Yellow',
            '008000',
            'Green',
            '0000FF',
            'Blue',
            'EF4444',
            'Orange',
          ],
          content_style:
            'body { font-family:Source Sans Pro,Helvetica,Arial,sans-serif; font-size:18px } ' +
            'blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {margin:0}' +
            'h1,h2,h3,h4,h5,h6 { font-family:Inter,sans-serif; font-size: inherit;font-weight: bold;} ' +
            'a {color: inherit;text-decoration: inherit;}',
        }}
      />
    </div>
  );
};
export default TinyEditor;
