import Avatar from 'components/Avatar';
import { getNameInitials } from 'utils/formatters';

const MentionInComment = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { sender, data, title, message } = notification;

  const onClick = () => {
    markAsRead();
    let path = '';

    switch (data.module) {
      case 'task':
        path = `/tasks/${data.taskItemId}`;
        break;
      case 'salesClient':
        path = `/sales/client/${data.salesClientId}`;
        break;
      case 'agencyClient':
        path = `/clients/redirect/${data.agencyClientId}`;
        break;
      case 'lead':
        path = `/leads/profile/${data.leadId}`;
        break;
    }
    router.push(path);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <Avatar
        size="48px"
        imageSrc={sender?.avatar?.thumbnailUrl}
        initials={getNameInitials(sender.firstName, sender.lastName)}
      />
      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>

        <div className="text-13 text-grayscale-800 italic flex">
          <span
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          ></span>
        </div>
      </div>

      {children}
    </div>
  );
};

export default MentionInComment;
