import axios from 'axios';
import moment from 'moment';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Pie, Cell, PieChart, ResponsiveContainer, Label } from 'recharts';

import { useRoles } from 'features/roles';

const ClientSummary = ({ year }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [teams, setTeams] = useState([]);
  const { PROJECT_MANAGER } = useRoles();

  const COLORS = ['#0013C7', '#002F5D', '#1879D8', '#82B0DE', '#D3E4F5'];
  const cards = [
    {
      title: 'Current Partners',
      key: 'totalClients',
    },
    {
      title: 'New Partners',
      key: 'newClients',
    },
    {
      title: 'Terminated Partners',
      key: 'terminatedClients',
    },
  ];

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

      const summaryResponse = await axios.get(
        `/v2/agency/clients/analytics/summary`,
        { params: { startDate, endDate } }
      );

      const summaryData = summaryResponse.data.data;

      const usersResponse = await axios.get('/v2/users', {
        params: {
          endDate,
          startDate,
          'role.code': PROJECT_MANAGER.value,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'totalAssignedActiveClients',
            'totalAssignedNewClients',
            'totalAssignedTerminatedClients',
          ],
        },
      });

      const usersData = usersResponse.data.data.map((user, idx) => ({
        id: user.userId,
        newClients: user.totalAssignedNewClients,
        totalClients: user.totalAssignedActiveClients,
        name: `Team ${user.firstName} ${user.lastName}`,
        terminatedClients: user.totalAssignedTerminatedClients,
        color: COLORS[idx],
      }));

      let teams = [
        ...usersData,
        {
          id: 'unassigned',
          name: 'Unassigned',
          color: '#7EAFFF',
          newClients: summaryData.newClients - sumBy(usersData, 'newClients'),
          totalClients:
            summaryData.totalClients - sumBy(usersData, 'totalClients'),
          terminatedClients:
            summaryData.terminatedClients -
            sumBy(usersData, 'terminatedClients'),
        },
      ];

      if (isSubscribed) {
        setSummary(summaryResponse.data.data);
        setTeams(teams);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  function CustomLabel(props) {
    const { cx, cy } = props.viewBox;
    return (
      <>
        <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central">
          <tspan
            alignmentBaseline="middle"
            fontSize="32px"
            fontFamily="Inter"
            fontWeight="bold"
          >
            {props.value}
          </tspan>
        </text>
        <text x={cx} y={cy + 25} textAnchor="middle" dominantBaseline="central">
          <tspan fontSize="13px" fontFamily="Source Sans Pro">
            Total
          </tspan>
        </text>
      </>
    );
  }

  return (
    <div className="grid lg:grid-cols-3 gap-8">
      {cards.map((card, i) => (
        <div className="bg-white p-6" key={card.title}>
          <h3 className="leading-1.2 font-bold tracking-3/4 font-inter text-grayscale-900">
            {card.title}
          </h3>

          <div className="h-72">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
                  Loading Data.....
                </p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={teams}
                    dataKey={card.key}
                    nameKey="name"
                    innerRadius={70}
                    outerRadius={100}
                    labelLine={false}
                    isAnimationActive={false}
                  >
                    <Label
                      width={30}
                      position="center"
                      content={<CustomLabel value={summary[card.key]} />}
                    ></Label>

                    {teams.map((entry) => {
                      return <Cell key={`${entry.id}`} fill={entry.color} />;
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>

          <ul>
            {teams.map((team) => (
              <li key={team.id} className="flex items-center space-x-2 mb-2">
                <div
                  className="w-2 h-2"
                  style={{ backgroundColor: team.color }}
                />

                <p className="w-full text-grayscale-900 text-11 leading-1.5 flex items-center justify-between">
                  <span>{team.name}</span>

                  <span>{team[card.key]}</span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ClientSummary;
