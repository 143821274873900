import { ago } from 'utils/formatters';
import {
  ChevronLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { useState } from 'react';

const Log = ({ data }) => {
  const { name } = useLoggedInUser();
  const [isShow, setIsShow] = useState(false);

  const onSetShow = (e) => {
    e.preventDefault();
    setIsShow(!isShow);
  };

  let more = null;
  if (data.moreInformation) {
    more = data.moreInformation;
  } else if (data.moreInfo) {
    more = data.moreInfo;
  }

  return (
    <div>
      <div className="log flex flex-col xl:flex-row justify-between">
        <span
          className="text-sm w-3/4 truncate text-gray-500 whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: data.logText
              ? data.logText.replace(name, 'You')
              : data.content.replace(name, 'You'),
          }}
        ></span>

        <span className="text-xs text-gray-400 w-28 text-right">
          {ago(data.createdAt)}
        </span>
        {more && (
          <button className="pb-2 pl-1" onClick={(e) => onSetShow(e)}>
            <InformationCircleIcon className="w-5 h-5 transform left-1.5  text-gray-400" />
          </button>
        )}
      </div>
      {more && isShow && (
        <div className="flex">
          <div className="whitespace-pre-wrap text-1xs text-gray-500 pl-5 leading-snug relative">
            <ChevronLeftIcon className="w-3 h-3 inline transform -rotate-45 absolute left-1.5 text-gray-400" />
            <div className="mt-0.5 border-l-2 border-dotted border-gray-300 pl-1.5 break-all">
              {more}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Log;
