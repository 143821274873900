import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';

const UpdatesToggle = ({ onToggle }) => {
  return (
    <div className="container-fixed absolute right-1 mt-9 bg-gray-50 border-l border-gray-100">
      <button
        className="bg-white rounded-tr-xl rounded-br-xl px-1 py-2 w-10 h-40 inline-flex flex-col items-center"
        onClick={() => {
          onToggle(true);
        }}
      >
        <ChevronDoubleLeftIcon className="w-8 h-10 py-1 justify-self-start text-gray-400" />
        <p className="text-sm mt-10 transform rotate-90 whitespace-nowrap text-gray-400">
          Show Updates
        </p>
      </button>
    </div>
  );
};

export default UpdatesToggle;
