import React, { createContext, useState } from 'react';
import QueryString from 'qs';
import { useSelector } from 'react-redux';
import {
  selectCurrentAccount,
  selectCurrentMarketplace,
} from 'features/accounts/accountsSlice';
import useClientsMenu from './menu/useClientsMenu';
import useBillingMenu from './menu/useBillingMenu';
import useLeadsMenu from './menu/useLeadsMenu';
import useMarketingMenu from './menu/useMarketingMenu';
import useSalesMenu from './menu/useSalesMenu';
import useAdminMenu from './menu/useAdminMenu';
import useWritingMenu from './menu/useWritingMenu';
import useDesignMenu from './menu/useDesignMenu';
import usePartnerMenu from './menu/usePartnerMenu';
import useUserMenu from './menu/useUserMenu';

import { CubeTransparentIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

export const Context = createContext();

export const NavProvider = ({ children }) => {
  const marketplace = useSelector(selectCurrentMarketplace);
  const account = useSelector(selectCurrentAccount);
  const { isAgencyLevel } = usePermissions();

  const clients = useClientsMenu();
  const billing = useBillingMenu();
  const leads = useLeadsMenu();
  const marketing = useMarketingMenu();
  const sales = useSalesMenu();
  const admin = useAdminMenu();
  const writing = useWritingMenu();
  const design = useDesignMenu();
  const partner = usePartnerMenu();
  const user = useUserMenu();

  const items = [
    {
      name: 'Workspace',
      href: '/home',
      icon: CubeTransparentIcon,
      visible: () => isAgencyLevel(),
    },
    clients,
    billing,
    leads,
    marketing,
    sales,
    writing,
    design,
    admin,
    ...partner,
    ...user,
  ]
    .filter(
      (item) =>
        item.visible() &&
        (item.requiresAccount ? !!account : true) &&
        (item.requiresMarketplace ? !!marketplace : true)
    )
    .map((item) => {
      if (account) {
        item.href = item.href.replace(':accountId', account.accountId);
      }

      let itemQuery = item.query ? item.query() : {};
      const queryString = QueryString.stringify(itemQuery);

      if (item.requiresMarketplace && marketplace) {
        itemQuery.marketplace = marketplace.details.countryCode;
      }

      item.href = `${item.href}${queryString !== '' ? `?${queryString}` : ''}`;

      if (item.children && item.children.length > 0) {
        let children = item.children
          .filter(
            (child) =>
              child.visible() &&
              (child.requiresAccount ? !!account : true) &&
              (child.requiresMarketplace ? !!marketplace : true)
          )
          .map((child) => {
            if (account) {
              child.href = child.href.replace(':accountId', account.accountId);
            }

            let itemQuery = child.query ? child.query() : {};
            const queryString = QueryString.stringify(itemQuery);

            if (child.requiresMarketplace && marketplace) {
              itemQuery.marketplace = marketplace.details.countryCode;
            }

            child.href = `${child.href}${
              queryString !== '' ? `?${queryString}` : ''
            }`;
            return child;
          });

        item.children = children;
      }

      return item;
    });

  const userNavigation = [
    { name: 'Settings', href: '/settings', type: 'link' },
    { name: 'Sign Out', type: 'button' },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [sidebarSubmenuOpen, setSidebarSubmenuOpen] = useState(true);

  const value = {
    items,
    userNavigation,
    mobileMenuOpen,
    setMobileMenuOpen,
    sidebarSubmenuOpen,
    setSidebarSubmenuOpen,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
