const ACTIVE_STATUS = {
  value: 'ACTIVE',
  color: '#15803c',
  bgColor: '#dcfce7',
};

const INVITED_STATUS = {
  value: 'INVITED',
  color: '#854d0e',
  bgColor: '#fefce8',
};

const INACTIVE_STATUS = {
  value: 'INACTIVE',
  color: '#be185d',
  bgColor: '#fce7f3',
};

const PAUSED_STATUS = {
  value: 'PAUSED',
  color: '#002F5D',
  bgColor: '#D3E4F5',
};

export const statuses = {
  ACTIVE_STATUS,
  INVITED_STATUS,
  INACTIVE_STATUS,
  PAUSED_STATUS,
};
