import { useEffect, useState } from 'react';
import { Table } from 'components';
import TabNav from 'components/TabNav';
import useQueryParams from 'hooks/useQueryParams';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSalesClients } from '../salesSlice';
import usePermissions from 'hooks/usePermissions';
import { cloneDeep } from 'lodash';

const ListView = ({ tableColumns, searchParams }) => {
  const tableCols = cloneDeep(tableColumns).map((el) => {
    delete el.type;
    return {
      ...el,
    };
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { salesClients, paginationParams } = useSelector(
    (state) => state.sales
  );

  const { params, updateParams, sortParam } = useQueryParams(paginationParams);

  //reset initial page is 1st page and status booked
  useEffect(() => {
    updateParams({ ...params, page: 1, status: 'booked' });
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchSalesClients({ ...params, ...searchParams })).then(() => {
      setLoading(false);
    });
  }, [dispatch, params, searchParams]);

  const [navTabs, setNavTabs] = useState([
    {
      name: 'Booked',
      value: 'booked',
      current: true,
      className: '',
      visible: userCan('sales.deals.booked.view'),
    },
    {
      name: 'Proposal',
      value: 'Proposal',
      current: false,
      visible: userCan('sales.deals.proposal.view'),
    },
    {
      name: 'Transfer to Ops',
      value: 'Transfer to Operations',
      current: false,
      visible: userCan('sales.deals.transfer_to_ops.view'),
    },
    {
      name: 'Prospect',
      value: 'prospect',
      current: false,
      visible: userCan('sales.deals.prospect.view'),
    },
    {
      name: 'Lost',
      value: 'Lost',
      current: false,
      visible: userCan('sales.deals.lost.view'),
    },
    {
      name: 'No Show',
      value: 'No Show',
      current: false,
      visible: userCan('sales.deals.no_show.view'),
    },
    {
      name: 'Pending',
      value: 'Pending',
      current: false,
      visible: userCan('sales.deals.pending.view'),
    },
  ]);

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField ? sortField : 'createdAt'}:${sortOrder}`,
    });
  };

  return (
    <>
      <TabNav
        tabs={navTabs}
        setTabs={setNavTabs}
        onClick={(tab) => updateParams({ status: tab.value })}
        theme="tabbed"
      />
      <Table
        columns={tableCols.filter((el) => el.show)}
        data={salesClients}
        onTableChange={onTableChange}
        params={{ ...params, ...searchParams }}
        bordered={false}
        keyField="salesClientId"
        defaultSorted={[
          {
            dataField: sortParam ? sortParam[0] : 'client',
            order: sortParam ? sortParam[1] : 'asc',
          },
        ]}
        loading={loading}
        headerWrapperClasses="bg-gray-40"
      />
    </>
  );
};

export default ListView;
