import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import MetricsCard from './MetricsCard';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import axios from 'axios';
import PieChart from './Charts/PieChart';
import MarketplaceChart from './Charts/MarketplaceChart';
import ProductCategoryChart from './Charts/ProductCategoryChart';
import LeadQualityChart from './Charts/LeadQualityChart';

const MetricsOverview = ({ selectedDates }) => {
  const [groupLoading, setGroupLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState({});
  const { startDate, endDate } = selectedDates;
  const [processedLeads, setProcessedLeads] = useState(0);
  const [pitchedLeads, setPitchedLeads] = useState(0);
  const [responseRate, setResponseRate] = useState(0);
  const [bookedLeads, setBookedLeads] = useState(0);
  const [directBookingRate, setDirectBookingRate] = useState(0);
  const [bookingCallRate, setBookingCallRate] = useState(0);
  const [overAllBookingRate, setOverAllBookingRate] = useState(0);
  const [mpResponseData, setMpResponseData] = useState({});
  const [mpClosedData, setMpClosedData] = useState({});
  const [mpBookedData, setMpBookedData] = useState({});
  const [pcResponseData, setPcResponseData] = useState({});
  const [pcClosedData, setPcClosedData] = useState({});
  const [pcBookedData, setPcBookedData] = useState({});
  const [lqResponseData, setLqResponseData] = useState({});
  const [lqBookedData, setLqBooked] = useState({});
  const [lqPitchedData, setLqPitchedData] = useState({});
  const [rtResponseData, setRtResponseData] = useState({});

  const getGroupMetrics = async () => {
    let params = {
      startDate: selectedDates.selectedStartDate, // '2023-01-01T00:00:00+08:00', //
      endDate: selectedDates.selectedEndDate,
    };
    setGroupLoading(true);
    await axios
      .get(`/agency/leads/group`, {
        params,
      })
      .then((res) => {
        setGroupData(res.data.data);
      });
    setGroupLoading(false);
  };

  useEffect(async () => {
    async function getData() {
      await getGroupMetrics();
    }

    if (!groupLoading) {
      await getData();
    }
  }, [selectedDates]);

  // Set computed Data
  useEffect(() => {
    if (Object.keys(groupData).length > 0) {
      // Organize response into groups by source
      // By marketplace (response)
      const mpResponse = groupData?.responsed?.reduce((acc, entry) => {
        if (
          entry.responseType &&
          entry.marketplace &&
          entry.responseType !== 'Direct-Booking'
        ) {
          if (!acc[entry.marketplace]) {
            acc[entry.marketplace] = {
              name: entry.marketplace,
              value: 0,
              label: 'Responses',
            };
          }
          acc[entry.marketplace].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setMpResponseData(mpResponse);

      // By productCategory (response)
      const pcResponse = groupData?.responsed?.reduce((acc, entry) => {
        if (entry.productCategory) {
          if (!acc[entry.productCategory]) {
            acc[entry.productCategory] = {
              name: entry.productCategory,
              value: 0,
              label: 'Responses',
            };
          }
          acc[entry.productCategory].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setPcResponseData(pcResponse);

      // By leadQuality (response)
      const lqProductCategory = groupData?.responsed?.reduce((acc, entry) => {
        if (!acc[entry.leadQuality]) {
          acc[entry.leadQuality] = {
            name: entry.leadQuality,
            value: 0,
            label: 'Responses',
          };
        }
        acc[entry.leadQuality].value += parseInt(entry.count);
        return acc;
      }, {});
      setLqResponseData(lqProductCategory);

      // By responseType (response)
      const rtProductCategory = groupData?.responsed?.reduce((acc, entry) => {
        if (entry.responseType && entry.responseType !== 'Direct-Booking') {
          if (!acc[entry.responseType]) {
            acc[entry.responseType] = {
              name: entry.responseType,
              value: 0,
            };
          }
          acc[entry.responseType].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setRtResponseData(rtProductCategory);

      // By status (booked)
      const booked = groupData?.booked?.reduce((acc, entry) => {
        if (!acc[entry.status]) {
          acc[entry.status] = {
            name: entry.status,
            value: 0,
          };
        }
        acc[entry.status].value += parseInt(entry.count);
        return acc;
      }, {});
      setBookedLeads(booked);

      // By marketplace (booked)
      const mpBooked = groupData?.booked?.reduce((acc, entry) => {
        if (entry.marketplace) {
          if (!acc[entry.marketplace]) {
            acc[entry.marketplace] = {
              name: entry.marketplace,
              value: 0,
              label: 'Bookings',
            };
          }
          acc[entry.marketplace].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setMpBookedData(mpBooked);

      // By leadQuality (booked)
      const lqBooked = groupData?.booked?.reduce((acc, entry) => {
        if (entry.leadQuality) {
          if (!acc[entry.leadQuality]) {
            acc[entry.leadQuality] = {
              name: entry.leadQuality,
              value: 0,
              label: 'Bookings',
            };
          }
          acc[entry.leadQuality].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setLqBooked(lqBooked);

      // By leadQuality (pitched)
      const lqPitchData = groupData?.pitched?.reduce((acc, entry) => {
        if (entry.leadQuality) {
          if (!acc[entry.leadQuality]) {
            acc[entry.leadQuality] = {
              name: entry.leadQuality,
              value: 0,
              label: 'Pitches',
            };
          }
          acc[entry.leadQuality].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setLqPitchedData(lqPitchData);

      // By productCategory (booked)
      const pcBooked = groupData?.booked?.reduce((acc, entry) => {
        if (entry.productCategory) {
          if (!acc[entry.productCategory]) {
            acc[entry.productCategory] = {
              name: entry.productCategory,
              value: 0,
              label: 'Bookings',
            };
          }
          acc[entry.productCategory].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setPcBookedData(pcBooked);

      // By marketplace (closed)
      const mpClosed = groupData?.closed?.reduce((acc, entry) => {
        if (entry.marketplace) {
          if (!acc[entry.marketplace]) {
            acc[entry.marketplace] = {
              name: entry.marketplace,
              value: 0,
              label: 'Closed Deals',
            };
          }
          acc[entry.marketplace].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setMpClosedData(mpClosed); // Set closed deals grouped by marketplace

      // By productCategory (closed)
      const pcClosed = groupData?.closed?.reduce((acc, entry) => {
        if (entry.productCategory) {
          if (!acc[entry.productCategory]) {
            acc[entry.productCategory] = {
              name: entry.productCategory,
              value: 0,
              label: 'Closed Deals',
            };
          }
          acc[entry.productCategory].value += parseInt(entry.count);
        }

        return acc;
      }, {});
      setPcClosedData(pcClosed); // Set closed deals grouped by product category

      setProcessedLeads(groupData?.processed ?? 0);

      const pitchData = groupData?.pitched?.reduce((acc, entry) => {
        return acc + parseInt(entry.count);
      }, 0);
      setPitchedLeads(pitchData);

      const resRate = groupData?.responsed?.reduce((acc, entry) => {
        if (
          entry.responseType &&
          entry.responseType !== 'Accepted Disqualified' &&
          entry.responseType !== 'Direct-Booking'
        ) {
          return acc + parseInt(entry.count);
        } else {
          return acc;
        }
      }, 0);
      setResponseRate(Math.round((resRate / pitchData) * 100));

      // Direct Booking Rate
      const dbRate = groupData?.booked?.reduce((acc, entry) => {
        if (entry.status === 'Direct-Booking') {
          return acc + parseInt(entry.count);
        } else {
          return acc;
        }
      }, 0);
      setDirectBookingRate(Math.round((dbRate / pitchData) * 100));

      // Call-booking Rate
      const cbRate = groupData?.booked?.reduce((acc, entry) => {
        if (entry.status === 'Call-Booked') {
          return acc + parseInt(entry.count);
        } else {
          return acc;
        }
      }, 0);
      setBookingCallRate(Math.round((cbRate / pitchData) * 100));
      setOverAllBookingRate(Math.round(((cbRate + dbRate) / pitchData) * 100));
    }
  }, [groupData]);

  return (
    <>
      <div className="grid gap-1 grid-cols-3">
        <MetricsCard
          label="Processed Leads"
          data={processedLeads}
          footerData={false}
        />

        <MetricsCard
          label="Pitched Leads"
          data={pitchedLeads}
          footerData={false}
        />

        <MetricsCard
          label="Response Rate"
          data={`${responseRate}%`}
          footerData={false}
        />

        <MetricsCard
          label="Direct Booking Rate"
          data={`${directBookingRate}%`}
          footerData={false}
        />

        <MetricsCard
          label="Booked Call Rate"
          data={`${bookingCallRate}%`}
          footerData={false}
        />

        <MetricsCard
          label="Over-all Booking Rate"
          data={`${overAllBookingRate}%`}
          footerData={false}
        />
      </div>
      <div className="grid gap-1 grid-cols-4">
        <div className="col-span-2 pt-4">
          <PieChart title="Number of Responses" data={rtResponseData} />
        </div>
        <div className="col-span-2 pt-4">
          <PieChart title="Number of Booked Calls" data={bookedLeads} />
        </div>
      </div>
      <div className="grid gap-1 grid-cols-3 pt-4">
        <MarketplaceChart
          title="Top Marketplace"
          mpBookedData={mpBookedData}
          mpResponseData={mpResponseData}
          mpClosedData={mpClosedData} // Pass the new closed data for marketplace
        />
        <ProductCategoryChart
          title="Top Product Category"
          pcBookedData={pcBookedData}
          pcResponseData={pcResponseData}
          pcClosedData={pcClosedData} // Pass the new closed data for product category
        />

        <LeadQualityChart
          title="Lead Quality"
          lqResponseData={lqResponseData}
          lqBookedData={lqBookedData}
          lqPitchedData={lqPitchedData}
        />
      </div>
      {/* <pre>rtResponseData: {JSON.stringify(rtResponseData, null, '\t')}</pre> */}
      {/* 
      <pre>lqResponseData: {JSON.stringify(lqResponseData, null, '\t')}</pre>
      <pre>lqBookedData: {JSON.stringify(lqBookedData, null, '\t')}</pre>
      <pre>lqPitchedData: {JSON.stringify(lqPitchedData, null, '\t')}</pre>
      <pre>pcBookedData: {JSON.stringify(pcBookedData, null, '\t')}</pre>
      
      <pre>mpResponseData: {JSON.stringify(mpResponseData, null, '\t')}</pre>
      
      <pre>bookedLeads: {JSON.stringify(bookedLeads, null, '\t')}</pre>
      <pre>rtResponseData: {JSON.stringify(rtResponseData, null, '\t')}</pre>
      
      
      */}
      {/* <pre>{JSON.stringify(groupData, null, '\t')}</pre> */}
    </>
  );
};

export default withRouter(MetricsOverview);
