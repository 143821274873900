import { withRouter } from 'react-router-dom';
import classNames from 'utils/classNames';
import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import { Context } from 'layouts/NavProvider';
import { useContext } from 'react';

const SidebarSubmenuLink = ({ onOpen, currentItem, item }) => {
  const { sidebarSubmenuOpen, setSidebarSubmenuOpen } = useContext(Context);

  const isSelectedMenu = () => {
    return currentItem && sidebarSubmenuOpen && currentItem.name === item.name;
  };

  const markSelected = () => {
    return currentItem && !sidebarSubmenuOpen && currentItem.name === item.name;
  };

  return (
    <a
      href={item.children[0].href}
      className={classNames(
        'group flex-row text-white rounded-md hover:bg-white font-inter hover:text-red-500 flex relative cursor-pointer w-full px-3 py-2 text-xs space-x-1 items-center focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
        (isSelectedMenu() || markSelected()) && 'bg-white text-red-500'
      )}
      onClick={(e) => {
        e.preventDefault();
        onOpen(item);
      }}
    >
      {item.iconType && item.iconType === 'svg' ? (
        <svg
          className={classNames(
            'fill-current group-hover:text-red-500',
            isSelectedMenu() || markSelected() ? 'text-red-500' : 'text-white'
          )}
          style={item.iconSize ?? { width: '14px', height: '14px' }}
        >
          <use xlinkHref={item.icon} />
        </svg>
      ) : (
        <item.icon className="h-4 w-4 inline" aria-hidden="true" />
      )}

      <span
        className={classNames(
          'flex items-center before-arrow',
          isSelectedMenu() && 'expand'
        )}
      >
        {item.name}
      </span>
      {markSelected() && (
        <button
          className="rounded-full absolute z-1 -right-8 p-1 border border-grayscale-600 bg-white shadow-md cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSidebarSubmenuOpen(!sidebarSubmenuOpen);
          }}
        >
          <ChevronDoubleLeftIcon className="w-3.5 justify-self-start text-grayscale-600 transform rotate-180" />
        </button>
      )}
    </a>
  );
};

export default withRouter(SidebarSubmenuLink);
