import React, { useState, useEffect, useReducer } from 'react';
import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import MockupDescription from './MockupDescription';
import MockupImages from './MockupImages';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchListingMockupItem } from 'features/clients/Client/Dashboard/Mockup/mockupSlice';
import classNames from 'utils/classNames';
import classnames from 'classnames';
import update from 'immutability-helper';
import { capitalize } from 'lodash';
import MockupModal from './MockupModal';
import useAlert from 'hooks/useAlert';

function revisionsReducer(revisions, action) {
  switch (action.type) {
    case 'ready': {
      if (action.canSave) {
        return update(revisions, {
          canSave: { $set: action.canSave },
        });
      } else {
        const hasUpdate = revisions.data.some(
          (rev) => rev.revisions.length > 0
        );
        return update(revisions, {
          canSave: { $set: hasUpdate },
        });
      }
    }
    case 'show': {
      return update(revisions, {
        open: { $set: action.toggle },
      });
    }
    case 'add': {
      const { id, type } = action.revision;
      const idx = revisions.data.findIndex(
        (r) => r.id === id && r.type === type
      );
      let rev = {
        listingMockupRevisionId: null,
        listingMockupSlideId: type === 'slide' ? id : null,
        listingMockupTextId: type === 'text' ? id : null,
        comment: action.comment,
        label: action.label,
      };
      return update(revisions, {
        canSave: { $set: true },
        data: {
          [idx]: { action: { $set: 'insert' }, revisions: { $push: [rev] } },
        },
      });
    }
    case 'update': {
      const idx = revisions.data.findIndex(
        (r) => r.id === action.revision.id && r.type === action.revision.type
      );
      const rIdx = 0;

      return update(revisions, {
        canSave: { $set: true },
        data: {
          [idx]: {
            action: { $set: 'update' },
            revisions: {
              [rIdx]: { comment: { $set: action.comment } },
            },
          },
        },
      });
    }
    case 'delete': {
      const idx = revisions.data.findIndex(
        (r) => r.id === action.revision.id && r.type === action.revision.type
      );
      const rIdx = 0;

      return update(revisions, {
        canSave: { $set: true },
        data: {
          [idx]: {
            action: { $set: 'delete' },
            revisions: { $splice: [[rIdx, 1]] },
            label: { $set: action.label },
          },
        },
      });
    }
    case 'reset': {
      return action.revisions;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

function populateRevisions(mockupItem, type) {
  return mockupItem.map((mi) => {
    return {
      id: mi[`listingMockup${capitalize(type)}Id`],
      type,
      action: '',
      revisions: mi.revisions,
    };
  });
}

const ListingMockup = ({ item }) => {
  const dispatched = useDispatch();
  const { alertError } = useAlert();
  const [isResetRevisions, setIsResetRevisions] = useState(false);
  const [isRevisionsSaved, setIsRevisionsSaved] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [revisions, dispatch] = useReducer(revisionsReducer, {
    open: false,
    canSave: false,
    data: [
      ...populateRevisions(item.listingMockupText, 'text'),
      ...populateRevisions(item.listingMockupSlide, 'slide'),
    ],
  });

  const [revisionsCopy, setRevisionsCopy] = useState(revisions);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setRevisionsCopy(revisions);
  }, [item]);

  const onDisplayRevisions = (toggle) => dispatch({ type: 'show', toggle });

  const onResetRevisions = () => {
    setIsResetRevisions(false);
    dispatch({ type: 'reset', revisions: revisionsCopy });
  };

  const onUpdateRevision = ({ revision, comment, action, label }) => {
    dispatch({ type: action, revision, comment, label });
  };

  const onSaveMockupRevisions = async () => {
    try {
      setSaving(true);
      const data = revisions.data.filter((r) => r.action !== '');
      await axios.post(
        `agency/clients/listing-mockup/${item.listingMockupId}/revisions`,
        { data, status: 'for_revision' }
      );

      onDisplayRevisions(false);
      setIsRevisionsSaved(true);
      reloadItem();
    } catch (error) {
      alertError('Failed to save revisions', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  const onApproveMockup = async () => {
    try {
      setSaving(true);
      await axios.patch(
        `agency/clients/listing-mockup/${item.listingMockupId}/status`,
        {
          status: 'approved',
        }
      );
      reloadItem();
      setIsApproved(true);
    } catch (error) {
      alertError('Failed to approve mockup', error.response.data.message);
    } finally {
      setSaving(false);
    }
  };

  const reloadItem = () => {
    dispatched(fetchListingMockupItem(item.listingMockupId));
  };

  return (
    <div className="w-full px-[10%]">
      {item.listingMockupSlide.length > 0 &&
        item.listingMockupText.length > 0 && (
          <>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                    <h2
                      className={classNames(
                        classnames({
                          'text-yellow-500': item.status === 'for_revision',
                          'text-green-500': item.status === 'approved',
                        }),
                        'font-inter tracking-3/4'
                      )}
                    >
                      {item.asin}
                    </h2>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'rotate-180 transform' : '',
                        'h-6 w-6 text-gray-500'
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-5 text-sm text-gray-500">
                    {({ close }) => (
                      <>
                        <div className="flex items-center justify-between mb-8">
                          <div className="flex mt-4 self-end">
                            <p className="font-bold text-3xl leading-7 text-black ml-3">
                              {item.asin}
                            </p>
                            <p
                              className={classNames(
                                item.status === 'for_revision'
                                  ? 'bg-yellow-100 text-yellow-500'
                                  : '',
                                item.status === 'approved'
                                  ? 'bg-green-100 text-green-500'
                                  : '',
                                'rounded-full font-normal text-xs py-2 px-4 ml-4'
                              )}
                            >
                              {item.status === 'for_revision' &&
                                'Revisions Submitted'}
                              {item.status === 'approved' && 'Approved'}
                            </p>
                          </div>
                          <div className="flex space-x-4">
                            {item.isExpired && (
                              <p className="flex rounded-full font-normal text-xs py-2 px-4 ml-4 bg-gray-700 text-white items-center">
                                Expired
                              </p>
                            )}

                            {revisions.open ? (
                              <>
                                <Button
                                  classes="border-2 font-bold tracking-widest mt-4text-sm border-blue-900"
                                  bgColor="transparent"
                                  hoverColor="bule-200"
                                  roundedSize="full"
                                  textColor="blue-900"
                                  px={6}
                                  py={2}
                                  shadow=""
                                  textSize=""
                                  disabled={item.isExpired}
                                  onClick={() => setIsResetRevisions(true)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  classes={classNames(
                                    !revisions.canSave ? 'opacity-30' : '',
                                    'border-0 font-bold tracking-widest'
                                  )}
                                  bgColor="blue-900"
                                  hoverColor="blue-500"
                                  roundedSize="full"
                                  textColor="white"
                                  px={6}
                                  py={2}
                                  shadow=""
                                  textSize=""
                                  onClick={onSaveMockupRevisions}
                                  disabled={!revisions.canSave}
                                  showLoading={true}
                                  loading={saving}
                                  disabled={item.isExpired}
                                >
                                  Submit Revision Request
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  classes={classNames(
                                    'border-2 font-bold tracking-widest border-blue-900'
                                  )}
                                  bgColor="transparent"
                                  hoverColor="bule-200"
                                  roundedSize="full"
                                  textColor="blue-900"
                                  px={6}
                                  py={2}
                                  shadow=""
                                  textSize=""
                                  onClick={() => onDisplayRevisions(true)}
                                  disabled={item.isExpired}
                                >
                                  {item.status === 'approved'
                                    ? 'Reopen for Revision'
                                    : item.status === 'for_revision'
                                    ? 'Edit Revisions'
                                    : 'Request for Revision'}
                                </Button>
                                {item.status !== 'approved' && (
                                  <Button
                                    classes={classNames(
                                      item.status !== 'published' &&
                                        item.status !== 'for_revision'
                                        ? 'opacity-30'
                                        : '',
                                      'border-0 font-bold tracking-widest'
                                    )}
                                    bgColor="green-500"
                                    hoverColor="green-400"
                                    roundedSize="full"
                                    textColor="white"
                                    px={6}
                                    py={2}
                                    shadow=""
                                    textSize=""
                                    onClick={onApproveMockup}
                                    showLoading={true}
                                    loading={saving}
                                    disabled={
                                      item.status !== 'published' &&
                                      item.status !== 'for_revision'
                                    }
                                    disabled={item.isExpired}
                                  >
                                    Approve
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="xl:grid xl:grid-cols-12 xl:space-x-4">
                          <div className="xl:col-span-5 2xl:col-span-4">
                            <MockupImages
                              listingMockup={item}
                              revisions={revisions}
                              onUpdateRevision={onUpdateRevision}
                            />
                          </div>
                          <div
                            className="xl:col-span-7 2xl:col-span-8 py-7 px-6 bg-white space-y-4 overflow-auto"
                            style={{ maxHeight: '500px' }}
                          >
                            <MockupDescription
                              listingMockup={item}
                              revisions={revisions}
                              onUpdateRevision={onUpdateRevision}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/**Close Modal */}
            <MockupModal open={isResetRevisions} setOpen={setIsResetRevisions}>
              <div className="flex-col">
                <p className="text-center text-2xl font-bold">
                  Are you sure you want to cancel?
                </p>
                <p className="text-center text-2xl font-bold">
                  {item.status === 'approved' || item.status === 'for_revision'
                    ? 'Your changes will not be saved.'
                    : 'Your comments will be deleted.'}
                </p>
              </div>

              <div className="mt-4 flex justify-center">
                <Button
                  classes="border-2 font-bold tracking-widest mt-4 text-xs border-gray-500"
                  bgColor="transparent"
                  hoverColor="gray-200"
                  roundedSize="full"
                  textColor="gray-500"
                  px={6}
                  py={2}
                  shadow=""
                  textSize=""
                  onClick={() => setIsResetRevisions(false)}
                >
                  Keep leaving comments
                </Button>
                <Button
                  classes="border-0 font-bold tracking-widest mt-4 text-xs ml-3"
                  bgColor="red-500"
                  hoverColor="red-600"
                  roundedSize="full"
                  textColor="white"
                  px={6}
                  py={2}
                  shadow=""
                  textSize=""
                  onClick={onResetRevisions}
                >
                  Cancel Revision
                </Button>
              </div>
            </MockupModal>
            {/**Submit Revision */}
            <MockupModal open={isRevisionsSaved} setOpen={setIsRevisionsSaved}>
              <div className="flex">
                <div className="flex justify-end">
                  <CheckCircleIcon className="h-8 w-8 text-blue-900" />
                </div>
                <div>
                  <p className="text-center text-2xl font-bold text-blue-900">
                    Your revision request has been submitted
                  </p>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  classes="border-2 font-bold tracking-widest text-xs"
                  bgColor="blue-900"
                  hoverColor="blue-500"
                  roundedSize="full"
                  textColor="white"
                  px={6}
                  py={2}
                  shadow=""
                  textSize=""
                  onClick={() => setIsRevisionsSaved(false)}
                >
                  Okay
                </Button>
              </div>
            </MockupModal>
            {/**Approve Revision */}
            <MockupModal open={isApproved} setOpen={setIsApproved}>
              <div className="flex items-center justify-center space-x-2">
                <CheckCircleIcon className="h-8 w-8 text-green-500" />
                <p className="text-center text-2xl font-bold text-green-500">
                  This listing has been approved
                </p>
              </div>

              <div className="flex justify-center mt-4">
                <Button
                  classes="border-2 font-bold tracking-widest text-xs"
                  bgColor="green-500"
                  hoverColor="green-400"
                  roundedSize="full"
                  textColor="white"
                  px={6}
                  py={2}
                  shadow=""
                  textSize=""
                  onClick={() => setIsApproved(false)}
                >
                  Okay
                </Button>
              </div>
            </MockupModal>
          </>
        )}
    </div>
  );
};

export default ListingMockup;
