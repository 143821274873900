import axios from 'axios';
import { getNameInitials } from 'utils/formatters';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MailEditor from 'components/Editor/MailEditor';
import useMailThread from 'hooks/useMailThread';
import MailOptions from 'components/Editor/MailOptions';
import MailBody from 'components/Editor/MailBody';
import { setAlert } from 'features/alerts/alertsSlice';

const Email = ({ data, onChangeData }) => {
  const { employees } = useSelector((state) => state.tasks);
  const [reply, setReply] = useState(false);
  const cRef = useRef();
  const [threadData, setThreadData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const { threadHeader, threadHeaderContains } = useMailThread(threadData);
  const dispatch = useDispatch();

  const onLoadThread = async () => {
    setFetching(true);
    let res = await axios.get(`/agency/tasks/mail/${data.taskCommentId}`);

    let { attachmentIds, latestMessage } = res.data;

    if (attachmentIds.length > 0) {
      const a = await Promise.all(
        attachmentIds.map(
          async ({
            attachmentId,
            attachmentRef,
            mimeType,
            messageId,
            filename,
          }) => {
            const resAttachment = await axios.post(
              `/agency/tasks/mail/${data.taskCommentId}/attachments`,
              {
                attachmentId,
                messageId,
              }
            );

            if (latestMessage !== '') {
              if (attachmentRef) {
                latestMessage = latestMessage.replace(
                  `cid:${attachmentRef.value}`,
                  `data:${mimeType};base64,${resAttachment.data
                    .replace(/-/g, `+`)
                    .replace(/_/g, `/`)}`
                );
              }
            }

            return {
              attachmentId,
              attachmentRef,
              mimeType,
              messageId,
              filename,
              data: resAttachment.data,
            };
          }
        )
      );

      setMessage(latestMessage);
      setAttachments(a);
    } else {
      setMessage(latestMessage);
    }

    setThreadData(res.data);
    setFetching(false);
  };

  useEffect(() => {
    if (data) {
      onLoadThread();
    }
  }, [data.taskCommentId]);

  useEffect(() => {
    if (reply === true) {
      setTimeout(() => {
        if (cRef && cRef.current) {
          cRef.current.editor.focus();
        }
      }, 500);
    }
  }, [reply]);

  const deleteComment = async () => {
    await axios
      .delete(`/agency/tasks/comments/${data.taskCommentId}`)
      .then(() => onChangeData(false));
  };

  const togglePinComment = (action) => {
    axios
      .post(`/agency/tasks/comments/${data.taskCommentId}/${action}`)
      .then(() => onChangeData(false));
  };

  const onReload = () => {
    setTimeout(() => {
      onLoadThread();
    }, 3000);
    setReply(false);
  };

  const onDownloadAttachment = async (attachment) => {
    const { attachmentId, messageId } = attachment;
    const resAttachment = await axios.post(
      `/agency/tasks/mail/${data.taskCommentId}/attachments`,
      {
        attachmentId,
        messageId,
      }
    );

    if (resAttachment.data) {
      let fetchDataModified = `data:${
        attachment.mimeType
      };base64,${resAttachment.data.replace(/-/g, `+`).replace(/_/g, `/`)}`;
      let a = document.createElement('a');
      a.href = fetchDataModified;
      a.download = attachment.filename;
      a.click();
    } else {
      dispatch(
        setAlert(
          'error',
          'File not downloaded',
          'Error downloading this attachment'
        )
      );
    }
  };

  return (
    <div className="comment flex justify-between">
      <div className="bg-red-500 flex-shrink-0 text-white w-10 h-10 rounded-3xl text-base uppercase text-center leading-10 font-bold">
        {getNameInitials(data.creator.firstName, data.creator.lastName)}
      </div>
      <div className="ml-3 bg-white rounded-lg flex-1 shadow-sm px-4 py-2 group max-w-lg w-3/4">
        <MailOptions
          data={data}
          threadData={threadData}
          togglePinComment={togglePinComment}
          onDelete={deleteComment}
          reply={reply}
          setReply={setReply}
          onLoadThread={onLoadThread}
        />
        {reply && (
          <div className="-mx-4 mb-4">
            <MailEditor
              qRef={cRef}
              onChangeData={onChangeData}
              setOpen={setReply}
              editCommentValue={data.structure}
              threadData={{
                mailTo: threadHeader('Return-Path')
                  .replace('<', '')
                  .replace('>', ''),
                mailSubject: threadHeader('Subject'),
                mailMessage: `<br><div class="gmail_quote"><div dir="ltr" class="gmail_attr">On ${threadHeader(
                  'Date'
                )} , ${threadHeader('Return-Path')
                  .replace('<', '&lt;')
                  .replace(
                    '>',
                    '&gt;'
                  )} wrote:<br></div><blockquote class="gmail_quote" style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex">${message}</blockquote></div>`,
                mailFrom: threadHeader('Delivered-To'),
                mailCc: threadHeaderContains('Cc') ? threadHeader('Cc') : '',
                mailBcc: threadHeaderContains('Bcc') ? threadHeader('Bcc') : '',
                inReplyTo: threadHeaderContains('In-Reply-To')
                  ? threadHeader('In-Reply-To')
                  : '',
                references: threadHeaderContains('References')
                  ? threadHeader('References')
                  : '',
                threadId: threadData.threadId,
              }}
              employees={employees}
              primaryKey={{ key: 'taskCommentId', value: data.taskCommentId }}
              postUrl="/agency/tasks/mail"
              isReplying={true}
              afterSend={onReload}
            />
          </div>
        )}

        <MailBody
          data={data}
          threadData={threadData}
          message={message}
          attachments={attachments}
          onLoadThread={onLoadThread}
          fetching={fetching}
          onDownloadAttachment={onDownloadAttachment}
        />
      </div>
    </div>
  );
};

export default Email;
