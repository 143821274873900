import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { Table } from 'components';
import ServiceModal from './ServiceModal';
import classNames from 'utils/classNames';

const MonthlyPartnersPerService = ({ dateRange }) => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ rows: [] });

  const [openClients, setOpenClients] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({});

  const [tableColumns, setTableColumns] = useState([
    {
      dataField: 'service',
      text: 'Service',
      sort: true,
      headerStyle: { minWidth: '180px' },
    },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(
        '/v2/agency/clients/analytics/service/records',
        {
          params: { ...dateRange },
        }
      );

      if (isSubscribed) {
        setRecords({
          page: 1,
          from: 1,
          rows: response.data.data,
          to: response.data.data.length,
          pageSize: response.data.data.length,
        });
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [dateRange]);

  useEffect(() => {
    if (records.rows.length) {
      const [item] = records.rows;

      setTableColumns(
        Object.keys(item).map((key) => {
          const [startDate, endDate] = key.split('-to-');

          return {
            sort: false,
            dataField: key,
            text:
              key === 'service' ? 'Service' : moment(startDate).format('MMM'),
            formatter: (cell, row) => {
              if (key === 'service') return cell;

              return (
                <p
                  className={classNames(
                    cell ? 'text-secondary-light underline cursor-pointer' : '',
                    'text-center'
                  )}
                  onClick={() => {
                    if (cell) {
                      setSelectedService(row.service);
                      setSelectedDateRange({ startDate, endDate });
                      setOpenClients(true);
                    }
                  }}
                >
                  {cell}
                </p>
              );
            },
          };
        })
      );
    }
  }, [records.rows]);

  return (
    <div className="space-y-5">
      {selectedService && !isEmpty(selectedDateRange) && (
        <ServiceModal
          open={openClients}
          setOpen={setOpenClients}
          service={selectedService}
          dateRange={selectedDateRange}
        />
      )}

      <div className="text-grayscale-900 flex justify-between">
        <p className="font-inter font-bold leading-1.2 tracking-3/4">
          Breakdown
        </p>

        <p className="flex space-x-2">
          <span>{moment(dateRange.startDate).format('MMM DD, YYYY')}</span>
          <span>-</span>
          <span>{moment(dateRange.endDate).format('MMM DD, YYYY')}</span>
        </p>
      </div>

      <Table
        columns={[...tableColumns].map((column) => {
          return {
            ...column,
            classes:
              'text-grayscale-800 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left',
            headerClasses:
              'text-grayscale-700 text-13 leading-1.5 px-2.5 py-4 whitespace-nowrap text-left font-normal',
          };
        })}
        data={records}
        params={{ page: 1, pageSize: 10 }}
        keyField="service"
        loading={loading}
      />
    </div>
  );
};

export default MonthlyPartnersPerService;
