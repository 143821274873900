import moment from 'moment';
import { useSelector } from 'react-redux';

import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';

import DatePicker from 'features/datePicker/DatePicker';

import StoreTrends from './components/StoreTrends';
import StorePerformanceCard from './components/StorePerformanceCard';
import StorePerformanceMatrix from './components/StorePerformanceMatrix';
import SalesAndTrafficConversion from './components/SalesAndTrafficConversion';

const Overview = ({ account, marketplace }) => {
  const selectedDates = useSelector(selectCurrentDateRange);

  const dates = [
    {
      display: 'Custom',
      startDate: selectedDates.startDate,
      endDate: selectedDates.endDate,
    },
    {
      display: 'Yesterday',
      startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    {
      display: 'This Week',
      startDate: moment().startOf('week').format('YYYY-MM-DD'),
      endDate: moment().endOf(1, 'week').format('YYYY-MM-DD'),
    },
    {
      display: 'This Month',
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf(1, 'month').format('YYYY-MM-DD'),
    },
  ];

  return (
    <div className="bg-white p-5">
      <div className="grid grid-cols-3">
        <div className="col-span-2">
          <p className="text-grayscale-900 tracking-3/4 font-inter font-bold leading-1.2 text-xl">
            Overview
          </p>
          <p className="text-grayscale-700 leading-1.5">
            Summary metrics of your business
          </p>
        </div>

        <DatePicker
          position="right"
          showLabel={false}
          buttonBg="grayscale-400"
        />
      </div>

      <div className="mt-10">
        <p className="text-grayscale-900 tracking-3/4 font-inter font-bold leading-1.2">
          Store Performance
        </p>

        <div className="grid grid-cols-2 gap-8 mt-8">
          {dates.map((date) => (
            <StorePerformanceCard
              key={`profits-store-performance-card-${date.display}`}
              accountId={account.accountId}
              marketplace={marketplace.details.countryCode}
              startDate={date.startDate}
              endDate={date.endDate}
              display={date.display}
            />
          ))}
        </div>
      </div>

      <StorePerformanceMatrix
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
      />

      <StoreTrends
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
      />

      <SalesAndTrafficConversion
        accountId={account.accountId}
        marketplace={marketplace.details.countryCode}
      />
    </div>
  );
};

export default Overview;
