import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setDraftComment } from './commentSlice';
import { Quill } from 'react-quill';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import QuillMention from 'quill-mention';
import 'quill-mention/dist/quill.mention.css';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);
Quill.register('modules/mentions', QuillMention);

const Embed = Quill.import('blots/embed');
class LinkAttachmentBlot extends Embed {
  static create(value) {
    let node = super.create();
    const { title, url, key } = value;

    node.setAttribute('href', url);
    node.setAttribute('className', 'text-red-500');
    node.setAttribute('data-id', key);
    node.setAttribute('title', title);
    node.innerText = title;

    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute('href'),
      id: node.getAttribute('data-id'),
      title: node.getAttribute('title'),
    };
  }

  static formats(node) {
    return node.getAttribute('href');
  }
}
LinkAttachmentBlot.blotName = 'linkAttachment';
LinkAttachmentBlot.tagName = 'A';
LinkAttachmentBlot.className = 'link-attach';

Quill.register(LinkAttachmentBlot);

const useComment = (qRef = null) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [contentValue, setContentValue] = useState('');

  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ color: [] }],
    ['link', 'image'],
    ['clean'],
  ];

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'mention',
    'linkAttachment',
    'style',
    'width',
    'height',
    'align',
    'alt',
    'float',
  ];

  const saveDraftComment = (payload) => {
    dispatch(setDraftComment(payload));
  };

  return {
    value,
    setValue,
    contentValue,
    setContentValue,
    toolbarOptions,
    formats,
    saveDraftComment,
  };
};

export default useComment;
