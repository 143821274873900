import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import classNames from 'utils/classNames';
import { setAlert } from 'features/alerts/alertsSlice';

const ExportButton = ({ params, className = '', endpoint }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onExport = () => {
    setLoading(true);

    axios
      .get(`/advertising/${endpoint}/export`, {
        params,
      })
      .then((res) => {
        console.log(res.data);
        dispatch(setAlert('success', res.data.message));
      })
      .finally(() => setLoading(false));
  };

  return (
    <button
      type="submit"
      className={classNames(
        className,
        'font-bold text-sm text-secondary-light hover:text-secondary focus:text-secondary tracking-2 w-full text-center px-6 py-4 leading-normal'
      )}
      disabled={loading ? true : false}
      onClick={onExport}
    >
      {t('Advertising.ExportButton')}
    </button>
  );
};

export default ExportButton;
