import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChatAltIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from 'utils/formatters';
import DenyModal from './components/DenyModal';
import useUpsell from 'hooks/useUpsell';
import axios from 'axios';
import useAlert from 'hooks/useAlert';
import PriceLabel from 'components/PriceLabel';
import classNames from 'utils/classNames';
import Label from 'components/Forms/Label';
import Badge from 'components/Badge';
import classnames from 'classnames';
import BsPopover from 'components/BsPopover';
import usePermissions from 'hooks/usePermissions';

const UpsellReviewModal = ({
  open,
  setOpen,
  upsell,
  getUpsells,
  setIsOpenEmail,
  type = 'reviewer',
}) => {
  const { isAgencySuperUser } = usePermissions();
  const [isOpenDeny, setIsOpenDeny] = useState(false);
  const { STATUS, isItemWalmartListingOptimization, isItemPaidReviews } =
    useUpsell();
  const { alertError } = useAlert();

  const handleApproval = async () => {
    await axios
      .patch(`/agency/upsell/${upsell.details.upsellId}/approve`)
      .then((response) => {
        setOpen(false);
        setIsOpenEmail(true);
        getUpsells();
      })
      .catch((error) => {
        alertError('Something went wrong', error.response.data.message);
      });
  };

  return (
    <>
      <>
        <Modal
          open={open}
          setOpen={setOpen}
          as={'div'}
          align="top"
          noOverlayClick={true}
          zIndex="z-20"
        >
          <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
            <ModalHeader
              title={
                <div className="flex items-center space-x-8  pb-4">
                  <span> Upsell Request</span>
                  {isAgencySuperUser() && (
                    <BsPopover
                      title={<ChatAltIcon className="w-4 h-4 inline" />}
                    >
                      <div className="absolute bg-white shadow-lg px-4 pb-4 rounded-lg w-3/4 z-10 right-4 h-96 overflow-y-auto">
                        {upsell?.notes?.length > 0 && (
                          <div className="col-span-2 flex flex-col">
                            <label className="text-grayscale-800 text-base pb-1 border-b pt-4 mb-1 sticky top-0 bg-white">
                              Logs
                            </label>
                            {upsell?.notes?.map((note) => {
                              return (
                                <div className="flex justify-between items-start py-0.5">
                                  <p
                                    key={note.upsellLogId}
                                    className="text-xs text-grayscale-800 font-normal whitespace-pre-wrap"
                                  >
                                    {note.note}
                                  </p>
                                  <span className="text-1xs text-grayscale-700 font-normal">
                                    {dateFormatter(note.createdAt)}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </BsPopover>
                  )}
                </div>
              }
              setOpen={setOpen}
              fontSize="text-xl"
              fontStyle="font-bold"
              px="px-4 md:px-8"
              py="pt-4 md:pt-8 pb-0"
              xAlign="items-start"
              border=""
            />
            <div
              className="p-8 pt-0 overflow-y-auto"
              style={{ height: '55vh' }}
            >
              {upsell && (
                <>
                  {upsell.status === STATUS.REJECTED && (
                    <div className="mb-4 bg-red-50 rounded-lg py-4 px-4">
                      <p className="text-left text-xl text-red-500 font-bold pb-4">
                        Your upsell request was rejected
                      </p>
                      {upsell.rejectedByUser && (
                        <>
                          <Label>Rejected By</Label>
                          <p className="text-left text-sm font-normal">
                            {upsell.rejectedByUser?.firstName}&nbsp;
                            {upsell.rejectedByUser?.lastName}
                          </p>
                        </>
                      )}
                      <Label>Reason for rejection</Label>
                      <p className="text-left text-sm font-normal">
                        {upsell.reason ?? 'n/a'}
                      </p>
                    </div>
                  )}
                  <div className="mt-3">
                    <div className="sm:grid grid-cols-2 gap-4">
                      <div className="col-span-1 flex flex-col">
                        <label className="text-gray-500 text-xs pb-1">
                          Client
                        </label>
                        {upsell?.agencyClient?.client}
                      </div>
                      <div className="col-span-1 flex flex-col">
                        <label className="text-gray-500 text-xs pb-1">
                          Date
                        </label>
                        {dateFormatter(upsell?.createdAt)}
                      </div>
                      <div className="col-span-2 flex flex-col">
                        <label className="text-gray-500 text-xs pb-1">
                          Upsell Items
                        </label>
                        {upsell?.details?.name}
                      </div>
                      {isItemPaidReviews(upsell?.details?.name) ? (
                        <>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Review Fee
                            </label>
                            <PriceLabel price={upsell?.details?.reviewFee} />
                          </div>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Processing Fee
                            </label>
                            <PriceLabel
                              currency="%"
                              price={upsell?.details?.processingFee}
                            />
                          </div>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Tax
                            </label>
                            <PriceLabel
                              currency="%"
                              price={upsell?.details?.tax}
                            />
                          </div>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Estimate Completion Date
                            </label>
                            {upsell?.details?.completionDate}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Quantity
                            </label>
                            {numberFormatter(upsell?.details?.qty)}
                          </div>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Unit Price
                            </label>
                            <PriceLabel price={upsell?.details?.price} />
                          </div>
                        </>
                      )}
                      <div className="col-span-1 flex flex-col">
                        <label className="text-gray-500 text-xs pb-1">
                          Total
                        </label>
                        <PriceLabel price={upsell?.totalAmount} />
                      </div>

                      {upsell.status !== STATUS.AWAITING_APPROVAL && (
                        <>
                          <div className="col-span-1 flex flex-col">
                            <label className="text-gray-500 text-xs pb-1">
                              Status
                            </label>
                            <Badge
                              color={classnames({
                                orange:
                                  upsell.status === STATUS.AWAITING_APPROVAL,
                                gray: upsell.status === STATUS.DRAFT,
                                blue: upsell.status === STATUS.APPROVED,
                                yellow:
                                  upsell.status === STATUS.SENT ||
                                  upsell.status === STATUS.OVERDUE ||
                                  upsell.status === STATUS.PENDING,
                                green: upsell.status === STATUS.PAID,
                                red: upsell.status === STATUS.REJECTED,
                                purple: upsell.status === STATUS.WAIVED,
                              })}
                              classes="capitalize text-center w-36 justify-center"
                              rounded="full"
                              padding="py-1.5"
                              textSize="sm"
                              fontFamily=""
                            >
                              {upsell.status}
                            </Badge>
                          </div>
                          {upsell.invoiceNumber && (
                            <div className="col-span-1 flex flex-col">
                              <label className="text-gray-500 text-xs pb-1">
                                Invoice Number
                              </label>
                              <Link
                                to={`/invoices/${upsell.invoiceId}`}
                                className="text-secondary-light underline text-base"
                              >
                                {upsell.invoiceNumber}
                              </Link>
                            </div>
                          )}
                        </>
                      )}

                      <div className="col-span-2 flex flex-col">
                        <label className="text-gray-500 text-xs pb-1">
                          Upsell Description
                        </label>
                        <p className="whitespace-pre-wrap break-words">
                          {upsell?.details?.description}
                        </p>
                      </div>
                      {upsell?.details && (
                        <div className="col-span-2 border-t border-grayscale-500 mt-4">
                          <div className="font-bold text-lg pt-6 pb-2 text-grayscale-900">
                            {isItemPaidReviews(upsell?.details?.name)
                              ? 'ASINs to Review'
                              : isItemWalmartListingOptimization(
                                  upsell?.details?.name
                                )
                              ? 'Service Items'
                              : 'Service ASINs'}
                          </div>
                        </div>
                      )}

                      {upsell?.serviceAsins?.map((asin, index) => {
                        return (
                          <div
                            key={asin.upsellServiceAsinId}
                            className="col-span-2 grid grid-cols-3 border border-grayscale-500 rounded-xl p-4 gap-x-6 gap-y-2"
                          >
                            <div
                              className={classNames(
                                isItemPaidReviews(upsell?.details?.name)
                                  ? 'col-span-1'
                                  : 'col-span-3',
                                'flex flex-col'
                              )}
                            >
                              <label className="text-grayscale-800 text-base pb-1 font-bold">
                                {isItemWalmartListingOptimization(
                                  upsell.details.name.toLowerCase().trim()
                                )
                                  ? ' Item '
                                  : ' ASIN '}
                                {index + 1}
                              </label>
                              <span
                                className="text-ellipsis truncate"
                                title={asin.asin}
                              >
                                {asin.asin}
                              </span>
                            </div>
                            {isItemPaidReviews(upsell?.details?.name) && (
                              <>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Quantity
                                  </label>
                                  {asin.qty}
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Unit Price
                                  </label>
                                  <PriceLabel price={asin.price} />
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Product Cost
                                  </label>
                                  <PriceLabel price={asin.productCost} />
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Processing Fee
                                  </label>
                                  <PriceLabel price={asin.totalProcessingFee} />
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Review Fee
                                  </label>
                                  <PriceLabel price={asin.totalReviewFee} />
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Tax
                                  </label>
                                  <PriceLabel price={asin.totalTax} />
                                </div>
                                <div className="col-span-1 flex flex-col">
                                  <label className="text-grayscale-700 text-sm pb-1">
                                    Total Amount
                                  </label>
                                  <PriceLabel price={asin.totalAmount} />
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}

                      <div className="col-span-2 flex flex-col  border-t border-b border-grayscale-500 mt-4 pt-8 pb-12">
                        <label className="text-gray-500 text-xs pb-1">
                          Note
                        </label>
                        <p key={upsell?.note}>{upsell?.note}</p>
                      </div>
                    </div>
                  </div>

                  {upsell.status === STATUS.AWAITING_APPROVAL &&
                    type !== 'creator' && (
                      <div className="mt-8 flex space-x-6">
                        <Button
                          classes="border-0 font-bold tracking-2"
                          bgColor="red-500"
                          hoverColor="red-700"
                          roundedSize="full"
                          textColor="white"
                          px={12}
                          py={1.5}
                          showLoading={true}
                          onClick={() => setIsOpenDeny(true)}
                        >
                          Deny
                        </Button>
                        <Button
                          classes="border-0 font-bold tracking-2"
                          bgColor="green-500"
                          hoverColor="green-700"
                          roundedSize="full"
                          textColor="white"
                          px={10}
                          py={1.5}
                          type="submit"
                          showLoading={true}
                          onClick={() => handleApproval()}
                        >
                          Approve
                        </Button>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </Modal>
      </>

      <DenyModal
        open={isOpenDeny}
        setOpen={setIsOpenDeny}
        upsellData={upsell}
        getUpsells={getUpsells}
        setReviewModalOpen={setOpen}
      />
    </>
  );
};

export default UpsellReviewModal;
