import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, number, date, array, ref, min, boolean } from 'yup';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import axios from 'axios';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { setAlert } from 'features/alerts/alertsSlice';
import Label from 'components/Forms/Label';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';
import { isEmpty } from 'lodash';

const UpdateDefaultContactModal = ({
  open,
  setOpen,
  agencyClient,
  onReload,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const {
    defaultContact: user,
    defaultContactId: userId,
    agencyClientId,
  } = agencyClient;
  const [initialValues, setInitialValues] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    if(user){
    setInitialValues({
      userId: userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });}
  }, [user]);

  const validationSchema = object().shape({
    userId: string().required('Required'),
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email().required('Required'),
  });

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const response = await axios.patch(
        `/agency/clients/${agencyClientId}/contact`,
        values
      );
      dispatch(
        setAlert(
          'success',
          'Default contact updated',
          'Successfully updated contact details'
        )
      );
      onReload(response.data.data);
      setSaving(false);
      setOpen(false);
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-10"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title={
            <div className="flex items-center space-x-4">
              <span>Update Default Contact Details</span>
            </div>
          }
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <Form>
              <div className="sm:grid grid-cols-2 gap-x-6 gap-y-4 p-4 md:pb-8 md:px-8 md:pt-0 overflow-y-auto">
                <div className="col-span-2 ">
                  <Label>First name</Label>
                  <Field
                    name="firstName"
                    placeholder=""
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="text"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2 ">
                  <Label>Last name</Label>
                  <Field
                    name="lastName"
                    placeholder=""
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="text"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2 ">
                  <Label>Email</Label>
                  <Field
                    name="email"
                    placeholder=""
                    className="form-input disabled-white"
                    onChange={(e) => handleChange(e)}
                    type="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-2  my-8 flex justify-between items-center">
                  <div className="space-x-4">
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="red"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>

                    <Button
                      classes="border-0 font-bold tracking-wider "
                      bgColor="red-700"
                      hoverColor="red-400"
                      roundedSize="2xl"
                      textColor="white"
                      px={12}
                      py={1.5}
                      shadow=""
                      type="submit"
                      showLoading={true}
                      loading={saving}
                    >
                      Update
                    </Button>
                  </div>
                  {!isEmpty(errors) && (
                    <div className="text-red-700">Error(s) found!</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default UpdateDefaultContactModal;
