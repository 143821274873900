import { useFormikContext } from 'formik';
import Confirm from 'components/Confirm';
import { CheckIcon } from '@heroicons/react/outline';

const ConfirmApplyModal = ({
  creditsApplied,
  open,
  setOpen,
  saving,
  okText,
}) => {
  const { values, handleSubmit } = useFormikContext();

  return (
    <Confirm
      title={`Are you sure you want to apply this credit amount of $${creditsApplied}?`}
      content={
        <>
          <div className="sm:grid grid-cols-2 text-left px-4 mb-8">
            {values.invoices
              ?.filter(
                (i) =>
                  i.apply && i.creditsToApply > 0 && i.customer_id !== 'autoapp'
              )
              .map((i) => (
                <div className="col-span-1 flex items-center">
                  <CheckIcon className="w-4 h-4 text-success inline mr-1" />
                  <span className="text-grayscale-800">{i.number}</span>{' '}
                  <span className="text-xs mx-1">&nbsp;&nbsp;&nbsp;</span>
                  <span className="text-xs">$</span>
                  <span className="text-grayscale-900 font-bold">
                    {i.creditsToApply}
                  </span>
                </div>
              ))}

            {values.autoApply && (
              <div className="col-span-2 flex items-center justify-center leading-none mt-2 text-grayscale-800 py-2 tracking-tight">
                <CheckIcon className="w-4 h-4 text-success inline mr-1" />{' '}
                Auto-apply to next month's invoice
                <span className="text-xs mx-1">&nbsp;&nbsp;&nbsp;</span>
                <span className="text-xs">$</span>
                <span className="text-grayscale-900 font-bold">
                  {values.invoices[values.invoices.length - 1].creditsToApply}
                </span>
              </div>
            )}
          </div>
          <p className="text-sm leading-tight">
            If you click&nbsp;
            <span className="text-success-dark">{okText}</span>, the credit
            amount will be automatically applied to the selected invoice(s) and
            the partner will receive a notification about the changes
          </p>
        </>
      }
      open={open}
      setOpen={setOpen}
      onOkClick={handleSubmit}
      onCancelClick={() => setOpen(false)}
      okText={okText}
      showOkLoading={true}
      okLoading={saving}
    />
  );
};
export default ConfirmApplyModal;
