import { UserSelector } from 'components';
import useClientAssignees from 'features/clients/Client/useClientAssignees';

const ClientAssigneeItem = ({
  item,
  clientId,
  assignee,
  params = {},
  className = '',
  disabled = false,
}) => {
  const { updateAssignee, deleteAssignee } = useClientAssignees();

  return (
    <div className={className} key={item.role.value}>
      <UserSelector
        params={params}
        disabled={disabled}
        value={assignee?.user}
        onChange={({ userId }) =>
          updateAssignee(clientId, userId, item.role.value)
        }
        onDelete={({ userId }) => deleteAssignee(clientId, userId)}
      />

      <p className="text-11 md:text-13 leading-1.5 text-grayscale-700 mt-1.5 text-center px-2">
        {item.label ?? item.role.name}
      </p>
    </div>
  );
};

export default ClientAssigneeItem;
