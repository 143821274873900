import { useEffect, useRef, useState } from 'react';
import { hostedFields, client, dataCollector } from 'braintree-web';
import axios from 'axios';
import useAlert from './useAlert';
import useBraintreeUtils from './useBraintreeUtils';

const useBraintreePayment = () => {
  const { alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [clientInstance, setClientInstance] = useState(undefined);
  const [clientError, setClientError] = useState(false);
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [paymentFormError, setPaymentFormError] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const cardNumber = useRef();
  const cvv = useRef();
  const expirationDate = useRef();
  const { showTokenizeError } = useBraintreeUtils();

  useEffect(() => {
    if (hostedFieldsInstance) hostedFieldsInstance.teardown();
    if (!clientInstance) {
      createClientInstance();
    }
    return () => {
      if (hostedFieldsInstance) hostedFieldsInstance.teardown();
      if (clientInstance) clientInstance.teardown();
    };
  }, []);

  useEffect(() => {
    if (clientInstance) {
      createCreditCardInstance();
    }
  }, [clientInstance]);

  const createClientInstance = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/bt/clienttoken');

      client.create(
        { authorization: response.data.clientToken },
        (err, clientInstance) => {
          if (err) {
            alertError(
              'Failed to connect with braintree',
              'Cannot create client'
            );
            setClientError(true);
            return;
          }
          setClientInstance(clientInstance);

          dataCollector.create(
            { client: clientInstance },
            (err, dataCollectorInstance) => {
              if (err) {
                alertError(
                  'Failed to connect with braintree',
                  'Device Data error'
                );
                return;
              }
              setDeviceData(dataCollectorInstance.deviceData);
            }
          );
        }
      );
    } catch (err) {
      alertError('Failed to connect with braintree', err.response.data.message);
    } finally {
      //setLoading(false);
    }
  };

  const createCreditCardInstance = () => {
    //setLoading(true);

    hostedFields.create(
      {
        client: clientInstance,
        styles: {
          //input: 'text-sm font-sourceSansPro h-10',
          ':focus': 'text-black',
          '.valid': 'text-black',
          '.invalid': 'text-red-400',
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: '1111 1111 1111 1111',
          },
          cvv: {
            selector: '#cvv',
            placeholder: '1111',
            maskInput: true,
            maxlength: 4,
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YY',
          },
        },
      },
      function (err, hostedFieldsInstance) {
        if (err) {
          setPaymentFormError(true);
          alertError('Failed to generate payment form', err);
          return;
        }

        setHostedFieldsInstance(hostedFieldsInstance);

        function findLabel(field) {
          switch (field.container.id) {
            case 'card-number':
              return cardNumber;
            case 'cvv':
              return cvv;
            case 'expiration-date':
              return expirationDate;
            default:
              break;
          }
        }

        hostedFieldsInstance.on('focus', function (event) {
          const el = findLabel(event.fields[event.emittedBy]);
          el.current.classList.add('label-float');
          el.current.classList.remove('filled');
        });

        // // Emulates floating label pattern
        hostedFieldsInstance.on('blur', function (event) {
          const field = event.fields[event.emittedBy];
          const label = findLabel(field);

          if (field.isEmpty) {
            label.current.classList.remove('label-float');
          } else if (field.isValid) {
            label.current.classList.add('filled');
          } else {
            label.current.classList.add('invalid');
          }
        });

        hostedFieldsInstance.on('empty', function (event) {
          const el = findLabel(event.fields[event.emittedBy]);
          el.current.classList.remove('filled');
          el.current.classList.remove('invalid');
        });

        hostedFieldsInstance.on('validityChange', function (event) {
          var field = event.fields[event.emittedBy];
          var label = findLabel(field);

          if (field.isPotentiallyValid) {
            label.current.classList.remove('invalid');
          } else {
            label.current.classList.add('invalid');
          }
        });

        setLoading(false);
      }
    );
  };

  return {
    clientInstance,
    clientError,
    hostedFieldsInstance,
    paymentFormError,
    deviceData,
    loading,
    cardNumber,
    cvv,
    expirationDate,
    showTokenizeError,
  };
};

export default useBraintreePayment;
