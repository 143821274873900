import { Popover } from '@headlessui/react';
import {
  SearchIcon,
  CheckIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import InputPrepend from './Forms/InputPrepend';

const ClientAsinsSelectMultiple = ({
  asins,
  selectedIds,
  onChange,
  position = 'left-1/2 -translate-x-1/2 transform',
  width = 'w-64',
  height = 'h-80',
  allowAdd = true,
  allowRemove = true,
  containerWidth = 'w-full max-w-sm',
  onSelectAll,
}) => {
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    setIsAllSelected(selectedIds.length === asins.length ? true : false);
  }, [selectedIds]);

  useEffect(() => {
    setFilteredAsins(asins);
  }, [asins]);

  const onSearch = (e) => {
    const search = e.target.value.toUpperCase();

    let f = asins.filter((asin) => asin.asin.includes(search));
    setFilteredAsins(f);
  };
  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className={containerWidth}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              disabled={!(allowAdd || allowRemove)}
              className={classNames(
                open ? '' : 'text-opacity-90',
                'group flex w-full items-center rounded-sm bg-orange-700 py-0 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              )}
            >
              <label className="flex items-center justify-between w-full px-2 py-3 cursor-pointer rounded-lg focus:outline-none sm:text-sm appearance-none border-white bg-gray-50 placeholder-gray-400 hover:ring-gray-200 hover:border-gray-200 hover:border-gray-200">
                <span>Select Available ASIN/s to add</span>
                <ChevronDownIcon className="ml-1 w-4 h-4 text-gray-400" />
              </label>
            </Popover.Button>

            <Popover.Panel
              className={classNames(
                position,
                width,
                'absolute z-20 min-h-full'
              )}
            >
              {({ close }) => (
                <div className="overflow-hidden rounded-b-lg shadow-lg ring-1 ring-gray-200 ring-opacity-5">
                  <div className="relative bg-white">
                    <div className="px-4 py-2">
                      <InputPrepend
                        name="search"
                        onChange={(e) => onDebouncedSearch(e)}
                        type="text"
                        placeholder="Search..."
                        prependText={<SearchIcon className="w-4 h-4" />}
                        border="border-none"
                        rounded="rounded-full"
                        classes="text-base form-input"
                        autoFocus
                      />
                    </div>
                    <div
                      className={classNames(
                        height,
                        'overflow-y-auto text-left'
                      )}
                    >
                      <div className="flex justify-between p-4">
                        <span className="font-bold">Available ASINS</span>
                        <button
                          type="button"
                          onClick={() => {
                            onSelectAll(
                              isAllSelected ? 'deselect' : 'select',
                              selectedIds.map((s) => s.asin)
                            );
                            setIsAllSelected(!isAllSelected);
                          }}
                        >
                          {isAllSelected ? 'Deselect' : 'Select'}&nbsp;All
                        </button>
                      </div>
                      {filteredAsins.length > 0 ? (
                        filteredAsins.map((asin) => {
                          const selected = selectedIds.find(
                            (a) => a.asin === asin.asin
                          );
                          const editable = selected ? allowRemove : allowAdd;

                          return (
                            <button
                              className={classNames(
                                editable ? '' : 'cursor-not-allowed',
                                'grid grid-cols-2 items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-gray-100 rounded-md focus:ring-gray-300 focus:border-gray-200'
                              )}
                              key={asin.asin}
                              value={asin.asin}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (editable)
                                  onChange(asin, selected ? 'remove' : 'add');
                              }}
                              type="button"
                            >
                              <span className="col-span-1 whitespace-pre-wrap">
                                {asin.asin}
                              </span>
                              <span className="col-span-1 flex justify-end">
                                <label
                                  className={classNames(
                                    selected ? 'bg-black' : 'bg-gray-200',
                                    editable ? '' : 'cursor-not-allowed',
                                    'block rounded-full w-6 h-6 leading-6 text-sm uppercase text-center font-normal items-end'
                                  )}
                                >
                                  <CheckIcon className="w-6 h-6 inline text-white relative transform -translate-y-0.5" />
                                </label>
                              </span>
                            </button>
                          );
                        })
                      ) : (
                        <div className="w-full text-xs text-gray-700 py-2 px-3">
                          No asins found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ClientAsinsSelectMultiple;
