import { useEffect, Fragment, useState, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string, boolean } from 'yup';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { RefreshIcon } from '@heroicons/react/outline';

import { joiAlertErrorsStringify } from 'utils/formatters';
import { setAlert } from 'features/alerts/alertsSlice';
import Label from 'components/Forms/Label';
import Button from 'components/Button';
import usePermissions from 'hooks/usePermissions';

const VariableForm = ({ action, data, setOpen, getVariables }) => {
  const dispatch = useDispatch();
  const { isAgencySuperUser } = usePermissions();

  const onCreateLead = async (values) => {
    try {
      await axios.post(`/agency/leads/variables`, values).then((res) => {
        console.log(res.data);
        setOpen(false);
        dispatch(setAlert('success', 'Variable Saved'));
        getVariables();
      });
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { list: 'check' },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
  ];

  const onUpdateLead = async (values) => {
    console.log('onUpdate');
    const { leadVariableId } = values;
    delete values.leadVariableId;
    delete values.createdAt;
    delete values.updatedAt;

    try {
      await axios
        .put(`/agency/leads/variables/${leadVariableId}`, values)
        .then((res) => {
          setOpen(false);
          dispatch(setAlert('success', 'Variable Updated'));
          getVariables();
        });
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onSubmit = async (values) => {
    if (action == 'add') {
      onCreateLead(values);
    } else {
      onUpdateLead(values);
    }
  };

  const reloadValue = (e, setFieldValue) => {
    e.preventDefault();
    setFieldValue('value', data.value);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const validationSchema = object().shape({
    key: string().required('Required'),
    value: string().required('Required'),
    description: string(),
    autoAssignable: boolean(),
  });

  return (
    <>
      <Formik
        initialValues={data}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <Label>Key</Label>
                <Field
                  name="key"
                  placeholder="Key"
                  className="form-select text-sm"
                  onChange={(e) => handleChange(e)}
                  type="text"
                />
                <ErrorMessage
                  name="key"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>

              <div className="col-span-12">
                <div className="flex justify-between">
                  <Label>Value</Label>

                  <button onClick={(e) => reloadValue(e, setFieldValue)}>
                    <RefreshIcon className="h-5 w-5" color="gray" />
                  </button>
                </div>
                {values.key.includes('itchTemplate') ? (
                  <ReactQuill
                    value={values.value}
                    onChange={(v) => setFieldValue('value', v)}
                    theme="snow"
                    modules={{
                      toolbar: {
                        container: toolbarOptions,
                      },
                    }}
                  />
                ) : (
                  <Field
                    name="value"
                    placeholder="Value"
                    className="form-select text-sm"
                    onChange={(e) => handleChange(e)}
                    as="textarea"
                  />
                )}

                <ErrorMessage
                  name="value"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>

              <div className="col-span-12">
                <Label>Description</Label>
                <Field
                  name="description"
                  placeholder="Description"
                  className="form-select text-sm"
                  onChange={(e) => handleChange(e)}
                  as="textarea"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-700 font-normal text-xs"
                />
              </div>
            </div>
            <div className="col-span-12 flex items-center">
              <Label>Auto Assignable</Label>

              <Field
                name="autoAssignable"
                onChange={(e) => handleChange(e)}
                className="text-red-600 focus:border-red-500 ring-0 focus:ring-0 focus:ring-red-500 rounded ml-1.5"
                type="checkbox"
                disabled={
                  data.key === 'defaultPitchTemplate' && !isAgencySuperUser()
                    ? true
                    : false
                }
              />

              <ErrorMessage
                name="description"
                component="div"
                className="text-red-700 font-normal text-xs"
              />
            </div>
            <div className="flex justify-between">
              <button
                onClick={(e) => {
                  onCancel(e);
                }}
                className="mt-2"
                color="green"
              >
                Cancel
              </button>
              <Button
                onClick={() => console.log(values)}
                type="submit"
                classes="mt-2"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VariableForm;
