import { useMemo } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';

import TaskItem from './TaskItem/TaskItem';
import classNames from 'utils/classNames';

const TaskGroup = ({
  title = '',
  showEmptyState = false,
  columns = [
    {
      key: 'assignees',
      text: 'Assignees',
      className: 'col-span-6 md:col-span-3 lg:col-span-1',
    },
    {
      text: 'BS',
      key: 'list.client.brandStategist',
      className: 'col-span-3 md:col-span-3 lg:col-span-1',
    },
    {
      text: 'AS',
      key: 'list.client.accountSpecialist',
      className: 'col-span-3 md:col-span-3 lg:col-span-1',
    },
    {
      text: 'Due Date',
      key: 'dueDate',
      className: 'col-span-6 md:col-span-3 lg:col-span-2 xl:col-span-1',
    },
    {
      text: 'Priority',
      key: 'priority',
      className: 'col-span-6 md:col-span-6 lg:col-span-2 xl:col-span-1',
    },
    {
      text: 'Status',
      key: 'status',
      className: 'col-span-12 md:col-span-6 lg:col-span-2',
    },
  ],
  isLoading,
  itemsConfig = {},
  taskItemList = {},
  onLoadMore,
}) => {
  const hasMoreItems = useMemo(
    () => taskItemList?.count > taskItemList?.rows?.length,
    [taskItemList]
  );

  return (
    <div>
      <div
        className={classNames(
          taskItemList?.rows?.length ? 'block' : 'hidden',
          isLoading ? 'opacity-50' : ''
        )}
      >
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={classNames(
                  open ? '' : 'border-b border-grayscale-500',
                  'py-1.5 text-grayscale-900 grid grid-cols-12 gap-4 items-end'
                )}
              >
                <div
                  className={classNames(
                    'flex items-center space-x-2 col-span-12 lg:col-span-3 xl:col-span-5'
                  )}
                >
                  <div
                    className={classNames(
                      open ? 'rotate-90 transform' : '',
                      'cursor-pointer w-6 h-6 flex justify-center items-center border-2 border-grayscale-700 rounded-full'
                    )}
                  >
                    <ChevronRightIcon className="w-3 h-3 text-grayscale-700" />
                  </div>

                  <p className="flex items-center space-x-2">
                    <span className="tracking-3/4 font-inter font-bold leading-1.2">
                      {title}
                    </span>
                    <span className="text-11 leading-1.5 text-grayscale-700 font-normal tracking-none">
                      {taskItemList?.count}{' '}
                      {(taskItemList?.count ?? 0) > 1 ? 'Tasks' : 'Task'}
                    </span>
                  </p>
                </div>

                {open && (
                  <>
                    {columns.map((column) => (
                      <p
                        key={column.key}
                        className={classNames(
                          column.className,
                          'text-11 text-center leading-1.5 text-grayscale-700 font-normal'
                        )}
                      >
                        {column.text}
                      </p>
                    ))}
                  </>
                )}
              </Disclosure.Button>

              <Disclosure.Panel>
                <div className="bg-white">
                  {taskItemList?.rows?.map((taskItem) => (
                    <TaskItem
                      columns={columns}
                      key={taskItem.taskItemId}
                      taskItem={taskItem}
                      showClientName={itemsConfig.showClientName}
                      onClick={itemsConfig?.onClick}
                      onUpdate={itemsConfig?.onUpdate}
                    />
                  ))}
                </div>

                {hasMoreItems && (
                  <p
                    onClick={onLoadMore}
                    className="text-grayscale-700 tracking-2 font-inter text-center cursor-pointer mt-5"
                  >
                    {isLoading ? 'Loading...' : 'Load more tasks'}
                  </p>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      {showEmptyState && !isLoading && !!!taskItemList?.rows?.length && (
        <div className="flex justify-center my-16">
          <p className="text-2xl leading-1.2 font-inter text-grayscale-700 tracking-2">
            No tasks found.
          </p>
        </div>
      )}
    </div>
  );
};

export default TaskGroup;
