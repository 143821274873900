import { useRef } from 'react';
import { XIcon } from '@heroicons/react/outline';
import MailEditor from 'components/Editor/MailEditor';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';

const MailEditorModal = ({
  openModal,
  setOpenModal,
  onClose,
  refreshList,
  setOpenEditor,
  employees,
  primaryKey,
  postUrl,
  isExpandEmail,
}) => {
  const qRef = useRef();

  return (
    <Modal
      open={openModal}
      setOpen={setOpenModal}
      as={'div'}
      align="middle"
      noOverlayClick={true}
      persistent={true}
    >
      <div className="inline-block w-9/12 xl:w-3/5 h-80vh my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl">
        <ModalHeader
          title={'New Email'}
          setOpen={setOpenModal}
          titleClasses="capitalize"
          border="border"
          px="px-4"
          py="py-4"
          iconClassname="h-8 w-8"
          rightContent={
            <button className="relative group mr-4">
              <XIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 absolute bottom-0 right-0 bg-red-500 text-white rounded-xl leading-none" />
            </button>
          }
          onClose={onClose}
        />
        <MailEditor
          qRef={qRef}
          onChangeData={refreshList}
          setOpenEditor={setOpenEditor}
          employees={employees}
          primaryKey={primaryKey}
          postUrl={postUrl}
          setOpen={setOpenModal}
          open={openModal}
          isExpandEmail={isExpandEmail}
          setOpenModal={setOpenModal}
        />
      </div>
    </Modal>
  );
};
export default MailEditorModal;
