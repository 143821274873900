import axios from 'axios';
import { PlusIcon } from '@heroicons/react/outline';
import { useRef, useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import { S3_LIMIT_SIZE } from 'utils/constants';
import classNames from 'utils/classNames';
import { useDispatch } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';

const ProfileLogo = ({ agencyClientId, deletedAt }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [profileUri, setProfileUri] = useState(null);

  const fetchLogo = async () => {
    const response = await axios.get(
      `/agency/clients/${agencyClientId}/profile-picture`
    );

    if (response.data.success) {
      if (response.data.data) {
        setProfileUri(response.data.data);
      } else {
        setProfileUri(null);
      }
    } else {
      setProfileUri(null);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, [agencyClientId]);

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  // * Upload files to S3
  const uploadFile = async (files) => {
    const file = files[0];
    if (file.size <= S3_LIMIT_SIZE) {
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);

      const response = await axios.post(
        `/agency/clients/${agencyClientId}/profile-picture`,
        formData
      );

      if (response.data.success) {
        setProfileUri(response.data.data);
        dispatch(
          setAlert('success', 'Profile picture uploaded', response.data.message)
        );
      }
      setLoading(false);
    } else {
      dispatch(
        setAlert(
          'error',
          'Profile picture not uploaded',
          `File size is too large: 5MB limit`
        )
      );
    }
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    uploadFile(files);
  };

  return (
    <div className="relative h-full overflow-hidden">
      {profileUri && (
        <img
          src={profileUri}
          className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-0 pointer-events-none"
        />
      )}

      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="hidden"
        disabled={loading}
      />
      {!deletedAt && (
        <FileDrop
          onTargetClick={onTargetClick}
          onDrop={(files, event) => uploadFile(files)}
          className="w-full h-full group cursor-pointer z-10"
          targetClassName={classNames(
            'w-full h-full items-center justify-center',
            profileUri
              ? 'hidden group-hover:flex hover:text-red-700'
              : 'flex hover:text-red-500 border'
          )}
        >
          <span
            className={classNames(
              profileUri
                ? 'text-white bg-black bg-opacity-30 rounded-xl'
                : 'text-gray-500 hover:text-red-500',
              'z-20 w-full flex  items-center justify-center '
            )}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Upload
          </span>
        </FileDrop>
      )}
    </div>
  );
};
export default ProfileLogo;
