import { PlusIcon } from '@heroicons/react/outline';
import { usePopper } from 'react-popper';
import { Form, Formik, Field } from 'formik';
import { Popover, Transition } from '@headlessui/react';
import { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import classNames from 'utils/classNames';
import useComment from 'components/Editor/components/useComment';
import StatusDropdown from './TaskItem/StatusDropdown';
import PriorityDropdown from './TaskItem/PriorityDropdown';
import { Label } from 'components/Forms';
import useTasks from '../useTasks';
import { Button } from 'components';
import cleanify from 'utils/cleanify';
import TaskListPicker from './TaskListPicker';
import * as yup from 'yup';

const FloatingTaskForm = ({ selectedTaskList = {}, onSuccess }) => {
  const cancelButton = useRef();
  const { createTask } = useTasks();
  const { formats, toolbarOptions } = useComment();

  let [referenceElement, setReferenceElement] = useState();
  const [taskList, setTaskList] = useState(selectedTaskList);

  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    strategy: 'fixed',
  });

  const onSubmit = (values, actions) => {
    createTask(cleanify({ ...values, taskListId: taskList?.taskListId }))
      .then(() => {
        cancelButton.current.click();
        onSuccess();
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <div className="fixed bottom-8 right-8">
      <Popover className="relative">
        <Popover.Button
          as="div"
          ref={setReferenceElement}
          className="cursor-pointer border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 bg-secondary text-white flex items-center px-4 py-1.5 space-x-2 hover:bg-secondary-light hover:border-secondary-light"
        >
          <PlusIcon className="w-3 h-3" />
          <span>Add Task</span>
        </Popover.Button>

        <Transition
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            as="div"
            className="bg-white shadow-2xl mt-3 w-screen max-w-md p-4 rounded-2xl z-50 bs-shadow"
          >
            {({ close }) => (
              <div className="bg-white z-50 relative">
                <Formik
                  onSubmit={onSubmit}
                  initialValues={{
                    description: '',
                    name: '',
                    priority: null,
                    status: taskList?.statuses
                      ? taskList?.statuses[0].name
                      : [],
                  }}
                  validationSchema={yup.object().shape({
                    name: yup.string().required('Required'),
                    status: yup.string().required('Required'),
                  })}
                >
                  {({
                    values,
                    isSubmitting,
                    setFieldValue,
                    isValid,
                    dirty,
                    resetForm,
                  }) => (
                    <Form>
                      <h3 className="font-medium text-lg mb-5">Add new Task</h3>

                      <div className="space-y-4">
                        {!selectedTaskList?.taskListId && (
                          <div>
                            <TaskListPicker
                              onChange={(taskList) => {
                                setTaskList(taskList);

                                if (taskList) {
                                  setFieldValue(
                                    'status',
                                    taskList.statuses[0].name
                                  );
                                }
                              }}
                            />
                          </div>
                        )}

                        <div className="flex items-center space-x-2">
                          <StatusDropdown
                            compact={true}
                            value={values.status}
                            disabled={!!!taskList?.statuses}
                            options={taskList?.statuses ?? []}
                            onChange={(value) => setFieldValue('status', value)}
                            className="relative items-center flex"
                            itemsClassName="absolute right-0 mt-2 w-64 z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                          />

                          <Field
                            type="text"
                            name="name"
                            className="form-input"
                          />
                        </div>

                        <div>
                          <Label textSize="11" textColor="grayscale-700">
                            Task Description
                          </Label>

                          <div className="bg-grayscale-300 rounded-md">
                            <div
                              className={classNames('quill-text-editor py-2')}
                            >
                              <ReactQuill
                                theme="bubble"
                                placeholder="Description"
                                value={values.description}
                                onChange={(value) =>
                                  setFieldValue('description', value)
                                }
                                bounds={`.quill-text-editor`}
                                modules={{
                                  toolbar: toolbarOptions,
                                  clipboard: {
                                    matchVisual: false,
                                  },
                                  imageActions: {},
                                  imageFormats: {},
                                }}
                                formats={formats}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex">
                          <button
                            ref={cancelButton}
                            type="button"
                            onClick={() => {
                              resetForm();
                              close();
                            }}
                            className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                          >
                            Cancel
                          </button>

                          <Button
                            type="submit"
                            roundedSize={40}
                            showLoading={true}
                            loading={isSubmitting}
                            disabled={!(isValid && dirty)}
                            classes="px-8 py-2.5 text-sm leading-1.5 text-grayscale-300 font-bold leading-1.5 tracking-2  bg-secondary hover:bg-secondary-light disabled:bg-secondary-dark disabled:opacity-20"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
  return <div className="absolute bottom-5 right-5"></div>;
};

export default FloatingTaskForm;
