import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'features/alerts/alertsSlice';
import usePermissions from 'hooks/usePermissions';
import { PlusIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import DatepickerSingle from 'components/DatepickerSingle';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';

const UpsellsExporter = () => {
  const dispatch = useDispatch();
  const { userCan, isAgencySuperUser } = usePermissions();
  const [exporting, setExporting] = useState(false);

  const { upsellsPaginationParams } = useSelector((state) => state.upsells);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalHideBtn, setShowCalHideBtn] = useState(false);

  const calenderHandler = async () => {
    if (showCalHideBtn == true) {
      setLoading(true);
      setShowCalHideBtn(!showCalHideBtn);

      await convertCsv();
      setLoading(false);
    }
  };

  useEffect(() => {
    calenderHandler();
  }, [selectedDate]);

  const convertCsv = async () => {
    setExporting(true);
    const [month, day, year] = selectedDate.toLocaleDateString().split('/');
    let response;
    try {
      response = await axios.get(
        `/agency/upsells/export?status=${
          upsellsPaginationParams.status
        }&csvDate=${year + '-' + month + '-' + day}`
      );

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'upsells.csv';
      link.click();
    } catch (error) {
      dispatch(
        setAlert('error', 'No Upsell data found for the selected invoice date')
      );
    }

    setExporting(false);
  };

  return (
    <DropdownMenu
      title={
        isAgencySuperUser() && (
          <Button
            classes={'rounded-full'}
            border=" border-2 border-blue-900 tracking-widest font-bold py-2.5"
            hoverColor="blue-100"
            textColor="blue-900"
            bgColor="bg-white"
            onClick={() => setShowCalHideBtn(!showCalHideBtn)}
            showLoading={true}
            loading={exporting}
          >
            <PlusIcon className="h-4 w-4 mr-2" /> Export by Date
          </Button>
        )
      }
      titleClasses="flex items-center mr-1"
      buttonBg="bg-transparent"
      buttonFontWeight="font-normal"
      hoverClasses="bg-none"
      textColor="text-cyan-600"
      classes="text-sm  rounded-2xl"
      buttonRounded=""
      hoverText="hover:text-cyan-500"
      dropdownWidth="w-88"
      padding=""
      position="absolute"
      hideArrow
      zIndex=""
    >
      <div className="px-1 py-1 flex flex-col">
        <Menu.Item>
          {({ active }) => (
            <div className="flex flex-col items-center space-y-2">
              {' '}
              <DatepickerSingle
                value={selectedDate}
                onChange={setSelectedDate}
              />
            </div>
          )}
        </Menu.Item>
      </div>
    </DropdownMenu>
  );
};

export default UpsellsExporter;
