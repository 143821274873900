import { startCase } from 'lodash';
import { NavLink, useRouteMatch } from 'react-router-dom';

const NavigationTab = () => {
  const { url } = useRouteMatch();

  return (
    <div className="hidden sm:block mt-8">
      <div className="flex justify-between items-center border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {['non-branded', 'branded'].map((tab) => (
            <NavLink
              key={tab}
              to={`${url
                .replace('non-branded', '')
                .replace('branded', '')}${tab}`}
              className="flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              activeClassName="border-red-500 text-red-500 font-bold hover:border-red-500 hover:text-red-500"
            >
              {startCase(tab)} Keywords
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default NavigationTab;
