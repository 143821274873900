import axios from 'axios';
import moment from 'moment';

import { useRoles } from 'features/roles';
import { useEffect, useState } from 'react';
import { upperFirst, camelCase } from 'lodash';
import useClient from 'features/clients/useClient';
import ServiceTypeBreakdown from './ServiceTypeBreakdown';

const TeamServiceTypeBreakdown = ({ year }) => {
  const { SERVICES } = useClient();
  const { PROJECT_MANAGER } = useRoles();
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

      const summaryResponse = await axios.get(
        `/v2/agency/clients/analytics/service/summary`,
        { params: { startDate, endDate } }
      );

      const usersResponse = await axios.get('/v2/users', {
        params: {
          endDate,
          startDate,
          'role.code': PROJECT_MANAGER.value,
          attributes: [
            'userId',
            'firstName',
            'lastName',
            ...SERVICES.map((serviceType) => {
              let serviceKey = upperFirst(camelCase(serviceType.value));

              return `totalAssignedActiveClientsWith${serviceKey}Service`;
            }),
          ],
        },
      });

      const teams = summaryResponse.data.data.map((item) => {
        let obj = { ...item };

        let serviceKey = upperFirst(camelCase(item.service));
        const attribute = `totalAssignedActiveClientsWith${serviceKey}Service`;

        let assigned = 0;

        usersResponse.data.data.forEach((user) => {
          obj[`Team ${user.firstName} ${user.lastName}`] = user[attribute];
          assigned = assigned + user[attribute];
        });

        obj['Unassigned'] = item.count - assigned;

        return obj;
      });

      if (isSubscribed) {
        setTeams(teams);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  return (
    <ServiceTypeBreakdown
      loading={loading}
      teams={teams}
      colors={['#0013C7', '#002F5D', '#1879D8', '#82B0DE', '#D3E4F5']}
    />
  );
};

export default TeamServiceTypeBreakdown;
