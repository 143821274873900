import React from 'react';
import { range } from 'lodash';

const TaskGroupSkeleton = () => {
  return (
    <div>
      <div className="flex space-x-2 mb-2">
        <div className="bg-grayscale-500 h-10 w-10 animate-pulse rounded-full" />
        <div className="bg-grayscale-500 h-10 w-36 animate-pulse rounded-40" />
      </div>

      <div className="bg-white space-y-2">
        {range(1, 10).map((idx) => (
          <div
            key={`item-skeleton-${idx}`}
            className="grid grid-cols-12 gap-4 py-4 px-4 items-center"
          >
            <div className="w-full lg:col-span-3 xl:col-span-5 space-y-2">
              <div className="grid grid-cols-3">
                <div
                  style={{ height: '13px' }}
                  className="bg-grayscale-400 rounded-40 animate-pulse"
                />
              </div>
              <div
                style={{ height: '16px' }}
                className="bg-grayscale-400 rounded-40 animate-pulse"
              />
              <div
                style={{ height: '16px' }}
                className="bg-grayscale-400 rounded-40 animate-pulse"
              />
            </div>

            <div
              style={{ width: '40px', height: '40px' }}
              className="bg-grayscale-500 rounded-full animate-pulse "
            />

            <div
              style={{ width: '40px', height: '40px' }}
              className="bg-grayscale-500 rounded-full animate-pulse "
            />

            <div
              style={{ width: '40px', height: '40px' }}
              className="bg-grayscale-500 rounded-full animate-pulse "
            />

            <div className="w-full h-10 bg-grayscale-500 rounded-40 animate-pulse col-span-2 xl:col-span-1" />
            <div className="w-full h-10 bg-grayscale-500 rounded-40 animate-pulse col-span-2 xl:col-span-1" />
            <div className="w-full h-10 bg-grayscale-500 rounded-40 animate-pulse col-span-2" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskGroupSkeleton;
