import { SearchIcon } from '@heroicons/react/solid';
import InputPrepend from 'components/Forms/InputPrepend';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import Select from 'components/Forms/Select';

const BoardViewSearchField = ({ setSearchParams, dealOwners }) => {
  const timeFilters = [
    'Today',
    'Yesterday',
    'Tomorrow',
    'This Week',
    'This Week so Far',
    'Last Week',
    'Next Week',
    'This Month',
    'This Month so Far',
    'Last Month',
    'Next Month',
    'This Quarter',
    'This Fiscal Quarter',
    'This Quarter so Far',
    'This Fiscal Quarter so Far',
    'Last Quarter',
    'Last Fiscal Quarter',
    'Next Quarter',
    'Next Fiscal Quarter',
    'This Year',
    'This Fiscal Year',
    'This Year so Far',
    'This Fiscal Year so Far',
    'Last Year',
    'Last Fiscal Year',
    'Next Year',
    'Next Fiscal Year',
    'Last 7 Days',
    'Last 14 Days',
    'Last 30 Days',
    'Last 60 Days',
    'Last 90 Days',
    'Last 180 Days',
    'Last 365 Days',
  ];

  const onSearch = debounce(async (e) => {
    const search = e.target.value.toLowerCase();
    setSearchParams((prev) => ({
      ...prev,
      search,
    }));
  }, 500);

  const onDateSelect = debounce(async (e) => {
    let lastActivityDate,
      creationDate,
      closeDate = null;
    let dateRange = [];
    if (e.target.value === 'Today') {
      dateRange[0] = moment().startOf('day').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Yesterday') {
      dateRange[0] = moment()
        .add(-1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(-1, 'days')
        .endOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Tomorrow') {
      dateRange[0] = moment()
        .add(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'days')
        .endOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Week') {
      dateRange[0] = moment().startOf('week').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('week').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Week so Far') {
      dateRange[0] = moment().startOf('week').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Week') {
      dateRange[0] = moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .subtract(1, 'weeks')
        .endOf('week')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Week') {
      dateRange[0] = moment()
        .add(1, 'weeks')
        .startOf('week')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'weeks')
        .endOf('week')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Month') {
      dateRange[0] = moment().startOf('month').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('month').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Month so Far') {
      dateRange[0] = moment().startOf('month').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Month') {
      dateRange[0] = moment()
        .add(-1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(-1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Month') {
      dateRange[0] = moment()
        .add(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Quarter') {
      dateRange[0] = moment()
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('quarter').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Fiscal Quarter') {
      dateRange[0] = moment()
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('quarter').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Quarter so Far') {
      dateRange[0] = moment()
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Fiscal Quarter so Far') {
      dateRange[0] = moment()
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Quarter') {
      dateRange[0] = moment()
        .subtract(1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .subtract(1, 'quarters')
        .endOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Fiscal Quarter') {
      dateRange[0] = moment()
        .subtract(1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .subtract(1, 'quarters')
        .endOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Quarter') {
      dateRange[0] = moment()
        .add(1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'quarters')
        .endOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Fiscal Quarter') {
      dateRange[0] = moment()
        .add(1, 'quarters')
        .startOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'quarters')
        .endOf('quarter')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Year') {
      dateRange[0] = moment().startOf('year').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('year').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Fiscal Year') {
      dateRange[0] = moment().startOf('year').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('year').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Year so Far') {
      dateRange[0] = moment().startOf('year').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'This Fiscal Year so Far') {
      dateRange[0] = moment().startOf('year').format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Year') {
      dateRange[0] = moment()
        .subtract(1, 'years')
        .startOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .subtract(1, 'years')
        .endOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last Fiscal Year') {
      dateRange[0] = moment()
        .subtract(1, 'years')
        .startOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .subtract(1, 'years')
        .endOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Year') {
      dateRange[0] = moment()
        .add(1, 'years')
        .startOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'years')
        .endOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Next Fiscal Year') {
      dateRange[0] = moment()
        .add(1, 'years')
        .startOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment()
        .add(1, 'years')
        .endOf('year')
        .format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 7 Days') {
      dateRange[0] = moment()
        .subtract(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 14 Days') {
      dateRange[0] = moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 30 Days') {
      dateRange[0] = moment()
        .subtract(30, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 60 Days') {
      dateRange[0] = moment()
        .subtract(60, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 90 Days') {
      dateRange[0] = moment()
        .subtract(90, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 180 Days') {
      dateRange[0] = moment()
        .subtract(180, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    } else if (e.target.value === 'Last 365 Days') {
      dateRange[0] = moment()
        .subtract(360, 'days')
        .startOf('day')
        .format('YYYY-MM-DD, h:mm:ss a');
      dateRange[1] = moment().endOf('day').format('YYYY-MM-DD, h:mm:ss a');
    }
    if (e.target.id === 'lastActivityDate') {
      lastActivityDate = dateRange;
      setSearchParams((prev) => ({
        ...prev,
        lastActivityDate,
      }));
    } else if (e.target.id === 'closeDate') {
      closeDate = dateRange;
      setSearchParams((prev) => ({
        ...prev,
        closeDate,
      }));
    } else if (e.target.id === 'creationDate') {
      creationDate = dateRange;
      setSearchParams((prev) => ({
        ...prev,
        creationDate,
      }));
    }
  }, 500);

  const onOwnerSelect = (e) => {
    setSearchParams((prev) => ({
      ...prev,
      dealOwner: e.target.value,
    }));
  };

  return (
    <div className="grid grid-cols-5 gap-4 w-full">
      <InputPrepend
        name="search"
        onChange={(e) => onSearch(e)}
        type="text"
        placeholder={'Search'}
        prependText={<SearchIcon className="w-4 h-4" />}
        border="border-white"
      />
      <select
        id="dealOwner"
        label="Deal Owner"
        className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
        onChange={onOwnerSelect}
      >
        <option value="">Deal Owner</option>
        {!!dealOwners.length &&
          dealOwners.map((owner, i) => (
            <option key={i} value={owner.userId}>
              {owner.firstName + ' ' + owner.lastName}
            </option>
          ))}
      </select>
      <select
        id="creationDate"
        className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
        onChange={onDateSelect}
      >
        {' '}
        <option value="">Create Date</option>
        {timeFilters.map((time, i) => (
          <option key={i} value={time}>
            {time}
          </option>
        ))}
      </select>
      <select
        id="lastActivityDate"
        label="Last Activity Date"
        className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
        onChange={onDateSelect}
      >
        <option value="">Last Activity Date</option>

        {timeFilters.map((time, i) => (
          <option key={i} value={time}>
            {time}
          </option>
        ))}
      </select>
      <select
        id="closeDate"
        label="Close Date"
        className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
        onChange={onDateSelect}
      >
        <option value="">Close Date</option>
        {timeFilters.map((time, i) => (
          <option key={i} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BoardViewSearchField;
