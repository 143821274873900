import { isFunction, sortBy } from 'lodash';
import { Menu } from '@headlessui/react';

import DropdownMenu from 'components/DropdownMenu';
import classNames from 'utils/classNames';

const TaskStatusDropdown = ({
  id,
  value,
  setValue,
  compact = true,
  disabled = false,
  options = [],
}) => {
  const onChange = (status) => {
    isFunction(setValue) && setValue(status);
  };

  const Display = ({ title }) => {
    return compact ? (
      <div
        className="w-3 h-3 rounded-sm bg-gray-500"
        title={title}
        style={{
          backgroundColor: options.find((o) => o.name === value)?.color,
        }}
      ></div>
    ) : (
      <div
        title={title}
        className={classNames(
          'rounded-md uppercase px-4 py-1.5 text-base font-bold font-inter'
        )}
        style={{
          color: options.find((o) => o.name === value)?.color,
          backgroundColor: options.find((o) => o.name === value)?.bgColor,
        }}
      >
        {value}
      </div>
    );
  };

  return disabled ? (
    <Display title={value} />
  ) : (
    <DropdownMenu
      title={<Display title="click to update status" />}
      titleClasses="flex items-center"
      buttonBg="bg-transparent"
      buttonFontWeight="font-normal"
      buttonFocus=""
      buttonClasses={compact ? `border border-white p-0.5 rounded-sm` : ''}
      hoverClasses=""
      textColor="text-gray-600"
      classes="text-sm"
      buttonRounded=""
      hoverText={compact ? 'hover:text-red-500' : ''}
      dropdownWidth="w-40"
      padding=""
      position="sm:left-0"
      zIndex="z-30"
      hideArrow
    >
      <div className="flex flex-col">
        {sortBy(options, 'order').map((status) => (
          <Menu.Item key={`${id}-${status.name}`} disabled={disabled}>
            {({ active }) => (
              <button
                className="flex items-center space-x-2 w-full hover:bg-gray-200 py-2 px-5"
                onClick={() => onChange(status)}
              >
                <div
                  className="w-3 h-3 rounded-sm"
                  style={{ backgroundColor: status.color }}
                />
                <span className="capitalize">{status.name}</span>
              </button>
            )}
          </Menu.Item>
        ))}
      </div>
    </DropdownMenu>
  );
};

export default TaskStatusDropdown;
