import axios from 'axios';
import { ChevronUpIcon } from '@heroicons/react/outline';
import UsersPopoverMultiple from 'components/UsersPopoverMultiple';
import ButtonLink from 'components/ButtonLink';
import { isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import useComponentVisible from 'hooks/useComponentVisible';
import AddSubtaskIcon from 'components/icons/AddSubtaskIcon';
import TaskDueDateDropdown from './TaskDueDateDropdown';
import PriorityDropdown from './PriorityDropdown';
import { Link, useRouteMatch } from 'react-router-dom';
import NewTaskKanban from './NewTaskKanban';
import { useDrag } from 'react-dnd';

const TaskRowKanban = ({
  data,
  isSubtask = false,
  canDrag = false,
  space,
  notifyAssignee = false,
  showClient = false,
  assigneeOptions = [],
  permissions = {},
}) => {
  const { url } = useRouteMatch();
  const [expandSubTasks, setExpandSubTasks] = useState(true);
  const [addSubTask, setAddSubTask] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const {
    canAddDueDate = true,
    canAddAssignee = true,
    canAddPriority = true,
    canAddSubtasks = true,
    canUpdateDueDate = true,
    canUpdatePriority = true,
    canRemoveAssignee = true,
  } = permissions;

  const [row, setRow] = useState({
    taskItemId: 0,
    status: 'pending',
    name: '',
    priority: 'none',
    dueDate: '',
    subtasks: [],
    assignees: [],
  });

  useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  const onSubtaskAdded = (subtask) => {
    setAddSubTask(false);
    let subtasks = [...row.subtasks, subtask];
    setRow({ ...row, subtasks });
  };

  const onUpdateAssignee = async (user, action) => {
    axios({
      method: action === 'add' ? 'POST' : 'DELETE',
      url: `/agency/tasks/items/${row.taskItemId}/assignees/${user.userId}`,
      data: { notifyUser: notifyAssignee },
    }).then((response) => {
      setRow(response.data.data);
    });
  };

  const onChangePriority = (priority) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        priority: priority ? priority.status : null,
      })
      .then((response) => {
        setRow(response.data.data);
      });
  };

  const onChangeDueDate = (dueDate) => {
    axios
      .put(`/agency/tasks/items/${row.taskItemId}`, {
        dueDate,
      })
      .then((response) => {
        setRow({ ...response.data.data, list: row.list });
      });
  };

  const [, drag] = useDrag({
    type: 'card',
    canDrag,
    item: { data: row },
  });

  return (
    <>
      <div
        ref={isSubtask ? null : drag}
        className={classNames(
          isSubtask ? 'list-group-col-sub' : 'list-group-col',
          'text-gray-500 group p-4'
        )}
      >
        <div className="col-span-8 text-grayscale-800">
          {showClient &&
            (row.client ||
              row.list?.folder?.space?.salesClient?.lead?.companyName) && (
              <div className="text-11 leading-1.5 text-grayscale-700">
                {row.client ||
                  row.list?.folder?.space?.salesClient?.lead?.companyName}
              </div>
            )}
          <p
            className={classNames(isSubtask && 'text-sm', 'relative')}
            title={row.name}
          >
            <Link to={`${url}/${row.taskItemId}`}>{row.name}</Link>
          </p>
        </div>
        <div className="col-span-4 flex justify-end">
          <UsersPopoverMultiple
            users={assigneeOptions}
            position="left-1/2 transform -translate-x-1/2"
            onChange={onUpdateAssignee}
            selectedIds={row.assignees.map((user) => user.userId)}
            allowAdd={canAddAssignee}
            allowRemove={canRemoveAssignee}
          />
        </div>
        <div
          className={classNames(
            isSubtask ? 'flex justify-between col-span-4' : 'col-span-8',
            'mt-8'
          )}
        >
          <TaskDueDateDropdown
            value={row.dueDate}
            setValue={onChangeDueDate}
            position="left-0"
            configurable={canAddDueDate}
            editable={canUpdateDueDate}
          />
          {isSubtask && (
            <PriorityDropdown
              value={row.priority}
              setValue={onChangePriority}
              configurable={canAddPriority}
              editable={canUpdatePriority}
            />
          )}
        </div>
        <div className="col-span-4 mt-8 flex justify-end items-center">
          {!isSubtask && (
            <PriorityDropdown
              value={row.priority}
              setValue={onChangePriority}
              configurable={canAddPriority}
              editable={canUpdatePriority}
            />
          )}

          {!isSubtask && row.subtasks.length === 0 && canAddSubtasks && (
            <button
              className="add-subtask-icon hover:opacity-50 ml-6"
              onClick={() => setAddSubTask(true)}
              title="Add Subtask"
            >
              <AddSubtaskIcon width="30" height="30" />
            </button>
          )}
        </div>

        {!isSubtask && row.subtasks.length > 0 && canAddSubtasks && (
          <div
            className="col-span-12 flex items-center justify-between mt-8"
            ref={ref}
          >
            <ButtonLink
              color="gray"
              classes={row.subtasks.length ? 'visible' : 'invisible'}
              onClick={() => setExpandSubTasks(!expandSubTasks)}
            >
              <ChevronUpIcon
                className={classNames(
                  !expandSubTasks && 'transform rotate-180',
                  'w-6 h-6 inline mr-1'
                )}
              />
              Subtasks
            </ButtonLink>

            <button
              className="add-subtask-icon hover:opacity-50 ml-6 self-end"
              onClick={() => setAddSubTask(true)}
              title="Add Subtask"
            >
              <AddSubtaskIcon width="30" height="30" />
            </button>
          </div>
        )}
      </div>
      {!isSubtask && row.subtasks.length > 0 && (
        <>
          <div
            className={classNames(
              expandSubTasks ? 'block' : 'hidden',
              'list-group-col-subs divide-y'
            )}
          >
            {row.subtasks.map((subtask) => (
              <TaskRowKanban
                key={subtask.taskItemId}
                data={subtask}
                isSubtask={true}
                space={space}
                assigneeOptions={assigneeOptions}
              />
            ))}
          </div>
        </>
      )}
      {addSubTask && (
        <NewTaskKanban
          isSubtask={true}
          cancel={() => setAddSubTask(false)}
          taskListId={row.taskListId}
          parentTaskItemId={row.taskItemId}
          created={onSubtaskAdded}
          assigneeOptions={assigneeOptions}
        />
      )}

      {/* {openModal && (
        <TaskDetailsModal
          key="tdm"
          open={openModal}
          setOpen={setOpenModal}
          task={row}
          isSubtask={isSubtask}
          setTask={setRow}
          onChange={onChange}
        />
      )} */}
    </>
  );
};

export default TaskRowKanban;
