import axios from 'axios';
import { useState } from 'react';

import { PERMISSION_FEATURES } from './permission.constants';

const usePermissions = () => {
  const [permissions, setPermissions] = useState({ loading: true, data: [] });

  const fetchPermissions = async (params = {}) => {
    setPermissions({ loading: true, data: [] });

    const { data: response } = await axios.get('/v2/permissions', { params });

    setPermissions({ loading: false, data: response.data });

    return response.data;
  };

  const updatePermissions = async (roleId, permissions) => {
    return await axios.post(`/v2/roles/${roleId}/permissions`, { permissions });
  };

  const addPermission = async (roleId, permissionId) => {
    return await axios.post(`/v2/roles/${roleId}/permissions/${permissionId}`);
  };

  const removePermission = async (roleId, permissionId) => {
    return await axios.delete(
      `/v2/roles/${roleId}/permissions/${permissionId}`
    );
  };

  return {
    PERMISSION_FEATURES,

    permissions,
    addPermission,
    fetchPermissions,
    removePermission,
    updatePermissions,
  };
};

export default usePermissions;
