import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Toggle from 'components/Toggle';
import usePermissions from 'hooks/usePermissions';
import Button from 'components/Button';
import { PROPOSAL_CONTENT } from 'utils/constants';
import { Formik, Form, FieldArray } from 'formik';

const ContentBlock = ({ onSubmit }) => {
  const { client } = useSelector((state) => state.sales);
  const { userCan } = usePermissions();

  const initialValues = {
    data: client?.proposalContent ? client.proposalContent : PROPOSAL_CONTENT,
  };

  const onSubmitForm = (e, values) => {
    onSubmit(e, values.data);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="flex justify-between sticky top-0 w-full z-1 bg-white py-2">
            <h4 className="text-xl font-bold mt-2 text-black">
              Proposal Content
            </h4>
            ​
            {userCan('sales.profiles.quotes.manage') && (
              <Button
                roundedSize="3xl"
                classes="tracking-wider font-bold"
                px={7}
                py={2}
                textSize="sm"
                color="blue"
                type="button"
                onClick={(e) => onSubmitForm(e, values)}
              >
                Save/Update Proposal Content
              </Button>
            )}
          </div>
          <FieldArray name="data">
            {() =>
              values.data.map((el, i) => (
                <div className="quill-proposalblock-editor border-b mb-4 pb-8">
                  <div className="flex justify-between">
                    <div className="py-4">
                      <span className="text-base font-bold text-grayscale-900">
                        {el.label}
                      </span>
                    </div>
                    <div>
                      <label className="mt-4 flex items-center">
                        <span className="mr-3 text-sm text-grayscale-700 text-left">
                          Include Section
                        </span>
                        <Toggle
                          onChange={(e) => setFieldValue(`data.${i}.isShow`, e)}
                          checked={el.isShow}
                        />
                      </label>
                    </div>
                  </div>
                  {el.isShow && (
                    <ReactQuill
                      theme="snow"
                      value={el.value}
                      onChange={(e) => setFieldValue(`data.${i}.value`, e)}
                    />
                  )}
                </div>
              ))
            }
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default ContentBlock;
