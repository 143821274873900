import React from 'react';
import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string, boolean } from 'yup';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { setAlert } from 'features/alerts/alertsSlice';
import useQuery from 'hooks/useQuery';
import logo from 'assets/logos/si-logo.svg';
import FormikErrorNotification from 'components/FormikErrorNotification';
import Checkbox from 'components/Forms/Checkbox';
import moment from 'moment';
moment.tz.setDefault('America/Toronto');

const Survey = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isEmployee = query.get('as') === 'employee';
  const params = useParams();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { token } = params;
  const emptyInitialValues = {
    description: null,
    isRecommend: null,
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  useEffect(async () => {
    try {
      const response = await axios.get(
        `/agency/clients/survey/${token}?isEmployee=${isEmployee}`
      );

      if (response.data.success) {
        setIsTokenValid(true);
        if (!response.data.data.seenDate) {
          setSaving(true);
          await axios
            .put(`/agency/clients/survey/${token}?isEmployee=${isEmployee}`, {
              seenDate: new Date(Date.now()).toISOString(),
              status: 'seen',
            })
            .then((response) => {
              // dispatch(setAlert('success', 'Successfully Save'));
            })
            .catch((error) => {
              dispatch(setAlert('error', error.response.data.message));
            })
            .finally(() => {
              setSaving(false);
            });
        }
        setInitialValues({
          description: response.data.data.description,
          isRecommend: response.data.data.isRecommend,
        });
      }
    } catch (error) {
      setIsTokenValid(false);
      console.log('token is not valid', error);
    }
  }, []);

  const ReqAstirisk = () => <span className="text-red-500">*</span>;

  const onSubmit = async (values) => {
    setSaving(true);
    await axios
      .put(`/agency/clients/survey/${token}?isEmployee=${isEmployee}`, {
        ...values,
        status: 'completed',
      })
      .then((response) => {
        dispatch(setAlert('success', 'Successfully Save'));
        setIsDone(true);
      })
      .catch((error) => {
        dispatch(setAlert('error', error.response.data.message));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const onSelect = async (val, setFieldValue) => {
    setFieldValue('isRecommend', val);
  };

  const validationSchema = object().shape({
    isRecommend: boolean().required('Will Recommend is required'),
    description: string().required('Reason is required'),
  });

  return (
    <div className="font-sourceSansPro">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormikErrorNotification />

            {isDone && (
              <div class="flex h-screen p-4">
                <div class="m-auto">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                      <img
                        className="h-20"
                        src={logo}
                        alt="seller-interactive"
                      />
                    </div>
                    <p className="mt-8 text-2xl col-span-2">
                      Thank you for taking the time to complete this survey.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {isTokenValid && !isDone && (
              <div class="flex h-screen p-4">
                <div class="m-auto">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                      <img
                        className="h-20"
                        src={logo}
                        alt="seller-interactive"
                      />
                    </div>
                    <p className="mt-8 text-2xl col-span-2">
                      Would you recommend Seller Interactive? <ReqAstirisk />
                    </p>
                    <div className="col-span-1 border rounded-lg">
                      <div className="pl-4 flex items-center py-1.5">
                        <Checkbox
                          classes="border-red-500 mr-2 rounded"
                          name="isRecommend"
                          checked={values.isRecommend}
                          onChange={() => onSelect(true, setFieldValue)}
                        />

                        <p className="text-grayscale-700 text-xl">Yes</p>
                      </div>
                    </div>
                    <div className="col-span-1 border rounded-lg">
                      <div className="pl-4 flex items-center py-1.5">
                        <Checkbox
                          classes="border-red-500 mr-2 rounded"
                          name="isRecommend"
                          checked={values.isRecommend === false}
                          onChange={() => onSelect(false, setFieldValue)}
                        />

                        <p className="text-grayscale-700 text-xl">No</p>
                      </div>
                    </div>
                    <p className="mt-8 text-2xl col-span-2">
                      Why? Why not? <ReqAstirisk />
                    </p>
                    <div className="col-span-2 pt-3">
                      <Field
                        name="description"
                        as="textarea"
                        placeholder="Why? Why not?"
                        rows={5}
                        className="form-input disabled-white raw-editor"
                      />
                    </div>
                    <div className="col-span-1 pt-3">
                      <button
                        style={{ backgroundColor: '#FF004F' }}
                        type="submit"
                        className="text-md text-white rounded-md px-10 py-1.5 bg-hover:bg-red-700 border border-transparent inline-flex items-center focus:outline-none"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isTokenValid && !isDone && (
              <div className="container mx-auto px-4 py-10 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                  <p className="mt-8 text-2xl">Survey link not found!</p>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Survey;
