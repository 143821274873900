import React from 'react';
import classNames from 'utils/classNames';
import Input from './Input';

const InputAppend = ({
  id,
  name = '',
  type = 'text',
  value,
  onChange,
  required = false,
  disabled,
  classes,
  containerClasses,
  appendText,
  border = 'border-gray-300',
  rounded = 'rounded-md',
  shadow = '',
  appendClickable = 'pointer-events-none',
  ...rest
}) => {
  return (
    <div className={`relative rounded-md shadow-sm ${containerClasses ?? ''}`}>
      <Input
        type={type}
        {...(id && { id })}
        name={id ?? name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        border={border}
        rounded={rounded}
        shadow={shadow}
        classes={`pr-8 ${classes ?? ''}`}
        {...rest}
      />
      <div
        className={classNames(
          appendClickable,
          'absolute inset-y-0 right-0 pr-3 flex items-center'
        )}
      >
        <span className="text-gray-500 sm:text-sm">{appendText}</span>
      </div>
    </div>
  );
};

export default InputAppend;
