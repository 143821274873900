const ExternalLink = ({ detailedReportLink }) => {
  return detailedReportLink ? (
    <div className="bg-white flex justify-center text-center mx-8 px-8 py-8">
      <a
        href={detailedReportLink}
        target="_blank"
        className="bg-secondary text-tiny text-grayscale-300 tracking-2 font-bold py-2 px-8 rounded-3xl"
      >
        Detailed PPC Reporting
      </a>
    </div>
  ) : null;
};

export default ExternalLink;
