import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { upperFirst } from 'lodash';

import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const MonthlyKPIs = ({ data }) => {
  const kpis = [
    { key: 'totalSales', label: 'OVERALL SALES', formatter: currencyFormatter },
    { key: 'cost', label: 'PPC SPEND', formatter: currencyFormatter },
    { key: 'sales', label: 'PPC SALES', formatter: currencyFormatter },
    {
      key: 'organicSales',
      label: 'ORGANIC SALES',
      formatter: currencyFormatter,
    },
    {
      key: 'impressions',
      label: 'IMPRESSIONS',
      formatter: numberFormatter,
    },
    {
      key: 'clicks',
      label: 'CLICKS',
      formatter: numberFormatter,
    },
    {
      key: 'ctr',
      label: 'CTR',
      formatter: percentageFormatter,
    },
    {
      key: 'acos',
      label: 'ACOS',
      formatter: percentageFormatter,
    },
    {
      key: 'orders',
      label: 'PPC ORDERS',
      formatter: numberFormatter,
    },
    {
      key: 'cr',
      label: 'CVR',
      formatter: percentageFormatter,
    },
    {
      key: 'cpc',
      label: 'CPC',
      formatter: currencyFormatter,
    },
    {
      key: 'roas',
      label: 'ROAS',
      formatter: percentageFormatter,
    },
  ];

  return (
    <div className="grid grid-cols-4 gap-4 mx-8 -mt-12">
      {kpis.map((kpi) => {
        const currentValue = data[kpi.key];
        const previousValue = data[`previous${upperFirst(kpi.key)}`];
        let Icon = null;
        let percentage = 0;
        let infoColor = 'green';

        if (currentValue !== previousValue && previousValue) {
          Icon = currentValue > previousValue ? ChevronUpIcon : ChevronDownIcon;

          const lessRef =
            kpi.key === 'acos' ||
            kpi.key === 'cpc' ||
            kpi.key === 'cost' ||
            kpi.key === 'tacos'
              ? currentValue
              : previousValue;
          const greaterRef =
            kpi.key === 'acos' ||
            kpi.key === 'cpc' ||
            kpi.key === 'cost' ||
            kpi.key === 'tacos'
              ? previousValue
              : currentValue;

          infoColor = lessRef < greaterRef ? 'success' : 'error';
          percentage = (currentValue - previousValue) / Math.abs(previousValue);
        }

        return (
          <div
            key={kpi.key}
            className="flex flex-col bg-white w-full rounded-md py-3 text-center"
            style={{ boxShadow: '0px 20px 24px rgba(17, 17, 17, 0.06)' }}
          >
            <p className="mt-1 text-grayscale-800 text-8 tracking-wider leading-normal">
              {kpi.label}
            </p>

            <div className="flex justify-center items-center font-inter font-bold">
              {/* <p className="text-grayscale-600 mr-1 text-10">$</p> */}
              <p className="text-grayscale-900 tracking-3/4 text-lg">
                {kpi.formatter(data[kpi.key])}
              </p>
            </div>

            <div className="flex justify-center items-center">
              {Icon && <Icon className={`text-${infoColor} h-3 w-3`} />}
              <p className={`text-6 text-${infoColor} ml-1`}>
                {percentageFormatter(percentage)}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MonthlyKPIs;
