import useComponentVisible from 'hooks/useComponentVisible';
import { useEffect, useState } from 'react';
import classNames from 'utils/classNames';
import { PencilIcon } from '@heroicons/react/outline';

const EditableContent = ({
  value,
  onUpdate,
  textSize = 'text-sm',
  tag = 'input',
  classes = 'focus:ring-white focus:border-white border-0 w-full p-0 bg-transparent',
  borderIndicator = '',
  saveOnBlur = true,
  openOnEditMode = false,
  placeholderText = 'Enter More Information',
  textClasses = '',
  showEditIcon = false,
  editButton,
  disabled = false,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(openOnEditMode);
  const [local, setLocal] = useState(value);

  useEffect(() => {
    setLocal(value);
  }, [isComponentVisible]);

  useEffect(() => {
    setIsComponentVisible(openOnEditMode);
  }, [openOnEditMode]);

  const onEditTitle = (e) => {
    if (e.key === 'Enter' && tag === 'input') {
      console.log('save input');
      onUpdate(e);
      setIsComponentVisible(false);
    } else if (e.key === 'Enter' && e.ctrlKey && tag === 'textarea') {
      console.log('save textarea');
      onUpdate(e);
      setIsComponentVisible(false);
    } else if (e.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const syncHeight = (e) => {
    setLocal(e.target.value);
  };

  return (
    <div ref={ref}>
      {isComponentVisible && !disabled ? (
        <>
          <div className="inline-grid items-stretch w-full">
            <textarea
              defaultValue={value}
              className={classNames(
                textSize,
                classes,
                'w-full p-0 border-0 placeholder-gray-300'
              )}
              style={{ gridArea: '1 / 1 / 2 / 2' }}
              onChange={syncHeight}
              onKeyDown={onEditTitle}
              onBlur={
                saveOnBlur
                  ? (e) => {
                      onUpdate(e);
                    }
                  : () => {}
              }
              placeholder={placeholderText}
              autoFocus
            />
            <span
              className={classNames(textSize, 'invisible whitespace-pre-wrap')}
              style={{ gridArea: '1 / 1 / 2 / 2' }}
            >
              {local}
            </span>
          </div>
          {tag === 'textarea' && (
            <div className="text-right uppercase">
              <sub className="font-bold">
                press <span className="text-red-500">ctrl + enter</span> to{' '}
                <span className="text-red-500">save</span>
              </sub>
            </div>
          )}
        </>
      ) : (
        <div
          className={classNames(
            editButton ? 'flex-col' : '',
            'flex items-center'
          )}
          onClick={() => setIsComponentVisible(true)}
        >
          <div
            className={classNames(
              textSize,
              value && value !== '' ? '' : 'text-gray-300',
              borderIndicator,
              textClasses,
              'font-sourceSansPro whitespace-pre-wrap'
            )}
          >
            {value && value !== '' ? value : placeholderText}{' '}
          </div>
          {showEditIcon && (
            <PencilIcon className="ml-1 w-4 h-4 inline text-gray-500" />
          )}
          {editButton && editButton}
        </div>
      )}
    </div>
  );
};

export default EditableContent;
