import ModalHeader from 'components/ModalHeader';
import { Field, Form, useFormikContext, ErrorMessage } from 'formik';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import { LEMLIST_RESPONSE_TYPE } from 'features/leads/utils/constants';
import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';

export default function LemlistAcceptedModal({ setOpen, onUpdateLead }) {
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    if (values.lemlistReplySeq !== '' && values.lemlistResponseType !== '') {
      onUpdateLead(values);
      onClose();
    } else {
      dispatch(setAlert('error', 'Both must be selected.'));
    }
  };

  return (
    <>
      <div className="inline-flex flex-col justify-center h-screen">
        <div className="inline-block w-96 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={'Lemlist Accepted Form'}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={onClose}
          />
          <Form>
            <div className="px-8 pt-3 flex flex-row">
              <div className="w-full">
                <Label>Email Sequence</Label>
                <Field
                  name="lemlistReplySeq"
                  as="select"
                  className="form-select disabled-white"
                >
                  <option value="">Select Sequence</option>
                  {[1, 2, 3, 4, 5, 6, 7, 'Unknown'].map((s) => {
                    return (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    );
                  })}
                </Field>

                <ErrorMessage
                  name="lemlistReplySeq"
                  component="div"
                  className="text-red-700 font-normal text-xs px-8"
                />
              </div>
            </div>
            <div className="px-8 pt-3 flex flex-row">
              <div className="w-full">
                <Label>Response type</Label>
                <Field
                  name="lemlistResponseType"
                  as="select"
                  className="form-select disabled-white"
                >
                  <option value="">Select response type</option>
                  {LEMLIST_RESPONSE_TYPE.map((s) => {
                    return (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    );
                  })}
                </Field>

                <ErrorMessage
                  name="lemlistResponseType"
                  component="div"
                  className="text-red-700 font-normal text-xs px-8"
                />
              </div>
            </div>

            <div className="flex justify-between px-12 py-8">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                className=" text-custom-sky font-bold text-sm"
                color="green"
              >
                Cancel
              </button>

              <Button
                classes="border-0 font-bold tracking-widest"
                bgColor="blue-900"
                hoverColor="blue-500"
                roundedSize="full"
                textColor="white"
                px={8}
                py={1}
                shadow=""
                textSize=""
                showLoading={true}
                onClick={() => onSubmit(values)}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
