import {
  Line,
  XAxis,
  YAxis,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { numberFormatter } from 'utils/formatters';
import moment from 'moment';
import axios from 'axios';
import ChartToolTip from 'components/Charts/ChartTooltip';
import { useEffect, useState } from 'react';

const MonthlyCount = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(moment().year().toString());

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/clients/analytics/records', {
        params: {
          endDate: moment(year).endOf('year').format('YYYY-MM-DD'),
          startDate: moment(year).startOf('year').format('YYYY-MM-DD'),
          withPrevious: true,
        },
      });

      if (isSubscribed) {
        setRecords(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  return (
    <div className="bg-white p-4">
      <div className="pb-5 pt-2 flex items-center justify-between">
        <p className="font-inter font-bold tracking-3/4 text-grayscale-900">
          Monthly Count
        </p>

        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="text-13 text-grayscale-800 bg-grayscale-400 leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
        >
          <option value={(moment().year() - 1).toString()}>
            {(moment().year() - 1).toString()}
          </option>
          <option value={moment().year().toString()}>
            {moment().year().toString()}
          </option>
        </select>
      </div>

      <div className="h-64">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="startDate"
                style={{ fontSize: '11px', color: '#202020' }}
                tickFormatter={(date) => moment(date).format('MMM')}
              />

              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
              />

              <Tooltip
                content={
                  <ChartToolTip
                    Title={({ payload }) => (
                      <p className="text-13 font-bold leading-1.5 text-grayscale-900">
                        {moment(payload[0].payload.startDate).format('YYYY')}
                      </p>
                    )}
                    CustomItemTitle={({ item }) => (
                      <p className="text-13 leading-1.5 text-grayscale-900">
                        {moment(item.payload.startDate).format('MMM')}
                      </p>
                    )}
                  />
                }
              />

              <Line
                yAxisId="0"
                fill="#3BD7AD"
                strokeWidth={3}
                dataKey={'newClients'}
                tickFormatter={(value) => numberFormatter(value)}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default MonthlyCount;
