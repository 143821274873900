import axios from 'axios';
import { orderBy, startCase } from 'lodash';
import { useEffect, useState } from 'react';

import classNames from 'utils/classNames';
import metrics from '../../utils/metrics';

const attributes = ['sales', 'roas', 'cost', 'orders', 'impressions', 'cpcon'];

const TopKeywordTracking = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [keywords, setKeywords] = useState({ rows: [] });
  const [selectedType, setSelectedType] = useState('branded');
  const [sortByAttribute, setSortByAttribute] = useState('sales');
  const [sortBy, setSortBy] = useState('desc');

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        `/account/advertising/${selectedType}-keywords`,
        {
          params: {
            pageSize: 5,
            endDate,
            startDate,
            accountId,
            marketplace,
            ...additionalParams,
            attributes: ['id', 'keywordText', ...attributes],
          },
        }
      );

      if (isSubscribed) {
        setKeywords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    accountId,
    marketplace,
    startDate,
    endDate,
    additionalParams,
    selectedType,
  ]);

  return (
    !!keywords.rows.length && (
      <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
        <div className="flex items-center justify-between border-b px-8 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
            Top 5 branded and non-branded keywords with most sales
          </p>
        </div>

        <div className="px-8 mt-8">
          <div className="flex justify-between">
            <p className="font-inter tracking-3/4 text-grayscale-800 leading-1.2 text-xl font-bold">
              Top 5 {selectedType} keywords with most sales
            </p>
            <div className="flex font-sourceSansPro items-center">
              <p className="text-mini leading-1.5 text-grayscale-900 mr-3">
                Sort by
              </p>
              <div className="relative z-0 inline-flex rounded-md">
                {['branded', 'non-branded'].map((type, index) => {
                  return (
                    <button
                      key={`keyword-tracking-button-${type}`}
                      type="button"
                      onClick={() => setSelectedType(type)}
                      className={classNames(
                        index ? 'rounded-r-md' : 'rounded-l-md',
                        selectedType === type
                          ? 'bg-grayscale-800 text-white'
                          : 'bg-white text-grayscale-800',
                        'tracking-2 font-bold relative inline-flex items-center py-2 px-8 border-2 border-grayscale-800 text-tiny'
                      )}
                    >
                      {startCase(type)}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-full">
            <table className="mt-5 divide-y divide-grayscale-500 w-full table-fixed font-sourceSansPro">
              <thead className="bg-success">
                <tr>
                  <th className="p-3 text-mini font-bold text-white border border-grayscale-500 text-left">
                    <div className="flex justify-between items-center whitespace-nowrap">
                      Keywords
                      <div
                        className="ml-2 cursor-pointer"
                        style={{ fontSize: '8px' }}
                        onClick={() => {
                          setSortByAttribute('keywordText');
                          setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
                        }}
                      >
                        <div
                          className={
                            sortBy === 'asc' &&
                            sortByAttribute === 'keywordText'
                              ? 'text-grayscale-600'
                              : ''
                          }
                        >
                          ▲
                        </div>
                        <div
                          className={
                            sortBy === 'desc' &&
                            sortByAttribute === 'keywordText'
                              ? 'text-grayscale-600'
                              : ''
                          }
                        >
                          ▼
                        </div>
                      </div>
                    </div>
                  </th>

                  {attributes
                    .map((attr) => metrics.find((m) => m.key === attr))
                    .map((metric) => (
                      <th
                        key={`top-keyword-tracking-header-${metric.key}`}
                        className="p-3 text-mini font-bold text-white border border-grayscale-500 text-right"
                      >
                        <div className="flex justify-between items-center whitespace-nowrap">
                          {metric.title}
                          <div
                            className="ml-2 cursor-pointer"
                            style={{ fontSize: '8px' }}
                            onClick={() => {
                              setSortByAttribute(metric.key);
                              setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
                            }}
                          >
                            <div
                              className={
                                sortBy === 'asc' &&
                                sortByAttribute === metric.key
                                  ? 'text-grayscale-600'
                                  : ''
                              }
                            >
                              ▲
                            </div>
                            <div
                              className={
                                sortBy === 'desc' &&
                                sortByAttribute === metric.key
                                  ? 'text-grayscale-600'
                                  : ''
                              }
                            >
                              ▼
                            </div>
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>

              <tbody className="">
                {keywords.rows.length ? (
                  orderBy(keywords.rows, [sortByAttribute], [sortBy]).map(
                    (record, rowIdx) => {
                      return (
                        <tr
                          key={`top-keyword-tracking-rows-${record.id}`}
                          className={rowIdx % 2 === 1 ? 'bg-grayscale-400' : ''}
                        >
                          <td className="p-3 text-mini text-grayscale-800 border border-grayscale-500 text-left">
                            {record.keywordText}
                          </td>
                          {attributes
                            .map((attr) => metrics.find((m) => m.key === attr))
                            .map((metric) => (
                              <td
                                key={`top-keyword-tracking-rows-${record.id}-${metric.key}`}
                                className="p-3 text-mini text-grayscale-800 border border-grayscale-500 text-right"
                              >
                                {metric.rawFormatter(record[metric.key])}
                              </td>
                            ))}
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="p-2 text-xs text-gray-500 sm:pr-6 xs:pr-8 border border-gray-200 text-center"
                    >
                      No records to display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
};

export default TopKeywordTracking;
