import { useRef } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';
import { FileDrop } from 'react-file-drop';

const MailAttachment = ({ onAttached }) => {
  const fileInputRef = useRef(null);

  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    uploadFiles(event.target.files);
  };

  const uploadFiles = (files) => {
    if (fileInputRef.current.files.length <= 0) {
      fileInputRef.current.files = files;
    }
    onAttached(fileInputRef.current.files);
  };

  // * Triggered when the button for adding files is clicked
  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex flex-col justify-center relative -top-1">
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="hidden"
        multiple
      />
      <FileDrop onTargetClick={onTargetClick}>
        <PaperClipIcon className="w-5 h-5 inline text-gray-500 hover:text-green-600" />
      </FileDrop>
    </div>
  );
};

export default MailAttachment;
