import axios from 'axios';
import { useEffect, useState } from 'react';
import { percentageFormatter } from 'utils/formatters';
import SectionComment from '../components/SectionComment';

const ActiveCampaignsOverview = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
  editableComment = true,
  comment = '',
  onChangeComment,
}) => {
  const [overview, setOverview] = useState({
    total: {},
    sp: {},
    sb: {},
    sd: {},
    auto: {},
    manual: {},
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        '/account/advertising/analytics/active-campaigns',
        {
          params: {
            accountId,
            marketplace,
            startDate,
            endDate,
            ...additionalParams,
          },
        }
      );

      if (isSubscribed) {
        setOverview(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    <div className="relative">
      <SectionComment
        value={comment}
        onChangeValue={onChangeComment}
        editable={editableComment}
      />

      <div
        style={{ backgroundColor: '#EF4444' }}
        className={`${className} rounded-3xl text-white py-5 px-10 grid grid-cols-11`}
      >
        <div className="flex items-center col-span-3">
          <p
            style={{ fontSize: '56px' }}
            className="font-inter leading-1.5 tracking-3/4 mr-2"
          >
            {overview.total.count}
          </p>
          <p className="font-inter leading-1.2 text-xl tracking-3/4">
            Active Campaigns
          </p>
        </div>

        <div className="col-span-4 text-mini leading-normal text-white w-full border-t lg:border-t-0 border-b lg:border-b-0 py-4 lg:py-0 lg:border-l lg:border-r lg:px-4 mb-4 lg:mb-0">
          <table className="w-full">
            <thead className="font-bold">
              <tr className="">
                <td width="30%" className="pb-2 lg:text-center">
                  Type
                </td>
                <td width="30%" className="pb-2 lg:text-center">
                  # of campaigns
                </td>
                <td width="30%" className="pb-2 lg:text-center">
                  Sales %
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="30%" className="lg:text-center">
                  SP
                </td>
                <td width="30%" className="lg:text-center">
                  {overview.sp.count}
                </td>
                <td width="30%" className="lg:text-center">
                  {percentageFormatter(
                    overview.total.sales
                      ? overview.sp.sales / overview.total.sales
                      : 0
                  )}
                </td>
              </tr>
              <tr>
                <td width="30%" className="lg:text-center">
                  SB
                </td>
                <td width="30%" className="lg:text-center">
                  {overview.sb.count}
                </td>
                <td width="30%" className="lg:text-center">
                  {percentageFormatter(
                    overview.total.sales
                      ? overview.sb.sales / overview.total.sales
                      : 0
                  )}
                </td>
              </tr>
              <tr>
                <td width="30%" className="lg:text-center">
                  SD
                </td>
                <td width="30%" className="lg:text-center">
                  {overview.sd.count}
                </td>
                <td width="30%" className="lg:text-center">
                  {percentageFormatter(
                    overview.total.sales
                      ? overview.sd.sales / overview.total.sales
                      : 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-mini leading-normal text-white w-full lg:px-4 col-span-4">
          <table className="w-full">
            <thead className="font-bold">
              <tr>
                <td width="30%" className="pb-2 lg:text-center">
                  Targeting Type
                </td>
                <td width="30%" className="pb-2 lg:text-center">
                  # of campaigns
                </td>
                <td width="30%" className="pb-2 lg:text-center">
                  Sales %
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="30%" className="lg:text-center">
                  Automatic
                </td>
                <td width="30%" className="lg:text-center">
                  {overview.auto.count}
                </td>
                <td width="30%" className="lg:text-center">
                  {percentageFormatter(
                    overview.total.sales
                      ? overview.auto.sales / overview.total.sales
                      : 0
                  )}
                </td>
              </tr>
              <tr>
                <td width="30%" className="lg:text-center">
                  Manual
                </td>
                <td width="30%" className="lg:text-center">
                  {overview.manual.count}
                </td>
                <td width="30%" className="lg:text-center">
                  {percentageFormatter(
                    overview.total.sales
                      ? overview.manual.sales / overview.total.sales
                      : 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActiveCampaignsOverview;
