import axios from 'axios';
import { Route, Switch, useHistory } from 'react-router-dom';
import Overview from './Overview';
import Invoice from './Invoice/Invoice';
import { useEffect } from 'react';

function InvoiceNumberRedirectRoute({ component: Component, ...rest }) {
  const history = useHistory();
  useEffect(() => {
    async function getInvoice() {
      try {
        const response = await axios.get(
          `/agency/invoices/no/${rest.computedMatch.params.number}`
        );
        history.replace(`/invoices/${response.data.data.invoiceId}/`);
      } catch (error) {
        history.replace('/not-found');
      }
    }

    getInvoice();
  }, [rest]);

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
}

const Invoices = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}`} component={Overview} exact />
      <InvoiceNumberRedirectRoute
        path={`${path}/no/:number`}
        component={Invoice}
      />
      <Route path={`${path}/:invoiceId`} component={Invoice} />
    </Switch>
  );
};
export default Invoices;
