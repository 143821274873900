import { keys, omit } from 'lodash';

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Brush,
} from 'recharts';

const renderCustomizedLabel = (props) => {
  return props.value > 0 ? (
    <text
      {...omit(props, ['content', 'textBreakAll', 'parentViewBox'])}
      textAnchor="middle"
      className="recharts-text recharts-label"
    >
      <tspan
        {...omit(props, ['content', 'textBreakAll', 'parentViewBox'])}
        textAnchor="middle"
        x={props.x + props.width / 2}
      >
        {props.value}
      </tspan>
    </text>
  ) : null;
};

const ServiceTypeBreakdown = ({ teams, loading, colors }) => {
  return (
    <div className="bg-white p-6">
      <h3 className="leading-1.2 font-bold tracking-3/4 font-inter text-grayscale-900">
        Total Client Count per Services
      </h3>

      <div className="h-72 mt-8">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-5 h-full space-x-8">
            <div className="col-span-4 w-full">
              <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
                <BarChart data={teams} margin={{ top: 20 }}>
                  <CartesianGrid stroke="#ECECEC" strokeDasharray="5 5" />
                  <XAxis
                    dataKey="service"
                    style={{ fontSize: '11px', color: '#202020' }}
                  />

                  <YAxis
                    style={{ fontSize: '11px' }}
                    axisLine={{ stroke: '#ECECEC' }}
                  />

                  {!!teams.length &&
                    keys(teams[0])
                      .filter((key) => key !== 'service' && key !== 'count')
                      .map((key, idx) => {
                        console.log(key);
                        return (
                          <Bar
                            key={key}
                            dataKey={key}
                            stackId={teams[0]['service']}
                            name={key}
                            barSize={50}
                            fill={colors[idx]}
                          >
                            <LabelList
                              dataKey={key}
                              position="middle"
                              fill="white"
                              fontSize="13px"
                              content={(props) =>
                                renderCustomizedLabel({
                                  ...props,
                                  y: props.y + props.height / 2,
                                })
                              }
                            />

                            <LabelList
                              dataKey="count"
                              position="top"
                              fill="#202020"
                              fontSize="16px"
                              fontWeight="700"
                              content={(props) =>
                                renderCustomizedLabel({
                                  ...props,
                                  dy: -10,
                                })
                              }
                            />
                          </Bar>
                        );
                      })}

                  <Brush
                    dataKey="service"
                    data={teams}
                    height={15}
                    endIndex={5}
                    className="text-8"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <ul className="flex flex-col justify-center">
              {teams.length &&
                keys(teams[0])
                  .filter((key) => key !== 'service' && key !== 'count')
                  .map((key, idx) => (
                    <li key={key} className="flex items-center space-x-2 mb-2">
                      <div
                        className="w-2 h-2"
                        style={{ backgroundColor: colors[idx] }}
                      />
                      <p className="w-full text-grayscale-900 text-11 leading-1.5 flex items-center justify-between">
                        {key}
                      </p>
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceTypeBreakdown;
