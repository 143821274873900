import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { getRulesAsync, selectRules } from '../advertisingSlice';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';

import {
  setItemList,
  setReport,
  selectReport,
  selectItemList,
  setItemOption,
} from './optimizationSlice';

import MultipleFilter from 'components/Forms/MultipleFilter';
import ColumnPicker from '../components/common/ColumnPicker';
import Select from 'components/Forms/Select';

import OptimizationReportItemOptions from './components/OptimizationReportItemOptions';
import OptimizationReportItems from './components/OptimizationReportItems';
import GenerateReportButton from './components/GenerateReportButton';
import PreviousOptimization from './components/PreviousOptimization';
import ProceedButton from './components/ProceedButton';

import { listBaseColumns, metricColumns } from '../utils/columns';
import { CAMPAIGNS, KEYWORDS, SEARCH_TERMS } from '../utils/constants';

import classNames from 'utils/classNames';
import { optimizeBid } from '../utils/optimization';

const acceptedCodeToBulkUpdate = [
  'SP:KEYWORDS:UPDATE_BID',
  'SP:KEYWORDS:UPDATE_STATUS',
  'SP:CAMPAIGNS:UPDATE_STATUS',
  'SP:CAMPAIGNS:UPDATE_DAILY_BUDGET',
  'SP:SEARCH_TERMS:CONVERT_AS_NEGATIVE_KEYWORD',
];

const Optimizations = ({ accountId, marketplace, campaignType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const rules = useSelector(selectRules);
  const report = useSelector(selectReport);
  const itemList = useSelector(selectItemList);
  const selectedDates = useSelector(selectCurrentDateRange);

  const [selectingAll, setSelectingAll] = useState(false);
  const [recordType, setRecordType] = useState(SEARCH_TERMS);
  const [selectedId, setSelectedId] = useState('');
  const [showPrevOptimization, setShowPrevOptimization] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(
    localStorage.getItem('optimizations-column') ??
      'advCampaignId,values.name,values.keywordText,values.bid,values.query,values.source,advSearchTermId,cost,sales,orders'
  );

  const [selectedRules, setSelectedRules] = useState({ rules: [] });

  const columns = [
    {
      dataField: 'advOptimizationReportItemId',
      text: '',
      formatter: (cell) => {
        const viewPreviousOptimization = () => {
          setSelectedId(cell);
          setShowPrevOptimization(true);
        };
        return (
          <InformationCircleIcon
            className="h-5 w-5 cursor-pointer"
            onClick={viewPreviousOptimization}
          />
        );
      },
    },
    ...listBaseColumns(accountId, marketplace, campaignType, recordType, t),
    ...metricColumns(),
    {
      dataField: 'options',
      auto: true,
      default: true,
      text: 'Options',
      headerStyle: { minWidth: '300px' },
      formatter: (cell, row) => (
        <OptimizationReportItemOptions
          accountId={accountId}
          marketplace={marketplace}
          options={cell}
          item={row}
        />
      ),
    },
  ];

  const onChangeRecordType = (e) => {
    dispatch(setReport(null));
    dispatch(setItemList({ rows: [] }));
    setRecordType(e.target.value);
  };

  useEffect(() => {
    dispatch(setReport(null));
    dispatch(setItemList({ rows: [] }));

    return function cleanUp() {
      dispatch(setReport(null));
      dispatch(setItemList({ rows: [] }));
    };
  }, [dispatch]);

  useEffect(() => {
    setSelectedRules({ rules: [] });
    dispatch(setReport(null));
    dispatch(setItemList({ rows: [] }));

    dispatch(
      getRulesAsync({
        recordType,
        campaignType,
        accountId,
        marketplace,
        pageSize: 1000,
      })
    );
  }, [dispatch, recordType, campaignType, accountId, marketplace]);

  useEffect(() => {
    dispatch(setReport(null));
    dispatch(setItemList({ rows: [] }));
  }, [dispatch, selectedRules, selectedDates]);

  const onChangeVisibleColumns = (newColumns) => {
    localStorage.setItem('optimizations-column', newColumns);
    setVisibleColumns(newColumns);
  };

  const onSelectAll = () => {
    setSelectingAll(true);
    const options = itemList.rows.map((item) => {
      let data = {};

      if (item.options[0].rule.action.code === 'SP:KEYWORDS:UPDATE_BID') {
        data.bid = optimizeBid(
          item.values.bid,
          item.options[0].rule.actionData
        );
      }

      if (
        item.options[0].rule.action.code === 'SP:CAMPAIGNS:UPDATE_DAILY_BUDGET'
      ) {
        data.dailyBudget = optimizeBid(
          item.values.dailyBudget,
          item.options[0].rule.actionData
        );
      }

      return {
        advOptimizationReportItemOptionId:
          item.options[0].advOptimizationReportItemOptionId,
        selected: true,
        data,
      };
    });

    axios
      .put(
        `/account/advertising/optimizations/reports/${report.advOptimizationReportId}/items`,
        { accountId, marketplace, options }
      )
      .then((response) => {
        const { data } = response.data;
        data.forEach((option) => {
          dispatch(setItemOption(option));
        });
        setSelectingAll(false);
      });
  };

  return (
    <div className="my-4">
      <PreviousOptimization
        accountId={accountId}
        marketplace={marketplace}
        campaignType={campaignType}
        open={showPrevOptimization}
        setOpen={setShowPrevOptimization}
        recordType={recordType}
        optimizableId={selectedId}
      />

      <div className="grid grid-cols-1 lg:grid-cols-6 gap-2 mb-4">
        <Select
          value={recordType}
          onChange={onChangeRecordType}
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal"
        >
          <option value={CAMPAIGNS}>Campaigns</option>
          <option value={KEYWORDS}>Keywords</option>
          <option value={SEARCH_TERMS}>Search Terms</option>
        </Select>
        <MultipleFilter
          selected={selectedRules}
          setSelected={(filters) => setSelectedRules(filters)}
          showLabel={false}
          buttonClass="inline-flex justify-center w-full rounded-2xl border-0 shadow-xs px-4 py-4 bg-white text-mini text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none"
          menuClass="overflow-y-scroll max-h-64 break-all overflow-x-hidden"
          noSelectedPreview={t('Advertising.Optimization.NoSelected')}
          hasSelectedPreview={t('Advertising.Optimization.HasSelected')}
          options={{
            rules:
              'rows' in rules
                ? rules.rows.map((rule) => {
                    return { value: rule.advRuleId, display: rule.name };
                  })
                : [],
          }}
        />

        <ColumnPicker
          options={columns
            .map((col) => {
              return {
                key: col.dataField,
                hideable: !!col.hideable,
                display: col.display ?? col.text,
                category: col.category,
                default: !!col.default,
              };
            })
            .filter(
              (col) =>
                col.key !== 'advOptimizationReportItemId' &&
                col.key !== 'options'
            )}
          values={visibleColumns}
          onChange={onChangeVisibleColumns}
        />

        <button
          type="submit"
          onClick={onSelectAll}
          disabled={
            selectingAll ||
            !report ||
            itemList.rows.length === 0 ||
            itemList.rows.some((item) => item.options.length !== 1) ||
            itemList.rows.every((item) =>
              item.options.some(
                (opt) =>
                  !acceptedCodeToBulkUpdate.includes(opt.rule.action.code)
              )
            )
          }
          className={classNames(
            'flex items-center disabled:opacity-95 justify-center w-full rounded-2xl border border-gray-300 shadow-sm px-4 py-2 bg-secondary text-sm font-medium text-grayscale-300 tracking-2 font-bold focus:outline-none',
            selectingAll ||
              !report ||
              itemList.rows.length === 0 ||
              itemList.rows.some((item) => item.options.length !== 1) ||
              itemList.rows.every((item) =>
                item.options.some(
                  (opt) =>
                    !acceptedCodeToBulkUpdate.includes(opt.rule.action.code)
                )
              )
              ? 'opacity-95 cursor-not-allowed'
              : 'hover:bg-secondary-light'
          )}
        >
          Select All
        </button>

        <GenerateReportButton
          accountId={accountId}
          marketplace={marketplace}
          campaignType={campaignType}
          recordType={recordType}
          ruleIds={selectedRules.rules}
        />
        <ProceedButton accountId={accountId} marketplace={marketplace} />
      </div>

      <OptimizationReportItems
        accountId={accountId}
        marketplace={marketplace}
        columns={columns
          .filter(
            (col) => visibleColumns.includes(col.dataField) || col.default
          )
          .map((column) => {
            return {
              ...column,
              classes: `${column.classes} py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
              headerClasses: `${column.headerClasses} py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
            };
          })}
      />
    </div>
  );
};

export default Optimizations;
