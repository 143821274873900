import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAlert } from 'features/alerts/alertsSlice';
import { reverse } from 'lodash';

export const fetchInvoice = createAsyncThunk(
  'invoice/getInvoice',
  async ({ invoiceId, force = false }, thunkAPI) => {
    try {
      const response = await axios.get(
        `/agency/invoices/${invoiceId}${force ? '?force=true' : ''}`
      );
      return response.data.data.invoice;
    } catch (err) {
      thunkAPI.dispatch(
        setAlert('error', 'Fetch invoice failed', err.response.data.message)
      );
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchInvoiceErrors = createAsyncThunk(
  'invoice/getInvoiceErrors',
  async (invoiceId, thunkAPI) => {
    try {
      const response = await axios.get(`/agency/invoices/${invoiceId}/errors`);
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        setAlert(
          'error',
          'Fetch invoice commission errors failed',
          err.response.data.message
        )
      );
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchUpdates = createAsyncThunk(
  'invoice/updates',
  async ({ params, invoiceId }, thunkAPI) => {
    const response = await axios.get(`/agency/invoices/${invoiceId}/updates`, {
      params,
    });
    return response.data.data;
  }
);

export const fetchOlderUpdates = createAsyncThunk(
  'invoice/olderUpdates',
  async ({ params, invoiceId }, thunkAPI) => {
    const response = await axios.get(`/agency/invoices/${invoiceId}/updates`, {
      params,
    });
    return response.data.data;
  }
);

export const getAttachments = createAsyncThunk(
  'invoice/attachments',
  async (params, thunkAPI) => {
    const response = await axios.get(`/agency/invoices/attachments`, {
      params: params.params,
    });
    return response.data.data;
  }
);

export const getLatestUpdates = (invoiceId) => async (dispatch) => {
  const params = {
    page: 1,
    pageSize: 20,
    scopes: [],
    sort: 'createdAt:desc',
  };
  dispatch(fetchUpdates({ params, invoiceId }));
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    loading: false,
    invoice: null,
    commissionErrors: null,
    updates: { rows: [] },
    olderUpdates: { nextPage: 1, rows: [] },
    attachments: {},
    paginationParams: {
      page: 1,
      pageSize: 20,
      search: '',
      sort: 'createdAt:asc',
      status: 'booked',
    },
    updatesPaginationParams: {
      page: 1,
      pageSize: 20,
      scopes: [],
      sort: 'createdAt:desc',
    },
  },
  reducers: {
    setPaginationParams: (state, action) => {
      state.paginationParams = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload;
    },
    setOlderUpdates: (state, action) => {
      state.olderUpdates = action.payload;
    },
    setCommissionErrors: (state, action) => {
      state.commissionErrors = action.payload;
    },
  },
  extraReducers: {
    [fetchInvoice.pending]: (state, { payload }) => {
      state.invoice = null;
      //state.invoiceLoaded = false;
    },
    [fetchInvoice.fulfilled]: (state, { payload }) => {
      state.invoice = payload;
      //state.clientLoaded = true;
    },
    [fetchInvoiceErrors.pending]: (state, { payload }) => {
      state.commissionErrors = null;
      //state.invoiceLoaded = false;
    },
    [fetchInvoiceErrors.fulfilled]: (state, { payload }) => {
      state.commissionErrors = payload;
      //state.clientLoaded = true;
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.updates = { ...payload, rows: reverse(payload.rows) };
    },
    [fetchOlderUpdates.fulfilled]: (state, { payload }) => {
      state.olderUpdates = {
        ...payload,
        rows: [...reverse(payload.rows), ...state.olderUpdates.rows],
      };
    },
    [getAttachments.fulfilled]: (state, { payload }) => {
      state.attachments = payload;
    },
  },
});

export const {
  setPaginationParams,
  setLoading,
  setInvoice,
  setUpdates,
  setOlderUpdates,
} = invoiceSlice.actions;

export const selectAttachments = (state) => state.invoice.attachments;

export default invoiceSlice.reducer;
