import React, { useState, useEffect } from 'react';
import usePermissions from 'hooks/usePermissions';
import { numberFormatter, percentageFormatter } from 'utils/formatters';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'features/auth/authSlice';

const MetricsSalesOverall = ({ teamSalesData }) => {
  const { salesId } = useParams();
  const user = useSelector(selectAuthenticatedUser);
  const [userId, setUserId] = useState(null);
  const { userCan, isAgencySuperUser } = usePermissions();

  useEffect(() => {
    if (salesId) {
      setUserId(salesId);
    } else {
      setUserId(user.userId);
    }
  }, [salesId, user]);

  const overallKpis = [
    {
      key: 'totalCallsBooked',
      label: 'Overall Booked Calls',
      formatter: numberFormatter,
    },
    {
      key: 'totalNoShow',
      label: 'Total No Show',
      formatter: numberFormatter,
    },
    {
      key: 'totalQuotesSent',
      label: 'Quotes Sent',
      formatter: numberFormatter,
    },

    // {
    //   key: 'numOfFullAccManagementDeals',
    //   label: 'Acc Management Deals',
    //   formatter: numberFormatter,
    // },
    // {
    //   key: 'totalDownSellDeal',
    //   label: 'Down-Sell Deal',
    //   formatter: numberFormatter,
    // },
  ];

  const serviceAgreementKpis = [
    {
      key: 'totalServiceAgreementSent',
      label: 'Sent',
      formatter: numberFormatter,
    },
    {
      key: 'totalServiceAgreementSigned',
      label: 'Signed',
      formatter: numberFormatter,
    },
    {
      key: 'serviceAgreementConversionRate',
      label: 'Conversation Rate',
      formatter: percentageFormatter,
    },
  ];

  const invoiceKpis = [
    {
      key: 'numOfInvoiceSent',
      label: 'Sent',
      formatter: numberFormatter,
    },
    {
      key: 'numOfInvoicePaid',
      label: 'Paid',
      formatter: numberFormatter,
    },
    {
      key: 'invoiceConversionRate',
      label: 'Conversion Rate',
      formatter: percentageFormatter,
    },
  ];

  // const monthlyRetainerKpis = [
  //   {
  //     key: 'numOfMonthlyRetainerSent',
  //     label: 'Deals',
  //     formatter: numberFormatter,
  //   },
  //   {
  //     key: 'numOfMonthlyRetainerClosed',
  //     label: 'Closed Deals',
  //     formatter: numberFormatter,
  //   },
  //   {
  //     key: 'monthlyRetainerConversionRate',
  //     label: 'Conversion Rate',
  //     formatter: percentageFormatter,
  //   },
  // ];

  return (
    <>
      {((userCan('sales.metrics') && userCan('sales.metrics.consultant')) ||
        userCan('sales.metrics.director') ||
        isAgencySuperUser()) && (
        <>
          {teamSalesData.map((r, key) => {
            return r.group.map((rg, key) => {
              return rg.userId === userId ? (
                <>
                  <div className="grid gap-4 mt-4">
                    <div className="pt-6 bg-white shadow rounded-lg mt-4">
                      <div className="grid gap-1 grid-cols-3 divide-x divide-gray-200 border-b">
                        {overallKpis.map((kpi) => {
                          const currentValue = rg.current[kpi.key];
                          const previousValue = rg.pervious[kpi.key];
                          let Icon = null;
                          let percentage = 0;
                          let infoColor = 'green';

                          if (currentValue !== previousValue && previousValue) {
                            Icon =
                              currentValue > previousValue
                                ? ChevronUpIcon
                                : ChevronDownIcon;

                            const lessRef =
                              kpi.key === 'totalCallsBooked' ||
                              kpi.key === 'totalNoShow' ||
                              kpi.key === 'totalQuotesSent' ||
                              kpi.key === 'numOfFullAccManagementDeals' ||
                              kpi.key === 'totalDownSellDeal'
                                ? currentValue
                                : previousValue;
                            const greaterRef =
                              kpi.key === 'totalCallsBooked' ||
                              kpi.key === 'totalNoShow' ||
                              kpi.key === 'totalQuotesSent' ||
                              kpi.key === 'numOfFullAccManagementDeals' ||
                              kpi.key === 'totalDownSellDeal'
                                ? previousValue
                                : currentValue;

                            infoColor = lessRef < greaterRef ? 'red' : 'green';
                            percentage =
                              (currentValue - previousValue) /
                              Math.abs(previousValue);
                          }

                          return (
                            <div
                              key={kpi.key}
                              className="w-full flex flex-col pb-5 text-center"
                            >
                              <p className="text-gray-500 text-base mb-1">
                                {kpi.label}
                              </p>
                              <p className="my-1 text-gray-600 font-normal text-3xl font-body">
                                {kpi.formatter(rg.current[kpi.key])}
                              </p>
                              <div className="flex justify-center items-center mt-1">
                                {Icon && (
                                  <Icon
                                    className={`text-${infoColor}-800 h-4 w-4`}
                                  />
                                )}
                                <p
                                  className={`text-base text-${infoColor}-800 ml-1`}
                                >
                                  {percentageFormatter(percentage)}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="px-6 py-8">
                    <div className="grid lg:grid-cols-2 gap-4 mt-4">
                      <div className="px-2 pt-6 bg-white shadow rounded-lg ">
                        <div className="text-xl font-bold text-grayscale-800 tracking-3/4 text-center pb-3">
                          Service Agreement
                        </div>

                        <div className="grid gap-1 grid-cols-3 divide-x divide-gray-200 border-b">
                          {serviceAgreementKpis.map((kpi) => {
                            const currentValue = rg.current[kpi.key];
                            const previousValue = rg.pervious[kpi.key];
                            let Icon = null;
                            let percentage = 0;
                            let infoColor = 'green';

                            if (
                              currentValue !== previousValue &&
                              previousValue
                            ) {
                              Icon =
                                currentValue > previousValue
                                  ? ChevronUpIcon
                                  : ChevronDownIcon;

                              const lessRef =
                                kpi.key === 'totalServiceAgreementSent' ||
                                kpi.key === 'totalServiceAgreementSigned' ||
                                kpi.key === 'serviceAgreementConversionRate'
                                  ? currentValue
                                  : previousValue;
                              const greaterRef =
                                kpi.key === 'totalServiceAgreementSent' ||
                                kpi.key === 'totalServiceAgreementSigned' ||
                                kpi.key === 'serviceAgreementConversionRate'
                                  ? previousValue
                                  : currentValue;

                              infoColor =
                                lessRef < greaterRef ? 'red' : 'green';
                              percentage =
                                (currentValue - previousValue) /
                                Math.abs(previousValue);
                            }

                            return (
                              <div
                                key={kpi.key}
                                className="w-full flex flex-col pb-5 text-center "
                              >
                                <p
                                  className="text-gray-500 text-sm mb-1 px-2"
                                  style={{ minHeight: '20px' }}
                                >
                                  {kpi.label}
                                </p>
                                <p className="my-1 text-black font-bold text-sm font-body">
                                  {kpi.formatter(rg.current[kpi.key])}
                                </p>
                                <div className="flex justify-center items-center mt-1">
                                  {Icon && (
                                    <Icon
                                      className={`text-${infoColor}-800 h-4 w-4`}
                                    />
                                  )}
                                  <p
                                    className={`text-sm text-${infoColor}-800 ml-1`}
                                  >
                                    {percentageFormatter(percentage)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="px-2 pt-6 bg-white shadow rounded-lg ">
                        <div className="text-xl font-bold text-grayscale-800 tracking-3/4 text-center pb-3">
                          Invoice
                        </div>

                        <div className="grid gap-1 grid-cols-3 divide-x divide-gray-200 border-b">
                          {invoiceKpis.map((kpi) => {
                            const currentValue = rg.current[kpi.key];
                            const previousValue = rg.pervious[kpi.key];
                            let Icon = null;
                            let percentage = 0;
                            let infoColor = 'green';

                            if (
                              currentValue !== previousValue &&
                              previousValue
                            ) {
                              Icon =
                                currentValue > previousValue
                                  ? ChevronUpIcon
                                  : ChevronDownIcon;

                              const lessRef =
                                kpi.key === 'numOfInvoiceSent' ||
                                kpi.key === 'numOfInvoicePaid' ||
                                kpi.key === 'invoiceConversionRate'
                                  ? currentValue
                                  : previousValue;
                              const greaterRef =
                                kpi.key === 'numOfInvoiceSent' ||
                                kpi.key === 'numOfInvoicePaid' ||
                                kpi.key === 'invoiceConversionRate'
                                  ? previousValue
                                  : currentValue;

                              infoColor =
                                lessRef < greaterRef ? 'red' : 'green';
                              percentage =
                                (currentValue - previousValue) /
                                Math.abs(previousValue);
                            }

                            return (
                              <div
                                key={kpi.key}
                                className="w-full flex flex-col pb-5 text-center "
                              >
                                <p
                                  className="text-gray-500 text-sm mb-1 px-2"
                                  style={{ minHeight: '20px' }}
                                >
                                  {kpi.label}
                                </p>
                                <p className="my-1 text-black font-bold text-sm font-body">
                                  {kpi.formatter(rg.current[kpi.key])}
                                </p>
                                <div className="flex justify-center items-center mt-1">
                                  {Icon && (
                                    <Icon
                                      className={`text-${infoColor}-800 h-4 w-4`}
                                    />
                                  )}
                                  <p
                                    className={`text-sm text-${infoColor}-800 ml-1`}
                                  >
                                    {percentageFormatter(percentage)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* <div className="px-2 pt-6 bg-white shadow rounded-lg ">
                          <div className="text-xl font-bold text-grayscale-800 tracking-3/4 text-center pb-3">
                            Monthly Retainer
                          </div>

                          <div className="grid gap-1 grid-cols-3 divide-x divide-gray-200 border-b">
                            {monthlyRetainerKpis.map((kpi) => {
                              const currentValue = rg.current[kpi.key];
                              const previousValue = rg.pervious[kpi.key];
                              let Icon = null;
                              let percentage = 0;
                              let infoColor = 'green';

                              if (currentValue !== previousValue) {
                                Icon =
                                  currentValue > previousValue
                                    ? ChevronUpIcon
                                    : ChevronDownIcon;

                                const lessRef =
                                  kpi.key === 'numOfMonthlyRetainerSent' ||
                                  kpi.key === 'numOfMonthlyRetainerClosed' ||
                                  kpi.key === 'monthlyRetainerConversionRate'
                                    ? currentValue
                                    : previousValue;
                                const greaterRef =
                                  kpi.key === 'numOfMonthlyRetainerSent' ||
                                  kpi.key === 'numOfMonthlyRetainerClosed' ||
                                  kpi.key === 'monthlyRetainerConversionRate'
                                    ? previousValue
                                    : currentValue;

                                infoColor =
                                  lessRef < greaterRef ? 'red' : 'green';
                                percentage =
                                  (currentValue - previousValue) /
                                  Math.abs(
                                    previousValue === 0 ? 1 : previousValue
                                  );
                              }

                              return (
                                <div
                                  key={kpi.key}
                                  className="w-full flex flex-col pb-5 text-center "
                                >
                                  <p
                                    className="text-gray-500 text-sm mb-1 px-2"
                                    style={{ minHeight: '20px' }}
                                  >
                                    {kpi.label}
                                  </p>
                                  <p className="my-1 text-black font-bold text-sm font-body">
                                    {kpi.formatter(rg.current[kpi.key])}
                                  </p>
                                  <div className="flex justify-center items-center mt-1">
                                    {Icon && (
                                      <Icon
                                        className={`text-${infoColor}-800 h-4 w-4`}
                                      />
                                    )}
                                    <p
                                      className={`text-sm text-${infoColor}-800 ml-1`}
                                    >
                                      {percentageFormatter(percentage)}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <div></div>
              );
            });
          })}
        </>
      )}
    </>
  );
};

export default MetricsSalesOverall;
