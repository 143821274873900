import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPlans = createAsyncThunk(
  'billing/fetchPlans',
  async (params, thunkAPI) => {
    const response = await axios.get(`/billing/plans`, { params });
    return response.data.data;
  }
);

export const fetchTaxes = createAsyncThunk(
  'billing/fetchTaxes',
  async (thunkAPI) => {
    const response = await axios.get(`/billing/taxes`);
    return response.data.data;
  }
);

export const fetchTaxExemptions = createAsyncThunk(
  'billing/fetchTaxExemptions',
  async (thunkAPI) => {
    const response = await axios.get(`/billing/tax-exemptions`);
    return response.data.data;
  }
);

export const fetchAddons = createAsyncThunk(
  'billing/fetchAddons',
  async (params, thunkAPI) => {
    const response = await axios.get(`/billing/addons`, { params });
    return response.data.data;
  }
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    plans: null,
    plansGrouped: null,
    taxes: null,
    taxExemptions: null,
    itemsPaginationParams: {
      sort: 'name:asc',
    },
    addons: null,
    addonsPaginationParams: {
      // page: 1,
      // pageSize: 1000,
      sort: 'name:asc',
    },
  },
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    setTaxes: (state, action) => {
      state.taxes = action.payload;
    },
    setTaxExemptions: (state, action) => {
      state.taxExemptions = action.payload;
    },
    setAddons: (state, action) => {
      state.addons = action.payload;
    },
  },
  extraReducers: {
    [fetchPlans.fulfilled]: (state, { payload }) => {
      state.plans = payload;
      state.plansGrouped = [...new Set(payload.map((p) => p.name))];
    },
    [fetchTaxes.fulfilled]: (state, { payload }) => {
      state.taxes = payload;
    },
    [fetchTaxExemptions.fulfilled]: (state, { payload }) => {
      state.taxExemptions = payload;
    },
    [fetchAddons.fulfilled]: (state, { payload }) => {
      state.addons = payload;
    },
  },
});

export const { setPlans, setTaxes, setTaxExemptions, setAddons } =
  billingSlice.actions;

export default billingSlice.reducer;
