import { useState, useEffect } from 'react';
import { Field, useFormikContext, FieldArray, ErrorMessage } from 'formik';
import { Disclosure, Menu } from '@headlessui/react';

import Label from 'components/Forms/Label';
import Button from 'components/Button';
import { PlusIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';

export const Questions = ({ acknowledgement }) => {
  const { values } = useFormikContext();
  const asinObj = {
    name: '',
    child: '',
  };

  return (
    <div
      className="relative flex flex-col p-6 lg:pb-28 lg:pl-10 md:pr-0 section"
      id="questions"
    >
      <div className="flex flex-col">
        <div className="z-10 md:w-9/12 space-y-6">
          <h2 className="text-4xl font-bold text-si-pink">Questions</h2>
        </div>
        <div className="sm:grid sm:grid-cols-12 space-y-3 m-2">
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please provide a list of the Parent ASINs and Child ASINs
                      requiring our services.
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4 pb-2">
                    <FieldArray name="clientInfo.asins">
                      {({ insert, remove, push }) => (
                        <>
                          {values.clientInfo.asins.length > 0 &&
                            values.clientInfo.asins.map((asin, index) => (
                              <div
                                key={`asin-${index}`}
                                className="grid grid-cols-12 gap-2 mb-3"
                              >
                                <div className="col-span-12 text-sm flex justify-between">
                                  <span className="font-bold text-gray-600">
                                    Parent ASIN # {index + 1}
                                  </span>
                                  <button
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    className="text-gray-500 hover:text-red-700"
                                  >
                                    <TrashIcon className="w-4 h-4" />
                                  </button>
                                </div>
                                <div className="col-span-12">
                                  <Label>Name</Label>
                                  <Field
                                    disabled={
                                      acknowledgement &&
                                      acknowledgement.signedDate
                                    }
                                    name={`clientInfo.asins.${index}.name`}
                                    className="form-input disabled-white placeholder:text-slate-400"
                                    type="text"
                                  />

                                  <ErrorMessage
                                    name={`clientInfo.asins.${index}.name`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                                <div className="col-span-12">
                                  <Label>Child ASIN</Label>
                                  <Field
                                    disabled={
                                      acknowledgement &&
                                      acknowledgement.signedDate
                                    }
                                    name={`clientInfo.asins.${index}.child`}
                                    className="form-input disabled-white placeholder:text-slate-400"
                                    as="textarea"
                                    rows={5}
                                    placeholder={`B08Z5NYG01
B08Z5NYG02\n`}
                                  />

                                  <ErrorMessage
                                    name={`clientInfo.asins.${index}.name`}
                                    component="div"
                                    className="text-red-700 font-normal text-xs"
                                  />
                                </div>
                              </div>
                            ))}

                          <div className="col-span-2">
                            <Button
                              classes="font-bold tracking-wider w-full justify-center"
                              bgColor="gray-50"
                              hoverColor="gray-200"
                              roundedSize="2xl"
                              textColor="gray-600"
                              border="border-2 border-gray-600"
                              py={1}
                              shadow=""
                              onClick={(e) => {
                                push(asinObj);
                              }}
                              disabled={
                                acknowledgement && acknowledgement.signedDate
                              }
                            >
                              <PlusIcon className="w-4 h-4 inline" /> ASIN
                            </Button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Mission Statement
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>What is your brand's mission or purpose?</Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.brandMissionPurpose`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.brandMissionPurpose`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        What do you aim to achieve with your products?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.productAimToAchieve`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.productAimToAchieve`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        How do you see your brand evolving in the future?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.futureBrandEvolving`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.futureBrandEvolving`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Brand Values
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        What are the core values, principles, and beliefs that
                        your brand upholds?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.brandValues`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.brandValues`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Brand Tone & Voice
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        What is your desired tone and voice for all brand
                        communications including Amazon content?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.desiredToneVoice`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.desiredToneVoice`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        For example: Friendly, authoritative, informative, ect.
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.exFriendlyAuthoritativeEct`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.exFriendlyAuthoritativeEct`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        How do you want customers to feel when they interact
                        with your brand?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.brandInteract`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.brandInteract`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Visual Identity
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        What specific colors, fonts, and other design elements
                        should be used in your Amazon designs and listing
                        images?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.colorFontDesign`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.colorFontDesign`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>Brand Colors and Hex Color Codes:</Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.hexColor`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.hexColor`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>Typography Guidelines & Fonts: </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.typography`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.typography`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>Other Design Elements: </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.designElement`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.designElement`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Brand Associations
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        Are there any associations, themes, or symbols that you
                        would like to be conveyed through the product listings
                        and images?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.brandAssociations`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.brandAssociations`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Brand Competitors
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        Who are your main competitors in the market?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.competitorMarket`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.competitorMarket`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        How do you differentiate your brand from competitors in
                        terms of branding and product offering?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.differentiate`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.differentiate`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us About Your Brand Messaging
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>
                        Do you have key brand messages, slogans, or taglines
                        that should be incorporated into product listings and
                        images?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.keyBrandMessage`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.keyBrandMessage`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        Are there any specific ideas, benefits or other details
                        you want to emphasize?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.detailsToEmphasize`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.detailsToEmphasize`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">
                      Please Tell Us about Your Advertising Goals
                    </h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>What is your target Ad Spend?</Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.targetAdSpend`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.targetAdSpend`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>What is your target ACOS and/or TACOS?</Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.targetAcos`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.targetAcos`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="col-span-12">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight font-bold border-b">
                    <h5 className="text-lg font-bold">General Information</h5>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col space-y-2 py-5">
                    <div className="col-span-12">
                      <Label>What are your current pain points?</Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.painPoints`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.painPoints`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                    <div className="col-span-12">
                      <Label>
                        What are your overall goals partnering with SI?
                      </Label>
                      <Field
                        disabled={acknowledgement && acknowledgement.signedDate}
                        name={`clientInfo.overallGoals`}
                        className="form-input disabled-white placeholder:text-slate-400"
                        type="text"
                      />

                      <ErrorMessage
                        name={`clientInfo.overallGoals`}
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
  );
};
