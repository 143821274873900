import React, { useEffect, useState } from 'react';
import usePermissions from 'hooks/usePermissions';
import { numberFormatter, percentageFormatter } from 'utils/formatters';
import Loading from 'components/Loading';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import axios from 'axios';

const MetricsOverall = ({ teamSalesData }) => {
  const { userCan, isAgencySuperUser } = usePermissions();

  const overallKpis = [
    {
      key: 'numOfFirstCall',
      label: 'Total First Call Presentations',
      formatter: numberFormatter,
    },
    {
      key: 'totalClosedDeals',
      label: '	Total Closed Deals',
      formatter: numberFormatter,
    },
    {
      key: 'conversionRate',
      label: 'Total Conversion Rate',
      formatter: percentageFormatter,
    },
  ];
  return (
    <>
      {((userCan('sales.metrics') && userCan('sales.metrics.director')) ||
        isAgencySuperUser()) && (
        <>
          {teamSalesData.map((r, key) => {
            return r.group.map((rg, key) => {
              return (
                <div className="grid gap-4 mt-4">
                  <div className="pt-6 bg-white shadow rounded-lg mt-4">
                    <div className="grid gap-1 grid-cols-3 divide-x divide-gray-200 border-b">
                      {overallKpis.map((kpi) => {
                        const currentValue = rg.current[kpi.key];
                        const previousValue = rg.pervious[kpi.key];
                        let Icon = null;
                        let percentage = 0;
                        let infoColor = 'green';

                        if (currentValue !== previousValue && previousValue) {
                          Icon =
                            currentValue > previousValue
                              ? ChevronUpIcon
                              : ChevronDownIcon;

                          const lessRef =
                            kpi.key === 'numOfFirstCall' ||
                            kpi.key === 'totalClosedDeals' ||
                            kpi.key === 'conversionRate'
                              ? currentValue
                              : previousValue;
                          const greaterRef =
                            kpi.key === 'numOfFirstCall' ||
                            kpi.key === 'totalClosedDeals' ||
                            kpi.key === 'conversionRate'
                              ? previousValue
                              : currentValue;

                          infoColor = lessRef < greaterRef ? 'red' : 'green';
                          percentage =
                            (currentValue - previousValue) /
                            Math.abs(previousValue);
                        }

                        return (
                          <div
                            key={kpi.key}
                            className="w-full flex flex-col pb-5 text-center"
                          >
                            <p className="text-gray-500 text-base mb-1">
                              {kpi.label}
                            </p>
                            <p className="my-1 text-gray-600 font-normal text-3xl font-body">
                              {kpi.formatter(rg.current[kpi.key])}
                            </p>
                            <div className="flex justify-center items-center mt-1">
                              {Icon && (
                                <Icon
                                  className={`text-${infoColor}-800 h-4 w-4`}
                                />
                              )}
                              <p
                                className={`text-base text-${infoColor}-800 ml-1`}
                              >
                                {percentageFormatter(percentage)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </>
      )}
    </>
  );
};

export default MetricsOverall;
