import React, { useEffect, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table';
import classNames from 'utils/classNames';
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
} from '@heroicons/react/outline';

const DataTable = ({
  data,
  columns,
  showGridLines = true,
  classnames,
  emptyContent,
}) => {
  const [page, setPage] = useState(1);

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  useEffect(() => {
    if (table) setPage(table.getState().pagination.pageIndex + 1);
  }, [table]);

  return (
    <div className="flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table
            className={classNames(
              showGridLines ? 'divide-y divide-gray-200' : '',
              'min-w-full  max-w-xl '
            )}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, i) => (
                    <th
                      scope="col"
                      className={classNames(
                        i === 0
                          ? 'pl-4 pr-3'
                          : i === headerGroup.headers.length - 1
                          ? 'pl-3 pr-4'
                          : 'px-3',
                        'py-4 text-left text-sm font-medium tracking-wide text-grayscale-600',
                        classnames?.header ?? ''
                      )}
                      style={{
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? 'auto'
                            : header.getSize(),
                      }}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className={showGridLines ? 'divide-y divide-gray-200' : ''}>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, i) => (
                    <td
                      key={cell.id}
                      className={classNames(
                        i === 0
                          ? 'pl-4 pr-3'
                          : i === row.getVisibleCells().length - 1
                          ? 'pl-3 pr-4'
                          : 'px-3',
                        'py-3 text-sm text-grayscale-800',
                        classnames?.cell ?? ''
                      )}
                      style={{
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? 'auto'
                            : cell.column.getSize(),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {(!data || data.length < 1) && emptyContent && (
            <div>{emptyContent}</div>
          )}
          <div className="flex items-center justify-between gap-2 w-full mt-4">
            {table.getPageCount() > 1 && (
              <>
                <span className="w-20">
                  <select
                    className="mr-2 pl-4 pr-11 py-1.5 text-base border-gray-300 focus:outline-none focus:ring-red-300 focus:border-red-300 sm:text-sm rounded-2xl"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </span>
                <div className="flex items-center text-sm leading-none">
                  <button
                    className="bg-white pl-2 pr-1 disabled:opacity-30 rounded-l-full border-l border-t border-b border-grayscale-500 h-9 hover:bg-grayscale-500"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <ChevronDoubleLeftIcon className="w-4 h-4" />
                  </button>
                  <button
                    className="bg-white px-1 disabled:opacity-30 border-t border-b border-grayscale-500 h-9 hover:bg-grayscale-500"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <ChevronLeftIcon className="w-4 h-4" />
                  </button>
                  <div className="bg-white px-1.5 flex items-center border-t border-b border-grayscale-500 leading-none h-9 text-sm">
                    Page&nbsp;&nbsp;
                    <input
                      type="number"
                      value={table.getState().pagination.pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        table.setPageIndex(page);
                      }}
                      className="noscroll w-7 leading-none border-none text-right ring-1 ring-grayscale-500 rounded-sm text-sm pl-0 pr-2 py-0.5"
                    />
                    <span className="text-sm ml-2">
                      of {table.getPageCount()}
                    </span>
                  </div>
                  <button
                    className="bg-white px-1 disabled:opacity-30 border-t border-b border-grayscale-500 h-9 hover:bg-grayscale-500"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <ChevronLeftIcon className="w-4 h-4 transform rotate-180" />
                  </button>
                  <button
                    className="bg-white pr-2 pl-1 disabled:opacity-30 rounded-r-full border-r border-t border-b border-grayscale-500 h-9 hover:bg-grayscale-500"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    <ChevronDoubleLeftIcon className="w-4 h-4 transform rotate-180" />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataTable;
