import { useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import uimg from 'assets/icons/uimg.svg';
import uvid from 'assets/icons/uvid.svg';
import ProgressBar from '@ramonak/react-progress-bar';
import classNames from 'utils/classNames';
import useImageLoad from 'hooks/useImageLoad';
import {
  ExclamationCircleIcon,
  VideoCameraIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/solid';
import useAlert from 'hooks/useAlert';
import SpinnerGrow from 'components/SpinnerGrow';
import { useDrag, useDrop } from 'react-dnd';

const MediaUpload = ({ file, index, onUpdate, moveCard, reorder = false }) => {
  const ref = useRef(null);
  const { alertError } = useAlert();
  const fileInputRef = useRef(null);
  const [filesUp, setFilesUp] = useState(null);
  const { onReloadSrc } = useImageLoad();
  const [isUploadOk, setIsUploadOk] = useState(true);
  const [isReloadingImage, setIsReloadingImage] = useState(false);
  const labels = [
    'Main Image',
    'Image 2',
    'Image 3',
    'Image 4',
    'Image 5',
    'Image 6',
    'Image 7',
    'Listing Video',
  ];

  useEffect(() => {
    if (file.result) {
      setIsUploadOk(file.result.success);
      if (file.result.success) {
        setIsReloadingImage(true);
        onClearFile();
      } else {
        alertError('Upload Failed', `Failed to upload ${file.title}`);
      }
    }
  }, [file.result]);

  useEffect(() => {
    if (reorder === true) {
      onClearFile();
    }
  }, [reorder]);

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const previewFiles = (files) => {
    setIsUploadOk(true);
    fileInputRef.current.files = files;

    onUpdate({ ...file, file: files, progress: 0 });

    setFilesUp(
      [...files].map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  //* Triggered after selecting a file
  const onFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      previewFiles(event.target.files);
    }
  };

  const onClearFile = () => {
    fileInputRef.current.files = null;
    fileInputRef.current.value = null;
    setFilesUp(null);
    onUpdate({ ...file, progress: 0 });
    setIsUploadOk(true);
  };

  const onImgLoad = () => {
    setIsReloadingImage(false);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: 'media',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // canDrop(item, monitor) {
    //   return reorder === true && file.type === 'image';
    // },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'media',
    item: () => {
      return { file, index };
    },
    canDrag: reorder === true && file.type === 'image',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  if (reorder === true && file.type === 'image') {
    drag(drop(ref));
  }

  return (
    <div
      ref={ref}
      className="col-span-1 relative"
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {reorder && file.type === 'image' && (
        <SwitchHorizontalIcon className="absolute w-4 h-4 text-red-400 filter drop-shadow-gray top-0.5 left-0.5 z-10" />
      )}
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={file.type === 'video' ? 'video/*' : 'image/*'}
        disabled={reorder}
      />
      <FileDrop
        onTargetClick={onTargetClick}
        onDrop={(files, event) => previewFiles(files)}
        className={reorder ? 'pointer-events-none' : ''}
      >
        {filesUp && filesUp.length > 0 && (
          <button
            className={classNames(
              reorder ? 'invisible' : '',
              'absolute right-0 -top-2 h-6 w-6 rounded-full text-white bg-custom-blue hover:bg-custom-sky text-center leading-6 cursor-pointer z-1'
            )}
            onClick={(e) => {
              e.stopPropagation();
              onClearFile();
            }}
            type="button"
            disabled={reorder}
          >
            &times;
          </button>
        )}
        <div className="text-center cursor-pointer h-28 bg-gray-100 rounded-sm flex items-center justify-center overflow-hidden relative">
          {filesUp && filesUp.length > 0 ? (
            file.type === 'video' ? (
              <span className="text-1xs leading-none flex flex-col items-center">
                <VideoCameraIcon className="w-4 h-4 mb-1" /> {filesUp[0].name}
              </span>
            ) : (
              <img
                key={filesUp[0].name}
                src={filesUp[0].preview}
                className="object-fill"
                onLoad={() => {
                  URL.revokeObjectURL(filesUp[0].preview);
                }}
              />
            )
          ) : file.slide ? (
            <>
              {isReloadingImage && (
                <div className="absolute flex w-auto text-red-500 ">
                  <SpinnerGrow size={2} />
                  <SpinnerGrow size={2} />
                  <SpinnerGrow size={2} />
                </div>
              )}
              {file.slide.type === 'video' && !isReloadingImage && (
                <span className="mockup-vid-play-btn absolute-center z-10"></span>
              )}
              <img
                key={file.slide.thumbUrl}
                src={file.slide.thumbUrl}
                alt={file.slide.alt}
                className={classNames(
                  file.slide.type === 'video' ? 'opacity-70' : '',
                  'w-full h-full object-cover'
                )}
                onLoad={onImgLoad}
                onError={(e) => {
                  onReloadSrc(e, file.slide.thumbUrl);
                }}
              />
            </>
          ) : (
            <img src={file.type === 'video' ? uvid : uimg} />
          )}
          {!isUploadOk && (
            <ExclamationCircleIcon className="absolute w-8 h-8 text-red-500 bg-white rounded-full" />
          )}
          <div
            className={classNames(
              file.progress <= 0 ? 'invisible' : '',
              'absolute w-full bottom-0'
            )}
          >
            <ProgressBar
              completed={file.progress}
              bgColor="#75e862"
              height="5px"
              borderRadius="0"
              isLabelVisible={false}
              baseBgColor="#e0dede"
              labelColor="#e80909"
              transitionTimingFunction="linear"
              animateOnRender
            />
          </div>
        </div>
      </FileDrop>
      <p className="w-full text-center mt-2">{labels[index]}</p>
    </div>
  );
};
export default MediaUpload;
