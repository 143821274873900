import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from 'utils/classNames';

const ModalCentered = ({
  open,
  setOpen,
  children,
  closeIconClassname = 'h-6 w-6 filter drop-shadow-gray',
  closeClasses = 'text-gray-400 hover:text-gray-500 rounded-md focus:outline-none',
  rounded = 'rounded-xl overflow-hidden',
  width = 'max-w-lg',
  style,
}) => {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      as="div"
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto"
    >
      <div
        className={classNames(
          rounded,
          width,
          'flex flex-col bg-gray-800 text-white text-center relative'
        )}
        style={style}
      >
        <Dialog.Overlay
          className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}
        />
        <button
          type="button"
          className={closeClasses}
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close</span>
          <XIcon className={closeIconClassname} aria-hidden="true" />
        </button>

        {children}
      </div>
    </Dialog>
  );
};

export default ModalCentered;
