import metrics from '../../../../utils/metrics';

const TableSummary = ({
  loading,
  attributes = [],
  records = [],
  textSize = 13,
  dataPadding = 'py-5 px-4',
  dataBgColor = 'white',
  fullTitle = true,
  tableClassName = '',
  className = 'overflow-x-auto mt-8 w-full',
}) => {
  return (
    <div className={className}>
      <table className={tableClassName}>
        <thead>
          <tr>
            <td
              className={`text-${textSize} text-grayscale-600 leading-1.5 sticky left-0 z-10 whitespace-nowrap ${dataPadding} bg-grayscale-200 border-b border-grayscale-500`}
            >
              Date Range
            </td>
            {attributes
              .map((attr) => metrics.find((m) => m.key === attr))
              .map((metric) => (
                <td
                  key={`trends-breakdown-table-header-${metric.key}`}
                  className={`text-${textSize} text-grayscale-600 leading-1.5 ${dataPadding} whitespace-nowrap border-b border-grayscale-500 bg-grayscale-200 text-right`}
                >
                  {fullTitle ? metric.title : metric.shortTitle ?? metric.title}
                </td>
              ))}
          </tr>
        </thead>

        <tbody>
          {!loading ? (
            records.map((item) => (
              <tr key={`trends-breakdown-table-row-${item.dateRange}`}>
                <td
                  className={`text-${textSize} text-grayscale-800 leading-1.5 sticky left-0 z-10 whitespace-nowrap ${dataPadding} bg-${dataBgColor}`}
                >
                  {item.dateRange}
                </td>
                {attributes
                  .map((attr) => metrics.find((m) => m.key === attr))
                  .map((metric) => (
                    <td
                      key={`trends-breakdown-table-row-${item.dateRange}-${metric.key}`}
                      className={`text-${textSize} text-grayscale-800 leading-1.5 ${dataPadding} whitespace-nowrap text-right`}
                    >
                      {metric.rawFormatter(item[metric.key])}
                    </td>
                  ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                className={`text-center tracking-2 px-3 py-4 text-${textSize} text-grayscale-800`}
                colSpan={15}
              >
                Loading Records
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TableSummary;
