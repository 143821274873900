import update from 'immutability-helper';
import { DEFAULT_MARKETPLACE } from 'utils/constants';
import { object, string, number, date, array, boolean, ref } from 'yup';
import moment from 'moment';
import { ifNullThenEmpty, ifNullThenEmptyArray } from 'utils/formatters';

export const descriptionCheck = (string) => /[<>]/.test(string);

export const proposalValidationSchema = {
  plan_code: string().required('Plan Code is required'),
  paymentOption: string().required('Payment Option is required'),
  service: string().required('Service type is required'),
  noOfAsins: number()
    .min(0, 'No of asin must be greater than or equal to 1')
    .required('No of asin is required'),
  name: string().nullable(),
  price: number().min(0).required('Monthly retainer is required'),
  noExpiry: boolean(),
  billing_cycles: number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable()
    .when('noExpiry', {
      is: false,
      then: number()
        .min(1, 'Billing cycles must be greater than or equal to 1')
        .required('Billing cycles is required')
        .typeError('Billing cycles is required'),
    }),
  currency_code: string().required(),
  defaultMarketplace: string().required('required'),
  contractSigned: date().nullable(),
  plan_description: string()
    .test(
      'Addon Description must not have any (<) less than or (>) greater than symbol',
      'Addon Description must not have any (<) less than or (>) greater than symbol',
      (value) => !descriptionCheck(value)
    )
    .nullable(),
  pricebook_id: string().nullable(),
  interval: string().nullable().when('paymentOption', {
    is: 'Lumpsum',
    then: string().required(),
  }),
};

export const commissionValidationSchema = {
  commissionType: string().nullable().when('noCommission', {
    is: false,
    then: string().required(),
  }),
  rate: number().when(['noCommission', 'commissionType'], {
    is: (noCommission, commissionType) =>
      noCommission || (!noCommission && commissionType === 'tiered'),
    then: number()
      .transform((v, o) => (o === '' ? null : v))
      .nullable(),
    otherwise: number()
      .min(0, 'Commission rate must be greater than or equal to 1')
      .required('Commission rate is required'),
  }),
  monthThreshhold: number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable(true)
    .when('commissionType', {
      is: (commissionType) => ['benchmark', 'rolling'].includes(commissionType),
      then: number().min(1).required(),
    }),

  baseline: number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable(),
  rules: array()
    .of(
      object().shape({
        min: number().min(0, 'Minimum value is 0').required('required'),
        max: number().min(ref('min'), 'Must be >= minGross').nullable(true),
        rate: number().min(0).required('required'),
      })
    )
    .nullable()
    .when(['noCommission', 'commissionType'], {
      is: (noCommission, commissionType) =>
        !noCommission && commissionType === 'tiered',
      then: array()
        .required()
        .min(1, 'Tiered commission must have at least 1 rule'),
    }),
  noCommission: boolean(),
  noCommissionReason: string()
    .nullable()
    .when('noCommission', {
      is: true,
      then: string().required('No commission reason is required'),
    }),
  autoAdd: boolean(),
};

export const addonValidationSchema = {
  addons: array()
    .of(
      object().shape({
        addon_code: string().required('Addon code is required'),
        name: string(),
        addon_description: string()
          .test(
            'Addon Description must not have any (<) less than or (>) greater than symbol',
            'Addon Description must not have any (<) less than or (>) greater than symbol',
            (value) => !descriptionCheck(value)
          )
          .nullable(),
        type: string().required(),
        quantity: number()
          .min(1, 'Qty must be greater than or equal to 1')
          .required('required'),
        price: number().min(0).required('required'),
      })
    )
    .nullable(),
};

const emptyProposalInitialValues = {
  isCreateProposal: false,
  isResendProposal: false,
  isSendInvite: false,
  isCreateAgencyClient: true,
  noOfAsins: 0,
  addonTemp: {
    name: '',
    addon_description: '',
    addon_code: '',
    price: 0,
    quantity: 1,
    type: 'one_time',
    action: 'add',
  },
  service: '',
  invoiceSentDate: null,
  totalAmountPaid: 0,
  noCommission: true,
  noCommissionReason: 'Put reason here',
  serviceAgreementLink: '',
  plan_code: '',
  name: '',
  reference_id: '',
  pricebook_id: '',
  price: 0,
  noExpiry: true,
  billing_cycles: '',
  currency_code: 'USD',
  convertDisabled: false,
  convert_retainer_cycle: null,
  retainer_after_convert: '',
  plan_description: '',
  addons: [],
  defaultMarketplace: DEFAULT_MARKETPLACE,
  siEmail: '',
  contractSigned: '',
  starts_at: '',
  commissionType: '',
  rate: 0,
  monthThreshhold: 0,
  baseline: null,
  managedAsins: [],
  rules: [],
  autoAdd: false,
  images: [],
  interval: '',
  skipProposal: false,
};

export const initialState = {
  initialValues: emptyProposalInitialValues,
  proposalInvite: null,
  acknowledgement: null,
  companyName: '',
  onboardingHistory: null,
};

export function proposalReducer(proposal, action) {
  switch (action.type) {
    case 'initialSetup': {
      const { AgencyClient } = action.client;
      const { account } = AgencyClient;

      const checkCommissionValue = (key) => {
        if (account && account.commissions[0]) {
          if (key === 'commence') {
            return account.commissions[0].commencedAt ? true : false;
          }
          return account.commissions[0][key];
        }

        if (AgencyClient.draftCommission) {
          return AgencyClient.draftCommission[key];
        }

        return null;
      };

      const values = {
        ...proposal.initialValues,
        noCommission: ifNullThenEmpty(AgencyClient.noCommission, false),
        noCommissionReason: ifNullThenEmpty(AgencyClient.noCommissionReason),
        serviceAgreementLink: ifNullThenEmpty(
          AgencyClient.serviceAgreementLink
        ),
        service: ifNullThenEmpty(AgencyClient.service),
        plan_code: ifNullThenEmpty(AgencyClient.hostedpageDetails?.plan_code),
        paymentOption: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.paymentOption
        ),
        freeServiceReason: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.freeServiceReason
        ),
        images: ifNullThenEmptyArray(AgencyClient.hostedpageDetails?.images),
        approvedBy: ifNullThenEmpty(AgencyClient.hostedpageDetails?.approvedBy),
        contractEndDate: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.contractEndDate
        ),
        interval: ifNullThenEmpty(AgencyClient.hostedpageDetails?.interval),
        name: ifNullThenEmpty(AgencyClient.hostedpageDetails?.name),
        reference_id: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.reference_id
        ),
        pricebook_id: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.pricebook_id
        ),
        price: ifNullThenEmpty(AgencyClient.hostedpageDetails?.price),
        noExpiry: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.noExpiry,
          true
        ),
        billing_cycles: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.billing_cycles
        ),
        currency_code: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.currency_code,
          'USD'
        ),
        convertDisabled:
          AgencyClient.hostedpageDetails?.convert_retainer_cycle === '' ||
          AgencyClient.hostedpageDetails?.convert_retainer_cycle === 0
            ? true
            : false,
        convert_retainer_cycle: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.convert_retainer_cycle,
          null
        ),
        retainer_after_convert: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.retainer_after_convert
        ),
        plan_description: ifNullThenEmpty(
          AgencyClient.hostedpageDetails?.plan_description
        ),
        addons: AgencyClient.hostedpageDetails?.addons ?? [],
        skipProposal: AgencyClient.hostedpageDetails?.skipProposal
          ? true
          : false,
        defaultMarketplace:
          AgencyClient.draftMarketplace ??
          DEFAULT_MARKETPLACE,
        siEmail: AgencyClient?.siEmail
          ? AgencyClient?.siEmail
          : ifNullThenEmpty(AgencyClient.hostedpageDetails?.siEmail),
        contractSigned: AgencyClient.contractSigned
          ? moment.utc(AgencyClient.contractSigned).format('YYYY-MM-DD')
          : '',
        starts_at: ifNullThenEmpty(AgencyClient.hostedpageDetails?.starts_at),
        commissionType: checkCommissionValue('type'),
        rate: checkCommissionValue('rate') ?? 0,
        noOfAsins:
          checkCommissionValue('managedAsins') &&
          checkCommissionValue('managedAsins').length
            ? checkCommissionValue('managedAsins').length
            : 0,
        monthThreshhold: checkCommissionValue('monthThreshold') ?? 0,
        baseline: checkCommissionValue('baseline') ?? null,
        managedAsins: checkCommissionValue('managedAsins') ?? [],
        rules: checkCommissionValue('rules') ?? [],
        autoAdd: checkCommissionValue('commence') ?? false,
      };

      const clientName =
        action.client && action.client.lead && action.client.lead.companyName
          ? action.client.lead.companyName
          : '';

      return update(proposal, {
        initialValues: { $set: values },
        proposalInvite: { $set: action.client.proposalInvite ?? null },
        acknowledgement: { $set: account?.acknowledgement ?? null },
        companyName: { $set: clientName },
        client: { $set: action.client },
        onboardingHistory: { $set: account?.onboardingHistory ?? null },
      });
    }
  }
}
