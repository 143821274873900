import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { MailIcon, ChatAltIcon } from '@heroicons/react/solid';
import { AtSymbolIcon } from '@heroicons/react/outline';
import { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import UploadAttachment from 'components/Editor/UploadAttachment';
import ButtonLink from 'components/ButtonLink';
import classNames from 'utils/classNames';
import { useSelector } from 'react-redux';
import useComment from './components/useComment';
import { isEqual } from 'lodash';

const CommentEditor = ({
  qRef,
  open,
  setOpen,
  isEditMode,
  editCommentValue = '',
  setOpenEditor,
  uploaderOptions,
  employees,
  onSave,
  isHasLinkedIn = false,
  canSwitchToMail = true,
  mentionOffset = 2,
}) => {
  const [saving, setSaving] = useState(false);
  const { draftComment } = useSelector((state) => state.comment);
  const {
    value,
    setValue,
    contentValue,
    setContentValue,
    formats,
    toolbarOptions,
    saveDraftComment,
  } = useComment(qRef);

  useEffect(() => {
    if (editCommentValue !== '') {
      setValue(editCommentValue);
    }
  }, [editCommentValue]);

  useEffect(() => {
    if (open && draftComment) {
      if (isEqual(uploaderOptions.primaryKey, draftComment.key)) {
        setValue(draftComment.structure);
      } else saveDraftComment(null);
    }
  }, [open]);

  const mention = {
    offsetTop: mentionOffset,
    mentionDenotationChars: ['@'],
    dataAttributes: [
      'userId',
      'id',
      'value',
      'denotationChar',
      'link',
      'target',
      'disabled',
      'type',
    ],
    source: useCallback(function (searchTerm, renderList, mentionChar) {
      let values;

      values = employees.map((e) => {
        return {
          ...e,
          value: `${e.firstName} ${e.lastName}`,
        };
      });

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    }, []),
  };

  const onUploadAttachment = (attachment) => {
    const range = qRef.current.editor.getSelection(true);
    let position = range ? range.index : 0;

    const url = uploaderOptions.getAttachmentLink(attachment);
    const title = attachment.title ?? attachment.name;

    qRef.current.editor.insertEmbed(position, 'linkAttachment', {
      title,
      url,
      key: attachment[uploaderOptions.attachmentKey],
    });
    qRef.current.editor.setSelection(position, title.length);
  };

  const onMention = () => {
    const range = qRef.current.editor.getSelection();
    let position = range ? range.index : 0;
    qRef.current.editor.insertText(position, '@');
  };

  const onSaveComment = async () => {
    setSaving(true);
    const data = {
      content: contentValue,
      structure: value,
      text: qRef.current.editor.getText(),
    };
    qRef.current.editor.setContents('');
    saveDraftComment(null);
    await onSave(data);
    setSaving(false);
  };

  const onChangeContent = (content, delta, source, editor) => {
    setValue(editor.getContents());
    setContentValue(content);
    saveDraftComment({
      structure: editor.getContents().ops,
      content: content,
      key: uploaderOptions.primaryKey,
    });
  };

  return (
    <>
      <div
        className={classNames(
          isEditMode && 'edit-comment',
          'quill-text-editor py-2'
        )}
      >
        <ReactQuill
          ref={qRef}
          theme="bubble"
          placeholder="Have any updates or comments? Click here to type!"
          value={value}
          onChange={onChangeContent}
          bounds={`.quill-text-editor`}
          modules={{
            mention,
            toolbar: toolbarOptions,
            clipboard: {
              matchVisual: false,
            },
            imageActions: {},
            imageFormats: {},
          }}
          formats={formats}
        />
      </div>
      <div className="flex justify-between py-3 px-4 border-t">
        {!isHasLinkedIn && (
          <div className="flex space-x-2">
            <ButtonLink onClick={onMention}>
              <AtSymbolIcon className="w-6 h-6 inline text-gray-500 hover:text-green-600" />
            </ButtonLink>

            <UploadAttachment
              onUpload={onUploadAttachment}
              {...uploaderOptions}
            />
          </div>
        )}
        <div className="space-x-4 flex items-center">
          {!isEditMode && (
            <div className="flex mx-1">
              {isHasLinkedIn ? (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('linkedIn');
                  }}
                  title="LinkedIn Thread"
                  classes="p-0.5 bg-blue-400 font-bold rounded"
                  color="white"
                >
                  in
                </ButtonLink>
              ) : (
                canSwitchToMail && (
                  <ButtonLink
                    onClick={() => {
                      setOpenEditor('email');
                    }}
                    title="Send Email"
                    classes="p-0.5"
                    disabled={!canSwitchToMail}
                  >
                    <MailIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
                  </ButtonLink>
                )
              )}

              <ButtonLink
                onClick={() => {
                  setOpenEditor('comment');
                }}
                title="Add Comment"
                classes="pointer-events-none p-0.5"
              >
                <ChatAltIcon className="w-5 h-5 inline text-blue-600 filter drop-shadow-sm " />
              </ButtonLink>
            </div>
          )}
          {isEditMode && (
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonLink>
          )}

          <Button
            roundedSize="2xl"
            classes="tracking-wider font-bold"
            px={6}
            py={2}
            textSize="xs"
            color="green"
            onClick={onSaveComment}
            showLoading={true}
            loading={saving}
          >
            {isEditMode ? 'Save' : 'Comment'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CommentEditor;
