import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { registerWithInviteTokenAsync } from './authSlice';
import AuthLayout from 'layouts/auth/AuthLayout';
import logo from 'assets/logos/logo-orange.png';
import PageLoader from 'components/PageLoader';
import ExpiredInvite from './ExpiredInvite';
import InviteNotFound from './InviteNotFound';
import { setAlert } from 'features/alerts/alertsSlice';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import Button from 'components/Button';

const Register = ({ history }) => {
  const { inviteToken } = useParams();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [invite, setInvite] = useState({});
  const [loading, setLoading] = useState(inviteToken ? true : false);

  useEffect(() => {
    const getInviteDetails = async () => {
      try {
        const invite = await axios({
          method: 'GET',
          url: `/invites/${inviteToken}`,
        });
        setInvite(invite.data.data);
        setInitialValues((prevFormData) => {
          return { ...prevFormData, email: invite.data.data.email };
        });
      } catch (error) {
        setInvite(null);
      }

      setLoading(false);
    };
    if (inviteToken) {
      getInviteDetails();
    }
  }, [inviteToken]);

  const validationSchema = object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string().email().required('Required'),
    password: string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters.'),
  });

  const onSubmit = (values) => {
    dispatch(registerWithInviteTokenAsync(values, inviteToken, history));
  };

  const onResendInvite = async () => {
    await axios.get(`/invites/${invite.inviteId}/resend`).then((res) => {
      dispatch(setAlert('success', `New invitation sent to ${invite.email}`));
    });
  };

  const expiredInvite = invite && moment().isAfter(invite.inviteExpire);

  return loading ? (
    <PageLoader />
  ) : (
    <AuthLayout>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {expiredInvite
            ? 'Your invitation has expired'
            : !invite
            ? 'Invitation not found'
            : 'Register your account'}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-8">
          {expiredInvite ? (
            <ExpiredInvite onResendInvite={onResendInvite} />
          ) : !invite ? (
            <InviteNotFound />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ handleChange, setFieldValue, values }) => (
                <Form className="flex flex-col space-y-3">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <Field
                        name="firstName"
                        placeholder=""
                        className="form-input text-sm"
                        type="text"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <Field
                        name="lastName"
                        placeholder=""
                        className="form-input text-sm"
                        type="text"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <Field
                        name="email"
                        placeholder=""
                        className="form-input text-sm disabled-gray "
                        type="text"
                        disabled={true}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <Field
                        name="password"
                        placeholder=""
                        className="form-input text-sm"
                        type="password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      classes="border-0 font-bold tracking-widest mt-5"
                      bgColor="red-500"
                      hoverColor="red-600"
                      roundedSize="full"
                      textColor="white"
                      px={10}
                      py={1.5}
                      shadow=""
                      textSize=""
                      showLoading={false}
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default Register;
