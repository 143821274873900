import { useState } from 'react';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Brush,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import metrics from '../../../../utils/metrics';

const ChartDisplay = ({
  className = '',
  loading,
  attributes = [],
  records = [],
}) => {
  const [visibleAttributes, setVisibleAttributes] = useState(attributes);

  const handleClick = ({ dataKey }) => {
    setVisibleAttributes(
      visibleAttributes.includes(dataKey)
        ? visibleAttributes.filter((m) => m !== dataKey)
        : [...visibleAttributes, dataKey]
    );
  };

  return (
    <div className={className} id="metrics-chart">
      <div className="h-64">
        {loading ? (
          <div className="bg-grayscale-200 h-full rounded-xl text-grayscale-800 animate-pulse font-inter text-2xl tracking-2 font-bold flex w-full items-center justify-center">
            <p className="text-center">Loading Chart Data</p>
          </div>
        ) : (
          <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
            <ComposedChart data={records}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey="dateRange" style={{ fontSize: '11px' }} />
              <YAxis
                yAxisId="0"
                orientation="left"
                stroke="#8884d8"
                style={{ fontSize: '11px' }}
                tickFormatter={(tick) => currencyFormatter(tick)}
              />

              <YAxis
                yAxisId="1"
                orientation="right"
                stroke="#82ca9d"
                tickFormatter={(tick) => percentageFormatter(tick)}
                style={{ fontSize: '11px' }}
              />

              <Tooltip
                content={
                  <CustomTooltip
                    containerStyle={{ width: '28rem' }}
                    containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl"
                    itemsClassName="grid grid-cols-2"
                  />
                }
              />

              <Legend
                onClick={handleClick}
                wrapperStyle={{
                  cursor: 'pointer',
                  fontSize: '11px',
                  marginTop: '10rem',
                }}
              />
              {records.length > 3 && (
                <Brush
                  dataKey="date"
                  data={records}
                  height={15}
                  endIndex={3}
                  className="text-8"
                />
              )}

              {attributes
                .map((attribute) =>
                  metrics.find((metric) => metric.key === attribute)
                )
                .map((metric) => {
                  return metric.chartType === 'bar' ? (
                    <Bar
                      yAxisId="0"
                      barCategoryGap={10}
                      key={`metrics-chart-${metric.key}`}
                      dataKey={metric.key}
                      fill={metric.color}
                      name={metric.title}
                      hide={!visibleAttributes.includes(metric.key)}
                      formatter={metric.rawFormatter}
                    />
                  ) : (
                    <Line
                      yAxisId="1"
                      key={`metrics-chart-${metric.key}`}
                      type="linear"
                      dataKey={metric.key}
                      stroke={metric.color}
                      fill={metric.color}
                      name={metric.title}
                      dot={{ fill: metric.color }}
                      hide={!visibleAttributes.includes(metric.key)}
                      formatter={metric.rawFormatter}
                    />
                  );
                })}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ChartDisplay;
