import axios from 'axios';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { groupBy, keys, last, sumBy } from 'lodash';

const Timesheet = ({ startDate, endDate }) => {
  const [activities, setActivities] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/hubstaff/activities', {
        params: {
          pageSize: 10000,
          sort: 'timeSlot:asc',
          include: ['project'],
          timeSlotStartDate: moment
            .tz(startDate, 'America/New_York')
            .startOf('D')
            .format(),
          timeSlotEndDate: moment
            .tz(endDate, 'America/New_York')
            .endOf('D')
            .format(),
        },
      });

      if (isSubscribed) {
        const { rows } = response.data.data;
        let groupId = 0;

        const records = rows.map((row, index) => {
          let newRow = { ...row };

          if (!index) {
            return { ...newRow, group: `${groupId}:${row.hubstaffProjectId}` };
          }

          if (
            moment(row.timeSlot).subtract(10, 'm').format() !==
            moment(rows[index - 1].timeSlot).format()
          ) {
            groupId = groupId + 1;
          }

          newRow.group = `${groupId}:${row.hubstaffProjectId}`;
          return newRow;
        });

        setActivities(groupBy(records, 'group'));
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [startDate, endDate]);

  return (
    <div className="bg-white rounded-xl p-6">
      <p className="font-inter font-bold leading-1.2 tracking-3/4 text-grayscale-900 mb-5">
        Timesheet
      </p>

      <div className="w-full h-72 md:h-60 mt-2 overflow-y-scroll">
        <table className="w-full">
          <thead>
            <tr>
              <td className="text-left text-11 font-bold text-grayscale-700">
                Project
              </td>
              <td className="text-left text-11 font-bold text-grayscale-700">
                Date
              </td>
              <td className="text-left text-11 font-bold text-grayscale-700">
                Start Time
              </td>
              <td className="text-left text-11 font-bold text-grayscale-700">
                Stop Time
              </td>
              <td className="text-left text-11 font-bold text-grayscale-700">
                Duration
              </td>
            </tr>
          </thead>

          <tbody>
            {keys(activities).map((key) => (
              <tr key={key}>
                <td className="text-11 text-grayscale-800 leading-1.5 py-2">
                  {activities[key][0].project.name}
                </td>
                <td className="text-11 text-grayscale-800 leading-1.5 py-2">
                  {moment(activities[key][0].timeSlot).format(
                    'ddd, MMMM Do YYYY'
                  )}
                </td>
                <td className="text-11 text-grayscale-800 leading-1.5 py-2">
                  {moment(activities[key][0].startsAt).format('hh:mm A')}
                </td>
                <td className="text-11 text-grayscale-800 leading-1.5 py-2">
                  {moment(last(activities[key]).startsAt)
                    .add(last(activities[key]).tracked, 's')
                    .format('hh:mm A')}
                </td>
                <td className="text-11 text-grayscale-800 leading-1.5 py-2">
                  {moment
                    .duration(sumBy(activities[key], 'tracked'), 'seconds')
                    .format('hh:mm:ss')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timesheet;
