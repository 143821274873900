import axios from 'axios';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Brush,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

import classNames from 'utils/classNames';
import metrics from '../../utils/metrics';
import { currencyFormatter, percentageFormatter } from 'utils/formatters';

const attributes = [
  'cost',
  'sales',
  'unitsSold',
  'impressions',
  'acos',
  'roas',
  'cr',
  'cpcon',
];

const KeywordTracking = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState({ rows: [] });
  const [selectedKeyword, setSelectedKeyword] = useState({ id: '' });
  const [selectedType, setSelectedType] = useState('branded');
  const [visibleAttributes, setVisibleAttributes] = useState(attributes);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      setRecords([]);
      setSelectedKeyword({ id: '' });
      const response = await axios.get(
        `/account/advertising/${selectedType}-keywords`,
        {
          params: {
            endDate,
            startDate,
            accountId,
            marketplace,
            ...additionalParams,
            attributes: ['id', 'keywordText', ...attributes],
          },
        }
      );

      if (isSubscribed) {
        const { data } = response.data;
        const { count, rows } = data;
        setKeywords(data);

        if (count) {
          setSelectedKeyword(rows[0]);
        }

        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    accountId,
    marketplace,
    startDate,
    endDate,
    additionalParams,
    selectedType,
  ]);

  useEffect(() => {
    if (!selectedKeyword.id) return;
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get(
        `/account/advertising/${selectedType}-keywords/${selectedKeyword.id}/records`,
        {
          params: {
            endDate,
            startDate,
            accountId,
            marketplace,
            attributes,
            ...additionalParams,
          },
        }
      );

      if (isSubscribed) {
        setRecords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    accountId,
    marketplace,
    startDate,
    endDate,
    additionalParams,
    selectedType,
    selectedKeyword,
  ]);

  const handleClick = ({ dataKey }) => {
    setVisibleAttributes(
      visibleAttributes.includes(dataKey)
        ? visibleAttributes.filter((m) => m !== dataKey)
        : [...visibleAttributes, dataKey]
    );
  };

  return (
    !!keywords.rows.length && (
      <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
        <div className="flex items-center justify-between border-b px-8 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25">
            Branded and Non-branded Keywords
          </p>
        </div>

        <div className="px-6 pb-6 mt-5 font-sourceSansPro">
          <div className="grid grid-cols-3">
            <div className="flex items-center font-sourceSansPro">
              <div className="relative z-0 inline-flex rounded-md">
                {['branded', 'non-branded'].map((type, index) => {
                  return (
                    <button
                      key={`keyword-tracking-button-${type}`}
                      type="button"
                      onClick={() => setSelectedType(type)}
                      className={classNames(
                        index ? 'rounded-r-md' : 'rounded-l-md',
                        selectedType === type
                          ? 'bg-grayscale-800 text-white'
                          : 'bg-white text-grayscale-800',
                        'tracking-2 font-bold relative inline-flex items-center py-3 px-8 border-2 tracking-2 border-grayscale-800 text-sm'
                      )}
                    >
                      {startCase(type)}
                    </button>
                  );
                })}
              </div>
            </div>

            {!loading && !!keywords.rows.length && (
              <div className="col-start-3">
                <select
                  value={selectedKeyword.id}
                  onChange={(e) =>
                    setSelectedKeyword(
                      keywords.rows.find(
                        (row) => row.id.toString() === e.target.value.toString()
                      )
                    )
                  }
                  className="w-full bg-grayscale-400 border-0 rounded-2xl px-5 py-3 text-mini font-sourceSansPro text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none"
                >
                  {keywords.rows.map((row) => {
                    return (
                      <option
                        key={`keyword-tracking-options-${row.id}`}
                        value={row.id}
                      >
                        {row.keywordText}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>

          {selectedKeyword.id ? (
            <div className="mt-8">
              <div className="grid grid-cols-8 gap-8 my-8">
                {attributes
                  .map((attr) => metrics.find((m) => m.key === attr))
                  .map((metric, index) => (
                    <div
                      key={`keyword-tracking-metrics-${metric.key}`}
                      className={
                        index === 7 ? '' : 'border-r border-grayscale-500'
                      }
                    >
                      <p className="text-tiny text-grayscale-700 leading-1.5">
                        {metric.title}
                      </p>
                      <div className="flex items-center">
                        {metric.prefix && (
                          <p className="font-inter text-xl text-grayscale-600 tracking-3/4">
                            {metric.prefix}
                          </p>
                        )}
                        <p className="font-inter text-25 text-grayscale-800 tracking-3/4">
                          {metric.formatter(selectedKeyword[metric.key])}
                        </p>
                        {metric.suffix && (
                          <p className="font-inter text-xl text-grayscale-600 tracking-3/4">
                            {metric.suffix}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              <div className="h-64 mt-12">
                <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
                  <ComposedChart data={records}>
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="date" style={{ fontSize: '11px' }} />
                    <YAxis
                      yAxisId="0"
                      orientation="left"
                      stroke="#8884d8"
                      style={{ fontSize: '11px' }}
                      tickFormatter={(tick) => currencyFormatter(tick)}
                    />

                    <YAxis
                      yAxisId="1"
                      orientation="right"
                      stroke="#82ca9d"
                      tickFormatter={(tick) => percentageFormatter(tick)}
                      style={{ fontSize: '11px' }}
                    />

                    <Tooltip
                      content={
                        <CustomTooltip
                          containerStyle={{ width: '28rem' }}
                          containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl"
                          itemsClassName="grid grid-cols-2"
                        />
                      }
                    />

                    <Legend
                      onClick={handleClick}
                      wrapperStyle={{
                        cursor: 'pointer',
                        fontSize: '11px',
                        marginTop: '10rem',
                      }}
                    />
                    {records.length > 3 && (
                      <Brush
                        dataKey="date"
                        data={records}
                        height={15}
                        endIndex={3}
                        className="text-8"
                      />
                    )}

                    {attributes
                      .map((attribute) =>
                        metrics.find((metric) => metric.key === attribute)
                      )
                      .map((metric) => {
                        return metric.chartType === 'bar' ? (
                          <Bar
                            yAxisId="0"
                            barCategoryGap={10}
                            key={`metrics-chart-${metric.key}`}
                            dataKey={metric.key}
                            fill={metric.color}
                            name={metric.title}
                            hide={!visibleAttributes.includes(metric.key)}
                            formatter={metric.rawFormatter}
                          />
                        ) : (
                          <Line
                            yAxisId="1"
                            key={`metrics-chart-${metric.key}`}
                            type="linear"
                            dataKey={metric.key}
                            fill={metric.color}
                            stroke={metric.color}
                            name={metric.title}
                            dot={{ fill: metric.color }}
                            hide={!visibleAttributes.includes(metric.key)}
                            formatter={metric.rawFormatter}
                          />
                        );
                      })}
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          ) : (
            <p className="tracking-2 font-semibold mt-8 text-grayscale-800 text-center text-xl">
              No Keyword Selected
            </p>
          )}
        </div>
      </div>
    )
  );
};

export default KeywordTracking;
