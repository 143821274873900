import Label from 'components/Forms/Label';
import moment from 'moment';
import { LUMPSUM, RECURRING } from 'utils/constants';
import { strDashToSpace } from 'utils/formatters';

const ProposalBlock = ({ proposal, values }) => {
  return (
    <div className="grid grid-cols-3">
      <div className="col-span-2 grid grid-cols-2 gap-x-3 gap-y-5">
        <div className="col-span-2">
          {proposal.proposalInvite && proposal.proposalInvite.proposalId && (
            <Label>
              {`Created: ${moment(
                proposal.proposalInvite.createdAt,
                'YYYY-MM-DD HH:mm:ss'
              ).format('MM-DD-YYYY')}`}
            </Label>
          )}
        </div>
        <div className="col-span-2 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Partner's Name</Label>
          <span>{proposal.companyName}</span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Plan</Label>
          <span>{values.name}</span>
          <sub>({values.plan_code})</sub>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Service Type</Label>
          <span>{values.service}</span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Default Marketplace</Label>
          <span>{values.defaultMarketplace}</span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Payment Method</Label>
          <span className="uppercase">
            {values.paymentOption}
            {values.paymentOption === LUMPSUM && (
              <>&nbsp;({strDashToSpace(values.interval)})</>
            )}
          </span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">
            {values.paymentOption === RECURRING
              ? 'Monthly Retainer'
              : `${values.name} Fee`}
          </Label>
          <span>{values.price}</span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">No. of Service Asins</Label>
          <span>{values.noOfAsins}</span>
        </div>

        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Contract Start Date</Label>
          <span>{values.contractSigned}</span>
        </div>
        <div className="col-span-1 flex flex-col space-y-2 text-sm">
          <Label textColor="text-grayscale-600">Contract End Date</Label>
          <span>{values.noExpiry ? 'Contract never expires' : ''}</span>
        </div>
      </div>
      <div className="col-span-1 mt-12 flex flex-col items-end space-y-2">
        {proposal.acknowledgement && (
          <>
            {proposal.acknowledgement.seenDate && (
              <div className="flex flex-row">
                <div className="bg-green-100 text-green-600 flex rounded-2xl w-36 text-sm items-center py-2 px-4 space-x-2 justify-center">
                  <span>Seen</span>
                </div>
                <Label classes="pt-1 pl-2 text-grayscale-600">
                  {moment(
                    proposal.acknowledgement.seenDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('MM-DD-YYYY')}
                </Label>
              </div>
            )}
            {proposal.acknowledgement.signedDate && (
              <div className="flex flex-row">
                <div className="bg-green-100 text-green-600 flex rounded-2xl w-36 text-sm items-center py-2 px-4 space-x-2 justify-center">
                  <span>Signed</span>
                </div>
                <Label classes="pt-1 pl-2 text-grayscale-600">
                  {moment(
                    proposal.acknowledgement.signedDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('MM-DD-YYYY')}
                </Label>
              </div>
            )}
            {proposal.client.AgencyClient?.status === 'registered' && (
              <div className="flex flex-row">
                <div className="bg-yellow-100 text-yellow-600 flex rounded-2xl w-36 text-sm items-center py-2 px-4 space-x-2 justify-center whitespace-nowrap">
                  <span>Payment Processing</span>
                </div>
                <Label classes="pt-1 pl-2 text-grayscale-600 invisible">
                  {moment(
                    proposal.acknowledgement.signedDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('MM-DD-YYYY')}
                </Label>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProposalBlock;
