import axios from 'axios';
import Modal from 'components/Modal';
import moment from 'moment';
import { XIcon } from '@heroicons/react/outline';
import { useState, useEffect, useCallback } from 'react';
import { Avatar } from 'components';
import { getNameInitials } from 'utils/formatters';
import StarRating from './StarRating';
import ImageViewer from 'react-simple-image-viewer';

const DetailsModal = ({ id, open, setOpen }) => {
  const [loading, setLoading] = useState(true);
  const [review, setReview] = useState({});

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(
        `/agency/clients/performance-ratings/${id}`,
        {
          params: {
            include: ['files', 'addedBy', 'client'],
          },
        }
      );

      if (isSubscribed) {
        setReview(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [id]);

  return (
    <Modal open={open} setOpen={setOpen} as={'div'} align="middle">
      {isViewerOpen && !loading && !!review.files.length && (
        <ImageViewer
          src={review.files.map((s) => s.url)}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{ zIndex: 9999 }}
        />
      )}

      <div className="inline-block w-full max-w-4xl my-8 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="p-8">
          <div className="flex justify-between items-center">
            <h4 className="text-grayscale-900 leading-1.2 font-inter font-bold text-xl tracking-3/4">
              Account Performance Review
            </h4>

            <XIcon
              className="w-5 h-5 text-grayscale-700 cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>

          {!loading && (
            <div className="mt-8">
              <div className="grid grid-cols-3 gap-5 border-b border-grayscale-500 pb-5">
                <div>
                  <p className="text-grayscale-700 text-13 leading-1.5">
                    Partner's Account
                  </p>
                  <p className="text-grayscale-900 text-13 leading-1.5">
                    {review.client?.client}
                  </p>
                </div>

                <div>
                  <p className="text-grayscale-700 text-13 leading-1.5">
                    Reviewer
                  </p>
                  <div className="flex items-center space-x-2">
                    <Avatar
                      imageSrc={review.addedBy?.avatar?.thumbnailUrl}
                      initials={getNameInitials(
                        review.addedBy?.firstName,
                        review.addedBy?.lastName
                      )}
                    />

                    <p className="text-grayscale-900 text-13 leading-1.5">
                      {review.addedBy?.firstName} {review.addedBy?.lastName}
                    </p>
                  </div>
                </div>

                <div>
                  <p className="text-grayscale-700 text-13 leading-1.5">
                    Date Added
                  </p>
                  <p className="text-grayscale-900 text-13 leading-1.5">
                    {moment(review.createdAt).format('MMM DD, YYYY')}
                  </p>
                </div>

                <div>
                  <p className="text-grayscale-700 text-13 leading-1.5">Type</p>
                  <p className="text-grayscale-900 text-13 leading-1.5">
                    {review.type}
                  </p>
                </div>

                <div>
                  <p className="text-grayscale-700 text-13 leading-1.5">
                    Rating
                  </p>
                  <div className="flex items-center space-x-2">
                    <p className="font-bold font-inter text-25 text-grayscale-800">
                      {review.rating}
                    </p>

                    <StarRating
                      value={review.rating}
                      readOnly={true}
                      size={6}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-5 gap-5 mt-4">
                <div className="col-span-3">
                  <p className="font-inter tracking-3/4 text-grayscale-800 font-bold leading-1.2 text-xl mb-3">
                    Files Uploaded
                  </p>

                  <div className="grid grid-cols-3 gap-4">
                    {review.files?.map((image, index) => (
                      <div
                        key={image.id}
                        className="aspect-w-1 aspect-h-1 border rounded-lg w-full"
                      >
                        <img
                          src={image.thumbnailUrl || image.url}
                          alt="Image"
                          className="h-full w-full object-cover"
                          onClick={() => openImageViewer(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-span-2">
                  <p className="font-inter tracking-3/4 text-grayscale-800 font-bold leading-1.2 text-xl mb-3">
                    Additional Notes
                  </p>

                  <p className="text-grayscale-900 text-13 leading-1.5">
                    {review.information}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DetailsModal;
