import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'utils/classNames';
import { usePopper } from 'react-popper';
import { useState } from 'react';

const DropdownMenu = ({
  children,
  title,
  classes,
  buttonBg = 'bg-red-600',
  buttonRounded = 'rounded-md',
  buttonFontWeight = 'font-medium',
  buttonTextSize = 'text-sm',
  buttonFocus = 'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
  buttonClasses = '',
  hoverClasses = 'bg-red-700',
  hoverText = '',
  textColor = 'text-white',
  titleClasses = 'mr-2 flex',
  hideArrow = false,
  dropdownWidth = 'w-56',
  padding = 'px-4 py-2',
  position = 'sm:left-0',
  zIndex = '',
  justifyStart = false,
  placement = 'bottom-start',
  popperStyling = true,
  containerClassName = '',
}) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  return (
    <Menu
      as="div"
      className={classNames(classes, `relative inline-block text-left`)}
    >
      <div className={containerClassName}>
        <Menu.Button
          ref={setReferenceElement}
          className={classNames(
            padding,
            buttonFontWeight,
            textColor,
            buttonBg,
            buttonRounded,
            hoverClasses !== '' ? `hover:${hoverClasses}` : '',
            hoverText,
            buttonFocus,
            buttonTextSize,
            !justifyStart ? 'justify-center' : '',
            'inline-flex  w-full',
            buttonClasses
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {title ? <div className={titleClasses}>{title}</div> : ''}
          {!hideArrow && (
            <ChevronDownIcon
              className="w-5 h-5 -mr-1 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
      </div>

      <Menu.Items
        ref={setPopperElement}
        style={popperStyling ? styles.popper : {}}
        {...attributes.popper}
        className={classNames(
          'absolute right-0',
          position,
          dropdownWidth,
          zIndex,
          'mt-0 z-20 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Menu.Items>
    </Menu>
  );
};

export default DropdownMenu;
