import axios from 'axios';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import DatePicker from 'features/datePicker/DatePicker';

import { useParams } from 'react-router-dom';

import Overview from '../components/Overview';
import AccountList from '../components/AccountList';
import ClientsSummary from '../components/ClientsSummary';

const UserDashboard = () => {
  const { userId } = useParams();

  const [user, setUser] = useState({});

  useEffect(() => {
    axios
      .get(`/users/${userId}`, {
        params: {
          attributes: [
            'userId',
            'firstName',
            'lastName',
            'totalClients',
            'totalOnHoldClients',
            'totalActiveClients',
            'totalPendingClients',
            'totalTerminatedClients',
            'totalOnboardingClients',
            'totalServiceAsins',
          ],
        },
      })
      .then((response) => {
        setUser(response.data.data);
      });
  }, [userId]);

  return (
    <div>
      <p className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
        Account Performance Overview
      </p>

      <div className="flex justify-end items-center mt-4">
        <DatePicker showLabel={false} />
      </div>

      {!isEmpty(user) && (
        <div>
          <Overview
            title={`${user.firstName} ${user.lastName}`}
            totalClients={user.totalClients}
            totalTerminatedClients={user.totalTerminatedClients}
            totalServiceAsins={user.totalServiceAsins}
          />

          <AccountList agencyClientAssigneeUserId={user.userId} />

          {!!user.totalClients && (
            <ClientsSummary
              title={`${user.firstName} ${user.lastName}`}
              totalClients={user.totalClients}
              totalOnHoldClients={user.totalOnHoldClients}
              totalActiveClients={user.totalActiveClients}
              totalPendingClients={user.totalPendingClients}
              totalTerminatedClients={user.totalTerminatedClients}
              totalOnboardingClients={user.totalOnboardingClients}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
