import { useEffect, useState } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import { Table } from 'components';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dateFormatterUTC } from 'utils/formatters';
import Badge from 'components/Badge';
import { SearchIcon } from '@heroicons/react/solid';
import {
  MailIcon,
  CheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import TabNav from 'components/TabNav';
import useQueryParams from 'hooks/useQueryParams';
import Button from 'components/Button';
import useAlert from 'hooks/useAlert';
import UpsellReviewModal from './UpsellReviewModal';
import useUpsell from 'hooks/useUpsell';
import UpsellsExporter from './components/UpsellsExporter';
import { columnClasses } from 'utils/table';
import Confirm from 'components/Confirm';
import upsellsEmpty from 'assets/images/upsells-empty.svg';
import UserAvatar from './components/UserAvatar';

const Upsells = () => {
  const { STATUS } = useUpsell();
  const { upsellsPaginationParams } = useSelector((state) => state.upsells);
  const { params, updateParams } = useQueryParams(upsellsPaginationParams);
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [upsells, setUpsells] = useState(null);
  const [selectedUpsell, setSelectedUpsell] = useState(null);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [mailing, setMailing] = useState(false);

  const [menuTabs, setMenuTabs] = useState([
    {
      displayName: 'Awaiting Approval',
      name: 'awaiting approval',
      current: params.status == STATUS.AWAITING_APPROVAL,
      visible: true,
    },
    {
      displayName: 'Approved',
      name: 'approved',
      current: params.status == STATUS.APPROVED,
      visible: true,
    },
    {
      displayName: 'Pending',
      name: 'pending',
      current: params.status == STATUS.PENDING,
      visible: true,
    },
    {
      displayName: 'Paid',
      name: 'paid',
      current: params.status == STATUS.PAID,
      visible: true,
    },
    {
      displayName: 'Rejected',
      name: 'rejected',
      current: params.status == STATUS.REJECTED,
      visible: true,
    },
  ]);

  const getUpsells = async () => {
    setLoading(true);
    await axios.get(`/agency/upsell`, { params }).then((res) => {
      setUpsells(res.data.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) getUpsells();
  }, [params]);

  const updateStatus = (status) => {
    updateParams({
      filter: status.toLowerCase(),
      page: 1,
    });
  };
  const onChangeInput = (status) => {
    updateParams({
      search: status.toLowerCase(),
      page: 1,
    });
  };

  const tableColumns = [
    {
      dataField: 'service',
      text: 'Service',
      sort: false,
      headerStyle: {
        minWidth: '250px',
      },
      formatter: (cell, row) => {
        return (
          <button
            className="font-normal uppercase flex flex-col underline text-secondary-light text-sm text-left"
            onClick={() => {
              setSelectedUpsell(row);
              setIsOpenReviewModal(true);
            }}
          >
            {row.details.name}
          </button>
        );
      },
      visible: true,
    },
    {
      dataField: 'agencyClientId',
      text: 'Client Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/clients/${row.agencyClientId}/dashboard`}
            className="text-secondary-light underline text-sm"
          >
            {row.agencyClient?.client}
          </Link>
        );
      },
      visible: true,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: false,
      headerStyle: {
        minWidth: '100px',
        textAlign: 'right',
      },
      classes: `${columnClasses} text-right`,
      formatter: (cell, row) => {
        return (
          <span className="font-normal text-right">
            <span className="text-grayscale-600">$</span>&nbsp;
            {row.totalAmount.toFixed(2)}
          </span>
        );
      },
      visible: true,
    },
    {
      dataField: 'invoiceNumber',
      text: 'Invoice Number',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <Link
            to={`/invoices/${row.invoiceId}`}
            className="text-secondary-light underline text-sm"
          >
            {row.invoiceNumber}
          </Link>
        );
      },
      visible:
        params.status === STATUS.PAID || params.status === STATUS.PENDING
          ? true
          : false,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        width: '180px',
        textAlign: 'center',
      },
      classes: `${columnClasses} text-center`,
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              color={classnames({
                orange: cell === STATUS.AWAITING_APPROVAL,
                gray: cell === STATUS.DRAFT,
                blue: cell === STATUS.APPROVED,
                yellow:
                  cell === STATUS.SENT ||
                  cell === STATUS.OVERDUE ||
                  cell === STATUS.PENDING,
                green: cell === STATUS.PAID,
                red: cell === STATUS.REJECTED,
              })}
              classes="capitalize text-center w-full justify-center"
              rounded="full"
              padding="py-2"
              textSize="sm"
              fontFamily=""
            >
              {cell}
            </Badge>
            {cell === STATUS.PENDING && row.seenAt && (
              <div className="flex justify-center items-center mt-1 relative transform -translate-x-2">
                <CheckIcon className="w-4 h-4 inline text-success relative -right-3" />
                <CheckIcon className="w-4 h-4 inline text-success" />
                <p className="text-xs text-success">seen</p>
              </div>
            )}
          </>
        );
      },
      visible: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      sort: true,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '180px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{dateFormatterUTC(row.createdAt)}</span>
        );
      },
      visible: true,
    },
    {
      dataField: 'requestedBy',
      text: 'Submitted By',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <UserAvatar user={row.requestedByUser} />;
      },
      visible: true,
    },
    {
      dataField: 'approvedBy',
      text: 'Approver',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return row.approvedByUser ? (
          <UserAvatar user={row.approvedByUser} />
        ) : (
          <>&mdash;</>
        );
      },
      visible:
        params.status === STATUS.AWAITING_APPROVAL ||
        params.status === STATUS.REJECTED
          ? false
          : true,
    },
    {
      dataField: 'rejectedBy',
      text: 'Rejector',
      sort: false,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return row.rejectedByUser ? (
          <UserAvatar user={row.rejectedByUser} />
        ) : (
          <>&mdash;</>
        );
      },
      visible: params.status === STATUS.REJECTED ? true : false,
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      classes: `${columnClasses} text-center`,
      headerStyle: {
        minWidth: '140px',
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        switch (row.status) {
          case STATUS.AWAITING_APPROVAL:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setIsOpenReviewModal(true);
                }}
              >
                <SearchIcon className="w-3.5 h-3.5 inline mr-2" /> Review
              </Button>
            );
          case STATUS.PENDING:
          case STATUS.SENT:
          case STATUS.OVERDUE:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setIsOpenEmail(true);
                }}
              >
                <MailIcon className="w-3.5 h-3.5 inline mr-2" /> Resend
              </Button>
            );
          case STATUS.APPROVED:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {
                  setSelectedUpsell(row);
                  setIsOpenEmail(true);
                }}
              >
                <MailIcon className="w-3.5 h-3.5 inline mr-2" /> Send
              </Button>
            );
          default:
            return (
              <Button
                classes="w-full justify-center rounded-xl text-grayscale-700 tracking-widest font-bold py-2 hover:text-grayscale-800 hover:border-grayscale-800 transition-all ease-in duration-75 disabled:opacity-30"
                border=" border-2 border-grayscale-700"
                color=""
                hoverColor="grayscale-500"
                px=""
                onClick={() => {}}
                disabled={true}
              >
                <DocumentTextIcon className="w-3.5 h-3.5 inline mr-2" /> View
              </Button>
            );
        }
      },
      visible: true,
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage && sizePerPage,
      sort: sortField ? `${sortField}:${sortOrder}` : 'createdAt:desc',
    });
  };

  const emailUpsell = async () => {
    setMailing(true);
    try {
      await axios.post(`agency/upsell/${selectedUpsell.upsellId}/send`, {
        subscriptionId:
          selectedUpsell.agencyClient.account.subscription.subscriptionId,
      });
      alertSuccess('success', 'Email send successfuly');
      setIsOpenEmail(false);
      getUpsells();
    } catch (error) {
      alertError('error', 'Something went wrong !');
    } finally {
      setMailing(false);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-center gap-8 items-center">
          <h2 className="text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate font-inter py-5 border-b-2 border-transparent">
            Upsells
          </h2>

          <div className="flex justify-center gap-8 items-center">
            <div className="flex flex-row sm:flex-col lg:flex-row h-12 gap-1">
              <select
                id="filter"
                label="Filter"
                // value={params.filter}
                className="h-full rounded-l-xl w-40 border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
                onChange={(e) => updateStatus(e.target.value)}
              >
                {[
                  {
                    label: 'Name',
                    value: 'name',
                  },
                  {
                    label: 'Client Name',
                    value: 'clientName',
                  },
                  {
                    label: 'Submitted by',
                    value: 'submittedBy',
                  },
                ].map((status, i) => (
                  <option
                    key={i}
                    value={status.value}
                    disabled={status.disabled ?? ''}
                    className={status.disabled ? 'bg-red-50' : ''}
                  >
                    {status.label}
                  </option>
                ))}
              </select>
              <div className="h-full min-h-12 relative">
                <div className="h-full absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">
                    <SearchIcon className="w-4 h-4" />
                  </span>
                </div>
                <input
                  type="text"
                  className="h-full rounded-r-xl w-60 pl-12 border-0 sm:text-sm appearance-none focus:ring-0 focus:border-0 focus:appearance-none  disabled:bg-gray-100"
                  placeholder={'Search'}
                  onChange={(e) => onChangeInput(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-8">
          <UpsellsExporter />
        </div>
      </div>

      <TabNav
        tabs={menuTabs}
        setTabs={setMenuTabs}
        onSelectChange={(e) => updateStatus(e.target.value)}
        onClick={(selectedTab) =>
          updateParams({
            status: selectedTab.name.toLowerCase(),
            page: 1,
            sizePerPage: params.pageSize,
          })
        }
        theme="tabbed"
        displayName={true}
      />

      <Table
        columns={tableColumns.filter((t) => t.visible)}
        data={upsells}
        onTableChange={onTableChange}
        params={params}
        keyField="upsellId"
        loading={loading}
        rowClasses="my-4 text-left"
        noDataDisplay={
          params.status === STATUS.AWAITING_APPROVAL ? (
            <div className="flex flex-col justify-center items-center space-y-8 py-12">
              <img
                src={upsellsEmpty}
                alt="No data"
                width="220"
                className="inline"
              />
              <span className="text-lg font-inter tracking-3/4">
                You don’t have anything to approve as of now
              </span>
            </div>
          ) : null
        }
      />

      <Confirm
        title={
          params.status === STATUS.AWAITING_APPROVAL ? (
            <div className="flex flex-col text-center">
              <div className=" text-custom-green">
                <span className="border-2 border-custom-green rounded-full py-3.5 px-1">
                  <CheckIcon className="w-12 h-12 inline" />
                </span>
              </div>
              <h3 className="text-2xl font-bold mt-4">
                This upsell request has been approved
              </h3>
            </div>
          ) : (
            'Are you sure you want to resend the billing summary invoice email to the client?'
          )
        }
        content={
          params.status === STATUS.AWAITING_APPROVAL
            ? 'Do you want to send the client the billing summary email?'
            : `${selectedUpsell?.agencyClient?.defaultContact?.email}`
        }
        open={isOpenEmail}
        setOpen={setIsOpenEmail}
        onOkClick={emailUpsell}
        onCancelClick={() => setIsOpenEmail(false)}
        okText={
          params.status === STATUS.AWAITING_APPROVAL ? 'Send Email' : 'Resend'
        }
        cancelText={
          params.status === STATUS.AWAITING_APPROVAL ? 'No' : 'Cancel'
        }
        maxWidth="max-w-lg"
        showOkLoading={true}
        okLoading={mailing}
      />

      <UpsellReviewModal
        open={isOpenReviewModal}
        setOpen={setIsOpenReviewModal}
        upsell={selectedUpsell}
        getUpsells={getUpsells}
        setIsOpenEmail={setIsOpenEmail}
        isOpenEmail={isOpenEmail}
        type="reviewer"
      />
    </>
  );
};

export default withRouter(Upsells);
