import { AdjustmentsIcon } from '@heroicons/react/outline';

const SmartFilterButton = ({ display, count, onClick }) => {
  return (
    <button
      className="w-full relative flex justify-around items-center rounded-2xl border-0 shadow-xs px-6 py-4 bg-white text-mini text-grayscale-900 focus:outline-none focus:ring-0 appearance-none focus:appearance-none"
      onClick={onClick}
    >
      <AdjustmentsIcon className="h-4 w-4 mr-2" />
      {display}
      {count > 0 && (
        <div className="flex items-center justify-center h-6 w-6 absolute text-white rounded-full border-2 border-white -right-3 -top-3 bg-red-600">
          <span className="text-tiny">{count}</span>
        </div>
      )}
    </button>
  );
};

export default SmartFilterButton;
