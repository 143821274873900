import axios from 'axios';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useState, useEffect } from 'react';

import {
  ChurnRate,
  UpsellRate,
  ChartsFilter,
  UpsellAmount,
  MMRDifference,
  RevenueChurnRate,
  AnalysisSnapshot,
  AnalysisBreakdown,
  NetRevenueChurnRate,
} from './components';

import useChurn from 'features/churn/useChurn';
import ClientModal from 'features/churn/components/ClientModal';
import ReferralRate from './components/ReferralRate';

const Overview = () => {
  const { calculateChurn } = useChurn();

  const [summary, setSummary] = useState({});
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(moment().format('YYYY'));

  const [openClientList, setOpenClientList] = useState(false);
  const [filterGroups, setFilterGroups] = useState('[]');

  const [visibleCharts, setVisibleCharts] = useState([
    { key: 'Churn Rate', visible: true },
    { key: 'Revenue Churn Rate', visible: true },
    { key: 'Churn Analysis Snapshot', visible: true },
    // Hide this sections for now.
    // { key: 'Net Revenue Churn Rate', visible: true },
    // { key: 'MRR Beginning and MRR End', visible: true },
    // { key: 'Upsell Rate', visible: true },
    // { key: 'Upsell Value', visible: true },
    // { key: 'Churn Analysis Breakdown', visible: true },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const startDate = moment(year).startOf('year').format('YYYY-MM-DD');
    const endDate = moment(year).endOf('year').format('YYYY-MM-DD');

    const fetchData = async () => {
      setLoading(true);

      const [summaryData, recordsData] = await Promise.all(
        ['summary', 'records'].map(async (resource) => {
          let endpoint = `/v2/agency/clients/analytics/${resource}`;

          const response = await axios.get(endpoint, {
            params: {
              startDate,
              endDate,
              withPrevious: true,
              subscriptionPaymentOption: 'Recurring',
              attributes: [
                'newClients',
                'totalClients',
                'upsoldAmount',
                'upsoldClients',
                'monthlyRetainer',
                'terminatedClients',
                'lostMonthlyRetainer',
                'gainedMonthlyRetainer',
                'beginningClients',
                'beginningMonthlyRetainer',
                'inactiveClients',
                'referredClients',
              ],
            },
          });

          return response.data.data;
        })
      );

      if (isSubscribed) {
        setSummary(calculateChurn(summaryData));
        setRecords(recordsData.map((record) => calculateChurn(record)));
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [year]);

  return (
    <div>
      {openClientList && (
        <ClientModal
          open={openClientList}
          setOpen={setOpenClientList}
          filterGroups={filterGroups}
        />
      )}

      <div className="flex justify-between items-center my-6">
        <p className="text-xl font-inter tracking-3/4 text-grayscale-900 font-bold">
          Overview
        </p>

        <div className="flex items-center space-x-4">
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="text-13 text-grayscale-800 bg-white leading-1.5 border-0 outline-none w-48 px-4 py-3 rounded-xl focus:outline-none focus:ring-0"
          >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </select>

          <ChartsFilter
            options={cloneDeep([...visibleCharts])}
            setVisibleCharts={setVisibleCharts}
          />
        </div>
      </div>

      <div className="grid xl:grid-cols-2 gap-8">
        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('Churn Rate') && (
          <ChurnRate
            year={year}
            loading={loading}
            summary={summary}
            records={records}
            setOpenClientList={setOpenClientList}
            setClientListFilterGroups={setFilterGroups}
          />
        )}

        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('Net Revenue Churn Rate') && (
          <NetRevenueChurnRate
            loading={loading}
            summary={summary}
            records={records}
            setOpenClientList={setOpenClientList}
            setClientListFilterGroups={setFilterGroups}
          />
        )}

        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('Revenue Churn Rate') && (
          <RevenueChurnRate
            year={year}
            loading={loading}
            summary={summary}
            records={records}
            setOpenClientList={setOpenClientList}
            setClientListFilterGroups={setFilterGroups}
          />
        )}

        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('MRR Beginning and MRR End') && (
          <MMRDifference
            loading={loading}
            summary={summary}
            records={records}
            setOpenClientList={setOpenClientList}
            setClientListFilterGroups={setFilterGroups}
          />
        )}

        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('Upsell Rate') && (
          <UpsellRate loading={loading} summary={summary} records={records} />
        )}

        {visibleCharts
          .filter(({ visible }) => visible)
          .map(({ key }) => key)
          .includes('Upsell Value') && (
          <UpsellAmount loading={loading} summary={summary} records={records} />
        )}
      </div>

      <ReferralRate
        loading={loading}
        records={records}
        summary={summary}
        setOpenClientList={setOpenClientList}
        setClientListFilterGroups={setFilterGroups}
      />

      {visibleCharts
        .filter(({ visible }) => visible)
        .map(({ key }) => key)
        .includes('Churn Analysis Snapshot') && (
        <AnalysisSnapshot
          loading={loading}
          records={records}
          setOpenClientList={setOpenClientList}
          setClientListFilterGroups={setFilterGroups}
        />
      )}

      {visibleCharts
        .filter(({ visible }) => visible)
        .map(({ key }) => key)
        .includes('Churn Analysis Breakdown') && (
        <AnalysisBreakdown
          loading={loading}
          records={records}
          setOpenClientList={setOpenClientList}
          setClientListFilterGroups={setFilterGroups}
        />
      )}
    </div>
  );
};

export default Overview;
