import { ExclamationIcon } from '@heroicons/react/outline';
import { useTermination } from 'features/termination';

const TerminatedClient = ({
  router,
  notification,
  markAsRead,
  children,
  className = '',
}) => {
  const { data, message, title } = notification;

  const { STATUS } = useTermination();

  const path =
    data.status === STATUS.PENDING
      ? `/clients/fortermination`
      : `/clients/redirect/${data.agencyClientId}`;

  const onClick = () => {
    markAsRead();
    router.push(path);
  };

  return (
    <div className={`grid grid-cols-8 gap-3 ${className}`} onClick={onClick}>
      <div className="rounded-full bg-error flex items-center justify-center w-12 h-12">
        <ExclamationIcon className="w-8 h-8 text-white" />
      </div>

      <div className="col-span-6">
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">{title}</p>
        <p className="text-grayscale-900 text-base leading-1.5">{message} </p>
        <p className="text-grayscale-800 text-13 mb-0 leading-1.5">
          Reason: {data.reason}
        </p>
      </div>

      {children}
    </div>
  );
};

export default TerminatedClient;
