import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Modal = ({
  open,
  setOpen,
  children,
  align = 'center',
  noOverlayClick = false,
  as = null,
  persistent,
  showOverlay = true,
  zIndex = 'z-10',
}) => {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      as="div"
      className={`fixed inset-0 ${zIndex} flex items-center justify-center overflow-y-auto`}
    >
      <div className="flex w-full items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {showOverlay && (
          <Dialog.Overlay
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
              noOverlayClick ? 'pointer-events-none' : ''
            }`}
          />
        )}

        {align === 'center' && (
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
        )}

        {children}
      </div>
    </Dialog>
  );
};

export default Modal;
