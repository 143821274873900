import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import Card from 'components/Card';
import { DocumentTextIcon } from '@heroicons/react/outline';

const generateDistinctColors = (count, hue) => {
  const colors = [];
  const goldenRatio = 0.618033988749895;
  for (let i = 0; i < count; i++) {
    hue += goldenRatio;
    hue %= 1;
    const color = `hsl(${Math.floor(hue * 360)}, 70%, 50%)`;
    colors.push(color);
  }
  return colors;
};

const MarketplaceChart = ({
  title,
  mpBookedData,
  mpResponseData,
  mpClosedData,
}) => {
  // Convert object to array
  const chartBookedData = Object.values(mpBookedData);
  const chartResponseData = Object.values(mpResponseData);
  const chartClosedData = Object.values(mpClosedData);

  const distinctColorsBooked = generateDistinctColors(
    chartBookedData.length,
    Math.random()
  );

  const distinctColorsResponse = generateDistinctColors(
    chartResponseData.length,
    Math.random()
  );

  const distinctColorsClosed = generateDistinctColors(
    chartClosedData.length,
    Math.random()
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataItem = payload[0].payload;
      return (
        <div className="bg-white shadow-md p-2 border border-gray-300 rounded">
          <p>{dataItem.label}</p>
          <p>{`${dataItem.name} : ${dataItem.value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <div className="flex justify-center">
        <div className="w-1/2 flex justify-center">
          <div>
            <h2 className="text-center text-xl font-bold">
              <DocumentTextIcon className="inline w-5 h-5 mb-1" /> {title}
            </h2>
            <div className="pt-4 text-center">
              <span className="text-red-600 px-4">Outer: Responses</span>
              <span className="text-blue-600 px-4">Middle: Bookings</span>
              <span className="text-green-600 px-4">Inner: Closed Deals</span>
            </div>

            <PieChart width={300} height={300}>
              {/* Closed Deals (Innermost Pie) */}
              <Pie
                data={chartClosedData}
                cx={150}
                cy={150}
                innerRadius={20}
                outerRadius={50}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label
              >
                {chartClosedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={distinctColorsClosed[index]}
                  />
                ))}
              </Pie>

              {/* Bookings (Middle Pie) */}
              <Pie
                data={chartBookedData}
                cx={150}
                cy={150}
                innerRadius={50}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label
              >
                {chartBookedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={distinctColorsBooked[index]}
                  />
                ))}
              </Pie>

              {/* Responses (Outer Pie) */}
              <Pie
                data={chartResponseData}
                cx={150}
                cy={150}
                innerRadius={80}
                outerRadius={95}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                label
              >
                {chartResponseData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={distinctColorsResponse[index]}
                  />
                ))}
              </Pie>

              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MarketplaceChart;
