import { useDispatch, useSelector } from 'react-redux';

import Toggle from 'components/Forms/Toggle';
import PageHeader from 'components/PageHeader';
import { setAlert } from 'features/alerts/alertsSlice';
import { updateMaintenanceSettings } from 'features/settings/settingsSlice';
import { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import Loading from 'components/Loading';

const QboIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [qbo, setQbo] = useState(null);
  const qboAuthUrl = `${process.env.REACT_APP_API_BASE_URL}/qbo/authorize`;

  const checkQboConnection = async () => {
    setLoading(true);
    await axios
      .get('/qbo/companyinfo')
      .then((res) => setQbo(res.data.data))
      .catch((error) => setQbo(null))
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    checkQboConnection();
  }, []);

  const disconnect = async () => {
    setLoading(true);
    await axios
      .get('/qbo/disconnect')
      .then((res) => setQbo(null))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const connect = () => {
    window.location.href = qboAuthUrl;
  };

  return !loading ? (
    <div className="flex items-center ">
      {qbo ? (
        <>
          QBO Connected
          <button
            type="button"
            className="ml-4 border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
            onClick={disconnect}
          >
            Disconnect
          </button>
        </>
      ) : (
        <>
          Connect to QBO
          <button
            type="button"
            className="ml-4 border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
            onClick={connect}
          >
            Authorize
          </button>
        </>
      )}
    </div>
  ) : (
    <div className="flex items-center ">
      Checking QBO connection
      <Loading />
    </div>
  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const { maintenance } = useSelector((state) => state.settings);

  const onChange = (value) => {
    dispatch(updateMaintenanceSettings(value)).then(({ error }) => {
      if (error) {
        dispatch(setAlert('error', 'Failed to update maintenance status'));
      }
    });
  };

  return (
    <div>
      <PageHeader title="System Settings" />

      <div className="flex flex-col items-start text-left mt-12 space-y-12">
        <label className="flex items-center mr-4 text-grayscale-900 text-base font-inter font-bold tracking-3/4">
          App Maintenance Mode&nbsp;&nbsp;&nbsp;&nbsp;
          <Toggle checked={maintenance} onChange={onChange} />
        </label>
        <QboIntegration />
      </div>
    </div>
  );
};

export default Settings;
