import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useTasks } from 'Version2/features/tasks';
import { TaskGroup } from 'Version2/features/tasks/components';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';

import classNames from 'utils/classNames';

const MyWorkspace = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('All');
  const [pageSize, setPageSize] = useState(10);
  const { taskItemList, getTaskItemList } = useTasks();

  const tabs = [
    {
      text: 'All',
      filters: [],
    },
    {
      text: 'Overdue',
      filters: [
        {
          attribute: 'dueDate',
          operator: 'LTE',
          value: moment.tz(moment.tz.guess()).format(),
        },
      ],
    },
    {
      text: 'Due Today',
      filters: [
        {
          attribute: 'dueDate',
          operator: 'BETWEEN',
          value: [
            moment.tz(moment.tz.guess()).startOf('d').format(),
            moment.tz(moment.tz.guess()).endOf('d').format(),
          ],
        },
      ],
    },
    {
      text: 'Due Tomorrow',
      filters: [
        {
          attribute: 'dueDate',
          operator: 'BETWEEN',
          value: [
            moment.tz(moment.tz.guess()).add(1, 'd').startOf('d').format(),
            moment.tz(moment.tz.guess()).add(1, 'd').endOf('d').format(),
          ],
        },
      ],
    },
    {
      text: 'Due This Week',
      filters: [
        {
          attribute: 'dueDate',
          operator: 'BETWEEN',
          value: [
            moment.tz(moment.tz.guess()).startOf('w').format(),
            moment.tz(moment.tz.guess()).endOf('w').format(),
          ],
        },
      ],
    },
  ];

  useEffect(() => refreshList(), [selectedTab, pageSize]);

  const refreshList = () => {
    getTaskItemList({
      filterGroups: JSON.stringify([
        {
          filters: [
            ...(tabs.find((tab) => tab.text === selectedTab)?.filters ?? []),
            {
              attribute: 'isSubtask',
              operator: 'EQ',
              value: false,
            },
            {
              attribute: 'isAssignedToMe',
              operator: 'EQ',
              value: true,
            },
          ],
        },
      ]),
      pageSize,
      sorts: 'updatedAt',
      attributes: [
        'due',
        'name',
        'taskItemId',
        'taskListId',
        'dueDate',
        'status',
        'priority',
        'hasSubtasks',
        'parentTaskItemId',
        'hierarchyLevelId',
      ],
      include: [
        'assignees[avatar]',
        'subtasks[assignees[avatar]]',
        'list[client[brandStrategist[user[avatar]],accountSpecialist[user[avatar]]]]',
      ],
    });
  };

  return (
    <div className="py-4">
      <h3 className="font-inter leading-1.2 tracking-3/4 text-grayscale-900 font-bold text-xl mt-2">
        My Upcoming Deadlines
      </h3>

      <div className="border-b border-grayscale-500 mt-4">
        <nav
          className="-mb-px flex space-x-8 text-grayscale-800"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <div
              key={tab.text}
              onClick={() => setSelectedTab(tab.text)}
              className={classNames(
                'cursor-pointer text-13 flex items-center whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm border-transparent leading-1.5',
                selectedTab === tab.text
                  ? 'border-secondary-light font-bold text-secondary-light'
                  : ''
              )}
            >
              {tab.text}
            </div>
          ))}
        </nav>
      </div>

      <div className="mt-6">
        <TaskGroup
          isLoading={taskItemList.loading}
          title="My Tasks"
          showEmptyState={true}
          onItemClick={(task) =>
            history.push(`/home/my-workspace/${task.taskItemId}`)
          }
          taskItemList={taskItemList.data}
          onLoadMore={() => setPageSize(taskItemList.data.pageSize + 10)}
          itemsConfig={{
            onClick: (task) =>
              history.push(`/home/my-workspace/${task.taskItemId}`),
          }}
        />
      </div>

      <Switch>
        <Route
          path="/home/my-workspace/:taskItemId"
          exact={true}
          render={() => {
            return <TaskDetailsPage onChange={() => refreshList()} />;
          }}
        />
      </Switch>
    </div>
  );
};

export default MyWorkspace;
