import axios from 'axios';
import { debounce } from 'lodash';
import { DndProvider } from 'react-dnd';
import { useEffect, useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { getClientSpace } from 'features/tasks/tasksSlice';

import InputPrepend from 'components/Forms/InputPrepend';
import TaskGroup from 'features/tasks/components/workspace/TaskGroup';

import useBodyClass from 'hooks/useBodyClass';
import useQueryParams from 'hooks/useQueryParams';
import useTaskCategories from 'hooks/useTaskCategories';

import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import { TASK_FOLDERS } from 'utils/constants';

const groups = [
  { label: 'Status', value: 'status' },
  { label: 'Priority', value: 'priority' },
  { label: 'Due Date', value: 'due' },
];

const statuses = [
  {
    order: 1,
    type: 'open',
    name: 'Pending',
    color: '#002F5D',
    bgColor: '#D3E4F5',
    params: { filter: { status: 'Pending' } },
  },
  {
    order: 2,
    type: 'open',
    name: 'In Progress',
    color: '#BFA654',
    bgColor: '#FFEFD0',
    params: { filter: { status: 'In Progress' } },
  },
  {
    order: 3,
    type: 'closed',
    name: 'Done',
    color: '#00966D',
    bgColor: '#CFFFDD',
    params: { filter: { status: 'Done' } },
  },
];

const Workspace = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { salesClientId } = useParams();

  const { priorities, due } = useTaskCategories();
  const { boardView } = useSelector((state) => state.tasks);
  useBodyClass(['tasks-light', 'tasks', boardView]);

  const [users, setUsers] = useState([]);
  const [reload, setReload] = useState(false);
  const [taskListOptions, setTaskListOptions] = useState([]);
  const [taskListId, setTaskListId] = useState(null);

  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const updateGroupBy = (value) => {
    updateParams({ ...params, groupBy: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      const taskSpace = await dispatch(
        getClientSpace(salesClientId, { include: ['folders'] })
      );

      if (taskSpace) {
        const folder = taskSpace.folders.find(
          (f) => f.name === TASK_FOLDERS.operations
        );
        setTaskListId(folder.list[0].taskListId);
      }
    };

    fetchData();
  }, [dispatch, salesClientId]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/users', {
        params: { roleLevel: 'agency', pageSize: 1000 },
      });

      if (isSubscribed) {
        setUsers(response.data.data.rows);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [dispatch]);

  return (
    <div className="">
      <div className="sm:grid grid-cols-12 mb-4">
        <div className="col-span-4">
          <InputPrepend
            name="search"
            defaultValue={params?.search}
            onChange={(e) => onDebouncedSearch(e)}
            type="text"
            placeholder={'Search Tasks'}
            prependText={<SearchIcon className="w-4 h-4" />}
            border="border-grayscale-500"
          />
        </div>

        <div className="flex col-span-6 col-start-7 items-center justify-end">
          <label className="text-sm text-gray-500">Group By</label>
          <select
            label="status"
            value={params.groupBy}
            className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
            onChange={(e) => updateGroupBy(e.target.value)}
          >
            {groups.map((group, i) => (
              <option key={i} value={group.value}>
                {group.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!!taskListId && (
        <div>
          <DndProvider backend={HTML5Backend}>
            <div className="space-y-3 list-container">
              {{
                due,
                status: statuses,
                priority: priorities,
              }[params.groupBy]?.map((category) => (
                <TaskGroup
                  reload={reload}
                  taskListId={taskListId}
                  groupKey={params.groupBy}
                  withTaskType={true}
                  setReload={setReload}
                  boardView={boardView}
                  key={category.name}
                  canDragInBoardView={true}
                  canDragInListView={true}
                  title={category.name}
                  titleColor={category.color}
                  titleBgColor={category.bgColor}
                  assigneeOptions={users}
                  allowAddTask={true}
                  defaultStatus={statuses[0].name}
                  statusOptions={statuses}
                  taskListOptions={taskListOptions}
                  params={{
                    pageSize: 5,
                    sort: 'updatedAt',
                    search: params.search,
                    taskListId,
                    ...(category.params.filter ?? {}),
                    taskListCodes: ['client_operation_task_list'],
                    scopes: [
                      'underSalesClient',
                      ...(category.params.scopes ?? []),
                    ],
                    include: [
                      'list.folder.space.salesClient',
                      'subtasks',
                      'assignees',
                    ],
                  }}
                  taskRowColumns={[
                    { key: 'assignees', className: 'col-span-2' },
                    { key: 'dueDate', className: 'col-span-2' },
                    { key: 'priority', className: 'col-span-1' },
                    { key: 'type', className: 'col-span-1' },
                  ]}
                />
              ))}
            </div>
          </DndProvider>
        </div>
      )}

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={() => {
                  updateParams({
                    search: '',
                    groupBy: 'status',
                  });
                  setReload(!reload);
                }}
                assigneeOptions={users}
                notifyAssignee={true}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default Workspace;
