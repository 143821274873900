/* profile tabs css */

export const linkStyles =
  'capitalize py-2 w-32 border-t border-l border-r rounded-t-md text-center text-sm';

export const setTabStyle = (cTab, tabs, pathname) => {
  const index = tabs.findIndex((tab) => tab.tab === cTab.tab);
  const currentIndex = tabs.findIndex((tab) => tab.url === pathname);
  const lastIndex = tabs.length - 1;
  let className = [];

  if (currentIndex === index) {
    className.push('current');
    if (index === 0) {
      className.push('left');
    } else if (index === lastIndex) {
      className.push('right');
    } else {
      className.push('middle');
    }
  } else {
    const isBefore = currentIndex - 1 === index;
    const isAfter = currentIndex + 1 === index;
    const isCurrentFirst = currentIndex === 0;
    const isCurrentLast = currentIndex === lastIndex;
    const isCurrentMiddle = currentIndex > 0 && currentIndex < lastIndex;

    if (isCurrentMiddle) {
      if (isBefore) {
        className.push('tail-right');
      }
      if (isAfter) {
        className.push('tail-left');
      }
    } else if (isCurrentFirst && isAfter) {
      className.push('tail-left');
    } else if (isCurrentLast && isBefore) {
      className.push('tail-right');
    }
  }

  return className.join(' ');
};

export const tabStyles = (index, currentIndex, lastIndex) => {
  let className = [];

  if (currentIndex === index) {
    className.push('current__secondary');
    if (index === 0) {
      className.push('left');
    } else if (index === lastIndex) {
      className.push('right');
    } else {
      className.push('middle');
    }
  } else {
    const isBefore = currentIndex - 1 === index;
    const isAfter = currentIndex + 1 === index;
    const isCurrentFirst = currentIndex === 0;
    const isCurrentLast = currentIndex === lastIndex;
    const isCurrentMiddle = currentIndex > 0 && currentIndex < lastIndex;

    if (isCurrentMiddle) {
      if (isBefore) {
        className.push('tail-right');
      }
      if (isAfter) {
        className.push('tail-left');
      }
    } else if (isCurrentFirst && isAfter) {
      className.push('tail-left');
    } else if (isCurrentLast && isBefore) {
      className.push('tail-right');
    }
  }

  return className.join(' ');
};
