import React from 'react';
import classNames from 'utils/classNames';
import Input from './Input';

const InputPrepend = ({
  id,
  name = '',
  type = 'text',
  value,
  onChange,
  disabled,
  prependText = '',
  border = 'border-gray-300',
  rounded = 'rounded-md',
  shadow = '',
  classes = '',
  containerClasses = '',
  ...rest
}) => {
  return (
    <div
      className={classNames(shadow, containerClasses, 'relative rounded-md ')}
    >
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span className="text-gray-500 sm:text-sm">{prependText}</span>
      </div>
      <Input
        type={type}
        {...(id && { id })}
        name={id ?? name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        border={border}
        rounded={rounded}
        shadow={shadow}
        classes={`pl-8 ${classes ?? ''}`}
        {...rest}
      />
    </div>
  );
};

export default InputPrepend;
