import axios from 'axios';
import { useEffect, useState } from 'react';

import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

import { currencyFormatter, percentageFormatter } from 'utils/formatters';
import metrics from '../../utils/metrics';

const attributes = ['sales', 'cost', 'acos'];

const MetricsChart = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      let params = {
        accountId,
        marketplace,
        startDate,
        endDate,
        attributes,
        ...additionalParams,
      };

      const response = await axios.get(
        '/account/advertising/analytics/records',
        { params }
      );

      if (isSubscribed) {
        setRecords(response.data.data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  return (
    <div className={className} id="metrics-chart">
      <div className="h-64">
        <ResponsiveContainer width={'100%'} height="100%" debounce={50}>
          <ComposedChart data={records}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              style={{ fontSize: '11px' }}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis
              yAxisId="0"
              orientation="left"
              stroke="#8884d8"
              style={{ fontSize: '11px' }}
              tickFormatter={(tick) => currencyFormatter(tick)}
            />

            <YAxis
              yAxisId="1"
              orientation="right"
              stroke="#82ca9d"
              tickFormatter={(tick) => percentageFormatter(tick)}
              style={{ fontSize: '11px' }}
            />

            <Tooltip
              content={
                <CustomTooltip containerClassName="bg-white z-10 shadow px-4 py-6 font-sourceSansPro rounded-2xl w-48" />
              }
            />

            <Legend
              wrapperStyle={{
                cursor: 'pointer',
                fontSize: '11px',
                marginTop: '10rem',
              }}
            />

            {attributes
              .map((attribute) =>
                metrics.find((metric) => metric.key === attribute)
              )
              .map((metric) => {
                return (
                  <Line
                    yAxisId={metric.key === 'acos' ? '1' : '0'}
                    key={`metrics-chart-${metric.key}`}
                    type="linear"
                    dataKey={metric.key}
                    fill={metric.color}
                    stroke={metric.color}
                    name={metric.title}
                    dot={{ fill: metric.color }}
                    formatter={metric.rawFormatter}
                    isAnimationActive={false}
                  />
                );
              })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MetricsChart;
