import axios from 'axios';
import { useState, useEffect } from 'react';

const SearchTermExandableRow = ({ accountId, marketplace, searchTerm }) => {
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get('/account/advertising/keywords', {
        params: {
          accountId,
          marketplace,
          campaignType: 'sponsoredProducts',
          keywordText: searchTerm.values.query,
          include: ['adGroup', 'campaign'],
        },
      })
      .then((response) => {
        let { rows } = response.data.data;

        rows = rows.filter((row) => {
          return searchTerm.values.keywordTargeting
            ? searchTerm.values.keywordTargeting.advKeywordId !==
                row.advKeywordId
            : true;
        });
        setKeywords(rows);
      })
      .then(() => setLoading(false));
  }, [accountId, marketplace, searchTerm]);

  if (loading) {
    return 'Loading';
  }

  return keywords.length ? (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ml-2">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap"
            >
              Keyword Text
            </th>
            <th
              scope="col"
              className="py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap"
            >
              Match Type
            </th>
            <th
              scope="col"
              className="py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap"
            >
              Campaign
            </th>
            <th
              scope="col"
              className="py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap"
            >
              Ad Group
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {keywords.map((keyword) => (
            <tr key={keyword.advKeywordId}>
              <td className="py-2.5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap">
                {keyword.keywordText}
              </td>
              <td className="py-2.5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap">
                {keyword.matchType}
              </td>
              <td className="py-2.5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap">
                {keyword.campaign.name}
              </td>
              <td className="py-2.5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap">
                {keyword.adGroup.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="w-full flex justify-center my-3 text-gray-700 font-medium text-sm">
      <span className="text-center">No Related Keywords</span>
    </div>
  );
};

export default SearchTermExandableRow;
