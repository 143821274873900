import { roles } from 'features/roles/constants';

const PROJECT_MANAGER_ASSIGNEE = {
  role: roles.PROJECT_MANAGER,
};

const BRAND_STRATEGIST_ASSIGNEE = {
  role: roles.BRAND_STRATEGIST,
};

const ACCOUNT_SPECIALIST_ASSIGNEE = {
  role: roles.ACCOUNT_SPECIALIST,
};

const PPC_TEAM_LEAD_ASSIGNEE = {
  role: roles.PPC_TEAM_LEAD,
};

const PPC_SPECIALIST_ASSIGNEE = {
  role: roles.PPC_SPECIALIST,
};

const SALES_REPRESENTATIVE_ASSIGNEE = {
  role: roles.SALES_REPRESENTATIVE,
  allowedRoles: [
    roles.SALES_REPRESENTATIVE,
    roles.BUSINESS_DEVELOPMENT_MANAGER,
    roles.DIRECTOR_OF_SALES,
    roles.SENIOR_LEAD_GENERATION_REPRESENTATIVE,
  ],
};

const LEAD_REPRESENTATIVE_ASSIGNEE = {
  role: roles.LEAD_GENERATION_REPRESENTATIVE,
  allowedRoles: [
    roles.LEAD_GENERATION_REPRESENTATIVE,
    roles.LEAD_GENERATION_PITCHER,
    roles.LEAD_GENERATION_TEAM_CAPTAIN,
    roles.LEAD_PITCHING_TEAM_CAPTAIN,
  ],
};

const SALES_ADMINISTRATOR_ASSIGNEE = {
  role: roles.SALES_ADMINISTRATOR,
  allowedRoles: [
    roles.SALES_ADMINISTRATOR,
    roles.SALES_ADMINISTRATOR_TEAM_LEAD,
  ],
};

const DESIGNER_ASSIGNEE = {
  role: roles.GRAPHIC_DESIGNER,
  label: 'Designer',
};

const COPYWRITER_ASSIGNEE = {
  role: roles.COPYWRITER,
};

export const assignees = {
  PROJECT_MANAGER_ASSIGNEE,
  BRAND_STRATEGIST_ASSIGNEE,
  ACCOUNT_SPECIALIST_ASSIGNEE,
  PPC_TEAM_LEAD_ASSIGNEE,
  PPC_SPECIALIST_ASSIGNEE,
  SALES_REPRESENTATIVE_ASSIGNEE,
  LEAD_REPRESENTATIVE_ASSIGNEE,
  SALES_ADMINISTRATOR_ASSIGNEE,
  DESIGNER_ASSIGNEE,
  COPYWRITER_ASSIGNEE,
};
