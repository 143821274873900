import { useRoles } from 'features/roles';
import { useSelector } from 'react-redux';

import { selectAuthenticatedUser } from 'features/auth/authSlice';

const usePermissions = () => {
  const user = useSelector(selectAuthenticatedUser);

  const {
    levels,
    BRAND_STRATEGIST,
    AGENCY_SUPER_USER,
    ACCOUNT_SPECIALIST,
    SALES_ADMINISTRATOR,
  } = useRoles();

  const userCan = (permissions) => {
    return user?.role?.permissions
      ? user.role.permissions.some((p) =>
          permissions.split('|').includes(p.access)
        )
      : false;
  };

  const userCanAll = (permissions) => {
    return permissions
      .split('|')
      .every((p) => user.role.permissions.find((pm) => pm.access === p));
  };

  const isAgencyLevel = () => {
    return user.role.level === levels.AGENCY_LEVEL;
  };

  const isApplicationLevel = () => {
    return user.role.level === levels.APPLICATION_LEVEL;
  };

  const isAgencySuperUser = () => {
    return user.role.code === AGENCY_SUPER_USER.value;
  };

  const isMine = (userId) => {
    return user.userId === userId;
  };

  const isSalesAdmin = () => {
    return user.role.code === SALES_ADMINISTRATOR.value;
  };

  const isBrandStrategist = () => {
    return user.role.code === BRAND_STRATEGIST.value;
  };

  const isAccountSpecialist = () => {
    return user.role.code === ACCOUNT_SPECIALIST.value;
  };

  const hasAccessToAllClients = () => {
    return user.role.hasAccessToAllClients;
  };

  return {
    userCan,
    userCanAll,
    isAgencyLevel,
    isApplicationLevel,
    isAgencySuperUser,
    isMine,
    isSalesAdmin,
    hasAccessToAllClients,
    isBrandStrategist,
    isAccountSpecialist,
  };
};

export default usePermissions;
