import { isFunction } from 'lodash';
import { Pie, Cell, PieChart, ResponsiveContainer, Label } from 'recharts';

const ClientSummary = ({ total = 0, loading, records = [] }) => {
  function CustomLabel(props) {
    const { cx, cy } = props.viewBox;
    return (
      <>
        <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central">
          <tspan
            alignmentBaseline="middle"
            fontSize="32px"
            fontFamily="Inter"
            fontWeight="bold"
          >
            {props.value}
          </tspan>
        </text>
        <text x={cx} y={cy + 25} textAnchor="middle" dominantBaseline="central">
          <tspan fontSize="13px" fontFamily="Source Sans Pro">
            Total
          </tspan>
        </text>
      </>
    );
  }

  return (
    <div className="bg-white p-6">
      <p className="font-inter text-grayscale-900 leading-1.2 tracking-3/4 font-bold">
        Distribution
      </p>

      <div className="h-72 flex items-center">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-2xl text-grayscale-700 animate-pulse tracking-2 leading-1.5 font-inter text-center items-center">
              Loading Data.....
            </p>
          </div>
        ) : total ? (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={records}
                dataKey="value"
                nameKey="name"
                innerRadius={70}
                outerRadius={100}
                labelLine={false}
                isAnimationActive={false}
              >
                <Label
                  width={30}
                  position="center"
                  content={<CustomLabel value={total} />}
                ></Label>

                {records.map((entry) => {
                  return <Cell key={`${entry.name}`} fill={entry.color} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p className="text-2xl text-grayscale-700 tracking-2 leading-1.5 font-inter text-center items-center">
            No Data to display.
          </p>
        )}
      </div>

      <ul>
        {records.map((record) => (
          <li key={record.name} className="flex items-center space-x-2 mb-2">
            <div
              className="w-2 h-2"
              style={{ backgroundColor: record.color }}
            />

            <p className="w-full text-grayscale-900 text-11 leading-1.5 flex items-center justify-between">
              <span>{record.name}</span>
              <span>{record.value}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientSummary;
