import Button from 'components/Button';
import Toggle from 'components/Toggle';
import FormikErrorNotification from 'components/FormikErrorNotification';
import Modal from 'components/Modal';
import usePermissions from 'hooks/usePermissions';
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { pick } from 'lodash';
import { proposalValidationSchema } from './ProposalReducer';
import ModalHeader from 'components/ModalHeader';
import SubscriptionForm from './components/SubscriptionForm';

const ProposalModal = ({ proposal, open, setOpen, data, onSubmit }) => {
  const { userCan } = usePermissions();
  const [initialValues, setInitialValues] = useState(data);
  useEffect(() => {
    if (data) {
      setInitialValues(
        pick(data, [
          'plan_code',
          'service',
          'noOfAsins',
          'name',
          'price',
          'noExpiry',
          'billing_cycles',
          'currency_code',
          'defaultMarketplace',
          'plan_description',
          'convertDisabled',
          'convert_retainer_cycle',
          'retainer_after_convert',
          'contractSigned',
          'start_date',
          'paymentOption',
          'freeServiceReason',
          'images',
          'approvedBy',
          'contractEndDate',
          'interval',
          'skipProposal',
        ])
      );
    }
  }, [data]);

  const validationSchema = object().shape(proposalValidationSchema);

  const onClose = () => setOpen(false);

  const onSubmitForm = (values) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Modal setOpen={setOpen} open={open} as="div" zIndex="z-20">
      <div className="bg-white absolute top-8 inset-x-1/3 rounded-lg w-1/3 ">
        <ModalHeader
          title="Create Proposal"
          setOpen={setOpen}
          border="0"
          fontSize="text-2xl"
          fontStyle="font-bold"
          px="px-10"
          classes="pt-10"
        />

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikErrorNotification />
              <div className="overflow-y-auto" style={{ height: '500px' }}>
                <SubscriptionForm action="edit" open={open} />
              </div>

              <div className="flex items-center justify-between pt-6 px-8 pb-10">
                <div className="flex items-center gap-1">
                  <button
                    type="button"
                    className="text-secondary-light font-bold tracking-widest text-sm mr-2"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {userCan('sales.profiles.manage') && (
                    <div className="">
                      <Button
                        type="submit"
                        classes="tracking-widest px-12 py-1.5 rounded-full text-sm font-bold text-white bg-blue-900 hover:bg-blue-400 focus:ring-red-500 border border-transparent border-0 font-bold tracking-wider inline-flex items-center focus:outline-none"
                        px={10}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>

                <div className="flex items-center gap-2">
                  <span className="whitespace-nowrap text-xs text-grayscale-600">
                    Skip Proposal
                  </span>
                  <Toggle
                    className="ml-8"
                    onChange={() => {
                      setFieldValue('skipProposal', !values.skipProposal);
                    }}
                    checked={values.skipProposal}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProposalModal;
