import axios from 'axios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import DatePicker from 'features/datePicker/DatePicker';

import classNames from 'utils/classNames';
import TableSummary from './components/TableSummary';
import ChartDisplay from './components/ChartDisplay';
import SmartFilter from '../../../components/SmartFilter';

import metrics from '../../../utils/metrics';

const attributes = [
  'impressions',
  'clicks',
  'ctr',
  'cpc',
  'cr',
  'sales',
  'cost',
  'acos',
  'tacos',
  'unitsSold',
  'advertisingSalesPercentage',
  'organicUnitsSold',
  'organicSalesPercentage',
  'totalSales',
];

const granularities = [
  { key: 'week', display: 'Weekly' },
  { key: 'month', display: 'Monthly' },
];

const Breakdown = () => {
  const { account, marketplace } = useSelector((state) => state.account);
  const { range: dateRange } = useSelector((state) => state.datePicker);

  const [loading, setLoading] = useState(false);
  const [breakdown, setBreakdown] = useState([]);
  const [selectedGranularity, setSelectedGranularity] = useState('week');

  const [filters, setFilters] = useState({
    advCampaignIds: [],
    advPortfolioIds: [],
    campaignTypes: [],
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(
        `/account/advertising/analytics/${selectedGranularity}`,
        {
          params: {
            accountId: account.accountId,
            marketplace: marketplace.details.countryCode,
            ...dateRange,
            include: ['totalSales'],
            attributes: [
              'impressions',
              'clicks',
              'ctr',
              'cpc',
              'cr',
              'sales',
              'cost',
              'acos',
              'unitsSold',
            ],
            ...filters,
          },
        }
      );

      if (isSubscribed) {
        setBreakdown(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, dateRange, selectedGranularity, filters]);

  const convertCsv = () => {
    let header = [
      'Date Range',
      ...attributes
        .map((attr) => metrics.find((m) => m.key === attr))
        .map((m) => m.title),
    ];

    let values = breakdown.map((item) => {
      return [
        item.dateRange,
        ...attributes
          .map((attr) => metrics.find((m) => m.key === attr))
          .map((metric) => `"${metric.rawFormatter(item[metric.key])}"`),
      ];
    });

    let csv = '';
    csv += header.toString() + '\n' + values.join('\n');
    const blob = new Blob([csv], {
      type: 'text/csv',
      encoding: 'UTF-8',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'trends';
    link.click();
  };

  return (
    <div>
      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-3">
          <DatePicker
            position="left"
            showLabel={false}
            buttonBg="grayscale-400"
          />
        </div>

        <div className="col-span-2 col-start-7">
          <SmartFilter
            accountId={account.accountId}
            marketplace={marketplace.details.countryCode}
            campaignIds={filters.advCampaignIds}
            campaignTypes={filters.campaignTypes}
            portfolioIds={filters.advPortfolioIds}
            onChange={setFilters}
            buttonBg="grayscale-400"
            productIds={[]}
            only={['campaignTypes', 'advPortfolioIds', 'advCampaignIds']}
          />
        </div>
      </div>

      <ChartDisplay
        className="mt-10"
        loading={loading}
        attributes={attributes}
        records={breakdown}
      />

      <div className="mt-12">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-grayscale-700 text-mini leading1.5">
              {granularities.find((g) => g.key === selectedGranularity).display}
            </p>
            <p className="font-inter font-bold tracking-3/4 text-grayscale-900 leading-1.2">
              Breakdown
            </p>
          </div>
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center font-sourceSansPro">
              <p className="text-grayscale-800 text-mini mr-3">Sort By</p>
              <div className="relative z-0 inline-flex rounded-md">
                {granularities.map((granularity, index) => {
                  return (
                    <button
                      key={`trends-breakdown-granularity-option-${granularity.key}`}
                      type="button"
                      onClick={() => setSelectedGranularity(granularity.key)}
                      className={classNames(
                        index ? 'rounded-r-md' : 'rounded-l-md',
                        selectedGranularity === granularity.key
                          ? 'bg-grayscale-800 text-white'
                          : 'bg-white text-grayscale-800',
                        'tracking-2 font-bold relative inline-flex items-center py-2 px-8 border-2 border-grayscale-800 text-tiny'
                      )}
                    >
                      {granularity.display}
                    </button>
                  );
                })}
              </div>
            </div>
            <button
              onClick={convertCsv}
              className="font-bold relative inline-flex items-center py-2 px-8 border-2 tracking-2 border-grayscale-800 text-grayscale-800 text-tiny rounded-md"
              disabled={loading}
            >
              Export
            </button>
          </div>
        </div>

        <TableSummary
          loading={loading}
          attributes={attributes}
          records={breakdown}
        />
      </div>
    </div>
  );
};

export default Breakdown;
