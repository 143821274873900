import { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import Badge from 'components/Badge';
import { SearchIcon } from '@heroicons/react/outline';
import Spinner from 'components/Spinner';
const _ = require('lodash');

const GlobalSearch = ({ history }) => {
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async (inputValue) => {
    try {
      setLoading(true);
      const response = await axios.get('/agency/search/list-searched-clients', {
        params: {
          query: inputValue,
        },
      });
      setLoading(false);
      const data = response.data.output;
      const newFormattedOptions = data.map((item) => ({
        value: item.client ? item.client : item.companyName,
        label: (
          <div className="w-full pr-2">
            <Link
              className="flex justify-between items-center"
              to={
                item.agencyClientId
                  ? `/clients/${item.agencyClientId}/dashboard`
                  : `/leads/profile/${item.leadId}`
              }
            >
              {item.profileLink ? (
                <img
                  src={item.profileLink}
                  alt={item.client}
                  className="h-7 w-7 rounded-full"
                />
              ) : (
                <Badge
                  classes="rounded-full bg-gray-200 text-gray-900 w-7 h-7 justify-center shadow"
                  color=""
                  rounded="lg"
                >
                  <span>{`${
                    item.client
                      ? item.client.charAt(0).toUpperCase()
                      : item.companyName.charAt(0).toUpperCase()
                  }`}</span>
                  <span>{`${
                    item.client
                      ? item.client.charAt(1).toUpperCase()
                      : item.companyName.charAt(1).toUpperCase()
                  }`}</span>
                </Badge>
              )}{' '}
              <span className="w-28 break-words -ml-3 text-sm">
                {' '}
                {item.client ? item.client : item.companyName}
              </span>
              <span
                className={`text-xs font-bold rounded px-1 ${
                  item.agencyClientId
                    ? 'text-red-600 bg-red-200'
                    : 'text-blue-600 bg-blue-200'
                }`}
              >
                {item.agencyClientId ? 'C' : 'L'}
              </span>
            </Link>
          </div>
        ),
      }));
      setFormattedOptions(newFormattedOptions);
    } catch (error) {
      console.error('Error fetching data from backend:', error);
    }
  };

  const handleInputChange = _.debounce((inputValue) => {
    if (inputValue) {
      fetchData(inputValue);
    } else {
      setFormattedOptions([]);
    }
  }, 1000);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      '& input:focus': {
        boxShadow: 'none',
        borderColor: 'transparent',
      },
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    singleValue: (styles) => ({ ...styles, width: '100%' }),
  };
  const filterOption = () => true;
  const loader = [
    {
      value: 'loading',
      label: (
        <div className="flex justify-center">
          <Spinner color="dodgerblue" />
        </div>
      ),
    },
  ];
  return (
    <span className="w-48 sm:w-56 mr-4">
      <Select
        placeholder={
          <span className="flex items-center">
            <SearchIcon className="h-4 w-4 mr-2" /> Enter "Name or Email"
          </span>
        }
        options={loading ? loader : formattedOptions}
        onInputChange={handleInputChange}
        isClearable
        isSearchable
        styles={customStyles}
        filterOption={filterOption}
        closeMenuOnSelect
        noOptionsMessage={() => 'No Results'}
      />
    </span>
  );
};

export default withRouter(GlobalSearch);
