import StatusMenu from './StatusDropdown';
import { useState, useRef, useMemo } from 'react';
import PriorityMenu from './PriorityDropdown';
import classNames from 'utils/classNames';
import DueDateDropdown from './DueDateDropdown';
import ClientAssignee from './ClientAssignee';
import { isFunction } from 'lodash';

import useTasks from '../../useTasks';
import Assignees from './Assignees';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';

const TaskItem = ({
  taskItem,
  columns = [],
  showClientName = true,
  onClick,
  onUpdate,
}) => {
  const {
    updateTask,
    addAssignee,
    getAssignees,
    removeAssignee,
    updateAssignee,
  } = useTasks();

  const toggleSubtasks = useRef();

  const [task, setTask] = useState(taskItem);
  const [isHovered, setIsHovered] = useState(false);
  const [openSubtasks, setOpenSubtasks] = useState(false);

  const hasSubtasks = useMemo(
    () =>
      'subtasks' in taskItem &&
      Array.isArray(taskItem.subtasks) &&
      !!taskItem.subtasks.length,
    [taskItem]
  );

  const onChange = async (attribute, value) => {
    const response = await updateTask(task.taskItemId, { [attribute]: value });

    const updatedTask = { ...task, ...response.data };
    setTask(updatedTask);

    if (isFunction(onUpdate)) {
      onUpdate(attribute, updateTask);
    }
  };

  const onAddAssignee = async (userId) => {
    await addAssignee(task.taskItemId, userId);

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  const onRemoveAssignee = async (userId) => {
    await removeAssignee(task.taskItemId, userId);

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  const onUpdateAssignee = async (userId, assigneeId) => {
    await updateAssignee(task.taskItemId, userId, { assigneeId });

    const response = await getAssignees(task.taskItemId);

    setTask({ ...task, assignees: response.data });
  };

  return (
    <>
      <div
        className="py-4 grid grid-cols-12 gap-4 hover:bg-grayscale-300"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={classNames(
            'col-span-12 lg:col-span-3 xl:col-span-5 flex items-center space-x-2',
            hasSubtasks ? 'pl-4' : taskItem.parentTaskItemId ? 'pl-16' : 'pl-10'
          )}
        >
          {hasSubtasks && (
            <ChevronRightIcon
              onClick={() => {
                toggleSubtasks.current.click();
                setOpenSubtasks(!openSubtasks);
              }}
              className={classNames(
                'w-4 h-4 text-grayscale-700 cursor-pointer',
                openSubtasks ? 'rotate-90 transform' : ''
              )}
            />
          )}

          <div>
            {showClientName && (
              <p className="text-grayscale-700 text-11 leading-1.5">
                {task?.list?.client?.client}
              </p>
            )}

            <p
              className={classNames(
                'text-13 leading-1.5 cursor-pointer',
                isHovered ? 'text-secondary-light' : 'text-grayscale-800'
              )}
              onClick={() => {
                if (isFunction(onClick)) {
                  onClick(task);
                }
              }}
            >
              {task?.name}
            </p>
          </div>
        </div>

        {columns.some((column) => column.key === 'assignees') && (
          <div
            className={classNames(
              'flex justify-center',
              columns.find((column) => column.key === 'assignees').className
            )}
          >
            <Assignees
              onAdd={onAddAssignee}
              onRemove={onRemoveAssignee}
              onUpdate={onUpdateAssignee}
              value={task.assignees ?? []}
            />
          </div>
        )}

        {columns.some(
          (column) => column.key === 'list.client.brandStategist'
        ) && (
          <div
            className={classNames(
              'flex justify-center',
              columns.find(
                (column) => column.key === 'list.client.brandStategist'
              ).className
            )}
          >
            <ClientAssignee user={task.list?.client?.brandStrategist?.user} />
          </div>
        )}

        {columns.some(
          (column) => column.key === 'list.client.accountSpecialist'
        ) && (
          <div
            className={classNames(
              'flex justify-center',
              columns.find(
                (column) => column.key === 'list.client.accountSpecialist'
              ).className
            )}
          >
            <ClientAssignee user={task.list?.client?.accountSpecialist?.user} />
          </div>
        )}

        {columns.some((column) => column.key === 'dueDate') && (
          <div
            className={classNames(
              'flex justify-center',
              columns.find((column) => column.key === 'dueDate').className
            )}
          >
            <DueDateDropdown
              value={task.dueDate}
              setValue={(dueDate) => onChange('dueDate', dueDate ?? null)}
            />
          </div>
        )}

        {columns.some((column) => column.key === 'priority') && (
          <div
            className={classNames(
              columns.find((column) => column.key === 'priority').className
            )}
          >
            <PriorityMenu
              value={task.priority}
              onChange={(priority) => onChange('priority', priority)}
            />
          </div>
        )}

        {columns.some((column) => column.key === 'status') && (
          <div
            className={classNames(
              columns.find((column) => column.key === 'status').className
            )}
          >
            <StatusMenu
              value={task.status}
              options={task.list.statuses}
              onChange={(status) => onChange('status', status)}
            />
          </div>
        )}
      </div>

      {hasSubtasks && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button ref={toggleSubtasks} className="hidden">
                oyyy
              </Disclosure.Button>

              <Disclosure.Panel className="bg-white">
                {taskItem.subtasks
                  .map((subtask) => ({ ...taskItem, ...subtask, subtasks: [] }))
                  .map((subtask) => (
                    <TaskItem
                      key={subtask.taskItemId}
                      taskItem={subtask}
                      columns={columns}
                      showClientName={false}
                    />
                  ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

export default TaskItem;
