import axios from 'axios';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/outline';

import useQuery from 'hooks/useQuery';

import DatePicker from 'features/datePicker/DatePicker';
import { selectCurrentDateRange } from 'features/datePicker/datePickerSlice';

import { Table } from 'components';
import InputPrepend from 'components/Forms/InputPrepend';

import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utils/formatters';

const ProductPerformance = ({ account, marketplace }) => {
  let query = useQuery();
  const history = useHistory();
  const selectedDates = useSelector(selectCurrentDateRange);
  const [loading, setLoading] = useState(false);
  let [performanceData, setPerformanceData] = useState({ rows: [] });
  const [params, setParams] = useState({
    page: parseInt(query.get('page') ?? 1),
    pageSize: parseInt(query.get('pageSize') ?? 10),
    sort: query.get('sort') ?? 'price',
    search: query.get('search') ?? '',
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get(`/account/products/listings`, {
        params: {
          ...params,
          accountId: account.accountId,
          marketplace: marketplace.details.countryCode,
          startDate: selectedDates.startDate,
          endDate: selectedDates.endDate,
          attributes: [
            'listingId',
            'asin',
            'sku',
            'price',
            'title',
            'thumbnail',
            'sessions',
            'pageViews',
            'unitsRefund',
            'margin',
            'cost',
            'profit',
            'totalRevenue',
            'roi',
          ],
        },
      });

      if (isSubscribed) {
        setPerformanceData(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [account, marketplace, params, selectedDates]);

  const updateParams = (newParams, search = false) => {
    setParams(newParams);
    query.set('page', newParams.page);
    query.set('pageSize', newParams.pageSize);
    query.set('sort', newParams.sort);

    if (search) {
      if (newParams.search === '') {
        query.delete('search');
      } else {
        query.set('search', newParams.search);
      }
    }

    history.push(window.location.pathname + '?' + query.toString());
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = {
      ...params,
      page,
      pageSize: sizePerPage,
    };
    updateParams(newParams);
  };

  const tableColumns = [
    {
      dataField: 'listingId',
      text: 'Products',
      sort: false,
      headerStyle: {
        minWidth: '350px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return (
          <div className="flex">
            <img
              src={row.thumbnail}
              className="h-20 w-20 mr-2"
              alt={row.asin}
            />

            <div className="relative flex flex-col justify-center px-3.5">
              <div className="mb-3.5">
                <p className="font-normal text-tiny text-grayscale-800 leading-4">
                  {row.title}
                </p>
              </div>
              <div className="mb-3.5">
                <p className="text-tiny">
                  <span className="text-grayscale-600">ASIN: </span>
                  <span className="text-secondary-light underline">
                    {row.asin}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-tiny">
                  <span className="text-grayscale-600">SKU: </span>
                  <span className="text-grayscale-800 font-normal">
                    {row.sku}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'totalRevenue',
      text: 'Gross Revenue',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(row.totalRevenue)}
          </p>
        );
      },
    },
    {
      dataField: 'cost',
      text: 'Expenses',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(row.cost)}
          </p>
        );
      },
    },
    {
      dataField: 'profit',
      text: 'Net Profit',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {currencyFormatter(row.profit ?? 0)}
          </p>
        );
      },
    },
    {
      dataField: 'margin',
      text: 'Margin',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {percentageFormatter(row.margin)}
          </p>
        );
      },
    },
    {
      dataField: 'roi',
      text: 'ROI',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {percentageFormatter(row.roi)}
          </p>
        );
      },
    },
    {
      dataField: 'unitsRefund',
      text: 'Refunds',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(row.unitsRefund)}
          </p>
        );
      },
    },
    {
      dataField: 'pageViews',
      text: 'Page Views',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(row.pageViews)}
          </p>
        );
      },
    },
    {
      dataField: 'sessions',
      text: 'Sessions',
      sort: false,
      headerStyle: {
        minWidth: '120px',
        textAlign: 'right',
      },
      formatter: (cell, row) => {
        return (
          <p className="flex justify-end text-mini text-grayscale-800 font-normal">
            {numberFormatter(row.sessions)}
          </p>
        );
      },
    },
  ];

  const onSearch = (e) => {
    updateParams({
      ...params,
      search: e.target.value,
    });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  return (
    <div className="bg-white p-5">
      <div className="grid grid-cols-12 mb-8">
        <div className="col-span-4 flex items-center space-x-4">
          <h1 className="text-grayscale-900 text-xl tracking-3/4 leading-1.2 font-inter font-bold">
            Product Performance
          </h1>
        </div>

        <div className="col-span-4 flex justify-end items-center space-x-4">
          <div className="sm:col-span-2 bg-grayscale-400">
            <InputPrepend
              name="search"
              defaultValue={params.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Name, ASINs or SKUs'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
              classes="py-3.5 pr-6 pl-9"
            />
          </div>
        </div>

        <div className="col-span-4 pl-3">
          <DatePicker
            position="right"
            showLabel={false}
            buttonBg="grayscale-400"
          />
        </div>
      </div>

      <Table
        columns={tableColumns}
        data={performanceData}
        onTableChange={onTableChange}
        params={params}
        keyField="listingId"
        defaultSorted={[{ dataField: 'price', order: 'desc' }]}
        loading={loading}
      />
    </div>
  );
};

export default ProductPerformance;
