import axios from 'axios';
import { useEffect, useState } from 'react';
import { Pie, Cell, PieChart, ResponsiveContainer } from 'recharts';

import metrics from '../../utils/metrics';

const attributes = ['sales', 'cost', 'orders'];

const KeywordTrackingComparison = ({
  className = '',
  accountId,
  marketplace,
  startDate,
  endDate,
  additionalParams = {},
}) => {
  const [overview, setOverview] = useState([
    { type: 'branded' },
    { type: 'non-branded' },
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const data = await Promise.all(
        ['branded', 'non-branded'].map(async (type) => {
          const response = await axios.get(
            `/account/advertising/${type}-keywords/analytics`,
            {
              params: {
                endDate,
                startDate,
                accountId,
                marketplace,
                attributes,
                ...additionalParams,
              },
            }
          );

          return { type, ...response.data.data };
        })
      );

      if (isSubscribed) {
        setOverview(data);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [accountId, marketplace, startDate, endDate, additionalParams]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        className="font-sourceSansPro"
        fontSize="13px"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    !!overview.some((i) => i.sales || i.cost || i.orders) && (
      <div className={`${className} rounded-3xl bg-white bs-shadow py-6`}>
        <div className="flex items-center justify-between border-b px-8 pb-6">
          <p className="font-bold text-inter leading-1.2 text-grayscale-900 tracking-3/4 text-25 text-grayscale-800">
            Branded Vs. Non-Branded Keyword
          </p>
        </div>

        {overview.some((element) =>
          attributes.some((attr) => !element[attr])
        ) ? (
          <p className="font-inter text-center pt-8 text-lg font-bold tracking-2 text-grayscale-700">
            No data to display
          </p>
        ) : (
          <>
            <div className="px-8 mt-8 grid grid-cols-3 gap-12">
              {attributes
                .map((attr) => metrics.find((m) => m.key === attr))
                .map((metric) => (
                  <div key={`keyword-tracking-comparison-${metric.key}`}>
                    <p className="font-inter font-bold leading-1.2 text-xl tracking-3/4 text-center">
                      {metric.title}
                    </p>

                    <div className="h-48 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={overview}
                            dataKey={metric.key}
                            nameKey="type"
                            cx="50%"
                            cy="50%"
                            innerRadius={30}
                            outerRadius={70}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            isAnimationActive={false}
                          >
                            {overview.map((entry, index) => {
                              return (
                                <Cell
                                  key={`keyword-tracking-comparison-${metric.key}-graph-${entry.type}`}
                                  fill={
                                    entry.type === 'branded'
                                      ? '#FF89A6'
                                      : '#1879D8'
                                  }
                                />
                              );
                            })}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                ))}
            </div>

            <div className="flex justify-center mt-4">
              <div className="flex items-center mr-5">
                <div className="w-4 h-4 bg-primary-light"></div>
                <p className="text-grayscale-900 leading-1.5 text-tiny ml-1">
                  Branded
                </p>
              </div>

              <div className="flex items-center">
                <div className="w-4 h-4 bg-secondary-light"></div>
                <p className="text-grayscale-900 leading-1.5 text-tiny ml-1">
                  Non-Branded
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default KeywordTrackingComparison;
