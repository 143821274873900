import moment from 'moment-timezone';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from 'components/Charts/CustomTooltip';

const HoursWorked = ({ records = [] }) => {
  const chartStyle = { fontSize: '13px', color: '#666666', lineHeight: '150%' };

  return (
    <div className="bg-white rounded-xl p-6">
      <p className="font-inter font-bold leading-1.2 tracking-3/4 text-grayscale-900 mb-5">
        Total Hours Worked Per Day
      </p>

      <div className="w-full h-72 md:h-60 mt-2">
        <ResponsiveContainer className="w-full" height="100%">
          <BarChart className="w-full" data={records}>
            <XAxis
              dataKey="startDate"
              style={chartStyle}
              tickFormatter={(value) => moment(value).format('MMM D')}
            />

            <YAxis
              yAxisId="left"
              style={chartStyle}
              axisLine={false}
              tickFormatter={(value) =>
                moment.duration(value, 'seconds').format('h')
              }
            />

            <Tooltip
              content={
                <CustomTooltip containerClassName="bg-white z-10 shadow p-2 font-sourceSansPro rounded" />
              }
            />

            <Bar
              yAxisId="left"
              fill="#FF89A6"
              name="Hours Worked"
              dataKey="trackedSummation"
              formatter={(value) =>
                moment.duration(value, 'seconds').format('hh:mm')
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HoursWorked;
