import axios from 'axios';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import Select from 'components/Forms/Select';
import SearchBar from './filters/SearchBar';
import SelectFilter from './filters/SelectFilter';
import StatusFilter from './filters/StatusFilter';
import StatusSwitch from './common/StatusSwitch';
import ExportButton from './ExportButton';

import {
  AD_GROUPS,
  CAMPAIGNS,
  NEGATIVE_EXACT,
  NEGATIVE_PHRASE,
} from '../utils/constants';

const NegativeKeywords = ({ accountId, marketplace, campaignType }) => {
  const { t } = useTranslation();
  const [negativeKeywords, setNegativeKeywords] = useState({ rows: [] });

  const [level, setLevel] = useState(AD_GROUPS);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    const payload = {
      accountId,
      marketplace,
      campaignType,
      ...params,
      include: [level === CAMPAIGNS ? 'campaign' : 'adGroup'],
    };

    const url =
      level === CAMPAIGNS ? 'campaign-negative-keywords' : 'negative-keywords';

    axios
      .get(`/advertising/${url}`, { params: payload })
      .then((response) => setNegativeKeywords(response.data.data));
  }, [accountId, marketplace, campaignType, level, params]);

  const columns = [
    {
      dataField: 'keywordText',
      text: t('Advertising.NegKeyword.Text'),
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <p>{startCase(cell)}</p>
            {row.AdvAdGroup && (
              <p className="text-grayscale-600 text-tiny">
                Ad Group: {row.AdvAdGroup.name}
              </p>
            )}
          </>
        );
      },
    },
    {
      dataField: 'row.AdvCampaign',
      text: 'Campaign',
      sort: false,
      formatter: (cell, row) =>
        row.AdvCampaign ? row.AdvCampaign.name : row.AdvAdGroup.campaign.name,
    },
    {
      dataField: 'matchType',
      text: t('Advertising.NegKeyword.MatchType'),
      sort: true,
      formatter: (cell) => startCase(cell),
    },
    {
      dataField: 'state',
      text: t('Advertising.Common.Status'),
      sort: true,
      headerStyle: { minWidth: '75px' },
      formatter: (cell, row) => (
        <StatusSwitch
          status={cell}
          entity={
            level === CAMPAIGNS
              ? 'campaign-negative-keywords'
              : 'negative-keywords'
          }
          entityId={
            row[
              level === CAMPAIGNS
                ? 'advCampaignNegativeKeywordId'
                : 'advNegativeKeywordId'
            ]
          }
          accountId={accountId}
          marketplace={marketplace}
          disabled={true}
        />
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sort;

    if (sortField && sortOrder) {
      newParams.sort = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  return (
    <div className="my-4">
      <div className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-12">
        <SearchBar
          params={params}
          className="lg:col-span-4"
          onApplyFilter={setParams}
          placeholder="Search Keyword"
        />

        <Select
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lg:col-span-2"
          onChange={(e) => setLevel(e.target.value)}
        >
          <option value={AD_GROUPS}>
            {t('Advertising.NegKeyword.Filter.Level.AdGroup')}
          </option>
          <option value={CAMPAIGNS}>
            {t('Advertising.NegKeyword.Filter.Level.Campaign')}
          </option>
        </Select>

        <StatusFilter
          params={params}
          setParams={setParams}
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lg:col-span-2"
        />

        <SelectFilter
          name="matchType"
          onApplyFilter={setParams}
          params={params}
          className="p-4 border-0 bg-white block w-full rounded-2xl border-gray-300 focus:outline-none focus:ring-0 appearance-none focus:appearance-none text-mini placeholder-grayscale-600 text-grayscale-900 leading-normal lg:col-span-2"
          placeholder={t('Advertising.NegKeyword.Filter.MatchType')}
          options={[
            {
              value: NEGATIVE_EXACT,
              display: t(
                'Advertising.NegKeyword.Filter.MatchType.NegativeExact'
              ),
            },
            {
              value: NEGATIVE_PHRASE,
              display: t(
                'Advertising.NegKeyword.Filter.MatchType.NegativePhrase'
              ),
            },
          ]}
        />

        <ExportButton
          className="lg-col-span-2"
          params={{
            accountId,
            marketplace,
            campaignType,
            include: [level === AD_GROUPS ? 'adGroup' : 'campaign'],
          }}
          endpoint={
            level === AD_GROUPS
              ? 'negative-keywords'
              : 'campaign-negative-keywords'
          }
        />
      </div>
      <Table
        keyField={
          level === CAMPAIGNS
            ? 'advCampaignNegativeKeywordId'
            : 'advNegativeKeywordId'
        }
        columns={columns.map((column) => {
          return {
            ...column,
            classes: `py-5 px-4 text-mini text-grayscale-800 leading-normal whitespace-nowrap`,
            headerClasses: `py-5 px-4 text-left text-mini font-medium text-grayscale-600 leading-normal bg-grayscale-200 border-b border-grayscale-500 whitespace-nowrap`,
          };
        })}
        data={negativeKeywords}
        onTableChange={onTableChange}
        params={params}
        bordered={false}
      />
    </div>
  );
};

export default NegativeKeywords;
